import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './PageCard.module.scss';
import AddIcon from '../../../../assets/icons/plus-icon.svg';
import ArrowIcon from '../../../../assets/icons/arrow.svg';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import UnfavoriteIcon from '../../../../assets/icons/star-not-favorite-active.svg';
import FavoriteIcon from '../../../../assets/icons/star-favorite-active.svg';
import DeleteIcon from '../../../../assets/icons/delete-red-bin.svg';
import UrlIcon from '../../../../assets/icons/url-page-icon.svg';
import ButtonSpinner from '../../UI/Spinner/ButtonSpinner';

import { handleImagePath } from '../../../../state/utils/FileManagement';
import { deletePage } from '../../../../state/ducks/Pages/actions';

class PageCard extends Component {

  onEditPageHandler = (event) => {
    event.stopPropagation();
    this.props.editingPageHandler(this.props.data.id);
  }

  goToDefaultHandler = () => {
    this.props.click(this.props.data);
  }

  goToUrlHandler = () => {
    window.open(this.props.data.url);
  }

  render() {
    let isMobile = this.props.isMobile;

    if (this.props.addingCard) {
      return (
        <div className={isMobile ? `${styles.CardMobile} ${styles.CardAddingStyle}` : `${styles.Card} ${styles.CardAddingStyle}`} onClick={this.props.click}>
          <img src={AddIcon} alt="Add" className={styles.AddIcon} />
          <div className={styles.CardBody} style={{ textAlign: 'center', padding: '0 30px' }}>
            <p className={styles.CardTitle}>Add New Page</p>
            <p className={styles.CardSubTitle}>Create your new pages where you can add details or add URL.</p>
          </div>
        </div>
      );
    } else {
      if (this.props.data) {
        const { id, name, detail, properties } = this.props.data;

        const isPageAdmin = this.props.data && this.props.loggedInUser.id === this.props.data.admin_id;
        const isOrgAdmin = this.props.currentOrg && this.props.loggedInUser.admin_of_org && this.props.loggedInUser.admin_of_org.includes(+this.props.currentOrg);
        const isAdmin = this.props.loggedInUser && this.props.loggedInUser.is_superuser;
        return (
          <div className={isMobile ? `${styles.CardMobile} ${styles.CardFlexStyle}` : `${styles.Card} ${styles.CardFlexStyle}`} onClick={+this.props.data.page_type !== 2 ? this.goToDefaultHandler : this.goToUrlHandler}>
            <div className={`${styles.CardHover} ${isPageAdmin || isOrgAdmin || isAdmin ? styles.AdminCardHover : styles.MemberCardHover}`}>
              {
                this.props.loading ?
                  <div className={styles.ActionButtons}><div className={styles.Loading}><ButtonSpinner /></div></div> :
                  this.props.favorite ?
                    <div className={styles.ActionButtons} onClick={(event) => this.props.togglePageFavorite(event, 'unfav', this.props.favorite.id)}>
                      <img src={FavoriteIcon} alt="favorite page" className={styles.FavIcon} />
                    </div> :
                    <div className={styles.ActionButtons} onClick={(event) => this.props.togglePageFavorite(event, 'fav', id)}>
                      <img src={UnfavoriteIcon} alt="favorite page" className={styles.Icon} />
                    </div>
              }
              <span className={styles.Line} />
              <div className={styles.ActionButtons} onClick={this.onEditPageHandler}>
                <img src={EditIcon} alt="Edit Page" className={styles.Icon} />
              </div>
              {
                isPageAdmin || isOrgAdmin || isAdmin ? <React.Fragment>
                  <span className={styles.Line} />
                  <div className={styles.ActionButtons} onClick={(event) => this.props.toggleDeleteModal(event, id)}>
                    <img src={DeleteIcon} alt="Delete Page" className={styles.Icon} />
                  </div> </React.Fragment> : null
              }

            </div>
            <div style={{ height: "40%" }}>
              {
                +this.props.data.page_type !== 2 ? <img src={properties && properties.image ? handleImagePath(properties.image) : ''} alt={`${name}`} className={styles.CoverImage} />
                  : <div style={{ position: 'relative' }}>
                    <img src={properties.image} alt={`${name}`} className={styles.CoverImage} />
                    <img src={UrlIcon} className={styles.LinkIcon} alt="Link Icon" />
                  </div>
              }
            </div>
            {
              this.props.favorite ?
                <div className={styles.FavoritedPage}>
                  <img src={FavoriteIcon} alt="favorite page" />
                </div>
                : null
            }
            <div className={`${styles.CardBody} ${styles.Flex}`}>
              <div className={styles.CardTitle}>{name}</div>
              <div className={styles.CardSubTitle}>{detail}</div>
            </div>
            <div className={styles.CardFooter}>
              <div className={styles.FooterWrapper}>
                <span>View This Page</span>
                <div className={styles.ArrowWrapper}>
                  <img src={ArrowIcon} alt="view this page" className={styles.ArrowIcon} />
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        // can show a loading card!
        return null;
      }

    }

  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { loggedInUser } = state.auth;
  const { loading } = state.userFavorite;
  return { loading, loggedInUser, currentOrg };
};

export default connect(mapStateToProps, { deletePage })(PageCard);