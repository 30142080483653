import React, { useEffect, useState } from 'react';
import moment from 'moment';

import styles from './TimeClock.module.scss';

const TimeClock = () => {
    const [timenow, setTimenow] = useState(moment().format('HH:mm'));
    const [datenow, setDatenow] = useState(moment().format('dddd, DD MMM YYYY'));

    useEffect(() => {
        const clockInterval = setInterval(() => {
            setTimenow(moment().format('HH:mm'));
            setDatenow(moment().format('dddd, DD MMM YYYY'));
        }, 60 * 1000);
        return () => clearInterval(clockInterval);
    });

    return (
        <div className={styles.ClockWrapper}>
            <div className={styles.Time}>{timenow}</div>
            <div className={styles.Date}>{datenow}</div>
        </div>
    );
}

export default TimeClock;