import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  docMasterDatas: null,
  updatedDocMasterDatas: null,
  updatedDocMasterData: null,
  addedDocMasterData: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_DOC_MASTER_DATAS: return clearDocMasterDatas(state, action);

    case actionTypes.GET_DOC_MASTER_DATAS_START: return getDocMasterDatasStart(state, action);
    case actionTypes.GET_DOC_MASTER_DATAS_SUCCESS: return getDocMasterDatasSuccess(state, action);
    case actionTypes.GET_DOC_MASTER_DATAS_FAIL: return getDocMasterDatasFail(state, action);

    case actionTypes.POST_DOC_MASTER_DATAS_START: return postDocMasterDatasStart(state, action);
    case actionTypes.POST_DOC_MASTER_DATAS_SUCCESS: return postDocMasterDatasSuccess(state, action);
    case actionTypes.POST_DOC_MASTER_DATAS_FAIL: return postDocMasterDatasFail(state, action);

    case actionTypes.ADD_DOC_MASTER_DATA_START: return addDocMasterDataStart(state, action);
    case actionTypes.ADD_DOC_MASTER_DATA_SUCCESS: return addDocMasterDataSuccess(state, action);
    case actionTypes.ADD_DOC_MASTER_DATA_FAIL: return addDocMasterDataFail(state, action);

    case actionTypes.EDIT_DOC_MASTER_DATA_START: return editDocMasterDataStart(state, action);
    case actionTypes.EDIT_DOC_MASTER_DATA_SUCCESS: return editDocMasterDataSuccess(state, action);
    case actionTypes.EDIT_DOC_MASTER_DATA_FAIL: return editDocMasterDataFail(state, action);

    case actionTypes.DELETE_DOC_MASTER_DATA_START: return deleteDocMasterDataStart(state, action);
    case actionTypes.DELETE_DOC_MASTER_DATA_SUCCESS: return deleteDocMasterDataSuccess(state, action);
    case actionTypes.DELETE_DOC_MASTER_DATA_FAIL: return deleteDocMasterDataFail(state, action);

    default: return state;
  }
};

const clearDocMasterDatas = (state) => {
  return updateObject(state, { docMasterDatas: null });
}

const getDocMasterDatasStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getDocMasterDatasSuccess = (state, action) => {
  return updateObject(state, {
    docMasterDatas: action.docMasterDatas,
    error: null,
    loading: false
  });
}

const getDocMasterDatasFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const postDocMasterDatasStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const postDocMasterDatasSuccess = (state, action) => {
  return updateObject(state, {
    updatedDocMasterDatas: action.updatedDocMasterDatas,
    result: action.result,
    error: null,
    loading: false
  });
}

const postDocMasterDatasFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    result: action.result,
    loading: false
  });
}


const addDocMasterDataStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addDocMasterDataSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedDocMasterData: action.addedDocMasterData,
    error: null,
    loading: false
  });
}

const addDocMasterDataFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editDocMasterDataStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editDocMasterDataSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedDocMasterData: action.updatedDocMasterData,
    error: null,
    loading: false
  });
}

const editDocMasterDataFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteDocMasterDataStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteDocMasterDataSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteDocMasterDataFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}


export default reducer;