import React, { Component } from "react";
import { checkValidity } from "../../../../state/utils";
import styles from "./TagInput.module.scss";
import CloseIcon from "../../../../assets/icons/cancel-circle.svg";

export default class TagInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    }
    this.textInput = React.createRef();
  }

  onKeyUpAddTags = (event) => {
    clearTimeout(this.checkingValueTimeout);
    if (event.target.value === "") {
      this.props.setStillShowingError(false);
      this.setState({ error: false });
    } else if (event.key === " " && event.target.value !== "") {
      this.checkValue(event);
    } else {
      this.checkingValueTimeout = setTimeout(() => {
        this.checkValue(event);
      }, 500);
    }
  }

  checkValue = (event) => {
    const value = event.target.value.trim();
    if (!checkValidity(value, this.props.validation)) {
      this.setState({ error: true });
      this.props.setStillShowingError(true);
    } else {
      this.props.setStillShowingError(false);
      this.setState({ error: false });
      this.props.onChange({
        target: {
          name: this.props.name,
          value: this.props.value
            ? [...this.props.value.split(","), value].join()
            : [value].join(),
        },
      });
      event.target.value = "";
    }
  }

  onKeyDownCheckWhiteSpace = (event) => {
    if (event.key === "Backspace" && event.target.value === "") {
      this.removeTags(this.props.value.split(",").length - 1);
    }
    if (
      (event.key === " " && event.target.value === "") ||
      (event.key === " " &&
        !checkValidity(event.target.value, this.props.validation))
    ) {
      event.preventDefault();
    }
  }

  removeTags = (index) => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: [...this.props.value.split(",")]
          .filter((_, i) => i !== index)
          .join(),
      },
    });
  }

  render() {
    let inputElement;
    if (this.props.icon) {
      inputElement = (
        <div
          className={`${styles.tags} ${this.state.error ? styles.Error : ""}`}
          onClick={() => {
            this.textInput.current.focus();
          }}
        >
          <div
            style={{
              backgroundColor: "#2b2b30",
              flex: "0 0 40px",
              borderRadius: " 3px 0px 0px 3px",
            }}
          ></div>
          <div style={{ paddingLeft: "16px", width: "100%" }}>
            <div className={styles.tagList}>
              {this.props.value
                ? this.props.value.split(",").map((tag, index) => (
                  <div key={index} className={styles.tag}>
                    <span className={styles.tagtitle}>{tag}</span>
                    <div onClick={() => this.removeTags(index)}>
                      <img
                        src={CloseIcon}
                        alt="close"
                        className={styles.tagRemove}
                      />
                    </div>
                  </div>
                ))
                : null}

              <input
                ref={this.textInput}
                type="text"
                placeholder={this.props.value ? "" : this.props.placeholder}
                className={`${styles.tagInput}`}
                onKeyUp={(event) => this.onKeyUpAddTags(event)}
                onKeyDown={(event) => this.onKeyDownCheckWhiteSpace(event)}
              />
            </div>
          </div>
          <div className={styles.IconWrapper}>
            <img
              className={styles.Icon}
              src={this.props.icon}
              alt={`${this.props.name} icon`}
            />
          </div>
          {this.props.suffixclick ? (
            <div
              onClick={this.props.suffixclick}
              {...this.props}
              className={styles.SuffixIconWrapper}
            >
              <img
                className={styles.SuffixIcon}
                src={this.props.suffixicon}
                alt={`${this.props.suffixname} icon`}
              />
            </div>
          ) : null}
        </div>
      );
    } else {
      inputElement = (
        <input
          className={`${styles.FormField} ${this.props.whitemode ? styles.WhiteMode : ""
            } ${this.props.error ? styles.Error : ""} ${this.props.disabled ? styles.Disabled : ""
            } ${this.props.labelin ? styles.TextRight : ""}`}
          onChange={this.props.onChange}
          {...this.props}
        />
      );
    }
    return (
      <div
        className={styles.FormGroup}
        style={this.props.overidestyleformgroup}
      >
        {this.props.label && (
          <label
            className={`${styles.Title} ${this.props.labelin ? styles.LabelIn : ""
              }`}
          >
            {this.props.label} {this.props.required ? "*" : ""}
          </label>
        )}
        {inputElement}
        {this.state.error && (
          <div className={styles.ErrorText}>{this.props.error}</div>
        )}
      </div>
    );
  }
}
