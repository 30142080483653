export const GET_VARIABLES_START = 'GET_VARIABLES_START';
export const GET_VARIABLES = 'GET_VARIABLES';
export const GET_VARIABLES_SUCCESS = 'GET_VARIABLES_SUCCESS';
export const GET_VARIABLES_FAIL = 'GET_VARIABLES_FAIL';

export const ADD_VARIABLE_START = 'ADD_VARIABLE_START';
export const ADD_VARIABLE = 'ADD_VARIABLE';
export const ADD_VARIABLE_SUCCESS = 'ADD_VARIABLE_SUCCESS';
export const ADD_VARIABLE_FAIL = 'ADD_VARIABLE_FAIL';

export const EDIT_VARIABLE_START = 'EDIT_VARIABLE_START';
export const EDIT_VARIABLE = 'EDIT_VARIABLE';
export const EDIT_VARIABLE_SUCCESS = 'EDIT_VARIABLE_SUCCESS';
export const EDIT_VARIABLE_FAIL = 'EDIT_VARIABLE_FAIL';

export const DELETE_VARIABLE_START = 'DELETE_VARIABLE_START';
export const DELETE_VARIABLE = 'DELETE_VARIABLE';
export const DELETE_VARIABLE_SUCCESS = 'DELETE_VARIABLE_SUCCESS';
export const DELETE_VARIABLE_FAIL = 'DELETE_VARIABLE_FAIL';