import React, { Component } from 'react';
import Select from 'react-select';

import styles from './RemoveMemberModal.module.scss';
import Modal from '../../UI/Modal/Modal';
import Background from '../../../../assets/images/modal-bg.svg';
import Button from '../../UI/Button/Button';

class RemoveMemberModal extends Component {

  componentDidUpdate(prevProps, propState) {
    if (prevProps.removingMember !== this.props.removingMember || prevProps.members !== this.props.members) {
      this.setMemberOptions();
    }
  }

  state = {
    memberOptions: null,
  };

  setMemberOptions = () => {
    let memberOptions = [];
    this.props.members.forEach(member => {
      if (member.email !== this.props.removingMember && !member.is_superuser) {
        memberOptions.push({
          id: member.id,
          label: member.first_name + ' ' + member.last_name + ' (' + member.email + ')'
        });
      }
    });
    this.setState({ memberOptions });
  }

  render() {
    return (
      <Modal show={this.props.open} modalClosed={this.props.modalClosed}>
        <div className={styles.RemoveMemberModal}>
          <div className={styles.Title}>Are you sure to REMOVE this member out of organization?</div>
          <div className={styles.Description}>Please transfer page admin rights to another member</div>
          <div className={styles.Description}>and this member will be removed</div>
          <div className={styles.SelectWrapper}>
            <Select
              isSearchable
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              options={this.state.memberOptions}
              placeholder="Search by name/email/username"
              value={this.props.newAdmin}
              onChange={this.props.onChangeAdminHandler}
              getOptionValue={opt => opt.id}
            />
          </div>
          <div className={styles.YesButtonWrapper}>
            <Button type="button" color="red" name="Yes please" click={this.props.clickRemoveHandler} disabled={!this.props.newAdmin} />
          </div>
          <div className={styles.CancelButton} onClick={this.props.modalClosed}>Cancel</div>

        </div>
        <div className={styles.Background} style={{ backgroundImage: `url(${Background})` }} />
      </Modal>
    );
  }
}
const Svg = p => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div style={{ color: 'rgb(204, 204, 204)', height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

export default RemoveMemberModal;