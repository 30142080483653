import * as actionTypes from './types';

export const clearAdvanceProductionPlans = () => {
  return {
    type: actionTypes.CLEAR_ADVANCE_PRODUCTION_PLANS
  };
}

// ----------------------- SEARCH ADVANCE_PRODUCTION_PLANS -----------------------
export const searchAdvanceProductionPlansStart = () => {
  return {
    type: actionTypes.SEARCH_ADVANCE_PRODUCTION_PLANS_START
  };
}

export const searchAdvanceProductionPlans = (org_id, po,saveParams) => {
  return {
    type: actionTypes.SEARCH_ADVANCE_PRODUCTION_PLANS,
    org_id, po, saveParams
  };
}

export const searchAdvanceProductionPlansSuccess = (response, saveParams) => {
  return {
    type: actionTypes.SEARCH_ADVANCE_PRODUCTION_PLANS_SUCCESS,
    resultSearchPlans: response.plans,
    result: response.result,
    saveParams
  };
}

export const searchAdvanceProductionPlansFail = (error) => {
  return {
    type: actionTypes.SEARCH_ADVANCE_PRODUCTION_PLANS_FAIL,
    error
  };
}

// ----------------------- GET ADVANCE_PRODUCTION_PLANS -----------------------
export const getAdvanceProductionPlansStart = () => {
  return {
    type: actionTypes.GET_ADVANCE_PRODUCTION_PLANS_START
  };
}

export const getAdvanceProductionPlans = (org_id, structure_uuid, start, end, is_running, po, target, machine_id, columns, saveParams) => {
  return {
    type: actionTypes.GET_ADVANCE_PRODUCTION_PLANS,
    org_id, structure_uuid, start, end, is_running, po, target, machine_id, columns, saveParams
  };
}

export const getAdvanceProductionPlansSuccess = (response, saveParams) => {
  return {
    type: actionTypes.GET_ADVANCE_PRODUCTION_PLANS_SUCCESS,
    advanceProductionPlans: response.plans,
    result: response.result,
    saveParams
  };
}

export const getAdvanceProductionPlansFail = (error) => {
  return {
    type: actionTypes.GET_ADVANCE_PRODUCTION_PLANS_FAIL,
    error
  };
}

// ----------------------- ADD ADVANCE_PRODUCTION_PLAN -----------------------
export const clearUploadedPlans = () => {
  return {
    type: actionTypes.CLEAR_UPLOADED_PLANS
  };
}

export const addAdvanceProductionPlanStart = () => {
  return {
    type: actionTypes.ADD_ADVANCE_PRODUCTION_PLAN_START
  };
}

export const addAdvanceProductionPlan = (structure_uuid, po, target, start, end, machine_id, columns) => {
  return {
    type: actionTypes.ADD_ADVANCE_PRODUCTION_PLAN,
    structure_uuid, po, target, start, end, machine_id, columns
  };
}

export const addAdvanceProductionPlanSuccess = (response) => {
  return {
    type: actionTypes.ADD_ADVANCE_PRODUCTION_PLAN_SUCCESS,
    addedAdvanceProductionPlan: response.plan,
    result: response.result
  };
}

export const addAdvanceProductionPlanFail = (error) => {
  return {
    type: actionTypes.ADD_ADVANCE_PRODUCTION_PLAN_FAIL,
    error
  };
}

// ----------------------- UPLOAD ADVANCE_PRODUCTION_PLANS FOR TEMPLATE -----------------------
export const uploadAdvanceProductionPlansStart = () => {
  return {
    type: actionTypes.UPLOAD_ADVANCE_PRODUCTION_PLANS_START
  };
}

export const uploadAdvanceProductionPlans = (body) => {
  return {
    type: actionTypes.UPLOAD_ADVANCE_PRODUCTION_PLANS,
    body
  };
}

export const uploadAdvanceProductionPlansSuccess = (response) => {
  return {
    type: actionTypes.UPLOAD_ADVANCE_PRODUCTION_PLANS_SUCCESS,
    uploadedPlans: response.plans,
    result: response.result
  };
}

export const uploadAdvanceProductionPlansFail = (error) => {
  return {
    type: actionTypes.UPLOAD_ADVANCE_PRODUCTION_PLANS_FAIL,
    error
  };
}

// ----------------------- EDIT ADVANCE_PRODUCTION_PLAN -----------------------
export const editAdvanceProductionPlanStart = () => {
  return {
    type: actionTypes.EDIT_ADVANCE_PRODUCTION_PLAN_START
  };
}

export const editAdvanceProductionPlan = (uuid, po, target, start, end, machine_id, columns) => {
  return {
    type: actionTypes.EDIT_ADVANCE_PRODUCTION_PLAN,
    uuid, po, target, start, end, machine_id, columns
  };
}

export const editAdvanceProductionPlanSuccess = (response) => {
  return {
    type: actionTypes.EDIT_ADVANCE_PRODUCTION_PLAN_SUCCESS,
    updatedAdvanceProductionPlan: response.plan,
    result: response.result
  };
}

export const editAdvanceProductionPlanFail = (error) => {
  return {
    type: actionTypes.EDIT_ADVANCE_PRODUCTION_PLAN_FAIL,
    error
  };
}

// ----------------------- DELETE ADVANCE_PRODUCTION_PLAN -----------------------
export const deleteAdvanceProductionPlanStart = () => {
  return {
    type: actionTypes.DELETE_ADVANCE_PRODUCTION_PLAN_START
  };
}

export const deleteAdvanceProductionPlan = (uuid) => {
  return {
    type: actionTypes.DELETE_ADVANCE_PRODUCTION_PLAN,
    uuid
  };
}

export const deleteAdvanceProductionPlanSuccess = (result) => {
  return {
    type: actionTypes.DELETE_ADVANCE_PRODUCTION_PLAN_SUCCESS,
    result
  };
}

export const deleteAdvanceProductionPlanFail = (error) => {
  return {
    type: actionTypes.DELETE_ADVANCE_PRODUCTION_PLAN_FAIL,
    error
  };
}