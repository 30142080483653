import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { scaleTime, scaleLinear } from '@vx/scale';
import { Brush } from '@vx/brush';
import { extent, bisector } from 'd3-array';
import { GridRows } from '@vx/grid';
import { Bar, Line } from '@vx/shape';
import { withTooltip, TooltipWithBounds, defaultStyles } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import _ from 'lodash';

import LineChartVx from './LineChartVx';

// TODO: Optimize tooltip

// Initialize constants and variables
// const brushMargin = { top: 10, bottom: 15, left: 50, right: 20 };
const brushMargin = { top: 10, bottom: 15, left: 70, right: 20 };
const chartSeparation = 30;
const selectedBrushStyle = {
    stroke: '#ecf0f1',
    fill: '#ecf0f1',
    fillOpacity: '0.2',
};
// const COLORS = ['#1665d8', '#ff5454', 'rgba(75,192,192,1)', '#34aa44', '#FFA000'];
const COLORS = ['#1665d8', '#ff5454', 'rgba(75,192,192,1)', '#34aa44', '#FFA000', '#9b59b6', '#f1c40f', '#e67e22', '#e74c3c', '#81ecec', '#a29bfe', '#fab1a0', '#fd79a8', '#5f27cd', '#ecf0f1'];
const background = '#1D1D20';
const tooltipStyles = {
    ...defaultStyles,
    background,
    border: '1px solid white',
    color: 'white',
    fontSize: '12px',
    paddingLeft: '12px',
    paddingRight: '12px',
};

// Util
const timeLocaleDateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
const handleDecimal = (number) => typeof (number) == "number" && (number - Math.floor(number)) !== 0 ? number.toFixed(2) : number;

// Accessors function
const getDate = (d) => d ? new Date(d.timestamp) : null;
const getDataValue = (d) => d.value;
const bisectDate = bisector(d => new Date(d.timestamp)).left;

const calDataValueMin = (dataSeries) => {
    let dataMin = 0;
    _.forEach(dataSeries, (el, i) => {
        let dataMinCheck = _.minBy(el, 'value')["value"];
        dataMin = i === 0 ? dataMinCheck : dataMinCheck < dataMin ? dataMinCheck : dataMin;
    })
    return dataMin
}

const calDataValueMax = (dataSeries) => {
    let dataMax = 0;
    _.forEach(dataSeries, (el, i) => {
        let dataMaxCheck = _.maxBy(el, 'value')["value"];
        dataMax = dataMaxCheck > dataMax ? dataMaxCheck : dataMax;
    });
    return dataMax
}

// const calDataMaxIndex = (dataSeries) =>  {
//     let dataMax = 0;
//     let dataMaxIndex = 0;
//     dataSeries.forEach((el,i) => {
//         let dataMaxCheck = Math.max(...el.map(data => data.value));
//         dataMaxIndex = dataMaxCheck > dataMax ? i : dataMaxIndex;
//         dataMax = dataMaxCheck > dataMax ? dataMaxCheck : dataMax;
//     });
//     return dataMaxIndex
// }

const calDataMaxLengthIndex = (dataSeries) => {
    let maxLength = 0;
    let dataMaxLengthIndex = 0;
    dataSeries.forEach((d, i) => {
        dataMaxLengthIndex = d.length > maxLength ? i : dataMaxLengthIndex;
        maxLength = d.length > maxLength ? d.length : maxLength;
    });
    return dataMaxLengthIndex
}

const sortByTimestamp = (data, nextData) => {
    let comparison = 0;
    if (data.timestamp > nextData.timestamp) {
        comparison = 1;
    } else if (data.timestamp < nextData.timestamp) {
        comparison = -1;
    }
    return comparison;
}

const dateStringToUnix = (dateVal) => {
    let dateUnix = 0;
    if (typeof (dateVal) === "string") {
        dateUnix = Date.parse(dateVal);
    } else {
        dateUnix = dateVal;
    }
    return dateUnix
}

const calDateRefData = (dataSeries) => {
    let dateRefData = [];
    dataSeries.forEach((data) => {
        dateRefData = dateRefData.concat(data);
    })
    return _.uniqBy(dateRefData, 'timestamp').sort(sortByTimestamp);
}

// Timestamp interval generator
// 60000, 120000, 900000, 1800000, 3600000, 
const generateTimestampInterval = (dataSeries, preDateRefData, interval) => {
    if (interval !== 1000) {
        let startDateRef = dateStringToUnix(preDateRefData[0]["timestamp"]);
        let stopDateRef = dateStringToUnix(preDateRefData[preDateRefData.length - 1]["timestamp"]);
        let tsInterval = [];
        tsInterval[0] = { "timestamp": startDateRef };
        for (let currentInterval = startDateRef; currentInterval < stopDateRef; currentInterval += interval) {
            tsInterval.push({ "timestamp": currentInterval + interval });
        }
        return tsInterval.sort(sortByTimestamp)
    } else {
        return preDateRefData
    }
}

const fillGapData = (dateRefData, dataSeries) => {
    let filledDataSeries = [];
    dataSeries.forEach((data, index) => {
        filledDataSeries[index] = data.filter(val => _.includes(dataSeries, val.timestamp) ? dataSeries[_.findIndex({ 'timestamp': val.timestamp })] : { "value": 0.0000000001, "timestamp": val.timestamp });
    })
    return filledDataSeries
}

function HistoricalLineChartVx({
    compact = false,
    width,
    height,
    margin = {
        top: 20,
        left: 70,
        bottom: 20,
        right: 20,
    },
    data,
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
    devices,
    dataTimestampInterval
}) {
    const dataMaxLengthIndex = calDataMaxLengthIndex(data);
    const refData = data[dataMaxLengthIndex]; // Data to be set as chart scale reference // TODO: To be optimized
    const preDateRefData = useMemo(() => calDateRefData(data), [data]);
    const dateRefData = useMemo(() => generateTimestampInterval(data, preDateRefData, dataTimestampInterval), [data]);
    const filledDataSeries = useMemo(() => fillGapData(dateRefData, data), [data]);

    const [filteredData, setFilteredData] = useState(refData);
    const [filteredDateRefData, setFilteredDateRefData] = useState(dateRefData);
    const [dataSeries, setDataSeries] = useState(filledDataSeries);
    const [dataUnit, setDataUnit] = useState('');
    const [dataValueMin, setDataValueMin] = useState(calDataValueMin(filledDataSeries));
    const [dataValueMax, setDataValueMax] = useState(calDataValueMax(filledDataSeries));
    const [dataValueMinBrush, setDataValueMinBrush] = useState(calDataValueMin(filledDataSeries));
    const [dataValueMaxBrush, setDataValueMaxBrush] = useState(calDataValueMax(filledDataSeries));

    const initialBrushPositionStart = 0;
    const initialBrushPositionEnd = dateRefData.length - 1;

    const onBrushChange = (domain) => {
        if (!domain) return;
        const { x0, x1, y0, y1 } = domain;
        const newRefData = refData.filter(s => {
            const x = getDate(s).getTime();
            const y = getDataValue(s);
            return x > x0 && x < x1 && y > y0 && y < y1;
        });
        const newDateRefData = dateRefData.filter(s => {
            const x = getDate(s).getTime();
            // const y = getDataValue(s);
            // return x > x0 && x < x1 && y > y0 && y < y1;
            return x > x0 && x < x1;
        });
        setFilteredData(newRefData);
        setFilteredDateRefData(newDateRefData);

        let newDataSeriesArray = [];
        for (let i = 0; i < filledDataSeries.length; i++) {
            let newDataSeriesEl = filledDataSeries[i].filter(s => {
                const x = getDate(s).getTime();
                const y = getDataValue(s);
                return x > x0 && x < x1 && y > y0 && y < y1;
            });
            newDataSeriesArray[i] = newDataSeriesEl
        }
        setDataSeries(newDataSeriesArray);
        setDataValueMin(calDataValueMin(newDataSeriesArray));
        setDataValueMax(calDataValueMax(newDataSeriesArray));
    };

    const innerHeight = height - margin.top - margin.bottom;
    const topChartBottomMargin = compact ? chartSeparation / 2 : chartSeparation + 10;
    const topChartHeight = 0.8 * innerHeight - topChartBottomMargin;
    const bottomChartHeight = innerHeight - topChartHeight - chartSeparation;

    // Bounding range
    const xMax = Math.max(width - margin.left - margin.right, 0);
    const yMax = Math.max(topChartHeight, 0);
    const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0);
    const yBrushMax = Math.max(bottomChartHeight - brushMargin.top - brushMargin.bottom, 0);

    // Scales
    const dateScale = useMemo(
        () =>
            scaleTime({
                range: [0, xMax],
                domain: extent(filteredDateRefData, getDate),
                // nice: true,
            }),
        [xMax, filteredDateRefData],
    );
    const dataValueScale = useMemo(
        () =>
            scaleLinear({
                range: [yMax, 0],
                // domain: [0, max(filteredData, getDataValue) || 0],
                domain: [dataValueMin, dataValueMax],
                nice: true,
            }),
        [yMax, filteredData],
    );
    const brushDateScale = useMemo(
        () =>
            scaleTime({
                range: [0, xBrushMax],
                domain: extent(filteredDateRefData, getDate),
            }),
        [xBrushMax],
    );
    const brushDataValueScale = useMemo(
        () =>
            scaleLinear({
                range: [yBrushMax, 0],
                // domain: [0, max(refData, getDataValue) || 0],
                domain: [dataValueMinBrush, dataValueMaxBrush],
                nice: true,
            }),
        [yBrushMax],
    );

    const initialBrushPosition = useMemo(
        () => ({
            // start: { x: brushDateScale(getDate(refData[initialBrushPositionStart])) },
            start: { x: brushDateScale(getDate(dateRefData[initialBrushPositionStart])) },
            // end: { x: brushDateScale(getDate(refData[initialBrushPositionEnd])) },
            end: { x: brushDateScale(getDate(dateRefData[initialBrushPositionEnd])) },
        }),
        [brushDateScale],
    );

    // Tooltip handler
    const handleTooltip = useCallback(
        (event) => {
            const { x, y } = localPoint(event) || { x: 0 };
            const xPoint = x - margin.left;
            const x0 = dateScale.invert(xPoint);
            const index = bisectDate(filteredDateRefData, x0, 1);
            const d0 = filteredDateRefData[index - 1];
            const d1 = filteredDateRefData[index];
            // let d = d0;
            let dataIndex = 0;
            if (d1 && getDate(d1)) {
                // d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
                if (x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf()) {
                    // d = d1;
                    dataIndex = index;
                } else {
                    // d = d0;
                    dataIndex = index - 1;
                    if (dataIndex < 0) {
                        dataIndex = 0;
                    }
                }
            }
            let tooltipDataSet = [];

            dataSeries.forEach((data, index) => {
                tooltipDataSet[0] = filteredDateRefData[dataIndex];
                for (let datum of data) {
                    let dateOnFocus = dateStringToUnix(datum["timestamp"]);
                    if (dateOnFocus == dateStringToUnix(filteredDateRefData[dataIndex]["timestamp"])) {
                        if (datum.value === 0.0000000001) {
                            tooltipDataSet[index + 1] = { 'value': 'N/A', 'timestamp': filteredDateRefData[dataIndex]["timestamp"] };
                        } else {
                            tooltipDataSet[index + 1] = datum;
                        }
                        break;
                    } else {
                        tooltipDataSet[index + 1] = { 'value': 'N/A', 'timestamp': filteredDateRefData[dataIndex]["timestamp"] };
                    }
                }
            });

            showTooltip({
                // tooltipData: d,
                tooltipData: tooltipDataSet,
                tooltipLeft: x,
                // tooltipTop: dataValueScale(getDataValue(d)),
                tooltipTop: y,
            });
        },
        [showTooltip, dataValueScale, dateScale],
    );

    useEffect(() => {
        // Set data unit
        if (devices || devices !== null) {
            let unit = devices[0].unit_name;
            let sameUnit = true;
            devices.forEach((device, i) => {
                if (i !== 0 && unit !== device.unit_name) sameUnit = false;
            });
            if (sameUnit) setDataUnit(unit);
        }
    });

    return (
        <div>
            <svg width={width} height={height}>
                {/* Chart background line */}
                <GridRows
                    left={margin.left}
                    top={margin.top}
                    scale={dataValueScale}
                    // height={topChartHeight}
                    width={xMax}
                    strokeDasharray="1,3"
                    stroke={'white'}
                    strokeOpacity={0.4}
                    pointerEvents="none"
                />

                {/* Data Plot Chart */}
                {dataSeries.map((data, i) => (
                    <LineChartVx
                        key={'LinePlot' + i}
                        hideBottomAxis={compact}
                        data={data}
                        width={width}
                        margin={{ ...margin, bottom: topChartBottomMargin }}
                        yMax={yMax}
                        xScale={dateScale}
                        yScale={dataValueScale}
                        color={COLORS[i]}
                        dataUnit={dataUnit !== '' ? dataUnit : ''}
                    />
                ))}

                {/* Tooltip Area for data pointing*/}
                <Bar
                    x={margin.left}
                    y={margin.top}
                    width={xMax}
                    height={yMax}
                    fill="transparent"
                    onTouchStart={handleTooltip}
                    onTouchMove={handleTooltip}
                    onMouseMove={handleTooltip}
                    onMouseLeave={() => hideTooltip()}
                />

                {/* Vertical Line for pointer */}
                {tooltipData && (
                    <g>
                        <Line
                            from={{ x: tooltipLeft, y: margin.top }}
                            to={{ x: tooltipLeft, y: yMax + margin.top }}
                            stroke={'white'}
                            strokeWidth={1}
                            pointerEvents="none"
                            strokeDasharray="5,2"
                        />
                        {
                            tooltipData.map((data, index) => {
                                return (index > 0 && data.value !== 'N/A') && (
                                    <circle
                                        key={'circlePlot' + index}
                                        cx={tooltipLeft}
                                        cy={dataValueScale(data['value']) + margin.top}
                                        r={4}
                                        fill={COLORS[index - 1]}
                                        stroke="white"
                                        strokeWidth={2}
                                        pointerEvents="none"
                                    />)
                            })
                        }
                    </g>
                )}

                {/* Brush Zoom Part */}
                {dataSeries.map((data, i) => (
                    <LineChartVx
                        key={'BrushLinePlot' + i}
                        hideLeftAxis
                        data={filledDataSeries[i]}
                        width={width}
                        yMax={yBrushMax}
                        xScale={brushDateScale}
                        yScale={brushDataValueScale}
                        margin={brushMargin}
                        top={topChartHeight + topChartBottomMargin + margin.top}
                        gradientColor={COLORS[i]}
                        color={COLORS[i]}
                    >
                        {/* Brush box will be render in last the line plot */}
                        {i === (dataSeries.length - 1) ?
                            <Brush
                                xScale={brushDateScale}
                                yScale={brushDataValueScale}
                                width={xBrushMax}
                                height={yBrushMax}
                                margin={brushMargin}
                                handleSize={20}
                                resizeTriggerAreas={['left', 'right']}
                                brushDirection="horizontal"
                                initialBrushPosition={initialBrushPosition}
                                onChange={onBrushChange}
                                // onClick={() => setFilteredData(refData)}
                                selectedBoxStyle={selectedBrushStyle}
                            />
                            : null
                        }
                    </LineChartVx>
                ))}
            </svg>

            {/* Data Tooltip Box */}
            {tooltipData && (
                <div>
                    <TooltipWithBounds
                        key={Math.random()}
                        top={tooltipTop - 12}
                        left={tooltipLeft + 12}
                        style={tooltipStyles}
                    >
                        <p>{getDate(tooltipData[0]).toLocaleDateString(undefined, timeLocaleDateOptions)} - {getDate(tooltipData[0]).toLocaleTimeString('en-GB')}</p>
                        {dataSeries.map((data, index) => (
                            <div key={`tooltipData${index + 1}`} style={{ display: 'flex', alignItems: 'center' }}>
                                <svg width={15} height={15} style={{ marginRight: '10px' }}>
                                    <rect width={15} height={15} style={{ fill: COLORS[index] }} />
                                </svg>
                                <p>{`${devices[index]['label']}: ${tooltipData[index + 1] ? handleDecimal(getDataValue(tooltipData[index + 1])) : 'N/A'}`}</p>
                            </div>
                        ))}
                    </TooltipWithBounds>
                </div>
            )}
        </div>
    );
}

export default withTooltip(HistoricalLineChartVx);