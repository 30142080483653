import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './EventHistory.module.scss';
import LoadingTable from '../../UI/LoadingSkeleton/LoadingTable/LoadingTable';
import Pagination from '../../UI/Pagination/Pagination';

import { searchEvents } from '../../../../state/ducks/Event/actions';

const LEVELS = ["BREAKDOWN", "CRITICAL", "WARNING", "ALERT", "INFO"];

class EventHistory extends Component {
	state = {
		eventsResult: null,
		currentPage: 0,
		rowsPerPage: 50,
	}

	componentDidMount() {
		this.props.searchEvents(this.props.currentOrg);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg) {
			this.props.searchEvents(this.props.currentOrg);
		} else if (prevProps.eventsResult !== this.props.eventsResult && this.props.eventsResult) {
			this.setState({ eventsResult: this.props.eventsResult });
		}
	}

	onChangePageHandler = (event, page) => {
		event.preventDefault();
		this.setState({ currentPage: page });
	}

	onChangeRowsPerPageHandler = (event) => {
		event.preventDefault();
		let amount = +event.target.value;
		const { eventsResult } = this.state;
		let currentPage = this.state.currentPage;
		if (currentPage > 0 && (currentPage * amount) > eventsResult?.length) {
			currentPage = 0;
		}
		this.setState({ rowsPerPage: amount, currentPage });
	}

	render() {
		const { eventsResult, currentPage, rowsPerPage } = this.state;
		let events = eventsResult && eventsResult.length ? eventsResult.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage) : [];

		return (
			<div className={styles.EventHistory}>
				{/* <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
					<div className={styles.SearchBarWrapper}>
						<div className={[styles.AutoFillBox, this.state.autoSearchIndex === 0 ? styles.Selected : ''].join(' ')} onClick={() => this.onAutoSearchPlans(0)}>TODAY</div>
						<div className={[styles.AutoFillBox, this.state.autoSearchIndex === 1 ? styles.Selected : ''].join(' ')} onClick={() => this.onAutoSearchPlans(1)}>THIS WEEK</div>
						<div className={[styles.AutoFillBox, this.state.autoSearchIndex === 2 ? styles.Selected : ''].join(' ')} onClick={() => this.onAutoSearchPlans(2)}>THIS MONTH</div>
						<div className={styles.SearchInputWrapper}>
							<img className={styles.SearchIconInput} style={{ verticalAlign: 'middle', width: 15 }} src={SearchIcon} alt="Search" />
							<input className={styles.SearchExpand} type="text" placeholder="Search" onChange={this.onSearchingHandler} />
							<div className={styles.AdvancedSearch} onClick={this.props.openAdvancedSearchDrawer}>Advanced Search</div>
						</div>
					</div>
				</div> */}
				<div className={styles.Note}>* The events below is from {moment().subtract(1, 'day').format("DD/MM/YY HH:mm")} to {moment().format("DD/MM/YY HH:mm")} </div>
				<div className={styles.TableWrapper}>
					<table>
						<thead>
							<tr>
								<th style={{ textAlign: 'center', width: 35 }}>No.</th>
								<th style={{ width: 115 }}>Level</th>
								<th>Name</th>
								<th style={{ width: 110 }}>Trigged Time</th>
								<th>Message</th>
								<th>Machine(s)</th>
							</tr>
						</thead>
						<tbody>
							{
								this.props.loading
									? <tr><td colSpan="100%"><LoadingTable /></td></tr>
									: events && events.length ?
										events.map((ev, index) => (
											<tr key={`event-${index + 1}`}>
												<td style={{ textAlign: 'center' }}>{(rowsPerPage * currentPage) + (index + 1)}.</td>
												<td style={{ display: 'flex', justifyContent: 'center' }}>
													<div className={`${styles.LevelBg} ${ev.level === 0 ? styles.Breakdown : ev.level === 1 ? styles.Critical : ev.level === 2 ? styles.Warning : ev.level === 3 ? styles.Alert : styles.Info}`}>
														<div className={`${styles.LevelLegend} ${ev.level === 0 ? styles.Breakdown : ev.level === 1 ? styles.Critical : ev.level === 2 ? styles.Warning : ev.level === 3 ? styles.Alert : styles.Info}`} />
														<div className={`${styles.LevelLabel} ${ev.level === 0 ? styles.Breakdown : ev.level === 1 ? styles.Critical : ev.level === 2 ? styles.Warning : ev.level === 3 ? styles.Alert : styles.Info}`}>{LEVELS[ev.level]}</div>
													</div>
												</td>
												<td>{ev.rule ? ev.rule.name : 'MANUAL'}</td>
												<td>{moment(ev.created_date).format("DD/MM/YY HH:mm")}</td>
												<td>{ev.message}</td>
												<td>
													{
														ev.machine_ids ? ev.machine_ids.map((ev_mid, j) =>
															!ev_mid ? '-' : this.props.machines.find(m => m.id === ev_mid).name + (j !== ev.machine_ids.length - 1 ? ', ' : '')
														) : '-'
													}
												</td>
											</tr>
										)) : <tr><td colSpan="100%" style={{ textAlign: 'center' }}>No events found</td></tr>
							}
						</tbody>
					</table>
				</div>
				<Pagination
					total={this.state.eventsResult?.length}
					currentPage={currentPage}
					rowsPerPage={rowsPerPage}
					onChangePage={this.onChangePageHandler}
					onChangeRowsPerPage={this.onChangeRowsPerPageHandler}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { currentOrg } = state.org;
	const { eventsResult, loading } = state.event;
	const { machines } = state.machine;

	return { currentOrg, eventsResult, loading, machines };
};

export default connect(mapStateToProps, { searchEvents })(EventHistory);