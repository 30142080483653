
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_SCHEDULERS, ADD_SCHEDULER, EDIT_SCHEDULER, DELETE_SCHEDULER, GET_SCHEDULER_TYPES, ADD_SCHEDULER_TYPE, EDIT_SCHEDULER_TYPE, DELETE_SCHEDULER_TYPE } from './types';

export function* watchScheduler() {
  yield takeEvery(GET_SCHEDULERS, getSchedulersSaga);
  yield takeEvery(ADD_SCHEDULER, addSchedulerSaga);
  yield takeEvery(EDIT_SCHEDULER, editSchedulerSaga);
  yield takeEvery(DELETE_SCHEDULER, deleteSchedulerSaga);
  yield takeEvery(GET_SCHEDULER_TYPES, getSchedulerTypesSaga);
  yield takeEvery(ADD_SCHEDULER_TYPE, addSchedulerTypeSaga);
  yield takeEvery(EDIT_SCHEDULER_TYPE, editSchedulerTypeSaga);
  yield takeEvery(DELETE_SCHEDULER_TYPE, deleteSchedulerTypeSaga);
}

export function* getSchedulersSaga(params) {
  yield put(actions.getSchedulersStart());

  let api = 'v1/scheduler';
  api += '?org_id=' + params.org_id;
  api += params.start ? '&start=' + params.start : '';
  api += params.end ? '&end=' + params.end: '';

  try {
    const response = yield axios.get(api);
    // console.log("GET SCHEDULERS SUCCESS", response.data);
    yield put(actions.getSchedulersSuccess(response.data));
  } catch (err) {
    console.log("GET SCHEDULERS FAILED", err)
    yield put(actions.getSchedulersFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* addSchedulerSaga(params) {
  yield put(actions.addSchedulerStart());

  const api = 'v1/scheduler';

  let postData = "org_id=" + params.org_id;
  postData += "&title=" + encodeURIComponent(params.title);
  postData += "&type_id=" + params.type_id;
  postData += "&machine_ids=" + params.machine_ids;
  postData += "&start=" + params.start;
  postData += "&end=" + params.end;
  postData += params.end_repeat ? "&end_repeat=" + params.end_repeat: '';
  postData += "&description=" + encodeURIComponent(params.description);
  postData += "&frequency=" + params.frequency;
  postData += "&properties=" + encodeURIComponent(JSON.stringify(params.properties));

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD SCHEDULER SUCCESS", response);
    yield put(actions.addSchedulerSuccess(response.data));
  } catch (err) {
    console.log("ADD SCHEDULER FAILED", err.response.data);
    yield put(actions.addSchedulerFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* editSchedulerSaga(params) {
  yield put(actions.editSchedulerStart());

  const api = 'v1/scheduler';

  let putData = "id=" + params.id;
  putData += "&start_edit=" + params.start_edit;
  putData += "&title=" + encodeURIComponent(params.title);
  putData += "&type_id=" + params.type_id;
  putData += "&machine_ids=" + params.machine_ids;
  putData += "&start=" + params.start;
  putData += "&end=" + params.end;
  putData += "&end_repeat=" + params.end_repeat;
  putData += "&description=" + encodeURIComponent(params.description);
  putData += "&frequency=" + params.frequency;
  putData += "&all_in_future=" + params.all_in_future;
  putData += "&properties=" + encodeURIComponent(JSON.stringify(params.properties));

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT SCHEDULER SUCCESS", response.data);
    yield put(actions.editSchedulerSuccess(response.data, params.properties));
  } catch (err) {
    console.log("EDIT SCHEDULER FAILED", err.response.data);
    yield put(actions.editSchedulerFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* deleteSchedulerSaga(params) {
  yield put(actions.deleteSchedulerStart());

  let api = 'v1/scheduler?id=' + params.id;
  api += '&start_delete=' + params.start_delete;
  api += '&all_in_future=' + params.all_in_future;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE SCHEDULER SUCCESS", response.data);
    yield put(actions.deleteSchedulerSuccess(response.data.result, params.properties));
  } catch (err) {
    console.log("DELETE SCHEDULER FAILED", err.response);
    yield put(actions.deleteSchedulerFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* getSchedulerTypesSaga(params) {
  yield put(actions.getSchedulerTypesStart());

  let api = 'v1/scheduler/type?org_id=' + params.org_id;

  try {
    const response = yield axios.get(api);
    // console.log("GET SCHEDULER TYPES SUCCESS", response.data);
    yield put(actions.getSchedulerTypesSuccess(response.data));
  } catch (err) {
    console.log("GET SCHEDULER TYPES FAILED", err)
    yield put(actions.getSchedulerTypesFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* addSchedulerTypeSaga(params) {
  yield put(actions.addSchedulerTypeStart());

  const api = 'v1/scheduler/type';

  let postData = "org_id=" + params.org_id;
  postData += "&name=" + encodeURIComponent(params.name);
  postData += "&color=" + params.color;
  postData += "&category=" + params.category;
  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD SCHEDULER_TYPE SUCCESS", response.data);
    yield put(actions.addSchedulerTypeSuccess(response.data));
  } catch (err) {
    // console.log("ADD SCHEDULER_TYPE FAILED", err.response);
    yield put(actions.addSchedulerTypeFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* editSchedulerTypeSaga(params) {
  yield put(actions.editSchedulerTypeStart());

  const api = 'v1/scheduler/type';

  let putData = "id=" + params.id;
  putData += "&name=" + encodeURIComponent(params.name);
  putData += "&color=" + params.color;
  putData += "&category=" + params.category;

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT SCHEDULER_TYPE SUCCESS", response.data);
    yield put(actions.editSchedulerTypeSuccess(response.data));
  } catch (err) {
    // console.log("EDIT SCHEDULER_TYPE FAILED", err.response);
    yield put(actions.editSchedulerTypeFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* deleteSchedulerTypeSaga(params) {
  yield put(actions.deleteSchedulerTypeStart());

  const api = 'v1/scheduler/type?id=' + params.id;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE SCHEDULER_TYPE SUCCESS", response.data);
    yield put(actions.deleteSchedulerTypeSuccess(response.data.result));
  } catch (err) {
    // console.log("DELETE SCHEDULER_TYPE FAILED", err.response);
    yield put(actions.deleteSchedulerTypeFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}