import React, { Component } from 'react';

import styles from './Structure.module.scss';
import PlanStructure from '../../components/Production/PlanStructure/PlanStructure';
import Button from '../../components/UI/Button/Button';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';

const PRODUCTION_LIST = ['Plan\'s Structure'];

class Structure extends Component {
  state = {
    currentTabIndex: 0,
    addStructureTrigger: 0
  };

  onAddNewStructureHandler = () => {
    this.setState(prevState => {
      return { addStructureTrigger: prevState.addStructureTrigger + 1 }
    });
  }

  render() {
    return (
      <div className={styles.Structure}>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              PRODUCTION_LIST.map((menuTitle, index) => (
                <div key={`production-${index}`} className={index === this.state.currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentProductionIndex: index })}>
                  <span className={styles.MenuTitle}>{menuTitle}</span>
                </div>
              ))
            }
          </div>
          <div className={styles.AddButtonWrapper}>
            {this.state.currentTabIndex === 0 && <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Structure" noMargin click={this.onAddNewStructureHandler} />}
          </div>
        </div>
        <div className="MainContentPadding">
          {this.state.currentTabIndex === 0 && <PlanStructure isNewAdd={this.state.addStructureTrigger} />}
        </div>
      </div>
    );
  }
}

export default Structure;
