import React, { Component } from 'react';

import { updateObject, checkValidity } from '../../../../../state/utils';
import styles from '../SystemDeviceConfig.module.scss';
import Input from '../../../UI/Input/Input';
import SimpleSwitch from '../../../UI/SimpleSwitch/SimpleSwitch';

const DEFAULT_FORM = {
  ssid: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  },
  password: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  },
  wifiIp: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  },
  wifiSubnet: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  },
  wifiGateway: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  },
  wifiPrimaryDns: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  },
  wifiSecondaryDns: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  }
};

class WifiFields extends Component {
  state = {
    form: DEFAULT_FORM,
    formIsValid: false,
    enableStaicIp: false
  }

  componentDidMount() {
    if (this.props.networkDetails && Object.keys(this.props.networkDetails).length) {
      this.setupWifiForm(this.props.networkDetails, false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.wifiConfig !== this.props.wifiConfig && this.props.wifiConfig) {
      this.setupWifiForm(this.props.wifiConfig, true);
    }
  }

  setupWifiForm = (wifiConfig, isFromOriginalConfigs) => {
    if (wifiConfig && Object.keys(wifiConfig).length) {
      let updatedForm = { ...this.state.form };
      for (let inputIdentifier in updatedForm) {
        let key = inputIdentifier;
        if (isFromOriginalConfigs) {
          switch (inputIdentifier) {
            case 'wifiIp': key = 'ip'; break;
            case 'wifiSubnet': key = 'subnet'; break;
            case 'wifiGateway': key = 'gateway'; break;
            case 'wifiPrimaryDns': key = 'primary_dns'; break;
            case 'wifiSecondaryDns': key = 'secondary_dns'; break;
            default: break;
          }
        }

        const updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: wifiConfig[key] ? wifiConfig[key] : '',
          valid: true,
          touched: true
        });
        updatedForm = updateObject(updatedForm, {
          [inputIdentifier]: updatedElement
        });
      }
      this.setState({ form: updatedForm, formIsValid: true, enableStaicIp: isFromOriginalConfigs ? wifiConfig['static_ip'] : wifiConfig.wifiEnableStaticIp });
    }
  }

  handleWifiTextChange = event => {
    const { name, value } = event.target;
    this.props.setNetworkDetails(name, value);

    let updatedElement = updateObject(this.state.form[name], {
      value: value,
      valid: checkValidity(value, this.state.form[name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.form, {
      [name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ form: updatedForm, formIsValid });
  };

  onChangeEnableWifiStaticIpHandler = (event) => {
    event.stopPropagation();
    this.props.setNetworkDetails('wifiEnableStaticIp', !this.state.enableStaicIp);
    this.setState(prevState => {
      return { enableStaicIp: !prevState.enableStaicIp }
    });
  }

  render() {
    const { ssid, password, wifiIp, wifiSubnet, wifiGateway, wifiPrimaryDns, wifiSecondaryDns } = this.state.form;

    return (
      <div style={{ display: this.props.show ? 'block' : 'none' }}>
        <div className={styles.Label}>Wi-Fi Settings</div>
        <Input
          labelin="true"
          label="SSID"
          name="ssid"
          type="text"
          value={ssid.value}
          autoComplete="off"
          placeholder=""
          onChange={this.handleWifiTextChange}
        />
        <Input
          labelin="true"
          label="Password"
          name="password"
          type="text"
          value={password.value}
          autoComplete="off"
          placeholder=""
          onChange={this.handleWifiTextChange}
        />
        <div className={styles.SwitchWrapper}>
          <div style={{ flex: 1 }}>Static IP</div>
          <SimpleSwitch enable={this.state.enableStaicIp} onChangeEnableHandler={this.onChangeEnableWifiStaticIpHandler} />
        </div>
        {
          this.state.enableStaicIp ?
            <>
              <Input
                labelin="true"
                label="IP"
                name="wifiIp"
                type="text"
                value={wifiIp.value}
                autoComplete="off"
                onChange={this.handleWifiTextChange}
              />
              <Input
                labelin="true"
                label="Subnet"
                name="wifiSubnet"
                type="text"
                value={wifiSubnet.value}
                autoComplete="off"
                onChange={this.handleWifiTextChange}
              />
              <Input
                labelin="true"
                label="Gateway"
                name="wifiGateway"
                type="text"
                value={wifiGateway.value}
                autoComplete="off"
                onChange={this.handleWifiTextChange}
              />
              <Input
                labelin="true"
                label="Primary DNS"
                name="wifiPrimaryDns"
                type="text"
                value={wifiPrimaryDns.value}
                autoComplete="off"
                onChange={this.handleWifiTextChange}
              />
              <Input
                labelin="true"
                label="Secondary DNS"
                name="wifiSecondaryDns"
                type="text"
                value={wifiSecondaryDns.value}
                autoComplete="off"
                onChange={this.handleWifiTextChange}
              />
            </>
            : null
        }

        <div className={styles.BetweenSection} />
      </div>
    );
  }
}

export default WifiFields;