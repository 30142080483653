import React, { Component } from 'react';

import styles from '../SystemDeviceConfig.module.scss';
import WifiFields from './WifiFields';
import EthernetFields from './EthernetFields';
import XbeeFields from './XbeeFields';
import CellularFields from './CellularFields';
import SimpleSwitch from '../../../UI/SimpleSwitch/SimpleSwitch';

const DEFAULT_NETWORK_FORM = [{
  name: 'Wi-Fi',
  value: false
}, {
  name: 'Ethernet',
  value: false
}, {
  name: 'XBee',
  value: false
}, {
  name: 'Cellular',
  value: false
}];

class NetworkSettings extends Component {
  state = {
    networkForm: DEFAULT_NETWORK_FORM,
    wifiConfig: null,
    ethConfig: null,
    xbeeConfig: null,
    cellularConfig: null,
    edgeConfig: null,
    currentMenu: 1,
  }

  componentDidMount() {
    if (this.props.currentConfigs && Object.keys(this.props.currentConfigs).length) {
      if (!this.props.hasAlreadyBeenToNetworkTab) {
        this.props.beenToNetworkTab();
        this.setUpNetworkSettings();
      }

      if (this.props.fusoVersion) {
        const updatedNetworkForm = [...DEFAULT_NETWORK_FORM];
        updatedNetworkForm.splice(2, 1);
        this.setState({ networkForm: updatedNetworkForm });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentConfigs !== this.props.currentConfigs && this.props.currentConfigs) {
      this.setUpNetworkSettings();
    }
  }

  setUpNetworkSettings = () => {
    let updatedNetworkDetails = {};
    const updatedNetworkForm = [...this.state.networkForm];
    updatedNetworkForm.forEach(netw => {
      netw.value = false;
    });
    let newWifi = '', newEth = '', newXbee = '', newCellular = '', newEdge = '';
    const { wifi_config, eth_config, xbee_config, cellular_config, edge_config } = this.props.currentConfigs;
    if (wifi_config && wifi_config.enable) {
      updatedNetworkForm[0].value = true;
      newWifi = wifi_config;
      updatedNetworkDetails.wifiEnableStaticIp = wifi_config.static_ip ? wifi_config.static_ip : false;
      updatedNetworkDetails.ssid = wifi_config.ssid;
      updatedNetworkDetails.password = wifi_config.password;
      if (wifi_config.static_ip) {
        updatedNetworkDetails.wifiIp = wifi_config.ip;
        updatedNetworkDetails.wifiSubnet = wifi_config.subnet;
        updatedNetworkDetails.wifiGateway = wifi_config.gateway;
        updatedNetworkDetails.wifiPrimaryDns = wifi_config.primary_dns;
        updatedNetworkDetails.wifiSecondaryDns = wifi_config.secondary_dns;
      }
    }

    if (eth_config && eth_config.enable) {
      updatedNetworkForm[1].value = true;
      newEth = eth_config;
      updatedNetworkDetails.ethernetEnableStaticIp = eth_config.static_ip ? eth_config.static_ip : false;
      if (eth_config.static_ip) {
        updatedNetworkDetails.ethernetIp = eth_config.ip;
        updatedNetworkDetails.ethernetSubnet = eth_config.subnet;
        updatedNetworkDetails.ethernetGateway = eth_config.gateway;
        updatedNetworkDetails.ethernetPrimaryDns = eth_config.primary_dns;
        updatedNetworkDetails.ethernetSecondaryDns = eth_config.secondary_dns;
      }
    }

    if (xbee_config && xbee_config.enable) {
      updatedNetworkForm[2].value = true;
      newXbee = xbee_config;
      updatedNetworkDetails.masterHighAddress = this.byte4ToHex(xbee_config.master_h_addr);
      updatedNetworkDetails.masterLowAddress = this.byte4ToHex(xbee_config.master_l_addr);
    }

    if (cellular_config && cellular_config.enable) {
      updatedNetworkForm[3].value = true;
      newCellular = cellular_config;
      cellular_config.apn_configs_map.forEach(apn => {
        if (apn[0] === 'name') {
          updatedNetworkDetails.cellularAPN = apn[1];
        } else if (apn[0] === 'username') {
          updatedNetworkDetails.cellularUsername = apn[1];
        } else if (apn[0] === 'password') {
          updatedNetworkDetails.cellularPassword = apn[1];
        }
      });
    }

    newEdge = edge_config ? edge_config : '';
    if (edge_config) {
      updatedNetworkDetails.mainInterval = edge_config.main_interval ? parseInt(edge_config.main_interval / 1000) : 0;
      updatedNetworkDetails.coapHost = edge_config.coap_host;
      updatedNetworkDetails.coapPort = edge_config.coap_port;
      updatedNetworkDetails.rebootInterval = edge_config.reboot_interval ? parseInt(edge_config.reboot_interval / 1000) : 0;
    }

    this.setState({ networkForm: updatedNetworkForm, wifiConfig: newWifi, ethConfig: newEth, xbeeConfig: newXbee, cellularConfig: newCellular, edgeConfig: newEdge });
    this.props.setNetworkEnable(updatedNetworkForm);
    this.props.setAllNetworkDetails(updatedNetworkDetails);
  }

  byte4ToHex = (byte4) => {
    let hex = byte4.toString(16);
    if (hex.length < 8) {
      const addedZero = 8 - hex.length;
      hex = "0".repeat(addedZero) + hex;
    }
    return hex;
  }

  changeNetworkOptionHandler = (index) => {
    const updatedNetworkForm = [...this.state.networkForm];
    const { name, value } = updatedNetworkForm[index];
    if (name === 'XBee' || name === 'Cellular') {
      let foundIndex;
      if (name === 'XBee') foundIndex = this.state.networkForm.findIndex(nf => nf.name === 'Cellular');
      else foundIndex = this.state.networkForm.findIndex(nf => nf.name === 'XBee');

      if (!value && updatedNetworkForm[foundIndex].value) {
        updatedNetworkForm[foundIndex].value = false;
      }
    }
    updatedNetworkForm[index].value = !updatedNetworkForm[index].value;

    this.setState({ networkForm: updatedNetworkForm });
    this.props.setNetworkEnable(updatedNetworkForm);
  }

  render() {
    const { currentMenu } = this.state;

    let content = null;

    if (currentMenu === 1) {
      content = <WifiFields
        show={this.state.networkForm[0].value}
        wifiConfig={this.state.wifiConfig}
        setNetworkDetails={this.props.setNetworkDetails}
        networkDetails={this.props.networkDetails}
      />;
    } else if (currentMenu === 2) {
      content = <EthernetFields
        show={this.state.networkForm[1].value}
        ethConfig={this.state.ethConfig}
        setNetworkDetails={this.props.setNetworkDetails}
        networkDetails={this.props.networkDetails}
      />;
    } else if (currentMenu === 3) {
      content = <XbeeFields
        show={this.state.networkForm[2].value}
        xbeeConfig={this.state.xbeeConfig}
        setNetworkDetails={this.props.setNetworkDetails}
        networkDetails={this.props.networkDetails}
      />;
    } else if (currentMenu === 4) {
      content = <CellularFields
        show={this.state.networkForm[3].value}
        cellularConfig={this.state.cellularConfig}
        setNetworkDetails={this.props.setNetworkDetails}
        networkDetails={this.props.networkDetails}
      />;
    }

    return (
      <div className={styles.Container}>
        <div className={styles.MenuWrapper}>
          {
            this.state.networkForm.map((network, i) => {
              return <div className={currentMenu === (i + 1) ? `${styles.Menu} ${styles.Active}` : styles.Menu} key={`network-options-${i}`} onClick={() => this.setState({ currentMenu: i + 1 })}>
                <div className={styles.Label}>{network.name}</div>
                <SimpleSwitch enable={network.value ? true : false} onChangeEnableHandler={() => this.changeNetworkOptionHandler(i)} />
              </div>
            })
          }
        </div>
        <div className={styles.ContentWrapper}>
          {content}
        </div>
      </div>
    );
  }
}

export default NetworkSettings;