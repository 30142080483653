import React, { Component } from 'react';
import moment from 'moment';

import styles from './AssetMap.module.scss';
import Button from '../../../components/UI/Button/Button';
import DownloadIcon from '../../../../assets/icons/AssetTracking/cloud.svg';
import MarkerMap from '../MarkerMap/MarkerMap';
import ActiveAssetIcon from '../../../../assets/icons/realtime.svg';
import AssetIcon from '../../../../assets/icons/realtime-gray.svg';
import GreenIcon from '../../../../assets/icons/AssetTracking/green-dot.svg';
import RedIcon from '../../../../assets/icons/AssetTracking/red-dot.svg';
import MoreIcon from '../../../../assets/icons/more.svg';

class AssetMap extends Component {
  state = {
    selectedList: null
  }

  onSelectedList = (list) => {
    this.setState({ selectedList: list })
  }

  render() {
    let now = new Date();
    return (
      <div className={styles.AssetMap}>
        <MarkerMap />
        <div style={{ display: 'flex', marginTop: 30 }}>
          <div className={styles.Title}>Asset Dormancy</div>
          <div className={styles.Button}>
            <div style={{ width: 138 }}><Button name="Real Time" color="white" noMargin /></div>
            <div style={{ marginLeft: 10, width: 156 }}><Button icon={DownloadIcon} name="Download File" color="primary" noMargin /></div>
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
        </div>
        <div className={styles.TableWrapper}>
          <table>
            <thead>
              <tr>
                <th>ASSET</th>
                <th>STATUS</th>
                <th>LOCATION</th>
                <th>LAST ACTIVE</th>
                <th>DORMANT (DAYS)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.data ? this.props.data.map(list => {
                  let listStyle = null;
                  let Icon = AssetIcon;
                  if (this.state.selectedList) {
                    if (this.state.selectedList.asset === list.asset) {
                      listStyle = styles.ActiveList;
                      Icon = ActiveAssetIcon;
                    }
                  }
                  return (
                    <tr key={list.asset} className={listStyle} onClick={() => this.onSelectedList(list)}>
                      <td>
                        <div style={{ display: 'flex' }}>
                          <img src={Icon} alt="Asset Icon" style={{ marginRight: 10 }} />
                          {list.asset}
                        </div>
                      </td>
                      <td>
                        <div style={{ display: 'flex' }}>
                          {list.status ? <img style={{ marginLeft: 20 }} src={GreenIcon} alt="Status Icon" />
                            : <img style={{ marginLeft: 20 }} src={RedIcon} alt="Status Icon" />}
                        </div>
                      </td>
                      <td>{list.location}</td>
                      <td>{moment(list.last_active).format("DD/MM/YYYY")}</td>
                      <td>{((now.getTime() - list.last_active) / 1000 / 60 / 60 / 24).toFixed(2)}</td>
                      <td><img src={MoreIcon} alt="More Icon" /></td>
                    </tr>
                  );
                }) : null
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default AssetMap;
