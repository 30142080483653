import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Dashboard.module.scss';
import POViewBox from './POViewBox/POViewBox';
import MachineViewBox from './MachineViewBox/MachineViewBox';
import BatchViewBox from './BatchViewBox/BatchViewBox';

import { getModuleConfigs } from '../../../../state/ducks/Module/actions';
import { getMachinesAndActuals } from '../../../../state/ducks/Machine/actions';
import { getAdvanceProductionBatchs } from '../../../../state/ducks/AdvanceProductionBatch/actions';
import { getAdvanceProductionPlans, clearAdvanceProductionPlans } from '../../../../state/ducks/AdvanceProductionPlan/actions';
import { getProductionPlanStructures } from '../../../../state/ducks/AdvanceProductionPlanStructure/actions';

class DashboardGrid extends Component {
  state = {
    view: 0,
    displayOEEOverHundred: true,
    isFloating: null
  };

  componentDidMount() {
    this.props.clearAdvanceProductionPlans();
    this.props.getModuleConfigs(this.props.currentOrg, 3);
    this.props.getMachinesAndActuals(this.props.currentOrg);
    this.props.getProductionPlanStructures(this.props.currentOrg);
    this.machineInterval = setInterval(() => {
      this.props.getMachinesAndActuals(this.props.currentOrg);
    }, 60 * 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg) {
      this.props.clearAdvanceProductionPlans();
      this.props.getModuleConfigs(this.props.currentOrg, 3);
      this.props.getProductionPlanStructures(this.props.currentOrg);
      this.props.getMachinesAndActuals(this.props.currentOrg);
      this.setUpDataInterval();
    } else if (prevProps.dashboardIndex !== this.props.dashboardIndex || prevProps.currentOrg !== this.props.currentOrg) {
      if (this.props.dashboardIndex === 0) {
        this.setUpDataInterval();
      } else if (this.props.dashboardIndex === 1) {
        clearInterval(this.batchInterval);
        this.props.getProductionPlanStructures(this.props.currentOrg);
        this.props.getAdvanceProductionPlans(this.props.currentOrg, null, null, null, true);
        this.poInterval = setInterval(() => {
          this.props.getAdvanceProductionPlans(this.props.currentOrg, null, null, null, true);
        }, 60 * 1000);
      } else if (this.props.dashboardIndex === 2) {
        clearInterval(this.poInterval);
        this.props.getAdvanceProductionBatchs(this.props.currentOrg, null, new Date().getTime());
        this.batchInterval = setInterval(() => {
          this.props.getAdvanceProductionBatchs(this.props.currentOrg, null, new Date().getTime());
        }, 60 * 1000);
      }
    } else if (prevProps.moduleConfigs !== this.props.moduleConfigs && this.props.moduleConfigs) {
      let displayOEEOverHundred = this.props.moduleConfigs.find(mc => mc.key === 'apqOver');
      displayOEEOverHundred = displayOEEOverHundred && displayOEEOverHundred.value === 'true' ? true : false;
      let isFloating = this.props.moduleConfigs.find(mc => mc.key === 'isFloating');
      isFloating = isFloating && isFloating.value === 'true' ? true : false;
      this.setState({ displayOEEOverHundred, isFloating });
    }
  }

  setUpDataInterval = () => {
    clearInterval(this.poInterval);
    clearInterval(this.batchInterval);
    clearInterval(this.machineInterval);
    this.machineInterval = setInterval(() => {
      this.props.getMachinesAndActuals(this.props.currentOrg);
    }, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.machineInterval);
    clearInterval(this.poInterval);
    clearInterval(this.batchInterval);
  }

  render() {
    return (
      <div className={styles.DashboardGrid}>
        {/* <div className={styles.ViewSwitchingWrapper}>
          <div onClick={() => this.setState({ view: 0 })} className={`${styles.ViewButton} ${styles.Left} ${this.state.view === 0 ? '' : styles.SelectedLeft}`}>Machine View</div>
          <div onClick={() => this.setState({ view: 1 })} className={`${styles.ViewButton} ${styles.Right} ${this.state.view === 1 ? styles.SelectedRight : ''}`}>PO View</div>
        </div> */}

        {this.props.dashboardIndex === 0 && <div className={styles.MachineBoxesWrapper}>
          {
            this.props.machinesAndActuals ?
              this.props.machinesAndActuals.sort((a, b) => a.name.localeCompare(b.name)).map(m => (
                <MachineViewBox key={`MachineId-${m.id}`} machine={m} displayOEEOverHundred={this.state.displayOEEOverHundred} isFloating={this.state.isFloating} planStructures={this.props.planStructures} />
              ))
              : null
          }
        </div>}

        {this.props.dashboardIndex === 1 && <div className={styles.PORowsWrapper}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
            <div style={{ fontSize: 12, padding: '10px 30px', marginRight: 10, background: 'linear-gradient(270deg, #34aa44 0%, #34aa4433 100%)' }}>PO is producing AHEAD</div>
            <div style={{ fontSize: 12, padding: '10px 30px', background: 'linear-gradient(270deg, #e6492d 0%, #e6492d33 100%)' }}>PO is producing BEHIND</div>
          </div>
          {
            this.props.advanceProductionPlans && this.props.advanceProductionPlans.length
              ? this.props.advanceProductionPlans.sort((a, b) => a.po.localeCompare(b.po)).map(plan => <POViewBox key={`plans-${plan.uuid}`} plan={plan} isFloating={this.state.isFloating} />)
              : <div style={{ textAlign: 'center', fontWeight: '500' }}>No plans found</div>
          }
        </div>}

        {this.props.dashboardIndex === 2 && <div className={styles.MachineBoxesWrapper} style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 300px))' }}>
          {
            this.props.productionBatchs && this.props.productionBatchs.length
              ? this.props.productionBatchs.map(batch => <BatchViewBox key={`batch-${batch.uuid}`} batch={batch} />)
              : <div style={{ textAlign: 'center', fontWeight: '500' }}>No batchs found</div>
          }
        </div>}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { machinesAndActuals } = state.machine;
  const { advanceProductionPlans } = state.advanceProductionPlan;
  const { productionBatchs } = state.advanceProductionBatch;
  const { planStructures } = state.advanceProductionPlanStructure;
  const { moduleConfigs } = state.module;
  return { currentOrg, machinesAndActuals, productionBatchs, advanceProductionPlans, moduleConfigs, planStructures };
};

export default connect(mapStateToProps, { getMachinesAndActuals, getAdvanceProductionBatchs, getAdvanceProductionPlans, clearAdvanceProductionPlans, getModuleConfigs, getProductionPlanStructures })(DashboardGrid);
