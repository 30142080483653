import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './Report.module.scss';
import ReactSelect from 'react-select';

import PresetReport from '../../components/Report/Preset/PresetReport';
import HistoricalReportVx from '../../components/Report/HistoricalVx/HistoricalReportVx';
import ParetoReport from '../../components/Report/Pareto/ParetoReport';
import OEEReport from '../../components/Report/OEE/OEEReport';

import { getMachines } from '../../../state/ducks/Machine/actions';

const REPORT_TYPE = [
  { label: 'Preset', module: 'all', component: PresetReport },
  { label: 'Data Report', module: 'all', component: HistoricalReportVx },
  { label: 'Pareto Report', module: 3, component: ParetoReport },
  { label: 'OEE Report', module: 3, component: OEEReport },
];

const colourStyles = {
  singleValue: (styles) => ({
    ...styles,
    color: "white"
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
  }),
  input: base => ({
    ...base,
    color: "white"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
};

const sortingOptions = [
  { value: 1, label: 'A-Z' },
  { value: 2, label: 'Z-A' },
  { value: 3, label: 'Oldest' },
  { value: 4, label: 'Newest' }
]

class Report extends Component {
  state = {
    currentReportIndex: 0,
    selectedPreset: null,
    selectedSort: 0,
    presetLength: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.props.getMachines(this.props.currentOrg);
      this.setState({ currentReportIndex: 0, selectedPreset: null, selectedSort: 0, presetLength: 0 });
    }
  }

  setSelectedPresetHandler = (preset) => {
    let reportType = preset.type === 'data' ? 1 : 2;
    if (preset) {
      this.setState({
        currentReportIndex: reportType,
        selectedPreset: preset ? preset : null,
      });
    }
  }

  backToPresetHandler = () => {
    this.setState({
      currentReportIndex: 0,
      selectedPreset: null,
    });
  }

  clearPresetHandler = () => {
    this.setState({
      selectedPreset: null,
    });
  }

  onSortingChange = (option) => {
    this.setState({
      selectedSort: option.value,
    });
  }

  sortingOptionDisplayHanlder = (presetLength) => {
    let newPresetLength = presetLength;
    this.setState({
      presetLength: newPresetLength,
    })
  }

  render() {
    const { presetLength, currentReportIndex, selectedSort, selectedPreset } = this.state;
    const { currentOrg, orgs } = this.props;

    const org = orgs?.find(o => o.id === currentOrg);

    let sortingOption = sortingOptions.filter(option => option.value === selectedSort);

    let page = null;
    let type = REPORT_TYPE[currentReportIndex];
    if (type && (type.module === 'all' || (org && org.modules?.includes(type.module)))) {
      page = <type.component viewPresetHandler={this.setSelectedPresetHandler} sortingOption={selectedSort} sortingOptionDisplay={this.sortingOptionDisplayHanlder} clearPresetHandler={this.clearPresetHandler} selectedPreset={selectedPreset} />
    }

    return (
      <div className={styles.Report}>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              REPORT_TYPE.map((report, index) => {
                if (report.module !== 'all' && org && !org.modules?.includes(report.module))
                  return;
                return (<div key={`report-${index}`} className={index === currentReportIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentReportIndex: index })}>
                  <span className={styles.MenuTitle}>{report.label}</span>
                </div>);
              })
            }
          </div>
          {(currentReportIndex === 0 && presetLength > 0) &&
            <div className={styles.SortingSelect}>
              <ReactSelect
                isSearchable={false}
                options={sortingOptions}
                styles={colourStyles}
                placeholder="Sorting"
                value={sortingOption}
                onChange={(option) => this.onSortingChange(option)}
                getOptionValue={opt => opt.id}
              />
            </div>
          }
        </div>
        <div className={styles.MainContentPadding}>
          {page}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentOrg: state.org.currentOrg,
    orgs: state.org.orgs,
  };
};

export default connect(mapStateToProps, { getMachines })(Report);
