import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { updateObject, checkValidity } from '../../../../state/utils';
// import moment from 'moment';
import ImageUploading from 'react-images-uploading';

import styles from './FloorPlanImageDrawer.module.scss';
import Button from '../../UI/Button/Button';
import UploadImageIcon from '../../../../assets/images/upload-image-primary.png';

import { editPage } from '../../../../state/ducks/Pages/actions';
import { uploadFloorPlanImage } from '../../../../configs/firebase';

const MAX_IMAGE_UPLOAD = 1;


class WidgetDrawerFloorPlanImage extends Component {
  state = {
    images: [],
    floorPlanImage: null,
    isFloorPlanImageChanged: false
  }

  componentDidUpdate(prevProps, nextState) {
    if (prevProps.result === null && this.props.result === "success") {
      this.setState({
        images: [],
        floorPlanImage: null,
        isFloorPlanImageChanged: false
      })
      this.props.closed();
    }
  }

  onSubmitFloorPlanImageHandler = async () => {
    if (this.state.isFloorPlanImageChanged) {
      let imageFile = this.state.images[0].file;
      let imageUrl = await uploadFloorPlanImage(imageFile)
      let splittedOption = imageUrl.split('?');
      let splittedToken = splittedOption[1].split('&');
      let newProperties = { ...this.props.pageAndMembers.page.properties };
      newProperties.bg_img_filename = imageFile.name;
      newProperties.bg_img_url = splittedOption[0];
      newProperties.bg_img_alt = splittedToken[0];
      newProperties.bg_img_token = splittedToken[1];
      this.props.editPage(this.props.pageId, null, null, 4, null, newProperties);
    }
  }

  onImagesChange = (imageList, addUpdateIndex) => {
    let newImageList = []
    newImageList.push(imageList[addUpdateIndex])
    this.setState({
      images: newImageList,
      isFloorPlanImageChanged: true
    })
  };

  render() {
    const { images } = this.state;

    let disabledButton = false;
    if (images.length > 0) {
      disabledButton = false
    } else {
      disabledButton = true
    }

    let floorPlanImageUrl = null;
    let floorPlanImageFileName = null;
    if (this.props.pageAndMembers.page.properties.bg_img_filename) {
      floorPlanImageFileName = this.props.pageAndMembers.page.properties.bg_img_filename;
      floorPlanImageUrl = `${this.props.pageAndMembers.page.properties.bg_img_url}?${this.props.pageAndMembers.page.properties.bg_img_alt}&${this.props.pageAndMembers.page.properties.bg_img_token}`
    }

    return (
      <div className={styles.WidgetDrawer}>
        <p className={styles.Title}>Floor Plan Image</p>
        <ImageUploading
          // multiple
          value={images}
          onChange={this.onImagesChange}
          maxNumber={MAX_IMAGE_UPLOAD}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // UI
            <div className="upload__image-wrapper">
              {/* First usage */}
              {(!floorPlanImageUrl && images.length == 0) &&
                <div
                  style={{
                    backgroundColor: "rgba(22, 101, 216, 0.1)",
                    width: "100%",
                    height: "240px",
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // cursor: "pointer",
                    borderRadius: "8px",
                    border: "dashed 1px #1665d8"
                  }}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <img src={UploadImageIcon} alt="Upload Image Icon" width="72px" />
                  <p
                    style={{
                      color: "#1665d8",
                      marginTop: "0px",
                      marginBottom: "16px",
                      fontSize: "14px",
                    }}
                  >
                    Drag and drop or select an image.
                  </p>
                  <Button
                    type="button"
                    name={"Upload"}
                    color="primary"
                    overideButtonStyles={{ width: "50%", marginBottom: "0px" }}
                  />
                </div>
              }
              {/* First Upload */}
              {!floorPlanImageUrl && images.map((image, index) => (
                <div key={index} className="image-item">
                  <img src={image['data_url']} alt="floor-plan-image" width="100%" />
                  <p
                    style={{
                      marginTop: "16px",
                      marginBottom: "16px",
                      fontSize: "14px",
                      wordWrap: "break-word"
                    }}
                  >
                    FILENAME: {image.file.name}
                  </p>
                  <div className="image-item__btn-wrapper">
                    <Button
                      type="button"
                      name={"Edit"}
                      color="green"
                      click={onImageUpdate}
                    />
                  </div>
                </div>
              ))}
              {/* Exist Image */}
              {(floorPlanImageUrl && images.length == 0) &&
                <React.Fragment>
                  <img src={floorPlanImageUrl} alt="floor-plan-image" width="100%" />
                  <p
                    style={{
                      marginTop: "16px",
                      marginBottom: "16px",
                      fontSize: "14px",
                      wordWrap: "break-word"
                    }}
                  >
                    FILENAME: {floorPlanImageFileName}
                  </p>
                  <div className="image-item__btn-wrapper">
                    <Button
                      type="button"
                      name={"Edit"}
                      color="green"
                      click={onImageUpdate}
                    />
                  </div>
                </React.Fragment>
              }
              {/* Edit an exist Image */}
              {(floorPlanImageUrl && images.length > 0) &&
                images.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image['data_url']} alt="floor-plan-image" width="100%" />
                    <p
                      style={{
                        marginTop: "16px",
                        marginBottom: "16px",
                        fontSize: "14px",
                        wordWrap: "break-word"
                      }}
                    >
                      FILENAME: {image.file.name}
                    </p>
                    <div className="image-item__btn-wrapper">
                      <Button
                        type="button"
                        name={"Edit"}
                        color="green"
                        click={onImageUpdate}
                      />
                    </div>
                  </div>
                ))
              }
            </div>
          )}
        </ImageUploading>

        <div style={{ height: 70, width: 'auto' }} />
        {
          <React.Fragment>
            <Button
              type="submit"
              name={this.props.loading ? 'Loading...' : 'Done'}
              color="primary"
              disabled={disabledButton}
              loading={this.props.loading}
              click={this.onSubmitFloorPlanImageHandler}
            />
            <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
          </React.Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading } = state.widgets;
  const { result } = state.pages;
  return { loading, result };
};

export default connect(mapStateToProps, { editPage })(WidgetDrawerFloorPlanImage);