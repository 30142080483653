import React, { Component } from 'react';
import DrawingMap from '../DrawingMap/DrawingMap';

import styles from './AssetFence.module.scss';

const mockShape = [
  {
    id: 2,
    type: 'rectangle',
    position: {
      northEast: {
        lat: 40.73587635001136,
        lng: -73.65118244239909
      },
      southWest: {
        lat: 40.72612021481928,
        lng: -73.66337040016276
      }
    }
  },
  {
    id: 3,
    type: 'polygon',
    position: [
      {
        lat: 40.763693863817766,
        lng: -73.67178180763347
      },
      {
        lat: 40.75979262664911,
        lng: -73.67521503517253
      },
      {
        lat: 40.754850730976786,
        lng: -73.67298343727214
      },
      {
        lat: 40.74743719889406,
        lng: -73.68036487648112
      },
      {
        lat: 40.755891160584655,
        lng: -73.66268375465495
      },
      {
        lat: 40.760182760666384,
        lng: -73.66955020973307
      }
    ]
  }
]

const mockMarker = {
  id: '479776990-4',
  position: {
    lat: 40.744850730976786,
    lng: -73.77068343727214
  },
  type: 'gps'
}

class AssetFlat extends Component {
  render() {
    return (
      <div className={styles.AssetFlat}>
        <DrawingMap fencesData={mockShape} markerData={mockMarker} />
      </div>
    )
  }
}

export default AssetFlat;