import React, { useEffect } from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";

import styles from './StartStopPOModal.module.scss';
import Modal from '../../UI/Modal/Modal';

const ScannerModal = ({ open, modalClosed, receivedScannerText }) => {

  useEffect(() => {
    receivedScannerText('');
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, []);

  function handleEsc(event) {
    if (open && event.keyCode === 27) {
      modalClosed();
    }
  };

  return (
    <Modal darkMode show={open} modalClosed={modalClosed} overideStyles={{ width: '100%', height: '100%' }}>
      <div style={{ position: 'relative', height: '100%' }}>
        <BarcodeScannerComponent
          width={'100%'}
          height={'100%'}
          onUpdate={(err, result) => {
            if (result) {
              receivedScannerText(result.text);
              modalClosed();
            }
          }}
        />
        <div className={styles.CameraFrame} />
        <div className={styles.ScannerTopicWrapper}>
          <div className={styles.ScannerTopic}>Scan QR Code or Barcode</div>
        </div>
      </div>
    </Modal>
  );
}

export default ScannerModal;