import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  productionPlans: null,
  productionPlan: null,
  addedProductionPlan: null,
  updatedProductionPlan: null,
  selectedProductionPlan: null,
  selectedStartDate: null,
  selectedStopDate: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCTION_PLANS_START: return getProductionPlansStart(state, action);
    case actionTypes.GET_PRODUCTION_PLANS_SUCCESS: return getProductionPlansSuccess(state, action);
    case actionTypes.GET_PRODUCTION_PLANS_FAIL: return getProductionPlansFail(state, action);

    case actionTypes.GET_PRODUCTION_PLAN_START: return getProductionPlanStart(state, action);
    case actionTypes.GET_PRODUCTION_PLAN_SUCCESS: return getProductionPlanSuccess(state, action);
    case actionTypes.GET_PRODUCTION_PLAN_FAIL: return getProductionPlanFail(state, action);

    case actionTypes.ADD_PRODUCTION_PLAN_START: return addProductionPlanStart(state, action);
    case actionTypes.ADD_PRODUCTION_PLAN_SUCCESS: return addProductionPlanSuccess(state, action);
    case actionTypes.ADD_PRODUCTION_PLAN_FAIL: return addProductionPlanFail(state, action);

    case actionTypes.EDIT_PRODUCTION_PLAN_START: return editProductionPlanStart(state, action);
    case actionTypes.EDIT_PRODUCTION_PLAN_SUCCESS: return editProductionPlanSuccess(state, action);
    case actionTypes.EDIT_PRODUCTION_PLAN_FAIL: return editProductionPlanFail(state, action);

    case actionTypes.DELETE_PRODUCTION_PLAN_START: return deleteProductionPlanStart(state, action);
    case actionTypes.DELETE_PRODUCTION_PLAN_SUCCESS: return deleteProductionPlanSuccess(state, action);
    case actionTypes.DELETE_PRODUCTION_PLAN_FAIL: return deleteProductionPlanFail(state, action);

    case actionTypes.SET_SELECTED_PRODUCTION_PLAN: return setSelectedProductionPlan(state, action);
    case actionTypes.CLEAR_SELECTED_PRODUCTION_PLAN: return clearSelectedProductionPlan(state, action);
    default: return state;
  }
};

const getProductionPlansStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getProductionPlansSuccess = (state, action) => {
  const productionPlans = action.productionPlans ? action.productionPlans.sort((a, b) => {
    if (a.date === b.date) {
      return a.start - b.start;
    } else {
      return a.date - b.date;
    }
  }) : [];
  return updateObject(state, {
    type: action.type,
    productionPlans,
    error: null,
    loading: false,
    result: action.result
  });
}

const getProductionPlansFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    result: null
  });
}

const getProductionPlanStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getProductionPlanSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    productionPlan: action.productionPlan,
    error: null,
    loading: false,
    result: action.result
  });
}

const getProductionPlanFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    result: null
  });
}


const addProductionPlanStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addProductionPlanSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedProductionPlan: action.addedProductionPlan,
    error: null,
    loading: false
  });
}

const addProductionPlanFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editProductionPlanStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editProductionPlanSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedProductionPlan: action.updatedProductionPlan,
    error: null,
    loading: false
  });
}

const editProductionPlanFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteProductionPlanStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteProductionPlanSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteProductionPlanFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const setSelectedProductionPlan = (state, action) => {
  return updateObject(state, {
    selectedProductionPlan: action.selectedProductionPlan,
    selectedStartTime: action.selectedStartTime,
    selectedStopTime: action.selectedStopTime
  });
}

const clearSelectedProductionPlan = (state, action) => {
  return updateObject(state, {
    selectedProductionPlan: null,
    selectedStartTime: null,
    selectedStopTime: null
  });
}

export default reducer;