import * as actionTypes from './types';

// ----------------------- GET PRODUCTION_PLANS -----------------------
export const getProductionPlansStart = () => {
  return {
    type: actionTypes.GET_PRODUCTION_PLANS_START
  };
}

export const getProductionPlans = (org_id, start, stop, machine_id, po_number) => {
  return {
    type: actionTypes.GET_PRODUCTION_PLANS,
    org_id,
    start,
    stop,
    machine_id,
    po_number
  };
}

export const getProductionPlansSuccess = (response) => {
  return {
    type: actionTypes.GET_PRODUCTION_PLANS_SUCCESS,
    productionPlans: response.production_plans,
    result: response.result
  };
}

export const getProductionPlansFail = (error) => {
  return {
    type: actionTypes.GET_PRODUCTION_PLANS_FAIL,
    error: error
  };
}

// ----------------------- GET SINGLE PRODUCTION_PLAN -----------------------
export const getProductionPlanStart = () => {
  return {
    type: actionTypes.GET_PRODUCTION_PLAN_START
  };
}

export const getProductionPlan = (id) => {
  return {
    type: actionTypes.GET_PRODUCTION_PLAN,
    id
  };
}

export const getProductionPlanSuccess = (response) => {
  return {
    type: actionTypes.GET_PRODUCTION_PLAN_SUCCESS,
    productionPlan: response.production_plan,
    result: response.result
  };
}

export const getProductionPlanFail = (error) => {
  return {
    type: actionTypes.GET_PRODUCTION_PLAN_FAIL,
    error: error
  };
}

// ----------------------- ADD PRODUCTION_PLAN -----------------------
export const addProductionPlanStart = () => {
  return {
    type: actionTypes.ADD_PRODUCTION_PLAN_START
  };
}

export const addProductionPlan = (org_id, date, description, start, stop, shift_id, countingdevices_id, target, team_id) => {
  return {
    type: actionTypes.ADD_PRODUCTION_PLAN,
    org_id,
    date, description, start, stop, shift_id, countingdevices_id, target, team_id
  };
}

export const addProductionPlanSuccess = (response) => {
  return {
    type: actionTypes.ADD_PRODUCTION_PLAN_SUCCESS,
    addedProductionPlan: response.production_plan,
    result: response.result
  };
}

export const addProductionPlanFail = (error) => {
  return {
    type: actionTypes.ADD_PRODUCTION_PLAN_FAIL,
    error: error
  };
}

// ----------------------- EDIT PRODUCTION_PLAN -----------------------
export const editProductionPlanStart = () => {
  return {
    type: actionTypes.EDIT_PRODUCTION_PLAN_START
  };
}

export const editProductionPlan = (id, date, description, start, stop, shift_id, countingdevices_id, target, team_id) => {
  return {
    type: actionTypes.EDIT_PRODUCTION_PLAN,
    id,
    date, description, start, stop, shift_id, countingdevices_id, target, team_id
  };
}

export const editProductionPlanSuccess = (response) => {
  return {
    type: actionTypes.EDIT_PRODUCTION_PLAN_SUCCESS,
    updatedProductionPlan: response.production_plan,
    result: response.result
  };
}

export const editProductionPlanFail = (error) => {
  return {
    type: actionTypes.EDIT_PRODUCTION_PLAN_FAIL,
    error: error
  };
}

// ----------------------- DELETE PRODUCTION_PLAN -----------------------
export const deleteProductionPlanStart = () => {
  return {
    type: actionTypes.DELETE_PRODUCTION_PLAN_START
  };
}

export const deleteProductionPlan = (id) => {
  return {
    type: actionTypes.DELETE_PRODUCTION_PLAN,
    id: id
  };
}

export const deleteProductionPlanSuccess = (result) => {
  return {
    type: actionTypes.DELETE_PRODUCTION_PLAN_SUCCESS,
    result: result
  };
}

export const deleteProductionPlanFail = (error) => {
  return {
    type: actionTypes.DELETE_PRODUCTION_PLAN_FAIL,
    error: error
  };
}

// ----------------------- SET SELECTED PRODUCTION PLAN -----------------------
export const setSelectedProductionPlan = (selectedProductionPlan, selectedStartTime, selectedStopTime) => {
  return {
    type: actionTypes.SET_SELECTED_PRODUCTION_PLAN,
    selectedProductionPlan: selectedProductionPlan,
    selectedStartTime: selectedStartTime, 
    selectedStopTime: selectedStopTime
  };
}

// ----------------------- CLEAR SELECTED PRODUCTION PLAN -----------------------
export const clearSelectedProductionPlan = () => {
  return {
    type: actionTypes.CLEAR_SELECTED_PRODUCTION_PLAN,
  };
}

// export default {
//   getProductionPlansStart,
//   getProductionPlans,
//   getProductionPlansSuccess,
//   getProductionPlansFail,
//   getProductionPlanStart,
//   getProductionPlan,
//   getProductionPlanSuccess,
//   getProductionPlanFail,
//   addProductionPlan,
//   addProductionPlanStart,
//   addProductionPlanSuccess,
//   addProductionPlanFail,
//   editProductionPlan,
//   editProductionPlanStart,
//   editProductionPlanSuccess,
//   editProductionPlanFail,
//   deleteProductionPlan,
//   deleteProductionPlanStart,
//   deleteProductionPlanSuccess,
//   deleteProductionPlanFail,
//   setSelectedProductionPlan,
//   clearSelectedProductionPlan
// };