import React, { Component } from 'react';
import * as MarkerClusterer from "@google/markerclustererplus";

import './MarkerMap.scss';
import Map from '../Map/Map';

const locations = [
  { name: 'Test1', description: 'This is description', lat: 18.789660, lng: 98.984398 },
  { name: 'Test2', description: 'This is description', lat: 7.008647, lng: 100.474686 },
  { name: 'Test3', description: 'This is description', lat: 40.713776, lng: -74.013974 },
  { name: 'Test4', description: 'This is description', lat: 40.712576, lng: -74.015974 },
  { name: 'Test5', description: 'This is description', lat: 40.712676, lng: -74.016074 },
  { name: 'Test6', description: 'This is description', lat: 40.712996, lng: -74.043530 },
  { name: 'Test7', description: 'This is description', lat: 40.713476, lng: -74.013472 },
  { name: 'Test8', description: 'This is description', lat: 40.714271, lng: -74.011344 },
  { name: 'Test9', description: 'This is description', lat: 40.713946, lng: -74.093214 },
];

class MarkerMap extends Component {
  mapRef = React.createRef();

  state = {
    mapDim: null
  }

  componentDidMount() {
    this.setState({ mapDim: { width: this.mapRef.current.offsetWidth, height: 424 } });
  }

  createInfoWindow = (marker, map, markerDetail) => {
    let info = `<div class="InfoWindow"><div class="InfoName">${markerDetail.name}</div><div class="InfoDescription">${markerDetail.description}</div></div>`;
    const infoWindow = new window.google.maps.InfoWindow({
      content: info,
    })
    infoWindow.open(map, marker);
    map.addListener('zoom_changed', () => {
      infoWindow.close(map);
    });
  }

  createMarkerClusterTooltip = (markerCluster, cluster, map) => {
    let info = `<div>Markers Length ${cluster.markers_.length}</div>`;
    const infoWindow = new window.google.maps.InfoWindow({
      content: info,
      position: {
        lat: cluster.center_.lat(),
        lng: cluster.center_.lng()
      }
    })
    infoWindow.open(map);
    map.addListener('zoom_changed', () => {
      infoWindow.close(map);
    });
    markerCluster.addListener('mouseout', () => {
      infoWindow.close(map);
    });
  }

  getBoundsZoomLevel = (bounds) => {
    const { mapDim } = this.state;
    var WORLD_DIM = { height: 256, width: 256 };
    var ZOOM_MAX = 21;

    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    var latFraction = (this.latRad(ne.lat()) - this.latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = this.zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = this.zoom(mapDim.width, WORLD_DIM.width, lngFraction);
    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }

  latRad = (lat) => {
    var sin = Math.sin(lat * Math.PI / 180);
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  zoom = (mapPx, worldPx, fraction) => {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  createBoundsForMarkers = (markers) => {
    var bounds = new window.google.maps.LatLngBounds();
    markers.forEach(marker => {
      bounds.extend(marker.getPosition());
    });
    return bounds;
  }

  render() {
    let markers = [];

    return (
      <div ref={this.mapRef}>
        {
          this.state.mapDim ?
            <Map
              id="myMap"
              options={{
                minZoom: 2,
                clickableIcons: false,
                gestureHandling: 'greedy',
              }}
              onMapLoad={map => {
                locations.forEach(location => {
                  const marker = new window.google.maps.Marker({
                    position: { lat: location.lat, lng: location.lng },
                    map: map,
                    icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                  });
                  marker.addListener('click', () => {
                    this.createInfoWindow(marker, map, { name: location.name, description: location.description })
                  })
                  markers.push(marker);
                });
                let bounds = this.createBoundsForMarkers(markers);
                map.setCenter(bounds.getCenter());
                let zoom = this.getBoundsZoomLevel(bounds);
                map.setZoom(zoom);
                const markerCluster = new MarkerClusterer(map, markers, {
                  imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
                });
                markerCluster.addListener('mouseover', (e) => {
                  this.createMarkerClusterTooltip(markerCluster, e, map);
                });
              }}
            /> : null}
      </div>
    );
  }
}

export default MarkerMap;
