import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  schedulers: null,
  addedScheduler: null,
  updatedSchedulers: null,
  schedulerTypes: null,
  addedSchedulerType: null,
  updatedSchedulerType: null,
  deletedSchedulerProperties: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SCHEDULERS_START: return getSchedulersStart(state, action);
    case actionTypes.GET_SCHEDULERS_SUCCESS: return getSchedulersSuccess(state, action);
    case actionTypes.GET_SCHEDULERS_FAIL: return getSchedulersFail(state, action);

    case actionTypes.ADD_SCHEDULER_START: return addSchedulerStart(state, action);
    case actionTypes.ADD_SCHEDULER_SUCCESS: return addSchedulerSuccess(state, action);
    case actionTypes.ADD_SCHEDULER_FAIL: return addSchedulerFail(state, action);

    case actionTypes.EDIT_SCHEDULER_START: return editSchedulerStart(state, action);
    case actionTypes.EDIT_SCHEDULER_SUCCESS: return editSchedulerSuccess(state, action);
    case actionTypes.EDIT_SCHEDULER_FAIL: return editSchedulerFail(state, action);

    case actionTypes.DELETE_SCHEDULER_START: return deleteSchedulerStart(state, action);
    case actionTypes.DELETE_SCHEDULER_SUCCESS: return deleteSchedulerSuccess(state, action);
    case actionTypes.DELETE_SCHEDULER_FAIL: return deleteSchedulerFail(state, action);

    case actionTypes.GET_SCHEDULER_TYPES_START: return getSchedulerTypesStart(state, action);
    case actionTypes.GET_SCHEDULER_TYPES_SUCCESS: return getSchedulerTypesSuccess(state, action);
    case actionTypes.GET_SCHEDULER_TYPES_FAIL: return getSchedulerTypesFail(state, action);

    case actionTypes.ADD_SCHEDULER_TYPE_START: return addSchedulerTypeStart(state, action);
    case actionTypes.ADD_SCHEDULER_TYPE_SUCCESS: return addSchedulerTypeSuccess(state, action);
    case actionTypes.ADD_SCHEDULER_TYPE_FAIL: return addSchedulerTypeFail(state, action);

    case actionTypes.EDIT_SCHEDULER_TYPE_START: return editSchedulerTypeStart(state, action);
    case actionTypes.EDIT_SCHEDULER_TYPE_SUCCESS: return editSchedulerTypeSuccess(state, action);
    case actionTypes.EDIT_SCHEDULER_TYPE_FAIL: return editSchedulerTypeFail(state, action);

    case actionTypes.DELETE_SCHEDULER_TYPE_START: return deleteSchedulerTypeStart(state, action);
    case actionTypes.DELETE_SCHEDULER_TYPE_SUCCESS: return deleteSchedulerTypeSuccess(state, action);
    case actionTypes.DELETE_SCHEDULER_TYPE_FAIL: return deleteSchedulerTypeFail(state, action);

    default: return state;
  }
};

const getSchedulersStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getSchedulersSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    schedulers: action.schedulers,
    error: null,
    loading: false,
    result: action.result
  });
}

const getSchedulersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    result: null
  });
}


const addSchedulerStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addSchedulerSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedScheduler: action.addedScheduler,
    error: null,
    loading: false
  });
}

const addSchedulerFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editSchedulerStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editSchedulerSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedSchedulers: action.updatedSchedulers,
    error: null,
    loading: false
  });
}

const editSchedulerFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteSchedulerStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type, deletedSchedulerProperties: null });
}

const deleteSchedulerSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false,
    deletedSchedulerProperties: action.properties
  });
}

const deleteSchedulerFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getSchedulerTypesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getSchedulerTypesSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    schedulerTypes: action.schedulerTypes,
    error: null,
    loading: false
  });
}

const getSchedulerTypesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addSchedulerTypeStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addSchedulerTypeSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedSchedulerType: action.addedSchedulerType,
    error: null,
    loading: false
  });
}

const addSchedulerTypeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editSchedulerTypeStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editSchedulerTypeSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedSchedulerType: action.updatedSchedulerType,
    error: null,
    loading: false
  });
}

const editSchedulerTypeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteSchedulerTypeStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteSchedulerTypeSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteSchedulerTypeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;