import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  searchOwlModelLoading: null,
  device: null,
  devices: null,
  owlModel: null,
  addedDevice: null,
  updatedDevice: null,
  deviceStatus: null,
  lastResult: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DEVICES_START: return getDevicesStart(state, action);
    case actionTypes.GET_DEVICES_SUCCESS: return getDevicesSuccess(state, action);
    case actionTypes.GET_DEVICES_FAIL: return getDevicesFail(state, action);

    case actionTypes.GET_DEVICE_START: return getDeviceStart(state, action);
    case actionTypes.GET_DEVICE_SUCCESS: return getDeviceSuccess(state, action);
    case actionTypes.GET_DEVICE_FAIL: return getDeviceFail(state, action);

    case actionTypes.ADD_DEVICE_START: return addDeviceStart(state, action);
    case actionTypes.ADD_DEVICE_SUCCESS: return addDeviceSuccess(state, action);
    case actionTypes.ADD_DEVICE_FAIL: return addDeviceFail(state, action);

    case actionTypes.EDIT_DEVICE_START: return editDeviceStart(state, action);
    case actionTypes.EDIT_DEVICE_SUCCESS: return editDeviceSuccess(state, action);
    case actionTypes.EDIT_DEVICE_FAIL: return editDeviceFail(state, action);

    case actionTypes.DELETE_DEVICE_START: return deleteDeviceStart(state, action);
    case actionTypes.DELETE_DEVICE_SUCCESS: return deleteDeviceSuccess(state, action);
    case actionTypes.DELETE_DEVICE_FAIL: return deleteDeviceFail(state, action);

    case actionTypes.ADD_CUSTOM_DEVICES_START: return addCustomDevicesStart(state, action);
    case actionTypes.ADD_CUSTOM_DEVICES_SUCCESS: return addCustomDevicesSuccess(state, action);
    case actionTypes.ADD_CUSTOM_DEVICES_FAIL: return addCustomDevicesFail(state, action);

    case actionTypes.ADD_OWL_DEVICES_START: return addOwlDevicesStart(state, action);
    case actionTypes.ADD_OWL_DEVICES_SUCCESS: return addOwlDevicesSuccess(state, action);
    case actionTypes.ADD_OWL_DEVICES_FAIL: return addOwlDevicesFail(state, action);

    case actionTypes.DELETE_OWL_DEVICES_START: return deleteOwlDevicesStart(state, action);
    case actionTypes.DELETE_OWL_DEVICES_SUCCESS: return deleteOwlDevicesSuccess(state, action);
    case actionTypes.DELETE_OWL_DEVICES_FAIL: return deleteOwlDevicesFail(state, action);

    case actionTypes.SEARCH_OWL_MODEL_START: return searchOwlModelStart(state,action);
    case actionTypes.SEARCH_OWL_MODEL_SUCCESS: return searchOwlModelSuccess(state,action);
    case actionTypes.SEARCH_OWL_MODEL_FAIL: return searchOwlModelFail(state,action);

    case actionTypes.GET_DEVICE_STATUS_START: return getDeviceStatusStart(state, action);
    case actionTypes.GET_DEVICE_STATUS_SUCCESS: return getDeviceStatusSuccess(state, action);
    case actionTypes.GET_DEVICE_STATUS_FAIL: return getDeviceStatusFail(state, action);

    case actionTypes.CLEAR_OWL_MODEL: return clearOwlModel(state,action);
    default: return state;
  }
};

const clearOwlModel = (state, action) => {
  return updateObject(state, { owlModel: null });
}

const getDevicesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getDevicesSuccess = (state, action) => {
  return updateObject(state, {
    devices: action.devices,
    error: null,
    loading: false
  });
}

const getDevicesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getDeviceStart = (state, action) => {
  return updateObject(state, { device: null, result: null, error: null, loading: true });
}

const getDeviceSuccess = (state, action) => {
  return updateObject(state, {
    device: action.device,
    error: null,
    loading: false
  });
}

const getDeviceFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addDeviceStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addDeviceSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedDevice: action.addedDevice,
    error: null,
    loading: false
  });
}

const addDeviceFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addCustomDevicesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addCustomDevicesSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedDevice: action.addedDevice,
    error: null,
    loading: false
  });
}

const addCustomDevicesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addOwlDevicesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addOwlDevicesSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const addOwlDevicesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editDeviceStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editDeviceSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedDevice: action.updatedDevice,
    error: null,
    loading: false,
    lastResult: action.lastResult
  });
}

const editDeviceFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    lastResult: action.lastResult
  });
}

const deleteDeviceStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteDeviceSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteDeviceFail = (state, action) => {
  return updateObject(state, {
    type: action.type,
    error: action.error,
    loading: false
  });
}

const deleteOwlDevicesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteOwlDevicesSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteOwlDevicesFail = (state, action) => {
  return updateObject(state, {
    type: action.type,
    error: action.error,
    loading: false
  });
}

const searchOwlModelStart = (state) => {
  return updateObject(state, { error: null, searchOwlModelLoading: true });
}

const searchOwlModelSuccess = (state, action) => {
  return updateObject(state, {
    owlModel: action.owlModel,
    error: null,
    searchOwlModelLoading: false
  });
}

const searchOwlModelFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    searchOwlModelLoading: false
  });
}

const getDeviceStatusStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: false });
}

const getDeviceStatusSuccess = (state, action) => {
  return updateObject(state, {
    deviceStatus: action.deviceStatus,
    error: null,
    loading: false
  });
}

const getDeviceStatusFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;