import React, { Component } from 'react';
import RGL, { WidthProvider } from "react-grid-layout";
import { connect } from 'react-redux';
// import MobileDetect from 'mobile-detect';
// import _ from 'lodash';
// import moment from 'moment';

// import 'react-grid-layout/css/styles.css';
// import 'react-resizable/css/styles.css'
import './WidgetGrid.css';
import Line from '../Chart/Line/Line';
import WidgetContainer from '../WidgetContainer/WidgetContainer';
import Gauge from '../Chart/Gauge/Gauge';
import Text from '../Chart/Text/Text';
import Power from '../Chart/Power/Power';
import Timeline from '../Chart/Timeline/Timeline';
import DateTime from '../Chart/DateTime/DateTime';
import SwitchOnOff from '../Chart/Switch/SwitchOnOff/SwitchOnOff';
import SwitchSequence from '../Chart/Switch/SwitchSequence/SwitchSequence';
import Lamp from '../Chart/Lamp/Lamp';
import AddIcon from '../../../../assets/icons/plus-icon.svg';

import { getData, getDataLatest, getDataEval } from '../../../../state/ducks/Data/actions';
import { DELETE_WIDGET_SUCCESS } from '../../../../state/ducks/Widgets/types';
import { calculateNewValFromEquation } from '../../../../state/utils';

const GridLayout = WidthProvider(RGL);

class WidgetGrid extends Component {
  static defaultProps = {
    className: 'layout',
    isDraggable: true,
    isResizable: true,
    rowHeight: 160,
    margin: [30, 30],
  };

  state = {
    dataSet: [],
    isSetingFirstDataset: false,
    dataSetInterval: []
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.dashboard !== prevProps.dashboard && this.props.dashboard) {
      if (!this.state.isSetingFirstDataset && this.props.dashboard.length) {
        const dataSet = [];
        this.props.dashboard.forEach(dash => {
          dataSet.push({ id: dash.id, value: null });
        });
        this.setUpGetDataInterval(this.props.dashboard);
        this.setState({ dataSet, isSetingFirstDataset: true });
      } else if (!this.props.dashboard.length) {
        this.setState({ isSetingFirstDataset: true });
      }
    }

    if (this.props.result !== prevProps.result && this.props.result === 'success') {
      if (this.props.addedWidget !== prevProps.addedWidget && this.props.addedWidget) {
        const wid = this.props.addedWidget;
        const dataSet = [...this.state.dataSet];
        dataSet.push({ id: wid.id, value: null });
        if ((wid.type === 'gauge' && ((!wid.properties && !wid.properties.gaugeProps) || !wid.properties.gaugeProps.formula)) || wid.type === 'lamp' || (wid.type === 'text' && !wid.formula)) {
          const dataSetInterval = [...this.state.dataSetInterval];
          dataSetInterval.push(setInterval(() => {
            this.props.getDataLatest(wid.properties.socket, wid.properties.secret, null, wid.id);
          }, 1000));
          this.setState({ dataSet, dataSetInterval });
        } else if ((wid.type === 'text' && wid.formula) || (wid.type === 'gauge' && wid.properties.gaugeProps.formula)) {
          const dataSetInterval = [...this.state.dataSetInterval];
          const formula = wid.type === 'text' ? wid.formula : wid.properties.gaugeProps.formula;
          this.props.getDataEval(formula, { wid: wid.id });
          dataSetInterval.push(setInterval(() => {
            this.props.getDataEval(formula, { wid: wid.id });
          }, 1000 * 60));
          this.setState({ dataSet, dataSetInterval });
        } else {
          this.setState({ dataSet });
        }
      } else if (this.props.updatedWidget !== prevProps.updatedWidget && this.props.updatedWidget) {
        const dataSet = [...this.state.dataSet];
        let foundDataSetIndex = dataSet.findIndex(ds => ds.id === this.props.updatedWidget.id);
        dataSet[foundDataSetIndex].value = null;
        this.clearAllDataSetIntervals();
        this.setUpGetDataInterval(this.props.dashboard);

        this.setState({ dataSet });
      } else if (this.props.type === DELETE_WIDGET_SUCCESS) {
        const dataSet = [...this.state.dataSet];
        const removingIndex = dataSet.findIndex(ds => ds.id === this.props.deletedWidgetId);
        dataSet.splice(removingIndex, 1);

        const dataSetInterval = [...this.state.dataSetInterval];
        clearInterval(dataSetInterval[removingIndex]);
        dataSetInterval[removingIndex] = null;
        this.setState({ dataSet, dataSetInterval });
      }
    }

    if (prevProps.dataLatest !== this.props.dataLatest && this.props.dataLatest.data && this.props.dataLatest.data.length > 0) {
      const { data, properties: { wid } } = this.props.dataLatest;
      if (Date.now() - data[0].timestamp > 5 * 60 * 1000) {
        const foundIndex = this.props.dashboard.findIndex(dash => dash.id === wid);
        const dataSet = [...this.state.dataSet];
        if (dataSet[foundIndex]) {
          dataSet[foundIndex].value = null;
          dataSet[foundIndex].lastUpdated = data[0].timestamp;
          dataSet[foundIndex].lastValue = calculateNewValFromEquation(data[0].value, this.props.dashboard[foundIndex].equation);
        }
        this.setState({ dataSet });
      } else {
        // console.log('update...')
        this.updateDataSet(this.props.dataLatest);
      }
    }

    if (prevProps.dataEval !== this.props.dataEval) {
      this.updateDataSet(this.props.dataEval, true);
    }

    if (prevProps.updatedId !== this.props.updatedId && this.props.updatedId) {
      this.props.onClearUpdatedId();
    }
  }

  setUpGetDataInterval = (dashboard) => {
    const dataSetInterval = [];
    dashboard.forEach((dash, i) => {
      if ((dash.type === 'gauge' && (!dash.gaugeProps || !dash.gaugeProps.formula)) || dash.type === 'lamp' || (dash.type === 'text' && !dash.formula)) {
        dataSetInterval[i] = setInterval(() => {
          this.props.getDataLatest(dash.socket, dash.secret, null, dash.id);
        }, 1000);
      } else if ((dash.type === 'text' && dash.formula) || (dash.type === 'gauge' && dash.gaugeProps.formula)) {
        const formula = dash.type === 'text' ? dash.formula : dash.gaugeProps.formula;
        this.props.getDataEval(formula, { wid: dash.id });
        dataSetInterval[i] = setInterval(() => {
          this.props.getDataEval(formula, { wid: dash.id });
        }, 1000 * 60);
      }
    });

    this.setState({ dataSetInterval });
  }

  updateDataSet = (dataLatest, isEval) => {
    const { data, properties: { wid } } = dataLatest;
    const dataSet = [...this.state.dataSet];
    const foundDataSetIndex = dataSet.findIndex(ds => ds.id === wid);
    if (dataSet[foundDataSetIndex]) {
      if (isEval) {
        dataSet[foundDataSetIndex].value = data || data === 0 ? data : null;
        dataSet[foundDataSetIndex].lastUpdated = null;
        dataSet[foundDataSetIndex].lastValue = null;
      } else {
        dataSet[foundDataSetIndex].value = data[0] && (data[0].value || data[0].value === 0) ? data[0].value : null;
        dataSet[foundDataSetIndex].lastUpdated = null;
        dataSet[foundDataSetIndex].lastValue = null;
      }
    }
    this.setState({ dataSet });
  }

  clearAllDataSetIntervals = () => {
    this.state.dataSetInterval.forEach(di => {
      clearInterval(di);
    });
  }

  componentWillUnmount() {
    this.clearAllDataSetIntervals();
  }

  findComponent(dashboard, dataSet) {
    if (dashboard && dashboard.length > 0) {
      return dashboard.map((dash, i) => {
        let foundDataSetIndex = dataSet.findIndex(data => data.id === dash.id);
        let Key = dash.id;
        if (this.props.updatedId === dash.id) {
          Key = dash.id + 'Updated';
        }
        if (dash.type === 'line') {
          let foundCombiningWidget = null;
          if (dash.lineProps && dash.lineProps.combiningWidget) foundCombiningWidget = dashboard.find(d => d.id === dash.lineProps.combiningWidget.id);
          return (
            <div key={Key} data-grid={dash.layout}>
              <WidgetContainer data={dash} isEdit={this.props.isEdit} onEdit={(event) => this.props.onEditWidget(event, dash)}>
                <Line id={Key} widget={dash} combiningWidget={foundCombiningWidget} deviceOptions={this.props.deviceOptions} />
              </WidgetContainer>
            </div>
          );
        }
        if (dash.type === 'gauge') {
          return (
            <div key={Key} data-grid={dash.layout}>
              <WidgetContainer data={dash} isEdit={this.props.isEdit}
                lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                lastValue={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastValue : null}
                onEdit={(event) => this.props.onEditWidget(event, dash)}>
                <Gauge widget={dash} id={`gauge${i}`}
                  data={foundDataSetIndex !== -1 && dataSet[foundDataSetIndex].value}
                  lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                  lastValue={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastValue : null}
                />
              </WidgetContainer>
            </div>
          );
        }
        if (dash.type === 'text') {
          return (
            <div key={Key} data-grid={dash.layout}>
              <WidgetContainer data={dash} isEdit={this.props.isEdit}
                lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                lastValue={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastValue : null}
                onEdit={(event) => this.props.onEditWidget(event, dash)}>
                <Text widget={dash} id={`text${i}`}
                  data={foundDataSetIndex !== -1 && dataSet[foundDataSetIndex].value}
                  lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                />
              </WidgetContainer>
            </div>
          );
        }
        if (dash.type === 'switch') {
          return (
            <div key={Key} data-grid={dash.layout}>
              <WidgetContainer data={dash} isEdit={this.props.isEdit} onEdit={(event) => this.props.onEditWidget(event, dash)}>
                {dash.switchProps.switchType === 'onoff' && <SwitchOnOff widget={dash} id={`switch${i}`} deviceOptions={this.props.deviceOptions} />}
                {dash.switchProps.switchType === 'sequence' && <SwitchSequence widget={dash} id={`switch${i}`} />}
              </WidgetContainer>
            </div>
          );
        }
        if (dash.type === 'lamp') {
          return (
            <div key={Key} data-grid={dash.layout}>
              <WidgetContainer data={dash} isEdit={this.props.isEdit} onEdit={(event) => this.props.onEditWidget(event, dash)}
                lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                lastValue={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastValue : null}>
                <Lamp widget={dash} id={`lamp${i}`}
                  data={foundDataSetIndex !== -1 && dataSet[foundDataSetIndex].value}
                  lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                />
              </WidgetContainer>
            </div>
          );
        }
        if (dash.type === 'time') {
          return (
            <div key={Key} data-grid={dash.layout}>
              <DateTime id={`time${i}`} type={dash.type} data={dash} isEdit={this.props.isEdit} onEdit={(event) => this.props.onEditWidget(event, dash)} />
            </div>
          );
        }
        if (dash.type === 'date') {
          return (
            <div key={Key} data-grid={dash.layout}>
              <DateTime id={`date${i}`} type={dash.type} data={dash} isEdit={this.props.isEdit} onEdit={(event) => this.props.onEditWidget(event, dash)} />
            </div>
          );
        }
        if (dash.type === 'timeline') {
          return (
            <div key={Key} data-grid={dash.layout}>
              <WidgetContainer data={dash} isEdit={this.props.isEdit} onEdit={(event) => this.props.onEditWidget(event, dash)}>
                <Timeline widget={dash} id={`timeline${i}`} mqttData={foundDataSetIndex !== -1 && dataSet[foundDataSetIndex].value} deviceOptions={this.props.deviceOptions} />
              </WidgetContainer>
            </div>
          );
        }
        if (dash.type === 'power') {
          return (
            <div key={Key} data-grid={dash.layout}>
              <WidgetContainer data={dash} isEdit={this.props.isEdit}
                lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                lastValue={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastValue : null}
                onEdit={(event) => this.props.onEditWidget(event, dash)}>
                <Power widget={dash} id={`power${i}`}
                  // data={foundDataSetIndex !== -1 && dataSet[foundDataSetIndex].value}
                  lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                />
              </WidgetContainer>
            </div>
          );
        }
        return null;
      });
    }
    return [];
  }

  render() {
    const { dashboard } = this.props;
    const { dataSet } = this.state;

    let component = this.findComponent(dashboard, dataSet);
    return (
      <GridLayout onLayoutChange={this.props.onLayoutChange} {...this.props}>
        {
          component && component.length > 0 ? component :
            <div key="initialWidget" data-grid={{ x: 0, y: 0, w: 1, h: 2, static: true }}>
              <div className="AddBox" onClick={this.props.openDrawer}>
                <img src={AddIcon} alt="Add" className="AddIcon" />
                <div className="CardBody" style={{ textAlign: 'center' }}>
                  <p className="Title">Add your first widget here</p>
                </div>
              </div>
            </div>
        }
      </GridLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { dataLatest, dataEval } = state.data;
  const { type, result, addedWidget, updatedWidget, deletedWidgetId } = state.widgets;
  return { type, result, dataLatest, dataEval, addedWidget, updatedWidget, deletedWidgetId };
};

export default connect(mapStateToProps, { getData, getDataLatest, getDataEval })(WidgetGrid);