import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './FormulaBuilder.module.scss';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import Button from '../../components/UI/Button/Button';
import FormulaList from '../../components/FormulaBuilder/FormulaList/FormulaList';
import FormulaForm from '../../components/FormulaBuilder/FormulaForm/FormulaForm';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import VariableList from '../../components/FormulaBuilder/VariableList/VariableList';

import { getFormulas, deleteFormula } from '../../../state/ducks/Formula/actions';
import { DELETE_FORMULA_SUCCESS } from '../../../state/ducks/Formula/types';

const MENU = ['Formula Builder', 'Variables']

class FormulaBuilder extends Component {
  state = {
    currentTabIndex: 0,
    selectedFormulaIndex: null,
    editingFormula: null,
    isAdding: false,
    openFormulaForm: false,
    isDeleteModalOpened: false
  }

  componentDidMount() {
    this.props.getFormulas(this.props.currentOrg);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.result !== this.props.result && this.props.result === 'success') {
      if (this.props.updatedFormula !== prevProps.updatedFormula || this.props.addedFormula !== prevProps.addedFormula || (prevProps.type !== this.props.type && this.props.type === DELETE_FORMULA_SUCCESS)) {
        this.props.getFormulas(this.props.currentOrg);
        this.closeAddNewFormulaHandler();
      }
    }
  }

  onAddFormulaHandler = () => {
    if (!this.state.isAdding) {
      this.setState({ isAdding: true, editingFormula: null, openFormulaForm: true, selectedFormulaIndex: null });
    }
  }

  closeAddNewFormulaHandler = () => {
    this.setState({ openFormulaForm: false, isAdding: false, editingFormula: null, selectedFormulaIndex: null });
  }

  onEditFormulaHandler = (index) => {
    if (!this.state.editingFormula || this.state.editingFormula.id !== this.props.formulas[index].id) {
      this.setState({ isAdding: false, editingFormula: this.props.formulas[index], openFormulaForm: true, selectedFormulaIndex: index });
    }
  }

  onAddNewFormulaHandler = () => {
    this.setState({ openFormulaForm: true, isAdding: true, editingFormula: null, selectedFormulaIndex: null });
  }

  onDeleteFormulaHandler = (event) => {
    event.preventDefault();
    this.setState({ isDeleteModalOpened: true });
  }

  deleteModalClickHandler = () => {
    this.setState(prevState => {
      return { isDeleteModalOpened: !prevState.isDeleteModalOpened };
    });
  }

  onConfirmDeleteFormula = (event) => {
    event.preventDefault();
    this.props.deleteFormula(this.state.editingFormula.id);
    this.setState({ isDeleteModalOpened: false, editingFormula: null });
  }

  render() {

    return (
      <div className={styles.FormulaBuilder}>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              MENU.map((menu, index) => (
                <div key={`plan-list-${index}`} className={index === this.state.currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentTabIndex: index })}>
                  <span className={styles.MenuTitle}>{menu}</span>
                </div>
              ))
            }
          </div>
          <div className={styles.AddButtonWrapper}>
            {
              this.state.currentTabIndex === 0
                ? <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Formula" noMargin click={this.onAddNewFormulaHandler} />
                : null
            }
          </div>
        </div>

        {
          this.state.currentTabIndex === 0
            ? <div className={styles.FormulaBody}>
              <div className={styles.FormulaTopics}>
                <div className={styles.FormulaFix} style={{ height: `calc(100vh - ${140 + this.props.offsetHeight}px` }}>
                  {
                    this.props.loading ? <><div className={styles.LoadingCard}></div> <div className={styles.LoadingCard}></div></> :
                      this.props.formulas ?
                        <FormulaList
                          formulas={this.props.formulas}
                          editFormula={this.onEditFormulaHandler}
                          selectedFormulaIndex={this.state.selectedFormulaIndex}
                          closed={this.closeAddNewFormulaHandler}
                        />
                        : null
                  }
                </div>
              </div>

              <div className={styles.FormulaDetails}>
                {
                  this.state.openFormulaForm &&
                  <FormulaForm
                    isAdding={this.state.isAdding}
                    editingFormula={this.state.editingFormula}
                    closed={this.closeAddNewFormulaHandler}
                    toggleDeleteModal={this.onDeleteFormulaHandler}
                  />
                }
              </div>
            </div>
            : <VariableList />
        }

        <DeleteModal
          title="Formula"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeleteFormula}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { formulas, loading, result, addedFormula, updatedFormula, type } = state.formula;
  return { currentOrg, formulas, loading, result, addedFormula, updatedFormula, type };
};

export default connect(mapStateToProps, { getFormulas, deleteFormula })(FormulaBuilder);
