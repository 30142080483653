import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './AddDeviceForm.module.scss';
import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import DeviceForm from '../DeviceForm/DeviceForm';
import GrayArrowIcon from '../../../../assets/icons/Device/grey-arrow.svg';
import BlueArrowIcon from '../../../../assets/icons/Device/blue-arrow.svg';
import AddIcon from '../../../../assets/icons/plus-circle-blue.svg';
import DeleteIcon from '../../../../assets/icons/delete-red-bin.svg';

import { addCustomDevices } from '../../../../state/ducks/Devices/actions';

const DEFAULT_DEVICE_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  }
};

class AddCustomDeviceForm extends Component {
  state = {
    socketOpened: null,
    formIsValid: false,
    deviceForm: DEFAULT_DEVICE_FORM,
    allDeviceSocketsForm: [],
    deviceSocket: []
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.result !== this.props.result && this.props.result === 'success' && this.props.addedDevice !== prevProps.addedDevice) {
      this.setState({
        socketOpened: null,
        formIsValid: false,
        deviceForm: DEFAULT_DEVICE_FORM,
        allDeviceSocketsForm: null,
        deviceSocket: []
      });
    }
  }

  handleTextChange = event => {
    let updatedElement = updateObject(this.state.deviceForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.deviceForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.deviceForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ deviceForm: updatedForm, formIsValid });
  };

  openSocketHandler = (index) => {
    let newSocketOpen = null;
    if (this.state.socketOpened !== index) {
      newSocketOpen = index;
    }
    this.setState({ socketOpened: newSocketOpen });
  }

  addNewSocketHandler = () => {
    const updatedForm = [...this.state.allDeviceSocketsForm];
    let socket;
    if (updatedForm.length === 0) {
      socket = 1;
    } else {
      socket = updatedForm[updatedForm.length - 1].socket + 1;
    }
    const defaultName = 'Socket ' + socket;
    const defaultLocation = 'Location ' + socket;
    const defaultUnit = 'Unit ' + socket;
    const defaultSocketForm = {
      name: { value: defaultName, touched: true, valid: true },
      location: { value: defaultLocation, touched: true, valid: true },
      unit_name: { value: defaultUnit, touched: true, valid: true }
    };
    updatedForm.push({
      socket,
      form: defaultSocketForm,
      isValid: true
    });

    const deviceSocket = [...this.state.deviceSocket];
    deviceSocket.push({
      name: defaultName,
      location: defaultLocation,
      unit_name: defaultUnit
    });

    this.setState({ allDeviceSocketsForm: updatedForm, deviceSocket });
  }

  onRemoveNewSocketHandler = (event, index) => {
    event.stopPropagation();
    const deviceSocket = [...this.state.deviceSocket];
    deviceSocket.splice(index, 1);

    const updatedForm = [...this.state.allDeviceSocketsForm];
    updatedForm.splice(index, 1);
    this.setState({ allDeviceSocketsForm: updatedForm, deviceSocket });
  }

  handleOwlDeviceFormChange = (index, form, isValid) => {
    let updatedForm = [...this.state.allDeviceSocketsForm];
    updatedForm[index].form = form;
    updatedForm[index].isValid = isValid;
    this.setState({ allDeviceSocketsForm: updatedForm });
  }

  checkSubmitButton = () => {
    let valid = this.state.formIsValid;
    if (this.state.allDeviceSocketsForm) {
      this.state.allDeviceSocketsForm.forEach(form => {
        valid = form.isValid && valid;
      });
    }
    return !valid;
  }

  onSubmitDeviceHandler = (event) => {
    event.preventDefault();
    this.props.addCustomDevices(this.props.currentOrg, this.state.deviceForm, this.state.allDeviceSocketsForm);
  }

  render() {
    let deviceSocketsForm = null;
    deviceSocketsForm = (
      <React.Fragment>
        {
          this.state.allDeviceSocketsForm ? this.state.allDeviceSocketsForm.map((ds, index) => {
            let socketDetailStyle = [styles.CollapseContainer];
            let socketStyle = [styles.Socket];
            let arrowIcon = GrayArrowIcon;
            if (this.state.socketOpened === index) {
              socketStyle.push(styles.Active);
              socketDetailStyle.push(styles.Open);
              arrowIcon = BlueArrowIcon;
            } else {
              socketDetailStyle.push(styles.Close);
            }

            if (this.state.allDeviceSocketsForm) {
              if (!this.state.allDeviceSocketsForm[index].isValid) {
                socketStyle.push(styles.Error);
              }
            }
            return (
              <div key={`New-Socket-${ds.socket}`} className={styles.SocketsWrapper}>
                <div className={styles.SlideSocket} onClick={() => this.openSocketHandler(index)}>
                  <div className={socketStyle.join(' ')}>
                    <div style={{ display: 'flex' }}>
                      <div className={styles.SocketNumber}>Socket-{ds.socket}</div>
                      <img className={styles.DeleteIcon} alt="Delete socket" src={DeleteIcon} onClick={(event) => this.onRemoveNewSocketHandler(event, index)} />
                      <img alt="Open details" src={arrowIcon} />
                    </div>
                  </div>
                </div>
                <div className={socketDetailStyle.join(' ')}>
                  <DeviceForm deviceSocket={this.state.deviceSocket[index]} addIndex={index} handleOwlDeviceFormChange={this.handleOwlDeviceFormChange} />
                </div>
              </div>
            )
          }): null
        }
      </React.Fragment >
    );

    return (
      <form onSubmit={this.onSubmitDeviceHandler}>
        <div className={styles.Divider} />
        <Input
          label="device name"
          name="name"
          type="text"
          value={this.state.deviceForm.name.value}
          placeholder="Please insert Device Name"
          autoComplete="off"
          onChange={this.handleTextChange}
          error={`${this.state.deviceForm.name.touched && !this.state.deviceForm.name.valid ? 'Device Name is required' : ''}`}
          required
        />

        <div className={styles.Label}>Sockets can be added following your model</div>
        <div className={styles.AddSocketButton} onClick={this.addNewSocketHandler}>
          <img src={AddIcon} alt="Add icon for socket" className={styles.AddIcon} />
          <div className={styles.ButtonName}>Add new Socket</div>
        </div>
        {deviceSocketsForm}
        <div style={{ height: 15, width: '100%' }} />

        <Button
          type="submit"
          name={this.props.loading ? 'Loading...' : 'Done'}
          color="primary"
          disabled={this.checkSubmitButton()}
          loading={this.props.loading}
        />
        <Button
          type="button"
          name="Cancel"
          color="borderred"
          click={this.props.closed}
        />
      </form >
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { type, result, addedDevice, loading } = state.devices;
  return { currentOrg, type, addedDevice, result, loading };
};

export default connect(mapStateToProps, { addCustomDevices })(AddCustomDeviceForm);