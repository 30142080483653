import React, { Component } from 'react';

import { updateObject, checkValidity } from '../../../../../state/utils';
import styles from '../SystemDeviceConfig.module.scss';
import Input from '../../../UI/Input/Input';
// import SimpleSwitch from '../../../UI/SimpleSwitch/SimpleSwitch';

const DEFAULT_FORM = {
  cellularAPN: {
    value: 'internet',
    valid: true,
    touched: true,
    validation: {}
  },
  cellularUsername: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  },
  cellularPassword: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  }
};

class CellularFields extends Component {
  state = {
    form: DEFAULT_FORM,
    formIsValid: false
  }

  componentDidMount() {
    const { cellularAPN, cellularUsername, cellularPassword } = this.props.networkDetails;
    if (this.props.networkDetails && Object.keys(this.props.networkDetails).length && (cellularAPN || cellularUsername || cellularPassword)) {
      this.setupCellularForm(this.props.networkDetails, false);
    } else if (this.props.cellularConfig) {
      this.setupCellularForm(this.props.cellularConfig, true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cellularConfig !== this.props.cellularConfig && (this.props.cellularConfig || this.props.cellularConfig === '')) {
      this.setupCellularForm(this.props.cellularConfig, true);
    }
  }

  setupCellularForm = (cellularConfig, isFromOriginalConfigs) => {
    if (cellularConfig && Object.keys(cellularConfig).length) {
      let updatedForm = { ...this.state.form };
      for (let inputIdentifier in updatedForm) {
        let key = inputIdentifier;
        if (isFromOriginalConfigs) {
          switch (inputIdentifier) {
            case 'cellularAPN': key = 'name'; break;
            case 'cellularUsername': key = 'username'; break;
            case 'cellularPassword': key = 'password'; break;
            default: break;
          }
        }

        let value;
        if (cellularConfig === '') {
          if (key === 'name' || key === 'cellularAPN') value = 'internet';
          else value = '';
        } else {
          if (isFromOriginalConfigs) {
            if (cellularConfig.apnConfigsMap) {
              cellularConfig.apnConfigsMap.forEach(apn => {
                if (apn[0] === key) {
                  value = apn[1];
                }
              });
            }
          } else {
            value = (key === 'cellularAPN' && !cellularConfig[key]) ? 'internet' : cellularConfig[key];
          }
        }
        const updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: value ? value : '',
          valid: true,
          touched: true
        });
        updatedForm = updateObject(updatedForm, {
          [inputIdentifier]: updatedElement
        });
      }
      this.setState({ form: updatedForm, formIsValid: true });
    }
  }

  handleCellularTextChange = event => {
    const { name, value } = event.target;
    this.props.setNetworkDetails(name, value);

    let updatedElement = updateObject(this.state.form[name], {
      value: value,
      valid: checkValidity(value, this.state.form[name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.form, {
      [name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ form: updatedForm, formIsValid });
  };

  render() {
    const { cellularAPN, cellularUsername, cellularPassword } = this.state.form;

    return (
      <div style={{ display: this.props.show ? 'block' : 'none' }}>
        <div className={styles.Label}>Cellular Settings</div>
        <Input
          labelin="true"
          label="APN"
          name="cellularAPN"
          type="text"
          value={cellularAPN.value}
          autoComplete="off"
          onChange={this.handleCellularTextChange}
        />
        <Input
          labelin="true"
          label="Username"
          name="cellularUsername"
          type="text"
          value={cellularUsername.value}
          autoComplete="off"
          onChange={this.handleCellularTextChange}
        />
        <Input
          labelin="true"
          label="Password"
          name="cellularPassword"
          type="text"
          value={cellularPassword.value}
          autoComplete="off"
          onChange={this.handleCellularTextChange}
        />

        <div className={styles.BetweenSection} />
      </div>
    );
  }
}

export default CellularFields;