import * as actionTypes from './types';

// ----------------------- GET_ACTUAL_PRODUCTION --------------------------
export const getActualProductionStart = () => {
  return {
    type: actionTypes.GET_ACTUAL_PRODUCTION_START
  }
}

export const getActualProduction = (org_id, machine_ids, structure_uuid, start, end) => {
  return {
    type: actionTypes.GET_ACTUAL_PRODUCTION,
    org_id, machine_ids, structure_uuid, start, end
  }
}

export const getActualProductionSuccess = (actualProductions) => {
  return {
    type: actionTypes.GET_ACTUAL_PRODUCTION_SUCCESS,
    actualProductions
  }
}

export const getActualProductionFail = (error) => {
  return {
    type: actionTypes.GET_ACTUAL_PRODUCTION_FAIL,
    error
  }
}

// ----------------------- UPDATE_ACTUAL_PRODUCTION -----------------------
export const updateActualProductionStart = () => {
  return {
    type: actionTypes.UPDATE_ACTUAL_PRODUCTION_START
  };
}

export const updateActualProduction = (uuid, start, end, total, ok, defect, columns) => {
  return {
    type: actionTypes.UPDATE_ACTUAL_PRODUCTION,
    uuid, start, end, total, ok, defect, columns
  };
}

export const updateActualProductionSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_ACTUAL_PRODUCTION_SUCCESS,
    updatedActualProduction: response.production,
    result: response.result
  };
}

export const updateActualProductionFail = (error) => {
  return {
    type: actionTypes.UPDATE_ACTUAL_PRODUCTION_FAIL,
    error
  };
}

// ----------------------- DELETE_ACTUAL_PRODUCTION -----------------------
export const deleteActualProductionStart = () => {
  return {
    type: actionTypes.DELETE_ACTUAL_PRODUCTION_START
  };
}

export const deleteActualProduction = (uuid) => {
  return {
    type: actionTypes.DELETE_ACTUAL_PRODUCTION,
    uuid
  };
}

export const deleteActualProductionSuccess = (response) => {
  return {
    type: actionTypes.DELETE_ACTUAL_PRODUCTION_SUCCESS,
    result: response.result
  };
}

export const deleteActualProductionFail = (error) => {
  return {
    type: actionTypes.DELETE_ACTUAL_PRODUCTION_FAIL,
    error
  };
}

// ----------------------- START_PLAN-----------------------
export const startPlanStart = () => {
  return {
    type: actionTypes.START_PLAN_START
  };
}

export const startPlan = (plan_uuid, start, machine_id, target, columns) => {
  return {
    type: actionTypes.START_PLAN,
    plan_uuid, start, machine_id, target, columns
  };
}

export const startPlanSuccess = (response) => {
  return {
    type: actionTypes.START_PLAN_SUCCESS,
    startedProduction: response.production,
    result: response.result
  };
}

export const startPlanFail = (error) => {
  return {
    type: actionTypes.START_PLAN_FAIL,
    error
  };
}

// ----------------------- STOP PLAN -----------------------
export const stopPlanStart = () => {
  return {
    type: actionTypes.STOP_PLAN_START
  };
}

export const stopPlan = (uuid, total, ok, defect, columns) => {
  return {
    type: actionTypes.STOP_PLAN,
    uuid, total, ok, defect, columns
  };
}

export const stopPlanSuccess = (response) => {
  return {
    type: actionTypes.STOP_PLAN_SUCCESS,
    stoppedProduction: response.production,
    result: response.result
  };
}

export const stopPlanFail = (error) => {
  return {
    type: actionTypes.STOP_PLAN_FAIL,
    error
  };
}

// ----------------------- CALCULATE OEE -----------------------
export const calculateOeeStart = () => {
  return {
    type: actionTypes.CALCULATE_OEE_START
  };
}

export const calculateOee = (actual_uuid, stop_point, start, end, interval, properties, is_floating) => {
  return {
    type: actionTypes.CALCULATE_OEE,
    actual_uuid, stop_point, start, end, interval, properties, is_floating
  };
}

export const calculateOeeSuccess = (response, properties) => {
  return {
    type: actionTypes.CALCULATE_OEE_SUCCESS,
    apq: response.apq,
    average: response.average,
    expected: response.expected,
    sum: response.sum,
    properties: properties,
    result: response.result
  };
}

export const calculateOeeFail = (error) => {
  return {
    type: actionTypes.CALCULATE_OEE_FAIL,
    error
  };
}