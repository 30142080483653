import React, { Component } from 'react';

import Button from '../../../components/UI/Button/Button';
import styles from './VerifyEmail.module.scss';
import VerifyEmailBg from '../../../../assets/images/verify-email.svg';
import TellYourFriends from '../../../../assets/icons/Auth/send-round-1569.svg';

class VerifyEmail extends Component {

  goBackToLogin = () => {
    this.props.history.push('/auth');
  }

  render() {
    const email = this.props.match.params.email;
    return (
      <div className={styles.VerifyEmail}>
        <div className={styles.LeftSection}>
          <div style={{ flex: 1 }}>
            <div className={styles.Header}>Verify Your Email <span role="img" aria-label="email">✉️</span></div>
            <div>
              <div className={styles.Description}>To start using OWL, You need to verify</div>
              <div className={styles.Description}>your email ID &nbsp;
                <span className={styles.Email}>
                  {email}
                </span>
              </div>
            </div>
          </div>
          <Button type="button" color="blue" name="I did it, Log in now" click={this.goBackToLogin} />
        </div>
        <div className={styles.RightSection} style={{ backgroundImage: `url(${VerifyEmailBg})` }}>
          <div className={styles.Footer}>
            <p>Tell your friends about us <img src={TellYourFriends} alt="Tell Your Friends" /></p>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyEmail;
