import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import MobileDetect from 'mobile-detect';

import { renewToken, signout } from './state/ducks/Auth/actions';
import { getOrgs } from './state/ducks/Organization/actions';
import { history } from './state/ducks/index';
import Layout from './hoc/Layout/MainLayout';
import Auth from './views/containers/Auth/Auth/Auth';
import Pages from './views/containers/Pages/Pages';
import PageTimeline from './views/containers/PageTimeline/PageTimeline';
import Team from './views/containers/Team/Team';
import Widgets from './views/containers/Widgets/Widgets';
import Report from './views/containers/Report/Report';
import Production from './views/containers/Production/Production'; // for only GS
import AdvanceProductionBatch from './views/containers/AdvanceProductionBatch/AdvanceProductionBatch';
import Structure from './views/containers/AdvanceProductionPlanStructure/Structure';
import AdvanceProductionPlans from './views/containers/AdvanceProductionPlans/AdvanceProductionPlans';
import AdvanceActualProduction from './views/containers/AdvanceActualProduction/AdvanceActualProduction';
import Rule from './views/containers/Rule/Rule';
import Hook from './views/containers/Hook/Hook';
import Devices from './views/containers/Devices/Devices';
import Members from './views/containers/Members/Members';
import Profile from './views/containers/Profile/Profile';
import CreateOrg from './views/containers/Organization/CreateOrg';
import VerifyEmail from './views/containers/Auth/VerifyEmail/VerifyEmai';
import VerifiedEmail from './views/containers/Auth/VerifiedEmail/VerifiedEmail';
import ResetPassword from './views/containers/Auth/ResetPassword/ResetPassword';
import AssetTracking from './views/containers/AssetTracking/AssetTracking';
import Chat from './views/containers/Chat/Chat';
import Machine from './views/containers/Machine/Machine';
import VirtualDevice from './views/containers/VirtualDevice/VirtualDevice';
import Releases from './views/containers/Releases/Releases';
import Operator from './views/containers/Operator/Operator';
import ProductionDashboard from './views/containers/ProductionDashboard/ProductionDashboard';
import DocumentMasterData from './views/containers/DocumentMasterData/DocumentMasterData';
import NoteType from './views/containers/NoteType/NoteType';
import Documents from './views/containers/Documents/Documents';
import AdminDevices from './views/containers/Admin/AdminDevices';
import BreakdownLog from './views/containers/BreakdownLog/BreakdownLog';
import PcsSettings from './views/containers/PcsSettings/PcsSettings';
import PcsTimeline from './views/containers/PcsTimeline/PcsTimeline';
import NotificationCenter from './views/containers/Notification/NotificationCenter';
import FloorPlan from './views/containers/FloorPlan/FloorPlan';
import AnomalyDetection from './views/containers/AnomalyDetection/AnomalyDetection';
import PcsScheduler from './views/containers/PcsScheduler/PcsScheduler';
import FormulaBuilder from './views/containers/FormulaBuilder/FormulaBuilder';
import DeviceGrouping from './views/containers/DeviceGrouping/DeviceGrouping';
import Energy from './views/containers/Energy/Energy';

class App extends Component {

  state = {
    isMobile: new MobileDetect(window.navigator.userAgent).mobile() !== null && window.innerWidth < 600,
    isAdminMode: localStorage.getItem('sight_view_mode') === 'admin',
    orgModules: null
  }

  onToggleAdminMode = () => {
    if (!this.props.loggedInUser?.is_superuser)
      return;
    localStorage.setItem('sight_view_mode', this.state.isAdminMode ? 'user' : 'admin');
    this.setState({ isAdminMode: !this.state.isAdminMode });
  }

  onResize() {
    let isMobile = new MobileDetect(window.navigator.userAgent).mobile() !== null && window.innerWidth < 600;
    this.setState({ isMobile });
  }

  componentDidMount() {
    let app = this;
    window.onresize = function () { app.onResize(); };
    if (this.isAuthenticated() && this.props.location.pathname !== '/auth') {
      this.props.getOrgs();
      if (!localStorage.getItem('owlCurrentOrg') && !this.props.loggedInUser.is_superuser) {
        history.push('/create-organization');
      }
      this.props.renewToken();
      this.renewTokenInterval = setInterval(() => {
        if (this.isAuthenticated()) {
          this.props.renewToken();
        } else {
          clearInterval(this.renewTokenInterval);
        }
      }, 900 * 1000);
    } else if (!this.props.location.pathname.startsWith('/verify-email/') && !this.props.location.pathname.startsWith('/verify/') && !this.props.location.pathname.startsWith('/resetpassword/') && !this.props.location.pathname.startsWith('/releases')) {
      this.props.signout();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((this.state.isMobile !== nextState.isMobile)
      || (this.props.loggedInUser !== nextProps.loggedInUser)
      || (this.props.currentOrg !== nextProps.currentOrg)
      || (this.state.isAdminMode !== nextState.isAdminMode)
      || (nextProps.orgs !== this.props.orgs)
      || nextState.orgModules !== this.state.orgModules) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isAdminMode !== prevState.isAdminMode) {
      this.props.history.push(this.state.isAdminMode ? '/admin/device' : '/pages');
    } else if (prevProps.orgs !== this.props.orgs && this.props.orgs) {
      this.setOrgModules();
    } else if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg && this.props.orgs) {
      this.setOrgModules();
    }
  }

  setOrgModules = () => {
    const foundOrg = this.props.orgs.find(org => org.id === this.props.currentOrg);
    this.setState({ orgModules: foundOrg && foundOrg.modules ? foundOrg.modules : [] });
  }

  isAuthenticated = () => {
    let token = localStorage.getItem("owlJwtToken");
    if (token) {
      return Date.now() < (jwt_decode(token).exp * 1000);
    }
    return false;
  }

  render() {
    const { isAdminMode } = this.state;
    const isOrgAdmin = this.props.currentOrg && this.props.loggedInUser?.admin_of_org && this.props.loggedInUser.admin_of_org.includes(+this.props.currentOrg);
    const isAdmin = this.props.loggedInUser?.is_superuser;
    let isMobile = this.state.isMobile;

    if (!this.props.loggedInUser) {
      return <Switch>
        <Route path="/auth" component={() => <Auth isMobile={isMobile} />} />
        <Route path="/releases" component={() => <Releases />} />
        <Route path="/verify-email/:email" component={VerifyEmail} />
        <Route path="/verify/:token" component={VerifiedEmail} />
        <Route path="/resetpassword/:token" component={ResetPassword} />
        <Redirect to="/auth" />
      </Switch>;
    }

    if (isMobile) {
      return <Switch>
        <Route path="/auth" component={() => <Auth isMobile={isMobile} />} />
        <Route path="/releases" component={(route) => <Releases route={route} />} />
        <Route path="/operator" component={(route) => <Operator route={route} />} />
        <Route path="/create-organization" component={CreateOrg} />
        <Route path="/pages/:pageId"
          render={(route) => <Layout isAdminMode={false} component={Widgets} route={route} isMobile={isMobile} />} />
        <Route path="/pages"
          render={(route) => <Layout isAdminMode={false} component={Pages} route={route} isMobile={isMobile} />} />
        <Route path="/timeline/:pageId"
          render={(route) => <Layout isAdminMode={false} component={PageTimeline} route={route} isMobile={isMobile} />} />
      </Switch>;
    }

    if (isAdmin && isAdminMode) {
      return <Switch>
        <Route path="/auth" component={() => <Auth isMobile={isMobile} />} />
        <Route path="/releases" component={(route) => <Releases route={route} />} />
        <Route path="/admin/device" render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={AdminDevices} route={route} />} />
        <Route path="/admin/notification" render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={NotificationCenter} route={route} />} />
        <Redirect to="/admin/device" />
      </Switch>;
    }

    let memberRoutes = [
      <Route key="route_auth" path="/auth" component={() => <Auth isMobile={isMobile} />} />,
      <Route key="route_releases" path="/releases" component={(route) => <Releases route={route} />} />,
      <Route key="route_create-organization" path="/create-organization" component={CreateOrg} />,
      <Route key="route_pages_id" path="/pages/:pageId"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Widgets} route={route} isMobile={isMobile} />} />,
      <Route key="route_pages" path="/pages"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Pages} route={route} isMobile={isMobile} />} />,
      <Route key="route_timeline_id" path="/timeline/:pageId"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={PageTimeline} route={route} isMobile={isMobile} />} />,
      <Route key="route_floorplan_id" path="/floorplan/:pageId"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={FloorPlan} route={route} isMobile={isMobile} />} />,
      <Route key="route_devices" path="/devices"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Devices} route={route} />} />,
      <Route key="route_team" path="/team"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Team} route={route} />} />,
      <Route key="route_report" path="/report"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Report} route={route} />} />,
      <Route key="route_profile" path="/profile"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Profile} route={route} />} />,
      <Route key="route_asset-tracking" path="/asset-tracking"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={AssetTracking} route={route} />} />,
      <Route key="route_chat" path="/chat"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Chat} route={route} />} />,
      <Route key="route_machine" path="/machine"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Machine} route={route} />} />,
      <Route key="route_device-grouping" path="/device-grouping"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={DeviceGrouping} route={route} />} />,
      <Route key="route_virtual-device" path="/virtual-device"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={VirtualDevice} route={route} />} />,
      <Route key="route_formula_builder" path="/formula-builder"
        render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={FormulaBuilder} route={route} />} />,
    ];
    if (this.state.orgModules && this.state.orgModules?.includes(1)) {
      memberRoutes.push(<Route key="route_production" path="/production" render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Production} route={route} />} />);
    }
    if (this.state.orgModules && this.state.orgModules?.includes(3)) {
      memberRoutes.push(
        <Route key="route_operator" path="/operator" component={(route) => <Operator route={route} />} />,
        <Route key="route_pcs" path="/pcs"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={ProductionDashboard} route={route} />} />,
        <Route key="route_pcs_timeline" path="/pcs-timeline"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={PcsTimeline} route={route} />} />,
        <Route key="route_batch" path="/batch"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={AdvanceProductionBatch} route={route} />} />,
        <Route key="route_structure" path="/structure"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Structure} route={route} />} />,
        <Route key="route_production-plans" path="/production-plans"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={AdvanceProductionPlans} route={route} />} />,
        <Route key="route_actual-production" path="/actual-production"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={AdvanceActualProduction} route={route} />} />,
        <Route key="route_breakdown_log" path="/breakdown-log"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={BreakdownLog} route={route} />} />,
        <Route key="route_scheduler" path="/scheduler"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={PcsScheduler} route={route} />} />,
        <Route key="route_pcs_settings" path="/pcs-settings"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={PcsSettings} route={route} />} />
      );
    }
    if (this.state.orgModules && this.state.orgModules?.includes(5)) {
      memberRoutes.push(
        <Route key="route_master_data" path="/master-data"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={DocumentMasterData} route={route} />} />,
        <Route key="route_document_type" path="/document-type"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={NoteType} route={route} />} />,
        <Route key="route_documents" path="/documents"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Documents} route={route} />} />
      );
    }

    if (this.state.orgModules && this.state.orgModules?.includes(6)) {
      memberRoutes.push(
        <Route key="route_anomaly_detection" path="/anomaly-detection"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={AnomalyDetection} route={route} />} />,
      );
    }

    if (this.state.orgModules && this.state.orgModules?.includes(7)) {
      memberRoutes.push(
        <Route key="route_energy" path="/energy"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Energy} route={route} />} />,
      );
    }

    if (isOrgAdmin || isAdmin) {
      memberRoutes.push(
        <Route key="route_members" path="/members"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Members} route={route} />} />,
        <Route key="route_event" path="/event"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Rule} route={route} />} />,
        <Route key="route_hook" path="/hook"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={Hook} route={route} />} />,
        <Route key="route_notification" path="/notification"
          render={(route) => <Layout isAdminMode={isAdminMode} onToggleAdminMode={this.onToggleAdminMode} component={NotificationCenter} route={route} />} />,
      );
    }
    if (this.state.orgModules) {
      memberRoutes.push(<Redirect key="redirect_pages" to="/pages" />)
    }
    return <Switch>{memberRoutes}</Switch>;
  }
}

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    currentOrg: state.org.currentOrg,
    orgs: state.org.orgs,
  };
};

export default withRouter(connect(mapStateToProps, { renewToken, signout, getOrgs })(App));