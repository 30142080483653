import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import styles from './EnergyDashboard.module.scss';
import DailyEnergyBar from '../DailyEnergyBar/DailyEnergyBar';
import UsageEnergyLine from '../UsageEnergyLine/UsageEnergyLine';
import DailyReport from '../Reports/DailyReport';
import MonthlyReport from '../Reports/MonthlyReport';
import Button from "../../UI/Button/Button";
import Modal from '../../UI/Modal/Modal';
import DeviceRelations from '../../DeviceGrouping/DeviceRelations/DeviceRelations';
import energy1Icon from '../../../../assets/images/Energy/energy1.png';
import energy2Icon from '../../../../assets/images/Energy/energy2.png';

import { getDeviceGroupings } from '../../../../state/ducks/DeviceGrouping/actions';
import { getSchedulers } from '../../../../state/ducks/Scheduler/actions';
import { getPowers, getAwaitingPowers, getPowersJson, clearReqResCount } from '../../../../state/ducks/Power/actions';
import { getMachines } from '../../../../state/ducks/Machine/actions';
import { getDevices } from '../../../../state/ducks/Devices/actions';
import PageLoader from '../../UI/PageLoader/PageLoader';
import { SnackBar } from '../../UI/SnackBar/SnackBar';

const colourStyles = {
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
};

const START_SHIFT_TIME = '07:30:00';

class EnergyDashboard extends Component {
  snackbarRef = React.createRef();
  coverReportRef = React.createRef();
  state = {
    currentTabIndex: 0,
    selectedDate: '',
    selectedMonth: '',
    showingDate: null,
    relationsOption: [],
    childsOption: [],
    selectedRelation: null,
    selectedChild: [],
    amountLeaves: 0,
    allChildLeaves: 0,
    queryPeriod: 'd',
    lastestShowingDate: null,
    isLtModalOpen: false,
    relationsData: {},
    downloadData: 0,
    isReportModalOpen: false,
    parentEvaTimes: null,
    childEvaTimes: [],
    dailyReportType: null,
    isDailyReportTypeModalOpen: false,
    allRawDataset: null,
    groupMachines: null,
    isPageLoading: false,
    reportProcessing: false
  }

  componentDidMount() {
    this.props.getDevices(this.props.currentOrg, true);
    this.initData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentOrg !== prevProps.currentOrg && this.props.currentOrg) {
      this.initData();
      this.props.getDevices(this.props.currentOrg, true);
      this.setState({ selectedRelation: null, relationsOption: [], selectedChild: [], amountLeaves: 0, allChildLeaves: 0, queryPeriod: 'd', relationsData: {} });
    } else if (prevProps.deviceGroupings !== this.props.deviceGroupings && this.props.deviceGroupings) {
      const relationsOption = [];
      this.props.deviceGroupings.forEach(dg => {
        relationsOption.push({
          id: dg.id,
          label: dg.relation_ref,
          children: dg.payload.children
        });
      });
      this.setState({ relationsOption });
    } else if (prevProps.currentTab !== this.props.currentTab && (this.props.currentTab || this.props.currentTab === 0)) {
      if (this.props.currentTab === 1 && this.state.queryPeriod === 'd') {
        this.onChangePeriodHandler('m');
        this.callDataForCharts(this.checkLastSelectingNode(), null, 'm');
      } else if (this.props.currentTab === 1) {
        this.callDataForCharts(this.checkLastSelectingNode());
      } else {
        this.callDataForCharts(this.checkLastSelectingNode());
      }
    } else if (prevProps.powerError !== this.props.powerError && this.props.powerError) {
      this.snackbarRef.current.openSnackBar(this.props.powerError);
      setTimeout(() => {
        this.setState({ isPageLoading: false, isReportModalOpen: false, isDailyReportTypeModalOpen: false });
      }, 1000);
    } else if (prevProps.powers !== this.props.powers && this.props.powers) {
      if (this.props.awaitingRequests[this.props.responseCount - 1]) {
        this.props.getAwaitingPowers(this.props.awaitingRequests[this.props.responseCount - 1].req, this.props.awaitingRequests[this.props.responseCount - 1].props);
      } else {
        if (this.props.requestCount - 1 === this.props.responseCount) {
          this.props.clearReqResCount();
        }
      }
    } else if (prevProps.powersJSON !== this.props.powersJSON && this.props.powersJSON) {
      if (!this.props.awaitingRequests[this.props.responseCount - 1]) {
        if (this.props.requestCount - 1 === this.props.responseCount) {
          this.props.clearReqResCount();
          this.setState({ reportProcessing: true });
        }
      }
    }
  }

  checkLastSelectingNode = () => {
    let sendingData = { ...this.state.selectedRelation };
    if (this.state.selectedChild && this.state.selectedChild.length && this.state.selectedChild[0]) {
      function lastAvailable(data, c) {
        if (!data[c])
          return lastAvailable(data, c - 1);
        else return c;
      }
      let lastIndex = lastAvailable(this.state.selectedChild, this.state.selectedChild.length - 1);
      sendingData = this.state.selectedChild[lastIndex];
    }
    return sendingData;
  }

  initData = () => {
    // const mockDate = '2022-01-06 00:00';
    const today = moment().format("DD MMMM YYYY");
    const todayTextForInput = moment().format("YYYY-MM-DD");
    const monthTextForInput = moment().format("YYYY-MM");
    this.setState({ showingDate: today, lastestShowingDate: todayTextForInput, selectedDate: todayTextForInput, selectedMonth: monthTextForInput });
    this.props.getDeviceGroupings(this.props.currentOrg);

    let start = moment(today + ' ' + START_SHIFT_TIME).valueOf();
    let end;
    if (moment(today).valueOf() >= start) {
      end = start + (24 * 60 * 60 * 1000) - 1000;
    } else {
      end = start;
      start = end - (24 * 60 * 60 * 1000) + 1000;
    }
    this.props.getMachines(this.props.currentOrg);
    this.props.getSchedulers(this.props.currentOrg, start, end);
  }

  onChangeNavigationHandler = (which, value, index) => {
    if (which === 'relations') {
      if (this.state.selectedRelation && this.state.selectedRelation === value) return;

      if (this.props.deviceGroupings) {
        const foundRelation = this.props.deviceGroupings.find(dg => dg.id === value.id);
        this.setState({ relationsData: foundRelation.payload });
      }
      let childsOption = [];
      if (value && value.children) {
        this.nextPagesRef = [];
        childsOption[0] = [];
        value.children.forEach((c, i) => {
          this.nextPagesRef[i] = React.createRef();
          childsOption[0].push({
            id: c.id,
            label: c.value.title,
            itsId: c.itsId,
            isMachine: c.isMachine,
            isDevice: c.isDevice,
            children: c.children,
            secret: c.secret,
            socket: c.socket
          });
        });
      }
      this.callDataForCharts(value);
      this.setState({ selectedRelation: value, childsOption, selectedChild: [], allChildLeaves: 0 });
    } else {
      let selectedChild = [...this.state.selectedChild];
      if (selectedChild && selectedChild[index] && selectedChild[index] === value) return;

      selectedChild[index] = value;
      let childsOption = [...this.state.childsOption];
      if (this.props.currentTab !== 1) {
        let machineId = '';
        if (value && value.isMachine) {
          machineId = value.itsId;
        }
        if (value && value.children && value.children.length) {
          childsOption[index + 1] = [];
          value.children.forEach(c => {
            childsOption[index + 1].push({
              id: c.id,
              label: c.value.title,
              itsId: c.itsId,
              machineId: machineId,
              isMachine: c.isMachine,
              isDevice: c.isDevice,
              children: c.children,
              secret: c.secret,
              socket: c.socket
            });
          });
        } else {
          if (childsOption[index + 1]) {
            childsOption.splice(index + 1, childsOption.length - 1);
          }
        }
        if (selectedChild.length - 1 > index) {
          selectedChild.splice(index + 1, selectedChild.length - 1);
          childsOption.splice(index + 2, childsOption.length - 1);
        }
      }
      this.setState({ selectedChild, childsOption });

      if (value) {
        this.callDataForCharts(value);
      } else {
        // because: clear machine || device
        if (!selectedChild.length || !selectedChild[0]) {
          this.callDataForCharts(this.state.selectedRelation);
        } else {
          const foundCancelIndex = selectedChild.findIndex(sc => !sc);
          this.callDataForCharts(selectedChild[foundCancelIndex - 1]);
        }
      }
    }
  }

  callDataForCharts = (relations, date, period) => {
    if (!relations || !Object.keys(relations).length) return;

    // TO-FIX : ONLY CASE TOYOTA , according to 3 levels of grouping
    // function getLeafNodes(data) {
    //   const leafNodes = [];

    //   function traverse(node) {
    //     if (node.children.length === 0) {
    //       leafNodes.push(node);
    //     } else {
    //       for (let i = 0; i < node.children.length; i++) {
    //         traverse(node.children[i]);
    //       }
    //     }
    //   }
    //   traverse(data);
    //   return leafNodes;
    // }
    function getChildren(parent) {
      if (parent.children && parent.children.length) {
        return getChildren(parent.children);
      } else {
        return parent;
      }
    }
    let start, end;
    let startAprLastYear, endMarThisYear;
    let summarize;
    let allChild = false;
    let order = 1;
    let currentPeriod = period ? period : this.state.queryPeriod;
    const usingDate = date ? date : this.state.selectedDate;
    if (currentPeriod === 'd') {
      summarize = 10 * 60;
      start = moment(usingDate + ' ' + START_SHIFT_TIME).valueOf();
      end = start + (24 * 60 * 60 * 1000) - 1000;
      this.props.getSchedulers(this.props.currentOrg, start, end);
    } else if (currentPeriod === 'm') {
      summarize = 24 * 60 * 60;
      start = moment(usingDate).startOf('month').add(7, 'hours').add(30, 'minutes').valueOf() + 1000;
      end = moment(usingDate).endOf('month').add(7, 'hours').add(30, 'minutes').valueOf();
      this.props.getSchedulers(this.props.currentOrg, start, end);
    } else if (currentPeriod === 'y') {
      summarize = 1;
      start = moment(usingDate).set('month', 3).set('year', moment(usingDate).get('years')).startOf('month').valueOf(); // 1 APR, this year
      end = moment(usingDate).set('month', 2).set('year', moment(usingDate).get('years') + 1).endOf('month').valueOf(); // 1 MAR, next year
      startAprLastYear = moment(usingDate).set('month', 3).set('year', moment(usingDate).get('years') - 1).startOf('month').valueOf();
      endMarThisYear = moment(usingDate).set('month', 2).set('year', moment(usingDate).get('years')).endOf('month').valueOf();
      this.props.getSchedulers(this.props.currentOrg, start, end);
    }

    let machineId = '';
    if (relations.isMachine) {
      machineId = relations.itsId;
    } else if (relations.isDevice) {
      machineId = relations.machineId;
    }
    let groupMachines = [];
    if (relations.children && relations.children.length) {
      relations.children.forEach((p, i) => {
        groupMachines[i] = [];
        // console.log(getLeafNodes(p))
        const leaves = getChildren(p);
        let machine_id = 0;
        if (p.isMachine) {
          machine_id = p.itsId;
        }
        if (leaves && Array.isArray(leaves)) {
          allChild = true;
          leaves.forEach(ch => {
            if (currentPeriod !== 'y') this.props.getPowers(ch.secret, ch.socket, start, end, summarize, 'sec', machine_id, true, { infos: ch, order, group: i, machineId: machine_id });
            else {
              this.props.getPowers(ch.secret, ch.socket, start, end, summarize, 'month', machine_id, true, { infos: ch, order, group: i, machineId: machine_id });
              if (this.props.currentTab === 1) this.props.getPowers(ch.secret, ch.socket, startAprLastYear, endMarThisYear, summarize, 'month', machine_id, true, { infos: ch, order, group: i, avgEfficiencyLastYear: true, machineId: machine_id });
            }
            groupMachines[i].push({ infos: ch, order, group: i, machineId: machine_id });
            order++;
          });
          // this.prepareToCallPowersJson(start, end, groupMachines[i], i);
          // this.callingInterval(leaves, summarize, machine_id, i);
        } else {
          // level: machine
          if (currentPeriod !== 'y') this.props.getPowers(leaves.secret, leaves.socket, start, end, summarize, 'sec', machineId, true, { infos: leaves, order, machineId });
          else {
            this.props.getPowers(leaves.secret, leaves.socket, start, end, summarize, 'month', machineId, true, { infos: leaves, order, machineId });
            if (this.props.currentTab === 1) this.props.getPowers(leaves.secret, leaves.socket, startAprLastYear, endMarThisYear, summarize, 'month', machineId, true, { infos: leaves, order, avgEfficiencyLastYear: true, machineId });
          }
          order++;
        }
      });
    } else {
      // level: device
      if (currentPeriod !== 'y') this.props.getPowers(relations.secret, relations.socket, start, end, summarize, 'sec', machineId, true, { infos: relations, order });
      else this.props.getPowers(relations.secret, relations.socket, start, end, summarize, 'month', machineId, true, { infos: relations, order });
      order++;
    }

    if (allChild) this.setState({ allChildLeaves: order, groupMachines });
    this.setState({ amountLeaves: order, isPageLoading: true });
  }

  // prepareToCallPowersJson = (start, end, devices, i) => {
  //   let body = {};
  //   body.org_id = this.props.currentOrg;
  //   body.start = start;
  //   body.end = end;
  //   body.range = 1;
  //   body.unit = 'month';
  //   body.need_eff = true;
  //   body.filters = [];
  //   devices.forEach(device => {
  //     const { infos, machineId, order } = device;
  //     body.filters.push({
  //       secret: infos.secret, socket: infos.socket, machine_id: machineId, ref: i + '_' + order
  //     });
  //   });
  //   this.props.getPowersJson(body, { group: i });
  // }

  // INTERVAL 10m ABOUT THIS.PROPS.GETPOWERS();
  // callingInterval = (items, summarize, machine_id, groupIndex) => {
  //   if (this.isToday()) {
  //     let timeNow = moment().format('HH:mm');
  //     let floorStartMin = (timeNow.split(":")[1])[0] + '0';
  //     let floorEndMin = (timeNow.split(":")[1])[0] + '9';
  //     let start = new Date().setHours(timeNow.split(":")[0], floorStartMin, 0, 0);
  //     let end = new Date().setHours(timeNow.split(":")[0], floorEndMin, 59, 0);
  //     let timeLeftForFirstNextData = end - new Date().getTime();
  //     setTimeout(() => {
  //       if (items && Array.isArray(items)) {
  //         items.forEach(item => {
  //           this.props.getPowers(item.secret, item.socket, start, end, summarize, 'sec', machine_id, false, { infos: item, group: groupIndex, rt: true });
  //         });
  //       }
  //     }, timeLeftForFirstNextData);
  //   }
  // }

  isToday = () => {
    if (this.state.queryPeriod !== 'd') return false;
    return moment(this.state.selectedDate).isSame(moment(), 'day');
  }

  goToDate = (when) => {
    let changedDate = moment(this.state.selectedDate);
    if (when === 'next') {
      if (this.state.queryPeriod === 'd') changedDate = changedDate.add(1, 'day');
      else if (this.state.queryPeriod === 'm') changedDate = changedDate.add(1, 'month');
      else if (this.state.queryPeriod === 'y') changedDate = changedDate.add(1, 'year');
    } else if (when === 'prev') {
      if (this.state.queryPeriod === 'd') changedDate = changedDate.subtract(1, 'day');
      else if (this.state.queryPeriod === 'm') changedDate = changedDate.subtract(1, 'month');
      else if (this.state.queryPeriod === 'y') changedDate = changedDate.subtract(1, 'year');
    } else if (when === 'today') {
      changedDate = moment();
    }
    let updatedShowingDate = changedDate.format("DD MMMM YYYY");
    if (this.state.queryPeriod === 'm') {
      updatedShowingDate = changedDate.format("MMMM YYYY");
    } else if (this.state.queryPeriod === 'y') {
      updatedShowingDate = changedDate.format("YYYY");
    }
    let sendingData = { ...this.state.selectedRelation };
    if (this.state.selectedChild && this.state.selectedChild.length && this.state.selectedChild[0]) {
      function lastAvailable(data, c) {
        if (!data[c])
          return lastAvailable(data, c - 1);
        else return c;
      }
      let lastIndex = lastAvailable(this.state.selectedChild, this.state.selectedChild.length - 1);
      sendingData = this.state.selectedChild[lastIndex];
    }
    this.callDataForCharts(sendingData, changedDate.format("YYYY-MM-DD"));
    this.setState({ selectedDate: changedDate.format("YYYY-MM-DD"), showingDate: updatedShowingDate, lastestShowingDate: changedDate.format("YYYY-MM-DD"), selectedMonth: changedDate.format("YYYY-MM") })
  }

  handleDateChange = (e) => {
    const { value } = e.target;
    if (value) {
      let sendingData = { ...this.state.selectedRelation };
      if (this.state.selectedChild && this.state.selectedChild.length && this.state.selectedChild[0]) {
        function lastAvailable(data, c) {
          if (!data[c])
            return lastAvailable(data, c - 1);
          else return c;
        }
        let lastIndex = lastAvailable(this.state.selectedChild, this.state.selectedChild.length - 1);
        sendingData = this.state.selectedChild[lastIndex];
      }
      this.callDataForCharts(sendingData, value);

      let updatedShowingDate = moment(value).format("DD MMMM YYYY");
      if (this.state.queryPeriod === 'd') {
        this.setState({ selectedDate: value, showingDate: updatedShowingDate, lastestShowingDate: value });
      } else if (this.state.queryPeriod === 'm') {
        updatedShowingDate = moment(value).format("MMMM YYYY");
        const tempNewDate = value + '-' + moment(this.state.selectedDate).date();
        let updatedSelectedDate = tempNewDate;
        if (!moment(tempNewDate).isValid()) {
          const firstDateInMonth = value + '-01';
          updatedSelectedDate = moment(firstDateInMonth).endOf('month').format('YYYY-MM-DD');
        }
        this.setState({ showingDate: updatedShowingDate, selectedMonth: value, selectedDate: updatedSelectedDate, lastestShowingDate: updatedSelectedDate });
      }
    }
  }

  onChangePeriodHandler = (period) => {
    if (period === this.state.queryPeriod) return;
    let current;
    if (this.state.selectedChild && this.state.selectedChild.length && this.state.selectedChild[0]) {
      const child = this.state.selectedChild[this.state.selectedChild.length - 1] ? this.state.selectedChild[this.state.selectedChild.length - 1] : this.state.selectedChild[0];
      current = { ...child };
    } else {
      current = { ...this.state.selectedRelation };
    }

    let updatedShowingDate = moment(this.state.selectedDate);
    if (period === 'y') {
      updatedShowingDate = updatedShowingDate.format("YYYY");
    } else if (period === 'm') {
      updatedShowingDate = updatedShowingDate.format("MMMM YYYY");
    } else if (period === 'd' && this.state.queryPeriod === 'm') {
      updatedShowingDate = moment(this.state.lastestShowingDate).format("DD MMMM YYYY");
    } else if (period === 'd') {
      updatedShowingDate = updatedShowingDate.format("DD MMMM YYYY");
    }

    this.callDataForCharts(current, null, period);
    this.setState({ queryPeriod: period, showingDate: updatedShowingDate });
  }

  isOnDeviceLevel = () => {
    if (this.state.selectedChild && this.state.selectedChild.length) {
      if (this.state.selectedChild[0] && this.state.selectedChild[1]) {
        return true;
      }
    }
    return false;
  }

  renderShowingDate = () => {
    if (this.state.queryPeriod !== 'd') return this.state.showingDate;
    let selectedDate = moment(this.state.selectedDate);
    if (selectedDate.isSame(moment(), 'day')) {
      let timeNow = moment().format('HH:mm');
      const hour = +timeNow.split(":")[0];
      const min = +timeNow.split(":")[1]
      if (hour >= 19 || hour <= 7) {
        if (hour === 7 && min >= 30) {
          return 'Today, ' + this.state.showingDate;
        } else if (hour === 19 && min < 30) {
          return 'Today, ' + this.state.showingDate;
        } else if (hour < 24) {
          return 'Night shift, ' + this.state.showingDate;
        } else {
          // this.state.showingDate should be 1 day behind from now
          const nightShiftDate = selectedDate.subtract(1, 'day').format('DD MMM YYYY');
          return 'Night shift, ' + nightShiftDate;
        }
      } else {
        return 'Today, ' + this.state.showingDate;
      }
    }
    return this.state.showingDate;
  }

  showLevelsTreeHandler = () => {
    this.setState({ isLtModalOpen: true });
  }

  ltmodalClosed = () => {
    this.setState(prevState => {
      return { isLtModalOpen: !prevState.isLtModalOpen };
    });
  }

  onDownloadDataHandler = () => {
    if (!this.state.selectedRelation || !Object.keys(this.state.selectedRelation).length) return;

    this.setState(prevState => {
      return {
        downloadData: prevState.downloadData + 1
      }
    });
  }

  reportModalClosed = () => {
    this.setState(prevState => {
      return { isReportModalOpen: !prevState.isReportModalOpen };
    });
  }

  onCreateReportHandler = () => {
    if (this.state.queryPeriod === 'd') this.setState({ isDailyReportTypeModalOpen: true });
    else this.setState({ isReportModalOpen: true });
  }

  exportReport = async (name) => {
    const pdf = new jsPDF({ orientation: 'landscape' });
    const pdfWidth = pdf.internal.pageSize.getWidth();

    const source = this.coverReportRef.current;
    const canvas = await html2canvas(source, { useCORS: true });
    const data = canvas.toDataURL('image/png');
    const imgProperties = pdf.getImageProperties(data);
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);

    if (this.state.childsOption[0] && this.state.childsOption[0].length) {
      await Promise.all(this.state.childsOption[0].map(async (co, i) => {
        const childSource = this.nextPagesRef[i].current;
        const childCanvas = await html2canvas(childSource, { useCORS: true });
        const childData = childCanvas.toDataURL('image/png');
        const childImgProperties = pdf.getImageProperties(childData);
        const pdfHeight = (childImgProperties.height * pdfWidth) / childImgProperties.width;
        pdf.addPage();
        pdf.addImage(childData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      }));
    }
    pdf.save(`${name} Report_${this.state.selectedRelation.label}_${moment(this.state.selectedDate).format('DDMMYYYY')}.pdf`);
    this.setState({ isReportModalOpen: false, dailyReportType: null, isPageLoading: false, reportProcessing: false });
  }

  onSetEvaTimesHandler = (which, evaTimes, i) => {
    if (which === 'parent') this.setState({ parentEvaTimes: evaTimes });
    else {
      this.setState((prevState) => {
        const childEvaTimes = [...prevState.childEvaTimes];
        childEvaTimes[i] = evaTimes;
        return { childEvaTimes };
      });
    }
  }

  dailyReportTypeModalClosed = () => {
    this.setState(prevState => {
      return { isDailyReportTypeModalOpen: !prevState.isDailyReportTypeModalOpen, dailyReportType: null };
    });
  }

  onSelectDailyReportTypeHandler = (which) => {
    setTimeout(() => {
      this.exportReport('Daily');
    }, 500);
    this.setState({ dailyReportType: which, isDailyReportTypeModalOpen: false, isReportModalOpen: true, isPageLoading: true }); //, isPageLoading: true
  }

  monthlyExportHandler = () => {
    setTimeout(() => {
      this.exportReport('Monthly');
    }, 500);
    this.setState({ isPageLoading: true });
  }

  render() {

    return (
      <div className={styles.EnergyDashboard}>
        <SnackBar ref={this.snackbarRef} type={'failed'} neverDisappear />
        <PageLoader show={this.state.isPageLoading} percent={this.props.responseCount * 100 / this.props.requestCount} text={this.state.reportProcessing ? 'Downloading...' : null} />
        <Modal show={this.state.isDailyReportTypeModalOpen} modalClosed={this.dailyReportTypeModalClosed} overideStyles={{ height: 180, width: 550, minHeight: 'auto', color: 'black', overflow: 'hidden' }}>
          <div className={styles.DailyReportTypeModal}>
            <div className={styles.Title}>Create Daily Report</div>
            <div className={styles.ButtonsWrapper}>
              <Button
                active={this.state.queryPeriod === 'd'}
                type="button"
                name={'Standard Form'}
                color="primary"
                overideButtonStyles={{ marginBottom: 0, width: 180, marginRight: 20 }}
                click={() => this.onSelectDailyReportTypeHandler('standard')}
              />
              <Button
                active={this.state.queryPeriod === 'd'}
                type="button"
                name={'Form by Env.'}
                color="green"
                overideButtonStyles={{ marginBottom: 0, width: 180 }}
                click={() => this.onSelectDailyReportTypeHandler('env')}
              />
            </div>
          </div>
        </Modal>
        <Modal noCloseButton show={this.state.isReportModalOpen} modalClosed={this.reportModalClosed} overideStyles={{ height: '90%', width: '90%', minHeight: 'auto', color: 'black', overflow: 'auto' }}>
          {
            this.state.dailyReportType && this.state.isReportModalOpen && this.state.queryPeriod === 'd'
              ? <DailyReport
                allRawDataset={this.state.allRawDataset}
                amountLeaves={this.state.amountLeaves}
                allChildLeaves={this.state.allChildLeaves - 1}
                machines={this.props.machines}
                selectedRelation={this.state.selectedRelation}
                selectedChild={this.state.selectedChild}
                queryPeriod={this.state.queryPeriod}
                selectedDate={this.state.selectedDate}
                isEfficiency={this.props.currentTab === 1}
                setEvaTimes={this.onSetEvaTimesHandler}
                parentEvaTimes={this.state.parentEvaTimes}
                childsOption={this.state.childsOption}
                isStandardForm={this.state.dailyReportType === 'standard'}
                nextPagesRef={this.nextPagesRef}
                coverReportRef={this.coverReportRef}
                childEvaTimes={this.state.childEvaTimes}
                doneLoading={() => this.setState({ isPageLoading: false })}
              />
              : null
          }
          {
            this.state.queryPeriod === 'm' && this.state.isReportModalOpen
              ? <MonthlyReport
                allRawDataset={this.state.allRawDataset}
                amountLeaves={this.state.amountLeaves - 1}
                allChildLeaves={this.state.allChildLeaves}
                groupMachines={this.state.groupMachines}
                machines={this.props.machines}
                selectedRelation={this.state.selectedRelation}
                selectedChild={this.state.selectedChild}
                queryPeriod={this.state.queryPeriod}
                selectedDate={this.state.selectedDate}
                isEfficiency={this.props.currentTab === 1}
                setEvaTimes={this.onSetEvaTimesHandler}
                parentEvaTimes={this.state.parentEvaTimes}
                childsOption={this.state.childsOption}
                nextPagesRef={this.nextPagesRef}
                coverReportRef={this.coverReportRef}
                readyToExport={this.monthlyExportHandler}
                doneLoading={() => this.setState({ isPageLoading: false })}
                showPageLoading={() => this.setState({ isPageLoading: true })}
              />
              : null
          }
        </Modal>
        <Modal show={this.state.isLtModalOpen} modalClosed={this.ltmodalClosed} overideStyles={{ height: '80%', width: '50%', backgroundColor: '#1c1c1f', minHeight: 'auto' }}>
          {
            this.state.relationsData && Object.keys(this.state.relationsData).length
              ? <DeviceRelations noActions noToolbar data={this.state.relationsData} overideStyles={{ paddingLeft: 0 }} />
              : null
          }
        </Modal>
        <div className={styles.TopSection}>
          <div className={styles.DateWrapper}>
            <div className={styles.ShowingDate}>{this.renderShowingDate()}</div>
            <div className={styles.ActionForDate} onClick={() => this.goToDate('prev')}>Previous {this.state.queryPeriod === 'd' ? 'day' : this.state.queryPeriod === 'm' ? 'month' : 'year'}</div>
            <div className={styles.ActionForDate} onClick={() => this.goToDate('next')}>Next {this.state.queryPeriod === 'd' ? 'day' : this.state.queryPeriod === 'm' ? 'month' : 'year'}</div>

            <div style={{ position: 'relative' }}>
              {this.state.queryPeriod !== 'y' && <label htmlFor="date-select" className={styles.ActionForDate}>Go to {this.state.queryPeriod === 'd' ? 'date' : this.state.queryPeriod === 'm' ? 'month' : 'year'}</label>}
              <div className={styles.SelectedDateWrapper}>
                {
                  this.state.queryPeriod === 'd'
                    ? <input
                      id="date-select"
                      className={styles.SelectedDate}
                      type="date"
                      onChange={this.handleDateChange}
                      name="selectedDate"
                      value={this.state.selectedDate}
                    />
                    : <input
                      id="date-select"
                      className={styles.SelectedDate}
                      type="month"
                      onChange={this.handleDateChange}
                      name="selectedMonth"
                      value={this.state.selectedMonth}
                    />
                }
              </div>
            </div>
            {!this.isToday() && this.state.queryPeriod === 'd' ? <div className={styles.ActionForDate} onClick={() => this.goToDate('today')}>Today</div> : null}
          </div>
          <div>
            {
              this.props.currentTab !== 1
                ? <Button
                  active={this.state.queryPeriod === 'd'}
                  type="button"
                  name={'Day'}
                  color="gray"
                  overideButtonStyles={{ marginBottom: 0, width: 90, marginRight: 10 }}
                  click={() => this.onChangePeriodHandler('d')}
                />
                : null
            }
            <Button
              active={this.state.queryPeriod === 'm'}
              type="button"
              name={'Month'}
              color="gray"
              overideButtonStyles={{ marginBottom: 0, width: 90, marginRight: 10 }}
              click={() => this.onChangePeriodHandler('m')}
            />
            <Button
              active={this.state.queryPeriod === 'y'}
              type="button"
              name={'Year'}
              color="gray"
              overideButtonStyles={{ marginBottom: 0, width: 90 }}
              click={() => this.onChangePeriodHandler('y')}
            />
          </div>
        </div>
        <div className={styles.TopSection}>
          <div className={styles.NavigationSelectionSection}>
            <div className={styles.NavigationWrapper}>
              <div style={{ width: 200 }}>
                <Select isSearchable options={this.state.relationsOption} styles={colourStyles}
                  placeholder="Select Plant"
                  value={this.state.selectedRelation ? this.state.selectedRelation : ''}
                  onChange={(value) => this.onChangeNavigationHandler('relations', value)}
                  getOptionValue={opt => opt.id}
                />
              </div>
            </div>
            {
              this.state.selectedRelation
                ? this.state.childsOption.map((co, i) => (
                  <React.Fragment key={`${co.id}-${i}`}>
                    <div className={styles.NextChild}>{`>`}</div>
                    <div className={styles.NavigationWrapper}>
                      <div style={{ width: 200 }}>
                        <Select isSearchable isClearable options={co} styles={colourStyles}
                          placeholder="Next"
                          value={this.state.selectedChild[i] ? this.state.selectedChild[i] : ''}
                          onChange={(value) => this.onChangeNavigationHandler('child', value, i)}
                          getOptionValue={opt => opt.id}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))
                : null
            }
            {
              this.state.relationsData && Object.keys(this.state.relationsData).length
                ? <div className={styles.SeeLevels} onClick={this.showLevelsTreeHandler}>See levels tree</div>
                : null
            }
          </div>
          {
            this.state.selectedRelation
              ? <div>
                {
                  this.state.queryPeriod !== 'y' && this.state.selectedChild && (!this.state.selectedChild.length || !this.state.selectedChild[0])
                    ? <Button
                      active
                      type="button"
                      name={this.state.queryPeriod === 'd' ? 'Create Daily Report' : 'Create Monthly Report'}
                      color="primary"
                      overideButtonStyles={{ marginBottom: 0, width: 200, marginRight: 10 }}
                      click={this.onCreateReportHandler}
                    />
                    : null
                }
                <Button
                  type="button"
                  name={'Download Data'}
                  color="primary"
                  overideButtonStyles={{ marginBottom: 0, width: 200 }}
                  click={this.onDownloadDataHandler}
                />
              </div>
              : null
          }
        </div>

        {
          this.state.selectedRelation
            ? <>
              <DailyEnergyBar
                amountLeaves={this.state.amountLeaves - 1}
                machines={this.props.machines}
                selectedRelation={this.state.selectedRelation}
                selectedChild={this.state.selectedChild}
                queryPeriod={this.state.queryPeriod}
                selectedDate={this.state.selectedDate}
                isEfficiency={this.props.currentTab === 1}
                downloadData={this.state.downloadData}
                setEvaTimes={this.onSetEvaTimesHandler}
                saveAllRawDataset={value => this.setState({ allRawDataset: value })}
                doneLoading={() => this.setState({ isPageLoading: false })}
              />
              {
                this.isOnDeviceLevel() || this.props.currentTab === 1
                  ? null
                  : <>
                    <div style={{ height: 20 }} />
                    <UsageEnergyLine
                      amountLeaves={this.state.amountLeaves - 1}
                      selectedChild={this.state.selectedChild}
                      selectedRelation={this.state.selectedRelation}
                      queryPeriod={this.state.queryPeriod}
                      selectedDate={this.state.selectedDate}
                    />
                  </>
              }
            </>
            : <div className={styles.LandingText}>
              <div>
                <img src={energy1Icon} style={{ width: 50 }} />
                <img src={energy2Icon} style={{ width: 50, marginLeft: 10 }} />
              </div>
              <div className={styles.Header1}>Welcome to the Toyota Energy Dashboard</div>
              <div className={styles.Header2}>Please select plant to get started.</div>
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { deviceGroupings } = state.deviceGrouping;
  const { powers, powersJSON, responseCount, requestCount, awaitingRequests } = state.power;
  const { machines } = state.machine;

  return {
    currentOrg, deviceGroupings, machines, powerError: state.power.error, powers, powersJSON, responseCount, awaitingRequests, requestCount
  };
};

export default connect(mapStateToProps, { getDevices, getDeviceGroupings, getPowers, getMachines, getSchedulers, getPowersJson, getAwaitingPowers, clearReqResCount })(EnergyDashboard);
