export const GET_RULE_CONDITIONS_START = 'GET_RULE_CONDITIONS_START';
export const GET_RULE_CONDITIONS = 'GET_RULE_CONDITIONS';
export const GET_RULE_CONDITIONS_SUCCESS = 'GET_RULE_CONDITIONS_SUCCESS';
export const GET_RULE_CONDITIONS_FAIL = 'GET_RULE_CONDITIONS_FAIL';

export const ADD_RULE_CONDITION_START = 'ADD_RULE_CONDITION_START';
export const ADD_RULE_CONDITION = 'ADD_RULE_CONDITION';
export const ADD_RULE_CONDITION_SUCCESS = 'ADD_RULE_CONDITION_SUCCESS';
export const ADD_RULE_CONDITION_FAIL = 'ADD_RULE_CONDITION_FAIL';

export const EDIT_RULE_CONDITION_START = 'EDIT_RULE_CONDITION_START';
export const EDIT_RULE_CONDITION = 'EDIT_RULE_CONDITION';
export const EDIT_RULE_CONDITION_SUCCESS = 'EDIT_RULE_CONDITION_SUCCESS';
export const EDIT_RULE_CONDITION_FAIL = 'EDIT_RULE_CONDITION_FAIL';

export const DELETE_RULE_CONDITION_START = 'DELETE_RULE_CONDITION_START';
export const DELETE_RULE_CONDITION = 'DELETE_RULE_CONDITION';
export const DELETE_RULE_CONDITION_SUCCESS = 'DELETE_RULE_CONDITION_SUCCESS';
export const DELETE_RULE_CONDITION_FAIL = 'DELETE_RULE_CONDITION_FAIL';
