import React, { Component } from 'react';

import styles from './AssetTracking.module.scss';
import AssetMap from '../../components/AssetTracking/AssetMap/AssetMap';
import AssetList from '../../components/AssetTracking/AssetList/AssetList';
import AssetFence from '../../components/AssetTracking/AssetFence/AssetFence';

const mockData = [
  {
    asset: '479776990-4',
    status: true,
    location: 'Bangkok',
    last_active: 1568603034107,
  },
  {
    asset: '955970610-1',
    status: false,
    location: 'Wilmington',
    last_active: 1568689434107,
  },
  {
    asset: '835847439-3',
    status: true,
    location: 'Murfreesboro',
    last_active: 1568689434107,
  }
]

class AssetTracking extends Component {
  state = {
    assetType: 'list'
  }

  onTypeChangeHandler = (type) => {
    this.setState({ assetType: type });
  }

  render() {
    const { assetType } = this.state;
    return (
      <div className={styles.AssetTracking}>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            <div className={assetType === 'list' ? styles.ActiveMenu : styles.Menu} onClick={() => this.onTypeChangeHandler('list')}>
              <span className={styles.MenuTitle}>Asset List</span>
            </div>
            <div className={assetType === 'map' ? styles.ActiveMenu : styles.Menu} onClick={() => this.onTypeChangeHandler('map')}>
              <span className={styles.MenuTitle}>Asset Map</span>
            </div>
            <div className={assetType === 'fence' ? styles.ActiveMenu : styles.Menu} onClick={() => this.onTypeChangeHandler('fence')}>
              <span className={styles.MenuTitle}>Asset Fence</span>
            </div>
          </div>
        </div>
        <div className="MainContentPadding">
          {assetType === 'map' ? <AssetMap data={mockData} /> : assetType === 'list' ? <AssetList data={mockData} /> : <AssetFence />}
        </div>
      </div>
    );
  }
}

export default AssetTracking;
