import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  notifications: null,
  addedNotification: null,
  updatedNotification: null,
  inbox: null,
  notificationTopbar: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATIONS_START: return getNotificationsStart(state, action);
    case actionTypes.GET_NOTIFICATIONS_SUCCESS: return getNotificationsSuccess(state, action);
    case actionTypes.GET_NOTIFICATIONS_FAIL: return getNotificationsFail(state, action);

    case actionTypes.ADD_NOTIFICATION_START: return addNotificationStart(state, action);
    case actionTypes.ADD_NOTIFICATION_SUCCESS: return addNotificationSuccess(state, action);
    case actionTypes.ADD_NOTIFICATION_FAIL: return addNotificationFail(state, action);

    case actionTypes.UPDATE_NOTIFICATION_START: return updateNotificationStart(state, action);
    case actionTypes.UPDATE_NOTIFICATION_SUCCESS: return updateNotificationSuccess(state, action);
    case actionTypes.UPDATE_NOTIFICATION_FAIL: return updateNotificationFail(state, action);

    case actionTypes.DELETE_NOTIFICATION_START: return deleteNotificationStart(state, action);
    case actionTypes.DELETE_NOTIFICATION_SUCCESS: return deleteNotificationSuccess(state, action);
    case actionTypes.DELETE_NOTIFICATION_FAIL: return deleteNotificationFail(state, action);

    case actionTypes.GET_NOTIFICATIONS_INBOX_START: return getNotificationsInboxStart(state, action);
    case actionTypes.GET_NOTIFICATIONS_INBOX_SUCCESS: return getNotificationsInboxSuccess(state, action);
    case actionTypes.GET_NOTIFICATIONS_INBOX_FAIL: return getNotificationsInboxFail(state, action);

    case actionTypes.MARK_AS_READ_START: return markAsReadStart(state, action);
    case actionTypes.MARK_AS_READ_SUCCESS: return markAsReadSuccess(state, action);
    case actionTypes.MARK_AS_READ_FAIL: return markAsReadFail(state, action);

    case actionTypes.GET_NOTIFICATION_TOPBAR_START: return getNotificationTopbarStart(state, action);
    case actionTypes.GET_NOTIFICATION_TOPBAR_SUCCESS: return getNotificationTopbarSuccess(state, action);
    case actionTypes.GET_NOTIFICATION_TOPBAR_FAIL: return getNotificationTopbarFail(state, action);

    default: return state;
  }
};

const getNotificationsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getNotificationsSuccess = (state, action) => {
  return updateObject(state, {
    notifications: action.notifications,
    error: null,
    loading: false
  });
}

const getNotificationsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addNotificationStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addNotificationSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedNotification: action.addedNotification,
    error: null,
    loading: false
  });
}

const addNotificationFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const updateNotificationStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const updateNotificationSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedNotification: action.updatedNotification,
    error: null,
    loading: false
  });
}

const updateNotificationFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteNotificationStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const deleteNotificationSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    notifications: action.notifications,
    error: null,
    loading: false
  });
}

const deleteNotificationFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}


const getNotificationsInboxStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getNotificationsInboxSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    inbox: action.inbox,
    error: null,
    loading: false
  });
}

const getNotificationsInboxFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const markAsReadStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const markAsReadSuccess = (state, action) => {
  const updatedInbox = [...state.inbox];
  if (action.inbox && action.inbox.length === 1) {
    const foundIndex = updatedInbox.findIndex(inb => inb.notification_uuid === action.inbox[0].notification_uuid);
    updatedInbox[foundIndex] = action.inbox[0];
  } else {
    action.inbox.forEach(res => {
      const foundIndex = updatedInbox.findIndex(inb => inb.notification_uuid === res.notification_uuid);
      updatedInbox[foundIndex] = res;
    });
  }
  return updateObject(state, {
    type: action.type,
    inbox: updatedInbox,
    error: null,
    loading: false
  });
}

const markAsReadFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getNotificationTopbarStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getNotificationTopbarSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    notificationTopbar: action.notificationTopbar,
    error: null,
    loading: false
  });
}

const getNotificationTopbarFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}


export default reducer;