import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_OEES, ADD_OEE, EDIT_OEE, OEE_APPROVE, DELETE_OEE } from './types';

export function* watchOee() {
  yield takeEvery(GET_OEES, getOeesSaga);
  yield takeEvery(ADD_OEE, addOeeSaga);
  yield takeEvery(EDIT_OEE, editOeeSaga);
  yield takeEvery(OEE_APPROVE, oeeApproveSaga);
  yield takeEvery(DELETE_OEE, deleteOeeSaga);
}

export function* getOeesSaga(params) {
  yield put(actions.getOeesStart());

  let api = 'v1/oee';
  api += '?org_id=' + params.org_id;
  api += params.shift_id ? '&shift_id=' + params.shift_id : '';
  api += params.countingdevices_id ? '&countingdevices_id=' + params.countingdevices_id : '';
  api += params.productionplan_id ? '&productionplan_id=' + params.productionplan_id : '';

  try {
    const response = yield axios.get(api);
    console.log("GET OEES SUCCESS", response.data);
    yield put(actions.getOeesSuccess(response.data));
  } catch (err) {
    console.log("GET OEES FAILED", err)
    yield put(actions.getOeesFail(err.response));
  }
}

export function* addOeeSaga(params) {
  yield put(actions.addOeeStart());

  let postData = "org_id=" + params.org_id;
  postData += "&productionplan_id=" + params.productionplan_id;
  postData += "&actual=" + params.actual;
  postData += "&defect=" + params.defect;
  postData += "&runtime=" + params.runtime;
  postData += params.description ? "&description=" + encodeURIComponent(params.description) : '';

  const api = 'v1/oee';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD OEE SUCCESS", response);
    yield put(actions.addOeeSuccess(response.data));
  } catch (err) {
    console.log("ADD OEE FAILED", err.response.data);
    yield put(actions.addOeeFail(err.response.data.error));
  }
}

export function* editOeeSaga(params) {
  yield put(actions.editOeeStart());

  let putData = params.actual ? "&actual=" + params.actual : '';
  putData += params.defect ? "&defect=" + params.defect : '';
  putData += params.runtime ? "&runtime=" + params.runtime : '';
  putData += params.description ? "&description=" + encodeURIComponent(params.description) : '';


  const api = 'v1/oee/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT OEE SUCCESS", response.data);
    yield put(actions.editOeeSuccess(response.data));
  } catch (err) {
    console.log("EDIT OEE FAILED", err.response.data);
    yield put(actions.editOeeFail(err.response.data));
  }
}

export function* deleteOeeSaga(params) {
  yield put(actions.deleteOeeStart());

  const api = 'v1/oee/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE OEE SUCCESS", response.data);
    yield put(actions.deleteOeeSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE OEE FAILED", err.response);
    yield put(actions.deleteOeeFail(err.response));
  }
}

export function* oeeApproveSaga(params) {
  yield put(actions.oeeApproveStart());

  const api = 'v1/oeeapprove/' + params.id;

  try {
    const response = yield axios.put(api);
    console.log("OEE APPROVE SUCCESS", response.data);
    yield put(actions.oeeApproveSuccess(response.data.result));
  } catch (err) {
    console.log("OEE APPROVE FAILED", err.response.data);
    yield put(actions.oeeApproveFail(err.response.data));
  }
}

