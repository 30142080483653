import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  documentNotes: null,
  updatedDocumentNote: null,
  addedDocumentNote: null,
  noteTypes: null,
  updatedNoteType: null,
  addedNoteType: null,

  documents: null,
  updatedDocument: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DOCUMENT_NOTES_START: return getDocumentNotesStart(state, action);
    case actionTypes.GET_DOCUMENT_NOTES_SUCCESS: return getDocumentNotesSuccess(state, action);
    case actionTypes.GET_DOCUMENT_NOTES_FAIL: return getDocumentNotesFail(state, action);

    case actionTypes.ADD_DOCUMENT_NOTE_START: return addDocumentNoteStart(state, action);
    case actionTypes.ADD_DOCUMENT_NOTE_SUCCESS: return addDocumentNoteSuccess(state, action);
    case actionTypes.ADD_DOCUMENT_NOTE_FAIL: return addDocumentNoteFail(state, action);

    case actionTypes.EDIT_DOCUMENT_NOTE_START: return editDocumentNoteStart(state, action);
    case actionTypes.EDIT_DOCUMENT_NOTE_SUCCESS: return editDocumentNoteSuccess(state, action);
    case actionTypes.EDIT_DOCUMENT_NOTE_FAIL: return editDocumentNoteFail(state, action);

    case actionTypes.DELETE_DOCUMENT_NOTE_START: return deleteDocumentNoteStart(state, action);
    case actionTypes.DELETE_DOCUMENT_NOTE_SUCCESS: return deleteDocumentNoteSuccess(state, action);
    case actionTypes.DELETE_DOCUMENT_NOTE_FAIL: return deleteDocumentNoteFail(state, action);

    case actionTypes.CLEAR_NOTE_TYPES: return clearNoteTypes(state, action);

    case actionTypes.GET_NOTE_TYPES_START: return getNoteTypesStart(state, action);
    case actionTypes.GET_NOTE_TYPES_SUCCESS: return getNoteTypesSuccess(state, action);
    case actionTypes.GET_NOTE_TYPES_FAIL: return getNoteTypesFail(state, action);

    case actionTypes.ADD_NOTE_TYPE_START: return addNoteTypeStart(state, action);
    case actionTypes.ADD_NOTE_TYPE_SUCCESS: return addNoteTypeSuccess(state, action);
    case actionTypes.ADD_NOTE_TYPE_FAIL: return addNoteTypeFail(state, action);

    case actionTypes.EDIT_NOTE_TYPE_START: return editNoteTypeStart(state, action);
    case actionTypes.EDIT_NOTE_TYPE_SUCCESS: return editNoteTypeSuccess(state, action);
    case actionTypes.EDIT_NOTE_TYPE_FAIL: return editNoteTypeFail(state, action);

    case actionTypes.DELETE_NOTE_TYPE_START: return deleteNoteTypeStart(state, action);
    case actionTypes.DELETE_NOTE_TYPE_SUCCESS: return deleteNoteTypeSuccess(state, action);
    case actionTypes.DELETE_NOTE_TYPE_FAIL: return deleteNoteTypeFail(state, action);

    case actionTypes.GET_DOCUMENTS_START:
    case actionTypes.CREATE_DOCUMENT_START:
    case actionTypes.UPDATE_DOCUMENT_START:
    case actionTypes.DELETE_DOCUMENT_START:
      return actionDocumentsStart(state, action);
    case actionTypes.GET_DOCUMENTS_SUCCESS:
      return getDocumentsSuccess(state, action);
    case actionTypes.GET_DOCUMENTS_FAIL:
      return getDocumentsFail(state, action);
    case actionTypes.CREATE_DOCUMENT_SUCCESS:
    case actionTypes.UPDATE_DOCUMENT_SUCCESS:
      return createOrUpdateDocumentSuccess(state, action);
    case actionTypes.CREATE_DOCUMENT_FAIL:
    case actionTypes.UPDATE_DOCUMENT_FAIL:
      return createOrUpdateDocumentFail(state, action);
    case actionTypes.DELETE_DOCUMENT_SUCCESS:
    case actionTypes.DELETE_DOCUMENT_FAIL:
      return deleteDocumentFinish(state, action);

    default: return state;
  }
};

const getDocumentNotesStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getDocumentNotesSuccess = (state, action) => {
  return updateObject(state, {
    documentNotes: action.documentNotes,
    error: null,
    loading: false
  });
}

const getDocumentNotesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addDocumentNoteStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addDocumentNoteSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedDocumentNote: action.addedDocumentNote,
    error: null,
    loading: false
  });
}

const addDocumentNoteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editDocumentNoteStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, updatedDocumentNote: null });
}

const editDocumentNoteSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedDocumentNote: action.updatedDocumentNote,
    error: null,
    loading: false
  });
}

const editDocumentNoteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteDocumentNoteStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteDocumentNoteSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteDocumentNoteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const clearNoteTypes = (state) => {
  return updateObject(state, { noteTypes: null });
}

const getNoteTypesStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getNoteTypesSuccess = (state, action) => {
  return updateObject(state, {
    noteTypes: action.noteTypes,
    error: null,
    loading: false
  });
}

const getNoteTypesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addNoteTypeStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addNoteTypeSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedNoteType: action.addedNoteType,
    error: null,
    loading: false
  });
}

const addNoteTypeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editNoteTypeStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editNoteTypeSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedNoteType: action.updatedNoteType,
    error: null,
    loading: false
  });
}

const editNoteTypeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteNoteTypeStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteNoteTypeSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteNoteTypeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const actionDocumentsStart = (state, action) => {
  return updateObject(state, { type: action.type, result: null, updatedDocument: null, error: null, loading: true });
}

const getDocumentsSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    documents: action.note_documents,
    error: null,
    loading: false
  });
}

const getDocumentsFail = (state, action) => {
  return updateObject(state, {
    type: action.type,
    documents: null,
    result: action.result,
    error: action.error,
    loading: false
  });
}

const createOrUpdateDocumentSuccess = (state, action) => {
  // let documents = state.documents;
  // if (action.type === actionTypes.CREATE_DOCUMENT_SUCCESS)
  //   documents.push(action.note_documents[0]);
  // console.log("new ?", action.note_documents[0])
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedDocument: action.note_documents[0],
    // documents,
    error: null,
    loading: false
  });
}

const createOrUpdateDocumentFail = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedDocument: null,
    error: null,
    loading: false
  });
}

const deleteDocumentFinish = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: action.error,
    loading: false
  });
}

export default reducer;