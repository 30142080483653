import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import styles from './DeviceGrouping.module.scss';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import Button from "../../components/UI/Button/Button";
import DeviceRelations from '../../components/DeviceGrouping/DeviceRelations/DeviceRelations';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import RelationsDrawer from '../../components/DeviceGrouping/RelationsDrawer/RelationsDrawer';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';

import { getMachines } from '../../../state/ducks/Machine/actions';
import { getDevices } from '../../../state/ducks/Devices/actions';
import { getDeviceGroupings, addDeviceGrouping, editDeviceGrouping, deleteDeviceGrouping } from '../../../state/ducks/DeviceGrouping/actions';
import { DELETE_DEVICE_GROUPING_SUCCESS } from '../../../state/ducks/DeviceGrouping/types';

class DeviceGrouping extends Component {
  state = {
    currentTabIndex: 0,
    isDrawerOpened: false,
    editingNode: null,
    relationsData: {},
    isDeleteNodeModalOpened: false,
    isAdding: false,
    isDeletingRelations: false
  };

  componentDidMount() {
    this.props.getMachines(this.props.currentOrg);
    this.props.getDevices(this.props.currentOrg, true);
    this.props.getDeviceGroupings(this.props.currentOrg);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg) {
      this.props.getMachines(this.props.currentOrg);
      this.props.getDevices(this.props.currentOrg, true);
      this.props.getDeviceGroupings(this.props.currentOrg);
    } else if (prevProps.deviceGroupings !== this.props.deviceGroupings && this.props.deviceGroupings && this.props.deviceGroupings.length) {
      let index = this.state.currentTabIndex;
      if (this.state.currentTabIndex === -1) {
        // which means added!
        index = this.props.deviceGroupings.length - 1;
      }
      let relationsData = {};
      if (this.props.deviceGroupings[index]) {
        relationsData = this.props.deviceGroupings[index].payload;
      } else if (this.props.deviceGroupings[0]) {
        index = 0;
        relationsData = this.props.deviceGroupings[0].payload;
      }
      this.setState({ relationsData, currentTabIndex: index });
    } else if (this.props.result !== prevProps.result && this.props.result === 'success') {
      if (this.props.addedDeviceGrouping !== prevProps.addedDeviceGrouping || this.props.updatedDeviceGrouping !== prevProps.updatedDeviceGrouping || this.props.type === DELETE_DEVICE_GROUPING_SUCCESS) {
        this.props.getDeviceGroupings(this.props.currentOrg);
      }
    }
  }

  onChangeRelationsHandler = (index) => {
    this.setState({ currentTabIndex: index, relationsData: this.props.deviceGroupings[index].payload });
  }

  drawerToggleHandler = () => {
    this.setState(prevState => {
      return { isDrawerOpened: !prevState.isDrawerOpened }
    });
  }

  onAddRelationHandler = () => {
    let initRelations = {};
    let newUUID = uuidv4();
    initRelations = {
      id: newUUID,
      uuid: newUUID,
      isRoot: true,
      value: { title: '(title)' },
      children: []
    };
    this.setState({ currentTabIndex: -1, editingNode: null, relationsData: initRelations, isDeletingRelations: false });
  }

  onUpdateNodeHandler = (item) => {
    this.setState({ isAdding: false, editingNode: item, isDrawerOpened: true, isDeletingRelations: false });
  }

  onDeleteNodeHandler = (deleteRelations) => {
    let isDeletingRelations = false;
    if (deleteRelations) {
      isDeletingRelations = true;
    }
    this.setState({ isDeleteNodeModalOpened: true, isDeletingRelations });
  }

  onConfirmDeleteNodeHandler = () => {
    if (this.state.isDeletingRelations) {
      this.drawerToggleHandler();
      this.props.deleteDeviceGrouping(this.props.deviceGroupings[this.state.currentTabIndex].id);
      this.setState({ relationsData: {}, isDeleteNodeModalOpened: false });
    } else {
      const relationsData = { ...this.state.relationsData }
      const depth = this.state.editingNode.g_currentPath.split('-');
      let current = relationsData;
      if (depth.length === 2) {
        // parent.children[index] , splice out
        relationsData.children.splice(+depth[1], 1);
      } else if (depth.length > 2) {
        // e.g. depth = -0-1-2 , parent.children[0].children[1] splice current.children[2]
        for (let i = 1; i < depth.length - 1; i++) {
          current = current.children[+depth[i]];
        }
        current.children.splice(+depth[depth.length - 1], 1);
      }
      this.drawerToggleHandler();
      this.setState({ relationsData, isDeleteNodeModalOpened: false });
      this.props.editDeviceGrouping(
        this.props.deviceGroupings[this.state.currentTabIndex].id,
        relationsData.value.title,
        relationsData
      );
    }
  }

  onSaveRelationsHandler = (updatedChildren) => {
    // temporary , wait for api
    this.drawerToggleHandler();
    const depth = this.state.editingNode.g_currentPath.split('-');
    // depth -0 = ['','0'], -0-1 = ['','0', '1'] ... 
    let current = { ...this.state.relationsData };
    let pointer = current;
    if (depth.length > 1) {
      for (let i = 1; i <= depth.length - 1; i++) {
        pointer = pointer.children[+depth[i]];
      }
    }
    pointer.children = updatedChildren.children;

    const relationsData = depth.length > 1 ? current : updatedChildren;
    this.setState({ relationsData, editingNode: null });
    if (this.state.currentTabIndex === -1) {
      this.props.addDeviceGrouping(
        this.props.currentOrg,
        relationsData.value.title,
        relationsData
      );
    } else {
      this.props.editDeviceGrouping(
        this.props.deviceGroupings[this.state.currentTabIndex].id,
        relationsData.value.title,
        relationsData
      );
    }
  }

  render() {
    return (
      <div className={styles.DeviceGrouping} style={{ height: `calc(100vh - ${135 + this.props.offsetHeight}px` }}>
        <SideDrawer title={'Relations Management'} open={this.state.isDrawerOpened} closed={this.drawerToggleHandler}>
          <RelationsDrawer
            currentTabIndex={this.state.currentTabIndex}
            editingNode={this.state.editingNode}
            relations={this.state.relationsData}
            closed={this.drawerToggleHandler}
            saveRelations={this.onSaveRelationsHandler} // temporary , wait for api
            toggleDeleteModal={this.onDeleteNodeHandler}
          />
        </SideDrawer>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              this.props.deviceGroupings ? this.props.deviceGroupings.map((dg, index) => (
                <div key={`plan-list-${index}`} className={index === this.state.currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.onChangeRelationsHandler(index)}>
                  <span className={styles.MenuTitle}>{dg.relation_ref}</span>
                </div>
              )) : null
            }
          </div>
          <div className={styles.AddButtonWrapper}>
            <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Relations" noMargin click={this.onAddRelationHandler} />
          </div>
        </div>
        {
          this.state.relationsData && Object.keys(this.state.relationsData).length
            ? <DeviceRelations
              data={this.state.relationsData}
              clickNode={this.onUpdateNodeHandler} />
            : null
        }
        <DeleteModal
          title={`${this.state.isDeletingRelations ? 'Relations' : 'Node'}`}
          open={this.state.isDeleteNodeModalOpened}
          modalClosed={() => this.setState({ isDeleteNodeModalOpened: false })}
          confirmDelete={this.onConfirmDeleteNodeHandler}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg, orgs } = state.org;
  const { deviceGroupings, result, type, addedDeviceGrouping, updatedDeviceGrouping } = state.deviceGrouping;
  return { currentOrg, orgs, deviceGroupings, result, type, addedDeviceGrouping, updatedDeviceGrouping };
};

export default connect(mapStateToProps, { getMachines, getDevices, getDeviceGroupings, addDeviceGrouping, editDeviceGrouping, deleteDeviceGrouping })(DeviceGrouping);
