import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  errorLogs: null,
  addedErrorLog: null,
  updatedErrorLog: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ERROR_LOGS_START: return getErrorLogsStart(state, action);
    case actionTypes.GET_ERROR_LOGS_SUCCESS: return getErrorLogsSuccess(state, action);
    case actionTypes.GET_ERROR_LOGS_FAIL: return getErrorLogsFail(state, action);

    case actionTypes.ADD_ERROR_LOG_START: return addErrorLogStart(state, action);
    case actionTypes.ADD_ERROR_LOG_SUCCESS: return addErrorLogSuccess(state, action);
    case actionTypes.ADD_ERROR_LOG_FAIL: return addErrorLogFail(state, action);

    case actionTypes.UPDATE_ERROR_LOG_START: return updateErrorLogStart(state, action);
    case actionTypes.UPDATE_ERROR_LOG_SUCCESS: return updateErrorLogSuccess(state, action);
    case actionTypes.UPDATE_ERROR_LOG_FAIL: return updateErrorLogFail(state, action);

    default: return state;
  }
};

const getErrorLogsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getErrorLogsSuccess = (state, action) => {
  return updateObject(state, {
    errorLogs: action.errorLogs,
    error: null,
    loading: false
  });
}

const getErrorLogsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addErrorLogStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addErrorLogSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedErrorLog: action.addedErrorLog,
    error: null,
    loading: false
  });
}

const addErrorLogFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const updateErrorLogStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const updateErrorLogSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedErrorLog: action.updatedErrorLog,
    error: null,
    loading: false
  });
}

const updateErrorLogFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;