import React, { Component } from 'react';
import moment from 'moment';

import styles from './DateTime.module.scss';
import TimeIcon from '../../../../../assets/icons/Widgets/circle-time.svg';
import DateIcon from '../../../../../assets/icons/Widgets/circle-date.svg';
import WidgetContainer from '../../WidgetContainer/WidgetContainer';


class DateTime extends Component {

  state = {
    showDate: '',
    dateSubtitle: '',
    showTime: '',
    timeZoneSubtitle: ''
  }

  componentDidMount() {
    this.setupInterval();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.type !== this.props.type && this.props.type) {
      clearInterval(this.timer);
      this.setupInterval();
    }
  }

  setupInterval = () => {
    if (this.props.type === 'date') {
      this.timer = setInterval(() => {
        this.setupDate();
      }, 1000);
    } else {
      this.setState({ timeZoneSubtitle: Intl.DateTimeFormat().resolvedOptions().timeZone });
      this.timer = setInterval(() => {
        this.setupTime();
      }, 1000);
    }
  }

  setupDate = () => {
    let now = new Date();
    this.setState({ showDate: moment(now).format("DD/MM/YYYY"), dateSubtitle: moment(now).format("dddd, MMMM D") });
  }

  setupTime = () => {
    this.setState({ showTime: moment((new Date()).getTime()).format("HH:mm:ss") });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { id } = this.props;

    let ShowIcon = TimeIcon;
    let SubTitile = this.state.timeZoneSubtitle;
    if (this.props.type === 'date') {
      ShowIcon = DateIcon;
      SubTitile = this.state.dateSubtitle;
    }
    return (
      <WidgetContainer data={this.props.data} isEdit={this.props.isEdit} subTitle={SubTitile} onEdit={this.props.onEdit}>
        <div id={id} className={styles.DateTime}>
          <div className={styles.Display}>
            <div className={styles.Value}>
              {this.props.type === 'date' ? this.state.showDate : this.state.showTime}
            </div>
            <div className={styles.Icon}>
              <img src={ShowIcon} alt="Date Time Icon" />
            </div>
          </div>
        </div>
      </WidgetContainer>
    );
  }
}

export default DateTime;