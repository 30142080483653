export const GET_OEES_START = 'GET_OEES_START';
export const GET_OEES = 'GET_OEES';
export const GET_OEES_SUCCESS = 'GET_OEES_SUCCESS';
export const GET_OEES_FAIL = 'GET_OEES_FAIL';

export const ADD_OEE_START = 'ADD_OEE_START';
export const ADD_OEE = 'ADD_OEE';
export const ADD_OEE_SUCCESS = 'ADD_OEE_SUCCESS';
export const ADD_OEE_FAIL = 'ADD_OEE_FAIL';

export const EDIT_OEE_START = 'EDIT_OEE_START';
export const EDIT_OEE = 'EDIT_OEE';
export const EDIT_OEE_SUCCESS = 'EDIT_OEE_SUCCESS';
export const EDIT_OEE_FAIL = 'EDIT_OEE_FAIL';

export const DELETE_OEE_START = 'DELETE_OEE_START';
export const DELETE_OEE = 'DELETE_OEE';
export const DELETE_OEE_SUCCESS = 'DELETE_OEE_SUCCESS';
export const DELETE_OEE_FAIL = 'DELETE_OEE_FAIL';

export const OEE_APPROVE_START = 'OEE_APPROVE_START';
export const OEE_APPROVE = 'OEE_APPROVE';
export const OEE_APPROVE_SUCCESS = 'OEE_APPROVE_SUCCESS';
export const OEE_APPROVE_FAIL = 'OEE_APPROVE_FAIL';