export const GET_SEQUENTIAL_COMMAND_START = 'GET_SEQUENTIAL_COMMAND_START';
export const GET_SEQUENTIAL_COMMAND = 'GET_SEQUENTIAL_COMMAND';
export const GET_SEQUENTIAL_COMMAND_SUCCESS = 'GET_SEQUENTIAL_COMMAND_SUCCESS';
export const GET_SEQUENTIAL_COMMAND_FAIL = 'GET_SEQUENTIAL_COMMAND_FAIL';

export const GET_SEQUENTIAL_COMMANDS_START = 'GET_SEQUENTIAL_COMMANDS_START';
export const GET_SEQUENTIAL_COMMANDS = 'GET_SEQUENTIAL_COMMANDS';
export const GET_SEQUENTIAL_COMMANDS_SUCCESS = 'GET_SEQUENTIAL_COMMANDS_SUCCESS';
export const GET_SEQUENTIAL_COMMANDS_FAIL = 'GET_SEQUENTIAL_COMMANDS_FAIL';

export const START_SEQUENTIAL_COMMAND_START = 'START_SEQUENTIAL_COMMAND_START';
export const START_SEQUENTIAL_COMMAND = 'START_SEQUENTIAL_COMMAND';
export const START_SEQUENTIAL_COMMAND_SUCCESS = 'START_SEQUENTIAL_COMMAND_SUCCESS';
export const START_SEQUENTIAL_COMMAND_FAIL = 'START_SEQUENTIAL_COMMAND_FAIL';

export const STOP_SEQUENTIAL_COMMAND_START = 'STOP_SEQUENTIAL_COMMAND_START';
export const STOP_SEQUENTIAL_COMMAND = 'STOP_SEQUENTIAL_COMMAND';
export const STOP_SEQUENTIAL_COMMAND_SUCCESS = 'STOP_SEQUENTIAL_COMMAND_SUCCESS';
export const STOP_SEQUENTIAL_COMMAND_FAIL = 'STOP_SEQUENTIAL_COMMAND_FAIL';