import * as actionTypes from './types';

// ----------------------- GET ERROR LOGS -----------------------
export const getErrorLogsStart = () => {
  return {
    type: actionTypes.GET_ERROR_LOGS_START
  };
}

export const getErrorLogs = (user_id, start, end, offset, limit) => {
  return {
    type: actionTypes.GET_ERROR_LOGS,
    user_id, start, end, offset, limit
  };
}

export const getErrorLogsSuccess = (response) => {
  return {
    type: actionTypes.GET_ERROR_LOGS_SUCCESS,
    errorLogs: response.error_logs
  };
}

export const getErrorLogsFail = (error) => {
  return {
    type: actionTypes.GET_ERROR_LOGS_FAIL,
    error
  };
}

// ----------------------- ADD ERROR LOG -----------------------

export const addErrorLogStart = () => {
  return {
    type: actionTypes.ADD_ERROR_LOG_START
  };
}

export const addErrorLog = (log_str, message, req_url, req_method, req_header, req_body, resp_header, resp_body, status_code) => {
  return {
    type: actionTypes.ADD_ERROR_LOG,
    log_str, message, req_url, req_method, req_header, req_body, resp_header, resp_body, status_code
  };
}

export const addErrorLogSuccess = (response) => {
  return {
    type: actionTypes.ADD_ERROR_LOG_SUCCESS,
    addedErrorLog: response.error_logs,
    result: response.result
  };
}

export const addErrorLogFail = (error) => {
  return {
    type: actionTypes.ADD_ERROR_LOG_FAIL,
    error
  };
}

// ----------------------- UPDATE ERROR LOG -----------------------

export const updateErrorLogStart = () => {
  return {
    type: actionTypes.UPDATE_ERROR_LOG_START
  };
}

export const updateErrorLog = (uuid, message, log_str, req_url, req_method, req_header, req_body, resp_header, resp_body, status_code) => {
  return {
    type: actionTypes.UPDATE_ERROR_LOG,
    uuid, message, log_str, req_url, req_method, req_header, req_body, resp_header, resp_body, status_code
  };
}

export const updateErrorLogSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_ERROR_LOG_SUCCESS,
    updatedErrorLog: response.error_logs,
    result: response.result
  };
}

export const updateErrorLogFail = (error) => {
  return {
    type: actionTypes.UPDATE_ERROR_LOG_FAIL,
    error
  };
}