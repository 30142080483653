import React, { Component } from 'react';

import styles from './MachineCard.module.scss';
import AddIcon from '../../../../assets/icons/plus-icon.svg';
// import StatusDeviceIcon from '../../../../assets/icons/machine-status-device.svg';
import StatusDeviceIcon from '../../../../assets/icons/Machine/device-status.svg';
import TotalDeviceIcon from '../../../../assets/icons/Machine/device-total.svg';
import GoodDeviceIcon from '../../../../assets/icons/Machine/device-good.svg';
import DefectDeviceIcon from '../../../../assets/icons/Machine/device-bad.svg';

class MachineCard extends Component {
  onEditMachineHandler = (event) => {
    event.stopPropagation();
    this.props.editingMachine(this.props.machineIndex);
  }

  render() {
    if (this.props.addingCard) {
      return (
        <div className={`${styles.Card} ${styles.CardAddingStyle}`} onClick={this.props.click}>
          <img src={AddIcon} alt="Add" className={styles.AddIcon} />
          <div className={styles.CardBody} style={{ textAlign: 'center', padding: '0 30px' }}>
            <p className={styles.CardTitle} style={{ marginTop: 0 }}>Add New Machine</p>
            <p className={styles.CardSubTitle}>Fill up the form to add new Machine</p>
          </div>
        </div>
      );
    } else {
      if (this.props.machineData) {
        const { name, status_rules, status_device_secret, status_device_socket, total_device_secret, total_device_socket, good_device_secret, good_device_socket, defect_device_secret, defect_device_socket } = this.props.machineData;

        let foundStatusDevice = null, foundStatusSocket = null;
        let foundTotalDevice = null, foundTotalSocket = null;
        let foundGoodDevice = null, foundGoodSocket = null;
        let foundDefectDevice = null, foundDefectSocket = null;
        if (this.props.devices && this.props.devices.length && this.props.virtualDevices) {
          foundStatusDevice = this.props.devices.find(device => device.secret === status_device_secret);
          if (foundStatusDevice) {
            foundStatusSocket = foundStatusDevice ? foundStatusDevice.device_sockets.find(ds => ds.socket === status_device_socket) : null;
          } else {
            foundStatusDevice = this.props.virtualDevices.find(vd => vd.secret === status_device_secret);
            foundStatusSocket = { ...foundStatusDevice };
          }

          if (total_device_secret && total_device_socket) {
            foundTotalDevice = this.props.devices.find(device => device.secret === total_device_secret);
            if (foundTotalDevice) {
              foundTotalSocket = foundTotalDevice ? foundTotalDevice.device_sockets.find(ds => ds.socket === total_device_socket) : null;
            } else {
              foundTotalDevice = this.props.virtualDevices.find(vd => vd.secret === total_device_secret);
              foundTotalSocket = { ...foundTotalDevice };
            }
          }
          if (good_device_secret && good_device_socket) {
            foundGoodDevice = this.props.devices.find(device => device.secret === good_device_secret);
            if (foundGoodDevice) {
              foundGoodSocket = foundGoodDevice ? foundGoodDevice.device_sockets.find(ds => ds.socket === good_device_socket) : null;
            } else {
              foundGoodDevice = this.props.virtualDevices.find(vd => vd.secret === good_device_secret);
              foundGoodSocket = { ...foundGoodDevice };
            }
          }
          if (defect_device_secret && defect_device_socket) {
            foundDefectDevice = this.props.devices.find(device => device.secret === defect_device_secret);
            if (foundDefectDevice) {
              foundDefectSocket = foundDefectDevice ? foundDefectDevice.device_sockets.find(ds => ds.socket === defect_device_socket) : null;
            } else {
              foundDefectDevice = this.props.virtualDevices.find(vd => vd.secret === defect_device_secret);
              foundDefectSocket = { ...foundDefectDevice };
            }
          }
        }

        return (
          <div className={`${styles.Card} ${styles.CardFlexStyle}`} onClick={this.onEditMachineHandler}>
            <div className={`${styles.CardBody} ${styles.Flex}`}>
              <div className={styles.CardTitle}>{name}</div>
              <div className={styles.StatusDeviceWrapper}>
                <img src={StatusDeviceIcon} alt="status device" style={{ width: 20, height: 18 }} />
                <div className={styles.CardSubTitle}>{
                  foundStatusSocket && foundStatusDevice
                    ? <>{foundStatusSocket.name} <span style={{ opacity: 0.5, fontSize: 13 }}>({foundStatusDevice.name} :: {foundStatusSocket.socket})</span></>
                    : ''
                }</div>
              </div>
              <div style={{ margin: '10px 0' }}>
                {
                  Object.keys(status_rules).map(value =>
                    <div key={`${name}-status-${value}`}
                      className={`${styles.StatusBox} ${status_rules[value].color === '#222226' ? styles.ShadowBox : ''}`}
                      style={{ backgroundColor: status_rules[value].color + '33' }}
                    >
                      <div
                        className={`${styles.ReasonText}`}
                        style={{ color: status_rules[value].color === '#222226' ? 'black' : status_rules[value].color }}
                      >
                        {status_rules[value].reason} ({value})
                      </div>
                    </div>
                  )
                }
              </div>
              {
                foundTotalDevice
                  ? <div className={styles.StatusDeviceWrapper} style={{ margin: '5px 0' }}>
                    <img src={TotalDeviceIcon} alt="total device" style={{ width: 20, height: 18 }} />
                    <div className={styles.CardSubTitle}>{
                      foundTotalSocket
                        ? <>{foundTotalSocket.name} <span style={{ opacity: 0.5, fontSize: 13 }}>({foundTotalDevice.name} :: {foundTotalSocket.socket})</span></>
                        : ''
                    }</div>
                  </div>
                  : null
              }
              {
                foundGoodDevice
                  ? <div className={styles.StatusDeviceWrapper} style={{ margin: '5px 0' }}>
                    <img src={GoodDeviceIcon} alt="good device" style={{ width: 20, height: 18 }} />
                    <div className={styles.CardSubTitle}>{
                      foundGoodSocket
                        ? <>{foundGoodSocket.name} <span style={{ opacity: 0.5, fontSize: 13 }}>({foundGoodDevice.name} :: {foundGoodSocket.socket})</span></>
                        : ''
                    }</div>
                  </div>
                  : null
              }
              {
                foundDefectDevice
                  ? <div className={styles.StatusDeviceWrapper} style={{ margin: '5px 0' }}>
                    <img src={DefectDeviceIcon} alt="defect device" style={{ width: 20, height: 18 }} />
                    <div className={styles.CardSubTitle}>{
                      foundDefectSocket
                        ? <>{foundDefectSocket.name} <span style={{ opacity: 0.5, fontSize: 13 }}>({foundDefectDevice.name} :: {foundDefectSocket.socket})</span></>
                        : ''
                    }</div>
                  </div>
                  : null
              }
            </div>
          </div>
        );
      } else {
        // can show a loading card!
        return null;
      }
    }
  }
}

export default MachineCard;