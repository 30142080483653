export const GET_DEVICE_CONFIGS_START = 'GET_DEVICE_CONFIGS_START';
export const GET_DEVICE_CONFIGS = 'GET_DEVICE_CONFIGS';
export const GET_DEVICE_CONFIGS_SUCCESS = 'GET_DEVICE_CONFIGS_SUCCESS';
export const GET_DEVICE_CONFIGS_FAIL = 'GET_DEVICE_CONFIGS_FAIL';

export const ADD_DEVICE_CONFIG_START = 'ADD_DEVICE_CONFIG_START';
export const ADD_DEVICE_CONFIG = 'ADD_DEVICE_CONFIG';
export const ADD_DEVICE_CONFIG_SUCCESS = 'ADD_DEVICE_CONFIG_SUCCESS';
export const ADD_DEVICE_CONFIG_FAIL = 'ADD_DEVICE_CONFIG_FAIL';

export const DELETE_DEVICE_CONFIGS_START = 'DELETE_DEVICE_CONFIGS_START';
export const DELETE_DEVICE_CONFIGS = 'DELETE_DEVICE_CONFIGS';
export const DELETE_DEVICE_CONFIGS_SUCCESS = 'DELETE_DEVICE_CONFIGS_SUCCESS';
export const DELETE_DEVICE_CONFIGS_FAIL = 'DELETE_DEVICE_CONFIGS_FAIL';

export const GET_SYSTEM_DEVICE_CONFIG_START = 'GET_SYSTEM_DEVICE_CONFIG_START';
export const GET_SYSTEM_DEVICE_CONFIG = 'GET_SYSTEM_DEVICE_CONFIG';
export const GET_SYSTEM_DEVICE_CONFIG_SUCCESS = 'GET_SYSTEM_DEVICE_CONFIG_SUCCESS';
export const GET_SYSTEM_DEVICE_CONFIG_FAIL = 'GET_SYSTEM_DEVICE_CONFIG_FAIL';

export const SAVE_SYSTEM_DEVICE_CONFIG_START = 'SAVE_SYSTEM_DEVICE_CONFIG_START';
export const SAVE_SYSTEM_DEVICE_CONFIG = 'SAVE_SYSTEM_DEVICE_CONFIG';
export const SAVE_SYSTEM_DEVICE_CONFIG_SUCCESS = 'SAVE_SYSTEM_DEVICE_CONFIG_SUCCESS';
export const SAVE_SYSTEM_DEVICE_CONFIG_FAIL = 'SAVE_SYSTEM_DEVICE_CONFIG_FAIL';

export const GET_SYSTEM_DEVICE_CONFIG_JSON_START = 'GET_SYSTEM_DEVICE_CONFIG_JSON_START';
export const GET_SYSTEM_DEVICE_CONFIG_JSON = 'GET_SYSTEM_DEVICE_CONFIG_JSON';
export const GET_SYSTEM_DEVICE_CONFIG_JSON_SUCCESS = 'GET_SYSTEM_DEVICE_CONFIG_JSON_SUCCESS';
export const GET_SYSTEM_DEVICE_CONFIG_JSON_FAIL = 'GET_SYSTEM_DEVICE_CONFIG_JSON_FAIL';

export const SAVE_SYSTEM_DEVICE_CONFIG_JSON_START = 'SAVE_SYSTEM_DEVICE_CONFIG_JSON_START';
export const SAVE_SYSTEM_DEVICE_CONFIG_JSON = 'SAVE_SYSTEM_DEVICE_CONFIG_JSON';
export const SAVE_SYSTEM_DEVICE_CONFIG_JSON_SUCCESS = 'SAVE_SYSTEM_DEVICE_CONFIG_JSON_SUCCESS';
export const SAVE_SYSTEM_DEVICE_CONFIG_JSON_FAIL = 'SAVE_SYSTEM_DEVICE_CONFIG_JSON_FAIL';