export const GET_TEAMS_START = 'GET_TEAMS_START';
export const GET_TEAMS = 'GET_TEAMS';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAIL = 'GET_TEAMS_FAIL';

export const ADD_TEAM_START = 'ADD_TEAM_START';
export const ADD_TEAM = 'ADD_TEAM';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_FAIL = 'ADD_TEAM_FAIL';

export const EDIT_TEAM_START = 'EDIT_TEAM_START';
export const EDIT_TEAM = 'EDIT_TEAM';
export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
export const EDIT_TEAM_FAIL = 'EDIT_TEAM_FAIL';

export const DELETE_TEAM_START = 'DELETE_TEAM_START';
export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAIL = 'DELETE_TEAM_FAIL';

export const ADD_TEAM_MEMBERS_START = 'ADD_TEAM_MEMBERS_START';
export const ADD_TEAM_MEMBERS = 'ADD_TEAM_MEMBERS';
export const ADD_TEAM_MEMBERS_SUCCESS = 'ADD_TEAM_MEMBERS_SUCCESS';
export const ADD_TEAM_MEMBERS_FAIL = 'ADD_TEAM_MEMBERS_FAIL';

export const REMOVE_TEAM_MEMBERS_START = 'REMOVE_TEAM_MEMBERS_START';
export const REMOVE_TEAM_MEMBERS = 'REMOVE_TEAM_MEMBERS';
export const REMOVE_TEAM_MEMBERS_SUCCESS = 'REMOVE_TEAM_MEMBERS_SUCCESS';
export const REMOVE_TEAM_MEMBERS_FAIL = 'REMOVE_TEAM_MEMBERS_FAIL';

export const CHANGE_TEAM_ADMIN_START = 'CHANGE_TEAM_ADMIN_START';
export const CHANGE_TEAM_ADMIN = 'CHANGE_TEAM_ADMIN';
export const CHANGE_TEAM_ADMIN_SUCCESS = 'CHANGE_TEAM_ADMIN_SUCCESS';
export const CHANGE_TEAM_ADMIN_FAIL = 'CHANGE_TEAM_ADMIN_FAIL';

export const LEAVE_TEAM_START = 'LEAVE_TEAM_START';
export const LEAVE_TEAM = 'LEAVE_TEAM';
export const LEAVE_TEAM_SUCCESS = 'LEAVE_TEAM_SUCCESS';
export const LEAVE_TEAM_FAIL = 'LEAVE_TEAM_FAIL';