import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Chat.module.scss';
import ChatList from '../../components/Chat/ChatList/ChatList';
import ChatRoom from '../../components/Chat/ChatRoom/ChatRoom';
import { getChatrooms } from '../../../state/ducks/ChatRoom/actions';

class Chat extends Component {

  state = {
    selectedChat: null
  };

  componentDidMount() {
    this.props.getChatrooms(this.props.currentOrg, this.props.loggedInUser.id);
  }

  onSelectedChat = () => {

  }

  render() {
    return (
      <div className={styles.Chat}>
        <div className={styles.LeftSection}>
          <ChatList />
        </div>
        <div className={styles.RightSection}>
          <ChatRoom />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { chatrooms } = state.chatroom;
  const { currentOrg } = state.org;
  const { loggedInUser } = state.auth;
  return { currentOrg, chatrooms, loggedInUser };
};

export default connect(mapStateToProps, { getChatrooms })(Chat);
