import * as actionTypes from './types';



// ----------------------- SET BUFF ORG ID INDEX -----------------
export const setfilteredOrgBuff = (newFilteredOrgBuff, isEqual) => {
  return {
    type:actionTypes.SET_FILTERED_ORG_BUFF,
    newFilteredOrgBuff,
    isEqual,
  };
}

export const setfilteredOrgBuffUp = () => {
  return {
    type:actionTypes.SET_FILTERED_ORG_BUFF_UP,
  };
}

export const setfilteredOrgBuffDown = () => {
  return {
    type:actionTypes.SET_FILTERED_ORG_BUFF_DOWN,
  };
}

export const setCurrentOrgFromKey = () => {
  return {
    type:actionTypes.SET_CURRENT_ORG_FROM_KEY,
  };
}

export const setfilteredOrgBuffFalse = () => {
  return {
    type:actionTypes.SET_FILTERED_ORG_BUFF_FALSE,
  };
}
// ----------------------- SET CURRENT ORG -----------------------
export const setCurrentOrg = (org_id) => {
  return {
    type: actionTypes.SET_CURRENT_ORG,
    org_id
  };
}

// ----------------------- CLEAR CURRENT ORG -----------------------
export const clearCurrentOrg = () => {
  return {
    type: actionTypes.CLEAR_CURRENT_ORG
  };
}

// ----------------------- GET ORGS -----------------------
export const getOrgsStart = () => {
  return {
    type: actionTypes.GET_ORGS_START
  };
}

export const getOrgs = (id, name) => {
  return {
    type: actionTypes.GET_ORGS,
    id,
    name
  };
}

export const getOrgsSuccess = (response) => {
  if (response.organization) {
    return {
      type: actionTypes.GET_ORGS_SUCCESS,
      org: response.organization
    };
  }
  return {
    type: actionTypes.GET_ORGS_SUCCESS,
    orgs: response.organizations
  };
}

export const getOrgsFail = (response) => {
  return {
    type: actionTypes.GET_ORGS_FAIL,
    error: response.error
  };
}

// ----------------------- ADD ORG -----------------------
export const addOrgStart = () => {
  return {
    type: actionTypes.ADD_ORG_START
  };
}

export const addOrg = (name, street_address, province, country, post_number, tax_id, tel, logo) => {
  return {
    type: actionTypes.ADD_ORG,
    name, street_address, province, country, post_number, tax_id, tel, logo
  };
}

export const addOrgSuccess = (response) => {
  return {
    type: actionTypes.ADD_ORG_SUCCESS,
    addedOrg: response.organization,
    result: response.result
  };
}

export const addOrgFail = (response) => {
  return {
    type: actionTypes.ADD_ORG_FAIL,
    error: response.error
  };
}

// ----------------------- EDIT ORG -----------------------
export const editOrgStart = () => {
  return {
    type: actionTypes.EDIT_ORG_START
  };
}

export const editOrg = (id, name, street_address, province, country, post_number, tax_id, tel, logo) => {
  return {
    type: actionTypes.EDIT_ORG,
    id,
    name, street_address, province, country, post_number, tax_id, tel, logo
  };
}

export const editOrgSuccess = (response) => {
  return {
    type: actionTypes.EDIT_ORG_SUCCESS,
    updatedOrg: response.organization,
    result: response.result
  };
}

export const editOrgFail = (response) => {
  return {
    type: actionTypes.EDIT_ORG_FAIL,
    error: response.error
  };
}

// ----------------------- DELETE ORG -----------------------
export const deleteOrgStart = () => {
  return {
    type: actionTypes.DELETE_ORG_START
  };
}

export const deleteOrg = (id) => {
  return {
    type: actionTypes.DELETE_ORG,
    id: id
  };
}

export const deleteOrgSuccess = (response) => {
  return {
    type: actionTypes.DELETE_ORG_SUCCESS,
    result: response.result
  };
}

export const deleteOrgFail = (response) => {
  return {
    type: actionTypes.DELETE_ORG_FAIL,
    error: response.error
  };
}

// ----------------------- INVITE ORG MEMBER -----------------------
export const inviteMemberStart = () => {
  return {
    type: actionTypes.INVITE_MEMBER_START
  };
}

export const inviteMember = (id, email) => {
  return {
    type: actionTypes.INVITE_MEMBER,
    id, email
  };
}

export const inviteMemberSuccess = (result) => {
  return {
    type: actionTypes.INVITE_MEMBER_SUCCESS,
    result
  };
}

export const inviteMemberFail = (error) => {
  return {
    type: actionTypes.INVITE_MEMBER_FAIL,
    error
  };
}

// ----------------------- REMOVE ORG MEMBER -----------------------
export const removeMemberStart = () => {
  return {
    type: actionTypes.REMOVE_MEMBER_START
  };
}

export const removeMember = (id, transfer_uid, email) => {
  return {
    type: actionTypes.REMOVE_MEMBER,
    id, transfer_uid, email
  };
}

export const removeMemberSuccess = (result) => {
  return {
    type: actionTypes.REMOVE_MEMBER_SUCCESS,
    result
  };
}

export const removeMemberFail = (error) => {
  return {
    type: actionTypes.REMOVE_MEMBER_FAIL,
    error
  };
}

// ----------------------- GET ORG ADMIN -----------------------
export const getAdminsStart = () => {
  return {
    type: actionTypes.GET_ADMINS_START
  };
}

export const getAdmins = (id) => {
  return {
    type: actionTypes.GET_ADMINS,
    id
  };
}

export const getAdminsSuccess = (response) => {
  return {
    type: actionTypes.GET_ADMINS_SUCCESS,
    admins: response.users
  };
}

export const getAdminsFail = (response) => {
  return {
    type: actionTypes.GET_ADMINS_FAIL,
    error: response.error
  };
}

// ----------------------- INVITE ORG ADMIN -----------------------
export const inviteAdminStart = () => {
  return {
    type: actionTypes.INVITE_ADMIN_START
  };
}

export const inviteAdmin = (id, user_id) => {
  return {
    type: actionTypes.INVITE_ADMIN,
    id, user_id
  };
}

export const inviteAdminSuccess = (response) => {
  return {
    type: actionTypes.INVITE_ADMIN_SUCCESS,
    result: response.result,
    addedAdmin: response.user
  };
}

export const inviteAdminFail = (error) => {
  return {
    type: actionTypes.INVITE_ADMIN_FAIL,
    error
  };
}

// ----------------------- REMOVE ORG ADMIN -----------------------
export const removeAdminStart = () => {
  return {
    type: actionTypes.REMOVE_ADMIN_START
  };
}

export const removeAdmin = (id, user_id) => {
  return {
    type: actionTypes.REMOVE_ADMIN,
    id, user_id
  };
}

export const removeAdminSuccess = (result) => {
  return {
    type: actionTypes.REMOVE_ADMIN_SUCCESS,
    result
  };
}

export const removeAdminFail = (error) => {
  return {
    type: actionTypes.REMOVE_ADMIN_FAIL,
    error
  };
}

// ----------------------- GEN API KEY -----------------------
export const genAPIKeyStart = () => {
  return {
    type: actionTypes.GEN_API_KEY_START
  };
}

export const genAPIKey = (id) => {
  return {
    type: actionTypes.GEN_API_KEY, id
  };
}

export const genAPIKeySuccess = (response) => {
  return {
    type: actionTypes.GEN_API_KEY_SUCCESS,
    result: response.result,
    newapikey: response.organization.api_key
  };
}

export const genAPIKeyFail = (error) => {
  return {
    type: actionTypes.GEN_API_KEY_FAIL,
    error
  };
}

// export default {
//   setCurrentOrg,
//   getOrgsStart,
//   getOrgs,
//   getOrgsSuccess,
//   getOrgsFail,
//   addOrg,
//   addOrgStart,
//   addOrgSuccess,
//   addOrgFail,
//   editOrg,
//   editOrgStart,
//   editOrgSuccess,
//   editOrgFail,
//   deleteOrg,
//   deleteOrgStart,
//   deleteOrgSuccess,
//   deleteOrgFail,
//   inviteMember,
//   inviteMemberStart,
//   inviteMemberSuccess,
//   inviteMemberFail,
//   removeMember,
//   removeMemberStart,
//   removeMemberSuccess,
//   removeMemberFail,
//   getAdminsStart,
//   getAdmins,
//   getAdminsSuccess,
//   getAdminsFail,
//   inviteAdminStart,
//   inviteAdmin,
//   inviteAdminSuccess,
//   inviteAdminFail,
//   removeAdminStart,
//   removeAdmin,
//   removeAdminSuccess,
//   removeAdminFail,
//   genAPIKey,
//   genAPIKeyStart,
//   genAPIKeySuccess,
//   genAPIKeyFail
// };