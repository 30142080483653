import * as actionTypes from './types';

// ----------------------- GET SHIFTS -----------------------
export const getShiftsStart = () => {
  return {
    type: actionTypes.GET_SHIFTS_START
  };
}

export const getShifts = (org_id, id) => {
  return {
    type: actionTypes.GET_SHIFTS,
    org_id,
    id
  };
}

export const getShiftsSuccess = (response) => {
  return {
    type: actionTypes.GET_SHIFTS_SUCCESS,
    shifts: response.shifts
  };
}

export const getShiftsFail = (error) => {
  return {
    type: actionTypes.GET_SHIFTS_FAIL,
    error: error
  };
}

// ----------------------- ADD SHIFT -----------------------
export const addShiftStart = () => {
  return {
    type: actionTypes.ADD_SHIFT_START
  };
}

export const addShift = (org_id, name, start, stop, description) => {
  return {
    type: actionTypes.ADD_SHIFT,
    org_id,
    name,
    start,
    stop,
    description
  };
}

export const addShiftSuccess = (response) => {
  return {
    type: actionTypes.ADD_SHIFT_SUCCESS,
    addedShift: response.shift,
    result: response.result
  };
}

export const addShiftFail = (response) => {
  return {
    type: actionTypes.ADD_SHIFT_FAIL,
    error: response.error,
    result: response.result
  };
}

// ----------------------- EDIT SHIFT -----------------------
export const editShiftStart = () => {
  return {
    type: actionTypes.EDIT_SHIFT_START
  };
}

export const editShift = (id, name, start, stop, description) => {
  return {
    type: actionTypes.EDIT_SHIFT,
    id,
    name,
    start,
    stop,
    description
  };
}

export const editShiftSuccess = (response) => {
  return {
    type: actionTypes.EDIT_SHIFT_SUCCESS,
    updatedShift: response.shift,
    result: response.result
  };
}

export const editShiftFail = (response) => {
  return {
    type: actionTypes.EDIT_SHIFT_FAIL,
    error: response.error,
    result: response.result
  };
}

// ----------------------- DELETE SHIFT -----------------------
export const deleteShiftStart = () => {
  return {
    type: actionTypes.DELETE_SHIFT_START
  };
}

export const deleteShift = (id) => {
  return {
    type: actionTypes.DELETE_SHIFT,
    id: id
  };
}

export const deleteShiftSuccess = (result) => {
  return {
    type: actionTypes.DELETE_SHIFT_SUCCESS,
    result: result
  };
}

export const deleteShiftFail = (error) => {
  return {
    type: actionTypes.DELETE_SHIFT_FAIL,
    error: error
  };
}

// export default {
//   getShifts,
//   getShiftsStart,
//   getShiftsSuccess,
//   getShiftsFail,
//   addShift,
//   addShiftStart,
//   addShiftSuccess,
//   addShiftFail,
//   editShift,
//   editShiftStart,
//   editShiftSuccess,
//   editShiftFail,
//   deleteShift,
//   deleteShiftStart,
//   deleteShiftSuccess,
//   deleteShiftFail
// };
