
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_NOTIFICATIONS, ADD_NOTIFICATION, UPDATE_NOTIFICATION, DELETE_NOTIFICATION, GET_NOTIFICATIONS_INBOX, MARK_AS_READ, GET_NOTIFICATION_TOPBAR } from './types';

export function* watchNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, getNotificationsSaga);
  yield takeEvery(ADD_NOTIFICATION, addNotificationSaga);
  yield takeEvery(UPDATE_NOTIFICATION, updateNotificationSaga);
  yield takeEvery(DELETE_NOTIFICATION, deleteNotificationSaga);
  yield takeEvery(GET_NOTIFICATIONS_INBOX, getNotificationsInboxSaga);
  yield takeEvery(MARK_AS_READ, markAsReadSaga);
  yield takeEvery(GET_NOTIFICATION_TOPBAR, getNotificationTopbarSaga);
}

export function* getNotificationsSaga(params) {
  yield put(actions.getNotificationsStart());

  let api = 'v1/notification';
  let paramsQuery = params.org_id || params.start || params.end || params.offset || params.limit ? '?' : '';
  paramsQuery += params.org_id ? 'org_id=' + params.org_id : '';

  paramsQuery += params.start && paramsQuery !== '' ? '&' : '';
  paramsQuery += params.start ? 'start=' + params.start : '';

  paramsQuery += params.end && paramsQuery !== '' ? '&' : '';
  paramsQuery += params.end ? 'end=' + params.end : '';

  paramsQuery += params.offset && paramsQuery !== '' ? '&' : '';
  paramsQuery += params.offset ? 'offset=' + params.offset : '';

  paramsQuery += params.limit && paramsQuery !== '' ? '&' : '';
  paramsQuery += params.limit ? 'limit=' + params.limit : '';

  api += paramsQuery;
  try {
    const response = yield axios.get(api);
    // console.log("GET NOTIFICATIONS SUCCESS", response.data);
    yield put(actions.getNotificationsSuccess(response.data));
  } catch (err) {
    // console.log("GET NOTIFICATIONS FAILED", err)
    yield put(actions.getNotificationsFail(err && err.response ? err.response.error : 'Something went wrong'));
  }
}

export function* addNotificationSaga(params) {
  yield put(actions.addNotificationStart());
  let api = 'v1/notification';
  let postData = "title=" + encodeURIComponent(params.title);
  postData += params.content ? "&content=" + encodeURIComponent(params.content) : '';
  postData += params.notif_type ? "&type=" + params.notif_type : '';
  postData += params.org_ids ? "&org_ids=" + params.org_ids : '';
  postData += params.start ? "&start=" + params.start : '';
  postData += params.end ? "&end=" + params.end : '';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD NOTIFICATION SUCCESS", response.data);
    yield put(actions.addNotificationSuccess(response.data));
  } catch (err) {
    yield put(actions.addNotificationFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* updateNotificationSaga(params) {
  yield put(actions.updateNotificationStart());

  let api = 'v1/notification';
  let putData = "uuid=" + params.uuid;
  putData += params.title ? "&title=" + encodeURIComponent(params.title) : '';
  putData += params.content ? "&content=" + encodeURIComponent(params.content) : '';

  try {
    const response = yield axios.put(api, putData);
    // console.log("UPDATE NOTIFICATION SUCCESS", response.data);
    yield put(actions.updateNotificationSuccess(response.data));
  } catch (err) {
    yield put(actions.updateNotificationFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* deleteNotificationSaga(params) {
  yield put(actions.deleteNotificationStart());

  let api = 'v1/notification';
  api += params.uuid ? "?uuid=" + params.uuid : '';

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE NOTIFICATION SUCCESS", response.data);
    yield put(actions.deleteNotificationSuccess(response.data));
  } catch (err) {
    // console.log("DELETE NOTIFICATION FAILED", err.response);
    yield put(actions.deleteNotificationFail(err.response));
  }
}


export function* getNotificationsInboxSaga(params) {
  yield put(actions.getNotificationsInboxStart());

  let api = 'v1/notification/inbox';
  api += params.offset ? "?offset=" + params.offset : '';
  api += params.limit ? "&limit=" + params.limit : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET NOTIFICATIONS Inbox SUCCESS", response.data);
    yield put(actions.getNotificationsInboxSuccess(response.data));
  } catch (err) {
    // console.log("GET NOTIFICATIONS Inbox FAILED", err)
    yield put(actions.getNotificationsInboxFail(err && err.response ? err.response.error : 'Something went wrong'));
  }
}

export function* markAsReadSaga(params) {
  yield put(actions.markAsReadStart());

  let api = 'v1/notification/inbox';
  let putData = "uuids=" + params.uuids;

  try {
    const response = yield axios.put(api, putData);
    // console.log("MARK AS READ SUCCESS", response.data);
    yield put(actions.markAsReadSuccess(response.data));
  } catch (err) {
    // console.log("MARK AS READ FAILED", err)
    yield put(actions.markAsReadFail(err && err.response ? err.response.error : 'Something went wrong'));
  }
}

export function* getNotificationTopbarSaga(params) {
  yield put(actions.getNotificationTopbarStart());

  let api = 'v1/notification/topbar';

  try {
    const response = yield axios.get(api);
    // console.log("GET NOTIFICATIONS Topbar SUCCESS", response.data);
    yield put(actions.getNotificationTopbarSuccess(response.data));
  } catch (err) {
    // console.log("GET NOTIFICATIONS Topbar FAILED", err)
    yield put(actions.getNotificationTopbarFail(err && err.response ? err.response.error : 'Something went wrong'));
  }
}