import React, { Component } from 'react';

import { updateObject, checkValidity } from '../../../../../state/utils';
import styles from '../SystemDeviceConfig.module.scss';
import Input from '../../../UI/Input/Input';
import SimpleSwitch from '../../../UI/SimpleSwitch/SimpleSwitch';

const DEFAULT_FORM = {
  ethernetIp: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  },
  ethernetSubnet: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  },
  ethernetGateway: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  },
  ethernetPrimaryDns: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  },
  ethernetSecondaryDns: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  }
};

class EthernetFields extends Component {
  state = {
    form: DEFAULT_FORM,
    formIsValid: false,
    enableStaicIp: false
  }

  componentDidMount() {
    if (this.props.networkDetails && Object.keys(this.props.networkDetails).length) {
      this.setupEthForm(this.props.networkDetails, false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ethConfig !== this.props.ethConfig && (this.props.ethConfig || this.props.ethConfig === '')) {
      this.setupEthForm(this.props.ethConfig, true);
    }
  }

  setupEthForm = (ethConfig, isFromOriginalConfigs) => {
    if (ethConfig && Object.keys(ethConfig).length) {
      let updatedForm = { ...this.state.form };
      for (let inputIdentifier in updatedForm) {
        let key = inputIdentifier;
        if (isFromOriginalConfigs) {
          switch (inputIdentifier) {
            case 'ethernetIp': key = 'ip'; break;
            case 'ethernetSubnet': key = 'subnet'; break;
            case 'ethernetGateway': key = 'gateway'; break;
            case 'ethernetPrimaryDns': key = 'primaryDns'; break;
            case 'ethernetSecondaryDns': key = 'secondaryDns'; break;
            default: break;
          }
        }

        const updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: ethConfig[key] ? ethConfig[key] : '',
          valid: true,
          touched: true
        });
        updatedForm = updateObject(updatedForm, {
          [inputIdentifier]: updatedElement
        });
      }
      this.setState({ form: updatedForm, formIsValid: true, enableStaicIp: isFromOriginalConfigs ? (ethConfig['staticIp'] ? true : false) : (ethConfig.ethernetEnableStaticIp ? true : false) });
    }
  }

  handleEthernetTextChange = event => {
    const { name, value } = event.target;
    this.props.setNetworkDetails(name, value);

    let updatedElement = updateObject(this.state.form[name], {
      value: value,
      valid: checkValidity(value, this.state.form[name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.form, {
      [name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ form: updatedForm, formIsValid });
  };

  onChangeEnableEthernetStaticIpHandler = (event) => {
    event.stopPropagation();
    this.props.setNetworkDetails('ethernetEnableStaticIp', !this.state.enableStaicIp);
    this.setState(prevState => {
      return { enableStaicIp: !prevState.enableStaicIp }
    });
  }

  render() {
    const { ethernetIp, ethernetSubnet, ethernetGateway, ethernetPrimaryDns, ethernetSecondaryDns } = this.state.form;

    return (
      <div style={{ display: this.props.show ? 'block' : 'none' }}>
        <div className={styles.Label}>Ethernet Settings</div>
        <div className={styles.SwitchWrapper}>
          <div style={{ flex: 1 }}>Static IP</div>
          <SimpleSwitch enable={this.state.enableStaicIp} onChangeEnableHandler={this.onChangeEnableEthernetStaticIpHandler} />
        </div>
        {
          this.state.enableStaicIp ?
            <>
              <Input
                labelin="true"
                label="IP"
                name="ethernetIp"
                type="text"
                value={ethernetIp.value}
                autoComplete="off"
                onChange={this.handleEthernetTextChange}
              />
              <Input
                labelin="true"
                label="Subnet"
                name="ethernetSubnet"
                type="text"
                value={ethernetSubnet.value}
                autoComplete="off"
                onChange={this.handleEthernetTextChange}
              />
              <Input
                labelin="true"
                label="Gateway"
                name="ethernetGateway"
                type="text"
                value={ethernetGateway.value}
                autoComplete="off"
                onChange={this.handleEthernetTextChange}
              />
              <Input
                labelin="true"
                label="Primary DNS"
                name="ethernetPrimaryDns"
                type="text"
                value={ethernetPrimaryDns.value}
                autoComplete="off"
                onChange={this.handleEthernetTextChange}
              />
              <Input
                labelin="true"
                label="Secondary DNS"
                name="ethernetSecondaryDns"
                type="text"
                value={ethernetSecondaryDns.value}
                autoComplete="off"
                onChange={this.handleEthernetTextChange}
              />
            </>
            : null
        }

        <div className={styles.BetweenSection} />
      </div>
    );
  }
}

export default EthernetFields;