import React, { Component } from 'react';
import ReactSelect from 'react-select';

import styles from './TimelineDrawer.module.scss';
import barstyles from './TimelineBar.module.scss';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import DeviceEquationArea from '../UI/DeviceEquationArea/DeviceEquationArea'

const colourStyles = (isError = false) => {
  return {
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data && state.data.color ? state.data.color : "white",
      backgroundColor: state.data && state.data.color ? state.data.color : '',
      animation: state.data && state.data.flashing ? '2s infinite ' + barstyles.flashing : '',
      height: state.data.color ? 30 : '',
      width: state.data.color ? 30 : '',
      overflowX: 'hidden'
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#1c1c1f",
      borderRadius: 4,
      borderColor: state.isFocused ? "#1665d8" : isError ? "#e6492d" : "#2b2b30;",
      "&:hover": {
        borderColor: "#1665d8"
      },
      fontSize: 14,
      fontWeight: 500,
    }),
    input: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        margin: '5px 8px',
        height: data.color ? 30 : '',
        width: data.color ? 30 : '',
        color: data.color ? data.color : '',
        animation: data.flashing ? '2s infinite ' + barstyles.flashing : '',
        borderColor: "#1c1c1f",
        backgroundColor: data && data.color ? data.color : '#1c1c1f',
        "&:hover,&:active": {
          backgroundColor: data && data.color ? data.color : '#1665d8'
        }
      };
    },
    menu: base => ({
      ...base,
      marginTop: 0,
    }),
    menuList: base => ({
      ...base,
      backgroundColor: "#1c1c1f",
      fontSize: 14,
      fontWeight: 500
    }),
  };
};

const localDayStartingTime = localStorage.getItem('default_day_starting_time');

const DEFAULT_FORM = {
  name: '',
  dayStartingTime: localDayStartingTime ? localDayStartingTime : '00:00',
  machine: null,
  performanceFormula: '',
  qualityFormula: '',
  colorThreshold: { a: [60, 80], p: [60, 80], q: [60, 80], oee: [60, 80] }
};

class TimelineDrawer extends Component {

  state = {
    form: { ...DEFAULT_FORM },
    formErr: {
      name: null,
      machine: null
    },
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.editingObject && prevProps.editingObject !== this.props.editingObject) {
      this.setupForm(this.props.editingObject);
    } else if (this.props.isAdding !== prevProps.isAdding && this.props.isAdding) {
      this.setState({
        form: { ...DEFAULT_FORM },
        formErr: {
          name: null,
          machine: null
        }
      });
    }
  }

  setupForm = (obj) => {
    // console.log('Edit', obj)
    let form = { ...DEFAULT_FORM };
    form.name = obj.name;
    form.dayStartingTime = obj.day_starting_time;
    form.colorThreshold = obj.properties.colorThreshold;

    let existingMachine = { ...obj.machine };
    existingMachine.label = existingMachine.name;
    form.machine = existingMachine;
    if (obj.formula_oee) {
      const { p, q } = obj.formula_oee;
      if (p) form.performanceFormula = p;
      if (q) form.qualityFormula = q;
    }
    this.setState({ form });
  }

  onSubmitHandler = (event) => {
    event.preventDefault();
    let e = { ...this.state.formErr };
    if (this.state.form.machine === null) {
      e.machine = true;
      this.setState({ formErr: e });
      return;
    }
    e.machine = false;

    this.props.onSubmit(this.state.form);
    this.setState({ form: { ...DEFAULT_FORM }, formErr: e });
    this.props.closed();
  }

  handleInputChange = (key, value) => {
    let form = { ...this.state.form };
    let formErr = { ...this.state.formErr };
    form[key] = value;
    if (key === 'name') {
      if (value === '')
        formErr[key] = 'Timeline Name is required';
      else
        formErr[key] = '';
    }
    if (key === 'machine') {
      formErr.machine = value === null;
    }
    this.setState({ form, formErr });
  }

  setFormulaHandler = (key, value) => {
    let form = { ...this.state.form };
    if (key === 'p') {
      form.performanceFormula = value;
    } else if (key === 'q') {
      form.qualityFormula = value;
    }
    this.setState({ form });
  }

  handleColorThresholdChange = (key, index, value) => {
    let updatedForm = { ...this.state.form };
    updatedForm.colorThreshold[key][index] = +value;
    this.setState({ form: updatedForm });
  }

  render() {
    let machineOptions = this.props.machines ? this.props.machines.map(m => {
      let el = { ...m };
      el.label = el.name;
      return el;
    }) : [];

    let deviceOptions = [];
    if (this.props.devices) {
      this.props.devices.forEach(device => {
        device.device_sockets.forEach(socket => {
          if (!socket.can_control) {
            deviceOptions.push({
              id: socket.id,
              name: socket.name + " (" + device.name + ")",
              label: socket.name + " (" + device.name + ")",
              secret: socket.device_secret,
              socket: socket.socket,
              unit: socket.unit_name,
              equation: socket.functions,
              can_control: socket.can_control
            });
          }
        });
      });
    }

    const { name, dayStartingTime, machine, performanceFormula, qualityFormula, colorThreshold } = this.state.form;

    return (
      <div className={styles.TimelineDrawer}>
        <form onSubmit={this.onSubmitHandler}>
          <div style={{ display: "flex" }}>
            <Input
              label="Timeline name"
              type="text"
              value={name}
              placeholder="Insert timeline name"
              autoComplete="off"
              onChange={(event) => this.handleInputChange('name', event.target.value)}
              error={this.state.formErr.name}
              overidestyleformgroup={{ marginRight: 10, flex: 2 }}
              required
            />

            <Input
              label="Day Starting Time"
              type="time"
              value={dayStartingTime}
              onChange={(event) => this.handleInputChange('dayStartingTime', event.target.value)}
              required
            />
          </div>

          <label className={styles.Title}>MACHINE *</label>
          <ReactSelect isSearchable options={machineOptions} styles={colourStyles(this.state.formErr.machine)}
            placeholder="Select a machine"
            value={machine}
            onChange={(value) => this.handleInputChange('machine', value)}
            getOptionValue={opt => opt.id}
          />

          <div className={styles.FormulaFormGroup}>
            <label className={styles.Title}>PERFORMANCE FORMULA</label>
            <DeviceEquationArea deviceOptions={deviceOptions} value={performanceFormula} setFormula={(fml) => this.setFormulaHandler('p', fml)} />
          </div>
          <div className={styles.FormulaFormGroup}>
            <label className={styles.Title}>QUALITY FORMULA</label>
            <DeviceEquationArea deviceOptions={deviceOptions} value={qualityFormula} setFormula={(fml) => this.setFormulaHandler('q', fml)} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
            <label className={styles.Title}>Color Threshold *</label>
            <div className={styles.ColorThresholdWrapper}>
              <div className={styles.Label} style={{ width: 100 }}></div>
              <div className={styles.DangerLabel}>Danger ({"<"})</div>
              <div className={styles.WarningLabel}>Warning ({"<"})</div>
            </div>
            <div className={styles.ColorThresholdWrapper}>
              <div className={styles.Label}>Availability %</div>
              <Input
                type="number"
                value={colorThreshold.a[0]}
                onChange={(event) => this.handleColorThresholdChange("a", 0, event.target.value)}
                overidestyleformgroup={{ marginBottom: 0, marginRight: 5 }}
                required
              />
              <Input
                type="number"
                value={colorThreshold.a[1]}
                onChange={(event) => this.handleColorThresholdChange("a", 1, event.target.value)}
                overidestyleformgroup={{ marginBottom: 0 }}
                required
              />
            </div>
            <div className={styles.ColorThresholdWrapper}>
              <div className={styles.Label}>Performance %</div>
              <Input
                type="number"
                value={colorThreshold.p[0]}
                onChange={(event) => this.handleColorThresholdChange("p", 0, event.target.value)}
                overidestyleformgroup={{ marginBottom: 0, marginRight: 5 }}
                required
              />
              <Input
                type="number"
                value={colorThreshold.p[1]}
                onChange={(event) => this.handleColorThresholdChange("p", 1, event.target.value)}
                overidestyleformgroup={{ marginBottom: 0 }}
                required
              />
            </div>
            <div className={styles.ColorThresholdWrapper}>
              <div className={styles.Label}>Quality %</div>
              <Input
                type="number"
                value={colorThreshold.q[0]}
                onChange={(event) => this.handleColorThresholdChange("q", 0, event.target.value)}
                overidestyleformgroup={{ marginBottom: 0, marginRight: 5 }}
                required
              />
              <Input
                type="number"
                value={colorThreshold.q[1]}
                onChange={(event) => this.handleColorThresholdChange("q", 1, event.target.value)}
                overidestyleformgroup={{ marginBottom: 0 }}
                required
              />
            </div>
            <div className={styles.ColorThresholdWrapper}>
              <div className={styles.Label}>OEE %</div>
              <Input
                type="number"
                value={colorThreshold.oee[0]}
                onChange={(event) => this.handleColorThresholdChange("oee", 0, event.target.value)}
                overidestyleformgroup={{ marginBottom: 0, marginRight: 5 }}
                required
              />
              <Input
                type="number"
                value={colorThreshold.oee[1]}
                onChange={(event) => this.handleColorThresholdChange("oee", 1, event.target.value)}
                overidestyleformgroup={{ marginBottom: 0 }}
                required
              />
            </div>
          </div>

          <div style={{ height: 70, width: 'auto' }} />
          <React.Fragment>
            <Button
              type="submit"
              name='Done'
              color="primary"
            />
            <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
          </React.Fragment>
        </form>


      </div>
    );
  }
}

export default TimelineDrawer;