import * as actionTypes from './types';

// ----------------------- GET SCHEDULER -----------------------
export const getFormulasStart = () => {
  return {
    type: actionTypes.GET_FORMULAS_START
  };
}

export const getFormulas = (org_id, references) => {
  return {
    type: actionTypes.GET_FORMULAS,
    org_id, references
  };
}

export const getFormulasSuccess = (response) => {
  return {
    type: actionTypes.GET_FORMULAS_SUCCESS,
    formulas: response.formulas,
    result: response.result
  };
}

export const getFormulasFail = (error) => {
  return {
    type: actionTypes.GET_FORMULAS_FAIL,
    error
  };
}

// ----------------------- ADD FORMULA -----------------------
export const addFormulaStart = () => {
  return {
    type: actionTypes.ADD_FORMULA_START
  };
}

export const addFormula = (formula) => {
  return {
    type: actionTypes.ADD_FORMULA,
    formula
  };
}

export const addFormulaSuccess = (response) => {
  return {
    type: actionTypes.ADD_FORMULA_SUCCESS,
    addedFormula: response.formulas[0],
    result: response.result
  };
}

export const addFormulaFail = (error) => {
  return {
    type: actionTypes.ADD_FORMULA_FAIL,
    error
  };
}

// ----------------------- EDIT FORMULA -----------------------
export const editFormulaStart = () => {
  return {
    type: actionTypes.EDIT_FORMULA_START
  };
}

export const editFormula = (id, formula) => {
  return {
    type: actionTypes.EDIT_FORMULA,
    id, formula
  };
}

export const editFormulaSuccess = (response) => {
  return {
    type: actionTypes.EDIT_FORMULA_SUCCESS,
    updatedFormula: response.formulas[0],
    result: response.result
  };
}

export const editFormulaFail = (error) => {
  return {
    type: actionTypes.EDIT_FORMULA_FAIL,
    error
  };
}

// ----------------------- DELETE FORMULA -----------------------
export const deleteFormulaStart = () => {
  return {
    type: actionTypes.DELETE_FORMULA_START
  };
}

export const deleteFormula = (id) => {
  return {
    type: actionTypes.DELETE_FORMULA,
    id
  };
}

export const deleteFormulaSuccess = (result) => {
  return {
    type: actionTypes.DELETE_FORMULA_SUCCESS,
    result
  };
}

export const deleteFormulaFail = (error) => {
  return {
    type: actionTypes.DELETE_FORMULA_FAIL,
    error
  };
}

// ----------------------- GET FORMULA_MAPPINGS -----------------------
export const getFormulaMappingsStart = () => {
  return {
    type: actionTypes.GET_FORMULA_MAPPINGS_START
  };
}

export const getFormulaMappings = (org_id, references) => {
  return {
    type: actionTypes.GET_FORMULA_MAPPINGS,
    org_id, references
  };
}

export const getFormulaMappingsSuccess = (response) => {
  return {
    type: actionTypes.GET_FORMULA_MAPPINGS_SUCCESS,
    formulaMappings: response.maps
  };
}

export const getFormulaMappingsFail = (error) => {
  return {
    type: actionTypes.GET_FORMULA_MAPPINGS_FAIL,
    error
  };
}

// ----------------------- ADD FORMULA_MAPPING -----------------------
export const postFormulaMappingStart = () => {
  return {
    type: actionTypes.POST_FORMULA_MAPPING_START
  };
}

export const postFormulaMapping = (id, ref) => {
  return {
    type: actionTypes.POST_FORMULA_MAPPING,
    id, ref
  };
}

export const postFormulaMappingSuccess = (response) => {
  return {
    type: actionTypes.POST_FORMULA_MAPPING_SUCCESS,
    updatedFormulaMapping: response.maps[0],
    result: response.result
  };
}

export const postFormulaMappingFail = (error) => {
  return {
    type: actionTypes.POST_FORMULA_MAPPING_FAIL,
    error
  };
}

// ----------------------- DELETE FORMULA_MAPPING -----------------------
export const deleteFormulaMappingStart = () => {
  return {
    type: actionTypes.DELETE_FORMULA_MAPPING_START
  };
}

export const deleteFormulaMapping = (ref) => {
  return {
    type: actionTypes.DELETE_FORMULA_MAPPING,
    ref
  };
}

export const deleteFormulaMappingSuccess = (result) => {
  return {
    type: actionTypes.DELETE_FORMULA_MAPPING_SUCCESS,
    result
  };
}

export const deleteFormulaMappingFail = (error) => {
  return {
    type: actionTypes.DELETE_FORMULA_MAPPING_FAIL,
    error
  };
}
