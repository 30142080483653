import React, { Component } from 'react';

import styles from './Production.module.scss';
import PoPlan from '../../components/Production/PoPlan/PoPlan';

const PRODUCTION_LIST = ['Plans'];

class Production extends Component {
  state = {
    currentTabIndex: 0,
  };

  render() {
    return (
      <div className={styles.Production}>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              PRODUCTION_LIST.map((menuTitle, index) => (
                <div key={`production-${index}`} className={index === this.state.currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentProductionIndex: index })}>
                  <span className={styles.MenuTitle}>{menuTitle}</span>
                </div>
              ))
            }
          </div>
        </div>
        <div className="MainContentPadding">
          {this.state.currentTabIndex === 0 && <PoPlan />}
        </div>
      </div>
    );
  }
}

export default Production;
