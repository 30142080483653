import * as actionTypes from './types';

// ----------------------- GET_SEQUENTIAL_COMMAND -----------------------
export const getSequentialCommandStart = () => {
  return {
    type: actionTypes.GET_SEQUENTIAL_COMMAND_START
  };
}

export const getSequentialCommand = (id) => {
  return {
    type: actionTypes.GET_SEQUENTIAL_COMMAND,
    id
  };
}

export const getSequentialCommandSuccess = (response) => {
  return {
    type: actionTypes.GET_SEQUENTIAL_COMMAND_SUCCESS,
    result: response.result,
    sequentialCommand: response.sequence_command
  };
}

export const getSequentialCommandFail = (error) => {
  return {
    type: actionTypes.GET_SEQUENTIAL_COMMAND_FAIL,
    error
  };
}

// ----------------------- GET_SEQUENTIAL_COMMANDS ALL -----------------------
export const getSequentialCommandsStart = () => {
  return {
    type: actionTypes.GET_SEQUENTIAL_COMMANDS_START
  };
}

export const getSequentialCommands = (secret, socket, wid) => {
  return {
    type: actionTypes.GET_SEQUENTIAL_COMMANDS,
    secret, socket, wid
  };
}

export const getSequentialCommandsSuccess = (response, wid) => {
  return {
    type: actionTypes.GET_SEQUENTIAL_COMMANDS_SUCCESS,
    result: response.result,
    sequentialCommands: response.sequence_commands,
    wid
  };
}

export const getSequentialCommandsFail = (error) => {
  return {
    type: actionTypes.GET_SEQUENTIAL_COMMANDS_FAIL,
    error
  };
}

// ----------------------- START_SEQUENTIAL_COMMAND -----------------------
export const startSequentialCommandStart = () => {
  return {
    type: actionTypes.START_SEQUENTIAL_COMMAND_START
  };
}

export const startSequentialCommand = (secret, socket, values, ref) => {
  return {
    type: actionTypes.START_SEQUENTIAL_COMMAND,
    secret, socket, values, ref
  };
}

export const startSequentialCommandSuccess = (response) => {
  return {
    type: actionTypes.START_SEQUENTIAL_COMMAND_SUCCESS,
    result: response.result,
    sequentialCommand: response.sequence_command
  };
}

export const startSequentialCommandFail = (error) => {
  return {
    type: actionTypes.START_SEQUENTIAL_COMMAND_FAIL,
    error
  };
}


// ----------------------- STOP_SEQUENTIAL_COMMAND -----------------------
export const stopSequentialCommandStart = () => {
  return {
    type: actionTypes.STOP_SEQUENTIAL_COMMAND_START
  };
}

export const stopSequentialCommand = (id) => {
  return {
    type: actionTypes.STOP_SEQUENTIAL_COMMAND,
    id
  };
}

export const stopSequentialCommandSuccess = (response) => {
  return {
    type: actionTypes.STOP_SEQUENTIAL_COMMAND_SUCCESS,
    result: response.result,
    sequentialCommand: response.sequence_command
  };
}

export const stopSequentialCommandFail = (error) => {
  return {
    type: actionTypes.STOP_SEQUENTIAL_COMMAND_FAIL,
    error
  };
}

// export default {
//   getSequentialCommand,
//   getSequentialCommandStart,
//   getSequentialCommandSuccess,
//   getSequentialCommandFail,
//   startSequentialCommand,
//   startSequentialCommandStart,
//   startSequentialCommandSuccess,
//   startSequentialCommandFail,
//   stopSequentialCommand,
//   stopSequentialCommandStart,
//   stopSequentialCommandSuccess,
//   stopSequentialCommandFail
// };