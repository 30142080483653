export const GET_ERROR_LOGS_START = 'GET_ERROR_LOGS_START';
export const GET_ERROR_LOGS = 'GET_ERROR_LOGS';
export const GET_ERROR_LOGS_SUCCESS = 'GET_ERROR_LOGS_SUCCESS';
export const GET_ERROR_LOGS_FAIL = 'GET_ERROR_LOGS_FAIL';

export const ADD_ERROR_LOG_START = 'ADD_ERROR_LOG_START';
export const ADD_ERROR_LOG = 'ADD_ERROR_LOG';
export const ADD_ERROR_LOG_SUCCESS = 'ADD_ERROR_LOG_SUCCESS';
export const ADD_ERROR_LOG_FAIL = 'ADD_ERROR_LOG_FAIL';

export const UPDATE_ERROR_LOG_START = 'UPDATE_ERROR_LOG_START';
export const UPDATE_ERROR_LOG = 'UPDATE_ERROR_LOG';
export const UPDATE_ERROR_LOG_SUCCESS = 'UPDATE_ERROR_LOG_SUCCESS';
export const UPDATE_ERROR_LOG_FAIL = 'UPDATE_ERROR_LOG_FAIL';
