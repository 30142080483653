import React, { Component } from 'react';

import styles from '../SystemDeviceConfig.module.scss';
import SimpleSwitch from '../../../UI/SimpleSwitch/SimpleSwitch';
import ControlForm from './ControlForm';

class ControlSettings extends Component {
  state = {
    controlSocket: [],
    currentMenu: 0,
  }

  componentDidMount() {
    this.setupControlSockets();
    if (!this.props.hasAlreadyBeenToControlTab) {
      this.props.beenToControlTab();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentConfigs && prevProps.currentConfigs !== this.props.currentConfigs) {
      this.setupControlSockets();
    }
  }

  setupControlSockets = () => {
    const { controlForms } = this.props;
    let controls = [];

    if (this.props.hasAlreadyBeenToControlTab && this.props.controlList && this.props.controlList.length) {
      controls = [...this.props.controlList];
    } else {
      if (this.props.currentConfigs.enable_outputs && this.props.currentConfigs.enable_outputs.length) {
        controls = this.props.currentConfigs.enable_outputs;
        // if (controls.length > controlForms.length) {
        //   controls = controls.slice(0, controlForms.length - 1);
        // } else {
        let newControls = [];
        controlForms.forEach((_, i) => newControls.push(controls[i] ? controls[i] : false));
        controls = newControls;
        // }
      } else {
        controlForms.forEach(() => { controls.push(false); });
      }
    }

    this.setState({ controlSocket: controls });
    this.props.setControlList(controls);
  }

  onChangeControlHandler = (index) => {
    const updatedControlSocket = [...this.state.controlSocket];
    const updatedDeviceSocketForm = [...this.props.deviceSocketForm];
    updatedControlSocket[index] = !this.state.controlSocket[index];
    this.setState({ controlSocket: updatedControlSocket });
    this.props.setControlList(updatedControlSocket);

    const foundControlIndex = updatedDeviceSocketForm.findIndex(sdf => sdf.id === this.props.controlForms[index].id);
    if (!updatedControlSocket[index]) {
      updatedDeviceSocketForm[foundControlIndex].is_valid = true;
    } else {
      updatedDeviceSocketForm[foundControlIndex].is_valid = updatedDeviceSocketForm[foundControlIndex].name !== '';
    }
    this.props.setDeviceSocketForm(updatedDeviceSocketForm);
  }

  handleAnalogUpdate = socket => {
    const { setDeviceSocketForm } = this.props;
    const updatedDeviceSocketForm = [...this.props.deviceSocketForm];
    for (let i = 0; i < updatedDeviceSocketForm.length; i++) {
      if (socket.id === updatedDeviceSocketForm[i].id) {
        updatedDeviceSocketForm[i] = { ...socket };
        updatedDeviceSocketForm[i].is_valid = updatedDeviceSocketForm[i].name !== '';
        setDeviceSocketForm(updatedDeviceSocketForm);
        break;
      }
    }
  }

  render() {
    const { controlSocket, currentMenu } = this.state;
    const { controlForms } = this.props;
    if (controlSocket.length !== controlForms.length || controlForms.length <= 0) return null;

    return (
      <div className={styles.Container}>
        <div className={styles.MenuWrapper}>
          {
            controlForms ? controlForms.map((control, i) =>
              <div key={`control-${i}`} className={currentMenu === i ? `${styles.Menu} ${styles.Active}` : styles.Menu} onClick={() => this.setState({ currentMenu: i })}>
                <div style={{ flex: 1, color: control.is_valid ? '' : '#e6492d' }} className={styles.Label}>{control.name ? control.name : 'Control - ' + control.id}</div>
                <SimpleSwitch enable={controlSocket[i]} onChangeEnableHandler={() => this.onChangeControlHandler(i)} />
              </div>
            )
              : null
          }
        </div>
        <div className={styles.ContentWrapper}>
          {
            controlSocket[currentMenu] ?
              <ControlForm
                socket={controlForms[currentMenu]}
                handleAnalogUpdate={this.handleAnalogUpdate}
              /> : null
          }
        </div>
      </div>
      // <div>
      //   <div className={styles.Label}>Control Settings</div>
      //   {
      //     analogSockets ? analogSockets.map((control, i) =>
      //       <div key={`control-${i}`} className={styles.ModbusWrapper}>
      //         <div style={{ flex: 1 }}>Control {analogSockets[i].name}</div>
      //         <SimpleSwitch enable={controlSocket[i]} onChangeEnableHandler={() => this.onChangeControlHandler(i)} />
      //       </div>
      //     )
      //       : null
      //   }
      // </div>
    );
  }
}

export default ControlSettings;