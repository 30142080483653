import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './AnomalyDetection.module.scss';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import Button from '../../components/UI/Button/Button';
import AnomalyList from '../../components/AnomalyDetection/AnomalyList/AnomalyList';
import AddNewAnomaly from '../../components/AnomalyDetection/AddNewAnomaly/AddNewAnomaly';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';

import { getDevices } from '../../../state/ducks/Devices/actions';
import { getMachines } from '../../../state/ducks/Machine/actions';

class AnomalyDetection extends Component {
  state = {
    isAdding: false,
    currentTabIndex: 0,
    editingAnomaly: null,
    selectedAnomalyIndex: null,
    anomalyList: [],
    isDeleteModalOpened: false
  }

  componentDidMount() {
    this.props.getMachines(this.props.currentOrg);
    this.props.getDevices(this.props.currentOrg, true);
    let anomalyList = localStorage.getItem('sight_mocking_anomaly');
    if (anomalyList) anomalyList = JSON.parse(anomalyList);
    else anomalyList = [];
    this.setState({ anomalyList });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.props.getDevices(this.props.currentOrg, true);
      this.props.getMachines(this.props.currentOrg);
      this.closeAddNewAnomalyHandler();
    }
  }

  onAddAnomalyHandler = () => {
    this.setState({ isAdding: true, editingAnomaly: null, openAnomalyForm: true });
  }

  onEditAnomalyHandler = (index) => {
    this.setState({ isAdding: false, editingAnomaly: this.state.anomalyList[index], openAnomalyForm: true, selectedAnomalyIndex: index });
  }

  closeAddNewAnomalyHandler = () => {
    this.setState({ openAnomalyForm: false, isAdding: false, editingAnomaly: null, selectedAnomalyIndex: null })
  }

  onSaveAnomalyHandler = (updatedAnomalyList) => {
    this.setState({ anomalyList: updatedAnomalyList });
    this.closeAddNewAnomalyHandler();
  }

  onToggleEnableAnomalyHandler = (event, index) => {
    event.stopPropagation();
    const anomalyList = [...this.state.anomalyList];
    if (anomalyList[index].calibratedTime !== '') {
      anomalyList[index].enable = !anomalyList[index].enable;
      localStorage.setItem('sight_mocking_anomaly', JSON.stringify(anomalyList));
      this.setState({ anomalyList, openAnomalyForm: false });
    }
  }

  onDeleteAnomalyHandler = () => {
    this.setState({ isDeleteModalOpened: true });
  }

  deleteModalClickHandler = () => {
    this.setState(prevState => {
      return { isDeleteModalOpened: !prevState.isDeleteModalOpened }
    });
  }

  onConfirmDeleteAnomaly = (event) => {
    event.preventDefault();
    const anomalyList = [...this.state.anomalyList];
    anomalyList.splice(this.state.selectedAnomalyIndex, 1);
    localStorage.setItem('sight_mocking_anomaly', JSON.stringify(anomalyList));
    this.setState({ anomalyList, selectedAnomalyIndex: null, isDeleteModalOpened: false });
  }

  render() {
    return (
      <div className={styles.AnomalyDetection}>
        <div className={styles.AdTopBar}>
          <div className={styles.TopMenuAd}>
            <div style={{ display: 'flex', flex: 1 }}>
              <div className={styles.MenuWrapper}>

                <div className={this.state.currentTabIndex === 0 ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentTabIndex: 0 })}>
                  <span className={styles.MenuTitle}>Configure</span>
                </div>
                {/* <div className={this.state.currentTabIndex === 1 ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentTabIndex: 1 })}>
                  <span className={styles.MenuTitle}>History</span>
                </div> */}

              </div>
            </div>
            {
              this.state.currentTabIndex === 0
                ? <div className={styles.AddButtonWrapper}>
                  <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Anomaly" noMargin click={this.onAddAnomalyHandler} />
                </div>
                : null
            }
          </div>
        </div>
        {
          this.state.currentTabIndex === 0 ?
            <div className={styles.AdBody}>
              <div className={styles.AdTopics}>
                <div className={styles.AdFix}>
                  <AnomalyList
                    anomalyList={this.state.anomalyList}
                    onEditAnomaly={this.onEditAnomalyHandler}
                    selectedAnomalyIndex={this.state.selectedAnomalyIndex}
                    closed={this.closeAddNewAnomalyHandler}
                    toggleEnableAnomaly={this.onToggleEnableAnomalyHandler}
                  />
                </div>
              </div>

              <div className={styles.AdDetails}>
                {
                  this.state.openAnomalyForm &&
                  <AddNewAnomaly
                    isAdding={this.state.isAdding}
                    editingAnomaly={this.state.editingAnomaly}
                    selectedAnomalyIndex={this.state.selectedAnomalyIndex}
                    closed={this.closeAddNewAnomalyHandler}
                    saveAnomalySuccessfully={this.onSaveAnomalyHandler}
                    toggleDeleteModal={this.onDeleteAnomalyHandler}
                  />
                }
              </div>
            </div>
            : null
        }
        <DeleteModal
          title="Anomaly Detection"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeleteAnomaly}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  return { currentOrg };
};

export default connect(mapStateToProps, { getDevices, getMachines })(AnomalyDetection);