import * as actionTypes from './types';

export const clearReqResCount = () => {
  return {
    type: actionTypes.CLEAR_REQ_RES_COUNT
  };
}

export const saveRequest = (request, properties) => {
  return {
    type: actionTypes.SAVE_REQUEST,
    request, properties
  };
}

// ----------------------- GET POWERS -----------------------
export const getPowersStart = () => {
  return {
    type: actionTypes.GET_POWERS_START
  };
}

export const getAwaitingPowers = (apiParams, properties) => {
  return {
    type: actionTypes.GET_AWAITING_POWERS,
    apiParams, properties
  };
}

export const getPowers = (secret, socket, start, end, cal_range, range_unit, machine_id, need_efficiency, properties) => {
  return {
    type: actionTypes.GET_POWERS,
    secret, socket, start, end, cal_range, range_unit, machine_id, need_efficiency, properties
  };
}

export const getPowersSuccess = (response, properties) => {
  return {
    type: actionTypes.GET_POWERS_SUCCESS,
    powers: {
      data: response.data,
      properties
    },
    result: response.result
  };
}

export const getPowersFail = (error) => {
  return {
    type: actionTypes.GET_POWERS_FAIL,
    error
  };
}

// ----------------------- GET POWERS -----------------------
export const getPowersJsonStart = () => {
  return {
    type: actionTypes.GET_POWERS_JSON_START
  };
}

export const getPowersJson = (body, properties) => {
  return {
    type: actionTypes.GET_POWERS_JSON,
    body, properties
  };
}

export const getPowersJsonSuccess = (response, properties) => {
  return {
    type: actionTypes.GET_POWERS_JSON_SUCCESS,
    powers: {
      data: response.data,
      properties
    },
    result: response.result
  };
}

export const getPowersJsonFail = (error) => {
  return {
    type: actionTypes.GET_POWERS_JSON_FAIL,
    error
  };
}