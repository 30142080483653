import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import styles from './OperatorTable.module.scss';
import Button from '../../UI/Button/Button';
import StartStopPOIcon from '../../../../assets/icons/startAndStopPO.svg';
import LoadingTable from '../../UI/LoadingSkeleton/LoadingTable/LoadingTable';
import SearchIcon from '../../../../assets/icons/search.svg';
import PrintIcon from '../../../../assets/icons/Operator/printing-black.png';
// import SearchBar from './SearchBar';
import ActualProgressBar from '../../UI/ActualProgressBar/ActualProgessBar';

import { getAdvanceProductionPlans } from '../../../../state/ducks/AdvanceProductionPlan/actions';
import { calculateOee } from '../../../../state/ducks/AdvanceActualProduction/actions';
import { getDataEval, getDataLatest, getDatasetCal, getDataCal } from '../../../../state/ducks/Data/actions';

import ReactToPrint from "react-to-print";
import PrintoutPO from '../StopOperatorDrawer/PrintoutPO';

class OperatorTable extends Component {
	state = {
		headers: [],
		selectedPOIndex: -1,
		selectedActualIndex: -1,
		isModalOpened: false,
		autoSearchIndex: 0,
		showSearchBar: false,
		displayingPlans: []
	}

	componentDidMount() {
		if (this.props.structure && !this.state.headers.length) {
			this.setUpHeaders(this.props.structure);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.structure && prevProps.structure !== this.props.structure) {
			this.setUpHeaders(this.props.structure);
		} else if (this.props.advanceProductionPlans !== prevProps.advanceProductionPlans) {
			this.calculateEachCurrentTargets(this.props.advanceProductionPlans);
			this.props.selectPlan(this.props.advanceProductionPlans[this.state.selectedPOIndex]);
			if (this.props.advanceProductionPlans[this.state.selectedPOIndex] && !this.isEndYet(this.props.advanceProductionPlans[this.state.selectedPOIndex].actuals, true)) {
				this.props.selectActual(this.props.advanceProductionPlans[this.state.selectedPOIndex].actuals[0]);
			}
		} else if (this.props.defaultOperatorMachine !== prevProps.defaultOperatorMachine) {
			this.onAutoSearchPlans(this.state.autoSearchIndex);
		} else if (this.props.isLastAdvSearch !== prevProps.isLastAdvSearch && this.props.isLastAdvSearch) {
			this.setState({ autoSearchIndex: -1, selectedPOIndex: -1, selectedActualIndex: -1 });
		} else if (this.props.StartStopResult !== prevProps.StartStopResult && this.props.StartStopResult === 'success') {
			if ((prevProps.startedProduction !== this.props.startedProduction) || (prevProps.stoppedProduction !== this.props.stoppedProduction)) {
				const { latestQueryParams } = this.props;
				if (this.state.autoSearchIndex >= 0) {
					this.onAutoSearchPlans(this.state.autoSearchIndex, true);
				} else {
					this.props.getAdvanceProductionPlans(
						this.props.currentOrg,
						this.props.structure.uuid,
						latestQueryParams.start,
						latestQueryParams.end,
						false,
						latestQueryParams.po ? latestQueryParams.po : '',
						latestQueryParams.target ? latestQueryParams.target : '',
						latestQueryParams.machine ? latestQueryParams.machine.id : '',
						latestQueryParams.columns ? latestQueryParams.columns : '',
						true
					);
				}
			}
		} else if (this.props.dataEval && prevProps.dataEval !== this.props.dataEval) {
			// DP 05/08/22
			const updatedPlans = [...this.state.displayingPlans];
			const { actual, machineId, planUuid } = this.props.dataEval.properties;
			const foundPlanIndex = updatedPlans.findIndex(up => up.uuid === planUuid);
			if (foundPlanIndex !== -1) {
				const foundActualIndex = updatedPlans[foundPlanIndex].actuals.findIndex(act => act.uuid === actual.uuid);
				if (foundActualIndex !== -1) {
					updatedPlans[foundPlanIndex].actuals[foundActualIndex].actualNow = this.props.dataEval.data;
					updatedPlans[foundPlanIndex].actualTargets = _.reduce(updatedPlans[foundPlanIndex].actuals, (memo, b) => { return memo + (b.actualNow ? b.actualNow : b.total); }, 0);
				}
			}
			this.setState({ displayingPlans: updatedPlans });
		} else if (prevProps.clearSelection !== this.props.clearSelection && this.props.clearSelection) {
			this.setState({ selectedPOIndex: -1, selectedActualIndex: -1 });
		}
		if (this.props.actualOee && prevProps.actualOee !== this.props.actualOee) {
			const updatedPlans = [...this.state.displayingPlans];
			const { actualUuid, planUuid } = this.props.actualOee.properties;
			const foundPlanIndex = updatedPlans.findIndex(up => up.uuid === planUuid);
			if (foundPlanIndex !== -1) {
				const foundActualIndex = updatedPlans[foundPlanIndex].actuals.findIndex(act => act.uuid === actualUuid);
				if (foundActualIndex !== -1) {
					updatedPlans[foundPlanIndex].actuals[foundActualIndex].actualNow = this.props.actualOee.sum ? this.props.actualOee.sum.total : 0;
					updatedPlans[foundPlanIndex].actualTargets = _.reduce(updatedPlans[foundPlanIndex].actuals, (memo, b) => { return memo + (b.actualNow ? b.actualNow : b.total); }, 0);
				}
			}
			this.setState({ displayingPlans: updatedPlans });
		}
	}

	componentWillUnmount() {
		if (this.dataForPOInterval && Object.keys(this.dataForPOInterval).length) {
			Object.keys(this.dataForPOInterval).forEach(actualUuid => clearInterval(this.dataForPOInterval[actualUuid]));
			this.dataForPOInterval = null;
		}
	}

	// isThisTheRightData = (data, comp) => {
	//     if (data.properties.planUuid === comp.uuid) {
	//         return true;
	//     }
	//     return false;
	// }

	calculateEachCurrentTargets = (advanceProductionPlans) => {
		let isFloating = false;
		if (this.props.moduleConfigs) {
			isFloating = this.props.moduleConfigs.find(mc => mc.key === 'isFloating');
			isFloating = isFloating && isFloating.value === 'true' ? true : false;
		}
		const updatedPlans = [...advanceProductionPlans];
		let isSomeActualsRunning = [];
		if (!this.dataForPOInterval) this.dataForPOInterval = {};
		if (updatedPlans && updatedPlans.length) {
			updatedPlans.forEach((plan, i) => {
				if (plan.actuals && plan.actuals.length) {
					plan.actuals.forEach(actual => {
						if (actual.machine && this.isActualRunning(actual.end)) {
							isSomeActualsRunning[i] = true;
							// const { total_device_secret, total_device_socket, id, status_device_secret, status_device_socket } = actual.machine;
							// let method = 'sum';
							// if (actual.machine.is_total_device_accumulated) {
							// 	method = 'inc';
							// }
							// const actualsFromStart = '{ra:' + method + '(' + total_device_secret + '@' + total_device_socket + '@' + moment(actual.start).valueOf() + '-now())}';
							// this.props.getDataEval(actualsFromStart, { planUuid: plan.uuid, actual, machineId: id });
							// this.props.getDataLatest(status_device_socket, status_device_secret, null, null, { planUuid: uuid, actualUuid: actual.uuid, machineId: id });
							this.props.calculateOee(actual.uuid, null, new Date(actual.start).getTime(), new Date().getTime(), 60, { planUuid: plan.uuid, actualUuid: actual.uuid }, isFloating);
							clearInterval(this.dataForPOInterval[actual.uuid]);
							this.dataForPOInterval[actual.uuid] = setInterval(() => {
								// this.props.getDataEval(actualsFromStart, { planUuid: plan.uuid, actual, machineId: id });
								// this.props.getDataLatest(status_device_socket, status_device_secret, null, null, { planUuid: uuid, actualUuid: actual.uuid, machineId: id });
								this.props.calculateOee(actual.uuid, null, new Date(actual.start).getTime(), new Date().getTime(), 60, { planUuid: plan.uuid, actualUuid: actual.uuid }, isFloating);
							}, 10 * 1000);
						} else {
							if (this.dataForPOInterval[actual.uuid]) clearInterval(this.dataForPOInterval[actual.uuid]);
						}
					});

					if (!isSomeActualsRunning[i]) {
						// if there is actuals but all done, we will calculate actualTargets from actual.total
						updatedPlans[i].actualTargets = _.reduce(updatedPlans[i].actuals, (memo, b) => { return memo + b.total; }, 0);
					}
				}
			});
		}
		this.setState({ displayingPlans: updatedPlans });
	}

	isActualRunning = (time) => {
		return time === '0001-01-01T00:00:00Z';
	}

	whatIsDefaultMachineId = () => {
		const foundDefaultMachine = JSON.parse(localStorage.getItem("OwlOperatorDefaultMachine"));
		return (foundDefaultMachine && foundDefaultMachine[this.props.currentOrg]) ? foundDefaultMachine[this.props.currentOrg].id : null;
	}

	setUpHeaders = (structure) => {
		const { columns, po_column_order, start_column_order, end_column_order, target_column_order, machine_column_order } = structure;
		const currentColumns = [];
		currentColumns[po_column_order - 1] = { name: 'po', display_name: 'PO' };
		currentColumns[start_column_order - 1] = { name: 'start', display_name: 'Start' };
		currentColumns[end_column_order - 1] = { name: 'end', display_name: 'End' };
		currentColumns[target_column_order - 1] = { name: 'target', display_name: 'Target' };
		currentColumns[machine_column_order - 1] = { name: 'machine', display_name: 'Machine' };
		if (columns && columns.length) {
			columns.forEach(col => currentColumns[col.order - 1] = { name: col.name, display_name: col.display_name });
		}

		let start = new Date().setHours(0, 0, 0, 0);
		let stop = new Date().setHours(23, 59, 59, 999);
		this.props.getAdvanceProductionPlans(this.props.currentOrg, this.props.structure.uuid, start, stop, false, null, null, this.whatIsDefaultMachineId());
		this.setState({ headers: currentColumns, selectedPOIndex: -1, selectedActualIndex: -1, autoSearchIndex: 0, showSearchBar: false, isModalOpened: false });
		this.props.setHeaders(currentColumns);
	}

	onSelectActualHandler = (index, pressStopButton, event) => {
		this.props.selectActual(
			this.props.advanceProductionPlans[this.state.selectedPOIndex].actuals[index]
		);
		if (pressStopButton) {
			event.stopPropagation();
			this.props.openPlanDrawer();
			this.setState({ selectedActualIndex: index === this.state.selectedActualIndex ? index : -1 });
		} else {
			this.setState({ selectedActualIndex: index === this.state.selectedActualIndex ? -1 : index });
		}
	}

	onSelectPOHandler = (index, pressStartButton, event) => {
		this.props.selectPlan(this.props.advanceProductionPlans[index]);
		this.props.selectActual(null);
		if (pressStartButton) {
			event.stopPropagation();
			this.props.openPlanDrawer();
			this.setState({ selectedPOIndex: index });
		} else {
			this.setState({ selectedPOIndex: index === this.state.selectedPOIndex ? -1 : index });
		}
		this.setState({ selectedActualIndex: -1 });
	}

	isEndYet = (actual, checkAllActual) => {
		if (checkAllActual) {
			return !actual.find(ac => ac.end === '0001-01-01T00:00:00Z');
		}
		return actual !== '0001-01-01T00:00:00Z';
	}

	onAutoSearchPlans = (index, stillSelect) => {
		if (this.props.currentOrg && this.props.structure) {
			let start = new Date().setHours(0, 0, 0, 0);
			let stop = new Date().setHours(23, 59, 59, 999);
			if (index === 1) {
				// THIS WEEK
				start = moment().day(0).add(1, 'days').startOf('day').valueOf();
				stop = moment().day(7).endOf('day').valueOf();
			} else if (index === 2) {
				// THISMONTH
				start = moment().startOf('month').valueOf();
				stop = moment().endOf('month').valueOf();
			}

			if (index !== 3) {
				this.props.getAdvanceProductionPlans(this.props.currentOrg, this.props.structure.uuid, start, stop, false, null, null, this.whatIsDefaultMachineId());
			}

			this.setState(prevState => {
				return {
					autoSearchIndex: index,
					showSearchBar: index === 3 ? !prevState.showSearchBar : prevState.showSearchBar,
					selectedPOIndex: stillSelect ? prevState.selectedPOIndex : -1,
					selectedActualIndex: stillSelect ? 0 : -1
				};
			});
		}
	}

	// onSearchPlansHandler = (searchTexts) => {
	// 	const others = {};
	// 	Object.keys(searchTexts).forEach(key => {
	// 		if (key !== 'po' && key !== 'start' && key !== 'end' && key !== 'target' && key !== 'machine' && searchTexts[key] !== '') {
	// 			others[key] = searchTexts[key];
	// 		}
	// 	});
	// 	this.props.getAdvanceProductionPlans(
	// 		this.props.currentOrg,
	// 		this.props.structure.uuid,
	// 		moment(searchTexts.start),
	// 		moment(searchTexts.end).endOf('day').valueOf(),
	// 		searchTexts.po ? searchTexts.po : '',
	// 		searchTexts.target ? searchTexts.target : '',
	// 		searchTexts.machine ? searchTexts.machine.id : '',
	// 		others
	// 	);
	// }

	onSearchingHandler = (e) => {
		const search = e.target.value.toLowerCase();
		if (this.props.advanceProductionPlans && this.props.advanceProductionPlans.length) {
			const searchResult = this.props.advanceProductionPlans.filter(p => {
				const status = p.actuals ? (this.isEndYet(p.actuals, true) ? 'completed' : 'running') : '';
				if (p.po.toLowerCase().includes(search)) return p;
				else if (p.target === +search) return p;
				else if (p.machine && p.machine.name.toLowerCase().includes(search)) return p;
				else if (status.includes(search)) return p;
				else if (p.columns) {
					let found = false;
					Object.keys(p.columns).map(key => {
						if (p.columns[key].toLowerCase().includes(search)) found = true;
					});
					if (found) return p;
				}
			});
			this.setState({ displayingPlans: searchResult });
		}
	}

	displayCustomColumns = (value, name, plan) => {
		const pattern = /^\d+$/;
		if (value) {
			if (pattern.test(value)) return (+value).toLocaleString();
			return value;
		}
		else if (!value && plan.columns[name]) return plan.columns[name];

		return '-';
	}

	render() {
		if (!this.props.structure) {
			return (
				<div className={styles.HeaderTableWrapper}>
					<div className={styles.LeftSection}>
						<div className={styles.HeaderText} style={{ textAlign: 'center' }}>
							{
								this.props.structureLoading
									? 'Loading...'
									: 'Structure not found'
							}
						</div>
					</div>
				</div>
			);
		}

		const { displayingPlans } = this.state;

		return (
			<>
				<div className={styles.HeaderTableWrapper}>
					<div className={styles.LeftSection}>
						<div className={styles.AutoFillBoxWrapper}>
							<div className={[styles.AutoFillBox, this.state.autoSearchIndex === 0 ? styles.Selected : ''].join(' ')} onClick={() => this.onAutoSearchPlans(0)}>TODAY</div>
							<div className={[styles.AutoFillBox, this.state.autoSearchIndex === 1 ? styles.Selected : ''].join(' ')} onClick={() => this.onAutoSearchPlans(1)}>THIS WEEK</div>
							<div className={[styles.AutoFillBox, this.state.autoSearchIndex === 2 ? styles.Selected : ''].join(' ')} onClick={() => this.onAutoSearchPlans(2)}>THIS MONTH</div>
						</div>
						<div className={styles.SearchInputWrapper}>
							<img className={styles.SearchIconInput} style={{ verticalAlign: 'middle', width: 12 }} src={SearchIcon} alt="Search" />
							<input className={styles.SearchExpand} type="text" placeholder="Search" onChange={this.onSearchingHandler} />
							<div className={styles.AdvancedSearch} onClick={this.props.openAdvancedSearchDrawer}>Advanced Search</div>
						</div>

					</div>
				</div>

				<div className={styles.HeaderTableWrapper} style={{ margin: '20px 0' }}>
					<div style={{ display: 'flex' }}>
						<Button
							type="button" color="primary" noMargin
							overideButtonStyles={{ height: 50, marginRight: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', alignContent: 'space-evenly' }}
							click={() => this.props.openPlanDrawer('startpo')}>
							<div style={{ marginRight: 12 }}><div className={`${styles.StartPoIcon} ${styles.Big}`} /></div> START
						</Button>
						<Button
							type="button" color="red" noMargin
							overideButtonStyles={{ height: 50, marginLeft: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', alignContent: 'space-evenly' }}
							click={() => this.props.openPlanDrawer('stoppo')}>
							<div style={{ marginRight: 12 }}><div className={`${styles.StopPoIcon} ${styles.Big}`} /></div> STOP
						</Button>
					</div>
				</div>

				<div className={styles.TableWrapper}>
					<table>
						<thead>
							<tr>
								{/* <th style={{ textAlign: 'center' }}>No.</th> */}
								{
									this.state.headers.map(header =>
										header.name !== 'target'
											? <th key={`Header-${header.name}`}>{header.display_name}</th>
											: <React.Fragment key={"Header-Targets"}>
												{/* <th>Actual</th> */}
												<th>{header.display_name}</th>
												{/* <th>Progress</th> */}
											</React.Fragment>
									)
								}
								<th>Progress</th>
								{/* <th style={{ width: 120, textAlign: 'center' }}>Status</th> */}
								<th style={{ minWidth: 100 }}>
									<div className={styles.StatusLegendWrapper}>
										<div>
											<div className={styles.Legend}><div className={styles.BlueDot}></div>In Progress</div>
											<div className={styles.Legend}><div className={styles.GreenDot}></div>Completed</div>
										</div>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{/* {this.state.showSearchBar
								? <SearchBar columns={this.state.headers} searchPlans={this.onSearchPlansHandler} />
								: null
							} */}
							{
								this.props.loading
									? <tr><td colSpan={this.state.headers.length + 1}><LoadingTable /></td></tr>
									: displayingPlans && displayingPlans.length ?
										displayingPlans
											// .sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf())
											.map((plan, index) => (
												<React.Fragment key={`poplans-${index + 1}`}>
													<tr
														key={`poplans-${index + 1}`}
														className={this.state.selectedPOIndex === -1 ? '' : this.state.selectedPOIndex === index ? styles.Selected : styles.Unselected}
														onClick={() => this.onSelectPOHandler(index, false)}
													>
														{/* <td style={{ textAlign: 'center' }}>{index + 1}.</td> */}
														{
															this.state.headers.map(header => (
																header.name !== 'target'
																	? <td key={`${header.name}-${index}`}>
																		{
																			header.name === 'start' || header.name === 'end'
																				? moment(plan[header.name]).format('DD/MM/YY HH:mm')
																				: header.name === 'machine'
																					? plan.machine ? plan.machine.name : '-'
																					: plan[header.name]
																						? plan[header.name]
																						: plan.columns[header.name] ? plan.columns[header.name] : '-'
																		}
																	</td>
																	: <React.Fragment key={`actual-target-${index}`}>
																		{/* <td>{(plan.actualTargets).toLocaleString()}</td> */}
																		<td>{plan.actualTargets ? (plan.actualTargets).toLocaleString() : 0}/{plan[header.name].toLocaleString()}</td>
																	</React.Fragment>
															))
														}
														<td style={{ minWidth: window.matchMedia("(max-width: 900px)").matches ? 0 : 150 }}>
															<ActualProgressBar
																noBar={window.matchMedia("(max-width: 900px)").matches}
																value={plan.target && plan.actualTargets ? plan.actualTargets * 100 / plan.target : null}
																running={plan.actuals ? this.isEndYet(plan.actuals, true) ? false : true : false}
															/>
														</td>
														<td>
															<div className={`${styles.SwitchStartStopPo} ${styles.Start}`} onClick={(e) => this.onSelectPOHandler(index, true, e)}>
																<div style={{ marginRight: 7, opacity: this.state.selectedPOIndex === -1 ? 1 : this.state.selectedPOIndex === index ? 1 : 0.5 }} className={styles.StartPoIcon} />
																{/* <img style={{ width: 16, marginRight: 7, opacity: this.state.selectedPOIndex === -1 ? 1 : this.state.selectedPOIndex === index ? 1 : 0.5 }} src={StartStopPOIcon} /> */}
																START
															</div>
														</td>
														{/* <td style={{ textAlign: 'center' }}>
														<div className={`${styles.StatusBox} ${plan.actuals ? this.isEndYet(plan.actuals, true) ? styles.Completed : styles.Running : ''}`}>
															{plan.actuals ? this.isEndYet(plan.actuals, true) ? 'Completed' : 'Running' : ''}
														</div>
													</td> */}
													</tr>
													{
														this.state.selectedPOIndex === index && plan.actuals
															? plan.actuals.map((actual, ai) => (
																<tr
																	key={`${actual.uuid}`}
																	className={`${styles.ActualRow} ${this.state.selectedActualIndex === ai ? styles.SelectedActual : ''} ${this.isEndYet(actual.end) ? styles.Completed : styles.Running}`}
																// onClick={() => this.onSelectActualHandler(ai, false)}
																>
																	{/* <td></td> */}
																	{
																		this.state.headers.map((header, subrowindex) => (
																			header.name !== 'target'
																				? <td key={`actual-row-${header.name}`}>
																					{
																						header.name === 'start' || header.name === 'end'
																							? actual[header.name] === '0001-01-01T00:00:00Z' ? 'In Progress' : moment(actual[header.name]).format('DD/MM/YY HH:mm')
																							: header.name === 'machine'
																								? actual.machine?.name
																								: header.name === 'po'
																									? ''
																									: actual[header.name]
																										? actual[header.name]
																										: this.displayCustomColumns(actual.columns[header.name], header.name, plan)
																					}
																				</td>
																				: <React.Fragment key={`actual-row--target-${subrowindex}`}>
																					{/* <td>{actual.total.toLocaleString()}</td> */}
																					<td>{actual.actualNow ? actual.actualNow.toLocaleString() : actual.total.toLocaleString()}/{actual.target.toLocaleString()}</td>
																				</React.Fragment>
																		))
																	}
																	<td>
																		<ActualProgressBar
																			small
																			noBar={window.matchMedia("(max-width: 900px)").matches}
																			value={actual.actualNow ? actual.actualNow * 100 / actual.target : actual.total * 100 / actual.target}
																			running={!this.isEndYet(actual.end)}
																		/>
																	</td>
																	<td>
																		{
																			this.isActualRunning(actual.end)
																				? <div className={`${styles.SwitchStartStopPo} ${styles.Stop}`} onClick={(e) => this.onSelectActualHandler(ai, true, e)}>
																					{/* <img style={{ width: 16, marginRight: 7, opacity: this.state.selectedPOIndex === -1 ? 1 : this.state.selectedPOIndex === index ? 1 : 0.5 }} src={StartStopPOIcon} /> */}
																					<div style={{ marginRight: 10, opacity: this.state.selectedPOIndex === -1 ? 1 : this.state.selectedPOIndex === index ? 1 : 0.5 }} className={styles.StopPoIcon} />
																					STOP
																				</div>
																				:
																				<div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																					<ReactToPrint
																						trigger={() => <div className={styles.PrintButtonWrapper}><img src={PrintIcon} style={{ width: 20, height: 20 }} /><a href="#"></a></div>}
																						content={() => this.componentRef}
																					/>
																					<div style={{ display: 'none' }}>
																						<PrintoutPO
																							ref={el => (this.componentRef = el)}
																							customCols={actual.columns}
																							updatedCustomCols={actual.columns}
																							po={plan.po}
																							machine={actual.machine}
																							total={actual.total}
																							ok={actual.ok}
																							defect={actual.defect}
																							org={this.props.orgs && this.props.currentOrg ? this.props.orgs.find(o => o.id === this.props.currentOrg).name : 'n/a'}
																							actual={actual}
																							loggedInUser={this.props.loggedInUser}
																						/>
																					</div>
																				</div>
																		}
																	</td>
																	{/* <td style={{ textAlign: 'center' }}>
																	<div className={`${styles.ActualStatusBox} ${this.isEndYet(actual.end) ? styles.Completed : styles.Running}`}>
																		{this.isEndYet(actual.end) ? 'Completed' : 'Running'}
																	</div>
																</td> */}
																</tr>
															))
															: null

													}
												</React.Fragment>
											)) : <tr><td colSpan="100%" style={{ textAlign: 'center' }}>No PO plans found</td></tr>
							}
						</tbody>
					</table>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	const { loggedInUser } = state.auth;
	const { currentOrg, orgs } = state.org;
	const { planStructures } = state.advanceProductionPlanStructure;
	const { advanceProductionPlans, loading, latestQueryParams } = state.advanceProductionPlan;
	const { startedProduction, stoppedProduction } = state.advanceActualProduction;
	const { defaultOperatorMachine } = state.machine;
	const { actualOee } = state.advanceActualProduction;
	const { dataEval, dataLatest, datasetCal, dataCal } = state.data;
	const { moduleConfigs } = state.module;

	return {
		loggedInUser, currentOrg, orgs, advanceProductionPlans, loading, latestQueryParams,
		planStructures, structureLoading: state.advanceProductionPlanStructure.loading,
		defaultOperatorMachine: defaultOperatorMachine[currentOrg],
		startedProduction, stoppedProduction, StartStopResult: state.advanceActualProduction.result,
		dataEval, dataLatest, datasetCal, dataCal, actualOee,
		moduleConfigs
	};
}

export default connect(mapStateToProps, { getAdvanceProductionPlans, getDataEval, getDataLatest, getDatasetCal, getDataCal, calculateOee })(OperatorTable);

