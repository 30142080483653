import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import MultipleSelect from 'react-select';

import styles from './SchedulerDrawer.module.scss';
import { updateObject, checkValidity } from '../../../../state/utils';
import Button from '../../UI/Button/Button';
import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';
import Input from '../../UI/Input/Input';

import { addScheduler, editScheduler, getSchedulerTypes } from '../../../../state/ducks/Scheduler/actions';
import { DELETE_SCHEDULER_SUCCESS } from '../../../../state/ducks/Scheduler/types';

const colourStyles = {
	control: (base, state) => ({
		...base,
		backgroundColor: "#1c1c1f",
		borderRadius: 4,
		borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
		"&:hover": {
			borderColor: "#1665d8"
		},
		fontSize: 14,
		fontWeight: 500,
		color: 'white'
	}),
	input: base => ({
		...base,
		color: 'white'
	}),
	singleValue: (styles) => ({
		...styles,
		color: "white"
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			borderColor: "#1c1c1f",
			backgroundColor: isDisabled
				? null
				: isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
		};
	},
	menu: base => ({
		...base,
		marginTop: 0,
		zIndex: 1000
	}),
	menuList: base => ({
		...base,
		backgroundColor: "#1c1c1f",
		fontSize: 14,
		fontWeight: 500
	}),
	multiValue: base => ({
		...base,
		backgroundColor: "#1665d8",
	}),
	multiValueLabel: base => ({
		...base,
		color: "white",
	})
};

const REPEAT_OPTION = [{ id: 'none', label: 'None' }, { id: 'daily', label: 'Every day' }, { id: 'weekly', label: 'Every week' }, { id: 'monthly', label: 'Every month' }, { id: 'yearly', label: 'Every year' }];
const ENDS_REPEAT = [{ id: 'never', label: 'Never' }, { id: 'ends', label: 'At the date' }];
const DEFAULT_SCHEDULER_FORM = {
	name: {
		value: '',
		valid: false,
		touched: false,
		validation: {
			required: true
		}
	},
	startDate: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	startTime: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	endDate: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	endTime: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	machine: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: false
		}
	},
	repeat: {
		value: REPEAT_OPTION[0],
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	endsRepeat: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	typeId: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
};

class SchedulerDrawer extends Component {
	state = {
		formIsValid: true,
		schedulerForm: DEFAULT_SCHEDULER_FORM,
		machinesOption: [],
		repeatOption: REPEAT_OPTION,
		isRepeatEnd: false,
		typeOption: []
	}

	componentDidMount() {
		if (this.props.machines) {
			this.setMachinesOption(this.props.machines);
		}

		if (this.props.isAdding) {
			this.resetForm();
		} else if (this.props.editingScheduler) {
			this.setupForm(this.props.editingScheduler);
		}

		if (!this.props.schedulerTypes || !this.state.typeOption.length) {
			this.props.getSchedulerTypes(this.props.currentOrg);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isAdding !== this.props.isAdding && this.props.isAdding) {
			this.resetForm();
		} else if (this.props.machines !== prevProps.machines && this.props.machines) {
			this.setMachinesOption(this.props.machines);
		} else if (prevProps.result !== this.props.result && this.props.result === 'success') {
			if (!_.isEqual(this.props.updatedSchedulers, prevProps.updatedSchedulers) || this.props.addedScheduler !== prevProps.addedScheduler || this.props.type === DELETE_SCHEDULER_SUCCESS) {
				this.props.closed();
				this.resetForm();
			}
		} else if (this.props.editingScheduler && prevProps.editingScheduler !== this.props.editingScheduler) {
			this.setupForm(this.props.editingScheduler);
		}

		if (this.props.schedulerTypes !== prevProps.schedulerTypes && this.props.schedulerTypes) {
			const typeOption = [];
			this.props.schedulerTypes.forEach(st => {
				typeOption.push({
					id: st.id,
					label: st.name + ' (' + st.category + ')',
					color: st.color
				});
			});
			this.setState({ typeOption });
		}
	}

	onClearFields = () => {
		this.setState({ schedulerForm: DEFAULT_SCHEDULER_FORM, formIsValid: false });
	}

	setMachinesOption = (machines) => {
		const machinesOption = [];
		machines.forEach(machine => {
			machinesOption.push({
				id: machine.id,
				label: machine.name
			});
		});
		this.setState({ machinesOption });
	}

	resetForm = () => {
		const schedulerForm = { ...DEFAULT_SCHEDULER_FORM };
		for (let inputIdentifier in schedulerForm) {
			const startTimeValue = moment().valueOf();
			const endTimeValue = moment().valueOf();
			if (inputIdentifier === 'startDate') {
				schedulerForm.startDate.value = moment(startTimeValue).format('YYYY-MM-DD');
			} else if (inputIdentifier === 'startTime') {
				schedulerForm.startTime.value = moment(startTimeValue).format('HH:mm');
			} else if (inputIdentifier === 'endDate') {
				schedulerForm.endDate.value = moment(endTimeValue).format('YYYY-MM-DD');
			} else if (inputIdentifier === 'endTime') {
				schedulerForm.endTime.value = moment(endTimeValue).format('HH:mm');
			} else if (inputIdentifier === 'repeat') {
				schedulerForm.repeat.value = REPEAT_OPTION[0];
			} else if (inputIdentifier === 'endsRepeat') {
				schedulerForm.endsRepeat.value = moment(endTimeValue).format('YYYY-MM-DD');
			} else if (inputIdentifier === 'machine') {
				schedulerForm[inputIdentifier].value = '';
				schedulerForm[inputIdentifier].valid = true;
				schedulerForm[inputIdentifier].touched = true;
			} else {
				schedulerForm[inputIdentifier].value = '';
				schedulerForm[inputIdentifier].valid = false;
				schedulerForm[inputIdentifier].touched = false;
			}
		}
		this.setState({ schedulerForm, formIsValid: false });
	}

	setupForm = (scheduler) => {
		let updatedForm = { ...DEFAULT_SCHEDULER_FORM };
		let startDateValue = moment(scheduler.start).format('YYYY-MM-DD');
		const startTimeValue = moment(scheduler.start).format('HH:mm');
		let endDateValue = moment(scheduler.end).format('YYYY-MM-DD');
		const endTimeValue = moment(scheduler.end).format('HH:mm');
		if (scheduler.frequency !== 'none') {
			startDateValue = moment(scheduler.clickedEvent.start).format('YYYY-MM-DD');
			// if (scheduler.frequency === 'daily') {
			endDateValue = scheduler.clickedEvent.end ? moment(scheduler.clickedEvent.end).format('YYYY-MM-DD') : moment(scheduler.clickedEvent.start).format('YYYY-MM-DD');
			// } else {
			// 	endDateValue = moment(scheduler.clickedEvent.start).format('YYYY-MM-DD');
			// }
		}

		for (let inputIdentifier in updatedForm) {
			let updatedElement;
			if (inputIdentifier === 'name') {
				updatedElement = updateObject(updatedForm[inputIdentifier], {
					value: scheduler.title, valid: true, touched: true,
				});
			} else if (inputIdentifier === 'startDate') {
				updatedElement = updateObject(updatedForm[inputIdentifier], {
					value: startDateValue, valid: true, touched: true,
				});
			} else if (inputIdentifier === 'startTime') {
				updatedElement = updateObject(updatedForm[inputIdentifier], {
					value: startTimeValue, valid: true, touched: true,
				});
			} else if (inputIdentifier === 'endDate') {
				updatedElement = updateObject(updatedForm[inputIdentifier], {
					value: endDateValue, valid: true, touched: true,
				});
			} else if (inputIdentifier === 'endTime') {
				updatedElement = updateObject(updatedForm[inputIdentifier], {
					value: endTimeValue, valid: true, touched: true,
				});
			} else if (inputIdentifier === 'machine') {
				const foundMachines = [];
				scheduler.machine_ids.forEach(mid => {
					foundMachines.push(this.state.machinesOption.find(mo => mo.id === mid));
				});
				updatedElement = updateObject(updatedForm[inputIdentifier], {
					value: foundMachines, valid: true, touched: true,
				});
			} else if (inputIdentifier === 'repeat') {
				const foundFrequency = REPEAT_OPTION.find(ro => ro.id === scheduler.frequency);
				updatedElement = updateObject(updatedForm[inputIdentifier], {
					value: foundFrequency, valid: true, touched: true,
				});
			} else if (inputIdentifier === 'endsRepeat') {
				const hasRepeat = !this.isNoEnding(scheduler.end_repeat);
				this.setState({ isRepeatEnd: hasRepeat });
				updatedElement = updateObject(updatedForm[inputIdentifier], {
					value: hasRepeat ? moment(scheduler.end_repeat).format('YYYY-MM-DD') : endDateValue, valid: true, touched: true,
				});
			} else if (inputIdentifier === 'typeId') {
				const foundType = this.state.typeOption.find(to => to.id === scheduler.type_id);
				updatedElement = updateObject(updatedForm[inputIdentifier], {
					value: foundType, valid: true, touched: true,
				});
			}

			updatedForm = updateObject(updatedForm, {
				[inputIdentifier]: updatedElement
			});
		}
		this.setState({ schedulerForm: updatedForm, formIsValid: true });
	}

	isNoEnding = (time) => {
		return time === "0001-01-01T00:00:00Z" || time === "1970-01-01T07:00:00+07:00" || time === "1970-01-01T00:00:00Z";
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		const updatedElement = updateObject(this.state.schedulerForm[name], {
			value,
			valid: name === 'machine' || name === 'repeat' || name === 'typeId'
				? name === 'machine' && !value ? false : true
				: checkValidity(value, this.state.schedulerForm[name].validation),
			touched: true
		});

		const updatedForm = updateObject(this.state.schedulerForm, {
			[name]: updatedElement
		});

		let formIsValid = true;
		for (let inputIdentifier in updatedForm) {
			formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({ schedulerForm: updatedForm, formIsValid });
	}

	humanizeDuration = (ms, showSec) => {
		const days = moment.duration(ms).days();
		const hours = moment.duration(ms).hours();
		const minutes = moment.duration(ms).minutes();
		const seconds = moment.duration(ms).seconds();

		let result = days === 0 ? '' : days > 1 ? days + ' days ' : days + ' day ';
		result += hours === 0 ? '' : hours > 1 ? hours + ' hours ' : hours + ' hour ';
		result += minutes === 0 ? '' : minutes > 1 ? minutes + ' minutes ' : minutes + ' minute ';
		if (showSec) {
			result += seconds === 0 ? '' : seconds > 1 ? seconds + ' seconds ' : seconds + ' second';
		}
		return result;
	}

	onConfirmSaveSchedulerHandler = (event) => {
		event.preventDefault();
		const { name, startDate, startTime, endDate, endTime, machine, repeat, endsRepeat, typeId } = this.state.schedulerForm;
		if (this.props.isAdding) {
			// org_id, title, type_id, machine_ids, start, end, end_repeat, description, frequency, properties
			this.props.addScheduler(
				this.props.currentOrg,
				name.value,
				typeId.value.id,
				machine.value ? machine.value.map((x) => x.id).join(',') : '',
				new Date(startDate.value + ' ' + startTime.value).getTime(),
				new Date(endDate.value + ' ' + endTime.value).getTime(),
				this.state.isRepeatEnd ? new Date(endsRepeat.value + ' ' + endTime.value).getTime() : '',
				'',
				repeat.value.id,
				{}
			);
		} else {
			if (repeat.value.id === 'none') {
				this.props.editScheduler(
					this.props.editingScheduler.id,
					new Date(startDate.value + ' ' + startTime.value).getTime(),
					name.value,
					typeId.value.id,
					machine.value ? machine.value.map((x) => x.id).join(',') : '',
					new Date(startDate.value + ' ' + startTime.value).getTime(),
					new Date(endDate.value + ' ' + endTime.value).getTime(),
					'',
					'',
					'none',
					false,
					{}
				);
			} else {
				this.props.onToggleRepeatingModalHandler(this.state.schedulerForm, this.state.isRepeatEnd, false);
			}
		}
	}

	onClickDeleteHandler = (event) => {
		event.preventDefault();
		const { repeat } = this.state.schedulerForm;
		if (repeat.value.id === 'none') {
			this.props.toggleDeleteModal();
		} else {
			this.props.onToggleRepeatingModalHandler(this.state.schedulerForm, this.state.isRepeatEnd, true);
		}
	}

	render() {
		const { name, startDate, startTime, endDate, endTime, machine, repeat, endsRepeat, typeId } = this.state.schedulerForm;
		const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

		return (
			<div>
				<form onSubmit={this.onConfirmSaveSchedulerHandler}>
					<Input
						label="Scheduler name"
						name="name"
						type="text"
						value={name.value}
						autoComplete="off"
						onChange={this.handleChange}
						required
					/>

					<div className={styles.FormGroup}>
						<label className={styles.Title}>Type *</label>
						<MultipleSelect options={this.state.typeOption} styles={colourStyles}
							placeholder="Please select type"
							value={typeId.value}
							onChange={(value) => this.handleChange({ target: { name: 'typeId', value } })}
							getOptionValue={opt => opt.id}
						/>
					</div>

					<div className={styles.FormGroup}>
						<label className={styles.Title}>Start *</label>
						<div style={{ display: 'flex', position: 'relative' }}>
							{!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
							<input
								className={styles.SelectBox}
								style={{ paddingLeft: !isChrome ? 46 : null, flex: 2, position: 'initial' }}
								type="date"
								onChange={this.handleChange}
								name="startDate"
								value={startDate.value}
							/>
							<input
								className={styles.SelectBox}
								style={{ position: 'initial' }}
								type="time"
								onChange={this.handleChange}
								name="startTime"
								value={startTime.value}
							/>
						</div>
					</div>

					<div className={styles.FormGroup}>
						<label className={styles.Title}>End *</label>
						<div style={{ display: 'flex', position: 'relative' }}>
							{!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
							<input
								className={styles.SelectBox}
								style={{ paddingLeft: !isChrome ? 46 : null, flex: 2, position: 'initial' }}
								type="date"
								onChange={this.handleChange}
								name="endDate"
								value={endDate.value}
							/>
							<input
								className={styles.SelectBox}
								style={{ position: 'initial' }}
								type="time"
								onChange={this.handleChange}
								name="endTime"
								value={endTime.value}
							/>
						</div>
					</div>

					<div className={styles.FormGroup}>
						<label className={styles.Title}>Repeat *</label>
						<MultipleSelect options={this.state.repeatOption} styles={colourStyles}
							placeholder="Please select repeat"
							value={repeat.value}
							onChange={(value) => this.handleChange({ target: { name: 'repeat', value } })}
							getOptionValue={opt => opt.id}
						/>
					</div>

					{
						repeat.value && repeat.value.id !== 'none'
							? <div className={styles.FormGroup}>
								<label className={styles.Title}>Ends *</label>
								<div style={{ display: 'flex' }}>
									<div style={{ flex: 1 }}>
										<MultipleSelect options={[{ id: 'never', label: 'Never' }, { id: 'ends', label: 'At the date' }]} styles={colourStyles}
											placeholder="Please select ends"
											value={this.state.isRepeatEnd ? ENDS_REPEAT[1] : ENDS_REPEAT[0]}
											onChange={(value) => this.setState({ isRepeatEnd: value.id === 'never' ? false : true })}
											getOptionValue={opt => opt.id}
										/>
									</div>
									{
										this.state.isRepeatEnd
											? <div style={{ display: 'flex', position: 'relative' }}>
												{!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
												<input
													className={styles.SelectBox}
													style={{ paddingLeft: !isChrome ? 46 : null, flex: 2, position: 'initial' }}
													type="date"
													onChange={this.handleChange}
													name="endsRepeat"
													value={endsRepeat.value}
												/>
											</div>
											: null
									}
								</div>
							</div>
							: null
					}

					<div className={styles.FormGroup}>
						<label className={styles.Title}>Machine *</label>
						<MultipleSelect isMulti isSearchable closeMenuOnSelect={false} options={this.state.machinesOption} styles={colourStyles}
							placeholder="Please select machine"
							value={machine.value}
							onChange={(value) => this.handleChange({ target: { name: 'machine', value } })}
							getOptionValue={opt => opt.id}
						/>
					</div>
					<div className="ErrorText">{this.props.error ? this.props.error : ''}</div>

					<div style={{ height: 70, width: 'auto' }} />
					{
						this.props.isAdding
							? <>
								<Button
									type="submit"
									name={this.props.eventLoading ? 'Loading...' : 'Done'}
									color="primary"
									disabled={!this.state.formIsValid}
									loading={this.props.eventLoading}
								/>
								<Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
							</>
							: <>
								<Button
									type="submit"
									name={this.props.eventLoading ? 'Loading...' : 'Save'}
									color="green"
									disabled={!this.state.formIsValid}
									loading={this.props.eventLoading}
								/>
								<Button type="button" name="Delete" color="borderred" click={this.onClickDeleteHandler} />
							</>
					}
				</form >
			</div >
		);
	}
}

const mapStateToProps = (state) => {
	const { currentOrg } = state.org;
	const { addedScheduler, updatedSchedulers, type, result, error, schedulerTypes, addedSchedulerType, updatedSchedulerType } = state.scheduler;
	const { machines } = state.machine;

	return {
		currentOrg, machines,
		addedScheduler, type, result, updatedSchedulers, error, schedulerTypes, addedSchedulerType, updatedSchedulerType
	};
};

export default connect(mapStateToProps, { addScheduler, editScheduler, getSchedulerTypes })(SchedulerDrawer);