import React, { Component } from 'react';

import styles from './TeamCard.module.scss';
import AddIcon from '../../../../assets/icons/plus-icon.svg';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import EditHoverIcon from '../../../../assets/icons/edit.svg';
import Tooltip from '../../UI/ToolTip/Tooltip';

class TeamCard extends Component {
  state = {
    editIcon: EditIcon
  }

  onEditTeamHandler = (event) => {
    event.stopPropagation();
    this.props.editingTeamHandler(this.props.teamIndex);
  }

  render() {
    if (this.props.addingCard) {
      return (
        <div className={`${styles.Card} ${styles.CardAddingStyle}`} onClick={this.props.click}>
          <img src={AddIcon} alt="Add" className={styles.AddIcon} />
          <div className={styles.CardBody} style={{ textAlign: 'center', padding: '0 30px' }}>
            <p className={styles.CardTitle} style={{ marginTop: 0 }}>Add New Team</p>
            <p className={styles.CardSubTitle}>Create your new team and invite team members</p>
          </div>
        </div>
      );
    } else {
      if (this.props.data) {
        const { name, detail } = this.props.data;
        return (
          <div
            className={`${styles.Card} ${styles.CardFlexStyle}`}
            onClick={this.onEditTeamHandler}
            onMouseOver={() => { this.setState({ editIcon: EditHoverIcon }) }}
            onMouseOut={() => { this.setState({ editIcon: EditIcon }) }}
          >
            <div className={`${styles.CardBody} ${styles.Flex}`}>
              <div className={styles.CardTitle}>{name}</div>
              <div className={styles.CardSubTitle}>{detail}</div>
            </div>
            <div className={styles.CardFooter}>
              <div className={styles.FooterWrapper}>
                {
                  this.props.teamMembers.map((member, i) => {
                    if (i === 0) {
                      return (
                        <Tooltip overideIcon text={`${member.first_name} ${member.last_name}`} key={`member${member.id}_profilepicture`}>
                          <img src={member.profile_picture} alt="profile pic" className={styles.MembersTeamProfilePicture} />
                        </Tooltip>
                      );
                    } else if (i < 3) {
                      return (
                        <Tooltip overideIcon text={`${member.first_name} ${member.last_name}`} key={`member${member.id}_profilepicture`} overideTitleStyles={{ marginLeft: '-4px', width: 150 }}>
                          <img src={member.profile_picture} alt="profile pic" className={`${styles.MembersTeamProfilePicture} ${styles.Over}`} />
                        </Tooltip>
                      );
                    } else if (i === 3) {
                      return <div key="more_profilepicture" className={`${styles.MembersTeamProfilePicture} ${styles.More} ${styles.Over}`}>+{this.props.teamMembers.length - 3}</div>;
                    }
                    return null;
                  })
                }

                <div className={styles.IconWrapper}>
                  <img src={this.state.editIcon} alt="Edit Team" style={{ width: 20 }} />
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        // can show a loading card!
        return null;
      }

    }

  }
}

export default TeamCard;