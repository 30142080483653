import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './NoteType.module.scss';
import EditIcon from '../../../assets/icons/edit-gray.svg';

import { getNoteTypes } from '../../../state/ducks/Document/actions';
import { DELETE_NOTE_TYPE_SUCCESS } from '../../../state/ducks/Document/types';

class NoteTypeList extends Component {
	componentDidMount() {
		this.props.getNoteTypes(this.props.currentOrg);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg) {
			this.props.getNoteTypes(this.props.currentOrg);
		} else if (this.props.result !== prevProps.result && this.props.result === 'success') {
			if ((prevProps.addedNoteType !== this.props.addedNoteType) || (prevProps.updatedNoteType !== this.props.updatedNoteType
				|| this.props.type === DELETE_NOTE_TYPE_SUCCESS)) {
				this.props.getNoteTypes(this.props.currentOrg);
			}
		}
	}

	render() {
		const { noteTypes } = this.props;

		return (
			<div className={styles.NoteType}>
				{
					noteTypes && noteTypes.length
						? noteTypes.map(nt => (
							<div key={`NoteType-${nt.id}`} className={styles.NoteTypeCard}>
								<div className={styles.NoteTypeInfos}>
									<div className={styles.NoteTypeName}>{nt.name}</div>
									<div className={styles.NoteTypeTime}>Created Date : {moment(nt.created_date).format("DD/MM/YYYY HH:mm")}</div>
								</div>
								<div className={styles.ActionButtons}>
									<img src={EditIcon} style={{ width: 20, height: 20, marginLeft: 30 }} alt="Edit Note Type" onClick={(e) => this.props.editNoteType(e, nt)} />
								</div>
							</div>
						))
						: null
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { currentOrg } = state.org;
	const { type, result, noteTypes, addedNoteType, updatedNoteType } = state.document;
	return { currentOrg, type, result, noteTypes, addedNoteType, updatedNoteType };
};

export default connect(mapStateToProps, { getNoteTypes })(NoteTypeList);
