import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  page: null,
  pages: null,
  pageAndMembers: null,
  addedPage: null,
  updatedPage: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAGES_START: return getPagesStart(state, action);
    case actionTypes.GET_PAGES_SUCCESS: return getPagesSuccess(state, action);
    case actionTypes.GET_PAGES_FAIL: return getPagesFail(state, action);

    case actionTypes.GET_PAGE_AND_MEMBERS_START: return getPageAndMembersStart(state, action);
    case actionTypes.GET_PAGE_AND_MEMBERS_SUCCESS: return getPageAndMembersSuccess(state, action);
    case actionTypes.GET_PAGE_AND_MEMBERS_FAIL: return getPageAndMembersFail(state, action);

    case actionTypes.ADD_PAGE_START: return addPageStart(state, action);
    case actionTypes.ADD_PAGE_SUCCESS: return addPageSuccess(state, action);
    case actionTypes.ADD_PAGE_FAIL: return addPageFail(state, action);

    case actionTypes.EDIT_PAGE_START: return editPageStart(state, action);
    case actionTypes.EDIT_PAGE_SUCCESS: return editPageSuccess(state, action);
    case actionTypes.EDIT_PAGE_FAIL: return editPageFail(state, action);

    case actionTypes.DELETE_PAGE_START: return deletePageStart(state, action);
    case actionTypes.DELETE_PAGE_SUCCESS: return deletePageSuccess(state, action);
    case actionTypes.DELETE_PAGE_FAIL: return deletePageFail(state, action);

    case actionTypes.UPDATE_PAGE_AFTER_ADD_WIDGET: return updatePageAfterAddWidget(state, action);

    case actionTypes.ADD_PAGE_MEMBERS_START: return addPageMembersStart(state, action);
    case actionTypes.ADD_PAGE_MEMBERS_SUCCESS: return addPageMembersSuccess(state, action);
    case actionTypes.ADD_PAGE_MEMBERS_FAIL: return addPageMembersFail(state, action);

    case actionTypes.REMOVE_PAGE_MEMBERS_START: return removePageMembersStart(state, action);
    case actionTypes.REMOVE_PAGE_MEMBERS_SUCCESS: return removePageMembersSuccess(state, action);
    case actionTypes.REMOVE_PAGE_MEMBERS_FAIL: return removePageMembersFail(state, action);

    case actionTypes.CHANGE_PAGE_ADMIN_START: return changePageAdminStart(state, action);
    case actionTypes.CHANGE_PAGE_ADMIN_SUCCESS: return changePageAdminSuccess(state, action);
    case actionTypes.CHANGE_PAGE_ADMIN_FAIL: return changePageAdminFail(state, action);

    case actionTypes.LEAVE_PAGE_START: return leavePageStart(state, action);
    case actionTypes.LEAVE_PAGE_SUCCESS: return leavePageSuccess(state, action);
    case actionTypes.LEAVE_PAGE_FAIL: return leavePageFail(state, action);

    default: return state;
  }
};

const getPagesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getPagesSuccess = (state, action) => {
  const pages = action.pages ? action.pages.sort((a, b) => a.created_date - b.created_date): null;
  return updateObject(state, {
    pages,
    error: null,
    loading: false
  });
}

const getPagesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getPageAndMembersStart = (state, action) => {
  return updateObject(state, { result: null, error: null });
}

const getPageAndMembersSuccess = (state, action) => {
  return updateObject(state, {
    pageAndMembers: action.pageAndMembers,
    error: null,
    // loading: false
  });
}

const getPageAndMembersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addPageStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addPageSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedPage: action.addedPage,
    error: null,
    loading: false
  });
}

const addPageFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editPageStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editPageSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedPage: action.updatedPage,
    error: null,
    loading: false
  });
}

const editPageFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deletePageStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deletePageSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deletePageFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const updatePageAfterAddWidget = (state, action) => {
  return updateObject(state, {
    pageAndMembers: action.pageAndMembers,
    loading: false
  });
}

const addPageMembersStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addPageMembersSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedPage: action.updatedPage,
    error: null,
    loading: false
  });
}

const addPageMembersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const removePageMembersStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const removePageMembersSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedPage: action.updatedPage,
    error: null,
    loading: false
  });
}

const removePageMembersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const changePageAdminStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const changePageAdminSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedPage: action.updatedPage,
    error: null,
    loading: false
  });
}

const changePageAdminFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const leavePageStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const leavePageSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const leavePageFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}


export default reducer;