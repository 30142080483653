import * as actionTypes from './types';

// ----------------------- GET USER FAVORITE -----------------------
export const getUserFavoritesStart = () => {
  return {
    type: actionTypes.GET_USER_FAVORITES_START
  };
}

export const getUserFavorites = (org_id, favorite_type) => {
  return {
    type: actionTypes.GET_USER_FAVORITES,
    org_id, favorite_type
  };
}

export const getUserFavoritesSuccess = (response) => {
  return {
    type: actionTypes.GET_USER_FAVORITES_SUCCESS,
    favoriteResult: response.result,
    favorites: response.favorites
  };
}

export const getUserFavoritesFail = (error) => {
  return {
    type: actionTypes.GET_USER_FAVORITES_FAIL,
    error: error
  };
}

// ----------------------- ADD USER FAVORITE -----------------------
export const addUserFavoriteStart = () => {
  return {
    type: actionTypes.ADD_USER_FAVORITE_START
  };
}

export const addUserFavorite = (target_id, favorite_type) => {
  return {
    type: actionTypes.ADD_USER_FAVORITE,
    target_id, favorite_type
  };
}

export const addUserFavoriteSuccess = (response) => {
  return {
    type: actionTypes.ADD_USER_FAVORITE_SUCCESS,
    favoriteResult: response.result,
    addedFavorite: response.favorite
  };
}

export const addUserFavoriteFail = (error) => {
  return {
    type: actionTypes.ADD_USER_FAVORITE_FAIL,
    error: error
  };
}

// ----------------------- DELETE USER FAVORITE -----------------------
export const deleteUserFavoriteStart = () => {
  return {
    type: actionTypes.DELETE_USER_FAVORITE_START
  };
}

export const deleteUserFavorite = (id, target_id, org_id, favorite_type) => {
  return {
    type: actionTypes.DELETE_USER_FAVORITE,
    id, target_id, org_id, favorite_type
  };
}

export const deleteUserFavoriteSuccess = (response) => {
  return {
    type: actionTypes.DELETE_USER_FAVORITE_SUCCESS,
    favoriteResult: response.result,
  };
}

export const deleteUserFavoriteFail = (error) => {
  return {
    type: actionTypes.DELETE_USER_FAVORITE_FAIL,
    error: error
  };
}

// export default {
//   getUserFavoritesStart,
//   getUserFavorites,
//   getUserFavoritesSuccess,
//   getUserFavoritesFail,
//   addUserFavoriteStart,
//   addUserFavorite,
//   addUserFavoriteSuccess,
//   addUserFavoriteFail,
//   deleteUserFavorite,
//   deleteUserFavoriteStart,
//   deleteUserFavoriteSuccess,
//   deleteUserFavoriteFail
// };