import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import Select from 'react-select';

import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';
import styles from '../../Operator/StartOperatorDrawer/OperatorDrawer.module.scss';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

const colourStyles = {
    singleValue: (styles, { data }) => ({
        ...styles,
        color: data.color
    }),
    control: (base, state) => ({
        ...base,
        backgroundColor: "#1c1c1f",
        borderRadius: 4,
        borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
        "&:hover": {
            borderColor: "#1665d8"
        },
        fontSize: 14,
        fontWeight: 500,
    }),
    input: base => ({
        ...base,
        color: 'white'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            borderColor: "#1c1c1f",
            backgroundColor: isDisabled
                ? null
                : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
        };
    },
    menu: base => ({
        ...base,
        marginTop: 0,
    }),
    menuList: base => ({
        ...base,
        backgroundColor: "#1c1c1f",
        fontSize: 14,
        fontWeight: 500
    }),
};

const ORIGIN_OPTION = [{ id: 1, label: 'System' }, { id: 2, label: 'Operator' }, { id: 3, label: 'Others' }];
const STATUS_OPTION = [{ id: 1, label: 'Pending' }, { id: 2, label: 'Attached' }];

class SearchDrawer extends Component {
    state = {
        searchParams: {
            name: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            duration: '',
            machine: null,
            origin: -1,
            status: -1,
        },
        machinesOption: []
    }

    componentDidMount() {
        let machinesOption = [];
        if (this.props.machines) {
            this.props.machines
                .sort((a, b) => a.name.localeCompare(b.name))
                .forEach(machine => {
                    machinesOption.push({
                        id: machine.id,
                        label: machine.name
                    });
                });
        }
        this.setState({ machinesOption });

        if (this.props.savingSearchParams && Object.keys(this.props.savingSearchParams).length) {
            this.setState({ searchParams: this.props.savingSearchParams });
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        const searchParams = { ...this.state.searchParams };
        searchParams[name] = value;
        this.setState({ searchParams });
    }

    render() {
        const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        return (
            <div>
                <form onSubmit={(e) => this.props.searchBreakdownLogs(e, this.state.searchParams)}>
                    <div className={styles.FormGroup}>
                        <Input label="name" name="name" type="text" value={this.state.searchParams.name} onChange={this.handleChange} overidestyleformgroup={{ marginBottom: 0 }} />
                    </div>
                    <div style={{ flex: 1, marginBottom: '1rem' }}>
                        <label className={styles.Title}>Start *</label>
                        <div style={{ display: 'flex', position: 'relative' }}>
                            {/* {!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />} */}
                            <input
                                className={styles.SelectBox}
                                style={{ paddingLeft: !isChrome ? 46 : null, flex: 2 }}
                                type="date"
                                onChange={this.handleChange}
                                name="startDate"
                                value={this.state.searchParams.startDate}
                            />
                            <input
                                className={styles.SelectBox}
                                type="time"
                                onChange={this.handleChange}
                                name="startTime"
                                value={this.state.searchParams.startTime}
                            />
                        </div>
                    </div>
                    <div style={{ flex: 1, marginBottom: '1rem' }}>
                        <label className={styles.Title}>End *</label>
                        <div style={{ display: 'flex', position: 'relative' }}>
                            {/* {!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />} */}
                            <input
                                className={styles.SelectBox}
                                style={{ paddingLeft: !isChrome ? 46 : null, flex: 2 }}
                                type="date"
                                onChange={this.handleChange}
                                name="endDate"
                                value={this.state.searchParams.endDate}
                            />
                            <input
                                className={styles.SelectBox}
                                type="time"
                                onChange={this.handleChange}
                                name="endTime"
                                value={this.state.searchParams.endTime}
                            />
                        </div>
                    </div>
                    <div className={styles.FormGroup}>
                        <Input label="duration" name="duration" type="text" value={this.state.searchParams.duration} placeholder="within (x) mins" onChange={this.handleChange} overidestyleformgroup={{ marginBottom: 0 }} />
                    </div>
                    <div className={styles.FormGroup}>
                        <label className={styles.Title}>Machine *</label>
                        <Select options={this.state.machinesOption} styles={colourStyles}
                            isClearable
                            placeholder="Select a machine"
                            value={this.state.searchParams.machine}
                            onChange={(value) => this.handleChange({ target: { name: 'machine', value } })}
                            getOptionValue={opt => opt.id}
                        />
                    </div>
                    <div className={styles.FormGroup}>
                        <label className={styles.Title}>Origin *</label>
                        <Select options={ORIGIN_OPTION} styles={colourStyles}
                            isClearable
                            placeholder="Select a origin"
                            value={this.state.searchParams.origin}
                            onChange={(value) => this.handleChange({ target: { name: 'origin', value } })}
                            getOptionValue={opt => opt.id}
                        />
                    </div>
                    <div className={styles.FormGroup}>
                        <label className={styles.Title}>Log Status *</label>
                        <Select options={STATUS_OPTION} styles={colourStyles}
                            isClearable
                            placeholder="Select a status"
                            value={this.state.searchParams.status}
                            onChange={(value) => this.handleChange({ target: { name: 'status', value } })}
                            getOptionValue={opt => opt.id}
                        />
                    </div>

                    <div style={{ height: 70, width: 'auto' }} />
                    <Button
                        type="submit"
                        name={'Search'}
                        color="primary"
                    />
                    <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { machines } = state.machine;
    return { currentOrg, machines };
};

export default connect(mapStateToProps, {})(SearchDrawer);