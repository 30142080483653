
import axios from '../../../configs/axios-orders';
import { takeEvery, put, take } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_PAGES, ADD_PAGE, EDIT_PAGE, DELETE_PAGE, GET_PAGE_AND_MEMBERS, ADD_PAGE_MEMBERS, REMOVE_PAGE_MEMBERS, CHANGE_PAGE_ADMIN, LEAVE_PAGE, ADD_PAGE_MEMBERS_SUCCESS, REMOVE_PAGE_MEMBERS_SUCCESS } from './types';

export function* watchPages() {
  yield takeEvery(GET_PAGES, getPagesSaga);
  yield takeEvery(GET_PAGE_AND_MEMBERS, getPageAndMembersSaga);
  yield takeEvery(ADD_PAGE, addPageSaga);
  yield takeEvery(EDIT_PAGE, editPageSaga);
  yield takeEvery(DELETE_PAGE, deletePageSaga);
  yield takeEvery(ADD_PAGE_MEMBERS, addPageMembersSaga);
  yield takeEvery(REMOVE_PAGE_MEMBERS, removePageMembersSaga);
  yield takeEvery(CHANGE_PAGE_ADMIN, changePageAdminSaga);
  yield takeEvery(LEAVE_PAGE, leavePageSaga);
}

export function* getPagesSaga(params) {
  yield put(actions.getPagesStart());

  let api = 'v1/page';
  api += '?org_id=' + params.org_id;
  api += params.user_id ? '&user_id=' + params.user_id : '';
  api += params.name ? '&name=' + params.name : '';
  api += params.page_type ? ('&page_type=' + params.page_type) : '';


  try {
    const response = yield axios.get(api);
    // console.log("GET PAGES SUCCESS", response.data);
    yield put(actions.getPagesSuccess(response.data));
  } catch (err) {
    console.log("GET PAGES FAILED", err)
    yield put(actions.getPagesFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* getPageAndMembersSaga(params) {
  yield put(actions.getPageAndMembersStart());

  let api = 'v1/page/' + params.id;

  try {
    const response = yield axios.get(api);
    // console.log("GET PAGE AND MEMBERS SUCCESS", response.data);
    yield put(actions.getPageAndMembersSuccess(response.data));
  } catch (err) {
    console.log("GET PAGE AND MEMBERS FAILED", err)
    yield put(actions.getPageAndMembersFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* addPageSaga(params) {
  yield put(actions.addPageStart());

  let postData = 'org_id=' + encodeURIComponent(params.org_id);
  postData += "&name=" + encodeURIComponent(params.name);
  postData += params.detail ? '&detail=' + encodeURIComponent(params.detail) : '';
  postData += params.page_type ? '&page_type=' + params.page_type : '';
  postData += params.url ? '&url=' + encodeURIComponent(params.url) : '';
  postData += params.properties ? '&properties=' + JSON.stringify(params.properties) : '';

  const api = 'v1/page';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD PAGE SUCCESS", response);
    yield put(actions.addPageSuccess(response.data));
  } catch (err) {
    console.log("ADD PAGE FAILED", err.response.data);
    yield put(actions.addPageFail(err.response.data.error));
  }
}

export function* editPageSaga(params) {
  yield put(actions.editPageStart());

  let putData = params.name ? 'name=' + encodeURIComponent(params.name) : '';
  putData += params.detail ? '&detail=' + encodeURIComponent(params.detail) : '';
  putData += params.page_type ? '&page_type=' + params.page_type : '';
  putData += params.url ? '&url=' + encodeURIComponent(params.url) : '';
  putData += params.properties ? '&properties=' + JSON.stringify(params.properties) : '';

  const api = 'v1/page/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    if (params.addingMembers) {
      yield put(actions.addPageMembers(
        params.id,
        params.addingMembers
      ));
      yield take(ADD_PAGE_MEMBERS_SUCCESS);
    }
    if (params.deletingMembers) {
      yield put(actions.removePageMembers(
        params.id,
        params.deletingMembers
      ));
      yield take(REMOVE_PAGE_MEMBERS_SUCCESS);
    }
    console.log("EDIT PAGE SUCCESS", response.data);
    yield put(actions.editPageSuccess(response.data));
  } catch (err) {
    console.log("EDIT PAGE FAILED", err.response.data);
    yield put(actions.editPageFail(err.response.data));
  }
}

export function* deletePageSaga(params) {
  yield put(actions.deletePageStart());

  const api = 'v1/page/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE PAGE SUCCESS", response.data);
    yield put(actions.deletePageSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE PAGE FAILED", err.response);
    yield put(actions.deletePageFail(err.response));
  }
}

export function* addPageMembersSaga(params) {
  yield put(actions.addPageMembersStart());

  const api = 'v1/pagemember/' + params.id;
  let postData = 'user_ids=' + params.user_ids;

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD PAGE MEMBERS SUCCESS", response);
    yield put(actions.addPageMembersSuccess(response.data));
  } catch (err) {
    console.log("ADD PAGE MEMBERS FAILED", err.response.data);
    yield put(actions.addPageMembersFail(err.response.data.error));
  }
}

export function* removePageMembersSaga(params) {
  yield put(actions.removePageMembersStart());

  const api = 'v1/pagemember/' + params.id + '?user_ids=' + params.user_ids;

  try {
    const response = yield axios.delete(api, );
    console.log("REMOVE PAGE MEMBERS SUCCESS", response);
    yield put(actions.removePageMembersSuccess(response.data));
  } catch (err) {
    console.log("REMOVE PAGE MEMBERS FAILED", err.response.data);
    yield put(actions.removePageMembersFail(err.response.data.error));
  }
}

export function* changePageAdminSaga(params) {
  yield put(actions.changePageAdminStart());

  const api = 'v1/pageadmin/' + params.id;
  const postData = 'user_id=' + params.user_id;

  try {
    const response = yield axios.post(api, postData, );
    console.log("CHANGE PAGE ADMIN SUCCESS", response.data);
    yield put(actions.changePageAdminSuccess(response.data));
  } catch (err) {
    console.log("CHANGE PAGE ADMIN FAILED", err.response.data);
    yield put(actions.changePageAdminFail(err.response.data.error));
  }
}

export function* leavePageSaga(params) {
  yield put(actions.leavePageStart());

  const api = 'v1/pageleave/' + params.id;

  try {
    const response = yield axios.delete(api, );
    console.log("LEAVE PAGE SUCCESS", response.data);
    yield put(actions.leavePageSuccess(response.data.result));
  } catch (err) {
    console.log("LEAVE PAGE FAILED", err.response);
    yield put(actions.leavePageFail(err.response));
  }
}