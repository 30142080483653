export const GET_VIRTUAL_DEVICES_START = 'GET_VIRTUAL_DEVICES_START';
export const GET_VIRTUAL_DEVICES = 'GET_VIRTUAL_DEVICES';
export const GET_VIRTUAL_DEVICES_SUCCESS = 'GET_VIRTUAL_DEVICES_SUCCESS';
export const GET_VIRTUAL_DEVICES_FAIL = 'GET_VIRTUAL_DEVICES_FAIL';

export const ADD_VIRTUAL_DEVICE_START = 'ADD_VIRTUAL_DEVICE_START';
export const ADD_VIRTUAL_DEVICE = 'ADD_VIRTUAL_DEVICE';
export const ADD_VIRTUAL_DEVICE_SUCCESS = 'ADD_VIRTUAL_DEVICE_SUCCESS';
export const ADD_VIRTUAL_DEVICE_FAIL = 'ADD_VIRTUAL_DEVICE_FAIL';

export const EDIT_VIRTUAL_DEVICE_START = 'EDIT_VIRTUAL_DEVICE_START';
export const EDIT_VIRTUAL_DEVICE = 'EDIT_VIRTUAL_DEVICE';
export const EDIT_VIRTUAL_DEVICE_SUCCESS = 'EDIT_VIRTUAL_DEVICE_SUCCESS';
export const EDIT_VIRTUAL_DEVICE_FAIL = 'EDIT_VIRTUAL_DEVICE_FAIL';

export const DELETE_VIRTUAL_DEVICE_START = 'DELETE_VIRTUAL_DEVICE_START';
export const DELETE_VIRTUAL_DEVICE = 'DELETE_VIRTUAL_DEVICE';
export const DELETE_VIRTUAL_DEVICE_SUCCESS = 'DELETE_VIRTUAL_DEVICE_SUCCESS';
export const DELETE_VIRTUAL_DEVICE_FAIL = 'DELETE_VIRTUAL_DEVICE_FAIL';

export const TEST_VIRTUAL_DEVICE_SCRIPT_START = 'TEST_VIRTUAL_DEVICE_SCRIPT_START';
export const TEST_VIRTUAL_DEVICE_SCRIPT = 'TEST_VIRTUAL_DEVICE_SCRIPT';
export const TEST_VIRTUAL_DEVICE_SCRIPT_SUCCESS = 'TEST_VIRTUAL_DEVICE_SCRIPT_SUCCESS';
export const TEST_VIRTUAL_DEVICE_SCRIPT_FAIL = 'TEST_VIRTUAL_DEVICE_SCRIPT_FAIL';
