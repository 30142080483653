
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { SEARCH_ADVANCE_PRODUCTION_PLANS, GET_ADVANCE_PRODUCTION_PLANS, ADD_ADVANCE_PRODUCTION_PLAN, UPLOAD_ADVANCE_PRODUCTION_PLANS, EDIT_ADVANCE_PRODUCTION_PLAN, DELETE_ADVANCE_PRODUCTION_PLAN } from './types';

export function* watchAdvanceProductionPlans() {
  yield takeEvery(SEARCH_ADVANCE_PRODUCTION_PLANS, searchAdvanceProductionPlansSaga);
  yield takeEvery(GET_ADVANCE_PRODUCTION_PLANS, getAdvanceProductionPlansSaga);
  yield takeEvery(ADD_ADVANCE_PRODUCTION_PLAN, addAdvanceProductionPlanSaga);
  yield takeEvery(UPLOAD_ADVANCE_PRODUCTION_PLANS, uploadAdvanceProductionPlansSaga);
  yield takeEvery(EDIT_ADVANCE_PRODUCTION_PLAN, editAdvanceProductionPlanSaga);
  yield takeEvery(DELETE_ADVANCE_PRODUCTION_PLAN, deleteAdvanceProductionPlanSaga);
}

export function* searchAdvanceProductionPlansSaga(params) {
  yield put(actions.searchAdvanceProductionPlansStart());

  let api = 'v1/advanceproductionplan?org_id=' + params.org_id;
  api += params.po ? "&po=" + params.po : '';

  try {
    const response = yield axios.get(api);
    // console.log("SEARCH ADVANCE_PRODUCTION_PLANS SUCCESS", response.data);
    yield put(actions.searchAdvanceProductionPlansSuccess(response.data));
  } catch (err) {
    // console.log("SEARCH ADVANCE_PRODUCTION_PLANS FAILED", err)
    yield put(actions.searchAdvanceProductionPlansFail(err.response));
  }
}

export function* getAdvanceProductionPlansSaga(params) {
  yield put(actions.getAdvanceProductionPlansStart());

  let api = 'v1/advanceproductionplan?org_id=' + params.org_id;
  api += params.structure_uuid ? "&structure_uuid=" + params.structure_uuid : '';
  api += params.start ? "&start=" + params.start : '';
  api += params.end ? "&end=" + params.end : '';
  api += params.is_running ? "&is_running=" + params.is_running : '';
  api += params.po ? "&po=" + params.po : '';
  api += params.target ? "&target=" + params.target : '';
  api += params.machine_id ? "&machine_id=" + params.machine_id : '';
  api += params.columns ? "&columns=" + JSON.stringify(params.columns) : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET ADVANCE_PRODUCTION_PLANS SUCCESS", response.data);
    yield put(actions.getAdvanceProductionPlansSuccess(response.data, params.saveParams ? params : null));
  } catch (err) {
    console.log("GET ADVANCE_PRODUCTION_PLANS FAILED", err)
    yield put(actions.getAdvanceProductionPlansFail(err.response));
  }
}

export function* addAdvanceProductionPlanSaga(params) {
  yield put(actions.addAdvanceProductionPlanStart());

  let api = 'v1/advanceproductionplan';
  let postData = "structure_uuid=" + params.structure_uuid;
  postData += "&po=" + params.po;
  postData += params.target ? "&target=" + params.target : '';
  postData += params.start ? "&start=" + params.start : '';
  postData += params.end ? "&end=" + params.end : '';
  postData += params.machine_id ? "&machine_id=" + params.machine_id : '';
  postData += params.columns ? "&columns=" + JSON.stringify(params.columns) : '';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD ADVANCE_PRODUCTION_PLAN SUCCESS", response.data);
    yield put(actions.addAdvanceProductionPlanSuccess(response.data));
  } catch (err) {
    console.log("ADD ADVANCE_PRODUCTION_PLAN FAILED", err)
    yield put(actions.addAdvanceProductionPlanFail(err.response));
  }
}

export function* uploadAdvanceProductionPlansSaga(params) {
  yield put(actions.uploadAdvanceProductionPlansStart());

  let api = 'v1/advanceproductionplan';
  let postData = JSON.stringify(params.body);

  try {
    const response = yield axios.post(api, postData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    // console.log("UPLOAD ADVANCE_PRODUCTION_PLANS SUCCESS", response.data);
    yield put(actions.uploadAdvanceProductionPlansSuccess(response.data));
  } catch (err) {
    console.log("UPLOAD ADVANCE_PRODUCTION_PLANS FAILED", err.response)
    yield put(actions.uploadAdvanceProductionPlansFail(err.response.data));
  }
}

export function* editAdvanceProductionPlanSaga(params) {
  yield put(actions.editAdvanceProductionPlanStart());

  let putData = "uuid=" + params.uuid;
  putData += "&po=" + params.po;
  putData += params.target ? "&target=" + params.target : '';
  putData += params.start ? "&start=" + params.start : '';
  putData += params.end ? "&end=" + params.end : '';
  putData += params.machine_id ? "&machine_id=" + params.machine_id : '';
  putData += params.columns ? "&columns=" + JSON.stringify(params.columns) : '';

  const api = 'v1/advanceproductionplan';

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT ADVANCE_PRODUCTION_PLAN SUCCESS", response.data);
    yield put(actions.editAdvanceProductionPlanSuccess(response.data));
  } catch (err) {
    console.log("EDIT ADVANCE_PRODUCTION_PLAN FAILED", err.response.data);
    yield put(actions.editAdvanceProductionPlanFail(err.response.data));
  }
}

export function* deleteAdvanceProductionPlanSaga(params) {
  yield put(actions.deleteAdvanceProductionPlanStart());

  const api = 'v1/advanceproductionplan?uuid=' + params.uuid;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE ADVANCE_PRODUCTION_PLAN SUCCESS", response.data);
    yield put(actions.deleteAdvanceProductionPlanSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE ADVANCE_PRODUCTION_PLAN FAILED", err.response);
    yield put(actions.deleteAdvanceProductionPlanFail(err.response));
  }
}