
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_TIMELINES, ADD_TIMELINE, EDIT_TIMELINE, DELETE_TIMELINE } from './types';

export function* watchTimelines() {
  yield takeEvery(GET_TIMELINES, getTimelinesSaga);
  yield takeEvery(ADD_TIMELINE, addTimelineSaga);
  yield takeEvery(EDIT_TIMELINE, editTimelineSaga);
  yield takeEvery(DELETE_TIMELINE, deleteTimelineSaga);
}

export function* getTimelinesSaga(params) {
  yield put(actions.getTimelinesStart());

  let api = 'v1/timeline';
  api += '?page_id=' + params.page_id;

  try {
    const response = yield axios.get(api);
    // console.log("GET TIMELINES SUCCESS", response.data);
    yield put(actions.getTimelinesSuccess(response.data));
  } catch (err) {
    console.log("GET TIMELINES FAILED", err)
    yield put(actions.getTimelinesFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* addTimelineSaga(params) {
  yield put(actions.addTimelineStart());

  let postData = 'page_id=' + encodeURIComponent(params.page_id);
  postData += "&name=" + encodeURIComponent(params.name);
  postData += "&machine_id=" + encodeURIComponent(params.machine_id);
  postData += params.formula_oee ? "&formula_oee=" + encodeURIComponent(JSON.stringify(params.formula_oee)) : '';
  postData += "&day_starting_time=" + encodeURIComponent(params.day_starting_time);
  postData += "&properties=" + encodeURIComponent(JSON.stringify(params.properties));

  const api = 'v1/timeline';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD TIMELINE SUCCESS", response);
    yield put(actions.addTimelineSuccess(response.data));
  } catch (err) {
    console.log("ADD TIMELINE FAILED", err.response.data);
    yield put(actions.addTimelineFail(err.response.data.error));
  }
}

export function* editTimelineSaga(params) {
  yield put(actions.editTimelineStart());

  let putData = params.name ? 'name=' + encodeURIComponent(params.name) : '';
  putData += params.machine_id ? '&machine_id=' + encodeURIComponent(params.machine_id) : '';
  putData += params.formula_oee ? '&formula_oee=' + encodeURIComponent(JSON.stringify(params.formula_oee)) : '';
  putData += params.day_starting_time ? '&day_starting_time=' + encodeURIComponent(params.day_starting_time) : '';
  putData += params.properties ? '&properties=' + encodeURIComponent(JSON.stringify(params.properties)) : '';

  const api = 'v1/timeline/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT TIMELINE SUCCESS", response.data);
    yield put(actions.editTimelineSuccess(response.data));
  } catch (err) {
    console.log("EDIT TIMELINE FAILED", err.response.data);
    yield put(actions.editTimelineFail(err.response.data.error));
  }
}

export function* deleteTimelineSaga(params) {
  yield put(actions.deleteTimelineStart());

  const api = 'v1/timeline/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE Timeline SUCCESS", response.data);
    yield put(actions.deleteTimelineSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE Timeline FAILED", err.response);
    yield put(actions.deleteTimelineFail(err.response));
  }
}