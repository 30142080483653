import * as actionTypes from './types';

// ----------------------- GET PRODUCTION_PLAN_STRUCTURES -----------------------
export const getProductionPlanStructuresStart = () => {
  return {
    type: actionTypes.GET_PRODUCTION_PLAN_STRUCTURES_START
  };
}

export const getProductionPlanStructures = (org_id) => {
  return {
    type: actionTypes.GET_PRODUCTION_PLAN_STRUCTURES,
    org_id
  };
}

export const getProductionPlanStructuresSuccess = (response) => {
  return {
    type: actionTypes.GET_PRODUCTION_PLAN_STRUCTURES_SUCCESS,
    planStructures: response.structures,
    result: response.result
  };
}

export const getProductionPlanStructuresFail = (error) => {
  return {
    type: actionTypes.GET_PRODUCTION_PLAN_STRUCTURES_FAIL,
    error
  };
}

// ----------------------- ADD PRODUCTION_PLAN_STRUCTURE -----------------------
export const addProductionPlanStructureStart = () => {
  return {
    type: actionTypes.ADD_PRODUCTION_PLAN_STRUCTURE_START
  };
}

export const addProductionPlanStructure = (org_id, name, detail, columns, properties) => {
  return {
    type: actionTypes.ADD_PRODUCTION_PLAN_STRUCTURE,
    org_id, name, detail, columns, properties
  };
}

export const addProductionPlanStructureSuccess = (response) => {
  return {
    type: actionTypes.ADD_PRODUCTION_PLAN_STRUCTURE_SUCCESS,
    addedPlanStructure: response.structure,
    result: response.result
  };
}

export const addProductionPlanStructureFail = (error) => {
  return {
    type: actionTypes.ADD_PRODUCTION_PLAN_STRUCTURE_FAIL,
    error
  };
}

// ----------------------- EDIT PRODUCTION_PLAN_STRUCTURE -----------------------
export const editProductionPlanStructureStart = () => {
  return {
    type: actionTypes.EDIT_PRODUCTION_PLAN_STRUCTURE_START
  };
}

export const editProductionPlanStructure = (uuid, name, detail, columns, properties) => {
  return {
    type: actionTypes.EDIT_PRODUCTION_PLAN_STRUCTURE,
    uuid,
    name, detail, columns, properties
  };
}

export const editProductionPlanStructureSuccess = (response) => {
  return {
    type: actionTypes.EDIT_PRODUCTION_PLAN_STRUCTURE_SUCCESS,
    updatedPlanStructure: response.structure,
    result: response.result
  };
}

export const editProductionPlanStructureFail = (error) => {
  return {
    type: actionTypes.EDIT_PRODUCTION_PLAN_STRUCTURE_FAIL,
    error
  };
}

// ----------------------- DELETE PRODUCTION_PLAN_STRUCTURE -----------------------
export const deleteProductionPlanStructureStart = () => {
  return {
    type: actionTypes.DELETE_PRODUCTION_PLAN_STRUCTURE_START
  };
}

export const deleteProductionPlanStructure = (uuid) => {
  return {
    type: actionTypes.DELETE_PRODUCTION_PLAN_STRUCTURE,
    uuid
  };
}

export const deleteProductionPlanStructureSuccess = (result) => {
  return {
    type: actionTypes.DELETE_PRODUCTION_PLAN_STRUCTURE_SUCCESS,
    result
  };
}

export const deleteProductionPlanStructureFail = (error) => {
  return {
    type: actionTypes.DELETE_PRODUCTION_PLAN_STRUCTURE_FAIL,
    error
  };
}