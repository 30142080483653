import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './PoPlan.module.scss';
import { getProductionPlans } from '../../../../state/ducks/ProductionPlan/actions';
import { getMachines } from '../../../../state/ducks/Machine/actions';

import Button from '../../UI/Button/Button';
// import DownloadIcon from '../../../../assets/icons/Report/download.svg';
import ViewIcon from '../../../../assets/icons/Report/view.svg';
import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';

class PoPlan extends Component {
	state = {
		startDate: '2020-10-01',
		startTime: '05:00',
		stopDate: '2020-10-02',
		stopTime: '05:00',
	};

	componentDidMount() {
		this.setDefaultDateTime();
		this.props.getMachines(this.props.currentOrg);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.currentOrg !== this.props.currentOrg) {
			this.setDefaultDateTime();
			this.props.getMachines(this.props.currentOrg);
		} else if (prevProps.machines !== this.props.machines && this.props.machines) {
			this.callGetProductionPlans();
		}
	}

	setDefaultDateTime = () => {
		const defaultStop = moment().add(1, 'days').startOf('day').valueOf();
		const defaultStart = new Date().setHours(0, 0, 0, 0);

		this.setState({
			startDate: moment(defaultStart).format('YYYY-MM-DD'),
			startTime: moment(defaultStart).format('HH:mm'),
			stopDate: moment(defaultStop).format('YYYY-MM-DD'),
			stopTime: moment(defaultStop).format('HH:mm'),
		});
	}

	handleChange = (name, value) => {
		this.setState({ [name]: value });
	}

	onSubmitPOPlansHandler = (event) => {
		event.preventDefault();
		this.callGetProductionPlans();
	}

	callGetProductionPlans = () => {
		const { startDate, startTime, stopDate, stopTime } = this.state;
		const start = moment(startDate + ' ' + startTime);
		const stop = moment(stopDate + ' ' + stopTime);
		this.props.getProductionPlans(this.props.currentOrg, start, stop);
	}

	render() {
		const { startDate, stopDate } = this.state;
		const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

		return (
			<div>
				<form onSubmit={this.onSubmitPOPlansHandler} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
					<div style={{ display: 'flex', position: 'relative' }}>
						{!isChrome && <img src={CalendarIcon} alt="Starts" className={styles.CalendarIcon} />}
						<input className={styles.SelectBox} style={{ paddingLeft: !isChrome ? 46 : null, flex: 2 }} type="date" onChange={(event) => this.handleChange('startDate', event.target.value)} value={startDate} />
						{/* <input className={styles.SelectBox} type="time" onChange={(event) => this.handleChange('startTime', event.target.value)} value={startTime} /> */}
					</div>
					<div style={{ fontSize: 20, margin: '0 10px' }}> - </div>
					<div style={{ display: 'flex', position: 'relative' }}>
						{!isChrome && <img src={CalendarIcon} alt="Ends" className={styles.CalendarIcon} />}
						<input className={styles.SelectBox} style={{ paddingLeft: !isChrome ? 46 : null, flex: 2 }} type="date" onChange={(event) => this.handleChange('stopDate', event.target.value)} value={stopDate} />
						{/* <input className={styles.SelectBox} type="time" onChange={(event) => this.handleChange('stopTime', event.target.value)} value={stopTime} /> */}
					</div>
					<div className={styles.ViewButton}>
						<Button type="submit" icon={ViewIcon} color="primary" name="Search Plans" disabled={this.props.loading} noMargin />
					</div>
				</form>

				<div className={styles.TableWrapper}>
					<table>
						<thead>
							<tr>
								<th style={{ textAlign: 'center' }}>No.</th>
								<th>Start</th>
								<th>Stop</th>
								<th>PO Number</th>
								<th>Machine</th>
								<th>Targets</th>
								<th>Mat Code</th>
								<th>Mat Description</th>
							</tr>
						</thead>
						<tbody>
							{
								this.props.machines && this.props.productionPlans
									? this.props.productionPlans.length ?
										this.props.productionPlans.map((plan, index) => (
											<tr key={`poplans-${index + 1}`}>
												<td style={{ textAlign: 'center' }}>{index + 1}.</td>
												<td>{moment(plan.plan_start).format('DD/MM/YYYY HH:mm')}</td>
												<td>{moment(plan.plan_stop).format('DD/MM/YYYY HH:mm')}</td>
												<td>{plan.number}</td>
												<td>{this.props.machines && this.props.machines.length ? this.props.machines.filter(m => m.id === plan.machine_id)[0].name : ''}</td>
												<td>{plan.target.toLocaleString()}</td>
												<td>{plan.matcode}</td>
												<td>{plan.matdescription}</td>
											</tr>
										)) : <tr><td colSpan="8" style={{ textAlign: 'center' }}>No PO plans found</td></tr>
									: null
							}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { currentOrg } = state.org;
	const { productionPlans, loading } = state.productionPlan;
	const { machines } = state.machine;
	return { currentOrg, productionPlans, loading, machines };
};

export default connect(mapStateToProps, { getProductionPlans, getMachines })(PoPlan);
