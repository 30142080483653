import * as actionTypes from './types';

// ----------------------- GET DEVICE_SOCKETS -----------------------
export const searchEventsStart = () => {
  return {
    type: actionTypes.SEARCH_EVENTS_START
  };
}

export const searchEvents = (org_id, start, end, rule_id, level, machine_id, ref, offset, limit) => {
  return {
    type: actionTypes.SEARCH_EVENTS,
    org_id, start, end, rule_id, level, machine_id, ref, offset, limit
  };
}

export const searchEventsSuccess = (response) => {
  return {
    type: actionTypes.SEARCH_EVENTS_SUCCESS,
    eventsResult: response.events
  };
}

export const searchEventsFail = (error) => {
  return {
    type: actionTypes.SEARCH_EVENTS_FAIL,
    error
  };
}

// ----------------------- ADD EVENT -----------------------

export const addEventStart = () => {
  return {
    type: actionTypes.ADD_EVENT_START
  };
}

export const addEvent = (org_id, machine_id, start, end, origin, level, message) => {
  return {
    type: actionTypes.ADD_EVENT,
    org_id, machine_id, start, end, origin, level, message
  };
}

export const addEventSuccess = (response) => {
  return {
    type: actionTypes.ADD_EVENT_SUCCESS,
    addedEvent: response.events,
    result: response.result
  };
}

export const addEventFail = (error) => {
  return {
    type: actionTypes.ADD_EVENT_FAIL,
    error
  };
}