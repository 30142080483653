
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_ADVANCE_PRODUCTION_BATCHS, ADD_ADVANCE_PRODUCTION_BATCH, EDIT_ADVANCE_PRODUCTION_BATCH, DELETE_ADVANCE_PRODUCTION_BATCH, MAP_PLANS_TO_BATCH, GET_BATCH_PROGRESS } from './types';

export function* watchProductionBatchs() {
  yield takeEvery(GET_ADVANCE_PRODUCTION_BATCHS, getAdvanceProductionBatchsSaga);
  yield takeEvery(ADD_ADVANCE_PRODUCTION_BATCH, addAdvanceProductionBatchSaga);
  yield takeEvery(EDIT_ADVANCE_PRODUCTION_BATCH, editAdvanceProductionBatchSaga);
  yield takeEvery(DELETE_ADVANCE_PRODUCTION_BATCH, deleteAdvanceProductionBatchSaga);
  yield takeEvery(MAP_PLANS_TO_BATCH, mapPlansToBatchSaga);
  yield takeEvery(GET_BATCH_PROGRESS, getBatchProgressSaga);
}

export function* getAdvanceProductionBatchsSaga(params) {
  yield put(actions.getAdvanceProductionBatchsStart());

  let api = 'v1/advanceproductionbatch?org_id=' + params.org_id;
  api += params.batch ? "&batch=" + params.batch : '';
  api += params.date ? "&date=" + params.date : '';
  api += params.start ? "&start=" + params.start : '';
  api += params.end ? "&end=" + params.end : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET ADVANCE_PRODUCTION_BATCHS SUCCESS", response.data);
    yield put(actions.getAdvanceProductionBatchsSuccess(response.data));
  } catch (err) {
    console.log("GET ADVANCE_PRODUCTION_BATCHS FAILED", err)
    yield put(actions.getAdvanceProductionBatchsFail(err.response));
  }
}

export function* addAdvanceProductionBatchSaga(params) {
  yield put(actions.addAdvanceProductionBatchStart());

  let api = 'v1/advanceproductionbatch';
  let postData = "org_id=" + params.org_id;
  postData += "&batch=" + params.batch;
  postData += params.start ? "&start=" + params.start : '';
  postData += params.end ? "&end=" + params.end : '';
  postData += params.columns ? "&columns=" + JSON.stringify(params.columns) : '';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD ADVANCE_PRODUCTION_BATCH SUCCESS", response.data);
    yield put(actions.addAdvanceProductionBatchSuccess(response.data));
  } catch (err) {
    console.log("ADD ADVANCE_PRODUCTION_BATCH FAILED", err)
    yield put(actions.addAdvanceProductionBatchFail(err.response));
  }
}

export function* editAdvanceProductionBatchSaga(params) {
  yield put(actions.editAdvanceProductionBatchStart());

  let putData = "uuid=" + params.uuid;
  putData += "&batch=" + params.batch;
  putData += params.start ? "&start=" + params.start : '';
  putData += params.end ? "&end=" + params.end : '';
  putData += params.columns ? "&columns=" + JSON.stringify(params.columns) : '';

  const api = 'v1/advanceproductionbatch';

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT ADVANCE_PRODUCTION_BATCH SUCCESS", response.data);
    yield put(actions.editAdvanceProductionBatchSuccess(response.data));
  } catch (err) {
    console.log("EDIT ADVANCE_PRODUCTION_BATCH FAILED", err.response.data);
    yield put(actions.editAdvanceProductionBatchFail(err.response.data));
  }
}

export function* deleteAdvanceProductionBatchSaga(params) {
  yield put(actions.deleteAdvanceProductionBatchStart());

  const api = 'v1/advanceproductionbatch?uuid=' + params.uuid;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE ADVANCE_PRODUCTION_BATCH SUCCESS", response.data);
    yield put(actions.deleteAdvanceProductionBatchSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE ADVANCE_PRODUCTION_BATCH FAILED", err.response);
    yield put(actions.deleteAdvanceProductionBatchFail(err.response));
  }
}

export function* mapPlansToBatchSaga(params) {
  yield put(actions.mapPlansToBatchStart());

  const api = 'v1/advanceproductionbatch/plans';
  let putData = "uuid=" + params.uuid;
  putData += "&plan_uuids=" + params.plan_uuids;

  try {
    const response = yield axios.put(api, putData);
    console.log("MAP PLANS TO BATCH SUCCESS", response.data);
    yield put(actions.mapPlansToBatchSuccess(response.data.result));
  } catch (err) {
    console.log("MAP PLANS TO BATCH FAILED", err.response);
    yield put(actions.mapPlansToBatchFail(err.response));
  }
}

export function* getBatchProgressSaga(params) {
  yield put(actions.getBatchProgressStart());

  let api = 'v1/advanceproductionbatch/progress';
  api += "?uuid=" + params.uuid;

  try {
    const response = yield axios.get(api);
    yield put(actions.getBatchProgressSuccess(response.data, params.properties));
  } catch (err) {
    console.log("GET_BATCH_PROGRESS FAILED", err.response);
    yield put(actions.getBatchProgressFail(err.response));
  }
}
