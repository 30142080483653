import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './ErrorBoundary.module.scss';
import Button from '../../../components/UI/Button/Button';
import Input from '../../../components/UI/Input/Input';
import UnknownBg from '../../../../assets/images/unknown.svg';

import { addErrorLog, updateErrorLog } from '../../../../state/ducks/ErrorLog/actions';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, message: '', sent: false };
    }

    // static getDerivedStateFromError(error) {
    //     // Update state so the next render will show the fallback UI.
    //     return { hasError: true };
    // }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        const errorLog = error + '\n' + errorInfo.componentStack.split('\n')[1];
        this.props.addErrorLog(errorLog);
        this.setState({ error, message: '', sent: false });
    }

    sendTheReportHandler = () => {
        this.props.updateErrorLog(this.props.addedErrorLog[0].uuid, this.state.message);
        this.setState({ sent: true, message: '' });
    }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            return (
                <div className={styles.ErrorBoundary}>
                    <div className={styles.LeftSection}>
                        <div style={{ flex: 1 }}>
                            <div className={styles.Header}>Something went wrong!</div>
                            <div>
                                <div className={styles.Description}>Sorry, we have some technical issues (as you can see).</div>
                                <div className={styles.Description}>You may try again later.</div>
                            </div>
                            <Input
                                whitemode
                                name="detail"
                                value={this.state.message}
                                type="textarea"
                                placeholder="Please tell us what you have done before to help us do better"
                                rows="5"
                                onChange={(e) => this.setState({ message: e.target.value })}
                                overidestyleformgroup={{ maxWidth: 700, margin: '100px 0 30px 0' }}
                            />
                            <Button type="button" color="blue" name="Send" click={this.sendTheReportHandler} overideButtonStyles={{ maxWidth: 700 }} />

                            {this.state.sent && <div className={styles.SentMessage}> We have received your message. Brace yourself till we get the error fixed :)</div>}
                        </div>
                    </div>
                    <div className={styles.RightSection} style={{ backgroundImage: `url(${UnknownBg})` }}>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

const mapStateToProps = (state) => {
    const { addedErrorLog } = state.errorLog;
    return { addedErrorLog };
};


export default connect(mapStateToProps, { addErrorLog, updateErrorLog })(ErrorBoundary);