import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Hook.module.scss';
import Input from '../../components/UI/Input/Input';
import Select from '../../components/UI/Select/Select';
import Button from '../../components/UI/Button/Button';
import ToolTip from '../../components/UI/ToolTip/Tooltip';
import SimpleSwitch from '../../components/UI/SimpleSwitch/SimpleSwitch';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import CloseIcon from '../../../assets/icons/cancel-circle.svg';
import AddIcon from '../../../assets/icons/Rule/add-circle.svg';
import DeleteIcon from '../../../assets/icons/Rule/delete-circle.svg';

import { getHooks, deleteHook, enableHook, editHook, addHook } from '../../../state/ducks/Hook/actions';
import { getDevices } from '../../../state/ducks/Devices/actions';
import { GET_HOOKS_SUCCESS, ADD_HOOK_SUCCESS } from '../../../state/ducks/Hook/types';

class Hook extends Component {

  state = {
    selectedid: 0,
    isDeleteModalOpened: false,
    adding: false,
    selecteddevice: null,
    deviceOptions: [],
    headerList: [{ 'key': '', 'value': '', 'id': 0 }],
    form: {
      'name': '',
      'device_secret': '',
      'url': '',
      'headers': {},
      'content': '',
      'enable_lower_limit': false,
      'enable_upper_limit': false,
      'lower_limit': null,
      'upper_limit': null
    }
  }

  initDeviceOptions = () => {
    const { devices } = this.props;
    let deviceOptions = [];
    devices.forEach(device => {
      deviceOptions.push({ id: device.id, name: device.name + ' (' + device.secret + ')', secret: device.secret });
    });
    this.setState({ deviceOptions: deviceOptions });
  }

  componentDidMount() {
    const { devices, currentOrg } = this.props;
    this.props.getHooks(currentOrg);
    if (!devices || !devices.length || devices[0].org_id !== currentOrg) {
      this.props.getDevices(currentOrg, true);
    } else {
      this.initDeviceOptions();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.props.getHooks(this.props.currentOrg);
      this.props.getDevices(this.props.currentOrg, true);
    } else if (prevProps.devices !== this.props.devices) {
      this.initDeviceOptions();
    } else if (prevProps.loading && !this.props.loading && this.props.type !== GET_HOOKS_SUCCESS) {
      if (this.props.type === ADD_HOOK_SUCCESS) {
        this.setState({ adding: false, selectedid: this.props.addedHook.id });
      }
      this.props.getHooks(this.props.currentOrg);
    }
  }

  onAddHook = (event) => {
    if (this.state.adding)
      return;
    let { form, headerList } = this.state;
    headerList = [{ 'key': '', 'value': '', 'id': 0 }];
    form = {
      'name': '',
      'device_secret': '',
      'url': '',
      'headers': {},
      'content': '',
      'enable_lower_limit': false,
      'enable_upper_limit': false,
      'lower_limit': null,
      'upper_limit': null
    };
    this.setState({ adding: true, selectedid: 0, selecteddevice: -1, form, headerList })
  }

  onHookClick = (id) => {
    if (this.state.selectedid === id)
      return;
    let { form, headerList, selecteddevice } = this.state;
    const { hooks } = this.props;
    for (let i = 0; i < hooks.length; i++) {
      let hook = hooks[i];
      if (hook.id === id) {
        headerList = [];
        for (let key in hook.headers) {
          headerList.push({ 'key': key, 'value': hook.headers[key], 'id': headerList.length });
        }
        selecteddevice = '' + hook.device_id;
        form = {
          'name': hook.name,
          'device_secret': hook.device_secret,
          'url': hook.url,
          'headers': hook.headers,
          'content': hook.content,
          'enable_lower_limit': hook.enable_lower_limit,
          'enable_upper_limit': hook.enable_upper_limit,
          'lower_limit': hook.lower_limit,
          'upper_limit': hook.upper_limit
        }
        break;
      }
    }
    this.setState({ adding: false, selectedid: id, selecteddevice, form, headerList });
  }

  onTogleHookEnable = (id, enable) => {
    this.props.enableHook(id, enable);
  }

  onSubmitHandler = (event) => {
    event.preventDefault();
    const { form, headerList, adding, selectedid, selecteddevice, deviceOptions } = this.state;

    for (let i = 0; i < deviceOptions.length; i++) {
      if (deviceOptions[i].id === +selecteddevice) {
        form.device_secret = deviceOptions[i].secret;
        break;
      }
    }

    form.headers = {};
    headerList.forEach(header => {
      form.headers[header.key] = header.value;
    });
    console.log('Form', form);
    console.log('Selected Device', selecteddevice);
    if (adding) {
      this.props.addHook(form);
    } else {
      this.props.editHook(selectedid, form);
    }
  }

  onFormUpdate = (key, value) => {
    let form = this.state.form;
    form[key] = value;
    this.setState({ form: form });
  }

  onSelectedDevice = (event) => {
    this.setState({ selecteddevice: +event.target.value });
  }

  onCloseDetail = () => {
    this.setState({ selectedid: 0, adding: 0 });
  }

  addNewHeader = () => {
    const { headerList } = this.state;
    headerList.push({ 'key': '', 'value': '', 'id': headerList.length });
    this.setState({ headerList: headerList });
  }

  deleteHeader = (id) => {
    const { headerList } = this.state;
    for (let i = 0; i < headerList.length; i++) {
      if (headerList[i].id === id) {
        headerList.splice(i, 1);
        break;
      }
    }
    this.setState({ headerList: headerList });
  }

  onHeaderUpdate = (id, key, value) => {
    const { headerList } = this.state;
    for (let i = 0; i < headerList.length; i++) {
      if (headerList[i].id === id) {
        headerList[i].key = key;
        headerList[i].value = value;
        break;
      }
    }
    this.setState({ headerList: headerList });
  }

  onConfirmDeleteHook = () => {
    this.props.deleteHook(this.state.selectedid);
    this.setState({ selectedid: 0, isDeleteModalOpened: false });
  }

  isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  checkSubmitButton = () => {
    const { form, selecteddevice } = this.state;
    if (selecteddevice === -1)
      return false;

    if (!form.name)
      return false;

    if (!form.url || !this.isValidHttpUrl(form.url))
      return false;

    if (!form.content)
      return false;

    return true;
  }

  renderForm = () => {
    const { selecteddevice, form, headerList, deviceOptions, adding } = this.state;

    let headerdevs = [];
    for (let i = 0; i < headerList.length; i++) {
      let header = headerList[i];
      headerdevs.push(
        <div key={'header_' + i} className={styles.Flex}>
          <div className={styles.LeftTextField}>
            <Input
              name={'header_key_' + i}
              type="text"
              placeholder="Header key"
              autoComplete="off"
              value={header.key}
              onChange={e => { this.onHeaderUpdate(header.id, e.target.value, header.value) }}
              required
            />
          </div>
          <div style={{ flex: 2 }}>
            <Input
              name={'header_value_' + i}
              type="text"
              placeholder="Header value"
              autoComplete="off"
              value={header.value}
              onChange={e => { this.onHeaderUpdate(header.id, header.key, e.target.value) }}
              required
            />
          </div>
          <div className={styles.AddButton}>
            <img className={styles.IconButton} src={i === (headerList.length - 1) ? AddIcon : DeleteIcon} alt="Add Header Icon" onClick={i === (headerList.length - 1) ? this.addNewHeader : e => { this.deleteHeader(header.id) }} />
          </div>
        </div>
      );
    }

    return (
      <form className={styles.HookDetailWrapper} onSubmit={this.onSubmitHandler}>
        <div className={styles.FormWrapper}>
          <img src={CloseIcon} className={styles.CloseIcon} alt="close" onClick={this.onCloseDetail} />
          <div className={styles.Title}>
            {adding ? 'Add New Hook' : 'Edit Hook'}
          </div>
          <div className={styles.Description}>
            {adding ? 'Please fill the information to create a new hook' : 'Please edit your hook details '}
          </div>
          {this.props.error && <div className="ErrorText">{this.props.error.text}</div>}
          <hr className={styles.Divider} />

          <Input
            label="name of the hook"
            name="name"
            type="text"
            placeholder="Please name your hook"
            value={form.name}
            autoComplete="off"
            onChange={e => this.onFormUpdate("name", e.target.value)}
            required
          />

          <div className={styles.RightTextField}>
            <div className={styles.LabelGroup}>
              <div className={styles.Label}>Device</div>
            </div>
            <Select
              name="device"
              placeholder="Please select device"
              options={deviceOptions}
              value={selecteddevice || selecteddevice === '0' ? selecteddevice : deviceOptions[0].value}
              autoComplete="off"
              overidestyleformgroup={{ marginBottom: 15 }}
              onChange={this.onSelectedDevice}
            />
          </div>

          <Input
            label="target url"
            name="url"
            type="text"
            placeholder="https://api.example.com/hook"
            value={form.url}
            autoComplete="off"
            onChange={e => this.onFormUpdate("url", e.target.value)}
            required
          />

          <div className={styles.RightTextField}>
            <div className={styles.LabelGroup}>
              <div className={styles.Label}>Headers</div>
              <div className={styles.Info}>
                <ToolTip isInfo overideTitleStyles={{ width: 250 }}>Request headers for call target hook (optional)</ToolTip>
              </div>
            </div>
            {headerdevs}
          </div>

          <div className={styles.RightTextField}>
            <div className={styles.LabelGroup}>
              <div className={styles.Label}>Content *</div>
              <div className={styles.Info}>
                <ToolTip isInfo overideTitleStyles={{ width: 250 }}>
                  {`Request content. can use variable in content. ('@datetime', '@timestamp', 'value@{socket}@{type}' {socket} can be 1-70 and {type} can be 'int' or 'float')`}
                </ToolTip>
              </div>
            </div>
            <Input
              name="content"
              type="textarea"
              placeholder={'{"timestamp": @timestamp, "socket1": value@1@int, "socket2": value@2@float}'}
              value={form.content}
              autoComplete="off"
              onChange={e => this.onFormUpdate("content", e.target.value)}
              required
            />
          </div>

          <div className={styles.RightTextField}>
            <div className={styles.LabelGroup}>
              <div className={styles.Label}>limit</div>
              <div className={styles.Info}>
                <ToolTip isInfo overideTitleStyles={{ width: 120 }}>Limit of value</ToolTip>
              </div>
            </div>
            <div className={styles.Flex}>
              <div className={styles.LeftTextField} style={{ display: 'flex' }}>
                <div className={styles.Label} style={{ marginTop: 12 }}>Lower</div>
                <div style={{ flex: '0 1 40px', marginLeft: 20, marginTop: 10 }}>
                  <SimpleSwitch enable={form.enable_lower_limit} onChangeEnableHandler={e => this.onFormUpdate('enable_lower_limit', !form.enable_lower_limit)} />
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <Input
                    name='lower_limit'
                    type="number"
                    placeholder="0"
                    autoComplete="off"
                    value={form.lower_limit ? form.lower_limit : 0}
                    onChange={e => { this.onFormUpdate('lower_limit', +e.target.value) }}
                    disabled={!form.enable_lower_limit}
                  />
                </div>
              </div>
              <div className={styles.RightTextField} style={{ display: 'flex' }}>
                <div className={styles.Label} style={{ marginTop: 12 }}>Upper</div>
                <div style={{ flex: '0 1 40px', marginLeft: 20, marginTop: 10 }}>
                  <SimpleSwitch enable={form.enable_upper_limit} onChangeEnableHandler={e => this.onFormUpdate('enable_upper_limit', !form.enable_upper_limit)} />
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <Input
                    name='upper_limit'
                    type="number"
                    placeholder="0"
                    autoComplete="off"
                    value={form.upper_limit ? form.upper_limit : 0}
                    onChange={e => { this.onFormUpdate('upper_limit', +e.target.value) }}
                    disabled={!form.enable_upper_limit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ButtonsWrapper}>
          {
            adding ?
              <React.Fragment>
                <div style={{ width: 112 }}><Button type="button" name="Cancel" color="borderred" noMargin click={this.onCloseDetail} /></div>
                <div style={{ width: 200 }}>
                  <Button
                    type="submit"
                    name={this.props.loading ? 'Loading...' : 'Done'}
                    color="primary"
                    disabled={!this.checkSubmitButton()}
                    loading={this.props.loading}
                  />
                </div>
              </React.Fragment> :
              <React.Fragment>
                <div style={{ width: 112 }}><Button type="button" name="Delete" color="red" noMargin click={e => { this.setState({ isDeleteModalOpened: true }) }} /></div>
                <div style={{ width: 200 }}>
                  <Button
                    type="submit"
                    name={this.props.loading ? 'Loading...' : 'Save'}
                    color="green"
                    disabled={!this.checkSubmitButton()}
                    loading={this.props.loading}
                  />
                </div>
              </React.Fragment>
          }
        </div>
      </form>
    );
  }

  render() {
    const { loading, hooks } = this.props;
    const { selectedid, adding } = this.state;

    let list = [];
    if (loading) {
      list = [
        <div key="loading1" className={styles.LoadingCard}></div>,
        <div key="loading2" className={styles.LoadingCard}></div>
      ];
    } else if (hooks && hooks.length > 0) {
      hooks.forEach(hook => {
        let listClassName = [styles.HookListWrapper];
        if (hook.id === selectedid) {
          listClassName.push(styles.HookListSelected);
        }
        list.push(
          <div key={'hook' + hook.id} className={listClassName.join(' ')} onClick={() => this.onHookClick(hook.id)}>
            <div className={styles.SwitchWrapper}>
              <SimpleSwitch enable={hook.enable} onChangeEnableHandler={(event) => this.onTogleHookEnable(hook.id, !hook.enable)} />
            </div>
            <div>
              <div className={styles.Name}>{hook.name}</div>
              <div className={styles.Description}>{hook.device_secret}</div>
            </div>
          </div>
        );
      });
    }

    let detail = null;
    if (selectedid !== 0 || adding) {
      detail = this.renderForm();
    }


    return (
      <div className={styles.Hook}>
        <div className={styles.HookTopics}>
          <div className={styles.HookFix}>
            <div className={styles.TopMenuHook}>
              <div className={styles.MenuWrapper}>
                <div className={styles.Menu}><span className={styles.MenuTitle}>Configure</span></div>
              </div>
              <div className={styles.AddButtonWrapper}>
                <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Hook" noMargin click={this.onAddHook} />
              </div>
            </div>
            {list}
          </div>
        </div>

        <div className={styles.HookDetails}>
          {detail}
        </div>

        <DeleteModal
          title="Hook"
          open={this.state.isDeleteModalOpened}
          modalClosed={e => { this.setState({ isDeleteModalOpened: false }) }}
          confirmDelete={this.onConfirmDeleteHook}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { devices } = state.devices;
  const { type, result, hooks, addedHook, updatedHook, loading } = state.hook;
  return { currentOrg, devices, type, result, hooks, addedHook, updatedHook, loading };
}

export default connect(mapStateToProps, { getDevices, getHooks, deleteHook, enableHook, editHook, addHook })(Hook);