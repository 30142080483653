import React, { Component } from 'react';
import { connect } from 'react-redux';
import MultipleSelect from 'react-select';
import moment from 'moment';
import _ from 'lodash';
import * as XLSX from 'xlsx';

import styles from '../Report.module.scss';
import ReportImage from '../../../../assets/images/Report/report.png';
import ReportImage2x from '../../../../assets/images/Report/report@2x.png';
import ReportImage3x from '../../../../assets/images/Report/report@3x.png';
import DownloadIcon from '../../../../assets/icons/Report/download.svg';
import ViewIcon from '../../../../assets/icons/Report/view.svg';
import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';
import AddIcon from '../../../../assets/icons/plus-circle.svg';
import EditWhiteIcon from '../../../../assets/icons/edit-white.svg';

import Button from '../../UI/Button/Button';
import Select from '../../UI/Select/Select';
import Spinner from '../../UI/Spinner/Spinner';
import ParetoChart from './ParetoChart';
import ParetoStackedChart from './ParetoStackedChart';

import { getMachines } from '../../../../state/ducks/Machine/actions';
import { getParetoData } from '../../../../state/ducks/TimelineStatus/actions';
import { deleteReportPreset } from '../../../../state/ducks/ReportPreset/actions';
import { ADD_REPORT_PRESET_SUCCESS, EDIT_REPORT_PRESET_SUCCESS, DELETE_REPORT_PRESET_SUCCESS } from '../../../../state/ducks/ReportPreset/types';
import SideDrawer from '../../Navigation/SideDrawer/SideDrawer';
import PresetDrawer from '../PresetDrawer/PresetDrawer';
import DeleteModal from '../../shared/DeleteModal/DeleteModal';
import StarYellowIcon from '../../../../assets/icons/Report/star-yellow.svg';
import { SnackBar } from '../../UI/SnackBar/SnackBar';

import { intervalToDateConverter } from '../../../../state/utils/Report';

const colourStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8",
    },
    fontSize: 14,
    fontWeight: 500,
  }),
  input: (base) => ({
    ...base,
    color: "white",
    paddingTop: 10,
    paddingBottom: 9,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused
          ? "rgba(22, 101, 216, 0.7)"
          : "#1c1c1f",
    };
  },
  menu: (base) => ({
    ...base,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500,
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#1665d8",
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
};

const FILTER_OPTION = [
  { id: 0, name: "Show all statuses (for only 1 machine)" },
  { id: 1, name: "Combine others" },
  { id: 2, name: "Not display others" },
];

const INITIAL_STATE = {
  savedSelectedMachines: [],
  currentSelectedMachines: [],
  selectedFilter: 0,
  startDate: '2020-10-01',
  startTime: '05:00',
  stopDate: '2020-10-02',
  stopTime: '05:00',
  prepParetoData: null,
  autoFillIndex: 1,
  paretoResult: [],
  paretoOptions: [],
  fixedParetoResult: [],
  tempParetoDatas: [],
  stackedParetoData: [],
  openSelectForm: true,
  userClickOnce: false,
  isDownload: false,

  presetDrawerOpened: false,
  presetProperty: {
    machines: [],
    filter: 0,
    // summarize: '',
    startDate: '',
    startTime: '',
    stopDate: '',
    stopTime: '',
    interval: 1,
    startDiff: 0,
    stopDiff: 0
  },
  isDeleteModalOpened: false,
  deletingPreset: null,
  presetLoad: false,
}

function humanizeDuration(ms) {
  const days = moment.duration(ms).days();
  const hours = moment.duration(ms).hours();
  const minutes = moment.duration(ms).minutes();
  const seconds = moment.duration(ms).seconds();

  let result = days === 0 ? '' : days > 1 ? days + ' days ' : days + ' day ';
  result += hours === 0 ? '' : hours > 1 ? hours + ' hours ' : hours + ' hour ';
  result += minutes === 0 ? '' : minutes > 1 ? minutes + ' minutes ' : minutes + ' minute ';
  result += seconds === 0 ? '' : seconds > 1 ? seconds + ' seconds ' : seconds + ' second';

  return result;
}

class ParetoReport extends Component {
  snackbarRef = React.createRef();
  state = {
    ...INITIAL_STATE,
    ...{
      preset: this.props.selectedPreset ? this.props.selectedPreset.type === 'pareto' ? this.props.selectedPreset : null : null,
    }
  };

  componentDidMount() {
    const { machines, currentOrg } = this.props;
    if (!machines || !machines.length || machines[0].org_id !== currentOrg) {
      this.props.getMachines(currentOrg);
    }

    if (this.state.preset) {
      let newStartDate = this.state.preset.properties.startDate;
      let newStopDate = this.state.preset.properties.stopDate;
      if (this.state.preset.properties.interval === -1) {
        newStartDate = new Date(Date.now() + this.state.preset.properties.startDiff).toISOString().substring(0, 10);
        newStopDate = new Date(Date.now() + this.state.preset.properties.stopDiff).toISOString().substring(0, 10);
      } else {
        let intervalToDate = intervalToDateConverter(this.state.preset.properties.interval);
        newStartDate = intervalToDate[0];
        newStopDate = intervalToDate[1];
      }
      this.setState({
        savedSelectedMachines: this.state.preset.properties.machines,
        currentSelectedMachines: this.state.preset.properties.machines,
        selectedFilter: this.state.preset.properties.filter,
        autoFillIndex: this.state.preset.properties.interval,
        startDate: newStartDate,
        startTime: this.state.preset.properties.startTime,
        stopDate: newStopDate,
        stopTime: this.state.preset.properties.stopTime,
        presetLoad: true,
      });
    } else {
      this.setDefaultDateTime();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.onNewReportFormHandler();
      this.props.getMachines(this.props.currentOrg);
    } else {
      const { paretoData } = this.props;
      if (
        paretoData &&
        !_.isEqual(prevProps.paretoData, paretoData) &&
        this.state.savedSelectedMachines.length > 1
      ) {
        const tempParetoDatas = [...this.state.tempParetoDatas];
        const isDuplicateData = tempParetoDatas.filter(
          (td) => td.properties.machine_id === paretoData.properties.machine_id
        );

        if (!isDuplicateData.length) tempParetoDatas.push(paretoData);

        if (
          tempParetoDatas.length === this.state.savedSelectedMachines.length
        ) {
          this.readyForSetupStackedPareto(tempParetoDatas);
        }

        this.setState({ tempParetoDatas });
      } else if (
        paretoData &&
        !_.isEqual(prevProps.paretoData, paretoData) &&
        this.state.savedSelectedMachines.length === 1
      ) {
        const {
          savedSelectedMachines,
          startDate,
          startTime,
          stopDate,
          stopTime,
          isDownload,
        } = this.state;
        const start = moment(startDate + " " + startTime);
        const stop = moment(stopDate + " " + stopTime);
        let rangeTime = stop - start;

        let paretoResult = [],
          paretoOptions = [];
        let sumPercent = 0;
        const updatedPrepParetoData = {
          duration: [],
          status: [],
          percent: [],
          sumPercent: [],
          value: [],
        };

        const statusRules = savedSelectedMachines[0].status_rules;
        if (paretoData.data.length) {
          const countableExistingDuration = _.reduce(
            paretoData.data,
            (memo, pd) => {
              return memo + pd.duration;
            },
            0
          );
          const lostDuration = rangeTime - countableExistingDuration;

          let otherStatusesDuration = 0;
          if (+this.state.selectedFilter !== 0) {
            otherStatusesDuration = _.reduce(
              paretoData.data.filter((pd) => !statusRules[pd.value]),
              (memo, oth) => {
                return memo + oth.duration;
              },
              0
            );
            if (+this.state.selectedFilter === 2)
              rangeTime -= otherStatusesDuration;
          }

          const newParetoData = [...paretoData.data];
          if (lostDuration > 0) {
            newParetoData.push({ duration: lostDuration, value: "LOST" });
          }
          if (otherStatusesDuration > 0 && +this.state.selectedFilter === 1) {
            newParetoData.push({
              duration: otherStatusesDuration,
              value: "Others",
            });
          }

          newParetoData
            .sort((a, b) => b.duration - a.duration)
            .forEach((pd) => {
              const foundStatus = statusRules[pd.value];
              if (
                pd.value === "LOST" ||
                pd.value === "Others" ||
                +this.state.selectedFilter === 0 ||
                foundStatus
              ) {
                if (pd.value === "LOST" || pd.value === "Others")
                  updatedPrepParetoData.status.push(pd.value);
                else
                  updatedPrepParetoData.status.push(
                    foundStatus
                      ? foundStatus.reason + " (" + pd.value + ")"
                      : "N/A (" + pd.value + ")"
                  );

                updatedPrepParetoData.duration.push(pd.duration);
                updatedPrepParetoData.value.push(pd.value);
                const percent = (pd.duration * 100) / rangeTime;
                sumPercent += percent;
                updatedPrepParetoData.percent.push(percent.toFixed(2));
                updatedPrepParetoData.sumPercent.push(sumPercent.toFixed(2));
                paretoResult.push({
                  id: pd.value,
                  label: pd.value,
                  duration: pd.duration,
                });
                paretoOptions.push({
                  id: pd.value,
                  label: pd.value,
                  duration: pd.duration,
                });
              }
            });
        }
        if (isDownload) {
          this.setState({isDownload: false});
          let exportData = [];
          for(let i = 0 ; i < updatedPrepParetoData.status.length ; i++) {
            exportData.push({
              'Machine': savedSelectedMachines[0].label,
              'Status': updatedPrepParetoData.status[i],
              'Duration': updatedPrepParetoData.duration[i],
              'Percentage': updatedPrepParetoData.percent[i] + '%',
            })
          }
          const ws = XLSX.utils.json_to_sheet(exportData, {headers: ['Machine', 'Status', 'Duration', 'Percentage']});
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'pareto');
          XLSX.writeFile(wb, 'pareto-export.xlsx');
        }

        this.setState({
          prepParetoData: updatedPrepParetoData,
          fixedParetoResult: paretoResult,
          paretoResult,
          paretoOptions,
        });
      }

      // Auto load for preset
      if (prevState.presetLoad === false && this.state.presetLoad === true) {
        this.onSubmitReportHandler();
      }

      // Handler for preset CRUD response
      if (prevProps.result === null && this.props.result === 'success') {
        if (this.props.type === ADD_REPORT_PRESET_SUCCESS) {
          this.snackbarRef.current.openSnackBar('Preset has been saved');
          this.setState({
            preset: this.props.addedReportPreset,
            presetLoad: true
          });
        } else if (this.props.type === EDIT_REPORT_PRESET_SUCCESS) {
          this.snackbarRef.current.openSnackBar('Preset has been saved');
          this.setState({
            preset: this.props.updatedReportPreset
          });
        } else if (this.props.type === DELETE_REPORT_PRESET_SUCCESS) {
          this.props.backToPresetHandler();
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.clearPresetHandler();
    this.snackbarRef.current = null;
    this.snackbarRef = null;
  }

  readyForSetupStackedPareto = (paretoDatas) => {
    // console.log("READY ", paretoDatas);
    const {
      savedSelectedMachines,
      startDate,
      startTime,
      stopDate,
      stopTime,
      isDownload,
    } = this.state;
    const start = moment(startDate + " " + startTime);
    const stop = moment(stopDate + " " + stopTime);
    let rangeTime = (stop - start) * paretoDatas.length;

    const updatedStackedParetoData = [];

    let paretoResult = [],
      paretoOptions = [];
    let sortedMostDuration = [];
    let sumOtherStatusesDuration = [];
    let sumLostDuration = [];
    Object.keys(savedSelectedMachines[0].status_rules).forEach((sr) => {
      let sumDuration = 0;
      paretoDatas
        .sort(
          (a, b) =>
            _.reduce(
              b.data,
              (memo, b) => {
                return memo + b.duration;
              },
              0
            ) -
            _.reduce(
              a.data,
              (memo, a) => {
                return memo + a.duration;
              },
              0
            )
        )
        .forEach((paretoData, i) => {
          const found = paretoData.data.filter((pd) => pd.value === +sr)[0];
          sumDuration += found ? found.duration : 0;
          const foundIndex = savedSelectedMachines.findIndex(
            (mac) => mac.id === paretoData.properties.machine_id
          );
          const statusRules = savedSelectedMachines[foundIndex].status_rules;
          const countableExistingDuration = _.reduce(
            paretoData.data,
            (memo, pd) => {
              return memo + pd.duration;
            },
            0
          );
          sumLostDuration[i] = stop - start - countableExistingDuration;
          sumOtherStatusesDuration[i] = _.reduce(
            paretoData.data.filter((pd) => !statusRules[pd.value]),
            (memo, oth) => {
              return memo + oth.duration;
            },
            0
          );
        });
      sortedMostDuration.push({
        value: +sr,
        status: savedSelectedMachines[0].status_rules[sr].reason,
        duration: sumDuration,
      });
      paretoResult.push({
        id: +sr,
        label: savedSelectedMachines[0].status_rules[sr].reason,
        duration: sumDuration,
      });
      paretoOptions.push({
        id: +sr,
        label: savedSelectedMachines[0].status_rules[sr].reason,
        duration: sumDuration,
      });
    });
    if (+this.state.selectedFilter === 1) {
      const sumOthersDuration = _.reduce(
        sumOtherStatusesDuration,
        (memo, duration) => {
          return memo + duration;
        },
        0
      );
      sortedMostDuration.push({
        value: "Others",
        status: "Others",
        duration: sumOthersDuration,
      });
      paretoResult.push({
        id: "Others",
        label: "Others",
        duration: sumOthersDuration,
      });
      paretoOptions.push({
        id: "Others",
        label: "Others",
        duration: sumOthersDuration,
      });
    }
    const lostDuration = _.reduce(
      sumLostDuration,
      (memo, duration) => {
        return memo + duration;
      },
      0
    );
    sortedMostDuration.push({
      value: "LOST",
      status: "LOST",
      duration: lostDuration,
    });
    paretoResult.push({ id: "LOST", label: "LOST", duration: lostDuration });
    paretoOptions.push({ id: "LOST", label: "LOST", duration: lostDuration });

    sortedMostDuration = sortedMostDuration.sort(
      (a, b) => b.duration - a.duration
    );
    paretoResult = paretoResult.sort((a, b) => b.duration - a.duration);
    paretoOptions = paretoOptions.sort((a, b) => b.duration - a.duration);
    // console.log('sorted', sortedMostDuration)

    let sumPercent = 0;
    paretoDatas
      .sort(
        (a, b) =>
          _.reduce(
            b.data,
            (memo, b) => {
              return memo + b.duration;
            },
            0
          ) -
          _.reduce(
            a.data,
            (memo, a) => {
              return memo + a.duration;
            },
            0
          )
      )
      .forEach((paretoData, i) => {
        const foundMachine = savedSelectedMachines.filter(
          (mac) => mac.id === paretoData.properties.machine_id
        );
        const statusRules = foundMachine[0].status_rules;

        if (paretoData.data.length) {
          let newParetoData = [...paretoData.data];
          const aMachineDataset = {
            machine: foundMachine[0],
            duration: [],
            status: [],
            percent: 0,
            sumPercent: 0,
            value: [],
          };
          if (+this.state.selectedFilter !== 0) {
            if (+this.state.selectedFilter === 2)
              rangeTime -= sumOtherStatusesDuration[i];
          }

          if (
            sumOtherStatusesDuration[i] > 0 &&
            +this.state.selectedFilter === 1
          ) {
            newParetoData = newParetoData.filter(
              (npd) => statusRules[npd.value]
            );
            newParetoData.push({
              duration: sumOtherStatusesDuration[i],
              value: "Others",
            });
          }
          if (sumLostDuration[i] > 0) {
            newParetoData.push({ duration: sumLostDuration[i], value: "LOST" });
          }

          let sumDurationInBar = 0;
          sortedMostDuration.forEach((sorted) => {
            const found = newParetoData.filter(
              (npd) => npd.value === sorted.value
            )[0];
            aMachineDataset.status.push(sorted.status);
            aMachineDataset.duration.push(found ? found.duration : 0);
            aMachineDataset.value.push(sorted.value);
            sumDurationInBar += found ? found.duration : 0;
          });
          const percent = (sumDurationInBar * 100) / rangeTime;
          sumPercent += percent;
          aMachineDataset.percent = percent.toFixed(2);
          aMachineDataset.sumPercent = sumPercent.toFixed(2);
          aMachineDataset.totalDuration = sumDurationInBar;
          updatedStackedParetoData.push(aMachineDataset);
        }
      });

    if (isDownload) {
      this.setState({isDownload: false});
      let exportData = [];
      for(let i = 0 ; i < updatedStackedParetoData.length ; i++) {
        let machineData = updatedStackedParetoData[i];
        for(let j = 0 ; j < machineData.status.length ; j++) {
          exportData.push({
            'Machine': machineData.machine.label,
            'Status': machineData.status[j],
            'Duration': machineData.duration[j],
            'Percentage': ((machineData.duration[j] / machineData.totalDuration) * 100).toFixed(2) + '%',
          })
        }
      }
      const ws = XLSX.utils.json_to_sheet(exportData, {headers: ['Machine', 'Status', 'Duration', 'Percentage']});
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'pareto');
      XLSX.writeFile(wb, 'pareto-export.xlsx');
    }
    this.setState({
      stackedParetoData: updatedStackedParetoData,
      fixedParetoResult: paretoResult,
      paretoResult,
      paretoOptions,
      sumOtherStatusesDuration,
    });
  };

  setDefaultDateTime = () => {
    const defaultStop = moment().add(1, "days").startOf("day").valueOf();
    const defaultStart = new Date().setHours(0, 0, 0, 0);

    this.setState({
      startDate: moment(defaultStart).format("YYYY-MM-DD"),
      startTime: moment(defaultStart).format("HH:mm"),
      stopDate: moment(defaultStop).format("YYYY-MM-DD"),
      stopTime: moment(defaultStop).format("HH:mm"),
    });
  };

  onMachinesChangeHandler = (value) => {
    if (value && value.length > 1 && +this.state.selectedFilter === 0) {
      this.setState({ selectedFilter: 1 });
    }
    this.setState({ currentSelectedMachines: value ? value : [] });
  };

  handleChange = (name, value) => {
    if (
      name === "startDate" ||
      name === "startTime" ||
      name === "stopDate" ||
      name === "stopTime"
    ) {
      this.setState({ autoFillIndex: -1, [name]: value });
    }
    if (name === "selectedFilter") {
      if (+value !== 0 && this.state.currentSelectedMachines.length > 1) {
        this.setState({ [name]: Number(value) });
      } else if (this.state.currentSelectedMachines.length <= 1) {
        this.setState({ [name]: Number(value) });
      }
    }
  };

  autoFillDate = (index) => {
    let dateMoment = moment();

    let thisMonth =
      moment().get("month") + 1 < 10
        ? "0" + (moment().get("month") + 1)
        : moment().get("month") + 1;
    let thisDate =
      moment().get("date") < 10
        ? "0" + moment().get("date")
        : moment().get("date");

    let newStopDate = moment().get("year") + "-" + thisMonth + "-" + thisDate;
    let newStartTime = "00:00";
    let newStopTime = "00:00";
    if (index === 0) {
      // 1 hr
      const minute =
        dateMoment.get("minute") < 10
          ? "0" + dateMoment.get("minute")
          : dateMoment.get("minute");
      newStopTime =
        (dateMoment.get("hour") < 10
          ? "0" + dateMoment.get("hour")
          : dateMoment.get("hour")) +
        ":" +
        minute;
      newStartTime =
        (dateMoment.get("hour") - 1 < 10
          ? "0" + (dateMoment.get("hour") - 1)
          : dateMoment.get("hour") - 1) +
        ":" +
        minute;
    } else if (index === 1) {
      // today (24 hr)
      dateMoment = moment().add(1, "days");
      const newMonth =
        dateMoment.get("month") + 1 < 10
          ? "0" + (dateMoment.get("month") + 1)
          : dateMoment.get("month") + 1;
      const newDate =
        dateMoment.get("date") < 10
          ? "0" + dateMoment.get("date")
          : dateMoment.get("date");
      newStopDate = moment().get("year") + "-" + newMonth + "-" + newDate;
    } else if (index === 2) {
      // TODO: IF PROFILE SETTINGS EXIST, SET GLOBAL FIRST DAY OF WEEK = SUNDAY/MONDAY
      // this week
      dateMoment = moment().day(0);
      thisMonth =
        dateMoment.get("month") + 1 < 10
          ? "0" + (dateMoment.get("month") + 1)
          : dateMoment.get("month") + 1;
      thisDate =
        dateMoment.get("date") < 10
          ? "0" + dateMoment.get("date")
          : dateMoment.get("date");
      const endOfWeek = moment().day(7);
      const newMonth =
        endOfWeek.get("month") + 1 < 10
          ? "0" + (endOfWeek.get("month") + 1)
          : endOfWeek.get("month") + 1;
      const newDate =
        endOfWeek.get("date") < 10
          ? "0" + endOfWeek.get("date")
          : endOfWeek.get("date");
      newStopDate = endOfWeek.get("year") + "-" + newMonth + "-" + newDate;
    } else if (index === 3) {
      // this month
      dateMoment = moment().endOf("month");
      const dateInEndOfMonth =
        dateMoment.get("date") < 10
          ? "0" + dateMoment.get("date")
          : dateMoment.get("date");
      newStopDate =
        dateMoment.get("year") + "-" + thisMonth + "-" + dateInEndOfMonth;
      thisDate = "01";
    }
    let newStartDate =
      dateMoment.get("year") + "-" + thisMonth + "-" + thisDate;

    this.setState({
      autoFillIndex: index,
      startTime: newStartTime,
      startDate: newStartDate,
      stopTime: newStopTime,
      stopDate: newStopDate,
    });
  };

  onClickExportExcel = (event) => {
    if (event) {
      event.preventDefault();
    }
    const { currentSelectedMachines, startDate, startTime, stopDate, stopTime } = this.state;
    const start = moment(startDate + ' ' + startTime);
    const stop = moment(stopDate + ' ' + stopTime);
    currentSelectedMachines.forEach(machine => this.props.getParetoData(machine.id, start, stop));
    this.setState({ isDownload: true, tempParetoDatas: [], stackedParetoData: [], prepParetoData: null, savedSelectedMachines: currentSelectedMachines, openSelectForm: true, userClickOnce: true });
  }

  onSubmitReportHandler = (event) => {
    if (event) {
      event.preventDefault();
    }
    const { currentSelectedMachines, startDate, startTime, stopDate, stopTime } = this.state;
    const start = moment(startDate + ' ' + startTime);
    const stop = moment(stopDate + ' ' + stopTime);
    currentSelectedMachines.forEach(machine => this.props.getParetoData(machine.id, start, stop));
    this.setState({ tempParetoDatas: [], stackedParetoData: [], prepParetoData: null, savedSelectedMachines: currentSelectedMachines, openSelectForm: true, userClickOnce: true });
    // Toggle Report Option Animation
    this.toggleOptionStatus();
  }

  humanizeDuration = (ms, showSec) => {
    const days = moment.duration(ms).days();
    const hours = moment.duration(ms).hours();
    const minutes = moment.duration(ms).minutes();
    const seconds = moment.duration(ms).seconds();
    let result = days === 0 ? "" : days > 1 ? days + " days " : days + " day ";
    result += hours === 0 ? "" : hours > 1 ? hours + " hrs " : hours + " hr ";
    result +=
      minutes === 0 ? "" : minutes > 1 ? minutes + " mins " : minutes + " min ";
    if (showSec) {
      result +=
        seconds === 0
          ? ""
          : seconds > 1
            ? seconds + " secs "
            : seconds + " sec";
    }
    return result;
  };

  getDurationPercentage = (duration, index) => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    var sumDuration = duration.reduce(reducer);
    return `(${((duration[index] / sumDuration) * 100)
      .toFixed(2)
      .toString()} %)`;
  };

  estimatehumanizeDuration = (ms, durationPercentage) => {
    const days = moment.duration(ms).days();
    const hours = moment.duration(ms).hours();
    const minutes = moment.duration(ms).minutes();
    const seconds = moment.duration(ms).seconds();
    const minsec = Math.round(minutes + seconds / 60);
    const hourmin = Math.round(hours + minsec / 60);

    const roundhour =
      hourmin === 0
        ? ""
        : hourmin > 1
          ? hourmin + " hrs "
          : hourmin + " hr ";
    const roundmin =
      minsec === 0
        ? ""
        : minsec > 1
          ? minsec + " mins "
          : minsec + " min ";

    let result =
      seconds === 0 ? "" : seconds > 1 ? seconds + " secs " : seconds + " sec ";

    result =
      minutes === 0
        ? result
        : minutes > 1
          ? minutes + " mins " + result
          : minutes + " min " + result;

    result =
      hours === 0
        ? result
        : hours > 1
          ? roundmin ? "~ " + hours + " hrs " + roundmin : hours + " hrs "
          : roundmin ? "~ " + hours + " hr " + roundmin : hours + " hr ";

    result =
      days === 0
        ? result
        : days > 1
          ? roundhour ? "~ " + days + " days " + roundhour : days + " days "
          : roundhour ? "~ " + days + " day " + roundhour : days + " day ";

    return result + durationPercentage;
  };

  setFillRectColor = (index) => {
    let color =
      this.state.stackedParetoData[0].status[index] !== "Others"
        ? "#7a94ae"
        : "#58595b";
    if (
      this.state.stackedParetoData[0].machine.status_rules[
      this.state.stackedParetoData[0].value[index]
      ]
    ) {
      color = this.state.stackedParetoData[0].machine.status_rules[
        this.state.stackedParetoData[0].value[index]
      ].color;
      color = color === "#222226" ? "black" : color;
    }
    return color;
  };

  onChangeParetoResultHandler = (newParetoResult) => {
    const {
      savedSelectedMachines,
      startDate,
      startTime,
      stopDate,
      stopTime,
    } = this.state;
    const start = moment(startDate + " " + startTime);
    const stop = moment(stopDate + " " + stopTime);
    let rangeTime = (stop - start) * savedSelectedMachines.length;
    if (newParetoResult && newParetoResult.length) {
      this.state.fixedParetoResult.forEach((pd, index) => {
        const founds = newParetoResult.filter((npr) => npr.id === pd.id);
        if (founds.length === 0)
          rangeTime -= this.state.fixedParetoResult[index].duration;
      });
    }

    if (savedSelectedMachines.length === 1) {
      let sumPercent = 0;
      const updatedPrepParetoData = {
        duration: [],
        status: [],
        percent: [],
        sumPercent: [],
        value: [],
      };
      if (newParetoResult && newParetoResult.length) {
        const statusRules = savedSelectedMachines[0].status_rules;

        let otherStatusesDuration = 0;
        if (+this.state.selectedFilter !== 0) {
          otherStatusesDuration = _.reduce(
            this.props.paretoData.data.filter((pd) => !statusRules[pd.value]),
            (memo, oth) => {
              return memo + oth.duration;
            },
            0
          );
          if (+this.state.selectedFilter === 2)
            rangeTime -= otherStatusesDuration;
        }

        // this.state.fixedParetoResult.forEach((pd, index) => {
        //   const founds = newParetoResult.filter(npr => npr.id === pd.id);
        //   if (founds.length === 0) rangeTime -= this.state.fixedParetoResult[index].duration;
        // });

        newParetoResult
          .sort((a, b) => b.duration - a.duration)
          .forEach((pd) => {
            const foundStatus = statusRules[pd.id];
            if (pd.id === "LOST" || pd.id === "Others")
              updatedPrepParetoData.status.push(pd.id);
            else if (+this.state.selectedFilter === 0 || foundStatus)
              updatedPrepParetoData.status.push(
                foundStatus
                  ? foundStatus.reason + " (" + pd.id + ")"
                  : "N/A (" + pd.id + ")"
              );

            updatedPrepParetoData.duration.push(pd.duration);
            updatedPrepParetoData.value.push(pd.id);
            const percent = (pd.duration * 100) / rangeTime;
            sumPercent += percent;
            updatedPrepParetoData.percent.push(percent.toFixed(2));
            updatedPrepParetoData.sumPercent.push(sumPercent.toFixed(2));
          });
      }
      this.setState({ prepParetoData: updatedPrepParetoData });
    } else {
      const updatedStackedParetoData = [];
      if (newParetoResult && newParetoResult.length) {
        let sumPercent = 0;
        let sumLostDuration = [];
        this.state.tempParetoDatas
          .sort(
            (a, b) =>
              _.reduce(
                b.data,
                (memo, b) => {
                  return memo + b.duration;
                },
                0
              ) -
              _.reduce(
                a.data,
                (memo, a) => {
                  return memo + a.duration;
                },
                0
              )
          )
          .forEach((paretoData, i) => {
            const foundMachine = savedSelectedMachines.filter(
              (mac) => mac.id === paretoData.properties.machine_id
            );
            const statusRules = foundMachine[0].status_rules;
            const countableExistingDuration = _.reduce(
              paretoData.data,
              (memo, pd) => {
                return memo + pd.duration;
              },
              0
            );
            sumLostDuration[i] = stop - start - countableExistingDuration;

            let newParetoData = [...paretoData.data];
            const aMachineDataset = {
              machine: foundMachine[0],
              duration: [],
              status: [],
              percent: 0,
              sumPercent: 0,
              value: [],
            };
            if (+this.state.selectedFilter !== 0) {
              if (+this.state.selectedFilter === 2)
                rangeTime -= this.state.sumOtherStatusesDuration[i];
            }

            if (
              this.state.sumOtherStatusesDuration[i] > 0 &&
              +this.state.selectedFilter === 1
            ) {
              newParetoData = newParetoData.filter(
                (npd) => statusRules[npd.value]
              );
              newParetoData.push({
                duration: this.state.sumOtherStatusesDuration[i],
                value: "Others",
              });
            }
            if (sumLostDuration[i] > 0) {
              newParetoData.push({
                duration: sumLostDuration[i],
                value: "LOST",
              });
            }

            let sumDurationInBar = 0;
            newParetoResult
              .sort((a, b) => b.duration - a.duration)
              .forEach((sorted) => {
                const found = newParetoData.filter(
                  (npd) => npd.value === sorted.id
                )[0];
                aMachineDataset.status.push(sorted.label);
                aMachineDataset.duration.push(found ? found.duration : 0);
                aMachineDataset.value.push(sorted.id);
                sumDurationInBar += found ? found.duration : 0;
              });
            const percent = (sumDurationInBar * 100) / rangeTime;
            aMachineDataset.percent = percent.toFixed(2);
            updatedStackedParetoData.push(aMachineDataset);
          });

        updatedStackedParetoData
          .sort(
            (a, b) =>
              _.reduce(
                b.duration,
                (memo, b) => {
                  return memo + b;
                },
                0
              ) -
              _.reduce(
                a.duration,
                (memo, a) => {
                  return memo + a;
                },
                0
              )
          )
          .forEach((newSorted) => {
            sumPercent += +newSorted.percent;
            newSorted.sumPercent = sumPercent.toFixed(2);
          });
      }
      this.setState({ stackedParetoData: updatedStackedParetoData });
    }
    this.setState({ paretoResult: newParetoResult });
  };

  presetDrawerToggleHandler = (saved) => {
    let newStartDiff = new Date(this.state.startDate) - Date.now();
    let newStopDiff = new Date(this.state.stopDate) - Date.now();
    this.setState(prevState => ({
      presetDrawerOpened: !prevState.presetDrawerOpened,
      presetProperty: {
        // machines: this.state.currentSelectedMachines.length > 0 ? this.state.currentSelectedMachines : this.state.savedSelectedMachines,
        machines: this.state.currentSelectedMachines,
        filter: this.state.selectedFilter,
        startDate: this.state.startDate,
        startTime: this.state.startTime,
        stopDate: this.state.stopDate,
        stopTime: this.state.stopTime,
        interval: this.state.autoFillIndex,
        startDiff: newStartDiff,
        stopDiff: newStopDiff,
      }
    }))
  }

  deleteModalClickHandler = (preset) => {
    this.setState(prevState => {
      return {
        isDeleteModalOpened: !prevState.isDeleteModalOpened,
        deletingPreset: preset,
      }
    });
  }

  onConfirmDeletePreset = (event) => {
    event.preventDefault();
    if (this.state.deletingPreset) {
      const { id } = this.state.deletingPreset;
      this.props.deleteReportPreset(id);
      this.setState({ deletingPreset: null, isDeleteModalOpened: false });
    }
  }

  toggleOptionStatus = () => {
    let newOpenSelectForm = !this.state.openSelectForm;
    let formEl = document.querySelector('form');
    if (newOpenSelectForm) {
      this.openForm();
    } else {
      formEl.style.height = 0;
      formEl.style.paddingBottom = 0;
      // formEl.style.removeProperty('transition');
      formEl.style.transition = 'height 500ms ease-out';
    }
    this.setState({
      openSelectForm: newOpenSelectForm,
      // userClickOnce: true,
    });
  }

  openForm = () => {
    const formEl = document.querySelector('form')
    formEl.style.height = '360px';
    formEl.style.transition = 'height 500ms ease-in';
    formEl.style.padding = '20px 22px';
  }

  onNewReportFormHandler = () => {
    // this.props.clearData();
    const resetState = { ...INITIAL_STATE, ...{ preset: null } };
    this.openForm();
    this.setState(resetState);
    this.setDefaultDateTime();
  }

  render() {
    const { loggedInUser, currentOrg } = this.props;
    const { currentSelectedMachines, selectedFilter, startDate, startTime, stopDate, stopTime, paretoResult, paretoOptions, userClickOnce } = this.state;

    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    const isSubmitDisabled = !(currentSelectedMachines && currentSelectedMachines.length) || !startTime || !startDate || !stopTime || !stopDate;

    const options = [];
    if (this.props.machines) {
      this.props.machines.forEach((machine, index) => {
        options.push({
          id: machine.id,
          label: machine.name,
          index,
          status_rules: machine.status_rules,
        });
      });
    }

    let preset_created_user_match = false;
    if (this.state.preset) {
      if (loggedInUser.id === this.state.preset.created_user.id) {
        preset_created_user_match = true;
      }
    }

    return (
      <div className={styles.ReportQueryWrapper}>
        <SnackBar ref={this.snackbarRef} type="success" />
        <div className={styles.ReportTitleSection}>
          <span className={styles.Title}>{this.props.selectedPreset ? this.props.selectedPreset.name : 'Pareto Report'}</span>
          <div className={styles.OptionButtonWrapper}>
            {
              // Preset button for newly create
              !this.state.preset &&
              <div className={styles.SavePresetButtonOption}>
                <Button
                  noMargin
                  type="button"
                  icon={StarYellowIcon}
                  color="yellow-border"
                  name={'Save Preset'}
                  click={() => this.presetDrawerToggleHandler()}
                  loading={this.state.downloading}
                  disabled={currentSelectedMachines.length === 0 || !currentSelectedMachines.length}
                />
              </div>
            }
            {
              (this.state.preset && (loggedInUser.is_superuser || loggedInUser.admin_of_org.includes(currentOrg) || preset_created_user_match))
                ?
                <div className={styles.SavePresetButtonOption}>
                  <Button
                    noMargin
                    type="button"
                    icon={StarYellowIcon}
                    color="yellow-border"
                    name={this.state.presetLoad ? 'Save Changes' : 'Save Preset'}
                    click={() => this.presetDrawerToggleHandler()}
                    loading={this.state.downloading}
                    disabled={currentSelectedMachines.length === 0 || !currentSelectedMachines.length}
                  />
                </div>
                : null
            }
            {userClickOnce &&
              <div className={styles.EditReportButton} onClick={this.toggleOptionStatus}>
                <Button
                  icon={EditWhiteIcon}
                  key={'EditReportButton'}
                  color="green"
                  name="Edit"
                  noMargin
                />
              </div>
            }
            {this.state.presetLoad &&
              <div className={styles.NewReportButtonOption}>
                <Button
                  type="button"
                  icon={AddIcon}
                  color="primary"
                  name="New Report"
                  click={this.onNewReportFormHandler}
                  // loading={this.state.downloading}
                  noMargin />
              </div>
            }
          </div>
        </div>

        <div className={styles.SelectorsSection}>
          {
            !userClickOnce &&
            <img
              src={ReportImage}
              className={styles.ReportCoverImage}
              srcSet={`${ReportImage2x} 2x, ${ReportImage3x} 3x`}
              alt="Report cover"
              loading="lazy"
            />
          }

          <form
            className={styles.FormWrapper}
            style={{ height: this.state.preset ? '0' : '340px' }}
            onSubmit={this.onSubmitReportHandler}
          >
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, marginRight: 20 }}>
                <div
                  className={styles.FormGroup}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1rem",
                  }}
                >
                  <label className={styles.Title}>SELECT MACHINE *</label>
                  <MultipleSelect
                    isMulti
                    isSearchable
                    closeMenuOnSelect={false}
                    options={options}
                    styles={colourStyles}
                    isLoading={this.props.machinesLoading}
                    placeholder="You can select many machines to compare each other"
                    value={currentSelectedMachines}
                    onChange={this.onMachinesChangeHandler}
                    getOptionValue={(opt) => opt.id}
                  />
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <Select
                  name="selectedFilter"
                  label="Filter *"
                  placeholder="Select filtering output"
                  options={FILTER_OPTION}
                  value={selectedFilter}
                  autoComplete="off"
                  overidestyleformgroup={{ marginBottom: 15 }}
                  onChange={(event) =>
                    this.handleChange("selectedFilter", event.target.value)
                  }
                />
              </div>
            </div>

            <div className={styles.FormGroup}>
              <div style={{ flex: 1, marginRight: 20 }}>
                <label className={styles.Title}>Starts *</label>
                <div style={{ display: "flex", position: "relative" }}>
                  {!isChrome && (
                    <img
                      src={CalendarIcon}
                      alt="Starts"
                      className={styles.CalendarIcon}
                    />
                  )}
                  <input
                    className={styles.SelectBox}
                    style={{ paddingLeft: !isChrome ? 46 : null }}
                    type="date"
                    onChange={(event) =>
                      this.handleChange("startDate", event.target.value)
                    }
                    value={startDate}
                  />
                  <input
                    className={styles.SelectBox}
                    type="time"
                    onChange={(event) =>
                      this.handleChange("startTime", event.target.value)
                    }
                    value={startTime}
                  />
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <label className={styles.Title}>Ends *</label>
                <div style={{ display: "flex", position: "relative" }}>
                  {!isChrome && (
                    <img
                      src={CalendarIcon}
                      alt="Ends"
                      className={styles.CalendarIcon}
                    />
                  )}
                  <input
                    className={styles.SelectBox}
                    style={{ paddingLeft: !isChrome ? 46 : null }}
                    type="date"
                    onChange={(event) =>
                      this.handleChange("stopDate", event.target.value)
                    }
                    value={stopDate}
                  />
                  <input
                    className={styles.SelectBox}
                    type="time"
                    onChange={(event) =>
                      this.handleChange("stopTime", event.target.value)
                    }
                    value={stopTime}
                  />
                </div>
              </div>
            </div>
            <div style={{ margin: "10px 0" }}>
              <div
                className={[
                  styles.AutoFillBox,
                  this.state.autoFillIndex === 1 ? styles.Selected : "",
                ].join(" ")}
                onClick={() => this.autoFillDate(1)}
              >
                TODAY
              </div>
              <div
                className={[
                  styles.AutoFillBox,
                  this.state.autoFillIndex === 2 ? styles.Selected : "",
                ].join(" ")}
                onClick={() => this.autoFillDate(2)}
              >
                THIS WEEK
              </div>
              <div
                className={[
                  styles.AutoFillBox,
                  this.state.autoFillIndex === 3 ? styles.Selected : "",
                ].join(" ")}
                onClick={() => this.autoFillDate(3)}
              >
                THIS MONTH
              </div>
            </div>

            <div className={styles.ButtonsWrapper}>
              <div className={styles.DownloadButton}>
                <Button 
                  type="button" 
                  icon={DownloadIcon} 
                  color="green" 
                  name={this.state.downloading ? 'Loading...' : 'Download'} 
                  click={this.onClickExportExcel}
                  disabled={this.props.loading || isSubmitDisabled}
                  loading={this.state.downloading} 
                  noMargin />
              </div>
              <div className={styles.ViewButton}>
              
                <Button
                  type="submit"
                  icon={ViewIcon}
                  color="primary"
                  name="View Pareto"
                  disabled={this.props.loading || isSubmitDisabled}
                  noMargin
                />
              </div>
            </div>
          </form>

          {
            this.props.loading
              ? <div style={{ textAlign: 'center', padding: '100px 0' }}><Spinner /></div>
              : this.state.stackedParetoData && this.state.stackedParetoData.length
                ? <>
                  <ParetoStackedChart stackedParetoData={this.state.stackedParetoData} />
                  <div style={{ height: 20, width: '100%' }} />
                  <MultipleSelect isMulti isSearchable closeMenuOnSelect={false} options={paretoOptions} styles={colourStyles}
                    placeholder="Select statuses to display on a Pareto chart"
                    value={paretoResult}
                    onChange={this.onChangeParetoResultHandler}
                    getOptionValue={opt => opt.id}
                  />
                  <div style={{ marginTop: "30px" }}>
                    {this.state.stackedParetoData.map((device, i) => (
                      <div
                        className={styles.LegendBox}
                        key={device.machine.label}
                        style={{
                          backgroundColor: "#303035",
                          border: "1px solid #1C1C1F",
                          borderRadius: "4px",
                          padding: "4px 0px",
                          marginBottom: "8px",
                        }}
                      >
                        <div
                          style={{
                            width: "11%",
                            textAlign: "center",
                            boxSizing: "border-box",
                          }}
                        >
                          <p className={styles.LegendText} style={{ marginLeft: 0, fontSize: 14 }}>
                            {device.machine.label}
                          </p>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#1C1C1F",
                            height: "30px",
                            width: "2px",
                            margin: "auto auto auto 0px",
                          }}
                        />
                        <div
                          style={{
                            width: "87%",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {device.status.map((fixed, index) =>
                            device.duration[index] !== 0 ? (
                              <div
                                style={{
                                  width: "20%",
                                }}
                                key={fixed}
                              >
                                <div style={{ display: "flex", alignItems: 'center', padding: '10px 0' }}>
                                  <svg
                                    width={12}
                                    height={12}
                                  // style={{ margin: "12px 4px 12px 0px" }}
                                  >
                                    <rect
                                      width={12}
                                      height={12}
                                      style={{
                                        fill: this.setFillRectColor(index),
                                      }}
                                    />
                                  </svg>
                                  <div className={styles.LegendText}>{fixed}</div>
                                </div>
                                <p
                                  className={styles.LegendText}
                                  style={{
                                    fontSize: "11px",
                                    margin: "0px 0px 11px 0px",
                                    color: "#9ea0a5",
                                  }}
                                >
                                  {this.estimatehumanizeDuration(
                                    device.duration[index],
                                    this.getDurationPercentage(
                                      device.duration,
                                      index
                                    )
                                  )}
                                </p>
                              </div>
                            ) : null
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
                : this.state.prepParetoData
                  ? paretoOptions.length
                    ? <>
                      <ParetoChart paretoData={this.state.prepParetoData} />
                      <div style={{ height: 20, width: '100%' }} />
                      <MultipleSelect isMulti isSearchable closeMenuOnSelect={false} options={paretoOptions} styles={colourStyles}
                        placeholder="Select statuses to display on a Pareto chart"
                        value={paretoResult}
                        onChange={this.onChangeParetoResultHandler}
                        getOptionValue={opt => opt.id}
                      />
                      <div style={{ marginTop: "30px" }}>
                        <div
                          className={styles.LegendBox}
                          style={{
                            backgroundColor: "#303035",
                            border: "1px solid #1C1C1F",
                            borderRadius: "4px",
                            padding: "4px 0px",
                            marginBottom: "8px",
                          }}
                        >
                          <div
                            style={{
                              width: "11%",
                              textAlign: "center",
                              boxSizing: "border-box",
                            }}
                          >
                            <p className={styles.LegendText} style={{ marginLeft: 0, fontSize: 14 }}>
                              {this.state.savedSelectedMachines[0].label}
                            </p>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#1C1C1F",
                              height: "30px",
                              width: "2px",
                              margin: "auto auto auto 0px",
                            }}
                          />
                          <div
                            style={{
                              width: "87%",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {this.state.prepParetoData.status.map((status, index) => (
                              <div
                                key={`status-${index}-${status}`}
                                style={{
                                  // marginRight: "28px",
                                  width: "20%",
                                }}
                              >
                                <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
                                  <svg
                                    width={12}
                                    height={12}
                                  // style={{ margin: "12px 4px 12px 0px" }}
                                  >
                                    <rect
                                      width={12}
                                      height={12}
                                      style={{
                                        fill: "#f6ab2f"
                                      }}
                                    />
                                  </svg>
                                  <div className={styles.LegendText}>{status}</div>
                                </div>
                                <p
                                  className={styles.LegendText}
                                  style={{
                                    fontSize: "11px",
                                    margin: "0px 0px 11px 0px",
                                    color: "#9ea0a5",
                                  }}
                                >
                                  {this.estimatehumanizeDuration(
                                    this.state.prepParetoData.duration[index],
                                    this.getDurationPercentage(this.state.prepParetoData.duration, index)
                                  )}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                    : <div style={{ textAlign: 'center', padding: '100px 0' }}>No Data</div>
                  : null
          }
        </div>

        <SideDrawer
          noHeader
          open={this.state.presetDrawerOpened}
          closed={this.presetDrawerToggleHandler}
        >
          {
            this.state.presetDrawerOpened ?
              <PresetDrawer
                closed={this.presetDrawerToggleHandler}
                orgId={this.props.currentOrg}
                settingUpNewAddedWidget={this.settingUpNewAddedWidget}
                presetType={'pareto'}
                presetDrawerToggle={this.presetDrawerToggleHandler}
                preset={this.state.preset}
                presetProperty={this.state.presetProperty}
                onDelete={() => this.deleteModalClickHandler(this.state.preset)}
              />
              : null
          }
        </SideDrawer>
        <DeleteModal
          title="widget"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeletePreset}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { machines } = state.machine;
  const { loggedInUser } = state.auth;
  const { currentOrg } = state.org;
  const { loading, paretoData } = state.timelineStatus;
  const { type, result, addedReportPreset, updatedReportPreset } = state.reportPreset;

  return {
    loggedInUser, currentOrg, machines, machinesLoading: state.machine.loading, type, result, addedReportPreset, updatedReportPreset,
    loading, paretoData
  };
};

export default connect(mapStateToProps, { getMachines, getParetoData, deleteReportPreset })(ParetoReport);
