import React, { Component } from 'react';

import styles from './VerifiedSuccess.module.scss';
import Button from '../../UI/Button/Button';


class VerifiedSuccess extends Component {

  render() {

    let SuccessPage = (
      <React.Fragment>
        <div className={styles.Header}>Verified! <span role="img" aria-label="success">️✌️</span></div>
        <div>
          <div className={styles.Description}>Yeah ! You have successfully verified your Email.</div>
          <div className={styles.Description}>Please login to start OWL.</div>
        </div>
      </React.Fragment>
    );

    if (this.props.type === "resetPassword") {
      SuccessPage = (
        <React.Fragment>
          <div className={styles.Header}>Successful! <span role="img" aria-label="success">️✌️</span></div>
          <div>
            <div className={styles.Description}>Yeah ! You already reset your password.</div>
            <div className={styles.Description}>Please login to start OWL.</div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className={styles.VerifiedSuccess}>
        <div className={styles.Container}>
          <div style={{ flex: 1 }}>
            {SuccessPage}
          </div>
          <div className={styles.ButtonContainer}>
            <Button type="button" click={this.props.goBackToLogin} color="green" name="Login" />
          </div>
        </div>
      </div>
    );
  }
}

export default VerifiedSuccess;
