import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';

class ParetoChart extends Component {
	state = {
		chartData: null,
		options: null
	}

	componentDidMount() {
		this.setupData(this.props.paretoData)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.paretoData && !_.isEqual(prevProps.paretoData, this.props.paretoData)) {
			this.setupData(this.props.paretoData)
		}
	}

	setupOptions = (paretoData) => {
		const options = {
			responsive: true,
			tooltips: {
				mode: 'index',
				intersect: false,
				callbacks: {
					label: (tooltipItem, data) => {
						if (tooltipItem.datasetIndex === 1) {
							return this.humanizeDuration(tooltipItem.value, true) + ' (' + paretoData.percent[tooltipItem.index] + '%)';
						} else {
							return tooltipItem.value + '%';
						}
					}
				}
			},
			hover: {
				mode: 'index',
				intersect: false
			},
			elements: {
				line: {
					fill: false
				}
			},
			scales: {
				xAxes: [
					{
						display: true,
						gridLines: {
							display: true
						},
						// labels: {
						// 	show: true
						// },
						labels: paretoData.status,
					}
				],
				yAxes: [
					{
						type: 'linear',
						display: true,
						position: 'left',
						id: 'y-axis-1',
						gridLines: {
							display: false
						},
						labels: {
							show: true
						},
						ticks: {
							stepSize: 2 * 60 * 60 * 1000,
							beginAtZero: true,
							callback: (value, index, values) => {
								return this.humanizeDuration(value);
							}
						}
					},
					{
						type: 'linear',
						display: true,
						position: 'right',
						id: 'y-axis-2',
						gridLines: {
							display: true
						},
						labels: {
							show: true
						},
						ticks: {
							beginAtZero: true,
							callback: function (value, index, values) {
								return value + " %";
							}
						}
					}
				]
			}
		}
		this.setState({ options });
	}

	humanizeDuration = (ms, showSec) => {
		const days = moment.duration(ms).days();
		const hours = moment.duration(ms).hours();
		const minutes = moment.duration(ms).minutes();
		const seconds = moment.duration(ms).seconds();

		let result = days === 0 ? '' : days > 1 ? days + ' days ' : days + ' day ';
		result += hours === 0 ? '' : hours > 1 ? hours + ' hours ' : hours + ' hour ';
		result += minutes === 0 ? '' : minutes > 1 ? minutes + ' minutes ' : minutes + ' minute ';
		if (showSec) {
			result += seconds === 0 ? '' : seconds > 1 ? seconds + ' seconds ' : seconds + ' second';
		}

		return result;
	}

	setupData = (paretoData) => {
		this.setupOptions(paretoData)
		let chartData = {
			datasets: [{
				label: 'Cumulative percentage',
				type: 'line',
				data: paretoData.sumPercent,
				fill: false,
				borderColor: '#1665d8',
				backgroundColor: '#1665d8',
				pointBorderColor: '#1665d8',
				pointBackgroundColor: '#1665d8',
				pointHoverBackgroundColor: '#4483df',
				pointHoverBorderColor: '#4483df',
				yAxisID: 'y-axis-2'
			}, {
				type: 'bar',
				label: 'Complaint frequency',
				data: paretoData.duration,
				fill: false,
				backgroundColor: '#f6ab2f',
				borderColor: '#f6ab2f',
				hoverBackgroundColor: 'rgb(250, 194, 154)',
				hoverBorderColor: 'rgb(250, 194, 154)',
				yAxisID: 'y-axis-1'
			}]
		};

		this.setState({ chartData });
	}

	render() {
		return (
			<div style={{ width: '99%', position: 'relative' }}>
				{
					this.state.chartData && this.state.options ?
						<Bar data={this.state.chartData} height={100} options={this.state.options} />
						: null
				}
			</div>
		);
	}
}

export default ParetoChart;