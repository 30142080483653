import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_ACTUAL_PRODUCTION, UPDATE_ACTUAL_PRODUCTION, DELETE_ACTUAL_PRODUCTION, START_PLAN, STOP_PLAN, CALCULATE_OEE } from './types';

export function* watchActualProduction() {
  yield takeEvery(GET_ACTUAL_PRODUCTION, getActualProductionSaga);
  yield takeEvery(UPDATE_ACTUAL_PRODUCTION, updateActualProductionSaga);
  yield takeEvery(DELETE_ACTUAL_PRODUCTION, deleteActualProductionSaga);
  yield takeEvery(START_PLAN, startPlanSaga);
  yield takeEvery(STOP_PLAN, stopPlanSaga);
  yield takeEvery(CALCULATE_OEE, calculateOeeSaga);
}

export function* getActualProductionSaga(params) {
  yield put(actions.getActualProductionStart());

  let api = 'v1/advanceactualproduction?org_id=' + params.org_id;
  let offset = 0;
  if (params.machine_ids)
    api += '&machine_ids=' + params.machine_ids;
  if (params.structure_uuid)
    api += '&structure_uuid=' + encodeURIComponent(params.structure_uuid);
  if (params.start)
    api += '&start=' + params.start;
  if (params.end)
    api += '&end=' + params.end;

  let ret = [];
  try {
    while (true) {
      const response = yield axios.get(api + '&offset=' + offset);
      if (response.status === 200 && response.data.result === 'success') {
        ret = ret.concat(response.data.productions);
        if (response.data.productions.length < 100) {
          // console.log("GET_ACTUAL_PRODUCTION_SUCCESS", ret);
          yield put(actions.getActualProductionSuccess(ret));
          break;
        }
        offset += response.data.productions.length;
      } else {
        console.log("GET_ACTUAL_PRODUCTION_FAIL", response);
        yield put(actions.getActualProductionFail('GET ACTUAL PRODUCTION FAIL'));
        break;
      }
    }
  } catch (error) {
    console.log("GET_ACTUAL_PRODUCTION_FAIL", error);
    yield put(actions.getActualProductionFail(error));
  }
}

export function* updateActualProductionSaga(params) {
  yield put(actions.updateActualProductionStart());

  let api = 'v1/advanceactualproduction';
  let putData = "uuid=" + params.uuid;
  putData += params.start ? "&start=" + params.start : '';
  putData += params.end ? "&end=" + params.end : '';
  putData += params.total ? "&total=" + params.total : '';
  putData += params.ok ? "&ok=" + params.ok : '';
  putData += params.defect ? "&defect=" + params.defect : '';
  putData += params.columns ? "&columns=" + encodeURIComponent(JSON.stringify(params.columns)) : '';

  try {
    const response = yield axios.put(api, putData);
    console.log("UPDATE_ACTUAL_PRODUCTION SUCCESS", response.data);
    yield put(actions.updateActualProductionSuccess(response.data));
  } catch (err) {
    console.log("UPDATE_ACTUAL_PRODUCTION FAILED", err)
    yield put(actions.updateActualProductionFail(err.response));
  }
}

export function* deleteActualProductionSaga(params) {
  yield put(actions.deleteActualProductionStart());

  let api = 'v1/advanceactualproduction?uuid=' + params.uuid;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE_ACTUAL_PRODUCTION SUCCESS", response.data);
    yield put(actions.deleteActualProductionSuccess(response.data));
  } catch (err) {
    console.log("DELETE_ACTUAL_PRODUCTION FAILED", err)
    yield put(actions.deleteActualProductionFail(err.response));
  }
}

export function* startPlanSaga(params) {
  yield put(actions.startPlanStart());

  let postData = "plan_uuid=" + params.plan_uuid;
  postData += params.start ? "&start=" + params.start : '';
  postData += params.machine_id ? "&machine_id=" + params.machine_id : '';
  postData += params.target ? "&target=" + params.target : '';
  postData += params.columns ? "&columns=" + JSON.stringify(params.columns) : '';

  const api = 'v1/advanceactualproduction/start';

  try {
    const response = yield axios.post(api, postData);
    console.log("START PLAN SUCCESS", response.data);
    yield put(actions.startPlanSuccess(response.data));
  } catch (err) {
    console.log("START PLAN FAILED", err.response.data);
    yield put(actions.startPlanFail(err.response.data));
  }
}

export function* stopPlanSaga(params) {
  yield put(actions.stopPlanStart());

  const api = 'v1/advanceactualproduction/stop';
  let postData = "uuid=" + params.uuid;
  postData += params.total ? "&total=" + params.total : '';
  postData += params.ok ? "&ok=" + params.ok : '';
  postData += params.defect ? "&defect=" + params.defect : '';
  postData += params.columns ? "&columns=" + JSON.stringify(params.columns) : '';

  try {
    const response = yield axios.post(api, postData);
    console.log("STOP PLAN SUCCESS", response.data);
    yield put(actions.stopPlanSuccess(response.data));
  } catch (err) {
    console.log("STOP PLAN FAILED", err.response);
    yield put(actions.stopPlanFail(err.response));
  }
}

export function* calculateOeeSaga(params) {
  yield put(actions.calculateOeeStart());

  let api = 'v1/advanceactualproduction/apq';
  api += "?uuid=" + params.actual_uuid;
  api += params.stop_point ? "&stop_point=" + params.stop_point : '';
  api += params.start ? "&start=" + params.start : '';
  api += params.end ? "&end=" + params.end : '';
  api += params.interval ? "&interval=" + params.interval : '';
  api += params.is_floating ? "&is_floating=" + params.is_floating : '';

  try {
    const response = yield axios.get(api);
    // console.log("CALCULATE OEE SUCCESS", response.data);
    yield put(actions.calculateOeeSuccess(response.data, params.properties));
  } catch (err) {
    console.log("CALCULATE OEE FAILED", err.response);
    yield put(actions.calculateOeeFail(err.response));
  }
}
