import React, { useEffect } from 'react';

import styles from './StartStopPOModal.module.scss';
import Modal from '../../UI/Modal/Modal';
import StartPoBg from '../../../../assets/images/startpo.svg';
import StopPoBg from '../../../../assets/images/stoppo.svg';
import Button from '../../UI/Button/Button';

const StartStopPOModal = ({ open, modalClosed, type, confirm, planProps }) => {

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, []);

  function handleEsc(event) {
    if (open && event.keyCode === 27) {
      modalClosed();
    }
  };

  return (
    <Modal show={open} modalClosed={modalClosed}>
      <div className={styles.StartStopPoModal}>
        <div className={styles.Title}>Are you sure to <span className={`${type === 'start' ? styles.Green : styles.Red}`}>{type} #{planProps ? planProps.po.value : ''}</span><br /> at <span className={`${type === 'start' ? styles.Green : styles.Red}`}>
          {planProps ? planProps.machine.value.name : ''}</span> ?
        </div>
        <div className={styles.Description}>Click yes and this production plan will {type === 'start' ? 'be recording' : 'stop recording'}</div>
        <div className={styles.Description}>and you can not undo this.</div>

        <div className={styles.YesButtonWrapper}>
          <Button type="button" color={type === 'start' ? 'green' : 'red'} name={type === 'start' ? 'Yes, START it' : 'Yes, STOP it'} click={confirm} />
        </div>
        <div className={styles.CancelButton} onClick={modalClosed}>Cancel</div>

      </div>
      <div className={styles.Background} style={{ backgroundImage: `url(${type === 'start' ? StartPoBg : StopPoBg})` }} />
    </Modal>
  );
}

export default StartStopPOModal;