import * as actionTypes from './types';
import { updateObject } from '../../utils';

const localUser = localStorage.getItem("owlUser");

const initialState = {
  result: null,
  error: null,
  loading: false,
  loggedInUser: localUser ? JSON.parse(localUser) : null,
  verifiedStatus: null,
  forgetPasswordStatus: null,
  updatedUser: null,
  editType: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNIN_START: return signinStart(state, action);
    case actionTypes.SIGNIN_SUCCESS: return signinSuccess(state, action);
    case actionTypes.SIGNIN_FAIL: return signinFail(state, action);

    case actionTypes.SIGNUP_START: return signupStart(state, action);
    case actionTypes.SIGNUP_SUCCESS: return signupSuccess(state, action);
    case actionTypes.SIGNUP_FAIL: return signupFail(state, action);

    case actionTypes.SIGNOUT_SUCCESS: return signoutSuccess(state, action);

    case actionTypes.RENEW_TOKEN_SUCCESS: return renewTokenSuccess(state, action);

    case actionTypes.VERIFIED_USER_START: return verifiedUserStart(state, action);
    case actionTypes.VERIFIED_USER_SUCCESS: return verifiedUserSuccess(state, action);
    case actionTypes.VERIFIED_USER_FAIL: return verifiedUserFail(state, action);

    case actionTypes.RESEND_VERIFY_START: return resendVerifyStart(state, action);
    case actionTypes.RESEND_VERIFY_SUCCESS: return resendVerifySuccess(state, action);
    case actionTypes.RESEND_VERIFY_FAIL: return resendVerifyFail(state, action);

    case actionTypes.FORGET_PASSWORD_START: return forgetPasswordStart(state, action);
    case actionTypes.FORGET_PASSWORD_SUCCESS: return forgetPasswordSuccess(state, action);
    case actionTypes.FORGET_PASSWORD_FAIL: return forgetPasswordFail(state, action);

    case actionTypes.RESET_PASSWORD_START: return resetPassStart(state, action);
    case actionTypes.RESET_PASSWORD_SUCCESS: return resetPassSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAIL: return resetPassFail(state, action);

    case actionTypes.GET_LOGGEDIN_USER_START: return getLoggedInUserStart(state, action)
    case actionTypes.GET_LOGGEDIN_USER_SUCCESS: return getLoggedInUserSuccess(state, action)
    case actionTypes.GET_LOGGEDIN_USER_FAIL: return getLoggedInUserFail(state, action)

    case actionTypes.EDIT_USER_START: return editUserStart(state, action);
    case actionTypes.EDIT_USER_SUCCESS: return editUserSuccess(state, action);
    case actionTypes.EDIT_USER_FAIL: return editUserFail(state, action);

    default: return state
  }
};

const signinStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const signinSuccess = (state, action) => {
  return updateObject(state, {
    loggedInUser: action.user,
    error: null,
    loading: false
  });
}

const signinFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const signupStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const signupSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false
  });
}

const signupFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const signoutSuccess = (state, action) => {
  return updateObject(state, {
    loggedInUser: null,
    error: null
  });
}

const renewTokenSuccess = (state, action) => {
  localStorage.setItem('owlJwtToken', action.token);
  return state;
}

const verifiedUserStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
}

const verifiedUserSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    verifiedStatus: action.result,
  });
}

const verifiedUserFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    verifiedStatus: action.result,
  });
}

const resendVerifyStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const resendVerifySuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false
  });
}

const resendVerifyFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const forgetPasswordStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
}

const forgetPasswordSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    forgetPasswordStatus: action.result,
  });
}

const forgetPasswordFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const resetPassStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
}

const resetPassSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
  });
}

const resetPassFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getLoggedInUserStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
}

const getLoggedInUserSuccess = (state, action) => {
  return updateObject(state, {
    loggedInUser: action.user,
    loading: false
  });
}

const getLoggedInUserFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editUserStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, editType: action.editType });
}

const editUserSuccess = (state, action) => {
  return updateObject(state, {
    result: action.result,
    updatedUser: action.updatedUser,
    error: null,
    loading: false,
    editType: null
  });
}

const editUserFail = (state, action) => {
  return updateObject(state, {
    result: action.result,
    error: action.error,
    loading: false,
    editType: null
  });
}

export default reducer;