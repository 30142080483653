import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import styles from "./SwitchOrganization.module.scss";
import orgStyles from "../Organization.module.scss";
import Button from "../../UI/Button/Button";
import SearchIcon from "../../../../assets/icons/search-orgs.svg";
import RadioUnselectIcon from "../../../../assets/icons/radio-unselect.svg";
import RadioSelectIcon from "../../../../assets/icons/radio-select.svg";
import PlusCircleIcon from "../../../../assets/icons/plus-circle.svg";
import DefaultOrgLogoIcon from "../../../../assets/icons/Organization/default-org-logo.svg";
import EditIcon from "../../../../assets/icons/edit-gray.svg";
import LogoutIcon from "../../../../assets/icons/logout.svg";

import {
  setCurrentOrg,
  setfilteredOrgBuff,
} from "../../../../state/ducks/Organization/actions";

class SwitchOrganization extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.inputRef = React.createRef();
  }

  state = {
    searchText: "",
  };

  onChangeCurrentOrg = (orgId) => {
    if (orgId && orgId !== this.props.currentOrg) {
      localStorage.setItem("owlCurrentOrg", orgId);
      this.props.setCurrentOrg(orgId);
      this.props.modalClosed();
    }
  };

  handleTextChange = (event) => {
    this.props.keyboardListenerHandler(false);
    this.setState({ searchText: event.target.value });
  };

  scrollToMyRef = () =>
    this.myRef.current.scrollIntoView({ behavior: "smooth" });

  componentDidUpdate(prevProps) {
    let filteredOrg = null;
    let isEqual;
    if (this.props.orgs) {
      filteredOrg = this.props.orgs.filter(
        (org) =>
          org.name
            .toLowerCase()
            .indexOf(this.state.searchText.toLowerCase()) !== -1
      );
      if (
        filteredOrg !== null &&
        this.props.filteredOrgBuff !== null &&
        this.props.filteredOrgBuff.length !== filteredOrg.length
      ) {
        isEqual = filteredOrg.length === this.props.orgs.length;
        this.props.setfilteredOrgBuff(filteredOrg, isEqual);
      }
    }

    if (
      this.props.filteredOrgBuff === null &&
      this.props.orgs !== null &&
      filteredOrg !== null
    ) {
      isEqual = filteredOrg.length === this.props.orgs.length;
      this.props.setfilteredOrgBuff(filteredOrg, isEqual);
    }

    if (this.props.filteredOrgBuff !== prevProps.filteredOrgBuff) {
      if (this.myRef.current !== null) {
        this.myRef.current.scrollIntoView({ block: "center" });
        if (this.props.keyboardListenner) {
          this.inputRef.current.blur();
        }
      }
    }
  }

  handlerInitialFilteredOrgBuff = (filteredOrg, isEqual) => {
    isEqual = filteredOrg.length === this.props.orgs.length;
    this.props.setfilteredOrgBuff(filteredOrg, isEqual);
  };

  render() {
    const isAdmin =
      this.props.loggedInUser && this.props.loggedInUser.is_superuser;
    let isMobile = this.props.isMobile;
    let filteredOrg = null;

    if (this.props.orgs) {
      filteredOrg = this.props.orgs.filter(
        (org) =>
          org.name
            .toLowerCase()
            .indexOf(this.state.searchText.toLowerCase()) !== -1
      );
    }

    return (
      <div className={orgStyles.OrganizationModal}>
        <div className={orgStyles.HeaderWrapper}>
          <div className={orgStyles.Header}>Switch Organization</div>
          {isMobile ? null : (
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ position: "relative" }}>
                  <input
                    ref={this.inputRef}
                    className={`${styles.FormField} ${styles.WithIcon}`}
                    name="searchText"
                    type="text"
                    placeholder="Search Organization"
                    value={this.state.searchText}
                    autoComplete="off"
                    onChange={(event) => {
                      this.handleTextChange(event);
                    }}
                  />
                  <img
                    className={styles.Icon}
                    src={SearchIcon}
                    alt={`Search icon`}
                  />
                </div>
                <div style={{ width: 185 }}>
                  <Button
                    type="button"
                    icon={PlusCircleIcon}
                    color="primary"
                    name="Add Organization"
                    noMargin
                    click={this.props.onAddingOrg}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div style={{ overflow: "auto", height: "75vh" }}>
          {this.props.filteredOrgBuff !== null
            ? +filteredOrg.length === this.props.filteredOrgBuff.length
              ? filteredOrg.map((org, index) => {
                  var coverColor = this.props.currentOrg
                    ? +this.props.filteredOrgBuff[index]["didSelected"]
                      ? "#E8EAEF"
                      : "white"
                    : null;
                  var focusRef = this.props.currentOrg
                    ? +this.props.filteredOrgBuff[index]["didSelected"]
                      ? this.myRef
                      : null
                    : null;
                  return (
                    <div
                      ref={focusRef}
                      style={{ backgroundColor: coverColor }}
                      key={`Org${org.id}`}
                      className={styles.OrgWrapper}
                      id={org.id}
                      onClick={() => this.onChangeCurrentOrg(org.id)}
                    >
                      {this.props.currentOrg ? (
                        +this.props.filteredOrgBuff[index]["didSelected"] ? (
                          <img
                            style={{ cursor: "pointer" }}
                            src={RadioSelectIcon}
                            alt="radio check"
                          />
                        ) : (
                          <img
                            style={{ cursor: "pointer" }}
                            src={RadioUnselectIcon}
                            alt="radio uncheck"
                          />
                        )
                      ) : null}
                      {org.logo ? (
                        <img
                          className={styles.OrgLogo}
                          src={org.logo}
                          alt="organization logo"
                        />
                      ) : (
                        <img
                          className={styles.OrgLogo}
                          src={DefaultOrgLogoIcon}
                          alt="organization logo"
                        />
                      )}
                      <div className={styles.NameAndInformation}>
                        <div className={styles.Name}>{org.name}</div>
                        <div className={styles.OtherInformation}>
                          Created date:{" "}
                          {moment(org.created_date).format(
                            "MMM DD, YYYY HH:mm"
                          )}
                        </div>
                      </div>
                      {isMobile ? null : (
                        <div className={styles.RightSection}>
                          {this.props.currentOrg
                            ? +this.props.currentOrg === org.id && (
                                <div className={styles.CurrentOrg}>CURRENT</div>
                              )
                            : null}
                          {(this.props.loggedInUser &&
                            this.props.loggedInUser.admin_of_org &&
                            this.props.loggedInUser.admin_of_org.includes(
                              org.id
                            )) ||
                          isAdmin ? (
                            <div className={styles.OrgIconButton}>
                              <img
                                alt="edit org icon"
                                src={EditIcon}
                                onClick={() => this.props.onEditingOrg(org.id)}
                              />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  );
                })
              : null
            : null}
          {isMobile ? (
            <div className={orgStyles.SignoutBuffon}>
              <Button
                type="button"
                icon={LogoutIcon}
                color="red"
                name="Log Out"
                click={this.props.signout}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { orgs, currentOrg, filteredOrgBuff } = state.org;
  return { loggedInUser, orgs, currentOrg, filteredOrgBuff };
};

export default connect(mapStateToProps, { setCurrentOrg, setfilteredOrgBuff })(
  SwitchOrganization
);
