import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  advanceProductionPlans: null,
  addedAdvanceProductionPlan: null,
  updatedAdvanceProductionPlan: null,
  resultSearchPlans: null,
  latestQueryParams: null,
  uploadedPlans: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_ADVANCE_PRODUCTION_PLANS: return clearAdvanceProductionPlans(state, action);
    case actionTypes.CLEAR_UPLOADED_PLANS: return clearUploadedPlans(state, action);

    case actionTypes.SEARCH_ADVANCE_PRODUCTION_PLANS_START: return searchAdvanceProductionPlansStart(state, action);
    case actionTypes.SEARCH_ADVANCE_PRODUCTION_PLANS_SUCCESS: return searchAdvanceProductionPlansSuccess(state, action);
    case actionTypes.SEARCH_ADVANCE_PRODUCTION_PLANS_FAIL: return searchAdvanceProductionPlansFail(state, action);

    case actionTypes.GET_ADVANCE_PRODUCTION_PLANS_START: return getAdvanceProductionPlansStart(state, action);
    case actionTypes.GET_ADVANCE_PRODUCTION_PLANS_SUCCESS: return getAdvanceProductionPlansSuccess(state, action);
    case actionTypes.GET_ADVANCE_PRODUCTION_PLANS_FAIL: return getAdvanceProductionPlansFail(state, action);

    case actionTypes.ADD_ADVANCE_PRODUCTION_PLAN_START: return addAdvanceProductionPlanStart(state, action);
    case actionTypes.ADD_ADVANCE_PRODUCTION_PLAN_SUCCESS: return addAdvanceProductionPlanSuccess(state, action);
    case actionTypes.ADD_ADVANCE_PRODUCTION_PLAN_FAIL: return addAdvanceProductionPlanFail(state, action);

    case actionTypes.UPLOAD_ADVANCE_PRODUCTION_PLANS_START: return uploadAdvanceProductionPlansStart(state, action);
    case actionTypes.UPLOAD_ADVANCE_PRODUCTION_PLANS_SUCCESS: return uploadAdvanceProductionPlansSuccess(state, action);
    case actionTypes.UPLOAD_ADVANCE_PRODUCTION_PLANS_FAIL: return uploadAdvanceProductionPlansFail(state, action);

    case actionTypes.EDIT_ADVANCE_PRODUCTION_PLAN_START: return editAdvanceProductionPlanStart(state, action);
    case actionTypes.EDIT_ADVANCE_PRODUCTION_PLAN_SUCCESS: return editAdvanceProductionPlanSuccess(state, action);
    case actionTypes.EDIT_ADVANCE_PRODUCTION_PLAN_FAIL: return editAdvanceProductionPlanFail(state, action);

    case actionTypes.DELETE_ADVANCE_PRODUCTION_PLAN_START: return deleteAdvanceProductionPlanStart(state, action);
    case actionTypes.DELETE_ADVANCE_PRODUCTION_PLAN_SUCCESS: return deleteAdvanceProductionPlanSuccess(state, action);
    case actionTypes.DELETE_ADVANCE_PRODUCTION_PLAN_FAIL: return deleteAdvanceProductionPlanFail(state, action);

    default: return state;
  }
};

const clearAdvanceProductionPlans = (state, action) => {
  return updateObject(state, { result: null, error: null, advanceProductionPlans: null });
}

const clearUploadedPlans = (state, action) => {
  return updateObject(state, { result: null, error: null, uploadedPlans: null });
}

const searchAdvanceProductionPlansStart = (state, action) => {
  return updateObject(state, { result: null, error: null, resultSearchPlans: null });
}

const searchAdvanceProductionPlansSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    resultSearchPlans: action.resultSearchPlans,
    error: null,
    result: action.result,
    // latestQueryParams: action.saveParams
  });
}

const searchAdvanceProductionPlansFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    result: null
  });
}

const getAdvanceProductionPlansStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, latestQueryParams: null });
}

const getAdvanceProductionPlansSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    advanceProductionPlans: action.advanceProductionPlans,
    error: null,
    loading: false,
    result: action.result,
    latestQueryParams: action.saveParams
  });
}

const getAdvanceProductionPlansFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    result: null
  });
}

const addAdvanceProductionPlanStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addAdvanceProductionPlanSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedAdvanceProductionPlan: action.addedAdvanceProductionPlan,
    error: null,
    loading: false
  });
}

const addAdvanceProductionPlanFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const uploadAdvanceProductionPlansStart = (state, action) => {
  return updateObject(state, { result: null, error: null, uploadedPlans: null });
}

const uploadAdvanceProductionPlansSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    uploadedPlans: action.uploadedPlans,
    error: null
  });
}

const uploadAdvanceProductionPlansFail = (state, action) => {
  return updateObject(state, {
    error: action.error.error,
    loading: false
  });
}

const editAdvanceProductionPlanStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editAdvanceProductionPlanSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedAdvanceProductionPlan: action.updatedAdvanceProductionPlan,
    error: null,
    loading: false
  });
}

const editAdvanceProductionPlanFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteAdvanceProductionPlanStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteAdvanceProductionPlanSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteAdvanceProductionPlanFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;