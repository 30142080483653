export const GET_PAGES_START = 'GET_PAGES_START';
export const GET_PAGES = 'GET_PAGES';
export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';
export const GET_PAGES_FAIL = 'GET_PAGES_FAIL';

export const GET_PAGE_AND_MEMBERS_START = 'GET_PAGE_AND_MEMBERS_START';
export const GET_PAGE_AND_MEMBERS = 'GET_PAGE_AND_MEMBERS';
export const GET_PAGE_AND_MEMBERS_SUCCESS = 'GET_PAGE_AND_MEMBERS_SUCCESS';
export const GET_PAGE_AND_MEMBERS_FAIL = 'GET_PAGE_AND_MEMBERS_FAIL';

export const ADD_PAGE_START = 'ADD_PAGE_START';
export const ADD_PAGE = 'ADD_PAGE';
export const ADD_PAGE_SUCCESS = 'ADD_PAGE_SUCCESS';
export const ADD_PAGE_FAIL = 'ADD_PAGE_FAIL';

export const EDIT_PAGE_START = 'EDIT_PAGE_START';
export const EDIT_PAGE = 'EDIT_PAGE';
export const EDIT_PAGE_SUCCESS = 'EDIT_PAGE_SUCCESS';
export const EDIT_PAGE_FAIL = 'EDIT_PAGE_FAIL';

export const DELETE_PAGE_START = 'DELETE_PAGE_START';
export const DELETE_PAGE = 'DELETE_PAGE';
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_FAIL = 'DELETE_PAGE_FAIL';

export const UPDATE_PAGE_AFTER_ADD_WIDGET = 'UPDATE_PAGE_AFTER_ADD_WIDGET';

export const ADD_PAGE_MEMBERS_START = 'ADD_PAGE_MEMBERS_START';
export const ADD_PAGE_MEMBERS = 'ADD_PAGE_MEMBERS';
export const ADD_PAGE_MEMBERS_SUCCESS = 'ADD_PAGE_MEMBERS_SUCCESS';
export const ADD_PAGE_MEMBERS_FAIL = 'ADD_PAGE_MEMBERS_FAIL';

export const REMOVE_PAGE_MEMBERS_START = 'REMOVE_PAGE_MEMBERS_START';
export const REMOVE_PAGE_MEMBERS = 'REMOVE_PAGE_MEMBERS';
export const REMOVE_PAGE_MEMBERS_SUCCESS = 'REMOVE_PAGE_MEMBERS_SUCCESS';
export const REMOVE_PAGE_MEMBERS_FAIL = 'REMOVE_PAGE_MEMBERS_FAIL';

export const CHANGE_PAGE_ADMIN_START = 'CHANGE_PAGE_ADMIN_START';
export const CHANGE_PAGE_ADMIN = 'CHANGE_PAGE_ADMIN';
export const CHANGE_PAGE_ADMIN_SUCCESS = 'CHANGE_PAGE_ADMIN_SUCCESS';
export const CHANGE_PAGE_ADMIN_FAIL = 'CHANGE_PAGE_ADMIN_FAIL';

export const LEAVE_PAGE_START = 'LEAVE_PAGE_START';
export const LEAVE_PAGE = 'LEAVE_PAGE';
export const LEAVE_PAGE_SUCCESS = 'LEAVE_PAGE_SUCCESS';
export const LEAVE_PAGE_FAIL = 'LEAVE_PAGE_FAIL';