import React from 'react';
import { Group } from '@vx/group';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { curveMonotoneX } from '@vx/curve';
import { LinePath } from "@vx/shape";

// Initialize constants and variables
const axisColor = '#fff';
const axisBottomTickLabelProps = {
  textAnchor: 'middle',
  fontFamily: 'Arial',
  fontSize: 10,
  fill: axisColor,
};
const axisLeftTickLabelProps = {
  dx: '-0.25em',
  dy: '0.25em',
  fontFamily: 'Arial',
  fontSize: 10,
  textAnchor: 'end',
  fill: axisColor,
};
// const accentColorDark = '#75daad';

// Accessors function
const getDate = (d) => new Date(d.timestamp);
const getDataValue = (d) => d.value;
// const bisectDate = bisector(d => new Date(d.timestamp)).left;

const LineChartVx = 
  ({
    data,
    width,
    height,
    yMax,
    margin,
    xScale,
    yScale,
    hideBottomAxis = false,
    hideLeftAxis = false,
    top,
    left,
    children,
    color,
    dataUnit,
  }) => {
    if (width < 10) return null;

    return (
      <Group left={left || margin.left} top={top || margin.top}>
        < LinePath
          key={'1'}
          data={data}
          x={d => xScale(getDate(d)) || 0}
          y={d => yScale(getDataValue(d)) || 0}
          curve={curveMonotoneX}
          stroke={color}
          // strokeOpacity={0.6}
          strokeLinecap="round"
          strokeWidth="2"
        />
        {!hideBottomAxis && (
          <AxisBottom
            top={yMax}
            scale={xScale}
            // numTicks={width > 520 ? 10 : 5}
            numTicks={10}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={() => axisBottomTickLabelProps}
          />
        )}
        {!hideLeftAxis && (
          <AxisLeft
            scale={yScale}
            numTicks={10}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={() => axisLeftTickLabelProps}
            label={dataUnit}
            labelProps={{
              fill: '#fff',
            }}
          />
        )}
        {children}
      </Group>
    );
  }

export default LineChartVx;