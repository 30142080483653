import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import styles from './OrganizationAdmin.module.scss';
import orgStyles from '../Organization.module.scss';
import Button from '../../UI/Button/Button';
import DeleteIcon from '../../../../assets/icons/Organization/delete.svg';
import PlusCircleIcon from '../../../../assets/icons/plus-circle.svg';
import OrganizationDeleteTooltip from '../OrganizationDeleteTooltip/OrganizationDeleteTooltip';

import { getAdmins, inviteAdmin, removeAdmin } from '../../../../state/ducks/Organization/actions';
import { REMOVE_ADMIN_SUCCESS } from '../../../../state/ducks/Organization/types';
import { getMembers } from '../../../../state/ducks/Members/actions';

class OrganizationAdmin extends Component {

  state = {
    memberOptions: [],
    newAdmin: null,
    deleteAdminTooltip: false,
    removingAdmin: null
  }

  componentDidMount() {
    this.props.getAdmins(this.props.editingOrg.id);
    this.props.getMembers(this.props.editingOrg.id);
  }

  componentDidUpdate(prevPorps, provState) {
    if (prevPorps.members !== this.props.members || prevPorps.admins !== this.props.admins) {
      this.setMemberOptions();
    }
    if (prevPorps.result !== this.props.result && this.props.result === 'success') {
      if ((prevPorps.addedAdmin !== this.props.addedAdmin) || this.props.type === REMOVE_ADMIN_SUCCESS) {
        this.onClearNewAdmin();
        this.props.getAdmins(this.props.editingOrg.id);
        this.props.getMembers(this.props.editingOrg.id);
      }
    }
  }

  setMemberOptions = () => {
    let memberOptions = [];
    this.props.members && this.props.members.forEach(member => {
      let isAdmin = this.props.admins && this.props.admins.find(admin => admin.id === member.id);
      if (!isAdmin && !member.is_superuser) {
        memberOptions.push({
          id: member.id,
          label: member.first_name + ' ' + member.last_name + ' (' + member.email + ')'
        });
      }
    });
    this.setState({ memberOptions });
  }

  onChangeAdminHandler = value => {
    this.setState({ newAdmin: value });
  }

  onInvitedAdmin = () => {
    this.props.inviteAdmin(this.props.editingOrg.id, this.state.newAdmin.id);
  }

  onClearNewAdmin = () => {
    this.setState({ newAdmin: null });
  }

  onOpenDeleteAdminTooltipHandler = (adminId) => {
    this.setState({ deleteAdminTooltip: true, removingAdmin: adminId });
  }

  onCloseDeleteAdminTooltipHandler = () => {
    this.setState({ deleteAdminTooltip: false, removingAdmin: null });
  }

  onConfirmDeleteAdmin = () => {
    this.props.removeAdmin(this.props.editingOrg.id, this.state.removingAdmin);
    this.setState({ deleteAdminTooltip: false, removingAdmin: null });
  }

  render() {
    let loggedInUserAdmin;
    if (this.props.loggedInUser && this.props.admins) {
      loggedInUserAdmin = this.props.admins.find(admin => admin.id === this.props.loggedInUser.id);
    }
    return (
      <>
        <div className={styles.AddAdminWrapper}>
          <div style={{ flex: 1 }}></div>
          <div className={styles.SearchAdmin}>
            <Select
              isSearchable
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              options={this.state.memberOptions}
              placeholder="Select an admin (Search by name/email/username)"
              value={this.state.newAdmin}
              onChange={this.onChangeAdminHandler}
              getOptionValue={opt => opt.id}
            />
          </div>
          <div className={styles.AddButton}>
            <Button type="button" icon={PlusCircleIcon} color="primary" name="Add Admin" click={this.onInvitedAdmin} noMargin disabled={!this.state.newAdmin} />
          </div>
        </div>
        <div style={{ overflow: "auto", height: "75vh" }}>
          <div className={styles.OrganizationAdmin}>
            {
              loggedInUserAdmin && <div key={loggedInUserAdmin.id} className={styles.AdminList}>
                <div className={styles.AdminImage}>
                  <img className={styles.Image} src={loggedInUserAdmin.profile_picture} alt="admin pic" />
                </div>
                <div className={styles.AdminDetail}>
                  <div className={styles.Name}>{loggedInUserAdmin.first_name} {loggedInUserAdmin.last_name}</div>
                  {loggedInUserAdmin.job_title ? <div className={styles.Detail}>{loggedInUserAdmin.job_title}</div> : null}
                </div>
                <div className={styles.AdminButton}>
                  {
                    this.state.removingAdmin && this.state.removingAdmin === loggedInUserAdmin.id &&
                    <OrganizationDeleteTooltip show={this.state.deleteAdminTooltip} modalClosed={this.onCloseDeleteAdminTooltipHandler} admin>
                      Are you sure you want to leave this admin?
                <div className={orgStyles.TooltipButtonsContainer}>
                        <div className={orgStyles.TooltipButtonWrapper}>
                          <Button type="button" color="white" name="No" click={this.onCloseDeleteAdminTooltipHandler} noMargin />
                        </div>
                        <div className={orgStyles.TooltipButtonWrapper}>
                          <Button type="button" color="red" name="Yes" click={this.onConfirmDeleteAdmin} noMargin />
                        </div>
                      </div>
                    </OrganizationDeleteTooltip>
                  }
                  {
                    this.props.admins && this.props.admins.length === 1 ? null :
                      <Button type="button" color="borderred" name="Leave admin" noMargin click={() => this.onOpenDeleteAdminTooltipHandler(loggedInUserAdmin.id)} />
                  }
                </div>
              </div>
            }
            {
              this.props.admins ? this.props.admins.map(admin => {
                if (this.props.loggedInUser && this.props.loggedInUser.id !== admin.id) {
                  return (
                    <div key={admin.id} className={styles.AdminList}>
                      <div className={styles.AdminImage}>
                        <img className={styles.Image} src={admin.profile_picture} alt="profile pic" />
                      </div>
                      <div className={styles.AdminDetail}>
                        <div className={styles.Name}>{admin.first_name} {admin.last_name}</div>
                        {admin.job_title ? <div className={styles.Detail}>{admin.job_title}</div> : null}
                      </div>
                      <div className={styles.AdminButton}>
                        {
                          this.state.removingAdmin && this.state.removingAdmin === admin.id &&
                          <OrganizationDeleteTooltip show={this.state.deleteAdminTooltip} modalClosed={this.onCloseDeleteAdminTooltipHandler} admin>
                            Are you sure you want to delete this admin?
                        <div className={orgStyles.TooltipButtonsContainer}>
                              <div className={orgStyles.TooltipButtonWrapper}>
                                <Button type="button" color="white" name="No" click={this.onCloseDeleteAdminTooltipHandler} noMargin />
                              </div>
                              <div className={orgStyles.TooltipButtonWrapper}>
                                <Button type="button" color="red" name="Yes" click={this.onConfirmDeleteAdmin} noMargin />
                              </div>
                            </div>
                          </OrganizationDeleteTooltip>
                        }
                        <Button type="button" color="white" icon={DeleteIcon} name="Remove" noMargin click={() => this.onOpenDeleteAdminTooltipHandler(admin.id)} />
                      </div>
                    </div>
                  );
                }
                return null;
              }) : null
            }
          </div>
        </div>
      </>
    );
  }
}

const Svg = p => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div style={{ color: 'rgb(204, 204, 204)', height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { admins, result, type, loading, addedAdmin } = state.org;
  const { members } = state.members;
  return { admins, result, members, type, loading, addedAdmin, loggedInUser };
};

export default connect(mapStateToProps, { getAdmins, getMembers, inviteAdmin, removeAdmin })(OrganizationAdmin);
