
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_TIMELINE_STATUSES, ADD_TIMELINE_REMARK, EDIT_TIMELINE_REMARK, GET_PARETO_DATA } from './types';

export function* watchTimelineStatus() {
  yield takeEvery(GET_TIMELINE_STATUSES, getTimelineStatusSaga);
  yield takeEvery(ADD_TIMELINE_REMARK, addTimelineRemarkSaga);
  yield takeEvery(EDIT_TIMELINE_REMARK, editTimelineRemarkSaga);
  yield takeEvery(GET_PARETO_DATA, getParetoDataSage);
}

export function* getTimelineStatusSaga(params) {
  yield put(actions.getTimelineStatusStart());

  let api = 'v1/status';
  if (params.timeline_id)
    api += '?timeline_id=' + params.timeline_id;
  else
    api += '?timeline_id=0';
  if (params.machine_id)
    api += '&machine_id=' + params.machine_id;
  if (params.start)
    api += '&start=' + params.start;
  if (params.stop)
    api += '&stop=' + params.stop;
  api += '&padding=' + (1 * 60 * 60);

  const properties = {
    timeline_id: params.timeline_id,
    machine_id: params.machine_id,
    ...{ ...params.properties }
  };

  try {
    const response = yield axios.get(api);
    // console.log("GET TIMELINE STATUS SUCCESS", response.data);
    yield put(actions.getTimelineStatusSuccess(response.data, properties));
  } catch (err) {
    console.log("GET TIMELINES STATUS FAILED", err)
    yield put(actions.getTimelineStatusFail(err.response));
  }
}

export function* addTimelineRemarkSaga(params) {
  yield put(actions.addTimelineRemarkStart());
  let postData = 'text=' + encodeURIComponent(params.text);
  postData += '&timeline_id=' + params.timeline_id;
  postData += '&start=' + params.start;
  postData += '&stop=' + params.stop;

  const api = 'v1/statusremark';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD TIMELINE STATUS SUCCESS", response.data);
    yield put(actions.addTimelineRemarkSuccess(response.data));
  } catch (err) {
    console.log("ADD TIMELINE STATUS FAILED", err.response.data);
    yield put(actions.addTimelineRemarkFail(err.response.data));
  }
}

export function* editTimelineRemarkSaga(params) {
  yield put(actions.editTimelineRemarkStart());
  let putData = 'text=' + encodeURIComponent(params.text)

  const api = 'v1/statusremark/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT TIMELINE STATUS SUCCESS", response.data);
    yield put(actions.editTimelineRemarkSuccess(response.data));
  } catch (err) {
    console.log("EDIT TIMELINE STATUS FAILED", err.response.data);
    yield put(actions.editTimelineRemarkFail(err.response.data));
  }
}


export function* getParetoDataSage(params) {
  yield put(actions.getParetoDataStart());

  let api = 'v1/status/pareto';
  api += '?machine_id=' + params.machine_id;
  api += '&start=' + params.start;
  api += '&stop=' + params.stop;
  if (params.padding) api += '&padding=' + params.padding; //default = 3600

  try {
    const response = yield axios.get(api);
    console.log("GET PARETO DATA SUCCESS", response);
    yield put(actions.getParetoDataSuccess(response.data, { machine_id: params.machine_id }));
  } catch (err) {
    console.log("GET PARETO DATA FAILED");
    yield put(actions.getParetoDataFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}