import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Topbar.module.scss';
import toggleStyles from './ToggleButton.module.scss';
import OwlLogo from '../../../../assets/icons/owl-logo/owl-logo.png';
import OwlLogo2x from '../../../../assets/icons/owl-logo/owl-logo@2x.png';
import OwlLogo3x from '../../../../assets/icons/owl-logo/owl-logo@3x.png';
import DropdownIcon from '../../../../assets/icons/Topbar/dropdown-arrow.svg';
import ArrowDownIcon from '../../../../assets/icons/Topbar/arrow-down.svg';
import Admin from '../../../../assets/images/admin.svg';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import LogoutIcon from '../../../../assets/icons/red-logout.svg';
import SettingsIcon from '../../../../assets/icons/navigation-items/settings.svg';
import OrganizationModal from '../../Organization/OrganizationModal/OrganizationModal';

import { getOrgs, setfilteredOrgBuffFalse } from '../../../../state/ducks/Organization/actions';
import { getLoggedInUser } from '../../../../state/ducks/Auth/actions';
import { signout } from '../../../../state/ducks/Auth/actions';
import { history } from '../../../../state/ducks/index';
import { DELETE_ORG_SUCCESS, REMOVE_ADMIN_SUCCESS } from '../../../../state/ducks/Organization/types';
import NotificationDropdown from '../../Notification/NotificationDropdown/NotificationDropdown';

class Topbar extends Component {
  state = {
    isOrganizationModalOpened: false,
    isDropdownOpened: false,
  }

  componentDidMount() {
    const { loggedInUser } = this.props;
    if (!loggedInUser) this.props.getLoggedInUser(this.props.loggedInUser.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.result !== prevProps.result && this.props.result === 'success') {
      if (prevProps.addedOrg !== this.props.addedOrg || [DELETE_ORG_SUCCESS, REMOVE_ADMIN_SUCCESS].includes(this.props.type)) {
        this.props.getLoggedInUser(this.props.loggedInUser.id);
      }
      this.props.getOrgs();
    }
    if (this.props.loggedInUser !== prevProps.loggedInUser && !this.props.loggedInUser.teams && !this.props.loggedInUser.is_superuser) {
      history.push('/create-organization');
    }
    if (this.props.orgs !== prevProps.orgs && !this.props.orgs) {
      history.push('/create-organization');
    }
  }

  goToProfile = () => {
    history.push('/profile');
    this.setState({ isDropdownOpened: false });
  }

  goToChat = () => {
    history.push('/chat');
  }

  onOrganizationModalHandler = (event, id) => {
    event.stopPropagation();
    this.setState({ isOrganizationModalOpened: true });
  }

  organizationModalClickHandler = () => {
    this.props.setfilteredOrgBuffFalse();
    this.setState(prevState => {
      return { isOrganizationModalOpened: !prevState.isOrganizationModalOpened }
    });
  }

  dropdownMenuClickHandler = () => {
    this.setState(prevState => {
      return { isDropdownOpened: !prevState.isDropdownOpened }
    });
  }

  dropdownMenuClose = () => {
    this.setState({ isDropdownOpened: false });
  }

  onLogoClick = (event) => {
    history.push('/pages');
  }

  render() {
    let foundedOrg = null;
    if (this.props.orgs && this.props.orgs.length > 0 && this.props.currentOrg) {
      this.props.orgs.forEach(org => {
        if (org.id === parseInt(this.props.currentOrg))
          foundedOrg = org;
      });
    }

    let dropdownClasses = [styles.Dropdown];
    if (this.state.isDropdownOpened) {
      dropdownClasses.push(styles.DropdownIn);
    } else {
      dropdownClasses.push(styles.DropdownOut);
    }

    let isMobile = this.props.isMobile;

    let rightMenu = <>
      <div className={styles.RightItems}>
        {/* <div className={styles.RightIcon}>
          <img src={LangIcon} alt="Language" loading="lazy" />
        </div> */}
        {
          this.props.loggedInUser.is_superuser
            ? <div className={styles.RightItems} style={{ display: 'flex', alignItems: 'center', gap: '5px' }} onClick={this.props.onToggleAdminMode}>
              <div className={toggleStyles.toggleText}>User</div>
              <div className={this.props.isAdminMode ? [toggleStyles.toggle, toggleStyles.toggleOn].join(' ') : toggleStyles.toggle} id='switch'>
                <div className={toggleStyles.toggleButton}></div>
              </div>
              <div className={toggleStyles.toggleText}>Admin</div>
            </div>
            : null
        }

        <NotificationDropdown isMobile={isMobile} offsetHeight={this.props.offsetHeight} />

        <div style={{ display: "flex" }} className={styles.ProfileMenu} onClick={this.dropdownMenuClickHandler}>
          <div className={styles.RightIcon}>
            <img className={styles.ProfilePicture} src={this.props.loggedInUser && this.props.loggedInUser.profile_picture ? this.props.loggedInUser.profile_picture : Admin} alt="profile pic" />
          </div>
          <img src={DropdownIcon} alt="Drop down" loading="lazy" style={{ width: 7 }} />
        </div>
        {this.state.isDropdownOpened ? <div className={styles.DropdownContainer} onClick={this.dropdownMenuClose} ></div> : null}
        <div className={dropdownClasses.join(' ')}>
          <ul>
            <li>
              <div style={{ display: "flex" }} onClick={this.goToProfile}>
                <div className={styles.ListItem}> <img alt="account" src={EditIcon} /></div>
                <div className={styles.ListItem}>Manage Account</div>
              </div>
            </li>
            <li>
              <div style={{ display: "flex" }} onClick={this.props.signout}>
                <div className={styles.ListItem}> <img alt="logout" src={LogoutIcon} /></div>
                <div className={styles.ListItem} ><span className={styles.Logout}>Log Out</span></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>;


    return (
      <div className={styles.Topbar} style={{ top: this.props.offsetHeight ? this.props.offsetHeight : 0 }}>
        <div className={styles.LeftItems} style={isMobile ? { marginLeft: "20px" } : {}}>
          {this.props.isAdminMode ? <img src={OwlLogo} style={{ height: '100%', cursor: 'pointer' }} srcSet={`${OwlLogo2x} 2x, ${OwlLogo3x} 3x`} alt="OWL Logo" onClick={this.onLogoClick} /> : <>
            {
              foundedOrg
                ? foundedOrg.logo
                  ? <img src={foundedOrg.logo} style={{ height: '100%', cursor: 'pointer' }} alt="Org Logo" onClick={this.onLogoClick} />
                  : <img src={OwlLogo} style={{ height: '100%', cursor: 'pointer' }} srcSet={`${OwlLogo2x} 2x, ${OwlLogo3x} 3x`} alt="OWL Logo" onClick={this.onLogoClick} />
                : null
            }
            <div className={styles.Divider} />
            <div className={styles.Orgs} onClick={this.organizationModalClickHandler} style={isMobile ? { marginRight: "10px" } : {}}>
              {foundedOrg ? <><p>{foundedOrg.name}</p> <img className={styles.ArrowDown} src={ArrowDownIcon} alt="Open orgs" loading="lazy" /></> : null}
            </div></>}
        </div>
        {isMobile ? null : rightMenu}
        <OrganizationModal open={this.state.isOrganizationModalOpened} modalClosed={this.organizationModalClickHandler} signout={this.props.signout} isMobile={isMobile} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser, loading } = state.auth;
  const { type, orgs, currentOrg, result, addedOrg, updatedOrg, buffOrgIdIndex } = state.org;
  return { loggedInUser, type, orgs, currentOrg, result, addedOrg, updatedOrg, loading, buffOrgIdIndex };
};

export default connect(mapStateToProps, { getOrgs, getLoggedInUser, signout, setfilteredOrgBuffFalse })(Topbar);