import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_CHATROOMS, GET_CHATROOM, ADD_CHATROOM } from './types';

export function* watchChatroom() {
  yield takeEvery(GET_CHATROOMS, getChatroomsSaga);
  yield takeEvery(GET_CHATROOM, getChatroomSaga);
  yield takeEvery(ADD_CHATROOM, addChatroomSaga);
}

export function* getChatroomsSaga(params) {
  yield put(actions.getChatroomsStart());

  let api = 'v1/chatroom';
  api += '?org_id=' + params.org_id;
  api += params.user_id ? '&user_id' + params.user_id : '';

  try {
    const response = yield axios.get(api);
    console.log("GET CHATROOMS SUCCESS", response.data);
    yield put(actions.getChatroomsSuccess(response.data));
  } catch (err) {
    console.log("GET CHATROOMS FAILED", err)
    yield put(actions.getChatroomsFail(err.response));
  }
}

export function* getChatroomSaga(params) {
  yield put(actions.getChatroomStart());

  let api = 'v1/chatroom/' + params.id;

  try {
    const response = yield axios.get(api);
    console.log("GET CHATROOM SUCCESS", response.data);
    yield put(actions.getChatroomSuccess(response.data));
  } catch (err) {
    console.log("GET CHATROOM FAILED", err)
    yield put(actions.getChatroomFail(err.response));
  }
}

export function* addChatroomSaga(params) {
  yield put(actions.addChatroomStart());

  let postData = "title=" + encodeURIComponent(params.title);
  postData += "&org_id=" + params.org_id;
  postData += "&member_ids=" + params.member_ids;
  postData += params.logo ? '&logo=' + encodeURIComponent(params.logo) : '';

  const api = 'v1/chatroom';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD CHATROOM SUCCESS", response);
    yield put(actions.addChatroomSuccess(response.data));
  } catch (err) {
    console.log("ADD CHATROOM FAILED", err.response.data);
    yield put(actions.addChatroomFail(err.response.data.error));
  }
}
