import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Members.module.scss';
import Button from '../../components/UI/Button/Button';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import SearchIcon from '../../../assets/icons/search.svg';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import MemberDrawer from '../../components/Members/MemberDrawer/MemberDrawer';
import RemoveMemberModal from '../../components/Members/RemoveMemberModal/RemoveMemberModal';
import MemberTable from '../../components/Members/MemberTable/MemberTable';
import Select from '../../components/UI/Select/Select';
import LoadingWideCard from '../../components/UI/LoadingSkeleton/LoadingWideCard/LoadingWideCard';

import { DELETE_MEMBER_SUCCESS } from '../../../state/ducks/Members/types';
import { getMembers, deleteMember } from '../../../state/ducks/Members/actions';
import { REMOVE_MEMBER_SUCCESS, INVITE_MEMBER_SUCCESS } from '../../../state/ducks/Organization/types';
import { removeMember } from '../../../state/ducks/Organization/actions';

const sort_choices = [
  { id: 1, name: 'A - Z' },
  { id: 2, name: 'Z - A' },
  { id: 3, name: 'Oldest' },
  { id: 4, name: 'Newest' },
];

class Members extends Component {
  state = {
    isDrawerOpened: false,
    isAdding: true,
    // editingMember: null,
    // isDeleteModalOpened: false,
    // deletingMemberId: null,
    searchResult: null,
    isRemoveModalOpened: false,
    removingMember: null,
    sorting: '-1',
    newAdmin: null
  }

  componentDidMount() {
    this.props.getMembers(this.props.currentOrg);
    if (!this.state.searchResult) {
      this.onSetSearchResult();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.props.getMembers(this.props.currentOrg);
    } else {
      if (this.props.memberResult !== prevProps.memberResult && this.props.memberResult === 'success') {
        if ((prevProps.addedMember !== this.props.addedMember) || (prevProps.updatedMember !== this.props.updatedMember) || (this.props.memberType === DELETE_MEMBER_SUCCESS)) {
          this.props.getMembers(this.props.currentOrg);
        } else {
          // TODO: need to get deleted page index
          // case: delete
          // this.props.tags.splice(this.state.deletingIndex, 1);
        }
      }

      if (this.props.orgResult !== prevProps.orgResult && this.props.orgResult === 'success') {
        if (this.props.orgType === REMOVE_MEMBER_SUCCESS || this.props.orgType === INVITE_MEMBER_SUCCESS) {
          this.props.getMembers(this.props.currentOrg);
        }
      }

      if (prevProps.members !== this.props.members) {
        this.setState({ searchResult: this.props.members });
      }

    }
  }

  drawerToggleHandler = () => {
    this.setState(prevState => {
      return { isDrawerOpened: !prevState.isDrawerOpened }
    });
  }

  openDrawerForAddingHandler = () => {
    this.setState({ isAdding: true, editingMember: null, isDrawerOpened: true });
  }

  onSetSearchResult = () => {
    this.setState({ searchResult: this.props.members });
  }

  // onEditMemberHandler = (event, index) => {
  //   event.stopPropagation();
  //   this.setState({ isAdding: false, editingMember: this.props.members[index], isDrawerOpened: true });
  // }

  // onDeleteMemberHandler = (event, id) => {
  //   event.stopPropagation();
  //   this.setState({ deletingMemberId: id, isDeleteModalOpened: true });
  // }

  // deleteModalClickHandler = () => {
  //   this.setState(prevState => {
  //     return { isDeleteModalOpened: !prevState.isDeleteModalOpened }
  //   });
  // }

  // onConfirmDeleteMember = (event) => {
  //   event.preventDefault();
  //   this.props.deleteMember(this.state.deletingMemberId);
  //   this.setState({ deletingMemberId: null, isDeleteModalOpened: false });
  // }

  onRemoveMemberHandler = (event, email) => {
    event.stopPropagation();
    this.setState({ removingMember: email, isRemoveModalOpened: true, newAdmin: null });
  }

  removeModalClickHandler = () => {
    this.setState(prevState => {
      return { isRemoveModalOpened: !prevState.isRemoveModalOpened }
    });
  }

  onConfirmRemoveMember = (event) => {
    event.preventDefault();
    // TODO: check their teams/pages for changing the owner
    this.props.removeMember(this.props.currentOrg, this.state.newAdmin.id, this.state.removingMember.toLowerCase());
    this.setState({ removingMember: null, isRemoveModalOpened: false });
  }

  onSearchMemberChange = (event) => {
    const search = event.target.value.toLowerCase();
    const foundMembers = this.props.members.filter(member => {
      if (member.first_name.toLowerCase().includes(search) || member.last_name.toLowerCase().includes(search)
        || member.email.toLowerCase().includes(search) || member.username.toLowerCase().includes(search)) {
        return member;
      }
      return null;
    });
    this.setState({ searchResult: foundMembers });
  }

  handleSortChange = (event) => {
    this.setState({ sorting: +event.target.value });
  }

  onChangeAdminHandler = value => {
    this.setState({ newAdmin: value });
  }

  render() {
    return (
      <div className={styles.Members}>
        <SideDrawer title={this.state.isAdding ? 'Add/Invite Member' : 'Edit Member'} open={this.state.isDrawerOpened} closed={this.drawerToggleHandler}>
          <MemberDrawer closed={this.drawerToggleHandler} editingMember={this.state.editingMember} isAdding={this.state.isAdding} />
        </SideDrawer>
        <div className={styles.TopSection}>
          {/* TODO: change to <ul><li> when have more than 1 menu */}
          <div className={styles.MenuWrapper}>
            <div className={styles.Menu}><span className={styles.MenuTitle}>Members</span></div>
          </div>
          <Select
            name="sort"
            placeholder="Sorting"
            options={sort_choices}
            value={this.state.sorting}
            onChange={this.handleSortChange}
            overidestyleformgroup={{ marginBottom: 0, margin: '10px 30px', width: 100 }}
          />
          <div className={styles.AddButtonWrapper}>
            <Button type="button" icon={PlusCircleIcon} color="primary" name="Add/Invite Member" noMargin click={this.openDrawerForAddingHandler} />
          </div>
        </div>

        <div className="MainContentPadding">
          <div className={styles.MembersTable}>
            <div className={`${styles.MemberCard} ${styles.SearchWrapper}`}>
              <img style={{ marginRight: 20, verticalAlign: 'middle' }} src={SearchIcon} alt="Search members" />
              <input className={styles.SearchInput} type="text" placeholder="Search Members" onChange={this.onSearchMemberChange} />
            </div>
          </div>

          {
            this.props.loading ?
              <div className={styles.LoadingWideCard}><LoadingWideCard /></div>
              :
              <div className={styles.MembersTable}>
                <MemberTable
                  membersForShowing={this.state.searchResult}
                  allMembers={this.props.members}
                  sorting={this.state.sorting}
                  // editMemberHandler={this.onEditMemberHandler}
                  // deleteMemberHandler={this.onDeleteMemberHandler}
                  removeMemberHandler={this.onRemoveMemberHandler}
                />
              </div>
          }

        </div>
        {
          this.props.members &&
          <RemoveMemberModal
            open={this.state.isRemoveModalOpened}
            modalClosed={this.removeModalClickHandler}
            clickRemoveHandler={this.onConfirmRemoveMember}
            members={this.props.members}
            removingMember={this.state.removingMember}
            newAdmin={this.state.newAdmin}
            onChangeAdminHandler={this.onChangeAdminHandler} />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { members, addedMember, updatedMember, loading } = state.members;
  const { loggedInUser } = state.auth;
  const { currentOrg } = state.org;
  return {
    memberType: state.members.type,
    memberResult: state.members.result,
    members, addedMember, updatedMember, loading,
    orgType: state.org.type,
    orgResult: state.org.result,
    currentOrg,
    loggedInUser
  };
};

export default connect(mapStateToProps, { getMembers, deleteMember, removeMember })(Members);
