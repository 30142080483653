import * as actionTypes from './types';

// ----------------------- SIGN IN -----------------------
export const signinStart = () => {
  return {
    type: actionTypes.SIGNIN_START
  };
}

export const signin = (username, password) => {
  return {
    type: actionTypes.SIGNIN,
    username,
    password
  };
}

export const signinSuccess = (response) => {
  return {
    type: actionTypes.SIGNIN_SUCCESS,
    user: response.user
  };
}

export const signinFail = (response) => {
  return {
    type: actionTypes.SIGNIN_FAIL,
    error: response.error
  };
}

// ----------------------- SIGN UP -----------------------
export const signupStart = () => {
  return {
    type: actionTypes.SIGNUP_START
  };
}

export const signup = (email, username, password, first_name, last_name, profile_pic) => {
  return {
    type: actionTypes.SIGNUP,
    email,
    username,
    password,
    first_name,
    last_name,
    profile_pic,
  };
}

export const signupSuccess = () => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
  };
}

export const signupFail = (response) => {
  return {
    type: actionTypes.SIGNUP_FAIL,
    error: response.error,
  };
}

// ----------------------- SIGN OUT -----------------------

export const signout = () => {
  return {
    type: actionTypes.SIGNOUT
  };
}

export const signoutSuccess = () => {
  return {
    type: actionTypes.SIGNOUT_SUCCESS
  };
}

// ----------------------- RENEW TOKEN -----------------------

export const renewToken = () => {
  return {
    type: actionTypes.RENEW_TOKEN
  };
}

export const renewTokenSuccess = (response) => {
  return {
    type: actionTypes.RENEW_TOKEN_SUCCESS,
    token: response.token
  };
}

// ----------------------- VERIFIED USER -----------------------

export const verifiedUserStart = () => {
  return {
    type: actionTypes.VERIFIED_USER_START,
  };
}

export const verifiedUser = (token) => {
  return {
    type: actionTypes.VERIFIED_USER,
    token,
  };
}

export const verifiedUserSuccess = (response) => {
  return {
    type: actionTypes.VERIFIED_USER_SUCCESS,
    result: response.result
  };
}

export const verifiedUserFail = (response) => {
  return {
    type: actionTypes.VERIFIED_USER_FAIL,
    result: response.result
  };
}

// ----------------------- RESEND VERIFY -----------------------

export const resendVerifyStart = () => {
  return {
    type: actionTypes.RESEND_VERIFY_START,
  };
}

export const resendVerify = (email) => {
  return {
    type: actionTypes.RESEND_VERIFY,
    email,
  };
}

export const resendVerifySuccess = () => {
  return {
    type: actionTypes.RESEND_VERIFY_SUCCESS,
  };
}

export const resendVerifyFail = (response) => {
  return {
    type: actionTypes.RESEND_VERIFY_FAIL,
    error: response.error
  };
}

// ----------------------- FORGET PASS -----------------------

export const forgetPasswordStart = () => {
  return {
    type: actionTypes.FORGET_PASSWORD_START,
  };
}

export const forgetPassword = (email) => {
  return {
    type: actionTypes.FORGET_PASSWORD,
    email,
  };
}

export const forgetPasswordSuccess = (response) => {
  return {
    type: actionTypes.FORGET_PASSWORD_SUCCESS,
    result: response.result,
  };
}

export const forgetPasswordFail = (response) => {
  return {
    type: actionTypes.FORGET_PASSWORD_FAIL,
    error: response.error
  };
}

// ----------------------- RESET PASS -----------------------

export const resetPasswordStart = () => {
  return {
    type: actionTypes.RESET_PASSWORD_START,
  };
}

export const resetPassword = (token, newpassword) => {
  return {
    type: actionTypes.RESET_PASSWORD,
    token,
    newpassword,
  };
}

export const resetPasswordSuccess = () => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
  };
}

export const resetPasswordFail = (response) => {
  return {
    type: actionTypes.RESET_PASSWORD_FAIL,
    error: response.error
  };
}

// ----------------------- ONLY CASE: After create a new org (not the first one) -----------------------

export const getLoggedInUserStart = () => {
  return {
    type: actionTypes.GET_LOGGEDIN_USER_START,
  };
}

export const getLoggedInUser = (id) => {
  return {
    type: actionTypes.GET_LOGGEDIN_USER,
    id
  };
}

export const getLoggedInUserSuccess = (response) => {
  return {
    type: actionTypes.GET_LOGGEDIN_USER_SUCCESS,
    user: response.user
  };
}

export const getLoggedInUserFail = (response) => {
  return {
    type: actionTypes.GET_LOGGEDIN_USER_FAIL,
    error: response.error
  };
}

// ----------------------- EDIT USER -----------------------

export const editUserStart = (editType) => {
  return {
    type: actionTypes.EDIT_USER_START,
    editType: editType
  };
}

export const editUser = (id, edit_type, first_name, last_name, tel, job_title, profile_pic, username, password, newpassword) => {
  return {
    type: actionTypes.EDIT_USER,
    id,
    edit_type,
    first_name,
    last_name,
    tel,
    job_title,
    profile_pic,
    username,
    password,
    newpassword
  };
}

export const editUserSuccess = (response) => {
  return {
    type: actionTypes.EDIT_USER_SUCCESS,
    updatedUser: response.user,
    result: response.result
  };
}

export const editUserFail = (response) => {
  return {
    type: actionTypes.EDIT_USER_FAIL,
    error: response.error,
    result: response.result
  };
}

// export default {
//   signinStart,
//   signin,
//   signinSuccess,
//   signinFail,
//   signupStart,
//   signup,
//   signupSuccess,
//   signupFail,
//   signout,
//   signoutSuccess,
//   renewToken,
//   renewTokenSuccess,
//   verifiedUser,
//   verifiedUserSuccess,
//   verifiedUserFail,
//   resendVerifyStart,
//   resendVerify,
//   resendVerifySuccess,
//   resendVerifyFail,
//   forgetPasswordStart,
//   forgetPassword,
//   forgetPasswordSuccess,
//   forgetPasswordFail,
//   resetPasswordStart,
//   resetPassword,
//   resetPasswordSuccess,
//   resetPasswordFail,
//   getLoggedInUserStart,
//   getLoggedInUser,
//   getLoggedInUserSuccess,
//   getLoggedInUserFail,
//   editUserStart,
//   editUser,
//   editUserSuccess,
//   editUserFail
// };