import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from '../Auth.module.scss';
import SigninImage from '../../../../assets/images/signin.png';
import CeoImage from '../../../../assets/images/ceo.png';
import WorldIcon from '../../../../assets/icons/world.svg';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

import PrivacyModal from '../../UI/Privacy/PrivacyModal';

import { checkValidity, updateObject } from '../../../../state/utils';
import { signin } from '../../../../state/ducks/Auth/actions';

class SignIn extends Component {
  state = {
    isPrivacyModalOpened: false,
    formIsValid: false,
    signInForm: {
      username: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true
        }
      },
      password: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true
        }
      }
    }
  }

  handleChange = event => {
    let updatedElement = updateObject(this.state.signInForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.signInForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.signInForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ signInForm: updatedForm, formIsValid });
  };

  privacyModalClickHandler = () => {
    this.setState(prevState => {
      return { isPrivacyModalOpened: !prevState.isPrivacyModalOpened };
    });
  }

  onSignInHandler = (event) => {
    event.preventDefault();
    if (this.state.formIsValid) {
      this.privacyModalClickHandler();
    }
    else {
      this.onSetTouchedFields();
    }
  }

  acceptPrivacyHandler = (e, answer) => {
    e.preventDefault();
    this.privacyModalClickHandler();
    if (answer) {
      this.props.signin(this.state.signInForm.username.value, this.state.signInForm.password.value);
    }
  }

  onSetTouchedFields = () => {
    let newState = { ...this.state.signInForm };
    Object.keys(this.state.signInForm).forEach(name => {
      let updatedElement = updateObject(newState[name], {
        touched: true
      });
      const updatedForm = updateObject(newState, {
        [name]: updatedElement
      });
      newState = updatedForm;
      this.setState({ signInForm: updatedForm });
    });
  }

  render() {
    const { username, password } = this.state.signInForm;

    let signInForm = (
      <React.Fragment>
        <div style={{ flex: '0 1 90px' }}>
          <div className={styles.AuthTitle}>Hi There! </div>
          <div className={styles.AuthSubTitle}>We’re happy to have you here again!</div>
        </div>
        <form onSubmit={this.onSignInHandler} className={styles.FormContainer}>
          <div style={{ flex: 1 }}>
            {
              this.props.error === 'Username or password incorrect' ?
                <div className={styles.ErrorMessage}>Please check your username or password! </div>
                : this.props.error === 'Please verify user' ?
                  <div className={styles.ErrorMessage}>Please verify your email!</div>
                  : this.props.error === 'Network Error' ?
                    <div className={styles.ErrorMessage}>Network Error</div>
                    : ''
            }
            <div className={styles.FormGroup}>
              <Input
                label="username"
                name="username"
                type="text"
                value={username.value}
                onChange={this.handleChange}
                error={`${username.touched && !username.valid ? 'Username is required' : ''}`}
                whitemode="true"
              />
            </div>
            <div className={styles.FormGroup}>
              <Input
                label="password"
                name="password"
                type="password"
                value={password.value}
                onChange={this.handleChange}
                error={`${password.touched && !password.valid ? 'Password is required' : ''}`}
                whitemode="true"
              />
            </div>
            <div className={styles.GewNewpassword} onClick={this.props.goToForgetPassword} style={{ fontSize: 12 }}>Forget your password buddy?</div>
          </div>

          <Button
            type="submit"
            color="blue"
            name={this.props.loading ? 'Loading...' : 'Go to Dashboard'}
            loading={this.props.loading}
            noMargin
          />
        </form>
      </React.Fragment >
    );

    let isMobile = this.props.isMobile;

    return (
      <div className={styles.Auth}>
        <PrivacyModal
          open={this.state.isPrivacyModalOpened}
          modalClosed={this.privacyModalClickHandler}
          acceptPrivacy={this.acceptPrivacyHandler}
        />
        <div className={styles.LeftSection}>
          <div className={styles.AuthWrapper}>
            {signInForm}
          </div>
        </div>
        {isMobile ? null :
          <div className={styles.RightSection} style={{ backgroundImage: `url(${SigninImage})` }}>
            <div className={styles.Testimonial}>
              <div>
                <img src={WorldIcon} alt="Technimal.co.th" className={styles.WorldIcon} />
                <b>Technimal.co.th</b>
              </div>
              <p style={{ maxWidth: 250 }}>Delivering our technology to help you achieve business goal</p>
              <div>
                <img src={CeoImage} alt="CEO of Technimal" className={styles.CeoImage} />
                <span style={{ fontSize: 14 }}><b>CEO</b> Thanyathat Angsuphisit</span>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error } = state.auth;
  return { loading, error };
};

export default connect(mapStateToProps, { signin })(SignIn);