import React, { Component } from "react";
import Modal from "../../../components/UI/Modal/Modal";
import instStyles from "./InstructionModal.module.scss";
import Step1 from "../../../../assets/images/LineInstruction/step-1.png";
import Step2 from "../../../../assets/images/LineInstruction/step-2@3x.png";
import Step3 from "../../../../assets/images/LineInstruction/step-3@3x.png";
import Step4 from "../../../../assets/images/LineInstruction/step-4@3x.png";
import Step5 from "../../../../assets/images/LineInstruction/step-5@3x.png";
import Step6 from "../../../../assets/images/LineInstruction/step-6@3x.png";

export default class InstructionModal extends Component {
  render() {
    let ovStyle = { width: 1000, maxHeight: "90%", overflow: "auto" };
    return (
      <Modal
        show={this.props.open}
        modalClosed={this.props.modalClosed}
        overideStyles={ovStyle}
      >
        {
          <div className={instStyles.OrganizationModal}>
            <div className={instStyles.HeaderWrapper}>
              <div className={instStyles.Header}>
                How to register line notification
              </div>
            </div>
            <div className={instStyles.InstructionWrapper}>
              <div className={instStyles.InstructionCard}>
                <img className={instStyles.Image} src={Step1} alt="" />
                <div className={instStyles.CardBody}>
                  <div className={instStyles.CardStep}>
                    <div className={instStyles.CardSubTitle}>Step</div>
                    <div className={instStyles.CardTitle}>01</div>
                  </div>
                  <div className={instStyles.Divider}></div>
                  <div className={instStyles.CardDescribeBody}>
                    <div className={instStyles.CardDescribe}>
                      Open web browser to url
                    </div>
                    <div className={instStyles.CardLink}>
                      <a href="https://notify-bot.line.me/th/" target="_blank" rel="noreferrer">
                        https://notify-bot.line.me/th/
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={instStyles.InstructionCard}>
                <img className={instStyles.Image} src={Step2} alt="" />
                <div className={instStyles.CardBody}>
                  <div className={instStyles.CardStep}>
                    <div className={instStyles.CardSubTitle}>Step</div>
                    <div className={instStyles.CardTitle}>02</div>
                  </div>
                  <div className={instStyles.Divider}></div>
                  <div className={instStyles.CardDescribeBody}>
                    <div className={instStyles.CardDescribe}>
                      Login with line id
                    </div>
                  </div>
                </div>
              </div>
              <div className={instStyles.InstructionCard}>
                <img className={instStyles.Image} src={Step3} alt="" />
                <div className={instStyles.CardBody}>
                  <div className={instStyles.CardStep}>
                    <div className={instStyles.CardSubTitle}>Step</div>
                    <div className={instStyles.CardTitle}>03</div>
                  </div>
                  <div className={instStyles.Divider}></div>
                  <div className={instStyles.CardDescribeBody}>
                    <div className={instStyles.CardDescribe}>
                      Open "My page"
                    </div>
                  </div>
                </div>
              </div>
              <div className={instStyles.InstructionCard}>
                <img className={instStyles.Image} src={Step4} alt="" />
                <div className={instStyles.CardBody}>
                  <div className={instStyles.CardStep}>
                    <div className={instStyles.CardSubTitle}>Step</div>
                    <div className={instStyles.CardTitle}>04</div>
                  </div>
                  <div className={instStyles.Divider}></div>
                  <div className={instStyles.CardDescribeBody}>
                    <div className={instStyles.CardDescribe}>
                      Click "Generate token"
                    </div>
                  </div>
                </div>
              </div>
              <div className={instStyles.InstructionCard}>
                <img className={instStyles.Image} src={Step5} alt="" />
                <div className={instStyles.CardBody}>
                  <div className={instStyles.CardStep}>
                    <div className={instStyles.CardSubTitle}>Step</div>
                    <div className={instStyles.CardTitle}>05</div>
                  </div>
                  <div className={instStyles.Divider}></div>
                  <div className={instStyles.CardDescribeBody}>
                    <div className={instStyles.CardDescribe}>
                      Insert token name and select group chat 
                    </div>
                    <div className={instStyles.CardDescribe}>
                      that you want to send notification
                    </div>
                  </div>
                </div>
              </div>
              <div className={instStyles.InstructionCard}>
                <img className={instStyles.Image} src={Step6} alt="" />
                <div className={instStyles.CardBody}>
                  <div className={instStyles.CardStep}>
                    <div className={instStyles.CardSubTitle}>Step</div>
                    <div className={instStyles.CardTitle}>06</div>
                  </div>
                  <div className={instStyles.Divider}></div>
                  <div className={instStyles.CardDescribeBody}>
                    <div className={instStyles.CardDescribe}>
                      Copy token and
                    </div>
                    <div className={instStyles.CardDescribe}>
                      set in rule config
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        }
      </Modal>
    );
  }
}
