import React from 'react';

import styles from './LoadingTable.module.scss';

const LoadingTable = () => {
  return (
    <React.Fragment>
      <div className={styles.LoadingTable}></div>
      <div className={styles.LoadingTable}></div>
    </React.Fragment>
  );
}

export default LoadingTable;
