import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getSequentialCommand, getSequentialCommands, startSequentialCommand, stopSequentialCommand } from '../../../../../../state/ducks/SequentialCommand/actions';
import PlayIcon from '../../../../../../assets/icons/Widgets/SwitchSequence/play.svg';
import CantPlayIcon from '../../../../../../assets/icons/Widgets/SwitchSequence/cantplay.svg';
import StopIcon from '../../../../../../assets/icons/Widgets/SwitchSequence/stop.svg';
import BusyIcon from '../../../../../../assets/icons/Widgets/SwitchSequence/busy.svg';

import { START_SEQUENTIAL_COMMAND_SUCCESS, STOP_SEQUENTIAL_COMMAND_SUCCESS } from '../../../../../../state/ducks/SequentialCommand/types';

import styles from './SwitchSequence.module.scss';

const DEFAULT_SERV_UNSET_TIMESTAMP = "0001-01-01T00:00:00Z";
const START_COMMANDS_MESSAGE = 'Press to start sequential commands';
const DEAULT_UNAVAILABLE_CAUSES = { offline: false, busy: false };


class SwitchSequence extends Component {
  state = {
    doneFirstChecking: false,
    playing: false,
    explainedText: START_COMMANDS_MESSAGE,
    runningCommand: 0,
    unavailableBecause: DEAULT_UNAVAILABLE_CAUSES
  }

  componentDidMount() {
    if (this.props.widget) {
      const { sensor: { secret, socket } } = this.props.widget.switchProps.sequenceList;
      if (this.isDeviceOnline()) {
        const unavailableBecause = DEAULT_UNAVAILABLE_CAUSES;
        this.setState({ unavailableBecause });
        this.props.getSequentialCommands(secret, socket, this.props.widget.id);
      } else {
        const unavailableBecause = { offline: true, busy: false };
        this.setState({ unavailableBecause });
      }
    }
  }

  isDeviceOnline = () => {
    const { sensor: { secret } } = this.props.widget.switchProps.sequenceList;
    return this.props.devices.find(device => device.secret === secret).status === 'on';
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.result === 'success' && this.props.result !== prevProps.result) {
    if (this.props.commands && prevProps.commands !== this.props.commands && this.props.commands.wid === this.props.widget.id) {
      if (this.props.commands.sequentialCommands.length === 0) {
        // first created
        this.setState({ doneFirstChecking: true, playing: false });
      } else {
        let playing = this.state.playing;
        let explainedText = this.state.explainedText;
        let unavailableBecause = { ...this.state.unavailableBecause };
        if (!this.props.commands.sequentialCommands[0].is_finished && this.props.commands.sequentialCommands[0].finished_date === DEFAULT_SERV_UNSET_TIMESTAMP) {
          const { sensor: { secret, socket } } = this.props.widget.switchProps.sequenceList;
          const ref = JSON.parse(this.props.commands.sequentialCommands[0].ref);
          if (ref.secret === secret && +ref.socket === socket) {
            if (+ref.wid === this.props.widget.id) {
              const resumeId = this.props.commands.sequentialCommands[0].id;
              playing = true;
              unavailableBecause = { offline: !this.isDeviceOnline(), busy: false };
              const whenNow = this.props.commands.sequentialCommands[0].values.findIndex(val => !val.is_finished);
              explainedText = whenNow + ' / ' + this.props.commands.sequentialCommands[0].values.length + ' Commands';
              this.checkingSequenceInterval = setInterval(() => {
                this.props.getSequentialCommand(resumeId);
              }, 1000);
              this.setState({ playing });
            } else {
              unavailableBecause.busy = true;
            }
          }
        }

        this.setState({ doneFirstChecking: true, playing, explainedText, unavailableBecause });
      }
    }
    if (this.props.sequentialCommand !== prevProps.sequentialCommand && this.props.sequentialCommand && this.props.type === START_SEQUENTIAL_COMMAND_SUCCESS) {
      const { sensor: { secret, socket } } = this.props.widget.switchProps.sequenceList;
      const ref = JSON.parse(this.props.sequentialCommand.ref);
      if (ref.secret === secret && +ref.socket === socket) {
        if (+ref.wid === this.props.widget.id) {
          this.checkingSequenceInterval = setInterval(() => {
            this.props.getSequentialCommand(this.props.sequentialCommand.id);
          }, 1000);
        } else {
          const unavailableBecause = { offline: !this.isDeviceOnline(), busy: true };
          this.setState({ unavailableBecause });
        }
      }
    }
    if (this.props.type === STOP_SEQUENTIAL_COMMAND_SUCCESS && this.props.result === 'success' && this.props.result !== prevProps.result) {
      this.setState({ playing: false, explainedText: START_COMMANDS_MESSAGE, runningCommand: 0 });
    }
    // } else 

    if (this.props.error && this.props.error !== prevProps.error) {
      this.setState({ playing: false, explainedText: this.props.error, unavailableBecause: { offline: !this.isDeviceOnline(), busy: true } });
    }

    if (this.props.selectedSequentialCommand && prevProps.selectedSequentialCommand !== this.props.selectedSequentialCommand) {
      const values = this.props.selectedSequentialCommand.values;
      if (this.state.runningCommand < values.length && values[this.state.runningCommand] && values[this.state.runningCommand].is_finished) {
        const { values } = this.props.selectedSequentialCommand;
        let runningCommand = this.state.runningCommand;
        runningCommand++;
        // const explainedText = 'In ' + (values[runningCommand].delay / 1000) + ' s. It will command value at ' + values[runningCommand].value;
        const explainedText = this.state.explainedText === 'Stopping...' || this.state.explainedText === START_COMMANDS_MESSAGE ? this.state.explainedText : runningCommand + ' / ' + values.length + ' Commands';
        this.setState({ runningCommand, explainedText });
      } else if (this.props.selectedSequentialCommand.is_finished && this.props.selectedSequentialCommand.finished_date !== DEFAULT_SERV_UNSET_TIMESTAMP) {
        clearInterval(this.checkingSequenceInterval);
        this.setState({ playing: false, explainedText: START_COMMANDS_MESSAGE, unavailableBecause: { offline: !this.isDeviceOnline(), busy: false } });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.checkingSequenceInterval);
  }

  onPlayHandler = () => {
    const { sensor: { secret, socket }, values } = this.props.widget.switchProps.sequenceList;
    this.props.startSequentialCommand(secret, socket, JSON.stringify(values), JSON.stringify({ secret, socket, wid: this.props.widget.id }));
    // const explainedText = 'In ' + (values[0].delay / 1000) + ' s. It will command value at ' + values[0].value;
    const explainedText = '0 / ' + values.length + ' Commands';
    this.setState({ playing: true, explainedText, runningCommand: 0 });
  }

  onStopHandler = () => {
    this.props.stopSequentialCommand(this.props.sequentialCommand ? this.props.sequentialCommand.id : this.props.selectedSequentialCommand.id);
    const explainedText = 'Stopping...';
    this.setState({ explainedText });
  }

  render() {
    const { unavailableBecause, doneFirstChecking, playing, explainedText } = this.state;

    let available = true;
    Object.keys(unavailableBecause).forEach(key => {
      if (unavailableBecause[key]) available = false;
    });

    return (
      <div className={styles.SwitchWrapper}>
        {
          available ?
            doneFirstChecking ?
              <div>
                {
                  playing
                    ? <img src={StopIcon} style={{ width: 100, height: 100, cursor: 'pointer' }} onClick={this.onStopHandler} alt="stop icon" />
                    : <img src={PlayIcon} style={{ width: 100, height: 100, cursor: 'pointer' }} onClick={this.onPlayHandler} alt="play icon" />
                }
                <div className={styles.Description}>{explainedText}</div>
                {(playing && explainedText !== 'Stopping...') && <div className={styles.Description} style={{ marginTop: 10 }}>( Press to stop commands now )</div>}
              </div>
              : <div className={styles.Description}>Loading...</div>
            :
            <div>
              {unavailableBecause.offline ? <img src={CantPlayIcon} style={{ width: 100, height: 100 }} alt="cant play icon" /> : <img src={BusyIcon} style={{ width: 90, height: 90 }} alt="busy icon" />}
              <div className={styles.Description}>{unavailableBecause.offline ? 'Device Offline' : ''}</div>
            </div>
        }

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { devices } = state.devices;
  const { selectedSequentialCommand, sequentialCommand, commands, type, result, error } = state.sequentialCommand;
  return { devices, selectedSequentialCommand, sequentialCommand, commands, type, result, error };
};

export default connect(mapStateToProps, { getSequentialCommand, getSequentialCommands, startSequentialCommand, stopSequentialCommand })(SwitchSequence);