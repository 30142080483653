import React, { Component } from "react";
import { checkValidity } from "../../../../state/utils";

import styles from "./CustomTagInput.module.scss";
import CloseIcon from "../../../../assets/icons/cancel-circle.svg";

export default class CustomTagInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: false,
    }
    this.textInput = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.scannerText !== prevProps.scannerText) {
      this.setState({ value: this.props.scannerText });
      this.checkValue({ target: { value: this.props.scannerText } });
    }
  }

  onKeyUpAddTags = (event) => {
    clearTimeout(this.checkingValueTimeout);
    this.checkingValueTimeout = setTimeout(() => {
      this.checkValue(event);
    }, 500);
  }

  checkValue = (event) => {
    const value = event.target.value.trim();
    if (value === '') {
      this.props.searchResult(this.props.allBreakdownCodes);
    } else {
      const searchedResult = [];
      let filtering = this.props.allBreakdownCodes.filter(function f(bc) {
        if (bc.title.toLowerCase().includes(value.toLowerCase()) || bc.code.toLowerCase().includes(value.toLowerCase())) {
          searchedResult.push(bc)
          return true;
        }

        if (bc.childs && bc.childs.length) {
          return bc.childs.filter(f);
        }
      });

      this.props.searchResult(searchedResult);
    }
  }

  onKeyDownCheckWhiteSpace = (event) => {
    if (
      (event.key === " " && event.target.value === "") ||
      (event.key === " " &&
        !checkValidity(event.target.value, this.props.validation))
    ) {
      event.preventDefault();
    }
  }

  removeTags = (index) => {
    const updatedResult = [...this.props.value];
    updatedResult.splice(index, 1);
    this.props.onChange({
      target: {
        name: this.props.name,
        value: updatedResult,
      },
    });
  }

  onChange = (e) => {
    this.setState({ value: e.target.value });
  }

  render() {
    let inputElement;
    inputElement = (
      <div className={`${styles.tags} ${this.state.error ? styles.Error : ""}`} onClick={() => { this.textInput.current.focus(); }}      >
        <div style={{ paddingLeft: '10px', width: "100%" }}>
          <div className={styles.tagList}>
            {this.props.value
              ? this.props.value.map((breakdown, index) => (
                <div key={index} className={styles.tag}>
                  <span className={styles.tagtitle}>{breakdown.code}</span>
                  <div onClick={() => this.removeTags(index)}>
                    <img
                      src={CloseIcon}
                      alt="close"
                      className={styles.tagRemove}
                    />
                  </div>
                </div>
              ))
              : null}

            <input
              ref={this.textInput}
              type="text"
              value={this.state.value}
              onChange={this.onChange}
              placeholder={this.props.value ? "" : this.props.placeholder}
              className={`${styles.tagInput}`}
              onKeyUp={(event) => this.onKeyUpAddTags(event)}
              onKeyDown={(event) => this.onKeyDownCheckWhiteSpace(event)}
            />
          </div>
        </div>
      </div >
    );

    return (
      <div className={styles.FormGroup} style={this.props.overidestyleformgroup}>
        {inputElement}
      </div>
    );
  }
}
