import * as actionTypes from './types';

export const resetDeviceStart = () => {
    return {
      type: actionTypes.RESET_DEVICE_START
    };
}
  
export const resetDevice = (device) => {
    return {
      type: actionTypes.RESET_DEVICE,
      device
    };
}
  
export const resetDeviceSuccess = (response) => {
    return {
      type: actionTypes.RESET_DEVICE_SUCCESS,
      result: response
    };
}
  
export const resetDeviceFail = (response) => {
    return {
      type: actionTypes.RESET_DEVICE_FAIL,
      error: response.error
    };
}

export const rebootDeviceStart = () => {
  return {
    type: actionTypes.REBOOT_DEVICE_START
  };
}

export const rebootDevice = (device) => {
  return {
    type: actionTypes.REBOOT_DEVICE,
    device
  };
}

export const rebootDeviceSuccess = (response) => {
  return {
    type: actionTypes.REBOOT_DEVICE_SUCCESS,
    result: response
  };
}

export const rebootDeviceFail = (response) => {
  return {
    type: actionTypes.REBOOT_DEVICE_FAIL,
    error: response.error
  };
}
  
export const createDeviceStart = () => {
  return {
    type: actionTypes.CREATE_DEVICE_START
  };
};

export const createDevice = (form) => {
  return {
    type: actionTypes.CREATE_DEVICE,
    form
  };
};

export const createDeviceSuccess = (response) => {
  return {
    type: actionTypes.CREATE_DEVICE_SUCCESS,
    result: response
  };
};

export const createDeviceFail = (response) => {
  return {
    type: actionTypes.CREATE_DEVICE_FAIL,
    error: response.error
  };
};