export const GET_BREAKDOWNS_START = 'GET_BREAKDOWNS_START';
export const GET_BREAKDOWNS = 'GET_BREAKDOWNS';
export const GET_BREAKDOWNS_SUCCESS = 'GET_BREAKDOWNS_SUCCESS';
export const GET_BREAKDOWNS_FAIL = 'GET_BREAKDOWNS_FAIL';

export const EDIT_BREAKDOWN_START = 'EDIT_BREAKDOWN_START';
export const EDIT_BREAKDOWN = 'EDIT_BREAKDOWN';
export const EDIT_BREAKDOWN_SUCCESS = 'EDIT_BREAKDOWN_SUCCESS';
export const EDIT_BREAKDOWN_FAIL = 'EDIT_BREAKDOWN_FAIL';

export const CLEAR_BREAKDOWNS = 'CLEAR_BREAKDOWNS';