import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './ProductionBatchs.module.scss';
import SeePlansIcon from '../../../../assets/icons/see-plans-icon.svg';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import Button from '../../UI/Button/Button';

import { getAdvanceProductionBatchs } from '../../../../state/ducks/AdvanceProductionBatch/actions';
import { DELETE_ADVANCE_PRODUCTION_BATCH_SUCCESS, MAP_PLANS_TO_BATCH_SUCCESS } from '../../../../state/ducks/AdvanceProductionBatch/types';

class ProductionBatchs extends Component {
	state = {
		open: false
	};

	componentDidMount() {
		this.props.getAdvanceProductionBatchs(this.props.currentOrg); // org, batch, start, stop
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg) {
			this.props.getAdvanceProductionBatchs(this.props.currentOrg);
		} else if (this.props.result !== prevProps.result && this.props.result === 'success') {
			if ((prevProps.addedProductionBatch !== this.props.addedProductionBatch) || (prevProps.updatedProductionBatch !== this.props.updatedProductionBatch
				|| this.props.type === DELETE_ADVANCE_PRODUCTION_BATCH_SUCCESS || this.props.type === MAP_PLANS_TO_BATCH_SUCCESS)) {
				this.props.getAdvanceProductionBatchs(this.props.currentOrg);
			}
		}
	}

	render() {
		const { productionBatchs } = this.props;

		let arrowClasses = [styles.ArrowIcon];
		if (this.state.open) {
			arrowClasses.push(styles.ToggleDown);
		}

		return (
			<div className={styles.ProductionBatchs}>
				{
					productionBatchs && productionBatchs.length
						? productionBatchs.map(pb => (
							<div key={`Batch-${pb.uuid}`} className={styles.BatchCard}>
								<div className={styles.BatchInfos}>
									<div className={styles.BatchName}>{pb.batch_number} {pb.plans && <div className={styles.BatchPlans}>( {pb.plans.length} Plans )</div>}</div>
									<div className={styles.BatchTime}>{moment(pb.start).format("DD/MM/YYYY HH:mm")} - {moment(pb.end).format("DD/MM/YYYY HH:mm")}</div>
								</div>
								<div className={styles.ActionButtons}>
									<Button icon={SeePlansIcon} type="button" name="See plans" color="white" noMargin click={(e) => this.props.openPlansModal(e, pb)} overideButtonStyles={{ width: 150 }} />
									<img src={EditIcon} style={{ width: 20, height: 20, marginLeft: 30 }} alt="Edit Batch" onClick={(e) => this.props.editBatch(e, pb)} />
								</div>
							</div>
						))
						: null
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { currentOrg } = state.org;
	const { type, result, productionBatchs, addedProductionBatch, updatedProductionBatch } = state.advanceProductionBatch;
	return { currentOrg, type, result, productionBatchs, addedProductionBatch, updatedProductionBatch };
};

export default connect(mapStateToProps, { getAdvanceProductionBatchs })(ProductionBatchs);
