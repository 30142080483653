export const GET_DOC_MASTER_DATAS_START = 'GET_DOC_MASTER_DATAS_START';
export const GET_DOC_MASTER_DATAS = 'GET_DOC_MASTER_DATAS';
export const GET_DOC_MASTER_DATAS_SUCCESS = 'GET_DOC_MASTER_DATAS_SUCCESS';
export const GET_DOC_MASTER_DATAS_FAIL = 'GET_DOC_MASTER_DATAS_FAIL';

export const POST_DOC_MASTER_DATAS_START = 'POST_DOC_MASTER_DATAS_START';
export const POST_DOC_MASTER_DATAS = 'POST_DOC_MASTER_DATAS';
export const POST_DOC_MASTER_DATAS_SUCCESS = 'POST_DOC_MASTER_DATAS_SUCCESS';
export const POST_DOC_MASTER_DATAS_FAIL = 'POST_DOC_MASTER_DATAS_FAIL';

export const ADD_DOC_MASTER_DATA_START = 'ADD_DOC_MASTER_DATA_START';
export const ADD_DOC_MASTER_DATA = 'ADD_DOC_MASTER_DATA';
export const ADD_DOC_MASTER_DATA_SUCCESS = 'ADD_DOC_MASTER_DATA_SUCCESS';
export const ADD_DOC_MASTER_DATA_FAIL = 'ADD_DOC_MASTER_DATA_FAIL';

export const EDIT_DOC_MASTER_DATA_START = 'EDIT_DOC_MASTER_DATA_START';
export const EDIT_DOC_MASTER_DATA = 'EDIT_DOC_MASTER_DATA';
export const EDIT_DOC_MASTER_DATA_SUCCESS = 'EDIT_DOC_MASTER_DATA_SUCCESS';
export const EDIT_DOC_MASTER_DATA_FAIL = 'EDIT_DOC_MASTER_DATA_FAIL';

export const DELETE_DOC_MASTER_DATA_START = 'DELETE_DOC_MASTER_DATA_START';
export const DELETE_DOC_MASTER_DATA = 'DELETE_DOC_MASTER_DATA';
export const DELETE_DOC_MASTER_DATA_SUCCESS = 'DELETE_DOC_MASTER_DATA_SUCCESS';
export const DELETE_DOC_MASTER_DATA_FAIL = 'DELETE_DOC_MASTER_DATA_FAIL';

export const CLEAR_DOC_MASTER_DATAS = 'CLEAR_DOC_MASTER_DATAS';