import React from 'react';

import styles from './LoadingCard.module.scss';

const LoadingCard = (props) => {
  let LoadingStyles;
  if (props.isMobile) {
    LoadingStyles = styles.LoadingCardMobile;
    if (props.type === 'nopic')
      LoadingStyles = styles.LoadingCardNoPicMobile;
  } else {
    LoadingStyles = styles.LoadingCard;
    if (props.type === 'nopic')
      LoadingStyles = styles.LoadingCardNoPic;
  }
  return (
    <React.Fragment>
      <div className={LoadingStyles} style={props.isMobile ? {} : {marginLeft: "10px"}}></div>
      <div className={LoadingStyles}></div>
      <div className={LoadingStyles}></div>
    </React.Fragment>
  );
}

export default LoadingCard;
