
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_DEVICE_GROUPINGS, ADD_DEVICE_GROUPING, EDIT_DEVICE_GROUPING, DELETE_DEVICE_GROUPING } from './types';

export function* watchDeviceGroupings() {
  yield takeEvery(GET_DEVICE_GROUPINGS, getDeviceGroupingsSaga);
  yield takeEvery(ADD_DEVICE_GROUPING, addDeviceGroupingSaga);
  yield takeEvery(EDIT_DEVICE_GROUPING, editDeviceGroupingSaga);
  yield takeEvery(DELETE_DEVICE_GROUPING, deleteDeviceGroupingSaga);
}

export function* getDeviceGroupingsSaga(params) {
  yield put(actions.getDeviceGroupingsStart());

  let api = 'v1/devicegrouping';
  api += '?org_id=' + params.org_id;

  try {
    const response = yield axios.get(api);
    // console.log("GET DEVICE_GROUPINGS SUCCESS", response.data);
    yield put(actions.getDeviceGroupingsSuccess(response.data));
  } catch (err) {
    console.log("GET DEVICE_GROUPINGS FAILED", err)
    yield put(actions.getDeviceGroupingsFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* addDeviceGroupingSaga(params) {
  yield put(actions.addDeviceGroupingStart());

  let postData = 'org_id=' + params.org_id;
  postData += "&relation_ref=" + encodeURIComponent(params.relation_ref);
  postData += "&payload=" + encodeURIComponent(JSON.stringify(params.payload));

  const api = 'v1/devicegrouping';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD DEVICE_GROUPING SUCCESS", response);
    yield put(actions.addDeviceGroupingSuccess(response.data));
  } catch (err) {
    console.log("ADD DEVICE_GROUPING FAILED", err.response.data);
    yield put(actions.addDeviceGroupingFail(err.response.data.error));
  }
}

export function* editDeviceGroupingSaga(params) {
  yield put(actions.editDeviceGroupingStart());

  let putData = 'id=' + params.id;
  putData += params.relation_ref ? '&relation_ref=' + encodeURIComponent(params.relation_ref) : '';
  putData += params.payload ? '&payload=' + encodeURIComponent(JSON.stringify(params.payload)) : '';

  const api = 'v1/devicegrouping'

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT DEVICE_GROUPING SUCCESS", response.data);
    yield put(actions.editDeviceGroupingSuccess(response.data));
  } catch (err) {
    console.log("EDIT DEVICE_GROUPING FAILED", err.response.data);
    yield put(actions.editDeviceGroupingFail(err.response.data.error));
  }
}

export function* deleteDeviceGroupingSaga(params) {
  yield put(actions.deleteDeviceGroupingStart());

  const api = 'v1/devicegrouping?id=' + params.id;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE DeviceGrouping SUCCESS", response.data);
    yield put(actions.deleteDeviceGroupingSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE DeviceGrouping FAILED", err.response);
    yield put(actions.deleteDeviceGroupingFail(err.response));
  }
}