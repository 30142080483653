export const GET_FORMULAS_START = 'GET_FORMULAS_START';
export const GET_FORMULAS = 'GET_FORMULAS';
export const GET_FORMULAS_SUCCESS = 'GET_FORMULAS_SUCCESS';
export const GET_FORMULAS_FAIL = 'GET_FORMULAS_FAIL';

export const ADD_FORMULA_START = 'ADD_FORMULA_START';
export const ADD_FORMULA = 'ADD_FORMULA';
export const ADD_FORMULA_SUCCESS = 'ADD_FORMULA_SUCCESS';
export const ADD_FORMULA_FAIL = 'ADD_FORMULA_FAIL';

export const EDIT_FORMULA_START = 'EDIT_FORMULA_START';
export const EDIT_FORMULA = 'EDIT_FORMULA';
export const EDIT_FORMULA_SUCCESS = 'EDIT_FORMULA_SUCCESS';
export const EDIT_FORMULA_FAIL = 'EDIT_FORMULA_FAIL';

export const DELETE_FORMULA_START = 'DELETE_FORMULA_START';
export const DELETE_FORMULA = 'DELETE_FORMULA';
export const DELETE_FORMULA_SUCCESS = 'DELETE_FORMULA_SUCCESS';
export const DELETE_FORMULA_FAIL = 'DELETE_FORMULA_FAIL';

export const GET_FORMULA_MAPPINGS_START = 'GET_FORMULA_MAPPINGS_START';
export const GET_FORMULA_MAPPINGS = 'GET_FORMULA_MAPPINGS';
export const GET_FORMULA_MAPPINGS_SUCCESS = 'GET_FORMULA_MAPPINGS_SUCCESS';
export const GET_FORMULA_MAPPINGS_FAIL = 'GET_FORMULA_MAPPINGS_FAIL';

export const POST_FORMULA_MAPPING_START = 'POST_FORMULA_MAPPING_START';
export const POST_FORMULA_MAPPING = 'POST_FORMULA_MAPPING';
export const POST_FORMULA_MAPPING_SUCCESS = 'POST_FORMULA_MAPPING_SUCCESS';
export const POST_FORMULA_MAPPING_FAIL = 'POST_FORMULA_MAPPING_FAIL';

export const DELETE_FORMULA_MAPPING_START = 'DELETE_FORMULA_MAPPING_START';
export const DELETE_FORMULA_MAPPING = 'DELETE_FORMULA_MAPPING';
export const DELETE_FORMULA_MAPPING_SUCCESS = 'DELETE_FORMULA_MAPPING_SUCCESS';
export const DELETE_FORMULA_MAPPING_FAIL = 'DELETE_FORMULA_MAPPING_FAIL';