import * as actionTypes from './types';

// ----------------------- GET TEAMS -----------------------
export const getTeamsStart = () => {
  return {
    type: actionTypes.GET_TEAMS_START
  };
}

export const getTeams = (org_id, id, name) => {
  return {
    type: actionTypes.GET_TEAMS,
    org_id,
    id,
    name
  };
}

export const getTeamsSuccess = (response) => {
  if (response.team) {
    return {
      type: actionTypes.GET_TEAMS_SUCCESS,
      team: response.team
    };
  }
  return {
    type: actionTypes.GET_TEAMS_SUCCESS,
    teams: response.teams,
    allTeamsMembers: response.members
  };
}

export const getTeamsFail = (error) => {
  return {
    type: actionTypes.GET_TEAMS_FAIL,
    error: error
  };
}

// ----------------------- ADD TEAM -----------------------
export const addTeamStart = () => {
  return {
    type: actionTypes.ADD_TEAM_START
  };
}

export const addTeam = (org_id, name, detail) => {
  return {
    type: actionTypes.ADD_TEAM,
    org_id,
    name,
    detail
  };
}

export const addTeamSuccess = (response) => {
  return {
    type: actionTypes.ADD_TEAM_SUCCESS,
    addedTeam: response.team,
    result: response.result
  };
}

export const addTeamFail = (response) => {
  return {
    type: actionTypes.ADD_TEAM_FAIL,
    result: response.result,
    error: response.error
  };
}

// ----------------------- EDIT TEAM -----------------------
export const editTeamStart = () => {
  return {
    type: actionTypes.EDIT_TEAM_START
  };
}

export const editTeam = (id, name, detail, addingMembers, deletingMembers) => {
  return {
    type: actionTypes.EDIT_TEAM,
    id,
    name,
    detail,
    addingMembers, deletingMembers
  };
}

export const editTeamSuccess = (response) => {
  return {
    type: actionTypes.EDIT_TEAM_SUCCESS,
    updatedTeam: response.team,
    result: response.result
  };
}

export const editTeamFail = (error) => {
  return {
    type: actionTypes.EDIT_TEAM_FAIL,
    error: error
  };
}

// ----------------------- DELETE TEAM -----------------------
export const deleteTeamStart = () => {
  return {
    type: actionTypes.DELETE_TEAM_START
  };
}

export const deleteTeam = (id) => {
  return {
    type: actionTypes.DELETE_TEAM,
    id: id
  };
}

export const deleteTeamSuccess = (result) => {
  return {
    type: actionTypes.DELETE_TEAM_SUCCESS,
    result: result
  };
}

export const deleteTeamFail = (error) => {
  return {
    type: actionTypes.DELETE_TEAM_FAIL,
    error: error
  };
}

// ----------------------- ADD TEAM MEMBERS -----------------------
export const addTeamMembersStart = () => {
  return {
    type: actionTypes.ADD_TEAM_MEMBERS_START
  };
}

export const addTeamMembers = (id, new_members) => {
  return {
    type: actionTypes.ADD_TEAM_MEMBERS,
    id,
    new_members
  };
}

export const addTeamMembersSuccess = (response) => {
  return {
    type: actionTypes.ADD_TEAM_MEMBERS_SUCCESS,
    updatedTeamMembers: response.member
  };
}

export const addTeamMembersFail = (error) => {
  return {
    type: actionTypes.ADD_TEAM_MEMBERS_FAIL,
    error: error
  };
}

// ----------------------- REMOVE TEAM MEMBERS -----------------------
export const removeTeamMembersStart = () => {
  return {
    type: actionTypes.REMOVE_TEAM_MEMBERS_START
  };
}

export const removeTeamMembers = (id, del_members) => {
  return {
    type: actionTypes.REMOVE_TEAM_MEMBERS,
    id,
    del_members
  };
}

export const removeTeamMembersSuccess = (response) => {
  return {
    type: actionTypes.REMOVE_TEAM_MEMBERS_SUCCESS,
    updatedTeamMembers: response.member
  };
}

export const removeTeamMembersFail = (error) => {
  return {
    type: actionTypes.REMOVE_TEAM_MEMBERS_FAIL,
    error: error
  };
}

// ----------------------- CHANGE TEAM ADMIN -----------------------
export const changeTeamAdminStart = () => {
  return {
    type: actionTypes.CHANGE_TEAM_ADMIN_START
  };
}

export const changeTeamAdmin = (id, user_id) => {
  return {
    type: actionTypes.CHANGE_TEAM_ADMIN,
    id,
    user_id
  };
}

export const changeTeamAdminSuccess = (response) => {
  return {
    type: actionTypes.CHANGE_TEAM_ADMIN_SUCCESS,
    updatedTeam: response.team,
    result: response.result
  };
}

export const changeTeamAdminFail = (error) => {
  return {
    type: actionTypes.CHANGE_TEAM_ADMIN_FAIL,
    error: error
  };
}

// ----------------------- LEAVE TEAM -----------------------
export const leaveTeamStart = () => {
  return {
    type: actionTypes.LEAVE_TEAM_START
  };
}

export const leaveTeam = (id) => {
  return {
    type: actionTypes.LEAVE_TEAM,
    id: id
  };
}

export const leaveTeamSuccess = (result) => {
  return {
    type: actionTypes.LEAVE_TEAM_SUCCESS,
    result: result
  };
}

export const leaveTeamFail = (error) => {
  return {
    type: actionTypes.LEAVE_TEAM_FAIL,
    error: error
  };
}

// export default {
//   getTeamsStart,
//   getTeams,
//   getTeamsSuccess,
//   getTeamsFail,
//   addTeam,
//   addTeamStart,
//   addTeamSuccess,
//   addTeamFail,
//   editTeam,
//   editTeamStart,
//   editTeamSuccess,
//   editTeamFail,
//   deleteTeam,
//   deleteTeamStart,
//   deleteTeamSuccess,
//   deleteTeamFail,
//   addTeamMembers,
//   addTeamMembersStart,
//   addTeamMembersSuccess,
//   addTeamMembersFail,
//   removeTeamMembers,
//   removeTeamMembersStart,
//   removeTeamMembersSuccess,
//   removeTeamMembersFail,
//   changeTeamAdmin,
//   changeTeamAdminStart,
//   changeTeamAdminSuccess,
//   changeTeamAdminFail,
//   leaveTeam,
//   leaveTeamStart,
//   leaveTeamSuccess,
//   leaveTeamFail,
// };