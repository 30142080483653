import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import styles from './PageTimeline.module.scss';
import Button from '../../components/UI/Button/Button';
import goBackIcon from '../../../assets/icons/goback.svg';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import FullScreenIcon from '../../../assets/icons/full-screen.svg';
import NormalScreenIcon from '../../../assets/icons/normal-screen.svg';
import XIcon from '../../../assets/icons/cancel-circle.svg';
import TimelineBar from '../../components/Timeline/TimelineBar';
import TimelineDrawer from '../../components/Timeline/TimelineDrawer';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
// import EditIcon from '../../../assets/icons/edit.svg';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import TimelineEmptyImg from '../../../assets/images/empty-timeline.svg';

import { getPageAndMembers } from '../../../state/ducks/Pages/actions';
import { getDevices } from '../../../state/ducks/Devices/actions';
import { getTimelines, addTimeline, editTimeline, deleteTimeline } from '../../../state/ducks/Timeline/actions';
import { addTimelineRemark, editTimelineRemark } from '../../../state/ducks/TimelineStatus/actions';
import { getMachines } from '../../../state/ducks/Machine/actions';

const ONE_DAY_IN_MS = 86400000;

const parseNumToTextZero = (num) => {
  if (num < 10)
    return '0' + num;
  return '' + num;
}

const removeTimelineFromList = (arr, targetId) => {
  var index = -1;
  for (index = 0; index < arr.length; index++) {
    if (arr[index].id === targetId)
      break;
  }
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

const getToday = () => {
  let today = new Date();
  let dayStartingTime = localStorage.getItem('default_day_starting_time');
  if (dayStartingTime) {
    dayStartingTime = dayStartingTime.split(":");
    let newDay = new Date(new Date().setHours(+dayStartingTime[0], +dayStartingTime[1], 0, 0));
    if (today < newDay) {
      today = new Date(moment(today).valueOf() - ONE_DAY_IN_MS);
    }
  }
  return today;
}

class PageTimeline extends Component {
  state = {
    pageId: null,
    isFullScreen: false,
    bottomDrawerShow: false,
    reasons: {},
    isDrawerOpened: false,
    isAdding: false,
    editingObject: null,
    date: getToday(),
    drawerForm: null,
    timelines: [],
    isEdit: false,
    isDeleteModalOpened: false,
    deleteTimelineId: 0,
    statusList: [],
    statusLookingIndex: null,
    selectingTimelineIndex: null,
    updatingReasonObj: null,
    updatingReasonIndex: null,
    newReason: ''
  }

  componentDidMount() {
    const { match: { params } } = this.props.route;
    this.props.getDevices(this.props.currentOrg, true);
    this.props.getMachines(this.props.currentOrg);
    this.props.getTimelines(params.pageId);
    this.props.getPageAndMembers(params.pageId);

    this.reasonRefs = React.createRef();
    this.setState({ pageId: params.pageId, reasons: { 1: '' } });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.currentOrg !== this.props.currentOrg) {
      this.props.route.history.push('/');
      return false;
    }
    if (nextProps.devices !== this.props.devices) {
      return true;
    }
    if (nextProps.machines !== this.props.machines) {
      return true;
    }
    if (nextProps.timelines !== this.props.timelines) {
      this.setState({ timelines: nextProps.timelines });
      return true;
    }
    if (nextProps.addedTimeline !== this.props.addedTimeline) {
      let timelines = this.state.timelines;
      timelines.push(nextProps.addedTimeline);
      this.setState({ timelines });
      return true;
    }
    if (nextProps.updatedTimeline !== this.props.updatedTimeline) {
      let timelines = this.state.timelines;
      for (let i = 0; i < timelines.length; i++) {
        if (timelines[i].id === nextProps.updatedTimeline.id) {
          timelines[i] = nextProps.updatedTimeline;
          break;
        }
      }
      this.setState({ timelines, editingObject: null });
      return true;
    }
    if (nextProps.result !== this.props.result && nextProps.result === 'success' && this.state.deleteTimelineId > 0) {
      let timelines = removeTimelineFromList(this.state.timelines, this.state.deleteTimelineId);
      this.setState({ timelines, deleteTimelineId: 0, isDeleteModalOpened: false });
      return true;
    }
    if (nextProps.updatedTimelineStatus && !_.isEqual(this.props.updatedTimelineStatus, nextProps.updatedTimelineStatus)) {
      return true;
    }
    return !nextProps.loading;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.bottomDrawerShow && (this.state.statusLookingIndex || this.state.statusLookingIndex === 0) && prevState.statusLookingIndex !== this.state.statusLookingIndex) {
      setTimeout(() => this.statusRefs[this.state.statusLookingIndex].current.scrollIntoView({ block: "center", behavior: "smooth" }), 150);
    }
  }

  toggleFullScreen = () => {
    this.setState(prevState => {
      return { isFullScreen: !prevState.isFullScreen }
    });
  }

  handleCloseBottomDrawer = (event) => {
    this.setState({
      bottomDrawerShow: false,
      selectingTimelineIndex: null,
      statusLookingIndex: null,
      updatingReasonObj: null,
      updatingReasonIndex: null,
      newReason: ''
    });
  }

  handleTimelineBarClick = (selectedTimelineId, timelineStatuses, statusLookingIndex) => {
    const selectingTimelineIndex = this.props.timelines.findIndex(tl => tl.id === selectedTimelineId);
    if (selectingTimelineIndex !== this.state.selectingTimelineIndex) {
      this.setState({ updatingReasonObj: null, updatingReasonIndex: null, newReason: '' });
    }
    this.setState({ bottomDrawerShow: true, selectingTimelineIndex, statusLookingIndex, statusList: timelineStatuses.data });
  }

  handleDateChange = (event) => {
    this.setState({ date: new Date(event.target.value) });
    this.handleCloseBottomDrawer();
  }

  drawerClosedHandler = () => {
    this.setState({ isDrawerOpened: false });
  }

  openDrawerForAddingHandler = (event) => {
    this.setState({ isAdding: true, editingObject: null, isDrawerOpened: true });
  }

  handleToggleEdit = () => {
    this.setState({ isEdit: !this.state.isEdit });
  }

  handleDrawerFormSubmit = (form) => {
    let properties = {};
    if (form.colorThreshold) {
      properties.colorThreshold = form.colorThreshold;
    }

    let formula_oee = {};
    if (form.performanceFormula) formula_oee.p = form.performanceFormula;
    if (form.qualityFormula) formula_oee.q = form.qualityFormula;

    if (this.state.isAdding) {
      this.props.addTimeline(
        this.state.pageId,
        form.name,
        form.machine.id,
        formula_oee,
        form.dayStartingTime,
        properties);
    } else {
      this.props.editTimeline(
        this.state.editingObject.id,
        form.name,
        form.machine.id,
        formula_oee,
        form.dayStartingTime,
        properties);
    }
  }

  handleDeleteTimeline = (timeline) => {
    this.setState({ isDeleteModalOpened: true, deleteTimelineId: timeline.id });
  }

  handleEditTimeline = (timeline) => {
    this.setState({ editingObject: timeline, isDrawerOpened: true, isAdding: false })
  }

  deleteModalClickHandler = () => {
    this.setState({ isDeleteModalOpened: false });
  }

  onConfirmDeleteDevice = () => {
    this.props.deleteTimeline(this.state.deleteTimelineId);
  }

  humanizeDuration = (ms) => {
    const days = moment.duration(ms).days();
    const hours = moment.duration(ms).hours();
    const minutes = moment.duration(ms).minutes();
    const seconds = moment.duration(ms).seconds();

    let result = days === 0 ? '' : days > 1 ? days + ' days ' : days + ' day ';
    result += hours === 0 ? '' : hours > 1 ? hours + ' hours ' : hours + ' hour ';
    result += minutes === 0 ? '' : minutes > 1 ? minutes + ' minutes ' : minutes + ' minute ';
    result += seconds === 0 ? '' : seconds > 1 ? seconds + ' seconds ' : seconds + ' second';

    return result;
  }

  changeToReasonForm = (index) => {
    const { start, stop } = this.state.statusList[index];
    if (stop < start) return;
    setTimeout(() => this.reasonRefs.current.focus(), 500);
    const remark = this.state.statusList[index].remark ? this.state.statusList[index].remark : '';
    this.setState({ updatingReasonIndex: index, updatingReasonObj: this.state.statusList[index], newReason: remark });
  }

  onSubmitUpdatedReasonHandler = (event) => {
    event.preventDefault();
    if (this.state.updatingReasonObj.remark_id) {
      this.props.editTimelineRemark(this.state.updatingReasonObj.remark_id, this.state.newReason);
    } else {
      this.props.addTimelineRemark(
        this.props.timelines[this.state.selectingTimelineIndex].id,
        moment(this.state.updatingReasonObj.start).valueOf(),
        moment(this.state.updatingReasonObj.stop).valueOf(),
        this.state.newReason
      );
    }
    const statusList = [...this.state.statusList];
    statusList[this.state.updatingReasonIndex].remark = this.state.newReason;
    this.setState({ updatingReasonIndex: null, newReason: null, updatingReasonObj: null, statusList });
  }

  render() {
    if (this.props.error) {
      return (
        <div className={styles.TimelineNotFoundWrapper}>
          <div className={styles.TimelineNotFoundInfo}>{this.props.error}</div>
          <div className={styles.ButtonWrapper}><Button type="button" color="primary" name="Go back to Pages" click={() => this.props.route.history.push('/pages')} noMargin /></div>
        </div>
      );
    }

    let { isEdit, timelines, statusList, statusLookingIndex, selectingTimelineIndex, bottomDrawerShow } = this.state;

    let ScreenStyle = styles.MinimizeScreen;
    if (this.state.isFullScreen) {
      ScreenStyle = styles.FullScreen;
    }

    let row = [];
    if (Array.isArray(timelines) && timelines.length > 0) {
      timelines.forEach(timeline => {
        row.push(
          <TimelineBar
            isEdit={isEdit}
            key={'row_' + timeline.id}
            timeline={timeline}
            date={this.state.date}
            onBarClick={this.handleTimelineBarClick}
            onDelete={this.handleDeleteTimeline}
            onEdit={this.handleEditTimeline} />
        );
      });
    } else {
      isEdit = true;
    }

    let statusRows = [];
    this.statusRefs = [];
    if (bottomDrawerShow && Array.isArray(statusList) && statusList.length > 0) {
      statusList.forEach((status, i) => {
        let start = new Date(status.start);
        let stop = new Date(status.stop) < start ? 'In Progress' : new Date(status.stop);
        let diff = 0;
        if (stop !== 'In Progress') {
          if (stop > start) {
            diff = (moment(stop).valueOf() - moment(start).valueOf());
          }
          stop = moment(stop).format('DD/MM/YYYY HH:mm:ss');
        } else {
          diff = (moment().valueOf() - moment(start).valueOf());
        }

        start = moment(start).format('DD/MM/YYYY HH:mm:ss');
        const machineStatus = timelines[selectingTimelineIndex].machine.status_rules[status.value];

        let statusReason = 'N/A';
        let statusColor = '#9ea0a5';
        let isBlackColor = false;
        if (machineStatus) {
          statusReason = machineStatus.reason;
          statusColor = machineStatus.color;
          isBlackColor = machineStatus.color === '#222226' ? true : false;
        }
        this.statusRefs[i] = React.createRef();

        statusRows.push(
          <tr ref={this.statusRefs[i]} key={`status${i + 1}_` + start} className={(statusLookingIndex || statusLookingIndex === 0) && statusLookingIndex === i ? styles.SelectedRowFromTimeline : null}>
            <td>{start}</td>
            <td>{stop}</td>
            <td>{this.humanizeDuration(diff)}</td>
            <td className={styles.ReasonCol} style={{ color: statusColor }}>
              <div className={`${styles.DotStatusColor} ${isBlackColor ? styles.ShadowBox : ''}`} style={{ backgroundColor: statusColor }} />
              <span className={isBlackColor ? styles.ShadowText : ''}>{statusReason}</span>
            </td>
            <td onClick={() => this.changeToReasonForm(i)}>
              {
                i === this.state.updatingReasonIndex
                  ? <form onSubmit={this.onSubmitUpdatedReasonHandler} style={{ width: '100%' }}>
                    <input ref={this.reasonRefs}
                      className={styles.ReasonForm}
                      type="text"
                      value={this.state.newReason}
                      onChange={event => this.setState({ newReason: event.target.value })}
                    />
                  </form>
                  : status.remark
                    ? status.remark
                    : stop !== 'In Progress'
                      ? <img src={PlusCircleIcon} style={{ opacity: 0.25, cursor: 'pointer', width: 16 }} alt="add reason" /> : ''
              }
            </td>
          </tr>
        );
      });
    }

    let selectdate = this.state.date;
    // let today = getToday();
    // let yesterday = new Date(moment(today).valueOf() - ONE_DAY_IN_MS);
    let selectdatestr = selectdate.getFullYear() + '-' + parseNumToTextZero(selectdate.getMonth() + 1) + '-' + parseNumToTextZero(selectdate.getDate());
    // let todaystr = today.getFullYear() + '-' + parseNumToTextZero(today.getMonth() + 1) + '-' + parseNumToTextZero(today.getDate());
    // let yesterdaystr = yesterday.getFullYear() + '-' + parseNumToTextZero(yesterday.getMonth() + 1) + '-' + parseNumToTextZero(yesterday.getDate());

    return (
      <div className={ScreenStyle}>
        <SideDrawer title={this.state.isAdding ? 'Add New Timeline' : 'Edit Timeline'} open={this.state.isDrawerOpened} closed={this.drawerClosedHandler}>
          <TimelineDrawer
            isAdding={this.state.isAdding}
            currentOrg={this.props.currentOrg}
            editingObject={this.state.editingObject}
            devices={this.props.devices}
            machines={this.props.machines}
            closed={this.drawerClosedHandler}
            onSubmit={this.handleDrawerFormSubmit} />
        </SideDrawer>
        <div className={styles.TopSection}>
          <div style={{ flex: 1 }}>
            <Link to='/pages'><img src={goBackIcon} alt="Go back to page" /></Link>
            <span className={styles.PageName}>{this.props.pageAndMembers ? this.props.pageAndMembers.page.name : ''}</span>
          </div>
          <div key="fullscreen-btn" className={styles.ScreenButton}>
            {/* max={todaystr} min={yesterdaystr} */}
            <input type="date" placeholder="dd/mm/yyyy" value={selectdatestr} onChange={this.handleDateChange} onKeyDown={(e) => e.preventDefault()} />
            {/* <div className={styles.DatePickerTwoDays}>
              <div style={{ marginRight: 6 }}>Yesterday</div> / <div style={{ marginLeft: 6 }}>Today</div>
            </div> */}
            {
              this.state.isFullScreen ?
                <img src={NormalScreenIcon} alt="Normal Screen Icon" onClick={this.toggleFullScreen} />
                :
                <div className={styles.ScreenWrapper} onClick={this.toggleFullScreen} >
                  <img src={FullScreenIcon} alt="Full Screen Icon" style={{ width: 20, height: 20 }} />
                  <div className={styles.ScreenTitle}>Full Screen</div>
                </div>
            }
            {isEdit ?
              <>
                <div style={{ marginLeft: 10, marginTop: 3 }}><Button type="button" color="primary" name="Add Timeline" noMargin click={this.openDrawerForAddingHandler} /></div>
                <div style={{ marginLeft: 10, marginTop: 3 }}><Button type="button" color="green" name="Done" noMargin click={this.handleToggleEdit} /></div>
              </> :
              <div style={{ marginLeft: 10, marginTop: 3, width: 80 }}><Button type="button" color="primary" name="Edit" noMargin click={this.handleToggleEdit} /></div>
            }
          </div>
        </div>
        <div className={styles.ContentSection}>
          {
            this.props.machines &&
            <div className={styles.StatusRulesLegend}>
              <div className={styles.LegendTitle}>Status :</div>
              {
                this.props.timelines && this.props.timelines.length ? Object.keys(this.props.timelines[0].machine.status_rules).map(value => {
                  const { color, reason } = this.props.timelines[0].machine.status_rules[value];
                  return <div
                    key={`statusrules-${value}-${color}`}
                    className={styles.LegendBox}
                    style={{ backgroundColor: color, boxShadow: +value === 0 ? '0px 0px 6px -4px white' : 'none' }}>
                    {reason}
                  </div>
                }) : null
              }
            </div>
          }
          {timelines && timelines.length > 0 ?
            <div className={styles.TimelineTableWrapper} style={this.state.bottomDrawerShow ? {} : { height: 'calc(100% - 226px)' }}>
              <table>
                <thead>
                  <tr>
                    <th className={styles.machine_col}>Machine</th>
                    <th>Timeline</th>
                    <th className={styles.reason_col}>Reason</th>
                    <th className={styles.apq_col}>A</th>
                    <th className={styles.apq_col}>P</th>
                    <th className={styles.apq_col}>Q</th>
                    <th className={styles.oee_col}>OEE%</th>
                    {isEdit && <th style={{ width: 50 }}>Edit</th>}
                    {isEdit && <th style={{ width: 50 }}>Delete</th>}
                  </tr>
                </thead>
                <tbody>
                  {row}
                </tbody>
              </table>
            </div> :
            <div style={{ width: '100%', textAlign: 'center', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <img src={TimelineEmptyImg} alt="no timelines" />
              <div style={{ width: 190, marginTop: 10 }}>
                <Button type="button" color="primary" name="Add Timeline" noMargin click={this.openDrawerForAddingHandler} />
              </div>
            </div>
          }

          {
            this.state.bottomDrawerShow && <div className={styles.BottomDrawer} style={this.state.bottomDrawerShow ? {} : { display: 'none' }}>
              <div className={styles.BottomDrawerTopbar}>
                <div className={styles.BottomDrawerTopbarTitle}>
                  Machine Statuses Details ( {this.state.timelines[this.state.selectingTimelineIndex].name} )
              </div>
                <img src={XIcon} alt="close" onClick={this.handleCloseBottomDrawer} style={{ cursor: 'pointer' }} />
              </div>
              <div className={styles.BottomDrawerContent}>
                <table style={{ borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th>Start</th>
                      <th>Stop</th>
                      <th>Duration</th>
                      <th>Reason</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statusRows}
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
        <DeleteModal
          title="Timeline"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeleteDevice}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { pageAndMembers } = state.pages;
  const { currentOrg } = state.org;
  const { result, timelines, addedTimeline, updatedTimeline, loading, error } = state.timeline;
  const { devices } = state.devices;
  const { machines } = state.machine;
  const { updatedTimelineStatus } = state.timelineStatus;

  return {
    devices, machines, result, pageAndMembers,
    updatedTimelineStatus,
    error, currentOrg, timelines, addedTimeline, updatedTimeline, loading
  };
};

export default connect(mapStateToProps, {
  getPageAndMembers,
  getTimelines,
  addTimeline,
  editTimeline,
  deleteTimeline,
  getMachines,
  getDevices,
  addTimelineRemark,
  editTimelineRemark,
})(PageTimeline);
