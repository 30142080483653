import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  hooks: null,
  addedHook: null,
  updatedHook: null,
  updatedEnableHook: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_HOOKS_START: return actionStart(state, action);
    case actionTypes.GET_HOOKS_SUCCESS: return getHooksSuccess(state, action);
    case actionTypes.GET_HOOKS_FAIL: return actionFail(state, action);

    case actionTypes.ADD_HOOK_START: return actionStart(state, action);
    case actionTypes.ADD_HOOK_SUCCESS: return addHookSuccess(state, action);
    case actionTypes.ADD_HOOK_FAIL: return actionFail(state, action);

    case actionTypes.EDIT_HOOK_START: return actionStart(state, action);
    case actionTypes.EDIT_HOOK_SUCCESS: return editHookSuccess(state, action);
    case actionTypes.EDIT_HOOK_FAIL: return actionFail(state, action);

    case actionTypes.ENABLE_HOOK_START: return actionStart(state, action);
    case actionTypes.ENABLE_HOOK_SUCCESS: return enableHookSuccess(state, action);
    case actionTypes.ENABLE_HOOK_FAIL: return actionFail(state, action);

    case actionTypes.DELETE_HOOK_START: return actionStart(state, action);
    case actionTypes.DELETE_HOOK_SUCCESS: return deleteHookSuccess(state, action);
    case actionTypes.DELETE_HOOK_FAIL: return actionFail(state, action);
    default: return state;
  }
};

const actionStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const actionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getHooksSuccess = (state, action) => {
  const hooks = action.hooks ? action.hooks.sort((a, b) => a.id - b.id) : null;
  return updateObject(state, {
    type: action.type,
    hooks,
    error: null,
    loading: false
  });
}

const addHookSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedHook: action.addedHook,
    error: null,
    loading: false
  });
}

const editHookSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedHook: action.updatedHook,
    error: null,
    loading: false
  });
}

const enableHookSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    updatedEnableHook: action.updatedEnableHook,
    error: null,
    loading: false
  });
}

const deleteHookSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

export default reducer;