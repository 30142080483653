
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_EXPORT_CHECK, GET_EXPORT_EXCEL, GET_EXPORT_BREAKDOWN_EXCEL } from './types';

export function* watchExport() {
  yield takeEvery(GET_EXPORT_CHECK, getExportCheckSaga);
  yield takeEvery(GET_EXPORT_EXCEL, getExportExcelSaga);
  yield takeEvery(GET_EXPORT_BREAKDOWN_EXCEL, getExportBreakdownExcelSaga);
}

export function* getExportCheckSaga(params) {
  yield put(actions.getExportCheckStart());

  let api = 'v1/export/check/';
  api += params.export_id;

  try {
    const response = yield axios.get(api);
    console.log("GET EXPORT CHECK SUCCESS", response.data);
    yield put(actions.getExportCheckSuccess(response.data));
  } catch (err) {
    console.log("GET EXPORT CHECK FAILED", err)
    yield put(actions.getExportCheckFail(err.response));
  }
}

export function* getExportExcelSaga(params) {
  yield put(actions.getExportExcelStart());

  let api = 'v1/export/excel';
  api += '?device_socket_ids=' + params.device_socket_ids;
  api += params.start ? ('&start=' + params.start) : '';
  api += params.stop ? ('&stop=' + params.stop) : '';
  api += params.sum_interval ? ('&sum_interval=' + params.sum_interval) : '';
  api += params.sum_method ? ('&sum_method=' + params.sum_method) : '';

  try {
    const response = yield axios.get(api);
    if (response.data.export_id) {
      yield put(actions.getExportCheck(response.data.export_id));
    }
    console.log("GET EXPORT EXCEL SUCCESS", response.data);
    yield put(actions.getExportExcelSuccess(response.data));
  } catch (err) {
    console.log("GET EXPORT EXCEL FAILED", err)
    yield put(actions.getExportExcelFail(err.response));
  }
}

export function* getExportBreakdownExcelSaga(params) {
  yield put(actions.getExportBrekdownExcelStart());

  let api = 'v1/export/breakdown/excel';
  api += '?org_id=' + params.org_id;
  api += '&countingdevices_id=' + params.countingdevices_id;
  api += params.start ? ('&start=' + params.start) : '';
  api += params.stop ? ('&stop=' + params.stop) : '';

  try {
    const response = yield axios.get(api);
    if (response.data.export_id) {
      yield put(actions.getExportCheck(response.data.export_id));
    }
    console.log("GET EXPORT BREAKDOWN EXCEL SUCCESS", response.data);
    yield put(actions.getExportBreakdownExcelSuccess(response.data));
  } catch (err) {
    console.log("GET EXPORT BREAKDOWN EXCEL FAILED", err)
    yield put(actions.getExportBreakdownExcelFail(err.response));
  }
}
