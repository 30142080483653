import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  team: null,
  teams: null,
  allTeamsMembers: null,
  updatedTeamMembers: null,
  addedTeam: null,
  updatedTeam: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TEAMS_START: return getTeamsStart(state, action);
    case actionTypes.GET_TEAMS_SUCCESS: return getTeamsSuccess(state, action);
    case actionTypes.GET_TEAMS_FAIL: return getTeamsFail(state, action);

    case actionTypes.ADD_TEAM_START: return addTeamStart(state, action);
    case actionTypes.ADD_TEAM_SUCCESS: return addTeamSuccess(state, action);
    case actionTypes.ADD_TEAM_FAIL: return addTeamFail(state, action);

    case actionTypes.EDIT_TEAM_START: return editTeamStart(state, action);
    case actionTypes.EDIT_TEAM_SUCCESS: return editTeamSuccess(state, action);
    case actionTypes.EDIT_TEAM_FAIL: return editTeamFail(state, action);

    case actionTypes.DELETE_TEAM_START: return deleteTeamStart(state, action);
    case actionTypes.DELETE_TEAM_SUCCESS: return deleteTeamSuccess(state, action);
    case actionTypes.DELETE_TEAM_FAIL: return deleteTeamFail(state, action);

    case actionTypes.ADD_TEAM_MEMBERS_START: return addTeamMembersStart(state, action);
    case actionTypes.ADD_TEAM_MEMBERS_SUCCESS: return addTeamMembersSuccess(state, action);
    case actionTypes.ADD_TEAM_MEMBERS_FAIL: return addTeamMembersFail(state, action);

    case actionTypes.REMOVE_TEAM_MEMBERS_START: return removeTeamMembersStart(state, action);
    case actionTypes.REMOVE_TEAM_MEMBERS_SUCCESS: return removeTeamMembersSuccess(state, action);
    case actionTypes.REMOVE_TEAM_MEMBERS_FAIL: return removeTeamMembersFail(state, action);

    case actionTypes.CHANGE_TEAM_ADMIN_START: return changeTeamAdminStart(state, action);
    case actionTypes.CHANGE_TEAM_ADMIN_SUCCESS: return changeTeamAdminSuccess(state, action);
    case actionTypes.CHANGE_TEAM_ADMIN_FAIL: return changeTeamAdminFail(state, action);

    case actionTypes.LEAVE_TEAM_START: return leaveTeamStart(state, action);
    case actionTypes.LEAVE_TEAM_SUCCESS: return leaveTeamSuccess(state, action);
    case actionTypes.LEAVE_TEAM_FAIL: return leaveTeamFail(state, action);

    default: return state;
  }
};

const getTeamsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getTeamsSuccess = (state, action) => {
  if (action.team) {
    return updateObject(state, {
      team: action.team,
      error: null,
      loading: false
    });
  } else {
    const teams = action.teams.sort((a, b) => a.created_date - b.created_date);
    return updateObject(state, {
      teams,
      allTeamsMembers: action.allTeamsMembers,
      error: null,
      loading: false
    });
  }
}

const getTeamsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addTeamStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addTeamSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedTeam: action.addedTeam,
    error: null,
    loading: false
  });
}

const addTeamFail = (state, action) => {
  return updateObject(state, {
    result: action.result,
    error: action.error,
    loading: false
  });
}

const editTeamStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editTeamSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedTeam: action.updatedTeam,
    error: null,
    loading: false
  });
}

const editTeamFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteTeamStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteTeamSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteTeamFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addTeamMembersStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
}

const addTeamMembersSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    updatedTeamMembers: action.updatedTeamMembers,
    error: null,
    loading: false
  });
}

const addTeamMembersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const removeTeamMembersStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
}

const removeTeamMembersSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    updatedTeamMembers: action.updatedTeamMembers,
    error: null,
    loading: false
  });
}

const removeTeamMembersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const changeTeamAdminStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const changeTeamAdminSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedTeam: action.updatedTeam,
    error: null,
    loading: false
  });
}

const changeTeamAdminFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const leaveTeamStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const leaveTeamSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const leaveTeamFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;