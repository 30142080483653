import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Machine.module.scss';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import Button from '../../components/UI/Button/Button';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import LoadingCard from '../../components/UI/LoadingSkeleton/LoadingCard/LoadingCard';
import MachineCard from '../../components/Machine/MachineCard/MachineCard';
import MachineDrawer from '../../components/Machine/MachineDrawer/MachineDrawer';

import { getDevices } from '../../../state/ducks/Devices/actions';
import { getFormulas, postFormulaMapping, deleteFormulaMapping } from '../../../state/ducks/Formula/actions';
import { DELETE_FORMULA_MAPPING_SUCCESS } from '../../../state/ducks/Formula/types';
import { getVirtualDevices } from '../../../state/ducks/VirtualDevice/actions';
import { getMachines, addMachine, editMachine, deleteMachine } from '../../../state/ducks/Machine/actions';
import { DELETE_MACHINE_SUCCESS } from '../../../state/ducks/Machine/types';

class Machine extends Component {
  state = {
    isDrawerOpened: false,
    isAdding: false,
    editingMachine: null,
    isDeleteModalOpened: false
  }

  componentDidMount() {
    const { machines, devices, virtualDevices, currentOrg } = this.props;
    if (!machines || !machines.length || machines[0].org_id !== currentOrg) {
      this.props.getMachines(currentOrg);
    }
    if (!devices || !devices.length || devices[0].org_id !== currentOrg) {
      this.props.getDevices(currentOrg, true);
    }
    if (!virtualDevices || !virtualDevices.length || virtualDevices[0].org_id !== currentOrg) {
      this.props.getVirtualDevices(this.props.currentOrg);
    }
    this.props.getFormulas(this.props.currentOrg);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.props.getMachines(this.props.currentOrg);
      this.props.getDevices(this.props.currentOrg, true);
      this.props.getVirtualDevices(this.props.currentOrg);
      this.props.getFormulas(this.props.currentOrg);
    } else {
      if (this.props.result !== prevProps.result && this.props.result === 'success') {
        if ((prevProps.addedMachine !== this.props.addedMachine) || (prevProps.updatedMachine !== this.props.updatedMachine)
          || [DELETE_MACHINE_SUCCESS].includes(this.props.type)) {
          this.props.getMachines(this.props.currentOrg);
        } else {
          // TODO: need to get deleted team index
          // case: delete
          // this.props.tags.splice(this.state.deletingIndex, 1);
        }
      }
      if (this.props.formulaMappingResult !== prevProps.formulaMappingResult && this.props.formulaMappingResult === 'success') {
        if (prevProps.updatedFormulaMapping !== this.props.updatedFormulaMapping && this.props.updatedFormulaMapping || this.props.formulaType === DELETE_FORMULA_MAPPING_SUCCESS) {
          this.props.getFormulas(this.props.currentOrg);
        }
      }
    }
  }

  drawerToggleHandler = () => {
    this.setState(prevState => {
      return { isDrawerOpened: !prevState.isDrawerOpened }
    });
  }

  openDrawerForAddingHandler = () => {
    this.setState({ isAdding: true, editingMachine: null, isDrawerOpened: true });
  }

  onEditMachineHandler = (index) => {
    this.setState({ isAdding: false, editingMachine: this.props.machines[index], isDrawerOpened: true });
  }

  handleDrawerFormSubmit = (form, formulaMappings, kpis) => {
    const { statusDevice, totalDevice, goodDevice, defectDevice, aFormula, pFormula, qFormula, devicesChildrenValue, efficiencyKPI, productionUsageTarget } = form;
    const devicesChildren = [];
    if (devicesChildrenValue && devicesChildrenValue.length)
      devicesChildrenValue.forEach(dcv => devicesChildren.push(dcv.secret + '/' + dcv.socket));

    let properties = {
      devicesChildren
    };
    if (efficiencyKPI) properties.efficiencyKPI = efficiencyKPI;
    if (productionUsageTarget) properties.productionUsageTarget = productionUsageTarget;
    if (kpis && kpis.length) {
      properties.kpis = [];
      kpis.forEach(kpi => {
        if (kpi.stype && kpi.kpiValue) {
          properties.kpis.push({
            stypeId: kpi.stype.id,
            kpiValue: kpi.kpiValue
          });
        }
      });
    }
    const statusRules = {};
    form.status.forEach((sr, i) => {
      statusRules[sr.value] = {
        id: i + 1,
        reason: sr.reason,
        color: sr.color.color,
        flashing: sr.color.flashing,
        calInRunTime: sr.calInRunTime,
        generateEvent: sr.generateEvent
      };
    });
    if (this.state.isAdding) {
      this.props.addMachine(
        form.name,
        this.props.currentOrg,
        statusDevice.secret,
        statusDevice.socket,
        statusRules,
        totalDevice ? totalDevice.secret : null,
        totalDevice ? totalDevice.socket : null,
        goodDevice ? goodDevice.secret : null,
        goodDevice ? goodDevice.socket : null,
        defectDevice ? defectDevice.secret : null,
        defectDevice ? defectDevice.socket : null,
        properties
      );
    } else {
      this.props.editMachine(
        this.state.editingMachine.id,
        form.name,
        statusDevice.secret,
        statusDevice.socket,
        statusRules,
        totalDevice ? totalDevice.secret : null,
        totalDevice ? totalDevice.socket : null,
        goodDevice ? goodDevice.secret : null,
        goodDevice ? goodDevice.socket : null,
        defectDevice ? defectDevice.secret : null,
        defectDevice ? defectDevice.socket : null,
        properties
      );
    }

    if (this.state.editingMachine) {
      const aRef = 'machine:' + this.state.editingMachine.id + ':a';
      const pRef = 'machine:' + this.state.editingMachine.id + ':p';
      const qRef = 'machine:' + this.state.editingMachine.id + ':q';
  
      if (aFormula) {
        this.props.postFormulaMapping(aFormula.id, aRef);
      } else {
        if (formulaMappings.find(fm => fm.reference === aRef))
          this.props.deleteFormulaMapping(aRef);
      }
  
      if (pFormula) {
        this.props.postFormulaMapping(pFormula.id, pRef);
      } else {
        if (formulaMappings.find(fm => fm.reference === pRef))
          this.props.deleteFormulaMapping(pRef);
      }
  
      if (qFormula) {
        this.props.postFormulaMapping(qFormula.id, qRef);
      } else {
        if (formulaMappings.find(fm => fm.reference === qRef))
          this.props.deleteFormulaMapping(qRef);
      }
    }
  }

  handleDeleteMachine = () => {
    this.setState({ isDeleteModalOpened: true });
  }

  deleteModalClickHandler = () => {
    this.setState(prevState => {
      return { isDeleteModalOpened: !prevState.isDeleteModalOpened }
    });
  }

  onConfirmDeleteDevice = () => {
    this.props.deleteMachine(this.state.editingMachine.id);
    this.setState({ isAdding: false, editingMachine: null, isDrawerOpened: false, isDeleteModalOpened: false });
  }


  render() {
    let isMobile = this.props.isMobile;

    return (
      <div className={styles.Machine}>
        <SideDrawer title={this.state.isAdding ? 'Add New Machine' : 'Edit Machine'} open={this.state.isDrawerOpened} closed={this.drawerToggleHandler} overrideStyles={{ width: 450 }}>
          <MachineDrawer
            devices={this.props.devices}
            virtualDevices={this.props.virtualDevices}
            formulas={this.props.formulas}
            isAdding={this.state.isAdding}
            currentOrg={this.props.currentOrg}
            editingMachine={this.state.editingMachine}
            closed={this.drawerToggleHandler}
            onSubmit={this.handleDrawerFormSubmit}
            toggleDeleteModal={this.deleteModalClickHandler}
          />
        </SideDrawer>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            <div className={styles.Menu}><span className={styles.MenuTitle}>All Machines</span></div>
          </div>
          <div className={styles.AddButtonWrapper}>
            <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Machine" noMargin click={this.openDrawerForAddingHandler} />
          </div>
        </div>
        <div className="MainContentPadding" style={isMobile ? { paddingTop: "20px" } : {}}>
          <div className={isMobile ? styles.CardGridMobileContainer : styles.CardGridContainer}>
            {
              this.props.loading ?
                <LoadingCard isMobile={isMobile} /> :
                <React.Fragment>
                  {isMobile ? null : <MachineCard addingCard click={this.openDrawerForAddingHandler} />}
                  {
                    this.props.machines && this.props.machines.sort((a, b) => a.id - b.id).map((machine, index) => {
                      return (
                        <MachineCard
                          key={`machine${machine.id}`}
                          machineIndex={index}
                          devices={this.props.devices}
                          virtualDevices={this.props.virtualDevices}
                          machineData={machine}
                          editingMachine={this.onEditMachineHandler}
                        // isMobile={isMobile}
                        />
                      );
                    })
                  }
                </React.Fragment>
            }
          </div>
        </div>
        <DeleteModal
          title="Machine"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeleteDevice}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { devices } = state.devices;
  const { formulas, updatedFormulaMapping, formulaMappingResult } = state.formula;
  const { virtualDevices } = state.virtualDevice;
  const { type, result, machines, addedMachine, updatedMachine, loading } = state.machine;
  return {
    currentOrg, type, result, machines, addedMachine, updatedMachine, loading,
    devices, virtualDevices, formulas, updatedFormulaMapping, formulaMappingResult,
    formulaType: state.formula.type
  };
};

export default connect(mapStateToProps, { getMachines, getDevices, addMachine, editMachine, deleteMachine, getVirtualDevices, getFormulas, postFormulaMapping, deleteFormulaMapping })(Machine);
