import * as actionTypes from './types';

// ----------------------- GET OEE -----------------------
export const getOeesStart = () => {
  return {
    type: actionTypes.GET_OEES_START
  };
}

export const getOees = (org_id, productionplan_id, shift_id, countingdevices_id) => {
  return {
    type: actionTypes.GET_OEES,
    org_id, productionplan_id, shift_id, countingdevices_id
  };
}

export const getOeesSuccess = (response) => {
  return {
    type: actionTypes.GET_OEES_SUCCESS,
    oees: response.reports
  };
}

export const getOeesFail = (error) => {
  return {
    type: actionTypes.GET_OEES_FAIL,
    error: error
  };
}

// ----------------------- ADD OEE -----------------------
export const addOeeStart = () => {
  return {
    type: actionTypes.ADD_OEE_START
  };
}

export const addOee = (org_id, productionplan_id, actual, defect, runtime, description) => {
  return {
    type: actionTypes.ADD_OEE,
    org_id, productionplan_id, actual, defect, runtime, description
  };
}

export const addOeeSuccess = (response) => {
  return {
    type: actionTypes.ADD_OEE_SUCCESS,
    addedOee: response.report,
    result: response.result
  };
}

export const addOeeFail = (error) => {
  return {
    type: actionTypes.ADD_OEE_FAIL,
    error: error
  };
}

// ----------------------- EDIT OEE -----------------------
export const editOeeStart = () => {
  return {
    type: actionTypes.EDIT_OEE_START
  };
}

export const editOee = (id, actual, defect, runtime, description) => {
  return {
    type: actionTypes.EDIT_OEE,
    id, actual, defect, runtime, description
  };
}

export const editOeeSuccess = (response) => {
  return {
    type: actionTypes.EDIT_OEE_SUCCESS,
    updatedOee: response.report,
    result: response.result
  };
}

export const editOeeFail = (error) => {
  return {
    type: actionTypes.EDIT_OEE_FAIL,
    error: error
  };
}

// ----------------------- DELETE OEE -----------------------
export const deleteOeeStart = () => {
  return {
    type: actionTypes.DELETE_OEE_START
  };
}

export const deleteOee = (id) => {
  return {
    type: actionTypes.DELETE_OEE,
    id: id
  };
}

export const deleteOeeSuccess = (result) => {
  return {
    type: actionTypes.DELETE_OEE_SUCCESS,
    result: result
  };
}

export const deleteOeeFail = (error) => {
  return {
    type: actionTypes.DELETE_OEE_FAIL,
    error: error
  };
}


// ----------------------- OEE APPROVE -----------------------
export const oeeApproveStart = () => {
  return {
    type: actionTypes.OEE_APPROVE_START
  };
}

export const oeeApprove = (id) => {
  return {
    type: actionTypes.OEE_APPROVE,
    id: id
  };
}

export const oeeApproveSuccess = (result) => {
  return {
    type: actionTypes.OEE_APPROVE_SUCCESS,
    result: result
  };
}

export const oeeApproveFail = (error) => {
  return {
    type: actionTypes.OEE_APPROVE_FAIL,
    error: error
  };
}


// export default {
//   getOees,
//   getOeesStart,
//   getOeesSuccess,
//   getOeesFail,
//   addOeeStart,
//   addOee,
//   addOeeSuccess,
//   addOeeFail,
//   editOeeStart,
//   editOee,
//   editOeeSuccess,
//   editOeeFail,
//   deleteOeeStart,
//   deleteOee,
//   deleteOeeSuccess,
//   deleteOeeFail,
//   oeeApproveStart,
//   oeeApprove,
//   oeeApproveSuccess,
//   oeeApproveFail
// };