import React, { Component } from 'react'
import moment from 'moment';

import styles from '../Report.module.scss';
import ViewIconDark from '../../../../assets/icons/Report/view-dark.svg';
import Button from '../../UI/Button/Button';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import EditHoverIcon from '../../../../assets/icons/edit.svg';
import DeleteRedBin from '../../../../assets/icons/delete-red-bin.svg';
import PresetData from '../../../../assets/images/Report/preset_data.png'
import PresetPareto from '../../../../assets/images/Report/preset_pareto.png'

class PresetCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardName: '',
            lastUpdated: ''
        }
    }

    componentDidMount() {
        let updatedDate = new Date(this.props.lastUpdated);
        this.setState({
            cardName: this.props.cardName,
            // lastUpdated: updatedDate.toLocaleString()
            lastUpdated: moment(updatedDate).format("DD/MM/YYYY, HH:mm")
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.cardName !== this.props.cardName) {
            this.setState({
                cardName: this.props.cardName,
            })
        }
        if (prevProps.lastUpdated !== this.props.lastUpdated) {
            let updatedDate = new Date(this.props.lastUpdated);
            this.setState({
                // lastUpdated: updatedDate.toLocaleString(),
                lastUpdated: moment(updatedDate).format("DD/MM/YYYY, HH:mm")
            })
        }
    }

    render() {
        const { loggedInUser, currentOrg, createdUser, presetType } = this.props;
        const { cardName, lastUpdated } = this.state;

        return (
            <div className={styles.PresetCard}>
                <div className={styles.PresetPictureWrapper}>
                    <div
                        style={{ position: 'relative', width: '80px' }}
                    >
                        <img
                            src={presetType === 'data' ? PresetData : PresetPareto}
                            alt={`pic`}
                            className={styles.PresetPicture}
                        />
                        {
                            createdUser.profile_picture && <img
                                src={createdUser.profile_picture}
                                alt="Control Icon"
                                className={styles.ControlIcon}
                            />
                        }
                    </div>
                </div>
                <div className={styles.CardDetail}>
                    <p className={styles.PresetName}>{cardName}</p>
                    <p className={styles.PresetInformation}>Last Updated: {lastUpdated}</p>
                </div>
                <div className={styles.ActionButtons}>
                    {
                        (loggedInUser.is_superuser || loggedInUser.admin_of_org.includes(currentOrg) || loggedInUser.id === createdUser.id)
                        &&
                        <>
                            <img
                                style={{ cursor: 'pointer' }}
                                src={DeleteRedBin}
                                // onMouseOver={e => (e.currentTarget.src = EditHoverIcon)}
                                // onMouseOut={e => (e.currentTarget.src = EditIcon)}
                                alt="delete preset report"
                                onClick={() => this.props.deletePresetHandler()}
                            />
                            <img
                                style={{ marginRight: 18, cursor: 'pointer' }}
                                src={EditIcon}
                                onMouseOver={e => (e.currentTarget.src = EditHoverIcon)}
                                onMouseOut={e => (e.currentTarget.src = EditIcon)}
                                alt="edit preset report"
                                onClick={this.props.editPresetHandler}
                            />
                        </>
                    }
                </div>
                <div className={styles.ViewPresetButton}>
                    <Button type="submit" icon={ViewIconDark} color="white" name="View" noMargin click={this.props.viewPresetHandler} />
                </div>
            </div>
        )
    }
}

export default PresetCard;