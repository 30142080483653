import { React, Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './RawData.module.scss';
import sysStyles from '../SystemDeviceConfig.module.scss';
import { getDataLatest } from '../../../../../state/ducks/Data/actions';

class RawData extends Component {
    state = {
        data: {},
        timeout: undefined
    }

    componentDidMount = () => {
        this.initData();
    }

    componentWillUnmount = () => {
        if (this.state.timeout === undefined)
            clearTimeout(this.state.timeout);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.props.open) {
            if (this.state.timeout === undefined)
                clearTimeout(this.state.timeout);
            return;
        } else if (prevProps.open !== this.props.open) {
            this.initData();
        };

        if (prevProps.editingDevice !== this.props.editingDevice) {
            this.initData();
        } else if (this.props.dataLatest &&
            prevProps.dataLatest !== this.props.dataLatest &&
            this.props.dataLatest.data?.length > 0 &&
            this.props.dataLatest.properties.secret === this.props.editingDevice.secret) {
            const { dataLatest } = this.props;
            const { data } = this.state;
            if (data[dataLatest.properties.socket] && dataLatest.data.length > 0) {
                data[dataLatest.properties.socket].value = dataLatest.data[0].value;
                data[dataLatest.properties.socket].time = new Date(dataLatest.data[0].timestamp);
                this.setState({ data });
            }
        }
    }

    initData = () => {
        const { editingDevice } = this.props;
        let data = {};
        editingDevice?.device_sockets.forEach(socket => {
            if (socket.enable) {
                data[socket.socket] = {
                    name: socket.name,
                    value: 0,
                    time: null
                };
            }
        });
        this.setState({ data });
        this.reloadData();
    }

    reloadData = () => {
        const { data } = this.state;
        const { editingDevice, open } = this.props;
        if (!open) return;

        Object.keys(data).forEach(socket => {
            this.props.getDataLatest(socket, editingDevice.secret);
        });

        let t = setTimeout(this.reloadData, 10000);
        this.setState({ timeout: t });
    }

    render() {
        const { data, timeout } = this.state;
        const { open } = this.props;

        if (!open) {
            return null;
        }

        return <>
            <div className={sysStyles.Label}>Device's raw data</div>
            <div className={styles.BodySection}>
                {
                    Object.keys(data).map(socketID => {
                        return (
                            <div key={"Socket-Data-" + socketID} className={styles.DataCard}>
                                <div className={styles.DataCardName}>({socketID}) {data[socketID].name}</div>
                                <div className={styles.DataCardValue}>{data[socketID].value}</div>
                                <div className={styles.DataCardTime}>{data[socketID].time ? moment(data[socketID].time).format("DD/MM/YYYY HH:mm:ss") : "loading..."}</div>
                            </div>
                        )
                    })
                }
            </div>
        </>;
    }
}

const mapStateToProps = (state) => {
    const { dataLatest } = state.data;
    return { dataLatest };
};

export default connect(mapStateToProps, { getDataLatest })(RawData);