import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from '../Profile.module.scss';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

import { checkValidity, updateObject } from '../../../../state/utils';
import { editUser } from '../../../../state/ducks/Auth/actions';

const DEFAULT_BASIC_PROFILE_FORM = {
  first_name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  last_name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  email: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true,
      isEmail: true
    }
  },
  tel: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  job_title: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  }
};

class BasicProfileForm extends Component {
  state = {
    editType: 'basic',
    formIsValid: false,
    profileForm: DEFAULT_BASIC_PROFILE_FORM
  }

  componentDidMount() {
    this.setupForm(this.props.loggedInUser);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.updatedUser !== this.props.updatedUser) {
      this.setupForm(this.props.updatedUser);
    }
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.profileForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
      });

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
      this.setState({ profileForm: updatedForm, formIsValid: true });
    }
  }

  handleTextChange = event => {
    let updatedElement = updateObject(this.state.profileForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.profileForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.profileForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ profileForm: updatedForm, formIsValid });
  };

  onEditProfileHandler = event => {
    event.preventDefault();
    let { first_name, last_name, tel, job_title } = this.state.profileForm;
    if (this.state.formIsValid) {
      let newProfilePicture = this.props.loggedInUser.profile_picture;
      if (newProfilePicture.includes("ui-avatars.com")) {
        // 'https://ui-avatars.com/api/?background=' + COLORS[random] + '&color=fff&rounded=true&name=' + first_name.value + '+' + last_name.value;
        newProfilePicture = newProfilePicture.split("name=")[0];
        newProfilePicture += "name=" + first_name.value + '+' + last_name.value; 
      }
      this.props.editUser(this.props.loggedInUser.id, this.state.editType, first_name.value, last_name.value, tel.value, job_title.value, newProfilePicture);
    }
  }

  render() {
    let { first_name, last_name, email, tel, job_title } = this.state.profileForm;
    return (
      <div className={styles.ProfileContainer} style={{ marginTop: 30 }} >
        <div className={styles.Header}>
          <div className={styles.Title}>Basic Profile</div>
          <div className={styles.SubTitle}>This information can be edited from your profile page.</div>
        </div>
        <form onSubmit={this.onEditProfileHandler}>
          <div className={styles.FormInput}>

            <div style={{ display: 'flex' }}>
              <div className={styles.FormGroup} style={{ marginRight: 15 }}>
                <Input
                  label="first name"
                  type="text"
                  name="first_name"
                  value={first_name.value}
                  onChange={this.handleTextChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                  error={`${first_name.touched && !first_name.valid ? 'First name is required' : ''}`}
                />
              </div>
              <div className={styles.FormGroup}>
                <Input
                  label="last name"
                  type="text"
                  name="last_name"
                  value={last_name.value}
                  onChange={this.handleTextChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                  error={`${last_name.touched && !last_name.valid ? 'Last name is required' : ''}`}
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div className={styles.FormGroup} style={{ marginRight: 15 }}>
                <label className={styles.Title}>EMAIL ADDRESS</label>
                <input
                  className={styles.FormField}
                  value={email.value}
                  onChange={this.handleTextChange}
                  name="email"
                  type="text"
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className={styles.FormGroup}>
                <Input
                  label="phone number"
                  type="text"
                  name="tel"
                  value={tel.value}
                  onChange={this.handleTextChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                  error={`${tel.touched && !tel.valid ? 'Phone number is required' : ''}`}
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div className={styles.FormGroup} style={{ marginRight: 15 }}>
                <Input
                  label="job title"
                  type="text"
                  name="job_title"
                  value={job_title.value}
                  onChange={this.handleTextChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                />
              </div>
              <div className={styles.FormGroup}>
              </div>
            </div>
          </div>

          <div className={styles.Footer}>
            <div className={styles.ButtonWrapper}>
              <Button name={this.props.loading && this.props.editType === 'basic' ? "Loading..." : "Save Settings"} loading={this.props.loading && this.props.editType === 'basic'} color="green" type="submit" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser, loading, error, updatedUser, editType } = state.auth;
  return { loggedInUser, loading, error, updatedUser, editType };
};

export default connect(mapStateToProps, { editUser })(BasicProfileForm);