import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';

import { updateObject } from '../../../../../state/utils';
import { getDataCal, getDatasetCal, getData } from '../../../../../state/ducks/Data/actions';
import { calculateNewValFromEquation } from '../../../../../state/utils';

const COLORS = ['rgba(75,192,192,1)', '#2196F3', '#ff5454', '#FFA000'];

let stop = new Date().getTime();
let start = stop - 60 * 1000;


class LineChart extends Component {
  state = {
    widgetChannels: [],
    timeStamps: [],
    chartData: null,
    newDataCal: [],
    dataCalCount: 0,
    lineInterval: [],
    countInterval: null,
    options: {
      elements: {
        point: { radius: 0 }
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      responsive: true,
      animation: false,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          display: true
        }],
        yAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: ''
          },
          ticks: {
            // min: 0
          }
        }]
      }
    }
  };

  componentDidMount() {
    this.setupChannelsAndDatasets(this.props.widget, this.props.deviceOptions);
    this.setUpGetDataInterval(this.props.widget);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!nextProps.widget.range) {
      if (nextProps.widget && nextProps.data && nextProps.data.values.length > 0) {
        const ch = '/' + nextProps.data.properties.secret + '/' + nextProps.data.properties.socket;
        if (this.state.widgetChannels.includes(ch)) {
          return true;
        }
        return false;
      }
    } else {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.widget.range && this.state.chartData && this.props.data) {
      const ch = '/' + this.props.data.properties.secret + '/' + this.props.data.properties.socket;
      if (this.state.widgetChannels.includes(ch) && !_.isEqual(prevProps.data, this.props.data)) {
        this.newPush(this.props.data.values, ch);
      }
    }

    if (prevState.chartData !== this.state.chartData && this.state.chartData && !prevState.chartData) {
      if (this.props.widget.range) {
        this.getDatasetCals(this.props.widget.range);
      }
    }

    if (prevProps.datasetCal !== this.props.datasetCal && this.props.datasetCal && this.state.chartData) {
      if (this.props.datasetCal.id === this.props.id) {
        let dataCalCount = this.state.dataCalCount;
        let newDataCal = [...this.state.newDataCal];
        dataCalCount++;
        newDataCal.push(this.props.datasetCal);
        this.setState({ dataCalCount, newDataCal });

        if (dataCalCount === this.state.widgetChannels.length) {
          this.pushDatasetCalToTheChart(newDataCal);
          this.getDataCals(this.props.datasetCal.lastTimeStamp, this.props.widget.range);
        }
      }
    } else if (prevProps.dataCal !== this.props.dataCal && this.props.dataCal && this.state.chartData) {
      if (this.props.dataCal.id === this.props.id) {
        let dataCalCount = this.state.dataCalCount;
        let newDataCal = [...this.state.newDataCal];
        dataCalCount++;
        newDataCal.push(this.props.dataCal);
        this.setState({ dataCalCount, newDataCal });

        if (dataCalCount === this.state.widgetChannels.length) {
          this.pushDatasetCalToTheChart(newDataCal);
        }
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.state.lineInterval.forEach((li, i) => {
      clearInterval(li);
    });
  }

  setUpGetDataInterval = (widget) => {
    const lineInterval = [];
    widget.secret.forEach((w, i) => {
      if (!widget.range || Object.keys(widget.range).length === 0) {
        lineInterval[i] = setInterval(() => {
          stop = Date.now();
          start = stop - 60 * 1000;
          this.props.getData(null, null, start, stop, w, widget.socket[i]);
        }, 1000);
      }
    });

    this.setState({ lineInterval });
  }

  setupChannelsAndDatasets = (widget, deviceOptions) => {
    let newWidgetChannels = [...this.state.widgetChannels];
    let chartData = {
      labels: [],
      datasets: []
    };
    let isSameUnit = true;
    const checkedUnit = widget.unit[0];
    widget.secret.forEach((se, i) => {
      let device = deviceOptions.find(device => device.secret === widget.secret[i] && device.socket === widget.socket[i]);
      newWidgetChannels.push('/' + widget.secret[i] + '/' + widget.socket[i]);

      if (checkedUnit !== widget.unit[i]) {
        isSameUnit = false;
      }

      chartData.datasets.push({
        label: device ? device.name : '',
        data: [],
        fill: 'origin',
        lineTension: 0.1,
        borderColor: COLORS[i % 4],
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: COLORS[i % 4],
        pointBackgroundColor: COLORS[i % 4],
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: COLORS[i % 4],
        pointHoverBorderColor: COLORS[i % 4],
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      });
    });

    let options = { ...this.state.options };
    if (widget.secret.length === 1 || (widget.secret.length > 1 && isSameUnit)) {
      options = {
        elements: {
          point: { radius: 0 }
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data, more) {
              let datasets = [...data.datasets];
              const colors = datasets ? datasets[+tooltipItem.datasetIndex]?.colorsText : null;
              if (colors) {
                const found = colors.find(c => c.point === datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
                if (found) {
                  return datasets[tooltipItem.datasetIndex].label + ': ' + found.displayedText;
                }
              }
              return datasets[tooltipItem.datasetIndex].label + ': ' + datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: ''
            },
            ticks: {
              callback: function (value, index, values) {
                return value + ' ' + widget.unit[0];
              }
            }
          }]
        }
      }
    }

    if (this.props.combiningWidget) {
      let combiningWidgetDS = {
        label: this.props.combiningWidget.name,
        borderColor: '',
        backgroundColor: '',
        fill: false,
        data: []
      };
      if (this.props.combiningWidget.lampProps) {
        combiningWidgetDS.colorsText = this.props.combiningWidget.lampProps.colorTriggers;
      }
      newWidgetChannels.push('/' + this.props.combiningWidget.secret + '/' + this.props.combiningWidget.socket);
      chartData.datasets.push(combiningWidgetDS);
    }

    if (widget.static_value) {
      chartData.datasets.push({
        label: 'Static',
        borderColor: 'rgb(255,0,0)',
        backgroundColor: 'rgb(255,0,0)',
        fill: false,
        data: []
      });
    }

    this.setState({ widgetChannels: newWidgetChannels, chartData, options });
  }

  newPush = (data, channel) => {
    const updatedDS = [...this.state.chartData.datasets];
    const updatedLabels = [];
    const { equation, static_value } = this.props.widget;
    let indexToPushNewValue = this.state.widgetChannels.findIndex(ch => ch === channel);

    updatedDS[indexToPushNewValue].data = [];
    data.forEach(d => {
      updatedDS[indexToPushNewValue].data.push(+calculateNewValFromEquation(d.value, equation[indexToPushNewValue]));
      updatedLabels.push(moment(d.timestamp).format('HH:mm:ss'));
      if (static_value) {
        updatedDS[updatedDS.length - 1].data.push(+static_value);
      }
    });

    const updatedData = updateObject(this.state.chartData, {
      labels: updatedLabels,
      datasets: updatedDS
    });

    this.setState({ chartData: updatedData });
  }

  // DP ?
  pushDataToTheChart = (dataset) => {
    const timeStamps = [...this.state.timeStamps];
    const updatedDS = [...this.state.chartData.datasets];
    const updatedLabels = [...this.state.chartData.labels];
    const { equation, static_value } = this.props.widget;
    let indexToPushNewValue = this.state.widgetChannels.findIndex(channel => channel === dataset.channel);
    let pushCount;

    if (indexToPushNewValue !== -1) {
      const newData = +calculateNewValFromEquation(dataset.value, equation[indexToPushNewValue]);
      if (!timeStamps.length && !updatedDS[indexToPushNewValue].data.length) {
        // First push data to line chart
        updatedDS[indexToPushNewValue].data.push(newData);
        if (static_value) {
          updatedDS[updatedDS.length - 1].data.push(+static_value);
        }
        timeStamps.push(dataset.timestamp);
        updatedLabels.push(moment(dataset.timestamp).format('HH:mm:ss'));
      } else {
        const timeDiff = dataset.timestamp - timeStamps[timeStamps.length - 1];
        let newUpdatedDS = null;
        if (timeDiff > 2000) {
          if (timeDiff <= 3000) {
            pushCount = 1;
            updatedDS[indexToPushNewValue].data.push(null);
            updatedDS[indexToPushNewValue].data.push(newData);
            newUpdatedDS = this.calculateAverageData([...updatedDS[indexToPushNewValue].data], updatedDS[indexToPushNewValue].data.length - 1);

          } else if (timeDiff <= 4000) {
            pushCount = 2;
            updatedDS[indexToPushNewValue].data.push(null);
            updatedDS[indexToPushNewValue].data.push(null);
            updatedDS[indexToPushNewValue].data.push(newData);
            newUpdatedDS = this.calculateAverageData([...updatedDS[indexToPushNewValue].data], updatedDS[indexToPushNewValue].data.length - 1);
          } else if (timeDiff <= 5000) {
            pushCount = 3;
            updatedDS[indexToPushNewValue].data.push(null);
            updatedDS[indexToPushNewValue].data.push(null);
            updatedDS[indexToPushNewValue].data.push(null);
            updatedDS[indexToPushNewValue].data.push(newData);
            newUpdatedDS = this.calculateAverageData([...updatedDS[indexToPushNewValue].data], updatedDS[indexToPushNewValue].data.length - 1);
          } else if (timeDiff <= 6000) {
            pushCount = 4;
            updatedDS[indexToPushNewValue].data.push(null);
            updatedDS[indexToPushNewValue].data.push(null);
            updatedDS[indexToPushNewValue].data.push(null);
            updatedDS[indexToPushNewValue].data.push(null);
            updatedDS[indexToPushNewValue].data.push(newData);
            newUpdatedDS = this.calculateAverageData([...updatedDS[indexToPushNewValue].data], updatedDS[indexToPushNewValue].data.length - 1);
          } else if (timeDiff > 7000) {
            pushCount = parseInt(timeDiff / 1000 - 1);
            newUpdatedDS = null;
          }

        } else {
          pushCount = 0;
          updatedDS[indexToPushNewValue].data.push(newData);
          newUpdatedDS = this.calculateAverageData([...updatedDS[indexToPushNewValue].data], updatedDS[indexToPushNewValue].data.length - 1);
        }

        if (newUpdatedDS) {
          updatedDS[indexToPushNewValue].data = [...newUpdatedDS];
        }
        for (let count = pushCount; count >= 0; count--) {
          let timeStampToPush = dataset.timestamp + (count * -1000);
          timeStamps.push(timeStampToPush);

          if (pushCount >= 5) {
            if (count === 0) {
              updatedDS[indexToPushNewValue].data.push(newData);
            } else {
              updatedDS[indexToPushNewValue].data.push(null);
            }
          }

          updatedLabels.push(moment(timeStampToPush).format('HH:mm:ss'));

          if (static_value) {
            updatedDS[updatedDS.length - 1].data.push(+static_value);
          }

          if (timeStamps.length > 60) {
            timeStamps.splice(0, 1);
            updatedLabels.splice(0, 1);
            if (static_value) {
              updatedDS[updatedDS.length - 1].data.splice(0, 1);
            }
          }

          if (updatedDS[indexToPushNewValue].data.length > 60) {
            updatedDS[indexToPushNewValue].data.splice(0, 1);
            for (let lineIndex = 0; lineIndex < this.state.widgetChannels.length; lineIndex++) {
              if (lineIndex !== indexToPushNewValue) {
                updatedDS[lineIndex].data.push(null);
                updatedDS[lineIndex].data.splice(0, 1);
              }
            }
          } else {
            for (let lineIndex = 0; lineIndex < this.state.widgetChannels.length; lineIndex++) {
              if (lineIndex !== indexToPushNewValue) {
                updatedDS[lineIndex].data.push(null);
              }
            }
          }
        }
      }
    }

    const updatedData = updateObject(this.state.chartData, {
      labels: updatedLabels,
      datasets: updatedDS
    });

    this.setState({ timeStamps, chartData: updatedData });
  }

  // DP ?
  fillDataToTheChart = (dataset, timeIndex) => {
    const { equation } = this.props.widget;
    const updatedDS = [...this.state.chartData.datasets];
    const updatedLabels = [...this.state.chartData.labels];

    if (timeIndex) {
      let index = this.state.widgetChannels.findIndex(channel => channel === dataset.channel);

      if (index !== -1) {
        let updatedDataInDS = [...this.state.chartData.datasets[index].data];
        updatedDataInDS[timeIndex] = +calculateNewValFromEquation(dataset.value, equation[index]);

        let newUpdatedDS = this.calculateAverageData([...updatedDataInDS], timeIndex);
        if (!_.isEqual(updatedDataInDS, newUpdatedDS)) {
          updatedDataInDS = [...newUpdatedDS];
        }

        updatedDS[index] = updateObject(this.state.chartData.datasets[index], {
          data: updatedDataInDS
        });

        const updatedData = updateObject(this.state.chartData, {
          labels: updatedLabels,
          datasets: updatedDS
        });

        this.setState({ chartData: updatedData });
      }
    }
  }

  getDatasetCals = (widgetRange) => {
    let now = new Date();
    now.setMilliseconds(0);
    now.setSeconds(0);
    let startTime = now.getTime() - (+widgetRange.range * 1000);

    for (let index = 0; index < this.props.widget.secret.length; index++) {
      this.props.getDatasetCal(
        this.props.id,
        widgetRange.calculate,
        this.props.widget.secret[index],
        this.props.widget.socket[index],
        +widgetRange.summarize,
        startTime,
        now.getTime()
      );
    }

    if (this.props.combiningWidget) {
      this.props.getDatasetCal(
        this.props.id,
        'mode',
        this.props.combiningWidget.secret,
        this.props.combiningWidget.socket,
        +widgetRange.summarize,
        startTime,
        now.getTime()
      );
    }
  }

  getDataCals = (time, widgetRange) => {
    const summarizeTime = (+widgetRange.summarize * 1000);
    let startTime = time - summarizeTime;
    let stopTime = time;

    this.timer = setInterval(() => {
      startTime = startTime + summarizeTime;
      stopTime = stopTime + summarizeTime;
      for (let index = 0; index < this.props.widget.secret.length; index++) {
        this.props.getDataCal(
          this.props.id,
          this.props.widget.secret[index],
          this.props.widget.socket[index],
          startTime,
          stopTime,
          widgetRange.calculate
        );
      }
      if (this.props.combiningWidget) {
        this.props.getDataCal(
          this.props.id,
          this.props.combiningWidget.secret,
          this.props.combiningWidget.socket,
          startTime,
          stopTime,
          'mode'
        );
      }
    }, summarizeTime);
  }

  pushDatasetCalToTheChart = (datasetCal) => {
    const timeStamps = [...this.state.timeStamps];
    const updatedDS = [...this.state.chartData.datasets];
    const updatedLabels = [...this.state.chartData.labels];
    const { equation, static_value } = this.props.widget;

    datasetCal.forEach((dataset, index) => {
      let indexToPushNewValue = this.state.widgetChannels.findIndex(channel => channel === '/' + dataset.secret + '/' + dataset.socket);
      let updatedDataInDS = [...this.state.chartData.datasets[indexToPushNewValue].data];

      if (dataset.values) {
        dataset.values.forEach(dataValue => {
          if (index === 0) {
            timeStamps.push(dataValue.timestamp);
            updatedLabels.push(moment(dataValue.timestamp).format('HH:mm:ss'));
            if (static_value) {
              updatedDS[updatedDS.length - 1].data.push(+static_value);
            }
          }
          updatedDataInDS.push(+calculateNewValFromEquation(dataValue.value, equation[indexToPushNewValue]));
        });
      } else {
        if (index === 0) {
          timeStamps.push(dataset.timestamp);
          timeStamps.splice(0, 1);
          updatedLabels.push(moment(dataset.timestamp).format('HH:mm:ss'));
          updatedLabels.splice(0, 1);
          if (static_value) {
            updatedDS[updatedDS.length - 1].data.push(+static_value);
            updatedDS[updatedDS.length - 1].data.splice(0, 1);
          }
        }
        updatedDataInDS.push(+calculateNewValFromEquation(dataset.value, equation[indexToPushNewValue]));
        updatedDataInDS.splice(0, 1);
      }

      updatedDS[indexToPushNewValue] = updateObject(this.state.chartData.datasets[indexToPushNewValue], {
        data: updatedDataInDS
      });
    });

    const updatedData = updateObject(this.state.chartData, {
      labels: updatedLabels,
      datasets: updatedDS
    });

    this.setState({ timeStamps, chartData: updatedData, dataCalCount: 0, newDataCal: [] });
  }

  calculateAverageData(datas, lastIndex) {
    if (datas[lastIndex] || datas[lastIndex] === 0) {
      if (datas[lastIndex - 1] || datas[lastIndex - 1] === 0) {
        // datas = datas;
      } else if (datas[lastIndex - 2] || datas[lastIndex - 2] === 0) {
        datas[lastIndex - 1] = +((datas[lastIndex - 2] + datas[lastIndex]) / 2).toFixed(2);
      } else if (datas[lastIndex - 3] || datas[lastIndex - 3] === 0) {
        datas[lastIndex - 1] = +((datas[lastIndex - 3] + datas[lastIndex]) / 2).toFixed(2);
        datas[lastIndex - 2] = +((datas[lastIndex - 3] + datas[lastIndex]) / 2).toFixed(2);
      } else if (datas[lastIndex - 4] || datas[lastIndex - 4] === 0) {
        datas[lastIndex - 2] = +((datas[lastIndex - 4] + datas[lastIndex]) / 2).toFixed(2);
        datas[lastIndex - 3] = +((datas[lastIndex - 4] + datas[lastIndex - 2]) / 2).toFixed(2);
        datas[lastIndex - 1] = +((datas[lastIndex - 2] + datas[lastIndex]) / 2).toFixed(2);
      } else if (datas[lastIndex - 5] || datas[lastIndex - 5] === 0) {
        datas[lastIndex - 1] = +((datas[lastIndex - 5] + datas[lastIndex]) / 2).toFixed(2);
        datas[lastIndex - 2] = +((datas[lastIndex - 5] + datas[lastIndex]) / 2).toFixed(2);
        datas[lastIndex - 3] = +((datas[lastIndex - 5] + datas[lastIndex]) / 2).toFixed(2);
        datas[lastIndex - 4] = +((datas[lastIndex - 5] + datas[lastIndex]) / 2).toFixed(2);
      }
    }
    return datas;
  }

  render() {
    return (
      <React.Fragment>
        {
          (this.props.loading && this.props.loadingId === this.props.id) || !this.state.chartData ?
            <div style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>Loading...</div>
            :
            <div style={{ width: '99%', position: 'relative', marginTop: 20 }}>
              <Line key={`linechart-${this.props.widget.id}`} data={this.state.chartData} height={215} options={this.state.options} />
            </div>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { data, dataCal, datasetCal, loading, loadingId } = state.data;
  return { data, dataCal, datasetCal, loading, loadingId };
};

export default connect(mapStateToProps, {
  getData,
  getDataCal,
  getDatasetCal,
})(LineChart);