import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';

import rootReducer, { history } from "./ducks";
import jwtMiddleware from '../middlewares/jwtMiddleware';
import { watchAuth } from './ducks/Auth/sagas';
import { watchPages } from './ducks/Pages/sagas';
import { watchWidget } from './ducks/Widgets/sagas';
import { watchDevices } from './ducks/Devices/sagas';
import { watchDeviceSockets } from './ducks/DeviceSocket/sagas';
import { watchDeviceConfigs } from './ducks/DeviceConfig/sagas';
import { watchMembers } from './ducks/Members/sagas';
import { watchRules } from './ducks/Rule/sagas';
import { watchRuleCommands } from './ducks/RuleCommand/sagas';
import { watchRuleConditions } from './ducks/RuleCondition/sagas';
import { watchData } from './ducks/Data/sagas';
import { watchTeam } from './ducks/Team/sagas';
import { watchOrg } from './ducks/Organization/sagas';
import { watchProductionPlans } from './ducks/ProductionPlan/sagas';
import { watchShift } from './ducks/Shift/sagas';
import { watchCountingDevices } from './ducks/CountingDevices/sagas';
import { watchBreakdown } from './ducks/Breakdown/sagas';
import { watchOee } from './ducks/OEE/sagas';
import { watchUserFavorite } from './ducks/UserFavorite/sagas';
import { watchChatroom } from './ducks/ChatRoom/sagas';
import { watchExport } from './ducks/Export/sagas';
import { watchCommand } from './ducks/Command/sagas';
import { watchTimelines } from './ducks/Timeline/sagas';
import { watchTimelineStatus } from './ducks/TimelineStatus/sagas';
import { watchHooks } from './ducks/Hook/sagas';
import { watchMachine } from './ducks/Machine/sagas';
import { watchReportPreset } from './ducks/ReportPreset/sagas';
import { watchSequentialCommand } from './ducks/SequentialCommand/sagas';
import { watchVirtualDevices } from './ducks/VirtualDevice/sagas';
import { watchPlanStructures } from './ducks/AdvanceProductionPlanStructure/sagas';
import { watchAdvanceProductionPlans } from './ducks/AdvanceProductionPlan/sagas';
import { watchProductionBatchs } from './ducks/AdvanceProductionBatch/sagas';
import { watchActualProduction } from './ducks/AdvanceActualProduction/sagas';
import { watchEvents } from './ducks/Event/sagas';
import { watchDocMasterDatas } from './ducks/DocumentMasterData/sagas';
import { watchDocuments } from './ducks/Document/sagas';
import { watchAdmin } from './ducks/Admin/sagas';
import { watchErrorLogs } from './ducks/ErrorLog/sagas';
import { watchModuleConfigs } from './ducks/Module/sagas';
import { watchNotifications } from './ducks/Notification/sagas';
import { watchScheduler } from './ducks/Scheduler/sagas';
import { watchFormulas } from './ducks/Formula/sagas';
import { watchVariables } from './ducks/Variable/sagas';
import { watchDeviceGroupings } from './ducks/DeviceGrouping/sagas';
import { watchPower } from './ducks/Power/sagas';

const composeEnhancers = process.env.NODE_ENV === 'development' ?
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose) : compose;

export default function configureStore(initialState = {}) {

  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(routerMiddleware(history), jwtMiddleware, thunk, sagaMiddleware)
    )
  );

  sagaMiddleware.run(watchAuth);
  sagaMiddleware.run(watchPages);
  sagaMiddleware.run(watchWidget);
  sagaMiddleware.run(watchDevices);
  sagaMiddleware.run(watchDeviceSockets);
  sagaMiddleware.run(watchDeviceConfigs);
  sagaMiddleware.run(watchMembers);
  sagaMiddleware.run(watchRules);
  sagaMiddleware.run(watchRuleCommands);
  sagaMiddleware.run(watchRuleConditions);
  sagaMiddleware.run(watchData);
  sagaMiddleware.run(watchTeam);
  sagaMiddleware.run(watchOrg);
  sagaMiddleware.run(watchProductionPlans);
  sagaMiddleware.run(watchShift);
  sagaMiddleware.run(watchCountingDevices);
  sagaMiddleware.run(watchBreakdown);
  sagaMiddleware.run(watchOee);
  sagaMiddleware.run(watchUserFavorite);
  sagaMiddleware.run(watchChatroom);
  sagaMiddleware.run(watchExport);
  sagaMiddleware.run(watchCommand);
  sagaMiddleware.run(watchTimelines);
  sagaMiddleware.run(watchTimelineStatus);
  sagaMiddleware.run(watchHooks);
  sagaMiddleware.run(watchMachine);
  sagaMiddleware.run(watchReportPreset);
  sagaMiddleware.run(watchSequentialCommand);
  sagaMiddleware.run(watchVirtualDevices);
  sagaMiddleware.run(watchPlanStructures);
  sagaMiddleware.run(watchAdvanceProductionPlans);
  sagaMiddleware.run(watchProductionBatchs);
  sagaMiddleware.run(watchActualProduction);
  sagaMiddleware.run(watchEvents);
  sagaMiddleware.run(watchDocMasterDatas);
  sagaMiddleware.run(watchDocuments);
  sagaMiddleware.run(watchAdmin);
  sagaMiddleware.run(watchErrorLogs);
  sagaMiddleware.run(watchModuleConfigs);
  sagaMiddleware.run(watchNotifications);
  sagaMiddleware.run(watchScheduler);
  sagaMiddleware.run(watchFormulas);
  sagaMiddleware.run(watchVariables);
  sagaMiddleware.run(watchDeviceGroupings);
  sagaMiddleware.run(watchPower);

  return store;
}
