import React, { Component } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import MultipleSelect from 'react-select';

import { updateObject, checkValidity } from "../../../../state/utils";
import styles from "./AddNewNotification.module.scss";
import CloseIcon from "../../../../assets/icons/cancel-circle.svg";
import Input from "../../UI/Input/Input";
import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';
import Button from "../../UI/Button/Button";
import Select from "../../UI/Select/Select";

import { addNotification, updateNotification } from "../../../../state/ducks/Notification/actions";
import { DELETE_NOTIFICATION_SUCCESS } from "../../../../state/ducks/Notification/types";

const colourStyles = {
  control: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? 0.7 : 1,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500
  }),
  input: (base) => ({
    ...base,
    color: 'white'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "#1665d8",
  }),
  multiValueLabel: base => ({
    ...base,
    color: "white",
  })
};

const NOTIFICATION_TYPE = [
  { name: "Important (Top Bar)", id: 1 },
  { name: "Annoucement", id: 2 },
  { name: "In House", id: 3 }
];

const DEFAULT_NOTIFICATION_FORM = {
  title: {
    value: "",
    valid: false,
    touched: false,
    validation: {
      required: true,
    },
  },
  content: {
    value: "",
    valid: false,
    touched: false,
    validation: {
      required: true,
    },
  },
  type: {
    value: NOTIFICATION_TYPE[0].id,
    valid: true,
    touched: true,
    validation: {
      required: true,
    },
  },
  startDate: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  startTime: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  endDate: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  },
  endTime: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  },
};

class AddNewNotification extends Component {
  state = {
    formIsValid: false,
    notificationForm: DEFAULT_NOTIFICATION_FORM,
    notificationType: [],
    selectedOrgs: [{ id: 'all', label: 'All' }]
  };

  componentDidMount() {
    if (this.props.editingNotification) {
      this.setupForm(this.props.editingNotification);
    }
    if (!this.props.loggedInUser.is_superuser && this.props.isAdding) {
      const notificationForm = { ...this.state.notificationForm };
      notificationForm.type = {
        value: 3,
        valid: true,
        touched: true,
        validation: {
          required: true,
        }
      }
      this.setState({ notificationForm });
    }

    const isAdminMode = localStorage.getItem('sight_view_mode') === 'admin';
    const options = [...NOTIFICATION_TYPE];
    if (!this.props.loggedInUser.is_superuser || (this.props.loggedInUser.is_superuser  && !isAdminMode)) options.splice(0, 2);
    else options.splice(2, 1);
    this.setState({ notificationType: options });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAdding && prevProps.isAdding !== this.props.isAdding) {
      this.onClearFields();
    } else if (this.props.editingNotification && this.props.editingNotification !== prevProps.editingNotification) {
      this.setupForm(this.props.editingNotification);
    } else if (prevProps.addedNotification !== this.props.addedNotification || prevProps.updatedNotification !== this.props.updatedNotification ||
      (this.props.type !== prevProps.type && this.props.type === DELETE_NOTIFICATION_SUCCESS)) {
      this.props.closed();
      this.onClearFields();
    }
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.notificationForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement;
      if (inputIdentifier === 'startDate') {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: moment(form.start).format('YYYY-MM-DD'),
          valid: true,
          touched: true,
        });
      } else if (inputIdentifier === 'startTime') {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: moment(form.start).format('HH:mm'),
          valid: true,
          touched: true,
        });
      } else if (inputIdentifier === 'endDate') {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form.end !== '0001-01-01T00:00:00Z' ? moment(form.end).format('YYYY-MM-DD') : '',
          valid: true,
          touched: true,
        });
      } else if (inputIdentifier === 'endTime') {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form.end !== '0001-01-01T00:00:00Z' ? moment(form.end).format('HH:mm') : '',
          valid: true,
          touched: true,
        });
      } else {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier] ? form[inputIdentifier] : "",
          valid: true,
          touched: true,
        });
      }
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement,
      });
    }

    if (form.org_ids && form.org_ids.length) {
      let selectedOrgs = [];
      form.org_ids.forEach(id => {
        const found = this.props.orgs.find(org => org.id === id);
        selectedOrgs.push({
          id: found.id,
          label: found.name,
        });
      });
      this.setState({ selectedOrgs });
    } else {
      this.setState({ selectedOrgs: [{ id: 'all', label: 'All' }] });
    }

    this.setState({ notificationForm: updatedForm, formIsValid: true });
  };

  onClearFields = () => {
    let notificationForm = { ...DEFAULT_NOTIFICATION_FORM };
    if (!this.props.loggedInUser.is_superuser && this.props.isAdding) {
      notificationForm.type = {
        value: 3,
        valid: true,
        touched: true,
        validation: {
          required: true,
        }
      }
    }
    this.setState({ notificationForm: notificationForm, formIsValid: false, selectedOrgs: [{ id: 'all', label: 'All' }] });
  };

  handleChange = event => {
    let updatedElement = updateObject(this.state.notificationForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.notificationForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.notificationForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    if (+updatedForm.type.value === 1) {
      this.setState({ selectedOrgs: [{ id: 'all', label: 'All' }] });
    }
    this.setState({ notificationForm: updatedForm, formIsValid });
  };

  checkSubmitButton = () => {
    const { type, endDate, endTime } = this.state.notificationForm;
    let valid = this.state.formIsValid;
    if (+type.value === 1) {
      valid = valid && endDate.value && endTime.value;
    }

    return !valid;
  };

  onSubmitNotificationHandler = (event) => {
    const { title, content, type, startDate, startTime, endDate, endTime } = this.state.notificationForm;
    event.preventDefault();
    if (this.props.isAdding) {
      let selectedOrgs = '';
      if (+type.value === 2) {
        if (this.state.selectedOrgs && this.state.selectedOrgs.length === 1 && this.state.selectedOrgs[0].id === 'all') {
          selectedOrgs = '';
        } else {
          selectedOrgs = this.state.selectedOrgs.map(org => org.id).join(',');
        }
      } else if (+type.value === 3) {
        selectedOrgs = this.props.currentOrg;
      }
      this.props.addNotification(
        title.value,
        content.value,
        +type.value,
        selectedOrgs,
        moment(startDate.value + ' ' + startTime.value),
        +type.value === 1 && endDate.value && endTime.value ? moment(endDate.value + ' ' + endTime.value) : ''
      );
    } else {
      this.props.updateNotification(
        this.props.editingNotification.uuid,
        title.value,
        content.value
      );
    }
  };

  onOrgsChange = (value) => {
    if (!value) {
      this.setState({ selectedOrgs: [{ id: 'all', label: 'All' }] });
    } else {
      const isAllJustSelected = value[value.length - 1].id === 'all';
      if (isAllJustSelected) {
        this.setState({ selectedOrgs: [{ id: 'all', label: 'All' }] });
      } else {
        let updatedValue = [...value];
        if (updatedValue.find(v => v.id === 'all')) updatedValue.splice(0, 1);
        this.setState({ selectedOrgs: updatedValue });
      }
    }
  }

  render() {
    const { title, content, type, startDate, startTime, endDate, endTime } = this.state.notificationForm;
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    let orgsOption = [{ id: 'all', label: 'All' }];
    if (this.props.orgs) {
      this.props.orgs.forEach(org => {
        orgsOption.push({
          id: org.id,
          label: org.name,
        });
      });
    }

    return (
      <form className={styles.AddNewNotificationWrapper} onSubmit={this.onSubmitNotificationHandler}>
        <div className={styles.AddFormWrapper}>
          <img
            src={CloseIcon}
            className={styles.CloseIcon}
            alt="close"
            onClick={this.props.closed}
          />
          <div className={styles.Title}>
            {this.props.isAdding ? "Add New Notification" : "Edit Notification"}
          </div>
          <div className={styles.Description}>
            {this.props.isAdding
              ? "Please fill the information to create a new notification"
              : "Please edit your notification details"}
          </div>
          {this.props.error && <div className="ErrorText">{this.props.error.text}</div>}

          <hr className={styles.Divider} />

          <div className={styles.LabelGroup}>
            <div className={styles.Label}>choose type *</div>
          </div>
          <Select
            name="type"
            placeholder="Please choose type"
            options={this.state.notificationType}
            value={type.value}
            autoComplete="off"
            overidestyleformgroup={{ marginBottom: 15 }}
            onChange={this.handleChange}
            disabled={!this.props.isAdding}
          />

          <Input
            label="Title"
            name="title"
            type="text"
            placeholder="Please name your title"
            value={title.value}
            autoComplete="off"
            onChange={this.handleChange}
            error={`${title.touched && !title.valid ? "Title is required" : ""}`}
            required
          />
          <Input
            label="content"
            name="content"
            type="textarea"
            rows="5"
            placeholder="What is this notification about?"
            value={content.value}
            autoComplete="off"
            onChange={this.handleChange}
            required
          />

          <div style={{ display: 'flex' }}>
            <div className={styles.FormGroup} style={{ flex: 1, marginRight: 5 }}>
              <label className={styles.TimeTitle}>POSTED DATE *</label>
              <div style={{ display: 'flex', position: 'relative' }}>
                {!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
                <input
                  className={styles.SelectBox}
                  style={{ paddingLeft: !isChrome ? 46 : null, flex: 2, position: 'initial' }}
                  type="date"
                  onChange={this.handleChange}
                  name="startDate"
                  value={startDate.value}
                  disabled={!this.props.isAdding}
                />
                <input
                  className={styles.SelectBox}
                  style={{ position: 'initial' }}
                  type="time"
                  onChange={this.handleChange}
                  name="startTime"
                  value={startTime.value}
                  disabled={!this.props.isAdding}
                />
              </div>
            </div>

            {
              +type.value === 1
                ? <div className={styles.FormGroup} style={{ flex: 1, marginLeft: 5 }}>
                  <label className={styles.TimeTitle}>End *</label>
                  <div style={{ display: 'flex', position: 'relative' }}>
                    {!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
                    <input
                      className={styles.SelectBox}
                      style={{ paddingLeft: !isChrome ? 46 : null, flex: 2, position: 'initial' }}
                      type="date"
                      onChange={this.handleChange}
                      name="endDate"
                      value={endDate.value}
                      disabled={!this.props.isAdding}
                    />
                    <input
                      className={styles.SelectBox}
                      style={{ position: 'initial' }}
                      type="time"
                      onChange={this.handleChange}
                      name="endTime"
                      value={endTime.value}
                      disabled={!this.props.isAdding}
                    />
                  </div>
                </div>
                : null
            }
          </div>
          {
            +type.value === 1 || +type.value === 2 ?
              <div className={styles.FormGroup} style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                <label className={styles.TimeTitle}>ORGANIZATIONS *</label>
                <MultipleSelect isMulti isSearchable closeMenuOnSelect={false} options={orgsOption} styles={colourStyles}
                  placeholder="You can select some orgs to be specific"
                  value={this.state.selectedOrgs}
                  onChange={this.onOrgsChange}
                  getOptionValue={opt => opt.id}
                  isDisabled={+type.value === 1 || !this.props.isAdding}
                />
              </div>
              : null
          }
        </div>
        <div className={styles.ButtonsWrapper}>
          {this.props.isAdding ? (
            <React.Fragment>
              <div style={{ width: 112 }}>
                <Button
                  type="button"
                  name="Cancel"
                  color="borderred"
                  noMargin
                  click={this.props.closed}
                />
              </div>
              <div style={{ width: 200 }}>
                <Button
                  type="submit"
                  name={this.props.loading ? "Loading..." : "Done"}
                  color="primary"
                  disabled={this.checkSubmitButton()}
                  loading={this.props.loading}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div style={{ width: 112 }}>
                <Button
                  type="button"
                  name="Delete"
                  color="red"
                  noMargin
                  click={this.props.toggleDeleteModal}
                />
              </div>
              <div style={{ width: 200 }}>
                <Button
                  type="submit"
                  name={this.props.loading ? "Loading..." : "Save"}
                  color="green"
                  disabled={this.checkSubmitButton()}
                  loading={this.props.loading}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state) => {
  const { currentOrg, orgs } = state.org;
  const { loggedInUser } = state.auth;
  const { type, loading, result, addedNotification, updatedNotification, error } = state.notification;
  return {
    currentOrg, orgs, loggedInUser,
    type, loading, result, addedNotification, updatedNotification, error
  };
};

export default connect(mapStateToProps, { addNotification, updateNotification })(AddNewNotification);
