
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { SEARCH_EVENTS, ADD_EVENT } from './types';

export function* watchEvents() {
  yield takeEvery(SEARCH_EVENTS, searchEventsSaga);
  yield takeEvery(ADD_EVENT, addEventSaga);
}

export function* searchEventsSaga(params) {
  yield put(actions.searchEventsStart());

  let api = 'v1/event';
  api += '?org_id=' + params.org_id;
  api += params.start ? '&start=' + params.start : '';
  api += params.end ? '&end=' + params.end : '';
  api += params.rule_id ? '&rule_id=' + params.rule_id : '';
  api += params.level || params.level === 0 ? '&level=' + params.level : '';
  api += params.machine_id ? '&machine_id=' + params.machine_id : '';
  api += params.ref ? '&ref=' + params.ref : '';
  api += params.offset ? '&offset=' + params.offset : '';
  api += params.limit ? '&limit=' + params.limit : '';

  try {
    const response = yield axios.get(api);
    // console.log("SEARCH EVENTS SUCCESS", response.data);
    yield put(actions.searchEventsSuccess(response.data));
  } catch (err) {
    console.log("SEARCH EVENTS FAILED", err)
    yield put(actions.searchEventsFail(err && err.response ? err.response.error : 'Something went wrong'));
  }
}

export function* addEventSaga(params) {
  yield put(actions.addEventStart());

  let api = 'v1/event';
  let postData = "org_id=" + params.org_id;
  postData += "&machine_id=" + params.machine_id;
  postData += "&start=" + params.start;
  postData += "&end=" + params.end;
  postData += "&origin=" + params.origin;
  postData += params.level || params.level === 0 ? "&level=" + params.level : '';
  postData += params.message ? "&message=" + params.message : '';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD EVENT SUCCESS", response.data);
    yield put(actions.addEventSuccess(response.data));
  } catch (err) {
    // console.log("ADD EVENT FAILED", err.response)
    yield put(actions.addEventFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}