import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './ActualsTable.module.scss';
import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import DeleteIcon from '../../../../assets/icons/delete-red-bin.svg';
import AddIcon from '../../../../assets/icons/plus-circle-blue.svg';

import { updateActualProduction } from '../../../../state/ducks/AdvanceActualProduction/actions';
import { getDocumentNotes  } from '../../../../state/ducks/Document/actions';
import { DELETE_DOCUMENT_NOTE_SUCCESS } from '../../../../state/ducks/Document/types';

const DEFAULT_ACTUAL_FORM = {
  start: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  end: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  startTime: {
    value: '00:00',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  endTime: {
    value: '00:00',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  total: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: true,
      min: 0
    }
  },
  ok: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: true,
      min: 0
    }
  },
  defect: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: true,
      min: 0
    }
  }
};

class ActualDrawer extends Component {
  state = {
    formIsValid: false,
    actualForm: DEFAULT_ACTUAL_FORM,
    columns: {}
  }

  componentDidMount() {
    if (this.props.editingActual) {
      this.setupForm(this.props.editingActual);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.result === 'success' && prevProps.updatedActualProduction !== this.props.updatedActualProduction) {
      this.props.closed();
    } else if (this.props.editingActual && prevProps.editingActual !== this.props.editingActual) {
      this.setupForm(this.props.editingActual);
    } else if (prevProps.documentResult !== this.props.documentResult && this.props.documentResult === 'success') {
      if (this.props.updatedDocumentNote !== prevProps.updatedDocumentNote || this.props.addedDocumentNote !== prevProps.addedDocumentNote || this.props.type === DELETE_DOCUMENT_NOTE_SUCCESS) {
        const foundProdType = this.props.noteTypes.find(nt => nt.name.toLowerCase() === 'production');
        this.props.getDocumentNotes(this.props.currentOrg, foundProdType.id);
      }
    }
  }

  // onClearFields = () => {
  //   const actualForm = { ...DEFAULT_ACTUAL_FORM };
  //   const defaultStop = moment().add(1, 'days').startOf('day').valueOf();
  //   const defaultStart = new Date().setHours(0, 0, 0, 0);
  //   actualForm.start.value = moment(defaultStart).format('YYYY-MM-DD');
  //   actualForm.end.value = moment(defaultStop).format('YYYY-MM-DD');
  //   this.setState({ actualForm, formIsValid: false });
  // }

  setupForm = (form) => {
    let updatedForm = { ...this.state.actualForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement;
      if (inputIdentifier === 'start' || inputIdentifier === 'startTime') {
        updatedElement = updateObject(inputIdentifier === 'start' ? updatedForm.start : updatedForm.startTime, {
          value: inputIdentifier === 'start' ? moment(form.start).format('YYYY-MM-DD') : moment(form.start).format('HH:mm'),
          valid: true,
          touched: true,
        });
      } else if (inputIdentifier === 'end' || inputIdentifier === 'endTime') {
        updatedElement = updateObject(inputIdentifier === 'end' ? updatedForm.end : updatedForm.endTime, {
          value: inputIdentifier === 'end' ? moment(form.end).format('YYYY-MM-DD') : moment(form.end).format('HH:mm'),
          valid: true,
          touched: true,
        });
      } else {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier] ? form[inputIdentifier] : '',
          valid: true,
          touched: true,
        });
      }

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }

    const columns = {};
    if (this.props.structure && this.props.structure.columns) {
      this.props.structure.columns.forEach(st => {
        columns[st.name] = form.columns[st.name] ? form.columns[st.name] : form.plan.columns[st.name] ? form.plan.columns[st.name] : '';
      });
    }
    this.setState({ actualForm: updatedForm, formIsValid: true, columns });
  }

  handleFormChange = event => {
    let updatedElement = updateObject(this.state.actualForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.actualForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.actualForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ actualForm: updatedForm, formIsValid });
  };

  handleCustomColumnChange = (col, value) => {
    const columns = { ...this.state.columns };
    columns[col.name] = value;
    this.setState({ columns });
  }

  onSubmitActualHandler = (event) => {
    event.preventDefault();
    const { start, startTime, end, endTime, total, ok, defect } = this.state.actualForm;
    this.props.updateActualProduction(
      this.props.editingActual.uuid,
      moment(start.value + ' ' + startTime.value),
      moment(end.value + ' ' + endTime.value),
      total.value,
      ok.value,
      defect.value,
      this.state.columns
    );
  }

  render() {
    const { plan } = this.props.editingActual;
    const { start, end, startTime, endTime, total, ok, defect } = this.state.actualForm;
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    return (
      <div>
        <div className="ErrorText">{this.props.isAdding && this.props.result === 'failed' ? this.props.error : ''}</div>
        <form onSubmit={this.onSubmitActualHandler}>
          <Input
            label="po"
            name="po"
            type="text"
            value={plan.po}
            autoComplete="off"
            onChange={this.handleFormChange}
            required
            disabled
          />
          <div style={{ flex: 1, marginBottom: '1rem' }}>
            <label className={styles.Title}>Start *</label>
            <div style={{ display: 'flex', position: 'relative' }}>
              {!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
              <input
                className={styles.SelectBox}
                style={{ paddingLeft: !isChrome ? 46 : null, flex: 2 }}
                type="date"
                onChange={this.handleFormChange}
                name="start"
                value={start.value}
              />
              <input
                className={styles.SelectBox}
                type="time"
                onChange={this.handleFormChange}
                name="startTime"
                value={startTime.value}
              />
            </div>
          </div>
          <div style={{ flex: 1, marginBottom: '1rem' }}>
            <label className={styles.Title}>End *</label>
            <div style={{ display: 'flex', position: 'relative' }}>
              {!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
              <input
                className={styles.SelectBox}
                style={{ paddingLeft: !isChrome ? 46 : null, flex: 2 }}
                type="date"
                onChange={this.handleFormChange}
                name="end"
                value={end.value}
              />
              <input
                className={styles.SelectBox}
                type="time"
                onChange={this.handleFormChange}
                name="endTime"
                value={endTime.value}
              />
            </div>
          </div>

          {
            this.props.structure && this.props.structure.columns.length ?
              this.props.structure.columns.sort((a, b) => a.order - b.order).map(st => (
                <Input key={st.name}
                  label={st.display_name}
                  type="text"
                  value={this.state.columns[st.name] ? this.state.columns[st.name] : ''}
                  autoComplete="off"
                  onChange={(e) => this.handleCustomColumnChange(st, e.target.value)}
                />
              ))
              : null
          }

          <Input
            label="total"
            name="total"
            type="number"
            value={total.value || total.value === 0 ? total.value : 0}
            autoComplete="off"
            onChange={this.handleFormChange}
            error={`${total.touched && !total.valid ? `Total is required and greater than 0` : ''}`}
            required
          />
          <Input
            label="good"
            name="ok"
            type="number"
            value={ok.value || ok.value === 0 ? ok.value : 0}
            autoComplete="off"
            onChange={this.handleFormChange}
            required
            error={`${ok.touched && !ok.valid ? `Good is required and greater than 0` : ''}`}
          />
          <Input
            label="defect"
            name="defect"
            type="number"
            value={defect.value || defect.value === 0 ? defect.value : 0}
            autoComplete="off"
            onChange={this.handleFormChange}
            required
            error={`${defect.touched && !defect.valid ? `Defect is required and greater than 0` : ''}`}
          />

          <div className={styles.FormGroup}>
            <label className={styles.Title}>Attached Documents</label>
            {
              this.props.editingActual && this.props.documentNotes && this.props.documentNotes.length && this.props.documentNotes.find(n => n.ref === this.props.editingActual.uuid)
                ? this.props.documentNotes.map(dn => this.props.editingActual.uuid === dn.ref && (
                  <div key={dn.uuid} className={styles.DocumentWrapper}>
                    <div className={styles.DocTitle}>{dn.title}</div>
                    <img src={EditIcon} alt="Edit" className={styles.DocIcon} onClick={() => this.props.editProdDoc(dn)} />
                    <img src={DeleteIcon} alt="Delete" className={styles.DocIcon} onClick={() => this.props.deleteProdDoc(dn)} />
                  </div>
                ))
                : <div style={{ fontSize: 12, fontWeight: '500' }}>None</div>
            }
          </div>
          <div className={styles.AddProdBtn} onClick={this.props.addProductionNote}>
            <img src={AddIcon} alt="Add icon for modbus" className={styles.AddIcon} />
            <div className={styles.ButtonName}>Add Production Note</div>
          </div>

          <div style={{ height: 70, width: 'auto' }} />

          {
            this.props.isAdding
              ? <>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Done'}
                  color="primary"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
              </>
              : <>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Save'}
                  color="green"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
              </>
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { currentOrg } = state.org;
  const { updatedActualProduction, result, error } = state.advanceActualProduction;
  const { documentNotes, addedDocumentNote, updatedDocumentNote, noteTypes, type } = state.document;
  return {
    loggedInUser, currentOrg, updatedActualProduction, result, error,
    documentNotes, addedDocumentNote, updatedDocumentNote, documentResult: state.document.result, noteTypes, type
  };
};

export default connect(mapStateToProps, { updateActualProduction, getDocumentNotes })(ActualDrawer);