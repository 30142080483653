
import axios from '../../../configs/axios-orders';
import { takeEvery, put, take } from 'redux-saga/effects';

import * as actions from './actions';
import * as ruleConditionActions from '../RuleCondition/actions';
import * as ruleCommandActions from '../RuleCommand/actions';

import { ADD_RULE_CONDITION_SUCCESS, EDIT_RULE_CONDITION_SUCCESS, DELETE_RULE_CONDITION_SUCCESS } from '../RuleCondition/types';
import { ADD_RULE_COMMAND_SUCCESS, EDIT_RULE_COMMAND_SUCCESS, DELETE_RULE_COMMAND_SUCCESS } from '../RuleCommand/types';
import { GET_RULES, ADD_RULE, EDIT_RULE, DELETE_RULE, ENABLE_RULE, GET_ALARM_HISTORIES } from './types';

export function* watchRules() {
  yield takeEvery(GET_RULES, getRulesSaga);
  yield takeEvery(ADD_RULE, addRuleSaga);
  yield takeEvery(ENABLE_RULE, enableRuleSaga);
  yield takeEvery(EDIT_RULE, editRuleSaga);
  yield takeEvery(DELETE_RULE, deleteRuleSaga);
  yield takeEvery(GET_ALARM_HISTORIES, getAlarmHistoriesSaga);
}

export function* getRulesSaga(params) {
  yield put(actions.getRulesStart());
  let api = 'v1/rule';
  if (params.id) {
    api += '/' + params.id;
  }

  api += params.org_id ? '?org_id=' + params.org_id : '';
  api += params.name ? '&name=' + encodeURIComponent(params.name) : '';
  api += params.enable ? '&enable=' + params.enable : '';
  api += params.rule_type ? '&type=' + params.rule_type : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET RULES SUCCESS", response.data);
    yield put(actions.getRulesSuccess(response.data));
  } catch (err) {
    console.log("GET RULES FAILED", err)
    yield put(actions.getRulesFail(err.response));
  }
}

export function* addRuleSaga(params) {
  yield put(actions.addRuleStart());
  const api = 'v1/rule';
  // org_id, name, description, rule_type, enable, sampling_size, calculation_method, emails, mobile_numbers, hook
  let postData = 'org_id=' + params.org_id;
  postData += '&name=' + encodeURIComponent(params.ruleForm.name.value);
  postData += params.ruleForm.description.value ? '&description=' + encodeURIComponent(params.ruleForm.description.value) : '';
  postData += '&type=' + +params.ruleForm.type.value;
  postData += '&sampling_size=' + +params.ruleForm.sampling_size.value;
  postData += '&level=' + +params.ruleForm.level.value;
  postData += params.ruleForm.calculation_method.value !== '-1' ? '&calculation_method=' + params.ruleForm.calculation_method.value : '';
  postData += params.ruleForm.emails.value ? '&emails=' + encodeURIComponent(params.ruleForm.emails.value) : '';
  postData += params.ruleForm.mobile_numbers.value ? '&mobile_numbers=' + encodeURIComponent(params.ruleForm.mobile_numbers.value.replace(/\s/g, "")) : '';
  postData += params.ruleForm.line_token.value ? '&line_token=' + encodeURIComponent(params.ruleForm.line_token.value) : '';
  postData += params.ruleForm.true_message.value ? '&true_message=' + encodeURIComponent(params.ruleForm.true_message.value) : '';
  postData += params.ruleForm.false_message.value ? '&false_message=' + encodeURIComponent(params.ruleForm.false_message.value) : '';
  postData += params.ruleForm.hook.value ? '&hook=' + encodeURIComponent(params.ruleForm.hook.value) : '';
  postData += params.ruleForm.machine_ids.value ? '&machine_ids=' + params.ruleForm.machine_ids.value : '';
  postData += '&enable=true';

  try {
    const response = yield axios.post(api, postData);
    if (params.conditionForm) {
      yield* params.conditionForm.map(function* (conf) {
        // rule_id, operation, condition, device_socket, device_secret, value
        yield put(ruleConditionActions.addRuleCondition(
          response.data.rule.id,
          conf.form.operation.value,
          conf.form.condition.value,
          conf.form.device_secret.value,
          conf.form.device_socket.value,
          conf.form.value.value
        ));
        yield take(ADD_RULE_CONDITION_SUCCESS);
      });
    }
    if (params.commandForm) {
      yield* params.commandForm.map(function* (comf) {
        // rule_id, device_socket, device_secret, value
        yield put(ruleCommandActions.addRuleCommand(
          response.data.rule.id,
          comf.form.device_secret.value,
          comf.form.device_socket.value,
          comf.form.value.value
        ));
        yield take(ADD_RULE_COMMAND_SUCCESS);
      });
    }
    // console.log("ADD RULE SUCCESS", response.data);
    yield put(actions.addRuleSuccess(response.data));
  } catch (err) {
    // console.log("ADD RULE FAILED", err.response.data);
    yield put(actions.addRuleFail(err.response.data));
  }
}

// const commandType = state => state.ruleCommand.type;

export function* editRuleSaga(params) {
  yield put(actions.editRuleStart());
  const api = 'v1/rule/' + params.id;
  // id, name, description, type, enable, emails, mobile_numbers, hook, sampling_size, claculation_method
  // console.log("Editing Rules before put to 'v1/rule/'", params)
  let putData = 'name=' + encodeURIComponent(params.ruleForm.name.value);
  putData += '&description=' + encodeURIComponent(params.ruleForm.description.value);
  putData += '&type=' + +params.ruleForm.type.value;
  putData += '&sampling_size=' + +params.ruleForm.sampling_size.value;
  putData += '&level=' + +params.ruleForm.level.value;
  putData += params.ruleForm.calculation_method.value !== '-1' ? '&calculation_method=' + params.ruleForm.calculation_method.value : '';
  putData += params.ruleForm.emails.value ? '&emails=' + encodeURIComponent(params.ruleForm.emails.value) : '&emails=';
  putData += '&mobile_numbers=' + (params.ruleForm.mobile_numbers.value ? encodeURIComponent(params.ruleForm.mobile_numbers.value.replace(/\s/g, "")) : '');
  putData += params.ruleForm.line_token.value ? '&line_token=' + encodeURIComponent(params.ruleForm.line_token.value) : '';
  putData += params.ruleForm.true_message.value ? '&true_message=' + encodeURIComponent(params.ruleForm.true_message.value) : '';
  putData += params.ruleForm.false_message.value ? '&false_message=' + encodeURIComponent(params.ruleForm.false_message.value) : '';
  putData += params.ruleForm.machine_ids.value ? '&machine_ids=' + params.ruleForm.machine_ids.value : '';
  putData += params.ruleForm.hook.value ? '&hook=' + encodeURIComponent(params.ruleForm.hook.value) : '';

  try {
    const response = yield axios.put(api, putData);
    if (params.conditionForm) {
      yield* params.conditionForm.map(function* (conf) {
        if (conf.isAdding) {
          yield put(ruleConditionActions.addRuleCondition(
            params.id,
            conf.form.operation.value,
            conf.form.condition.value,
            conf.form.device_secret.value,
            conf.form.device_socket.value,
            conf.form.value.value
          ));
          yield take(ADD_RULE_CONDITION_SUCCESS);
        } else if (!conf.isAdding && conf.form) {
          yield put(ruleConditionActions.editRuleCondition(
            conf.conditionId,
            conf.form.operation.value,
            conf.form.condition.value,
            conf.form.device_secret.value,
            conf.form.device_socket.value,
            conf.form.value.value
          ));
          yield take(EDIT_RULE_CONDITION_SUCCESS);
        }
      });
    }
    if (params.commandForm) {
      yield* params.commandForm.map(function* (comf) {
        if (comf.isAdding) {
          yield put(ruleCommandActions.addRuleCommand(
            params.id,
            comf.form.device_secret.value,
            comf.form.device_socket.value,
            comf.form.value.value
          ));
          yield take(ADD_RULE_COMMAND_SUCCESS);
        } else if (!comf.isAdding && comf.form) {
          yield put(ruleCommandActions.editRuleCommand(
            comf.commandId,
            comf.form.device_secret.value,
            comf.form.device_socket.value,
            comf.form.value.value
          ));
          yield take(EDIT_RULE_COMMAND_SUCCESS);
        }
      });
    }
    if (params.deleteConditions) {
      yield* params.deleteConditions.map(function* (conId) {
        yield put(ruleConditionActions.deleteRuleCondition(conId));
        yield take(DELETE_RULE_CONDITION_SUCCESS);
      });
    }
    if (params.deleteCommands) {
      yield* params.deleteCommands.map(function* (comId) {
        yield put(ruleCommandActions.deleteRuleCommand(comId));
        yield take(DELETE_RULE_COMMAND_SUCCESS);
      });
    }
    // console.log("EDIT RULE SUCCESS", response.data);
    yield put(actions.editRuleSuccess(response.data));
  } catch (err) {
    // console.log("EDIT RULE FAILED", err.response.data);
    yield put(actions.editRuleFail(err.response.data));
  }
}

export function* enableRuleSaga(params) {
  yield put(actions.enableRuleStart());

  const api = 'v1/rule/' + params.id;
  let putData = 'enable=' + params.enable;
  putData += params.calculation_method ? '&calculation_method=' + params.calculation_method : '';
  putData += params.sampling_size ? '&sampling_size=' + params.sampling_size : '';

  try {
    const response = yield axios.put(api, putData);
    // console.log("ENABLE RULE SUCCESS", response.data);
    yield put(actions.enableRuleSuccess(response.data));
  } catch (err) {
    // console.log("ENABLE RULE FAILED", err.response.data);
    yield put(actions.enableRuleFail(err.response.data));
  }
}

export function* deleteRuleSaga(params) {
  yield put(actions.deleteRuleStart());

  const api = 'v1/rule/' + params.id;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE RULE SUCCESS", response.data);
    yield put(actions.deleteRuleSuccess(response.data.result));
  } catch (err) {
    // console.log("DELETE RULE FAILED", err.response);
    yield put(actions.deleteRuleFail(err.response));
  }
}

export function* getAlarmHistoriesSaga(params) {
  yield put(actions.getAlarmHistoriesStart());

  let api = 'v1/alarmhistories/' + params.rule_id;
  api += params.start ? '&start=' + params.start : '';
  api += params.end ? '&stop=' + params.end : '';
  try {
    const response = yield axios.get(api);
    // console.log("GET ALARM_HISTORIES SUCCESS", response.data);
    yield put(actions.getAlarmHistoriesSuccess(response.data));
  } catch (err) {
    console.log("GET ALARM_HISTORIES FAILED", err)
    yield put(actions.getAlarmHistoriesFail(err.response));
  }
}