import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  favoriteType: null,
  favoriteResult: null,
  loading: false,
  error: null,
  favorites: null,
  addedFavorite: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_FAVORITES_START: return getUserFavoritesStart(state, action);
    case actionTypes.GET_USER_FAVORITES_SUCCESS: return getUserFavoritesSuccess(state, action);
    case actionTypes.GET_USER_FAVORITES_FAIL: return getUserFavoritesFail(state, action);

    case actionTypes.ADD_USER_FAVORITE_START: return addUserFavoriteStart(state, action);
    case actionTypes.ADD_USER_FAVORITE_SUCCESS: return addUserFavoriteSuccess(state, action);
    case actionTypes.ADD_USER_FAVORITE_FAIL: return addUserFavoriteFail(state, action);
    
    case actionTypes.DELETE_USER_FAVORITE_START: return deleteUserFavoriteStart(state, action);
    case actionTypes.DELETE_USER_FAVORITE_SUCCESS: return deleteUserFavoriteSuccess(state, action);
    case actionTypes.DELETE_USER_FAVORITE_FAIL: return deleteUserFavoriteFail(state, action);

    default: return state;
  }
};

const getUserFavoritesStart = (state, action) => {
  return updateObject(state, { favoriteResult: null, error: null, loading: true });
}

const getUserFavoritesSuccess = (state, action) => {
  return updateObject(state, {
    favorites: action.favorites,
    error: null,
    loading: false
  });

}

const getUserFavoritesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addUserFavoriteStart = (state, action) => {
  return updateObject(state, { favoriteResult: null, error: null, loading: true });
}

const addUserFavoriteSuccess = (state, action) => {
  return updateObject(state, {
    addedFavorite: action.addedFavorite,
    favoriteResult: action.favoriteResult,
    error: null,
    loading: false
  });

}

const addUserFavoriteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}


const deleteUserFavoriteStart = (state, action) => {
  return updateObject(state, { favoriteResult: null, error: null, loading: true });
}

const deleteUserFavoriteSuccess = (state, action) => {
  return updateObject(state, {
    favoriteType: action.type,
    favoriteResult: action.favoriteResult,
    error: null,
    loading: false
  });

}

const deleteUserFavoriteFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;


