export const GET_POWERS_START = 'GET_POWERS_START';
export const GET_AWAITING_POWERS = 'GET_AWAITING_POWERS';
export const GET_POWERS = 'GET_POWERS';
export const GET_POWERS_SUCCESS = 'GET_POWERS_SUCCESS';
export const GET_POWERS_FAIL = 'GET_POWERS_FAIL';

export const GET_POWERS_JSON_START = 'GET_POWERS_JSON_START';
export const GET_POWERS_JSON = 'GET_POWERS_JSON';
export const GET_POWERS_JSON_SUCCESS = 'GET_POWERS_JSON_SUCCESS';
export const GET_POWERS_JSON_FAIL = 'GET_POWERS_JSON_FAIL';

export const CLEAR_REQ_RES_COUNT = 'CLEAR_REQ_RES_COUNT';
export const SAVE_REQUEST = 'SAVE_REQUEST';