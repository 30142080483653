import React, { useState } from 'react';

import styles from './Sidebar.module.scss';
import NavigationItems from '../NavigationItems/NavigationItems';
import ApplicationIcon from '../../../../assets/icons/navigation-items/menu.png';

const Sidebar = ({ route, isAdminMode }) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [pcsAccess, setPcsAccess] = useState(false);

  const dropdownMenuClickHandler = () => {
    setIsDropdownOpened(!isDropdownOpened);
  }

  const dropdownMenuClose = () => {
    setIsDropdownOpened(false);
  }

  const gotPcsAccess = (val) => {
    setPcsAccess(val);
  }

  const goToOperator = () => {
    route.history.push('/operator');
  }

  let dropdownClasses = [styles.Dropdown];
  if (isDropdownOpened) {
    dropdownClasses.push(styles.DropdownIn);
  } else {
    dropdownClasses.push(styles.DropdownOut);
  }

  return (
    <div className={styles.Sidebar}>
      <div className={styles.Topic}>MENU</div>
      <NavigationItems isAdminMode={isAdminMode} route={route} gotPcsAccess={gotPcsAccess} />

      <div className={styles.BottomSection}>
        {
          pcsAccess ?
            <div className={styles.BottomMenuWrapper} onClick={dropdownMenuClickHandler}>
              <div className={styles.BottomMenu}>
                <img src={ApplicationIcon} alt={"application"} className={styles.MenuIcon} />
                <div className={`${styles.MenuTitle} ${window.innerWidth <= 1024 ? styles.MenuTitleMobile : ''}`}>Applications</div>
              </div>
              {isDropdownOpened ? <div className={styles.DropdownContainer} onClick={dropdownMenuClose} ></div> : null}
              <div className={dropdownClasses.join(' ')}>
                <ul>
                  <li>
                    <div style={{ display: "flex" }} onClick={goToOperator}>
                      <div className={styles.ListItem}>Operator</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            : null
        }
        <div className={styles.Credit}>© Technimal</div>
      </div>
    </div>
  )
}

export default Sidebar;