import * as actionTypes from './types';

// ----------------------- ADD WIDGET -----------------------
export const addWidgetStart = () => {
  return {
    type: actionTypes.ADD_WIDGET_START
  };
}

export const addWidget = (id, title, widget_type, device_id, x, y, w, h, formula, formula_output, formula_object, properties) => {
  return {
    type: actionTypes.ADD_WIDGET,
    id, title, widget_type, device_id, x, y, w, h, formula, formula_output, formula_object, properties
  };
}

export const addWidgetSuccess = (response) => {
  return {
    type: actionTypes.ADD_WIDGET_SUCCESS,
    addedWidget: response.widget,
    result: response.result
  };
}

export const addWidgetFail = (error) => {
  return {
    type: actionTypes.ADD_WIDGET_FAIL,
    error: error
  };
}

// ----------------------- EDIT WIDGET -----------------------
export const editWidgetStart = () => {
  return {
    type: actionTypes.EDIT_WIDGET_START
  };
}

export const editWidget = (id, wid, title, widget_type, device_id, x, y, w, h, formula, formula_output, formula_object, properties) => {
  return {
    type: actionTypes.EDIT_WIDGET,
    id, wid, title, widget_type, device_id, x, y, w, h, formula, formula_output, formula_object, properties
  };
}

export const editWidgetSuccess = (response) => {
  return {
    type: actionTypes.EDIT_WIDGET_SUCCESS,
    updatedWidget: response.widget,
    result: response.result
  };
}

export const editWidgetFail = (error) => {
  return {
    type: actionTypes.EDIT_WIDGET_FAIL,
    error: error
  };
}

// ----------------------- EDIT WIDGET LAYOUT -----------------------
export const editWidgetLayoutStart = () => {
  return {
    type: actionTypes.EDIT_WIDGET_LAYOUT_START
  };
}

export const editWidgetLayout = (id, wid, x, y, w, h) => {
  return {
    type: actionTypes.EDIT_WIDGET_LAYOUT,
    id, wid, x, y, w, h
  };
}

export const editWidgetLayoutSuccess = (response) => {
  return {
    type: actionTypes.EDIT_WIDGET_LAYOUT_SUCCESS,
    updatedWidgetLayout: response.widget,
    result: response.result
  };
}

export const editWidgetLayoutFail = (error) => {
  return {
    type: actionTypes.EDIT_WIDGET_LAYOUT_FAIL,
    error: error
  };
}

// ----------------------- DELETE WIDGET -----------------------
export const deleteWidgetStart = () => {
  return {
    type: actionTypes.DELETE_WIDGET_START
  };
}

export const deleteWidget = (id, wid) => {
  return {
    type: actionTypes.DELETE_WIDGET,
    id,
    wid
  };
}

export const deleteWidgetSuccess = (result, wid) => {
  return {
    type: actionTypes.DELETE_WIDGET_SUCCESS,
    result,
    wid
  };
}

export const deleteWidgetFail = (error) => {
  return {
    type: actionTypes.DELETE_WIDGET_FAIL,
    error: error
  };
}

// export default {
//   addWidget,
//   addWidgetStart,
//   addWidgetSuccess,
//   addWidgetFail,
//   editWidget,
//   editWidgetStart,
//   editWidgetSuccess,
//   editWidgetFail,
//   deleteWidget,
//   deleteWidgetStart,
//   deleteWidgetSuccess,
//   deleteWidgetFail,
// };