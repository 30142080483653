import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './types';
import { GET_DOCUMENT_NOTES, ADD_DOCUMENT_NOTE, EDIT_DOCUMENT_NOTE, DELETE_DOCUMENT_NOTE, GET_NOTE_TYPES, ADD_NOTE_TYPE, EDIT_NOTE_TYPE, DELETE_NOTE_TYPE } from './types';

export function* watchDocuments() {
  yield takeEvery(GET_DOCUMENT_NOTES, getDocumentNotesSaga);
  yield takeEvery(ADD_DOCUMENT_NOTE, addDocumentNoteSaga);
  yield takeEvery(EDIT_DOCUMENT_NOTE, editDocumentNoteSaga);
  yield takeEvery(DELETE_DOCUMENT_NOTE, deleteDocumentNoteSaga);
  yield takeEvery(GET_NOTE_TYPES, getNoteTypesSaga);
  yield takeEvery(ADD_NOTE_TYPE, addNoteTypeSaga);
  yield takeEvery(EDIT_NOTE_TYPE, editNoteTypeSaga);
  yield takeEvery(DELETE_NOTE_TYPE, deleteNoteTypeSaga);

  yield takeEvery(types.GET_DOCUMENTS, getDocumentsSaga);
  yield takeEvery(types.CREATE_DOCUMENT, createDocumentSaga);
  yield takeEvery(types.UPDATE_DOCUMENT, updateDocumentSaga);
  yield takeEvery(types.DELETE_DOCUMENT, deleteDocumentSaga);
}

export function* getDocumentNotesSaga(params) {
  yield put(actions.getDocumentNotesStart());

  let api = 'v1/note/document';
  api += '?org_id=' + params.org_id;
  api += params.type_id ? '&type_id=' + params.type_id : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET DOCUMENT_NOTES SUCCESS", response.data);
    yield put(actions.getDocumentNotesSuccess(response.data));
  } catch (err) {
    // console.log("GET DOCUMENT_NOTES FAILED", err.response)
    yield put(actions.getDocumentNotesFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* addDocumentNoteSaga(params) {
  yield put(actions.addDocumentNoteStart());

  // org_id, type_id, title, content, options, ref, event_uuid, attachments
  let api = 'v1/note/document';
  let postData = "org_id=" + params.org_id;
  postData += "&type_id=" + params.type_id;
  postData += params.title ? "&title=" + params.title : '';
  postData += params.content ? "&content=" + params.content : '';
  postData += params.options ? "&options=" + JSON.stringify(params.options) : '';
  postData += params.ref ? "&ref=" + params.ref : '';
  postData += params.event_uuid ? "&event_uuid=" + params.event_uuid : '';
  postData += params.attachments ? "&attachments=" + params.attachments : '';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD DOCUMENT_NOTE SUCCESS", response.data);
    yield put(actions.addDocumentNoteSuccess(response.data));
  } catch (err) {
    // console.log("ADD DOCUMENT_NOTE FAILED", err.response)
    yield put(actions.addDocumentNoteFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* editDocumentNoteSaga(params) {
  yield put(actions.editDocumentNoteStart());

  let putData = "type_id=" + params.type_id;
  putData += params.title ? "&title=" + params.title : '';
  putData += params.content ? "&content=" + params.content : '';
  putData += params.options ? "&options=" + JSON.stringify(params.options) : '';
  putData += params.ref ? "&ref=" + params.ref : '';
  putData += params.event_uuid ? "&event_uuid=" + params.event_uuid : '';
  putData += params.attachments ? "&attachments=" + params.attachments : '';

  const api = 'v1/note/document/' + params.uuid;

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT DOCUMENT_NOTE SUCCESS", response.data);
    yield put(actions.editDocumentNoteSuccess(response.data));
  } catch (err) {
    // console.log("EDIT DOCUMENT_NOTE FAILED", err.response);
    yield put(actions.editDocumentNoteFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* deleteDocumentNoteSaga(params) {
  yield put(actions.deleteDocumentNoteStart());

  const api = 'v1/note/document/' + params.uuid

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE DOCUMENT_NOTE SUCCESS", response.data);
    yield put(actions.deleteDocumentNoteSuccess(response.data.result));
  } catch (err) {
    // console.log("DELETE DOCUMENT_NOTE FAILED", err.response);
    yield put(actions.deleteDocumentNoteFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}


export function* getNoteTypesSaga(params) {
  yield put(actions.getNoteTypesStart());

  let api = 'v1/note/type';
  api += '?org_id=' + params.org_id;

  try {
    const response = yield axios.get(api);
    // console.log("GET NOTE_TYPEs SUCCESS", response.data);
    yield put(actions.getNoteTypesSuccess(response.data));
  } catch (err) {
    // console.log("GET NOTE_TYPEs FAILED", err.response)
    yield put(actions.getNoteTypesFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* addNoteTypeSaga(params) {
  yield put(actions.addNoteTypeStart());

  let api = 'v1/note/type';
  let postData = 'org_id=' + params.org_id;
  postData += "&name=" + params.name;

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD NOTE_TYPE SUCCESS", response.data);
    yield put(actions.addNoteTypeSuccess(response.data));
  } catch (err) {
    // console.log("ADD NOTE_TYPE FAILED", err.response)
    yield put(actions.addNoteTypeFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* editNoteTypeSaga(params) {
  yield put(actions.editNoteTypeStart());

  let putData = "name=" + params.name;

  const api = 'v1/note/type/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT NOTE_TYPE SUCCESS", response.data);
    yield put(actions.editNoteTypeSuccess(response.data));
  } catch (err) {
    // console.log("EDIT NOTE_TYPE FAILED", err.response);
    yield put(actions.editNoteTypeFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* deleteNoteTypeSaga(params) {
  yield put(actions.deleteNoteTypeStart());

  const api = 'v1/note/type/' + params.id;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE NOTE_TYPE SUCCESS", response.data);
    yield put(actions.deleteNoteTypeSuccess(response.data.result));
  } catch (err) {
    // console.log("DELETE NOTE_TYPE FAILED", err.response);
    yield put(actions.deleteNoteTypeFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* getDocumentsSaga(params) {
  yield put(actions.actionStart(types.GET_DOCUMENTS_START));

  let api = 'v1/note/document';
  api += '?org_id=' + params.org_id;
  api += params.start ? '&start=' + params.start.getTime() : '';
  api += params.end ? '&end=' + params.end.getTime() : '';

  let offset = params.offset ? params.offset : 0;
  let limit = params.limit ? params.limit : 100;

  let documents = [];
  while (true) {
    try {
      const response = yield axios.get(api + '&offset=' + offset + '&limit=' + limit);
      let resp = response.data;
      if (resp.result === 'failed') {
        // console.log('GET DOCUMENTS FAILED', resp);
        yield put(actions.defaultActionDocumentFinish(types.GET_DOCUMENTS_FAIL, 'failed', resp.error));
        break;
      }
      offset += resp.note_documents.length;
      documents = documents.concat(resp.note_documents);
      if (resp.note_documents.length < limit)
        break;
    } catch (err) {
      // console.log('GET DOCUMENTS FAILED WITH ERROR', err);
      yield put(actions.defaultActionDocumentFinish(types.GET_DOCUMENTS_FAIL, 'failed', err.response ? err.response.data.error : 'Something went wrong'));
      break;
    }
  }

  // console.log('GET DOCUMENTS SUCCESS', documents);
  yield put(actions.getCreateOrUpdateDocumentsSuccess(types.GET_DOCUMENTS_SUCCESS, documents));
}

export function* createDocumentSaga(params) {
  yield put(actions.actionStart(types.CREATE_DOCUMENT_START));

  let api = 'v1/note/document';
  let postData = "org_id=" + params.org_id;
  postData += "&type_id=" + params.type_id;
  postData += "&title=" + encodeURIComponent(params.title);
  postData += "&content=" + encodeURIComponent(params.content);
  postData += "&ref=" + encodeURIComponent(params.ref);
  postData += "&event_uuid=" + encodeURIComponent(params.event_uuid);
  postData += "&attachments=" + encodeURIComponent(JSON.stringify(params.attachments));
  postData += "&options=" + encodeURIComponent(JSON.stringify(params.options));


  try {
    const response = yield axios.post(api, postData);
    let resp = response.data;
    if (resp.result === 'failed') {
      // console.log('CREATE DOCUMENT FAILED', resp);
      yield put(actions.defaultActionDocumentFinish(types.CREATE_DOCUMENT_FAIL, 'failed', resp.error));
    } else {
      // console.log("CREATE DOCUMENT SUCCESS", resp);
      yield put(actions.getCreateOrUpdateDocumentsSuccess(types.CREATE_DOCUMENT_SUCCESS, resp.note_documents));
    }
  } catch (err) {
    // console.log("CREATE DOCUMENT FAILED WITH ERROR", err)
    yield put(actions.defaultActionDocumentFinish(types.CREATE_DOCUMENT_FAIL, 'failed', err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* updateDocumentSaga(params) {
  yield put(actions.actionStart(types.UPDATE_DOCUMENT_START));

  let api = 'v1/note/document/' + params.uuid;
  let postData = "title=" + encodeURIComponent(params.title);
  postData += "&content=" + encodeURIComponent(params.content);
  postData += "&ref=" + encodeURIComponent(params.ref);
  postData += "&event_uuid=" + encodeURIComponent(params.event_uuid);
  postData += "&attachments=" + encodeURIComponent(JSON.stringify(params.attachments));
  postData += "&options=" + encodeURIComponent(JSON.stringify(params.options));

  try {
    const response = yield axios.put(api, postData);
    let resp = response.data;
    if (resp.result === 'failed') {
      // console.log('UPDATE DOCUMENT FAILED', resp);
      yield put(actions.defaultActionDocumentFinish(types.UPDATE_DOCUMENT_FAIL, 'failed', resp.error));
    } else {
      // console.log("UPDATE DOCUMENT SUCCESS", resp);
      yield put(actions.getCreateOrUpdateDocumentsSuccess(types.UPDATE_DOCUMENT_SUCCESS, resp.note_documents));
    }
  } catch (err) {
    // console.log("UPDATE DOCUMENT FAILED WITH ERROR", err)
    yield put(actions.defaultActionDocumentFinish(types.UPDATE_DOCUMENT_FAIL, 'failed', err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* deleteDocumentSaga(params) {
  yield put(actions.actionStart(types.DELETE_DOCUMENT_START));

  let api = 'v1/note/document/' + params.uuid;

  try {
    const response = yield axios.delete(api);
    let resp = response.data;
    if (resp.result === 'failed') {
      // console.log('DELETE DOCUMENT FAILED', resp);
      yield put(actions.defaultActionDocumentFinish(types.DELETE_DOCUMENT_FAIL, 'failed', resp.error));
    } else {
      // console.log("DELETE DOCUMENT SUCCESS", resp);
      yield put(actions.defaultActionDocumentFinish(types.DELETE_DOCUMENT_SUCCESS, 'success', null));
    }
  } catch (err) {
    // console.log("DELETE DOCUMENT FAILED WITH ERROR", err)
    yield put(actions.defaultActionDocumentFinish(types.DELETE_DOCUMENT_FAIL, 'failed', err.response ? err.response.data.error : 'Something went wrong'));
  }
}