import React, { Component } from 'react';

import styles from './DeviceEquationArea.module.scss';

class DeviceEquationArea extends Component {
  state = {
    eq: '',
    optionsOpened: false
  }

  componentDidMount() {
    this.setState({ eq: this.props.value });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value && prevProps.value !== this.props.value) {
      this.setState({ eq: this.props.value });
    }
  }

  onTextChange = event => {
    const text = event.target.value;

    if (text.endsWith("=")) {
      this.setState({ optionsOpened: true });
    } else {
      this.setState({ optionsOpened: false });
    }
    this.props.setFormula(text);
    this.setState({ eq: text });
  }

  pressDelete = e => {
    if (e.key === 'Backspace') {
      let eq = this.state.eq;
      const lastChar = eq.substring(eq.length - 1, eq.length);
      if (lastChar === '`') {
        let foundIndex;
        for (let i = eq.length - 2; i >= 0; i--) {
          if (eq[i] === '`') {
            foundIndex = i;
            break;
          }
        }
        const afterDeleteText = eq.slice(0, foundIndex + 1);
        this.setState({ eq: afterDeleteText });
      }
    }
  }

  onSelectOption = (option) => {
    let eq = this.state.eq.slice(0, -1);
    // eq += '`' + option.name + '`';
    eq += '{rt:' + option.id + '}';
    if (option.equation && option.equation.length) {
      option.equation.forEach(op => {
        if (op.operation) {
          eq += op.operation === 'x' ? '*' : op.operation;
          eq += op.value;
        }
      });
    }
    this.eqInput.focus();
    this.props.setFormula(eq);
    this.setState({ eq, optionsOpened: false });
  }

  render() {
    return (
      <div className={styles.FormGroup}>
        <textarea ref={(input) => { this.eqInput = input; }}
          className={styles.Textarea}
          rows='3'
          value={this.state.eq}
          onChange={this.onTextChange}
          // onKeyDown={this.pressDelete}
        />
        {
          this.state.optionsOpened ?
            <div className={styles.OptionsWrapper}>
              {
                this.props.deviceOptions.map(device => (
                  <div key={`option-*-${device.id}`} className={styles.Option} onClick={() => this.onSelectOption(device)}>
                    {device.name}
                  </div>
                ))
              }
            </div>
            : null
        }
      </div>
    );
  }
}

export default DeviceEquationArea;
