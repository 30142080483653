import React, { Component } from 'react';
import { SortableElement, SortableContainer, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import MultipleSelect from 'react-select';

import styles from './PlanStructure.module.scss';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import DeleteIcon from '../../../../assets/icons/delete-red-bin.svg';
import AddColIcon from '../../../../assets/icons/plus-gray.svg';
import DelColIcon from '../../../../assets/icons/minus-gray.svg';
import DragIcon from '../../../../assets/icons/navigation-items/submenu-gray.svg';
import Tick from '../../../../assets/icons/tick-square.svg';
import Untick from '../../../../assets/icons/untick-square.png';

import { updateObject, checkValidity } from '../../../../state/utils';

const colourStyles = {
	control: (base, state) => ({
		...base,
		backgroundColor: "#1c1c1f",
		borderRadius: 4,
		borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
		"&:hover": {
			borderColor: "#1665d8"
		},
		fontSize: 14,
		fontWeight: 500,
		color: 'white'
	}),
	input: base => ({
		...base,
		color: 'white'
	}),
	singleValue: (styles) => ({
		...styles,
		color: "white"
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			borderColor: "#1c1c1f",
			backgroundColor: isDisabled
				? null
				: isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
		};
	},
	menu: base => ({
		...base,
		marginTop: 0
	}),
	menuList: base => ({
		...base,
		backgroundColor: "#1c1c1f",
		fontSize: 14,
		fontWeight: 500
	}),
	multiValue: base => ({
		...base,
		backgroundColor: "#1665d8",
	}),
	multiValueLabel: base => ({
		...base,
		color: "white",
	})
};

const colStructureItem = ({ col, removeAt, order, changeColumnName }) => {
	function onRemoveHandler(e) {
		e.preventDefault();
		removeAt(order);
	}

	return <div key={col.name} className={`${styles.ColBox} ${styles.Editable}`}>
		<DragHandle />
		{col.editable
			? <>
				<Input
					name="newcol"
					type="text"
					placeholder="New column"
					value={col.display_name}
					onChange={e => changeColumnName(order, e.target.value)}
					overidestyleformgroup={{ marginBottom: 0, marginRight: 5, minWidth: 80 }}
				/>
				{col.removable && <img src={DelColIcon} alt="Del col" className={styles.ColIcon} onClick={(e) => onRemoveHandler(e)} />}
			</>
			: <div className={styles.ColumnName}>{col.display_name}</div>
		}
	</div>
}

const colStructureList = ({ items, removeColumn, changeColumnName }) => {
	return (
		<div className={styles.ColsWrapper}>
			{items && items.length && items.map((x, i) => {
				return <SortableItem
					col={x}
					order={i}
					removeAt={(index) => removeColumn(index)}
					changeColumnName={(index, value) => changeColumnName(index, value)}
					index={i}
					key={x.name}
				/>
			})}
		</div>
	)
};

const DragHandle = sortableHandle(() => <div className={styles.DragHandle}><img src={DragIcon} style={{ width: 10, height: 10 }} alt="Drag icon" /></div>);
const SortableItem = SortableElement(colStructureItem);
const SortableList = SortableContainer(colStructureList);

const DEFAULT_STRUCTURE_FORM = {
	name: {
		value: '',
		valid: false,
		touched: false,
		validation: {
			required: true
		}
	},
	detail: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: false
		}
	}
};

class AddNewStructure extends Component {
	state = {
		structureForm: DEFAULT_STRUCTURE_FORM,
		formIsValid: false,
		colsIsValid: true,
		currentColumns: [],
		columnsOption: [],
		displayingColumns: [],
		uniquePo: false
	};

	componentDidMount() {
		if (this.props.isAdding) {
			let currentColumns = [];
			currentColumns[0] = { name: 'po', display_name: 'PO' };
			currentColumns[1] = { name: 'start', display_name: 'Start' };
			currentColumns[2] = { name: 'end', display_name: 'End' };
			currentColumns[3] = { name: 'target', display_name: 'Target' };
			currentColumns[4] = { name: 'machine', display_name: 'Machine' };
			this.setState({ currentColumns });
			this.props.updatingStructureForm(false, currentColumns);
		} else if (this.props.structure) {
			this.loadCurrentColumns();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isEditing && prevProps.isEditing !== this.props.isEditing) {
			this.setupForm(this.props.structure);
			this.setupOption(null);
		}
	}

	loadCurrentColumns = () => {
		if (this.props.structure) {
			const { columns, po_column_order, start_column_order, end_column_order, target_column_order, machine_column_order } = this.props.structure;
			const currentColumns = [];
			currentColumns[po_column_order - 1] = { name: 'po', display_name: 'PO' };
			currentColumns[start_column_order - 1] = { name: 'start', display_name: 'Start' };
			currentColumns[end_column_order - 1] = { name: 'end', display_name: 'End' };
			currentColumns[target_column_order - 1] = { name: 'target', display_name: 'Target' };
			currentColumns[machine_column_order - 1] = { name: 'machine', display_name: 'Machine' };
			if (columns && columns.length) {
				columns.forEach(col => currentColumns[col.order - 1] = { name: col.name, display_name: col.display_name, editable: true, removable: this.props.structure.plans_count === 0 ? true : false });
			}

			this.setState({ currentColumns });
		}
	}

	setupForm = (structure) => {
		let updatedForm = { ...this.state.structureForm };
		let updatedElement = {};
		for (let inputIdentifier in updatedForm) {
			updatedElement = updateObject(updatedForm[inputIdentifier], {
				value: structure[inputIdentifier] ? structure[inputIdentifier] : '',
				valid: true,
				touched: true,
			});
			updatedForm = updateObject(updatedForm, {
				[inputIdentifier]: updatedElement
			});
		}

		this.props.onUpdateAllHandler(
			updatedForm,
			this.state.currentColumns,
			structure.properties && structure.properties.displayingColumns ? structure.properties.displayingColumns : null,
			structure.properties && structure.properties.unique_po ? structure.properties.unique_po : null
		);

		let uniquePo = false;
		if (structure.properties && structure.properties.unique_po) uniquePo = structure.properties.unique_po;

		this.setState({ structureForm: updatedForm, formIsValid: true, uniquePo });
	}

	setupOption = (updatedColumns) => {
		let columnsOption = [];
		let currentColumns = updatedColumns ? updatedColumns : this.state.currentColumns;
		currentColumns.forEach(c => {
			if (c.name !== 'po') {
				columnsOption.push({
					id: c.name,
					label: c.display_name
				});
			}
		});

		const { structure } = this.props;
		let displayingColumns = [];
		if (structure.properties && structure.properties.displayingColumns && structure.properties.displayingColumns.length) {
			structure.properties.displayingColumns.forEach(dc => {
				const found = columnsOption.find(co => co.id === dc.id);
				if (found) displayingColumns.push(found);
			});
		}
		this.setState({ columnsOption, displayingColumns });
	}

	onSortEnd = (e) => {
		let currentColumns = [...this.state.currentColumns];
		currentColumns = arrayMove(this.state.currentColumns, e.oldIndex, e.newIndex);
		this.props.updatingStructureForm(false, currentColumns);
		this.setState({ currentColumns });
	};

	onFormChangeHandler = (e) => {
		let newStructureForm = { ...this.state.structureForm };
		let updatedElement = updateObject(newStructureForm[e.target.name], {
			value: e.target.value,
			valid: checkValidity(e.target.value, newStructureForm[e.target.name].validation),
			touched: true
		});

		const updatedForm = updateObject(this.state.structureForm, {
			[e.target.name]: updatedElement
		});

		let formIsValid = true;
		for (let inputIdentifier in updatedForm) {
			formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
		}
		this.props.updatingStructureForm(true, updatedForm);
		this.setState({ structureForm: updatedForm, formIsValid });
	}

	checkAddingColsAreGood = (cols) => {
		return cols.find(col => col.display_name === '') === undefined;
	}

	addNewColHandler = () => {
		let updatedColumns = [...this.state.currentColumns];
		updatedColumns.push({ name: 'NEW_COL_' + (updatedColumns.length + 1), display_name: '', editable: true, removable: true });
		this.setState({ currentColumns: updatedColumns, colsIsValid: false });
		this.props.updatingStructureForm(false, updatedColumns);
	}

	onCancelHandler = () => {
		this.loadCurrentColumns();
		this.props.cancel();
	}

	onRemoveColumnHandler = (deletedIndex) => {
		let updatedColumns = [...this.state.currentColumns];
		updatedColumns.splice(deletedIndex, 1);

		let colsIsValid = this.checkAddingColsAreGood(updatedColumns);
		this.setState({ currentColumns: updatedColumns, colsIsValid });
		this.props.updatingStructureForm(false, updatedColumns);
	}

	onChangeColNameHandler = (index, value) => {
		let updatedColumns = [...this.state.currentColumns];
		updatedColumns[index].display_name = value;

		let colsIsValid = this.checkAddingColsAreGood(updatedColumns);
		this.setState({ currentColumns: updatedColumns, colsIsValid });
		this.setupOption(updatedColumns);
		this.props.updatingStructureForm(false, updatedColumns);
	}

	onDisplayColumnsChangeHandler = (value) => {
		this.setState({ displayingColumns: value });
		if (!value) {
			this.setState({ displayingColumns: [] });
		}
		this.props.updatingStructureForm(false, null, value, this.state.uniquePo);
	}

	toggleUniquePoHandler = (e) => {
		e.preventDefault();
		this.setState(prevState => {
			return {
				uniquePo: !prevState.uniquePo
			};
		});
		this.props.updatingStructureForm(false, null, this.state.displayingColumns, !this.state.uniquePo);
	}

	render() {
		const { name, detail } = this.state.structureForm;
		const { structure, isAdding, isEditing } = this.props;

		return (
			<div className={styles.StructureWrapper}>
				<div className={styles.HeaderWrapper}>
					<div style={{ flex: 1, display: 'flex', alignItems: 'baseline', flexDirection: 'column' }}>
						{
							isAdding || isEditing
								? <>
									<div style={{ flex: 1, display: 'flex' }}>
										<div style={{ width: 200, marginRight: 15 }}>
											<Input
												name="name"
												type="text"
												placeholder="Structure name *"
												value={name.value}
												onChange={e => { this.onFormChangeHandler(e) }}
												overidestyleformgroup={{ marginBottom: 0 }}
											/>
										</div>
										<div style={{ width: 300 }}>
											<Input
												name="detail"
												type="text"
												placeholder="Detail"
												value={detail.value}
												onChange={e => { this.onFormChangeHandler(e) }}
												overidestyleformgroup={{ marginBottom: 0 }}
											/>
										</div>
									</div>
									{
										isEditing
											? <div style={{ flex: 1, width: 515, marginTop: 10 }}>
												<MultipleSelect
													isMulti
													isSearchable
													closeMenuOnSelect={false}
													options={this.state.columnsOption}
													styles={colourStyles}
													placeholder="Select columns to display on Dashboard"
													value={this.state.displayingColumns}
													onChange={this.onDisplayColumnsChangeHandler}
													getOptionValue={(opt) => opt.id}
												/>
											</div>
											: null
									}
									{
										isEditing
											? <div style={{ flex: 1, width: 515, marginTop: 10, display: 'flex' }}>
												{
													this.state.uniquePo
														? <img style={{ width: 18, marginRight: 10 }} src={Tick} alt="checked" onClick={(e) => this.toggleUniquePoHandler(e)} />
														: <img style={{ width: 18, marginRight: 10 }} src={Untick} alt="unchecked" onClick={(e) => this.toggleUniquePoHandler(e)} />
												}
												<div style={{ fontSize: 14, fontWeight: '500' }}>Is PO name unique ?</div>
											</div>
											: null
									}

								</>
								: <>
									<div className={styles.Name}>{structure.name}</div>
									<div className={styles.Detail}>{structure.detail}</div>
								</>
						}

					</div>
					<div>
						{
							isAdding || isEditing
								? <div style={{ display: 'flex' }}>
									<div style={{ width: 100 }}>
										<Button type="button" name="Cancel" color="borderred" click={this.onCancelHandler} noMargin />
									</div>
									<div style={{ width: 150, marginLeft: 15 }}>
										<Button type="button" name={isAdding ? 'Done' : 'Save'} color={isAdding ? 'Primary' : 'Green'} click={(e) => this.props.saveNewStructure(isAdding ? 'add' : 'edit', e)} noMargin disabled={!this.state.formIsValid || !this.state.colsIsValid} />
									</div>
								</div>
								: <>
									<img src={EditIcon} alt="Edit Struture" className={styles.Icons} onClick={() => this.props.setEditingIndex(this.props.structureIndex)} />
									{structure.plans_count === 0 && <img src={DeleteIcon} alt="Delete Struture" className={styles.Icons} style={{ marginLeft: 25 }} onClick={() => this.props.deleteStructure(structure.uuid)} />}
								</>
						}

					</div>
				</div>
				<div className={styles.StructureTable}>
					{
						isAdding
							? <>
								<SortableList
									axis="x"
									items={this.state.currentColumns}
									useDragHandle
									removeColumn={(deletedIndex) => this.onRemoveColumnHandler(deletedIndex)}
									changeColumnName={(index, value) => this.onChangeColNameHandler(index, value)}
									onSortEnd={(e) => this.onSortEnd(e)}
								/>
								<div style={{ display: 'flex' }}>
									<img src={AddColIcon} alt="Add col" className={styles.ColIcon} onClick={this.addNewColHandler} />
								</div>
							</>
							: isEditing
								? <>
									<SortableList
										axis="x"
										items={this.state.currentColumns}
										useDragHandle
										removeColumn={(deletedIndex) => this.onRemoveColumnHandler(deletedIndex)}
										changeColumnName={(index, value) => this.onChangeColNameHandler(index, value)}
										onSortEnd={(e) => this.onSortEnd(e)}
									/>
									<div style={{ display: 'flex' }}>
										<img src={AddColIcon} alt="Add col" className={styles.ColIcon} onClick={() => this.addNewColHandler('edit')} />
									</div>
								</>
								: <div className={styles.ColsWrapper}>
									{
										this.state.currentColumns && this.state.currentColumns.map(col => (
											<div key={`${structure.name}--${col.name}`} className={`${styles.ColBox}`}>
												<div className={styles.ColumnName}>{col.display_name}</div>
											</div>
										))
									}
								</div>
					}
				</div>
				{isAdding && <div className={styles.Notice}>* The column name will be automatically changed to capital letters.</div>}
				{isEditing && <div className={styles.Notice}>* Note: If there are some plans, you can not modify the current columns.</div>}
			</div>
		);
	}
}

export default AddNewStructure;
