import React, { Component } from "react";
import Modal from "../../UI/Modal/Modal";
import styles from "./CalibrationModal.module.scss";
import Button from '../../UI/Button/Button';

export default class CalibrationModal extends Component {
  render() {
    let ovStyle = { width: 470, minHeight: 320, overflow: "auto" };
    return (
      <Modal
        show={this.props.open}
        modalClosed={this.props.modalClosed}
        overideStyles={ovStyle}
      >
        <div className={styles.CalibrationModal}>
          <div className={styles.HeaderWrapper}>
            <div className={styles.Header}>
              Calibration Process
            </div>
          </div>

          <div className={styles.BodyWrapper}>
            <div>Calibration may takes sereval hours or longer depending on amount of data and complexity of pattern. During calibration Anomaly Detection will not operate until calibration is completed.</div>
          </div>

          <div className={styles.FooterWrapper}>
            <Button type="button" color="primary" name="Proceed" noMargin click={this.props.confirmCalibrate} overideButtonStyles={{ width: 150 }} />
            <Button type="button" color="borderred" name="Cancel" noMargin click={this.props.modalClosed} overideButtonStyles={{ width: 150 }} />
          </div>
        </div>
      </Modal>
    );
  }
}
