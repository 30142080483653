import * as actionTypes from './types';

// ----------------------- GET VARIABLES -----------------------
export const getVariablesStart = () => {
  return {
    type: actionTypes.GET_VARIABLES_START
  };
}

export const getVariables = (org_id) => {
  return {
    type: actionTypes.GET_VARIABLES,
    org_id
  };
}

export const getVariablesSuccess = (response) => {
  return {
    type: actionTypes.GET_VARIABLES_SUCCESS,
    variables: response.variables
  };
}

export const getVariablesFail = (error) => {
  return {
    type: actionTypes.GET_VARIABLES_FAIL,
    error
  };
}

// ----------------------- ADD VARIABLE -----------------------
export const addVariableStart = () => {
  return {
    type: actionTypes.ADD_VARIABLE_START
  };
}

export const addVariable = (org_id, name, value, properties) => {
  return {
    type: actionTypes.ADD_VARIABLE,
    org_id, name, value, properties
  };
}

export const addVariableSuccess = (response) => {
  return {
    type: actionTypes.ADD_VARIABLE_SUCCESS,
    addedVariable: response.variables[0],
    result: response.result
  };
}

export const addVariableFail = (error) => {
  return {
    type: actionTypes.ADD_VARIABLE_FAIL,
    error
  };
}

// ----------------------- EDIT VARIABLE -----------------------
export const editVariableStart = () => {
  return {
    type: actionTypes.EDIT_VARIABLE_START
  };
}

export const editVariable = (id,  name, value, properties) => {
  return {
    type: actionTypes.EDIT_VARIABLE,
    id,  name, value, properties
  };
}

export const editVariableSuccess = (response) => {
  return {
    type: actionTypes.EDIT_VARIABLE_SUCCESS,
    updatedVariable: response.variables[0],
    result: response.result
  };
}

export const editVariableFail = (error) => {
  return {
    type: actionTypes.EDIT_VARIABLE_FAIL,
    error
  };
}

// ----------------------- DELETE VARIABLE -----------------------
export const deleteVariableStart = () => {
  return {
    type: actionTypes.DELETE_VARIABLE_START
  };
}

export const deleteVariable = (id) => {
  return {
    type: actionTypes.DELETE_VARIABLE,
    id
  };
}

export const deleteVariableSuccess = (result) => {
  return {
    type: actionTypes.DELETE_VARIABLE_SUCCESS,
    result
  };
}

export const deleteVariableFail = (error) => {
  return {
    type: actionTypes.DELETE_VARIABLE_FAIL,
    error
  };
}
