import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  moduleConfigs: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MODULE_CONFIGS_START: return getModuleConfigsStart(state, action);
    case actionTypes.GET_MODULE_CONFIGS_SUCCESS: return getModuleConfigsSuccess(state, action);
    case actionTypes.GET_MODULE_CONFIGS_FAIL: return getModuleConfigsFail(state, action);

    case actionTypes.POST_MODULE_CONFIG_START: return postModuleConfigStart(state, action);
    case actionTypes.POST_MODULE_CONFIG_SUCCESS: return postModuleConfigSuccess(state, action);
    case actionTypes.POST_MODULE_CONFIG_FAIL: return postModuleConfigFail(state, action);

    case actionTypes.POST_MODULE_CONFIG_JSON_START: return postModuleConfigJsonStart(state, action);
    case actionTypes.POST_MODULE_CONFIG_JSON_SUCCESS: return postModuleConfigJsonSuccess(state, action);
    case actionTypes.POST_MODULE_CONFIG_JSON_FAIL: return postModuleConfigJsonFail(state, action);

    default: return state;
  }
};

const getModuleConfigsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getModuleConfigsSuccess = (state, action) => {
  return updateObject(state, {
    moduleConfigs: action.moduleConfigs,
    error: null,
    loading: false
  });
}

const getModuleConfigsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const postModuleConfigStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const postModuleConfigSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    moduleConfigs: action.moduleConfigs,
    error: null,
    loading: false
  });
}

const postModuleConfigFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const postModuleConfigJsonStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const postModuleConfigJsonSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    moduleConfigs: action.moduleConfigs,
    error: null,
    loading: false
  });
}

const postModuleConfigJsonFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;