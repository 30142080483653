
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_MODULE_CONFIGS, POST_MODULE_CONFIG, POST_MODULE_CONFIG_JSON } from './types';

export function* watchModuleConfigs() {
  yield takeEvery(GET_MODULE_CONFIGS, getModuleConfigsSaga);
  yield takeEvery(POST_MODULE_CONFIG, postModuleConfigSaga);
  yield takeEvery(POST_MODULE_CONFIG_JSON, postModuleConfigJsonSaga);
}

export function* getModuleConfigsSaga(params) {
  yield put(actions.getModuleConfigsStart());

  let api = 'v1/moduleconfig';
  api += '?org_id=' + params.org_id;
  api += params.module_id ? '&module_id=' + params.module_id : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET MODULE CONFIGS SUCCESS", response.data);
    yield put(actions.getModuleConfigsSuccess(response.data));
  } catch (err) {
    // console.log("GET MODULE CONFIGSS FAILED", err)
    yield put(actions.getModuleConfigsFail(err && err.response ? err.response.error : 'Something went wrong'));
  }
}

export function* postModuleConfigSaga(params) {
  yield put(actions.postModuleConfigStart());

  let api = 'v1/moduleconfig';
  let postData = "org_id=" + params.org_id;
  postData += params.module_id ? "&module_id=" + params.module_id : '';
  postData += params.key ? "&key=" + params.key : '';
  postData += params.value ? "&value=" + encodeURIComponent(params.value) : '';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD MODULE CONFIGS SUCCESS", response.data);
    yield put(actions.postModuleConfigSuccess(response.data));
  } catch (err) {
    yield put(actions.postModuleConfigFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* postModuleConfigJsonSaga(params) {
  yield put(actions.postModuleConfigJsonStart());

  let api = 'v1/moduleconfig/json';

  try {
    const response = yield axios.post(api, JSON.stringify(params.body), {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    // console.log("POST JSON MODULE CONFIGS SUCCESS", response.data);
    yield put(actions.postModuleConfigJsonSuccess(response.data));
  } catch (err) {
    yield put(actions.postModuleConfigJsonFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}