import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import Select from 'react-select';

import styles from './ProductionLogDrawer.module.scss';
import { updateObject } from '../../../../state/utils';
import Button from '../../UI/Button/Button';
import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';
import Input from '../../UI/Input/Input';
import BreakdownCodeTreeSelection from '../BreakdownCodeTreeSelection/BreakdownCodeTreeSelection';

import { addDocumentNote, editDocumentNote } from '../../../../state/ducks/Document/actions';

const colourStyles = {
	control: (base, state) => ({
		...base,
		backgroundColor: "#1c1c1f",
		borderRadius: 4,
		borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
		"&:hover": {
			borderColor: "#1665d8"
		},
		fontSize: 14,
		fontWeight: 500,
		color: 'white'
	}),
	input: base => ({
		...base,
		color: 'white'
	}),
	singleValue: (styles) => ({
		...styles,
		color: "white"
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			borderColor: "#1c1c1f",
			backgroundColor: isDisabled
				? null
				: isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
		};
	},
	menu: base => ({
		...base,
		marginTop: 0
	}),
	menuList: base => ({
		...base,
		backgroundColor: "#1c1c1f",
		fontSize: 14,
		fontWeight: 500
	}),
	multiValue: base => ({
		...base,
		backgroundColor: "#1665d8",
	}),
	multiValueLabel: base => ({
		...base,
		color: "white",
	})
};

const DEFAULT_PRODUCTION_LOG_FORM = {
	title: {
		value: '',
		valid: false,
		touched: false,
		validation: {
			required: true
		}
	},
	startDate: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	startTime: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	endDate: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	endTime: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	machine: {
		value: '',
		valid: false,
		touched: false,
		validation: {
			required: true
		}
	},
	codes: {
		value: '',
		valid: false,
		touched: false,
		validation: {
			required: true
		}
	},
	content: {
		value: '',
		valid: false,
		touched: false,
		validation: {
			required: true
		}
	}
};

class ProductionLogDrawer extends Component {
	state = {
		formIsValid: true,
		productionLogForm: DEFAULT_PRODUCTION_LOG_FORM,
		machinesOption: []
	}

	componentDidMount() {
		if (this.props.machines) {
			this.setMachinesOption(this.props.machines);
		}

		if (this.props.isAdding) {
			this.setupNewForm();
		} else if (this.props.editingLog) {
			this.setupForm(this.props.editingLog);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isAdding !== this.props.isAdding && this.props.isAdding) {
			this.setupNewForm();
		} else if (this.props.machines !== prevProps.machines && this.props.machines) {
			this.setMachinesOption(this.props.machines);
		} else if (prevProps.result !== this.props.result && this.props.result === 'success' && this.props.mainTab === 0) {
			if (this.props.updatedDocumentNote !== prevProps.updatedDocumentNote || this.props.addedDocumentNote !== prevProps.addedDocumentNote) {
				this.setupNewForm();
				this.props.prodDocDrawerClosed();
			}
		} else if (this.props.editingLog && prevProps.editingLog !== this.props.editingLog) {
			this.setupForm(this.props.editingLog);
		}
	}

	onClearFields = () => {
		this.setState({ productionLogForm: DEFAULT_PRODUCTION_LOG_FORM, formIsValid: false });
	}

	setMachinesOption = (machines) => {
		const machinesOption = [];
		machines.forEach(machine => {
			machinesOption.push({
				id: machine.id,
				label: machine.name
			});
		});
		this.setState({ machinesOption });
	}

	setupNewForm = () => {
		let now = moment().valueOf();
		const productionLogForm = { ...DEFAULT_PRODUCTION_LOG_FORM };
		for (let inputIdentifier in productionLogForm) {
			if (inputIdentifier === 'startDate') {
				productionLogForm.startDate.value = moment(now).format('YYYY-MM-DD');
			} else if (inputIdentifier === 'startTime') {
				productionLogForm.startTime.value = moment(now).format('HH:mm');
			} else if (inputIdentifier === 'endDate') {
				productionLogForm.endDate.value = moment(now).format('YYYY-MM-DD');
			} else if (inputIdentifier === 'endTime') {
				productionLogForm.endTime.value = moment(now).add(1, 'hours').format('HH:mm');
			} else {
				productionLogForm[inputIdentifier].value = '';
				productionLogForm[inputIdentifier].valid = false;
				productionLogForm[inputIdentifier].touched = false;
			}
		}
		this.setState({ productionLogForm, formIsValid: false });
	}

	setupForm = (log) => {
		let updatedForm = { ...DEFAULT_PRODUCTION_LOG_FORM };
		let form = { ...log };
		for (let inputIdentifier in updatedForm) {
			if (!form[inputIdentifier]) {
				form = log.option;
			}
			let updatedElement = updateObject(updatedForm[inputIdentifier], {
				value: form[inputIdentifier] ? form[inputIdentifier] : '',
				valid: true,
				touched: true,
			});

			updatedForm = updateObject(updatedForm, {
				[inputIdentifier]: updatedElement
			});
			form = log;
		}
		this.setState({ productionLogForm: updatedForm, formIsValid: true });

	}

	handleChange = (e) => {
		const { name, value } = e.target;
		const updatedElement = updateObject(this.state.productionLogForm[name], {
			value,
			valid: name === 'codes' && value && !value.length ? false : true,
			touched: true
		});

		const updatedForm = updateObject(this.state.productionLogForm, {
			[name]: updatedElement
		});

		let formIsValid = true;
		for (let inputIdentifier in updatedForm) {
			formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({ productionLogForm: updatedForm, formIsValid });
	}

	humanizeDuration = (ms, showSec) => {
		const days = moment.duration(ms).days();
		const hours = moment.duration(ms).hours();
		const minutes = moment.duration(ms).minutes();
		const seconds = moment.duration(ms).seconds();

		let result = days === 0 ? '' : days > 1 ? days + ' days ' : days + ' day ';
		result += hours === 0 ? '' : hours > 1 ? hours + ' hours ' : hours + ' hour ';
		result += minutes === 0 ? '' : minutes > 1 ? minutes + ' minutes ' : minutes + ' minute ';
		if (showSec) {
			result += seconds === 0 ? '' : seconds > 1 ? seconds + ' seconds ' : seconds + ' second';
		}
		return result;
	}

	onConfirmSaveProdDocHandler = (event) => {
		event.preventDefault();
		const { title, startDate, startTime, endDate, endTime, machine, codes, content } = this.state.productionLogForm;
		const foundType = this.props.noteTypes.find(nt => nt.name.toLowerCase() === 'production');
		if (this.props.isAdding) {
			this.props.addDocumentNote(
				this.props.currentOrg,
				foundType.id,
				title.value,
				content.value,
				{
					startDate: startDate.value,
					startTime: startTime.value,
					endDate: endDate.value,
					endTime: endTime.value,
					machine: machine.value,
					codes: codes.value,
					ref: 'actual_uuid'
				},
				this.props.selectingActual.uuid
			);
		} else {
			this.props.editDocumentNote(
				this.props.editingLog.uuid,
				foundType.id,
				title.value,
				content.value,
				{
					startDate: startDate.value,
					startTime: startTime.value,
					endDate: endDate.value,
					endTime: endTime.value,
					machine: machine.value,
					codes: codes.value,
					ref: 'actual_uuid'
				},
				this.props.selectingActual.uuid
			);
		}
	}

	render() {
		const { title, startDate, startTime, endDate, endTime, machine, codes, content } = this.state.productionLogForm;
		const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

		return (
			<div>
				<form onSubmit={this.onConfirmSaveProdDocHandler}>
					<Input
						label="Log name"
						name="title"
						type="text"
						value={title.value}
						autoComplete="off"
						onChange={this.handleChange}
						required
					/>

					<div className={styles.FormGroup}>
						<label className={styles.Title}>Start *</label>
						<div style={{ display: 'flex', position: 'relative' }}>
							{!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
							<input
								className={styles.SelectBox}
								style={{ paddingLeft: !isChrome ? 46 : null, flex: 2, position: 'initial' }}
								type="date"
								onChange={this.handleChange}
								name="startDate"
								value={startDate.value}
							/>
							<input
								className={styles.SelectBox}
								style={{ position: 'initial' }}
								type="time"
								onChange={this.handleChange}
								name="startTime"
								value={startTime.value}
							/>
						</div>
					</div>

					<div className={styles.FormGroup}>
						<label className={styles.Title}>End *</label>
						<div style={{ display: 'flex', position: 'relative' }}>
							{!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
							<input
								className={styles.SelectBox}
								style={{ paddingLeft: !isChrome ? 46 : null, flex: 2, position: 'initial' }}
								type="date"
								onChange={this.handleChange}
								name="endDate"
								value={endDate.value}
							/>
							<input
								className={styles.SelectBox}
								style={{ position: 'initial' }}
								type="time"
								onChange={this.handleChange}
								name="endTime"
								value={endTime.value}
							/>
						</div>
					</div>

					<div className={styles.FormGroup}>
						<label className={styles.Title}>Machine *</label>
						<Select isSearchable options={this.state.machinesOption} styles={colourStyles}
							placeholder="Please select machine"
							value={machine.value}
							onChange={(value) => this.handleChange({ target: { name: 'machine', value } })}
							getOptionValue={opt => opt.id}
						/>
					</div>

					<div className={styles.FormGroup}>
						<label className={styles.Title}>Notes *</label>
						<Input
							type='textarea'
							rows='3'
							name='content'
							value={content.value}
							onChange={this.handleChange}
						/>
					</div>

					<div className={styles.FormGroup}>
						<label className={styles.Title}>Code *</label>
						{
							this.props.selectingActual
								? <BreakdownCodeTreeSelection
									selectingNoteType={this.props.noteTypes ? this.props.noteTypes.find(nt => nt.name.toLowerCase() === 'production') : null}
									codes={codes.value}
									saveCodes={(value) => this.handleChange({ target: { name: 'codes', value } })}
									scannerText={this.props.scannerText}
									toggleScannerHandler={this.props.toggleScannerHandler}
								/>
								: null
						}
					</div>

					<div style={{ height: 70, width: 'auto' }} />
					{
						this.props.isAdding
							? <>
								<Button
									type="submit"
									name={this.props.eventLoading ? 'Loading...' : 'Done'}
									color="primary"
									disabled={!this.state.formIsValid}
									loading={this.props.eventLoading}
								/>
								<Button type="button" name="Cancel" color="borderred" click={this.props.prodDocDrawerClosed} />
							</>
							: <>
								<Button
									type="submit"
									name={this.props.eventLoading ? 'Loading...' : 'Save'}
									color="green"
									disabled={!this.state.formIsValid}
									loading={this.props.eventLoading}
								/>
								<Button type="button" name="Cancel" color="borderred" click={this.props.prodDocDrawerClosed} />
							</>
					}
				</form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { currentOrg } = state.org;
	const { addedDocumentNote, updatedDocumentNote, loading, error, result, type, noteTypes } = state.document;

	return {
		currentOrg, machines: state.machine.machinesAndActuals, addedDocumentNote, updatedDocumentNote, loading, error, result, type, noteTypes,
		eventLoading: state.event.loading
	};
};

export default connect(mapStateToProps, { addDocumentNote, editDocumentNote })(ProductionLogDrawer);