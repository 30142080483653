export const GET_RULES_START = 'GET_RULES_START';
export const GET_RULES = 'GET_RULES';
export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS';
export const GET_RULES_FAIL = 'GET_RULES_FAIL';

export const ADD_RULE_START = 'ADD_RULE_START';
export const ADD_RULE = 'ADD_RULE';
export const ADD_RULE_SUCCESS = 'ADD_RULE_SUCCESS';
export const ADD_RULE_FAIL = 'ADD_RULE_FAIL';

export const ENABLE_RULE_START = 'ENABLE_RULE_START';
export const ENABLE_RULE = 'ENABLE_RULE';
export const ENABLE_RULE_SUCCESS = 'ENABLE_RULE_SUCCESS';
export const ENABLE_RULE_FAIL = 'ENABLE_RULE_FAIL';

export const EDIT_RULE_START = 'EDIT_RULE_START';
export const EDIT_RULE = 'EDIT_RULE';
export const EDIT_RULE_SUCCESS = 'EDIT_RULE_SUCCESS';
export const EDIT_RULE_FAIL = 'EDIT_RULE_FAIL';

export const DELETE_RULE_START = 'DELETE_RULE_START';
export const DELETE_RULE = 'DELETE_RULE';
export const DELETE_RULE_SUCCESS = 'DELETE_RULE_SUCCESS';
export const DELETE_RULE_FAIL = 'DELETE_RULE_FAIL';

export const CLEAR_RULE_ERROR = 'CLEAR_RULE_ERROR';

export const GET_ALARM_HISTORIES_START = 'GET_ALARM_HISTORIES_START';
export const GET_ALARM_HISTORIES = 'GET_ALARM_HISTORIES';
export const GET_ALARM_HISTORIES_SUCCESS = 'GET_ALARM_HISTORIES_SUCCESS';
export const GET_ALARM_HISTORIES_FAIL = 'GET_ALARM_HISTORIES_FAIL';