
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_DEVICE_CONFIGS, ADD_DEVICE_CONFIG, DELETE_DEVICE_CONFIGS, GET_SYSTEM_DEVICE_CONFIG, SAVE_SYSTEM_DEVICE_CONFIG, GET_SYSTEM_DEVICE_CONFIG_JSON, SAVE_SYSTEM_DEVICE_CONFIG_JSON } from './types';

export function* watchDeviceConfigs() {
  yield takeEvery(GET_DEVICE_CONFIGS, getDeviceConfigsSaga);
  yield takeEvery(ADD_DEVICE_CONFIG, addDeviceConfigSaga);
  yield takeEvery(DELETE_DEVICE_CONFIGS, deleteDeviceConfigsSaga);
  yield takeEvery(GET_SYSTEM_DEVICE_CONFIG, getSystemDeviceConfigSaga);
  yield takeEvery(SAVE_SYSTEM_DEVICE_CONFIG, saveSystemDeviceConfigSaga);
  yield takeEvery(GET_SYSTEM_DEVICE_CONFIG_JSON, getSystemDeviceConfigJsonSaga);
  yield takeEvery(SAVE_SYSTEM_DEVICE_CONFIG_JSON, saveSystemDeviceConfigJsonSaga);
}

export function* getDeviceConfigsSaga(params) {
  yield put(actions.getDeviceConfigsStart());

  let api = 'v1/deviceconfig?secret=' + params.secret;

  try {
    const response = yield axios.get(api);
    // console.log("GET DEVICE_CONFIGS SUCCESS", response.data);
    yield put(actions.getDeviceConfigsSuccess(response.data));
  } catch (err) {
    console.log("GET DEVICE_CONFIGS FAILED", err)
    yield put(actions.getDeviceConfigsFail(err.response));
  }
}

export function* addDeviceConfigSaga(params) {
  yield put(actions.addDeviceConfigStart());

  const api = 'v1/deviceconfig';
  try {
    let deleteApi = 'v1/deviceconfig?all_modbus=true&secret=' + params.secret;
    try {
      const response = yield axios.delete(deleteApi);
      // console.log("DELETE DEVICE_CONFIGS SUCCESS", response.data);
      yield put(actions.deleteDeviceConfigsSuccess(response.data));
      const addResponse = yield axios.post(api, JSON.stringify(params.jsonData), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      // console.log("ADD DEVICE_CONFIG SUCCESS", addResponse.data);
      yield put(actions.addDeviceConfigSuccess(addResponse.data));
    } catch (err) {
      console.log("DELETE DEVICE_CONFIGS FAILED", err)
      yield put(actions.deleteDeviceConfigsFail(err.response));
    }
  } catch (err) {
    console.log("ADD DEVICE_CONFIG FAILED", err.response.data);
    yield put(actions.addDeviceConfigFail(err.response.data.error));
  }
}

export function* deleteDeviceConfigsSaga() {
  yield put(actions.deleteDeviceConfigsStart());

  let api = 'v1/deviceconfig?all_modbus=true';

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE DEVICE_CONFIGS SUCCESS", response.data);
    yield put(actions.deleteDeviceConfigsSuccess(response.data));
  } catch (err) {
    console.log("DELETE DEVICE_CONFIGS FAILED", err)
    yield put(actions.deleteDeviceConfigsFail(err.response));
  }
}

export function* getSystemDeviceConfigSaga(params) {
  yield put(actions.getSystemDeviceConfigStart());

  let api = 'v1/systemdeviceconfig?secret=' + params.secret;

  try {
    const response = yield axios.get(api);
    // console.log("GET SYSTEM DEVICE CONFIG SUCCESS", response.data);
    yield put(actions.getSystemDeviceConfigSuccess(response.data));
  } catch (err) {
    console.log("GET SYSTEM DEVICE CONFIG FAILED", err)
    yield put(actions.getSystemDeviceConfigFail(err.response));
  }
}

export function* saveSystemDeviceConfigSaga(params) {
  yield put(actions.saveSystemDeviceConfigStart());

  const api = 'v1/systemdeviceconfig/' + params.secret;
  try {
    const response = yield axios.post(api, params.protobuf, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    // console.log("SAVE SYSTEM DEVICE CONFIG SUCCESS", response.data);
    yield put(actions.saveSystemDeviceConfigSuccess(response.data));
  } catch (err) {
    console.log("SAVE SYSTEM DEVICE CONFIG FAILED", err.response.data);
    yield put(actions.saveSystemDeviceConfigFail(err.response.data.error));
  }
}

export function* getSystemDeviceConfigJsonSaga(params) {
  yield put(actions.getSystemDeviceConfigJsonStart());

  let api = 'v1/systemdeviceconfigjson/' + params.secret;

  try {
    const response = yield axios.get(api);
    // console.log("GET SYSTEM DEVICE CONFIG JSON SUCCESS", response.data);
    yield put(actions.getSystemDeviceConfigJsonSuccess({ config: response.data }));
  } catch (err) {
    console.log("GET SYSTEM DEVICE CONFIG JSON FAILED", err)
    yield put(actions.getSystemDeviceConfigJsonFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* saveSystemDeviceConfigJsonSaga(params) {
  yield put(actions.saveSystemDeviceConfigJsonStart());

  const api = 'v1/systemdeviceconfigjson/' + params.secret;
  try {
    const response = yield axios.post(api, JSON.stringify(params.body), {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    // console.log("SAVE SYSTEM DEVICE CONFIG JSON SUCCESS", response.data);
    yield put(actions.saveSystemDeviceConfigJsonSuccess(response.data));
  } catch (err) {
    // console.log("SAVE SYSTEM DEVICE CONFIG FAILED", err.response.data);
    yield put(actions.saveSystemDeviceConfigJsonFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}