import React, { Component } from 'react';
import { connect } from 'react-redux';

import orgStyles from '../Organization.module.scss';
import Button from '../../UI/Button/Button';
import InputLogo from '../../../../assets/icons/Organization/avatar.svg';
import DeleteLogo from '../../../../assets/icons/Organization/delete.svg';
import OrganizationDeleteTooltip from '../../../components/Organization/OrganizationDeleteTooltip/OrganizationDeleteTooltip';
import Select from '../../UI/Select/Select';
import Input from '../../UI/Input/Input';

import { updateObject, checkValidity } from '../../../../state/utils';
import { addOrg, editOrg, deleteOrg, genAPIKey } from '../../../../state/ducks/Organization/actions';
import { GEN_API_KEY_SUCCESS } from '../../../../state/ducks/Organization/types';
import { storage } from '../../../../firebase/index';

const countryOptions = [
  { id: 'Thailand', name: '🇹🇭 Thailand' },
];

const DEFAULT_ORGANIZATION_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  tax_id: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  },
  street_address: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  province: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  country: {
    value: '-1',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  post_number: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  tel: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  },
};

class OrganizationForm extends Component {
  state = {
    formIsValid: false,
    orgForm: DEFAULT_ORGANIZATION_FORM,
    logo: null,
    uploading: false,
    generating: false,
    imgsrc: this.props.editingOrg ? this.props.editingOrg.logo : null,
    deleteOrgTooltip: false,
    apiKey: this.props.editingOrg ? this.props.editingOrg.api_key : '',
  };

  componentDidMount() {
    if (this.props.editingOrg) {
      this.setupForm(this.props.editingOrg);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.result !== prevProps.result && this.props.result === "success") {
      if (this.props.type === GEN_API_KEY_SUCCESS) {
        this.setState({generating: false, apiKey: this.props.newapikey});
        return;
      }
      if (this.props.isAdding) {
        this.props.modalClosed();
      }
      this.props.toggleModeHandler();
      this.onClearFields();
    }
  }

  onClearFields = () => {
    this.setState({ orgForm: DEFAULT_ORGANIZATION_FORM, formIsValid: false });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.orgForm };
    for (let inputIdentifier in updatedForm) {
      const updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
      });
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });

      this.setState({ orgForm: updatedForm, formIsValid: true });
    }
  }

  handleChange = event => {
    let updatedElement = updateObject(this.state.orgForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.orgForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.orgForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ orgForm: updatedForm, formIsValid });
  };

  onSelectFile = (event) => {
    if (this.state.imgsrc) {
      URL.revokeObjectURL(this.state.imgsrc);
    }
    this.setState({
      imgsrc: URL.createObjectURL(event.target.files[0]),
      logo: event.target.files[0]
    });

    event.target.value = null;
  }

  onDeleteLogoHandler = () => {
    if (this.state.imgsrc) {
      URL.revokeObjectURL(this.state.imgsrc);
      this.setState({ logo: '', imgsrc: null });
    }
  }

  onSubmitOrgHandler = (event) => {
    event.preventDefault();
    const { logo } = this.state;

    if (logo && logo !== '') {
      const uploadTask = storage.ref(`images/${logo.name}`).put(logo);
      uploadTask.on('state_changed',
        (snapshot) => {
          this.setState({ uploading: true })
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage.ref('images').child(logo.name).getDownloadURL().then(url => {
            this.setState({ uploading: false });
            this.addOrDeleteOrganization(url);
          });
        });
    }
    else {
      // not change logo or no logo (it will be NULL or '')
      this.addOrDeleteOrganization(logo);
    }
  }

  addOrDeleteOrganization = (orgLogo) => {
    const { name, tax_id, street_address, post_number, province, country, tel } = this.state.orgForm;
    if (this.props.isAdding) {
      this.props.addOrg(name.value, street_address.value, province.value, country.value, post_number.value, tax_id.value, tel.value, orgLogo);
    } else {
      this.props.editOrg(this.props.editingOrg.id, name.value, street_address.value, province.value, country.value, post_number.value, tax_id.value, tel.value, orgLogo);
    }
  }

  onOpenDeleteOrgTooltipHandler = () => {
    this.setState({ deleteOrgTooltip: true })
  }

  onCloseDeleteOrgTooltipHandler = () => {
    this.setState({ deleteOrgTooltip: false })
  }

  onDeleteOrg = (event) => {
    event.preventDefault();
    if (this.props.editingOrg) {
      this.props.deleteOrg(this.props.editingOrg.id);
      this.onCloseDeleteOrgTooltipHandler();
    }
  }

  onGenerateAPIKey = (event) => {
    if (this.state.generating)
      return;
    this.setState({ generating: true});
    this.props.genAPIKey(this.props.editingOrg.id);
  }

  render() {
    const { name, tax_id, street_address, post_number, province, country, tel } = this.state.orgForm;
    return (
      <div className={orgStyles.OrganizationModal}>
        <form onSubmit={this.onSubmitOrgHandler}>
          <div className={orgStyles.FormWrapper}>
            {
              this.props.error && !this.props.isAdding ?
                <div className={orgStyles.ErrorMessage}>{this.props.error}!</div>
                : null
            }
            <div style={{ display: 'flex' }}>
              <div className={orgStyles.FormGroup} style={{ marginRight: 15 }}>
                <Input
                  label="name"
                  name="name"
                  type="text"
                  value={name.value}
                  onChange={this.handleChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                  error={`${name.touched && !name.valid ? 'Name is required' : ''}`}
                  whitemode="true"
                />
              </div>
              <div className={orgStyles.FormGroup}>
                <Input
                  label="tax id"
                  name="tax_id"
                  type="text"
                  placeholder="(optional)"
                  value={tax_id.value}
                  onChange={this.handleChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                  whitemode="true"
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={orgStyles.FormGroup} style={{ marginRight: 15 }}>
                <Input
                  label="address"
                  name="street_address"
                  type="text"
                  value={street_address.value}
                  onChange={this.handleChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                  error={`${street_address.touched && !street_address.valid ? 'Address is required' : ''}`}
                  whitemode="true"
                />
              </div>
              <div className={orgStyles.FormGroup}>
                <Input
                  label="postal code"
                  name="post_number"
                  type="text"
                  value={post_number.value}
                  onChange={this.handleChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                  error={`${post_number.touched && !post_number.valid ? 'Postal Code is required' : ''}`}
                  whitemode="true"
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={orgStyles.FormGroup} style={{ marginRight: 15 }}>
                <Select
                  label="country"
                  name="country"
                  placeholder="Select a country"
                  value={country.value}
                  options={countryOptions}
                  onChange={this.handleChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                  whitemode />
              </div>
              <div className={orgStyles.FormGroup}>
                <Input
                  label="city"
                  name="province"
                  type="text"
                  value={province.value}
                  onChange={this.handleChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                  error={`${province.touched && !province.valid ? 'City is required' : ''}`}
                  whitemode="true"
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div className={orgStyles.FormGroup} style={{ marginRight: 15 }}>
                <div className={orgStyles.FormAddLogo}>
                  <label htmlFor="logo" className={orgStyles.CustomFileUpload} style={{ display: 'flex' }}>
                    <div>
                      {
                        this.state.imgsrc ?
                          <img src={this.state.imgsrc} alt="uploaded logo" className={orgStyles.ImagePreview} />
                          : <img src={InputLogo} alt="default logo" />
                      }
                    </div>
                    <div className={orgStyles.AddLogo}>
                      {
                        this.state.logo ?
                          this.state.logo.name
                          : this.state.imgsrc ?
                            'Change a Logo' : 'Add a Logo'
                      }
                    </div>
                  </label>
                  <input
                    name="logo"
                    id="logo"
                    type="file"
                    onChange={this.onSelectFile}
                    accept=".png, .jpg, .jpeg"
                  />
                  <div className={orgStyles.ShowSelectedFile}>
                    <img alt="Delete Org Logo" src={DeleteLogo} onClick={this.onDeleteLogoHandler} style={{ cursor: 'pointer' }} />
                  </div>
                </div>
              </div>
              <div className={orgStyles.FormGroup}>
                <Input
                  label="phone number"
                  name="tel"
                  type="text"
                  placeholder="(optional)"
                  value={tel.value}
                  onChange={this.handleChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                  whitemode="true"
                />
              </div>
            </div>

            {this.props.isAdding ? null : 
            <div style={{ display: 'flex' }}>
              <div className={orgStyles.FormGroup} style={{ marginRight: 15 }}>
                <Input
                    label="api key"
                    name="api_key"
                    type="text"
                    placeholder="API Key"
                    value={this.state.apiKey}
                    whitemode="true"
                    disabled={true}
                  />
              </div>
              <div style={{width: 120, marginTop: 26}}>
                <Button
                    type="button"
                    color="white"
                    name={this.state.generating ? "Generating..." : "Generate"}
                    click={this.onGenerateAPIKey}
                    noMargin
                  />
              </div>
            </div>
            }
          </div>
          <div className={orgStyles.ButtonWrapper}>
            {
              this.props.isAdding ?
                <Button
                  type="submit"
                  color="primary"
                  name={this.props.loading || this.state.uploading ? 'Loading...' : 'Done'}
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading || this.state.uploading}
                  noMargin
                />
                :
                <div style={{ textAlign: 'right' }}>
                  <OrganizationDeleteTooltip show={this.state.deleteOrgTooltip} modalClosed={this.onCloseDeleteOrgTooltipHandler}>
                    Are you sure you want to delete this?
                     <div className={orgStyles.TooltipButtonsContainer}>
                      <div className={orgStyles.TooltipButtonWrapper}>
                        <Button
                          type="button"
                          color="white"
                          name="No"
                          click={this.onCloseDeleteOrgTooltipHandler}
                          noMargin
                        />
                      </div>
                      <div className={orgStyles.TooltipButtonWrapper}>
                        <Button
                          type="button"
                          color="red"
                          name="Yes"
                          click={this.onDeleteOrg}
                          noMargin
                        />
                      </div>
                    </div>
                  </OrganizationDeleteTooltip>
                  <div className={`${orgStyles.ActionButton} ${orgStyles.DeleteBtn}`}>
                    <Button
                      type="button"
                      color="borderred"
                      name="Delete"
                      click={this.onOpenDeleteOrgTooltipHandler}
                      disabled={this.props.loading || this.state.uploading}
                      noMargin
                    />
                  </div>
                  <div className={`${orgStyles.ActionButton} ${orgStyles.SaveBtn}`}>
                    <Button
                      type="submit"
                      color="green"
                      name="Save"
                      disabled={!this.state.formIsValid}
                      loading={this.props.loading || this.state.uploading}
                      noMargin
                    />
                  </div>
                </div>
            }
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, result, type, newapikey } = state.org;
  return { loading, error, result, type, newapikey };
};

export default connect(mapStateToProps, { addOrg, editOrg, deleteOrg, genAPIKey })(OrganizationForm);