import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './ResetPassword.module.scss';
import ResetPassPic from '../../../../assets/images/reset-passwprd.png';
import TellYourFriends from '../../../../assets/icons/Auth/send-round-1569.svg';
import PasswordIcon from '../../../../assets/icons/Auth/view-pwd.svg';
import SeePasswordIcon from '../../../../assets/icons/Auth/view-pwd-blue.svg';
import Button from '../../../components/UI/Button/Button';

import { checkValidity, updateObject } from '../../../../state/utils';
import { resetPassword } from '../../../../state/ducks/Auth/actions';

class ResetPassword extends Component {
  state = {
    seePassword: false,
    seeConfirmPassword: false,
    formIsValid: false,
    resetPasswordForm: {
      newpassword: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true,
        }
      },
      confirmpassword: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true,
        }
      },
    }
  }

  handleChange = event => {
    let updatedElement = updateObject(this.state.resetPasswordForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.resetPasswordForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.resetPasswordForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ resetPasswordForm: updatedForm, formIsValid });
  };

  onResetPasswordHandler = (event) => {
    event.preventDefault();
    const { newpassword, confirmpassword } = this.state.resetPasswordForm;
    if (this.state.formIsValid) {
      if (newpassword.value === confirmpassword.value) {
        const token = this.props.match.params.token;
        this.props.resetPassword(token, newpassword.value);
      }
    }
    else {
      this.onSetTouchedFields();
    }
  }

  onSeePasswordHandler = () => {
    this.setState(prevState => {
      return { seePassword: !prevState.seePassword };
    });
  }

  onSeeConfirmPasswordHandler = () => {
    this.setState(prevState => {
      return { seeConfirmPassword: !prevState.seeConfirmPassword };
    });
  }

  goBackToLogin = () => {
    this.props.history.push('/auth');
  }

  onSetTouchedFields = () => {
    let newState = { ...this.state.resetPasswordForm };
    Object.keys(this.state.resetPasswordForm).forEach(name => {
      let updatedElement = updateObject(newState[name], {
        touched: true
      });
      const updatedForm = updateObject(newState, {
        [name]: updatedElement
      });
      newState = updatedForm;
      this.setState({ resetPasswordForm: updatedForm });
    });
  }

  render() {
    const { newpassword, confirmpassword } = this.state.resetPasswordForm;

    return (
      <div className={styles.Auth}>
        <div className={styles.AuthBoxContainer}>
          <div className={styles.ResetPass}>
            <div className={styles.LeftSection}>
              <div className={styles.AuthWrapper}>
                <div style={{ flex: '0 1 90px' }}>
                  <div className={styles.AuthTitle}>Reset Password? <span role="img" aria-label="lock">🔒</span></div>
                  <div className={styles.AuthSubTitle}>
                    Please enter your new password below
                  </div>
                </div>
                <form onSubmit={this.onResetPasswordHandler} className={styles.FormContainer}>
                  <div style={{ flex: 1, marginTop: 40 }}>
                    {newpassword.valid && confirmpassword.valid && newpassword.value !== confirmpassword.value ? <div className={styles.ErrorMessage}>Passwords don't match!</div> : ""}
                    {this.props.error === 'Token not found' ? <div className={styles.ErrorMessage}>Token not found!</div> : ''}
                    <div className={styles.FormGroup}>
                      <label className={styles.Title}>NEW PASSWORD</label>
                      <div style={{ position: 'relative' }}>
                        <input
                          name="newpassword"
                          type={this.state.seePassword ? 'text' : 'password'}
                          className={`${styles.FormField} ${newpassword.touched && !newpassword.valid ? styles.Error : ''}`}
                          value={newpassword.value}
                          onChange={this.handleChange}
                        />
                        <img className={styles.SeePassword} src={this.state.seePassword ? SeePasswordIcon : PasswordIcon} alt="See Password" onClick={this.onSeePasswordHandler} />
                      </div>
                      <div className={`${newpassword.touched && !newpassword.valid ? styles.ErrorText : styles.None}`}>Password is required</div>
                    </div>
                    <div className={styles.FormGroup}>
                      <label className={styles.Title}>CONFIRM PASSWORD</label>
                      <div style={{ position: 'relative' }}>
                        <input
                          name="confirmpassword"
                          type={this.state.seeConfirmPassword ? 'text' : 'password'}
                          className={`${styles.FormField} ${confirmpassword.touched && !confirmpassword.valid ? styles.Error : ''}`}
                          value={confirmpassword.value}
                          onChange={this.handleChange}
                        />
                        <img className={styles.SeePassword} src={this.state.seeConfirmPassword ? SeePasswordIcon : PasswordIcon} alt="See Password" onClick={this.onSeeConfirmPasswordHandler} />
                      </div>
                      <div className={`${confirmpassword.touched && !confirmpassword.valid ? styles.ErrorText : styles.None}`}>Password is required</div>
                    </div>
                    <Button
                      type="submit"
                      color="blue"
                      name={this.props.loading ? 'Loading...' : 'Reset'}
                      loading={this.props.loading}
                      noMargin
                    />
                    <p>Back to <span className={styles.SwapText} onClick={this.goBackToLogin}>Sign In?</span> </p>
                  </div>
                </form>
              </div>
            </div>
            <div className={styles.RightSection} style={{ backgroundImage: `url(${ResetPassPic})`, width: 360 }} >
              <div className={styles.Footer}>
                <p>Tell your friends about us <img alt="Tell your friends" src={TellYourFriends} /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error } = state.auth;
  return { loading, error };
};

export default connect(mapStateToProps, { resetPassword })(ResetPassword);