import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  timelines: null,
  addedTimeline: null,
  updatedTimeline: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TIMELINES_START: return getTimelinesStart(state, action);
    case actionTypes.GET_TIMELINES_SUCCESS: return getTimelinesSuccess(state, action);
    case actionTypes.GET_TIMELINES_FAIL: return getTimelinesFail(state, action);

    case actionTypes.ADD_TIMELINE_START: return addTimelineStart(state, action);
    case actionTypes.ADD_TIMELINE_SUCCESS: return addTimelineSuccess(state, action);
    case actionTypes.ADD_TIMELINE_FAIL: return addTimelineFail(state, action);

    case actionTypes.EDIT_TIMELINE_START: return editTimelineStart(state, action);
    case actionTypes.EDIT_TIMELINE_SUCCESS: return editTimelineSuccess(state, action);
    case actionTypes.EDIT_TIMELINE_FAIL: return editTimelineFail(state, action);

    case actionTypes.DELETE_TIMELINE_START: return deleteTimelineStart(state, action);
    case actionTypes.DELETE_TIMELINE_SUCCESS: return deleteTimelineSuccess(state, action);
    case actionTypes.DELETE_TIMELINE_FAIL: return deleteTimelineFail(state, action);

    default: return state;
  }
};

const getTimelinesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getTimelinesSuccess = (state, action) => {
  const timelines = action.timelines ? action.timelines.sort((a, b) => a.id - b.id) : null;
  return updateObject(state, {
    timelines,
    error: null,
    loading: false
  });
}

const getTimelinesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addTimelineStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addTimelineSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    addedTimeline: action.addedTimeline,
    error: null,
    loading: false
  });
}

const addTimelineFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editTimelineStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editTimelineSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    updatedTimeline: action.updatedTimeline,
    error: null,
    loading: false
  });
}

const editTimelineFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteTimelineStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteTimelineSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteTimelineFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;