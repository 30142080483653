import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';

import styles from './UsageEnergyLine.module.scss';

const COLORS = [
    '#F2CC56', '#AAE05B', '#6BC981', '#88D8FB', '#467EE5', '#9155D9', '#DD70DA', '#DA615C', '#EC9245', '#828282',
    '#AFA272', '#C0E18C', '#8DCAA0', '#ADDCF0', '#75A5F8', '#CAA7F5', '#F2A9F2', '#F09491', '#F5BD86', '#DBDBDB',
    '#C6A646', '#9DAF47', '#539664', '#67A0B8', '#536C95', '#5C41B8', '#994EBD', '#B04742', '#B65F37', '#A6A6A6',
    '#FBE74D', '#E2FE52', '#77FB4C', '#53B7F0', '#395BF6', '#7915F5', '#EA33DE', '#EA3323', '#EE7F31', '#5F657F'
];

class UsageEnergyLine extends Component {
    state = {
        chartData: null,
        options: null,
        allRawDataset: [],
        loading: false
    }

    componentDidMount() {
        this.setupData();
        if (this.props.forReport && this.props.allRawDataset) {
            if (this.props.isChildren) {
                this.preparingData([...this.props.allRawDataset].filter((ar => ar.properties.group === this.props.childIndex)));
            } else {
                this.preparingData(this.props.allRawDataset);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.currentOrg !== prevProps.currentOrg && this.props.currentOrg) {
            this.setState({ chartData: null, options: null, allRawDataset: [] });
        } else if (!_.isEqual(prevProps.selectedChild, this.props.selectedChild) && this.props.selectedChild) {
            this.setState({ allRawDataset: [], chartData: { datasets: [], labels: [] }, options: null });
        } else if (this.props.queryPeriod && prevProps.queryPeriod !== this.props.queryPeriod) {
            this.setState({ allRawDataset: [], chartData: { datasets: [], labels: [] }, options: null, loading: true });
        } else if (this.props.selectedDate !== prevProps.selectedDate && this.props.selectedDate) {
            this.setState({ allRawDataset: [] });
        }
        if (prevProps.powers !== this.props.powers && this.props.powers) {
            const { properties } = this.props.powers;
            let loading = true;
            if (properties.rt) {
                // console.log(this.props.powers)
            } else {
                let allRawDataset = [...this.state.allRawDataset];
                if (allRawDataset.length === 0) {
                    allRawDataset = new Array(this.props.amountLeaves).fill(undefined);
                }
                allRawDataset[properties.order - 1] = this.props.powers;

                const isAnyUndefined = allRawDataset.filter(ar => !ar);
                if (!isAnyUndefined.length && allRawDataset.length === this.props.amountLeaves) {
                    loading = false;
                    if (this.props.forReport && this.props.isChildren) {
                        this.preparingData(allRawDataset.filter((ar => ar.properties.group === this.props.childIndex)));
                    } else {
                        this.preparingData(allRawDataset);
                    }
                }
                // console.log(allRawDataset)
                this.setState({ allRawDataset, loading });
            }
        }
    }

    isFuture = (t) => {
        return moment(t).valueOf() > moment().valueOf();
    }

    isToday = (t) => {
        if (this.props.queryPeriod !== 'd') return false;
        return moment(t).isSame(moment(), 'day');
    }

    preparingData = (allRawDataset) => {
        const chartData = [];
        const timestamp = [];
        const names = [];

        allRawDataset.forEach((rawData, i) => {
            if (rawData) {
                if (!this.props.isChildren && (rawData.properties.group || rawData.properties.group === 0)) {
                    // case: group devices: Machine 
                    chartData[rawData.properties.group] = chartData[rawData.properties.group] ? chartData[rawData.properties.group] : [];
                    names[rawData.properties.group] = this.props.selectedRelation.children[rawData.properties.group].value.title;
                    if (rawData.data && rawData.data.length) {
                        let timeNow = moment().format('HH:mm');
                        let floorMin = (timeNow.split(":")[1])[0] + '0';
                        let skipThisDataTime = timeNow.split(":")[0] + ':' + floorMin;
                        rawData.data.forEach((v, j) => {
                            let t = moment(v.timestamp).format('HH:mm');
                            let showingTime = moment(v.timestamp).add(10, 'minutes').format('HH:mm');
                            if (!chartData[rawData.properties.group][j]) chartData[rawData.properties.group][j] = 0; // init
                            if (this.isFuture(v.timestamp)) {
                                chartData[rawData.properties.group][j] = null;
                            } else if (skipThisDataTime === t && this.isToday(v.timestamp)) {
                                chartData[rawData.properties.group][j] = null;
                            } else { chartData[rawData.properties.group][j] += v.production + v.non_production; }
                            if (i === 0) {
                                if (this.props.queryPeriod === 'm') showingTime = moment(v.timestamp).format('DD');
                                else if (this.props.queryPeriod === 'y') showingTime = moment(v.timestamp).format('MM/YY');
                                timestamp.push(showingTime);
                            }
                        });
                    }
                } else {
                    chartData[i] = [];
                    if (!rawData.properties.infos.value) {
                        names[i] = rawData.properties.infos.label;
                    } else {
                        names[i] = rawData.properties.infos.value.title;
                    }
                    if (rawData.data && rawData.data.length) {
                        rawData.data.forEach((v, j) => {
                            if (this.isFuture(v.timestamp)) chartData[i].push(null);
                            else chartData[i].push((v.production + v.non_production));
                            if (i === 0) {
                                let t = moment(v.timestamp).format('HH:mm');
                                let showingTime = moment(v.timestamp).add(10, 'minutes').format('HH:mm');
                                if (this.props.queryPeriod === 'm') t = moment(v.timestamp).format('DD');
                                else if (this.props.queryPeriod === 'y') t = moment(v.timestamp).format('MM/YY');
                                timestamp.push(showingTime);
                            }
                        });
                    }
                }
            }
        });

        chartData.forEach((ds, i) => {
            if (this.props.queryPeriod === 'm') {
                // ds.splice(0, 1);
                // ds.splice(ds.length - 1, 1);
            }
            ds.forEach((value, j) => {
                chartData[i][j] = value || value === 0 ? Number(value.toFixed(2)) : null;
            });
        });
        if (this.props.queryPeriod === 'm') {
            // timestamp.splice(0, 1);
            // timestamp.splice(timestamp.length - 1, 1);
        }

        this.setupData(names, chartData, timestamp);
    }

    setupOptions = (timestamp) => {
        const options = {
            interaction: {
                mode: "nearest"
            },
            responsive: true,
            tooltips: {
                mode: 'index',
                intersect: false,
                xAlign: 'left',
                yAlign: 'center',
                // callbacks: {
                //     label: (tooltipItem, data) => {
                //         if (tooltipItem.datasetIndex === 1) {
                //             return this.humanizeDuration(tooltipItem.value, true) + ' (' + paretoData.percent[tooltipItem.index] + '%)';
                //         } else {
                //             return tooltipItem.value + '%';
                //         }
                //     }
                // }
            },
            legend: {
                position: 'bottom',
                align: 'start',
                labels: {
                    fontColor: this.props.forReport ? 'rgb(0, 0, 0)' : 'rgba(100, 100, 100)'
                }
            },
            hover: {
                mode: 'index',
                intersect: false
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        gridLines: {
                            display: true,
                            color: this.props.forReport ? 'white' : '',
                            zeroLineColor: 'rgba(100, 100, 100)'
                        },
                        labels: timestamp,
                        ticks: {
                            fontColor: "",
                        }
                    }
                ],
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            display: true,
                            color: 'rgba(100, 100, 100, 0.25)',
                            zeroLineColor: 'rgba(100, 100, 100)'
                        },
                        labels: {
                            show: true
                        },
                        ticks: {
                            fontColor: this.props.forReport ? 'black' : '',
                            minTicksLimit: 10,
                            beginAtZero: true,
                            //     callback: (value, index, values) => {
                            //         return this.humanizeDuration(value);
                            //     }
                        }
                    }
                ]
            }
        }
        this.setState({ options });
    }

    setupData = (names, preparedChartData, timestamp) => {
        this.setupOptions(timestamp)
        const datasets = [];
        if (preparedChartData && preparedChartData.length) {
            preparedChartData.forEach((pcd, i) => {
                datasets[i] = {
                    label: names[i],
                    data: pcd,
                    fill: false,
                    borderColor: COLORS[i],
                    backgroundColor: COLORS[i],
                    pointRadius: 0,
                    pointBackgroundColor: COLORS[i],
                    lineTension: 0
                };
            });
        }
        let chartData = { datasets };

        this.setState({ chartData });
    }

    render() {

        return (
            <div className={styles.ChartWrapper} style={{ backgroundColor: this.props.forReport ? 'white' : '#222226' }}>
                {
                    this.props.queryPeriod === 'd'
                        ? <div className={styles.Header} style={{ color: this.props.forReport ? 'black' : 'inherit' }}>kWh <span style={{ color: this.props.forReport ? 'black' : 'inherit' }}>(Usage by machine)</span></div>
                        : this.props.queryPeriod === 'm'
                            ? <div className={styles.Header} style={{ color: this.props.forReport ? 'black' : 'inherit' }}>kWh <span style={{ color: this.props.forReport ? 'black' : 'inherit' }}>(Daily Average)</span></div>
                            : <div className={styles.Header} style={{ color: this.props.forReport ? 'black' : 'inherit' }}>kWh <span style={{ color: this.props.forReport ? 'black' : 'inherit' }}>(Monthly Average)</span></div>
                }
                {
                    this.state.loading
                        ? <div className={styles.LoadingText}>Loading...</div>
                        : this.state.chartData && this.state.options
                            ? <Line data={this.state.chartData} height={this.props.forReport ? this.props.isParent ? 65 : 80 : 50} options={this.state.options} />
                            : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { powers } = state.power;

    return {
        currentOrg, powers
    };
};

export default connect(mapStateToProps, {})(UsageEnergyLine);
