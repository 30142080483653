import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './MasterDataDrawer.module.scss';
import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

import { addDocMasterData, editDocMasterData } from '../../../../state/ducks/DocumentMasterData/actions';

const DEFAULT_MASTER_DATA_FORM = {
  code: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  title: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  description: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  }
};

class MasterDataDrawer extends Component {
  state = {
    formIsValid: false,
    masterDataForm: DEFAULT_MASTER_DATA_FORM
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAdding && (prevProps.isAdding !== this.props.isAdding)) {
      this.onClearFields();
    } else if (prevProps.result !== this.props.result && this.props.result === 'success') {
      if (this.props.updatedDocMasterData !== prevProps.updatedDocMasterData || this.props.addedDocMasterData !== prevProps.addedDocMasterData) {
        this.props.closed();
        this.onClearFields();
      }
    } else if (this.props.editingMasterData && prevProps.editingMasterData !== this.props.editingMasterData) {
      this.setupForm(this.props.editingMasterData);
    }
  }

  onClearFields = () => {
    this.setState({ masterDataForm: DEFAULT_MASTER_DATA_FORM, formIsValid: false });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.masterDataForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
      });

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
      this.setState({ masterDataForm: updatedForm, formIsValid: true });
    }
  }

  handleTextChange = event => {
    let updatedElement = updateObject(this.state.masterDataForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.masterDataForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.masterDataForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ masterDataForm: updatedForm, formIsValid });
  };

  onSubmitDocMasterDataHandler = (event) => {
    event.preventDefault();
    const { code, title, description } = this.state.masterDataForm;
    if (this.props.isAdding) {
      this.props.addDocMasterData(this.props.currentOrg, this.props.docType.id, code.value, title.value, description.value, this.props.parentNode ? this.props.parentNode.currentParentUuid : '');
    } else {
      this.props.editDocMasterData(this.props.editingMasterData.uuid, code.value, title.value, description.value, this.props.editingMasterData.parent_uuid);
    }
  }

  render() {
    const { code, title, description } = this.state.masterDataForm;

    return (
      <div className={styles.DocMasterDataDrawer}>
        {this.props.error && <div className="ErrorText">{this.props.error.text}</div>}
        <form onSubmit={this.onSubmitDocMasterDataHandler}>
          <Input
            label="Type"
            name="type"
            type="text"
            value={this.props.docType ? this.props.docType.name : ''}
            disabled
            required
          />
          <Input
            label="code"
            name="code"
            type="text"
            value={code.value}
            placeholder="Please insert code"
            autoComplete="off"
            onChange={this.handleTextChange}
            error={`${code.touched && !code.valid ? 'Breakdown Code is required' : ''}`}
            required
          />
          <Input
            label="Title"
            name="title"
            type="text"
            value={title.value}
            placeholder="Please insert title"
            autoComplete="off"
            onChange={this.handleTextChange}
            error={`${title.touched && !title.valid ? 'Title is required' : ''}`}
            required
          />
          <Input
            label="Description"
            name="description"
            type="text"
            value={description.value}
            placeholder="What is this about ?"
            onChange={this.handleTextChange}
          />
          {
            this.props.parentNode
              ? <div><span className={styles.Path}>PATH : </span>
                <span className={styles.PathValue}>
                  {this.props.editingMasterData
                    ? this.props.editingMasterData.path
                    : this.props.parentNode.keyTitle}
                  <span className={styles.NewPathValue}>{title.value ? title.value : '(new)'}</span>
                </span>
              </div>
              : null
          }

          <div style={{ height: 50, width: 'auto' }} />
          {
            this.props.isAdding
              ? <>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Done'}
                  color="primary"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
              </>
              : this.props.editingMasterData
                ? <>
                  <Button
                    type="submit"
                    name={this.props.loading ? 'Loading...' : 'Save'}
                    color="green"
                    disabled={!this.state.formIsValid}
                    loading={this.props.loading}
                  />
                  <Button type="button" name="Delete" color="borderred" disabled={this.props.editingMasterData.childs && this.props.editingMasterData.childs.length > 0} click={this.props.toggleDeleteModal} />
                </>
                : null
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { addedDocMasterData, updatedDocMasterData, loading, error, result, type } = state.docMasterData;

  return {
    currentOrg, addedDocMasterData, updatedDocMasterData, loading, error, result, type
  };
};

export default connect(mapStateToProps, { addDocMasterData, editDocMasterData })(MasterDataDrawer);