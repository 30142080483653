export const GET_TIMELINES_START = 'GET_TIMELINES_START';
export const GET_TIMELINES = 'GET_TIMELINES';
export const GET_TIMELINES_SUCCESS = 'GET_TIMELINES_SUCCESS';
export const GET_TIMELINES_FAIL = 'GET_TIMELINES_FAIL';

export const ADD_TIMELINE_START = 'ADD_TIMELINE_START';
export const ADD_TIMELINE = 'ADD_TIMELINE';
export const ADD_TIMELINE_SUCCESS = 'ADD_TIMELINE_SUCCESS';
export const ADD_TIMELINE_FAIL = 'ADD_TIMELINE_FAIL';

export const EDIT_TIMELINE_START = 'EDIT_TIMELINE_START';
export const EDIT_TIMELINE = 'EDIT_TIMELINE';
export const EDIT_TIMELINE_SUCCESS = 'EDIT_TIMELINE_SUCCESS';
export const EDIT_TIMELINE_FAIL = 'EDIT_TIMELINE_FAIL';

export const DELETE_TIMELINE_START = 'DELETE_TIMELINE_START';
export const DELETE_TIMELINE = 'DELETE_TIMELINE';
export const DELETE_TIMELINE_SUCCESS = 'DELETE_TIMELINE_SUCCESS';
export const DELETE_TIMELINE_FAIL = 'DELETE_TIMELINE_FAIL';