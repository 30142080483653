import React, { Component } from 'react';

import styles from './Text.module.scss';
import chartStyles from '../Chart.module.scss';
import { calculateNewValFromEquation } from '../../../../../state/utils';

class Text extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if ((nextProps.data || nextProps.data === 0) && this.props.data !== nextProps.data) {
      return true;
    } else if (nextProps.lastUpdated !== this.props.lastUpdated) {
      return true;
    }
    return false;
  }

  render() {
    const { data, widget, id, lastUpdated } = this.props;
    const textStyle = [styles.Value];
    if (data && data.toFixed(2).length > 5 && +data % 1 !== 0) {
      textStyle.push(styles.Small);
    }

    let displayedValue = data;
    if (data) {
      if (data.toString().includes(".00") || !data.toString().includes(".")) {
        displayedValue = data.toLocaleString();
      } else {
        displayedValue = data.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 });
      }
    }

    return (
      <div id={id} className={styles.Text}>
        <div className={textStyle.join(' ')}>
          {
            data || data === 0 ?
              widget.formula ?
                // data.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })
                displayedValue
                : calculateNewValFromEquation(data, widget.equation)
              : lastUpdated ?
                <div className={chartStyles.NoData}></div>
                : <span className={chartStyles.LoadingText}>Loading...</span>
          }
        </div>
        <div className={styles.Unit}>
          {
            data || data === 0 ?
              widget.customUnit ?
                widget.customUnit : widget.unit ?
                  widget.unit : ''
              : ''
          }
        </div>
      </div>
    );
  }
}

export default Text;