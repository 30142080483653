export const GET_TIMELINE_STATUSES_START = 'GET_TIMELINE_STATUSES_START';
export const GET_TIMELINE_STATUSES = 'GET_TIMELINE_STATUSES';
export const GET_TIMELINE_STATUSES_SUCCESS = 'GET_TIMELINE_STATUSES_SUCCESS';
export const GET_TIMELINE_STATUSES_FAIL = 'GET_TIMELINE_STATUSES_FAIL';

export const ADD_TIMELINE_REMARK_START = 'ADD_TIMELINE_REMARK_START';
export const ADD_TIMELINE_REMARK = 'ADD_TIMELINE_REMARK';
export const ADD_TIMELINE_REMARK_SUCCESS = 'ADD_TIMELINE_REMARK_SUCCESS';
export const ADD_TIMELINE_REMARK_FAIL = 'ADD_TIMELINE_REMARK_FAIL';

export const EDIT_TIMELINE_REMARK_START = 'EDIT_TIMELINE_REMARK_START';
export const EDIT_TIMELINE_REMARK = 'EDIT_TIMELINE_REMARK';
export const EDIT_TIMELINE_REMARK_SUCCESS = 'EDIT_TIMELINE_REMARK_SUCCESS';
export const EDIT_TIMELINE_REMARK_FAIL = 'EDIT_TIMELINE_REMARK_FAIL';

export const GET_PARETO_DATA_START = 'GET_PARETO_DATA_START';
export const GET_PARETO_DATA = 'GET_PARETO_DATA';
export const GET_PARETO_DATA_SUCCESS = 'GET_PARETO_DATA_SUCCESS';
export const GET_PARETO_DATA_FAIL = 'GET_PARETO_DATA_FAIL';

export const CLEAR_TIMELINE_STATUSES = 'CLEAR_TIMELINE_STATUSES';