/* eslint-disable */
// source: owlmsg.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.pb.Command', null, global);
goog.exportSymbol('proto.pb.FirmwareData', null, global);
goog.exportSymbol('proto.pb.FirmwareInfo', null, global);
goog.exportSymbol('proto.pb.OwlAnalogInput', null, global);
goog.exportSymbol('proto.pb.OwlAnalogInputType', null, global);
goog.exportSymbol('proto.pb.OwlCellularConfig', null, global);
goog.exportSymbol('proto.pb.OwlEdgeConfig', null, global);
goog.exportSymbol('proto.pb.OwlModbusAction', null, global);
goog.exportSymbol('proto.pb.OwlModbusActionType', null, global);
goog.exportSymbol('proto.pb.OwlModbusDataType', null, global);
goog.exportSymbol('proto.pb.OwlModbusInput', null, global);
goog.exportSymbol('proto.pb.OwlModbusOrder', null, global);
goog.exportSymbol('proto.pb.OwlModbusSerialConfig', null, global);
goog.exportSymbol('proto.pb.OwlMsg', null, global);
goog.exportSymbol('proto.pb.OwlMsgType', null, global);
goog.exportSymbol('proto.pb.OwlSystemCommand', null, global);
goog.exportSymbol('proto.pb.OwlTimestampData', null, global);
goog.exportSymbol('proto.pb.OwlWiFiAndEthernetConfig', null, global);
goog.exportSymbol('proto.pb.OwlXbeeConfig', null, global);
goog.exportSymbol('proto.pb.TimestampFuso', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Command = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.Command, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.Command.displayName = 'proto.pb.Command';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.OwlAnalogInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.OwlAnalogInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.OwlAnalogInput.displayName = 'proto.pb.OwlAnalogInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.OwlModbusAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.OwlModbusAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.OwlModbusAction.displayName = 'proto.pb.OwlModbusAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.OwlModbusInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.OwlModbusInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.OwlModbusInput.displayName = 'proto.pb.OwlModbusInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.OwlWiFiAndEthernetConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.OwlWiFiAndEthernetConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.OwlWiFiAndEthernetConfig.displayName = 'proto.pb.OwlWiFiAndEthernetConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.OwlXbeeConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.OwlXbeeConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.OwlXbeeConfig.displayName = 'proto.pb.OwlXbeeConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.OwlCellularConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.OwlCellularConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.OwlCellularConfig.displayName = 'proto.pb.OwlCellularConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.OwlEdgeConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.OwlEdgeConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.OwlEdgeConfig.displayName = 'proto.pb.OwlEdgeConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.OwlTimestampData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.OwlTimestampData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.OwlTimestampData.displayName = 'proto.pb.OwlTimestampData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.FirmwareInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.FirmwareInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.FirmwareInfo.displayName = 'proto.pb.FirmwareInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.FirmwareData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.FirmwareData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.FirmwareData.displayName = 'proto.pb.FirmwareData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.TimestampFuso = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.TimestampFuso.repeatedFields_, null);
};
goog.inherits(proto.pb.TimestampFuso, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.TimestampFuso.displayName = 'proto.pb.TimestampFuso';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.OwlMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.OwlMsg.repeatedFields_, null);
};
goog.inherits(proto.pb.OwlMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.OwlMsg.displayName = 'proto.pb.OwlMsg';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.Command.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.Command.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.Command} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Command.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    socket: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFieldWithDefault(msg, 3, 0),
    result: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Command}
 */
proto.pb.Command.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.Command;
  return proto.pb.Command.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Command} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Command}
 */
proto.pb.Command.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSocket(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Command.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.Command.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Command} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Command.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSocket();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getResult();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.pb.Command.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Command} returns this
 */
proto.pb.Command.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 socket = 2;
 * @return {number}
 */
proto.pb.Command.prototype.getSocket = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Command} returns this
 */
proto.pb.Command.prototype.setSocket = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 value = 3;
 * @return {number}
 */
proto.pb.Command.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Command} returns this
 */
proto.pb.Command.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool result = 4;
 * @return {boolean}
 */
proto.pb.Command.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.Command} returns this
 */
proto.pb.Command.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.OwlAnalogInput.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.OwlAnalogInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.OwlAnalogInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlAnalogInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    delay: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.OwlAnalogInput}
 */
proto.pb.OwlAnalogInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.OwlAnalogInput;
  return proto.pb.OwlAnalogInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.OwlAnalogInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.OwlAnalogInput}
 */
proto.pb.OwlAnalogInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.pb.OwlAnalogInputType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.OwlAnalogInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.OwlAnalogInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.OwlAnalogInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlAnalogInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDelay();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.pb.OwlAnalogInput.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlAnalogInput} returns this
 */
proto.pb.OwlAnalogInput.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OwlAnalogInputType type = 2;
 * @return {!proto.pb.OwlAnalogInputType}
 */
proto.pb.OwlAnalogInput.prototype.getType = function() {
  return /** @type {!proto.pb.OwlAnalogInputType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pb.OwlAnalogInputType} value
 * @return {!proto.pb.OwlAnalogInput} returns this
 */
proto.pb.OwlAnalogInput.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 delay = 3;
 * @return {number}
 */
proto.pb.OwlAnalogInput.prototype.getDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlAnalogInput} returns this
 */
proto.pb.OwlAnalogInput.prototype.setDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.OwlModbusAction.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.OwlModbusAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.OwlModbusAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlModbusAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pb_function: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reg: jspb.Message.getFieldWithDefault(msg, 3, 0),
    length: jspb.Message.getFieldWithDefault(msg, 4, 0),
    value: msg.getValue_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.OwlModbusAction}
 */
proto.pb.OwlModbusAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.OwlModbusAction;
  return proto.pb.OwlModbusAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.OwlModbusAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.OwlModbusAction}
 */
proto.pb.OwlModbusAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pb.OwlModbusActionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFunction(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReg(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLength(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.OwlModbusAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.OwlModbusAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.OwlModbusAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlModbusAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFunction();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getReg();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional OwlModbusActionType type = 1;
 * @return {!proto.pb.OwlModbusActionType}
 */
proto.pb.OwlModbusAction.prototype.getType = function() {
  return /** @type {!proto.pb.OwlModbusActionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pb.OwlModbusActionType} value
 * @return {!proto.pb.OwlModbusAction} returns this
 */
proto.pb.OwlModbusAction.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 function = 2;
 * @return {number}
 */
proto.pb.OwlModbusAction.prototype.getFunction = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlModbusAction} returns this
 */
proto.pb.OwlModbusAction.prototype.setFunction = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 reg = 3;
 * @return {number}
 */
proto.pb.OwlModbusAction.prototype.getReg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlModbusAction} returns this
 */
proto.pb.OwlModbusAction.prototype.setReg = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 length = 4;
 * @return {number}
 */
proto.pb.OwlModbusAction.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlModbusAction} returns this
 */
proto.pb.OwlModbusAction.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bytes value = 5;
 * @return {!(string|Uint8Array)}
 */
proto.pb.OwlModbusAction.prototype.getValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes value = 5;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.pb.OwlModbusAction.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.pb.OwlModbusAction.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pb.OwlModbusAction} returns this
 */
proto.pb.OwlModbusAction.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.OwlModbusInput.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.OwlModbusInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.OwlModbusInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlModbusInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pb_function: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reg: jspb.Message.getFieldWithDefault(msg, 4, 0),
    length: jspb.Message.getFieldWithDefault(msg, 5, 0),
    order: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dataType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ip: jspb.Message.getFieldWithDefault(msg, 8, ""),
    port: jspb.Message.getFieldWithDefault(msg, 9, 0),
    action: (f = msg.getAction()) && proto.pb.OwlModbusAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.OwlModbusInput}
 */
proto.pb.OwlModbusInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.OwlModbusInput;
  return proto.pb.OwlModbusInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.OwlModbusInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.OwlModbusInput}
 */
proto.pb.OwlModbusInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFunction(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReg(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLength(value);
      break;
    case 6:
      var value = /** @type {!proto.pb.OwlModbusOrder} */ (reader.readEnum());
      msg.setOrder(value);
      break;
    case 7:
      var value = /** @type {!proto.pb.OwlModbusDataType} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPort(value);
      break;
    case 10:
      var value = new proto.pb.OwlModbusAction;
      reader.readMessage(value,proto.pb.OwlModbusAction.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.OwlModbusInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.OwlModbusInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.OwlModbusInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlModbusInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFunction();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getReg();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.pb.OwlModbusAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.pb.OwlModbusInput.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlModbusInput} returns this
 */
proto.pb.OwlModbusInput.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 address = 2;
 * @return {number}
 */
proto.pb.OwlModbusInput.prototype.getAddress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlModbusInput} returns this
 */
proto.pb.OwlModbusInput.prototype.setAddress = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 function = 3;
 * @return {number}
 */
proto.pb.OwlModbusInput.prototype.getFunction = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlModbusInput} returns this
 */
proto.pb.OwlModbusInput.prototype.setFunction = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 reg = 4;
 * @return {number}
 */
proto.pb.OwlModbusInput.prototype.getReg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlModbusInput} returns this
 */
proto.pb.OwlModbusInput.prototype.setReg = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 length = 5;
 * @return {number}
 */
proto.pb.OwlModbusInput.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlModbusInput} returns this
 */
proto.pb.OwlModbusInput.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional OwlModbusOrder order = 6;
 * @return {!proto.pb.OwlModbusOrder}
 */
proto.pb.OwlModbusInput.prototype.getOrder = function() {
  return /** @type {!proto.pb.OwlModbusOrder} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.pb.OwlModbusOrder} value
 * @return {!proto.pb.OwlModbusInput} returns this
 */
proto.pb.OwlModbusInput.prototype.setOrder = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional OwlModbusDataType data_type = 7;
 * @return {!proto.pb.OwlModbusDataType}
 */
proto.pb.OwlModbusInput.prototype.getDataType = function() {
  return /** @type {!proto.pb.OwlModbusDataType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.pb.OwlModbusDataType} value
 * @return {!proto.pb.OwlModbusInput} returns this
 */
proto.pb.OwlModbusInput.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string ip = 8;
 * @return {string}
 */
proto.pb.OwlModbusInput.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlModbusInput} returns this
 */
proto.pb.OwlModbusInput.prototype.setIp = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional uint32 port = 9;
 * @return {number}
 */
proto.pb.OwlModbusInput.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlModbusInput} returns this
 */
proto.pb.OwlModbusInput.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional OwlModbusAction action = 10;
 * @return {?proto.pb.OwlModbusAction}
 */
proto.pb.OwlModbusInput.prototype.getAction = function() {
  return /** @type{?proto.pb.OwlModbusAction} */ (
    jspb.Message.getWrapperField(this, proto.pb.OwlModbusAction, 10));
};


/**
 * @param {?proto.pb.OwlModbusAction|undefined} value
 * @return {!proto.pb.OwlModbusInput} returns this
*/
proto.pb.OwlModbusInput.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.OwlModbusInput} returns this
 */
proto.pb.OwlModbusInput.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.OwlModbusInput.prototype.hasAction = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.OwlWiFiAndEthernetConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.OwlWiFiAndEthernetConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlWiFiAndEthernetConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    staticIp: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    ip: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subnet: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gateway: jspb.Message.getFieldWithDefault(msg, 5, ""),
    primaryDns: jspb.Message.getFieldWithDefault(msg, 6, ""),
    secondaryDns: jspb.Message.getFieldWithDefault(msg, 7, ""),
    ssid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    password: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.OwlWiFiAndEthernetConfig}
 */
proto.pb.OwlWiFiAndEthernetConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.OwlWiFiAndEthernetConfig;
  return proto.pb.OwlWiFiAndEthernetConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.OwlWiFiAndEthernetConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.OwlWiFiAndEthernetConfig}
 */
proto.pb.OwlWiFiAndEthernetConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStaticIp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubnet(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGateway(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryDns(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryDns(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.OwlWiFiAndEthernetConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.OwlWiFiAndEthernetConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlWiFiAndEthernetConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStaticIp();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubnet();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGateway();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrimaryDns();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSecondaryDns();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSsid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional bool enable = 1;
 * @return {boolean}
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.OwlWiFiAndEthernetConfig} returns this
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool static_ip = 2;
 * @return {boolean}
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.getStaticIp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.OwlWiFiAndEthernetConfig} returns this
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.setStaticIp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string ip = 3;
 * @return {string}
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlWiFiAndEthernetConfig} returns this
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.setIp = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subnet = 4;
 * @return {string}
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.getSubnet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlWiFiAndEthernetConfig} returns this
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.setSubnet = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string gateway = 5;
 * @return {string}
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.getGateway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlWiFiAndEthernetConfig} returns this
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.setGateway = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string primary_dns = 6;
 * @return {string}
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.getPrimaryDns = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlWiFiAndEthernetConfig} returns this
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.setPrimaryDns = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string secondary_dns = 7;
 * @return {string}
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.getSecondaryDns = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlWiFiAndEthernetConfig} returns this
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.setSecondaryDns = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string ssid = 8;
 * @return {string}
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.getSsid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlWiFiAndEthernetConfig} returns this
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.setSsid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string password = 9;
 * @return {string}
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlWiFiAndEthernetConfig} returns this
 */
proto.pb.OwlWiFiAndEthernetConfig.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.OwlXbeeConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.OwlXbeeConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.OwlXbeeConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlXbeeConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    masterHAddr: jspb.Message.getFieldWithDefault(msg, 2, 0),
    masterLAddr: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.OwlXbeeConfig}
 */
proto.pb.OwlXbeeConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.OwlXbeeConfig;
  return proto.pb.OwlXbeeConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.OwlXbeeConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.OwlXbeeConfig}
 */
proto.pb.OwlXbeeConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMasterHAddr(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMasterLAddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.OwlXbeeConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.OwlXbeeConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.OwlXbeeConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlXbeeConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMasterHAddr();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMasterLAddr();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional bool enable = 1;
 * @return {boolean}
 */
proto.pb.OwlXbeeConfig.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.OwlXbeeConfig} returns this
 */
proto.pb.OwlXbeeConfig.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional uint32 master_h_addr = 2;
 * @return {number}
 */
proto.pb.OwlXbeeConfig.prototype.getMasterHAddr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlXbeeConfig} returns this
 */
proto.pb.OwlXbeeConfig.prototype.setMasterHAddr = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 master_l_addr = 3;
 * @return {number}
 */
proto.pb.OwlXbeeConfig.prototype.getMasterLAddr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlXbeeConfig} returns this
 */
proto.pb.OwlXbeeConfig.prototype.setMasterLAddr = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.OwlCellularConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.OwlCellularConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.OwlCellularConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlCellularConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    apnConfigsMap: (f = msg.getApnConfigsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.OwlCellularConfig}
 */
proto.pb.OwlCellularConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.OwlCellularConfig;
  return proto.pb.OwlCellularConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.OwlCellularConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.OwlCellularConfig}
 */
proto.pb.OwlCellularConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    case 2:
      var value = msg.getApnConfigsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.OwlCellularConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.OwlCellularConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.OwlCellularConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlCellularConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getApnConfigsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional bool enable = 1;
 * @return {boolean}
 */
proto.pb.OwlCellularConfig.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.OwlCellularConfig} returns this
 */
proto.pb.OwlCellularConfig.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * map<string, string> apn_configs = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pb.OwlCellularConfig.prototype.getApnConfigsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pb.OwlCellularConfig} returns this
 */
proto.pb.OwlCellularConfig.prototype.clearApnConfigsMap = function() {
  this.getApnConfigsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.OwlEdgeConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.OwlEdgeConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.OwlEdgeConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlEdgeConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    mainInterval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    coapHost: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coapPort: jspb.Message.getFieldWithDefault(msg, 3, 0),
    coapOverTcp: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    serialConfig: jspb.Message.getFieldWithDefault(msg, 5, 0),
    rebootInterval: jspb.Message.getFieldWithDefault(msg, 6, 0),
    checkDataTimeout: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    doFullLoop: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    useDataBuffer: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    getCommand: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    resyncTimestamp: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    serialBuadrate: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.OwlEdgeConfig}
 */
proto.pb.OwlEdgeConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.OwlEdgeConfig;
  return proto.pb.OwlEdgeConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.OwlEdgeConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.OwlEdgeConfig}
 */
proto.pb.OwlEdgeConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMainInterval(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoapHost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCoapPort(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCoapOverTcp(value);
      break;
    case 5:
      var value = /** @type {!proto.pb.OwlModbusSerialConfig} */ (reader.readEnum());
      msg.setSerialConfig(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRebootInterval(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckDataTimeout(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoFullLoop(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseDataBuffer(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetCommand(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResyncTimestamp(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSerialBuadrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.OwlEdgeConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.OwlEdgeConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.OwlEdgeConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlEdgeConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMainInterval();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCoapHost();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoapPort();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getCoapOverTcp();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSerialConfig();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getRebootInterval();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCheckDataTimeout();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDoFullLoop();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getUseDataBuffer();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getGetCommand();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getResyncTimestamp();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getSerialBuadrate();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
};


/**
 * optional uint32 main_interval = 1;
 * @return {number}
 */
proto.pb.OwlEdgeConfig.prototype.getMainInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlEdgeConfig} returns this
 */
proto.pb.OwlEdgeConfig.prototype.setMainInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string coap_host = 2;
 * @return {string}
 */
proto.pb.OwlEdgeConfig.prototype.getCoapHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlEdgeConfig} returns this
 */
proto.pb.OwlEdgeConfig.prototype.setCoapHost = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 coap_port = 3;
 * @return {number}
 */
proto.pb.OwlEdgeConfig.prototype.getCoapPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlEdgeConfig} returns this
 */
proto.pb.OwlEdgeConfig.prototype.setCoapPort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool coap_over_tcp = 4;
 * @return {boolean}
 */
proto.pb.OwlEdgeConfig.prototype.getCoapOverTcp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.OwlEdgeConfig} returns this
 */
proto.pb.OwlEdgeConfig.prototype.setCoapOverTcp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional OwlModbusSerialConfig serial_config = 5;
 * @return {!proto.pb.OwlModbusSerialConfig}
 */
proto.pb.OwlEdgeConfig.prototype.getSerialConfig = function() {
  return /** @type {!proto.pb.OwlModbusSerialConfig} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.pb.OwlModbusSerialConfig} value
 * @return {!proto.pb.OwlEdgeConfig} returns this
 */
proto.pb.OwlEdgeConfig.prototype.setSerialConfig = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional uint32 reboot_interval = 6;
 * @return {number}
 */
proto.pb.OwlEdgeConfig.prototype.getRebootInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlEdgeConfig} returns this
 */
proto.pb.OwlEdgeConfig.prototype.setRebootInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool check_data_timeout = 7;
 * @return {boolean}
 */
proto.pb.OwlEdgeConfig.prototype.getCheckDataTimeout = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.OwlEdgeConfig} returns this
 */
proto.pb.OwlEdgeConfig.prototype.setCheckDataTimeout = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool do_full_loop = 8;
 * @return {boolean}
 */
proto.pb.OwlEdgeConfig.prototype.getDoFullLoop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.OwlEdgeConfig} returns this
 */
proto.pb.OwlEdgeConfig.prototype.setDoFullLoop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool use_data_buffer = 9;
 * @return {boolean}
 */
proto.pb.OwlEdgeConfig.prototype.getUseDataBuffer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.OwlEdgeConfig} returns this
 */
proto.pb.OwlEdgeConfig.prototype.setUseDataBuffer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool get_command = 10;
 * @return {boolean}
 */
proto.pb.OwlEdgeConfig.prototype.getGetCommand = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.OwlEdgeConfig} returns this
 */
proto.pb.OwlEdgeConfig.prototype.setGetCommand = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool resync_timestamp = 11;
 * @return {boolean}
 */
proto.pb.OwlEdgeConfig.prototype.getResyncTimestamp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.OwlEdgeConfig} returns this
 */
proto.pb.OwlEdgeConfig.prototype.setResyncTimestamp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional uint32 serial_buadrate = 12;
 * @return {number}
 */
proto.pb.OwlEdgeConfig.prototype.getSerialBuadrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlEdgeConfig} returns this
 */
proto.pb.OwlEdgeConfig.prototype.setSerialBuadrate = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.OwlTimestampData.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.OwlTimestampData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.OwlTimestampData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlTimestampData.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.OwlTimestampData}
 */
proto.pb.OwlTimestampData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.OwlTimestampData;
  return proto.pb.OwlTimestampData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.OwlTimestampData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.OwlTimestampData}
 */
proto.pb.OwlTimestampData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.OwlTimestampData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.OwlTimestampData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.OwlTimestampData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlTimestampData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.pb.OwlTimestampData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlTimestampData} returns this
 */
proto.pb.OwlTimestampData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 timestamp = 2;
 * @return {number}
 */
proto.pb.OwlTimestampData.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlTimestampData} returns this
 */
proto.pb.OwlTimestampData.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.FirmwareInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.FirmwareInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.FirmwareInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.FirmwareInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, ""),
    md5: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.FirmwareInfo}
 */
proto.pb.FirmwareInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.FirmwareInfo;
  return proto.pb.FirmwareInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.FirmwareInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.FirmwareInfo}
 */
proto.pb.FirmwareInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMd5(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.FirmwareInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.FirmwareInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.FirmwareInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.FirmwareInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMd5();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.pb.FirmwareInfo.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.FirmwareInfo} returns this
 */
proto.pb.FirmwareInfo.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string md5 = 2;
 * @return {string}
 */
proto.pb.FirmwareInfo.prototype.getMd5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.FirmwareInfo} returns this
 */
proto.pb.FirmwareInfo.prototype.setMd5 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 total_size = 3;
 * @return {number}
 */
proto.pb.FirmwareInfo.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.FirmwareInfo} returns this
 */
proto.pb.FirmwareInfo.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.FirmwareData.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.FirmwareData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.FirmwareData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.FirmwareData.toObject = function(includeInstance, msg) {
  var f, obj = {
    offset: jspb.Message.getFieldWithDefault(msg, 1, 0),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.FirmwareData}
 */
proto.pb.FirmwareData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.FirmwareData;
  return proto.pb.FirmwareData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.FirmwareData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.FirmwareData}
 */
proto.pb.FirmwareData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffset(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.FirmwareData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.FirmwareData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.FirmwareData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.FirmwareData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional uint32 offset = 1;
 * @return {number}
 */
proto.pb.FirmwareData.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.FirmwareData} returns this
 */
proto.pb.FirmwareData.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 size = 2;
 * @return {number}
 */
proto.pb.FirmwareData.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.FirmwareData} returns this
 */
proto.pb.FirmwareData.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes data = 3;
 * @return {!(string|Uint8Array)}
 */
proto.pb.FirmwareData.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes data = 3;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.pb.FirmwareData.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.pb.FirmwareData.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pb.FirmwareData} returns this
 */
proto.pb.FirmwareData.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.TimestampFuso.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.TimestampFuso.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.TimestampFuso.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.TimestampFuso} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.TimestampFuso.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.TimestampFuso}
 */
proto.pb.TimestampFuso.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.TimestampFuso;
  return proto.pb.TimestampFuso.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.TimestampFuso} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.TimestampFuso}
 */
proto.pb.TimestampFuso.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint64());
      msg.setTimestampsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.TimestampFuso.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.TimestampFuso.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.TimestampFuso} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.TimestampFuso.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
};


/**
 * repeated uint64 timestamps = 1;
 * @return {!Array<number>}
 */
proto.pb.TimestampFuso.prototype.getTimestampsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.pb.TimestampFuso} returns this
 */
proto.pb.TimestampFuso.prototype.setTimestampsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.pb.TimestampFuso} returns this
 */
proto.pb.TimestampFuso.prototype.addTimestamps = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.TimestampFuso} returns this
 */
proto.pb.TimestampFuso.prototype.clearTimestampsList = function() {
  return this.setTimestampsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.OwlMsg.repeatedFields_ = [5,12,13,14,15,20,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.OwlMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.OwlMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.OwlMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    secret: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apikey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
    commandsList: jspb.Message.toObjectList(msg.getCommandsList(),
    proto.pb.Command.toObject, includeInstance),
    dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, undefined) : [],
    edgeConfig: (f = msg.getEdgeConfig()) && proto.pb.OwlEdgeConfig.toObject(includeInstance, f),
    wifiConfig: (f = msg.getWifiConfig()) && proto.pb.OwlWiFiAndEthernetConfig.toObject(includeInstance, f),
    ethConfig: (f = msg.getEthConfig()) && proto.pb.OwlWiFiAndEthernetConfig.toObject(includeInstance, f),
    xbeeConfig: (f = msg.getXbeeConfig()) && proto.pb.OwlXbeeConfig.toObject(includeInstance, f),
    cellularConfig: (f = msg.getCellularConfig()) && proto.pb.OwlCellularConfig.toObject(includeInstance, f),
    analogInputsList: jspb.Message.toObjectList(msg.getAnalogInputsList(),
    proto.pb.OwlAnalogInput.toObject, includeInstance),
    modbusInputsList: jspb.Message.toObjectList(msg.getModbusInputsList(),
    proto.pb.OwlModbusInput.toObject, includeInstance),
    enableOutputsList: (f = jspb.Message.getRepeatedBooleanField(msg, 14)) == null ? undefined : f,
    timestampDataList: jspb.Message.toObjectList(msg.getTimestampDataList(),
    proto.pb.OwlTimestampData.toObject, includeInstance),
    systemCommand: jspb.Message.getFieldWithDefault(msg, 16, 0),
    firmwareInfo: (f = msg.getFirmwareInfo()) && proto.pb.FirmwareInfo.toObject(includeInstance, f),
    firmwareData: (f = msg.getFirmwareData()) && proto.pb.FirmwareData.toObject(includeInstance, f),
    timestampData2Name: jspb.Message.getFieldWithDefault(msg, 19, ""),
    timestampData2List: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    timestampFusoList: jspb.Message.toObjectList(msg.getTimestampFusoList(),
    proto.pb.TimestampFuso.toObject, includeInstance),
    ttl: jspb.Message.getFieldWithDefault(msg, 22, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.OwlMsg}
 */
proto.pb.OwlMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.OwlMsg;
  return proto.pb.OwlMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.OwlMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.OwlMsg}
 */
proto.pb.OwlMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecret(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApikey(value);
      break;
    case 3:
      var value = /** @type {!proto.pb.OwlMsgType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestamp(value);
      break;
    case 5:
      var value = new proto.pb.Command;
      reader.readMessage(value,proto.pb.Command.deserializeBinaryFromReader);
      msg.addCommands(value);
      break;
    case 6:
      var value = msg.getDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 7:
      var value = new proto.pb.OwlEdgeConfig;
      reader.readMessage(value,proto.pb.OwlEdgeConfig.deserializeBinaryFromReader);
      msg.setEdgeConfig(value);
      break;
    case 8:
      var value = new proto.pb.OwlWiFiAndEthernetConfig;
      reader.readMessage(value,proto.pb.OwlWiFiAndEthernetConfig.deserializeBinaryFromReader);
      msg.setWifiConfig(value);
      break;
    case 9:
      var value = new proto.pb.OwlWiFiAndEthernetConfig;
      reader.readMessage(value,proto.pb.OwlWiFiAndEthernetConfig.deserializeBinaryFromReader);
      msg.setEthConfig(value);
      break;
    case 10:
      var value = new proto.pb.OwlXbeeConfig;
      reader.readMessage(value,proto.pb.OwlXbeeConfig.deserializeBinaryFromReader);
      msg.setXbeeConfig(value);
      break;
    case 11:
      var value = new proto.pb.OwlCellularConfig;
      reader.readMessage(value,proto.pb.OwlCellularConfig.deserializeBinaryFromReader);
      msg.setCellularConfig(value);
      break;
    case 12:
      var value = new proto.pb.OwlAnalogInput;
      reader.readMessage(value,proto.pb.OwlAnalogInput.deserializeBinaryFromReader);
      msg.addAnalogInputs(value);
      break;
    case 13:
      var value = new proto.pb.OwlModbusInput;
      reader.readMessage(value,proto.pb.OwlModbusInput.deserializeBinaryFromReader);
      msg.addModbusInputs(value);
      break;
    case 14:
      var value = /** @type {!Array<boolean>} */ (reader.readPackedBool());
      msg.setEnableOutputsList(value);
      break;
    case 15:
      var value = new proto.pb.OwlTimestampData;
      reader.readMessage(value,proto.pb.OwlTimestampData.deserializeBinaryFromReader);
      msg.addTimestampData(value);
      break;
    case 16:
      var value = /** @type {!proto.pb.OwlSystemCommand} */ (reader.readEnum());
      msg.setSystemCommand(value);
      break;
    case 17:
      var value = new proto.pb.FirmwareInfo;
      reader.readMessage(value,proto.pb.FirmwareInfo.deserializeBinaryFromReader);
      msg.setFirmwareInfo(value);
      break;
    case 18:
      var value = new proto.pb.FirmwareData;
      reader.readMessage(value,proto.pb.FirmwareData.deserializeBinaryFromReader);
      msg.setFirmwareData(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestampData2Name(value);
      break;
    case 20:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint64());
      msg.setTimestampData2List(value);
      break;
    case 21:
      var value = new proto.pb.TimestampFuso;
      reader.readMessage(value,proto.pb.TimestampFuso.deserializeBinaryFromReader);
      msg.addTimestampFuso(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTtl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.OwlMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.OwlMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.OwlMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OwlMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecret();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApikey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getCommandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pb.Command.serializeBinaryToWriter
    );
  }
  f = message.getDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getEdgeConfig();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pb.OwlEdgeConfig.serializeBinaryToWriter
    );
  }
  f = message.getWifiConfig();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pb.OwlWiFiAndEthernetConfig.serializeBinaryToWriter
    );
  }
  f = message.getEthConfig();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.pb.OwlWiFiAndEthernetConfig.serializeBinaryToWriter
    );
  }
  f = message.getXbeeConfig();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.pb.OwlXbeeConfig.serializeBinaryToWriter
    );
  }
  f = message.getCellularConfig();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.pb.OwlCellularConfig.serializeBinaryToWriter
    );
  }
  f = message.getAnalogInputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.pb.OwlAnalogInput.serializeBinaryToWriter
    );
  }
  f = message.getModbusInputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.pb.OwlModbusInput.serializeBinaryToWriter
    );
  }
  f = message.getEnableOutputsList();
  if (f.length > 0) {
    writer.writePackedBool(
      14,
      f
    );
  }
  f = message.getTimestampDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.pb.OwlTimestampData.serializeBinaryToWriter
    );
  }
  f = message.getSystemCommand();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getFirmwareInfo();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.pb.FirmwareInfo.serializeBinaryToWriter
    );
  }
  f = message.getFirmwareData();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.pb.FirmwareData.serializeBinaryToWriter
    );
  }
  f = message.getTimestampData2Name();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getTimestampData2List();
  if (f.length > 0) {
    writer.writePackedUint64(
      20,
      f
    );
  }
  f = message.getTimestampFusoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.pb.TimestampFuso.serializeBinaryToWriter
    );
  }
  f = message.getTtl();
  if (f !== 0) {
    writer.writeUint32(
      22,
      f
    );
  }
};


/**
 * optional string secret = 1;
 * @return {string}
 */
proto.pb.OwlMsg.prototype.getSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.setSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string apikey = 2;
 * @return {string}
 */
proto.pb.OwlMsg.prototype.getApikey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.setApikey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OwlMsgType type = 3;
 * @return {!proto.pb.OwlMsgType}
 */
proto.pb.OwlMsg.prototype.getType = function() {
  return /** @type {!proto.pb.OwlMsgType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pb.OwlMsgType} value
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint64 timestamp = 4;
 * @return {number}
 */
proto.pb.OwlMsg.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Command commands = 5;
 * @return {!Array<!proto.pb.Command>}
 */
proto.pb.OwlMsg.prototype.getCommandsList = function() {
  return /** @type{!Array<!proto.pb.Command>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pb.Command, 5));
};


/**
 * @param {!Array<!proto.pb.Command>} value
 * @return {!proto.pb.OwlMsg} returns this
*/
proto.pb.OwlMsg.prototype.setCommandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pb.Command=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Command}
 */
proto.pb.OwlMsg.prototype.addCommands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pb.Command, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearCommandsList = function() {
  return this.setCommandsList([]);
};


/**
 * map<string, string> data = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pb.OwlMsg.prototype.getDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearDataMap = function() {
  this.getDataMap().clear();
  return this;};


/**
 * optional OwlEdgeConfig edge_config = 7;
 * @return {?proto.pb.OwlEdgeConfig}
 */
proto.pb.OwlMsg.prototype.getEdgeConfig = function() {
  return /** @type{?proto.pb.OwlEdgeConfig} */ (
    jspb.Message.getWrapperField(this, proto.pb.OwlEdgeConfig, 7));
};


/**
 * @param {?proto.pb.OwlEdgeConfig|undefined} value
 * @return {!proto.pb.OwlMsg} returns this
*/
proto.pb.OwlMsg.prototype.setEdgeConfig = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearEdgeConfig = function() {
  return this.setEdgeConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.OwlMsg.prototype.hasEdgeConfig = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional OwlWiFiAndEthernetConfig wifi_config = 8;
 * @return {?proto.pb.OwlWiFiAndEthernetConfig}
 */
proto.pb.OwlMsg.prototype.getWifiConfig = function() {
  return /** @type{?proto.pb.OwlWiFiAndEthernetConfig} */ (
    jspb.Message.getWrapperField(this, proto.pb.OwlWiFiAndEthernetConfig, 8));
};


/**
 * @param {?proto.pb.OwlWiFiAndEthernetConfig|undefined} value
 * @return {!proto.pb.OwlMsg} returns this
*/
proto.pb.OwlMsg.prototype.setWifiConfig = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearWifiConfig = function() {
  return this.setWifiConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.OwlMsg.prototype.hasWifiConfig = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional OwlWiFiAndEthernetConfig eth_config = 9;
 * @return {?proto.pb.OwlWiFiAndEthernetConfig}
 */
proto.pb.OwlMsg.prototype.getEthConfig = function() {
  return /** @type{?proto.pb.OwlWiFiAndEthernetConfig} */ (
    jspb.Message.getWrapperField(this, proto.pb.OwlWiFiAndEthernetConfig, 9));
};


/**
 * @param {?proto.pb.OwlWiFiAndEthernetConfig|undefined} value
 * @return {!proto.pb.OwlMsg} returns this
*/
proto.pb.OwlMsg.prototype.setEthConfig = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearEthConfig = function() {
  return this.setEthConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.OwlMsg.prototype.hasEthConfig = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional OwlXbeeConfig xbee_config = 10;
 * @return {?proto.pb.OwlXbeeConfig}
 */
proto.pb.OwlMsg.prototype.getXbeeConfig = function() {
  return /** @type{?proto.pb.OwlXbeeConfig} */ (
    jspb.Message.getWrapperField(this, proto.pb.OwlXbeeConfig, 10));
};


/**
 * @param {?proto.pb.OwlXbeeConfig|undefined} value
 * @return {!proto.pb.OwlMsg} returns this
*/
proto.pb.OwlMsg.prototype.setXbeeConfig = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearXbeeConfig = function() {
  return this.setXbeeConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.OwlMsg.prototype.hasXbeeConfig = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OwlCellularConfig cellular_config = 11;
 * @return {?proto.pb.OwlCellularConfig}
 */
proto.pb.OwlMsg.prototype.getCellularConfig = function() {
  return /** @type{?proto.pb.OwlCellularConfig} */ (
    jspb.Message.getWrapperField(this, proto.pb.OwlCellularConfig, 11));
};


/**
 * @param {?proto.pb.OwlCellularConfig|undefined} value
 * @return {!proto.pb.OwlMsg} returns this
*/
proto.pb.OwlMsg.prototype.setCellularConfig = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearCellularConfig = function() {
  return this.setCellularConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.OwlMsg.prototype.hasCellularConfig = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated OwlAnalogInput analog_inputs = 12;
 * @return {!Array<!proto.pb.OwlAnalogInput>}
 */
proto.pb.OwlMsg.prototype.getAnalogInputsList = function() {
  return /** @type{!Array<!proto.pb.OwlAnalogInput>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pb.OwlAnalogInput, 12));
};


/**
 * @param {!Array<!proto.pb.OwlAnalogInput>} value
 * @return {!proto.pb.OwlMsg} returns this
*/
proto.pb.OwlMsg.prototype.setAnalogInputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.pb.OwlAnalogInput=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.OwlAnalogInput}
 */
proto.pb.OwlMsg.prototype.addAnalogInputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.pb.OwlAnalogInput, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearAnalogInputsList = function() {
  return this.setAnalogInputsList([]);
};


/**
 * repeated OwlModbusInput modbus_inputs = 13;
 * @return {!Array<!proto.pb.OwlModbusInput>}
 */
proto.pb.OwlMsg.prototype.getModbusInputsList = function() {
  return /** @type{!Array<!proto.pb.OwlModbusInput>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pb.OwlModbusInput, 13));
};


/**
 * @param {!Array<!proto.pb.OwlModbusInput>} value
 * @return {!proto.pb.OwlMsg} returns this
*/
proto.pb.OwlMsg.prototype.setModbusInputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.pb.OwlModbusInput=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.OwlModbusInput}
 */
proto.pb.OwlMsg.prototype.addModbusInputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.pb.OwlModbusInput, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearModbusInputsList = function() {
  return this.setModbusInputsList([]);
};


/**
 * repeated bool enable_outputs = 14;
 * @return {!Array<boolean>}
 */
proto.pb.OwlMsg.prototype.getEnableOutputsList = function() {
  return /** @type {!Array<boolean>} */ (jspb.Message.getRepeatedBooleanField(this, 14));
};


/**
 * @param {!Array<boolean>} value
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.setEnableOutputsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.addEnableOutputs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearEnableOutputsList = function() {
  return this.setEnableOutputsList([]);
};


/**
 * repeated OwlTimestampData timestamp_data = 15;
 * @return {!Array<!proto.pb.OwlTimestampData>}
 */
proto.pb.OwlMsg.prototype.getTimestampDataList = function() {
  return /** @type{!Array<!proto.pb.OwlTimestampData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pb.OwlTimestampData, 15));
};


/**
 * @param {!Array<!proto.pb.OwlTimestampData>} value
 * @return {!proto.pb.OwlMsg} returns this
*/
proto.pb.OwlMsg.prototype.setTimestampDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.pb.OwlTimestampData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.OwlTimestampData}
 */
proto.pb.OwlMsg.prototype.addTimestampData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.pb.OwlTimestampData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearTimestampDataList = function() {
  return this.setTimestampDataList([]);
};


/**
 * optional OwlSystemCommand system_command = 16;
 * @return {!proto.pb.OwlSystemCommand}
 */
proto.pb.OwlMsg.prototype.getSystemCommand = function() {
  return /** @type {!proto.pb.OwlSystemCommand} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.pb.OwlSystemCommand} value
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.setSystemCommand = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional FirmwareInfo firmware_info = 17;
 * @return {?proto.pb.FirmwareInfo}
 */
proto.pb.OwlMsg.prototype.getFirmwareInfo = function() {
  return /** @type{?proto.pb.FirmwareInfo} */ (
    jspb.Message.getWrapperField(this, proto.pb.FirmwareInfo, 17));
};


/**
 * @param {?proto.pb.FirmwareInfo|undefined} value
 * @return {!proto.pb.OwlMsg} returns this
*/
proto.pb.OwlMsg.prototype.setFirmwareInfo = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearFirmwareInfo = function() {
  return this.setFirmwareInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.OwlMsg.prototype.hasFirmwareInfo = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional FirmwareData firmware_data = 18;
 * @return {?proto.pb.FirmwareData}
 */
proto.pb.OwlMsg.prototype.getFirmwareData = function() {
  return /** @type{?proto.pb.FirmwareData} */ (
    jspb.Message.getWrapperField(this, proto.pb.FirmwareData, 18));
};


/**
 * @param {?proto.pb.FirmwareData|undefined} value
 * @return {!proto.pb.OwlMsg} returns this
*/
proto.pb.OwlMsg.prototype.setFirmwareData = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearFirmwareData = function() {
  return this.setFirmwareData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.OwlMsg.prototype.hasFirmwareData = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string timestamp_data2_name = 19;
 * @return {string}
 */
proto.pb.OwlMsg.prototype.getTimestampData2Name = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.setTimestampData2Name = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * repeated uint64 timestamp_data2 = 20;
 * @return {!Array<number>}
 */
proto.pb.OwlMsg.prototype.getTimestampData2List = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.setTimestampData2List = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.addTimestampData2 = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearTimestampData2List = function() {
  return this.setTimestampData2List([]);
};


/**
 * repeated TimestampFuso timestamp_fuso = 21;
 * @return {!Array<!proto.pb.TimestampFuso>}
 */
proto.pb.OwlMsg.prototype.getTimestampFusoList = function() {
  return /** @type{!Array<!proto.pb.TimestampFuso>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pb.TimestampFuso, 21));
};


/**
 * @param {!Array<!proto.pb.TimestampFuso>} value
 * @return {!proto.pb.OwlMsg} returns this
*/
proto.pb.OwlMsg.prototype.setTimestampFusoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.pb.TimestampFuso=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.TimestampFuso}
 */
proto.pb.OwlMsg.prototype.addTimestampFuso = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.pb.TimestampFuso, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.clearTimestampFusoList = function() {
  return this.setTimestampFusoList([]);
};


/**
 * optional uint32 ttl = 22;
 * @return {number}
 */
proto.pb.OwlMsg.prototype.getTtl = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.OwlMsg} returns this
 */
proto.pb.OwlMsg.prototype.setTtl = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * @enum {number}
 */
proto.pb.OwlMsgType = {
  GET_TIMESTAMP: 0,
  GET_CONFIG: 1,
  GET_COMMAND: 2,
  POST_COMMAND: 3,
  POST_MONITOR: 4,
  GET_NETWORK_CONFIG: 5,
  GET_ANALOG_CONFIG: 6,
  GET_MODBUS_CONFIG: 7,
  GET_FIRMWARE_INFO: 8,
  GET_FIRMWARE_DATA: 9
};

/**
 * @enum {number}
 */
proto.pb.OwlAnalogInputType = {
  MA_4_20: 0,
  V_0_10: 1,
  DIGITAL: 2,
  FREQUENCY: 3,
  TIMESTAMP: 4,
  TOWERLAMP: 5
};

/**
 * @enum {number}
 */
proto.pb.OwlModbusOrder = {
  MSRF: 0,
  LSRF: 1
};

/**
 * @enum {number}
 */
proto.pb.OwlModbusDataType = {
  INT: 0,
  FLOAT: 1,
  DOUBLE: 2
};

/**
 * @enum {number}
 */
proto.pb.OwlModbusSerialConfig = {
  SERIAL_8N1: 0,
  SERIAL_8E1: 1,
  SERIAL_8O1: 2,
  SERIAL_7N1: 3,
  SERIAL_7E1: 4,
  SERIAL_7O1: 5
};

/**
 * @enum {number}
 */
proto.pb.OwlSystemCommand = {
  NONE: 0,
  REBOOT: 1,
  FIRMWARE_UPDATE: 2
};

/**
 * @enum {number}
 */
proto.pb.OwlModbusActionType = {
  POST: 0,
  PRE: 1
};

goog.object.extend(exports, proto.pb);