import axios from 'axios';

export const server = 'develop';

let baseURLs = {
  'production': 'https://nest.technimal.co.th/api/',
  'office': 'http://192.168.1.10/api/',
  'office_over_vpn': 'http://25.24.236.196/api/',
  'develop': 'http://172.30.62.132/api/',
  // 'develop': 'https://nestdev.technimal.co.th/api/',
  'demo': 'https://demo.technimal.co.th/api/',
  'docker': '/api/',
}

const instance = axios.create({
  baseURL: baseURLs[server],
  headers: { 'Cache-Control': 'no-cache' }
});

const token = localStorage.getItem("owlJwtToken");
if (token) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
} else {
  delete instance.defaults.headers.common['Authorization'];
}

export default instance;
