import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './DeviceCardList.module.scss';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import EditHoverIcon from '../../../../assets/icons/edit.svg';

import BatteryIcon from '../../../../assets/icons/Device/battery.svg';
import CameraIcon from '../../../../assets/icons/Device/camera.svg';
import Co2Icon from '../../../../assets/icons/Device/co2.svg';
import CountingIcon from '../../../../assets/icons/Device/counting.svg';
import ElectricityIcon from '../../../../assets/icons/Device/electricity.svg';
import FanIcon from '../../../../assets/icons/Device/fan.svg';
import FingerprintIcon from '../../../../assets/icons/Device/fingerprint.svg';
import FlameIcon from '../../../../assets/icons/Device/flame.svg';
import IceIcon from '../../../../assets/icons/Device/ice.svg';
import LightIcon from '../../../../assets/icons/Device/lights.svg';
import MapIcon from '../../../../assets/icons/Device/map.svg';
import N2Icon from '../../../../assets/icons/Device/n2.svg';
import O2Icon from '../../../../assets/icons/Device/o2.svg';
import OilIcon from '../../../../assets/icons/Device/oil.svg';
import PlugIcon from '../../../../assets/icons/Device/plug.svg';
import Pm25Icon from '../../../../assets/icons/Device/pm25.svg';
import PressureIcon from '../../../../assets/icons/Device/pressure.svg';
import TemperatureIcon from '../../../../assets/icons/Device/temperature.svg';
import WaterIcon from '../../../../assets/icons/Device/water.svg';

import { changeSocketIcon } from '../../../../state/ducks/DeviceSocket/actions';
import { CHANGE_SOCKET_ICON_SUCCESS } from '../../../../state/ducks/DeviceSocket/types';

const DEVICE_ICONS = [
  BatteryIcon, CameraIcon, Co2Icon, CountingIcon, ElectricityIcon, FanIcon,
  FingerprintIcon, FlameIcon, IceIcon, LightIcon, MapIcon, N2Icon, O2Icon, OilIcon,
  PlugIcon, Pm25Icon, PressureIcon, TemperatureIcon, WaterIcon
];

class DeviceCardList extends Component {
  state = {
    deviceSocketsOpen: [],
    openDeviceIcon: null, // SocketIndex
    updatingIndexes: {}
  }

  componentDidMount() {
    if (this.props.filteredDevice) {
      this.setState({ filteredDevice: this.props.filteredDevice });
      let openIndex = parseInt(localStorage.getItem("device_open_id"));
      if (!isNaN(openIndex)) {
        this.openDeviceSocketsHandler(openIndex);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type === CHANGE_SOCKET_ICON_SUCCESS && prevProps.updatedDeviceSocket !== this.props.updatedDeviceSocket) {
      this.updateDeviceSocketRow(this.props.updatedDeviceSocket);
    } else if (prevProps.filteredDevice !== this.props.filteredDevice) {
      this.setState({ filteredDevice: this.props.filteredDevice });
    }
  }

  updateDeviceSocketRow = (updatedDeviceSocket) => {
    const { deviceIndex, socketIndex } = this.state.updatingIndexes;
    let updatedDevice = [...this.state.filteredDevice];
    updatedDevice[deviceIndex].device_sockets[socketIndex] = updatedDeviceSocket;
    this.setState({ filteredDevice: updatedDevice });
  }

  openDeviceSocketsHandler = (index) => {
    const newOpen = this.props.filteredDevice.map(() => false);
    if (!this.state.deviceSocketsOpen[index]) {
      newOpen[index] = true;
      localStorage.setItem("device_open_id", index);
    } else {
      localStorage.removeItem("device_open_id");
    }
    this.setState({ deviceSocketsOpen: newOpen, openDeviceIcon: null });
  }

  onOpenDeviceIconHandler = (deviceIndex, socketIndex) => {
    if (this.state.deviceSocketsOpen[deviceIndex] && this.state.openDeviceIcon === socketIndex) {
      this.setState({ openDeviceIcon: null });
    } else {
      this.setState({ openDeviceIcon: socketIndex });
    }
  }

  onChangeDeviceIconHandler = (deviceIndex, socketIndex, icon) => {
    this.props.changeSocketIcon(
      this.props.filteredDevice[deviceIndex].device_sockets[socketIndex].id,
      icon
    );
    const updatingIndexes = {
      deviceIndex, socketIndex
    }
    this.setState({ openDeviceIcon: null, updatingIndexes });
  }

  render() {
    const isAdmin = (this.props.loggedInUser.admin_of_org && this.props.loggedInUser.admin_of_org.includes(+this.props.currentOrg)) || (this.props.loggedInUser && this.props.loggedInUser.is_superuser);

    return (
      this.state.filteredDevice ? this.state.filteredDevice.map((device, index) => {
        const { id, status, name, secret, created_date, tag, system_device } = device;
        let deviceAndSocketStyles = [styles.DeviceAndSocketWrapper];
        if (status === 'on') {
          deviceAndSocketStyles.push(styles.Active)
        }

        let arrowClasses = [styles.ArrowIcon];
        if (this.state.deviceSocketsOpen[index]) {
          arrowClasses.push(styles.Open);
        }

        return (
          <div key={`device-${id}`} className={styles.DeviceCardList}>
            <div className={styles.DeviceCard}>
              <div className={deviceAndSocketStyles.join(' ')}>
                <div className={styles.DeviceWrapper} onClick={() => this.openDeviceSocketsHandler(index)}>
                  <div className={styles.NameAndInformation}>
                    <div className={styles.DeviceName}>{name}</div>
                    <div className={styles.OtherInformation}>
                      {secret}
                      <span className={styles.MiddleDot}>&#183;</span>
                      {moment(created_date).format("MMM DD, YYYY")}
                    </div>
                  </div>
                  {tag !== '' &&
                    <div className={styles.TagsWrapper}>
                      {tag.split(",").map((tag, i) => {
                        const random = Math.floor(Math.random() * 1000);
                        let moreTagsStyle = [styles.MoreTags];
                        let tagBox = [styles.TagBox];
                        if (status !== 'on') {
                          moreTagsStyle.push(styles.Off);
                          tagBox.push(styles.Off);
                        }
                        if (i === 3) {
                          return <div key={`moretag+${tag}`} className={moreTagsStyle.join(' ')}>+{tag.split(",").length - 3}</div>;
                        } else if (i > 3) {
                          return null;
                        }
                        return <div key={`tag+${tag} ${random}`} className={tagBox.join(' ')}>{tag}</div>;
                      })}
                    </div>
                  }
                  <div className={styles.ActionButtons}>
                    {
                      isAdmin ?
                        <>
                          <img
                            style={{ marginRight: 30, cursor: 'pointer' }}
                            src={EditIcon}
                            onMouseOver={e => (e.currentTarget.src = EditHoverIcon)}
                            onMouseOut={e => (e.currentTarget.src = EditIcon)}
                            alt="edit device"
                            onClick={(event) => this.props.editDevice(event, id)}
                          />
                          {/* {
                            system_device
                              ? <img
                                style={{ marginRight: 30, cursor: 'pointer' }}
                                src={EditIcon}
                                onMouseOver={e => (e.currentTarget.src = EditHoverIcon)}
                                onMouseOut={e => (e.currentTarget.src = EditIcon)}
                                alt="edit device config"
                                onClick={(event) => this.props.editDevice(event, 'deviceConfig', id)}
                              />
                              : 
                              <>
                              <img
                              style={{ marginRight: 30 }}
                              src={EditIcon}
                              onMouseOver={e => (e.currentTarget.src = EditHoverIcon)}
                              onMouseOut={e => (e.currentTarget.src = EditIcon)}
                              alt="edit device"
                              onClick={(event) => this.props.editDevice(event, 'device', id)}
                            />
                            <img className={arrowClasses.join(' ')} src={ArrowIcon} alt="Open more member's details" />
                            </>
                          } */}
                        </> : null
                    }

                  </div>
                </div>
                {/* {
                  !system_device && this.state.deviceSocketsOpen[index] &&
                  <div className={styles.CollapseContainer}>
                    {device.device_sockets.filter(ds => ds.enable).map((ds, j) => {
                      const deviceIconTooltipStyles = [styles.ChangeDeviceIconTooltip];
                      if (this.state.openDeviceIcon === j) {
                        deviceIconTooltipStyles.push(styles.Open);
                      }

                      const deviceIconStyles = [styles.DevicePicture];
                      if (this.props.iconLoading && index === this.state.updatingIndexes.deviceIndex && j === this.state.updatingIndexes.socketIndex) {
                        deviceIconStyles.push(styles.Loading);
                      }

                      return (
                        <div key={`socket-${ds.id}`} className={styles.DeviceSocketRow}>
                          <div className={styles.DevicePictureWrapper}>
                            {this.props.iconLoading && index === this.state.updatingIndexes.deviceIndex && j === this.state.updatingIndexes.socketIndex
                              && <div className={styles.DeviceIconLoadingWrapper}><Spinner /></div>}
                            <div style={{ position: 'relative' }}>
                              <img
                                src={ds.image ? handleImagePath(ds.image) : DefaultDeviceIcon}
                                alt={`pic${id}`}
                                className={isAdmin ? deviceIconStyles.join(' ') : styles.NotAdminDevicePicture}
                                onClick={isAdmin ? () => this.onOpenDeviceIconHandler(index, j) : null}
                              />
                              <img
                                src={ds.can_control ? OutputIcon : InputIcon}
                                alt="Control Icon"
                                className={styles.ControlIcon}
                              />
                            </div>
                            <div className={deviceIconTooltipStyles.join(' ')}>
                              {
                                DEVICE_ICONS.map(di =>
                                  <img
                                    key={`icon-${di}`}
                                    src={di}
                                    alt={di}
                                    width="30"
                                    style={{ marginRight: 3, cursor: 'pointer' }}
                                    onClick={() => this.onChangeDeviceIconHandler(index, j, di)}
                                  />
                                )
                              }
                            </div>
                          </div>
                          <div className={styles.DeviceSocketInfo}>
                            {ds.name}
                            {ds.location ?
                              <><span className={styles.MiddleDot}>&#183;</span> {ds.location}</>
                              : ''
                            }
                          </div>
                          {ds.tag !== '' &&
                            <div className={`${styles.TagsWrapper} ${styles.BorderForSockets}`}>
                              {ds.tag.split(",").map((tag, tagIndex) => {
                                const random = Math.floor(Math.random() * 1000);
                                let moreTagsStyle = [styles.MoreTags];
                                let tagBox = [styles.TagBox];
                                if (status !== 'on') {
                                  moreTagsStyle.push(styles.Off);
                                  tagBox.push(styles.Off);
                                }
                                if (tagIndex === 3) {
                                  return <div key={`moretag+${tag}`} className={moreTagsStyle.join(' ')}>+{tag.split(",").length - 3}</div>;
                                } else if (tagIndex > 3) {
                                  return null;
                                }
                                return <div key={`tag+${tag} ${random}`} className={tagBox.join(' ')}>{tag}</div>;
                              })}
                            </div>
                          }
                          <div className={styles.ActionButtons}>
                            {
                              isAdmin ?
                                <img
                                  style={{ marginRight: 30, cursor: 'pointer' }}
                                  src={EditIcon}
                                  onMouseOver={e => (e.currentTarget.src = EditHoverIcon)}
                                  onMouseOut={e => (e.currentTarget.src = EditIcon)}
                                  alt="edit device socket"
                                  onClick={(event) => this.props.editDevice(event, 'deviceSocket', id, j)}
                                /> : null
                            }
                          </div>
                        </div>
                      );
                    })}
                  </div>
                } */}
              </div>
            </div>
          </div >
        );
      }) : null
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { loggedInUser } = state.auth;
  const { type, iconLoading, updatedDeviceSocket } = state.deviceSockets;
  return { currentOrg, loggedInUser, type, iconLoading, updatedDeviceSocket };
};

export default connect(mapStateToProps, { changeSocketIcon })(DeviceCardList);
