import React, { Component } from 'react';

import { updateObject, checkValidity } from '../../../../../state/utils';
import styles from '../SystemDeviceConfig.module.scss';
import Input from '../../../UI/Input/Input';
import SimpleSwitch from '../../../UI/SimpleSwitch/SimpleSwitch';

const DEFAULT_FORM = {
  masterHighAddress: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  },
  masterLowAddress: {
    value: '',
    valid: true,
    touched: true,
    validation: {}
  }
};

class XbeeFields extends Component {
  state = {
    form: DEFAULT_FORM,
    formIsValid: false,
    xbeeIsGateway: false
  }

  componentDidMount() {
    if (this.props.networkDetails && Object.keys(this.props.networkDetails).length) {
      this.setupXbeeForm(this.props.networkDetails, false);
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.xbeeConfig !== this.props.xbeeConfig && (this.props.xbeeConfig || this.props.xbeeConfig === '')) {
      this.setupXbeeForm(this.props.xbeeConfig, true);
    }
  }

  setupXbeeForm = (xbeeConfig, isFromOriginalConfigs) => {
    if (xbeeConfig && Object.keys(xbeeConfig).length) {
      let updatedForm = { ...this.state.form };
      for (let inputIdentifier in updatedForm) {
        let key = inputIdentifier;
        if (isFromOriginalConfigs) {
          switch (inputIdentifier) {
            case 'masterHighAddress': key = 'masterHAddr'; break;
            case 'masterLowAddress': key = 'masterLAddr'; break;
            default: break;
          }
        }
        const value = xbeeConfig[key] ? this.byte4ToHex(xbeeConfig[key]) : '';
        const updatedElement = updateObject(updatedForm[inputIdentifier], {
          value,
          valid: true,
          touched: true
        });
        updatedForm = updateObject(updatedForm, {
          [inputIdentifier]: updatedElement
        });
      }
      this.setState({ form: updatedForm, formIsValid: true });
    }
  }

  byte4ToHex = (byte4) => {
    let hex = byte4.toString(16);
    if (hex.length < 8) {
      const addedZero = 8 - hex.length;
      hex = "0".repeat(addedZero) + hex;
    }
    return hex;
  }


  handleXbeeTextChange = event => {
    const { name, value } = event.target;
    this.props.setNetworkDetails(name, value);

    let updatedElement = updateObject(this.state.form[name], {
      value: value,
      valid: checkValidity(value, this.state.form[name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.form, {
      [name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ form: updatedForm, formIsValid });
  };

  onChangeEnableXbeeStaticIpHandler = (event) => {
    event.stopPropagation();
    this.props.setNetworkDetails('xbeeIsGateway', !this.state.xbeeIsGateway);
    this.setState(prevState => {
      return { xbeeIsGateway: !prevState.xbeeIsGateway }
    });
  }

  render() {
    const { masterHighAddress, masterLowAddress } = this.state.form;

    return (
      <div style={{ display: this.props.show ? 'block' : 'none' }}>
        <div className={styles.Label}>XBee Settings</div>
        <div className={styles.SwitchWrapper}>
          <div style={{ flex: 1 }}>Gateway</div>
          <SimpleSwitch enable={this.state.xbeeIsGateway} onChangeEnableHandler={this.onChangeEnableXbeeStaticIpHandler} />
        </div>
        {
          !this.state.xbeeIsGateway ?
            <>
              <Input
                labelin="true"
                label="Master High Addr"
                name="masterHighAddress"
                type="text"
                value={masterHighAddress.value}
                autoComplete="off"
                onChange={this.handleXbeeTextChange}
              />
              <Input
                labelin="true"
                label="Master Low Addr"
                name="masterLowAddress"
                type="text"
                value={masterLowAddress.value}
                autoComplete="off"
                onChange={this.handleXbeeTextChange}
              />
            </>
            : null
        }

        <div className={styles.BetweenSection} />
      </div>
    );
  }
}

export default XbeeFields;