import React, { Component } from 'react';
import moment from 'moment';
import TimeAgo from 'react-timeago';

import styles from './WidgetContainer.module.scss';
import EditIcon from '../../../../assets/icons/edit-gray.svg';

class WidgetContainer extends Component {

  render() {
    const { data, children, onEdit, lastUpdated, lastValue } = this.props;

    let displayedLastValue = lastValue;
    if (lastValue && (lastValue.toString().includes(".00") || !lastValue.toString().includes("."))) {
      displayedLastValue = lastValue.toLocaleString();
    }

    let titleItems = null;
    let TitleStyle = styles.Title;

    if (data.type === 'date' || data.type === 'time') {
      TitleStyle = styles.TitleNoFlex;
      titleItems = <div className={styles.SubTitle}>{this.props.subTitle}</div>
    }

    return (
      <div className={styles.WidgetContainer}>
        <div className={styles.WidgetHeader}>
          <div className={styles.TitleWrapper}>
            <div className={TitleStyle}>{data.name}</div>
            {/* {this.props.errorMessage && <span className={styles.ErrorMessage}>{this.props.errorMessage}</span>} */}
            {titleItems}
          </div>
          {
            lastUpdated ?
              <div className={styles.SubTitle} style={{ margin: 0 }}>
                <div>Last value: {displayedLastValue.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })}</div>
                Last updated: <span style={{ textTransform: 'none' }}><TimeAgo date={moment(lastUpdated).valueOf()} /></span>
              </div>
              : null
          }
          {!this.props.isEdit && lastUpdated ? <div className={styles.NoData} /> : null}
          {this.props.isEdit ? <span className={styles.EditBtn} aria-hidden="true" onClick={onEdit}><img src={EditIcon} alt="Edit Widget Icon" className={styles.EditIcon} /></span> : null}
        </div>
        <div className={styles.WidgetBody}>
          {children}
        </div>
      </div>
    );
  }
}

export default WidgetContainer;
