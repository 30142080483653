import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  machines: null,
  machinesAndActuals: null,
  addedMachine: null,
  updatedMachine: null,
  defaultOperatorMachine: localStorage.getItem("OwlOperatorDefaultMachine") ? JSON.parse(localStorage.getItem("OwlOperatorDefaultMachine")) : '',
  machineAPQ: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT_OPERATOR_MACHINE: return setDefaultOperatorMachine(state, action);

    case actionTypes.GET_MACHINES_START: return getMachinesStart(state, action);
    case actionTypes.GET_MACHINES_SUCCESS: return getMachinesSuccess(state, action);
    case actionTypes.GET_MACHINES_FAIL: return getMachinesFail(state, action);

    case actionTypes.GET_MACHINES_AND_ACTUALS_START: return getMachinesAndActualsStart(state, action);
    case actionTypes.GET_MACHINES_AND_ACTUALS_SUCCESS: return getMachinesAndActualsSuccess(state, action);
    case actionTypes.GET_MACHINES_AND_ACTUALS_FAIL: return getMachinesAndActualsFail(state, action);

    case actionTypes.ADD_MACHINE_START: return addMachineStart(state, action);
    case actionTypes.ADD_MACHINE_SUCCESS: return addMachineSuccess(state, action);
    case actionTypes.ADD_MACHINE_FAIL: return addMachineFail(state, action);

    case actionTypes.EDIT_MACHINE_START: return editMachineStart(state, action);
    case actionTypes.EDIT_MACHINE_SUCCESS: return editMachineSuccess(state, action);
    case actionTypes.EDIT_MACHINE_FAIL: return editMachineFail(state, action);

    case actionTypes.DELETE_MACHINE_START: return deleteMachineStart(state, action);
    case actionTypes.DELETE_MACHINE_SUCCESS: return deleteMachineSuccess(state, action);
    case actionTypes.DELETE_MACHINE_FAIL: return deleteMachineFail(state, action);

    case actionTypes.GET_APQ_OF_MACHINE_START: return getApqOfMachineStart(state, action);
    case actionTypes.GET_APQ_OF_MACHINE_SUCCESS: return getApqOfMachineSuccess(state, action);
    case actionTypes.GET_APQ_OF_MACHINE_FAIL: return getApqOfMachineFail(state, action);

    default: return state;
  }
};

const setDefaultOperatorMachine = (state, action) => {
  localStorage.setItem("OwlOperatorDefaultMachine", action.defaultOperatorMachine);
  return updateObject(state, { defaultOperatorMachine: JSON.parse(action.defaultOperatorMachine) });
}

const getMachinesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getMachinesSuccess = (state, action) => {
  // const machines = action.machines ? action.machines.sort((a, b) => a.id - b.id) : null;
  return updateObject(state, {
    machines: action.machines,
    error: null,
    loading: false
  });
}

const getMachinesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getMachinesAndActualsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getMachinesAndActualsSuccess = (state, action) => {
  return updateObject(state, {
    machinesAndActuals: action.machinesAndActuals ? action.machinesAndActuals.sort((a, b) => a.name.localeCompare(b.name)) : null,
    error: null,
    loading: false
  });
}

const getMachinesAndActualsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addMachineStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addMachineSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedMachine: action.addedMachine,
    error: null,
    loading: false
  });
}

const addMachineFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editMachineStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editMachineSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedMachine: action.updatedMachine,
    error: null,
    loading: false
  });
}

const editMachineFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteMachineStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteMachineSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteMachineFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getApqOfMachineStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getApqOfMachineSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    machineAPQ: {
      value: action.machineAPQ,
      properties: action.properties
    },
    error: null,
    loading: false
  });
}

const getApqOfMachineFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;