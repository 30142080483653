
export const DEFAULT_ANALOG_OPTIONS = [
    { name: '4-20 mA', id: 'MA_4_20' },
    { name: '0-10 V', id: 'V_0_10' },
    { name: '24V/0V', id: 'DIGITAL' },
    { name: 'Frequency', id: 'FREQUENCY' },
    { name: 'Timestamp', id: 'TIMESTAMP' },
    { name: 'Towerlamp', id: 'TOWERLAMP' },
];

export const MODBUS_LENGTH_OPTIONS = [
    { name: '1', id: 1 },
    { name: '2', id: 2 }
];
  
export const MODBUS_ORDER_OPTIONS = [
    { name: 'Low/High', id: 'MSRF' },
    { name: 'High/Low', id: 'LSRF' }
];

export const MODBUS_VARIABLE_OPTIONS = [
    { name: 'Int', id: 'INT' },
    { name: 'Float', id: 'FLOAT' },
    { name: 'Double', id: 'DOUBLE' }
];

export const MODBUS_ACTION_TYPES = [
    { name: 'POST', id: 'POST' },
    { name: 'PRE', id: 'PRE' }
];

export const DEFAULT_MODBUS_FORM = {
    display_name: {
      value: '',
      valid: false,
      touched: false,
      validation: {
        required: true,
      }
    },
    location: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: false
      }
    },
    functions: {  // formula
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: false
      }
    },
    unit_name: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: false
      }
    },
    tag: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: false
      }
    },
    socket: {
      value: '',
      valid: false,
      touched: false,
      validation: {
        required: true,
        min: 21,
        max: 35
      }
    },
    ip: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: true,
        ipAddress: true
      }
    },
    port: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: true,
        min: 0,
        max: 65535
      }
    },
    address: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: true,
        min: 0,
        max: 255
      }
    },
    reg: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: true,
        min: 0,
        max: 9999
      }
    },
    pb_function: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: true,
        min: 0,
        max: 7
      }
    },
    length: {
      value: MODBUS_LENGTH_OPTIONS[0],
      valid: true,
      touched: true,
      validation: {
        required: true
      }
    },
    order: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: false
      }
    },
    dataType: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: false
      }
    },
    actionType: {
      value: MODBUS_ACTION_TYPES[0],
      valid: true,
      touched: true,
      validation: {
        required: false
      }
    },
    actionFunction: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: false
      }
    },
    actionRegister: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: false
      }
    },
    actionLength: {
      value: MODBUS_LENGTH_OPTIONS[0],
      valid: true,
      touched: true,
      validation: {
        required: false
      }
    },
    actionValue: {
      value: '',
      valid: true,
      touched: true,
      validation: {
        required: false
      }
    }
  };