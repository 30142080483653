import React, { Component } from 'react';
import ReactSelect from 'react-select';

import styles from './SwitchForm.module.scss';
import widgetStyles from '../WidgetForm.module.scss';
import Input from '../../../UI/Input/Input';
import AddIcon from '../../../../../assets/icons/Rule/add-circle.svg';
import OnOffGray from '../../../../../assets/icons/Widgets/Lamp/lampstyle-light-gray.svg';
import OnOffBlue from '../../../../../assets/icons/Widgets/Lamp/lampstyle-light-blue.svg';
import ToggleGray from '../../../../../assets/icons/Widgets/Lamp/lampstyle-bg-gray.svg';
import ToggleBlue from '../../../../../assets/icons/Widgets/Lamp/lampstyle-bg-blue.svg';

const colourStyles = {
	singleValue: (styles) => ({
		...styles,
		color: "white"
	}),
	control: (base, state) => ({
		...base,
		backgroundColor: "#1c1c1f",
		borderRadius: 4,
		borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
		"&:hover": {
			borderColor: "#1665d8"
		},
		fontSize: 14,
		fontWeight: 500,
	}),
	input: base => ({
		...base,
		color: "white"
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			borderColor: "#1c1c1f",
			backgroundColor: isDisabled
				? null
				: isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
		};
	},
	menu: base => ({
		...base,
		marginTop: 0,
	}),
	menuList: base => ({
		...base,
		backgroundColor: "#1c1c1f",
		fontSize: 14,
		fontWeight: 500
	}),
};


const DEFAULT_SWITCH_PROPS = {
	switchType: '',
	conditions: [{ sensor: null, on_value: 100, off_value: 0 }],
	sequenceList: {
		sensor: null,
		values: [{ value: 0, delay: 0 }]
	}
};

class SwitchForm extends Component {
	state = {
		switchProps: DEFAULT_SWITCH_PROPS
	}

	componentDidMount() {
		if (this.props.editingSwitchProps) {
			this.setupSwitchProps();
		} else {
			this.setState({ switchProps: DEFAULT_SWITCH_PROPS });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.editingSwitchProps !== this.props.editingSwitchProps && this.props.editingSwitchProps) {
			this.setupSwitchProps();
		}
	}

	setupSwitchProps = () => {
		const switchProps = { ...this.props.editingSwitchProps };
		this.setState({ switchProps });
		switchProps.isValid = true;
		this.props.handleSwitchChange(switchProps);
	}

	handleChange = (index, name, value, which) => {
		const switchProps = { ...this.state.switchProps };
		if (which === 'sequence') {
			switchProps.sequenceList.values[index][name] = name === 'delay' ? +value * 1000 : +value;
		} else if (which === 'onoff') {
			switchProps.conditions[index][name] = +value;
		}

		this.setState({ switchProps });
		switchProps.isValid = this.checkValidation(switchProps);
		this.props.handleSwitchChange(switchProps);
	}

	onSelectDevice = (index, value) => {
		const switchProps = { ...this.state.switchProps };
		if (index === -1) {
			switchProps.sequenceList.sensor = value;
		} else {
			switchProps.conditions[index].sensor = value;
		}

		this.setState({ switchProps });
		switchProps.isValid = this.checkValidation(switchProps);
		this.props.handleSwitchChange(switchProps);
	}

	handleAddHandler = (which) => {
		const switchProps = { ...this.state.switchProps };
		if (which === 'sequence') {
			switchProps.sequenceList.values.push({ value: 0, delay: 0 });
		} else if (which === 'onoff') {
			switchProps.conditions.push({ sensor: null, on_value: 100, off_value: 0 });
		}

		this.setState({ switchProps });
		switchProps.isValid = false;
		this.props.handleSwitchChange(switchProps);
	}

	handleDeleteHandler = (index, which) => {
		const switchProps = { ...this.state.switchProps };
		if (which === 'sequence') {
			switchProps.sequenceList.values.splice(index, 1);
			switchProps.sequenceList.values[0].delay = 0;
		} else if (which === 'onoff') {
			switchProps.conditions.splice(index, 1);
		}
		this.setState({ switchProps });
		switchProps.isValid = this.checkValidation(switchProps);
		this.props.handleSwitchChange(switchProps);
	}

	checkValidation = (switchProps) => {
		let isValid = true;
		if (switchProps.switchType === 'sequence') {
			if (!switchProps.sequenceList.sensor) isValid = isValid && false;
		} else if (switchProps.switchType === 'onoff') {
			switchProps.conditions.forEach(sp => {
				if (!sp.sensor) isValid = isValid && false;
			});
		}
		return isValid;
	}

	onChangeSwitchTypeHandler = (updatedType) => {
		let switchProps = { ...this.state.switchProps };
		switchProps.switchType = updatedType;
		if (this.props.editingSwitchProps) {
			if (this.props.editingSwitchProps.switchType === 'sequence' && updatedType === 'onoff') {
				switchProps.conditions = [{ sensor: null, on_value: 100, off_value: 0 }];
			} else if (this.props.editingSwitchProps.switchType === 'onoff' && updatedType === 'sequence') {
				switchProps.sequenceList = {
					sensor: null,
					values: [{ value: 0, delay: 0 }]
				};
			}
		}
		this.setState({ switchProps });
		this.props.handleSwitchChange(switchProps);
	}

	render() {
		const { switchType, conditions, sequenceList } = this.state.switchProps;

		return (
			<div className={widgetStyles.FormGroup}>
				<div style={{ marginBottom: 20 }}>
					<label className={widgetStyles.Title}>SWITCH TYPE *</label>
					<div style={{ display: 'flex' }}>
						<div className={`${styles.SwitchTypeWrapper} ${switchType === 'onoff' ? styles.Selected : ''}`} style={{ marginRight: 10, backgroundColor: switchType === 'onoff' ? 'rgba(22, 101, 216, 0.5)' : '' }} onClick={() => this.onChangeSwitchTypeHandler('onoff')}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<img src={switchType === 'onoff' ? OnOffBlue : OnOffGray} style={{ width: 36, marginRight: 10 }} alt="onoff selected" />
								<div className={`${styles.Title} ${switchType === 'onoff' ? styles.Selected : ''}`}>On/Off</div>
							</div>
						</div>
						<div className={`${styles.SwitchTypeWrapper} ${switchType === 'sequence' ? styles.Selected : ''}`} style={{ backgroundColor: switchType === 'sequence' ? 'rgba(22, 101, 216, 0.5)' : '' }} onClick={() => this.onChangeSwitchTypeHandler('sequence')}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<img src={switchType === 'sequence' ? ToggleBlue : ToggleGray} style={{ width: 40, marginRight: 5 }} alt="sequence selected" />
								<div className={`${styles.Title} ${switchType === 'sequence' ? styles.Selected : ''}`}>Sequence</div>
							</div>
						</div>
					</div>
				</div>
				{
					switchType === 'onoff' ?
						<>
							{
								this.state.switchProps ? conditions.map((sp, i) => (
									<div style={{ display: 'flex' }} key={`switch-condition-${i + 1}`}>
										<div style={{ width: '100%' }}>
											<label className={widgetStyles.Title}>{i + 1}. DEVICE *</label>
											<ReactSelect isSearchable options={this.props.deviceOptionsOnlyOutput} styles={colourStyles}
												placeholder="Select a device"
												value={conditions[i].sensor}
												onChange={(value) => this.onSelectDevice(i, value)}
												getOptionValue={opt => opt.id}
											/>
											<div style={{ display: 'flex', marginTop: 15, marginBottom: 20 }}>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<label className={widgetStyles.Title} style={{ marginBottom: 0, marginRight: 10 }}>ON*</label>
													<Input
														type="number"
														value={conditions[i].on_value}
														placeholder="Default is 100"
														autoComplete="off"
														overidestyleformgroup={{ marginRight: 15, marginBottom: 0 }}
														onChange={(event) => this.handleChange(i, 'on_value', event.target.value, 'onoff')}
													/>
												</div>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<label className={widgetStyles.Title} style={{ marginBottom: 0, marginRight: 10 }}>OFF*</label>
													<Input
														type="number"
														value={conditions[i].off_value}
														placeholder="Default is 0"
														autoComplete="off"
														overidestyleformgroup={{ marginBottom: 0 }}
														onChange={(event) => this.handleChange(i, 'off_value', event.target.value, 'onoff')}
													/>
												</div>
											</div>

										</div>
										{conditions && conditions.length > 1 && <div className={styles.DeleteButton} onClick={() => this.handleDeleteHandler(i, 'onoff')}>x</div>}
									</div>
								)) : null
							}
							<div className={widgetStyles.AddButton} onClick={() => this.handleAddHandler('onoff')}>
								<img src={AddIcon} alt="Add icon for device" className={widgetStyles.AddIcon} />
								<div className={widgetStyles.ButtonName}>Add Device</div>
							</div>
						</>
						: null
				}
				{
					switchType === 'sequence' ?
						<>
							<label className={widgetStyles.Title}>DEVICE *</label>
							<ReactSelect isSearchable options={this.props.deviceOptionsOnlyOutput} styles={colourStyles}
								placeholder="Select a device"
								value={sequenceList.sensor}
								onChange={(value) => this.onSelectDevice(-1, value)}
								getOptionValue={opt => opt.id}
							/>
							{
								sequenceList.values.map((val, j) => (
									<div key={`switch-sequence-${j + 1}`} style={{ display: 'flex', marginTop: 15, marginBottom: 20 }}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<label className={widgetStyles.Title} style={{ marginBottom: 0, marginRight: 10 }}>Value*</label>
											<Input
												type="number"
												value={val.value}
												placeholder="Default is 100"
												autoComplete="off"
												overidestyleformgroup={{ marginRight: 15, marginBottom: 0 }}
												onChange={(event) => this.handleChange(j, 'value', event.target.value, 'sequence')}
											/>
										</div>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<label className={widgetStyles.Title} style={{ marginBottom: 0, marginRight: 10 }}>Delay(s)*</label>
											<Input
												type="number"
												value={val.delay / 1000}
												placeholder="Default is 0"
												autoComplete="off"
												overidestyleformgroup={{ marginBottom: 0 }}
												onChange={(event) => this.handleChange(j, 'delay', event.target.value, 'sequence')}
												disabled={j === 0}
											/>
										</div>
										{sequenceList.values.length > 1 && <div className={`${styles.DeleteButton} ${styles.ForSequence}`} onClick={() => this.handleDeleteHandler(j, 'sequence')}>x</div>}
									</div>
								))
							}
							<div className={widgetStyles.AddButton} onClick={() => this.handleAddHandler('sequence')}>
								<img src={AddIcon} alt="Add icon for values" className={widgetStyles.AddIcon} />
								<div className={widgetStyles.ButtonName}>Add Value</div>
							</div>
						</>
						: null
				}

			</div>
		);
	}
}

export default SwitchForm;
