import React, { Component } from 'react';
import Select from 'react-select';

import styles from './ColorRangeForm.module.scss';
import Input from '../../UI/Input/Input';
import AddIcon from '../../../../assets/icons/Rule/add-circle.svg';
import DeleteIcon from '../../../../assets/icons/Rule/delete-circle.svg';

import { updateObject, checkValidity } from '../../../../state/utils';

const DEFAULT_COLOR_RANGE_FORM = {
  from_value: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  to_value: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  }
};

const colourStyles = {
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
};

class ColorRangeForm extends Component {

  state = {
    formIsValid: false,
    colorRangeForm: DEFAULT_COLOR_RANGE_FORM,
    selectedColor: null
  }

  componentDidMount() {
    this.setupForm(this.props.colorForm);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.colorForm !== this.props.colorForm && this.props.colorForm) {
      this.setupForm(this.props.colorForm);
    }
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.colorRangeForm };
    for (let inputIdentifier in updatedForm) {
      const updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] || form[inputIdentifier] === 0 ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
      });

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }

    let selectedColor = form.color;
    let formIsValid = form.isValid;
    this.setState({ colorRangeForm: updatedForm, formIsValid, selectedColor });
  }

  onChange = (value) => {
    let formIsValid = true;
    for (let inputIdentifier in this.state.colorRangeForm) {
      formIsValid = this.state.colorRangeForm[inputIdentifier].valid && formIsValid;
    }
    formIsValid = formIsValid && value !== null;

    this.props.handleColorRangeChange(this.props.index, this.state.colorRangeForm, value, formIsValid);
    this.setState({ selectedColor: value, formIsValid });
  }

  handleChange = event => {
    let updatedElement = updateObject(this.state.colorRangeForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.colorRangeForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.colorRangeForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    formIsValid = formIsValid && this.state.selectedColor !== null;

    this.props.handleColorRangeChange(this.props.index, updatedForm, this.state.selectedColor, formIsValid);
    this.setState({ colorRangeForm: updatedForm, formIsValid });
  };

  render() {
    const { from_value, to_value } = this.state.colorRangeForm;

    return (
      <div className={styles.ColorRangeForm}>
        <div style={{ display: 'flex', flex: '0 1 185px', marginRight: 15 }}>
          <div style={{ marginRight: 15 }}>
            <label className={styles.Title}>Range</label>
            <Input
              name="from_value"
              value={from_value.value}
              placeholder="From"
              type="number"
              onChange={this.handleChange}
              error={`${from_value.touched && !from_value.valid ? 'Value is required' : ''}`}
            />
          </div>
          <div>
            <label className={styles.Title}>&nbsp;</label>
            <Input
              name="to_value"
              value={to_value.value}
              placeholder="To"
              type="number"
              onChange={this.handleChange}
              error={`${to_value.touched && !to_value.valid ? 'Value is required' : ''}`}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ flex: 1 }}>
            <label className={styles.Title}>Pick color</label>
            <Select options={this.props.colorOptions} styles={colourStyles}
              placeholder="Color"
              value={this.state.selectedColor}
              onChange={this.onChange}
              getOptionValue={opt => opt.id}
            />
          </div>
          {
            this.props.formLength > 1 ?
              this.props.index === this.props.formLength - 1 ?
                <div className={styles.AddButton}>
                  <img className={styles.IconButton} src={DeleteIcon} alt="Delete Command Icon" onClick={this.props.removeColorRange} />
                  <img className={styles.IconButton} src={AddIcon} alt="Add Command Icon" onClick={this.props.addNewColorRange} />
                </div> :
                <div className={styles.AddButton}>
                  <img className={styles.IconButton} src={DeleteIcon} alt="Delete Command Icon" onClick={this.props.removeColorRange} />
                </div> :
              <div className={styles.AddButton}>
                <img className={styles.IconButton} src={AddIcon} alt="Add Command Icon" onClick={this.props.addNewColorRange} />
              </div>
          }
        </div>
      </div>
    );
  }
}

export default ColorRangeForm;
