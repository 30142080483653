import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import MultipleSelect from 'react-select';

import styles from './ProductionBatchs.module.scss';
import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';

import { getAdvanceProductionPlans } from '../../../../state/ducks/AdvanceProductionPlan/actions';
import { addAdvanceProductionBatch, editAdvanceProductionBatch } from '../../../../state/ducks/AdvanceProductionBatch/actions';

const colourStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
    color: 'white'
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "white"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "#1665d8",
  }),
  multiValueLabel: base => ({
    ...base,
    color: "white",
  })
};

const DEFAULT_BATCH_FORM = {
  batch_number: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  start: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  end: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  startTime: {
    value: '00:00',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  endTime: {
    value: '00:00',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  }
};

class BatchDrawer extends Component {
  state = {
    formIsValid: false,
    batchForm: DEFAULT_BATCH_FORM,
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAdding && (prevProps.isAdding !== this.props.isAdding)) {
      this.onClearFields();
    } else if ((prevProps.addedProductionBatch !== this.props.addedProductionBatch) || (prevProps.updatedProductionBatch !== this.props.updatedProductionBatch)) {
      this.props.closed();
    } else if (this.props.editingBatch && prevProps.editingBatch !== this.props.editingBatch) {
      this.setupForm(this.props.editingBatch);
    }
  }

  onClearFields = () => {
    const batchForm = { ...DEFAULT_BATCH_FORM };
    const defaultStop = moment().add(1, 'days').startOf('day').valueOf();
    const defaultStart = new Date().setHours(0, 0, 0, 0);
    batchForm.start.value = moment(defaultStart).format('YYYY-MM-DD');
    batchForm.end.value = moment(defaultStop).format('YYYY-MM-DD');
    this.setState({ batchForm, formIsValid: false });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.batchForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement;
      if (inputIdentifier === 'start' || inputIdentifier === 'startTime') {
        updatedElement = updateObject(inputIdentifier === 'start' ? updatedForm.start : updatedForm.startTime, {
          value: inputIdentifier === 'start' ? moment(form.start).format('YYYY-MM-DD') : moment(form.start).format('HH:mm'),
          valid: true,
          touched: true,
        });
      } else if (inputIdentifier === 'end' || inputIdentifier === 'endTime') {
        updatedElement = updateObject(inputIdentifier === 'end' ? updatedForm.end : updatedForm.endTime, {
          value: inputIdentifier === 'end' ? moment(form.end).format('YYYY-MM-DD') : moment(form.end).format('HH:mm'),
          valid: true,
          touched: true,
        });
      } else {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier] ? form[inputIdentifier] : '',
          valid: true,
          touched: true,
        });
      }

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }

    this.setState({ batchForm: updatedForm, formIsValid: true });
  }

  handleFormChange = event => {
    let updatedElement = updateObject(this.state.batchForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.batchForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.batchForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ batchForm: updatedForm, formIsValid });
  };

  onSubmitBatchHandler = (event) => {
    event.preventDefault();
    const { batch_number, start, startTime, end, endTime } = this.state.batchForm;
    if (this.props.isAdding) {
      this.props.addAdvanceProductionBatch(
        this.props.currentOrg,
        batch_number.value,
        moment(start.value + ' ' + startTime.value),
        moment(end.value + ' ' + endTime.value)
      );
    } else {
      this.props.editAdvanceProductionBatch(
        this.props.editingBatch.uuid,
        batch_number.value,
        moment(start.value + ' ' + startTime.value),
        moment(end.value + ' ' + endTime.value)
      );
    }
    this.onClearFields();
  }

  render() {
    const { batch_number, start, end, startTime, endTime } = this.state.batchForm;
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    return (
      <div>
        <div className="ErrorText">{this.props.isAdding && this.props.result === 'failed' ? this.props.error : ''}</div>
        <form onSubmit={this.onSubmitBatchHandler}>
          <Input
            label="batch"
            name="batch_number"
            type="text"
            value={batch_number.value}
            placeholder="Please insert your batch"
            autoComplete="off"
            onChange={this.handleFormChange}
            error={`${batch_number.touched && !batch_number.valid ? `Batch is required` : ''}`}
            required
          />
          <div style={{ flex: 1, marginBottom: '1rem' }}>
            <label className={styles.Title}>Start *</label>
            <div style={{ display: 'flex', position: 'relative' }}>
              {!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
              <input
                className={styles.SelectBox}
                style={{ paddingLeft: !isChrome ? 46 : null, flex: 2 }}
                type="date"
                onChange={this.handleFormChange}
                name="start"
                value={start.value}
              />
              <input
                className={styles.SelectBox}
                type="time"
                onChange={this.handleFormChange}
                name="startTime"
                value={startTime.value}
              />
            </div>
          </div>
          <div style={{ flex: 1, marginBottom: '1rem' }}>
            <label className={styles.Title}>End *</label>
            <div style={{ display: 'flex', position: 'relative' }}>
              {!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
              <input
                className={styles.SelectBox}
                style={{ paddingLeft: !isChrome ? 46 : null, flex: 2 }}
                type="date"
                onChange={this.handleFormChange}
                name="end"
                value={end.value}
              />
              <input
                className={styles.SelectBox}
                type="time"
                onChange={this.handleFormChange}
                name="endTime"
                value={endTime.value}
              />
            </div>
          </div>

          {/* <div style={{ flex: 1, marginBottom: '1rem' }}>
            <label className={styles.Title}>Production plans</label>
            <MultipleSelect
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              // options={options}
              styles={colourStyles}
              isLoading={this.props.plansLoading}
              placeholder="You can add plans to this batch"
              // value={currentSelectedMachines}
              // onChange={this.onMachinesChangeHandler}
              getOptionValue={(opt) => opt.id}
            />
          </div> */}

          <div style={{ height: 70, width: 'auto' }} />

          {
            this.props.isAdding
              ? <>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Done'}
                  color="primary"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
              </>
              : <>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Save'}
                  color="green"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Delete" color="borderred" click={this.props.toggleDeleteModal} />
              </>
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { currentOrg } = state.org;
  const { addedProductionBatch, updatedProductionBatch, result, error } = state.advanceProductionBatch;
  const { advanceProductionPlans } = state.advanceProductionPlan;
  return { loggedInUser, currentOrg, addedProductionBatch, updatedProductionBatch, result, error,
    advanceProductionPlans, plansLoading: state.advanceProductionPlan.loading
  };
};

export default connect(mapStateToProps, { addAdvanceProductionBatch, editAdvanceProductionBatch, getAdvanceProductionPlans })(BatchDrawer);