export const SET_CURRENT_ORG = 'SET_CURRENT_ORG';
export const SET_CURRENT_ORG_FROM_KEY= 'SET_CURRENT_ORG_FROM_KEY';
export const SET_FILTERED_ORG_BUFF = 'SET_FILTERED_ORG_BUFF';
export const SET_FILTERED_ORG_BUFF_UP = 'SET_FILTERED_ORG_BUFF_UP';
export const SET_FILTERED_ORG_BUFF_DOWN = 'SET_FILTERED_ORG_BUFF_DOWN';
export const SET_FILTERED_ORG_BUFF_FALSE = 'SET_FILTERED_ORG_BUFF_FALSE';
export const CLEAR_CURRENT_ORG = 'CLEAR_CURRENT_ORG';

export const GET_ORGS_START = 'GET_ORGS_START';
export const GET_ORGS = 'GET_ORGS';
export const GET_ORGS_SUCCESS = 'GET_ORGS_SUCCESS';
export const GET_ORGS_FAIL = 'GET_ORGS_FAIL';

export const ADD_ORG_START = 'ADD_ORG_START';
export const ADD_ORG = 'ADD_ORG';
export const ADD_ORG_SUCCESS = 'ADD_ORG_SUCCESS';
export const ADD_ORG_FAIL = 'ADD_ORG_FAIL';

export const EDIT_ORG_START = 'EDIT_ORG_START';
export const EDIT_ORG = 'EDIT_ORG';
export const EDIT_ORG_SUCCESS = 'EDIT_ORG_SUCCESS';
export const EDIT_ORG_FAIL = 'EDIT_ORG_FAIL';

export const DELETE_ORG_START = 'DELETE_ORG_START';
export const DELETE_ORG = 'DELETE_ORG';
export const DELETE_ORG_SUCCESS = 'DELETE_ORG_SUCCESS';
export const DELETE_ORG_FAIL = 'DELETE_ORG_FAIL';

export const INVITE_MEMBER_START = 'INVITE_MEMBER_START';
export const INVITE_MEMBER = 'INVITE_MEMBER';
export const INVITE_MEMBER_SUCCESS = 'INVITE_MEMBER_SUCCESS';
export const INVITE_MEMBER_FAIL = 'INVITE_MEMBER_FAIL';

export const REMOVE_MEMBER_START = 'REMOVE_MEMBER_START';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS';
export const REMOVE_MEMBER_FAIL = 'REMOVE_MEMBER_FAIL';

export const GET_ADMINS_START = 'GET_ADMINS_START';
export const GET_ADMINS = 'GET_ADMINS';
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMINS_FAIL = 'GET_ADMINS_FAIL';

export const INVITE_ADMIN_START = 'INVITE_ADMIN_START';
export const INVITE_ADMIN = 'INVITE_ADMIN';
export const INVITE_ADMIN_SUCCESS = 'INVITE_ADMIN_SUCCESS';
export const INVITE_ADMIN_FAIL = 'INVITE_ADMIN_FAIL';

export const REMOVE_ADMIN_START = 'REMOVE_ADMIN_START';
export const REMOVE_ADMIN = 'REMOVE_ADMIN';
export const REMOVE_ADMIN_SUCCESS = 'REMOVE_ADMIN_SUCCESS';
export const REMOVE_ADMIN_FAIL = 'REMOVE_ADMIN_FAIL';

export const GEN_API_KEY_START = 'GEN_API_KEY_START';
export const GEN_API_KEY = 'GEN_API_KEY';
export const GEN_API_KEY_SUCCESS = 'GEN_API_KEY_SUCCESS';
export const GEN_API_KEY_FAIL = 'GEN_API_KEY_FAIL';