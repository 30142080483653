import React, { Component } from 'react';

import styles from './RepeatingConfirmModal.module.scss';
import Modal from '../../UI/Modal/Modal';
import Background from '../../../../assets/images/modal-bg.svg';
import Button from '../../UI/Button/Button';

class RepeatingConfirmModal extends Component {
  componentDidMount() {
    window.addEventListener('keydown', this.handleEsc);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEsc);
  }

  handleEsc = (event) => {
    if (this.props.open && event.keyCode === 27) {
      this.props.modalClosed();
    }
  };

  render() {
    const { isEdit } = this.props;
    return (
      <Modal show={this.props.open} modalClosed={this.props.modalClosed} overideStyles={{ width: 550 }}>
        <div className={styles.RepeatingConfirmModal}>
          <div className={styles.Title}>{isEdit ? <>You are  <span style={{ color: '#34aa44' }}>CHANGING</span> a repeating event.</> : <>You are <span style={{ color: 'red' }}>DELETING</span> an event.</>}</div>
          <div className={styles.Description}>{isEdit ? "Do you want to change only this occurrence of the event," : "Do you want to delete this and all future occurrences"}</div>
          <div className={styles.Description}>{isEdit ? "or all occurrences?" : "of this event, or only the selected occurrence?"}</div>

          <div className={styles.ButtonsWrapper}>
            <div className={styles.YesButtonWrapper}>
              <Button type="button" color={`${isEdit ? 'green' : 'red'}`} name="All" click={() => this.props.confirm('all')} />
            </div>
            <div className={styles.YesButtonWrapper}>
              <Button type="button" color={`${isEdit ? 'green' : 'red'}`} name="All Future" click={() => this.props.confirm('all_future')} />
            </div>
            <div className={styles.YesButtonWrapper}>
              <Button type="button" color={`${isEdit ? 'green' : 'red'}`} name="Only This Event" click={() => this.props.confirm('only_this_event')} />
            </div>
          </div>
          <div className={styles.CancelButton} onClick={this.props.modalClosed}>Cancel</div>

        </div>
        <div className={styles.Background} style={{ backgroundImage: `url(${Background})` }} />
      </Modal>
    );
  }
}

export default RepeatingConfirmModal;