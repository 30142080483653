
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_PRODUCTION_PLAN, GET_PRODUCTION_PLANS, ADD_PRODUCTION_PLAN, EDIT_PRODUCTION_PLAN, DELETE_PRODUCTION_PLAN } from './types';

export function* watchProductionPlans() {
  yield takeEvery(GET_PRODUCTION_PLANS, getProductionPlansSaga);
  yield takeEvery(GET_PRODUCTION_PLAN, getProductionPlanSaga);
  yield takeEvery(ADD_PRODUCTION_PLAN, addProductionPlanSaga);
  yield takeEvery(EDIT_PRODUCTION_PLAN, editProductionPlanSaga);
  yield takeEvery(DELETE_PRODUCTION_PLAN, deleteProductionPlanSaga);
}

export function* getProductionPlansSaga(params) {
  yield put(actions.getProductionPlansStart());

  let api = 'v1/productionplan?org_id=' + params.org_id;
  api += params.start ? '&start=' + params.start : '';
  api += params.stop ? '&stop=' + params.stop : '';
  api += params.machine_id ? '&machine_id=' + params.machine_id : '';
  api += params.po_number ? '&number=' + params.po_number : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET PRODUCTION_PLANS SUCCESS", response.data);
    yield put(actions.getProductionPlansSuccess(response.data));
  } catch (err) {
    console.log("GET PRODUCTION_PLANS FAILED", err)
    yield put(actions.getProductionPlansFail(err.response));
  }
}

export function* getProductionPlanSaga(params) {
  yield put(actions.getProductionPlanStart());

  let api = 'v1/productionplan/' + params.id;

  try {
    const response = yield axios.get(api);
    console.log("GET SINGLE PRODUCTION_PLAN SUCCESS", response.data);
    yield put(actions.getProductionPlanSuccess(response.data));
  } catch (err) {
    console.log("GET SINGLE PRODUCTION_PLAN FAILED", err)
    yield put(actions.getProductionPlanFail(err.response));
  }
}

export function* addProductionPlanSaga(params) {
  yield put(actions.addProductionPlanStart());

  let postData = "org_id=" + params.org_id;
  postData += "&description=" + encodeURIComponent(params.description);
  postData += "&date=" + params.date;
  postData += "&start=" + params.start;
  postData += "&stop=" + params.stop;
  postData += "&shift_id=" + params.shift_id;
  postData += "&countingdevices_id=" + params.countingdevices_id;
  postData += "&target=" + params.target;
  postData += "&team_id=" + params.team_id;

  const api = 'v1/productionplan';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD PRODUCTION_PLAN SUCCESS", response);
    yield put(actions.addProductionPlanSuccess(response.data));
  } catch (err) {
    console.log("ADD PRODUCTION_PLAN FAILED", err.response.data);
    yield put(actions.addProductionPlanFail(err.response.data.error));
  }
}

export function* editProductionPlanSaga(params) {
  yield put(actions.editProductionPlanStart());

  let putData = "description=" + encodeURIComponent(params.description);
  putData += "&date=" + params.date;
  putData += "&start=" + params.start;
  putData += "&stop=" + params.stop;
  putData += "&shift_id=" + params.shift_id;
  putData += "&countingdevices_id=" + params.countingdevices_id;
  putData += "&target=" + params.target;
  putData += "&team_id=" + params.team_id;

  const api = 'v1/productionplan/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT PRODUCTION_PLAN SUCCESS", response.data);
    yield put(actions.editProductionPlanSuccess(response.data));
  } catch (err) {
    console.log("EDIT PRODUCTION_PLAN FAILED", err.response.data);
    yield put(actions.editProductionPlanFail(err.response.data));
  }
}

export function* deleteProductionPlanSaga(params) {
  yield put(actions.deleteProductionPlanStart());

  const api = 'v1/productionplan/' + params.id;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE PRODUCTION_PLAN SUCCESS", response.data);
    yield put(actions.deleteProductionPlanSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE PRODUCTION_PLAN FAILED", err.response);
    yield put(actions.deleteProductionPlanFail(err.response));
  }
}