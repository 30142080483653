import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from "../../UI/Modal/Modal";
import SwitchOrganization from "../SwitchOrganization/SwitchOrganization";
import EditOrganization from "../EditOrganization/EditOrganization";
import {
  setfilteredOrgBuff,
  setfilteredOrgBuffUp,
  setfilteredOrgBuffDown,
  setCurrentOrgFromKey,
} from "../../../../state/ducks/Organization/actions";

class OrganizationModal extends Component {
  state = {
    isSwitching: true,
    isAdding: true,
    editingOrg: null,
    editingType: "org",
    keyboardListenner: false,
    searchText: "",
  };

  keyboardListenerHandler = (toChange) => {
    this.setState({ keyboardListenner: toChange });
  };

  onModalHandler = (event) => {
    var keyCode = event.keyCode;
    if (keyCode === 27) {
      // ESC
      this.props.modalClosed();
    } else if (keyCode === 38) {
      //  arrow up
      this.setState({ keyboardListenner: true });
      this.props.setfilteredOrgBuffUp();
    } else if (keyCode === 40) {
      //  arrow down
      this.setState({ keyboardListenner: true });
      this.props.setfilteredOrgBuffDown();
    } else if (keyCode === 13) {
      // enter
      this.props.setCurrentOrgFromKey();
      this.props.modalClosed();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.open !== prevProps.open && this.props.open === false) {
      document.body.style.overflow = "unset";
      window.removeEventListener("keydown", this.onModalHandler, false);
      this.setState({
        isSwitching: true,
        isAdding: true,
        editingOrg: null,
        editingType: "org",
      });
    } else if (this.props.open !== prevProps.open && this.props.open === true) {
      document.body.style.overflow = "hidden";
      window.addEventListener("keydown", this.onModalHandler, false);
    }
  }

  toggleModeHandler = () => {
    this.setState((prevState) => {
      return { isSwitching: !prevState.isSwitching };
    });
  };

  onAddingOrg = () => {
    console.log('add')
    this.setState({
      isAdding: true,
      editingOrg: null,
      isSwitching: false,
      editingType: "org",
    });
  };

  onEditingOrg = (orgId) => {
    let editOrg = this.props.orgs.filter((org) => org.id === orgId);
    this.setState({
      isAdding: false,
      editingOrg: editOrg[0],
      isSwitching: false,
    });
  };

  onChangeEditType = (type) => {
    this.setState({ editingType: type });
  };

  render() {
    let isMobile = this.props.isMobile;
    let ovStyle = { width: 889 };
    if (isMobile) {
      ovStyle.width = "calc(100% - 20px)";
      ovStyle.maxHeight = "calc(100% - 60px)";
      ovStyle.minHeight = "100px";
    }
    return (
      <Modal
        isMobile={isMobile}
        keyboardListenerHandler={this.keyboardListenerHandler}
        show={this.props.open}
        modalClosed={this.props.modalClosed}
        overideStyles={ovStyle}
      >
        {this.state.isSwitching ? (
          <SwitchOrganization
            onAddingOrg={this.onAddingOrg}
            keyboardListenerHandler={this.keyboardListenerHandler}
            searchText={this.state.searchText}
            keyboardListenner={this.state.keyboardListenner}
            open={this.props.open}
            onEditingOrg={this.onEditingOrg}
            modalClosed={this.props.modalClosed}
            signout={this.props.signout}
            isMobile={isMobile}
          />
        ) : (
          <EditOrganization
            toggleModeHandler={this.toggleModeHandler}
            editingOrg={this.state.editingOrg}
            isAdding={this.state.isAdding}
            modalClosed={this.props.modalClosed}
            editingType={this.state.editingType}
            onChangeEditType={this.onChangeEditType}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { result, addedOrg, updatedOrg, orgs, currentOrg, orgId } = state.org;
  return { result, addedOrg, updatedOrg, orgs, currentOrg, orgId };
};

export default connect(mapStateToProps, {
  setfilteredOrgBuff,
  setfilteredOrgBuffUp,
  setfilteredOrgBuffDown,
  setCurrentOrgFromKey,
})(OrganizationModal);
