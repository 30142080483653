export const ADD_WIDGET_START = 'ADD_WIDGET_START';
export const ADD_WIDGET = 'ADD_WIDGET';
export const ADD_WIDGET_SUCCESS = 'ADD_WIDGET_SUCCESS';
export const ADD_WIDGET_FAIL = 'ADD_WIDGET_FAIL';

export const EDIT_WIDGET_START = 'EDIT_WIDGET_START';
export const EDIT_WIDGET = 'EDIT_WIDGET';
export const EDIT_WIDGET_SUCCESS = 'EDIT_WIDGET_SUCCESS';
export const EDIT_WIDGET_FAIL = 'EDIT_WIDGET_FAIL';

export const EDIT_WIDGET_LAYOUT_START = 'EDIT_WIDGET_LAYOUT_START';
export const EDIT_WIDGET_LAYOUT = 'EDIT_WIDGET_LAYOUT';
export const EDIT_WIDGET_LAYOUT_SUCCESS = 'EDIT_WIDGET_LAYOUT_SUCCESS';
export const EDIT_WIDGET_LAYOUT_FAIL = 'EDIT_WIDGET_LAYOUT_FAIL';

export const DELETE_WIDGET_START = 'DELETE_WIDGET_START';
export const DELETE_WIDGET = 'DELETE_WIDGET';
export const DELETE_WIDGET_SUCCESS = 'DELETE_WIDGET_SUCCESS';
export const DELETE_WIDGET_FAIL = 'DELETE_WIDGET_FAIL';