export const GET_DEVICES_START = 'GET_DEVICES_START';
export const GET_DEVICES = 'GET_DEVICES';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_FAIL = 'GET_DEVICES_FAIL';

export const GET_DEVICE_START = 'GET_DEVICE_START';
export const GET_DEVICE = 'GET_DEVICE';
export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
export const GET_DEVICE_FAIL = 'GET_DEVICE_FAIL';

export const ADD_DEVICE_START = 'ADD_DEVICE_START';
export const ADD_DEVICE = 'ADD_DEVICE';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const ADD_DEVICE_FAIL = 'ADD_DEVICE_FAIL';

export const EDIT_DEVICE_START = 'EDIT_DEVICE_START';
export const EDIT_DEVICE = 'EDIT_DEVICE';
export const EDIT_DEVICE_SUCCESS = 'EDIT_DEVICE_SUCCESS';
export const EDIT_DEVICE_FAIL = 'EDIT_DEVICE_FAIL';

export const DELETE_DEVICE_START = 'DELETE_DEVICE_START';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const DELETE_DEVICE_FAIL = 'DELETE_DEVICE_FAIL';

export const ADD_CUSTOM_DEVICES_START = 'ADD_CUSTOM_DEVICES_START';
export const ADD_CUSTOM_DEVICES = 'ADD_CUSTOM_DEVICES';
export const ADD_CUSTOM_DEVICES_SUCCESS = 'ADD_CUSTOM_DEVICES_SUCCESS';
export const ADD_CUSTOM_DEVICES_FAIL = 'ADD_CUSTOM_DEVICES_FAIL';

export const ADD_OWL_DEVICES_START = 'ADD_OWL_DEVICES_START';
export const ADD_OWL_DEVICES = 'ADD_OWL_DEVICES';
export const ADD_OWL_DEVICES_SUCCESS = 'ADD_OWL_DEVICES_SUCCESS';
export const ADD_OWL_DEVICES_FAIL = 'ADD_OWL_DEVICES_FAIL';

export const DELETE_OWL_DEVICES_START = 'DELETE_OWL_DEVICES_START';
export const DELETE_OWL_DEVICES = 'DELETE_OWL_DEVICES';
export const DELETE_OWL_DEVICES_SUCCESS = 'DELETE_OWL_DEVICES_SUCCESS';
export const DELETE_OWL_DEVICES_FAIL = 'DELETE_OWL_DEVICES_FAIL';

export const SEARCH_OWL_MODEL_START = 'SEARCH_OWL_MODEL_START';
export const SEARCH_OWL_MODEL = 'SEARCH_OWL_MODEL';
export const SEARCH_OWL_MODEL_SUCCESS = 'SEARCH_OWL_MODEL_SUCCESS';
export const SEARCH_OWL_MODEL_FAIL = 'SEARCH_OWL_MODEL_FAIL';

export const GET_DEVICE_STATUS_START = 'GET_DEVICE_STATUS_START';
export const GET_DEVICE_STATUS = 'GET_DEVICE_STATUS';
export const GET_DEVICE_STATUS_SUCCESS = 'GET_DEVICE_STATUS_SUCCESS';
export const GET_DEVICE_STATUS_FAIL = 'GET_DEVICE_STATUS_FAIL';

export const CLEAR_OWL_MODEL = 'CLEAR_OWL_MODEL';