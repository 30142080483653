import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './PageDrawer.module.scss';
import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import WidgetIcon from '../../../../assets/icons/widget.svg';
import TimelineIcon from '../../../../assets/icons/timeline.svg';
import UrlIcon from '../../../../assets/icons/urls.svg';
import SearchMember from '../../shared/SearchMember/SearchMember';
import PageIcon1 from '../../../../assets/images/Widget/bitmap1.svg';
import PageIcon2 from '../../../../assets/images/Widget/bitmap2.svg';
import PageIcon3 from '../../../../assets/images/Widget/bitmap3.svg';
import PageIcon4 from '../../../../assets/images/Widget/bitmap4@2x.jpg';
import FloorPlanIcon from '../../../../assets/icons/floor-plan.png';

import { getPageAndMembers, addPage, editPage, changePageAdmin } from '../../../../state/ducks/Pages/actions';

const TYPE_WIDGET_VALUE = 1;
const TYPE_URL_VALUE = 2;
const TYPE_TIMELINE_VALUE = 3;
const TYPE_FLOORPLAN_VALUE = 4;

const pageTypes = [{
  title: 'Widgets',
  icon: WidgetIcon,
  value: TYPE_WIDGET_VALUE
}, {
  title: 'URL',
  icon: UrlIcon,
  value: TYPE_URL_VALUE
},
// {
//   title: 'Floor Plan',
//   icon: FloorPlanIcon,
//   value: TYPE_FLOORPLAN_VALUE
// }
];

const DEFAULT_PAGE_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  detail: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  page_type: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isNumeric: true
    }
  },
  url: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  day_starting_time: {
    value: '00:00',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  }
};

class PageDrawer extends Component {
  state = {
    formIsValid: false,
    pageForm: DEFAULT_PAGE_FORM,
    addingMembers: '',
    deletingMembers: '',
    newAdmin: null,
    isPageFormChanged: false
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAdding && (prevProps.isAdding !== this.props.isAdding)) {
      this.onClearFields();
    } else if ((prevProps.addedPage !== this.props.addedPage) || (prevProps.updatedPage !== this.props.updatedPage)) {
      this.props.closed();
      this.onClearFields();
    } else if (this.props.editingPage && prevProps.editingPage !== this.props.editingPage) {
      this.setupForm(this.props.editingPage);
      this.props.getPageAndMembers(this.props.editingPage.id);
    }
    if (this.props.isDrawerOpened && this.props.editingPage && (prevProps.isDrawerOpened !== this.props.isDrawerOpened)) {
      this.onClearMemberChange();
    }
    if (prevProps.currentOrg !== this.props.currentOrg || prevProps.orgs !== this.props.orgs) {
      this.checkOrgModule();
    }
  }

  checkOrgModule = () => {
    const { currentOrg, orgs } = this.props;

    let foundedOrg = null;
    if (orgs && orgs.length > 0) {
      foundedOrg = orgs[0];
      if (currentOrg) {
        orgs.forEach(org => {
          if (org.id === parseInt(currentOrg))
            foundedOrg = org;
        });
      }
    }

    if (foundedOrg && foundedOrg.modules) {
      if (foundedOrg.modules.includes(2)) {
        if (pageTypes.length === 2) {
          pageTypes.push({
            title: 'Timeline',
            icon: TimelineIcon,
            value: TYPE_TIMELINE_VALUE
          });
        }
        return;
      }
    }

    if (pageTypes.length > 2) {
      pageTypes.pop();
    }
  }

  onClearFields = () => {
    this.setState({ pageForm: DEFAULT_PAGE_FORM, formIsValid: false });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.pageForm };
    let updatedElement = {};
    for (let inputIdentifier in updatedForm) {
      if (inputIdentifier === 'day_starting_time' && form.page_type === TYPE_TIMELINE_VALUE) {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form.properties['day_starting_time'] ? form.properties['day_starting_time'] : '00:00',
          valid: true,
          touched: true,
          validation: { required: true }
        });
      } else {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier] ? form[inputIdentifier] : '',
          valid: true,
          touched: true,
        });
      }
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }
    this.setState({ pageForm: updatedForm, formIsValid: true, isPageFormChanged: false });
  }

  handleTextChange = event => {
    const { name, value } = event.target;
    let updatedForm = null;
    let formIsValid = true;
    let updatedElement = updateObject(this.state.pageForm[name], {
      value,
      valid: checkValidity(value, this.state.pageForm[name].validation),
      touched: true
    });

    updatedForm = updateObject(this.state.pageForm, {
      [name]: updatedElement
    });

    for (let inputIdentifier in updatedForm) {
      if (!(inputIdentifier === 'url' && updatedForm['page_type'].value === TYPE_WIDGET_VALUE)) {
        formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
      }
    }

    if (this.props.editingPage) {
      let isPageFormChanged = this.props.editingPage.name !== updatedForm.name.value || this.props.editingPage.detail !== updatedForm.detail.value;
      this.setState({ isPageFormChanged });
    }
    this.setState({ pageForm: updatedForm, formIsValid });
  };

  handleTypeChange = (index) => {
    let updatedElement = updateObject(this.state.pageForm['page_type'], {
      value: pageTypes[index].value,
      valid: checkValidity(pageTypes[index].value, this.state.pageForm['page_type'].validation),
      touched: true
    });
    const updatedForm = updateObject(this.state.pageForm, {
      page_type: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      if (inputIdentifier === 'day_starting_time')
        continue;

      if (inputIdentifier === 'url' && updatedForm['page_type'].value !== TYPE_URL_VALUE)
        continue;

      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ pageForm: updatedForm, formIsValid });
  }

  onSaveOrDeleteMembersHandler = (type, members) => {
    let user_ids = '';
    members.forEach((member, i) => {
      user_ids += member.id;
      if (i !== members.length - 1) {
        user_ids += ',';
      }
    });

    if (type === 'add') {
      this.setState({ addingMembers: user_ids });
    } else {
      this.setState({ deletingMembers: user_ids });
    }
  }

  onSubmitPageHandler = (event) => {
    event.preventDefault();
    const { name, detail, page_type, url, day_starting_time } = this.state.pageForm;
    if (this.props.isAdding) {
      const ICONS = [PageIcon1, PageIcon2, PageIcon3, PageIcon4];
      const random = Math.floor(Math.random() * 4);
      let properties = {
        image: ICONS[random],
        widget_columns: 4
      };
      if (page_type.value === TYPE_TIMELINE_VALUE) {
        properties.day_starting_time = day_starting_time.value;
      }
      this.props.addPage(
        this.props.currentOrg,
        name.value,
        detail.value,
        page_type.value,
        page_type.value !== TYPE_URL_VALUE ? null : url.value,
        page_type.value !== TYPE_URL_VALUE ? properties : null
      );
    } else {
      if (this.state.newAdmin) {
        this.props.changePageAdmin(this.props.editingPage.id, this.state.newAdmin);
        this.setState({ newAdmin: null });
      } else {
        let properties = this.props.editingPage.properties;
        if (page_type.value === TYPE_TIMELINE_VALUE) {
          properties.day_starting_time = day_starting_time.value;
        }
        this.props.editPage(
          this.props.editingPage.id,
          name.value,
          detail.value,
          page_type.value,
          page_type.value === TYPE_WIDGET_VALUE ? null : url.value,
          properties,
          this.state.addingMembers ? this.state.addingMembers : null,
          this.state.deletingMembers ? this.state.deletingMembers : null
        )
      }
    }
  }

  isChangingAdminHandler = (uid) => {
    this.setState({ newAdmin: uid });
  }

  onClearMemberChange = () => {
    this.setState({ newAdmin: null, addingMembers: '', deletingMembers: '' });
  }

  render() {
    const { name, detail, page_type, url, day_starting_time } = this.state.pageForm;
    let optionalInputs = null;
    if (page_type.value === TYPE_URL_VALUE && this.props.isAdding) {
      optionalInputs = (
        <div>
          <input
            name="url"
            type="text"
            placeholder="Page URL *"
            className={`${styles.PageUrlInput} ${(url.touched && !url.valid) || (this.props.error && this.props.error === 'Incorrect url') ? styles.Error : ''} `}
            value={url.value}
            onChange={this.handleTextChange}
            autoComplete="off"
          />
          {url.touched && !url.valid ? <div className="ErrorText">URL is required</div> : this.props.error && this.props.error === 'Incorrect url' ? <div className="ErrorText">Incorrect Url</div> : null}
        </div>
      );
    }
    if (page_type.value === TYPE_TIMELINE_VALUE) {
      optionalInputs = (
        <div>
          <Input
            name="day_starting_time"
            type="time"
            label="Day Starting Time"
            value={day_starting_time.value}
            onChange={this.handleTextChange}
            autoComplete="off"
          />
        </div>
      );
    }

    const isPageAdmin = this.props.editingPage && this.props.loggedInUser.id === this.props.editingPage.admin_id;
    const isOrgAdmin = this.props.currentOrg && this.props.loggedInUser.admin_of_org && this.props.loggedInUser.admin_of_org.includes(+this.props.currentOrg);
    const isAdmin = this.props.loggedInUser && this.props.loggedInUser.is_superuser;

    return (
      <div>
        <form onSubmit={this.onSubmitPageHandler}>
          {this.props.error && this.props.error !== 'Incorrect url' ? <div className={styles.ErrorMessage}>{this.props.error}</div> : null}
          <Input
            label="page name"
            name="name"
            type="text"
            value={name.value}
            placeholder="Please insert your page name"
            autoComplete="off"
            onChange={this.handleTextChange}
            error={`${name.touched && !name.valid ? 'Page Name is required' : ''}`}
            required
            disabled={(!this.props.isAdding && !isPageAdmin && !isOrgAdmin && !isAdmin) || this.state.newAdmin}
          />
          <Input
            label="detail"
            name="detail"
            value={detail.value}
            type="textarea"
            placeholder="What is this Page about? Tell people more juicy details."
            rows="5"
            onChange={this.handleTextChange}
            error={`${detail.touched && !detail.valid ? 'Detail is required' : ''}`}
            required
            disabled={(!this.props.isAdding && !isPageAdmin && !isOrgAdmin && !isAdmin) || this.state.newAdmin}
          />
          {
            this.props.isAdding ?
              <React.Fragment>
                <Input
                  label="type"
                  type="radio"
                  options={pageTypes}
                  name="page_type"
                  value={page_type.value}
                  onChangeRadioButton={this.handleTypeChange}
                  required
                />
                {optionalInputs}
              </React.Fragment>
              :
              <div style={{ position: 'relative' }}>
                {optionalInputs}
                <SearchMember
                  editing={this.props.editingPage}
                  isDrawerOpened={this.props.isDrawerOpened}
                  saveOrDeleteMembers={this.onSaveOrDeleteMembersHandler}
                  isChangingAdmin={this.isChangingAdminHandler}
                  membersAlreadyIn={this.props.pageAndMembers ? this.props.pageAndMembers.members : null}
                  isFormChanged={this.state.isPageFormChanged}
                  isOrgAdmin={isOrgAdmin}
                  newAdmin={this.state.newAdmin}
                />
              </div>
          }

          <div style={{ height: 70, width: 'auto' }} />
          {
            this.props.isAdding ?
              <React.Fragment>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Done'}
                  color="primary"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
              </React.Fragment>
              : isPageAdmin || isOrgAdmin || isAdmin ?
                <React.Fragment>
                  <Button
                    type="submit"
                    name={this.props.loading ? 'Loading...' : 'Save'}
                    color="green"
                    disabled={!this.state.formIsValid}
                    loading={this.props.loading}
                  />
                  <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
                </React.Fragment>
                :
                <Button
                  type="button"
                  name={this.props.loading ? 'Loading...' : 'Leave this page'}
                  color="borderred"
                  click={this.props.leavePage}
                  loading={this.props.loading}
                />
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { currentOrg, orgs } = state.org;
  const { addedPage, updatedPage, pageAndMembers, loading, error } = state.pages;
  return { loggedInUser, currentOrg, orgs, addedPage, updatedPage, pageAndMembers, loading, error };
};

export default connect(mapStateToProps, {
  getPageAndMembers,
  addPage,
  editPage,
  changePageAdmin
})(PageDrawer);