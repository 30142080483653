import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import styles from './FormulaModal.module.scss';
import Modal from '../../UI/Modal/Modal';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import RadioUnselectIcon from '../../../../assets/icons/radio-unselect.svg';
import RadioSelectIcon from '../../../../assets/icons/radio-select.svg';

import { updateObject, checkValidity } from '../../../../state/utils';
import { getVariables, addVariable, editVariable } from '../../../../state/ducks/Variable/actions';
import { getMachines } from '../../../../state/ducks/Machine/actions';
import { getProductionPlanStructures } from '../../../../state/ducks/AdvanceProductionPlanStructure/actions';
import { getDevices } from '../../../../state/ducks/Devices/actions';
import CustomStartEnd from './CustomStartEnd';

const colourStyles = {
    control: (base, state) => ({
        ...base,
        backgroundColor: "white",
        borderRadius: 4,
        borderColor: state.isFocused ? "#1665d8" : "#e2e5ed",
        "&:hover": {
            borderColor: "#e2e5ed"
        },
        fontSize: 14,
        fontWeight: 500,
        color: 'black'
    }),
    input: base => ({
        ...base,
        color: 'black'
    }),
    singleValue: (styles) => ({
        ...styles,
        color: "black"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            borderColor: "white",
            backgroundColor: isDisabled
                ? null
                : isFocused ? 'rgba(22, 101, 216, 0.7)' : "white",
            color: 'rgba(0, 0, 0, 0.7)'
        };
    },
    menu: base => ({
        ...base,
        marginTop: 0,
        zIndex: 1000
    }),
    menuList: base => ({
        ...base,
        backgroundColor: "white",
        fontSize: 14,
        fontWeight: 500
    }),
    multiValue: base => ({
        ...base,
        backgroundColor: "#1665d8",
    }),
    multiValueLabel: base => ({
        ...base,
        color: "black",
    })
};

const METHOD_OPTION = [{ id: 'rt', label: 'Real Time' },
{ id: 'avg', label: 'Average' }, { id: 'max', label: 'Max' }, { id: 'min', label: 'Min' }, { id: 'mode', label: 'Mode' }, { id: 'mean', label: 'Mean' },
{ id: 'sum', label: 'Sum' }, { id: 'count', label: 'Count' }, { id: 'inc', label: 'Increment' }, { id: 'firstts', label: 'Firstts' }, { id: 'lastts', label: 'Lastts' }, { id: 'tsdelta', label: 'Tsdelta' }, { id: 'sumdelta', label: 'Sumdelta' }
];
const DATA_SYMBOL = [{ id: 1, label: 'Equal', value: '=' }, { id: 2, label: 'Not equal', value: '!=' }];
const FORMULA_TS = [{ id: 'timerange', label: 'Time range (ms)' }, { id: 'startend', label: 'Start - End' }];
const TIME_OPTION = [{ id: 'yesterday', label: 'Yesterday' }, { id: 'now', label: 'Now' }, { id: 'today', label: 'Today' }, { id: 'tomorrow', label: 'Tomorrow' }, { id: 'poattr', label: 'PO attr' }];
const MENU = [{ id: 1, name: 'Formula' }, { id: 2, name: 'PO.' }, { id: 3, name: 'Import' }, { id: 4, name: 'Number' }, { id: 5, name: 'Manual' }];
const DEFAULT_VAR_FORM = {
    name: {
        value: '',
        valid: false,
        touched: false,
        validation: {
            required: true
        }
    },
    value: {
        value: '',
        valid: true,
        touched: true,
        validation: {
            required: false
        }
    }
};

class FormulaModal extends Component {
    state = {
        selectingMenu: 1,
        variableForm: DEFAULT_VAR_FORM,
        formIsValid: false,
        formulaValues: {},
        methodOption: [],
        deviceOption: [],
        importsOption: [],
        machinesOption: [],
        structuresOption: [],
        columnsOption: [],
        poValues: {},
        importValue: null,
        manualFormula: '',
        customStart: '',
        customEnd: '',
        isSettingCustomStartEnd: false,
        isSettingCustomMode: null
    }
    componentDidMount() {
        window.addEventListener('keydown', this.handleEsc);
        this.props.getVariables(this.props.currentOrg);
        this.props.getMachines(this.props.currentOrg);
        this.props.getProductionPlanStructures(this.props.currentOrg);
        this.props.getDevices(this.props.currentOrg, true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.machines !== prevProps.machines && this.props.machines) {
            this.setMachinesOption(this.props.machines);
        } else if (this.props.planStructures !== prevProps.planStructures && this.props.planStructures) {
            this.setStructuresOption(this.props.planStructures);
        } else if (this.props.variables !== prevProps.variables && this.props.variables) {
            this.setImportsOption(this.props.variables);
        } else if (this.props.devices !== prevProps.devices && this.props.devices) {
            this.setDeviceOption(this.props.devices);
        } else if (this.props.editingVariable !== prevProps.editingVariable && this.props.editingVariable) {
            this.setState({ formulaValues: {}, poValues: {}, importValue: null, customStart: '', customEnd: '', });
            this.setupForm(this.props.editingVariable);
        }

        if (prevProps.result !== this.props.result && this.props.result === 'success') {
            if (this.props.addedVariable !== prevProps.addedVariable) {
                const { id, name, value, org_id, properties } = this.props.addedVariable;
                this.props.saveVariable({ id, name, value, org_id, properties });
                this.props.getVariables(this.props.currentOrg);
            } else if (this.props.updatedVariable !== prevProps.updatedVariable) {
                const { id, name, value, org_id, properties } = this.props.updatedVariable;
                this.props.saveVariable({ id, name, value, org_id, properties });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleEsc);
    }

    handleEsc = (event) => {
        if (this.props.open && event.keyCode === 27) {
            this.props.modalClosed();
        }
    };

    setupForm = (form) => {
        let updatedForm = { ...this.state.variableForm };
        for (let inputIdentifier in updatedForm) {
            let updatedElement = updateObject(updatedForm[inputIdentifier], {
                value: form[inputIdentifier] ? form[inputIdentifier] : '',
                valid: true,
                touched: true,
                validation: {
                    required: true,
                },
            });

            updatedForm = updateObject(updatedForm, {
                [inputIdentifier]: updatedElement,
            });
        }

        let selectingMenu = 1;
        if (form.properties) {
            if (form.properties.formula) {
                let formulaValues = {};
                let fullFormula = '';
                if (form.value.indexOf('rt:') !== -1) {
                    fullFormula = form.value.split('rt:');
                    formulaValues.method = METHOD_OPTION.find(mo => mo.id === 'rt');
                    const device = fullFormula[1].split('}')[0];
                    formulaValues.device = this.state.deviceOption.find(dop => dop.id === device);
                } else {
                    if (form.value.indexOf('ra:') !== -1) {
                        fullFormula = form.value.split('ra:');
                    } else {
                        fullFormula = form.value.split('{');
                    }
                    const method = fullFormula[1].split('(')[0];
                    formulaValues.method = METHOD_OPTION.find(mo => mo.id === method);

                    const methodKeySplit = method + '(';
                    const deviceAndTime = fullFormula[1].split(methodKeySplit)[1];
                    const theRest = deviceAndTime.split('@');
                    let socket = '';
                    if (theRest[1].indexOf('!=') !== -1) {
                        formulaValues.dataSymbol = DATA_SYMBOL.find(ds => ds.value === '!=');
                        formulaValues.deviceData = theRest[1].split('!=')[1];
                        socket = theRest[1].split('!=')[0];
                    } else if (theRest[1].indexOf('=') !== -1) {
                        formulaValues.dataSymbol = DATA_SYMBOL.find(ds => ds.value === '=');
                        formulaValues.deviceData = theRest[1].split('=')[1];
                        socket = theRest[1].split('=')[0];
                    } else {
                        socket = theRest[1];
                    }

                    const device = theRest[0] + '@' + socket;
                    formulaValues.device = this.state.deviceOption.find(dop => dop.id === device);

                    if (theRest.length === 3) {
                        if (theRest[2].indexOf('now') !== -1 || theRest[2].indexOf('today') !== -1 || theRest[2].indexOf('tomorrow') !== -1 || theRest[2].indexOf('yesterday') !== -1) {
                            formulaValues.formulaTimeSystem = FORMULA_TS[1];
                            let period = theRest[2].replaceAll(')}', '');
                            // e.g. theRest[2] = yesterday(08,00)-now())}
                            period = period.replaceAll(',', ':');
                            const start = period.split('-')[0]; // == now(), today(xx:yy)
                            formulaValues.start = TIME_OPTION.find(to => to.id === start.split('(')[0]);
                            if (formulaValues.start.id === 'now') formulaValues.disabledStart = true;
                            else {
                                let startTime = start.replace(formulaValues.start.id, '');
                                startTime = startTime.replace('(', '');
                                startTime = startTime.replace(')', '');
                                formulaValues.startTime = startTime;
                            }
                            const end = period.split('-')[1];
                            formulaValues.end = TIME_OPTION.find(to => to.id === end.split('(')[0]);
                            if (formulaValues.end.id === 'now') formulaValues.disabledEnd = true;
                            else {
                                let endTime = end.replace(formulaValues.end.id, '');
                                endTime = endTime.replace('(', '');
                                endTime = endTime.replace(')', '');
                                formulaValues.endTime = endTime;
                            }
                        } else {
                            formulaValues.formulaTimeSystem = FORMULA_TS[0];
                            formulaValues.timeRange = +(theRest[2].replaceAll(')}', ''));
                        }
                    } else {
                        // if theRest has length of array = 4, which means it has poattr inside
                        formulaValues.formulaTimeSystem = FORMULA_TS[1];
                        let timeSeries = '';
                        for (let i = 2; i <= theRest.length - 1; i++) {
                            timeSeries += theRest[i] + (i === theRest.length - 1 ? '' : '@');
                        }
                        if (timeSeries.indexOf('}-') !== -1) {
                            // there is a poattr at START
                            formulaValues.start = TIME_OPTION.find(to => to.id === 'poattr');
                            let customStart = timeSeries.split('}-')[0] + '}';
                            this.setState({ customStart });

                            const checkTime = timeSeries.split('}-')[1];
                            if (checkTime.indexOf('poattr') === -1) {
                                let period = checkTime.replaceAll(')}', '');
                                period = period.replaceAll(',', ':'); // period should be now(), yesterday(hh:mm), today(hh:mm), tomorrow(hh:mm)
                                formulaValues.end = TIME_OPTION.find(to => to.id === period.split('(')[0]);
                                if (formulaValues.end.id === 'now') formulaValues.disabledEnd = true;
                                else {
                                    let endTime = period.replace(formulaValues.end.id, '');
                                    endTime = endTime.replace('(', '');
                                    endTime = endTime.replace(')', '');
                                    formulaValues.endTime = endTime;
                                }
                            }
                        }
                        if (timeSeries.indexOf('-{') !== -1) {
                            // there is a poattr at END
                            formulaValues.end = TIME_OPTION.find(to => to.id === 'poattr');
                            let customEnd = '{' + timeSeries.split('-{')[1];
                            customEnd = customEnd.replace(')})}', ')}');
                            this.setState({ customEnd });

                            const checkTime = timeSeries.split('-{')[0];
                            if (checkTime.indexOf('poattr') === -1) {
                                let period = checkTime;
                                period = period.replaceAll(',', ':'); // period should be now(), yesterday(hh:mm), today(hh:mm), tomorrow(hh:mm)
                                formulaValues.start = TIME_OPTION.find(to => to.id === period.split('(')[0]);
                                if (formulaValues.start.id === 'now') formulaValues.disabledStart = true;
                                else {
                                    let startTime = period.replace(formulaValues.start.id, '');
                                    startTime = startTime.replace('(', '');
                                    startTime = startTime.replace(')', '');
                                    formulaValues.startTime = startTime;
                                }
                            }
                        }
                    }
                }
                this.setState({ formulaValues });
            } else if (form.properties.poattr) {
                selectingMenu = 2;
                const poattr = form.value.split(':');
                let poValues = {};
                if (this.props.machines) {
                    const foundMachine = this.props.machines.find(m => m.id === +poattr[1]);
                    if (foundMachine) poValues.machine = { id: foundMachine.id, label: foundMachine.name };
                    else poValues.machine = { id: -1, label: '(not found)' };
                }
                const foundStructure = this.props.planStructures.find(ps => ps.uuid === poattr[2]);
                poValues.structure = { id: foundStructure.uuid, label: foundStructure.name };
                this.onChangePoValuesHandler('structure', poValues.structure);
                const colAndTimes = poattr[3].split('@');
                const col = colAndTimes[0];
                const foundColumn = foundStructure.columns.find(sc => sc.name === col);
                poValues.column = { id: col, label: foundColumn.display_name };
                if (poattr.length === 4) {
                    if (this.props.planStructures) {
                        // colAndTimes[1] = now()-today(20,00)}
                        let period = colAndTimes[1].slice(0, -1);
                        period = period.replaceAll(',', ':');
                        const start = period.split('-')[0]; // == new(), today(xx:yy)
                        poValues.start = TIME_OPTION.find(to => to.id === start.split('(')[0]);
                        if (poValues.start.id === 'now') poValues.disabledStart = true;
                        else {
                            let startTime = start.replace(poValues.start.id, '');
                            startTime = startTime.replace('(', '');
                            startTime = startTime.replace(')', '');
                            poValues.startTime = startTime;
                        }
                        const end = period.split('-')[1];
                        poValues.end = TIME_OPTION.find(to => to.id === end.split('(')[0]);
                        if (poValues.end.id === 'now') poValues.disabledEnd = true;
                        else {
                            let endTime = end.replace(poValues.end.id, '');
                            endTime = endTime.replace('(', '');
                            endTime = endTime.replace(')', '');
                            poValues.endTime = endTime;
                        }
                    }
                } else {
                    // poattr inside somewhere
                    let timeSeries = '' + colAndTimes[1] + ':';
                    for (let i = 4; i <= poattr.length - 1; i++) {
                        timeSeries += poattr[i] + (i === poattr.length - 1 ? '' : ':');
                    }
                    if (timeSeries.indexOf('}-') !== -1) {
                        // there is a poattr at START
                        poValues.start = TIME_OPTION.find(to => to.id === 'poattr');
                        let customStart = timeSeries.split('}-')[0] + '}';
                        this.setState({ customStart });

                        const checkTime = timeSeries.split('}-')[1];
                        if (checkTime.indexOf('poattr') === -1) {
                            let period = checkTime.replaceAll(')}', ')');
                            period = period.replaceAll(',', ':'); // period should be now(), yesterday(hh:mm), today(hh:mm), tomorrow(hh:mm)
                            poValues.end = TIME_OPTION.find(to => to.id === period.split('(')[0]);
                            if (poValues.end.id === 'now') poValues.disabledEnd = true;
                            else {
                                let endTime = period.replace(poValues.end.id, '');
                                endTime = endTime.replace('(', '');
                                endTime = endTime.replace(')', '');
                                poValues.endTime = endTime;
                            }
                        }
                    }
                    if (timeSeries.indexOf('-{') !== -1) {
                        // there is a poattr at END
                        poValues.end = TIME_OPTION.find(to => to.id === 'poattr');
                        let customEnd = '{' + timeSeries.split('-{')[1];
                        customEnd = customEnd.replace(')}}', ')}');
                        this.setState({ customEnd });

                        const checkTime = timeSeries.split('-{')[0];
                        if (checkTime.indexOf('poattr') === -1) {
                            let period = checkTime;
                            period = period.replaceAll(',', ':'); // period should be now(), yesterday(hh:mm), today(hh:mm), tomorrow(hh:mm)
                            poValues.start = TIME_OPTION.find(to => to.id === period.split('(')[0]);
                            if (poValues.start.id === 'now') poValues.disabledStart = true;
                            else {
                                let startTime = period.replace(poValues.start.id, '');
                                startTime = startTime.replace('(', '');
                                startTime = startTime.replace(')', '');
                                poValues.startTime = startTime;
                            }
                        }
                    }
                }
                this.setState({ poValues });
            } else if (form.properties.import) {
                selectingMenu = 3;
                if (this.props.variables) {
                    const foundImport = this.props.variables.find(v => v.id === form.id);
                    this.setState({ importValue: { id: foundImport.id, label: foundImport.name } });
                }
            } else if (form.properties.number) {
                selectingMenu = 4;
            } else if (form.properties.manual) {
                selectingMenu = 5;
                this.setState({ manualFormula: form.value });
            }
        }
        this.setState({ variableForm: updatedForm, formIsValid: true, selectingMenu });
    };

    handleFormChange = (event) => {
        let updatedElement = updateObject(this.state.variableForm[event.target.name], {
            value: event.target.value,
            valid: checkValidity(event.target.value, this.state.variableForm[event.target.name].validation),
            touched: true
        });

        const updatedForm = updateObject(this.state.variableForm, {
            [event.target.name]: updatedElement
        });

        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
        }

        this.setState({ variableForm: updatedForm, formIsValid });
    };

    handleManualFormulaChange = (event) => {
        this.setState({ manualFormula: event.target.value });
    }

    onChangeMenuHandler = (value) => {
        this.setState({ selectingMenu: value });
    }

    setDeviceOption = (devices) => {
        const deviceOption = [];
        if (devices) {
            devices.forEach(device => {
                device.device_sockets.forEach(ds => {
                    if (!ds.can_control) {
                        deviceOption.push({
                            id: ds.device_secret + '@' + ds.socket,
                            name: ds.name + " (" + device.name + ")",
                            label: ds.name + " (" + device.name + ")",
                            socketId: ds.id
                        });
                    }
                });
            });
        }
        this.setState({ deviceOption });
    }

    setImportsOption = (variables) => {
        const importsOption = [];
        variables.forEach(v => {
            importsOption.push({
                id: v.id,
                label: v.name
            });
        });
        this.setState({ importsOption });
    }

    handleImportChange = (value) => {
        this.setState({ importValue: value });
    }

    setMachinesOption = (machines) => {
        const machinesOption = [];
        machines.forEach(machine => {
            machinesOption.push({
                id: machine.id,
                label: machine.name
            });
        });
        this.setState({ machinesOption });
    }

    setStructuresOption = (planStructures) => {
        const structuresOption = [];
        planStructures.forEach(ps => {
            structuresOption.push({
                id: ps.uuid,
                label: ps.name
            });
        });
        this.setState({ structuresOption });
    }

    onChangeFormulaValuesHandler = (which, value) => {
        const formulaValues = { ...this.state.formulaValues };
        formulaValues[which] = value;
        if (which === 'formulaTimeSystem') {
            if (value.id === 'timerange') {
                formulaValues.start = '';
                formulaValues.startTime = '';
                formulaValues.disabledStart = false;
                formulaValues.end = '';
                formulaValues.endTime = '';
                formulaValues.disabledEnd = false;
            } else {
                // start - end
                formulaValues.timeRange = '';
            }
        } else if (which === 'start') {
            if (value.id === 'now') {
                formulaValues.startTime = '';
                formulaValues.disabledStart = true;
            } else {
                formulaValues.disabledStart = false;
            }
        } else if (which === 'end') {
            if (value.id === 'now') {
                formulaValues.endTime = '';
                formulaValues.disabledEnd = true;
            } else {
                formulaValues.disabledEnd = false;
            }
        } else if (which === 'method') {
            if (value.id !== 'firstts' && value.id !== 'lastts' && value.id !== 'tsdelta' && value.id !== 'sumdelta') {
                formulaValues.dataSymbol = '';
                formulaValues.deviceData = '';
            }
        }
        this.setState({ formulaValues });
    }

    onChangePoValuesHandler = (which, value) => {
        const poValues = { ...this.state.poValues };
        if (which === 'structure') {
            poValues.structure = value;
            poValues.column = '';
            const foundStructure = this.props.planStructures.find(ps => ps.uuid === value.id);
            const columnsOption = [{ id: 'target', label: 'Target' }, { id: 'actual_start', label: 'Actual Start' }, { id: 'actual_end', label: 'Actual End' }];
            if (foundStructure.columns && foundStructure.columns.length) {
                foundStructure.columns.forEach(c => {
                    columnsOption.push({
                        id: c.name,
                        label: c.display_name
                    });
                });
            }
            this.setState({ columnsOption });
        } else if (which === 'start') {
            poValues.start = value;
            if (value.id === 'now') {
                poValues.startTime = '';
                poValues.disabledStart = true;
            } else {
                poValues.disabledStart = false;
            }
        } else if (which === 'end') {
            poValues.end = value;
            if (value.id === 'now') {
                poValues.endTime = '';
                poValues.disabledEnd = true;
            } else {
                poValues.disabledEnd = false;
            }
        } else {
            poValues[which] = value;
        }
        this.setState({ poValues });
    }

    onSetCustomStartEnd = (which) => {
        this.setState({ isSettingCustomStartEnd: true, isSettingCustomMode: which });
    }

    onSaveCustomStartEndHandler = (value) => {
        if (this.state.isSettingCustomMode === 'start') {
            this.setState({ customStart: value, isSettingCustomStartEnd: false });
        } else {
            this.setState({ customEnd: value, isSettingCustomStartEnd: false });
        }
    }

    checkSubmitButton = () => {
        let disabled = false;
        const { value } = this.state.variableForm;
        if (this.state.selectingMenu === 1) {
            const { formulaValues } = this.state;
            if (!formulaValues.method || !formulaValues.device) {
                disabled = true;
            } else if (formulaValues.method && formulaValues.method.id === 'rt') {
                if (!formulaValues.method || !formulaValues.device)
                    disabled = true;
            } else if (formulaValues.method && (formulaValues.method.id === 'firstts' || formulaValues.method.id === 'lastts' || formulaValues.method.id === 'tsdelta' || formulaValues.method.id === 'sumdelta')) {
                if (!formulaValues.dataSymbol || !formulaValues.deviceData) disabled = true;
            } else if (!formulaValues.formulaTimeSystem || (formulaValues.formulaTimeSystem.id === 'timerange' && !formulaValues.timeRange)) {
                disabled = true;
            } else if (formulaValues.formulaTimeSystem.id === 'startend') {
                if (!formulaValues.start || !formulaValues.end) disabled = true;
                else if ((formulaValues.start.id !== 'now' && formulaValues.start.id !== 'poattr' && !formulaValues.startTime) || (formulaValues.end.id !== 'now' && formulaValues.end.id !== 'poattr' && !formulaValues.endTime)) disabled = true;
                else if (formulaValues.start.id === 'poattr' && !this.state.customStart) disabled = true;
                else if (formulaValues.end.id === 'poattr' && !this.state.customEnd) disabled = true;
            }
        } else if (this.state.selectingMenu === 2) {
            const { poValues } = this.state;
            if (!poValues.machine || !poValues.structure || !poValues.column) disabled = true;
            else if (!poValues.start || !poValues.end) disabled = true;
            else if ((poValues.start.id !== 'now' && poValues.start.id !== 'poattr' && !poValues.startTime) || (poValues.end.id !== 'now' && poValues.end.id !== 'poattr' && !poValues.endTime)) disabled = true;
            else if (poValues.start.id === 'poattr' && !this.state.customStart) disabled = true;
            else if (poValues.end.id === 'poattr' && !this.state.customEnd) disabled = true;
        } else if (this.state.selectingMenu === 3) {
            return this.state.importValue ? false : true;
        } else if (this.state.selectingMenu === 4) {
            disabled = value.value === '' ? true : false;
        } else if (this.state.selectingMenu === 5) {
            disabled = this.state.manualFormula ? false : true;
        }
        return disabled || !this.state.formIsValid;
    }

    onSubmitFormulaHandler = (event) => {
        event.preventDefault();
        const isNew = this.props.editingVariable.name === 'new' && this.props.editingVariable.value === -1;
        let value = this.state.variableForm.value.value;
        let properties = '';
        if (this.state.selectingMenu === 1) {
            const { formulaValues } = this.state;
            if (formulaValues.method.id === 'rt') {
                value = '{rt:' + formulaValues.device.id + '}';
            } else {
                let period = '';
                if (formulaValues.formulaTimeSystem.id === 'startend') {
                    if (formulaValues.start.id === 'poattr') {
                        period = this.state.customStart + '-';
                    } else {
                        period = formulaValues.start.id + '(' + (formulaValues.start.id === 'now' ? '' : formulaValues.startTime.replace(':', ',')) + ')-';
                    }
                    if (formulaValues.end.id === 'poattr') {
                        period += this.state.customEnd;
                    } else {
                        period += formulaValues.end.id + '(' + (formulaValues.end.id === 'now' ? '' : formulaValues.endTime.replace(':', ',')) + ')';
                    }
                } else {
                    period = formulaValues.timeRange;
                }
                let method = '';
                let deviceDataCond = ''
                if ((formulaValues.method.id !== 'firstts' && formulaValues.method.id !== 'lastts' && formulaValues.method.id !== 'tsdelta' && formulaValues.method.id !== 'sumdelta')) {
                    method = 'ra:' + formulaValues.method.id;
                } else {
                    method = formulaValues.method.id;
                    deviceDataCond = formulaValues.dataSymbol.value + '' + formulaValues.deviceData;
                }
                value = '{' + method + '(' + formulaValues.device.id + '' + deviceDataCond + '@' + period + ')}';
            }
            properties = { formula: true };
        } else if (this.state.selectingMenu === 2) {
            const { poValues } = this.state;
            let period = '';
            if (poValues.start.id === 'poattr') {
                period = this.state.customStart + '-';
            } else {
                period += poValues.start.id + '(' + (poValues.start.id === 'now' ? '' : poValues.startTime.replace(':', ',')) + ')-';
            }
            if (poValues.end.id === 'poattr') {
                period += this.state.customEnd;
            } else {
                period += poValues.end.id + '(' + (poValues.end.id === 'now' ? '' : poValues.endTime.replace(':', ',')) + ')';
            }
            value = '{poattr:' + poValues.machine.id + ':' + poValues.structure.id + ':' + poValues.column.id + '@' + period + '}';
            properties = { poattr: true };
        } else if (this.state.selectingMenu === 3) {
            properties = { import: true };
            const foundVar = this.props.variables.find(v => v.id === this.state.importValue.id);
            this.props.saveVariable({
                id: foundVar.id,
                name: foundVar.name,
                value: foundVar.value,
                org_id: this.props.currentOrg,
                properties
            });
        } else if (this.state.selectingMenu === 4) {
            properties = { number: true };
        } else if (this.state.selectingMenu === 5) {
            properties = { manual: true };
            value = this.state.manualFormula;
        }
        if (this.state.selectingMenu !== 3) {
            if (isNew) {
                this.props.addVariable(
                    this.props.currentOrg,
                    this.state.variableForm.name.value,
                    value,
                    properties
                );
            } else {
                this.props.editVariable(
                    this.props.editingVariable.id,
                    this.state.variableForm.name.value,
                    value,
                    properties
                );
            }
        }
    }

    render() {
        const { name, value } = this.state.variableForm;

        return (
            <Modal show={this.props.open} modalClosed={this.props.modalClosed} overideStyles={{ minHeight: 765, width: 550 }}>
                <div className={styles.FormulaModal}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <div className={styles.MenuWrapper}>
                            {
                                MENU.map((m, i) => (
                                    <div key={`${m.id}_${m.name}`} htmlFor={`menu${m.id}`} className={styles.List}>
                                        <div className={styles.RadioWrapper}>
                                            <img src={this.state.selectingMenu === m.id ? RadioSelectIcon : RadioUnselectIcon} alt="Radio Icon" className={styles.RadioIcon} />
                                            <input id={`menu${m.id}`} type="radio" value={m.id} checked={this.state.selectingMenu === m.id} onChange={() => this.onChangeMenuHandler(m.id)} />
                                            <label htmlFor={`menu${m.id}`} className={styles.RadioLabel}>{m.name}</label>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <form style={{ height: '100%', display: 'flex', flexDirection: 'column' }} onSubmit={this.onSubmitFormulaHandler}>
                            <div className={styles.BodyContent}>
                                {
                                    this.state.selectingMenu !== 3 && !this.state.isSettingCustomStartEnd
                                        ? <Input
                                            whitemode="true"
                                            label="Name"
                                            name="name"
                                            type="text"
                                            value={name.value}
                                            placeholder="Please insert your variable name"
                                            autoComplete="off"
                                            onChange={this.handleFormChange}
                                            error={`${name.touched && !name.valid ? `Name is required` : ''}`}
                                            required
                                        />
                                        : null
                                }
                                {
                                    this.state.selectingMenu === 5 && !this.state.isSettingCustomStartEnd
                                        ? <><Input
                                            whitemode="true"
                                            label="manual formula"
                                            name="manualFormula"
                                            type="textarea"
                                            rows="3"
                                            value={this.state.manualFormula}
                                            placeholder="Please insert your full formula"
                                            autoComplete="off"
                                            onChange={this.handleManualFormulaChange}
                                            required
                                        />
                                            {this.props.error && <div className='ErrorText'>Something went wrong. Please check your formula.</div>}
                                        </>
                                        : null
                                }
                                {
                                    this.state.isSettingCustomStartEnd
                                        ? <CustomStartEnd
                                            editingCustomStartEnd={this.state.isSettingCustomMode === 'start' ? this.state.customStart : this.state.customEnd}
                                            cancel={() => this.setState({ isSettingCustomStartEnd: false })}
                                            machinesOption={this.state.machinesOption}
                                            structuresOption={this.state.structuresOption}
                                            timeOption={TIME_OPTION}
                                            machines={this.props.machines}
                                            colourStyles={colourStyles}
                                            planStructures={this.props.planStructures}
                                            saveCustomStartEnd={this.onSaveCustomStartEndHandler}
                                        />
                                        : null
                                }
                                {
                                    this.state.selectingMenu === 1 && !this.state.isSettingCustomStartEnd
                                        ? <>
                                            <div className='ErrorText'>{this.props.error}</div>
                                            <div className={styles.SelectTitle}>Method * </div>
                                            <Select isSearchable options={METHOD_OPTION} styles={colourStyles}
                                                placeholder="Please select method"
                                                value={this.state.formulaValues.method ? this.state.formulaValues.method : ''}
                                                onChange={(value) => this.onChangeFormulaValuesHandler('method', value)}
                                                getOptionValue={opt => opt.id}
                                            />
                                            <div style={{ marginBottom: '1rem' }}></div>
                                            <div className={styles.SelectTitle}>Device * </div>
                                            <Select isSearchable options={this.state.deviceOption} styles={colourStyles}
                                                placeholder="Please select device"
                                                value={this.state.formulaValues.device ? this.state.formulaValues.device : ''}
                                                onChange={(value) => this.onChangeFormulaValuesHandler('device', value)}
                                                getOptionValue={opt => opt.id}
                                            />
                                            <div style={{ marginBottom: '1rem' }}></div>
                                            {this.state.formulaValues.method && (this.state.formulaValues.method.id === 'firstts' || this.state.formulaValues.method.id === 'lastts' || this.state.formulaValues.method.id === 'tsdelta' || this.state.formulaValues.method.id === 'sumdelta')
                                                ? <>
                                                    <div className={styles.SelectTitle}>Device Data * </div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ flex: 1, marginRight: 5 }}>
                                                            <Select isSearchable options={DATA_SYMBOL} styles={colourStyles}
                                                                placeholder="Please select symbol"
                                                                value={this.state.formulaValues.dataSymbol ? this.state.formulaValues.dataSymbol : ''}
                                                                onChange={(value) => this.onChangeFormulaValuesHandler('dataSymbol', value)}
                                                                getOptionValue={opt => opt.value}
                                                            />
                                                        </div>
                                                        <input
                                                            className={styles.SelectTimeBox}
                                                            // whitemode="true"
                                                            name="deviceData"
                                                            type="number"
                                                            value={this.state.formulaValues.deviceData ? this.state.formulaValues.deviceData : ''}
                                                            placeholder="device data"
                                                            autoComplete="off"
                                                            onChange={(e) => this.onChangeFormulaValuesHandler('deviceData', e.target.value)}
                                                            required
                                                        // overidestyleformgroup={{ flex: 1, minHeight: 39, margin: 0 }}
                                                        />
                                                    </div>
                                                    <div style={{ marginBottom: '1rem' }}></div>
                                                </>
                                                : null
                                            }
                                            {
                                                this.state.formulaValues.method && this.state.formulaValues.method.id !== 'rt'
                                                    ? <>
                                                        <div className={styles.SelectTitle}>Time range or Start-End * </div>
                                                        <Select isSearchable options={FORMULA_TS} styles={colourStyles}
                                                            placeholder="Please select time option"
                                                            value={this.state.formulaValues.formulaTimeSystem ? this.state.formulaValues.formulaTimeSystem : ''}
                                                            onChange={(value) => this.onChangeFormulaValuesHandler('formulaTimeSystem', value)}
                                                            getOptionValue={opt => opt.id}
                                                        />
                                                        <div style={{ marginBottom: '1rem' }}></div>
                                                    </>
                                                    : null
                                            }
                                            {
                                                this.state.formulaValues?.formulaTimeSystem && this.state.formulaValues.formulaTimeSystem.id === 'timerange'
                                                    ? <Input
                                                        whitemode="true"
                                                        label="Time range (ms)"
                                                        name="timeRange"
                                                        type="number"
                                                        value={this.state.formulaValues.timeRange ? this.state.formulaValues.timeRange : ''}
                                                        placeholder="Timestamp"
                                                        autoComplete="off"
                                                        onChange={(e) => this.onChangeFormulaValuesHandler('timeRange', e.target.value)}
                                                        required
                                                        overidestyleformgroup={{ flex: 1, minHeight: 39, margin: 0 }}
                                                    />
                                                    : this.state.formulaValues?.formulaTimeSystem && this.state.formulaValues.formulaTimeSystem.id === 'startend'
                                                        ? <>
                                                            <div className={styles.SelectTitle}>Start * </div>
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ flex: 2, marginRight: 5 }}>
                                                                    <Select isSearchable options={TIME_OPTION} styles={colourStyles}
                                                                        placeholder="Please select start"
                                                                        value={this.state.formulaValues.start ? this.state.formulaValues.start : ''}
                                                                        onChange={(value) => this.onChangeFormulaValuesHandler('start', value)}
                                                                        getOptionValue={opt => opt.id}
                                                                    />
                                                                </div>
                                                                {
                                                                    this.state.formulaValues.start?.id === 'poattr'
                                                                        ? <div className={styles.CustomStartEndButton} onClick={() => this.onSetCustomStartEnd('start')}>Set start</div>
                                                                        : <input
                                                                            className={styles.SelectTimeBox}
                                                                            type="time"
                                                                            onChange={(e) => this.onChangeFormulaValuesHandler('startTime', e.target.value)}
                                                                            name="startTime"
                                                                            value={this.state.formulaValues.startTime ? this.state.formulaValues.startTime : ''}
                                                                            disabled={this.state.formulaValues.disabledStart ? this.state.formulaValues.disabledStart : false}
                                                                        />
                                                                }
                                                            </div>
                                                            {
                                                                this.state.formulaValues.start?.id === 'poattr'
                                                                    ? <div className={styles.customStartEndValue}>{this.state.customStart}</div>
                                                                    : null
                                                            }
                                                            <div style={{ marginBottom: '1rem' }}></div>
                                                            <div className={styles.SelectTitle}>End * </div>
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ flex: 2, marginRight: 5 }}>
                                                                    <Select isSearchable options={TIME_OPTION} styles={colourStyles}
                                                                        placeholder="Please select end"
                                                                        value={this.state.formulaValues.end ? this.state.formulaValues?.end : ''}
                                                                        onChange={(value) => this.onChangeFormulaValuesHandler('end', value)}
                                                                        getOptionValue={opt => opt.id}
                                                                    />
                                                                </div>
                                                                {
                                                                    this.state.formulaValues.end?.id === 'poattr'
                                                                        ? <div className={styles.CustomStartEndButton} onClick={() => this.onSetCustomStartEnd('end')}>Set end</div>
                                                                        : <input
                                                                            className={styles.SelectTimeBox}
                                                                            type="time"
                                                                            onChange={(e) => this.onChangeFormulaValuesHandler('endTime', e.target.value)}
                                                                            name="endTime"
                                                                            value={this.state.formulaValues.endTime ? this.state.formulaValues.endTime : ''}
                                                                            disabled={this.state.formulaValues.disabledEnd ? this.state.formulaValues.disabledEnd : false}
                                                                        />
                                                                }
                                                            </div>
                                                            {
                                                                this.state.formulaValues.end?.id === 'poattr'
                                                                    ? <div className={styles.customStartEndValue}>{this.state.customEnd}</div>
                                                                    : null
                                                            }
                                                        </>
                                                        : null
                                            }
                                        </>
                                        : null
                                }
                                {
                                    this.state.selectingMenu === 2 && !this.state.isSettingCustomStartEnd
                                        ? <>
                                            <div className={styles.SelectTitle}>Machine * </div>
                                            <Select isSearchable options={this.state.machinesOption} styles={colourStyles}
                                                placeholder="Please select machine"
                                                value={this.state.poValues.machine ? this.state.poValues.machine : ''}
                                                onChange={(value) => this.onChangePoValuesHandler('machine', value)}
                                                getOptionValue={opt => opt.id}
                                            />
                                            <div style={{ marginBottom: '1rem' }}></div>
                                            <div className={styles.SelectTitle}>Structure * </div>
                                            <Select isSearchable options={this.state.structuresOption} styles={colourStyles}
                                                placeholder="Please select structure"
                                                value={this.state.poValues.structure ? this.state.poValues.structure : ''}
                                                onChange={(value) => this.onChangePoValuesHandler('structure', value)}
                                                getOptionValue={opt => opt.id}
                                            />
                                            <div style={{ marginBottom: '1rem' }}></div>
                                            <div className={styles.SelectTitle}>Column * </div>
                                            <Select isSearchable options={this.state.columnsOption} styles={colourStyles}
                                                placeholder="Please select column"
                                                value={this.state.poValues.column ? this.state.poValues.column : ''}
                                                onChange={(value) => this.onChangePoValuesHandler('column', value)}
                                                getOptionValue={opt => opt.id}
                                            />
                                            <div style={{ marginBottom: '1rem' }}></div>
                                            <div className={styles.SelectTitle}>Start * </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ flex: 2, marginRight: 5 }}>
                                                    <Select isSearchable options={TIME_OPTION} styles={colourStyles}
                                                        placeholder="Please select start"
                                                        value={this.state.poValues.start ? this.state.poValues.start : ''}
                                                        onChange={(value) => this.onChangePoValuesHandler('start', value)}
                                                        getOptionValue={opt => opt.id}
                                                    />
                                                </div>
                                                {
                                                    this.state.poValues.start?.id === 'poattr'
                                                        ? <div className={styles.CustomStartEndButton} onClick={() => this.onSetCustomStartEnd('start')}>Set start</div>
                                                        : <input
                                                            className={styles.SelectTimeBox}
                                                            type="time"
                                                            onChange={(e) => this.onChangePoValuesHandler('startTime', e.target.value)}
                                                            name="startTime"
                                                            value={this.state.poValues.startTime ? this.state.poValues.startTime : ''}
                                                            disabled={this.state.poValues.disabledStart ? this.state.poValues.disabledStart : false}
                                                        />
                                                }
                                            </div>
                                            {
                                                this.state.poValues.start?.id === 'poattr'
                                                    ? <div className={styles.customStartEndValue}>{this.state.customStart}</div>
                                                    : null
                                            }
                                            <div style={{ marginBottom: '1rem' }}></div>
                                            <div className={styles.SelectTitle}>End * </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ flex: 2, marginRight: 5 }}>
                                                    <Select isSearchable options={TIME_OPTION} styles={colourStyles}
                                                        placeholder="Please select end"
                                                        value={this.state.poValues.end ? this.state.poValues.end : ''}
                                                        onChange={(value) => this.onChangePoValuesHandler('end', value)}
                                                        getOptionValue={opt => opt.id}
                                                    />
                                                </div>
                                                {
                                                    this.state.poValues.end?.id === 'poattr'
                                                        ? <div className={styles.CustomStartEndButton} onClick={() => this.onSetCustomStartEnd('end')}>Set end</div>
                                                        : <input
                                                            className={styles.SelectTimeBox}
                                                            type="time"
                                                            onChange={(e) => this.onChangePoValuesHandler('endTime', e.target.value)}
                                                            name="endTime"
                                                            value={this.state.poValues.endTime ? this.state.poValues.endTime : ''}
                                                            disabled={this.state.poValues.disabledEnd ? this.state.poValues.disabledEnd : false}
                                                        />
                                                }
                                            </div>
                                            {
                                                this.state.poValues.end?.id === 'poattr'
                                                    ? <div className={styles.customStartEndValue}>{this.state.customEnd}</div>
                                                    : null
                                            }
                                        </>
                                        : null
                                }
                                {
                                    this.state.selectingMenu === 3 && !this.state.isSettingCustomStartEnd
                                        ? <>
                                            <div className={styles.SelectTitle}>Select Import * </div>
                                            <Select isSearchable options={this.state.importsOption} styles={colourStyles}
                                                placeholder="Please select import"
                                                value={this.state.importValue}
                                                onChange={(value) => this.handleImportChange(value)}
                                                getOptionValue={opt => opt.id}
                                            />
                                        </>
                                        : null
                                }
                                {
                                    this.state.selectingMenu === 4 && !this.state.isSettingCustomStartEnd
                                        ? <>
                                            <Input
                                                whitemode="true"
                                                label="Number"
                                                name="value"
                                                type="number"
                                                value={value.value}
                                                placeholder="Please insert your number value"
                                                autoComplete="off"
                                                onChange={this.handleFormChange}
                                                error={`${value.touched && !value.valid ? `Number is required` : ''}`}
                                                required
                                            />
                                        </>
                                        : null
                                }
                            </div>
                            <div className={styles.FooterContent}>
                                {
                                    this.state.isSettingCustomStartEnd
                                        ? null
                                        : this.props.editingVariable && this.props.editingVariable.name === 'new' && this.props.editingVariable.value === -1
                                            ? <>
                                                <div style={{ width: 100 }}>
                                                    <Button type="button" name="Cancel" color="borderred" noMargin click={this.props.modalClosed} />
                                                </div>
                                                {
                                                    this.props.formulaSet[this.props.editingVarIndex[0]]?.length === 1 && this.props.editingVarIndex[0] === 0
                                                        ? null
                                                        : <div style={{ width: 150 }}>
                                                            <Button type="button" name="remove" color="red" noMargin click={this.props.removeItem} />
                                                        </div>
                                                }
                                                <div style={{ width: 150 }}>
                                                    <Button type="submit" name={"Done"} color="primary" noMargin disabled={this.checkSubmitButton()} />
                                                </div>
                                            </>
                                            : <>
                                                {
                                                    this.props.formulaSet[this.props.editingVarIndex[0]]?.length === 1 && this.props.editingVarIndex[0] === 0
                                                        ? null
                                                        : <div style={{ width: 150 }}>
                                                            <Button type="button" name="remove" color="red" noMargin click={this.props.removeItem} />
                                                        </div>
                                                }
                                                <div style={{ width: 150 }}>
                                                    <Button type="submit" name={"Save"} color="green" noMargin disabled={this.checkSubmitButton()} />
                                                </div>
                                            </>

                                }

                            </div>
                        </form>
                    </div>

                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { variables, addedVariable, result, updatedVariable, error } = state.variable;
    const { machines } = state.machine;
    const { devices } = state.devices;
    const { planStructures } = state.advanceProductionPlanStructure;
    return { currentOrg, variables, machines, planStructures, addedVariable, result, updatedVariable, error, devices };
};

export default connect(mapStateToProps, { getVariables, getMachines, getProductionPlanStructures, addVariable, editVariable, getDevices })(FormulaModal);
