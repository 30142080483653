import React, { Component } from 'react';

import CommandForm from '../CommandForm/CommandForm';
import styles from './RuleCommand.module.scss';

class RuleCondition extends Component {
  render() {
    return (
      <React.Fragment>
        <div className={styles.LabelGroup}>
          <div className={styles.Label}>then * </div>
        </div>
        {
          this.props.allCommandsForm ? this.props.allCommandsForm.map((com, index) => {
            if (this.props.allCommandsForm.length - 1 === index && index === 0) {
              return (
                <CommandForm
                  key={index}
                  addIndex={index}
                  command={this.props.commands[index]}
                  deviceOptions={this.props.deviceOptions}
                  commandChange={this.props.commandChange}
                  addNewCommand={this.props.addNewCommand}
                  firstCommand />
              )
            }
            else if (this.props.allCommandsForm.length - 1 === index) {
              return (
                <CommandForm
                  key={index}
                  addIndex={index}
                  command={this.props.commands[index]}
                  deviceOptions={this.props.deviceOptions}
                  commandChange={this.props.commandChange}
                  addNewCommand={this.props.addNewCommand}
                  removeCommand={() => this.props.removeCommand(index)} />
              )
            } else {
              return (
                <CommandForm
                  key={index}
                  addIndex={index}
                  command={this.props.commands[index]}
                  deviceOptions={this.props.deviceOptions}
                  commandChange={this.props.commandChange}
                  removeCommand={() => this.props.removeCommand(index)} />
              )
            }
          }) : null
        }
      </React.Fragment>
    );
  }
}

export default RuleCondition;
