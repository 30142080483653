import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './ChatList.module.scss';

class ChatList extends Component {
  render() {
    return (
      <div className={styles.ChatList}>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            <div className={styles.Menu}><span className={styles.MenuTitle}>All Conversations</span></div>
          </div>
        </div>
        <div className={styles.TeamSection}>
          <div className={styles.Header}>
            <div className={styles.HeaderTitle}>CHATROOMS</div>
          </div>
          {
            // this.props.chatrooms ? this.props.chatrooms.map(chat => {
              // return (
                // <div key={chat.id} className={styles.List}>
                //   <div className={styles.MemberPicture}>
                //     {
                //       this.props.allTeamsMembers[team.id] && this.props.allTeamsMembers[team.id].length === 1 ?
                //         <img src={this.props.allTeamsMembers[team.id][0].profile_picture} className={styles.ProfilePic} alt="Member Profile Picture" /> :
                //         <React.Fragment>
                //           <img src={this.props.allTeamsMembers[team.id][0].profile_picture} className={styles.ProfilePic} alt="Member Profile Picture" />
                //           <div style={{ position: 'relative' }}>
                //             <img src={this.props.allTeamsMembers[team.id][1].profile_picture} className={styles.ProfilePicTwo} alt="Member Profile Picture" />
                //           </div>
                //         </React.Fragment>
                //     }
                //   </div>
                //   <div className={styles.TeamDetail}>
                //     <div className={styles.TeamName}>{team.name}</div>
                //     <div className={styles.LastChat}>We have lake-front vacation rentals. No specific liability but…</div>
                //   </div>
                // </div>
              //);
            // }) : null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { chatrooms } = state.chatroom;
  return { chatrooms };
};

export default connect(mapStateToProps, {})(ChatList);