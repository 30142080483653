import React, { Component } from 'react';
import { connect } from 'react-redux'

import styles from '../Profile.module.scss';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

import { checkValidity, updateObject } from '../../../../state/utils';
import { editUser } from '../../../../state/ducks/Auth/actions';

const DEFAULT_PASSWORD_FORM = {
  password: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true,
    }
  },
  newpassword: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true,
    }
  }
};

class PasswordForm extends Component {
  state = {
    formIsValid: false,
    editType: 'password',
    passwordForm: DEFAULT_PASSWORD_FORM
  }

  handleChange = event => {
    let updatedElement = updateObject(this.state.passwordForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.passwordForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.passwordForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ passwordForm: updatedForm, formIsValid });
  };

  onSetTouchedFields = () => {
    let newState = { ...this.state.passwordForm };
    Object.keys(this.state.passwordForm).forEach(name => {
      let updatedElement = updateObject(newState[name], {
        touched: true
      });
      const updatedForm = updateObject(newState, {
        [name]: updatedElement
      });
      newState = updatedForm;
      this.setState({ passwordForm: updatedForm });
    });
  }

  onClearFields = () => {
    this.setState({ passwordForm: DEFAULT_PASSWORD_FORM, formIsValid: false });
  }

  onEditPasswordHandler = (event) => {
    event.preventDefault();
    const { password, newpassword } = this.state.passwordForm;
    const { loggedInUser } = this.props;
    if (this.state.formIsValid) {
      this.props.editUser(
        loggedInUser.id,
        this.state.editType,
        loggedInUser.first_name,
        loggedInUser.last_name,
        loggedInUser.tel,
        loggedInUser.job_title,
        loggedInUser.profile_pic,
        loggedInUser.username,
        password.value,
        newpassword.value
      );
      this.onClearFields();
    }
    else {
      this.onSetTouchedFields();
    }
  }

  render() {
    let { password, newpassword } = this.state.passwordForm;
    return (
      <div className={styles.ProfileContainer}>
        <div className={styles.Header}>
          <div className={styles.Title}>Password</div>
          <div className={styles.SubTitle}>You can change your Password here.</div>
        </div>
        <form onSubmit={this.onEditPasswordHandler}>
          <div className={styles.FormInput}>
            <div style={{ display: 'flex' }}>
              <div className={styles.FormGroup} style={{ marginRight: 15 }}>
                <Input
                  label="old password"
                  name="password"
                  type="password"
                  value={password.value}
                  onChange={this.handleChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                  error={`${password.touched && !password.valid ? 'Old password is required' : ''}`}
                />
              </div>
              <div className={styles.FormGroup}>
                <Input
                  label="new password"
                  name="newpassword"
                  type="password"
                  value={newpassword.value}
                  onChange={this.handleChange}
                  overidestyleformgroup={{ marginBottom: 0 }}
                  error={`${newpassword.touched && !newpassword.valid ? 'New password is required' : ''}`}
                />
              </div>
            </div>
          </div>
          <div className={styles.Footer}>
            <div className={styles.ButtonWrapper}>
              <Button
                type="submit"
                color="borderwhite"
                name={this.props.loading && this.props.editType === 'password' ? 'Loading...' : 'Save Password'}
                loading={this.props.loading && this.props.editType === 'password'}
              />
            </div>
          </div>
        </form>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, loggedInUser, updatedUser, editType } = state.auth;
  return { loading, error, loggedInUser, updatedUser, editType };
};

export default connect(mapStateToProps, { editUser })(PasswordForm);