import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Draggable from 'react-draggable';

import styles from './FloorPlanWidgetContainer.module.scss';
import Line from '../Widgets/FloorPlan/Line/Line';
import WidgetContainer from '../Widgets/WidgetContainer/WidgetContainer';
import WidgetContainerFloorPlan from '../Widgets/WidgetContainerFloorPlan/WidgetContainerFloorPlan';
import Gauge from '../Widgets/FloorPlan/Gauge/Gauge';
import Text from '../Widgets/FloorPlan/Text/Text';
import Power from '../Widgets/FloorPlan/Power/Power';
import Timeline from '../Widgets/FloorPlan/Timeline/Timeline';
import DateTime from '../Widgets/FloorPlan/DateTime/DateTime';
import SwitchOnOff from '../Widgets/FloorPlan/Switch/SwitchOnOff/SwitchOnOff';
import SwitchSequence from '../Widgets/FloorPlan/Switch/SwitchSequence/SwitchSequence';
import Lamp from '../Widgets/FloorPlan/Lamp/Lamp';
import DraggableContainer from '../Widgets/DraggableContainer/DraggableContainer';
// import TimeIcon from '../../../assets/icons/Widgets/time.svg';

import { getData, getDataLatest, getDataEval } from '../../../state/ducks/Data/actions';
import { DELETE_WIDGET_SUCCESS } from '../../../state/ducks/Widgets/types';
import { calculateNewValFromEquation } from '../../../state/utils';


class PlanWidgetContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataSet: [],
      isSetingFirstDataset: false,
      dataSetInterval: [],
      floorPlanImageUrl: null
    }
    this.ImagePaneRef = React.createRef()
  }

  componentDidMount() {
    if (this.props.page.properties) {
      let newFloorPlanImageUrl
      newFloorPlanImageUrl = `${this.props.page.properties.bg_img_url}?${this.props.page.properties.bg_img_alt}&${this.props.page.properties.bg_img_token}`
      this.setState({
        floorPlanImageUrl: newFloorPlanImageUrl
      })
    }
    console.log(this.ImagePaneRef.current.offsetWidth)
  }

  componentDidUpdate(prevProps, nextState) {
    if (prevProps.page.properties !== this.props.page.properties) {
      let newFloorPlanImageUrl
      newFloorPlanImageUrl = `${this.props.page.properties.bg_img_url}?${this.props.page.properties.bg_img_alt}&${this.props.page.properties.bg_img_token}`
      this.setState({
        floorPlanImageUrl: newFloorPlanImageUrl
      })
    }

    if (this.props.dashboard !== prevProps.dashboard && this.props.dashboard && this.props.dashboard.length) {
      if (!this.state.isSetingFirstDataset) {
        const dataSet = [];
        this.props.dashboard.forEach(dash => {
          dataSet.push({ id: dash.id, value: null });
        });
        this.setUpGetDataInterval(this.props.dashboard);
        this.setState({ dataSet, isSetingFirstDataset: true });
      }
    }

    if (this.props.result !== prevProps.result && this.props.result === 'success') {
      if (this.props.addedWidget !== prevProps.addedWidget && this.props.addedWidget) {
        const wid = this.props.addedWidget;
        const dataSet = [...this.state.dataSet];
        dataSet.push({ id: wid.id, value: null });
        if ((wid.type === 'gauge' && ((!wid.properties && !wid.properties.gaugeProps) || !wid.properties.gaugeProps.formula)) || wid.type === 'lamp' || (wid.type === 'text' && !wid.formula)) {
          const dataSetInterval = [...this.state.dataSetInterval];
          dataSetInterval.push(setInterval(() => {
            this.props.getDataLatest(wid.properties.socket, wid.properties.secret, null, wid.id);
          }, 1000));
          this.setState({ dataSet, dataSetInterval });
        } else if ((wid.type === 'text' && wid.formula) || (wid.type === 'gauge' && wid.properties.gaugeProps.formula)) {
          const dataSetInterval = [...this.state.dataSetInterval];
          const formula = wid.type === 'text' ? wid.formula : wid.properties.gaugeProps.formula;
          this.props.getDataEval(formula, { wid: wid.id });
          dataSetInterval.push(setInterval(() => {
            this.props.getDataEval(formula, { wid: wid.id });
          }, 1000 * 60));
          this.setState({ dataSet, dataSetInterval });
        } else {
          this.setState({ dataSet });
        }
      } else if (this.props.updatedWidget !== prevProps.updatedWidget && this.props.updatedWidget) {
        const dataSet = [...this.state.dataSet];
        let foundDataSetIndex = dataSet.findIndex(ds => ds.id === this.props.updatedWidget.id);
        dataSet[foundDataSetIndex].value = null;
        this.clearAllDataSetIntervals();
        this.setUpGetDataInterval(this.props.dashboard);

        this.setState({ dataSet });
      } else if (this.props.type === DELETE_WIDGET_SUCCESS) {
        const dataSet = [...this.state.dataSet];
        const removingIndex = dataSet.findIndex(ds => ds.id === this.props.deletedWidgetId);
        dataSet.splice(removingIndex, 1);

        const dataSetInterval = [...this.state.dataSetInterval];
        clearInterval(dataSetInterval[removingIndex]);
        dataSetInterval[removingIndex] = null;
        this.setState({ dataSet, dataSetInterval });
      }
    }

    if (prevProps.dataLatest !== this.props.dataLatest && this.props.dataLatest.data && this.props.dataLatest.data.length > 0) {
      const { data, properties: { wid } } = this.props.dataLatest;
      if (Date.now() - data[0].timestamp > 5 * 60 * 1000) {
        const foundIndex = this.props.dashboard.findIndex(dash => dash.id === wid);
        const dataSet = [...this.state.dataSet];
        if (dataSet[foundIndex]) {
          dataSet[foundIndex].value = null;
          dataSet[foundIndex].lastUpdated = data[0].timestamp;
          dataSet[foundIndex].lastValue = calculateNewValFromEquation(data[0].value, this.props.dashboard[foundIndex].equation);
        }
        this.setState({ dataSet });
      } else {
        // console.log('update...')
        this.updateDataSet(this.props.dataLatest);
      }
    }

    if (prevProps.dataEval !== this.props.dataEval) {
      this.updateDataSet(this.props.dataEval, true);
    }

    if (prevProps.updatedId !== this.props.updatedId && this.props.updatedId) {
      this.props.onClearUpdatedId();
    }
  }

  setUpGetDataInterval = (dashboard) => {
    const dataSetInterval = [];
    dashboard.forEach((dash, i) => {
      if ((dash.type === 'gauge' && (!dash.gaugeProps || !dash.gaugeProps.formula)) || dash.type === 'lamp' || (dash.type === 'text' && !dash.formula)) {
        dataSetInterval[i] = setInterval(() => {
          this.props.getDataLatest(dash.socket, dash.secret, null, dash.id);
        }, 1000);
      } else if ((dash.type === 'text' && dash.formula) || (dash.type === 'gauge' && dash.gaugeProps.formula)) {
        const formula = dash.type === 'text' ? dash.formula : dash.gaugeProps.formula;
        this.props.getDataEval(formula, { wid: dash.id });
        dataSetInterval[i] = setInterval(() => {
          this.props.getDataEval(formula, { wid: dash.id });
        }, 1000 * 60);
      }
    });

    this.setState({ dataSetInterval });
  }

  updateDataSet = (dataLatest, isEval) => {
    const { data, properties: { wid } } = dataLatest;
    const dataSet = [...this.state.dataSet];
    const foundDataSetIndex = dataSet.findIndex(ds => ds.id === wid);
    if (dataSet[foundDataSetIndex]) {
      if (isEval) {
        dataSet[foundDataSetIndex].value = data || data === 0 ? data : null;
        dataSet[foundDataSetIndex].lastUpdated = null;
        dataSet[foundDataSetIndex].lastValue = null;
      } else {
        dataSet[foundDataSetIndex].value = data[0] && (data[0].value || data[0].value === 0) ? data[0].value : null;
        dataSet[foundDataSetIndex].lastUpdated = null;
        dataSet[foundDataSetIndex].lastValue = null;
      }
    }
    this.setState({ dataSet });
  }

  clearAllDataSetIntervals = () => {
    this.state.dataSetInterval.forEach(di => {
      clearInterval(di);
    });
  }

  componentWillUnmount() {
    this.clearAllDataSetIntervals();
  }

  findComponent(dashboard, dataSet) {
    if (dashboard && dashboard.length > 0) {
      return dashboard.map((dash, i) => {
        let foundDataSetIndex = dataSet.findIndex(data => data.id === dash.id);
        let Key = dash.id;
        if (this.props.updatedId === dash.id) {
          Key = dash.id + 'Updated';
        }
        if (dash.type === 'line') {
          let foundCombiningWidget = null;
          if (dash.lineProps && dash.lineProps.combiningWidget) foundCombiningWidget = dashboard.find(d => d.id === dash.lineProps.combiningWidget.id);
          return (
            <DraggableContainer key={Key}>
              <Line id={Key} widget={dash} combiningWidget={foundCombiningWidget} deviceOptions={this.props.deviceOptions} />
            </DraggableContainer>
          );
        }
        if (dash.type === 'gauge') {
          return (
            <DraggableContainer key={Key}>
              <WidgetContainer data={dash} isEdit={this.props.isEdit}
                lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                lastValue={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastValue : null}
                onEdit={(event) => this.props.onEditWidget(event, dash)}>
                <Gauge widget={dash} id={`gauge${i}`}
                  data={foundDataSetIndex !== -1 && dataSet[foundDataSetIndex].value}
                  lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                  lastValue={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastValue : null}
                />
              </WidgetContainer>
            </DraggableContainer>
          );
        }
        if (dash.type === 'text') {
          return (
            <DraggableContainer key={Key}>
              <WidgetContainer data={dash} isEdit={this.props.isEdit}
                lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                lastValue={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastValue : null}
                onEdit={(event) => this.props.onEditWidget(event, dash)}>
                <Text widget={dash} id={`text${i}`}
                  data={foundDataSetIndex !== -1 && dataSet[foundDataSetIndex].value}
                  lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                />
              </WidgetContainer>
            </DraggableContainer>
          );
        }
        if (dash.type === 'switch') {
          return (
            <DraggableContainer key={Key}>
              <WidgetContainerFloorPlan data={dash} isEdit={this.props.isEdit} onEdit={(event) => this.props.onEditWidget(event, dash)}>
                {dash.switchProps.switchType === 'onoff' && <SwitchOnOff widget={dash} id={`switch${i}`} deviceOptions={this.props.deviceOptions} />}
                {dash.switchProps.switchType === 'sequence' && <SwitchSequence widget={dash} id={`switch${i}`} />}
              </WidgetContainerFloorPlan>
            </DraggableContainer>
          );
        }
        if (dash.type === 'lamp') {
          return (
            // <div key={Key} data-grid={dash.layout}>
            <DraggableContainer key={Key} dash={dash} pageId={this.props.page.id}>
              <WidgetContainerFloorPlan data={dash} isEdit={this.props.isEdit} onEdit={(event) => this.props.onEditWidget(event, dash)}
                lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                lastValue={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastValue : null}>
                <Lamp widget={dash} id={`lamp${i}`}
                  data={foundDataSetIndex !== -1 && dataSet[foundDataSetIndex].value}
                  lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                />
              </WidgetContainerFloorPlan>
            </DraggableContainer>
            // </div>
          );
        }
        if (dash.type === 'time') {
          return (
            <DraggableContainer key={Key}>
              <DateTime id={`time${i}`} type={dash.type} data={dash} isEdit={this.props.isEdit} onEdit={(event) => this.props.onEditWidget(event, dash)} />
            </DraggableContainer>
          );
        }
        if (dash.type === 'date') {
          return (
            <DraggableContainer key={Key}>
              <DateTime id={`date${i}`} type={dash.type} data={dash} isEdit={this.props.isEdit} onEdit={(event) => this.props.onEditWidget(event, dash)} />
            </DraggableContainer>
          );
        }
        if (dash.type === 'timeline') {
          return (
            <DraggableContainer key={Key}>
              <WidgetContainer data={dash} isEdit={this.props.isEdit} onEdit={(event) => this.props.onEditWidget(event, dash)}>
                <Timeline widget={dash} id={`timeline${i}`} mqttData={foundDataSetIndex !== -1 && dataSet[foundDataSetIndex].value} deviceOptions={this.props.deviceOptions} />
              </WidgetContainer>
            </DraggableContainer>
          );
        }
        if (dash.type === 'power') {
          return (
            <DraggableContainer key={Key}>
              <WidgetContainer data={dash} isEdit={this.props.isEdit}
                lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                lastValue={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastValue : null}
                onEdit={(event) => this.props.onEditWidget(event, dash)}>
                <Power widget={dash} id={`power${i}`}
                  // data={foundDataSetIndex !== -1 && dataSet[foundDataSetIndex].value}
                  lastUpdated={dataSet[foundDataSetIndex] ? dataSet[foundDataSetIndex].lastUpdated : null}
                />
              </WidgetContainer>
            </DraggableContainer>
          );
        }
        return null;
      });
    }
    return [];
  }

  render() {
    const { dashboard, isFullScreen } = this.props;
    // console.log("🚀 ~ file: FloorPlanWidgetContainer.js ~ line 301 ~ PlanWidgetContainer ~ render ~ dashboard", dashboard)
    const { dataSet, floorPlanImageUrl } = this.state;

    let widgets = this.findComponent(dashboard, dataSet);

    return (
      <div className={styles.Container}>
        {/* Plan image pane */}
        <div
          ref={this.ImagePaneRef}
          className={isFullScreen ? styles.ImagePaneFullScreen : styles.ImagePane}
          style={{ 
            backgroundImage: `url(${floorPlanImageUrl})`,
            // paddingTop: percentage(image-height($url) / image-width($url))
          }}
        >
          {/* Draggable widgets */}
          {widgets}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { dataLatest, dataEval } = state.data;
  const { type, result, addedWidget, updatedWidget, deletedWidgetId } = state.widgets;
  return { type, result, dataLatest, dataEval, addedWidget, updatedWidget, deletedWidgetId };
};

export default connect(mapStateToProps, { getData, getDataLatest, getDataEval })(PlanWidgetContainer)