import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import BackIcon from '../../../../assets/icons/goback.svg';
import Button from '../../UI/Button/Button';

import styles from './FormulaModal.module.scss';
const TIME_OPTION = [{ id: 'yesterday', label: 'Yesterday' }, { id: 'now', label: 'Now' }, { id: 'today', label: 'Today' }, { id: 'tomorrow', label: 'Tomorrow' }];

class CustomStartEnd extends Component {
    state = {
        poValues: {},
        columnsOption: [],
    }

    componentDidMount() {
        if (this.props.editingCustomStartEnd) {
            this.setPoValues();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.editingCustomStartEnd !== this.props.editingCustomStartEnd && this.props.editingCustomStartEnd) {
            this.setPoValues();
        }
    }

    setPoValues = () => {
        const poattr = this.props.editingCustomStartEnd.split(':');
        let poValues = {};
        if (this.props.machines) {
            const foundMachine = this.props.machines.find(m => m.id === +poattr[1]);
            if (foundMachine) poValues.machine = { id: foundMachine.id, label: foundMachine.name };
            else poValues.machine = { id: -1, label: '(not found)' };
        }
        if (this.props.planStructures) {
            const foundStructure = this.props.planStructures.find(ps => ps.uuid === poattr[2]);
            poValues.structure = { id: foundStructure.uuid, label: foundStructure.name };
            this.onChangePoValuesHandler('structure', poValues.structure);
            const last = poattr[3].split('@');
            const col = last[0];
            poValues.column = { id: col, label: col };
            // last[1] = now()-today(20,00)}
            let period = last[1].slice(0, -1);
            period = period.replaceAll(',', ':');
            const start = period.split('-')[0]; // == new(), today(xx:yy)
            poValues.start = TIME_OPTION.find(to => to.id === start.split('(')[0]);
            if (poValues.start.id === 'now') poValues.disabledStart = true;
            else {
                let startTime = start.replace(poValues.start.id, '');
                startTime = startTime.replace('(', '');
                startTime = startTime.replace(')', '');
                poValues.startTime = startTime;
            }
            const end = period.split('-')[1];
            poValues.end = TIME_OPTION.find(to => to.id === end.split('(')[0]);
            if (poValues.end.id === 'now') poValues.disabledEnd = true;
            else {
                let endTime = end.replace(poValues.end.id, '');
                endTime = endTime.replace('(', '');
                endTime = endTime.replace(')', '');
                poValues.endTime = endTime;
            }
        }
        this.setState({ poValues });
    }

    onChangePoValuesHandler = (which, value) => {
        const poValues = { ...this.state.poValues };
        if (which === 'structure') {
            poValues.structure = value;
            poValues.column = '';
            const foundStructure = this.props.planStructures.find(ps => ps.uuid === value.id);
            const columnsOption = [{ id: 'target', label: 'Target' }, { id: 'actual_start', label: 'Actual Start' }, { id: 'actual_end', label: 'Actual End' }];
            if (foundStructure.columns && foundStructure.columns.length) {
                foundStructure.columns.forEach(c => {
                    columnsOption.push({
                        id: c.name,
                        label: c.name
                    });
                });
            }
            this.setState({ columnsOption });
        } else if (which === 'start') {
            poValues.start = value;
            if (value.id === 'now') {
                poValues.startTime = '';
                poValues.disabledStart = true;
            } else {
                poValues.disabledStart = false;
            }
        } else if (which === 'end') {
            poValues.end = value;
            if (value.id === 'now') {
                poValues.endTime = '';
                poValues.disabledEnd = true;
            } else {
                poValues.disabledEnd = false;
            }
        } else {
            poValues[which] = value;
        }
        this.setState({ poValues });
    }

    checkSubmitButton = () => {
        let disabled = false;

        const { poValues } = this.state;
        if (!poValues.machine || !poValues.structure || !poValues.column || !poValues.start || (poValues.start.id !== 'now' && !poValues.startTime) || !poValues.end || (poValues.end.id !== 'now' && !poValues.endTime))
            disabled = true;

        return disabled;
    }

    onSubmitCustomStartEndHandler = (event) => {
        event.preventDefault();

        const { poValues } = this.state;
        let period = '';
        period += poValues.start.id + '(' + (poValues.start.id === 'now' ? '' : poValues.startTime.replace(':', ',')) + ')-';
        period += poValues.end.id + '(' + (poValues.end.id === 'now' ? '' : poValues.endTime.replace(':', ',')) + ')';
        const value = '{poattr:' + poValues.machine.id + ':' + poValues.structure.id + ':' + poValues.column.id + '@' + period + '}';
        this.props.saveCustomStartEnd(value);
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div className={styles.BodyContent} style={{ padding: 0 }}>
                        <div className={styles.GoBackWrapper} onClick={this.props.cancel}>
                            <img src={BackIcon} style={{ width: 15, height: 15, marginRight: 10 }} /> Back
                        </div>
                        <div className={styles.SelectTitle}>Machine * </div>
                        <Select isSearchable options={this.props.machinesOption} styles={this.props.colourStyles}
                            placeholder="Please select machine"
                            value={this.state.poValues.machine ? this.state.poValues.machine : ''}
                            onChange={(value) => this.onChangePoValuesHandler('machine', value)}
                            getOptionValue={opt => opt.id}
                        />
                        <div style={{ marginBottom: '1rem' }}></div>
                        <div className={styles.SelectTitle}>Structure * </div>
                        <Select isSearchable options={this.props.structuresOption} styles={this.props.colourStyles}
                            placeholder="Please select structure"
                            value={this.state.poValues.structure ? this.state.poValues.structure : ''}
                            onChange={(value) => this.onChangePoValuesHandler('structure', value)}
                            getOptionValue={opt => opt.id}
                        />
                        <div style={{ marginBottom: '1rem' }}></div>
                        <div className={styles.SelectTitle}>Column * </div>
                        <Select isSearchable options={this.state.columnsOption} styles={this.props.colourStyles}
                            placeholder="Please select column"
                            value={this.state.poValues.column ? this.state.poValues.column : ''}
                            onChange={(value) => this.onChangePoValuesHandler('column', value)}
                            getOptionValue={opt => opt.id}
                        />
                        <div style={{ marginBottom: '1rem' }}></div>
                        <div className={styles.SelectTitle}>Start * </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 2, marginRight: 5 }}>
                                <Select isSearchable options={TIME_OPTION} styles={this.props.colourStyles}
                                    placeholder="Please select start"
                                    value={this.state.poValues.start ? this.state.poValues.start : ''}
                                    onChange={(value) => this.onChangePoValuesHandler('start', value)}
                                    getOptionValue={opt => opt.id}
                                />
                            </div>
                            <input
                                className={styles.SelectTimeBox}
                                type="time"
                                onChange={(e) => this.onChangePoValuesHandler('startTime', e.target.value)}
                                name="startTime"
                                value={this.state.poValues.startTime ? this.state.poValues.startTime : ''}
                                disabled={this.state.poValues.disabledStart ? this.state.poValues.disabledStart : false}
                            />
                        </div>
                        <div style={{ marginBottom: '1rem' }}></div>
                        <div className={styles.SelectTitle}>End * </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 2, marginRight: 5 }}>
                                <Select isSearchable options={TIME_OPTION} styles={this.props.colourStyles}
                                    placeholder="Please select end"
                                    value={this.state.poValues.end ? this.state.poValues.end : ''}
                                    onChange={(value) => this.onChangePoValuesHandler('end', value)}
                                    getOptionValue={opt => opt.id}
                                />
                            </div>
                            <input
                                className={styles.SelectTimeBox}
                                type="time"
                                onChange={(e) => this.onChangePoValuesHandler('endTime', e.target.value)}
                                name="endTime"
                                value={this.state.poValues.endTime ? this.state.poValues.endTime : ''}
                                disabled={this.state.poValues.disabledEnd ? this.state.poValues.disabledEnd : false}
                            />
                        </div>
                    </div>
                    <div className={styles.FooterContent}>
                        <div style={{ width: 100 }}>
                            <Button type="button" name="Cancel" color="borderred" noMargin click={this.props.cancel} />
                        </div>
                        <div style={{ width: 150 }}>
                            <Button type="button" name={"Save"} color="green" noMargin disabled={this.checkSubmitButton()} click={this.onSubmitCustomStartEndHandler} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
const mapStateToProps = (state) => {
    // const { } = state;
    return {};
};

export default connect(mapStateToProps, {})(CustomStartEnd);