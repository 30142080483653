import * as actionTypes from './types';

// ----------------------- GET RULE_CONDITION -----------------------
export const getRuleConditionsStart = () => {
  return {
    type: actionTypes.GET_RULE_CONDITIONS_START
  };
}

export const getRuleConditions = (rule_id) => {
  return {
    type: actionTypes.GET_RULE_CONDITIONS,
    rule_id
  };
}

export const getRuleConditionsSuccess = (response) => {
  return {
    type: actionTypes.GET_RULE_CONDITIONS_SUCCESS,
    ruleConditions: response.rule_conditions
  };
}

export const getRuleConditionsFail = (error) => {
  return {
    type: actionTypes.GET_RULE_CONDITIONS_FAIL,
    error: error
  };
}

// ----------------------- ADD RULE_CONDITION -----------------------
export const addRuleConditionStart = () => {
  return {
    type: actionTypes.ADD_RULE_CONDITION_START
  };
}

export const addRuleCondition = (rule_id, operation, condition, device_secret, device_socket, value) => {
  return {
    type: actionTypes.ADD_RULE_CONDITION,
    rule_id, operation, condition, device_secret, device_socket, value
  };
}

export const addRuleConditionSuccess = (response) => {
  return {
    type: actionTypes.ADD_RULE_CONDITION_SUCCESS,
    addedRuleCondition: response.rule_condition,
    result: response.result
  };
}

export const addRuleConditionFail = (error) => {
  return {
    type: actionTypes.ADD_RULE_CONDITION_FAIL,
    error: error
  };
}

// ----------------------- EDIT RULE_CONDITION -----------------------
export const editRuleConditionStart = () => {
  return {
    type: actionTypes.EDIT_RULE_CONDITION_START
  };
}

export const editRuleCondition = (id, operation, condition, device_secret, device_socket, value) => {
  return {
    type: actionTypes.EDIT_RULE_CONDITION,
    id, operation, condition, device_secret, device_socket, value
  };
}

export const editRuleConditionSuccess = (response) => {
  return {
    type: actionTypes.EDIT_RULE_CONDITION_SUCCESS,
    updatedRuleCondition: response.rule_condition,
    result: response.result
  };
}

export const editRuleConditionFail = (error) => {
  return {
    type: actionTypes.EDIT_RULE_CONDITION_FAIL,
    error: error
  };
}

// ----------------------- DELETE RULE_CONDITION -----------------------
export const deleteRuleConditionStart = () => {
  return {
    type: actionTypes.DELETE_RULE_CONDITION_START
  };
}

export const deleteRuleCondition = (id) => {
  return {
    type: actionTypes.DELETE_RULE_CONDITION,
    id: id
  };
}

export const deleteRuleConditionSuccess = (result) => {
  return {
    type: actionTypes.DELETE_RULE_CONDITION_SUCCESS,
    result: result
  };
}

export const deleteRuleConditionFail = (error) => {
  return {
    type: actionTypes.DELETE_RULE_CONDITION_FAIL,
    error: error
  };
}

// export default {
//   getRuleConditionsStart,
//   getRuleConditions,
//   getRuleConditionsSuccess,
//   getRuleConditionsFail,
//   addRuleConditionStart,
//   addRuleCondition,
//   addRuleConditionSuccess,
//   addRuleConditionFail,
//   editRuleConditionStart,
//   editRuleCondition,
//   editRuleConditionSuccess,
//   editRuleConditionFail,
//   deleteRuleConditionStart,
//   deleteRuleCondition,
//   deleteRuleConditionSuccess,
//   deleteRuleConditionFail,
// }

