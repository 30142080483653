export const GET_ACTUAL_PRODUCTION_START = 'GET_ACTUAL_PRODUCTION_START';
export const GET_ACTUAL_PRODUCTION = 'GET_ACTUAL_PRODUCTION';
export const GET_ACTUAL_PRODUCTION_SUCCESS = 'GET_ACTUAL_PRODUCTION_SUCCESS';
export const GET_ACTUAL_PRODUCTION_FAIL = 'GET_ACTUAL_PRODUCTION_FAIL';

export const UPDATE_ACTUAL_PRODUCTION_START = 'UPDATE_ACTUAL_PRODUCTION_START';
export const UPDATE_ACTUAL_PRODUCTION = 'UPDATE_ACTUAL_PRODUCTION';
export const UPDATE_ACTUAL_PRODUCTION_SUCCESS = 'UPDATE_ACTUAL_PRODUCTION_SUCCESS';
export const UPDATE_ACTUAL_PRODUCTION_FAIL = 'UPDATE_ACTUAL_PRODUCTION_FAIL';

export const DELETE_ACTUAL_PRODUCTION_START = 'DELETE_ACTUAL_PRODUCTION_START';
export const DELETE_ACTUAL_PRODUCTION = 'DELETE_ACTUAL_PRODUCTION';
export const DELETE_ACTUAL_PRODUCTION_SUCCESS = 'DELETE_ACTUAL_PRODUCTION_SUCCESS';
export const DELETE_ACTUAL_PRODUCTION_FAIL = 'DELETE_ACTUAL_PRODUCTION_FAIL';

export const START_PLAN_START = 'START_PLAN_START';
export const START_PLAN = 'START_PLAN';
export const START_PLAN_SUCCESS = 'START_PLAN_SUCCESS';
export const START_PLAN_FAIL = 'START_PLAN_FAIL';

export const STOP_PLAN_START = 'STOP_PLAN_START';
export const STOP_PLAN = 'STOP_PLAN';
export const STOP_PLAN_SUCCESS = 'STOP_PLAN_SUCCESS';
export const STOP_PLAN_FAIL = 'STOP_PLAN_FAIL';

export const CALCULATE_OEE_START = 'CALCULATE_OEE_START';
export const CALCULATE_OEE = 'CALCULATE_OEE';
export const CALCULATE_OEE_SUCCESS = 'CALCULATE_OEE_SUCCESS';
export const CALCULATE_OEE_FAIL = 'CALCULATE_OEE_FAIL';