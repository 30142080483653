import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as XLSX from 'xlsx';
import _ from 'lodash';

import styles from './AdvanceActualProduction.module.scss';
import Button from '../../components/UI/Button/Button';
import DownloadIcon from '../../../assets/icons/Report/download.svg';
import FullScreenIcon from '../../../assets/icons/full-screen.svg';
import NormalScreenIcon from '../../../assets/icons/normal-screen.svg';

import { getProductionPlanStructures } from '../../../state/ducks/AdvanceProductionPlanStructure/actions';
import { getActualProduction } from '../../../state/ducks/AdvanceActualProduction/actions';
import { getMachinesAndActuals } from '../../../state/ducks/Machine/actions';
import { deleteDocumentNote, getNoteTypes, getDocumentNotes, clearNoteTypes } from '../../../state/ducks/Document/actions';

import ActualsTable from '../../components/Production/AdvanceActualProduction/ActualsTable';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import SearchDrawer from '../../components/Operator/SearchDrawer/SearchDrawer';
import ActualDrawer from '../../components/Production/AdvanceActualProduction/ActualDrawer';
import ProductionLogDrawer from '../../components/Operator/StopOperatorDrawer/ProductionLogDrawer';
import ScannerModal from '../../components/Operator/StartStopPoModal/ScannerModal';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';

class AdvanceActualProduction extends Component {
  state = {
    currentTabIndex: 0,
    query: {
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().setHours(23, 59, 59, 999),
    },
    isDrawerOpened: false,
    advancedSearch: false,
    isAdminOfOrg: false,
    isEdit: false,
    editingActual: null,
    isFullScreen: false,
    actualProductions: [],
    isProdDocAdding: false,
    editingProdDoc: null,
    scannerText: '',
    isScannerModalOpened: false
  };

  componentDidMount() {
    this.props.getNoteTypes(this.props.currentOrg);
    if (!this.props.machines) {
      this.props.getMachinesAndActuals(this.props.currentOrg);
    }
    if (!this.props.planStructures) {
      this.props.getProductionPlanStructures(this.props.currentOrg);
    } else if (this.props.planStructures.length) {
      this.setupDataInterval();
    }
    if ((this.props.loggedInUser?.admin_of_org && this.props.loggedInUser.admin_of_org.length) || this.props.loggedInUser?.is_superuser) {
      let isAdminOfOrg = false;
      if (!this.props.loggedInUser.is_superuser) {
        isAdminOfOrg = this.props.loggedInUser.admin_of_org.includes(this.props.currentOrg);
      }
      this.setState({ isAdminOfOrg: isAdminOfOrg || this.props.loggedInUser.is_superuser });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentOrg !== prevProps.currentOrg && this.props.currentOrg) {
      this.props.getMachinesAndActuals(this.props.currentOrg);
      this.props.getProductionPlanStructures(this.props.currentOrg);
      this.props.clearNoteTypes();
      setTimeout(() => {
        this.props.getNoteTypes(this.props.currentOrg, { orgChanged: true });
      }, 500);
    }
    if (prevProps.planStructures !== this.props.planStructures ||
      prevState.currentTabIndex !== this.state.currentTabIndex ||
      prevState.query !== this.state.query) {
      clearInterval(this.actualProductionInterval);
      if (this.props.planStructures[this.state.currentTabIndex]) {
        this.setupDataInterval();
      }
    }
    if (this.props.result === 'success' && prevProps.updatedActualProduction !== this.props.updatedActualProduction) {
      this.props.getProductionPlanStructures(this.props.currentOrg);
    }
    if (!_.isEqual(prevProps.actualProductions, this.props.actualProductions) && this.props.actualProductions) {
      this.setState({ actualProductions: this.props.actualProductions });
    }
    if (this.props.noteTypes && prevProps.noteTypes !== this.props.noteTypes) {
      const foundProdType = this.props.noteTypes.find(nt => nt.name.toLowerCase() === 'production');
      if (foundProdType) this.props.getDocumentNotes(this.props.currentOrg, foundProdType.id);
    }
  }

  componentWillUnmount() {
    clearInterval(this.actualProductionInterval);
  }

  setupDataInterval = () => {
    this.props.getActualProduction(this.props.currentOrg, null, this.props.planStructures[this.state.currentTabIndex].uuid, this.state.query.start, this.state.query.end);
    this.actualProductionInterval = setInterval(() => {
      this.props.getActualProduction(this.props.currentOrg, null, this.props.planStructures[this.state.currentTabIndex].uuid, this.state.query.start, this.state.query.end);
    }, 60 * 1000);
  }

  onFilter = (start, end) => {
    this.setState({ query: { start, end }, advancedSearch: false });
  }

  onSearchPlansHandler = (e, searchTexts) => {
    clearInterval(this.actualProductionInterval);
    e.preventDefault();
    this.setState({
      advancedSearch: true,
      isDrawerOpened: false,
      query: {
        start: new Date(searchTexts.start + ' 00:00').getTime(),
        end: new Date(searchTexts.end + ' 00:00').getTime()
      }
    });
  }

  getTableColumns = () => {
    if (!this.props.planStructures) return;

    const { columns, po_column_order, start_column_order, end_column_order, target_column_order, machine_column_order } = this.props.planStructures[this.state.currentTabIndex];
    const currentColumns = [];
    currentColumns[po_column_order - 1] = { name: 'po', display_name: 'PO' };
    currentColumns[start_column_order - 1] = { name: 'start', display_name: 'Start' };
    currentColumns[end_column_order - 1] = { name: 'end', display_name: 'End' };
    currentColumns[target_column_order - 1] = { name: 'target', display_name: 'Target' };
    currentColumns[machine_column_order - 1] = { name: 'machine', display_name: 'Machine' };
    if (columns && columns.length) {
      columns.forEach(col => currentColumns[col.order - 1] = { name: col.name, display_name: col.display_name });
    }
    currentColumns.push({ name: 'total', display_name: 'Total' });
    currentColumns.push({ name: 'ok', display_name: 'Good' });
    currentColumns.push({ name: 'defect', display_name: 'Defect' });
    return currentColumns;
  }

  onDownloadActualHandler = (e) => {
    e.preventDefault();
    let data = [[]];
    const headers = this.getTableColumns();
    headers.forEach(col => {
      data[0].push(col.display_name);

      this.props.actualProductions.forEach((actual, i) => {
        if (!data[i + 1]) data[i + 1] = [];
        if (col.name === 'po') {
          data[i + 1].push(actual.plan.po);
        } else if (col.name === 'start' || col.name === 'end') {
          data[i + 1].push(moment(actual[col.name]).format('DD/MM/YYYY HH:mm'))
        } else if (col.name === 'machine') {
          data[i + 1].push(actual.machine.name);
        } else if (col.name === 'target' || col.name === 'total' || col.name === 'ok' || col.name === 'defect') {
          data[i + 1].push(actual[col.name]);
        } else {
          // custom columns
          if (actual.columns[col.name]) {
            data[i + 1].push(actual.columns[col.name]);
          } else if (actual.plan.columns[col.name]) {
            data[i + 1].push(actual.plan.columns[col.name]);
          } else {
            data[i + 1].push('-');
          }
        }
      });
    });
    /* convert from array of arrays to workbook */
    let wb = XLSX.utils.book_new();
    // let ws = XLSX.utils.json_to_sheet(dd);
    let ws = XLSX.utils.aoa_to_sheet(data);

    wb.SheetNames.push('');
    wb.Sheets[''] = ws;

    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

    let url = window.URL.createObjectURL(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }));

    let a = document.createElement('a');
    a.href = url;
    a.download = 'Actuals of ' + this.props.planStructures[this.state.currentTabIndex].name.toLowerCase() + '.xlsx';
    a.click();

    window.URL.revokeObjectURL(url);
  }

  s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }

  onEditActualHandler = (actual) => {
    if (this.state.isAdminOfOrg) {
      this.setState({ editingActual: actual, isEdit: true, isDrawerOpened: true });
    }
  }

  toggleFullScreen = () => {
    this.setState(prevState => {
      return { isFullScreen: !prevState.isFullScreen }
    });
  }

  toggleScannerHandler = () => {
    this.setState(prevState => {
      return { isScannerModalOpened: !prevState.isScannerModalOpened }
    });
  }

  addProductionNoteHandler = () => {
    this.setState({ isProdDocAdding: true, editingProdDoc: null, isDrawerProdDocOpened: true, deletingProdDocUuid: null });
  }

  toggleProductionNoteHandler = () => {
    this.setState(prevState => { return { isDrawerProdDocOpened: !prevState.isDrawerProdDocOpened } });
  }

  onEditProdDocHandler = (doc) => {
    this.setState({ isProdDocAdding: false, editingProdDoc: doc, isDrawerProdDocOpened: true, deletingProdDocUuid: null });
  }

  onConfirmDeleteProdDoc = () => {
    this.props.deleteDocumentNote(this.state.deletingProdDocUuid);
    this.setState({ isProdDocAdding: false, editingProdDoc: null, isDeleteProdDocModalOpened: false, deletingProdDocUuid: null });
  }

  onDeleteProdDocHandler = (doc) => {
    this.setState({ deletingProdDocUuid: doc.uuid, isDeleteProdDocModalOpened: true });
  }

  render() {
    const { planStructures, loading } = this.props;
    const { currentTabIndex, query, isDrawerOpened, advancedSearch, isEdit, actualProductions } = this.state;
    let ScreenStyle = styles.MinimizeScreen;
    if (this.state.isFullScreen) {
      ScreenStyle = styles.FullScreen;
    }

    return (
      <div className={`${styles.AdvanceProductionPlans} ${ScreenStyle}`}>
        <SideDrawer overrideStyles={{ width: '100%', maxWidth: '95%', paddingRight: 400, zIndex: this.state.isScannerModalOpened ? 1000 : 1001 }}
          title="Production Document Note"
          open={this.state.isDrawerProdDocOpened}
          closed={this.toggleProductionNoteHandler}
        >
          <ProductionLogDrawer
            mainTab={0}
            isAdding={this.state.isProdDocAdding}
            prodDocDrawerClosed={this.toggleProductionNoteHandler}
            editingLog={this.state.editingProdDoc}
            selectingActual={this.state.editingActual}
            toggleDeleteModal={this.onDeleteProdDocHandler}
            toggleScannerHandler={this.toggleScannerHandler}
            scannerText={this.state.scannerText}
            clearScannerText={() => this.setState({ scannerText: '' })}
          />
        </SideDrawer>
        <SideDrawer
          title={isEdit ? 'Update Actual' : 'Advanced Search Actual Production'}
          open={isDrawerOpened}
          closed={() => this.setState({ isDrawerOpened: false, isDrawerProdDocOpened: false })}
          overrideStyles={{ zIndex: this.state.isScannerModalOpened ? 1000 : 1001 }}
        >
          {
            isEdit
              ? <ActualDrawer
                editingActual={this.state.editingActual}
                closed={() => this.setState({ isDrawerOpened: false, isDrawerProdDocOpened: false })}
                structure={planStructures ? planStructures[currentTabIndex] : null}
                addProductionNote={this.addProductionNoteHandler}
                editProdDoc={this.onEditProdDocHandler}
                deleteProdDoc={this.onDeleteProdDocHandler}
              />
              : <SearchDrawer columns={[{ name: 'start' }, { name: 'end' }]} searchPlans={this.onSearchPlansHandler} closed={() => this.setState({ isDrawerOpened: false })} />
          }
        </SideDrawer>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              planStructures?.map((pl, index) => (
                <div key={`plan-list-${index}`} className={index === currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentTabIndex: index })}>
                  <span className={styles.MenuTitle}>{pl.name}</span>
                </div>
              ))
            }
          </div>
          <div className={styles.AddButtonWrapper}>
            {actualProductions && actualProductions.length ? <Button type="button" icon={DownloadIcon} color="green" name="Download" noMargin click={this.onDownloadActualHandler} /> : null}
          </div>
          <div className={styles.ScreenButton}>
            {
              this.state.isFullScreen ?
                <img src={NormalScreenIcon} alt="Normal Screen Icon" onClick={this.toggleFullScreen} /> :
                <div className={styles.ScreenWrapper} onClick={this.toggleFullScreen} >
                  <img src={FullScreenIcon} alt="Full Screen Icon" />
                </div>
            }
          </div>
        </div>
        <div className="MainContentPadding">
          <ActualsTable
            isAdminOfOrg={this.state.isAdminOfOrg}
            editActual={actual => this.onEditActualHandler(actual)}
            // loading={loading}
            query={query}
            actuals={actualProductions}
            structure={planStructures ? planStructures[currentTabIndex] : null}
            onFilter={this.onFilter}
            advancedSearch={advancedSearch}
            onAdvancedSearchClick={() => this.setState({ isDrawerOpened: true, isEdit: false })} />
        </div>
        {
          this.state.isScannerModalOpened &&
          <ScannerModal
            receivedScannerText={(text) => this.setState({ scannerText: text })}
            open={this.state.isScannerModalOpened}
            modalClosed={this.toggleScannerHandler}
          />
        }
        <DeleteModal
          title="Production Document"
          open={this.state.isDeleteProdDocModalOpened}
          modalClosed={() => this.setState({ isDeleteProdDocModalOpened: false })}
          confirmDelete={this.onConfirmDeleteProdDoc}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { currentOrg } = state.org;
  const { planStructures } = state.advanceProductionPlanStructure;
  const { type, error, loading, actualProductions, updatedActualProduction, result } = state.advanceActualProduction;
  const { documentNotes, noteTypes } = state.document;
  const { machines } = state.machine;
  return { loggedInUser, currentOrg, planStructures, machines, loading, actualProductions, error, actualType: type, updatedActualProduction, result, documentNotes, noteTypes };
};

export default connect(mapStateToProps, {
  getActualProduction,
  getProductionPlanStructures,
  getMachinesAndActuals,
  deleteDocumentNote,
  getNoteTypes,
  getDocumentNotes,
  clearNoteTypes
})(AdvanceActualProduction);
