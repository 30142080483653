import React, { Component } from 'react';
import { connect } from 'react-redux';
// import moment from 'moment';
// import TimeAgo from 'react-timeago';

import styles from './VirtualDeviceList.module.scss';

class VirtualDeviceList extends Component {
    state = {
        virtualDevices: null
    }

    componentDidMount() {
        if (this.props.virtualDevices) {
          this.setState({ virtualDevices: this.props.virtualDevices });
        }
      }

      componentDidUpdate(prevProps, prevState) {
        if (this.props.virtualDevices !== prevProps.virtualDevices) {
          this.setState({ virtualDevices: this.props.virtualDevices });
        }
      }

      render() {
          return (
            this.state.virtualDevices ? this.state.virtualDevices.map((virtualDevice, i) => {
                let classList = [styles.AddedVirtualDeviceWrapper];
                if (this.props.selectedVirtualDevice === i)
                    classList.push(styles.Selected);

                return (
                    <div key={virtualDevice.uuid} className={classList.join(' ')} onClick={() => this.props.onEditVirtualDevice(i)}>
                        <div style={{ flex: 1 }}>
                            <div className={styles.Name}>{virtualDevice.name}</div>
                            <div className={styles.Description}>{virtualDevice.secret}</div>
                        </div>
                    </div>
                );
            }) : null
          );
      }
}

const mapStateToProps = (state) => {
    return {};
}

export default connect(mapStateToProps, {})(VirtualDeviceList)