import React, { Component } from 'react';
import _ from 'lodash';

import styles from './PageLoader.module.scss';
import Backdrop from '../Backdrop/Backdrop';
import Spinner2 from '../Spinner/Spinner';

class PageLoader extends Component {

  render() {

    return (
      <React.Fragment>
        <Backdrop show={this.props.show} overideStyles={{ zIndex: 2000 }} />
        {
          this.props.show
            ? <div className={styles.PageLoader} style={{ zIndex: 2001 }} >
              <Spinner2 />
              {
                this.props.text
                  ? <div className={styles.Percent}>{this.props.text}</div>
                  : this.props.percent || this.props.percent === 0
                    ? <div className={styles.Percent}>{Math.floor(this.props.percent)}%</div>
                    : null
              }
            </div>
            : null
        }

      </React.Fragment>
    );
  }
}

export default PageLoader;