import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';

import styles from './LampForm.module.scss';
import Input from '../../../UI/Input/Input';
import AddIcon from '../../../../../assets/icons/Rule/add-circle.svg';
import DeleteIcon from '../../../../../assets/icons/Rule/delete-circle.svg';

import { updateObject, checkValidity } from '../../../../../state/utils';

const DEFAULT_COLOR_TRIGGER_FORM = {
  point: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  color: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  displayedText: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  }
};

const colourStyles = {
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
  }),
  input: base => ({
    ...base,
    color: 'white',
    height: 35,
    paddingTop: 10
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
};

class ColorTrigger extends Component {
  state = {
    formIsValid: false,
    colorTriggersForm: DEFAULT_COLOR_TRIGGER_FORM
  }

  componentDidMount() {
    this.setupForm(this.props.colorTrigger);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.colorTrigger, this.props.colorTrigger) && this.props.colorTrigger) {
      // after text change, it goes here? suppose not
      this.setupForm(this.props.colorTrigger);
    }
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.colorTriggersForm };
    for (let inputIdentifier in updatedForm) {
      const updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] || form[inputIdentifier] === 0 ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
      });

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }

    let formIsValid = form.isValid;
    this.setState({ colorTriggersForm: updatedForm, formIsValid });
  }

  onColorChange = (value) => {
    let updatedElement = updateObject(this.state.colorTriggersForm['color'], {
      value: value.color,
      valid: checkValidity(value.color, this.state.colorTriggersForm['color'].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.colorTriggersForm, {
      color: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.props.handleColorTriggerChange(this.props.index, updatedForm, formIsValid);
    this.setState({ colorTriggersForm: updatedForm, formIsValid });
  }

  handleChange = event => {
    let updatedElement = updateObject(this.state.colorTriggersForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.colorTriggersForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.colorTriggersForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.props.handleColorTriggerChange(this.props.index, updatedForm, formIsValid);
    this.setState({ colorTriggersForm: updatedForm, formIsValid });
  };

  render() {
    const { point, color, displayedText } = this.state.colorTriggersForm;
    const selectedColor = this.props.colorOptions.filter(c => c.color === color.value)[0];

    return (
      <>
        <div className={styles.ColorTrigger}>
          <div style={{ display: 'flex', flex: 1, marginRight: 15 }}>
            <Input
              name="point"
              value={point.value}
              placeholder="Value"
              type="number"
              onChange={this.handleChange}
              error={`${point.touched && !point.valid ? 'Value is required' : ''}`}
            />
          </div>
          <div style={{ display: 'flex', flex: 2 }}>
            <div style={{ flex: 1 }}>
              <Select options={this.props.colorOptions} styles={colourStyles}
                placeholder="Color"
                value={selectedColor}
                onChange={this.onColorChange}
                getOptionValue={opt => opt.id}
              />
            </div>
            {
              this.props.formLength > 1 ?
                this.props.index === this.props.formLength - 1 ?
                  <div className={styles.AddButton}>
                    <img className={styles.IconButton} src={DeleteIcon} alt="Delete Icon" onClick={this.props.removeColorTrigger} />
                    <img className={styles.IconButton} src={AddIcon} alt="Add Icon" onClick={() => this.props.addNewColorTrigger(false)} />
                  </div>
                  :
                  <div className={styles.AddButton}>
                    <img className={styles.IconButton} src={DeleteIcon} alt="Delete Icon" onClick={this.props.removeColorTrigger} />
                  </div>
                :
                <div className={styles.AddButton}>
                  <img className={styles.IconButton} src={AddIcon} alt="Add Icon" onClick={() => this.props.addNewColorTrigger(false)} />
                </div>
            }
          </div>
        </div>
        <Input type="text" name="displayedText" value={displayedText.value} placeholder={`Display this text instead of value (${point.value})`} onChange={this.handleChange} />
      </>
    );
  }
}

export default ColorTrigger;
