import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from '../SystemDeviceConfig.module.scss';

class DeviceHealth extends Component {

    render() {
        const { deviceStatus } = this.props;
        return (
            <>
                <div className={styles.HealthRowWrapper} style={{ padding: 0, paddingLeft: 5 }}>
                    <div className={styles.Label}>Date</div>
                    <div className={`${styles.Label} ${styles.Status}`}>Status</div>
                </div>

                {
                    deviceStatus && deviceStatus.length > 0
                        ? deviceStatus.map((ds, i) => (
                            <div key={`device-status-${i}`} className={styles.HealthRowWrapper}>
                                <div className={styles.Info}>{moment(ds.date).format('DD/MM/YYYY HH:mm:ss')}</div>
                                <div className={`${styles.Info} ${ds.status === 'on' ? styles.On : styles.Off} ${styles.Status}`}>{ds.status === 'on' ? 'Connect' : 'Disconnect'}</div>
                            </div>
                        ))
                        : <div>Not found</div>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { deviceStatus } = state.devices;
    return { deviceStatus };
};

export default connect(mapStateToProps, {})(DeviceHealth);