import React from 'react';

import styles from './LoadingWideCard.module.scss';

const LoadingWideCard = ({ lightTheme }) => {
  return (
    <React.Fragment>
      <div className={`${styles.LoadingWideCard} ${lightTheme ? styles.White : ''}`}></div>
      <div className={`${styles.LoadingWideCard} ${lightTheme ? styles.White : ''}`}></div>
    </React.Fragment>
  );
}

export default LoadingWideCard;
