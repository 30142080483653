import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import NotificationIcon from '../../../../assets/icons/navigation-items/notification-blue.png';
import ImportantIcon from '../../../../assets/icons/caution.png';

import styles from './NotificationList.module.scss';

class NotificationList extends Component {
  state = {
    notificationList: null
  }

  componentDidMount() {
    if (this.props.notifications) {
      this.setState({ notificationList: this.props.notifications });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.notifications !== prevProps.notifications) {
      this.setState({ notificationList: this.props.notifications });
    }
  }

  render() {
    return (
      this.state.notificationList ? this.state.notificationList.map((notif, i) => {
        let notifWrapper = [styles.AddedNotificationWrapper];
        if (this.props.selectedNotification === i) {
          notifWrapper.push(styles.Selected);
        }

        let past = false;
        if (notif.type === 1) {
          if (moment(notif.end).valueOf() < moment().valueOf())  past = true;
        } else if (moment(notif.start).valueOf() < moment().valueOf()) {
          past = true;
        }

        return (
          <div key={notif.uuid} className={notifWrapper.join(' ')} onClick={() => this.props.onEditNotification(i)}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', opacity: past ? 0.6 : 1 }}>
              {
                notif.type === 1
                  ? <img src={ImportantIcon} alt="notif icon" style={{ width: 25, height: 25, marginRight: 9 }} />
                  : <img src={NotificationIcon} alt="notif icon" style={{ width: 20, height: 20, marginRight: 15 }} />
              }
              <div className={styles.Name}>{notif.title}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={styles.TimeText}>{moment(notif.start).format("DD/MM/YYYY HH:mm")}</div>
            </div>
          </div>
        )
      }) : null
    )
  }

}
const mapStateToProps = (state) => {
  // const { } = state;
  return {};
};

export default connect(mapStateToProps, {})(NotificationList);