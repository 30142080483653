import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

import styles from './MachineViewBox.module.scss';
import GaugeChart from '../../../UI/GaugeChart/GaugeChart';

import { getDataEval, getDataLatest, getDatasetCal } from '../../../../../state/ducks/Data/actions';
import { calculateOee } from '../../../../../state/ducks/AdvanceActualProduction/actions';
import _ from 'lodash';

const PERIODS_FOR_OEE_CHART = 6;

class MachineViewBox extends Component {
    state = {
        producedActual: {},
        currentMachineStatus: {},
        currentAPQ: {},
        currentOEE: {},
        historyOEE: {},
        displayingColumns: ''
    };

    componentDidMount() {
        if (this.props.machine.actuals && this.props.machine.actuals.length && this.props.isFloating !== null && (this.props.isFloating.toString() === 'true' || this.props.isFloating.toString() === 'false')) {
            this.getDataForMachineViewBox();
        }
        if (this.props.planStructures && this.props.machine.actuals && this.props.machine.actuals.length) {
            this.setTextDisplayingColumns();
        }
    }

    getDataForMachineViewBox = () => {
        const { total_device_secret, total_device_socket, actuals, id, status_device_secret, status_device_socket } = this.props.machine;
        if (this.props.machine.actuals && this.props.machine.actuals.length) {
            let method = 'sum';
            if (this.props.machine.is_total_device_accumulated) {
                method = 'inc';
            }
            const actualsFromStart = '{ra:' + method + '(' + total_device_secret + '@' + total_device_socket + '@' + moment(actuals[0].start).valueOf() + '-now())}';
            // this.props.getDataEval(actualsFromStart, { machineId: id });
            this.props.getDataLatest(status_device_socket, status_device_secret, null, null, { machineId: id });
            this.findOeeHistory(actuals[0]);

            clearInterval(this.dataForMachineInterval);
            this.dataForMachineInterval = setInterval(() => {
                // this.props.getDataEval(actualsFromStart, { machineId: id });
                this.props.getDataLatest(status_device_socket, status_device_secret, null, null, { machineId: id });
                this.findOeeHistory(actuals[0]);
            }, 60 * 1000);
        }
    }

    setTextDisplayingColumns = () => {
        const foundStructure = this.props.planStructures.find(ps => ps.uuid === this.props.machine.actuals[0].structure_uuid);

        if (foundStructure && foundStructure.properties && foundStructure.properties.displayingColumns) {
            let displayingColumns = '';
            const { columns, plan } = this.props.machine.actuals[0];
            foundStructure.properties.displayingColumns.forEach((dc, i) => {
                if (columns && columns[dc.id]) {
                    displayingColumns += (i === 0 ? '' : ', ') + dc.label + ': ' + columns[dc.id];
                } else if (plan.columns && plan.columns[dc.id]) {
                    displayingColumns += (i === 0 ? '' : ', ') + dc.label + ': ' + plan.columns[dc.id];
                } else {
                    displayingColumns += (i === 0 ? '' : ', ') + dc.label + ': -';
                }
            });
            this.setState({ displayingColumns });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.planStructures !== this.props.planStructures && this.props.planStructures) {
            if (this.props.machine.actuals && this.props.machine.actuals.length) {
                this.setTextDisplayingColumns();
            }
        }
        if (this.props.dataEval && prevProps.dataEval !== this.props.dataEval && this.isThisTheRightData(this.props.dataEval, this.props.machine)) {
            // const producedActual = { ...this.state.producedActual };
            // producedActual[this.props.dataEval.properties.machineId] = this.props.dataEval.data;
            // this.setState({ producedActual });
        } else if (this.props.dataLatest && prevProps.dataLatest !== this.props.dataLatest && this.isThisTheRightData(this.props.dataLatest, this.props.machine)) {
            const currentMachineStatus = { ...this.state.currentMachineStatus };
            currentMachineStatus[this.props.dataLatest.properties.machineId] = this.props.dataLatest.data && this.props.dataLatest.data.length ? this.props.dataLatest.data[0].value : '';
            this.setState({ currentMachineStatus });
        } else if (this.props.isFloating !== prevProps.isFloating && this.props.isFloating !== null && (this.props.isFloating.toString() === 'true' || this.props.isFloating.toString() === 'false')) {
            this.getDataForMachineViewBox();
        } else if (this.props.machine && this.props.machine.actuals && !_.isEqual(prevProps.machine.actuals, this.props.machine.actuals)) {
            // case: no-po -----> po ( actuals null -> actuals [...] )
            this.getDataForMachineViewBox();
        } else if (this.props.actualOee && prevProps.actualOee !== this.props.actualOee && this.isThisTheRightData(this.props.actualOee, this.props.machine)) {
            if (!this.props.actualOee.properties.isHistory && this.props.machine.actuals) {
                // For current OEE : DEPRECATED (13/06/2022) => use history to loop calculation each apq
                const currentOEE = { ...this.state.currentOEE };
                if (this.props.actualOee.apq) {
                    currentOEE[this.props.actualOee.properties.machineId] = this.props.actualOee.apq[0].a * this.props.actualOee.apq[0].p * this.props.actualOee.apq[0].q / 10000;
                } else {
                    currentOEE[this.props.actualOee.properties.machineId] = 0;
                }
                this.findOeeHistory(this.props.machine.actuals[0]);
                this.setState({ currentOEE });
            } else {
                // for OEE history
                if (this.props.machine.actuals && this.props.machine.actuals.length) {
                    const isOver = this.state.currentOEE[this.props.actualOee.properties.machineId] >= 80;
                    let graphData = {
                        labels: [],
                        datasets: [{
                            label: 'OEE%',
                            borderColor: isOver ? '#34aa44' : '#ea272a',
                            backgroundColor: isOver ? '#34aa4433' : '#ea272a33',
                            // backgroundColor: true ? '#203623' : '#3d2122',
                            fill: true,
                            data: [],
                            pointBackgroundColor: isOver ? '#34aa44' : '#ea272a',
                            pointRadius: 1,
                            lineTension: 0
                        }]
                    };

                    const historyOEE = { ...this.state.historyOEE };
                    historyOEE[this.props.actualOee.properties.machineId] = {};
                    const { start } = this.props.machine.actuals[0];
                    const diffDays = new Date().getTime() - new Date(start).getTime();

                    if (this.props.actualOee.apq) {
                        let value = { runTime: 0, totalTime: 0, good: 0, total: 0 };
                        this.props.actualOee.apq.forEach((ea, i) => {
                            value.runTime += ea.runtime;
                            value.totalTime += ea.total_time;
                            value.good += ea.good;
                            value.total += ea.total;

                            graphData.labels.push(moment(ea.ts).format('HH:mm'));
                            if (ea.a === -1 || ea.p === -1 || ea.q === -1) {
                                graphData.datasets[0].data.push(null);
                            } else {
                                if (i === 0 && this.props.actualOee.apq[1] && this.props.actualOee.apq[1].a === -1 && this.props.actualOee.apq[1].p === -1 && this.props.actualOee.apq[1].q === -1) {
                                    // case for plot only first data (0) and jump to now()
                                    graphData.datasets[0].data.push(null);
                                } else {
                                    if (moment(ea.ts) > moment() - (60 * 1000)) {
                                        graphData.datasets[0].data.push(null);
                                    } else {
                                        const ava = ea.a;
                                        const per = ea.p;
                                        const qua = ea.q;
                                        // const ava = (value.runTime * 100 / value.totalTime);
                                        // const per = value.total * this.props.actualOee.expected * 100 / diffDays;
                                        // const qua = (value.good * 100 / value.total);
                                        const oeePercent = (ava * per * qua / 10000);
                                        if (!this.props.displayOEEOverHundred && oeePercent >= 100) {
                                            graphData.datasets[0].data.push(100);
                                        } else {
                                            graphData.datasets[0].data.push(oeePercent.toFixed(2));
                                        }
                                    }
                                }
                            }
                        });
                        const avaPercent = this.props.actualOee.sum.a;
                        const perPercent = this.props.actualOee.sum.p;
                        const quaPercent = this.props.actualOee.sum.q;
                        // const avaPercent = (value.runTime * 100 / value.totalTime);
                        // const perPercent = value.total * this.props.actualOee.expected * 100 / diffDays;
                        // const quaPercent = (value.good * 100 / value.total);
                        const oeePercent = (avaPercent * perPercent * quaPercent) / 10000;
                        const currentAPQ = { ...this.state.currentAPQ };
                        currentAPQ[this.props.actualOee.properties.machineId] = {
                            a: isNaN(avaPercent) ? 0 : avaPercent,
                            p: isNaN(perPercent) ? 0 : perPercent,
                            q: isNaN(quaPercent) ? 0 : quaPercent
                        }
                        const currentOEE = { ...this.state.currentOEE };
                        currentOEE[this.props.actualOee.properties.machineId] = oeePercent;
                        this.setState({ currentOEE, currentAPQ });
                    }

                    const producedActual = { ...this.state.producedActual };
                    producedActual[this.props.actualOee.properties.machineId] = this.props.actualOee.sum.total;

                    historyOEE[this.props.actualOee.properties.machineId] = graphData;
                    this.setState({ historyOEE, producedActual });
                }
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.dataForMachineInterval);
    }

    isThisTheRightData = (data, comp) => {
        if (data.properties && data.properties.machineId === comp.id) {
            return true;
        }
        return false;
    }

    findOeeHistory = (actual) => {
        let startQuery = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).subtract(PERIODS_FOR_OEE_CHART - 1, 'hours').valueOf();
        let nextHour = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours').valueOf();
        this.props.calculateOee(actual.uuid, null, startQuery, nextHour, 1, { isHistory: true, machineId: this.props.machine.id }, this.props.isFloating);
    }

    setupOptions = () => {
        let options = {
            elements: {
                // point: { radius: 0 }
            },
            tooltips: {
                mode: 'index',
                intersect: false,
            },
            hover: {
                mode: 'nearest',
                intersect: true
            },
            responsive: true,
            animation: false,
            maintainAspectRatio: false,
            legend: {
                position: 'top',
                display: false,
            },
            scales: {
                xAxes: [{
                    display: true,
                    gridLines: {
                        color: '#2b2b30',
                        zeroLineColor: '#2b2b30',
                        lineWidth: 2
                    },
                    ticks: {
                        fontColor: '#9ea0a5',
                        minTicksLimit: 6,
                        maxTicksLimit: 6,
                        callback: (dataLabel, index, labels) => {
                            if (index % 60 === 0) return dataLabel;
                            else return '';
                        }
                    }
                }],
                yAxes: [{
                    display: true,
                    gridLines: {
                        color: '#2b2b30',
                        zeroLineColor: '#2b2b30',
                        lineWidth: 2
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'OEE %',
                        fontColor: '#9ea0a5',
                    },
                    ticks: {
                        min: 0,
                        stepSize: 50,
                        maxTicksLimit: 3,
                        fontColor: '#9ea0a5',
                    }
                }]
            }
        };
        if (!this.props.displayOEEOverHundred) {
            options.scales.yAxes[0].ticks.max = 100;
        }
        return options;
    }

    humanizeDuration = (ms, showSec) => {
        const days = moment.duration(ms).days();
        const hours = moment.duration(ms).hours();
        const minutes = moment.duration(ms).minutes();
        const seconds = moment.duration(ms).seconds();

        let result = days === 0 ? '' : days > 1 ? days + ' days ' : days + ' day ';
        result += hours === 0 ? '' : hours > 1 ? hours + ' hours ' : hours + ' hour ';
        result += minutes === 0 ? '' : minutes > 1 ? minutes + ' minutes ' : minutes + ' minute ';
        if (showSec) {
            result += seconds === 0 ? '' : seconds > 1 ? seconds + ' seconds ' : seconds + ' second';
        }
        return result;
    }

    render() {
        const { machine: { id, name, actuals, status_rules }, displayOEEOverHundred } = this.props;

        return (
            <div className={`${styles.MachineBox}`}
                style={{ background: `linear-gradient(180deg, ${actuals && status_rules[this.state.currentMachineStatus[id]] ? status_rules[this.state.currentMachineStatus[id]].color : '#9ea0a555'} 10%, #222226 70%)` }}
            >
                <div className={`${styles.Top}`}>
                    <div className={styles.Name}>{name}</div>
                    <div className={styles.Description}>{actuals ? 'PO: ' + actuals[0].plan.po : '-'}</div>
                    <div className={styles.Description} style={{ fontSize: 12, opacity: 0.7, marginTop: 5, marginBottom: 10 }}>{this.state.displayingColumns}</div>
                    <div
                        className={styles.MachineStatusText}
                        style={{
                            color: actuals && status_rules[this.state.currentMachineStatus[id]] ? status_rules[this.state.currentMachineStatus[id]].color + '' : '',
                            backgroundColor: actuals ? '#22222655' : '',
                        }}
                    >
                        {actuals && status_rules[this.state.currentMachineStatus[id]] ? status_rules[this.state.currentMachineStatus[id]].reason : 'N/A'}
                    </div>
                </div>
                <div className={`${styles.Middle}`}>
                    <div className={styles.GaugeWrapper}>
                        <GaugeChart
                            value={actuals && this.state.currentOEE[id] ? !displayOEEOverHundred && this.state.currentOEE[id] >= 100 ? 100 : this.state.currentOEE[id] : 0}
                            min={0}
                            max={100}
                            size={180}
                            strokewidth={20}
                            color={this.state.currentOEE[id] <= 80 ? '#ea272a' : '#34aa44'}
                        />
                        <div className={styles.GaugeValue}>
                            <div>
                                {
                                    actuals && (this.state.currentOEE[id] || this.state.currentOEE[id] === 0)
                                        ? !displayOEEOverHundred && this.state.currentOEE[id] >= 100
                                            ? this.state.currentOEE[id] > 100 ? '> 100%' : '100%'
                                            : this.state.currentOEE[id].toFixed(2) + '%'
                                        : '---'
                                }
                            </div>
                            <div className={styles.Sub}>OEE</div>
                        </div>
                    </div>
                    <div style={{ marginTop: 15, textAlign: 'center' }}>
                        <div className={styles.Description}>
                            {actuals && this.state.currentAPQ[id]
                                ? <>
                                    A: <div className={styles.APQTextWrapper}><b>{!displayOEEOverHundred && this.state.currentAPQ[id].a >= 100
                                        ? this.state.currentAPQ[id].a > 100 ? '> 100' : '100'
                                        : this.state.currentAPQ[id].a.toFixed(2)}%</b></div>
                                    P: <div className={styles.APQTextWrapper}><b>{!displayOEEOverHundred && this.state.currentAPQ[id].p >= 100
                                        ? this.state.currentAPQ[id].p > 100 ? '> 100' : '100'
                                        : this.state.currentAPQ[id].p.toFixed(2)}%</b></div>
                                    Q: <div className={styles.APQTextWrapper}><b>{!displayOEEOverHundred && this.state.currentAPQ[id].q >= 100
                                        ? this.state.currentAPQ[id].q > 100 ? '> 100' : '100'
                                        : this.state.currentAPQ[id].q.toFixed(2)}%</b></div>
                                </>
                                : '-'}
                        </div>
                    </div>
                    <div style={{ marginTop: 15, textAlign: 'center' }}>
                        <div className={styles.Description}>{actuals ? `Targets: ${this.state.producedActual[id] || this.state.producedActual[id] === 0 ? this.state.producedActual[id].toLocaleString() : 'loading...'}/${(actuals[0].target).toLocaleString()}` : '-'}</div>
                    </div>
                    <div style={{ marginTop: 15, textAlign: 'center' }}>
                        <div className={styles.Description} style={{ color: actuals && status_rules[this.state.currentMachineStatus[id]] ? status_rules[this.state.currentMachineStatus[id]].color + '' : '' }}>
                            {actuals ? `Elapsed Time: ${this.humanizeDuration(new Date() - new Date(actuals[0].start))}` : ''}
                        </div>
                    </div>
                </div>
                <div className={`${styles.Bottom}`}>
                    {actuals && this.state.historyOEE[id] ? <Line data={this.state.historyOEE[id]} height={120} options={this.setupOptions()} /> : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { dataEval, dataLatest, datasetCal } = state.data;
    const { actualOee } = state.advanceActualProduction;

    return { currentOrg, dataEval, dataLatest, actualOee, datasetCal };
};

export default connect(mapStateToProps, { getDataEval, getDataLatest, calculateOee, getDatasetCal })(MachineViewBox);