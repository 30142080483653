import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  productionBatchs: null,
  addedProductionBatch: null,
  updatedProductionBatch: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ADVANCE_PRODUCTION_BATCHS_START: return getAdvanceProductionBatchsStart(state, action);
    case actionTypes.GET_ADVANCE_PRODUCTION_BATCHS_SUCCESS: return getAdvanceProductionBatchsSuccess(state, action);
    case actionTypes.GET_ADVANCE_PRODUCTION_BATCHS_FAIL: return getAdvanceProductionBatchsFail(state, action);

    case actionTypes.ADD_ADVANCE_PRODUCTION_BATCH_START: return addAdvanceProductionBatchStart(state, action);
    case actionTypes.ADD_ADVANCE_PRODUCTION_BATCH_SUCCESS: return addAdvanceProductionBatchSuccess(state, action);
    case actionTypes.ADD_ADVANCE_PRODUCTION_BATCH_FAIL: return addAdvanceProductionBatchFail(state, action);

    case actionTypes.EDIT_ADVANCE_PRODUCTION_BATCH_START: return editAdvanceProductionBatchStart(state, action);
    case actionTypes.EDIT_ADVANCE_PRODUCTION_BATCH_SUCCESS: return editAdvanceProductionBatchSuccess(state, action);
    case actionTypes.EDIT_ADVANCE_PRODUCTION_BATCH_FAIL: return editAdvanceProductionBatchFail(state, action);

    case actionTypes.DELETE_ADVANCE_PRODUCTION_BATCH_START: return deleteAdvanceProductionBatchStart(state, action);
    case actionTypes.DELETE_ADVANCE_PRODUCTION_BATCH_SUCCESS: return deleteAdvanceProductionBatchSuccess(state, action);
    case actionTypes.DELETE_ADVANCE_PRODUCTION_BATCH_FAIL: return deleteAdvanceProductionBatchFail(state, action);

    case actionTypes.MAP_PLANS_TO_BATCH_START: return mapPlansToBatchStart(state, action);
    case actionTypes.MAP_PLANS_TO_BATCH_SUCCESS: return mapPlansToBatchSuccess(state, action);
    case actionTypes.MAP_PLANS_TO_BATCH_FAIL: return mapPlansToBatchFail(state, action);

    case actionTypes.GET_BATCH_PROGRESS_START: return getBatchProgressStart(state, action);
    case actionTypes.GET_BATCH_PROGRESS_SUCCESS: return getBatchProgressSuccess(state, action);
    case actionTypes.GET_BATCH_PROGRESS_FAIL: return getBatchProgressFail(state, action);
    
    default: return state;
  }
};

const getAdvanceProductionBatchsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getAdvanceProductionBatchsSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    productionBatchs: action.productionBatchs,
    error: null,
    loading: false,
    result: action.result
  });
}

const getAdvanceProductionBatchsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    result: null
  });
}

const addAdvanceProductionBatchStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addAdvanceProductionBatchSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedProductionBatch: action.addedProductionBatch,
    error: null,
    loading: false
  });
}

const addAdvanceProductionBatchFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editAdvanceProductionBatchStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editAdvanceProductionBatchSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedProductionBatch: action.updatedProductionBatch,
    error: null,
    loading: false
  });
}

const editAdvanceProductionBatchFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteAdvanceProductionBatchStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteAdvanceProductionBatchSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteAdvanceProductionBatchFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const mapPlansToBatchStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const mapPlansToBatchSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const mapPlansToBatchFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getBatchProgressStart = (state, action) => {
  return updateObject(state, { result: null, error: null });
}

const getBatchProgressSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    batchProgress: {
      progress: action.progress,
      properties: action.properties
    },
    error: null
  });
}

const getBatchProgressFail = (state, action) => {
  return updateObject(state, {
    error: action.error
  });
}

export default reducer;