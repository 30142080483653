import * as actionTypes from './types';

// ----------------------- GET BREAKDOWN -----------------------
export const getDocMasterDatasStart = () => {
  return {
    type: actionTypes.GET_DOC_MASTER_DATAS_START
  };
}

export const getDocMasterDatas = (org_id, need_tree, type_id) => {
  return {
    type: actionTypes.GET_DOC_MASTER_DATAS,
    org_id, need_tree, type_id
  };
}

export const getDocMasterDatasSuccess = (response) => {
  return {
    type: actionTypes.GET_DOC_MASTER_DATAS_SUCCESS,
    docMasterDatas: response.masterdatas
  };
}

export const getDocMasterDatasFail = (error) => {
  return {
    type: actionTypes.GET_DOC_MASTER_DATAS_FAIL,
    error
  };
}

// ----------------------- EDIT BREAKDOWN -----------------------
export const postDocMasterDatasStart = () => {
  return {
    type: actionTypes.POST_DOC_MASTER_DATAS_START
  };
}

export const postDocMasterDatas = (org_id, body) => {
  return {
    type: actionTypes.POST_DOC_MASTER_DATAS,
    org_id, body
  };
}

export const postDocMasterDatasSuccess = (response) => {
  return {
    type: actionTypes.POST_DOC_MASTER_DATAS_SUCCESS,
    updatedDocMasterDatas: response.masterdatas,
    result: response.result
  };
}

export const postDocMasterDatasFail = (response) => {
  return {
    type: actionTypes.POST_DOC_MASTER_DATAS_FAIL,
    result: response.result,
    error: response.error
  };
}

// ----------------------- ADD DOC_MASTER_DATA -----------------------

export const addDocMasterDataStart = () => {
  return {
    type: actionTypes.ADD_DOC_MASTER_DATA_START
  };
}

export const addDocMasterData = (org_id, type_id, code, title, description, parent_uuid) => {
  return {
    type: actionTypes.ADD_DOC_MASTER_DATA,
    org_id, type_id, code, title, description, parent_uuid
  };
}

export const addDocMasterDataSuccess = (response) => {
  return {
    type: actionTypes.ADD_DOC_MASTER_DATA_SUCCESS,
    addedDocMasterData: response.masterdatas,
    result: response.result
  };
}

export const addDocMasterDataFail = (error) => {
  return {
    type: actionTypes.ADD_DOC_MASTER_DATA_FAIL,
    error
  };
}

// ----------------------- EDIT DOC_MASTER_DATA -----------------------
export const editDocMasterDataStart = () => {
  return {
    type: actionTypes.EDIT_DOC_MASTER_DATA_START
  };
}

export const editDocMasterData = (uuid, code, title, description, parent_uuid) => {
  return {
    type: actionTypes.EDIT_DOC_MASTER_DATA,
    uuid, code, title, description, parent_uuid
  };
}

export const editDocMasterDataSuccess = (response) => {
  return {
    type: actionTypes.EDIT_DOC_MASTER_DATA_SUCCESS,
    updatedDocMasterData: response.masterdatas,
    result: response.result
  };
}

export const editDocMasterDataFail = (error) => {
  return {
    type: actionTypes.EDIT_DOC_MASTER_DATA_FAIL,
    error
  };
}

// ----------------------- DELETE DOC_MASTER_DATA -----------------------
export const deleteDocMasterDataStart = () => {
  return {
    type: actionTypes.DELETE_DOC_MASTER_DATA_START
  };
}

export const deleteDocMasterData = (org_id, uuids) => {
  return {
    type: actionTypes.DELETE_DOC_MASTER_DATA,
    org_id, uuids
  };
}

export const deleteDocMasterDataSuccess = (result) => {
  return {
    type: actionTypes.DELETE_DOC_MASTER_DATA_SUCCESS,
    result
  };
}

export const deleteDocMasterDataFail = (error) => {
  return {
    type: actionTypes.DELETE_DOC_MASTER_DATA_FAIL,
    error
  };
}

// ----------------------- CLEAR_DOC_MASTER_DATAS -----------------------
export const clearDocMasterDatas = () => {
  return {
    type: actionTypes.CLEAR_DOC_MASTER_DATAS
  };
}