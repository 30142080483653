import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './PcsSettings.module.scss';
import Saved from '../../../assets/images/all-saved.png';
import Tick from '../../../assets/icons/tick-square.svg';
import Untick from '../../../assets/icons/untick-square.png';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';

import { getModuleConfigs, postModuleConfigJson } from '../../../state/ducks/Module/actions';

const DEFAULT_CONFIGS = {
  isFloating: false,
  apqOver: false,
  timelineTime: '08:00'
};

const TAB_LIST = ['Settings'];

class PcsSettings extends Component {
  state = {
    currentTabIndex: 0,
    isSavedModalShowed: false,
    configs: DEFAULT_CONFIGS
  };

  componentDidMount() {
    this.props.getModuleConfigs(this.props.currentOrg, 3);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentOrg !== prevProps.currentOrg && this.props.currentOrg) {
      this.props.getModuleConfigs(this.props.currentOrg, 3);
    } else if (this.props.moduleConfigs !== prevProps.moduleConfigs && this.props.moduleConfigs) {
      if (this.props.moduleConfigs.length) {
        const configs = { ...this.state.configs };
        this.props.moduleConfigs.forEach(mc => {
          const value = mc.value === 'true' ? true : mc.value === 'false' ? false : mc.value;
          configs[mc.key] = value;
        });
        this.setState({ configs });
      } else {
        this.setState({ configs: DEFAULT_CONFIGS });
      }
    }
  }

  changeTabStructureHandler = (index) => {
    this.setState({ currentTabIndex: index });
  };

  onSaveModuleConfigsHandler = (e) => {
    e.preventDefault();

    const data = [];
    Object.keys(this.state.configs).forEach((key, i) => {
      data[i] = {
        org_id: this.props.currentOrg,
        module_id: 3, // PCS
        key,
        value: '' + this.state.configs[key]
      }
    });

    this.props.postModuleConfigJson(data);
    this.setState({ isSavedModalShowed: true });
  }

  changeConfigHandler = (key, value) => {
    const updatedConfigs = { ...this.state.configs };
    updatedConfigs[key] = value;
    this.setState({ configs: updatedConfigs });
  }

  render() {
    const { isFloating, apqOver, timelineTime } = this.state.configs;
    return (
      <div className={styles.PcsSettings}>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              TAB_LIST.map((name, index) => (
                <div key={`logs-${index}`} className={index === this.state.currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.changeTabStructureHandler(index)}>
                  <span className={styles.MenuTitle}>{name}</span>
                </div>
              ))
            }
          </div>
        </div>
        <div className="MainContentPadding" style={{ marginLeft: 10 }}>
          <div style={{ marginBottom: 40 }}>
            <div className={styles.Topic} style={{ display: 'flex', alignItems: 'end' }}>
              <div style={{ flex: 1 }}>DASHBOARD</div>
              <Button className={styles.AddDeviceButton} type="button" name="Save" color="primary" click={this.onSaveModuleConfigsHandler} overideButtonStyles={{ width: 200 }} noMargin />
            </div>
            <div className={styles.SettingsSection}>
              <div className={styles.SettingWrapper}>
                <div className={styles.Name}>
                  Time floating for OEE calculation
                </div>
                {
                  isFloating
                    ? <img className={styles.TickIcon} src={Tick} alt="tick" onClick={() => this.changeConfigHandler('isFloating', false)} />
                    : <img className={styles.TickIcon} src={Untick} alt="untick" onClick={() => this.changeConfigHandler('isFloating', true)} />
                }
              </div>
              <div className={styles.SettingWrapper}>
                <div className={styles.Name}>
                  Display APQ over 100% (and OEE report)
                </div>
                {
                  apqOver
                    ? <img className={styles.TickIcon} src={Tick} alt="tick" onClick={() => this.changeConfigHandler('apqOver', false)} />
                    : <img className={styles.TickIcon} src={Untick} alt="untick" onClick={() => this.changeConfigHandler('apqOver', true)} />}
              </div>
            </div>
          </div>


          <div className={styles.Topic}>TIMELINE</div>
          <div className={styles.SettingsSection}>
            <div className={styles.SettingWrapper}>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div className={styles.Name} style={{ flex: 2 }}>
                  Starting Time
                </div>
                <input className={styles.SelectBox} type="time" onChange={(event) => this.changeConfigHandler('timelineTime', event.target.value)} value={timelineTime} />
              </div>
            </div>
          </div>
        </div>

        <Modal className={styles.Modal} show={this.state.isSavedModalShowed} modalClosed={() => this.setState({ isSavedModalShowed: false })}>
          <img src={Saved} style={{ width: 120 }} />
          <div className={styles.ModalText}>All configs have been saved.</div>
          <div className={styles.ButtonWrapper}>
            <Button type="button" color="green" name="OK" click={() => this.setState({ isSavedModalShowed: false })} noMargin />
          </div>
        </Modal>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { moduleConfigs } = state.module;
  return {
    currentOrg, moduleConfigs
  };
};

export default connect(mapStateToProps, { getModuleConfigs, postModuleConfigJson })(PcsSettings);
