export const GET_ADVANCE_PRODUCTION_BATCHS_START = 'GET_ADVANCE_PRODUCTION_BATCHS_START';
export const GET_ADVANCE_PRODUCTION_BATCHS = 'GET_ADVANCE_PRODUCTION_BATCHS';
export const GET_ADVANCE_PRODUCTION_BATCHS_SUCCESS = 'GET_ADVANCE_PRODUCTION_BATCHS_SUCCESS';
export const GET_ADVANCE_PRODUCTION_BATCHS_FAIL = 'GET_ADVANCE_PRODUCTION_BATCHS_FAIL';

export const ADD_ADVANCE_PRODUCTION_BATCH_START = 'ADD_ADVANCE_PRODUCTION_BATCH_START';
export const ADD_ADVANCE_PRODUCTION_BATCH = 'ADD_ADVANCE_PRODUCTION_BATCH';
export const ADD_ADVANCE_PRODUCTION_BATCH_SUCCESS = 'ADD_ADVANCE_PRODUCTION_BATCH_SUCCESS';
export const ADD_ADVANCE_PRODUCTION_BATCH_FAIL = 'ADD_ADVANCE_PRODUCTION_BATCH_FAIL';

export const EDIT_ADVANCE_PRODUCTION_BATCH_START = 'EDIT_ADVANCE_PRODUCTION_BATCH_START';
export const EDIT_ADVANCE_PRODUCTION_BATCH = 'EDIT_ADVANCE_PRODUCTION_BATCH';
export const EDIT_ADVANCE_PRODUCTION_BATCH_SUCCESS = 'EDIT_ADVANCE_PRODUCTION_BATCH_SUCCESS';
export const EDIT_ADVANCE_PRODUCTION_BATCH_FAIL = 'EDIT_ADVANCE_PRODUCTION_BATCH_FAIL';

export const DELETE_ADVANCE_PRODUCTION_BATCH_START = 'DELETE_ADVANCE_PRODUCTION_BATCH_START';
export const DELETE_ADVANCE_PRODUCTION_BATCH = 'DELETE_ADVANCE_PRODUCTION_BATCH';
export const DELETE_ADVANCE_PRODUCTION_BATCH_SUCCESS = 'DELETE_ADVANCE_PRODUCTION_BATCH_SUCCESS';
export const DELETE_ADVANCE_PRODUCTION_BATCH_FAIL = 'DELETE_ADVANCE_PRODUCTION_BATCH_FAIL';

export const MAP_PLANS_TO_BATCH_START = 'MAP_PLANS_TO_BATCH_START';
export const MAP_PLANS_TO_BATCH = 'MAP_PLANS_TO_BATCH';
export const MAP_PLANS_TO_BATCH_SUCCESS = 'MAP_PLANS_TO_BATCH_SUCCESS';
export const MAP_PLANS_TO_BATCH_FAIL = 'MAP_PLANS_TO_BATCH_FAIL';

export const GET_BATCH_PROGRESS_START = 'GET_BATCH_PROGRESS_START';
export const GET_BATCH_PROGRESS = 'GET_BATCH_PROGRESS';
export const GET_BATCH_PROGRESS_SUCCESS = 'GET_BATCH_PROGRESS_SUCCESS';
export const GET_BATCH_PROGRESS_FAIL = 'GET_BATCH_PROGRESS_FAIL';