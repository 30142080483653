import React, { Component } from "react";
import { connect } from "react-redux";
import MultipleSelect from 'react-select';

import { updateObject, checkValidity } from "../../../../state/utils";
import styles from "./AddNewRule.module.scss";
import EmailIcon from "../../../../assets/icons/email.svg";
import mobile_numbersIcon from "../../../../assets/icons/message.svg";
import LineIcon from "../../../../assets/icons/line.svg";
import InfoIcon from "../../../../assets/icons/Rule/info.svg";
import CloseIcon from "../../../../assets/icons/cancel-circle.svg";
import Input from "../../UI/Input/Input";
import TagInput from "../../UI/TagInput/TagInput";
import Button from "../../UI/Button/Button";
import Select from "../../UI/Select/Select";
import RuleCommand from "../RuleCommand/RuleCommand";
import RuleCondition from "../RuleCondition/RuleCondition";
// import ToolTip from "../../UI/ToolTip/Tooltip";
// import InFormationIcon from "../../../../assets/icons/Rule/info.svg";
import InstructionModal from "../InstructionModal/InstructionModal";

import {
  addRule,
  clearRuleError,
  editRule,
} from "../../../../state/ducks/Rule/actions";
import { DELETE_RULE_SUCCESS } from "../../../../state/ducks/Rule/types";
// import DeleteIcon from "../../../../assets/icons/Rule/delete-circle.svg";

const colourStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "#1665d8",
  }),
  multiValueLabel: base => ({
    ...base,
    color: "white",
  })
};

const DEFAULT_RULE_FORM = {
  name: {
    value: "",
    valid: false,
    touched: false,
    validation: {
      required: true,
    },
  },
  description: {
    value: "",
    valid: true,
    touched: true,
    validation: {
      required: false,
    },
  },
  type: {
    value: "-1",
    valid: false,
    touched: false,
    validation: {
      required: true,
    },
  },
  level: {
    value: 4,
    valid: true,
    touched: true,
    validation: {
      required: true,
    },
  },
  calculation_method: {
    value: "-1",
    valid: true,
    touched: true,
    validation: {
      required: false,
    },
  },
  sampling_size: {
    value: "0",
    valid: true,
    touched: true,
    validation: {
      required: false,
    },
  },
  emails: {
    value: "",
    valid: true,
    touched: true,
    validation: {
      isEmail: true,
    },
  },
  mobile_numbers: {
    value: "",
    valid: true,
    touched: true,
    validation: {
      isMobileNumber: true,
      required: false
    },
  },
  line_token: {
    value: "",
    valid: true,
    touched: true,
    validation: {
      required: false,
    },
  },
  true_message: {
    value: "",
    valid: true,
    touched: true,
    validation: {
      required: false,
    },
  },
  false_message: {
    value: "",
    valid: true,
    touched: true,
    validation: {
      required: false,
    },
  },
  hook: {
    value: "",
    valid: true,
    touched: true,
    validation: {
      required: false,
    },
  },
  machine_ids: {
    value: "",
    valid: true,
    touched: true,
    validation: {
      required: false,
    },
  }
};

const DEFAULT_CONDITION_FORM = {
  device_socket_id: { value: "-1", valid: false, touched: false },
  device_socket: { value: "", valid: false, touched: false },
  device_secret: { value: "", valid: false, touched: false },
  operation: { value: "and", valid: false, touched: false },
  condition: { value: "-1", valid: false, touched: false },
  value: { value: "", valid: false, touched: false },
};

const DEFAULT_COMMAND_FORM = {
  device_socket_id: { value: "-1", valid: false, touched: false },
  device_socket: { value: "", valid: false, touched: false },
  device_secret: { value: "", valid: false, touched: false },
  value: { value: "", valid: false, touched: false },
};

const DEFAULT_COMMAND = {
  device_secret: "",
  device_socket: -1,
  value: "",
};

const DEFAULT_CONDITION = {
  condition: ">",
  device_secret: "",
  device_socket: -1,
  operation: "and",
  value: "",
};

const RULE_TYPE = [
  { name: "Alarm", id: "1" },
  { name: "Command", id: "2" },
  // { name: 'Cloud command', id: '3' },
  { name: "Alarm and Command", id: "4" },
  // { name: 'Alarm and Cloud command', id: '5' }
];

const CALCULATION_METHOD = [
  { name: "Average", id: "avg" },
  { name: "Mean", id: "mean" },
  { name: "Mode", id: "mode" },
  { name: "Max", id: "max" },
  { name: "Min", id: "min" },
];

const LEVELS = [
  { name: "Info", id: 4 },
  { name: "Alert", id: 3 },
  { name: "Warning", id: 2 },
  { name: "Critical", id: 1 },
  { name: "Breakdown", id: 0 }
];

class AddNewRule extends Component {
  state = {
    formIsValid: false,
    ruleForm: DEFAULT_RULE_FORM,
    allCommandsForm: [],
    allConditionsForm: [],
    commands: [],
    conditions: [],
    allDeviceOptions: [],
    onlyOutputDeviceOptions: [],
    deleteConditionsId: [],
    deleteCommandsId: [],
    addConditionError: false,
    addCommandError: false,
    isInstructionModalOpened: false,
    selectedMachines: [],
    stillShowingErrorOnMobiles: false,
    stillShowingErrorOnEmails: false
  };

  componentDidMount() {
    if (this.props.editingRule) {
      this.setupForm(this.props.editingRule);
      if (this.props.editingRule.commands) {
        this.setupCommands(this.props.editingRule.commands);
      }
      if (this.props.editingRule.conditions) {
        this.setupConditions(this.props.editingRule.conditions);
      }
    }
    this.setDefaultDeviceOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAdding && prevProps.isAdding !== this.props.isAdding) {
      if (this.props.error) {
        this.props.clearRuleError();
      }
      this.onClearFields();
      this.clearAllCommands();
      this.clearAllConditions();
      this.clearDeleteCommandsAndConditionsId();
      this.onClearConditionError();
      this.onClearCommandError();
    } else if (this.props.editingRule && this.props.editingRule !== prevProps.editingRule) {
      if (this.props.error) {
        this.props.clearRuleError();
      }
      this.onClearFields();
      this.clearAllCommands();
      this.clearAllConditions();
      this.clearDeleteCommandsAndConditionsId();
      this.onClearConditionError();
      this.onClearCommandError();
      this.setupForm(this.props.editingRule);
      if (this.props.editingRule.commands) {
        this.setupCommands(this.props.editingRule.commands);
      }
      if (this.props.editingRule.conditions) {
        this.setupConditions(this.props.editingRule.conditions);
      }
    } else if (prevProps.addedRule !== this.props.addedRule || prevProps.updatedRule !== this.props.updatedRule ||
      (this.props.type !== prevProps.type && this.props.type === DELETE_RULE_SUCCESS)) {
      this.props.closed();
      this.onClearFields();
      if (this.props.error) {
        this.props.clearRuleError();
      }
    }
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.ruleForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement;
      if (
        inputIdentifier === "emails" ||
        inputIdentifier === "mobile_numbers"
      ) {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier] ? form[inputIdentifier].join() : "",
          valid: true,
          touched: true,
          validation:
            inputIdentifier === "emails"
              ? { isEmail: true }
              : inputIdentifier === "mobile_numbers"
                ? { isMobileNumber: true }
                : { required: true },
        });
      } else if (inputIdentifier === "line_token") {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier] ? form[inputIdentifier] : "",
          valid: true,
          touched: true,
          validation: {
            required: true,
          },
        });
      } else if (inputIdentifier === "calculation_method") {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier] ? form[inputIdentifier] : "-1",
          valid: true,
          touched: true,
          validation: {
            required: true,
          },
        });
      } else if (inputIdentifier === "sampling_size") {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier] ? form[inputIdentifier] : "0",
          valid: true,
          touched: true,
          validation: {
            required: true,
          },
        });
      } else if (inputIdentifier === "machine_ids") {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier] ? form[inputIdentifier].join(',') : "",
          valid: true,
          touched: true,
        });
        if (form['machine_ids'] && this.props.machines) {
          const selectedMachines = [];
          form.machine_ids.forEach(mid => {
            const found = this.props.machines.find(m => m.id === mid);
            if (found) {
              selectedMachines.push({
                id: found.id,
                label: found.name
              });
            }
          });
          this.setState({ selectedMachines });
        }
      } else if (inputIdentifier === "level") {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier],
          valid: true,
          touched: true,
        })
      } else {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier] ? form[inputIdentifier] : "",
          valid: true,
          touched: true,
        });
      }
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement,
      });
    }
    this.setState({ ruleForm: updatedForm, formIsValid: true });
  };

  setupCommands = (commands) => {
    let newAllCommandsForm = [];
    let newCommands = [];
    commands.forEach((com) => {
      newAllCommandsForm.push({
        form: null,
        isAdding: false,
        commandId: com.id,
        isValid: true,
      });
      newCommands.push({
        device_secret: com.device_secret,
        device_socket: com.device_socket,
        value: com.value,
      });
    });
    this.setState({
      allCommandsForm: newAllCommandsForm,
      commands: newCommands,
    });
  };

  setupConditions = (conditions) => {
    let newAllConditionsForm = [];
    let newConditions = [];
    conditions.forEach((con) => {
      newAllConditionsForm.push({
        form: null,
        isAdding: false,
        conditionId: con.id,
        isValid: true,
      });
      newConditions.push({
        condition: con.condition,
        device_secret: con.device_secret,
        device_socket: con.device_socket,
        operation: con.operation,
        value: con.value,
      });
    });
    this.setState({
      allConditionsForm: newAllConditionsForm,
      conditions: newConditions,
    });
  };

  onClearFields = () => {
    this.setState({ ruleForm: DEFAULT_RULE_FORM, formIsValid: false });
  };

  clearAllCommands = () => {
    this.setState({ allCommandsForm: [], commands: [] });
  };

  clearAllConditions = () => {
    this.setState({ allConditionsForm: [], conditions: [] });
  };

  clearDeleteCommandsAndConditionsId = () => {
    this.setState({ deleteCommandsId: [], deleteConditionsId: [] });
  };

  // onClearCommandAndConditionFields = () => {
  //   const newAllConditionsForm = [...this.state.allConditionsForm];
  //   let commands = [...this.state.commands];
  //   let conditions = [...this.state.conditions];
  //   newAllConditionsForm.map((con, index) => {
  //     if (index !== 0) {
  //       con.form = DEFAULT_CONDITION_FORM;
  //       con.isValid = false;
  //       conditions[index] = DEFAULT_CONDITION;
  //     }
  //   });
  //   const newAllCommandsForm = [...this.state.allCommandsForm];
  //   newAllCommandsForm.map((con, index) => {
  //     con.form = DEFAULT_CONDITION_FORM;
  //     con.isValid = this.state.ruleForm.type.value === 1 ? true : false;
  //     commands[index] = DEFAULT_COMMAND;
  //   });

  //   this.setState({ allConditionsForm: newAllConditionsForm, allCommandsForm: newAllCommandsForm, conditions, commands });
  // }

  addNewCommandHandler = () => {
    if (this.checkCommandValid(null)) {
      let updatedForm = [...this.state.allCommandsForm];
      let newCommands = [...this.state.commands];
      updatedForm.push({
        commandId: null,
        form: DEFAULT_COMMAND_FORM,
        isAdding: true,
        isValid: false,
      });
      newCommands.push(DEFAULT_COMMAND);
      this.setState({
        allCommandsForm: updatedForm,
        commands: newCommands,
        addCommandError: false,
      });
    } else {
      this.setState({ addCommandError: true });
    }
  };

  addNewConditionHandler = () => {
    if (this.checkConditionValid(null)) {
      let updatedForm = [...this.state.allConditionsForm];
      let newConditions = [...this.state.conditions];
      updatedForm.push({
        conditionId: null,
        form: DEFAULT_CONDITION_FORM,
        isAdding: true,
        isValid: false,
      });
      newConditions.push(DEFAULT_CONDITION);
      this.setState({
        allConditionsForm: updatedForm,
        conditions: newConditions,
        addConditionError: false,
      });
    } else {
      this.setState({ addConditionError: true });
    }
  };

  onRemoveConditionHandler = (index) => {
    let newAllConditionsForm = [...this.state.allConditionsForm];
    let newConditions = [...this.state.conditions];
    let newDeleteConditionsId = [...this.state.deleteConditionsId];
    if (this.state.allConditionsForm[index].conditionId) {
      newDeleteConditionsId.push(
        this.state.allConditionsForm[index].conditionId
      );
    }
    newAllConditionsForm.splice(index, 1);
    newConditions.splice(index, 1);
    if (this.checkConditionValid(newAllConditionsForm)) {
      this.onClearConditionError();
    }
    this.setState({
      allConditionsForm: newAllConditionsForm,
      conditions: newConditions,
      deleteConditionsId: newDeleteConditionsId,
    });
  };

  onRemoveCommandHandler = (index) => {
    let newAllCommandsForm = [...this.state.allCommandsForm];
    let newCommands = [...this.state.commands];
    let newDeleteCommandsId = [...this.state.deleteCommandsId];
    if (this.state.allCommandsForm[index].commandId) {
      newDeleteCommandsId.push(this.state.allCommandsForm[index].commandId);
    }
    newAllCommandsForm.splice(index, 1);
    newCommands.splice(index, 1);
    if (this.checkCommandValid(newAllCommandsForm)) {
      this.onClearCommandError();
    }
    this.setState({
      allCommandsForm: newAllCommandsForm,
      commands: newCommands,
      deleteCommandsId: newDeleteCommandsId,
    });
  };

  instructionModalClickHandler = () => {
    this.setState((prevState) => {
      return { isInstructionModalOpened: !prevState.isInstructionModalOpened };
    });
  };

  // setDeviceOption = (currentDevice) => {
  //   if (this.props.devices) {
  //     let newDeviceOptions = [];
  //     let filterDevice = [...this.props.devices];
  //     if (currentDevice) {
  //       let index = filterDevice.findIndex(device => device.secret === currentDevice);
  //       filterDevice = [filterDevice[index]];
  //     }
  //     filterDevice.forEach(device => {
  //       if (device) {
  //         device.device_sockets.forEach(socket => {
  //           newDeviceOptions.push({ id: socket.id, name: socket.name + ' (' + device.name + ')', secret: socket.device_secret, socket: socket.socket });
  //         });
  //       }
  //     });
  //     this.setState({ deviceOptions: newDeviceOptions });
  //   }
  // }

  setDefaultDeviceOptions = () => {
    if (this.props.devices) {
      let allDeviceOptions = [],
        onlyOutputDeviceOptions = [];
      let devices = [...this.props.devices];
      devices.forEach((device) => {
        device.device_sockets.forEach((socket) => {
          allDeviceOptions.push({
            id: socket.id,
            name: socket.name + " (" + device.name + ")",
            secret: socket.device_secret,
            socket: socket.socket,
          });
          if (socket.can_control)
            onlyOutputDeviceOptions.push({
              id: socket.id,
              name: socket.name + " (" + device.name + ")",
              secret: socket.device_secret,
              socket: socket.socket,
            });
        });
      });
      this.setState({ allDeviceOptions, onlyOutputDeviceOptions });
    }
  };

  handleCommandChange = (index, form, isValid) => {
    let updatedForm = [...this.state.allCommandsForm];
    let newCommands = [...this.state.commands];
    updatedForm[index].form = form;
    updatedForm[index].isValid = isValid;
    newCommands[index] = {
      device_secret: form.device_secret.value,
      device_socket: form.device_socket.value,
      value: form.value.value,
    };
    if (this.checkConditionValid(updatedForm)) {
      this.onClearCommandError();
    }
    this.setState({ allCommandsForm: updatedForm, commands: newCommands });
  };

  handleConditionChange = (index, form, isValid) => {
    let updatedForm = [...this.state.allConditionsForm];
    let newConditions = [...this.state.conditions];
    updatedForm[index].form = form;
    updatedForm[index].isValid = isValid;
    newConditions[index] = {
      condition: form.condition.value,
      device_secret: form.device_secret.value,
      device_socket: +form.device_socket.value,
      operation: form.operation.value,
      value: form.value.value,
    };
    if (this.checkConditionValid(updatedForm)) {
      this.onClearConditionError();
    }
    this.setState({
      allConditionsForm: updatedForm,
      conditions: newConditions,
    });
  };

  handleChange = (event) => {
    let updatedElement;
    if (event.target.name === "true_message" || event.target.name === "false_message") {
      updatedElement = updateObject(this.state.ruleForm[event.target.name], {
        value:
          event.target.value.length <= 70
            ? event.target.value
            : this.state.ruleForm[event.target.name].value,
        valid: true,
        touched: true,
      });
    } else if (event.target.name === "sampling_size") {
      updatedElement = updateObject(this.state.ruleForm[event.target.name], {
        value: event.target.value <= 0 ? 0 : event.target.value,
        valid: true,
        touched: true
      });
    } else {
      updatedElement = updateObject(this.state.ruleForm[event.target.name], {
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          this.state.ruleForm[event.target.name].validation
        ),
        touched: true,
      });
    }

    let updatedForm;
    if (event.target.name === "sampling_size") {
      let updatedCalculationMethod = updateObject(
        this.state.ruleForm["calculation_method"],
        {
          touch: this.state.ruleForm["calculation_method"].value === "-1" && event.target.value !== "0" ? false : true,
          valid: this.state.ruleForm["calculation_method"].value === "-1" && event.target.value > '0' ? false : true,
          value: event.target.value !== "0" ? this.state.ruleForm["calculation_method"].value : "-1",
          validation: {
            require: this.state.ruleForm["calculation_method"].value === "-1" && event.target.value !== "0" ? true : false,
          },
        }
      );
      updatedForm = updateObject(this.state.ruleForm, {
        [event.target.name]: updatedElement,
        calculation_method: updatedCalculationMethod,
      });
    } else {
      updatedForm = updateObject(this.state.ruleForm, {
        [event.target.name]: updatedElement,
      });
    }

    let formIsValid = true;
    // for (let inputIdentifier in updatedForm) {
    //   formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    // }

    // new check! : only name and sampling/method, commands and conditions will be checked under the submit button's cond.
    formIsValid = updatedForm.name.valid && updatedForm.sampling_size.valid && updatedForm.calculation_method.valid;
    this.setState({ ruleForm: updatedForm, formIsValid });
  };

  handleTypeChange = (event) => {
    let newRuleForm = { ...this.state.ruleForm };
    let updatedElement = updateObject(newRuleForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(
        event.target.value,
        newRuleForm[event.target.name].validation
      ),
      touched: true,
    });

    const updatedForm = updateObject(this.state.ruleForm, {
      [event.target.name]: updatedElement
    });
    
    if (event.target.name === 'type') {
      if (this.state.allConditionsForm.length === 0) {
        this.addNewConditionHandler();
      }
      if (+event.target.value !== 1) {
        if (this.state.allCommandsForm.length === 0) {
          this.addNewCommandHandler();
        }
      } else {
        this.clearAllCommands();
      }
    }

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ ruleForm: updatedForm, formIsValid });
  };

  onMachinesChange = (value) => {
    let newRuleForm = { ...this.state.ruleForm };
    let updatedElement = updateObject(newRuleForm["machine_ids"], {
      value: !value ? '' : value.map((x) => x.id).join(','),
      valid: true,
      touched: true,
    });

    const updatedForm = updateObject(this.state.ruleForm, {
      machine_ids: updatedElement
    });
    this.setState({ ruleForm: updatedForm, selectedMachines: value });
  }

  onSubmitRuleHandler = (event) => {
    event.preventDefault();
    if (this.props.isAdding) {
      this.props.addRule(
        this.props.currentOrg,
        this.state.ruleForm,
        this.state.allConditionsForm ? this.state.allConditionsForm : null,
        this.state.allCommandsForm ? this.state.allCommandsForm : null
      );
    } else {
      this.props.editRule(
        this.props.editingRule.id,
        this.state.ruleForm,
        this.state.allConditionsForm ? this.state.allConditionsForm : null,
        this.state.allCommandsForm
          ? this.state.ruleForm.type.value === 1
            ? null
            : this.state.allCommandsForm
          : null,
        this.state.deleteConditionsId ? this.state.deleteConditionsId : null,
        this.state.deleteCommandsId ? this.state.deleteCommandsId : null
      );
    }
  };

  onClearConditionError = () => {
    this.setState({ addConditionError: false });
  };

  onClearCommandError = () => {
    this.setState({ addCommandError: false });
  };

  checkCommandValid = (form) => {
    let valid = true;
    if (form) {
      form.forEach((form) => {
        valid = form.isValid && valid;
      });
    } else {
      if (this.state.allCommandsForm) {
        this.state.allCommandsForm.forEach((form) => {
          valid = form.isValid && valid;
        });
      }
    }
    return valid;
  };

  checkConditionValid = (form) => {
    let valid = true;
    if (form) {
      form.forEach((form) => {
        valid = form.isValid && valid;
      });
    } else {
      if (this.state.allConditionsForm) {
        this.state.allConditionsForm.forEach((form) => {
          valid = form.isValid && valid;
        });
      }
    }
    return valid;
  };

  checkSubmitButton = () => {
    let valid = this.state.formIsValid;
    // formIsValid :: contains name, sampling and method
    // others will check on commands amd conditions.
    if (this.state.allConditionsForm) {
      valid = this.checkConditionValid() && valid;
    } else {
      // if no sensor on conditions = NO!
      return false;
    }

    if (+this.state.ruleForm.type.value !== 2) {
      if (this.state.allCommandsForm && this.state.allCommandsForm.length) {
        valid = this.checkCommandValid() && valid;
      }
      const { emails, mobile_numbers, line_token, hook } = this.state.ruleForm;
      if (emails.value !== '' || mobile_numbers.value !== '' || line_token.value !== '' || hook.value !== '') {
        valid = valid && !this.state.stillShowingErrorOnEmails && !this.state.stillShowingErrorOnMobiles;
      } else {
        valid = false;
      }
    }

    return !valid;
  };

  render() {
    const {
      name,
      description,
      level,
      sampling_size,
      calculation_method,
      type,
      emails,
      mobile_numbers,
      line_token,
      hook,
      true_message,
      false_message
    } = this.state.ruleForm;

    const machinesOption = [];
    if (this.props.machines) {
      this.props.machines.forEach(machine => {
        machinesOption.push({
          id: machine.id,
          label: machine.name
        });
      });
    }

    return (
      <form
        className={styles.AddNewRuleWrapper}
        onSubmit={this.onSubmitRuleHandler}
      >
        <div className={styles.AddFormWrapper}>
          <img
            src={CloseIcon}
            className={styles.CloseIcon}
            alt="close"
            onClick={this.props.closed}
          />
          <div className={styles.Title}>
            {this.props.isAdding ? "Add New Event" : "Edit Event"}
          </div>
          <div className={styles.Description}>
            {this.props.isAdding
              ? "Please fill the information to create a new event"
              : "Please edit your event details "}
          </div>
          {this.props.error &&
            this.props.error.field !== "mobile_numbers" &&
            this.props.error.field !== "hook" && (
              <div className="ErrorText">{this.props.error.text}</div>
            )}

          <hr className={styles.Divider} />

          <Input
            label="name of the event"
            name="name"
            type="text"
            placeholder="Please name your event"
            value={name.value}
            autoComplete="off"
            onChange={this.handleChange}
            error={`${name.touched && !name.valid ? "Event Name is required" : ""
              }`}
            required
          />
          <div className={styles.Flex}>
            <div className={styles.LeftTextField}>
              <Input
                label="details"
                name="description"
                type="text"
                placeholder="What is this Event about?"
                overidestyleformgroup={{ marginBottom: 0 }}
                value={description.value}
                autoComplete="off"
                onChange={this.handleChange}
              />
            </div>
            <div className={styles.RightTextField}>
              <Select
                label="Level"
                name="level"
                placeholder="Please choose level"
                options={LEVELS}
                value={level.value}
                autoComplete="off"
                overidestyleformgroup={{ marginBottom: 15 }}
                onChange={this.handleTypeChange}
              />
            </div>
          </div>


          <hr className={styles.Divider} />
          <div className={styles.LabelGroup}>
            <div className={styles.Label}>choose type *</div>
            <div className={styles.Info}>
              {/* <ToolTip showInfo title="Type Information"><img src={InFormationIcon} alt="Information Icon" /></ToolTip> */}
            </div>
          </div>
          <Select
            name="type"
            placeholder="Please choose type"
            options={RULE_TYPE}
            value={type.value}
            autoComplete="off"
            overidestyleformgroup={{ marginBottom: 15 }}
            onChange={this.handleTypeChange}
          />
          {type.value !== "-1" ? (
            <React.Fragment>
              <div className={styles.Flex}>
                <div className={styles.LeftTextField}>
                  <div className={styles.LabelGroup}>
                    <div className={styles.Label}>sampling size *</div>
                    <div className={styles.Info}>
                      {/* <ToolTip title="Sampling Size Information"><img src={InFormationIcon} alt="Information Icon" /></ToolTip> */}
                    </div>
                  </div>
                  <Input
                    name="sampling_size"
                    type="number"
                    placeholder="Sampling size (second)"
                    value={sampling_size.value}
                    onChange={this.handleChange}
                    overidestyleformgroup={{ marginBottom: 15 }}
                  />
                </div>
                <div className={styles.RightTextField}>
                  <div className={styles.LabelGroup}>
                    <div className={styles.Label}>calculation method</div>
                    <div className={styles.Info}>
                    </div>
                  </div>
                  <Select
                    name="calculation_method"
                    placeholder="Select a calculation method"
                    options={CALCULATION_METHOD}
                    value={calculation_method.value}
                    autoComplete="off"
                    overidestyleformgroup={{ marginBottom: 15 }}
                    onChange={this.handleChange}
                    disabled={+sampling_size.value <= 0 ? true : false}
                  />
                </div>
              </div>
              <RuleCondition
                allConditionsForm={this.state.allConditionsForm}
                conditions={this.state.conditions}
                deviceOptions={this.state.allDeviceOptions}
                addNewCondition={this.addNewConditionHandler}
                conditionChange={this.handleConditionChange}
                removeCondition={this.onRemoveConditionHandler}
              />
              {this.state.addConditionError && (
                <div className={styles.ErrorMessage}>
                  Please fill out this form before add!
                </div>
              )}
              {+type.value !== 1 ? (
                <React.Fragment>
                  <RuleCommand
                    allCommandsForm={this.state.allCommandsForm}
                    commands={this.state.commands}
                    deviceOptions={this.state.onlyOutputDeviceOptions}
                    addNewCommand={this.addNewCommandHandler}
                    commandChange={this.handleCommandChange}
                    removeCommand={this.onRemoveCommandHandler}
                  />
                  {this.state.addCommandError && (
                    <div className={styles.ErrorMessage}>
                      Please fill out this form before add!
                    </div>
                  )}
                </React.Fragment>
              ) : null}
              {+type.value === 1 || +type.value === 4 ? (
                <>
                  <hr className={styles.Divider} />
                  <div className={styles.Topic}>Please fill at least one of Emails/Mobile numbers/Line Token/Hook *</div>
                  <div className={styles.Flex}>
                    <div className={styles.LeftTextField}>
                      <TagInput
                        icon={EmailIcon}
                        name="emails"
                        type="text"
                        placeholder="email@technimal.co.th, Add by press spacebar"
                        overidestyleformgroup={{ marginBottom: 0 }}
                        value={emails.value}
                        validation={emails.validation}
                        onChange={this.handleChange}
                        error={`${"Emails must be in the correct format, 'hello@technimal.co.th'"}`}
                        setStillShowingError={(value) => { this.setState({ stillShowingErrorOnMobiles: value }) }}
                      />
                    </div>
                    <div className={styles.LeftTextField}>
                      <TagInput
                        icon={mobile_numbersIcon}
                        name="mobile_numbers"
                        type="text"
                        placeholder="0891231234, Add by press spacebar"
                        overidestyleformgroup={{ marginBottom: 0 }}
                        value={mobile_numbers.value}
                        validation={mobile_numbers.validation}
                        onChange={this.handleChange}
                        error={"Phone No. must be in the correct format, '0801234567'"}
                        setStillShowingError={(value) => { this.setState({ stillShowingErrorOnEmails: value }) }}
                      />
                    </div>
                    <div className={styles.RightTextField}>
                      <Input
                        suffixclick={this.instructionModalClickHandler}
                        // suffixClick={"text"}
                        icon={LineIcon}
                        name="line_token"
                        type="text"
                        placeholder="LINE Token"
                        overidestyleformgroup={{ marginBottom: 0 }}
                        value={line_token.value}
                        autoComplete="off"
                        onChange={this.handleChange}
                        suffixicon={InfoIcon}
                        suffix="info"
                      // onsuffixicclick={0}
                      // error={`${emails.touched && !emails.valid ? 'Email is required and in the correct format' : ''}`}
                      />
                    </div>
                  </div>
                  <div className={styles.Flex} style={{ marginTop: 16 }}>
                    <div className={styles.LeftTextField}>
                      <Input
                        label="Message when conditions are TRUE"
                        name="true_message"
                        type="textarea"
                        rows="2"
                        // placeholder="Emails, Separate by comma (,) *"
                        overidestyleformgroup={{ marginBottom: 0 }}
                        value={true_message.value}
                        autoComplete="off"
                        onChange={this.handleChange}
                      />
                      <div className={styles.countingCharacters}>
                        {true_message.value.length} / 70
                      </div>
                    </div>
                    <div className={styles.RightTextField}>
                      <Input
                        label="Message when conditions are FALSE"
                        name="false_message"
                        type="textarea"
                        rows="2"
                        // placeholder="+66991231234, Separate by comma (,) *"
                        overidestyleformgroup={{ marginBottom: 0 }}
                        value={false_message.value}
                        autoComplete="off"
                        onChange={this.handleChange}
                      />
                      <div className={styles.countingCharacters}>
                        {false_message.value.length} / 70
                      </div>
                    </div>
                  </div>
                  <hr className={styles.Divider} />
                  <Input
                    label="hook"
                    name="hook"
                    type="text"
                    placeholder="https://www.technimal.co.th/api/"
                    value={hook.value}
                    autoComplete="off"
                    onChange={this.handleChange}
                    error={
                      this.props.error && this.props.error.field === "hook"
                        ? this.props.error.text
                        : null
                    }
                  />
                  <div className={styles.LabelGroup} style={{ flexDirection: 'column' }}>
                    <label className={styles.Label} style={{ marginBottom: '0.7rem' }}>DISPLAY ON</label>
                    <MultipleSelect isMulti isSearchable closeMenuOnSelect={false}
                      options={machinesOption}
                      styles={colourStyles}
                      placeholder="You can select many machines (it will pop the event up when it meets the condition."
                      value={this.state.selectedMachines}
                      onChange={this.onMachinesChange}
                      getOptionValue={opt => opt.id}
                    />
                  </div>
                </>
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
        <div className={styles.ButtonsWrapper}>
          {this.props.isAdding ? (
            <React.Fragment>
              <div style={{ width: 112 }}>
                <Button
                  type="button"
                  name="Cancel"
                  color="borderred"
                  noMargin
                  click={this.props.closed}
                />
              </div>
              <div style={{ width: 200 }}>
                <Button
                  type="submit"
                  name={this.props.loading ? "Loading..." : "Done"}
                  color="primary"
                  disabled={this.checkSubmitButton()}
                  loading={this.props.loading}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div style={{ width: 112 }}>
                <Button
                  type="button"
                  name="Delete"
                  color="red"
                  noMargin
                  click={this.props.toggleDeleteModal}
                />
              </div>
              <div style={{ width: 200 }}>
                <Button
                  type="submit"
                  name={this.props.loading ? "Loading..." : "Save"}
                  color="green"
                  disabled={this.checkSubmitButton()}
                  loading={this.props.loading}
                />
              </div>
            </React.Fragment>
          )}
          <InstructionModal
            open={this.state.isInstructionModalOpened}
            modalClosed={this.instructionModalClickHandler}
          />
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { devices } = state.devices;
  const { machines } = state.machine;
  const { type, loading, result, addedRule, updatedRule, error } = state.rule;
  return {
    currentOrg,
    devices,
    type,
    loading,
    result,
    addedRule,
    updatedRule,
    error,
    machines
  };
};

export default connect(mapStateToProps, { addRule, clearRuleError, editRule })(AddNewRule);
