import React, { Component } from 'react';
import moment from 'moment';

import styles from './AssetList.module.scss';
// import ActiveAssetIcon from '../../../../assets/icons/realtime.svg';
import AssetIcon from '../../../../assets/icons/realtime-gray.svg';
import GreenIcon from '../../../../assets/icons/AssetTracking/green-dot.svg';
import RedIcon from '../../../../assets/icons/AssetTracking/red-dot.svg';
import MoreIcon from '../../../../assets/icons/more.svg';

class AssetList extends Component {
  render() {
    let now = new Date();
    return (
      <div className={styles.AssetList}>
        <div className={styles.TableWrapper}>
          <table>
            <thead>
              <tr>
                <th>ASSET</th>
                <th>STATUS</th>
                <th>LOCATION</th>
                <th>LAST ACTIVE</th>
                <th>DORMANT (DAYS)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.data ? this.props.data.map(list => {
                  return (
                    <tr key={list.asset}>
                      <td>
                        <div style={{ display: 'flex' }}>
                          <img src={AssetIcon} alt="Asset Icon" style={{ marginRight: 10 }} />
                          {list.asset}
                        </div>
                      </td>
                      <td>
                        <div style={{ display: 'flex' }}>
                          {list.status ? <img style={{ marginLeft: 20 }} src={GreenIcon} alt="Status Icon" />
                            : <img style={{ marginLeft: 20 }} src={RedIcon} alt="Status Icon" />}
                        </div>
                      </td>
                      <td>{list.location}</td>
                      <td>{moment(list.last_active).format("DD/MM/YYYY")}</td>
                      <td>{((now.getTime() - list.last_active) / 1000 / 60 / 60 / 24).toFixed(2)}</td>
                      <td><img src={MoreIcon} alt="More Icon" /></td>
                    </tr>
                  );
                }) : null
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default AssetList;
