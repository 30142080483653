import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './MainLayout.module.scss';
import Topbar from '../../views/components/Navigation/Topbar/Topbar';
import Sidebar from '../../views/components/Navigation/Sidebar/Sidebar';
import PrivacyModal from '../../views/components/UI/Privacy/PrivacyModal';

import { getNotificationTopbar } from '../../state/ducks/Notification/actions';

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPrivacyModalOpened: false,
      offsetHeight: 0,
      notificationClosed: false
    };

    this.notifTopbarRef = React.createRef();
  }

  componentDidMount() {
    this.props.getNotificationTopbar();
    const expiredDate = localStorage.getItem("Technimal_sight_privacy");
    if (new Date().getTime() > expiredDate) {
      this.setState({ isPrivacyModalOpened: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.notificationTopbar && prevProps.notificationTopbar !== this.props.notificationTopbar) {
      if (this.notifTopbarRef && this.notifTopbarRef.current)
        this.setState({ offsetHeight: this.notifTopbarRef.current.clientHeight });
    }
  }

  acceptPrivacyHandler = (e, answer) => {
    e.preventDefault()
    if (answer) {
      this.setState({ isPrivacyModalOpened: false });
    }
  }

  onCloseNotificationTopbarHandler = () => {
    this.setState({ notificationClosed: true, offsetHeight: 0 });
  }

  render() {
    const { isMobile, isAdminMode, onToggleAdminMode } = this.props;

    let mainContentStyle = this.props.mainContentStyle ? this.props.mainContentStyle : {};
    if (this.state.offsetHeight > 0) {
      mainContentStyle.marginTop = 60 + this.state.offsetHeight;
      mainContentStyle.minHeight = 'calc(100vh - ' + (60 + this.state.offsetHeight) + 'px)';
    }
    if (isMobile)
      mainContentStyle.marginLeft = 0;

    return (
      <>
        {
          this.props.notificationTopbar && this.props.notificationTopbar.length && !this.state.notificationClosed
            ? <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 9 }}>
              <div className={styles.NotificationTopbar} ref={this.notifTopbarRef}>
                <span className={styles.CloseButton} aria-hidden="true" onClick={this.onCloseNotificationTopbarHandler}>&times;</span>
                <div style={{ width: '70%' }}>
                  <span className={styles.Title}>{this.props.notificationTopbar[0].title}</span>
                  <span>{this.props.notificationTopbar[0].content}</span>
                  <div className={styles.PostedDate}>on {moment(this.props.notificationTopbar[0].start).format('llll')}</div>
                </div>
              </div>
            </div>
            : null
        }
        <Topbar isMobile={isMobile} isAdminMode={isAdminMode} onToggleAdminMode={onToggleAdminMode} offsetHeight={this.state.offsetHeight} />
        <PrivacyModal
          open={this.state.isPrivacyModalOpened}
          modalClosed={this.privacyModalClickHandler}
          acceptPrivacy={this.acceptPrivacyHandler}
        />
        <div className="container-fluid">
          {isMobile ? null :
            <div className={styles.Sidebar} style={{ top: 60 + this.state.offsetHeight, height: `calc(100vh - ${60 + this.state.offsetHeight}px)` }}>
              <Sidebar isAdminMode={isAdminMode} np route={this.props.route} />
            </div>}
          <div className={styles.MainContainer} style={mainContentStyle}>
            <this.props.component route={this.props.route} isMobile={isMobile} offsetHeight={this.state.offsetHeight} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { notificationTopbar } = state.notification;
  return { notificationTopbar };
};

export default connect(mapStateToProps, { getNotificationTopbar })(MainLayout);