import React, { Component } from 'react';

import styles from './SideDrawer.module.scss';
import Backdrop from '../../UI/Backdrop/Backdrop';

class SideDrawer extends Component {
  componentDidMount() {
    window.addEventListener('keydown', this.handleEsc);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEsc);
  }

  handleEsc = (event) => {
    if (this.props.open && event.keyCode === 27) {
      this.props.closed();
    }
  };

  render() {
    let drawerClasses = [styles.SideDrawer];
    if (this.props.open) {
      drawerClasses.push(styles.Open);
    } else {
      drawerClasses.push(styles.Close);
    }

    let drawerHeader = (
      <div className={styles.DrawerHeader}>
        <span className={styles.DrawerTitle}>{this.props.title}</span>
        <span className={styles.CloseBtn} aria-hidden="true" onClick={this.props.closed}>&times;</span>
      </div>
    );
    if (this.props.noHeader) {
      drawerHeader = null;
    }

    return (
      <React.Fragment>
        <Backdrop show={this.props.open} clicked={this.props.closed} />
        <div className={drawerClasses.join(' ')} style={this.props.overrideStyles}>
          {drawerHeader}
          <div className={styles.DrawerBody}>
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default SideDrawer;