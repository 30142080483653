import React from 'react';

import styles from './Select.module.scss';
import ButtonSpinner from '../../UI/Spinner/ButtonSpinner';
import ToolTip from '../../UI/ToolTip/Tooltip';

const select = (props) => (
  <div className={styles.FormGroup} style={props.overidestyleformgroup}>
    {props.label && <label className={`${styles.Title} ${props.labelin ? styles.LabelIn : ''}`}>{props.label}</label>}
    {props.tooltip && <ToolTip isInfo style={{ marginLeft: 10 }} overideTitleStyles={{ width: 240 }}>{props.tooltip}</ToolTip>}

    <select
      className={`${styles.SelectBox} ${props.whitemode ? styles.WhiteMode : ''} ${props.loading || props.disabled ? styles.Disabled : ''} ${props.labelin ? styles.TextRight : ''}`}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
    >
      {
        props.noPlaceHolder ?
          null
          :
          <option value='-1' disabled={!props.notRequired}>{props.placeholder}</option>
      }
      {
        props.options ? props.options.map(opt =>
          <option key={"select-option-" + opt.name + "-" + opt.id} value={opt.id}>{opt.name}</option>
        ) : null
      }
    </select>
    {
      props.loading ?
        <div className={styles.LoadingWrapper}><ButtonSpinner /></div>
        : null
    }
  </div>
);

export default select;