import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  member: null,
  members: null,
  addedMember: null,
  updatedMember: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MEMBERS_START: return getMembersStart(state, action);
    case actionTypes.GET_MEMBERS_SUCCESS: return getMembersSuccess(state, action);
    case actionTypes.GET_MEMBERS_FAIL: return getMembersFail(state, action);

    case actionTypes.GET_MEMBER_START: return getMemberStart(state, action);
    case actionTypes.GET_MEMBER_SUCCESS: return getMemberSuccess(state, action);
    case actionTypes.GET_MEMBER_FAIL: return getMemberFail(state, action);

    case actionTypes.ADD_MEMBER_START: return addMemberStart(state, action);
    case actionTypes.ADD_MEMBER_SUCCESS: return addMemberSuccess(state, action);
    case actionTypes.ADD_MEMBER_FAIL: return addMemberFail(state, action);

    case actionTypes.EDIT_MEMBER_START: return editMemberStart(state, action);
    case actionTypes.EDIT_MEMBER_SUCCESS: return editMemberSuccess(state, action);
    case actionTypes.EDIT_MEMBER_FAIL: return editMemberFail(state, action);

    case actionTypes.DELETE_MEMBER_START: return deleteMemberStart(state, action);
    case actionTypes.DELETE_MEMBER_SUCCESS: return deleteMemberSuccess(state, action);
    case actionTypes.DELETE_MEMBER_FAIL: return deleteMemberFail(state, action);
    default: return state;
  }
};

const getMembersStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getMembersSuccess = (state, action) => {
  if (action.member) {
    return updateObject(state, {
      member: action.member,
      error: null,
      loading: false
    });
  } else {
    const members = action.members ? action.members.sort((a, b) => a.created_date - b.created_date): null;
    return updateObject(state, {
      members,
      error: null,
      loading: false
    });
  }
}

const getMembersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getMemberStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getMemberSuccess = (state, action) => {
  return updateObject(state, {
    member: action.member,
    error: null,
    loading: false
  });
}

const getMemberFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addMemberStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addMemberSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedMember: action.addedMember,
    error: null,
    loading: false
  });
}

const addMemberFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editMemberStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editMemberSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedMember: action.updatedMember,
    error: null,
    loading: false
  });
}

const editMemberFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteMemberStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteMemberSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteMemberFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;