import React, { Component } from 'react';
import moment from 'moment';
import Barcode from 'react-barcode';

class PrintoutPO extends Component {
    isEndYet = (actual) => {
        return actual !== '0001-01-01T00:00:00Z';
    }

    render() {
        return (
            <table style={{ color: '#000', fontSize: 16, fontWeight: '500', background: 'white', width: 150, borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th style={{ width: 180 }}></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3} style={{ fontSize: 12, color: '#3e3f42', padding: '10px 10px 0 10px' }}>{this.props.org}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{ padding: '10px 10px 0 10px' }}>{this.props.po && <Barcode width={1} value={this.props.po} />}</td>
                    </tr>
                    <tr style={{ padding: 5, verticalAlign: 'baseline' }}>
                        <td colSpan={3} style={{ fontSize: 18, fontWeight: '600', padding: 10 }}>PO#: {this.props.po}</td>
                    </tr>
                    <tr style={{ padding: 5, verticalAlign: 'baseline' }}>
                        <td colSpan={3} style={{ padding: '0px 10px 10px 10px' }}>MACHINE: {this.props.machine.name}</td>
                    </tr>
                    <tr style={{ padding: 5, verticalAlign: 'baseline' }}>
                        <td colSpan={3} style={{ fontSize: 14, padding: '0 10px' }}>START: {this.props.actual && moment(this.props.actual.start).format('DD/MM/YYYY HH:mm')}</td>
                    </tr>
                    <tr style={{ padding: 5, verticalAlign: 'baseline' }}>
                        <td colSpan={3} style={{ fontSize: 14, padding: '0 10px 10px 10px' }}>END: {this.props.actual && this.isEndYet(this.props.actual.end) ? moment(this.props.actual.end).format('DD/MM/YYYY HH:mm') : moment().format('DD/MM/YYYY HH:mm')}</td>
                    </tr>
                    <tr style={{ borderTop: '1px solid #000' }}>
                        <td colSpan={3} style={{ padding: '5px 10px' }}>TARGET: {this.props.actual?.target.toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{ padding: '5px 10px' }}>TOTAL QTY: {this.props.total.toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{ padding: '5px 10px' }}>GOOD: {this.props.ok.toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{ padding: '5px 10px' }}>DEFECT: {this.props.defect.toLocaleString()}</td>
                    </tr>
                    {
                        this.props.customCols && Object.keys(this.props.customCols).length > 0
                            ? Object.keys(this.props.customCols).map((key, i) => (
                                <tr key={`${key}${i}`}>
                                    <td colSpan={3} style={{ padding: '5px 10px' }}>{key.toUpperCase()}: {this.props.updatedCustomCols[key] ? this.props.updatedCustomCols[key] : this.props.customCols[key]}</td>
                                </tr>
                            ))
                            : null
                    }
                    <tr style={{ borderTop: '1px solid #000' }}>
                        <td colSpan={2} style={{ padding: 10 }}></td>
                        <td style={{ padding: '10px 10px 10px 0' }}>{this.props.loggedInUser && this.props.loggedInUser.first_name} {this.props.loggedInUser && this.props.loggedInUser.last_name}</td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ padding: 10 }}></td>
                        <td style={{ padding: '0 10px 10px 0' }}>{moment().format('DD/MM/YYYY HH:mm')}</td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default PrintoutPO;
