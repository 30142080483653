import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addCommand } from '../../../../../../state/ducks/Command/actions';
import { getDataLatest } from '../../../../../../state/ducks/Data/actions';
import styles from './SwitchOnOff.module.scss';
import BusyIcon from '../../../../../../assets/icons/Widgets/SwitchSequence/busy.svg';


class SwitchOnOff extends Component {
  state = {
    stateClearInterval: true,
    lastData: null,
    on: 100,
    off: 0,
    checkingInterval: null,
    isAllDevicesOnline: false
  }

  componentDidMount() {
    const { switchProps } = this.props.widget;
    if (switchProps && switchProps.conditions) {
      setTimeout(() => {
        this.setCheckStatusSwitch();
      }, 100);

      if (switchProps.conditions[0] && (switchProps.conditions[0].on_value || +switchProps.conditions[0].on_value === 0)) {
        this.setState({ on: +switchProps.conditions[0].on_value });
      }
      if (switchProps.conditions[0] && (switchProps.conditions[0].off_value || +switchProps.conditions[0].off_value === 0)) {
        this.setState({ off: +switchProps.conditions[0].off_value });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.dataLatest !== prevProps.dataLatest && this.props.dataLatest) {
      if (this.props.dataLatest.data && this.props.widget && this.isThisTheRightData(this.props.dataLatest, this.props.widget)) {
        const lastData = this.props.dataLatest.data && this.props.dataLatest.data.length > 0 ? this.props.dataLatest.data[0].value : this.state.off;
        if (this.state.lastData !== lastData) {
          if (this.state.stateClearInterval) {
            clearInterval(this.state.checkingInterval);
            this.setState({ lastData, stateClearInterval: false, checkingInterval: null });
          } else {
            this.setState({ lastData });
          }
        }
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((this.state.lastData !== nextState.lastData) || (this.state.stateClearInterval !== nextState.stateClearInterval)) {
      return true;
    }
    // console.log("$$$", this.props.dataLatest)
    // if (this.props.dataLatest === null) {
    //   return nextProps.dataLatest !== null;
    // }
    // console.log(nextProps.dataLatest.properties, nextProps.dataLatest.data[0].value,this.props.dataLatest.data[0].value, this.props.dataLatest.properties)
    // if (this.props.dataLatest && nextProps.dataLatest && nextProps.dataLatest.data.length && this.props.dataLatest.data.length
    //   && nextProps.dataLatest.data[0].value !== this.props.dataLatest.data[0].value) {
    //   return true;
    // }
    // if (nextProps.dataLatest.data.length === 0) {
    //   return true;
    // }

    if (this.props.widget && nextProps.dataLatest && !this.isThisTheRightData(nextProps.dataLatest, this.props.widget)) {
      return false;
    }
    return true;
  }

  isThisTheRightData = (data, comp) => {
    // if (data.properties.wid === comp.id) {
    if (data.properties.secret === comp.switchProps.conditions[0].sensor.secret && data.properties.socket === comp.switchProps.conditions[0].sensor.socket) {
      return true;
    }
    return false;
  }

  onChangeStatus = () => {
    // const { on, off } = this.state;
    // let value;
    // if (this.state.lastData === on) {
    //   value = off;
    // } else {
    //   value = on;
    // }
    // let device = this.props.deviceOptions.find(d => d.secret === this.props.widget.secret && d.socket === this.props.widget.socket);
    // this.props.addCommand(this.props.currentOrg, device.id, value);
    if (this.state.isAllDevicesOnline) {
      this.props.widget.switchProps.conditions.forEach(cond => {
        const device = this.props.deviceOptions.find(d => d.secret === cond.sensor.secret && d.socket === cond.sensor.socket);
        let value = cond.on_value;
        if (this.state.lastData === this.state.on) {
          value = cond.off_value
        }
        this.props.addCommand(this.props.currentOrg, device.id, value);
      });

      this.setCheckStatusSwitch();
      this.setState({ stateClearInterval: true });
    }
  }

  setCheckStatusSwitch = () => {
    const { switchProps } = this.props.widget;

    let isAllDevicesOnline = true;
    switchProps.conditions.forEach(cond => {
      const online = this.props.devices.find(device => device.secret === cond.sensor.secret).status === 'on';
      isAllDevicesOnline = isAllDevicesOnline && online;
    });

    const checkingInterval = setInterval(() => {
      this.props.getDataLatest(switchProps.conditions[0].sensor.socket, switchProps.conditions[0].sensor.secret, null, this.props.widget.id);
    }, 1000);

    this.setState({ checkingInterval, isAllDevicesOnline });
  }

  componentWillUnmount() {
    clearInterval(this.state.checkingInterval);
  }

  render() {
    const { on } = this.state;
    let toggleStyles = [styles.toggle];
    if (this.state.lastData === on) {
      toggleStyles.push(styles.toggleOn);
    }

    return (
      <div className={styles.Switch}>
        {this.props.error === 'Device is busy' ? <img src={BusyIcon} style={{ width: 90, height: 90 }} alt="busy icon" />
          : <>
            <div className={styles.ToggleWrapper}>
              {
                this.state.stateClearInterval ? 'Loading...' :
                  <div className={toggleStyles.join(' ')} id='switch' onClick={this.onChangeStatus}>
                    <div className={styles.toggleTextOff}>OFF</div>
                    <div className={styles.glowComp}></div>
                    <div className={styles.toggleButton}></div>
                    <div className={styles.toggleTextOn}>ON</div>
                  </div>
              }
            </div>
            {
              this.state.stateClearInterval
                ? ''
                : <div style={{ marginTop: 30 }}>
                  <div className={styles.Description}>{!this.state.isAllDevicesOnline ? 'Device Offline' : ''}</div>
                </div>
            }
          </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { devices } = state.devices
  const { currentOrg } = state.org;
  const { dataLatest } = state.data;
  const { error } = state.command;
  return { currentOrg, dataLatest, devices, error };
};

export default connect(mapStateToProps, { addCommand, getDataLatest })(SwitchOnOff);