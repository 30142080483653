import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  chatroom: null,
  chatrooms: null,
  addedChatroom: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CHATROOMS_START: return getChatroomsStart(state, action);
    case actionTypes.GET_CHATROOMS_SUCCESS: return getChatroomsSuccess(state, action);
    case actionTypes.GET_CHATROOMS_FAIL: return getChatroomsFail(state, action);

    case actionTypes.GET_CHATROOM_START: return getChatroomStart(state, action);
    case actionTypes.GET_CHATROOM_SUCCESS: return getChatroomSuccess(state, action);
    case actionTypes.GET_CHATROOM_FAIL: return getChatroomFail(state, action);

    case actionTypes.ADD_CHATROOM_START: return addChatroomStart(state, action);
    case actionTypes.ADD_CHATROOM_SUCCESS: return addChatroomSuccess(state, action);
    case actionTypes.ADD_CHATROOM_FAIL: return addChatroomFail(state, action);

    default: return state;
  }
};

const getChatroomsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getChatroomsSuccess = (state, action) => {
  if (action.chatroom) {
    return updateObject(state, {
      chatroom: action.chatroom,
      error: null,
      loading: false
    });
  } else {
    return updateObject(state, {
      chatrooms: action.chatrooms,
      error: null,
      loading: false
    });
  }
}

const getChatroomsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getChatroomStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getChatroomSuccess = (state, action) => {
  return updateObject(state, {
    chatroom: action.chatroom,
    error: null,
    loading: false
  });
}

const getChatroomFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addChatroomStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addChatroomSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedChatroom: action.addedChatroom,
    error: null,
    loading: false
  });
}

const addChatroomFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;