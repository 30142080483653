import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import TimeAgo from 'react-timeago';

import SimpleSwitch from '../../../components/UI/SimpleSwitch/SimpleSwitch';
import styles from './RuleList.module.scss';

import AlarmHistoryIcon from '../../../../assets/icons/Rule/alarm-history.svg';
import { enableRule } from '../../../../state/ducks/Rule/actions';
import { ENABLE_RULE_SUCCESS } from '../../../../state/ducks/Rule/types';

class RuleList extends Component {
  state = {
    ruleLists: null
  }

  componentDidMount() {
    if (this.props.rules) {
      this.setState({ ruleLists: this.props.rules });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type === ENABLE_RULE_SUCCESS && this.props.updatedEnableRule !== prevProps.updatedEnableRule) {
      this.updateRuleListRow(this.props.updatedEnableRule);
    } else if (this.props.rules !== prevProps.rules) {
      this.setState({ ruleLists: this.props.rules });
    }
  }

  updateRuleListRow = (updatedRule) => {
    let updatedRuleLists = [...this.state.ruleLists];
    const index = updatedRuleLists.findIndex(rule => rule.id === updatedRule.id);
    updatedRuleLists[index].enable = updatedRule.enable;
    this.setState({ ruleLists: updatedRuleLists });
  }

  onChangeEnableHandler = (event, index) => {
    event.stopPropagation();
    this.props.enableRule(this.state.ruleLists[index].id, !this.state.ruleLists[index].enable, this.state.ruleLists[index].calculation_method, this.state.ruleLists[index].sampling_size);
    this.props.closed();
  }

  render() {
    return (
      this.state.ruleLists ? this.state.ruleLists.map((rule, i) => {
        let ruleWrapper = [styles.AddedRuleWrapper];
        if (this.props.selectedRule === i) {
          ruleWrapper.push(styles.Selected);
        }

        return (
          <div key={rule.name} className={ruleWrapper.join(' ')} onClick={() => this.props.onEditRule(i)}>
            <div className={styles.SwitchWrapper}>
              <SimpleSwitch enable={rule.enable} onChangeEnableHandler={(event) => this.onChangeEnableHandler(event, i)} />
            </div>
            <div style={{ flex: 1 }}>
              <div className={styles.Name}>{rule.name}</div>
              <div className={styles.Description}>{rule.description}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {rule.last_trigger !== '0001-01-01T00:00:00Z'
                ? <>
                  <div className={styles.LastTriggerText}><TimeAgo date={moment(rule.last_trigger).valueOf()} /></div>
                  <div className={styles.HistoryIconWrapper}>
                    <img src={AlarmHistoryIcon} alt="Open History" style={{ width: 20 }} onClick={(event) => this.props.openHistoriesModal(event, i)} />
                  </div>
                </>
                : <div className={styles.LastTriggerText}>Never</div>
              }
            </div>
          </div>
        )
      }) : null
    )
  }

}
const mapStateToProps = (state) => {
  const { updatedEnableRule, type } = state.rule;
  return { updatedEnableRule, type };
};

export default connect(mapStateToProps, { enableRule })(RuleList);