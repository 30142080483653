import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import styles from './Timeline.module.scss';

import { getData } from '../../../../../state/ducks/Data/actions';

class Timeline extends Component {

  componentDidMount() {
    if (this.props.widget) {
      const now = new Date();
      this.setupValueWidth();
      if (now.getTime() > this.props.widget.time_range.start) {
        this.props.getData(
          this.props.widget.id,
          null,
          this.props.widget.time_range.start,
          this.props.widget.time_range.stop,
          this.props.widget.secret,
          this.props.widget.socket
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data && this.props.data && this.props.data.line_id === this.props.widget.id) {
      this.setupTimelineData();
    } else if (this.props.mqttData !== prevProps.mqttData && this.props.mqttData && this.props.mqttData.timestamp <= this.props.widget.time_range.stop &&
      this.props.mqttData.timestamp >= this.props.widget.time_range.start) {
      this.updatedTimelineData(this.props.mqttData);
    }
  }

  state = {
    allDatasets: [],
    valueWidth: 0,
    deviceName: null
  }

  setupValueWidth = () => {
    let valueWidth = 0;
    valueWidth = 100 / ((this.props.widget.time_range.stop - this.props.widget.time_range.start) / 1000);
    this.setState({ valueWidth });
  }

  setupTimelineData = () => {
    let allDatasets = [];
    let findDevice = this.props.deviceOptions.find(d => d.secret === this.props.widget.secret && d.socket === this.props.widget.socket);
    this.props.data.values.forEach(data => {
      let color = '#2F9E3E';
      let findColor = null;
      if (this.props.widget.colors) {
        findColor = this.props.widget.colors.find(color => data.value >= color.from_value && data.value <= color.to_value);
      }
      if (findColor) {
        color = findColor.color;
      }
      let dataToPush = { value: data.value, timestamp: data.timestamp, color: color };
      allDatasets.push(dataToPush);
    });
    this.setState({ allDatasets, deviceName: findDevice.name });
  }

  updatedTimelineData = (data) => {
    let allDatasets = [...this.state.allDatasets];
    let color = '#2F9E3E';
    let findColor = null;
    if (this.props.widget.colors) {
      findColor = this.props.widget.colors.find(color => data.value >= color.from_value && data.value <= color.to_value);
    }
    if (findColor) {
      color = findColor.color;
    }
    let dataToPush = { value: data.value, timestamp: data.timestamp, color: color };
    allDatasets.push(dataToPush);
    this.setState({ allDatasets });
  }

  render() {
    const { widget, id } = this.props;
    let start = this.props.widget.time_range.start;
    let stop = this.props.widget.time_range.stop;
    let middle = (start + stop) / 2;
    let label1 = (start + middle) / 2;
    let label2 = (middle + stop) / 2;
    return (
      <div id={id} className={styles.Timeline}>
        {
          this.state.allDatasets && this.state.allDatasets.length ?
            <React.Fragment>
              <div className={styles.HeaderWrapper}>
                {
                  widget.colors ? widget.colors.map((color, i) => {
                    return (
                      <div key={i} className={styles.Header} style={{ marginRight: 10 }}>
                        <div className={styles.Block} style={{ backgroundColor: color.color }}></div>{color.from_value} - {color.to_value}
                      </div>
                    )
                  }) : null
                }
              </div>
              <div className={styles.TimelineWrapper}>
                <div className={styles.TimelineBg}>
                  <div className={styles.TimelineValueWrapper}>
                    <div className={styles.TimelineLabel}>
                      <div className={styles.Start}>{moment(start).format('HH:mm:ss')}</div>
                      <div className={styles.Label1}>{moment(label1).format('HH:mm:ss')}</div>
                      <div className={styles.Middle}>{moment(middle).format('HH:mm:ss')}</div>
                      <div className={styles.Label2}>{moment(label2).format('HH:mm:ss')}</div>
                      <div className={styles.Stop}>{moment(stop).format('HH:mm:ss')}</div>
                    </div>
                    {
                      this.state.allDatasets.map((timelineData, index) => {
                        return (
                          <div key={timelineData.timestamp} className={styles.TimelineValue} style={{ width: `${this.state.valueWidth}%`, backgroundColor: timelineData.color }}>
                            <div className={styles.Title}>
                              <div className={styles.Time}>{moment(timelineData.timestamp).format('HH:mm:ss')}</div>
                              <div className={styles.Value}>{this.state.deviceName}: {timelineData.value}</div>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            </React.Fragment> : <div className={styles.Loading}>Loading...</div>
        }
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { data } = state.data;
  return { data };
};

export default connect(mapStateToProps, { getData })(Timeline);