import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  updatedWidget: null,
  updatedWidgetLayout: null,
  addedWidget: null,
  deletedWidgetId: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.ADD_WIDGET_START: return addWidgetStart(state, action);
    case actionTypes.ADD_WIDGET_SUCCESS: return addWidgetSuccess(state, action);
    case actionTypes.ADD_WIDGET_FAIL: return addWidgetFail(state, action);

    case actionTypes.EDIT_WIDGET_START: return editWidgetStart(state, action);
    case actionTypes.EDIT_WIDGET_SUCCESS: return editWidgetSuccess(state, action);
    case actionTypes.EDIT_WIDGET_FAIL: return editWidgetFail(state, action);

    case actionTypes.EDIT_WIDGET_LAYOUT_START: return editWidgetLayoutStart(state, action);
    case actionTypes.EDIT_WIDGET_LAYOUT_SUCCESS: return editWidgetLayoutSuccess(state, action);
    case actionTypes.EDIT_WIDGET_LAYOUT_FAIL: return editWidgetLayoutFail(state, action);

    case actionTypes.DELETE_WIDGET_START: return deleteWidgetStart(state, action);
    case actionTypes.DELETE_WIDGET_SUCCESS: return deleteWidgetSuccess(state, action);
    case actionTypes.DELETE_WIDGET_FAIL: return deleteWidgetFail(state, action);
    default: return state;
  }
};

const addWidgetStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addWidgetSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedWidget: action.addedWidget,
    error: null,
    loading: false
  });
}

const addWidgetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editWidgetStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editWidgetSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedWidget: action.updatedWidget,
    error: null,
    loading: false
  });
}

const editWidgetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editWidgetLayoutStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editWidgetLayoutSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedWidgetLayout: action.updatedWidgetLayout,
    error: null,
    loading: false
  });
}

const editWidgetLayoutFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteWidgetStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, deletedWidgetId: null, type: action.type });
}

const deleteWidgetSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    deletedWidgetId: action.wid,
    error: null,
    loading: false
  });
}

const deleteWidgetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;