import React, { Component } from 'react';

import { updateObject, checkValidity } from '../../../../state/utils';
import styles from '../Rule.module.scss';
import Input from '../../UI/Input/Input';
import Select from '../../UI/Select/Select';
import AddIcon from '../../../../assets/icons/Rule/add-circle.svg';
import DeleteIcon from '../../../../assets/icons/Rule/delete-circle.svg';

const DEFAULT_COMMAND_FORM = {
  device_socket_id: {
    value: '-1',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  device_socket: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  device_secret: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  value: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
};

class CommandForm extends Component {
  state = {
    formIsValid: false,
    commandForm: DEFAULT_COMMAND_FORM,
  }

  componentDidMount() {
    if (this.props.command) {
      this.setupForm(this.props.command);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.command !== prevProps.command) {
      this.setupForm(this.props.command);
    }
  }

  setupForm = (form) => {
    let filterDevice = this.props.deviceOptions.find(option => option.socket === form.device_socket && option.secret === form.device_secret);
    let updatedForm = { ...this.state.commandForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] !== '' ? form[inputIdentifier] : '',
        valid: form[inputIdentifier] !== '' ? true : false,
        touched: form[inputIdentifier] !== '' ? true : false
      });

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }
    let updatedDeviceSocketId = updateObject(updatedForm['device_socket_id'], {
      value: filterDevice ? filterDevice.id : '-1',
      valid: filterDevice ? true : false,
      touched: filterDevice ? true : false
    });
    updatedForm = updateObject(updatedForm, {
      device_socket_id: updatedDeviceSocketId
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ commandForm: updatedForm, formIsValid });
  }

  handleChange = event => {
    let updatedForm;
    let updatedElement = updateObject(this.state.commandForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.commandForm[event.target.name].validation),
      touched: true
    });

    if (event.target.name === 'device_socket_id') {
      let selectedSocket = this.props.deviceOptions.find(option => option.id === +event.target.value);
      let updatedDeviceSecret = updateObject(this.state.commandForm['device_secret'], {
        value: selectedSocket.secret,
        valid: checkValidity(selectedSocket.secret, this.state.commandForm['device_secret'].validation),
        touched: true
      });
      let updatedDeviceSocket = updateObject(this.state.commandForm['device_socket'], {
        value: selectedSocket.socket,
        valid: checkValidity(selectedSocket.secret, this.state.commandForm['device_socket'].validation),
        touched: true
      });

      updatedForm = updateObject(this.state.commandForm, {
        [event.target.name]: updatedElement,
        device_secret: updatedDeviceSecret,
        device_socket: updatedDeviceSocket
      });
    } else {
      updatedForm = updateObject(this.state.commandForm, {
        [event.target.name]: updatedElement
      });
    }

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ commandForm: updatedForm, formIsValid });
    this.props.commandChange(
      this.props.addIndex,
      updatedForm,
      formIsValid
    );
  }

  render() {
    const { device_socket_id, value } = this.state.commandForm;
    return (
      <div className={styles.Rule}>
        <div className={styles.Flex}>
          <div style={{ flex: 3 }}>
            <Select
              name="device_socket_id"
              placeholder="Select a device"
              options={this.props.deviceOptions}
              value={device_socket_id.value}
              autoComplete="off"
              overidestyleformgroup={{ marginBottom: 15 }}
              onChange={this.handleChange}
            />
          </div>
          <div style={{ flex: 1 }}>
            <div className={styles.Flex}>
              <div style={{ flex: 2 }}>
                <Input
                  name="value"
                  type="number"
                  placeholder="Value"
                  overidestyleformgroup={{ marginBottom: 15, marginLeft: 15 }}
                  value={value.value}
                  autoComplete="off"
                  onChange={this.handleChange}
                />
              </div>
              {
                this.props.addNewCommand ?
                  this.props.firstCommand ?
                    <div className={styles.AddButton}>
                      <img className={styles.IconButton} src={AddIcon} alt="Add Command Icon" onClick={this.props.addNewCommand} />
                    </div> :
                    <div className={styles.AddButton}>
                      <img className={styles.IconButton} src={DeleteIcon} alt="Delete Command Icon" onClick={this.props.removeCommand} />
                      <img className={styles.IconButton} src={AddIcon} alt="Add Command Icon" onClick={this.props.addNewCommand} />
                    </div> :
                  <div className={styles.AddButton}>
                    <img className={styles.IconButton} src={DeleteIcon} alt="Delete Command Icon" onClick={this.props.removeCommand} />
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CommandForm;