import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_SHIFTS, ADD_SHIFT, EDIT_SHIFT, DELETE_SHIFT } from './types';

export function* watchShift() {
  yield takeEvery(GET_SHIFTS, getShiftsSaga);
  yield takeEvery(ADD_SHIFT, addShiftSaga);
  yield takeEvery(EDIT_SHIFT, editShiftSaga);
  yield takeEvery(DELETE_SHIFT, deleteShiftSaga);
}

export function* getShiftsSaga(params) {
  yield put(actions.getShiftsStart());

  let api = 'v1/shift';
  api += '?org_id=' + params.org_id;

  try {
    const response = yield axios.get(api);
    console.log("GET SHIFT SUCCESS", response.data);
    yield put(actions.getShiftsSuccess(response.data));
  } catch (err) {
    console.log("GET SHIFT FAILED", err)
    yield put(actions.getShiftsFail(err.response));
  }
}

export function* addShiftSaga(params) {
  yield put(actions.addShiftStart());

  let postData = 'name=' + encodeURIComponent(params.name);
  postData += '&org_id=' + params.org_id;
  postData += '&start=' + params.start;
  postData += '&stop=' + params.stop;
  postData += params.description ? '&description=' + encodeURIComponent(params.description) : '';

  const api = 'v1/shift';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD SHIFT SUCCESS", response);
    yield put(actions.addShiftSuccess(response.data));
  } catch (err) {
    console.log("ADD SHIFT FAILED", err.response.data);
    yield put(actions.addShiftFail(err.response.data));
  }
}

export function* editShiftSaga(params) {
  yield put(actions.editShiftStart());

  let putData = 'name=' + encodeURIComponent(params.name);
  putData += '&start=' + params.start;
  putData += '&stop=' + params.stop;
  putData += params.description ? '&description=' + encodeURIComponent(params.description) : '';

  const api = 'v1/shift/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT SHIFT SUCCESS", response);
    yield put(actions.editShiftSuccess(response.data));
  } catch (err) {
    console.log("EDIT SHIFT FAILED", err.response.data);
    yield put(actions.editShiftFail(err.response.data));
  }
}

export function* deleteShiftSaga(params) {
  yield put(actions.deleteShiftStart());

  const api = 'v1/shift/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE SHIFT SUCCESS", response.data);
    yield put(actions.deleteShiftSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE SHIFT FAILED", err.response);
    yield put(actions.deleteShiftFail(err.response));
  }
}
