import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_USER_FAVORITES, ADD_USER_FAVORITE, DELETE_USER_FAVORITE } from './types';

export function* watchUserFavorite() {
  yield takeEvery(GET_USER_FAVORITES, getUserFavoritesSaga);
  yield takeEvery(ADD_USER_FAVORITE, addUserFavoriteSaga);
  yield takeEvery(DELETE_USER_FAVORITE, deleteUserFavoriteSaga);
}

export function* getUserFavoritesSaga(params) {
  yield put(actions.getUserFavoritesStart());
  const api = 'v1/userfavorite?org_id=' + params.org_id + '&type=' + params.favorite_type;

  try {
    const response = yield axios.get(api);
    // console.log("GET USER FAVORITE SUCCESS", response.data);
    yield put(actions.getUserFavoritesSuccess(response.data));
  } catch (err) {
    console.log("GET USER FAVORITE FAILED", err.response.data);
    yield put(actions.getUserFavoritesFail(err.response.data));
  }
}

export function* addUserFavoriteSaga(params) {
  yield put(actions.addUserFavoriteStart());

  let postData = 'taget_id=' + params.target_id;
  postData += '&type=' + params.favorite_type;

  const api = 'v1/userfavorite';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD USER FAVORITE SUCCESS", response);
    yield put(actions.addUserFavoriteSuccess(response.data));
  } catch (err) {
    console.log("ADD USER FAVORITE FAILED", err.response.data);
    yield put(actions.addUserFavoriteFail(err.response.data.error));
  }
}

export function* deleteUserFavoriteSaga(params) {
  yield put(actions.deleteUserFavoriteStart());

  let api = 'v1/userfavorite?id=' + params.id;
  api += params.target_id ? '&target_id=' + params.target_id : '';
  api += params.org_id ? '&org_id=' + params.org_id : '';
  api += params.favorite_type ? '&favorite_type=' + params.favorite_type : '';

  try {
    const response = yield axios.delete(api);
    console.log("DELETE USER FAVORITE SUCCESS", response.data);
    yield put(actions.deleteUserFavoriteSuccess(response.data));
  } catch (err) {
    console.log("DELETE USER FAVORITE FAILED", err.response);
    yield put(actions.deleteUserFavoriteFail(err.response));
  }
}