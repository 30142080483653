
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_DEVICE_SOCKETS, ADD_DEVICE_SOCKET, EDIT_DEVICE_SOCKET, CHANGE_SOCKET_ICON, EDIT_DEVICE_SOCKETS_WITH_JSON } from './types';

export function* watchDeviceSockets() {
  yield takeEvery(GET_DEVICE_SOCKETS, getDeviceSocketsSaga);
  yield takeEvery(ADD_DEVICE_SOCKET, addDeviceSocketSaga);
  yield takeEvery(EDIT_DEVICE_SOCKET, editDeviceSocketSaga);
  yield takeEvery(EDIT_DEVICE_SOCKETS_WITH_JSON, editDeviceSocketsWithJSONSaga);
  yield takeEvery(CHANGE_SOCKET_ICON, changeSocketIconSaga);
}

export function* getDeviceSocketsSaga(params) {
  yield put(actions.getDeviceSocketsStart());

  let api = 'v1/devicesocket';

  api += params.secret ? ('?secret=' + params.secret) : '';
  api += params.tag ? ('&tag=' + encodeURIComponent(params.tag)) : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET DEVICE_SOCKETS SUCCESS", response.data);
    yield put(actions.getDeviceSocketsSuccess(response.data));
  } catch (err) {
    console.log("GET DEVICE_SOCKETS FAILED", err)
    yield put(actions.getDeviceSocketsFail(err.response));
  }
}

export function* addDeviceSocketSaga(params) {
  yield put(actions.addDeviceSocketStart());

  const api = 'v1/devicesocket';

  // device_id, socket, location, name, description, can_control, unit, image
  let postData = "device_id=" + params.device.id;
  postData += "&socket=" + params.socket;
  postData += "&location=" + encodeURIComponent(params.location);
  postData += "&name=" + encodeURIComponent(params.name);
  postData += params.description ? '&description=' + encodeURIComponent(params.description) : '';
  postData += params.can_control ? ('&can_control=' + params.can_control) : '';
  postData += params.unit ? ('&unit=' + params.unit) : '';
  postData += params.tag ? '&tag=' + encodeURIComponent(params.tag) : '';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD DEVICE_SOCKET SUCCESS", response);
    yield put(actions.addDeviceSocketSuccess(response.data));

    if (params.functions) {
      let setDeviceFunc = 'secret=' + params.device.secret;
      setDeviceFunc += '&socket=' + params.socket;
      setDeviceFunc += '&functions=';
      let functions = [];
      let splitFunctions = params.functions.trim().split(' ');
      let order = 1;
      for (let i = 0; i < splitFunctions.length; i = i + 2) {
        let object = { operation: splitFunctions[i], order: order, value: parseFloat(splitFunctions[i + 1]) };
        functions.push(object);
        order++;
      }
      setDeviceFunc += encodeURIComponent(JSON.stringify(functions));
      const setDeviceFuncApi = 'v1/function';
      const setDeviceFuncResponse = yield axios.post(setDeviceFuncApi, setDeviceFunc);
      // console.log("SET DEVICE FUNCTION SUCCESS", setDeviceFuncResponse);
    }

  } catch (err) {
    console.log("ADD DEVICE_SOCKET FAILED", err.response.data);
    yield put(actions.addDeviceSocketFail(err.response.data.error));
  }
}

export function* editDeviceSocketSaga(params) {
  yield put(actions.editDeviceSocketStart());

  // deviceSocket, location, name, description, unit, image
  let putData = "id=" + params.deviceSocket.id;
  putData += "&location=" + encodeURIComponent(params.location);
  putData += "&name=" + encodeURIComponent(params.name);
  putData += params.description ? '&description=' + encodeURIComponent(params.description) : '';
  putData += params.unit ? ('&unit=' + params.unit) : '';
  putData += params.tag ? '&tag=' + encodeURIComponent(params.tag) : '';

  const api = 'v1/devicesocket/' + params.deviceSocket.id;

  try {
    if (params.functions) {
      let setDeviceFunc = 'secret=' + params.deviceSocket.device_secret;
      setDeviceFunc += '&socket=' + params.deviceSocket.socket;
      setDeviceFunc += '&functions=';
      let functions = [];
      let splitFunctions = params.functions.trim().split(' ');
      let order = 1;
      for (let i = 0; i < splitFunctions.length; i = i + 2) {
        let object = { operation: splitFunctions[i], order: order, value: parseFloat(splitFunctions[i + 1]) };
        functions.push(object);
        order++;
      }
      setDeviceFunc += encodeURIComponent(JSON.stringify(functions));
      const setDeviceFuncApi = 'v1/function';
      const setDeviceFuncResponse = yield axios.post(setDeviceFuncApi, setDeviceFunc);
      // console.log("SET DEVICE FUNCTION SUCCESS", setDeviceFuncResponse);
    }

    const response = yield axios.put(api, putData);
    // console.log("EDIT DEVICE_SOCKET SUCCESS", response.data);
    yield put(actions.editDeviceSocketSuccess(response.data));
  } catch (err) {
    console.log("EDIT DEVICE_SOCKET FAILED", err.response.data);
    yield put(actions.editDeviceSocketFail(err.response.data));
  }
}

export function* editDeviceSocketsWithJSONSaga(params) {
  yield put(actions.editDeviceSocketsWithJSONStart());

  try {
    let body = [];
    for (let i = 0; i < params.form.length; i++) {
      const d = params.form[i];
      body.push({
        socket: d.id,
        name: d.name,
        location: d.location,
        unit_name: d.unit_name,
        tag: d.tag
      });

      if (d.functions) {
        let functions = [];
        let splitFunctions = d.functions.trim().split(' ');
        let order = 1;
        for (let i = 0; i < splitFunctions.length; i = i + 2) {
          let object = { operation: splitFunctions[i], order: order, value: parseFloat(splitFunctions[i + 1]) };
          functions.push(object);
          order++;
        }
        let setDeviceFunc = 'secret=' + params.secret;
        setDeviceFunc += '&socket=' + d.id;
        setDeviceFunc += '&functions=' + encodeURIComponent(JSON.stringify(functions));
        const setDeviceFuncResponse = yield axios.post('v1/function', setDeviceFunc);
        // console.log("SET DEVICE FUNCTION SUCCESS", setDeviceFuncResponse.data);
      } else {
        const deleteFunctionParams = 'secret=' + params.secret + '&socket=' + d.id + '&functions='
        yield axios.post('v1/function', deleteFunctionParams);
      }
    };

    let api = "v1/devicesocketjson/" + params.secret;
    const response = yield axios.put(api, JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    // console.log("EDIT DEVICE_SOCKETS_WITH_JSON SUCCESS", response.data);
    yield put(actions.editDeviceSocketsWithJSONSuccess(response.data));
  } catch (err) {
    console.log("EDIT DEVICE_SOCKETS_WITH_JSON FAILED", err.response.data);
    yield put(actions.editDeviceSocketsWithJSONFail(err.response.data));
  }
}

export function* changeSocketIconSaga(params) {
  yield put(actions.changeSocketIconStart());

  let putData = "id=" + params.id;
  putData += "&image=" + encodeURIComponent(params.image);

  const api = 'v1/devicesocket/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT CHANGE SOCKET ICON SUCCESS", response.data);
    yield put(actions.changeSocketIconSuccess(response.data));
  } catch (err) {
    console.log("EDIT CHANGE SOCKET ICON FAILED", err.response.data);
    yield put(actions.changeSocketIconFail(err.response.data));
  }
}