import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import styles from './Power.module.scss';
import { getData, getDataLatest, getDataEval } from '../../../../../state/ducks/Data/actions';

const ONE_DAY_IN_SECONDS = 86400 * 1000;

class Power extends Component {
  state = {
    query_times: [],
    currentPrice: 0
  }

  componentDidMount() {
    this.setupQueryTimes();

    // check time and prepare for reset at ref_time
    const now = moment().valueOf();
    const beforeMidnight = new Date().setHours(0, 0, 0, 0) + ONE_DAY_IN_SECONDS;
    let intervalTime = beforeMidnight - now;
    this.resetInterval = setInterval(() => {
      this.setState({ currentPrice: 0 });
      intervalTime = intervalTime < ONE_DAY_IN_SECONDS ? ONE_DAY_IN_SECONDS : intervalTime;
    }, intervalTime);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.dataEval && this.isThisTheRightData(nextProps.dataEval, this.props.widget)) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.dataEval && !_.isEqual(prevProps.dataEval, this.props.dataEval)) {
      if ((!prevProps.dataEval && this.props.dataEval) || this.isDiffData(prevProps.dataEval, this.props.dataEval) || prevProps.dataEval.properties.qtIndex !== this.props.dataEval.properties.qtIndex) {
        const { widget, dataEval } = this.props;
        if (widget.powerProps.prices.length === 1) {
          this.setState({ currentPrice: dataEval.data * widget.powerProps.prices[0].price });
        } else {
          this.setState(prevState => {
            return { currentPrice: prevState.currentPrice + (dataEval.data * widget.powerProps.prices[dataEval.properties.qtIndex].price) }
          });
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.resetInterval) clearInterval(this.resetInterval);
  }

  isThisTheRightData = (data, comp) => {
    if (data.properties.secret === comp.secret && data.properties.socket === comp.socket) {
      return true;
    }
    return false;
  }

  isDiffData = (prevData, thisData) => {
    if (!prevData || prevData.properties.secret !== thisData.properties.secret || prevData.properties.socket !== thisData.properties.socket) {
      return true;
    }
    return false;
  }

  getRefTS = () => {
    let widget = this.props.widget;
    if (!widget.powerProps)
      return new Date().setHours(0, 0, 0, 0);
    let ref_time = widget.powerProps.ref_time_start.split(":");
    return new Date().setHours(+ref_time[0], +ref_time[1], 0, 0);
  }

  setupQueryTimes = () => {
    let widget = this.props.widget;
    if (!widget.powerProps || !Array.isArray(widget.powerProps.prices)) return;

    widget.powerProps.prices.forEach((price, i) => {
      const isTomorrow = price.stop === '00:00';
      const startFormula = 'today(' + price.start.replace(":", ",") + ')';
      const stopFormula = (isTomorrow ? 'tomorrow' : 'today') + '(' + price.stop.replace(":", ",") + ')';
      const formula = '{ra:inc(' + widget.device_id + '@' + startFormula + '-' + stopFormula + ')}';
      // console.log(i, formula)
      this.props.getDataEval(formula, {
        wid: widget.id,
        secret: widget.secret,
        socket: widget.socket,
        qtIndex: i
      });
    });
  }

  render() {
    const { id } = this.props;
    const textStyle = [styles.Value];

    if (this.state.currentPrice && this.state.currentPrice.toFixed(2).length > 5) {
      textStyle.push(styles.Small);
    }

    return (
      <div id={id} className={styles.Text}>
        <div className={textStyle.join(' ')}>
          {this.state.currentPrice.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })}
        </div>
        <div className={styles.Unit}>Baht</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { dataLatest, result, dataEval } = state.data;
  return { dataLatest, result, dataEval };
};

export default connect(mapStateToProps, { getData, getDataLatest, getDataEval })(Power);;