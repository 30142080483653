
import axios from '../../../configs/axios-orders';
import { takeEvery, put, take } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_TEAMS, ADD_TEAM, EDIT_TEAM, DELETE_TEAM, ADD_TEAM_MEMBERS, REMOVE_TEAM_MEMBERS, CHANGE_TEAM_ADMIN, LEAVE_TEAM, ADD_TEAM_MEMBERS_SUCCESS, REMOVE_TEAM_MEMBERS_SUCCESS } from './types';

export function* watchTeam() {
  yield takeEvery(GET_TEAMS, getTeamsSaga);
  yield takeEvery(ADD_TEAM, addTeamSaga);
  yield takeEvery(EDIT_TEAM, editTeamSaga);
  yield takeEvery(DELETE_TEAM, deleteTeamSaga);
  yield takeEvery(ADD_TEAM_MEMBERS, addTeamMembersSaga);
  yield takeEvery(REMOVE_TEAM_MEMBERS, removeTeamMembersSaga);
  yield takeEvery(CHANGE_TEAM_ADMIN, changeTeamAdminSaga);
  yield takeEvery(LEAVE_TEAM, leaveTeamSaga);
}

export function* getTeamsSaga(params) {
  yield put(actions.getTeamsStart());

  let api = 'v1/team';
  if (params.id) {
    api += '/' + params.id;
  }

  api += '?org_id=' + params.org_id;
  api += params.name ? '&name=' + params.name : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET TEAMS SUCCESS", response.data);
    yield put(actions.getTeamsSuccess(response.data));
  } catch (err) {
    console.log("GET TEAMS FAILED", err)
    yield put(actions.getTeamsFail(err.response));
  }
}

export function* addTeamSaga(params) {
  yield put(actions.addTeamStart());

  let postData = 'org_id=' + encodeURIComponent(params.org_id);
  postData += "&name=" + encodeURIComponent(params.name);
  postData += params.detail ? '&detail=' + encodeURIComponent(params.detail) : '';

  const api = 'v1/team';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD TEAM SUCCESS", response);
    yield put(actions.addTeamSuccess(response.data));
  } catch (err) {
    console.log("ADD TEAM FAILED", err.response.data);
    yield put(actions.addTeamFail(err.response.data));
  }
}

export function* editTeamSaga(params) {
  yield put(actions.editTeamStart());

  let putData = 'name=' + encodeURIComponent(params.name);
  putData += params.detail ? '&detail=' + encodeURIComponent(params.detail) : '';

  const api = 'v1/team/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    if (params.addingMembers) {
      yield put(actions.addTeamMembers(
        params.id,
        params.addingMembers
      ));
      yield take(ADD_TEAM_MEMBERS_SUCCESS);
    }
    if(params.deletingMembers){
      yield put(actions.removeTeamMembers(
        params.id,
        params.deletingMembers
      ));
      yield take(REMOVE_TEAM_MEMBERS_SUCCESS);
    }
    console.log("EDIT TEAM SUCCESS", response.data);
    yield put(actions.editTeamSuccess(response.data));
  } catch (err) {
    console.log("EDIT TEAM FAILED", err.response.data);
    yield put(actions.editTeamFail(err.response.data));
  }
}

export function* deleteTeamSaga(params) {
  yield put(actions.deleteTeamStart());

  const api = 'v1/team/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE TEAM SUCCESS", response.data);
    yield put(actions.deleteTeamSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE TEAM FAILED", err.response);
    yield put(actions.deleteTeamFail(err.response));
  }
}

export function* addTeamMembersSaga(params) {
  yield put(actions.addTeamMembersStart());

  const api = 'v1/teammember/' + params.id;
  let putData = 'new_members=' + params.new_members;

  try {
    const response = yield axios.put(api, putData);
    console.log("ADD TEAM MEMBERS SUCCESS", response.data);
    yield put(actions.addTeamMembersSuccess(response.data));
  } catch (err) {
    console.log("ADD TEAM MEMBERS FAILED", err.response.data);
    yield put(actions.addTeamMembersFail(err.response.data.error));
  }
}

export function* removeTeamMembersSaga(params) {
  yield put(actions.removeTeamMembersStart());

  const api = 'v1/teammember/' + params.id + '?del_members=' + params.del_members;

  try {
    const response = yield axios.delete(api);
    console.log("REMOVE TEAM MEMBERS SUCCESS", response.data);
    yield put(actions.removeTeamMembersSuccess(response.data));
  } catch (err) {
    console.log("REMOVE TEAM MEMBERS FAILED", err.response.data);
    yield put(actions.removeTeamMembersFail(err.response.data.error));
  }
}

export function* changeTeamAdminSaga(params) {
  yield put(actions.changeTeamAdminStart());

  const api = 'v1/teamadmin/' + params.id + '/' + params.user_id;

  try {
    const response = yield axios.put(api);
    console.log("CHANGE TEAM ADMIN SUCCESS", response.data);
    yield put(actions.changeTeamAdminSuccess(response.data));
  } catch (err) {
    console.log("CHANGE TEAM ADMIN FAILED", err.response.data);
    yield put(actions.changeTeamAdminFail(err.response.data.error));
  }
}

export function* leaveTeamSaga(params) {
  yield put(actions.leaveTeamStart());

  const api = 'v1/teamleave/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("LEAVE TEAM SUCCESS", response.data);
    yield put(actions.leaveTeamSuccess(response.data.result));
  } catch (err) {
    console.log("LEAVE TEAM FAILED", err.response);
    yield put(actions.leaveTeamFail(err.response));
  }
}