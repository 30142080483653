
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';

import { GET_HOOKS, ADD_HOOK, EDIT_HOOK, DELETE_HOOK, ENABLE_HOOK } from './types';

export function* watchHooks() {
  yield takeEvery(GET_HOOKS, getHooksSaga);
  yield takeEvery(ADD_HOOK, addHookSaga);
  yield takeEvery(ENABLE_HOOK, enableHookSaga);
  yield takeEvery(EDIT_HOOK, editHookSaga);
  yield takeEvery(DELETE_HOOK, deleteHookSaga);
}

export function* getHooksSaga(params) {
  yield put(actions.getHooksStart());
  let api = 'v1/hook?org_id=' + params.org_id;

  try {
    const response = yield axios.get(api);
    // console.log("GET HOOKS SUCCESS", response.data);
    yield put(actions.getHooksSuccess(response.data));
  } catch (err) {
    console.log("GET HOOKS FAILED", err)
    yield put(actions.getHooksFail(err.response));
  }
}

export function* addHookSaga(params) {
  yield put(actions.addHookStart());

  const api = 'v1/hook';
  let postData = 'device_secret=' + params.hookForm.device_secret;
  postData += '&name=' + encodeURIComponent(params.hookForm.name);
  postData += '&url=' + encodeURIComponent(params.hookForm.url);
  postData += '&headers=' + encodeURIComponent(JSON.stringify(params.hookForm.headers));
  postData += '&content=' + encodeURIComponent(params.hookForm.content);
  postData += params.hookForm.enable_lower_limit ? '&enable_lower_limit=true' : '&enable_lower_limit=false';
  postData += params.hookForm.enable_upper_limit ? '&enable_upper_limit=true' : '&enable_upper_limit=false';
  postData += params.hookForm.lower_limit ? '&lower_limit=' + params.hookForm.lower_limit : '&lower_limit=0';
  postData += params.hookForm.upper_limit ? '&upper_limit=' + params.hookForm.upper_limit : '&upper_limit=0';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD HOOK SUCCESS", response.data);
    yield put(actions.addHookSuccess(response.data));
  } catch (err) {
    console.log("ADD HOOK FAILED", err.response.data);
    yield put(actions.addHookFail(err.response.data));
  }
}

export function* editHookSaga(params) {
  yield put(actions.editHookStart());

  const api = 'v1/hook/' + params.id;
  // id, name, description, type, enable, emails, mobile_numbers, hook, sampling_size, claculation_method


  let putData = '';
  if (params.hookForm.device_secret)
    putData += 'device_secret=' + encodeURIComponent(params.hookForm.device_secret);
  if (params.hookForm.name)
    putData += '&name=' + encodeURIComponent(params.hookForm.name);
  if (params.hookForm.url)
    putData += '&url=' + encodeURIComponent(params.hookForm.url);
  if (params.hookForm.headers !== {})
    putData += '&headers=' + encodeURIComponent(JSON.stringify(params.hookForm.headers));
  if (params.hookForm.content)
    putData += '&content=' + encodeURIComponent(params.hookForm.content);
  putData += params.hookForm.enable_lower_limit ? '&enable_lower_limit=true' : '&enable_lower_limit=false';
  putData += params.hookForm.enable_upper_limit ? '&enable_upper_limit=true' : '&enable_upper_limit=false';
  putData += params.hookForm.lower_limit ? '&lower_limit=' + params.hookForm.lower_limit : '&lower_limit=0';
  putData += params.hookForm.upper_limit ? '&upper_limit=' + params.hookForm.upper_limit : '&upper_limit=0';

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT HOOK SUCCESS", response.data);
    yield put(actions.editHookSuccess(response.data));
  } catch (err) {
    console.log("EDIT HOOK FAILED", err.response.data);
    yield put(actions.editHookFail(err.response.data));
  }
}

export function* enableHookSaga(params) {
  yield put(actions.enableHookStart());

  const api = 'v1/hook/' + params.id;
  let putData = 'enable=' + params.enable;

  try {
    const response = yield axios.put(api, putData);
    console.log("ENABLE HOOK SUCCESS", response.data);
    yield put(actions.enableHookSuccess(response.data));
  } catch (err) {
    console.log("ENABLE HOOK FAILED", err.response.data);
    yield put(actions.enableHookFail(err.response.data));
  }
}

export function* deleteHookSaga(params) {
  yield put(actions.deleteHookStart());

  const api = 'v1/hook/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE HOOK SUCCESS", response.data);
    yield put(actions.deleteHookSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE HOOK FAILED", err.response);
    yield put(actions.deleteHookFail(err.response));
  }
}