import * as actionTypes from './types';

// ----------------------- SET SELECTED PRODUCTION PLAN -----------------------
export const setDefaultOperatorMachine = (defaultOperatorMachine) => {
  return {
    type: actionTypes.SET_DEFAULT_OPERATOR_MACHINE,
    defaultOperatorMachine
  };
}

// ----------------------- GET MACHINES -----------------------
export const getMachinesStart = () => {
  return {
    type: actionTypes.GET_MACHINES_START
  };
}

export const getMachines = (org_id) => {
  return {
    type: actionTypes.GET_MACHINES,
    org_id
  };
}

export const getMachinesSuccess = (response) => {
  return {
    type: actionTypes.GET_MACHINES_SUCCESS,
    machines: response.machines
  };
}

export const getMachinesFail = (error) => {
  return {
    type: actionTypes.GET_MACHINES_FAIL,
    error
  };
}

// ----------------------- GET MACHINES -----------------------
export const getMachinesAndActualsStart = () => {
  return {
    type: actionTypes.GET_MACHINES_AND_ACTUALS_START
  };
}

export const getMachinesAndActuals = (org_id) => {
  return {
    type: actionTypes.GET_MACHINES_AND_ACTUALS,
    org_id
  };
}

export const getMachinesAndActualsSuccess = (response) => {
  return {
    type: actionTypes.GET_MACHINES_AND_ACTUALS_SUCCESS,
    machinesAndActuals: response.machines
  };
}

export const getMachinesAndActualsFail = (error) => {
  return {
    type: actionTypes.GET_MACHINES_AND_ACTUALS_FAIL,
    error
  };
}

// ----------------------- ADD MACHINE -----------------------
export const addMachineStart = () => {
  return {
    type: actionTypes.ADD_MACHINE_START
  };
}

export const addMachine = (name, org_id, status_device_secret, status_device_socket, status_rules, total_device_secret, total_device_socket, good_device_secret, good_device_socket, defect_device_secret, defect_device_socket, properties) => {
  return {
    type: actionTypes.ADD_MACHINE,
    name, org_id, status_device_secret, status_device_socket, status_rules, total_device_secret, total_device_socket, good_device_secret, good_device_socket, defect_device_secret, defect_device_socket, properties
  };
}

export const addMachineSuccess = (response) => {
  return {
    type: actionTypes.ADD_MACHINE_SUCCESS,
    result: response.result,
    addedMachine: response.machines[0]
  };
}

export const addMachineFail = (error) => {
  return {
    type: actionTypes.ADD_MACHINE_FAIL,
    error
  };
}

// ----------------------- EDIT MACHINE -----------------------
export const editMachineStart = () => {
  return {
    type: actionTypes.EDIT_MACHINE_START
  };
}

export const editMachine = (id, name, status_device_secret, status_device_socket, status_rules, total_device_secret, total_device_socket, good_device_secret, good_device_socket, defect_device_secret, defect_device_socket, properties) => {
  return {
    type: actionTypes.EDIT_MACHINE,
    id, name, status_device_secret, status_device_socket, status_rules, total_device_secret, total_device_socket, good_device_secret, good_device_socket, defect_device_secret, defect_device_socket, properties
  };
}

export const editMachineSuccess = (response) => {
  return {
    type: actionTypes.EDIT_MACHINE_SUCCESS,
    result: response.result,
    updatedMachine: response.machines[0],
  };
}

export const editMachineFail = (error) => {
  return {
    type: actionTypes.EDIT_MACHINE_FAIL,
    error
  };
}

// ----------------------- DELETE MACHINE -----------------------
export const deleteMachineStart = () => {
  return {
    type: actionTypes.DELETE_MACHINE_START
  };
}

export const deleteMachine = (id) => {
  return {
    type: actionTypes.DELETE_MACHINE,
    id
  };
}

export const deleteMachineSuccess = (result) => {
  return {
    type: actionTypes.DELETE_MACHINE_SUCCESS,
    result
  };
}

export const deleteMachineFail = (error) => {
  return {
    type: actionTypes.DELETE_MACHINE_FAIL,
    error
  };
}

// ----------------------- GET APQ -----------------------
export const getApqOfMachineStart = () => {
  return {
    type: actionTypes.GET_APQ_OF_MACHINE_START
  };
}

export const getApqOfMachine = (id, start, end, properties) => {
  return {
    type: actionTypes.GET_APQ_OF_MACHINE,
    id, start, end, properties
  };
}

export const getApqOfMachineSuccess = (response, properties) => {
  return {
    type: actionTypes.GET_APQ_OF_MACHINE_SUCCESS,
    machineAPQ: response.apq,
    result: response.result,
    properties,
  };
}

export const getApqOfMachineFail = (error) => {
  return {
    type: actionTypes.GET_APQ_OF_MACHINE_FAIL,
    error
  };
}