import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Devices.module.scss';
import Button from '../../components/UI/Button/Button';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import AddDeviceDrawer from '../../components/Devices/AddDeviceDrawer/AddDeviceDrawer';
import EditDeviceDrawer from '../../components/Devices/EditDeviceDrawer/EditDeviceDrawer';
import EditDeviceSocketDrawer from '../../components/Devices/EditDeviceDrawer/EditDeviceSocketDrawer';
// import EditDeviceConfigDrawer from '../../components/Devices/EditDeviceDrawer/EditDeviceConfigDrawer';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import LoadingWideCard from '../../components/UI/LoadingSkeleton/LoadingWideCard/LoadingWideCard';
import DeviceCardList from '../../components/Devices/DeviceCardList/DeviceCardList';
import SystemDeviceConfigModal from '../../components/Devices/SystemDeviceConfig/SystemDeviceConfigModal';

import { ADD_OWL_DEVICES_SUCCESS, DELETE_DEVICE_SUCCESS, DELETE_OWL_DEVICES_SUCCESS } from '../../../state/ducks/Devices/types';
import { SAVE_SYSTEM_DEVICE_CONFIG_SUCCESS } from '../../../state/ducks/DeviceConfig/types';
import { getDevices, deleteDevice, deleteOwlDevices } from '../../../state/ducks/Devices/actions';
import { EDIT_DEVICE_SOCKETS_WITH_JSON_SUCCESS } from '../../../state/ducks/DeviceSocket/types';

class Devices extends Component {
  state = {
    isDrawerOpened: false,
    isAdding: true,
    editingDevice: null,
    editingDeviceSocket: null,
    editMode: 'device',
    isDeleteModalOpened: false,
    searchDevice: '',
    deviceConfigModalOpened: false,
  }

  componentDidMount() {
    localStorage.removeItem("device_open_id");
    const { devices, currentOrg } = this.props;
    if (!devices || !devices.length || devices[0].org_id !== currentOrg) {
      this.props.getDevices(currentOrg, true, true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.props.getDevices(this.props.currentOrg, true, true);
    } else if (this.props.result !== prevProps.result && this.props.result === 'success') {
      if ((this.props.type === ADD_OWL_DEVICES_SUCCESS) ||
        (prevProps.updatedDevice !== this.props.updatedDevice) ||
        (prevProps.addedDevice !== this.props.addedDevice) ||
        (this.props.type === DELETE_DEVICE_SUCCESS) ||
        (this.props.type === DELETE_OWL_DEVICES_SUCCESS)) {
        this.props.getDevices(this.props.currentOrg, true, true);
      } else {
        // TODO: need to get deleted device index
        // case: delete
        // this.props.tags.splice(this.state.deletingIndex, 1);
      }
    } else if (prevProps.updatedDeviceSockets !== this.props.updatedDeviceSockets) {
      this.props.getDevices(this.props.currentOrg, true, true);
    } else if (this.props.dcType === EDIT_DEVICE_SOCKETS_WITH_JSON_SUCCESS && this.props.dcResult !== prevProps.dcResult && this.props.dcResult === 'success') {
      this.props.getDevices(this.props.currentOrg, true, true);
    }
  }

  drawerToggleHandler = () => {
    this.setState(prevState => {
      return { isDrawerOpened: !prevState.isDrawerOpened }
    });
  }

  openDrawerForAddingHandler = () => {
    this.setState({ isAdding: true, editingDevice: null, editingDeviceSocket: null, isDrawerOpened: true });
  }

  onEditDeviceHandler = (event, deviceId) => {
    event.stopPropagation();
    let editingDevice = this.props.devices.find(device => device.id === deviceId);
    this.setState({ deviceConfigModalOpened: true, editingDevice });
  }

  onDeleteDeviceHandler = () => {
    this.setState({ isDeleteModalOpened: true });
  }

  onDeleteOwlDevicesHandler = (secret) => {
    this.setState({ deletingDeviceSecret: secret, isDeleteModalOpened: true });
  }

  deleteModalClickHandler = () => {
    this.setState(prevState => {
      return { isDeleteModalOpened: !prevState.isDeleteModalOpened }
    });
  }

  onConfirmDeleteDevice = (event) => {
    event.preventDefault();
    if (this.state.editingDevice.system_device) {
      this.props.deleteOwlDevices(this.props.currentOrg, this.state.editingDevice.secret);
    } else {
      this.props.deleteDevice(this.state.editingDevice.id);
    }
    this.setState({ editingDevice: null, isDeleteModalOpened: false, isDrawerOpened: false, deviceConfigModalOpened: false });
  }

  handleSearchChange = event => {
    this.setState({ searchDevice: event.target.value });
  }

  systemDeviceConfigClickHandler = () => {
    this.setState(prevState => {
      return { deviceConfigModalOpened: !prevState.deviceConfigModalOpened }
    });
  }

  render() {
    let { devices } = this.props;

    const isAdmin = (this.props.loggedInUser.admin_of_org && this.props.loggedInUser.admin_of_org.includes(+this.props.currentOrg)) || (this.props.loggedInUser && this.props.loggedInUser.is_superuser);

    let currentSecretCount = '';
    let filteredDevice = null;
    let secretDeviceCount = [];
    let count = 1;
    if (devices) {
      const searchText = this.state.searchDevice.toLowerCase();
      filteredDevice = devices.filter(
        device => {
          const foundOnDevice = device.name.toLowerCase().indexOf(searchText) !== -1 || device.secret.toLowerCase().indexOf(searchText) !== -1
          if (foundOnDevice) {
            return true;
          } else {
            return device.device_sockets.find(ds => ds.name.toLowerCase().indexOf(searchText) !== -1 || ds.location.toLowerCase().indexOf(searchText) !== -1)
          }
        }
      );
      for (let index in devices) {
        if (currentSecretCount !== devices[index].secret) {
          secretDeviceCount.push({ secret: currentSecretCount, count: count });
          count = 1;
          currentSecretCount = devices[index].secret;
        } else {
          count++;
        }
        if (+index === devices.length - 1) {
          secretDeviceCount.push({ secret: devices[index].secret, count: count });
        }
      }
    }

    return (
      <div className={styles.Devices}>
        <SideDrawer title={this.state.isAdding ? 'Add New Device' : 'Edit Device'} open={this.state.isDrawerOpened} closed={this.drawerToggleHandler}>
          {
            this.state.isAdding ?
              <AddDeviceDrawer closed={this.drawerToggleHandler} />
              :
              this.state.editMode === 'device' ?
                <EditDeviceDrawer
                  editingDevice={this.state.editingDevice}
                  closed={this.drawerToggleHandler}
                  toggleDeleteModal={this.onDeleteDeviceHandler}
                />
                :
                this.state.editMode === 'deviceSocket' ?
                  <EditDeviceSocketDrawer
                    editingDeviceSocket={this.state.editingDeviceSocket}
                    closed={this.drawerToggleHandler}
                    toggleDeleteModal={this.onDeleteDeviceHandler}
                  />
                  :
                  null
            // <EditDeviceConfigDrawer
            //   editingDevice={this.state.editingDevice}
            //   closed={this.drawerToggleHandler}
            // />
          }
        </SideDrawer>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            <div className={styles.Menu}><span className={styles.MenuTitle}>Devices</span></div>
          </div>
          {
            isAdmin &&
            <div className={styles.AddButtonWrapper}>
              <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Device" noMargin click={this.openDrawerForAddingHandler} />
            </div>
          }
        </div>
        <div className="MainContentPadding">
          <div className={styles.DevicesTable}>
            <input type="text" value={this.state.searchDevice} onChange={this.handleSearchChange} className={styles.SearchInput} placeholder="Search Device by name, secret" />
            {this.props.loading || this.props.dsLoading || (this.props.dcLoading && this.props.dcType === SAVE_SYSTEM_DEVICE_CONFIG_SUCCESS) ?
              <LoadingWideCard /> :
              <DeviceCardList
                filteredDevice={filteredDevice}
                editDevice={this.onEditDeviceHandler}
              />
            }
          </div>
        </div>

        <SystemDeviceConfigModal
          open={this.state.deviceConfigModalOpened}
          modalClosed={this.systemDeviceConfigClickHandler}
          editingDevice={this.state.editingDevice}
          onDelete={this.deleteModalClickHandler}
        />
        <DeleteModal
          title="device"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeleteDevice}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { loggedInUser } = state.auth;
  const { type, result, devices, addedDevice, updatedDevice, loading } = state.devices;
  const { updatedDeviceSockets } = state.deviceSockets;
  return {
    currentOrg, type, result, devices, addedDevice, updatedDevice, loggedInUser, loading,
    updatedDeviceSockets, dsLoading: state.deviceSockets.loading,
    dcResult: state.deviceConfigs.result, dcLoading: state.deviceConfigs.loading, dcType: state.deviceConfigs.type
  };
};

export default connect(mapStateToProps, { getDevices, deleteDevice, deleteOwlDevices })(Devices);
