import React, { Component } from 'react';

import styles from './OrganizationDeleteTooltip.module.scss';
import Backdrop from '../../UI/Backdrop/NoColorBackdrop';

class OrganizationDeleteTooltip extends Component {

  render() {
    let modalStyles = {
      opacity: this.props.show ? '1' : '0',
      visibility: this.props.show? 'visible' : 'hidden'
    };

    let tooltipTextStyles = styles.tooltiptext;
    if(this.props.admin){
      tooltipTextStyles = styles.admintooltiptext;
    }

    return (
      <React.Fragment>
        <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
        <div className={styles.tooltip}>
          <div className={tooltipTextStyles} style={modalStyles}>
            <div style={{ position: 'relative' ,margin: 30, textAlign: "left" }}>
              {this.props.children}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OrganizationDeleteTooltip;