
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_ERROR_LOGS, ADD_ERROR_LOG, UPDATE_ERROR_LOG } from './types';

export function* watchErrorLogs() {
  yield takeEvery(GET_ERROR_LOGS, getErrorLogsSaga);
  yield takeEvery(ADD_ERROR_LOG, addErrorLogSaga);
  yield takeEvery(UPDATE_ERROR_LOG, updateErrorLogSaga);
}

export function* getErrorLogsSaga(params) {
  yield put(actions.getErrorLogsStart());

  let api = 'v1/errorlog';
  let paramsQuery = params.user_id || params.start || params.end || params.offset || params.limit ? '?' : '';
  paramsQuery += params.user_id ? 'user_id=' + params.user_id : '';

  paramsQuery += params.start && paramsQuery !== '' ? '&' : '';
  paramsQuery += params.start ? 'start=' + params.start : '';

  paramsQuery += params.end && paramsQuery !== '' ? '&' : '';
  paramsQuery += params.end ? 'end=' + params.end : '';

  paramsQuery += params.offset && paramsQuery !== '' ? '&' : '';
  paramsQuery += params.offset ? 'offset=' + params.offset : '';

  paramsQuery += params.limit && paramsQuery !== '' ? '&' : '';
  paramsQuery += params.limit ? 'limit=' + params.limit : '';

  api += paramsQuery;
  try {
    const response = yield axios.get(api);
    // console.log("GET ERROR LOGS SUCCESS", response.data);
    yield put(actions.getErrorLogsSuccess(response.data));
  } catch (err) {
    // console.log("GET ERROR LOGSS FAILED", err)
    yield put(actions.getErrorLogsFail(err && err.response ? err.response.error : 'Something went wrong'));
  }
}

export function* addErrorLogSaga(params) {
  yield put(actions.addErrorLogStart());

  let api = 'v1/errorlog';
  let postData = "log_str=" + encodeURIComponent(params.log_str);
  postData += params.message ? "&message=" + encodeURIComponent(params.message) : '';
  postData += params.req_url ? "&req_url=" + encodeURIComponent(params.req_url) : '';
  postData += params.req_method ? "&req_method=" + params.req_method : '';
  postData += params.req_header ? "&req_header=" + params.req_header : '';
  postData += params.req_body ? "&req_body=" + encodeURIComponent(params.req_body) : '';
  postData += params.resp_header ? "&resp_header=" + params.resp_header : '';
  postData += params.resp_body ? "&resp_body=" + encodeURIComponent(params.resp_body) : '';
  postData += params.status_code ? "&status_code=" + params.status_code : '';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD ERRORLOGS SUCCESS", response.data);
    yield put(actions.addErrorLogSuccess(response.data));
  } catch (err) {
    // yield put(actions.addErrorLogFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* updateErrorLogSaga(params) {
  yield put(actions.updateErrorLogStart());

  let api = 'v1/errorlog/' + params.uuid;
  let putData = "message=" + encodeURIComponent(params.message);
  putData += params.log_str ? "&log_str=" + encodeURIComponent(params.log_str) : '';
  putData += params.req_url ? "&req_url=" + encodeURIComponent(params.req_url) : '';
  putData += params.req_method ? "&req_method=" + params.req_method : '';
  putData += params.req_header ? "&req_header=" + params.req_header : '';
  putData += params.req_body ? "&req_body=" + encodeURIComponent(params.req_body) : '';
  putData += params.resp_header ? "&resp_header=" + params.resp_header : '';
  putData += params.resp_body ? "&resp_body=" + encodeURIComponent(params.resp_body) : '';
  putData += params.status_code ? "&status_code=" + params.status_code : '';

  try {
    const response = yield axios.put(api, putData);
    // console.log("UPDATE ERROR LOGS SUCCESS", response.data);
    yield put(actions.updateErrorLogSuccess(response.data));
  } catch (err) {
    yield put(actions.updateErrorLogFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}