import React, { Component } from 'react';

import ConditionForm from '../ConditionForm/ConditionForm';
import styles from './RuleCondition.module.scss';

class RuleCondition extends Component {
  render() {
    return (
      <React.Fragment>
        <div className={styles.Label}>if *</div>
        {
          this.props.allConditionsForm ? this.props.allConditionsForm.map((con, index) => {
            if (this.props.allConditionsForm.length === 1 && index === 0) {
              return (
                <ConditionForm
                  key={index}
                  addIndex={index}
                  condition={this.props.conditions[index]}
                  deviceOptions={this.props.deviceOptions}
                  conditionChange={this.props.conditionChange}
                  addNewCondition={this.props.addNewCondition}
                  firstCondition />
              )
            } else if (index === 0) {
              return (
                <ConditionForm
                  key={index}
                  addIndex={index}
                  condition={this.props.conditions[index]}
                  deviceOptions={this.props.deviceOptions}
                  conditionChange={this.props.conditionChange}
                  removeCondition={() => this.props.removeCondition(index)}
                  firstCondition />
              )
            } else if (this.props.allConditionsForm.length - 1 === index) {
              return (
                <ConditionForm
                  key={index}
                  addIndex={index}
                  condition={this.props.conditions[index]}
                  deviceOptions={this.props.deviceOptions}
                  conditionChange={this.props.conditionChange}
                  addNewCondition={this.props.addNewCondition}
                  removeCondition={() => this.props.removeCondition(index)} />
              )
            } else {
              return (
                <ConditionForm
                  key={index}
                  addIndex={index}
                  condition={this.props.conditions[index]}
                  deviceOptions={this.props.deviceOptions}
                  conditionChange={this.props.conditionChange}
                  removeCondition={() => this.props.removeCondition(index)} />
              )
            }
          }
          ) : null
        }
      </React.Fragment>
    );
  }
}

export default RuleCondition;
