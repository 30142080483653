export const GET_RULE_COMMANDS_START = 'GET_RULE_COMMANDS_START';
export const GET_RULE_COMMANDS = 'GET_RULE_COMMANDS';
export const GET_RULE_COMMANDS_SUCCESS = 'GET_RULE_COMMANDS_SUCCESS';
export const GET_RULE_COMMANDS_FAIL = 'GET_RULE_COMMANDS_FAIL';

export const ADD_RULE_COMMAND_START = 'ADD_RULE_COMMAND_START';
export const ADD_RULE_COMMAND = 'ADD_RULE_COMMAND';
export const ADD_RULE_COMMAND_SUCCESS = 'ADD_RULE_COMMAND_SUCCESS';
export const ADD_RULE_COMMAND_FAIL = 'ADD_RULE_COMMAND_FAIL';

export const EDIT_RULE_COMMAND_START = 'EDIT_RULE_COMMAND_START';
export const EDIT_RULE_COMMAND = 'EDIT_RULE_COMMAND';
export const EDIT_RULE_COMMAND_SUCCESS = 'EDIT_RULE_COMMAND_SUCCESS';
export const EDIT_RULE_COMMAND_FAIL = 'EDIT_RULE_COMMAND_FAIL';

export const DELETE_RULE_COMMAND_START = 'DELETE_RULE_COMMAND_START';
export const DELETE_RULE_COMMAND = 'DELETE_RULE_COMMAND';
export const DELETE_RULE_COMMAND_SUCCESS = 'DELETE_RULE_COMMAND_SUCCESS';
export const DELETE_RULE_COMMAND_FAIL = 'DELETE_RULE_COMMAND_FAIL';

