
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
// import * as pageActions from '../Pages/actions';
import { ADD_WIDGET, DELETE_WIDGET, EDIT_WIDGET, EDIT_WIDGET_LAYOUT } from './types';

export function* watchWidget() {
  yield takeEvery(ADD_WIDGET, addWidgetSaga);
  yield takeEvery(EDIT_WIDGET, editWidgetSaga);
  yield takeEvery(EDIT_WIDGET_LAYOUT, editWidgetLayoutSaga);
  yield takeEvery(DELETE_WIDGET, deleteWidgetSaga);
}

export function* addWidgetSaga(params) {
  yield put(actions.addWidgetStart());

  let postData = 'title=' + encodeURIComponent(params.title);
  postData += "&type=" + params.widget_type;
  postData += "&device_id=" + params.device_id;
  postData += "&x=" + params.x;
  postData += "&y=" + params.y;
  postData += "&w=" + params.w;
  postData += "&h=" + params.h;
  postData += params.formula ? "&formula=" + encodeURIComponent(params.formula) : '';
  postData += params.formula_output ? "&formula_output=" + encodeURIComponent(params.formula_output) : '';
  postData += params.formula_object ? "&formula_object=" + params.formula_object : '';
  postData += params.properties ? '&properties=' + encodeURIComponent(JSON.stringify(params.properties)) : '';

  const api = 'v1/pagewidget/' + params.id;

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD WIDGET SUCCESS", response);
    yield put(actions.addWidgetSuccess(response.data));
    // yield put(pageActions.updatePageAfterAddWidget(response.data));
  } catch (err) {
    console.log("ADD WIDGET FAILED", err.response.data);
    yield put(actions.addWidgetFail(err.response.data.error));
  }
}

export function* editWidgetSaga(params) {
  yield put(actions.editWidgetStart());

  let putData = 'title=' + encodeURIComponent(params.title);
  putData += "&type=" + params.widget_type;
  putData += "&device_id=" + params.device_id;
  putData += "&x=" + params.x;
  putData += "&y=" + params.y;
  putData += "&w=" + params.w;
  putData += "&h=" + params.h;
  putData += params.formula ? "&formula=" + encodeURIComponent(params.formula) : '';
  putData += params.formula_output ? "&formula_output=" + encodeURIComponent(params.formula_output) : '';
  putData += params.formula_object ? "&formula_object=" + params.formula_object : '';

  if (params.properties.gaugeProps && params.properties.gaugeProps.formula) {
    params.properties.gaugeProps.formula = encodeURIComponent(params.properties.gaugeProps.formula);
  }
  putData += params.properties ? "&properties=" + encodeURIComponent(JSON.stringify(params.properties)) : "";

  const api = 'v1/pagewidget/' + params.id + '/' + params.wid;

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT WIDGET SUCCESS", response.data);
    yield put(actions.editWidgetSuccess(response.data));
  } catch (err) {
    console.log("EDIT WIDGET FAILED", err.response.data);
    yield put(actions.editWidgetFail(err.response.data));
  }
}

export function* editWidgetLayoutSaga(params) {
  yield put(actions.editWidgetLayoutStart());

  let putData = "x=" + params.x;
  putData += "&y=" + params.y;
  putData += "&w=" + params.w;
  putData += "&h=" + params.h;

  const api = 'v1/pagewidget/' + params.id + '/' + params.wid;

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT WIDGET LAYOUT SUCCESS", response.data);
    yield put(actions.editWidgetLayoutSuccess(response.data));
  } catch (err) {
    console.log("EDIT WIDGET LAYOUT FAILED", err.response.data);
    yield put(actions.editWidgetLayoutFail(err.response.data));
  }
}

export function* deleteWidgetSaga(params) {
  yield put(actions.deleteWidgetStart());

  const api = 'v1/pagewidget/' + params.id + '/' + params.wid;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE WIDGET SUCCESS", response.data);
    yield put(actions.deleteWidgetSuccess(response.data.result, params.wid));
    // yield put(pageActions.updatePageAfterAddWidget(response.data));
  } catch (err) {
    console.log("DELETE WIDGET FAILED", err.response);
    yield put(actions.deleteWidgetFail(err.response));
  }
}