import React, { Component } from 'react'

import styles from '../../EditDeviceDrawer/EditDevice.module.scss';
import Input from '../../../UI/Input/Input';
import Select from '../../../UI/Select/Select';
import SimpleSwitch from '../../../UI/SimpleSwitch/SimpleSwitch';

import { updateObject, checkValidity } from '../../../../../state/utils';
import { MODBUS_ACTION_TYPES } from '../variable';

class ModbusForm extends Component {
  state = {
    formIsValid: false,
    modbusForm: this.props.defaultModbusForm,
    overTcp: false,
    hasModbusActions: false
  }

  componentDidMount() {
    if (this.props.modbusInfo) {
      this.setupModbusForm(this.props.modbusInfo);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.modbusInfo !== this.props.modbusInfo) {
      this.setupModbusForm(this.props.modbusInfo);
    }
  }

  setupModbusForm = (modbusInfo) => {
    const { overTcpState, modbusActionsState } = this.props;
    this.setState({ modbusForm: modbusInfo, formIsValid: true, overTcp: overTcpState ? overTcpState : false, hasModbusActions: modbusActionsState ? modbusActionsState : false });
  }

  handleTextChange = event => {
    const { name, value } = event.target;
    this.formChanges(name, value, false);
  }

  decimalToHex = v => {
    const actionLength = this.state.modbusForm.actionLength.value.id;
    let hex, result;
    if (actionLength === 1) {
      hex = (Number(v) + 0x100).toString(16).substr(-2).toUpperCase();
      result = '0x' + hex;
    } else {
      hex = (Number(v) + 0x10000).toString(16).substr(-4).toUpperCase();
      result = '0x' + hex.substr(0, 2) + ' 0x' + hex.substr(2);
    }
    return result;
  }

  formChanges = (name, value, skipValidation) => {
    let updatedElement;
    if (name === 'socket') {
      updatedElement = updateObject(this.state.modbusForm[name], {
        value,
        valid: this.props.canUseSocketNumber(this.props.editIndex, value),
        touched: true
      });
    } else {
      updatedElement = updateObject(this.state.modbusForm[name], {
        value,
        valid: skipValidation ? true : checkValidity(value, this.state.modbusForm[name].validation),
        touched: true
      });
    }

    let updatedForm = updateObject(this.state.modbusForm, {
      [name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ modbusForm: updatedForm, formIsValid });
    this.props.handleModbusFormChange(
      this.props.editIndex,
      updatedForm,
      formIsValid,
      this.state.overTcp,
      this.state.hasModbusActions
    );
  }

  // validateValue = (value, validation) => {
  //   const result = checkValidity(value, validation);
  //   if (result) {
  //     checkMoreValidationForRange
  //   } else {
  //     return result;
  //   }
  // }

  handleSelectChange = event => {
    const { name, value } = event.target;
    let selectedValue;
    if (name === 'length' || name === 'actionLength') {
      selectedValue = this.props.lengthOptions[value - 1];
      if (value === '2' && name !== 'actionLength') {
        this.setDefaultOrderAndVariable();
      } else {
        this.formChanges(name, selectedValue, true);
      }
    } else if (name === 'order') {
      selectedValue = this.props.orderOptions.filter(ord => ord.id === value)[0];
      this.formChanges(name, selectedValue, true);
    } else if (name === 'dataType') {
      selectedValue = this.props.variableOptions.filter(va => va.id === value)[0];
      this.formChanges(name, selectedValue, true);
    } else if (name === 'actionType') {
      selectedValue = MODBUS_ACTION_TYPES.filter(ma => ma.id === value)[0];
      this.formChanges(name, selectedValue, true);
    }
  }

  setDefaultOrderAndVariable = () => {
    let updatedLength = updateObject(this.state.modbusForm['length'], {
      value: this.props.lengthOptions[1],
      valid: true,
      touched: true
    });
    let updatedOrder = updateObject(this.state.modbusForm['order'], {
      value: this.props.orderOptions[0],
      valid: true,
      touched: true
    });
    let updatedVariable = updateObject(this.state.modbusForm['dataType'], {
      value: this.props.variableOptions[0],
      valid: true,
      touched: true
    });

    let updatedForm = updateObject(this.state.modbusForm, {
      length: updatedLength,
      order: updatedOrder,
      variable: updatedVariable
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ modbusForm: updatedForm, formIsValid });
    this.props.handleModbusFormChange(
      this.props.editIndex,
      updatedForm,
      formIsValid,
      this.state.overTcp,
      this.state.hasModbusActions
    );
  }

  checkMoreValidationForRange = (value, min, max) => {
    let error = '';
    if (value < min || value > max) {
      return 'The value must be between ' + min + ' to ' + max;
    }
    return error;
  }

  onChangeModbusActionsHandler = (event) => {
    event.stopPropagation();
    this.setState(prevState => {
      return { hasModbusActions: !prevState.hasModbusActions };
    });
    this.props.handleModbusFormChange(
      this.props.editIndex,
      this.state.modbusForm,
      true,
      this.state.overTcp,
      !this.state.hasModbusActions
    );
  }

  onChangeOverTcpHandler = (event) => {
    event.stopPropagation();
    let formIsValid = true;
    if (this.state.overTcp) {
      for (let inputIdentifier in this.state.modbusForm) {
        if (inputIdentifier !== 'ip' && inputIdentifier !== 'port') {
          formIsValid = this.state.modbusForm[inputIdentifier].valid && formIsValid;
        }
      }
    } else {
      for (let inputIdentifier in this.state.modbusForm) {
        formIsValid = this.state.modbusForm[inputIdentifier].valid && formIsValid;
      }
    }

    this.setState(prevState => {
      return { overTcp: !prevState.overTcp, formIsValid }
    });
    this.props.handleModbusFormChange(
      this.props.editIndex,
      this.state.modbusForm,
      formIsValid,
      !this.state.overTcp,
      this.state.hasModbusActions
    );
  }

  render() {
    const { display_name, location, functions, unit_name, tag, socket, ip, port, address, reg, pb_function, length, order, dataType, actionType, actionFunction, actionRegister, actionLength, actionValue } = this.state.modbusForm;

    return (
      <>
        <Input
          labelin="true"
          label="Name"
          name="display_name"
          type="text"
          value={display_name.value}
          placeholder="Modbus data 1"
          autoComplete="off"
          onChange={this.handleTextChange}
          error={`${display_name.touched && !display_name.valid ? 'name is required and not duplicate with other socket name.' : ''}`}
          required
        />
        <Input
          labelin="true"
          label="Socket NO."
          name="socket"
          type="number"
          value={socket.value}
          placeholder={`(21-35)`}
          autoComplete="off"
          onChange={this.handleTextChange}
          error={`${socket.touched && !socket.valid ? 'socket is required and not duplicate with other socket no. (21-35)' : ''}`}
          required
        />
        <Input
          labelin="true"
          label="Location"
          name="location"
          type="text"
          value={location.value}
          placeholder="Please insert your sensor location"
          autoComplete="off"
          onChange={this.handleTextChange}
        />

        <Input
          labelin="true"
          label="Unit"
          name="unit_name"
          type="text"
          value={unit_name.value}
          placeholder="m/min"
          autoComplete="off"
          onChange={this.handleTextChange}
        />

        <Input
          labelin="true"
          label="Function"
          name="functions"
          type="text"
          value={functions.value}
          placeholder="x 109 / 23"
          autoComplete="off"
          onChange={this.handleTextChange}
        />

        <Input
          labelin="true"
          label="Tag"
          name="tag"
          type="text"
          value={tag.value}
          placeholder="Tag1,Tag2,..."
          autoComplete="off"
          onChange={this.handleTextChange}
        />
        <div className={styles.ModbusWrapper} style={{ margin: '0 0 15px 0' }}>
          <div className={styles.Title}>Over TCP</div>
          <SimpleSwitch enable={this.state.overTcp} onChangeEnableHandler={this.onChangeOverTcpHandler} />
        </div>
        {
          this.state.overTcp ?
            <>
              <Input
                labelin="true"
                label="IP"
                name="ip"
                type="text"
                value={ip.value}
                placeholder="e.g. 192.168.1.1"
                autoComplete="off"
                onChange={this.handleTextChange}
                error={`${ip.touched && !ip.valid ? 'IP is required and must be in a correct IP Address pattern' : ''}`}
              />
              <Input
                labelin="true"
                label="Port"
                name="port"
                type="number"
                value={port.value}
                placeholder="e.g. 8080"
                autoComplete="off"
                onChange={this.handleTextChange}
                error={`${port.touched && !port.valid ? 'Port is required and must be between 0 - 65535' : this.checkMoreValidationForRange(+port.value, 0, 65535)}`}
              />
            </>
            : null
        }
        {
          !this.state.overTcp ?
            <Input
              labelin="true"
              label="Device ID"
              name="address"
              type="number"
              value={address.value}
              placeholder="(0-255)"
              autoComplete="off"
              onChange={this.handleTextChange}
              error={`${address.touched && !address.valid ? 'Device ID is required and must be between 0 - 255' : this.checkMoreValidationForRange(+address.value, 0, 255)}`}
              style={{ flex: 1 }}
            />
            : null
        }
        <Input
          labelin="true"
          label="Register"
          name="reg"
          value={reg.value}
          type="number"
          placeholder="(0-9999)"
          onChange={this.handleTextChange}
          error={`${reg.touched && !reg.valid ? 'Register is required and must be between 0 - 9999' : this.checkMoreValidationForRange(+reg.value, 0, 9999)}`}
          style={{ flex: 1 }}
        />
        <Input
          labelin="true"
          label="Function"
          name="pb_function"
          type="number"
          value={pb_function.value}
          placeholder="(0-7)"
          autoComplete="off"
          onChange={this.handleTextChange}
          error={`${pb_function.touched && !pb_function.valid ? 'Function is required and must be between 0 - 7' : this.checkMoreValidationForRange(+pb_function.value, 0, 7)}`}
        />
        <Select
          labelin="true"
          label="Length"
          name="length"
          options={this.props.lengthOptions}
          placeholder="Select Length"
          value={length.value.id}
          onChange={this.handleSelectChange}
          overidestyleformgroup={{ marginBottom: '1rem' }}
        />

        {
          this.state.modbusForm.length.value.id === 2 ?
            <>
              <Select
                labelin="true"
                label="Order"
                name="order"
                options={this.props.orderOptions}
                placeholder="Select Order"
                value={order.value.id}
                onChange={this.handleSelectChange}
                overidestyleformgroup={{ marginBottom: '1rem' }}
              />
              <Select
                labelin="true"
                label="Data Type"
                name="dataType"
                options={this.props.variableOptions}
                placeholder="Select Variable"
                value={dataType.value.id}
                onChange={this.handleSelectChange}
                overidestyleformgroup={{ marginBottom: '1rem' }}
              />
            </>
            : null
        }

        <div className={styles.ModbusWrapper} style={{ margin: '0 0 15px 0' }}>
          <div className={styles.Title}>Modbus Actions</div>
          <SimpleSwitch enable={this.state.hasModbusActions} onChangeEnableHandler={this.onChangeModbusActionsHandler} />
        </div>

        {
          this.state.hasModbusActions ?
            <>
              <Select
                labelin="true"
                label="Action Type"
                name="actionType"
                options={MODBUS_ACTION_TYPES}
                placeholder="Select Action Type"
                value={actionType.value.id}
                onChange={this.handleSelectChange}
                overidestyleformgroup={{ marginBottom: '1rem' }}
              />
              <Input
                labelin="true"
                label="Action Register"
                name="actionRegister"
                value={actionRegister.value}
                type="number"
                placeholder="(0-9999)"
                onChange={this.handleTextChange}
                error={`${actionRegister.touched && !actionRegister.valid ? 'Action Register is required and must be between 0 - 9999' : this.checkMoreValidationForRange(+actionRegister.value, 0, 9999)}`}
                style={{ flex: 1 }}
              />
              <Input
                labelin="true"
                label="Action Function"
                name="actionFunction"
                type="number"
                value={actionFunction.value}
                placeholder="(0-16)"
                autoComplete="off"
                onChange={this.handleTextChange}
                error={`${actionFunction.touched && !actionFunction.valid ? 'Action Function is required and must be between 0 - 7' : this.checkMoreValidationForRange(+actionFunction.value, 0, 16)}`}
              />
              <Select
                labelin="true"
                label="Action Length"
                name="actionLength"
                options={this.props.lengthOptions}
                placeholder="Select Action Length"
                value={actionLength.value.id}
                onChange={this.handleSelectChange}
                overidestyleformgroup={{ marginBottom: '1rem' }}
              />
              <Input
                labelin="true"
                label="Action Value"
                name="actionValue"
                type="number"
                value={actionValue.value}
                placeholder={`(0-${actionLength.value.id === 1 ? '255)' : '65535)'}`}
                autoComplete="off"
                onChange={this.handleTextChange}
                error={`${actionValue.touched && !actionValue.valid ? 'Action Value is required and must be between 0 - 7' : this.checkMoreValidationForRange(+actionValue.value, 0, actionLength.value.id === 1 ? 255 : 65535)}`}
                overidestyleformgroup={{ marginBottom: '0.5rem' }}
              />
              {actionValue.value && Number(actionValue.value) >= 0 ? <div style={{ textAlign: 'right', fontSize: 11, opacity: 0.75 }}>{this.decimalToHex(actionValue.value)}</div> : null}
            </>
            : null
        }

        <div style={{ height: 15, width: '100%' }} />
      </>
    )
  }
}

export default ModbusForm;