import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Pages.module.scss';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import PageDrawer from '../../components/Pages/PageDrawer/PageDrawer';
import PageCard from '../../components/Pages/PageCard/PageCard';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import LoadingCard from '../../components/UI/LoadingSkeleton/LoadingCard/LoadingCard';
import FolderIcon from '../../../assets/icons/folder.svg';
import FilterGrayIcon from '../../../assets/icons/filter-gray.svg';
import FilterBlueIcon from '../../../assets/icons/filter-blue.svg';
import FilterMenu from '../../components/Pages/FilterMenu/FilterMenu';

import { getPages, deletePage, leavePage } from '../../../state/ducks/Pages/actions';
import { DELETE_PAGE_SUCCESS, LEAVE_PAGE_SUCCESS } from '../../../state/ducks/Pages/types';
import { addUserFavorite, getUserFavorites, deleteUserFavorite } from '../../../state/ducks/UserFavorite/actions';
import { DELETE_USER_FAVORITE_SUCCESS } from '../../../state/ducks/UserFavorite/types';

const FAVORITE_TYPE = 1;

const FilterTypes = [
  { id: 'all', name: 'All Pages' },
  { id: 'favorite', name: 'Favorite Pages' }
];

class Pages extends Component {
  state = {
    isDrawerOpened: false,
    isAdding: false,
    editingPage: null,
    isDeleteModalOpened: false,
    deletingPageId: null,
    isFilterMenuOpened: false,
    selectedFilterType: 'all',
    filteredPages: []
  }

  componentDidMount() {
    if (this.props.currentOrg) {
      this.props.getPages(this.props.currentOrg);
      if (!this.props.favorites) {
        this.props.getUserFavorites(this.props.currentOrg, FAVORITE_TYPE);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg) {
      this.props.getPages(this.props.currentOrg);
      this.props.getUserFavorites(this.props.currentOrg, FAVORITE_TYPE);
    } else {
      if (this.props.result !== prevProps.result && this.props.result === 'success') {
        if ((prevProps.addedPage !== this.props.addedPage) || (prevProps.updatedPage !== this.props.updatedPage)
          || [DELETE_PAGE_SUCCESS, LEAVE_PAGE_SUCCESS].includes(this.props.type)) {
          this.props.getPages(this.props.currentOrg);
        }
      }
      if (this.props.favoriteResult !== prevProps.favoriteResult && this.props.favoriteResult === 'success') {
        if ((prevProps.addedFavorite !== this.props.addedFavorite) || this.props.favoriteType === DELETE_USER_FAVORITE_SUCCESS) {
          this.props.getUserFavorites(this.props.currentOrg, FAVORITE_TYPE);
        }
      }
      if (this.props.pages !== prevProps.pages || this.props.favorites !== prevProps.favorites) {
        this.setupFilterPage(this.state.selectedFilterType);
      }
    }
  }

  drawerClosedHandler = () => {
    this.setState({ isDrawerOpened: false });
  }

  openDrawerForAddingHandler = () => {
    this.setState({ isAdding: true, editingPage: null, isDrawerOpened: true });
  }

  onEditPage = (id) => {
    const foundPage = this.props.pages.find(p => p.id === id);
    this.setState({ isAdding: false, editingPage: foundPage, isDrawerOpened: true });
  }

  onPageClick = (page) => {
    if (page.page_type === 3) {
      localStorage.setItem('default_day_starting_time', page.properties.day_starting_time ? page.properties.day_starting_time : '00:00');
      this.props.route.history.push(`/timeline/${page.id}`);
      return;
    } else if (page.page_type === 4) {
      this.props.route.history.push(`/floorplan/${page.id}`);
      return;
    }
    this.props.route.history.push(`/pages/${page.id}`);
  }

  onDeletePageHandler = (event, id) => {
    event.stopPropagation();
    this.setState({ deletingPageId: id, isDeleteModalOpened: true });
  }

  deleteModalClickHandler = () => {
    this.setState(prevState => {
      return { isDeleteModalOpened: !prevState.isDeleteModalOpened }
    });
  }

  onConfirmDeletePage = (event) => {
    event.preventDefault();
    this.props.deletePage(this.state.deletingPageId);
    this.setState({ deletingPageId: null, isDeleteModalOpened: false });
  }

  leavePageHandler = () => {
    this.props.leavePage(this.state.editingPage.id);
    this.setState({ deletingPageId: null, isDeleteModalOpened: false, isDrawerOpened: false, editingPage: null });
  }

  onHandlePageFavorite = (event, type, id) => {
    event.stopPropagation();
    if (type === 'fav') {
      this.props.addUserFavorite(id, FAVORITE_TYPE);
    } else {
      this.props.deleteUserFavorite(id);
    }
  }

  openFilterMenuHandler = () => {
    this.setState({ isFilterMenuOpened: true });
  }

  closedFilterMenuHandler = () => {
    this.setState({ isFilterMenuOpened: false });
  }

  handleFilterTypeChange = (event) => {
    this.setupFilterPage(event.target.value);
    this.setState({ selectedFilterType: event.target.value });
  }

  setupFilterPage = (selectedFilterType) => {
    let filteredPages = [];
    if (this.props.pages) {
      this.props.pages.forEach(page => {
        let favorite = null;
        if (this.props.favorites) {
          favorite = this.props.favorites.find(fav => fav.target_id === page.id && fav.user_id === this.props.loggedInUser.id);
        }
        if (favorite) {
          filteredPages.push({ data: page, favorite: favorite });
        }
      });
      if (selectedFilterType === 'all') {
        this.props.pages.forEach(page => {
          let favorite = null;
          if (this.props.favorites) {
            favorite = this.props.favorites.find(fav => fav.target_id === page.id && fav.user_id === this.props.loggedInUser.id);
          }
          if (!favorite) {
            filteredPages.push({ data: page, favorite: favorite });
          }
        });
      }
    }
    this.setState({ filteredPages })
  }

  render() {
    let FilterIcon = this.state.isFilterMenuOpened ? FilterBlueIcon : FilterGrayIcon;
    let isFilterSelected = FilterTypes.find(type => type.id === this.state.selectedFilterType);

    let isMobile = this.props.isMobile;

    return (
      <div className="MainContentPadding" style={isMobile ? { paddingTop: "20px" } : {}}>
        <FilterMenu
          show={this.state.isFilterMenuOpened}
          closed={this.closedFilterMenuHandler}
          filterTypes={FilterTypes}
          handleTypeChange={this.handleFilterTypeChange}
          selectedFilterType={this.state.selectedFilterType}
        />
        <div className={styles.FilterBar}>
          <div className={styles.FilterName}><img src={FolderIcon} alt="Floder Icon" /><div className={styles.Name}>{isFilterSelected.name} ({this.state.filteredPages.length})</div> </div>
          <span className={styles.FilterLine} />
          <img className={styles.FilterButton} src={FilterIcon} alt="Filter Icon" onClick={this.openFilterMenuHandler} />
        </div>
        {isMobile ? null :
          <SideDrawer title={this.state.isAdding ? 'Add New Page' : 'Edit Page'} open={this.state.isDrawerOpened} closed={this.drawerClosedHandler}>
            <PageDrawer
              isDrawerOpened={this.state.isDrawerOpened}
              closed={this.drawerClosedHandler}
              editingPage={this.state.editingPage}
              isAdding={this.state.isAdding}
              leavePage={this.leavePageHandler}
            />
          </SideDrawer>
        }
        <div className={isMobile ? styles.CardGridMobileContainer : styles.CardGridContainer}>
          {
            this.props.loading ?
              <LoadingCard isMobile={isMobile} /> :
              <React.Fragment>
                {isMobile ? null : <PageCard addingCard click={this.openDrawerForAddingHandler} />}
                {
                  this.state.filteredPages ? this.state.filteredPages.map((page, index) => {
                    return (
                      <PageCard
                        key={`page${page.data.id}`}
                        pageIndex={index} data={page.data}
                        click={this.onPageClick}
                        editingPageHandler={this.onEditPage}
                        toggleDeleteModal={this.onDeletePageHandler}
                        favorite={page.favorite}
                        togglePageFavorite={this.onHandlePageFavorite}
                        isMobile={isMobile}
                      />
                    );
                  }) : null
                }
              </React.Fragment>
          }
        </div>

        <DeleteModal
          title="page"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeletePage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { currentOrg } = state.org;
  const { type, result, pages, addedPage, updatedPage, loading } = state.pages;
  const { favorites, addedFavorite, favoriteResult, favoriteType } = state.userFavorite;
  return { type, result, pages, addedPage, updatedPage, currentOrg, favorites, addedFavorite, favoriteResult, favoriteType, loading, loggedInUser };
};

export default connect(mapStateToProps, { getPages, deletePage, leavePage, getUserFavorites, addUserFavorite, deleteUserFavorite })(Pages);