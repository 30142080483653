export const GET_DEVICE_GROUPINGS_START = 'GET_DEVICE_GROUPINGS_START';
export const GET_DEVICE_GROUPINGS = 'GET_DEVICE_GROUPINGS';
export const GET_DEVICE_GROUPINGS_SUCCESS = 'GET_DEVICE_GROUPINGS_SUCCESS';
export const GET_DEVICE_GROUPINGS_FAIL = 'GET_DEVICE_GROUPINGS_FAIL';

export const ADD_DEVICE_GROUPING_START = 'ADD_DEVICE_GROUPING_START';
export const ADD_DEVICE_GROUPING = 'ADD_DEVICE_GROUPING';
export const ADD_DEVICE_GROUPING_SUCCESS = 'ADD_DEVICE_GROUPING_SUCCESS';
export const ADD_DEVICE_GROUPING_FAIL = 'ADD_DEVICE_GROUPING_FAIL';

export const EDIT_DEVICE_GROUPING_START = 'EDIT_DEVICE_GROUPING_START';
export const EDIT_DEVICE_GROUPING = 'EDIT_DEVICE_GROUPING';
export const EDIT_DEVICE_GROUPING_SUCCESS = 'EDIT_DEVICE_GROUPING_SUCCESS';
export const EDIT_DEVICE_GROUPING_FAIL = 'EDIT_DEVICE_GROUPING_FAIL';

export const DELETE_DEVICE_GROUPING_START = 'DELETE_DEVICE_GROUPING_START';
export const DELETE_DEVICE_GROUPING = 'DELETE_DEVICE_GROUPING';
export const DELETE_DEVICE_GROUPING_SUCCESS = 'DELETE_DEVICE_GROUPING_SUCCESS';
export const DELETE_DEVICE_GROUPING_FAIL = 'DELETE_DEVICE_GROUPING_FAIL';