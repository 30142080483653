import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

import { editDeviceSocket } from '../../../../state/ducks/DeviceSocket/actions';
import { CHANGE_SOCKET_ICON_SUCCESS } from '../../../../state/ducks/DeviceSocket/types';

const DEFAULT_DEVICESOCKET_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  location: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  unit_name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  functions: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  },
  tag: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  }
};

class EditDeviceSocketDrawer extends Component {
  state = {
    formIsValid: false,
    deviceForm: DEFAULT_DEVICESOCKET_FORM
  }

  componentDidMount() {
    if (this.props.editingDeviceSocket) {
      this.setupForm(this.props.editingDeviceSocket);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.editingDeviceSocket && prevProps.editingDeviceSocket !== this.props.editingDeviceSocket) {
      this.setupForm(this.props.editingDeviceSocket);
    } else if ((prevProps.updatedDeviceSocket !== this.props.updatedDeviceSocket) && this.props.type !== CHANGE_SOCKET_ICON_SUCCESS) {
      this.props.closed();
    }
  }

  onClearFields = () => {
    this.setState({ deviceForm: DEFAULT_DEVICESOCKET_FORM, formIsValid: false });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.deviceForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement;
      if (inputIdentifier === 'functions' && form[inputIdentifier]) {
        let formFunctions = '';
        form[inputIdentifier].forEach(form => {
          formFunctions += form.operation + ' ' + form.value + ' ';
        });
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: formFunctions.slice(0, -1),
          valid: true,
          touched: true
        });
      } else {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier] ? form[inputIdentifier] : '',
          valid: true,
          touched: true
        });
      }
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }
    this.setState({ deviceForm: updatedForm, formIsValid: true });

  }

  handleTextChange = event => {
    let updatedElement = updateObject(this.state.deviceForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.deviceForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.deviceForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ deviceForm: updatedForm, formIsValid });
  };

  onSaveDeviceHandler = (event) => {
    const { name, location, unit_name, tag, functions } = this.state.deviceForm;
    event.preventDefault();
    this.props.editDeviceSocket(
      this.props.editingDeviceSocket,
      name.value,
      location.value,
      null, // description
      unit_name.value,
      tag.value,
      functions.value
    );
  }

  render() {
    const { name, tag, location, unit_name, functions } = this.state.deviceForm;
    return (
      <div>
        <form onSubmit={this.onSaveDeviceHandler}>
          <Input
            label="device name"
            name="name"
            type="text"
            value={name.value}
            placeholder="Please insert your device name"
            autoComplete="off"
            onChange={this.handleTextChange}
            error={`${name.touched && !name.valid ? 'device Name is required' : ''}`}
            required
          />
          <Input
            label="location"
            name="location"
            value={location ? location.value : null}
            type="text"
            placeholder="Please insert Device's location"
            onChange={this.handleTextChange}
            error={`${location.touched && !location.valid ? 'Location is required' : ''}`}
            required
          />
          <Input
            label="unit"
            name="unit_name"
            type="text"
            value={unit_name ? unit_name.value : null}
            placeholder="m/min"
            autoComplete="off"
            onChange={this.handleTextChange}
            error={`${unit_name.touched && !unit_name.valid ? 'Unit is required' : ''}`}
            required
          />
          <Input
            label="function"
            name="functions"
            type="text"
            value={functions ? functions.value : null}
            placeholder="x 109 / 23"
            autoComplete="off"
            onChange={this.handleTextChange}
          />
          <Input
            label="tag"
            name="tag"
            value={tag.value}
            type="text"
            placeholder="Tag1,Tag2,..."
            autoComplete="off"
            onChange={this.handleTextChange}
          />
          <div style={{ height: 70, width: 'auto' }} />
          <Button
            type="submit"
            name={this.props.loading ? 'Loading...' : 'Save'}
            color="green"
            disabled={!this.state.formIsValid}
            loading={this.props.loading}
          />
          <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { updatedDeviceSocket, loading, type } = state.deviceSockets;
  return { updatedDeviceSocket, loading, type };
};

export default connect(mapStateToProps, { editDeviceSocket })(EditDeviceSocketDrawer);