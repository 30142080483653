import * as actionTypes from './types';

// ----------------------- GET DEVICES -----------------------
export const getDevicesStart = () => {
  return {
    type: actionTypes.GET_DEVICES_START
  };
}

export const getDevices = (org_id, want_socket, want_function, name, secret, tag, serial_number) => {
  return {
    type: actionTypes.GET_DEVICES,
    org_id, want_socket, want_function, name, secret, tag, serial_number
  };
}

export const getDevicesSuccess = (devices) => {
  return {
    type: actionTypes.GET_DEVICES_SUCCESS,
    devices: devices
  };
}

export const getDevicesFail = (error) => {
  return {
    type: actionTypes.GET_DEVICES_FAIL,
    error: error
  };
}

// ----------------------- GET SINGLE DEVICE -----------------------
export const getDeviceStart = () => {
  return {
    type: actionTypes.GET_DEVICE_START
  };
}

export const getDevice = (id) => {
  return {
    type: actionTypes.GET_DEVICE,
    id
  };
}

export const getDeviceSuccess = (response) => {
  return {
    type: actionTypes.GET_DEVICE_SUCCESS,
    device: response.device
  };
}

export const getDeviceFail = (error) => {
  return {
    type: actionTypes.GET_DEVICE_FAIL,
    error: error
  };
}

// ----------------------- ADD DEVICE -----------------------
export const addDeviceStart = () => {
  return {
    type: actionTypes.ADD_DEVICE_START
  };
}

export const addDevice = (org_id, name, description, tag, image, serial_number, secret) => {
  return {
    type: actionTypes.ADD_DEVICE,
    org_id, name, description, tag, image, serial_number, secret
  };
}

export const addDeviceSuccess = (response) => {
  return {
    type: actionTypes.ADD_DEVICE_SUCCESS,
    addedDevice: response.device,
    result: response.result
  };
}

export const addDeviceFail = (error) => {
  return {
    type: actionTypes.ADD_DEVICE_FAIL,
    error: error
  };
}

// ----------------------- EDIT DEVICE -----------------------
export const editDeviceStart = () => {
  return {
    type: actionTypes.EDIT_DEVICE_START
  };
}

export const editDevice = (id, name, description, tag, image, secret, monitor) => {
  return {
    type: actionTypes.EDIT_DEVICE,
    id, name, description, tag, image, secret, monitor
  };
}

export const editDeviceSuccess = (response, ts) => {
  return {
    type: actionTypes.EDIT_DEVICE_SUCCESS,
    updatedDevice: response.device,
    result: response.result,
    lastResult: ts
  };
}

export const editDeviceFail = (error, ts) => {
  return {
    type: actionTypes.EDIT_DEVICE_FAIL,
    error: error,
    lastResult: ts
  };
}

// ----------------------- DELETE DEVICE -----------------------
export const deleteDeviceStart = () => {
  return {
    type: actionTypes.DELETE_DEVICE_START
  };
}

export const deleteDevice = (id) => {
  return {
    type: actionTypes.DELETE_DEVICE,
    id: id
  };
}

export const deleteDeviceSuccess = (result) => {
  return {
    type: actionTypes.DELETE_DEVICE_SUCCESS,
    result: result
  };
}

export const deleteDeviceFail = (error) => {
  return {
    type: actionTypes.DELETE_DEVICE_FAIL,
    error: error
  };
}

// ----------------------- ADD CUSTOM DEVICE -----------------------
export const addCustomDevicesStart = () => {
  return {
    type: actionTypes.ADD_CUSTOM_DEVICES_START
  };
}

export const addCustomDevices = (org_id, deviceForm, deviceSocketsForm) => {
  return {
    type: actionTypes.ADD_CUSTOM_DEVICES,
    org_id, deviceForm, deviceSocketsForm
  };
}

export const addCustomDevicesSuccess = (response) => {
  return {
    type: actionTypes.ADD_CUSTOM_DEVICES_SUCCESS,
    result: response.result,
    addedDevice: response.device
  };
}

export const addCustomDevicesFail = (response) => {
  return {
    type: actionTypes.ADD_CUSTOM_DEVICES_FAIL,
    error: response.error
  };
}

// ----------------------- ADD OWL MODEL DEVICE -----------------------
export const addOwlDevicesStart = () => {
  return {
    type: actionTypes.ADD_OWL_DEVICES_START
  };
}

export const addOwlDevices = (org_id, secret, deviceId, deviceForm, deviceSocketsForm) => {
  return {
    type: actionTypes.ADD_OWL_DEVICES,
    org_id, secret, deviceId, deviceForm, deviceSocketsForm
  };
}

export const addOwlDevicesSuccess = (response) => {
  return {
    type: actionTypes.ADD_OWL_DEVICES_SUCCESS,
    result: response.result
  };
}

export const addOwlDevicesFail = (response) => {
  return {
    type: actionTypes.ADD_OWL_DEVICES_FAIL,
    error: response.error
  };
}

// ----------------------- DELETE SYSTEM DEVICE -----------------------
export const deleteOwlDevicesStart = () => {
  return {
    type: actionTypes.DELETE_OWL_DEVICES_START
  };
}

export const deleteOwlDevices = (org_id, secret) => {
  return {
    type: actionTypes.DELETE_OWL_DEVICES,
    org_id,
    secret
  };
}

export const deleteOwlDevicesSuccess = (result) => {
  return {
    type: actionTypes.DELETE_OWL_DEVICES_SUCCESS,
    result: result
  };
}

export const deleteOwlDevicesFail = (error) => {
  return {
    type: actionTypes.DELETE_OWL_DEVICES_FAIL,
    error: error
  };
}

// ----------------------- SEARCH OWL MODEL -----------------------
export const searchOwlModelStart = () => {
  return {
    type: actionTypes.SEARCH_OWL_MODEL_START
  };
}

export const searchOwlModel = (secret) => {
  return {
    type: actionTypes.SEARCH_OWL_MODEL,
    secret
  };
}

export const searchOwlModelSuccess = (response) => {
  return {
    type: actionTypes.SEARCH_OWL_MODEL_SUCCESS,
    owlModel: response.devices
  };
}

export const searchOwlModelFail = (response) => {
  return {
    type: actionTypes.SEARCH_OWL_MODEL_FAIL,
    error: response.error
  };
}

export const clearOwlModel = () => {
  return {
    type: actionTypes.CLEAR_OWL_MODEL
  };
}

// ----------------------- GET DEVICE_STATUS -----------------------
export const getDeviceStatusStart = () => {
  return {
    type: actionTypes.GET_DEVICE_STATUS_START
  };
}

export const getDeviceStatus = (secret, limit, offset) => {
  return {
    type: actionTypes.GET_DEVICE_STATUS,
    secret, limit, offset
  };
}

export const getDeviceStatusSuccess = (response) => {
  return {
    type: actionTypes.GET_DEVICE_STATUS_SUCCESS,
    deviceStatus: response.device_status
  };
}

export const getDeviceStatusFail = (error) => {
  return {
    type: actionTypes.GET_DEVICE_STATUS_FAIL,
    error
  };
}

// export default {
//   getDevicesStart,
//   getDevices,
//   getDevicesSuccess,
//   getDevicesFail,
//   addDevice,
//   addDeviceStart,
//   addDeviceSuccess,
//   addDeviceFail,
//   editDevice,
//   editDeviceStart,
//   editDeviceSuccess,
//   editDeviceFail,
//   deleteDevice,
//   deleteDeviceStart,
//   deleteDeviceSuccess,
//   deleteDeviceFail,
//   searchOwlModel,
//   searchOwlModelStart,
//   searchOwlModelSuccess,
//   searchOwlModelFail,
//   clearOwlModel
// };