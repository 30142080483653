import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_COMMAND_START: return addCommandStart(state, action);
    case actionTypes.ADD_COMMAND_SUCCESS: return addCommandSuccess(state, action);
    case actionTypes.ADD_COMMAND_FAIL: return addCommandFail(state, action);

    default: return state;
  }
};

const addCommandStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addCommandSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    // addedCountingDevices: action.addedCountingDevices,
    // error: null,
    loading: false
  });
}

const addCommandFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;