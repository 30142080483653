import React, { Component } from 'react';

import styles from './FilterMenu.module.scss';
import Backdrop from '../../UI/Backdrop/NoColorBackdrop';
import RadioUnselectIcon from '../../../../assets/icons/radio-unselect.svg';
import RadioSelectIcon from '../../../../assets/icons/radio-select.svg';

class FilterMenu extends Component {
  render() {
    return (
      <React.Fragment>
        <Backdrop show={this.props.show} clicked={this.props.closed} />
        {
          this.props.show ?
            <div className={styles.FilterMenu}>
              <div className={styles.RadioField}>
                {
                  this.props.filterTypes.map(type => {
                    let RadioIcon = RadioUnselectIcon;
                    let isChecked = false;
                    if (this.props.selectedFilterType === type.id) {
                      RadioIcon = RadioSelectIcon;
                      isChecked = true;
                    }
                    return (
                      <label htmlFor={type.id} className={styles.List} key={type.id}>
                        <div className={styles.RadioWrapper}>
                          <img src={RadioIcon} alt="Radio Icon" className={styles.RadioIcon} />
                          <input id={type.id} type="radio" value={type.id} checked={isChecked} onChange={this.props.handleTypeChange} />
                          <label htmlFor={type.id} className={styles.RadioLabel}>{type.name}</label>
                        </div>
                      </label>
                    );
                  })
                }
              </div>
            </div> : null
        }
      </React.Fragment>
    );
  }
}

export default FilterMenu;
