import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DecompositionTreeGraph } from '@ant-design/graphs';

import styles from './DeviceRelations.module.scss';

let CONFIG = {
  data: {},
  markerCfg: (cfg) => {
    const { children } = cfg;
    return {
      show: children?.length,
    };
  },
  nodeCfg: {
    autoWidth: true,
    items: {
      layout: 'follow',
    },
    title: {
      containerStyle: (cfg) => {
        return {
          fill: cfg?.isRoot ? '#1665d8' : cfg?.isMachine ? '#34aa44' : cfg?.isDevice ? '#f6ab2f' : '#9ea0a5',
          radius: [2]
        };
      },
    },
    style: {
      stroke: 'transparent',
    },
    nodeStateStyles: {
      hover: {
        stroke: '#fff',
        lineWidth: 2,
      },
    },
  },
  edgeCfg: {
    endArrow: {
      show: false,
    },
    style: (item, graph) => {
      const node = graph.findById(item.source).getModel();
      return {
        stroke: node.isRoot ? '#1665d8' : node.isMachine ? '#34aa44' : node.isDevice ? '#f6ab2f' : '#9ea0a5',
        strokeOpacity: 0.75,
        lineWidth: 2
      };
    },
    edgeStateStyles: {
      hover: {
        strokeOpacity: 1,
      },
    },
  },
  toolbarCfg: {
    show: true,
  },
  layout: {
    getVGap: () => {
      return 5;
    },
    getHGap: () => {
      return 120;
    },
    getHeight: () => {
      return 20;
    },
  },
  behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
};

class DeviceRelations extends Component {
  state = {
    config: CONFIG
  };

  componentDidMount() {
    const config = { ...this.state.config };
    config.data = this.props.data;
    if (this.props.noToolbar) {
      config.toolbarCfg.show = false;
    }
    this.setState({ config });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data && this.props.data) {
      const config = { ...this.state.config };
      config.data = this.props.data;
      if (this.props.noToolbar) {
        config.toolbarCfg.show = false;
      }
      this.setState({ config });
    }
  }

  render() {
    return (
      <div
        className={styles.RelationsWrapper}
        style={this.props.overideStyles}

      >
        {
          this.props.data
            ? <DecompositionTreeGraph {...this.state.config}
              onReady={(graph) => {
                // setTimeout(() => {
                //   graph.zoom(1);
                // });
                graph.on('node:click', (evt) => {
                  if (!evt.target.destroyed && !this.props.noActions) {
                    // expand/collapse node => evt.target.destroyed == true
                    this.props.clickNode(evt.item._cfg.model);
                  }
                });
              }} />
            : null
        }
      </div>
    );
  }
}

export default connect(null, {})(DeviceRelations);
