import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  data: null,
  dataCal: null,
  datasetCal: null,
  dataLatest: null,
  loadingId: null,
  loadingPercent: null,
  dataEval: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_DATA: return clearData(state);
    case actionTypes.GET_DATA_START: return getDataStart(state, action);
    case actionTypes.GET_DATA_SUCCESS: return getDataSuccess(state, action);
    case actionTypes.GET_DATA_FAIL: return getDataFail(state, action);

    case actionTypes.GET_DATACAL_START: return getDataCalStart(state, action);
    case actionTypes.GET_DATACAL_SUCCESS: return getDataCalSuccess(state, action);
    case actionTypes.GET_DATACAL_FAIL: return getDataCalFail(state, action);

    case actionTypes.GET_DATASET_CAL_START: return getDatasetCalStart(state, action);
    case actionTypes.GET_DATASET_CAL_SUCCESS: return getDatasetCalSuccess(state, action);
    case actionTypes.GET_DATASET_CAL_FAIL: return getDatasetCalFail(state, action);

    case actionTypes.GET_FULL_DATASET_CAL_START: return getDatasetCalStart(state, action);
    case actionTypes.GET_FULL_DATASET_CAL_SUCCESS: return getDatasetCalSuccess(state, action);
    case actionTypes.GET_FULL_DATASET_CAL_FAIL: return getDatasetCalFail(state, action);

    case actionTypes.GET_DATALATEST_START: return getDataLatestStart(state, action);
    case actionTypes.GET_DATALATEST_SUCCESS: return getDataLatestSuccess(state, action);
    case actionTypes.GET_DATALATEST_FAIL: return getDataLatestFail(state, action);

    case actionTypes.SET_LOADING_PERCENT: return setLoadingPercent(state, action);

    case actionTypes.GET_DATA_EVAL_START: return getDataEvalStart(state, action);
    case actionTypes.GET_DATA_EVAL_SUCCESS: return getDataEvalSuccess(state, action);
    case actionTypes.GET_DATA_EVAL_FAIL: return getDataEvalFail(state, action);
    default: return state;
  }
};

const clearData = (state) => {
  return updateObject(state, initialState);
}

const getDataStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true, data: null });
}

const getDataSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    error: null,
    loading: false
  });
}

const getDataFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getDataCalStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true, dataCal: null });
}

const getDataCalSuccess = (state, action) => {
  return updateObject(state, {
    dataCal: action.dataCal,
    error: null,
    loading: false
  });
}

const getDataCalFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getDatasetCalStart = (state, action) => {
  return updateObject(state, {
    result: null,
    error: null,
    loading: true,
    datasetCal: null,
    loadingId: action.loadingId
  });
}

const getDatasetCalSuccess = (state, action) => {
  return updateObject(state, {
    datasetCal: action.datasetCal,
    error: null,
    loading: false,
    loadingId: null,
  });
}

const getDatasetCalFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    loadingId: null
  });
}

const setLoadingPercent = (state, action) => {
  return updateObject(state, {
    loadingPercent: action.loadingPercent
  });
}

const getDataLatestStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true, data: null });
}

const getDataLatestSuccess = (state, action) => {
  return updateObject(state, {
    dataLatest: {
      data: action.dataLatest,
      properties: action.props
    },
    error: null,
    loading: false
  });
}

const getDataLatestFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getDataEvalStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true, data: null });
}

const getDataEvalSuccess = (state, action) => {
  return updateObject(state, {
    dataEval: {
      data: action.dataEval,
      properties: action.props
    },
    error: null,
    loading: false
  });
}

const getDataEvalFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;