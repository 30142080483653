import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import styles from './VariableList.module.scss';
import DeleteIcon from '../../../../assets/icons/delete-red-bin.svg';
import DeleteModal from '../../shared/DeleteModal/DeleteModal';

import { getVariables, deleteVariable } from '../../../../state/ducks/Variable/actions';
import { DELETE_VARIABLE_SUCCESS } from '../../../../state/ducks/Variable/types';

class VariableList extends Component {
  state = {
    howManyBeenUsed: [],
    isDeleteModalOpened: false,
    deletingVariable: null
  }

  componentDidMount() {
    this.props.getVariables(this.props.currentOrg);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.variables !== this.props.variables && this.props.variables) {
      const howManyBeenUsed = new Array(this.props.variables.length).fill(0);
      this.props.variables.forEach((v, i) => {
        if (this.props.formulas) {
          let counting = 0;
          this.props.formulas.forEach(f => {
            if (f.sets.find(set => set.variable_id === v.id))
              counting++;
          });
          howManyBeenUsed[i] = counting;
        }
      });
      this.setState({ howManyBeenUsed });
    } else if (this.props.result !== prevProps.result && this.props.result === 'success') {
      if (this.props.type === DELETE_VARIABLE_SUCCESS) {
        this.props.getVariables(this.props.currentOrg);
      }
    }
  }

  // onDeleteVariableHandler = (event) => {
  //   event.preventDefault();
  //   this.setState({ isDeleteModalOpened: true });
  // }

  deleteModalClickHandler = () => {
    this.setState(prevState => {
      return { isDeleteModalOpened: !prevState.isDeleteModalOpened };
    });
  }

  onConfirmDeleteVariable = (event) => {
    event.preventDefault();
    this.props.deleteVariable(this.state.deletingVariable.id);
    this.setState({ isDeleteModalOpened: false, deletingVariable: null });
  }

  render() {
    return (
      <div className={styles.ListWrapper}>
        {
          this.props.variables ? this.props.variables.map((variable, i) => (
            <div key={variable.id} className={styles.VariableBox}>
              <div className={styles.TopSection}>
                <div className={styles.Name}>{variable.name}</div>
                {!this.state.howManyBeenUsed[i] && <img src={DeleteIcon} alt="Delete Variable" className={styles.Icon} onClick={() => this.setState({ deletingVariable: variable, isDeleteModalOpened: true })} />}
              </div>
              <div className={styles.VariableValue}>{variable.value}</div>
              {this.state.howManyBeenUsed[i] ? <div className={styles.SubText}>This is being used in <b>{this.state.howManyBeenUsed[i]}</b> formula.</div> : ''}
            </div>
          )) : null
        }
        <DeleteModal
          title="Variable"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeleteVariable}
        />
      </div>
    )
  }

}
const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { variables, result, type } = state.variable;
  const { formulas } = state.formula;
  return { currentOrg, variables, formulas, result, type };
};

export default connect(mapStateToProps, { getVariables, deleteVariable })(VariableList);