import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './MemberDrawer.module.scss';
import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import GrayArrowDownIcon from '../../../../assets/icons/Device/grey-arrow.svg';
import BlueArrowUpIcon from '../../../../assets/icons/Device/blue-arrow.svg';

import { addMember, editMember } from '../../../../state/ducks/Members/actions';
import { inviteMember } from '../../../../state/ducks/Organization/actions';
import { INVITE_MEMBER_SUCCESS } from '../../../../state/ducks/Organization/types';

const COLORS = ['1665d8', 'f6ab2f', '42cb83', '32c5ff', '6758f3', '44d7b6'];

const DEFAULT_MEMBER_FORM = {
  username: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  password: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  first_name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  last_name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  email: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true,
      isEmail: true
    }
  },
  job_title: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  }
};

class MemberDrawer extends Component {
  state = {
    formIsValid: false,
    invite_member: '',
    memberForm: DEFAULT_MEMBER_FORM,
    openMemberFormType: 0 // 1: never signed up, 2: existing
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAdding && (prevProps.isAdding !== this.props.isAdding)) {
      this.onClearFields();
    } else if ((prevProps.addedMember !== this.props.addedMember) || (prevProps.updatedMember !== this.props.updatedMember)) {
      this.props.closed();
      this.onClearFields();
  } else if (this.props.editingMember && prevProps.editingMember !== this.props.editingMember) {
      this.setupForm(this.props.editingMember);
    }

    if (this.props.orgResult !== prevProps.orgResult && this.props.orgResult === 'success') {
      if (this.props.orgType === INVITE_MEMBER_SUCCESS) {
        this.props.closed();
        this.setState({ invite_member: '', formIsValid: false })
      }
    }
  }

  onClearFields = () => {
    this.setState({ memberForm: DEFAULT_MEMBER_FORM, formIsValid: false });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.memberForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
      });
      if (!this.props.isAdding && inputIdentifier === 'password') {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: '',
          valid: true,
          touched: true,
          validation: { required: false }
        });
      }

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
      this.setState({ memberForm: updatedForm, formIsValid: true });
    }
  }

  handleTextChange = event => {
    let updatedElement = updateObject(this.state.memberForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.memberForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.memberForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ memberForm: updatedForm, formIsValid });
  };

  handleInviteMemberChange = event => {
    const valid = checkValidity(event.target.value, { isEmail: true });
    this.setState({ invite_member: event.target.value, formIsValid: valid });
  }

  onSubmitMemberHandler = (event) => {
    event.preventDefault();
    const { username, password, first_name, last_name, email, job_title } = this.state.memberForm;
    if (this.state.openMemberFormType === 2) {
      this.props.inviteMember(this.props.currentOrg, this.state.invite_member);
      this.setState({ invite_member: '', formIsValid: false });
    } else if (this.state.openMemberFormType === 1) {
      if (this.props.isAdding) {
        // username, password, first_name, last_name, email, is_system_admin, org_id, team_id, verified, profile_pic
        const random = Math.floor(Math.random() * 6);
        const defaultProfilePicture = 'https://ui-avatars.com/api/?background=' + COLORS[random]
          + '&color=fff&rounded=true&name=' + first_name.value + '+' + last_name.value;
        this.props.addMember(
          username.value,
          password.value,
          first_name.value,
          last_name.value,
          email.value,
          job_title.value,
          false, // is_system_admin
          this.props.currentOrg,
          '', // team_id
          true, // verified
          defaultProfilePicture
        );
      } else {
        // NO EDIT MEMBER FOR NOW
        // this.props.editMember(
        //   this.props.editingMember.id,
        //   first_name.value,
        //   last_name.value,
        //   password.value,
        //   job_title.value
        // );
      }
    }
  }

  onOpenMemberFormHandler = (which) => {
    if (this.state.openMemberFormType === which) {
      this.setState({ openMemberFormType: 0 });
    } else {
      this.setState({ openMemberFormType: which });
    }
  }

  render() {
    const { username, password, first_name, last_name, email, job_title } = this.state.memberForm;

    let memberForm = null;
    let firstBoxWrapperStyles = [styles.BoxWrapper];
    let firstArrowIconStyles = GrayArrowDownIcon;
    let secondBoxWrapperStyles = [styles.BoxWrapper];
    let secondArrowIconStyles = GrayArrowDownIcon;

    if (this.state.openMemberFormType === 1) {
      firstBoxWrapperStyles.push(styles.Open);
      firstArrowIconStyles = BlueArrowUpIcon;
      memberForm = (
        <React.Fragment>
          <Input
            label="username"
            name="username"
            type="text"
            value={username.value}
            placeholder="Please insert username"
            autoComplete="off"
            onChange={this.handleTextChange}
            error={`${username.touched && !username.valid ? 'Username is required' : ''}`}
            required
          />
          <Input
            label="password"
            name="password"
            value={password.value}
            type="password"
            placeholder="Please insert password"
            onChange={this.handleTextChange}
            error={`${password.touched && !password.valid ? 'Password is required' : ''}`}
            required
          />
          <div className={styles.Divider} />
          <Input
            label="first name"
            name="first_name"
            type="text"
            value={first_name.value}
            placeholder="John"
            autoComplete="off"
            onChange={this.handleTextChange}
            error={`${first_name.touched && !first_name.valid ? 'First Name is required' : ''}`}
            required
          />
          <Input
            label="last name"
            name="last_name"
            type="text"
            value={last_name.value}
            placeholder="Wick"
            autoComplete="off"
            onChange={this.handleTextChange}
            error={`${last_name.touched && !last_name.valid ? 'Last Name is required' : ''}`}
            required
          />
          {
            this.props.isAdding &&
            <Input
              label="email"
              name="email"
              type="text"
              value={email.value}
              placeholder="John.Wick@technimal.co.th"
              autoComplete="off"
              onChange={this.handleTextChange}
              error={`${email.touched && !email.valid ? 'Email is required' : ''}`}
              required
            />
          }
          <Input
            label="job title"
            name="job_title"
            type="text"
            value={job_title.value}
            placeholder="Engineer"
            autoComplete="off"
            onChange={this.handleTextChange}
          />
        </React.Fragment>
      );
    } else if (this.state.openMemberFormType === 2) {
      secondBoxWrapperStyles.push(styles.Open);
      secondArrowIconStyles = BlueArrowUpIcon;
      memberForm = (
        <Input
          label="invite member by existing email"
          name="invite_member"
          type="text"
          value={this.state.invite_member}
          placeholder="One email at a time"
          autoComplete="off"
          onChange={this.handleInviteMemberChange}
        />
      );
    }

    const memberFormWrapper = (
      <form onSubmit={this.onSubmitMemberHandler}>
        {memberForm}
        <div style={{ height: 70, width: 'auto' }} />
        <Button
          type="submit"
          name={this.props.loading ? 'Loading...' : 'Done'}
          color="primary"
          disabled={!this.state.formIsValid || this.state.openMemberFormType === 0}
          loading={this.props.loading}
        />
        <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
      </form>
    );

    return (
      <div className={styles.MemberDrawer}>
        <div className={firstBoxWrapperStyles.join(' ')} onClick={() => this.onOpenMemberFormHandler(1)}>
          <div className={styles.Title}>For whom never signed up</div>
          <div className={styles.ArrowIcon}><img alt="Arrow" src={firstArrowIconStyles} /></div>
        </div>
        {this.state.openMemberFormType === 1 && memberFormWrapper}
        <div className={secondBoxWrapperStyles.join(' ')} onClick={() => this.onOpenMemberFormHandler(2)}>
          <div className={styles.Title}>Existing Member in the platform</div>
          <div className={styles.ArrowIcon}><img alt="Arrow" src={secondArrowIconStyles} /></div>
        </div>
        {this.state.openMemberFormType === 2 && memberFormWrapper}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, addedMember, updatedMember } = state.members;
  const { currentOrg } = state.org;
  return {
    loading,
    addedMember,
    updatedMember,
    orgType: state.org.type,
    orgResult: state.org.result,
    currentOrg
  };
};

export default connect(mapStateToProps, { addMember, editMember, inviteMember })(MemberDrawer);