import React, { Component } from 'react';

import styles from './WidgetFormulaModal.module.scss';
import Modal from '../../UI/Modal/Modal';
import Button from '../../UI/Button/Button';
import AddIcon from '../../../../assets/icons/Rule/add-circle.svg';
import DelIcon from '../../../../assets/icons/delete-member-button.svg';
import ReactSelect from 'react-select';

const widgetFormulaModalContainerStyle = {
  backgroundColor: '#222327',
  maxWidth: '1200px',
  width: '90%',
  height: '500px',
}

const dataTypeOptions = [
  { value: 'rt', label: 'Realtime' },
  { value: 'ra', label: 'Calculate' },
  { value: 'firstts', label: 'First' },
  { value: 'firsttsnot', label: 'First Not' },
  { value: 'lastts', label: 'Last' },
  { value: 'lasttsnot', label: 'Last Not' },
  { value: 'tsdelta', label: 'Delta' },
  { value: 'tsdeltanot', label: 'Delta Not' },
]

const calMethodOptions = [
  { value: 'min', label: 'Minimum' },
  { value: 'max', label: 'Maximum' },
  { value: 'avg', label: 'Average' },
  { value: 'mode', label: 'Mode' },
  { value: 'sum', label: 'Summary' },
  { value: 'count', label: 'Count' },
]

const timingTypeOptions = [
  { value: 'period', label: 'Period' },
  // { value: 'custom', label: 'Custom Range' },
  { value: 'custom', label: 'Range' },
]

const customRangeOptions = [
  { value: 'now', label: 'Now' },
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'tomorrow', label: 'Tomorrow' },
]

const colourStyles = {
  singleValue: (styles) => ({
    ...styles,
    color: "white"
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
  }),
  input: base => ({
    ...base,
    color: "white"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "white",
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0,
    // borderColor: "red",
    // padding: 20,
    // position: 'absolute',
    // zIndex: 999999,
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 999999,
  })
};

const translateFormulaOutputToFormula = (textFormula, deviceFormulaForm) => {
  if (textFormula && textFormula != '') {
    let textFormulaCleaned = textFormula.replace(/ /g, '');
    textFormulaCleaned = textFormulaCleaned.replace(/!@#%&/g, '');
    let textFormulaToArray = textFormulaCleaned.split('');
    let deviceList = [];
    textFormulaToArray.forEach((char, index) => {
      if ((index < textFormulaToArray.length) &&
        (char === 'D') &&
        (typeof (parseInt(textFormulaToArray[index + 1])) == 'number' &&
          (textFormulaToArray[index + 1] !== undefined))
      ) {
        deviceList.push(char + textFormulaToArray[index + 1]);
      }
    });
    let textFormulaTranslate = textFormulaCleaned;
    if (deviceList.length > 0) {
      deviceList.forEach((device, index) => {
        let formulaObjectIndex = parseInt(device.replace('D', '') - 1);
        if (deviceFormulaForm[formulaObjectIndex] !== undefined) {
          textFormulaTranslate = textFormulaTranslate.replace(device, deviceFormulaForm[formulaObjectIndex]['formulaForm']);
        }
      });
      return textFormulaTranslate
    } else {
      return ''
    }
  } else {
    return ''
  }
}

const convertFormulaObjectFromListToString = (list) => {
  let listToString = '';
  if (list || list.length > 0) {
    let listSize = list.length - 1;

    list.forEach((device, i) => {
      if (listSize === 0) {
        listToString += JSON.stringify(device);
      } else if (i < listSize) {
        listToString += JSON.stringify(device) + '::';
      } else {
        listToString += JSON.stringify(device);
      }
    });

    return listToString
  } else {
    return listToString
  }
}


const convertTimeStampForInput = (time) => {
  return time.replace(',', ':');
}

const selectMenuPortalTarget = document.querySelector('body');

const FORMULA_OBJECT_PROTO = {
  deviceId: 0,
  deviceSecret: 0,
  deviceSocket: 0,
  deviceName: '',
  dataType: 'rt',
  targetValue: 0,
  calMethod: 'min',
  timingType: 'period',
  timePeriod: 0,
  startDate: '',
  startTime: '',
  stopDate: '',
  stopTime: '',
  formulaForm: '',
};

class WidgetFormulaModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deviceOptions: [],
      deviceNotSelect: false,
      formulaOutputError: false,
    };

    this.objRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.widgetFormula.formulaOutput !== '' && this.props.widgetFormula.formulaOutput === '') {
      this.setState({
        formulaOutputError: true,
      })
    }
    if (prevProps.widgetFormula.formulaOutput === '' && this.props.widgetFormula.formulaOutput !== '') {
      this.setState({
        formulaOutputError: false,
      })
    }

    if (prevProps.widgetFormula.formulaObjectLength !== this.props.widgetFormula.formulaObjectLength) {
      let formulaObjectList = this.props.widgetFormula.formulaObjectList;
      let nonSelectObj = formulaObjectList.filter(object => object.deviceName === '');
      let stillNonSelectObj = nonSelectObj.length > 0 ? true : false;

      // Scrool to new added object
      if (stillNonSelectObj && this.objRef.current) {
        this.objRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }

  componentDidMount() {
    this.setState({
      deviceOptions: this.props.deviceOptions
    });
  }

  // componentWillUnmount() {
  // window.removeEventListener('keydown', this.handleEsc);
  // }

  // handleEsc = (event) => {
  //   if (this.props.open && event.keyCode === 27) {
  //     this.props.modalClosed(event);
  //     this.setState(prevState => ({
  //       formulaObject: [],
  //       formulaObjectString: '',
  //       formulaObjectCount: 0,
  //       outputFormulaText: '',
  //       outputFormulaTranslate: '',
  //     }));
  //   }
  // };

  addFormulaObjectHandler = () => {
    let newFormulaObjectList = this.props.widgetFormula.formulaObjectList;
    if (newFormulaObjectList.length !== 0 && newFormulaObjectList[newFormulaObjectList.length - 1]['deviceName'] === '') {
      this.setState({
        deviceNotSelect: true,
      })
      this.objRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      // let addedFormulaObject = FORMULA_OBJECT_PROTO;
      let addedFormulaObject = {
        deviceId: 0,
        deviceSecret: 0,
        deviceSocket: 0,
        deviceName: '',
        dataType: 'rt',
        targetValue: 0,
        calMethod: 'min',
        timingType: 'period',
        timePeriod: 0,
        startDate: '',
        startTime: '',
        stopDate: '',
        stopTime: '',
        formulaForm: '',
      };
      newFormulaObjectList.push(addedFormulaObject);
      let newFormulaObject = convertFormulaObjectFromListToString(newFormulaObjectList);
      this.props.formulaObjectUpdate(newFormulaObject, newFormulaObjectList);
      this.setState({
        deviceNotSelect: false,
      })
    }
  }

  onFormulaObjectChangeHandler = (event, index, type) => {
    let newFormulaObjectList = this.props.widgetFormula.formulaObjectList;
    let changedObject = newFormulaObjectList[index];
    switch (type) {
      case 'device':
        changedObject.deviceId = event.id;
        changedObject.deviceSecret = event.secret;
        changedObject.deviceSocket = event.socket;
        changedObject.deviceName = event.name;
        break;
      case 'datatype':
        changedObject.dataType = event.value;
        break;
      case 'calmethod':
        changedObject.calMethod = event.value;
        break;
      case 'timingtype':
        changedObject.timingType = event.value;
        break;
      case 'timeperiod':
        changedObject.timePeriod = parseInt(event.target.value) > 0 ? parseInt(event.target.value) : 0;
        break;
      case 'targetvalue':
        changedObject.targetValue = event.target.value;
        break;
      case 'startdate':
        changedObject.startDate = event.value;
        if (event.value === 'now') {
          let currentDate = new Date();
          let currentHour = currentDate.getHours();
          let currentMinute = currentDate.getMinutes() < 10 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
          changedObject.startTime = currentHour + ',' + currentMinute;
        } else {
          changedObject.startTime = '';
        }
        break;
      case 'starttime':
        changedObject.startTime = event.target.value.replace(':', ',');
        break;
      case 'stopdate':
        changedObject.stopDate = event.value;
        if (event.value === 'now') {
          let currentDate = new Date();
          let currentHour = currentDate.getHours();
          let currentMinute = currentDate.getMinutes() < 10 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
          changedObject.stopTime = currentHour + ',' + currentMinute;
        } else {
          changedObject.stopTime = '';
        }
        break;
      case 'stoptime':
        changedObject.stopTime = event.target.value.replace(':', ',');
        break;
      case 'delete':
        newFormulaObjectList.splice(index, 1);
        break;
      default:
        break;
    }

    if (type !== 'delete') {
      if (newFormulaObjectList.length > 0) {
        changedObject.formulaForm = `{${changedObject['dataType']}:${changedObject['deviceSecret']}@${changedObject['deviceSocket']}}`;

        if (changedObject['dataType'] === 'rt') {
          changedObject.formulaForm = `{${changedObject['dataType']}:${changedObject['deviceSecret']}@${changedObject['deviceSocket']}}`;
        }
        else if (changedObject['dataType'] === 'ra') {
          if (changedObject['timingType'] === 'period') {
            changedObject.formulaForm = `{${changedObject['dataType']}:${changedObject['calMethod']}(${changedObject['deviceSecret']}@${changedObject['deviceSocket']}@${changedObject['timePeriod']})}`;
          } else {
            changedObject.formulaForm = `{${changedObject['dataType']}:${changedObject['calMethod']}(${changedObject['deviceSecret']}@${changedObject['deviceSocket']}@${changedObject['startDate']}(${changedObject['startTime']})-${changedObject['stopDate']}(${changedObject['stopTime']}))}`;
          }
        }
        else {
          if (changedObject['dataType'].includes('not')) {
            if (changedObject['timingType'] === 'period') {
              changedObject.formulaForm = `{${changedObject['dataType'].replace('not', '')}(${changedObject['deviceSecret']}@${changedObject['deviceSocket']}!=${changedObject['targetValue']}@${changedObject['timePeriod']})}`;
            } else {
              changedObject.formulaForm = `{${changedObject['dataType'].replace('not', '')}(${changedObject['deviceSecret']}@${changedObject['deviceSocket']}!=${changedObject['targetValue']}@${changedObject['startDate']}(${changedObject['startTime']})-${changedObject['stopDate']}(${changedObject['stopTime']}))}`;
            }
          } else {
            if (changedObject['timingType'] === 'period') {
              changedObject.formulaForm = `{${changedObject['dataType']}(${changedObject['deviceSecret']}@${changedObject['deviceSocket']}=${changedObject['targetValue']}@${changedObject['timePeriod']})}`;
            } else {
              changedObject.formulaForm = `{${changedObject['dataType']}(${changedObject['deviceSecret']}@${changedObject['deviceSocket']}=${changedObject['targetValue']}@${changedObject['startDate']}(${changedObject['startTime']})-${changedObject['stopDate']}(${changedObject['stopTime']}))}`;
            }
          }
        }
      }
    }

    let newFormulaObject = convertFormulaObjectFromListToString(newFormulaObjectList);
    this.props.formulaObjectUpdate(newFormulaObject, newFormulaObjectList);
    if (this.props.widgetFormula.formulaOutput !== '') {
      this.onOutputFormulaChangeHandler();
    }
  }

  onOutputFormulaChangeHandler = (event) => {
    let newFormulaOutput;
    if (event) {
      newFormulaOutput = event.target.value;
    } else {
      newFormulaOutput = this.props.widgetFormula.formulaOutput;
    }
    if (newFormulaOutput) {
      if (newFormulaOutput.includes('d')) {
        newFormulaOutput = newFormulaOutput.replaceAll('d', 'D');
      }
    }
    let newWidgetFormula = translateFormulaOutputToFormula(newFormulaOutput, this.props.widgetFormula.formulaObjectList);
    this.props.widgetFormulaUpdate(newWidgetFormula, newFormulaOutput);
  }

  onIndexClickHandler = (event) => {
    let selectedFormulaObject = event.target.innerText;
    let formulaOutputEl = document.getElementById('formulaOutput');
    formulaOutputEl.focus()
    let newFormulaOutput = formulaOutputEl.value;
    if (newFormulaOutput === '') {
      newFormulaOutput += selectedFormulaObject + ' ';
    } else if (newFormulaOutput.charAt(newFormulaOutput.length - 1) === ' ') {
      newFormulaOutput += selectedFormulaObject + ' ';
    } else {
      newFormulaOutput += ' ' + selectedFormulaObject;
    }
    let newWidgetFormula = translateFormulaOutputToFormula(newFormulaOutput, this.props.widgetFormula.formulaObjectList);
    this.props.widgetFormulaUpdate(newWidgetFormula, newFormulaOutput);
  }

  onSubmitHandler = () => {
    let allValid = true;
    this.props.widgetFormula.formulaObjectList.forEach(object => {
      if (object.deviceName === '') {
        allValid = false;
        this.setState({
          deviceNotSelect: true,
        })
      }
    });

    if (this.props.widgetFormula.formulaOutput === '') {
      allValid = false;
      this.setState({
        formulaOutputError: true,
      })
    }
    else {
      this.setState({
        formulaOutputError: false,
      })
    }

    if (allValid) {
      this.props.onWidgetFormulaSubmit();
      this.props.modalClosed(true);
    }
  }

  render() {
    const { widgetFormula } = this.props;
    const { deviceOptions, deviceNotSelect, formulaOutputError } = this.state;

    return (
      <Modal
        show={this.props.open}
        modalClosed={() => this.props.modalClosed(false)}
        overideStyles={widgetFormulaModalContainerStyle}
        whiteCloseButton={true}
        disableCloseOnBackdrop
      >
        <div className={styles.WidgetFormulaModal}>
          <div className={styles.Title} style={{ marginBottom: '20px' }}>Formula</div>

          {widgetFormula.formulaObjectLength > 0 ?
            <div className={styles.formulaObjectContainer} id={'objectList'}>
              {widgetFormula.formulaObjectList.map((device, index) => {
                let objToBeRef = widgetFormula.formulaObjectList.length > 0 && index === widgetFormula.formulaObjectList.length - 1 ? this.objRef : null;
                return (
                  <div
                    key={'D' + (index + 1)}
                    className={styles.formulaObject}
                    ref={objToBeRef}
                  >
                    <p className={styles.objectIndex} onClick={(event) => this.onIndexClickHandler(event)}>D{index + 1}</p>
                    <div style={{ width: '20%' }}>
                      <label>DEVICES *</label>
                      <ReactSelect
                        menuPortalTarget={selectMenuPortalTarget}
                        isSearchable
                        options={deviceOptions}
                        styles={colourStyles}
                        placeholder="Select a device"
                        value={device.deviceName !== '' ? deviceOptions.filter(option => option.name === device.deviceName)[0] : ''}
                        // value={device.deviceName}
                        onChange={(event) => this.onFormulaObjectChangeHandler(event, index, 'device')}
                      />
                      {(deviceNotSelect && device.deviceName === '') &&
                        <p style={{ margin: '0 0', marginLeft: '2px' }}>
                          <small style={{ color: '#e73c1e' }}>Please select a device socket</small>
                        </p>
                      }
                    </div>
                    <div style={{ width: '12%' }}>
                      {device.deviceName !== '' ?
                        <>
                          <label>DATA TYPE</label>
                          <ReactSelect
                            menuPortalTarget={selectMenuPortalTarget}
                            isSearchable={false}
                            options={dataTypeOptions}
                            styles={colourStyles}
                            value={dataTypeOptions.filter(option => option.value === device.dataType)[0]}
                            onChange={(event) => this.onFormulaObjectChangeHandler(event, index, 'datatype')}
                          />
                        </>
                        : null
                      }
                    </div>
                    <div style={{ width: '12%' }}>
                      {device.deviceName !== '' && device.dataType === 'ra' ?
                        <>
                          <label>METHOD</label>
                          <ReactSelect
                            menuPortalTarget={selectMenuPortalTarget}
                            options={calMethodOptions}
                            styles={colourStyles}
                            value={calMethodOptions.filter(option => option.value === device.calMethod)[0]}
                            onChange={(event) => this.onFormulaObjectChangeHandler(event, index, 'calmethod')}
                          />
                        </>
                        : null
                      }
                      {device.deviceName !== '' && (device.dataType !== 'rt' && device.dataType !== 'ra') ?
                        <>
                          {device.dataType === 'firstts' || device.dataType === 'lastts' || device.dataType === 'tsdelta' ?
                            <label>EQUAL VALUE</label>
                            : <label>NOT EQUAL VALUE</label>
                          }
                          <input
                            id="atValue"
                            type="text"
                            className={styles.textInputField}
                            placeholder={'Ex. 10'}
                            value={device.targetValue}
                            style={{ maxWidth: '110px' }}
                            onChange={(event) => this.onFormulaObjectChangeHandler(event, index, 'targetvalue')}
                          />
                        </>
                        : null
                      }
                    </div>
                    <div style={{ width: '10%' }}>
                      {device.deviceName !== '' && device.dataType !== 'rt' ?
                        <>
                          <label>TIMING TYPE</label>
                          <ReactSelect
                            menuPortalTarget={selectMenuPortalTarget}
                            isSearchable={false}
                            options={timingTypeOptions}
                            styles={colourStyles}
                            value={timingTypeOptions.filter(option => option.value === device.timingType)[0]}
                            onChange={(event) => this.onFormulaObjectChangeHandler(event, index, 'timingtype')}
                          />
                        </>
                        : null
                      }
                    </div>
                    <div style={{ width: '19%' }}>
                      {device.deviceName !== '' && device.dataType !== 'rt' && device.timingType === 'period' ?
                        <>
                          <label>TIME PERIOD (ms)</label>
                          <input
                            type="text"
                            className={styles.textInputField}
                            style={{ width: '100px' }}
                            placeholder={'Ex. 3600'}
                            value={device.timePeriod}
                            onChange={(event) => this.onFormulaObjectChangeHandler(event, index, 'timeperiod')}
                          />
                        </>
                        : null
                      }
                      {device.deviceName !== '' && device.dataType !== 'rt' && device.timingType !== 'period' ?
                        <>
                          <label>FROM</label>
                          <div style={{ display: 'flex', marginRight: 0 }}>
                            <div style={{ flex: 2, marginRight: 0 }}>
                              <ReactSelect
                                menuPortalTarget={selectMenuPortalTarget}
                                isSearchable={false}
                                options={customRangeOptions}
                                styles={colourStyles}
                                value={customRangeOptions.filter(option => option.value === device.startDate)[0]}
                                onChange={(event) => this.onFormulaObjectChangeHandler(event, index, 'startdate')}
                              />
                            </div>
                            <div style={{ flex: 1 }}>
                              <input
                                className={styles.FormField}
                                style={{ marginTop: 0 }}
                                name="time"
                                type="time"
                                value={convertTimeStampForInput(device.startTime)}
                                onChange={(event) => this.onFormulaObjectChangeHandler(event, index, 'starttime')}
                              />
                            </div>
                          </div>
                        </>
                        : null
                      }
                    </div>
                    <div style={{ width: '19%' }}>
                      {device.deviceName !== '' && device.dataType !== 'rt' && device.timingType !== 'period' ?
                        <>
                          <label>TO</label>
                          <div style={{ display: 'flex', marginRight: 0 }}>
                            <div style={{ flex: 2, marginRight: 0 }}>
                              <ReactSelect
                                menuPortalTarget={selectMenuPortalTarget}
                                isSearchable={false}
                                options={customRangeOptions}
                                styles={colourStyles}
                                value={customRangeOptions.filter(option => option.value === device.stopDate)[0]}
                                onChange={(event) => this.onFormulaObjectChangeHandler(event, index, 'stopdate')}
                              />
                            </div>
                            <div style={{ flex: 1 }}>
                              <input
                                className={styles.FormField}
                                style={{ marginTop: 0 }}
                                name="time"
                                type="time"
                                value={convertTimeStampForInput(device.stopTime)}
                                onChange={(event) => this.onFormulaObjectChangeHandler(event, index, 'stoptime')}
                              />
                            </div>
                          </div>
                        </>
                        : null
                      }
                    </div>
                    <img
                      key={'delBtn' + (index + 1)}
                      src={DelIcon}
                      alt="Delete"
                      width={24}
                      onClick={(event) => this.onFormulaObjectChangeHandler(event, index, 'delete')}
                      style={{
                        marginLeft: '2px',
                        paddingTop: '12px',
                        cursor: 'pointer'
                      }}
                    />
                  </div>
                )
              })}
            </div>
            : null
          }

          <div className={styles.AddFormulaObjectButton} onClick={() => this.addFormulaObjectHandler()}>
            <img src={AddIcon} alt="Add" width={20} />
            <p style={{ marginLeft: '10px', color: '#1665d8' }}>Add Device Object</p>
          </div>

          {/* {widgetFormula.formulaObjectLength > 0 && (widgetFormula.Formula != null || widgetFormula.Formula != '') ? */}
          {widgetFormula.formulaObjectLength > 0 ?
            <div
              style={{
                width: '90%',
                position: 'absolute',
                bottom: '-10px',
                left: '5%',
                right: '5%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <p style={{ fontSize: '14px', color: 'white', marginRight: '10px' }}>Output</p>
              <div>
                <input
                  id="formulaOutput"
                  type="text"
                  className={styles.textInputField}
                  placeholder={'Ex. D1*100'}
                  value={widgetFormula.formulaOutput}
                  style={{ height: '20px', width: '300px', marginRight: '10px' }}
                  onChange={(event) => this.onOutputFormulaChangeHandler(event)}
                />
                <p style={{ margin: '0', height: '16px' }}>
                  {formulaOutputError &&
                    <small style={{ color: '#e73c1e' }}>Please enter output formula</small>
                  }
                </p>
              </div>

              <div style={{ width: '160px' }}>
                <Button
                  type="button"
                  color="green"
                  name="Submit"
                  noMargin
                  click={() => this.onSubmitHandler()}
                  // disabled={true}
                  disabled={!widgetFormula.formulaOutput || widgetFormula.formulaOutput === ''}
                />
              </div>
            </div>
            : null
          }

        </div>
      </Modal>
    );
  }
}

export default WidgetFormulaModal;