import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './VerifiedFail.module.scss';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

import { checkValidity, updateObject } from '../../../../state/utils';
import { resendVerify } from '../../../../state/ducks/Auth/actions';

class VerifiedFail extends Component {

  state = {
    formIsValid: false,
    resendEmailForm: {
      email: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true,
          isEmail: true
        }
      }
    }
  }

  handleChange = event => {
    let updatedElement = updateObject(this.state.resendEmailForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.resendEmailForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.resendEmailForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ resendEmailForm: updatedForm, formIsValid });
  };

  onResendEmailHandler = (event) => {
    event.preventDefault();
    if (this.state.formIsValid) {
      this.props.resendVerify(this.state.resendEmailForm.email.value);
    }
    else {
      this.onSetTouchedFields();
    }
  }

  onSetTouchedFields = () => {
    let newState = { ...this.state.resendEmailForm };
    Object.keys(this.state.resendEmailForm).forEach(name => {
      let updatedElement = updateObject(newState[name], {
        touched: true
      });
      const updatedForm = updateObject(newState, {
        [name]: updatedElement
      });
      newState = updatedForm;
      this.setState({ resendEmailForm: updatedForm });
    });
  }


  render() {
    const { email } = this.state.resendEmailForm;

    return (
      <div className={styles.VerifiedFail}>
        <div className={styles.Container}>
          <div style={{ flex: 1 }}>
            <div className={styles.Header}>Verification Failed</div>
            <div>
              <div className={styles.Description}>Please request a new verification link!</div>
              <form onSubmit={this.onResendEmailHandler} className={styles.FormContainer}>
                <div style={{ flex: 1, marginTop: 40 }}>
                  {this.props.error === 'User not found' ? <div className={styles.ErrorMessage}>Please check your email!</div> : ''}
                  <div className={styles.FormGroup}>
                    <Input
                      label="e-mail address"
                      name="email"
                      type="text"
                      value={email.value}
                      onChange={this.handleChange}
                      error={`${email.touched && !email.valid ? 'Email is required and must be the correct email format' : ''}`}
                      whitemode="true"
                    />
                  </div>
                </div>
                <div className={styles.ButtonContainer}>
                  <Button
                    type="submit"
                    color="primary"
                    name="Resend Verification Link"
                    noMargin
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { error } = state.auth;
  return { error };
};

export default connect(mapStateToProps, { resendVerify })(VerifiedFail);
