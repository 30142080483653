import React, { Component } from 'react';
import { connect } from 'react-redux';

import Backdrop from '../../UI/Backdrop/NoColorBackdrop';

import styles from './SymbolModal.module.scss';

class SymbolModal extends Component {
  render() {
    if (!this.props.show) return '';

    const { rowData, currentItemIndex } = this.props;
    let hasBracket = false;
    if (rowData) {
      if ((rowData[currentItemIndex - 2] &&rowData[currentItemIndex - 2].special === '(') || (rowData[currentItemIndex + 2] && rowData[currentItemIndex + 2].special === ')')) {
        hasBracket = true;
      }
    }

    return (
      <React.Fragment>
        <Backdrop show={this.props.show} clicked={this.props.closed} />
        {
          this.props.show ?
            <div className={styles.SymbolModal} style={{ width: rowData.length > 1 ? 250 : 160 }}>
              <div className={styles.SymbolsContainer}>
                <div className={`${styles.EachSymbol} ${this.props.selected === '+' ? styles.Selected : ''}`} onClick={() => this.props.selectSymbol('+')}>+</div>
                <div className={`${styles.EachSymbol} ${this.props.selected === '-' ? styles.Selected : ''}`} onClick={() => this.props.selectSymbol('-')}>-</div>
                <div className={`${styles.EachSymbol} ${this.props.selected === '*' ? styles.Selected : ''}`} onClick={() => this.props.selectSymbol('*')}>x</div>
                <div className={`${styles.EachSymbol} ${this.props.selected === '/' ? styles.Selected : ''}`} onClick={() => this.props.selectSymbol('/')}>/</div>
                {
                  rowData.length > 1
                    ? <>
                      <div className={styles.BorderDivider}></div>
                      <div className={`${styles.EachSymbol} ${hasBracket ? styles.Selected : ''}`} style={{ width: 75, fontSize: 18 }} onClick={(e) => this.props.toggleBracket(e, hasBracket)}>(A+B)</div>
                    </>
                    : null
                }

              </div>
            </div>
            : null
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(SymbolModal);