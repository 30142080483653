import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import _ from 'lodash';
// import Draggable from 'react-draggable';

import styles from './FloorPlan.module.scss';
import Button from '../../components/UI/Button/Button';
import goBackIcon from '../../../assets/icons/goback.svg';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
// import WidgetDrawer from '../../components/Widgets/WidgetDrawer/WidgetDrawer';
// import WidgetDrawerFloorPlan from '../../components/Widgets/WidgetDrawer/WidgetDrawer';
import WidgetDrawerFloorPlan from '../../components/Widgets/WidgetDrawerFloorPlan/WidgetDrawerFloorPlan';
import FloorPlanImageDrawer from '../../components/FloorPlan/FloorPlanImageDrawer/FloorPlanImageDrawer';
// import WidgetGrid from '../../components/Widgets/WidgetGrid/WidgetGrid';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import FullScreenIcon from '../../../assets/icons/full-screen.svg';
import NormalScreenIcon from '../../../assets/icons/normal-screen.svg';
// import Select from '../../components/UI/Select/Select';
import WidgetFormulaModal from '../../components/Widgets/WidgetFormulaModal/WidgetFormulaModal';
// import DateTime from '../../components/Widgets/Chart/DateTime/DateTime';

import { getPageAndMembers, editPage } from '../../../state/ducks/Pages/actions';
import { getDevice, getDevices } from '../../../state/ducks/Devices/actions';
import { addWidget, editWidget, deleteWidget, editWidgetLayout } from '../../../state/ducks/Widgets/actions';
import { setCurrentOrg } from '../../../state/ducks/Organization/actions';
import { EDIT_WIDGET_LAYOUT_SUCCESS, DELETE_WIDGET_SUCCESS } from '../../../state/ducks/Widgets/types';
import FloorPlanWidgetContainer from '../../components/FloorPlan/FloorPlanWidgetContainer';

// const customColumnOptions = [
//   { id: 3, name: '3 columns' },
//   { id: 4, name: '4 columns' },
//   { id: 5, name: '5 columns' },
//   { id: 6, name: '6 columns' },
// ];


const convertFormulaObjectFromStringToArray = (listString) => {
  let listArray = [];
  if (listString) {
    if (listString.includes("::")) {
      listArray = listString.split('::').map((el) => JSON.parse(el));
    } else {
      listArray.push(JSON.parse(listString));
    }
  }
  return listArray
}

class FloorPlan extends Component {
  state = {
    isDrawerOpened: false,
    pageId: null,
    dashboard: [],
    layout: [],
    isSavingNewWidget: false,
    savingWidget: null,
    deletingWidget: null,
    isDeleteModalOpened: false,
    isFullScreen: false,
    customColumns: 4,
    isAdding: false,
    editingWidget: null,
    updatedId: null,
    isEdit: false,
    isWidgetFormulaModalOpened: false,
    isWidgetFormulaChanged: false,
    widgetFormula: {
      formulaObject: '',
      formulaObjectList: [],
      formulaObjectLength: 0,
      formula: '',
      formulaOutput: '',
      formulaOutputError: false,
    },
    activeDrags: 0,
    isFloorPlanImageDrawerOpened: false,
    floorPlanImage: null
  }

  componentDidMount() {
    const { match: { params } } = this.props.route;
    this.props.getPageAndMembers(params.pageId);
    this.setState({ pageId: params.pageId });
  }

  shouldComponentUpdate(nextProps, nextState) {
    // if (nextProps.currentOrg !== this.props.currentOrg) {
    //   this.props.route.history.push('/');
    //   return false;
    // }
    return !nextProps.loading;
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("🚀 ~ file: FloorPlan.js ~ line 87 ~ FloorPlan ~ componentDidUpdate ~ prevProps", prevProps)
    // console.log("🚀 ~ file: FloorPlan.js ~ line 87 ~ FloorPlan ~ componentDidUpdate ~ prevState", prevState)
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.props.route.history.push('/pages');
    }
    if (this.props.result !== prevProps.result && this.props.result === 'success') {
      if ((prevProps.addedWidget !== this.props.addedWidget && this.props.addedWidget) ||
        (prevProps.updatedWidget !== this.props.updatedWidget && this.props.updatedWidget) || 
        this.props.type === EDIT_WIDGET_LAYOUT_SUCCESS) {
        this.props.getPageAndMembers(this.state.pageId);
      } else if (this.props.type === DELETE_WIDGET_SUCCESS) {
        this.props.getPageAndMembers(this.state.pageId);
      }
    }

    if (this.props.devices && prevProps.devices !== this.props.devices) {
      console.log(this.props.pageAndMembers.page.widgets)
      // when org is ok, and got devices -> first set data
      this.setDataForGrid(this.props.pageAndMembers.page.widgets, this.props.pageAndMembers.page.properties);
    }

    if (this.props.pageAndMembers && !prevProps.pageAndMembers) {
      // checking org first (maybe from the direct url with diff org!)
      if (this.props.pageAndMembers.page.org_id === this.props.currentOrg) {
        this.props.getDevices(this.props.currentOrg, true, true);
      } else {
        this.props.setCurrentOrg(this.props.pageAndMembers.page.org_id);
        this.props.getDevices(this.props.pageAndMembers.page.org_id, true, true);
      }
    } else if (this.props.pageAndMembers && prevProps.pageAndMembers) {
      if (this.props.pageAndMembers.page.widgets !== prevProps.pageAndMembers.page.widgets) {
        console.log(this.props.pageAndMembers.page.widgets)
        this.setDataForGrid(this.props.pageAndMembers.page.widgets, this.props.pageAndMembers.page.properties);
      }
    }

    if (this.props.pageResult !== prevProps.pageResult && this.props.pageResult === 'success') {
      this.props.getPageAndMembers(this.state.pageId);
    }
  }

  onClearUpdatedId = () => {
    this.setState({ updatedId: null });
  }

  drawerToggleHandler = () => {
    if (!this.state.isWidgetFormulaModalOpened) {
      this.setState(prevState => {
        return {
          isDrawerOpened: !prevState.isDrawerOpened,
          // isWidgetFormulaModalOpened: false,
          isWidgetFormulaChanged: false,
          widgetFormula: {
            formulaObject: '',
            formulaObjectList: [],
            formulaObjectLength: 0,
            formula: '',
            formulaOutput: '',
            formulaOutputError: false,
          },
        }
      });
    }
  }

  floorPlanImageDrawerToggleHandler = () => {
    this.setState(prevState => {
      return {
        isFloorPlanImageDrawerOpened: !prevState.isFloorPlanImageDrawerOpened,
      }
    });
  }

  openDrawerForAddingHandler = () => {
    this.setState({ isAdding: true, editingWidget: null, isDrawerOpened: true });
  }

  openFloorPlanImageDrawerHandler = () => {
    this.setState({ isFloorPlanImageDrawerOpened: true });
  }

  onEditWidget = (event, widget) => {
    event.stopPropagation();
    let newWidgetFormula = this.state.widgetFormula;
    let newFormula = this.state.isWidgetFormulaChanged ? newWidgetFormula.formula : widget !== null ? widget.formula : '';
    let newWidgetFormulaOutput = this.state.isWidgetFormulaChanged ? newWidgetFormula.formulaOutput : widget !== null ? widget.formula_output !== '' ? widget.formula_output : widget.formula : '';
    newWidgetFormula.formula = newFormula;
    newWidgetFormula.formulaOutput = newWidgetFormulaOutput;
    this.setState({ isAdding: false, editingWidget: widget, isDrawerOpened: true, widgetFormula: newWidgetFormula });
  }

  setDataForGrid = (widgets, PageProperties) => {
    console.log('-------------->>>>>>>setting dashboard...', widgets)
    const dashboard = [];
    let maxHeight, minHeight;
    const customColumns = PageProperties.widget_columns ? PageProperties.widget_columns : +PageProperties.widget_column;
    if (widgets) {
      widgets.forEach(widget => {
        // let channel;
        if (widget.type === 'time' || widget.type === 'date') {
          maxHeight = 1;
          minHeight = 1;
        } else {
          maxHeight = 2;
          minHeight = 2;
        }

        let lampProps = {};
        if (widget.properties && widget.properties.lampProps) {
          lampProps = widget.properties.lampProps;
        } else if (widget.properties && widget.properties.lamps) {
          // case: old lamp properties
          lampProps = {
            lampStyle: 'light',
            colorTriggers: widget.properties.lamps
          };
        }

        let switchProps = {};
        if (widget.properties && widget.properties.switchProps) {
          switchProps = widget.properties.switchProps;
        } else if (widget.properties && (widget.properties.on_value || widget.properties.on_value === 0)) {
          // case: old switch properties
          const { secret, socket } = widget.properties;
          const foundDevice = this.props.devices.filter(d => d.secret === secret);
          if (foundDevice[0]) {
            const foundDeviceSocket = foundDevice[0].device_sockets.filter(d => d.socket === socket)[0];
            const sensor = {
              id: foundDeviceSocket.id,
              name: foundDeviceSocket.name + " (" + foundDevice[0].name + ")",
              label: foundDeviceSocket.name + " (" + foundDevice[0].name + ")",
              secret: foundDeviceSocket.device_secret,
              socket: foundDeviceSocket.socket,
              unit: foundDeviceSocket.unit_name,
              equation: foundDeviceSocket.functions,
              can_control: foundDeviceSocket.can_control
            };
            switchProps = {
              conditions: [{
                sensor,
                on_value: widget.properties.on_value,
                off_value: widget.properties.off_value,
              }]
            };
          }
        }

        dashboard.push({
          id: widget.id,
          device_id: widget.properties ? widget.properties.device_id : null,
          secret: widget.properties ? widget.properties.secret : null,
          socket: widget.properties ? widget.properties.socket : null,
          unit: widget.properties ? widget.properties.unit : null,
          name: widget.title,
          type: widget.type,
          equation: widget.properties ? widget.properties.equation : null,
          static_value: widget.properties && widget.properties.static_value ? widget.properties.static_value : null,
          // on_value: widget.properties && widget.properties.on_value ? widget.properties.on_value : null,
          // off_value: widget.properties && widget.properties.off_value ? widget.properties.off_value : null,
          range: widget.properties && widget.properties.range ? widget.properties.range : null,
          colors: widget.properties && widget.properties.colors ? widget.properties.colors : null,
          time_range: widget.properties && widget.properties.time_range ? widget.properties.time_range : null,
          lampProps,
          gaugeProps: widget.properties && widget.properties.gaugeProps ? widget.properties.gaugeProps : null,
          formula: widget.formula ? widget.formula : null,
          formula_output: widget.formula_output ? widget.formula_output : null,
          formula_object: widget.formula_object ? widget.formula_object : null,
          customUnit: widget.properties && widget.properties.customUnit ? widget.properties.customUnit : null,
          powerProps: widget.properties && widget.properties.powerProps ? widget.properties.powerProps : null,
          switchProps,
          lineProps: widget.properties && widget.properties.lineProps ? widget.properties.lineProps : null,
          layout: {
            x: widget.x,
            y: widget.y,
            w: widget.w,
            h: widget.h,
            maxH: maxHeight,
            minH: minHeight
          }
        });
      });
    }
    this.setState({ dashboard, customColumns });
  }

  settingUpNewAddedWidget = (widgetForm, selectedSensors, sensor, colorRangeForm, timeRange, lineProps, lampProps, gaugeProps, formula, customUnit, powerProps, switchProps) => {
    console.log('settingUpNewAddedWidget')
    const updatedDashboard = [...this.state.dashboard];
    const { name, type, static_value } = widgetForm;

    let foundDevice;
    if (sensor) {
      this.props.devices.forEach(device => {
        device.device_sockets.forEach(ds => {
          if (ds.id === sensor.id) {
            foundDevice = ds;
          }
        });
      });
    }

    let width = 1, height = 2, maxHeight = 2, minHeight = 2;
    if (type.value === 'line') {
      width = 3;
    }

    let savingWidget;
    if (type.value === 'date' || type.value === 'time') {
      height = 1;
      maxHeight = 1;
      minHeight = 1;
      savingWidget = { id: 'new' + (updatedDashboard.length + 1), name: name.value, type: type.value };
      updatedDashboard.push({
        id: savingWidget.id,
        name: name.value,
        type: type.value,
        layout: {
          x: updatedDashboard.length % 4,
          y: Infinity,
          w: width,
          h: height,
          maxH: maxHeight,
          minH: minHeight
        }
      });
    } else {
      let device_id = [], secret = [], socket = [], unit = [], equation = [];
      if (selectedSensors) {
        // multiple line chart
        selectedSensors.forEach(sensor => {
          device_id.push(sensor.id);
          secret.push(sensor.secret);
          socket.push(sensor.socket);
          unit.push(sensor.unit);
          equation.push(sensor.equation);
        });
      }

      savingWidget = {
        id: 'new' + (updatedDashboard.length + 1),
        name: name.value,
        type: type.value,
        device_id: device_id.length ? device_id : (sensor ? +sensor.id : null),
        secret: secret.length ? secret : (foundDevice ? foundDevice.device_secret : null),
        socket: socket.length ? socket : (foundDevice ? foundDevice.socket : null),
        unit: unit.length ? unit : (foundDevice ? foundDevice.unit_name : null),
        equation: equation.length ? equation : (foundDevice ? foundDevice.functions : null),
        static_value: static_value.value ? static_value.value : null,
        // on_value: on_value.value ? on_value.value : null,
        // off_value: off_value.value ? off_value.value : null,
        colors: colorRangeForm ? colorRangeForm : null,
        time_range: timeRange ? timeRange : null,
        lampProps: lampProps ? lampProps : null,
        gaugeProps: gaugeProps ? gaugeProps : null,
        // formula: formula ? formula : null,
        formula: this.state.widgetFormula.formula ? this.state.widgetFormula.formula : null,
        formula_output: this.state.widgetFormula.formulaOutput ? this.state.widgetFormula.formulaOutput : '',
        formula_object: this.state.widgetFormula.formulaObject ? this.state.widgetFormula.formulaObject : '',
        customUnit: customUnit ? customUnit : null,
        powerProps: powerProps ? powerProps : null,
        switchProps: switchProps ? switchProps : null,
        lineProps: lineProps ? lineProps : null,
      };
      updatedDashboard.push({
        id: savingWidget.id,
        name: name.value,
        type: type.value,
        device_id: device_id.length ? device_id : (sensor ? +sensor.id : null),
        secret: secret.length ? secret : (foundDevice ? foundDevice.device_secret : null),
        socket: socket.length ? socket : (foundDevice ? foundDevice.socket : null),
        unit: unit.length ? unit : (foundDevice ? foundDevice.unit : null),
        equation: equation.length ? equation : (foundDevice ? foundDevice.functions : null),
        static_value: static_value.value ? static_value.value : null,
        // on_value: on_value.value ? on_value.value : null,
        // off_value: off_value.value ? off_value.value : null,
        colors: colorRangeForm ? colorRangeForm : null,
        time_range: timeRange ? timeRange : null,
        lampProps: lampProps ? lampProps : null,
        gaugeProps: gaugeProps ? gaugeProps : null,
        // formula: formula ? formula : null,
        formula: this.state.widgetFormula.formula ? this.state.widgetFormula.formula : null,
        formula_output: this.state.widgetFormula.formulaOutput ? this.state.widgetFormula.formulaOutput : '',
        formula_object: this.state.widgetFormula.formulaObject ? this.state.widgetFormula.formulaObject : '',
        customUnit: customUnit ? customUnit : null,
        powerProps: powerProps ? powerProps : null,
        switchProps: switchProps ? switchProps : null,
        lineProps: lineProps ? lineProps : null,
        layout: {
          x: updatedDashboard.length % 4,
          y: Infinity,
          w: width,
          h: height,
          maxH: maxHeight,
          minH: minHeight
        }
      });
    }
    // console.log("SAVING,,", savingWidget, updatedDashboard)

    this.setState({
      savingWidget,
      dashboard: updatedDashboard,
      isDrawerOpened: false,
      isSavingNewWidget: true,
    }, () => {
      this.addNewWidgetFloorPlan()
    });

  }

  settingUpEditWidget = (widgetId, widgetForm, selectedSensors, sensor, lineChartRange, colorRangeForm, dateTimeRange, lineProps, lampProps, gaugeProps, formula, customUnit, powerProps, switchProps) => {
    const updatedDashboard = [...this.state.dashboard];
    const updatedDashboardIndex = updatedDashboard.findIndex(dash => dash.id === widgetId);
    const { name, type, static_value } = widgetForm;
    let foundDevice;
    if (sensor) {
      this.props.devices.forEach(device => {
        device.device_sockets.forEach(ds => {
          if (ds.id === sensor.id) {
            foundDevice = ds;
          }
        });
      });
    }

    let height = 2, maxHeight = 2, minHeight = 2;
    let updatingWidget;
    if (type.value === 'date' || type.value === 'time') {
      height = 1;
      maxHeight = 1;
      minHeight = 1;
      updatingWidget = { id: widgetId, name: name.value, type: type.value };
      updatedDashboard[updatedDashboardIndex] = {
        id: widgetId,
        name: name.value,
        type: type.value,
        layout: {
          x: updatedDashboard[updatedDashboardIndex].layout.x,
          y: updatedDashboard[updatedDashboardIndex].layout.y,
          w: updatedDashboard[updatedDashboardIndex].layout.w,
          h: height,
          maxH: maxHeight,
          minH: minHeight
        }
      }
    } else {
      let device_id = [], secret = [], socket = [], unit = [], equation = [];
      if (type.value === 'line' && selectedSensors) {
        // multiple line chart
        selectedSensors.forEach(sensor => {
          device_id.push(sensor.id);
          secret.push(sensor.secret);
          socket.push(sensor.socket);
          unit.push(sensor.unit);
          equation.push(sensor.equation);
        });
      }

      updatingWidget = {
        id: widgetId,
        name: name.value,
        type: type.value,
        device_id: device_id.length ? device_id : (sensor ? +sensor.id : null),
        secret: secret.length ? secret : (foundDevice ? foundDevice.device_secret : null),
        socket: socket.length ? socket : (foundDevice ? foundDevice.socket : null),
        unit: unit.length ? unit : (foundDevice ? foundDevice.unit_name : null),
        equation: equation.length ? equation : (foundDevice ? foundDevice.functions : null),
        static_value: static_value.value ? static_value.value : null,
        // on_value: on_value.value ? on_value.value : null,
        // off_value: on_value.value ? off_value.value : null,
        range: lineChartRange ? lineChartRange : null,
        colors: colorRangeForm ? colorRangeForm : null,
        time_range: dateTimeRange ? dateTimeRange : null,
        lampProps: lampProps ? lampProps : null,
        gaugeProps: gaugeProps ? gaugeProps : null,
        // formula: formula ? formula : null,
        formula: this.state.widgetFormula.formula ? this.state.widgetFormula.formula : null,
        formula_output: this.state.widgetFormula.formulaOutput ? this.state.widgetFormula.formulaOutput : '',
        formula_object: this.state.widgetFormula.formulaObject ? this.state.widgetFormula.formulaObject : '',
        customUnit: customUnit ? customUnit : null,
        powerProps: powerProps ? powerProps : null,
        switchProps: switchProps ? switchProps : null,
        lineProps: lineProps ? lineProps : null,
      };
      updatedDashboard[updatedDashboardIndex] = {
        id: widgetId,
        name: name.value,
        type: type.value,
        device_id: device_id.length ? device_id : (sensor ? +sensor.id : null),
        secret: secret.length ? secret : (foundDevice ? foundDevice.device_secret : null),
        socket: socket.length ? socket : (foundDevice ? foundDevice.socket : null),
        unit: unit.length ? unit : (foundDevice ? foundDevice.unit : null),
        equation: equation.length ? equation : (foundDevice ? foundDevice.functions : null),
        static_value: static_value.value ? static_value.value : null,
        // on_value: on_value.value ? on_value.value : null,
        // off_value: on_value.value ? off_value.value : null,
        range: lineChartRange ? lineChartRange : null,
        colors: colorRangeForm ? colorRangeForm : null,
        time_range: dateTimeRange ? dateTimeRange : null,
        lampProps: lampProps ? lampProps : null,
        gaugeProps: gaugeProps ? gaugeProps : null,
        // formula: formula ? formula : null,
        formula: this.state.widgetFormula.formula ? this.state.widgetFormula.formula : null,
        formula_output: this.state.widgetFormula.formulaOutput ? this.state.widgetFormula.formulaOutput : '',
        formula_object: this.state.widgetFormula.formulaObject ? this.state.widgetFormula.formulaObject : '',
        customUnit: customUnit ? customUnit : null,
        powerProps: powerProps ? powerProps : null,
        switchProps: switchProps ? switchProps : null,
        lineProps: lineProps ? lineProps : null,
        layout: {
          x: updatedDashboard[updatedDashboardIndex].layout.x,
          y: updatedDashboard[updatedDashboardIndex].layout.y,
          w: updatedDashboard[updatedDashboardIndex].layout.w,
          h: height,
          maxH: maxHeight,
          minH: minHeight
        }
      };
    }

    // console.log("----------- UPDATE WIDGET HERE -------------")
    const { x, y, w, h } = updatedDashboard[updatedDashboardIndex].layout;
    const device_id = updatingWidget.device_id;
    const secret = updatingWidget.secret;
    const socket = updatingWidget.socket;
    const unit = updatingWidget.unit;
    const equation = updatingWidget.equation;
    const range = updatingWidget.range;
    let properties = {
      // for only line type
      device_id, secret, socket, unit, equation, static_value: updatingWidget.static_value, range, lineProps: updatingWidget.lineProps
    };

    if (updatingWidget.type === 'date' || updatingWidget.type === 'time') {
      properties = null;
    } else if (updatingWidget.type === 'timeline') {
      properties = {
        secret, socket, unit, equation, static_value: updatingWidget.static_value, range,
        colors: updatingWidget.colors, time_range: updatingWidget.time_range
      }
    } else if (updatingWidget.type === 'switch') {
      properties = {
        secret, socket, unit, equation, switchProps: updatingWidget.switchProps
        // on_value: updatingWidget.on_value, off_value: updatingWidget.off_value
      }
    } else if (updatingWidget.type === 'lamp') {
      properties = {
        secret, socket, unit, equation, lampProps: updatingWidget.lampProps
      }
    } else if (updatingWidget.type === 'gauge') {
      properties = {
        secret, socket, unit, equation, gaugeProps: updatingWidget.gaugeProps
      };
    } else if (updatingWidget.type === 'text') {
      properties = {
        customUnit: updatingWidget.customUnit
      };
    } else if (updatingWidget.type === 'power') {
      properties = {
        device_id, secret, socket, unit, equation, powerProps: updatingWidget.powerProps
      }
    }

    if (!this.props.isMobile)
      this.props.editWidget(this.state.pageId, updatingWidget.id, updatingWidget.name, updatingWidget.type, updatingWidget.device_id, x, y, w, h, updatingWidget.formula, updatingWidget.formula_output, updatingWidget.formula_object, properties);

    // console.log('setupEditWidget');
    this.setState({
      dashboard: updatedDashboard,
      isDrawerOpened: false,
      updatedId: widgetId,
      isWidgetFormulaChanged: false,
      widgetFormula: {
        formulaObject: '',
        formulaObjectList: [],
        formulaObjectLength: 0,
        formula: '',
        formulaOutput: '',
        formulaOutputError: false,
      },
    });
  }

  onLayoutChange = (layout) => {
    let newDashboard = [...this.state.dashboard];
    if (this.state.isSavingNewWidget) {
      // console.log("------------ ON LAYOUT CHANGE, UPDATE HERE -------------")
      const { id, name, type, device_id, secret, socket, unit, equation, static_value, colors, time_range, lampProps, gaugeProps, formula, formula_output, formula_object, customUnit, powerProps, switchProps, lineProps } = this.state.savingWidget;
      const fountLayout = layout.filter(lo => id === lo.i);
      const { x, y, w, h } = fountLayout[0];
      let properties = {
        device_id, secret, socket, unit, equation, static_value, lineProps
      };
      if (type === 'date' || type === 'time') {
        properties = null;
      } else if (type === 'timeline') {
        properties = {
          secret, socket, unit, equation, static_value, colors, time_range
        };
      } else if (type === 'switch') {
        properties = {
          secret, socket, unit, equation, switchProps
          // on_value, off_value
        };
      } else if (type === 'lamp') {
        properties = {
          secret, socket, unit, equation, lampProps
        };
      } else if (type === 'gauge') {
        properties = {
          secret, socket, unit, equation, gaugeProps
        };
      } else if (type === 'text') {
        properties = {
          customUnit
        };
      } else if (type === 'power') {
        properties = {
          device_id, secret, socket, unit, equation, powerProps
        };
      }

      this.props.addWidget(this.state.pageId, name, type, device_id, x, y, w, h, formula, formula_output, formula_object, properties);
      this.setState({
        isSavingNewWidget: false,
        isWidgetFormulaChanged: false,
        widgetFormula: {
          formulaObject: '',
          formulaObjectList: [],
          formulaObjectLength: 0,
          formula: '',
          formulaOutput: '',
          formulaOutputError: false,
        },
      });
    } else if (this.state.dashboard && layout.length === this.state.dashboard.length) {
      // console.log("updated LAYOUT ->", layout)
      this.state.dashboard.forEach((dash, i) => {
        if (!this.props.isMobile)
          this.props.editWidgetLayout(
            this.state.pageId,
            dash.id,
            layout[i].x,
            layout[i].y,
            layout[i].w,
            layout[i].h
          );
        newDashboard[i].layout = { h: layout[i].h, maxH: layout[i].maxH, minH: layout[i].minH, w: layout[i].w, x: layout[i].x, y: layout[i].y };
      });
      this.setState({ initialedSaveLocation: true, dashboard: newDashboard })
    }
    // need save location
    // this.setState({ layout });
  }

  onDeleteWidgetHandler = (event, widget) => {
    event.stopPropagation();
    const deletingWidget = {
      id: widget.id,
      device_id: widget.device_id,
      secret: widget.secret,
      socket: widget.socket
    };
    this.setState({ deletingWidget, isDeleteModalOpened: true, isDrawerOpened: false });
  }

  deleteModalClickHandler = () => {
    this.setState(prevState => {
      return { isDeleteModalOpened: !prevState.isDeleteModalOpened }
    });
  }

  onConfirmDeleteWidget = (event) => {
    event.preventDefault();
    if (this.state.deletingWidget) {
      const { id } = this.state.deletingWidget;
      this.props.deleteWidget(this.state.pageId, id);
      this.setState({ deletingWidget: null, isDeleteModalOpened: false });
    }
  }

  goToPages = () => {
    this.props.route.history.push('/pages');
  }

  toggleFullScreen = () => {
    this.setState(prevState => {
      return { isFullScreen: !prevState.isFullScreen }
    });
  }

  handleChange = (newColumns) => {
    const customColumns = +newColumns;
    if (this.props.pageAndMembers) {
      let newProperties = { ...this.props.pageAndMembers.page.properties };
      newProperties.widget_columns = customColumns;
      this.props.editPage(this.state.pageId, null, null, null, null, newProperties);
      this.setState({ customColumns })
    }
  }

  toggleEdit = () => {
    this.setState({
      isEdit: !this.state.isEdit,
    });
  }

  onWidgetFormulaEditHandler = (event, editingWidget) => {
    event.stopPropagation();
    if (!this.state.isWidgetFormulaChanged && editingWidget !== null) {
      let newWidgetFormula = editingWidget !== null ? editingWidget.formula : '';
      let newWidgetFormulaOutput = editingWidget !== null ? editingWidget.formula_output : '';
      let newWidgetFormulaObject = editingWidget !== null ? editingWidget.formula_object : '';
      let newWidgetFormulaObjectList = convertFormulaObjectFromStringToArray(newWidgetFormulaObject);

      this.setState(prevState => ({
        isWidgetFormulaModalOpened: true,
        widgetFormula: {
          ...prevState.widgetFormula,
          formula: newWidgetFormula,
          formulaOutput: newWidgetFormulaOutput,
          formulaObject: newWidgetFormulaObject,
          formulaObjectList: newWidgetFormulaObjectList,
          formulaObjectLength: newWidgetFormulaObjectList.length,
        }
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        isWidgetFormulaModalOpened: true,
      }));
    }
  }

  widgetFormulaModalCloseHandler = (forSubmit) => {
    if (!forSubmit) {
      let newFormulaOutput = this.state.editingWidget ? this.state.editingWidget.formula_output !== '' ? this.state.editingWidget.formula_output : this.state.editingWidget.formula : '';
      this.setState(prevState => {
        return {
          isWidgetFormulaModalOpened: !prevState.isWidgetFormulaModalOpened,
          widgetFormula: {
            formulaObject: '',
            formulaObjectList: [],
            formulaObjectLength: 0,
            formula: '',
            formulaOutput: newFormulaOutput,
            formulaOutputError: false,
          },
        }
      });
    } else {
      this.setState(prevState => {
        return {
          isWidgetFormulaModalOpened: !prevState.isWidgetFormulaModalOpened,
        }
      });
    }
  }

  widgetFormulaUpdateHandler = (widgetFormula, widgetFormulaOutput) => {
    this.setState(prevState => {
      return ({
        widgetFormula: {
          ...prevState.widgetFormula,
          formula: widgetFormula,
          formulaOutput: widgetFormulaOutput
        }
      })
    });
  }

  widgetFormulaObjectUpdateHandler = (formulaObject, formulaObjectList) => {
    this.setState(prevState => ({
      ...prevState,
      widgetFormula: {
        ...prevState.widgetFormula,
        formulaObject: formulaObject,
        formulaObjectList: formulaObjectList,
        formulaObjectLength: formulaObjectList.length,

      }
    }));
  }

  onWidgetFormulaSubmitHandler = () => {
    if (this.state.editingWidget) {
      if ((this.state.editingWidget.formula !== this.state.widgetFormula.formula) ||
        (this.state.editingWidget.formula_output !== this.state.widgetFormula.formulaOutput) ||
        (this.state.editingWidget.formula_object !== this.state.widgetFormula.formulaObject)
      ) {
        this.setState(prevState => ({
          ...prevState,
          isWidgetFormulaChanged: true
        }))
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        isWidgetFormulaChanged: true
      }))
    }
  }

  onStart = () => {
    this.setState({ activeDrags: ++this.state.activeDrags });
  };

  onStop = () => {
    this.setState({ activeDrags: --this.state.activeDrags });
  };

  addNewWidgetFloorPlan = () => {
    const { id, name, type, device_id, secret, socket, unit, equation, static_value, colors, time_range, lampProps, gaugeProps, formula, formula_output, formula_object, customUnit, powerProps, switchProps, lineProps } = this.state.savingWidget;
    const { x, y, w, h } = { x: 1, y: 1, w: 1, h: 1 };
    let properties = {
      device_id, secret, socket, unit, equation, static_value, lineProps
    };
    if (type === 'date' || type === 'time') {
      properties = null;
    } else if (type === 'timeline') {
      properties = {
        secret, socket, unit, equation, static_value, colors, time_range
      };
    } else if (type === 'switch') {
      properties = {
        secret, socket, unit, equation, switchProps
        // on_value, off_value
      };
    } else if (type === 'lamp') {
      properties = {
        secret, socket, unit, equation, lampProps
      };
    } else if (type === 'gauge') {
      properties = {
        secret, socket, unit, equation, gaugeProps
      };
    } else if (type === 'text') {
      properties = {
        customUnit
      };
    } else if (type === 'power') {
      properties = {
        device_id, secret, socket, unit, equation, powerProps
      };
    }

    this.props.addWidget(this.state.pageId, name, type, device_id, x, y, w, h, formula, formula_output, formula_object, properties);
  }

  render() {
    let ScreenStyle = styles.MinimizeScreen;
    let WidgetGridKey = 'MinimizeScreen';
    if (this.state.isFullScreen) {
      ScreenStyle = styles.FullScreen;
      WidgetGridKey = 'FullScreen';
    }

    const deviceOptions = [];
    if (this.props.devices) {
      this.props.devices.forEach(device => {
        device.device_sockets.forEach(ds => {
          deviceOptions.push({
            id: ds.id,
            name: ds.name + " (" + device.name + ")",
            label: ds.name + " (" + device.name + ")",
            secret: ds.device_secret,
            socket: ds.socket,
            unit: ds.unit_name,
            equation: ds.functions,
            can_control: ds.can_control
          });
        });
      });
    }

    const { isDrawerOpened, pageId, isAdding, editingWidget, customColumns, isFullScreen, dashboard, updatedId, isDeleteModalOpened, isWidgetFormulaModalOpened, widgetFormula, isFloorPlanImageDrawerOpened } = this.state;

    let isMobile = this.props.isMobile;

    let topMenu = [];
    if (!isMobile) {
      if (!this.state.isEdit) {
        topMenu.push(<div key="fullscreen-btn" className={styles.ScreenButton}>
          {
            isFullScreen ?
              <img src={NormalScreenIcon} alt="Normal Screen Icon" onClick={this.toggleFullScreen} />
              :
              <div className={styles.ScreenWrapper} onClick={this.toggleFullScreen} >
                <img src={FullScreenIcon} alt="Full Screen Icon" />
                <div className={styles.ScreenTitle}>Full Screen</div>
              </div>
          }
        </div>);
        topMenu.push(<div key="edit-btn" style={{ width: 80 }}><Button type="button" color="primary" name="Edit" noMargin click={this.toggleEdit} /></div>);
      } else {
        // topMenu.push(<div key="cols-btn" className={styles.CustomGrid}>
        //   <Select
        //     name="customColumns"
        //     placeholder="Select a cols"
        //     options={customColumnOptions}
        //     value={customColumns ? customColumns : 4}
        //     autoComplete="off"
        //     overidestyleformgroup={{ marginBottom: 0 }}
        //     onChange={(event) => this.handleChange(event.target.value)}
        //   />
        // </div>);
        topMenu.push(<div key="fullscreen-btn" className={styles.ScreenButton}>
          {
            isFullScreen ?
              <img src={NormalScreenIcon} alt="Normal Screen Icon" onClick={this.toggleFullScreen} />
              :
              <div className={styles.ScreenWrapper} onClick={this.toggleFullScreen} >
                <img src={FullScreenIcon} alt="Full Screen Icon" />
                <div className={styles.ScreenTitle}>Full Screen</div>
              </div>
          }
        </div>);
        // topMenu.push(<div key="add-btn" style={{ width: 190, marginRight: 30 }}><Button type="button" color="primary" name="Add More Widgets" noMargin click={this.openDrawerForAddingHandler} /></div>);
        topMenu.push(<div key="plan-btn" style={{ width: 190, marginRight: 30 }}><Button type="button" color="primary" name="Edit Floor Plan Image" noMargin click={this.openFloorPlanImageDrawerHandler} /></div>);
        topMenu.push(<div key="add-btn" style={{ width: 190, marginRight: 30 }}><Button type="button" color="primary" name="Add More Widgets" noMargin click={this.openDrawerForAddingHandler} /></div>);
        topMenu.push(<div key="done-btn" style={{ width: 80 }}><Button type="button" color="green" name="Done" noMargin click={this.toggleEdit} /></div>);
      }
    }

    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };

    return (
      <div className={ScreenStyle}>
        {
          this.props.error ?
            <div className={styles.NoPagesWrapper}>
              <div className={styles.NoPagesInfo}>Something went wrong</div>
              <div className={styles.NoPagesButtonWrapper}><Button type="button" color="primary" name="Go back to Pages" click={this.goToPages} noMargin /></div>
            </div>
            :
            this.props.pageAndMembers &&
            <React.Fragment>
              <SideDrawer noHeader open={isFloorPlanImageDrawerOpened} closed={this.floorPlanImageDrawerToggleHandler}>
                <FloorPlanImageDrawer
                  closed={this.floorPlanImageDrawerToggleHandler}
                  dashboard={dashboard}
                  pageId={pageId}
                  orgId={this.props.pageAndMembers ? this.props.pageAndMembers.page.org_id : null}
                  settingUpNewAddedWidget={this.settingUpNewAddedWidget}
                  deviceOptions={deviceOptions}
                  isAdding={isAdding}
                  editWidget={editingWidget}
                  settingUpEditWidget={this.settingUpEditWidget}
                  onDelete={this.onDeleteWidgetHandler}
                  onEditFormula={this.onWidgetFormulaEditHandler}
                  selectedWidgetFormula={widgetFormula.formula}
                  selectedWidgetFormulaOutput={widgetFormula.formulaOutput}
                  pageAndMembers={this.props.pageAndMembers}
                />
              </SideDrawer>
              <SideDrawer noHeader open={isDrawerOpened} closed={this.drawerToggleHandler}>
                <WidgetDrawerFloorPlan
                  closed={this.drawerToggleHandler}
                  dashboard={dashboard}
                  pageId={pageId}
                  orgId={this.props.pageAndMembers ? this.props.pageAndMembers.page.org_id : null}
                  settingUpNewAddedWidget={this.settingUpNewAddedWidget}
                  deviceOptions={deviceOptions}
                  isAdding={isAdding}
                  editWidget={editingWidget}
                  settingUpEditWidget={this.settingUpEditWidget}
                  onDelete={this.onDeleteWidgetHandler}
                  onEditFormula={this.onWidgetFormulaEditHandler}
                  selectedWidgetFormula={widgetFormula.formula}
                  selectedWidgetFormulaOutput={widgetFormula.formulaOutput}
                />
              </SideDrawer>
              {/* Header */}
              <div className={styles.TopSection}>
                <div style={{ flex: 1 }}>
                  <Link to='/pages'><img src={goBackIcon} alt="Go back to page" /></Link>
                  <span className={styles.PageName} >{this.props.pageAndMembers ? this.props.pageAndMembers.page.name : ''}</span>
                </div>
                {topMenu}
              </div>
              {/* Content */}
              {/* <div className={styles.WidgetSection}>
                <WidgetGrid
                  key={`${WidgetGridKey}Cols${isMobile ? 1 : customColumns}`}
                  openDrawer={this.openDrawerForAddingHandler}
                  dashboard={dashboard}
                  onLayoutChange={this.onLayoutChange}
                  cols={isMobile ? 1 : customColumns ? customColumns : 4}
                  deviceOptions={deviceOptions}
                  updatedId={updatedId}
                  onClearUpdatedId={this.onClearUpdatedId}
                  onEditWidget={this.onEditWidget}
                  isEdit={!isMobile && this.state.isEdit}
                  isDraggable={!isMobile && this.state.isEdit}
                  isResizable={!isMobile && this.state.isEdit}
                />
              </div> */}

              {/* Plan container */}
              <FloorPlanWidgetContainer
                page={this.props.pageAndMembers.page}
                dashboard={dashboard}
                isFullScreen={isFullScreen}
              />

              <DeleteModal
                title="widget"
                open={isDeleteModalOpened}
                modalClosed={this.deleteModalClickHandler}
                confirmDelete={this.onConfirmDeleteWidget}
              />

              {isWidgetFormulaModalOpened ?
                <WidgetFormulaModal
                  title="widget formula"
                  open={isWidgetFormulaModalOpened}
                  modalClosed={this.widgetFormulaModalCloseHandler}
                  deviceOptions={deviceOptions}
                  editWidget={editingWidget}
                  widgetFormula={widgetFormula}
                  formulaObjectUpdate={this.widgetFormulaObjectUpdateHandler}
                  widgetFormulaUpdate={this.widgetFormulaUpdateHandler}
                  onWidgetFormulaSubmit={this.onWidgetFormulaSubmitHandler}
                />
                : null
              }
            </React.Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { pageAndMembers, error, result: pageResult, type: pageType } = state.pages;
  const { currentOrg } = state.org;
  const { type, result, addedWidget, updatedWidget, loading } = state.widgets;
  const { device, devices } = state.devices;
  const widgetError = state.widgets.error;
  return {
    type, device, devices, result, pageAndMembers, widgetError,
    error, currentOrg, addedWidget, updatedWidget, loading, pageResult, pageType
  };
};

export default connect(mapStateToProps, {
  getPageAndMembers,
  addWidget,
  editWidget,
  editWidgetLayout,
  deleteWidget,
  getDevice,
  getDevices,
  editPage,
  setCurrentOrg
})(FloorPlan);
