import * as actionTypes from './types';

// ----------------------- ADD COMMAND -----------------------
export const addCommandStart = () => {
  return {
    type: actionTypes.ADD_COMMAND_START
  };
}

export const addCommand = (org_id, device_socket_id, value) => {
  return {
    type: actionTypes.ADD_COMMAND,
    org_id, device_socket_id, value
  };
}

export const addCommandSuccess = (response) => {
  return {
    type: actionTypes.ADD_COMMAND_SUCCESS,
    // addedCommand: response.command,
    result: response.result
  };
}

export const addCommandFail = (error) => {
  return {
    type: actionTypes.ADD_COMMAND_FAIL,
    error: error
  };
}

// export default {
//   addCommandStart,
//   addCommand,
//   addCommandSuccess,
//   addCommandFail
// };