import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './ProductionBatchs.module.scss';
import Modal from '../../UI/Modal/Modal';
import Button from '../../UI/Button/Button';
import SearchIcon from '../../../../assets/icons/search.svg';
import PlusCircleIcon from '../../../../assets/icons/plus-gray.svg';
import CloseIcon from '../../../../assets/icons/close-gray.svg';
import PlanIcon from '../../../../assets/icons/plan-icon.svg';
import LoadingWideCard from '../../UI/LoadingSkeleton/LoadingWideCard/LoadingWideCard';

import { getAdvanceProductionPlans } from '../../../../state/ducks/AdvanceProductionPlan/actions';

class BatchMapsPlans extends Component {
    state = {
        start: '',
        end: '',
        addedPlans: []
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleEsc);
        const defaultStop = new Date().setHours(23, 59, 59, 999);
        const defaultStart = new Date().setHours(0, 0, 0, 0);
        const start = moment(defaultStart).format('YYYY-MM-DD');
        const end = moment(defaultStop).format('YYYY-MM-DD');
        this.setState({ start, end });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentPlans !== this.props.currentPlans) {
            const addedPlans = this.props.currentPlans ? [...this.props.currentPlans] : [];

            const defaultStop = new Date().setHours(23, 59, 59, 999);
            const defaultStart = new Date().setHours(0, 0, 0, 0);
            const start = moment(defaultStart).format('YYYY-MM-DD');
            const end = moment(defaultStop).format('YYYY-MM-DD');
            this.setState({ start, end, addedPlans });
            this.props.getAdvanceProductionPlans(this.props.currentOrg, null, defaultStart, defaultStop);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleEsc);
    }

    handleEsc = (event) => {
        if (this.props.open && event.keyCode === 27) {
            this.props.modalClosed();
        }
    };

    handleDateChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    onSearchPlansHandler = (event) => {
        event.preventDefault();
        const end = new Date(this.state.end).setHours(23, 59, 59, 999);
        const start = new Date(this.state.start).setHours(0, 0, 0, 0);
        this.props.getAdvanceProductionPlans(this.props.currentOrg, null, start, end);
    }

    preparePlanHandler = (index, how) => {
        const addedPlans = [...this.state.addedPlans];
        if (how === 'remove') {
            addedPlans.splice(index, 1);
        } else {
            addedPlans.push(this.props.advanceProductionPlans[index]);
        }
        this.setState({ addedPlans });
    }

    render() {
        return (
            <Modal show={this.props.open} modalClosed={this.props.modalClosed} overideStyles={{ width: 520 }}>
                <div className={styles.MapPlansModal}>
                    <div className={styles.HeaderWrapper}>
                        <div className={styles.Header}>Mapping Plans</div>
                    </div>
                    <div className={styles.BodyWrapper}>

                        {
                            this.state.addedPlans && this.state.addedPlans.length
                                ? <div className={styles.AddedPlansWrapper}>
                                    {
                                        this.state.addedPlans.map((ap, di) =>
                                            <div className={styles.AddedPlanBox} key={ap.uuid}
                                                style={{
                                                    marginRight: di % 2 === 0 && di !== this.state.addedPlans.length - 1 ? '10px' : 0,
                                                    flex: di % 2 === 0 ? ' 0 1 200px' : '1 1 190px'
                                                }}
                                            >
                                                <div className={styles.MappingPO}>{ap.po}</div>
                                                <img src={CloseIcon} style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => this.preparePlanHandler(di, 'remove')} />
                                            </div>
                                        )
                                    }
                                </div>
                                : null
                        }
                        <div className={styles.SearchWrapper}>
                            <input
                                className={styles.DateBox}
                                type="date"
                                onChange={this.handleDateChange}
                                name="start"
                                value={this.state.start}
                            />
                            <input
                                className={styles.DateBox}
                                type="date"
                                onChange={this.handleDateChange}
                                name="end"
                                value={this.state.end}
                            />
                            <div className={styles.SearchButton} onClick={this.onSearchPlansHandler}>
                                <img src={SearchIcon} style={{ width: 16, height: 16 }} />
                            </div>
                        </div>
                        <div className={styles.PlanSearchResult}>
                            {
                                this.props.loading
                                    ? <LoadingWideCard lightTheme />
                                    : this.props.productionBatchs && this.props.advanceProductionPlans && this.props.advanceProductionPlans.length
                                        ? this.props.advanceProductionPlans.map((plan, i) => {
                                            if (!this.state.addedPlans.find(ap => ap.uuid === plan.uuid)) {
                                                return <div key={`plan-${plan.uuid}`} className={styles.PlanRow} onClick={() => this.preparePlanHandler(i)}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={PlanIcon} style={{ width: 20, height: 20, marginRight: '10px' }} />
                                                        <span style={{ wordBreak: 'break-all' }}>{plan.po}</span>
                                                        <span style={{ opacity: 0.65, marginLeft: 5, fontSize: 12 }}>
                                                            {plan.batch_uuid && plan.batch_uuid !== '0' ? '(' + this.props.productionBatchs.find(pb => pb.uuid === plan.batch_uuid)?.batch_number + ')' : ''}
                                                        </span>
                                                    </div>
                                                    <img src={PlusCircleIcon} style={{ width: 20, height: 20 }} />
                                                </div>;
                                            }
                                        })
                                        : null
                            }
                        </div>
                    </div>
                    <div className={styles.ButtonsWrapper}>
                        <div className={styles.YesButtonWrapper}>
                            <Button type="button" color="green" name="Save" click={() => this.props.confirmMappingPlans(this.state.addedPlans)} noMargin />
                        </div>
                        <div className={styles.CancelButton} onClick={this.props.modalClosed}>Cancel</div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { advanceProductionPlans, loading, type, result } = state.advanceProductionPlan;
    const { productionBatchs } = state.advanceProductionBatch;
    return { currentOrg, advanceProductionPlans, loading, type, result, productionBatchs };
};

export default connect(mapStateToProps, { getAdvanceProductionPlans })(BatchMapsPlans);