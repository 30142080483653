import React, { useEffect } from 'react';
import axios from 'axios';

export const server = 'develop';

let baseURLs = {
    'production': 'ws.technimal.co.th',
    'develop': '172.30.62.121',
    'demo': 'demo.technimal.co.th',
}

const OperatorWs = ({ authToken, onMessage, currentOrg }) => {

    useEffect(() => {
        const url = 'https://' + baseURLs[server] + '/auth';
        const t = axios.get(url, { headers: { Authorization: `Bearer ${authToken}` } })
            .then(res => {
                const WS_CLIENT = new WebSocket(`wss://${baseURLs[server]}/ws?org_id=${currentOrg}&token=${res.data}`);
                WS_CLIENT.onopen = () => {
                    console.log('WebSocket Client Connected');
                };
                WS_CLIENT.onmessage = (message) => {
                    onMessage(JSON.parse(message.data));
                };
            })

    }, []);

    return <></>;
}

export default OperatorWs;