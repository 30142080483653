import * as actionTypes from './types';

// ----------------------- GET RULES -----------------------
export const getRulesStart = () => {
  return {
    type: actionTypes.GET_RULES_START
  };
}

export const getRules = (org_id, name, enable, rule_type) => {
  return {
    type: actionTypes.GET_RULES,
    org_id, name, enable, rule_type
  };
}

export const getRulesSuccess = (response) => {
  return {
    type: actionTypes.GET_RULES_SUCCESS,
    rules: response.rules
  };
}

export const getRulesFail = (error) => {
  return {
    type: actionTypes.GET_RULES_FAIL,
    error: error
  };
}

// ----------------------- ADD RULE -----------------------
export const addRuleStart = () => {
  return {
    type: actionTypes.ADD_RULE_START
  };
}

export const addRule = (org_id, ruleForm, conditionForm, commandForm) => {
  return {
    type: actionTypes.ADD_RULE,
    org_id, ruleForm, conditionForm, commandForm
  };
}

export const addRuleSuccess = (response) => {
  return {
    type: actionTypes.ADD_RULE_SUCCESS,
    addedRule: response.rule,
    result: response.result
  };
}

export const addRuleFail = (response) => {
  return {
    type: actionTypes.ADD_RULE_FAIL,
    error: {
      text: response.error,
      field: response.field ? response.field : null
    }
  };
}

// ----------------------- ENABLE RULE -----------------------
export const enableRuleStart = () => {
  return {
    type: actionTypes.ENABLE_RULE_START
  };
}

export const enableRule = (id, enable, calculation_method, sampling_size) => {
  return {
    type: actionTypes.ENABLE_RULE,
    id, enable, calculation_method, sampling_size
  };
}

export const enableRuleSuccess = (response) => {
  return {
    type: actionTypes.ENABLE_RULE_SUCCESS,
    updatedEnableRule: response.rule,
    result: response.result
  };
}

export const enableRuleFail = (response) => {
  return {
    type: actionTypes.ENABLE_RULE_FAIL,
    error: response.error
  };
}

// ----------------------- EDIT RULE -----------------------
export const editRuleStart = () => {
  return {
    type: actionTypes.EDIT_RULE_START
  };
}

export const editRule = (id, ruleForm, conditionForm, commandForm, deleteConditions, deleteCommands) => {
  return {
    type: actionTypes.EDIT_RULE,
    id, ruleForm, conditionForm, commandForm, deleteConditions, deleteCommands
  };
}

export const editRuleSuccess = (response) => {
  return {
    type: actionTypes.EDIT_RULE_SUCCESS,
    updatedRule: response.rule,
    result: response.result
  };
}

export const editRuleFail = (response) => {
  return {
    type: actionTypes.EDIT_RULE_FAIL,
    error: {
      text: response.error,
      field: response.field ? response.field : null
    }
  };
}

// ----------------------- DELETE RULE -----------------------
export const deleteRuleStart = () => {
  return {
    type: actionTypes.DELETE_RULE_START
  };
}

export const deleteRule = (id) => {
  return {
    type: actionTypes.DELETE_RULE,
    id: id
  };
}

export const deleteRuleSuccess = (result) => {
  return {
    type: actionTypes.DELETE_RULE_SUCCESS,
    result: result
  };
}

export const deleteRuleFail = (error) => {
  return {
    type: actionTypes.DELETE_RULE_FAIL,
    error: error
  };
}

export const clearRuleError = () => {
  return {
    type: actionTypes.CLEAR_RULE_ERROR
  };
}


// ----------------------- GET ALARM_HISTORIES -----------------------
export const getAlarmHistoriesStart = () => {
  return {
    type: actionTypes.GET_ALARM_HISTORIES_START
  };
}

export const getAlarmHistories = (rule_id, start, end) => {
  return {
    type: actionTypes.GET_ALARM_HISTORIES,
    rule_id,
    start,
    end
  };
}

export const getAlarmHistoriesSuccess = (response) => {
  return {
    type: actionTypes.GET_ALARM_HISTORIES_SUCCESS,
    alarmHistories: response.histories,
    result: response.result
  };
}

export const getAlarmHistoriesFail = (error) => {
  return {
    type: actionTypes.GET_ALARM_HISTORIES_FAIL,
    error: error
  };
}

// export default {
//   getRulesStart,
//   getRules,
//   getRulesSuccess,
//   getRulesFail,
//   addRule,
//   addRuleStart,
//   addRuleSuccess,
//   addRuleFail,
//   enableRule,
//   enableRuleStart,
//   enableRuleSuccess,
//   enableRuleFail,
//   deleteRule,
//   deleteRuleStart,
//   deleteRuleSuccess,
//   deleteRuleFail,
//   clearRuleError
// };