import * as actionTypes from './types';

// ----------------------- GET EXPORT CHECK -----------------------
export const getExportCheckStart = () => {
  return {
    type: actionTypes.GET_EXPORT_CHECK_START
  };
}

export const getExportCheck = (export_id) => {
  return {
    type: actionTypes.GET_EXPORT_CHECK,
    export_id
  };
}

export const getExportCheckSuccess = (response) => {
  return {
    type: actionTypes.GET_EXPORT_CHECK_SUCCESS,
    url: response.url,
    error: response.err
  };
}

export const getExportCheckFail = (error) => {
  return {
    type: actionTypes.GET_EXPORT_CHECK_FAIL,
    error: error
  };
}

// ----------------------- GET EXPORT EXCEL -----------------------
export const getExportExcelStart = () => {
  return {
    type: actionTypes.GET_EXPORT_EXCEL_START
  };
}

export const getExportExcel = (device_socket_ids, start, stop, sum_interval, sum_method) => {
  return {
    type: actionTypes.GET_EXPORT_EXCEL,
    device_socket_ids, start, stop, sum_interval, sum_method
  };
}

export const getExportExcelSuccess = (response) => {
  return {
    type: actionTypes.GET_EXPORT_EXCEL_SUCCESS,
    exportId: response.export_id
  };
}

export const getExportExcelFail = (error) => {
  return {
    type: actionTypes.GET_EXPORT_EXCEL_FAIL,
    error: error
  };
}

// ----------------------- GET EXPORT BREAKDOWN EXCEL -----------------------
export const getExportBrekdownExcelStart = () => {
  return {
    type: actionTypes.GET_EXPORT_BREAKDOWN_EXCEL_START
  };
}

export const getExportBrekdownExcel = (org_id, countingdevices_id, start, stop) => {
  return {
    type: actionTypes.GET_EXPORT_BREAKDOWN_EXCEL,
    org_id, countingdevices_id, start, stop
  };
}

export const getExportBreakdownExcelSuccess = (response) => {
  return {
    type: actionTypes.GET_EXPORT_BREAKDOWN_EXCEL_SUCCESS,
    exportId: response.export_id
  };
}

export const getExportBreakdownExcelFail = (error) => {
  return {
    type: actionTypes.GET_EXPORT_BREAKDOWN_EXCEL_FAIL,
    error: error
  };
}


// export default {
//   getExportCheckStart,
//   getExportCheck,
//   getExportCheckSuccess,
//   getExportCheckFail,
//   getExportExcelStart,
//   getExportExcel,
//   getExportExcelSuccess,
//   getExportExcelFail,
//   getExportBrekdownExcelStart,
//   getExportBrekdownExcel,
//   getExportBreakdownExcelSuccess,
//   getExportBreakdownExcelFail
// };