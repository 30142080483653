import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  breakdowns: null,
  updatedBreakdown: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BREAKDOWNS_START: return getBreakdownStart(state, action);
    case actionTypes.GET_BREAKDOWNS_SUCCESS: return getBreakdownSuccess(state, action);
    case actionTypes.GET_BREAKDOWNS_FAIL: return getBreakdownFail(state, action);

    case actionTypes.EDIT_BREAKDOWN_START: return editBreakdownStart(state, action);
    case actionTypes.EDIT_BREAKDOWN_SUCCESS: return editBreakdownSuccess(state, action);
    case actionTypes.EDIT_BREAKDOWN_FAIL: return editBreakdownFail(state, action);

    case actionTypes.CLEAR_BREAKDOWNS: return clearBreakdowns(state, action);
    default: return state;
  }
};

const getBreakdownStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getBreakdownSuccess = (state, action) => {
  return updateObject(state, {
    breakdowns: action.breakdowns,
    error: null,
    loading: false
  });
}

const getBreakdownFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editBreakdownStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editBreakdownSuccess = (state, action) => {
  return updateObject(state, {
    updatedBreakdown: action.updatedBreakdown,
    result: action.result,
    error: null,
    loading: false
  });
}

const editBreakdownFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    result: action.result,
    loading: false
  });
}

const clearBreakdowns = (state, action) => {
  return updateObject(state, {
    breakdowns: null
  });
}



export default reducer;