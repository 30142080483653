import firebase from 'firebase/app';
import 'firebase/storage';

// TODO: need to change to OWL's firebase
var firebaseConfig = {
  apiKey: "AIzaSyAmp-XGXlagfCpqIjvUCY6cJuL8dJxvMqs",
  authDomain: "new-owl-upload.firebaseapp.com",
  databaseURL: "https://new-owl-upload.firebaseio.com",
  projectId: "new-owl-upload",
  storageBucket: "new-owl-upload.appspot.com",
  messagingSenderId: "248475960706",
  appId: "1:248475960706:web:626dbf8ace7867ea"
};
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default }
