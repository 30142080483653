import React, { PureComponent } from 'react';
import styles from './SnackBar.module.scss';
import DoneIcon from '../../../../assets/icons/done.svg';
import FailedIcon from '../../../../assets/icons/failed.svg';

export class SnackBar extends PureComponent {
  message = ''

  state = {
    isActive: false,
  }

  openSnackBar = (message = 'Something went wrong...') => {
    this.message = message;
    if (this.props.neverDisappear) {
      this.setState({ isActive: true });
    } else {
      this.setState({ isActive: true }, () => {
        setTimeout(() => {
          this.setState({ isActive: false });
        }, 3000);
      });
    }
  }

  onCloseSnackBar = () => {
    this.setState({ isActive: false });
  }

  render() {
    const { isActive } = this.state;

    let SnackBarStyle = [styles.snackbar];
    let SnackBarIcon;
    if (isActive) SnackBarStyle.push(styles.show);
    else SnackBarStyle.push(styles.hide);
    if (this.props.type === 'success') {
      SnackBarStyle.push(styles.Success);
      SnackBarIcon = DoneIcon;
    } else if (this.props.type === 'failed') {
      SnackBarStyle.push(styles.Fail);
      SnackBarIcon = FailedIcon;
    }
    return (
      <div className={SnackBarStyle.join(' ')}>
        <div style={{ display: 'flex', margin: 'auto', position: 'relative' }}>
          <img src={SnackBarIcon} style={{ marginRight: 20 }} alt="snack icon" />
          <div className={styles.Message}>{this.message}</div>
          <div className={styles.Close} onClick={this.onCloseSnackBar}>&times;</div>
        </div>
      </div>
    )
  }
}