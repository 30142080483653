export const GET_MODULE_CONFIGS_START = 'GET_MODULE_CONFIGS_START';
export const GET_MODULE_CONFIGS = 'GET_MODULE_CONFIGS';
export const GET_MODULE_CONFIGS_SUCCESS = 'GET_MODULE_CONFIGS_SUCCESS';
export const GET_MODULE_CONFIGS_FAIL = 'GET_MODULE_CONFIGS_FAIL';

export const POST_MODULE_CONFIG_START = 'POST_MODULE_CONFIG_START';
export const POST_MODULE_CONFIG = 'POST_MODULE_CONFIG';
export const POST_MODULE_CONFIG_SUCCESS = 'POST_MODULE_CONFIG_SUCCESS';
export const POST_MODULE_CONFIG_FAIL = 'POST_MODULE_CONFIG_FAIL';

export const POST_MODULE_CONFIG_JSON_START = 'POST_MODULE_CONFIG_JSON_START';
export const POST_MODULE_CONFIG_JSON = 'POST_MODULE_CONFIG_JSON';
export const POST_MODULE_CONFIG_JSON_SUCCESS = 'POST_MODULE_CONFIG_JSON_SUCCESS';
export const POST_MODULE_CONFIG_JSON_FAIL = 'POST_MODULE_CONFIG_JSON_FAIL';
