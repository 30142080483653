import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import * as actionTypes from './types';

export function* watchAdmin() {
    yield takeEvery(actionTypes.RESET_DEVICE, resetDeviceSaga);
    yield takeEvery(actionTypes.REBOOT_DEVICE, rebootDeviceSaga);
    yield takeEvery(actionTypes.CREATE_DEVICE, createDeviceSaga);
}

export function* resetDeviceSaga(params) {
    yield put(actions.resetDeviceStart());

    if (!params.device) {
        yield put(actions.resetDeviceFail({ error: 'No device found' }));
        return;
    }

    const api = 'v1/admin/resetdevice/'+params.device.id;
    try {
        const response = yield axios.post(api);
        yield put(actions.resetDeviceSuccess(response.data));
    } catch(e) {
        yield put(actions.resetDeviceFail(e));
    }
}

export function* rebootDeviceSaga(params) {
    yield put(actions.rebootDeviceStart());
    
    if (!params.device) {
        yield put(actions.rebootDeviceFail({ error: 'No device found' }));
        return;
    }

    const api = 'v1/admin/rebootdevice/'+params.device.id;
    try {
        const response = yield axios.post(api);
        yield put(actions.rebootDeviceSuccess(response.data));
    } catch(e) {
        yield put(actions.rebootDeviceFail(e));
    }
}

export function* createDeviceSaga(params) {
    yield put(actions.createDeviceStart());

    if (!params.form) {
        yield put(actions.createDeviceFail({ error: 'No form found' }));
        return;
    }

    const form = params.form;
    let formData = {
        name: form.model.toUpperCase() + "-" + form.secret.replaceAll(":", ""),
        secret: form.secret,
        serial_number: form.serial,
        model: form.model,
        firmware_version: form.firmware
    };

    if (form.model.endsWith('fuso'))
        formData.name = 'FUSO-' + form.secret.replaceAll(":", "");

    let api = 'v1/device';
    let response = null;
    try {
        response = yield axios.post(api, new URLSearchParams(formData).toString());
        console.log('CREATE DEVICE SUCCESS', response.data);
    } catch(e) {
        console.log('CREATE DEVICE FAIL', e);
        yield put(actions.createDeviceFail(e));
        return;
    }
    
    let device = response.data.device;
    let inputAmount = 4;
    let outputAmount = 4;
    if (form.model.startsWith('e02')) {
        if (form.model.endsWith('fuso')) {
            inputAmount = 4;
            outputAmount = 0;
        } else {
            inputAmount = 2;
            outputAmount = 1;
        }
    }

    api = 'v1/devicesocket';
    let sockets = [];
    for (let i = 0 ; i < (inputAmount + outputAmount) ; i++) {
        let socket = i+1;
        if (socket > inputAmount)
            socket += (10 - inputAmount);

        formData = {
            device_id: device.id,
            socket: socket,
            location: 'N/A',
            can_control: socket > inputAmount
        };
        try {
            response = yield axios.post(api, new URLSearchParams(formData).toString());
            sockets.push(response.data.device_socket);
        } catch(e) {
            console.log('CREATE DEVICE SOCKET FAIL', e);
            yield put(actions.createDeviceFail(e));
            return;
        }
    }
    device.device_sockets = sockets;
    yield put(actions.createDeviceSuccess(device));
}