
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_MEMBERS, GET_MEMBER, ADD_MEMBER, EDIT_MEMBER, DELETE_MEMBER } from './types';

export function* watchMembers() {
  yield takeEvery(GET_MEMBERS, getMembersSaga);
  yield takeEvery(GET_MEMBER, getMemberSaga);
  yield takeEvery(ADD_MEMBER, addMemberSaga);
  yield takeEvery(EDIT_MEMBER, editMemberSaga);
  yield takeEvery(DELETE_MEMBER, deleteMemberSaga);
}

export function* getMembersSaga(params) {
  yield put(actions.getMembersStart());

  let api = 'v1/user';
  // org_id, first_name, last_name, email, username, verified, team_id
  api += '?';
  api += params.org_id ? ('org_id=' + params.org_id) : '';
  api += params.first_name ? '&first_name=' + encodeURIComponent(params.first_name) : '';
  api += params.last_name ? '&last_name=' + encodeURIComponent(params.last_name) : '';
  api += params.email ? '&email=' + encodeURIComponent(params.email.toLowerCase()) : '';
  api += params.username ? '&username=' + encodeURIComponent(params.username) : '';
  api += params.verified ? ('&verified=' + params.verified) : '';
  api += params.is_system_admin ? ('&is_system_admin=' + params.is_system_admin) : '';
  api += params.team_id ? ('&team_id=' + params.team_id) : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET MEMBERS SUCCESS", response.data);
    yield put(actions.getMembersSuccess(response.data));
  } catch (err) {
    console.log("GET MEMBERS FAILED", err)
    yield put(actions.getMembersFail(err.response));
  }
}

export function* getMemberSaga(params) {
  yield put(actions.getMemberStart());

  let api = 'v1/user/' + params.id;

  try {
    const response = yield axios.get(api);
    console.log("GET MEMBER SUCCESS", response.data);
    yield put(actions.getMemberSuccess(response.data));
  } catch (err) {
    console.log("GET MEMBER FAILED", err)
    yield put(actions.getMemberFail(err.response));
  }
}

export function* addMemberSaga(params) {
  yield put(actions.addMemberStart());
  // username, password, first_name, last_name, email, is_system_admin, org_id, team_id, verified
  let postData = "username=" + encodeURIComponent(params.username);
  postData += "&password=" + encodeURIComponent(params.password);
  postData += params.first_name ? '&first_name=' + encodeURIComponent(params.first_name) : '';
  postData += params.last_name ? '&last_name=' + encodeURIComponent(params.last_name) : '';
  postData += params.email ? '&email=' + encodeURIComponent(params.email.toLowerCase()) : '';
  postData += params.job_title ? ('&job_title=' + params.job_title) : '';
  postData += params.is_system_admin ? ('&is_system_admin=' + params.is_system_admin) : '';
  postData += params.org_id ? ('&org_id=' + params.org_id) : '';
  postData += params.team_id ? ('&team_id=' + params.team_id) : '';
  postData += params.verified ? ('&verified=' + params.verified) : '';
  postData += params.profile_pic ? '&profile_pic=' + encodeURIComponent(params.profile_pic) : '';

  const api = 'v1/user';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD MEMBER SUCCESS", response);
    yield put(actions.addMemberSuccess(response.data));
  } catch (err) {
    console.log("ADD MEMBER FAILED", err.response.data);
    yield put(actions.addMemberFail(err.response.data.error));
  }
}

export function* editMemberSaga(params) {
  yield put(actions.editMemberStart());

  let putData = 'first_name=' + encodeURIComponent(params.first_name);
  putData += '&last_name=' + encodeURIComponent(params.last_name);
  putData += params.password ? '&password=' + encodeURIComponent(params.password) : '';
  putData += params.job_title ? ('&job_title=' + params.job_title) : '';

  const api = 'v1/user/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT MEMBER SUCCESS", response.data);
    yield put(actions.editMemberSuccess(response.data));
  } catch (err) {
    console.log("EDIT MEMBER FAILED", err.response.data);
    yield put(actions.editMemberFail(err.response.data));
  }
}

export function* deleteMemberSaga(params) {
  yield put(actions.deleteMemberStart());

  const api = 'v1/user/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE MEMBER SUCCESS", response.data);
    yield put(actions.deleteMemberSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE MEMBER FAILED", err.response);
    yield put(actions.deleteMemberFail(err.response));
  }
}