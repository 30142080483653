
import axios from '../../../configs/axios-orders';
import { takeEvery, put, take } from 'redux-saga/effects';

import * as actions from './actions';
import * as deviceSocketActions from '../DeviceSocket/actions';
import { GET_DEVICES, GET_DEVICE, ADD_DEVICE, EDIT_DEVICE, DELETE_DEVICE, SEARCH_OWL_MODEL, DELETE_OWL_DEVICES, ADD_OWL_DEVICES, ADD_CUSTOM_DEVICES, GET_DEVICE_STATUS } from './types';
import { ADD_DEVICE_SOCKET_SUCCESS, EDIT_DEVICE_SOCKET_SUCCESS } from '../DeviceSocket/types';

export function* watchDevices() {
  yield takeEvery(GET_DEVICES, getDevicesSaga);
  yield takeEvery(GET_DEVICE, getDeviceSaga);
  yield takeEvery(ADD_DEVICE, addDeviceSaga);
  yield takeEvery(EDIT_DEVICE, editDeviceSaga);
  yield takeEvery(DELETE_DEVICE, deleteDeviceSaga);
  yield takeEvery(ADD_OWL_DEVICES, addOwlDevicesSaga);
  yield takeEvery(ADD_CUSTOM_DEVICES, addCustomDevicesSaga);
  yield takeEvery(DELETE_OWL_DEVICES, deleteOwlDevicesSaga);
  yield takeEvery(SEARCH_OWL_MODEL, searchOwlModelSaga);
  yield takeEvery(GET_DEVICE_STATUS, getDeviceStatusSaga);
}

export function* getDevicesSaga(params) {
  yield put(actions.getDevicesStart());

  let api = 'v1/device';
  let query = [];
  if (params.org_id) query.push('org_id=' + params.org_id);
  if (params.want_socket) query.push('want_socket=' + params.want_socket);
  if (params.want_function) query.push('want_function=' + params.want_function);
  if (params.name) query.push('name=' + encodeURIComponent(params.name));
  if (params.secret) query.push('secret=' + params.secret);
  if (params.tag) query.push('tag=' + params.tag);
  if (params.serial_number) query.push('serial_number=' + params.serial_number);

  let offset = 0;
  let devices = [];
  while (true) {
    try {
      const response = yield axios.get(api + '?' + query.join('&') + '&offset=' + offset + '&limit=100');
      devices.push(...response.data.devices);
      if (response.data.devices.length < 100) break;
      offset += 100;
    } catch (err) {
      console.log("GET DEVICES FAILED", err)
      yield put(actions.getDevicesFail(err.response));
      return;
    }
  }
  yield put(actions.getDevicesSuccess(devices));
}

export function* getDeviceSaga(params) {
  yield put(actions.getDeviceStart());
  const api = 'v1/device/' + params.id;

  try {
    const response = yield axios.get(api);
    // console.log("GET A DEVICE SUCCESS", response.data);
    yield put(actions.getDeviceSuccess(response.data));
  } catch (err) {
    console.log("GET A DEVICE FAILED", err)
    yield put(actions.getDeviceFail(err.response));
  }
}

export function* addDeviceSaga(params) {
  yield put(actions.addDeviceStart());

  const api = 'v1/device';

  // org_id, name, description, tag, image, secret, 
  let postData = 'org_id=' + encodeURIComponent(params.org_id);
  postData += '&name=' + params.name;
  postData += params.description ? '&description=' + encodeURIComponent(params.description) : '';
  postData += params.tag ? '&tag=' + encodeURIComponent(params.tag) : '';
  postData += params.image ? '&image=' + encodeURIComponent(params.image) : '';
  postData += params.secret ? '&secret=' + encodeURIComponent(params.secret) : '';
  postData += params.serial_number ? '&image=' + encodeURIComponent(params.serial_number) : '';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD DEVICE SUCCESS", response.data);
    yield put(actions.addDeviceSuccess(response.data));
  } catch (err) {
    console.log("ADD DEVICE FAILED", err.response.data);
    yield put(actions.addDeviceFail(err.response.data.error));
  }
}

export function* editDeviceSaga(params) {
  yield put(actions.editDeviceStart());

  const api = 'v1/device/' + params.id;

  let putData = 'name=' + encodeURIComponent(params.name);
  putData += params.description ? '&description=' + encodeURIComponent(params.description) : '';
  putData += params.tag ? '&tag=' + encodeURIComponent(params.tag) : '';
  putData += params.image ? '&image=' + encodeURIComponent(params.image) : '';
  putData += params.monitor === true ? '&monitor=true' : params.monitor === false ? '&monitor=false' : '';
  // putData += params.secret ? '&secret=' + encodeURIComponent(params.secret) : '';

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT DEVICE SUCCESS", response.data);
    yield put(actions.editDeviceSuccess(response.data, Date.now()));
  } catch (err) {
    console.log("EDIT DEVICE FAILED", err.response);
    yield put(actions.editDeviceFail(err.response, Date.now()));
  }
}

export function* deleteDeviceSaga(params) {
  yield put(actions.deleteDeviceStart());

  const api = 'v1/device/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE DEVICE SUCCESS", response.data);
    yield put(actions.deleteDeviceSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE DEVICE FAILED", err.response);
    yield put(actions.deleteDeviceFail(err.response));
  }
}

export function* addCustomDevicesSaga(params) {
  yield put(actions.addCustomDevicesStart());

  let api = 'v1/device';

  try {
    let postData = 'org_id=' + params.org_id;
    postData += '&name=' + params.deviceForm.name.value;
    const response = yield axios.post(api, postData);
    console.log("ADDED DEVICE AND DEVICE SOCKETS..");

    yield* params.deviceSocketsForm.map(function* (ds) {
      // device_id, socket, name, location, description, can_control, unit, image, tag
      yield put(deviceSocketActions.addDeviceSocket(
        response.data.device,
        ds.socket,
        ds.form.name.value,
        ds.form.location.value,
        null, //description,
        false, // can_control
        ds.form.unit_name ? ds.form.unit_name.value : null,
        ds.form.tag ? ds.form.tag.value : null,
        ds.form.functions ? ds.form.functions.value : null
      ));
      yield take(ADD_DEVICE_SOCKET_SUCCESS);
    });

    yield put(actions.addCustomDevicesSuccess(response.data));
    console.log("DONE.... ADD CUSTOM DEVICE SUCCESS", response.data);
  } catch (err) {
    console.log("ADD CUSTOM DEVICE FAILED", err.response);
    yield put(actions.addCustomDevicesFail(err.response.data));
  }
}

export function* addOwlDevicesSaga(params) {
  yield put(actions.addOwlDevicesStart());

  let api = 'v1/orgdevice/' + params.org_id + '/' + params.secret;

  try {
    const response = yield axios.put(api);
    console.log("CHANGED ORG FOR DEVICE AND DEVICE SOCKETS..")
    yield put(actions.editDevice(
      params.deviceId,
      params.deviceForm.name.value
    ));

    yield* params.deviceSocketsForm.map(function* (ds) {
      yield put(deviceSocketActions.editDeviceSocket(
        ds,
        ds.form.name.value,
        ds.form.location.value,
        null,
        ds.form.unit_name ? ds.form.unit_name.value : null,
        ds.form.tag ? ds.form.tag.value : null,
        ds.form.functions ? ds.form.functions.value : null
      ));
      yield take(EDIT_DEVICE_SOCKET_SUCCESS);
    });

    console.log("ADD OWL DEVICE SUCCESS", response.data);
    yield put(actions.addOwlDevicesSuccess(response.data));
  } catch (err) {
    console.log("ADD OWL DEVICE FAILED", err.response);
    yield put(actions.addOwlDevicesFail(err.response.data));
  }
}

export function* deleteOwlDevicesSaga(params) {
  yield put(actions.deleteOwlDevicesStart());
  const api = 'v1/orgdevice/' + params.org_id + '/' + params.secret;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE OWL DEVICE SUCCESS", response.data);
    yield put(actions.deleteOwlDevicesSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE OWL DEVICE FAILED", err.response);
    yield put(actions.deleteOwlDevicesFail(err.response));
  }
}

export function* searchOwlModelSaga(params) {
  yield put(actions.searchOwlModelStart());
  let api = 'v1/device';
  api += params.secret ? '?secret=' + params.secret : '';
  api += '&want_socket=true';

  try {
    const response = yield axios.get(api);
    console.log("SEARCH OWL MODEL SUCCESS", response.data);
    yield put(actions.searchOwlModelSuccess(response.data));
  } catch (err) {
    console.log("SEARCH OWL MODEL FAILED", err)
    yield put(actions.searchOwlModelFail(err.response.data));
  }
}

export function* getDeviceStatusSaga(params) {
  yield put(actions.getDeviceStatusStart());

  let api = 'v1/devicestatus';
  api += params.secret ? '?secret=' + params.secret : '';
  api += params.limit ? '&limit=' + params.limit : '';
  api += params.offset ? '&offset=' + params.offset : '';

  try {
    const response = yield axios.get(api);
    yield put(actions.getDeviceStatusSuccess(response.data));
  } catch (err) {
    console.log("GET DEVICES FAILED", err)
    yield put(actions.getDeviceStatusFail(err.response));
  }
}