import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  reportPresets: null,
  addedReportPreset: null,
  updatedReportPreset: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REPORT_PRESETS_START: return getReportPresetsStart(state, action);
    case actionTypes.GET_REPORT_PRESETS_SUCCESS: return getReportPresetsSuccess(state, action);
    case actionTypes.GET_REPORT_PRESETS_FAIL: return getReportPresetsFail(state, action);

    case actionTypes.ADD_REPORT_PRESET_START: return addReportPresetStart(state, action);
    case actionTypes.ADD_REPORT_PRESET_SUCCESS: return addReportPresetSuccess(state, action);
    case actionTypes.ADD_REPORT_PRESET_FAIL: return addReportPresetFail(state, action);

    case actionTypes.EDIT_REPORT_PRESET_START: return editReportPresetStart(state, action);
    case actionTypes.EDIT_REPORT_PRESET_SUCCESS: return editReportPresetSuccess(state, action);
    case actionTypes.EDIT_REPORT_PRESET_FAIL: return editReportPresetFail(state, action);

    case actionTypes.DELETE_REPORT_PRESET_START: return deleteReportPresetStart(state, action);
    case actionTypes.DELETE_REPORT_PRESET_SUCCESS: return deleteReportPresetSuccess(state, action);
    case actionTypes.DELETE_REPORT_PRESET_FAIL: return deleteReportPresetFail(state, action);

    default: return state;
  }
};

const getReportPresetsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getReportPresetsSuccess = (state, action) => {
  return updateObject(state, {
    reportPresets: action.reportPresets,
    error: null,
    loading: false
  });
}

const getReportPresetsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addReportPresetStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addReportPresetSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedReportPreset: action.addedReportPreset,
    error: null,
    loading: false
  });
}

const addReportPresetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editReportPresetStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editReportPresetSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedReportPreset: action.updatedReportPreset,
    error: null,
    loading: false
  });
}

const editReportPresetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteReportPresetStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteReportPresetSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteReportPresetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;