import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';

class ParetoStackedChart extends Component {
	state = {
		chartData: null,
		options: null
	}

	componentDidMount() {
		this.setupData(this.props.stackedParetoData);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.stackedParetoData && !_.isEqual(prevProps.stackedParetoData, this.props.stackedParetoData)) {
			this.setupData(this.props.stackedParetoData);
		}
	}

	setupOptions = (stackedParetoData) => {
		const options = {
			responsive: true,
			tooltips: {
				mode: 'index',
				reverse: true,
				intersect: false,
				callbacks: {
					title: (tooltipItem, data) => {
						return stackedParetoData[tooltipItem[0].index].machine.label + " (" + stackedParetoData[tooltipItem[0].index].percent + "%)";
					},
					label: (tooltipItem, data) => {
						if (tooltipItem.datasetIndex === 0) {
							return "Cumulative percentage: " + tooltipItem.value + "%";
						} else {
							return +tooltipItem.value ? data.datasets[tooltipItem.datasetIndex].label + ": " + this.humanizeDuration(tooltipItem.value, true) : "";
						}
					}
				}
			},
			legend: {
				reverse: true,
			},
			hover: {
				mode: 'index',
				intersect: false
			},
			elements: {
				line: {
					fill: false
				}
			},
			scales: {
				xAxes: [
					{
						stacked: true,
						display: true,
						gridLines: {
							display: true
						},
						// labels: {
						// 	show: true
						// },
						labels: stackedParetoData.map(spd => spd.machine.label),
					}
				],
				yAxes: [
					{
						type: 'linear',
						display: true,
						position: 'left',
						id: 'y-axis-1',
						gridLines: {
							display: false
						},
						stacked: true,
						labels: {
							show: true
						},
						ticks: {
							stepSize: 2 * 60 * 60 * 1000,
							beginAtZero: true,
							callback: (value, index, values) => {
								return this.humanizeDuration(value);
							}
						}
					},
					{
						type: 'linear',
						display: true,
						position: 'right',
						id: 'y-axis-2',
						gridLines: {
							display: true
						},
						labels: {
							show: true
						},
						ticks: {
							max: 100,
							beginAtZero: true,
							callback: function (value, index, values) {
								return value + " %";
							}
						}
					}
				]
			}
		}
		this.setState({ options });
	}

	humanizeDuration = (ms, showSec) => {
		const days = moment.duration(ms).days();
		const hours = moment.duration(ms).hours();
		const minutes = moment.duration(ms).minutes();
		const seconds = moment.duration(ms).seconds();

		let result = days === 0 ? '' : days > 1 ? days + ' days ' : days + ' day ';
		result += hours === 0 ? '' : hours > 1 ? hours + ' hrs ' : hours + ' hr ';
		result += minutes === 0 ? '' : minutes > 1 ? minutes + ' mins ' : minutes + ' min ';
		if (showSec) {
			result += seconds === 0 ? '' : seconds > 1 ? seconds + ' secs ' : seconds + ' sec';
		}

		return result;
	}

	setupData = (stackedParetoData) => {
		this.setupOptions(stackedParetoData);
		const datasets = [];
		if (!stackedParetoData.length) return;
		datasets.push({
			label: 'Cumulative percentage',
			type: 'line',
			data: stackedParetoData.map(spd => spd.sumPercent),
			fill: false,
			borderColor: '#1665d8',
			backgroundColor: '#1665d8',
			pointBorderColor: '#1665d8',
			pointBackgroundColor: '#1665d8',
			pointHoverBackgroundColor: '#4483df',
			pointHoverBorderColor: '#4483df',
			yAxisID: 'y-axis-2'
		});

		for (let i = stackedParetoData[0].duration.length - 1; i >= 0; i--) {
			let color = stackedParetoData[0].status[i] !== 'Others' ? '#7a94ae' : '#58595b'; // 58595b = LOST 173f5f
			if (stackedParetoData[0].machine.status_rules[stackedParetoData[0].value[i]]) {
				color = stackedParetoData[0].machine.status_rules[stackedParetoData[0].value[i]].color;
				color = color === '#222226' ? 'black' : color;
			}
			datasets.push({
				type: 'bar',
				label: stackedParetoData[0].status[i],
				data: stackedParetoData.map(spd => spd.duration[i]),
				fill: false,
				backgroundColor: color,
				borderColor: color,
				hoverBackgroundColor: color,
				hoverBorderColor: color,
				// yAxisID: 'y-axis-' + i
			});
		}

		let chartData = { datasets };

		this.setState({ chartData });
	}

	getRandomColor = () => {
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	render() {
		return (
			<div style={{ width: '99%', position: 'relative' }}>
				{
					this.state.chartData && this.state.options ?
						<Bar data={this.state.chartData} height={120} options={this.state.options} />
						: null
				}
			</div>
		);
	}
}

export default ParetoStackedChart;