import React, { Component } from 'react';

import styles from './ChangeNewManager.module.scss';
import CheckIcon from '../../../../assets/icons/check.svg';
import Button from '../../UI/Button/Button';
import Tooltip from '../../UI/ToolTip/Tooltip';

const SHOW_MAXIMUM_MEMBERS = 5;

class ChangeNewManager extends Component {
  state = {
    selectedIndex: 'current',
    revealOtherMembers: false
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.open && prevProps.open !== this.props.open) {
      this.setState({ selectedIndex: 'current', revealOtherMembers: false })
    }
  }

  onChangeOwnerHandler = (index) => {
    this.setState({ selectedIndex: index });
  }

  onSubmitChangeOwnerHandler = () => {
    if (this.state.selectedIndex !== 'current') {
      this.props.onChangeOwner(this.state.selectedIndex);
    }
    this.props.closed();
  }

  onRevealOtherMembers = () => {
    this.setState({ revealOtherMembers: true });
  }

  render() {
    const changeManagerModalStyles = [styles.ChangeNewManager];
    if (this.props.open && this.props.isDrawerOpened) {
      changeManagerModalStyles.push(styles.Open);
    }

    return (
      <div className={changeManagerModalStyles.join(' ')}>
        <div className={styles.Title}>Choose New Manager</div>
        <div className={styles.MembersWrapper}>
          <div className={styles.ProfilePictureWrapper}>
            {this.props.owner ?
              <Tooltip overideIcon text={`${this.props.owner.first_name} ${this.props.owner.last_name}`}>
                <img
                  src={this.props.owner.profile_picture}
                  alt="owner profile pic"
                  className={styles.MemberProfilePicture}
                  onClick={() => this.onChangeOwnerHandler('current')}
                />
                {this.state.selectedIndex === 'current' ? <img src={CheckIcon} alt="current owner" className={styles.Checked} /> : null}
              </Tooltip>
              : null
            }
          </div>
          {
            this.props.members ?
              this.props.members.map((member, i) => {
                if (i < SHOW_MAXIMUM_MEMBERS) {
                  return (
                    <Tooltip key={`member-${member.id}`} overideIcon text={`${member.first_name} ${member.last_name}`}>
                      <div className={styles.ProfilePictureWrapper}>
                        <img src={member.profile_picture} alt="memberjo profile pic" className={styles.MemberProfilePicture} onClick={() => this.onChangeOwnerHandler(i)} />
                        { this.state.selectedIndex === i ? <img src={CheckIcon} alt="current owner" className={styles.Checked} /> : null }
                      </div>
                    </Tooltip>
                  );
                } else if (i === SHOW_MAXIMUM_MEMBERS && !this.state.revealOtherMembers) {
                  return (
                    <div key={`more-members`} className={styles.ProfilePictureWrapper} style={{ verticalAlign: 'top' }}>
                      <div className={styles.MoreMembers} onClick={this.onRevealOtherMembers}>+{this.props.members.length - 1}</div>
                    </div>
                  );
                } else if (this.state.revealOtherMembers && i >= SHOW_MAXIMUM_MEMBERS) {
                  return (
                    <Tooltip key={`member-${member.id}`} overideIcon text={`${member.first_name} ${member.last_name}`}>
                      <div className={styles.ProfilePictureWrapper}>
                        <img src={member.profile_picture} alt="memberjo profile pic" className={styles.MemberProfilePicture} onClick={() => this.onChangeOwnerHandler(i)} />
                        { this.state.selectedIndex === i ? <img src={CheckIcon} alt="current owner" className={styles.Checked} /> : null }
                      </div>
                    </Tooltip>
                  );
                }
                return null;
              })
              : null
          }
        </div>
        <div className={styles.ChangeInfo}>
          Let other people manage the team, it means you will no longer be the manager. This step can not undo after saved the changes!
        </div>
        <Button type="button" name="Done" color="primary" click={this.onSubmitChangeOwnerHandler} />
        <Button type="button" name="Cancel" color="borderred" click={this.props.closed} noMargin />
      </div>
    );
  }
}

export default ChangeNewManager;