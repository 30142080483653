export const GET_HOOKS_START = 'GET_HOOKS_START';
export const GET_HOOKS = 'GET_HOOKS';
export const GET_HOOKS_SUCCESS = 'GET_HOOKS_SUCCESS';
export const GET_HOOKS_FAIL = 'GET_HOOKS_FAIL';

export const ADD_HOOK_START = 'ADD_HOOK_START';
export const ADD_HOOK = 'ADD_HOOK';
export const ADD_HOOK_SUCCESS = 'ADD_HOOK_SUCCESS';
export const ADD_HOOK_FAIL = 'ADD_HOOK_FAIL';

export const ENABLE_HOOK_START = 'ENABLE_HOOK_START';
export const ENABLE_HOOK = 'ENABLE_HOOK';
export const ENABLE_HOOK_SUCCESS = 'ENABLE_HOOK_SUCCESS';
export const ENABLE_HOOK_FAIL = 'ENABLE_HOOK_FAIL';

export const EDIT_HOOK_START = 'EDIT_HOOK_START';
export const EDIT_HOOK = 'EDIT_HOOK';
export const EDIT_HOOK_SUCCESS = 'EDIT_HOOK_SUCCESS';
export const EDIT_HOOK_FAIL = 'EDIT_HOOK_FAIL';

export const DELETE_HOOK_START = 'DELETE_HOOK_START';
export const DELETE_HOOK = 'DELETE_HOOK';
export const DELETE_HOOK_SUCCESS = 'DELETE_HOOK_SUCCESS';
export const DELETE_HOOK_FAIL = 'DELETE_HOOK_FAIL';
