import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_DOC_MASTER_DATAS, POST_DOC_MASTER_DATAS, ADD_DOC_MASTER_DATA, EDIT_DOC_MASTER_DATA, DELETE_DOC_MASTER_DATA } from './types';

export function* watchDocMasterDatas() {
  yield takeEvery(GET_DOC_MASTER_DATAS, getDocMasterDatasSaga);
  yield takeEvery(POST_DOC_MASTER_DATAS, postDocMasterDatasSaga);
  yield takeEvery(ADD_DOC_MASTER_DATA, addDocMasterDataSaga);
  yield takeEvery(EDIT_DOC_MASTER_DATA, editDocMasterDataSaga);
  yield takeEvery(DELETE_DOC_MASTER_DATA, deleteDocMasterDataSaga);
}

export function* getDocMasterDatasSaga(params) {
  yield put(actions.getDocMasterDatasStart());

  let api = 'v1/masterdata';
  api += '?org_id=' + params.org_id;
  api += params.need_tree ? '&need_tree=' + params.need_tree : '';
  api += params.type_id ? '&type_id=' + params.type_id : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET BREAKDOWN CODES SUCCESS", response.data);
    yield put(actions.getDocMasterDatasSuccess(response.data));
  } catch (err) {
    // console.log("GET BREAKDOWN CODES FAILED", err.response)
    yield put(actions.getDocMasterDatasFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* postDocMasterDatasSaga(params) {
  yield put(actions.postDocMasterDatasStart());

  const api = 'v1/masterdata/' + params.org_id;
  let postData = JSON.stringify(params.body);

  try {
    const response = yield axios.post(api, postData);
    // console.log("POST BREAKDOWN CODES SUCCESS", response.data);
    yield put(actions.postDocMasterDatasSuccess(response.data));
  } catch (err) {
    // console.log("POST BREAKDOWN CODES FAILED", err.response)
    yield put(actions.postDocMasterDatasFail(err.response ? err.response.data : { error: 'Something went wrong' }));

  }
}


export function* addDocMasterDataSaga(params) {
  yield put(actions.addDocMasterDataStart());

  let api = 'v1/masterdata';
  let postData = "org_id=" + params.org_id;
  postData += "&type_id=" + params.type_id;
  postData += "&code=" + params.code;
  postData += params.title ? "&title=" + params.title : '';
  postData += params.description ? "&description=" + params.description : '';
  postData += params.parent_uuid ? "&parent_uuid=" + params.parent_uuid : '';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD DOC_MASTER_DATA SUCCESS", response.data);
    yield put(actions.addDocMasterDataSuccess(response.data));
  } catch (err) {
    // console.log("ADD DOC_MASTER_DATA FAILED", err.response)
    yield put(actions.addDocMasterDataFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* editDocMasterDataSaga(params) {
  yield put(actions.editDocMasterDataStart());

  let putData = "code=" + params.code;
  putData += params.title ? "&title=" + params.title : '';
  putData += params.description ? "&description=" + params.description : '';
  putData += params.parent_uuid ? "&parent_uuid=" + params.parent_uuid : '';

  const api = 'v1/masterdata/' + params.uuid;

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT DOC_MASTER_DATA SUCCESS", response.data);
    yield put(actions.editDocMasterDataSuccess(response.data));
  } catch (err) {
    // console.log("EDIT DOC_MASTER_DATA FAILED", err.response);
    yield put(actions.editDocMasterDataFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* deleteDocMasterDataSaga(params) {
  yield put(actions.deleteDocMasterDataStart());

  const api = 'v1/masterdata/' + params.org_id + '?uuids=' + params.uuids;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE DOC_MASTER_DATA SUCCESS", response.data);
    yield put(actions.deleteDocMasterDataSuccess(response.data.result));
  } catch (err) {
    // console.log("DELETE DOC_MASTER_DATA FAILED", err.response);
    yield put(actions.deleteDocMasterDataFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}