import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  result: null,
  error: null,
  loading: false,
  oees: null,
  addedOee: null,
  updatedOee: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_OEES_START: return getOeesStart(state, action);
    case actionTypes.GET_OEES_SUCCESS: return getOeesSuccess(state, action);
    case actionTypes.GET_OEES_FAIL: return getOeesFail(state, action);

    case actionTypes.ADD_OEE_START: return addOeeStart(state, action);
    case actionTypes.ADD_OEE_SUCCESS: return addOeeSuccess(state, action);
    case actionTypes.ADD_OEE_FAIL: return addOeeFail(state, action);

    case actionTypes.EDIT_OEE_START: return editOeeStart(state, action);
    case actionTypes.EDIT_OEE_SUCCESS: return editOeeSuccess(state, action);
    case actionTypes.EDIT_OEE_FAIL: return editOeeFail(state, action);

    case actionTypes.DELETE_OEE_START: return deleteOeeStart(state, action);
    case actionTypes.DELETE_OEE_SUCCESS: return deleteOeeSuccess(state, action);
    case actionTypes.DELETE_OEE_FAIL: return deleteOeeFail(state, action);

    case actionTypes.OEE_APPROVE_START: return oeeApproveStart(state, action);
    case actionTypes.OEE_APPROVE_SUCCESS: return oeeApproveSuccess(state, action);
    case actionTypes.OEE_APPROVE_FAIL: return oeeApproveFail(state, action);

    default: return state;
  }
};

const getOeesStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getOeesSuccess = (state, action) => {
  return updateObject(state, {
    oees: action.oees,
    error: null,
    loading: false
  });
}

const getOeesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addOeeStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addOeeSuccess = (state, action) => {
  return updateObject(state, {
    result: action.result,
    addedOee: action.addedOee,
    error: null,
    loading: false
  });
}

const addOeeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editOeeStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editOeeSuccess = (state, action) => {
  return updateObject(state, {
    result: action.result,
    updatedOee: action.updatedOee,
    error: null,
    loading: false
  });
}

const editOeeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}


const deleteOeeStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteOeeSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteOeeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const oeeApproveStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const oeeApproveSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const oeeApproveFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;