import React from 'react';

import './SimpleSwitch.scss';

const simpleSwitch = (props) => (
  <label className={`switch ${props.disabledSwitch ? 'disabled' : ''}`}>
    <input disabled={props.disabledSwitch} name="enable_alarm" type="checkbox" checked={props.enable} onChange={props.onChangeEnableHandler} />
    <span className="slider round"></span>
  </label>
);

export default simpleSwitch;