import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import styles from './BreakdownCodeTreeSelection.module.scss';
import Tick from '../../../../assets/icons/tick-square.svg';
import Untick from '../../../../assets/icons/untick-square.png';
import ScannerIcon from '../../../../assets/icons/Operator/scanner.png';
import CustomTagInput from '../../UI/CustomTagInput/CustomTagInput';

import { getDocMasterDatas, clearDocMasterDatas } from '../../../../state/ducks/DocumentMasterData/actions';

const RecursiveComponent = ({ node, indexes, level, selectBreakdown, openingIndexes, parentNode, openChilds, selectedCodes }) => {
    const hasChildren = node && node.childs && node.childs.length

    return (
        <>
            <div className={`${styles.BreakdownCodeRow} ${parentNode && parentNode.currentParentUuid === node.uuid ? styles.Selected : ''}`} onClick={() => selectBreakdown(node, indexes, level)}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 30 * level }} />
                        <div className={styles.OpenChild} onClick={(e) => openChilds(e, indexes)}>{hasChildren ? node.opened ? '-' : '+' : ''}</div>
                        <img src={selectedCodes.find(sc => sc.uuid === node.uuid) ? Tick : Untick} style={{ width: 16 }} />
                        <div className={styles.Title}>{node.title} <span className={styles.Code}>({node.code})</span></div>
                    </div>
                </div>

            </div>
            {node.opened && hasChildren && node.childs.sort((a, b) => new Date(a.created_date) - new Date(b.created_date)).map((item, j) => (
                <RecursiveComponent key={item.code} node={item} indexes={[...indexes, j]} level={level + 1} selectBreakdown={selectBreakdown} openingIndexes={openingIndexes} parentNode={parentNode} openChilds={openChilds} selectedCodes={selectedCodes} />
            ))}
        </>
    )
}

class BreakdownCodeTreeSelection extends Component {
    state = {
        currentBreakdownCodes: [],
        result: []
    }

    componentDidMount() {
        this.props.clearDocMasterDatas();
        if (this.props.selectingNoteType) {
            this.props.getDocMasterDatas(this.props.currentOrg, true, this.props.selectingNoteType.id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.selectingNoteType, prevProps.selectingNoteType) && this.props.selectingNoteType) {
            this.props.getDocMasterDatas(this.props.currentOrg, true, this.props.selectingNoteType.id);
        } else if (this.props.docMasterDatas && !_.isEqual(prevProps.docMasterDatas, this.props.docMasterDatas)) {
            this.setState({ currentBreakdownCodes: [...this.props.docMasterDatas] });
        } else if (!_.isEqual(prevProps.codes, this.props.codes)) {
            this.setState({ result: this.props.codes ? this.props.codes : [] });
        } else if (this.props.scannerText !== prevState.scannerText && this.props.scannerText) {
            const updatedResult = [...this.state.result];
            const alreadySelected = updatedResult.find(ur => ur.code.toLowerCase() === this.props.scannerText.toLowerCase() || ur.title.toLowerCase() === this.props.scannerText.toLowerCase());
            if (!alreadySelected) {
                const found = this.props.docMasterDatas.find(dm => dm.code.toLowerCase() === this.props.scannerText.toLowerCase() || dm.title.toLowerCase() === this.props.scannerText.toLowerCase());
                if (found) {
                    updatedResult.push(found);
                    this.props.saveCodes(updatedResult);
                    this.setState({ result: updatedResult });
                }
            }
        }
    }

    onOpenChildsHandler = (event, indexes) => {
        event.stopPropagation();
        const updatedBreakdownCodes = [...this.state.currentBreakdownCodes];
        let node = [...updatedBreakdownCodes];
        indexes.forEach((ind, j) => {
            if (j === indexes.length - 1) {
                node[ind].opened = node[ind].opened ? false : true;
            } else {
                node[ind].opened = true;
            }
            node = node[ind].childs;
        });

        this.setState({ currentBreakdownCodes: updatedBreakdownCodes });
    }

    onSelectBreakdownHandler = (node, indexes, level) => {
        const updatedResult = [...this.state.result];
        const foundIndex = updatedResult.findIndex(ur => ur.uuid === node.uuid);
        if (foundIndex >= 0) {
            updatedResult.splice(foundIndex, 1);
        } else {
            updatedResult.push(node);
        }
        this.props.saveCodes(updatedResult);
        this.setState({ result: updatedResult });
    }

    handleChange = event => {
        this.props.saveCodes(event.target.value);
        this.setState({ result: event.target.value });
    };

    onSearchBreakdownCodesHandler = (result) => {
        this.setState({ currentBreakdownCodes: result });
    }

    render() {

        return (
            <div>
                <div className={styles.BreakdownCodesSelectionWrapper}>
                    <img className={styles.ScannerIconInPO} src={ScannerIcon} alt="Scanner" style={{ width: 24, height: 24 }} onClick={this.props.toggleScannerHandler} />
                    <CustomTagInput
                        type="text"
                        value={this.state.result}
                        onChange={this.handleChange}
                        allBreakdownCodes={this.props.docMasterDatas}
                        searchResult={this.onSearchBreakdownCodesHandler}
                        scannerText={this.props.scannerText}
                    />
                </div>
                <div className={styles.BreakdownCodesWrapper}>
                    {
                        this.state.currentBreakdownCodes && this.state.currentBreakdownCodes.length
                            ? this.state.currentBreakdownCodes.sort((a, b) => new Date(a.created_date) - new Date(b.created_date))
                                .map((bc, i) =>
                                    <React.Fragment key={bc.uuid}>
                                        <RecursiveComponent
                                            key={bc.code}
                                            indexes={[i]}
                                            node={bc}
                                            level={0}
                                            selectBreakdown={this.onSelectBreakdownHandler}
                                            openingIndexes={this.state.openingIndexes}
                                            parentNode={this.state.parentNode}
                                            openChilds={this.onOpenChildsHandler}
                                            editBreakdown={this.onEditBreakdownHandler}
                                            selectedCodes={this.state.result}
                                        />
                                        <div style={{ height: 10, width: '100%' }} />
                                    </React.Fragment>
                                )
                            : <div style={{ fontSize: 12, fontWeight: '500' }}>Not found.</div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { docMasterDatas } = state.docMasterData

    return { currentOrg, docMasterDatas };
};

export default connect(mapStateToProps, { getDocMasterDatas, clearDocMasterDatas })(BreakdownCodeTreeSelection);