import * as actionTypes from './types';

// ----------------------- GET DEVICE_CONFIGS -----------------------
export const getDeviceConfigsStart = () => {
  return {
    type: actionTypes.GET_DEVICE_CONFIGS_START
  };
}

export const getDeviceConfigs = (secret) => {
  return {
    type: actionTypes.GET_DEVICE_CONFIGS,
    secret
  };
}

export const getDeviceConfigsSuccess = (response) => {
  return {
    type: actionTypes.GET_DEVICE_CONFIGS_SUCCESS,
    deviceConfigs: response.config
  };
}

export const getDeviceConfigsFail = (error) => {
  return {
    type: actionTypes.GET_DEVICE_CONFIGS_FAIL,
    error: error
  };
}

// ----------------------- ADD DEVICE_CONFIG -----------------------
export const addDeviceConfigStart = () => {
  return {
    type: actionTypes.ADD_DEVICE_CONFIG_START
  };
}

export const addDeviceConfig = (secret, jsonData) => {
  return {
    type: actionTypes.ADD_DEVICE_CONFIG,
    secret,
    jsonData
  };
}

export const addDeviceConfigSuccess = (response) => {
  return {
    type: actionTypes.ADD_DEVICE_CONFIG_SUCCESS,
    result: response.result
  };
}

export const addDeviceConfigFail = (error) => {
  return {
    type: actionTypes.ADD_DEVICE_CONFIG_FAIL,
    error: error
  };
}

// ----------------------- DELETE DEVICE_CONFIG -----------------------
export const deleteDeviceConfigsStart = () => {
  return {
    type: actionTypes.DELETE_DEVICE_CONFIGS_START
  };
}

export const deleteDeviceConfigs = () => {
  return {
    type: actionTypes.DELETE_DEVICE_CONFIGS
  };
}

export const deleteDeviceConfigsSuccess = (response) => {
  return {
    type: actionTypes.DELETE_DEVICE_CONFIGS_SUCCESS,
    result: response.result
  };
}

export const deleteDeviceConfigsFail = (error) => {
  return {
    type: actionTypes.DELETE_DEVICE_CONFIGS_FAIL,
    error: error
  };
}

// ----------------------- GET NEW SYSTEM DEVICE CONFIG -----------------------
export const getSystemDeviceConfigStart = () => {
  return {
    type: actionTypes.GET_SYSTEM_DEVICE_CONFIG_START
  };
}

export const getSystemDeviceConfig = (secret) => {
  return {
    type: actionTypes.GET_SYSTEM_DEVICE_CONFIG,
    secret
  };
}

export const getSystemDeviceConfigSuccess = (response) => {
  return {
    type: actionTypes.GET_SYSTEM_DEVICE_CONFIG_SUCCESS,
    systemDeviceConfig: response.config
  };
}

export const getSystemDeviceConfigFail = (error) => {
  return {
    type: actionTypes.GET_SYSTEM_DEVICE_CONFIG_FAIL,
    error
  };
}

// ----------------------- SAVE NEW SYSTEM DEVICE CONFIG -----------------------
export const saveSystemDeviceConfigStart = () => {
  return {
    type: actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_START
  };
}

export const saveSystemDeviceConfig = (secret, protobuf) => {
  return {
    type: actionTypes.SAVE_SYSTEM_DEVICE_CONFIG,
    secret,
    protobuf
  };
}

export const saveSystemDeviceConfigSuccess = (response) => {
  return {
    type: actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_SUCCESS,
    result: response.result
  };
}

export const saveSystemDeviceConfigFail = (error) => {
  return {
    type: actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_FAIL,
    error: error
  };
}

export const getSystemDeviceConfigJsonStart = () => {
  return {
    type: actionTypes.GET_SYSTEM_DEVICE_CONFIG_JSON_START
  };
}

export const getSystemDeviceConfigJson = (secret) => {
  return {
    type: actionTypes.GET_SYSTEM_DEVICE_CONFIG_JSON,
    secret
  };
}

export const getSystemDeviceConfigJsonSuccess = (response) => {
  return {
    type: actionTypes.GET_SYSTEM_DEVICE_CONFIG_JSON_SUCCESS,
    systemDeviceConfigJson: response.config
  };
}

export const getSystemDeviceConfigJsonFail = (error) => {
  return {
    type: actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_JSON_FAIL,
    error
  };
}

export const saveSystemDeviceConfigJsonStart = () => {
  return {
    type: actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_JSON_START
  };
}

export const saveSystemDeviceConfigJson = (secret, body) => {
  return {
    type: actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_JSON,
    secret,
    body
  };
}

export const saveSystemDeviceConfigJsonSuccess = (response) => {
  return {
    type: actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_JSON_SUCCESS,
    updatedSystemDeviceConfigJson: response.config,
    result: response.result
  };
}

export const saveSystemDeviceConfigJsonFail = (error) => {
  return {
    type: actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_JSON_FAIL,
    error
  };
}

// export default {
//   getDeviceConfigsStart,
//   getDeviceConfigs,
//   getDeviceConfigsSuccess,
//   getDeviceConfigsFail,
//   addDeviceConfig,
//   addDeviceConfigStart,
//   addDeviceConfigSuccess,
//   addDeviceConfigFail,
//   deleteDeviceConfigsStart,
//   deleteDeviceConfigs,
//   deleteDeviceConfigsSuccess,
//   deleteDeviceConfigsFail,
//   getSystemDeviceConfigStart,
//   getSystemDeviceConfig,
//   getSystemDeviceConfigSuccess,
//   getSystemDeviceConfigFail,
//   saveSystemDeviceConfig,
//   saveSystemDeviceConfigStart,
//   saveSystemDeviceConfigSuccess,
//   saveSystemDeviceConfigFail,
// };