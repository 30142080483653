import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  result: null,
  error: null,
  loading: false,
  shift: null,
  shifts: null,
  addedShift: null,
  updatedShift: null,
  type: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SHIFTS_START: return getShiftsStart(state, action);
    case actionTypes.GET_SHIFTS_SUCCESS: return getShiftsSuccess(state, action);
    case actionTypes.GET_SHIFTS_FAIL: return getShiftsFail(state, action);

    case actionTypes.ADD_SHIFT_START: return addShiftStart(state, action);
    case actionTypes.ADD_SHIFT_SUCCESS: return addShiftSuccess(state, action);
    case actionTypes.ADD_SHIFT_FAIL: return addShiftFail(state, action);

    case actionTypes.EDIT_SHIFT_START: return editShiftStart(state, action);
    case actionTypes.EDIT_SHIFT_SUCCESS: return editShiftSuccess(state, action);
    case actionTypes.EDIT_SHIFT_FAIL: return editShiftFail(state, action);

    case actionTypes.DELETE_SHIFT_START: return deleteShiftStart(state, action);
    case actionTypes.DELETE_SHIFT_SUCCESS: return deleteShiftSuccess(state, action);
    case actionTypes.DELETE_SHIFT_FAIL: return deleteShiftFail(state, action);

    default: return state;
  }
};

const getShiftsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getShiftsSuccess = (state, action) => {
  return updateObject(state, {
    shifts: action.shifts,
    error: null,
    loading: false
  });
}

const getShiftsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addShiftStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addShiftSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedShift: action.addedShift,
    error: null,
    loading: false
  });
}

const addShiftFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    result: action.result,
    loading: false
  });
}

const editShiftStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editShiftSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedShift: action.updatedShift,
    error: null,
    loading: false
  });
}

const editShiftFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    result: action.result
  });
}

const deleteShiftStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteShiftSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteShiftFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;