import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './AdvanceProductionBatch.module.scss';
import Button from '../../components/UI/Button/Button';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import ProductionBatchs from '../../components/Production/AdvanceProductionBatch/ProductionBatchs';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import BatchDrawer from '../../components/Production/AdvanceProductionBatch/BatchDrawer';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';

import { deleteAdvanceProductionBatch } from '../../../state/ducks/AdvanceProductionBatch/actions';
import BatchMapsPlans from '../../components/Production/AdvanceProductionBatch/BatchMapsPlans';
import { mapPlansToBatch } from '../../../state/ducks/AdvanceProductionBatch/actions';

const BATCH_LIST = ['Batch'];

class AdvanceProductionBatch extends Component {
  state = {
    currentTabIndex: 0,
    addStructureTrigger: 0,
    isAdding: false,
    editingBatch: null,
    isDrawerOpened: false,
    isDeleteModalOpened: false,
    deletingBatchUuid: null,
    isMapPlansModalOpened: false,
    mappingBatch: null
  };



  openDrawerForAddingHandler = () => {
    this.setState({ isAdding: true, editingBatch: null, isDrawerOpened: true });
  }

  drawerToggleHandler = () => {
    this.setState(prevState => {
      return { isDrawerOpened: !prevState.isDrawerOpened }
    });
  }

  onEditBatchHandler = (event, batch) => {
    event.stopPropagation();
    this.setState({ editingBatch: batch, isDrawerOpened: true, isAdding: false });
  }

  onDeleteBatchHandler = () => {
    this.setState({ deletingBatchUuid: this.state.editingBatch.uuid, isDeleteModalOpened: true });
  }

  onConfirmDeleteBatch = (event) => {
    event.preventDefault();
    this.props.deleteAdvanceProductionBatch(this.state.deletingBatchUuid);
    this.setState({ deletingBatchUuid: null, isDeleteModalOpened: false, isDrawerOpened: false, editingBatch: null });
  }

  onOpenPlansModalHandler = (event, batch) => {
    event.stopPropagation();
    this.setState({ isMapPlansModalOpened: true, mappingBatch: batch });
  }

  onMappingPlansHandler = (addedPlans) => {
    this.setState({ isMapPlansModalOpened: false, mappingBatch: null });
    this.props.mapPlansToBatch(
      this.state.mappingBatch.uuid,
      addedPlans.map(e => e.uuid).join()
    );
  }

  render() {
    return (
      <div className={styles.AdvanceProductionBatch}>
        <SideDrawer title={this.state.isAdding ? 'Add New Batch' : 'Edit Batch'} open={this.state.isDrawerOpened} closed={this.drawerToggleHandler}>
          <BatchDrawer
            isDrawerOpened={this.state.isDrawerOpened}
            closed={this.drawerToggleHandler}
            editingBatch={this.state.editingBatch}
            isAdding={this.state.isAdding}
            toggleDeleteModal={this.onDeleteBatchHandler}
          />
        </SideDrawer>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              BATCH_LIST.map((menuTitle, index) => (
                <div key={`batch-${index}`} className={index === this.state.currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentBatchIndex: index })}>
                  <span className={styles.MenuTitle}>{menuTitle}</span>
                </div>
              ))
            }
          </div>
          <div className={styles.AddButtonWrapper}>
            <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Batch" noMargin click={this.openDrawerForAddingHandler} />
          </div>
        </div>
        <div className="MainContentPadding">
          <ProductionBatchs
            editBatch={this.onEditBatchHandler}
            openPlansModal={this.onOpenPlansModalHandler}
          />
        </div>
        <DeleteModal
          title="batch"
          open={this.state.isDeleteModalOpened}
          modalClosed={() => this.setState({ isDeleteModalOpened: false })}
          confirmDelete={this.onConfirmDeleteBatch}
        />
        <BatchMapsPlans
          currentPlans={this.state.mappingBatch ? this.state.mappingBatch.plans : null}
          open={this.state.isMapPlansModalOpened}
          modalClosed={() => this.setState({ isMapPlansModalOpened: false })}
          confirmMappingPlans={this.onMappingPlansHandler}
        />
      </div>
    );
  }
}

export default connect(null, { deleteAdvanceProductionBatch, mapPlansToBatch })(AdvanceProductionBatch);
