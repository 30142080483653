import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './PlansTable.module.scss';
import SearchIcon from '../../../../assets/icons/search.svg';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import LoadingTable from '../../UI/LoadingSkeleton/LoadingTable/LoadingTable';

import { getAdvanceProductionPlans } from '../../../../state/ducks/AdvanceProductionPlan/actions';
import { DELETE_ADVANCE_PRODUCTION_PLAN_SUCCESS } from '../../../../state/ducks/AdvanceProductionPlan/types';

class PlansTable extends Component {
  state = {
    // startDate: '',
    // startTime: '00:00:00',
    // stopDate: '',
    // stopTime: '23:59:59',
    headers: [],
    autoSearchIndex: 0,
    displayingPlans: []
  };

  componentDidMount() {
    // const now = this.setDefaultDateTime();
    this.setUpHeaders(this.props.structure);
    this.onAutoSearchPlans(0);
    // this.props.getAdvanceProductionPlans(this.props.currentOrg, this.props.structure.uuid, now.start, now.stop);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      // this.setDefaultDateTime();
      this.onAutoSearchPlans(0);
      // const now = this.getStartAndStopDateTimeNow();
      // this.props.getAdvanceProductionPlans(this.props.currentOrg, this.props.structure.uuid, now.start, now.stop);
    } else if (this.props.advanceProductionPlans !== prevProps.advanceProductionPlans) {
      this.setState({ displayingPlans: this.props.advanceProductionPlans });
    } else if (this.props.result !== prevProps.result && this.props.result === 'success') {
      if ((prevProps.addedAdvanceProductionPlan !== this.props.addedAdvanceProductionPlan)
        || (prevProps.updatedAdvanceProductionPlan !== this.props.updatedAdvanceProductionPlan)
        || this.props.type === DELETE_ADVANCE_PRODUCTION_PLAN_SUCCESS
        || prevProps.uploadedPlans !== this.props.uploadedPlans) {
        // const now = this.getStartAndStopDateTimeNow();
        // this.props.getAdvanceProductionPlans(this.props.currentOrg, this.props.structure.uuid, now.start, now.stop);
        this.onAutoSearchPlans(0);
      }
    } else if (this.props.structure && prevProps.structure !== this.props.structure) {
      this.setUpHeaders(this.props.structure);

      // const now = this.getStartAndStopDateTimeNow();
      // this.props.getAdvanceProductionPlans(this.props.currentOrg, this.props.structure.uuid, now.start, now.stop);
      this.onAutoSearchPlans(0);
    }
  }

  // getStartAndStopDateTimeNow = () => {
  //   const { startDate, startTime, stopDate, stopTime } = this.state;
  //   const start = moment(startDate + ' ' + startTime);
  //   const stop = moment(stopDate + ' ' + stopTime);
  //   return { start, stop };
  // }

  // setDefaultDateTime = () => {
  //   // const defaultStop = moment().add(1, 'days').startOf('day').valueOf();
  //   const defaultStop = new Date().setHours(23, 59, 59, 999);
  //   const defaultStart = new Date().setHours(0, 0, 0, 0);

  //   this.setState({
  //     startDate: moment(defaultStart).format('YYYY-MM-DD'),
  //     // startTime: moment(defaultStart).format('HH:mm'),
  //     stopDate: moment(defaultStop).format('YYYY-MM-DD'),
  //     // stopTime: moment(defaultStop).format('HH:mm'),
  //   });
  //   return { start: defaultStart, stop: defaultStop };
  // }

  setUpHeaders = (structure) => {
    const { columns, po_column_order, start_column_order, end_column_order, target_column_order, machine_column_order } = structure;
    const currentColumns = [];
    currentColumns[po_column_order - 1] = { name: 'po', display_name: 'PO' };
    currentColumns[start_column_order - 1] = { name: 'start', display_name: 'Start' };
    currentColumns[end_column_order - 1] = { name: 'end', display_name: 'End' };
    currentColumns[target_column_order - 1] = { name: 'target', display_name: 'Target' };
    currentColumns[machine_column_order - 1] = { name: 'machine', display_name: 'Machine' };
    if (columns && columns.length) {
      columns.forEach(col => currentColumns[col.order - 1] = { name: col.name, display_name: col.display_name });
    }
    this.setState({ headers: currentColumns });
    this.props.setHeaders(currentColumns);
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  // onSubmitPOPlansHandler = (event) => {
  //   event.preventDefault();
  //   const now = this.getStartAndStopDateTimeNow();
  //   this.props.getAdvanceProductionPlans(this.props.currentOrg, this.props.structure.uuid, now.start, now.stop);
  // }

  onAutoSearchPlans = (index) => {
    if (this.props.currentOrg) {
      let start = new Date().setHours(0, 0, 0, 0);
      let stop = new Date().setHours(23, 59, 59, 999);
      if (index === 1) {
        // THIS WEEK
        start = moment().day(0).add(1, 'days').startOf('day').valueOf();
        stop = moment().day(7).endOf('day').valueOf();
      } else if (index === 2) {
        // THISMONTH
        start = moment().startOf('month').valueOf();
        stop = moment().endOf('month').valueOf();
      }
      this.props.getAdvanceProductionPlans(this.props.currentOrg, this.props.structure.uuid, start, stop);
      this.setState({ autoSearchIndex: index });
    }
  }

  isEndYet = (actual) => {
    return actual !== '0001-01-01T00:00:00Z';
  }

  onSearchingHandler = (e) => {
    const search = e.target.value.toLowerCase();
    if (this.props.advanceProductionPlans && this.props.advanceProductionPlans.length) {
      const searchResult = this.props.advanceProductionPlans.filter(p => {
        const status = p.actuals ? (this.isEndYet(p.actuals[0].end) ? 'completed' : 'running') : '';
        if (p.po.toLowerCase().includes(search)) return p;
        else if (p.target === +search) return p;
        else if (p.machine && p.machine.name.toLowerCase().includes(search)) return p;
        else if (status.includes(search)) return p;
        else if (p.columns) {
          let found = false;
          Object.keys(p.columns).map(key => {
            if (p.columns[key].toLowerCase().includes(search)) found = true;
          });
          if (found) return p;
        }
      });
      this.setState({ displayingPlans: searchResult });
    }
  }

  render() {
    // const { startDate, stopDate } = this.state;
    // const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
          <div className={styles.SearchBarWrapper}>
            <div className={[styles.AutoFillBox, this.state.autoSearchIndex === 0 ? styles.Selected : ''].join(' ')} onClick={() => this.onAutoSearchPlans(0)}>TODAY</div>
            <div className={[styles.AutoFillBox, this.state.autoSearchIndex === 1 ? styles.Selected : ''].join(' ')} onClick={() => this.onAutoSearchPlans(1)}>THIS WEEK</div>
            <div className={[styles.AutoFillBox, this.state.autoSearchIndex === 2 ? styles.Selected : ''].join(' ')} onClick={() => this.onAutoSearchPlans(2)}>THIS MONTH</div>
            <div className={styles.SearchInputWrapper}>
              <img className={styles.SearchIconInput} style={{ verticalAlign: 'middle', width: 15 }} src={SearchIcon} alt="Search" />
              <input className={styles.SearchExpand} type="text" placeholder="Search" onChange={this.onSearchingHandler} />
              <div className={styles.AdvancedSearch} onClick={this.props.openAdvancedSearchDrawer}>Advanced Search</div>
            </div>
          </div>
          {/* <div style={{ display: 'flex', position: 'relative' }}>
            {!isChrome && <img src={CalendarIcon} alt="Starts" className={styles.CalendarIcon} />}
            <input className={styles.SelectBox} style={{ paddingLeft: !isChrome ? 46 : null, flex: 2 }} type="date" onChange={(event) => this.handleChange('startDate', event.target.value)} value={startDate} />
          </div>
          <div style={{ fontSize: 20, margin: '0 10px' }}> - </div>
          <div style={{ display: 'flex', position: 'relative' }}>
            {!isChrome && <img src={CalendarIcon} alt="Ends" className={styles.CalendarIcon} />}
            <input className={styles.SelectBox} style={{ paddingLeft: !isChrome ? 46 : null, flex: 2 }} type="date" onChange={(event) => this.handleChange('stopDate', event.target.value)} value={stopDate} />
          </div>
          <div className={styles.ViewButton}>
            <Button type="submit" icon={ViewIcon} color="primary" name="Search Plans" disabled={this.props.loading} noMargin />
          </div> */}
        </div>

        <div className={styles.TableWrapper}>
          <table>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>No.</th>
                {this.state.headers.map(header => <th key={header.name}>{header.display_name}</th>)}
                <th style={{ width: 30 }}></th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.loading
                  ? <tr><td colSpan={this.state.headers.length + 1}><LoadingTable /></td></tr>
                  : this.state.displayingPlans && this.state.displayingPlans.length ?
                    this.state.displayingPlans.map((plan, index) => (
                      <tr key={`poplans-${index + 1}`}>
                        <td style={{ textAlign: 'center' }}>{index + 1}.</td>
                        {
                          this.state.headers.map(header => (
                            // old : wordBreak: header.name === 'po' ? 'break-all' : 'initial'
                            <td key={header.name} style={{ wordBreak: header.name === 'po' ? 'initial' : 'initial' }}>
                              {
                                header.name === 'start' || header.name === 'end'
                                  ? moment(plan[header.name]).format('DD/MM/YYYY HH:mm')
                                  : header.name === 'target'
                                    ? plan[header.name].toLocaleString()
                                    : header.name === 'machine'
                                      ? plan.machine ? plan.machine.name : '-'
                                      : plan[header.name]
                                        ? plan[header.name]
                                        : plan.columns[header.name] ? plan.columns[header.name] : '-' // in plan.columns or fixed col but no data (e.g. no machine)
                              }
                            </td>
                          ))
                        }
                        <td><img src={EditIcon} style={{ width: 20, height: 20, cursor: 'pointer' }} alt="Edit Plan" onClick={() => this.props.editPlan(plan)} /></td>
                      </tr>
                    )) : <tr><td colSpan="100%" style={{ textAlign: 'center' }}>No PO plans found</td></tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { advanceProductionPlans, loading, addedAdvanceProductionPlan, updatedAdvanceProductionPlan, type, result, uploadedPlans } = state.advanceProductionPlan;
  return { currentOrg, advanceProductionPlans, loading, addedAdvanceProductionPlan, updatedAdvanceProductionPlan, type, result, uploadedPlans };
};

export default connect(mapStateToProps, { getAdvanceProductionPlans })(PlansTable);
