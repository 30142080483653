import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import styles from './TimelineBar.module.scss';
import { getTimelineStatus, clearTimelineStatuses } from '../../../../state/ducks/TimelineStatus/actions';

const BAR_COUNT_M = 1440;
const ONE_MIN_IN_MS = 60000;
const ONE_DAY_IN_MS = 86400000;

class TimelineBar extends Component {
    state = {
        isBarLoading: true,
        startQueryTime: 0,
        stopQueryTime: 0,
        currentvalue: 0,
        currentReason: '',
        bars: [],
        isNoData: false,
        oee: { availability: 0, performance: 0, quality: 0 },
        isNoPlanNow: false,
        avaDenominator: [],
    }

    componentDidMount() {
        this.props.clearTimelineStatuses();
        this.reloadDataSetInterval();
    }

    componentWillUnmount() {
        if (this.loadDataSetInterval) {
            clearInterval(this.loadDataSetInterval);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { machine, timelineStatuses } = this.props;
        if (timelineStatuses && !_.isEqual(timelineStatuses, prevProps.timelineStatuses)) {
            if (timelineStatuses.data && machine && timelineStatuses.properties.machine_id === machine.id) {
                // console.log(machine.id, machine.name, " #### ", this.props.timelineStatuses.data.length)
                this.createBar(timelineStatuses);
                this.setState({ isBarLoading: false, savedTimelineStatus: timelineStatuses });
            } else {
                this.setState({ isBarLoading: false });
            }
        } else if (!_.isEqual(prevProps.machine, machine) && timelineStatuses && timelineStatuses.data && this.state.savedTimelineStatus.properties.machine_id === machine.id) {
            this.createBar(timelineStatuses);
        } else if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg) {
            clearInterval(this.loadDataSetInterval);
        } else if (prevProps.startingPeriod !== this.props.startingPeriod && this.props.startingPeriod) {
            this.reloadDataSetInterval();
        } else if (this.props.selectedDate !== prevProps.selectedDate && this.props.selectedDate) {
            this.props.clearTimelineStatuses();
            this.reloadDataSetInterval();
        }
    }

    reloadDataSetInterval = () => {
        clearInterval(this.loadDataSetInterval);
        this.loadDataSet();
        this.loadDataSetInterval = setInterval(() => {
            this.loadDataSet();
        }, 60 * 1000);
    }

    loadDataSet = () => {
        const { machine } = this.props;
        if (!this.props.startingPeriod) return;

        let today = this.props.selectedDate ? this.props.selectedDate : new Date();
        let splited = this.props.startingPeriod.split(":");
        let h = +splited[0];
        let m = +splited[1];
        if (!this.props.selectedDate) {
            if (today.getHours() < h || (today.getHours() === h && today.getMinutes() < m)) {
                today = new Date(today.getTime() - ONE_DAY_IN_MS);
            }
        }

        let startQueryTime = today.setHours(h, m, 0, 0);
        let stopQueryTime = startQueryTime + ONE_DAY_IN_MS;
        this.props.getTimelineStatus(0, startQueryTime, stopQueryTime, machine.id);

        this.setState({ startQueryTime, stopQueryTime });
    }

    createBar = (timelineStatuses) => {
        const status_rules = this.props.machine.status_rules;
        let bars = [];
        let currentReason = '';
        let isNoData = false;
        if (timelineStatuses && timelineStatuses.data && timelineStatuses.data.length) {
            timelineStatuses.data.forEach((tls, i) => {
                let baseBarClass = styles.Bar;
                let barStart = moment(tls.start).valueOf();
                let barStop = moment(tls.stop).valueOf() < moment(tls.start).valueOf() ? moment().valueOf() : moment(tls.stop).valueOf();
                barStart = barStart < this.state.startQueryTime ? this.state.startQueryTime : barStart;
                barStop = barStop > this.state.stopQueryTime ? this.state.stopQueryTime : barStop;

                if (i === 0 && moment(tls.start).valueOf() > this.state.startQueryTime) {
                    bars.push(this.renderEachBar(i, this.state.startQueryTime, moment(tls.start).valueOf(), null, styles.BarFirst, timelineStatuses));
                    baseBarClass = styles.Bar;
                }
                if (barStart === this.state.startQueryTime) {
                    baseBarClass = styles.BarFirst;
                }
                if (barStop === this.state.stopQueryTime) {
                    baseBarClass = styles.BarLast;
                }
                if (barStart === this.state.startQueryTime && barStop === this.state.stopQueryTime) {
                    baseBarClass = styles.BarFirst + ' ' + styles.BarLast;
                }

                bars.push(this.renderEachBar(i, barStart, barStop, tls, baseBarClass));

                if (i === timelineStatuses.data.length - 1) {
                    currentReason = status_rules[tls.value] ? status_rules[tls.value].reason : 'N/A';
                }
            });
        } else {
            // All empty
            isNoData = true;
            bars.push(this.renderEachBar(null, this.state.startQueryTime, this.state.stopQueryTime, 0, styles.BarFirst + ' ' + styles.BarLast));
        }
        this.props.setCurrentStatus(currentReason);
        this.setState({ bars, currentReason, isNoData });
    }

    handleBarClick = (timelineStatusRow, timelineStatusRowIndex) => {
        if (!this.props.onBarClick) return;
        this.props.onBarClick(this.props.machine, this.state.savedTimelineStatus, timelineStatusRowIndex);
    }

    renderEachBar = (index, starttime, endtime, timelineStatusRow, baseclass) => {
        const status_rules = this.props.machine.status_rules;
        let timedif = (endtime - starttime) / ONE_MIN_IN_MS;
        let barwidth = timedif / BAR_COUNT_M;
        let divstyle = { width: 'calc(100% * ' + barwidth + ')' };

        let rule;
        let className = baseclass;
        if (!timelineStatusRow) {
            rule = { color: '#222226' };
        } else if (status_rules[timelineStatusRow.value]) {
            rule = status_rules[timelineStatusRow.value];
        } else {
            rule = { color: '#9ea0a5' };
        }

        if (rule.color) {
            divstyle.backgroundColor = rule.color === '#023E8A' ? '#062A68' : rule.color;
            if (rule.flashing)
                className += ' ' + styles.BarFlashing;
        }

        if (timelineStatusRow) {
            return <div key={`Bar-${starttime}-${index}`} className={`${className} ${this.props.onBarClick ? styles.BarClickAble : ''}`} style={divstyle} onClick={() => this.handleBarClick(timelineStatusRow, index)}></div>;
        } else {
            return <div key={`NoBar-${starttime}-${endtime}`} className={className} style={divstyle}></div>;
        }
    }

    render() {
        let bar_label = [];
        if (this.props.startingPeriod) {
            let splited = this.props.startingPeriod.split(":");
            for (let i = 0; i < 13; i++) {
                let d = new Date().setHours((i * 2) + (+splited[0]), +splited[1], 0, 0);
                d = new Date(d);
                d = moment(d).format('HH:mm');
                bar_label.push(<div key={'bar_label_' + i}>{d}</div>);
            }
        }

        return (
            <div className={styles.TimelineBar}>
                {
                    this.state.isBarLoading
                        ? <div className={styles.TimelineLoading}>Loading...</div>
                        : !this.state.isNoData
                            ? <div className={styles.TimelineWrapper}>
                                {this.state.bars}
                            </div>
                            : <div className={styles.TimelineWrapper}>
                                <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>No Data</div>
                                {this.state.bars}
                            </div>
                }
                <div className={styles.TimelineLabel}>
                    {bar_label}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { timelineStatuses } = state.timelineStatus;
    return { currentOrg, timelineStatuses }
}

export default connect(mapStateToProps, { getTimelineStatus, clearTimelineStatuses })(TimelineBar);