import React, { Component } from 'react';
import { connect } from 'react-redux';
import MobileDetect from 'mobile-detect';

import styles from '../Auth.module.scss';
import SigninImage from '../../../../assets/images/signin.png';
import CeoImage from '../../../../assets/images/ceo.png';
import WorldIcon from '../../../../assets/icons/world.svg';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

import { checkValidity, updateObject } from '../../../../state/utils';
import { signup } from '../../../../state/ducks/Auth/actions';

class SignUp extends Component {
  state = {
    formIsValid: false,
    signUpForm: {
      username: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true
        }
      },
      password: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true
        }
      },
      email: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true,
          isEmail: true
        }
      },
      first_name: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true
        }
      },
      last_name: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true
        }
      }
    }
  }

  handleChange = event => {
    let updatedElement = updateObject(this.state.signUpForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.signUpForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.signUpForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ signUpForm: updatedForm, formIsValid });
  };

  onSignUpHandler = (event) => {
    event.preventDefault();
    if (this.state.formIsValid) {
      const { username, password, email, first_name, last_name } = this.state.signUpForm;
      const COLORS = ['1665d8', 'f6ab2f', '42cb83', '32c5ff', '6758f3', '44d7b6'];
      const random = Math.floor(Math.random() * 6);
      const defaultProfilePicture = 'https://ui-avatars.com/api/?background=' + COLORS[random] + '&color=fff&rounded=true&name=' + first_name.value + '+' + last_name.value;

      this.props.signup(
        email.value,
        username.value,
        password.value,
        first_name.value,
        last_name.value,
        defaultProfilePicture,
      );
    }
    else {
      this.onSetTouchedFields();
    }
  }

  onSetTouchedFields = () => {
    let newState = { ...this.state.signUpForm };
    Object.keys(this.state.signUpForm).forEach(name => {
      let updatedElement = updateObject(newState[name], {
        touched: true
      });
      const updatedForm = updateObject(newState, {
        [name]: updatedElement
      });
      newState = updatedForm;
      this.setState({ signUpForm: updatedForm });
    });
  }

  render() {
    const { username, password, email, first_name, last_name } = this.state.signUpForm;

    let signUpForm = (
      <React.Fragment>
        <div style={{ flex: '0 1 90px' }}>
          <div className={styles.AuthTitle}>Create Your Account</div>
          <div className={styles.AuthSubTitle}>Get started with OWL with love!</div>
        </div>
        <form onSubmit={this.onSignUpHandler} className={styles.FormContainer}>
          <div style={{ flex: 1 }}>
            {this.props.error ? <div className={styles.ErrorMessage}>{this.props.error}</div> : ''}
            <div className={styles.FormGroup}>
              <Input
                label="username"
                name="username"
                type="text"
                value={username.value}
                onChange={this.handleChange}
                error={`${username.touched && !username.valid ? 'Username is required' : ''}`}
                whitemode="true"
              />
            </div>
            <div className={styles.FormGroup}>
              <Input
                label="password"
                name="password"
                type="password"
                value={password.value}
                onChange={this.handleChange}
                error={`${password.touched && !password.valid ? 'Password is required' : ''}`}
                whitemode="true"
              />
            </div>
            <div style={{ display: 'flex' }}>
              <div className={styles.FormGroup} style={{ marginRight: 15 }}>
                <Input
                  label="first name"
                  name="first_name"
                  type="text"
                  value={first_name.value}
                  onChange={this.handleChange}
                  error={`${first_name.touched && !first_name.valid ? 'First Name is required' : ''}`}
                  whitemode="true"
                />
              </div>
              <div className={styles.FormGroup}>
                <Input
                  label="last name"
                  name="last_name"
                  type="text"
                  value={last_name.value}
                  onChange={this.handleChange}
                  error={`${last_name.touched && !last_name.valid ? 'Last Name is required' : ''}`}
                  whitemode="true"
                />
              </div>
            </div>
            <div className={styles.FormGroup}>
              <Input
                label="e-mail address"
                name="email"
                type="text"
                value={email.value}
                onChange={this.handleChange}
                error={`${email.touched && !email.valid ? 'Email is required and must be the correct email format' : ''}`}
                whitemode="true"
              />
            </div>
            <div style={{ marginTop: 50 }}>
              <Button
                type="submit"
                color="blue"
                name={this.props.loading ? 'Loading...' : 'Get Started with OWL'}
                loading={this.props.loading}
                noMargin
              />
            </div>
          </div>
        </form>
      </React.Fragment>
    );

    return (
      <div className={styles.Auth}>
        <div className={styles.LeftSection}>
          <div className={styles.AuthWrapper}>
            {signUpForm}
          </div>
        </div>
        {this.props.isMobile ? null :
          <div className={styles.RightSection} style={{ backgroundImage: `url(${SigninImage})` }}>
            <div className={styles.Testimonial}>
              <div>
                <img src={WorldIcon} alt="Technimal.co.th" className={styles.WorldIcon} />
                <b>Technimal.co.th</b>
              </div>
              <p style={{ maxWidth: 250 }}>Delivering our technology to help you achieve business goal</p>
              <div>
                <img src={CeoImage} alt="CEO of Technimal" className={styles.CeoImage} />
                <span style={{ fontSize: 14 }}><b>CEO</b> Thanyathat Angsuphisit</span>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error } = state.auth;
  return { loading, error };
};

export default connect(mapStateToProps, { signup })(SignUp);