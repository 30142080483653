import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import authReducer from './Auth';
import pagesReducer from './Pages';
import widgetsReducer from './Widgets';
import DevicesReducer from './Devices';
import DeviceSocketReducer from './DeviceSocket';
import DeviceConfigReducer from './DeviceConfig';
import membersReducer from './Members';
import ruleReducer from './Rule';
import hookReducer from './Hook';
import ruleCommandReducer from './RuleCommand';
import ruleConditionReducer from './RuleCondition';
import dataReducer from './Data';
import teamReducer from './Team';
import organizationReducer from './Organization';
import productionPlanReducer from './ProductionPlan';
import shiftReducer from './Shift';
import countingDevicesReducer from './CountingDevices';
import breakdownReducer from './Breakdown';
import oeeReducer from './OEE';
import userFavoriteReducer from './UserFavorite';
import chatroomReducer from './ChatRoom';
import exportReducer from './Export';
import commandReducer from './Command';
import timelinesReducer from './Timeline';
import timelineStatusReducer from './TimelineStatus';
import machineReducer from './Machine';
import reportPresetReducer from './ReportPreset';
import sequentialCommandReducer from './SequentialCommand';
import virtualDeviceReducer from './VirtualDevice';
import advanceProductionPlanStructureReducer from './AdvanceProductionPlanStructure';
import advanceProductionPlanReducer from './AdvanceProductionPlan';
import advanceProductionBatchReducer from './AdvanceProductionBatch';
import advanceActualProductionReducer from './AdvanceActualProduction';
import eventReducer from './Event';
import documentMasterDataReducer from './DocumentMasterData';
import documentReducer from './Document';
import adminReducer from './Admin';
import errorLogReducer from './ErrorLog';
import moduleReducer from './Module';
import notificationReducer from './Notification';
import schedulerReducer from './Scheduler';
import formulaReducer from './Formula';
import variableReducer from './Variable';
import deviceGroupingReducer from './DeviceGrouping';
import powerReducer from './Power';

export const history = createBrowserHistory();

const appReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  pages: pagesReducer,
  widgets: widgetsReducer,
  devices: DevicesReducer,
  deviceSockets: DeviceSocketReducer,
  deviceConfigs: DeviceConfigReducer,
  members: membersReducer,
  rule: ruleReducer,
  ruleCommand: ruleCommandReducer,
  ruleCondition: ruleConditionReducer,
  data: dataReducer,
  team: teamReducer,
  org: organizationReducer,
  productionPlan: productionPlanReducer,
  shift: shiftReducer,
  countingDevices: countingDevicesReducer,
  breakdown: breakdownReducer,
  oee: oeeReducer,
  userFavorite: userFavoriteReducer,
  chatroom: chatroomReducer,
  export: exportReducer,
  command: commandReducer,
  timeline: timelinesReducer,
  timelineStatus: timelineStatusReducer,
  hook: hookReducer,
  machine: machineReducer,
  reportPreset: reportPresetReducer,
  sequentialCommand: sequentialCommandReducer,
  virtualDevice: virtualDeviceReducer,
  advanceProductionPlanStructure: advanceProductionPlanStructureReducer,
  advanceProductionPlan: advanceProductionPlanReducer,
  advanceProductionBatch: advanceProductionBatchReducer,
  advanceActualProduction: advanceActualProductionReducer,
  event: eventReducer,
  docMasterData: documentMasterDataReducer,
  document: documentReducer,
  admin: adminReducer,
  errorLog: errorLogReducer,
  module: moduleReducer,
  notification: notificationReducer,
  scheduler: schedulerReducer,
  formula: formulaReducer,
  variable: variableReducer,
  deviceGrouping: deviceGroupingReducer,
  power: powerReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'SIGNOUT_SUCCESS') {
    state = undefined;
  }
  return appReducer(state, action)
}

export default rootReducer;