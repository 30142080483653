import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Rule.module.scss';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import Button from '../../components/UI/Button/Button';
import AddNewRule from '../../components/Rule/AddNewRule/AddNewRule';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import RuleList from '../../components/Rule/RuleList/RuleList';
import AlarmHistoriesModal from './AlarmHistoriesModal';

import { getRules, deleteRule, enableRule } from '../../../state/ducks/Rule/actions';
import { getDevices } from '../../../state/ducks/Devices/actions';
import { getMachines } from '../../../state/ducks/Machine/actions';
import { DELETE_RULE_SUCCESS } from '../../../state/ducks/Rule/types';
import EventHistory from '../../components/Event/EventHistory/EventHistory';

class Rule extends Component {
  state = {
    selectedRule: null,
    openRuleForm: false,
    isAdding: false,
    editingRule: null,
    isDeleteModalOpened: false,
    isAlarmHistoriesModalModalOpened: false,
    selectedRuleForHistories: null,
    currentTabIndex: 0
  }

  componentDidMount() {
    const { rules, devices, currentOrg, machines } = this.props;
    if (!rules || !rules.length || rules[0].org_id !== currentOrg) {
      this.props.getRules(currentOrg);
    }
    if (!devices || !devices.length || devices[0].org_id !== currentOrg) {
      this.props.getDevices(currentOrg, true);
    }
    if (!machines || !machines.length || machines[0].org_id !== currentOrg) {
      this.props.getMachines(this.props.currentOrg);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.props.getRules(this.props.currentOrg);
      this.props.getDevices(this.props.currentOrg, true);
      this.props.getMachines(this.props.currentOrg);
      this.closeAddNewRuleHandler();
    } else {
      if ((this.props.result !== prevProps.result) && this.props.result === 'success') {
        if ((prevProps.addedRule !== this.props.addedRule) || (prevProps.updatedRule !== this.props.updatedRule) || (this.props.type === DELETE_RULE_SUCCESS)) {
          this.props.getRules(this.props.currentOrg);
        } else {
          // TODO: need to get deleted page index
          // case: delete
          // this.props.tags.splice(this.state.deletingIndex, 1);
        }
      }
    }
  }

  onAddRule = () => {
    if (!this.state.isAdding) {
      this.setState({ isAdding: true, editingRule: null, openRuleForm: true, selectedRule: null });
    }
  }

  closeAddNewRuleHandler = () => {
    this.setState({ openRuleForm: false, isAdding: false, editingRule: null, selectedRule: null })
  }

  onEditRule = (index) => {
    if (!this.state.editingRule || this.state.editingRule.id !== this.props.rules[index].id) {
      this.setState({ isAdding: false, editingRule: this.props.rules[index], openRuleForm: true, selectedRule: index });
    }
  }

  onDeleteRuleHandler = () => {
    this.setState({ isDeleteModalOpened: true });
  }

  deleteModalClickHandler = () => {
    this.setState(prevState => {
      return { isDeleteModalOpened: !prevState.isDeleteModalOpened }
    });
  }

  onConfirmDeleteRule = () => {
    this.props.deleteRule(this.state.editingRule.id);
    this.setState({ isDeleteModalOpened: false });
  }

  onOpenHistoriesModalHandler = (event, index) => {
    event.stopPropagation();
    this.setState({ isAlarmHistoriesModalModalOpened: true, selectedRuleForHistories: this.props.rules[index] });
  }

  alarmHistoriesModalClickHandler = () => {
    this.setState(prevState => {
      return { isAlarmHistoriesModalModalOpened: !prevState.isAlarmHistoriesModalModalOpened }
    });
  }

  render() {
    return (
      <div className={styles.Rule}>
        <div className={styles.RuleTopBar}>
          <div className={styles.TopMenuRule}>
            <div style={{ display: 'flex', flex: 1 }}>
              <div className={styles.MenuWrapper}>

                <div className={this.state.currentTabIndex === 0 ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentTabIndex: 0 })}>
                  <span className={styles.MenuTitle}>Configure</span>
                </div>
                <div className={this.state.currentTabIndex === 1 ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentTabIndex: 1 })}>
                  <span className={styles.MenuTitle}>History</span>
                </div>

              </div>
            </div>
            {
              this.state.currentTabIndex === 0
                ? <div className={styles.AddButtonWrapper}>
                  <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Event" noMargin click={this.onAddRule} />
                </div>
                : null
            }
          </div>
        </div>
        {
          this.state.currentTabIndex === 0 ?
            <div className={styles.RuleBody}>
              <div className={styles.RuleTopics}>
                <div className={styles.RuleFix}>
                  {
                    this.props.loading ? <><div className={styles.LoadingCard}></div> <div className={styles.LoadingCard}></div></> :
                      this.props.rules ?
                        <RuleList
                          rules={this.props.rules}
                          onEditRule={this.onEditRule}
                          selectedRule={this.state.selectedRule}
                          closed={this.closeAddNewRuleHandler}
                          openHistoriesModal={this.onOpenHistoriesModalHandler}
                        />
                        : null
                  }
                </div>
              </div>

              <div className={styles.RuleDetails}>
                {
                  this.state.openRuleForm &&
                  <AddNewRule
                    isAdding={this.state.isAdding}
                    devices={this.props.devices}
                    editingRule={this.state.editingRule}
                    closed={this.closeAddNewRuleHandler}
                    toggleDeleteModal={this.onDeleteRuleHandler}
                  />
                }
              </div>
            </div>
            : null
        }
        {
          this.state.currentTabIndex === 1 ?
            <div className={styles.RuleBody}>
              <div className={styles.RuleDetails}>
                <EventHistory />
              </div>
            </div>
            : null
        }

        <DeleteModal
          title="rule"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeleteRule}
        />

        <AlarmHistoriesModal open={this.state.isAlarmHistoriesModalModalOpened} modalClosed={this.alarmHistoriesModalClickHandler} selectedRule={this.state.selectedRuleForHistories} />

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { devices } = state.devices;
  const { machines } = state.machine;
  const { type, result, rules, addedRule, updatedRule, loading } = state.rule;
  return { currentOrg, devices, type, result, rules, addedRule, updatedRule, loading, machines };
};

export default connect(mapStateToProps, { getRules, getDevices, getMachines, deleteRule, enableRule })(Rule);