import React, { Component } from 'react';
import { connect } from 'react-redux';
import MultipleSelect from 'react-select';
import ReactSelect from 'react-select';
import moment from 'moment';
import _ from 'lodash';
import { ParentSize } from '@vx/responsive';
import CsvDownloader from 'react-csv-downloader';

import styles from '../Report.module.scss';
import ReportImage from '../../../../assets/images/Report/report.png';
import ReportImage2x from '../../../../assets/images/Report/report@2x.png';
import ReportImage3x from '../../../../assets/images/Report/report@3x.png';
import DownloadIcon from '../../../../assets/icons/Report/download.svg';
import AddIcon from '../../../../assets/icons/plus-circle.svg';
import ViewIcon from '../../../../assets/icons/Report/view.svg';
import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';
import Button from '../../UI/Button/Button';
import Select from '../../UI/Select/Select';
import Spinner from '../../UI/Spinner/Spinner';
import EditWhiteIcon from '../../../../assets/icons/edit-white.svg';
import StarYellowIcon from '../../../../assets/icons/Report/star-yellow.svg';

import HistoricalLineChartVx from './HistoricalLineChartVx';
import SideDrawer from '../../Navigation/SideDrawer/SideDrawer';
import PresetDrawer from '../PresetDrawer/PresetDrawer';
import DeleteModal from '../../shared/DeleteModal/DeleteModal';
import { SnackBar } from '../../UI/SnackBar/SnackBar';

import { intervalToDateConverter } from '../../../../state/utils/Report';
import { getDevices } from '../../../../state/ducks/Devices/actions';
import { getVirtualDevices } from '../../../../state/ducks/VirtualDevice/actions';
import { getDatasetCal, clearData, getData, setLoadingPercent } from '../../../../state/ducks/Data/actions';
import { deleteReportPreset } from '../../../../state/ducks/ReportPreset/actions';
import { getExportCheck, getExportExcel } from '../../../../state/ducks/Export/actions';
import { calculateNewValFromEquation } from '../../../../state/utils';
import { ADD_REPORT_PRESET_SUCCESS, EDIT_REPORT_PRESET_SUCCESS, DELETE_REPORT_PRESET_SUCCESS } from '../../../../state/ducks/ReportPreset/types';

const summarizeOptions = [
  { id: '60', name: '1 min' },
  { id: '120', name: '2 mins' },
  { id: '900', name: '15 mins' },
  { id: '1800', name: '30 mins' },
  { id: '3600', name: '1 hour' },
];

const methodOptions = [
  { id: 'avg', name: 'Average' },
  { id: 'max', name: 'Max' },
  { id: 'min', name: 'Min' },
  { id: 'mode', name: 'Mode' },
  { id: 'sum', name: 'Sum' },
  { id: 'count', name: 'Count' },
];

// TODO: Add appropriate time intervals
const chartIntervalOptions = [
  // { value: '60', label: '1 min' },
  // { value: '120', label: '2 mins' },
  // { value: '900', label: '15 mins' },
  // { value: '1800', label: '30 mins' },
  { value: '3600', label: '1 hour' },
  { value: '86400', label: 'Today' },
  { value: '604800', label: 'This week' },
  { value: '2592000', label: 'This month' },
]

const colourStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "#1665d8",
  }),
  multiValueLabel: base => ({
    ...base,
    color: "white",
  })
};

const chartOptionColourStyles = {
  singleValue: (styles) => ({
    ...styles,
    color: "white"
  }),
  control: (base, state) => ({
    ...base,
    width: 200,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      width: 200,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0,
    width: 200,
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
};

// const DATA_COLORS = ['#1665d8', '#ff5454', 'rgba(75,192,192,1)', '#34aa44', '#FFA000'];
const DATA_COLORS = ['#1665d8', '#ff5454', 'rgba(75,192,192,1)', '#34aa44', '#FFA000', '#9b59b6', '#f1c40f', '#e67e22', '#e74c3c', '#81ecec', '#a29bfe', '#fab1a0', '#fd79a8', '#5f27cd', '#ecf0f1'];

// Utils
// const handleDecimal = (number) => (number - Math.floor(number)) !== 0 ? number.toFixed(2) : number;

const INITIAL_STATE = {
  selectedDevices: [],
  selectedMethod: '-1',
  selectedSummarize: '-1',
  queryStringDevices: null,
  startDate: '',
  startTime: '',
  stopDate: '',
  stopTime: '',
  loading: false,
  datasets: [],
  countDatasets: 0,
  downloading: false,
  showPercent: [],
  autoFillIndex: 0,
  legendShowList: [],
  minimumValueList: [],
  maximumValueList: [],
  averageValueList: [],
  openSelectForm: true,
  userClickOnce: false,
  selectedDevicesLoad: [],
  dataMaxIndex: 0,
  finishDownload: false,
  presetDrawerOpened: false,
  presetProperty: {
    devices: [],
    method: '',
    summarize: '',
    startDate: '',
    startTime: '',
    stopDate: '',
    stopTime: '',
    interval: '',
    startDiff: 0,
    stopDiff: 0
  },
  isDeleteModalOpened: false,
  deletingPreset: null,
  presetLoad: false,
  dataTimestampInterval: 1000,
};

class HistoricalReportVx extends Component {
  snackbarRef = React.createRef();

  state = {
    ...INITIAL_STATE,
    ...{
      preset: this.props.selectedPreset ? this.props.selectedPreset.type === 'data' ? this.props.selectedPreset : null : null,
    }
  };

  componentDidMount() {
    const { devices, currentOrg } = this.props;
    if (!devices || !devices.length || devices[0].org_id !== currentOrg) {
      this.props.getDevices(currentOrg, true, true);
      this.props.getVirtualDevices(this.props.currentOrg);
    }
    this.props.clearData();
    if (this.state.preset) {
      let newStartDate = this.state.preset.properties.startDate;
      let newStopDate = this.state.preset.properties.stopDate;
      if (this.state.preset.properties.interval === -1) {
        newStartDate = new Date(Date.now() + this.state.preset.properties.startDiff).toISOString().substring(0, 10);
        newStopDate = new Date(Date.now() + this.state.preset.properties.stopDiff).toISOString().substring(0, 10);
      } else {
        let intervalToDate = intervalToDateConverter(this.state.preset.properties.interval);
        newStartDate = intervalToDate[0];
        newStopDate = intervalToDate[1];
      }
      this.setState({
        selectedDevices: this.state.preset.properties.devices,
        selectedMethod: this.state.preset.properties.method,
        selectedSummarize: this.state.preset.properties.summarize,
        autoFillIndex: this.state.preset.properties.interval,
        startDate: newStartDate,
        startTime: this.state.preset.properties.startTime,
        stopDate: newStopDate,
        stopTime: this.state.preset.properties.stopTime,
        presetLoad: true,
      });
    } else {
      this.setDefaultDateTime();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.onNewReportFormHandler();
      this.props.getDevices(this.props.currentOrg, true, true);
      this.props.getVirtualDevices(this.props.currentOrg);
    } else {
      if (this.props.datasetCal && prevProps.datasetCal !== this.props.datasetCal) {
        this.setDatasets(this.props.datasetCal, true);
      } else if (this.props.error !== prevProps.error && this.props.error) {
        this.setState({ loading: false });
      }

      if (this.props.data && prevProps.data !== this.props.data) {
        this.setDatasets(this.props.data, false);
      }

      if (!_.isEqual(prevProps.loadingPercent, this.props.loadingPercent) && this.props.loadingPercent) {
        let showPercent = [...this.state.showPercent];
        if (!this.state.showPercent.length) {
          showPercent = new Array(this.state.selectedDevices.length).fill(0);
        } else {
          showPercent[this.props.loadingPercent.deviceIndex] = +this.props.loadingPercent.percent;
        }
        this.setState({ showPercent });
      }
      if (!_.isEqual(prevProps.loadingPercent, this.props.loadingPercent) && !this.props.loadingPercent) {
        this.setState({ showPercent: [] });
      }

      clearInterval(this.exportCheck);
      if ((prevProps.exportId === this.props.exportId) && this.props.exportId && !this.props.url && !this.props.exportError) {
        this.exportCheck = setInterval(() => {
          this.props.getExportCheck(this.props.exportId);
        }, 1000)
      } else if ((prevProps.url !== this.props.url) && this.props.url) {
        this.setState({ downloading: false });
        window.open(this.props.url);
      }

      // Calculate Presummarize Data after retrieve all datatsets
      if (prevState.loading === true && this.state.loading === false) {
        if (this.state.userClickOnce) {
          // Calculate data timestamp interval to be set
          let newDataTimestampInterval = 1000;
          if (this.state.selectedSummarize !== '-1') {
            newDataTimestampInterval = parseInt(this.state.selectedSummarize) * 1000;
          }
          this.setState({ finishDownload: true, dataTimestampInterval: newDataTimestampInterval });
        }

        if (this.state.datasets.length === 0 || (this.state.datasets[0] && this.state.datasets[0].length === 0)) {
          this.toggleOptionStatus();
        }

        this.calPresumData();
      } else if (prevState.loading === false && this.state.loading === true) {
        if (this.state.userClickOnce) {
          this.setState({ finishDownload: false });
        }
      }

      // Auto load for preset
      if (prevState.presetLoad === false && this.state.presetLoad === true) {
        this.onSubmitReportHandler();
      }

      // Handler for preset CRUD response
      if (prevProps.result === null && this.props.result === 'success') {
        if (this.props.type === ADD_REPORT_PRESET_SUCCESS) {
          this.snackbarRef.current.openSnackBar('Preset has been saved');
          this.setState({
            preset: this.props.addedReportPreset,
            presetLoad: true
          });
        } else if (this.props.type === EDIT_REPORT_PRESET_SUCCESS) {
          this.snackbarRef.current.openSnackBar('Preset has been saved');
          this.setState({
            preset: this.props.updatedReportPreset
          });
        } else if (this.props.type === DELETE_REPORT_PRESET_SUCCESS) {
          this.props.backToPresetHandler();
        }
      }
    }
  }

  setDefaultDateTime = () => {
    let defaultStop = moment().valueOf();
    let defaultStart = defaultStop - 3600000;
    this.setState({
      startDate: moment(defaultStart).format('YYYY-MM-DD'),
      startTime: moment(defaultStart).format('HH:mm'),
      stopDate: moment(defaultStop).format('YYYY-MM-DD'),
      stopTime: moment(defaultStop).format('HH:mm'),
    });
  }

  setDatasets = (data, isCal) => {
    let datasets = [...this.state.datasets];
    if (datasets.length === 0) {
      datasets = new Array(this.state.selectedDevices.length).fill([]);
    }

    if (isCal) {
      if (data) {
        const foundIndex = this.state.selectedDevices.findIndex(device => device.secret === data.secret && device.socket === data.socket);
        // datasets[foundIndex] = data.values;
        datasets[foundIndex] = this.reCalculateArrayByEquation(data.values, this.state.selectedDevices[foundIndex].functions);
        this.setState({ datasets });
      }
    } else {
      if (data.values.length && data.values.length > 0) {
        const foundIndex = this.state.selectedDevices.findIndex(device => device.secret === data.properties.secret && device.socket === data.properties.socket);
        // datasets[foundIndex] = data.values;
        datasets[foundIndex] = this.reCalculateArrayByEquation(data.values, this.state.selectedDevices[foundIndex].functions);
        this.setState({ datasets });
      }
    }

    this.setState(prevState => {
      return {
        countDatasets: prevState.countDatasets + 1
      }
    });

    if ((this.state.countDatasets + 1) === this.state.selectedDevices.length) {
      this.setState({ loading: false });
    }
  }

  reCalculateArrayByEquation = (dataValues, equation) => {
    return dataValues.map(dv => {
      return {
        value: calculateNewValFromEquation(dv.value, equation),
        timestamp: dv.timestamp
      }
    });
  }

  onChange = (value) => {
    this.setState({ selectedDevices: value });
    if (!value) {
      this.setState({ queryStringDevices: '' });
    }
  }

  handleChange = (name, value) => {
    if (name === 'startDate' || name === 'startTime' || name === 'stopDate' || name === 'stopTime') {
      this.setState({ autoFillIndex: -1 });
    }
    this.setState({ [name]: value });
  };

  onSubmitReportHandler = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ datasets: [], loading: true, countDatasets: 0, openSelectForm: true, selectedDevices: this.state.selectedDevices, selectedDevicesLoad: this.state.selectedDevices });
    const { startDate, startTime, stopDate, stopTime } = this.state;
    const start = moment(startDate + ' ' + startTime);
    const stop = moment(stopDate + ' ' + stopTime);
    if (this.state.selectedSummarize !== '-1' && this.state.selectedMethod !== '-1') {
      this.props.setLoadingPercent(null);
      this.state.selectedDevices.forEach((device, index) => {
        this.props.getDatasetCal(index, this.state.selectedMethod, device.secret, device.socket, this.state.selectedSummarize, start.valueOf(), stop.valueOf());
      });
    } else if (this.state.selectedSummarize === '-1' && this.state.selectedMethod === '-1') {
      this.props.setLoadingPercent(null);
      this.state.selectedDevices.forEach((device, index) => {
        this.props.getData(index, null, start.valueOf(), stop.valueOf(), device.secret, device.socket);
      });
    } else {
      this.setState({ loading: false });
    }
    // Toggle Report Option Animation
    this.toggleOptionStatus();
  }

  // onClickExportExcel = () => {
  //   let deviceSocketId = '';
  //   const { startDate, startTime, stopDate, stopTime, selectedSummarize, selectedMethod, selectedDevices } = this.state;
  //   const start = moment(startDate + ' ' + startTime);
  //   const stop = moment(stopDate + ' ' + stopTime);

  //   selectedDevices.forEach((device, index) => {
  //     if (index === 0) {
  //       deviceSocketId += device.id;
  //     } else {
  //       deviceSocketId += ',' + device.id;
  //     }
  //   });
  //   this.props.getExportExcel(
  //     deviceSocketId,
  //     start.valueOf(),
  //     stop.valueOf(),
  //     selectedSummarize === '-1' ? null : selectedSummarize,
  //     selectedMethod === '-1' ? null : selectedMethod
  //   );
  //   this.setState({ downloading: true });
  // }

  prepareDataForCSV = () => {

  }

  autoFillDate = (index) => {
    let dateMoment = moment();

    let thisMonth = (moment().get('month') + 1) < 10 ? '0' + (moment().get('month') + 1) : moment().get('month') + 1;
    let thisDate = moment().get('date') < 10 ? '0' + moment().get('date') : moment().get('date');

    let newStopDate = moment().get('year') + '-' + thisMonth + '-' + thisDate;
    let newStartTime = '00:00';
    let newStopTime = '00:00';
    if (index === 0) {
      // 1 hr
      const minute = dateMoment.get('minute') < 10 ? '0' + dateMoment.get('minute') : dateMoment.get('minute');
      newStopTime = (dateMoment.get('hour') < 10 ? '0' + dateMoment.get('hour') : dateMoment.get('hour')) + ':' + minute;
      newStartTime = ((dateMoment.get('hour') - 1) < 10 ? '0' + (dateMoment.get('hour') - 1) : (dateMoment.get('hour') - 1)) + ':' + minute;
    } else if (index === 1) {
      // today (24 hr)
      dateMoment = moment().add(1, 'days');
      const newMonth = (dateMoment.get('month') + 1) < 10 ? '0' + (dateMoment.get('month') + 1) : dateMoment.get('month') + 1;
      const newDate = dateMoment.get('date') < 10 ? '0' + dateMoment.get('date') : dateMoment.get('date');
      newStopDate = moment().get('year') + '-' + newMonth + '-' + newDate;
    } else if (index === 2) {
      // TODO: IF PROFILE SETTINGS EXIST, SET GLOBAL FIRST DAY OF WEEK = SUNDAY/MONDAY
      // this week 
      dateMoment = moment().day(0);
      thisMonth = (dateMoment.get('month') + 1) < 10 ? '0' + (dateMoment.get('month') + 1) : dateMoment.get('month') + 1;
      thisDate = dateMoment.get('date') < 10 ? '0' + dateMoment.get('date') : dateMoment.get('date');
      const endOfWeek = moment().day(7);
      const newMonth = (endOfWeek.get('month') + 1) < 10 ? '0' + (endOfWeek.get('month') + 1) : endOfWeek.get('month') + 1;
      const newDate = endOfWeek.get('date') < 10 ? '0' + endOfWeek.get('date') : endOfWeek.get('date');
      newStopDate = endOfWeek.get('year') + '-' + newMonth + '-' + newDate;
    } else if (index === 3) {
      // this month
      dateMoment = moment().endOf('month');
      const dateInEndOfMonth = dateMoment.get('date') < 10 ? '0' + dateMoment.get('date') : dateMoment.get('date');
      newStopDate = dateMoment.get('year') + '-' + thisMonth + '-' + dateInEndOfMonth;
      thisDate = '01';
    }
    let newStartDate = dateMoment.get('year') + '-' + thisMonth + '-' + thisDate

    this.setState({
      autoFillIndex: index,
      startTime: newStartTime,
      startDate: newStartDate,
      stopTime: newStopTime,
      stopDate: newStopDate
    });
  }

  chartOptionChangeHandler = (option) => {
    let fillDateIndex = chartIntervalOptions.indexOf(chartIntervalOptions.filter(opt => opt.value === option.value)[0]);
    this.autoFillDate(fillDateIndex);
    if (fillDateIndex > 1) {
      if (this.state.selectedMethod === '-1') { this.setState({ selectedMethod: 'avg' }) }
      if (this.state.selectedSummarize === '-1') { this.setState({ selectedSummarize: '1800' }) }
    } else {
      this.setState({ selectedMethod: '-1', selectedSummarize: '-1' });
    }
    setTimeout(() => this.onSubmitReportHandler(), 500);
  }

  componentWillUnmount() {
    clearInterval(this.exportCheck);
    this.props.clearPresetHandler();
    this.snackbarRef.current = null;
    this.snackbarRef = null;
  }

  toggleOptionStatus = () => {
    let newOpenSelectForm = !this.state.openSelectForm;
    let formEl = document.querySelector('form');
    if (newOpenSelectForm) {
      this.openForm();
    } else {
      formEl.style.height = 0;
      formEl.style.paddingBottom = 0;
      // formEl.style.removeProperty('transition');
      formEl.style.transition = 'height 500ms ease-out';
    }
    this.setState({
      openSelectForm: newOpenSelectForm,
      userClickOnce: true,
    });
  }

  openForm = () => {
    const formEl = document.querySelector('form')
    formEl.style.height = '360px';
    formEl.style.transition = 'height 500ms ease-in';
    formEl.style.padding = '20px 22px';
  }

  // calDataMaxIndex = () => {
  //   var dataMax = 0;
  //   var newDataMaxIndex = 0;
  //   _.forEach(this.state.datasets, (el, i) => {
  //     let dataMaxCheck = _.maxBy(el, 'value')["value"];
  //     newDataMaxIndex = dataMaxCheck > dataMax ? i : this.state.dataMaxIndex;
  //   })
  //   this.setState(prevState => ({
  //     dataMaxIndex: newDataMaxIndex,
  //   }))
  // }

  calPresumData = () => {
    let legendShowList = [];
    let minimumValueList = [];
    let maximumValueList = [];
    let averageValueList = [];
    if (this.state.selectedDevices && this.state.selectedDevices.length > 0) {
      this.state.selectedDevices.forEach((device, deviceIndex) => {
        let maximumValue = 0;
        let minimumValue = 0;
        let summationValue = 0;
        if (this.state.datasets[deviceIndex]) {
          if (this.state.datasets[deviceIndex].length > 0) {
            legendShowList[deviceIndex] = true;
            this.state.datasets[deviceIndex].forEach((data, dataIndex) => {
              // Find minimum Value
              if (dataIndex === 0) {
                minimumValue = data.value;
                minimumValueList[deviceIndex] = minimumValue.toFixed(2);
              } else {
                if (data.value < minimumValue) {
                  minimumValue = data.value;
                  minimumValueList[deviceIndex] = minimumValue.toFixed(2);
                }
              }
              // Find Maximum Value
              if (dataIndex === 0) {
                maximumValue = data.value;
                maximumValueList[deviceIndex] = maximumValue.toFixed(2);
              } else {
                if (data.value > maximumValue) {
                  maximumValue = data.value;
                  maximumValueList[deviceIndex] = maximumValue.toFixed(2);
                }
              }
              // Find average value
              summationValue += data.value;
              if (dataIndex === this.state.datasets[deviceIndex].length - 1) {
                let averageVal = summationValue / this.state.datasets[deviceIndex].length;
                averageValueList[deviceIndex] = averageVal.toFixed(2);
              }
            });
          } else {
            legendShowList[deviceIndex] = true;
            minimumValueList[deviceIndex] = 'N/A';
            maximumValueList[deviceIndex] = 'N/A';
            averageValueList[deviceIndex] = 'N/A';
          }
        } else {
          legendShowList[deviceIndex] = false;
          minimumValueList[deviceIndex] = 0.00;
          maximumValueList[deviceIndex] = 0.00;
          averageValueList[deviceIndex] = 0.00;
        }
      });
    } else {
      // Do nothing. //
    }
    this.setState({
      legendShowList: legendShowList,
      minimumValueList: minimumValueList,
      maximumValueList: maximumValueList,
      averageValueList: averageValueList,
    })
  }

  presetDrawerToggleHandler = (saved) => {
    let newStartDiff = new Date(this.state.startDate) - Date.now();
    let newStopDiff = new Date(this.state.stopDate) - Date.now();
    this.setState(prevState => ({
      presetDrawerOpened: !prevState.presetDrawerOpened,
      presetProperty: {
        devices: this.state.selectedDevices,
        method: this.state.selectedMethod,
        summarize: this.state.selectedSummarize,
        startDate: this.state.startDate,
        startTime: this.state.startTime,
        stopDate: this.state.stopDate,
        stopTime: this.state.stopTime,
        interval: this.state.autoFillIndex,
        startDiff: newStartDiff,
        stopDiff: newStopDiff,
      }
    }));
  }

  deleteModalClickHandler = (preset) => {
    this.setState(prevState => {
      return {
        isDeleteModalOpened: !prevState.isDeleteModalOpened,
        deletingPreset: preset,
      }
    });
  }

  onNewReportFormHandler = () => {
    this.props.clearData();
    const resetState = { ...INITIAL_STATE, ...{ preset: null } };
    this.openForm();
    this.setState(resetState);
    this.setDefaultDateTime();
  }

  onConfirmDeletePreset = (event) => {
    event.preventDefault();
    if (this.state.deletingPreset) {
      const { id } = this.state.deletingPreset;
      this.props.deleteReportPreset(id);
      this.setState({ deletingPreset: null, isDeleteModalOpened: false });
    }
  }

  onDownloadCSV = async () => {
    const { datasets, selectedDevicesLoad } = this.state;
    let body = [];

    let header = ['Date', 'Time']
    let lengths = []
    let indexs = []
    for (let i = 0; i < selectedDevicesLoad.length; i++) {
      header.push(selectedDevicesLoad[i].label.replaceAll(",", " "));
      lengths.push(datasets[i].length);
      indexs.push(0);
    }
    body.push(header)

    let rowMap = {}
    for (let i = 0; i < datasets.length; i++) {
      let dataset = datasets[i]
      dataset.forEach(data => {
        let ts = new Date(data.timestamp)
        let row = rowMap[ts.getTime()]
        if (!row) {
          row = []
          row.push(ts.getDate() + '/' + (ts.getMonth() + 1) + '/' + ts.getFullYear());
          row.push(ts.getHours() + ':' + ts.getMinutes() + ':' + ts.getSeconds() + "." + ts.getMilliseconds());
          for (let tmpi = 0; tmpi < i; tmpi++) {
            row.push('')
          }
        }
        if (row.length - 2 <= i) {
          if (isNaN(data.value) || (data.value > 0 && data.value <= 1e-9))
            row.push('');
          else
            row.push(data.value)
          rowMap[ts.getTime()] = row
        }
      })
    }

    Object.keys(rowMap).sort().forEach(key => {
      body.push(rowMap[key])
    })

    return Promise.resolve(body);
  }

  render() {
    const { loggedInUser, currentOrg } = this.props;
    const { selectedDevices, selectedMethod, selectedSummarize, startDate, startTime, stopDate, stopTime, userClickOnce, legendShowList, minimumValueList, maximumValueList, averageValueList, finishDownload } = this.state;
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    let isDisabled = !(selectedDevices && selectedDevices.length) || !startTime || !startDate || !stopTime || !stopDate;

    let isViewButtonDisabled = true;
    if (!isDisabled) {
      const start = moment(startDate + ' ' + startTime);
      const stop = moment(stopDate + ' ' + stopTime);
      const isInOneDay = stop - start <= (24 * 60 * 60 * 1000);
      if (selectedSummarize !== '-1' && selectedMethod !== '-1') {
        isViewButtonDisabled = false;
      } else if (selectedSummarize === '-1' && selectedMethod === '-1' && isInOneDay) {
        isViewButtonDisabled = false;
      }
    }

    const options = [];
    if (this.props.devices) {
      this.props.devices.forEach(device => {
        device.device_sockets.forEach(ds => {
          if (!ds.can_control) {
            options.push({
              id: ds.id,
              label: ds.name + " (" + device.name + ")",
              secret: ds.device_secret,
              socket: ds.socket,
              functions: ds.functions,
              unit_name: ds.unit_name
            });
          }
        });
      });
    }

    if (this.props.virtualDevices) {
      this.props.virtualDevices.forEach(vd => {
        options.push({
          id: vd.uuid,
          label: vd.name + " (Virtual)",
          secret: vd.secret,
          socket: vd.socket,
          functions: '',
          unit_name: ''
        });
      });
    }

    let show = '0.00';
    if (this.state.selectedDevices && this.state.selectedDevices.length) {
      if (this.state.showPercent.length) {
        let sum = 0;
        this.state.showPercent.forEach(per => {
          sum += per;
        });
        show = (sum / this.state.selectedDevices.length).toFixed(2);
      }
    }

    let preset_created_user_match = false;
    if (this.state.preset) {
      if (loggedInUser.id === this.state.preset.created_user.id) {
        preset_created_user_match = true;
      }
    }

    // undefined timestamp hotfix purpose
    let undefinedTimestampFound = false;
    if (this.state.datasets[0] && this.state.datasets[0].length === 0) {
      undefinedTimestampFound = true;
    }

    return (
      <div className={styles.ReportQueryWrapper}>
        <SnackBar ref={this.snackbarRef} type="success" />
        <div className={styles.ReportTitleSection}>
          <span className={styles.Title}>{this.props.selectedPreset ? this.props.selectedPreset.name : 'Data Report'}</span>
          <div className={styles.OptionButtonWrapper}>
            {
              // Preset button for newly create
              !this.state.preset &&
              <div className={styles.SavePresetButtonOption}>
                <Button
                  type="button"
                  icon={StarYellowIcon}
                  color="yellow-border"
                  name={'Save Preset'}
                  click={() => this.presetDrawerToggleHandler()}
                  loading={this.state.downloading}
                  disabled={!selectedDevices || selectedDevices.length === 0}
                  noMargin />
              </div>
            }
            {
              (this.state.preset && (loggedInUser.is_superuser || loggedInUser.admin_of_org.includes(currentOrg) || preset_created_user_match))
                ?
                <div className={styles.SavePresetButtonOption}>
                  <Button
                    type="button"
                    icon={StarYellowIcon}
                    color="yellow-border"
                    name={this.state.presetLoad ? 'Save Changes' : 'Save Preset'}
                    click={() => this.presetDrawerToggleHandler()}
                    loading={this.state.downloading}
                    disabled={!selectedDevices || selectedDevices.length === 0}
                    noMargin />
                </div>
                : null
            }
            {(this.state.selectedDevices &&
              (this.state.countDatasets === this.state.selectedDevices.length) &&
              (this.state.selectedDevices.length >= 1)) ||
              (userClickOnce && finishDownload)
              ?
              <>
                <div className={styles.EditReportButton} onClick={this.toggleOptionStatus}>
                  <Button
                    icon={EditWhiteIcon}
                    key={'EditReportButton'}
                    color="green"
                    name="Edit"
                    noMargin
                  />
                </div>
                <CsvDownloader datas={this.onDownloadCSV} filename={"export_data.csv"} className={styles.EditReportButton} style={{ width: 150 }}>
                  <Button type="button" icon={DownloadIcon} color="green" name={this.state.downloading ? 'Loading...' : 'Download'}
                    loading={this.state.downloading} disabled={isDisabled} noMargin />
                </CsvDownloader>
              </>
              :
              null
            }
            {this.state.presetLoad &&
              <div className={styles.NewReportButtonOption}>
                <Button
                  type="button"
                  icon={AddIcon}
                  color="primary"
                  name="New Report"
                  click={this.onNewReportFormHandler}
                  // loading={this.state.downloading}
                  noMargin />
              </div>
            }
          </div>

        </div>
        <div className={styles.SelectorsSection}>
          {
            this.state.loading || userClickOnce ?
              null
              :
              <img
                src={ReportImage}
                className={styles.ReportCoverImage}
                srcSet={`${ReportImage2x} 2x, ${ReportImage3x} 3x`}
                alt="Report cover"
                loading="lazy"
              />
          }
          <form className={styles.FormWrapper} style={{ height: this.state.preset ? '0' : '340px' }} onSubmit={this.onSubmitReportHandler}>
            <div className={styles.FormGroup} style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
              <label className={styles.Title}>SELECT DEVICES *</label>
              <MultipleSelect isMulti isSearchable closeMenuOnSelect={false} options={options} styles={colourStyles}
                placeholder="You can select many devices to compare each other"
                value={selectedDevices}
                onChange={this.onChange}
                getOptionValue={opt => opt.id}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1, marginRight: 20 }}>
                <Select
                  name="selectedMethod"
                  label="Select Method"
                  placeholder="Select calculation method"
                  options={methodOptions}
                  value={selectedMethod}
                  autoComplete="off"
                  overidestyleformgroup={{ marginBottom: 15 }}
                  onChange={(event) => this.handleChange('selectedMethod', event.target.value)}
                  notRequired
                  tooltip={"When you set date(start-stop) over 24 hours, you NEED to set method AND summarize"}
                />
              </div>
              <div style={{ flex: 1 }}>
                <Select
                  name="selectedSummarize"
                  label="Summarize Every"
                  placeholder="Select summarize"
                  options={summarizeOptions}
                  value={selectedSummarize}
                  autoComplete="off"
                  overidestyleformgroup={{ marginBottom: 15 }}
                  onChange={(event) => this.handleChange('selectedSummarize', event.target.value)}
                  notRequired
                  tooltip={"When you set date(start-stop) over 24 hours, you NEED to set method AND summarize"}
                />
              </div>
            </div>

            <div className={styles.FormGroup}>
              <div style={{ flex: 1, marginRight: 20 }}>
                <label className={styles.Title}>Starts *</label>
                <div style={{ display: 'flex', position: 'relative' }}>
                  {!isChrome && <img src={CalendarIcon} alt="Starts" className={styles.CalendarIcon} />}
                  <input className={styles.SelectBox} style={{ paddingLeft: !isChrome ? 46 : null }} type="date"
                    onChange={(event) => this.handleChange('startDate', event.target.value)} value={startDate} />
                  <input className={styles.SelectBox} type="time" onChange={(event) => this.handleChange('startTime', event.target.value)}
                    value={startTime} />
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <label className={styles.Title}>Ends *</label>
                <div style={{ display: 'flex', position: 'relative' }}>
                  {!isChrome && <img src={CalendarIcon} alt="Ends" className={styles.CalendarIcon} />}
                  <input className={styles.SelectBox} style={{ paddingLeft: !isChrome ? 46 : null }} type="date"
                    onChange={(event) => this.handleChange('stopDate', event.target.value)} value={stopDate} />
                  <input className={styles.SelectBox} type="time" onChange={(event) => this.handleChange('stopTime', event.target.value)}
                    value={stopTime} />
                </div>
              </div>
            </div>

            <div style={{ margin: '10px 0' }}>
              <div className={[styles.AutoFillBox, this.state.autoFillIndex === 0 ? styles.Selected : ''].join(' ')} onClick={() => this.autoFillDate(0)}>1 HOUR</div>
              <div className={[styles.AutoFillBox, this.state.autoFillIndex === 1 ? styles.Selected : ''].join(' ')} onClick={() => this.autoFillDate(1)}>TODAY</div>
              <div className={[styles.AutoFillBox, this.state.autoFillIndex === 2 ? styles.Selected : ''].join(' ')} onClick={() => this.autoFillDate(2)}>THIS WEEK</div>
              <div className={[styles.AutoFillBox, this.state.autoFillIndex === 3 ? styles.Selected : ''].join(' ')} onClick={() => this.autoFillDate(3)}>THIS MONTH</div>
            </div>

            <div className={styles.ButtonsWrapper}>
              <div className={styles.ViewButton}>
                <Button type="submit" icon={ViewIcon} color="primary" name="View My Report" disabled={this.state.loading || isDisabled || isViewButtonDisabled} noMargin />
              </div>
            </div>
          </form>
          {
            this.props.error && this.props.error.data ?
              <div className={styles.ErrorWrapper}>
                <div>Something went wrong! Please contact Technimal administrator.</div>
                <div className={styles.DetailsError}>( Details: {this.props.error.data.error})</div>
              </div>
              :
              this.state.loading ?
                <div style={{ textAlign: 'center', paddingTop: 100, paddingBottom: 100 }}>
                  <div className={styles.Percent}>
                    {show} %
                  </div>
                  <Spinner />
                </div>
                :
                (this.state.datasets && this.state.datasets.length > 0) &&
                  (!undefinedTimestampFound) ?
                  <div>
                    {/* TODO: Add Datetime detail for more informative */}
                    {/* <p style={{textAlign: 'center'}}>{startDate} {startTime} - {stopDate} {stopTime}</p> */}
                    <div style={{ maxWidth: '99%', display: 'flex', justifyContent: 'flex-end', paddingRight: '32px' }}>
                      <ReactSelect
                        options={chartIntervalOptions}
                        value={chartIntervalOptions[this.state.autoFillIndex]}
                        name="selectedChartInterval"
                        styles={chartOptionColourStyles}
                        onChange={(option) => this.chartOptionChangeHandler(option)}
                      />
                    </div>
                    {(finishDownload && !undefinedTimestampFound) &&
                      <ParentSize style={{ width: '99%' }}>
                        {parent => (
                          <HistoricalLineChartVx data={this.state.datasets} devices={this.state.selectedDevicesLoad} width={parent.width} height={400} dataTimestampInterval={this.state.dataTimestampInterval} />
                        )}
                      </ParentSize>
                    }
                    <div style={{ marginLeft: '50px' }}>
                      {
                        this.state.selectedDevicesLoad ?
                          this.state.selectedDevicesLoad.map((device, i) => (
                            legendShowList[i] ? <div
                              key={'Legend' + i}
                              className={styles.LegendBox}
                            >
                              <svg width={20} height={20} style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <rect width={20} height={20} style={{ fill: DATA_COLORS[i] }} />
                              </svg>
                              <p className={styles.LegendText} style={{ marginLeft: '10px', marginRight: '20px', width: '40%', }}>{device['label']}</p>
                              <p className={styles.LegendText} style={{ marginLeft: '10px', marginRight: '10px', width: '15%', }}>Average: {averageValueList[i]}</p>
                              <p className={styles.LegendText} style={{ marginLeft: '10px', marginRight: '10px', width: '15%', }}>Minimum: {minimumValueList[i]}</p>
                              <p className={styles.LegendText} style={{ marginLeft: '10px', marginRight: '10px', width: '15%', }}>Maximum: {maximumValueList[i]}</p>
                            </div>
                              : null
                          ))
                          : null
                      }
                    </div>
                  </div>
                  :
                  ((this.state.datasets && this.state.datasets.length === 0 && userClickOnce) || undefinedTimestampFound) ?
                    <div className={styles.ErrorWrapper} style={{ color: 'white' }}>Data not found!</div>
                    : null
          }
        </div>
        <SideDrawer
          noHeader
          open={this.state.presetDrawerOpened}
          closed={this.presetDrawerToggleHandler}
        >
          {
            this.state.presetDrawerOpened ?

              <PresetDrawer
                closed={this.presetDrawerToggleHandler}
                orgId={this.props.currentOrg}
                settingUpNewAddedWidget={this.settingUpNewAddedWidget}
                presetType={'data'}
                presetDrawerToggle={this.presetDrawerToggleHandler}
                preset={this.state.preset}
                presetProperty={this.state.presetProperty}
                onSubmit={this.onPresetDrawerSubmitHandler}
                onDelete={() => this.deleteModalClickHandler(this.state.preset)}
              />
              : null
          }
        </SideDrawer>
        {/* {
          this.state.isDeleteModalOpened ? */}
        <DeleteModal
          title="widget"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeletePreset}
        />
        {/* :
            null
        } */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { devices } = state.devices;
  const { currentOrg } = state.org;
  const { datasetCal, data, error, loadingPercent } = state.data;
  const { exportId, url } = state.export;
  const { type, result, addedReportPreset, updatedReportPreset } = state.reportPreset;
  const { virtualDevices } = state.virtualDevice;

  return {
    loggedInUser, currentOrg, devices, datasetCal, data, exportId, url, error, loadingPercent, type, result, addedReportPreset, updatedReportPreset,
    exportError: state.export.error,
    virtualDevices
  };
};

export default connect(mapStateToProps, { getDevices, getDatasetCal, getData, clearData, getExportCheck, getExportExcel, setLoadingPercent, deleteReportPreset, getVirtualDevices })(HistoricalReportVx);
