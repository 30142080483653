import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import Button from '../../components/UI/Button/Button';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import EditIcon from '../../../assets/icons/edit-gray.svg';
import ViewIcon from '../../../assets/icons/Report/view.svg';
import DeleteIcon from '../../../assets/icons/delete-red-bin.svg';
import Pagination from '../../components/UI/Pagination/Pagination';
import DocumentEditor from '../../components/Documents/DocumentEditor';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import LoadingWideCard from '../../components/UI/LoadingSkeleton/LoadingWideCard/LoadingWideCard';

import { DELETE_DOCUMENT_SUCCESS, GET_DOCUMENTS_SUCCESS, UPDATE_DOCUMENT_SUCCESS, CREATE_DOCUMENT_SUCCESS } from '../../../state/ducks/Document/types';
import { getDocuments, createDocument, updateDocument, deleteDocument } from '../../../state/ducks/Document/actions';

import styles from './Documents.module.scss';

class Documents extends Component {
    state = {
        searchForm: {
            text: "",
            start: null,
            end: null,
            needReload: false
        },
        documents: [],
        targetDocument: {
            uuid: '',
            title: 'New Document',
            content: '',
        },
        isNew: true,
        isEditorOpen: false,
        currentPage: 0,
        rowsPerPage: 50,
        isDeleteModalOpened: false
    }

    componentDidMount = () => {
        if (this.props.orgs) {
            this.initSearch();
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.type !== this.props.type) {
            if (this.props.type === GET_DOCUMENTS_SUCCESS) {
                this.filterDocuments(this.props.documents);
            }
            if (this.props.type === CREATE_DOCUMENT_SUCCESS || this.props.type === UPDATE_DOCUMENT_SUCCESS || this.props.type === DELETE_DOCUMENT_SUCCESS) {
                this.props.getDocuments(this.props.currentOrg, this.state.searchForm.start, this.state.searchForm.end);
            }
        } else if (this.props.orgs && !_.isEqual(this.props.orgs, prevProps.orgs)) {
            this.initSearch();
        } else if (this.props.currentOrg !== prevProps.currentOrg && this.props.currentOrg) {
            this.initSearch();
        }
    }

    initSearch = () => {
        let searchForm = {
            text: "",
            end: new Date(),
            start: this.state.searchForm && this.state.searchForm.start ? this.state.searchForm.start : new Date(this.props.orgs.find(org => org.id === this.props.currentOrg).created_date),
            needReload: false
        };
        this.props.getDocuments(this.props.currentOrg, searchForm.start, searchForm.end);
        this.setState({ searchForm });
    }

    openEditor = (doc) => {
        if (!doc) {
            this.setState({
                isEditorOpen: true, targetDocument: {
                    uuid: '',
                    title: 'New Document',
                    content: '',
                }, isNew: true
            });
        } else {
            this.setState({
                isEditorOpen: true, targetDocument: {
                    uuid: doc.uuid,
                    title: doc.title,
                    content: doc.content,
                }, isNew: false
            });
        }
    }

    onSearch = () => {
        const { searchForm } = this.state;
        if (searchForm.needReload) {
            this.props.getDocuments(this.props.currentOrg, searchForm.start, searchForm.end);
        } else {
            this.filterDocuments(this.props.documents);
        }
    }

    filterDocuments = (documents) => {
        const { searchForm } = this.state;
        let searchText = searchForm.text.toLowerCase();
        let filteredDocuments = searchText ? documents.filter(doc => {
            return doc.title.toLowerCase().includes(searchText) ||
                doc.content.toLowerCase().includes(searchText) ||
                doc.author?.first_name.toLowerCase().includes(searchText) ||
                doc.author?.last_name.toLowerCase().includes(searchText) ||
                doc.event?.message.toLowerCase().includes(searchText) ||
                (doc.event?.level === 0 && "breakdown".includes(searchText)) ||
                (doc.event?.level === 1 && "critical".includes(searchText)) ||
                (doc.event?.level === 2 && "warning".includes(searchText)) ||
                (doc.event?.level === 3 && "alert".includes(searchText)) ||
                (doc.event?.level === 4 && "info".includes(searchText)) ||
                doc.ref.toLowerCase().includes(searchText) ||
                doc.type.name.toLowerCase().includes(searchText);
        }) : documents;
        this.setState({ filteredDocuments });
    }

    onSearchTextChange = (v) => {
        const { searchForm } = this.state;
        searchForm.text = v;
        this.setState({ searchForm });
    }

    onTimeChange = (name, d) => {
        const { searchForm } = this.state;
        let isStart = name.startsWith('start');

        let current = isStart ? searchForm.start : searchForm.end;
        if (name.endsWith('-date')) {
            let ds = d.split('-');
            current = new Date(+ds[0], +ds[1] - 1, +ds[2], current.getHours(), current.getMinutes());
        } else {
            let ds = d.split(':');
            current = new Date(current.getFullYear(), current.getMonth(), current.getDate(), +ds[0], +ds[1], isStart ? 0 : 59);
        }

        if (isStart) {
            if (current.getTime() === searchForm.start.getTime()) {
                return;
            }
            searchForm.start = current;
        } else {
            if (current.getTime() === searchForm.end.getTime()) {
                return;
            }
            searchForm.end = current;
        }
        searchForm.needReload = true;
        this.setState({ searchForm });
    }

    onChangePageHandler = (event, page) => {
        event.preventDefault();
        this.setState({ currentPage: page });
    }

    onChangeRowsPerPageHandler = (event) => {
        event.preventDefault();

        let amount = +event.target.value;

        const { filteredDocuments } = this.state;
        let currentPage = this.state.currentPage;
        if (currentPage > 0 && (currentPage * amount) > filteredDocuments?.length) {
            currentPage = 0;
        }

        this.setState({ rowsPerPage: amount, currentPage });
    }

    onDocumentContentChange = (content) => {
        const { targetDocument } = this.state;
        targetDocument.content = content;
        this.setState({ targetDocument });
    }

    onDocumentTitleChange = (title) => {
        const { targetDocument } = this.state;
        targetDocument.title = title;
        this.setState({ targetDocument });
    }

    onSaveHandler = () => {
        const { targetDocument, isNew } = this.state;
        const { createDocument, updateDocument, currentOrg } = this.props;

        if (isNew) {
            createDocument(currentOrg, 1, targetDocument.title, targetDocument.content, '', '', {}, {});
        } else {
            updateDocument(targetDocument.uuid, targetDocument.title, targetDocument.content, '', '', {}, {});
        }

        this.setState({ isEditorOpen: false });
    }

    onDeleteClick = (uuid) => {
        const { targetDocument } = this.state;
        targetDocument.uuid = uuid;
        this.setState({ isDeleteModalOpened: true, targetDocument });
    }

    onDeleteHandler = () => {
        const { targetDocument } = this.state;
        const { deleteDocument } = this.props;
        deleteDocument(targetDocument.uuid);
        this.setState({ isDeleteModalOpened: false });
    }

    renderDateInput = (d, label, onChange) => {
        if (!d) d = new Date();
        let dateStr = d.getFullYear() + "-" + (d.getMonth() < 9 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
        let timeStr = (d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) + ":" + (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes());
        return (
            <div className={styles.SearchDate}>
                {label === 'start' ? 'from' : 'to'}
                <input className={styles.SelectBox} type="date" onChange={e => onChange(label + '-date', e.target.value)} value={dateStr} />
                <input className={styles.SelectBox} type="time" onChange={e => onChange(label + '-time', e.target.value)} value={timeStr} />
            </div>
        );
    }

    renderItems = (isAdmin) => {
        const { filteredDocuments, currentPage, rowsPerPage } = this.state;
        let docs = filteredDocuments.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

        return (
            <table className={styles.DocumentsTable}>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Title</th>
                        <th>Author</th>
                        {/* <th>Created</th>
                        <th>Updated By</th> */}
                        <th>Updated</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docs.map(d => {
                            return (
                                <tr key={'doc-' + d.uuid}>
                                    <td>{d.type?.name}</td>
                                    <td>{d.title}</td>

                                    <td>{d.author?.first_name} {d.author?.last_name}</td>
                                    {/* <td>{moment(d.created_date).format("DD/MM/YYYY HH:mm:ss")}</td>
                                    <td>{d.last_updated_by?.first_name} {d.last_updated_by?.last_name}</td> */}
                                    <td>{moment(d.last_updated).format("DD/MM/YYYY HH:mm:ss")}</td>
                                    <td style={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
                                        {
                                            isAdmin ? <>
                                                <img src={EditIcon} alt="Edit" style={{ marginRight: 6 }} onClick={e => this.openEditor(d)} />
                                                <img src={DeleteIcon} alt="Delete" onClick={e => this.onDeleteClick(d.uuid)} />
                                            </> : <img src={ViewIcon} alt="View" onClick={e => this.openEditor(d)} />
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        );
    }

    render() {
        const { searchForm, filteredDocuments, currentPage, rowsPerPage, isEditorOpen, targetDocument, isDeleteModalOpened } = this.state;
        const { loggedInUser, currentOrg, loading } = this.props;
        const isAdmin = (loggedInUser?.admin_of_org && loggedInUser?.admin_of_org.includes(+currentOrg)) || loggedInUser?.is_superuser;

        return (<div className={styles.DocumentsContainer}>
            <div className={styles.Documents}>
                <div className={styles.TopSection}>
                    <div className={styles.MenuWrapper}>
                        <div className={styles.Menu}><span className={styles.MenuTitle}>Documents</span></div>
                    </div>

                    {
                        isAdmin &&
                        <div className={styles.AddButtonWrapper}>
                            <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Documents" noMargin click={e => this.openEditor()} />
                        </div>
                    }
                </div>
                <div className={styles.ContentSection}>
                    <div className={styles.SearchSection}>
                        <input className={styles.SearchInput} type="text" placeholder="Search" value={searchForm.text} onChange={e => this.onSearchTextChange(e.target.value)} />
                        {this.renderDateInput(searchForm.start, 'start', this.onTimeChange)}
                        {this.renderDateInput(searchForm.end, 'end', this.onTimeChange)}
                        <Button type="button" color="green" name="Search" noMargin click={this.onSearch} />
                    </div>
                    <div className={styles.BodySection}>
                        {loading ? <LoadingWideCard /> : <>
                            {
                                filteredDocuments?.length ? this.renderItems(isAdmin) :
                                    <div className={styles.EmptyDocument}>
                                        <p>No documents found.</p>
                                    </div>
                            }
                            <Pagination
                                total={filteredDocuments?.length}
                                currentPage={currentPage}
                                rowsPerPage={rowsPerPage}
                                onChangePage={this.onChangePageHandler}
                                onChangeRowsPerPage={this.onChangeRowsPerPageHandler}
                            />
                        </>}
                    </div>
                </div>
            </div>
            <DocumentEditor
                isAdmin={isAdmin}
                isOpen={isEditorOpen}
                isNew={targetDocument.isNew}
                target={targetDocument}
                onTitleChange={this.onDocumentTitleChange}
                onContentChange={this.onDocumentContentChange}
                onClose={() => this.setState({ isEditorOpen: false })}
                onSave={this.onSaveHandler} />
            <DeleteModal
                title="document"
                overrideTitle={<>Are you sure to <span style={{ color: 'red' }}>DELETE</span> this?</>}
                overrideDescription1="This process will delete the document permanently"
                overrideDescription2="which is irreversible."
                open={isDeleteModalOpened}
                modalClosed={e => this.setState({ isDeleteModalOpened: false })}
                confirmDelete={this.onDeleteHandler}
            />
        </div>);
    }
}

const mapStateToProps = (state) => {
    const { currentOrg, orgs } = state.org;
    const { loggedInUser } = state.auth;
    const { type, documents, loading, updatedDocument } = state.document;
    return { currentOrg, orgs, loggedInUser, type, documents, loading, updatedDocument };
};

export default connect(mapStateToProps, { getDocuments, createDocument, updateDocument, deleteDocument })(Documents);