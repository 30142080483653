import React from 'react';

import styles from './Button.module.scss';
import ButtonSpinner from '../Spinner/ButtonSpinner';

const button = (props) => {
  let buttonStyles = [styles.Button];
  if (props.color.toLowerCase() === 'primary') {
    buttonStyles.push(styles.Primary);
  } else if (props.color.toLowerCase() === 'green') {
    buttonStyles.push(styles.Green);
  } else if (props.color.toLowerCase() === 'red') {
    buttonStyles.push(styles.Red);
  } else if (props.color.toLowerCase() === 'borderblue') {
    buttonStyles.push(styles.BorderBlue);
  } else if (props.color.toLowerCase() === 'borderred') {
    buttonStyles.push(styles.BorderRed);
  } else if (props.color.toLowerCase() === 'bordergreen') {
    buttonStyles.push(styles.BorderGreen);
  } else if (props.color.toLowerCase() === 'white') {
    buttonStyles.push(styles.White);
  } else if (props.color.toLowerCase() === 'blue') {
    buttonStyles.push(styles.Blue);
  } else if (props.color.toLowerCase() === 'borderwhite') {
    buttonStyles.push(styles.BorderWhite);
  } else if (props.color.toLowerCase() === 'yellow') {
    buttonStyles.push(styles.Yellow);
  } else if (props.color.toLowerCase() === 'orange') {
    buttonStyles.push(styles.Orange);
  } else if (props.color.toLowerCase() === 'yellow-border') {
    buttonStyles.push(styles.YellowBorder);
  } else if (props.color.toLowerCase() === 'bordergray') {
    buttonStyles.push(styles.BorderGray);
  } else if (props.color.toLowerCase() === 'gray') {
    buttonStyles.push(styles.Gray);
  }

  if (props.disabled || props.loading) {
    buttonStyles.push(styles.Disabled);
  }

  if (props.noMargin) {
    buttonStyles.push(styles.NoMargin);
  }

  if (props.active) {
    buttonStyles.push(styles.Active);
  }

  if (props.className) {
    buttonStyles.concat(props.className)
  }

  return (
    <button className={buttonStyles.join(' ')} type={props.type} onClick={props.click} disabled={props.disabled} style={props.overideButtonStyles}>
      {
        props.icon && !props.loading ?
          <img src={props.icon} alt={props.icon} className={styles.IconButton} />
          : null
      }
      {
        props.loading ?
          <div className={styles.LoadingWrapper}><ButtonSpinner /></div>
          : null
      }
      {props.name} {props.children}
    </button>
  );
}

export default button;