import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './FormulaList.module.scss';

class FormulaList extends Component {
  state = {
    formulaList: null
  }

  componentDidMount() {
    if (this.props.formulas) {
      this.setState({ formulaList: this.props.formulas });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.formulas !== prevProps.formulas) {
      this.setState({ formulaList: this.props.formulas });
    }
  }

  render() {
    return (
      this.state.formulaList ? this.state.formulaList.map((fml, i) => {
        let fmlWrapper = [styles.AddedWrapper];
        if (this.props.selectedFormulaIndex === i) {
          fmlWrapper.push(styles.Selected);
        }

        let past = false;
        if (fml.type === 1) {
          if (moment(fml.end).valueOf() < moment().valueOf())  past = true;
        } else if (moment(fml.start).valueOf() < moment().valueOf()) {
          past = true;
        }

        return (
          <div key={fml.id} className={fmlWrapper.join(' ')} onClick={() => this.props.editFormula(i)}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', opacity: past ? 0.6 : 1 }}>
              <div className={styles.Name}>{fml.name}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={styles.TimeText}>{moment(fml.start).format("DD/MM/YYYY HH:mm")}</div>
            </div>
          </div>
        )
      }) : null
    )
  }

}
const mapStateToProps = (state) => {
  // const { } = state;
  return {};
};

export default connect(mapStateToProps, {})(FormulaList);