import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import styles from './BatchViewBox.module.scss';

import { getBatchProgress } from '../../../../../state/ducks/AdvanceProductionBatch/actions';

class BatchViewBox extends Component {
    state = {
        runningPo: 0,
        batchProgress: {}
    };

    componentDidMount() {
        const { uuid, plans } = this.props.batch;
        this.props.getBatchProgress(uuid, { batch: this.props.batch });

        let allTotal = 0;
        let allTarget = _.reduce(plans, (memo, b) => { return memo + b.target; }, 0);

        if (plans) {
            plans.forEach(plan => {
                allTotal += _.reduce(plan.actuals, (memo, b) => { return memo + b.total; }, 0); // only total..
            });
        }

        this.setState({ runningPo: this.findRunningPo(plans), batchProgress: allTotal * 100 / allTarget });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.batchProgress !== this.props.batchProgress && this.props.batchProgress && this.isThisTheRightData(this.props.batchProgress, this.props.batch)) {
            const batchProgress = { ...this.state.batchProgress };
            const { progress, properties } = this.props.batchProgress;
            batchProgress[properties.batch.uuid] = progress;
            this.setState({ batchProgress });
        }
    }

    isThisTheRightData = (data, comp) => {
        if (data.properties.batch.uuid === comp.uuid) {
            return true;
        }
        return false;
    }

    findRunningPo = (plans) => {
        let running = 0;
        if (!plans) return '';

        plans.forEach(plan => {
            let foundRunning;
            if (plan.actuals) {
                foundRunning = plan.actuals.find(ac => ac.end === '0001-01-01T00:00:00Z');
            }
            if (foundRunning) running++;
        });
        return running ? '(' + running + ' running)' : '';
    }

    render() {
        const { batch_number, plans, start, end } = this.props.batch;
        const over = this.state.batchProgress[this.props.batch.uuid] && this.state.batchProgress[this.props.batch.uuid] > 80;

        return (
            <div className={`${styles.BatchBox}`}

                style={{ background: true ? `linear-gradient(180deg, ${!this.state.runningPo ? '#9ea0a555' : over ? '#34aa44' : '#e6492d'} -10%, #222226 70%)` : '#222226' }}
            >
                <div className={styles.BatchName}>{batch_number}</div>
                <div className={styles.ProgressWrapper}>
                    {
                        this.state.runningPo && this.state.batchProgress[this.props.batch.uuid] ?
                            <React.Fragment>
                                <div className={styles.Percentage}>{this.state.batchProgress[this.props.batch.uuid].toFixed(2)}%</div>
                                <div className={styles.PercentageBarWrapper}>
                                    <div style={{
                                        width: this.state.batchProgress[this.props.batch.uuid] ? this.state.batchProgress[this.props.batch.uuid] >= 100 ? '100%' : this.state.batchProgress[this.props.batch.uuid].toFixed(2) + '%' : '0'
                                    }} className={`${styles.PercentageBarValue} ${this.state.batchProgress[this.props.batch.uuid] >= 80 ? styles.Green : styles.Red}`} />
                                </div>
                            </React.Fragment>
                            : <div className={styles.Percentage}>---</div>
                    }
                </div>
                <div className={styles.AmountPO}>{plans ? plans.length : 0} POs in this batch. {this.state.runningPo}</div>
                <div className={styles.Divider} />
                <div className={styles.DateWrapper}>
                    <div className={styles.GreenDot} />
                    <div className={styles.StartText}>START</div>
                    <div className={styles.StartTime}>{moment(start).format("DD/MM/YY HH:mm")}</div>
                </div>
                <div className={styles.DateWrapper}>
                    <div className={styles.RedDot} />
                    <div className={styles.StartText} style={{ marginRight: 30 }}>END</div>
                    <div className={styles.StartTime}>{moment(end).format("DD/MM/YY HH:mm")}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { batchProgress } = state.advanceProductionBatch;

    return { batchProgress };
};

export default connect(mapStateToProps, { getBatchProgress })(BatchViewBox);
