export const SEARCH_ADVANCE_PRODUCTION_PLANS_START = 'SEARCH_ADVANCE_PRODUCTION_PLANS_START';
export const SEARCH_ADVANCE_PRODUCTION_PLANS = 'SEARCH_ADVANCE_PRODUCTION_PLANS';
export const SEARCH_ADVANCE_PRODUCTION_PLANS_SUCCESS = 'SEARCH_ADVANCE_PRODUCTION_PLANS_SUCCESS';
export const SEARCH_ADVANCE_PRODUCTION_PLANS_FAIL = 'SEARCH_ADVANCE_PRODUCTION_PLANS_FAIL';

export const GET_ADVANCE_PRODUCTION_PLANS_START = 'GET_ADVANCE_PRODUCTION_PLANS_START';
export const GET_ADVANCE_PRODUCTION_PLANS = 'GET_ADVANCE_PRODUCTION_PLANS';
export const GET_ADVANCE_PRODUCTION_PLANS_SUCCESS = 'GET_ADVANCE_PRODUCTION_PLANS_SUCCESS';
export const GET_ADVANCE_PRODUCTION_PLANS_FAIL = 'GET_ADVANCE_PRODUCTION_PLANS_FAIL';

export const ADD_ADVANCE_PRODUCTION_PLAN_START = 'ADD_ADVANCE_PRODUCTION_PLAN_START';
export const ADD_ADVANCE_PRODUCTION_PLAN = 'ADD_ADVANCE_PRODUCTION_PLAN';
export const ADD_ADVANCE_PRODUCTION_PLAN_SUCCESS = 'ADD_ADVANCE_PRODUCTION_PLAN_SUCCESS';
export const ADD_ADVANCE_PRODUCTION_PLAN_FAIL = 'ADD_ADVANCE_PRODUCTION_PLAN_FAIL';

export const UPLOAD_ADVANCE_PRODUCTION_PLANS_START = 'UPLOAD_ADVANCE_PRODUCTION_PLANS_START';
export const UPLOAD_ADVANCE_PRODUCTION_PLANS = 'UPLOAD_ADVANCE_PRODUCTION_PLANS';
export const UPLOAD_ADVANCE_PRODUCTION_PLANS_SUCCESS = 'UPLOAD_ADVANCE_PRODUCTION_PLANS_SUCCESS';
export const UPLOAD_ADVANCE_PRODUCTION_PLANS_FAIL = 'UPLOAD_ADVANCE_PRODUCTION_PLANS_FAIL';

export const EDIT_ADVANCE_PRODUCTION_PLAN_START = 'EDIT_ADVANCE_PRODUCTION_PLAN_START';
export const EDIT_ADVANCE_PRODUCTION_PLAN = 'EDIT_ADVANCE_PRODUCTION_PLAN';
export const EDIT_ADVANCE_PRODUCTION_PLAN_SUCCESS = 'EDIT_ADVANCE_PRODUCTION_PLAN_SUCCESS';
export const EDIT_ADVANCE_PRODUCTION_PLAN_FAIL = 'EDIT_ADVANCE_PRODUCTION_PLAN_FAIL';

export const DELETE_ADVANCE_PRODUCTION_PLAN_START = 'DELETE_ADVANCE_PRODUCTION_PLAN_START';
export const DELETE_ADVANCE_PRODUCTION_PLAN = 'DELETE_ADVANCE_PRODUCTION_PLAN';
export const DELETE_ADVANCE_PRODUCTION_PLAN_SUCCESS = 'DELETE_ADVANCE_PRODUCTION_PLAN_SUCCESS';
export const DELETE_ADVANCE_PRODUCTION_PLAN_FAIL = 'DELETE_ADVANCE_PRODUCTION_PLAN_FAIL';

export const CLEAR_ADVANCE_PRODUCTION_PLANS = 'CLEAR_ADVANCE_PRODUCTION_PLANS';
export const CLEAR_UPLOADED_PLANS = 'CLEAR_UPLOADED_PLANS';