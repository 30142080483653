import React, { Component } from 'react';

import { updateObject, checkValidity } from '../../../../state/utils';
import styles from '../Rule.module.scss';
import Input from '../../UI/Input/Input';
import Select from '../../UI/Select/Select';
import AddIcon from '../../../../assets/icons/Rule/add-circle.svg';
import DeleteIcon from '../../../../assets/icons/Rule/delete-circle.svg';

const DEFAULT_CONDITION_FORM = {
  device_socket_id: {
    value: '-1',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  device_socket: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  device_secret: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  operation: {
    value: 'and',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  },
  condition: {
    value: '>',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  value: {
    value: 0,
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
};

const conditionOption = [
  { name: '>', id: '>' },
  { name: '>=', id: '>=' },
  { name: '<', id: '<' },
  { name: '<=', id: '<=' },
  { name: '=', id: '=' },
  { name: '!= (Not equal)', id: '!=' }
]

const operationOption = [
  { name: 'And', id: 'and' },
  { name: 'Or', id: 'or' },
]

class ConditionForm extends Component {

  state = {
    formIsValid: false,
    conditionForm: DEFAULT_CONDITION_FORM,
  }

  componentDidMount() {
    if (this.props.condition) {
      this.setupForm(this.props.condition);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.condition !== prevProps.condition) && this.props.condition) {
      this.setupForm(this.props.condition);
    }
  }

  setupForm = (form) => {
    let filterDevice;
    if (form.device_socket !== -1 && form.device_secret !== '') {
      filterDevice = this.props.deviceOptions.find(option => option.socket === form.device_socket && option.secret === form.device_secret);
    }
    let updatedForm = { ...this.state.conditionForm };
    for (let inputIdentifier in updatedForm) {
      form[inputIdentifier] = inputIdentifier === 'value' && form[inputIdentifier] === '' ? 0 : form[inputIdentifier];
      let updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] !== '' ? form[inputIdentifier] : '',
        valid: form[inputIdentifier] !== '' ? true : false,
        touched: form[inputIdentifier] !== '' ? true : false
      });
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }
    let updatedDeviceSocketId = updateObject(updatedForm['device_socket_id'], {
      value: filterDevice ? filterDevice.id : '-1',
      valid: filterDevice ? true : false,
      touched: filterDevice ? true : false,
    });
    updatedForm = updateObject(updatedForm, {
      device_socket_id: updatedDeviceSocketId
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ conditionForm: updatedForm, formIsValid });
  }

  handleChange = event => {
    const { value, name } = event.target;
    let updatedForm;
    let updatedElement = updateObject(this.state.conditionForm[name], {
      value: name === 'value' && value === '' ? 0 : value,
      valid: name === 'value' && value === '' ? true : checkValidity(value, this.state.conditionForm[name].validation),
      touched: true
    });
    if (name === 'device_socket_id') {
      let selectedSocket = this.props.deviceOptions.find(option => option.id === +value);
      let updatedDeviceSecret = updateObject(this.state.conditionForm['device_secret'], {
        value: selectedSocket.secret,
        valid: checkValidity(selectedSocket.secret, this.state.conditionForm['device_secret'].validation),
        touched: true
      });
      let updatedDeviceSocket = updateObject(this.state.conditionForm['device_socket'], {
        value: selectedSocket.socket,
        valid: checkValidity(selectedSocket.secret, this.state.conditionForm['device_socket'].validation),
        touched: true
      });

      updatedForm = updateObject(this.state.conditionForm, {
        [name]: updatedElement,
        device_secret: updatedDeviceSecret,
        device_socket: updatedDeviceSocket
      });
    } else {
      updatedForm = updateObject(this.state.conditionForm, {
        [event.target.name]: updatedElement
      });
    }

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ conditionForm: updatedForm, formIsValid });
    this.props.conditionChange(
      this.props.addIndex,
      updatedForm,
      formIsValid
    );
  }

  render() {
    const { device_socket_id, value, operation, condition } = this.state.conditionForm;
    return (
      <div className={styles.Rule}>
        <div className={styles.Flex}>
          <div className={styles.LeftTextField}>
            <div className={styles.Flex}>
              {
                this.props.firstCondition ?
                  null :
                  <div style={{ flex: 1, marginRight: 15 }}>
                    <Select
                      name="operation"
                      placeholder="Select an operation"
                      options={operationOption}
                      value={operation.value}
                      autoComplete="off"
                      overidestyleformgroup={{ marginBottom: 15 }}
                      onChange={this.handleChange}
                    />
                  </div>
              }
              <div style={{ flex: 3 }}>
                <Select
                  name="device_socket_id"
                  placeholder="Select a device"
                  options={this.props.deviceOptions}
                  value={device_socket_id.value}
                  autoComplete="off"
                  overidestyleformgroup={{ marginBottom: 15 }}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.RightTextField}>
            <div className={styles.Flex}>
              <div className={styles.LeftTextField}>
                <Select
                  name="condition"
                  placeholder="Select a condition"
                  options={conditionOption}
                  value={condition.value}
                  autoComplete="off"
                  overidestyleformgroup={{ marginBottom: 15 }}
                  onChange={this.handleChange}
                />
              </div>
              <div className={styles.RightTextField}>
                <div className={styles.Flex}>
                  <div style={{ flex: 2 }}>
                    <Input
                      name="value"
                      type="number"
                      placeholder="Value"
                      overidestyleformgroup={{ marginBottom: 15 }}
                      value={value.value}
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </div>
                  {
                    this.props.addNewCondition ?
                      this.props.addIndex === 0 ?
                        <div className={styles.AddButton}>
                          <img className={styles.IconButton} src={AddIcon} alt="Add Condition Icon" onClick={this.props.addNewCondition} />
                        </div> :
                        <div className={styles.AddButton}>
                          <img className={styles.IconButton} src={DeleteIcon} alt="Delete Condition Icon" onClick={this.props.removeCondition} />
                          <img className={styles.IconButton} src={AddIcon} alt="Add Condition Icon" onClick={this.props.addNewCondition} />
                        </div> :
                      <div className={styles.AddButton}>
                        <img className={styles.IconButton} src={DeleteIcon} alt="Delete Condition Icon" onClick={this.props.removeCondition} />
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ConditionForm;
