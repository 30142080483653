import axios from '../../../configs/axios-orders';
import { takeEvery, put, call, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as actions from './actions';
import * as orgActions from '../Organization/actions';
import { SIGNIN, SIGNUP, SIGNOUT, RENEW_TOKEN, VERIFIED_USER, RESEND_VERIFY, FORGET_PASSWORD, RESET_PASSWORD, GET_LOGGEDIN_USER, EDIT_USER } from './types';

export function* watchAuth() {
  yield takeEvery(SIGNIN, signinSaga);
  yield takeEvery(SIGNUP, signupSaga);
  yield takeEvery(SIGNOUT, signoutSaga);
  yield takeEvery(RENEW_TOKEN, renewTokenSaga);
  yield takeEvery(VERIFIED_USER, verifiedUserSaga);
  yield takeEvery(RESEND_VERIFY, resendVerifySaga);
  yield takeEvery(FORGET_PASSWORD, fogetPasswordSaga);
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
  yield takeEvery(GET_LOGGEDIN_USER, getLoggedInUserSaga);
  yield takeEvery(EDIT_USER, editUserSaga);
}

export function* renewTokenSaga() {
  const api = 'v1/renewtoken';
  try {
    const response = yield axios.put(api);
    console.log("RENEW TOKEN SUCCESS");
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
    yield put(actions.renewTokenSuccess(response.data));
  } catch (err) {
    console.log("RENEW TOKEN FAILED", err);
    yield put(push('/auth'));
  }
}

export function* signinSaga(params) {
  yield put(actions.signinStart());
  let authData = "username=" + params.username;
  authData += "&password=" + params.password;
  const api = 'v1/gettoken';
  try {
    const response = yield axios.post(api, authData);
    console.log("SIGN IN SUCCESS");
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
    yield localStorage.setItem('owlJwtToken', response.data.token);
    yield localStorage.setItem('owlUser', JSON.stringify(response.data.user));
    yield put(actions.signinSuccess(response.data));

    if (!response.data.user.is_superuser && (!response.data.user.teams || response.data.user.teams.length === 0)) {
      yield put(push('/create-organization'));
    } else {
      if (!localStorage.getItem('owlCurrentOrg')) {
        const getOrgApi = 'v1/org';
        const response = yield axios.get(getOrgApi);
        if (response && response.data) {
          const orgId = response.data.organizations[0].id;
          yield localStorage.setItem('owlCurrentOrg', orgId);
          yield put(orgActions.setCurrentOrg(orgId));
          yield put(orgActions.getOrgsSuccess(response.data));
        }
      }
      yield put(push('/pages'));
      while (true) {
        if (!localStorage.getItem('owlJwtToken')) break;
        yield put(actions.renewToken());
        yield delay(900 * 1000);
      }
    }
  } catch (err) {
    console.log("SIGN IN FAILED", err);
    yield call([localStorage, 'removeItem'], 'owlJwtToken');
    yield call([localStorage, 'removeItem'], 'owlUser');
    yield call([localStorage, 'removeItem'], 'owlCurrentOrg');
    yield put(actions.signinFail(err.response ? err.response.data : { error: 'Network Error' }));
  }
}

export function* signupSaga(params) {
  yield put(actions.signupStart());

  let postData = "email=" + params.email;
  postData += "&username=" + params.username;
  postData += "&password=" + params.password;
  postData += "&first_name=" + params.first_name;
  postData += "&last_name=" + params.last_name;
  postData += "&profile_pic=" + encodeURIComponent(params.profile_pic);

  const api = 'v1/userex/register';

  try {
    const response = yield axios.post(api, postData);
    console.log("SIGN UP SUCCESS");
    yield put(actions.signupSuccess());
    const email = response.data.user.email;
    yield put(push('/verify-email/' + email));
  } catch (err) {
    console.log("SIGN UP FAILED", err);
    yield put(actions.signupFail(err.response.data));
  }
}

export function* verifiedUserSaga(params) {
  yield put(actions.verifiedUserStart());
  let api = 'v1/userex/verify';
  api += "/" + params.token;

  try {
    const response = yield axios.get(api);
    // console.log("VERIFIED SUCCESS", response.data);
    yield put(actions.verifiedUserSuccess(response.data));
  } catch (err) {
    console.log("VERIFIED USER FAILED", err);
    yield put(actions.verifiedUserFail(err.response.data));
  }
}

export function* resendVerifySaga(params) {
  yield put(actions.resendVerifyStart());
  const api = 'v1/userex/resendverify';
  const email = params.email;
  let postData = "email=" + email;

  try {
    const response = yield axios.post(api, postData);
    console.log("RESEND VERIFY SUCCESS", response.data);
    yield put(actions.resendVerifySuccess());
    yield put(push('/verify-email/' + email));
  } catch (err) {
    console.log("RESEND VERIFY FAIL", err);
    yield put(actions.resendVerifyFail(err.response.data));
  }
}

export function* fogetPasswordSaga(params) {
  yield put(actions.forgetPasswordStart());
  const api = 'v1/userex/resetpassword';
  let postData = "email=" + params.email;

  try {
    const response = yield axios.post(api, postData);
    // console.log("FORGET PASS SUCCESS", response.data);
    yield put(actions.forgetPasswordSuccess(response.data));
  } catch (err) {
    console.log("FORGET PASS FAIL", err);
    yield put(actions.forgetPasswordFail(err.response.data));
  }
}

export function* resetPasswordSaga(params) {
  yield put(actions.resetPasswordStart());
  const api = 'v1/userex/resetpassword';
  let putData = "token=" + params.token;
  putData += "&newpassword=" + params.newpassword;

  try {
    yield axios.put(api, putData);
    // const response = yield axios.put(api, putData);
    // console.log("RESET PASS SUCCESS", response.data);
    yield put(actions.resetPasswordSuccess());
    yield put(push('/verify/reset-password-success'));
  } catch (err) {
    console.log("RESET PASS FAIL", err);
    yield put(actions.resetPasswordFail(err.response.data));
  }
}

export function* signoutSaga() {
  yield call([localStorage, 'removeItem'], 'owlJwtToken');
  yield call([localStorage, 'removeItem'], 'owlUser');
  yield call([localStorage, 'removeItem'], 'owlCurrentOrg');
  console.log("SIGN OUT SUCCESS");
  yield put(actions.signoutSuccess());
  yield put(push('/auth'));
}

export function* getLoggedInUserSaga(params) {
  yield put(actions.getLoggedInUserStart());
  let api = 'v1/user/';
  api += params.id;
  try {
    const response = yield axios.get(api);
    yield localStorage.setItem('owlUser', JSON.stringify(response.data.user));
    // console.log("GET LOGGED IN USER SUCCESS", response.data);
    yield put(actions.getLoggedInUserSuccess(response.data));
  } catch (err) {
    console.log("GET LOGGED IN USER FAILED", err.response.data);
    yield put(actions.getLoggedInUserFail(err.response.data));
  }
}

export function* editUserSaga(params) {
  yield put(actions.editUserStart(params.edit_type));

  let api = 'v1/user/';
  api += params.id;

  let putData = 'first_name=' + params.first_name;
  putData += '&last_name=' + params.last_name;
  putData += '&email=' + params.email;
  putData += params.tel ? '&tel=' + params.tel : '';
  putData += params.job_title ? '&job_title=' + params.job_title : '';
  putData += params.profile_pic ? '&profile_pic=' + encodeURIComponent(params.profile_pic) : '';
  putData += params.newpassword ? '&password=' + params.newpassword : '';

  try {
    if (params.newpassword) {
      let authData = "username=" + params.username;
      authData += "&password=" + params.password;
      const getTokenApi = 'v1/gettoken';
      yield axios.post(getTokenApi, authData);
    }
    const response = yield axios.put(api, putData);
    // console.log("EDIT USER SUCCESS", response.data);
    yield put(actions.editUserSuccess(response.data));
  } catch (err) {
    console.log("EDIT USER FAIL", err.response.data);
    yield put(actions.editUserFail(err.response.data));
  }
}


