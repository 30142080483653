import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';

import { updateObject } from '../../../../../state/utils';
import styles from '../WidgetForm.module.scss';
import Input from '../../../UI/Input/Input';
import DeviceEquationArea from '../../../UI/DeviceEquationArea/DeviceEquationArea';
import AddIcon from '../../../../../assets/icons/Rule/add-circle.svg';
import LeftIcon from '../../../../../assets/icons/Widgets/click-left.svg';
import RightIcon from '../../../../../assets/icons/Widgets/click-right.svg';
import RangeForm from './RangeForm';

const COLOR_OPTIONS = [
  { label: 'Red', id: '#ff5454', color: '#ff5454' },
  { label: 'Orange', id: '#ffa000', color: '#ffa000' },
  { label: 'Yellow', id: '#facf55', color: '#facf55' },
  { label: 'Green', id: '#34aa44', color: '#34aa44' },
  { label: 'Blue', id: '#1665d8', color: '#1665d8' },
];

const DEFAULT_GAUGE_PROPS = {
  min: 0,
  max: 100,
  formula: '',
  customUnit: '',
  isValid: false
};

const colourStyles = {
  singleValue: (styles) => ({
    ...styles,
    color: "white"
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
  }),
  input: base => ({
    ...base,
    color: "white"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
};


class GaugeForm extends Component {
  state = {
    gaugeProps: DEFAULT_GAUGE_PROPS,
    thresholdRanges: [],
    mode: 'formula',
    selectedSensor: null
  };

  componentDidMount() {
    if (this.props.editingWidget) {
      this.settingUp(this.props.editingWidget);
    } else {
      this.setState({ gaugeProps: DEFAULT_GAUGE_PROPS, thresholdRanges: [] });
    }
  }

  settingUp = (widget) => {
    let selectedSensor = null, mode = 'formula';
    if (widget.secret && widget.socket) {
      mode = 'select';
      selectedSensor = this.props.deviceOptions.find(device => device.secret === widget.secret && device.socket === widget.socket);
    }

    let gaugeProps = updateObject(this.props.editingWidget.gaugeProps, {
      isValid: true
    });
    const thresholdRanges = this.props.editingWidget.gaugeProps && this.props.editingWidget.gaugeProps.thresholdRanges ? this.props.editingWidget.gaugeProps.thresholdRanges : [];
    const updatedGaugeProps = updateObject(gaugeProps, {
      thresholdRanges,
      mode,
      selectedSensor
    });
    this.props.handleGaugePropsChange(updatedGaugeProps);
    this.setState({ gaugeProps, thresholdRanges, mode, selectedSensor });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.editingWidget !== this.props.editingWidget && this.props.editingWidget) {
      this.settingUp(this.props.editingWidget);
    }
  }

  checkingThresholdRangesValid = (thresholdRanges) => {
    let isValid = true;
    const updatedThresholdRanges = thresholdRanges ? thresholdRanges : { ...this.state.thresholdRanges };
    if (Object.keys(updatedThresholdRanges).length) {
      for (let inputIdentifier in updatedThresholdRanges) {
        isValid = updatedThresholdRanges[inputIdentifier].isValid && isValid;
      }
    }
    return isValid
  }

  onChangeHandler = (name, value) => {
    let isValid = this.checkingThresholdRangesValid();
    if (this.state.mode === 'formula') {
      isValid = isValid && (name === 'formula' ? value !== '' : this.state.gaugeProps.formula !== '');
    }
    const updatedGaugeProps = updateObject(this.state.gaugeProps, {
      [name]: value,
      isValid,
      thresholdRanges: this.state.thresholdRanges,
      mode: this.state.mode,
      selectedSensor: this.state.selectedSensor
    });

    this.props.handleGaugePropsChange(updatedGaugeProps);
    this.setState({ gaugeProps: updatedGaugeProps });
  }

  handleGaugeChange = event => {
    let isValid = this.checkingThresholdRangesValid();
    const updatedGaugeProps = updateObject(this.state.gaugeProps, {
      [event.target.name]: +event.target.value,
      isValid: isValid && event.target.value !== '',
      thresholdRanges: this.state.thresholdRanges,
      mode: this.state.mode,
      selectedSensor: this.state.selectedSensor
    });
    this.props.handleGaugePropsChange(updatedGaugeProps);
    this.setState({ gaugeProps: updatedGaugeProps });
  }

  addNewThresholdRange = (firstSet) => {
    let thresholdRanges = [];
    if (!firstSet) {
      thresholdRanges = [...this.state.thresholdRanges];
    }
    let defaultColorRange = { isValid: false, start: 0, stop: 100, color: null };
    thresholdRanges.push(defaultColorRange);
    this.setState({ thresholdRanges });

    const updatedGaugeProps = updateObject(this.state.gaugeProps, {
      isValid: false,
      thresholdRanges,
      mode: this.state.mode,
      selectedSensor: this.state.selectedSensor
    });
    this.props.handleGaugePropsChange(updatedGaugeProps);
  }

  handleThresholdChange = (index, colorRangeForm, selectedColor, valid) => {
    let updatedForm = [...this.state.thresholdRanges];
    updatedForm[index] = {
      isValid: valid,
      start: +colorRangeForm.start.value,
      stop: +colorRangeForm.stop.value,
      color: selectedColor
    };
    this.setState({ thresholdRanges: updatedForm });

    let isValid = true && this.checkingThresholdRangesValid(updatedForm);
    const updatedGaugeProps = updateObject(this.state.gaugeProps, {
      isValid: isValid && this.state.gaugeProps.isValid,
      thresholdRanges: updatedForm,
      mode: this.state.mode,
      selectedSensor: this.state.selectedSensor
    });
    this.props.handleGaugePropsChange(updatedGaugeProps);
  }

  removeThresholdRange = (index) => {
    let thresholdRanges = [...this.state.thresholdRanges];
    thresholdRanges.splice(index, 1);
    this.setState({ thresholdRanges });

    let isValid = true && this.checkingThresholdRangesValid();
    const updatedGaugeProps = updateObject(this.state.gaugeProps, {
      isValid: isValid && this.state.gaugeProps.isValid,
      thresholdRanges,
      mode: this.state.mode,
      selectedSensor: this.state.selectedSensor
    });
    this.props.handleGaugePropsChange(updatedGaugeProps);
  }

  changeModeSelectSensor = () => {
    let mode = '', isValid = true;
    if (this.state.mode === 'formula') {
      mode = 'select';
      isValid = this.state.selectedSensor ? true : false;
    } else {
      mode = 'formula';
      isValid = (this.state.gaugeProps.formula && this.state.gaugeProps.formula !== '') ? true : false;
    }
    const updatedGaugeProps = updateObject(this.state.gaugeProps, {
      isValid,
      thresholdRanges: this.state.thresholdRanges,
      mode,
      selectedSensor: this.state.selectedSensor
    });
    this.props.handleGaugePropsChange(updatedGaugeProps);
    this.setState({ mode });
  }

  onSelectSensorChange = (sensor) => {
    let isValid = this.checkingThresholdRangesValid();
    const updatedGaugeProps = updateObject(this.state.gaugeProps, {
      isValid,
      thresholdRanges: this.state.thresholdRanges,
      mode: this.state.mode,
      selectedSensor: sensor
    });
    this.props.handleGaugePropsChange(updatedGaugeProps);
    this.setState({ selectedSensor: sensor });
  }

  render() {
    const { min, max, formula, customUnit } = this.state.gaugeProps;

    return (
      <React.Fragment>
        <div className={styles.FormGroup}>
          <div style={{ display: 'flex' }}>
            <label className={styles.Title} style={{ flex: 1 }}>{this.state.mode === 'formula' ? 'FORMULA' : 'DEVICE'} *</label>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <img src={LeftIcon} alt="left" style={{ cursor: 'pointer' }} onClick={this.changeModeSelectSensor} />
              <div className={styles.SensorWayLabel}>{this.state.mode === 'formula' ? 'Text' : 'Select'}</div>
              <img src={RightIcon} alt="Right" style={{ cursor: 'pointer' }} onClick={this.changeModeSelectSensor} />
            </div>
          </div>
          {
            this.state.mode === 'formula'
              ? <DeviceEquationArea deviceOptions={this.props.deviceOptions} value={formula} setFormula={(fml) => this.onChangeHandler('formula', fml)} />
              : <ReactSelect isSearchable options={this.props.deviceOptions} styles={colourStyles}
                placeholder="Select a device"
                value={this.state.selectedSensor}
                onChange={(sensor) => this.onSelectSensorChange(sensor)}
                getOptionValue={opt => opt.id}
              />
          }

        </div>
        <div className={styles.FormGroup}>
          <label className={styles.Title}>UNIT</label>
          <input type="text" className={styles.FormField} value={customUnit ? customUnit : ''} onChange={(event) => this.onChangeHandler('customUnit', event.target.value)} />
        </div>
        <div style={{ display: 'flex', marginTop: 15 }}>
          <Input
            label="min"
            name="min"
            type="number"
            value={min}
            placeholder="Default is 0"
            autoComplete="off"
            overidestyleformgroup={{ marginRight: 15, flex: 1 }}
            onChange={this.handleGaugeChange}
          />
          <Input
            label="maximum gauge"
            name="max"
            type="number"
            value={max}
            placeholder="Default is 100"
            autoComplete="off"
            overidestyleformgroup={{ flex: 2 }}
            onChange={this.handleGaugeChange}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 3 }}>
            <label className={styles.Title}>Range Triggers</label>
          </div>
          <div style={{ flex: 2, marginLeft: 15 }}>
            <label className={styles.Title}>Colors</label>
          </div>
        </div>
        {
          this.state.thresholdRanges ? this.state.thresholdRanges.map((lamp, index) => (
            <RangeForm
              key={`rangeForm-${index + 1}`}
              rangeForm={lamp}
              index={index}
              // formLength={this.state.thresholdRanges.length}
              removeRange={() => this.removeThresholdRange(index)}
              handleThresholdChange={this.handleThresholdChange}
              colorOptions={COLOR_OPTIONS} />
          )) : null
        }
        <div className={styles.AddButton} onClick={() => this.addNewThresholdRange(false)}>
          <img src={AddIcon} alt="Add range" className={styles.AddIcon} />
          <div className={styles.ButtonName}>Add Range</div>
        </div>
      </React.Fragment>
    );
  }
}

// const mapStateToProps = (state) => {
//   const { loading, updatedWidget } = state.widgets;
//   return { loading, updatedWidget };
// };

export default connect(null, {})(GaugeForm);