import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';

import styles from './POViewBox.module.scss';
import MadeIcon from '../../../../../assets/icons/box.svg';
import TimeIcon from '../../../../../assets/icons/access-time.svg';

import { getDataEval, getDataLatest, getDatasetCal, getDataCal } from '../../../../../state/ducks/Data/actions';
import { calculateOee } from '../../../../../state/ducks/AdvanceActualProduction/actions';
import { updateObject } from '../../../../../state/utils';

const PERIODS_FOR_OEE_CHART = 5;

class POViewBox extends Component {
    state = {
        open: false,
        elaspedTime: 0,
        overallProgress: 0,
        producedActual: {},
        currentMachineStatus: {},
        historyPartsMade: {},
        planShouldProduceNow: 0,
        eachActualShouldProduceNow: {},
        totalMade: 0,
        noHistoryFound: false
    };

    componentDidMount() {
        const { actuals, uuid } = this.props.plan;
        this.calculateHowLongShouldProduceNow();
        this.produceNowInterval = setInterval(() => {
            this.calculateHowLongShouldProduceNow();
        }, 60 * 1000);

        this.dataForPOInterval = {};

        if (actuals) {
            actuals.forEach(actual => {
                if (actual.machine && this.isActualRunning(actual.end)) {
                    const { total_device_secret, total_device_socket, id, status_device_secret, status_device_socket } = actual.machine;
                    // let method = 'sum';
                    // if (actual.machine.is_total_device_accumulated) {
                    //     method = 'inc';
                    // }
                    // const actualsFromStart = '{ra:' + method + '(' + total_device_secret + '@' + total_device_socket + '@' + moment(actual.start).valueOf() + '-now())}';
                    // this.props.getDataEval(actualsFromStart, { planUuid: uuid, actual, machineId: id });
                    this.props.getDataLatest(status_device_socket, status_device_secret, null, null, { planUuid: uuid, actualUuid: actual.uuid, machineId: id });
                    this.findPartsMadeHistory(actual);
                    clearInterval(this.dataForPOInterval[actual.uuid]);
                    this.dataForPOInterval[actual.uuid] = setInterval(() => {
                        // this.props.getDataEval(actualsFromStart, { planUuid: uuid, actual, machineId: id });
                        this.findPartsMadeHistory(actual);
                        this.props.getDataLatest(status_device_socket, status_device_secret, null, null, { planUuid: uuid, actualUuid: actual.uuid, machineId: id });
                    }, 60 * 1000);
                }
            });
        }
    }

    findPartsMadeHistory = (actual) => {
        let startQuery = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).subtract(PERIODS_FOR_OEE_CHART - 1, 'hours').valueOf();
        let nextHour = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours').valueOf();
        this.props.calculateOee(actual.uuid, null, startQuery, nextHour, 60, { planUuid: this.props.plan.uuid, actualUuid: actual.uuid }, this.props.isFloating);
    }

    calculateHowLongShouldProduceNow = () => {
        const { actuals, target, start, end } = this.props.plan;
        const planDuration = (moment(end).valueOf() - moment(start).valueOf()) / (60 * 1000);
        const planSpeedTargetsPerMin = target / planDuration;
        const now = moment().valueOf();
        const elaspedTime = now - moment.min(actuals.map(d => moment(d.start))).valueOf();
        const planShouldProduceNow = planSpeedTargetsPerMin * Math.ceil(elaspedTime / (60 * 1000));

        const eachActualShouldProduceNow = {};
        actuals.map(act => {
            eachActualShouldProduceNow[act.uuid] = Math.floor(planSpeedTargetsPerMin * Math.ceil((now - moment(act.start).valueOf()) / (60 * 1000)));
        });

        this.setState({ elaspedTime: this.humanizeDuration(elaspedTime), planShouldProduceNow, eachActualShouldProduceNow });
    }

    calculateForPartsMade = (uuid, actual, secret, socket) => {
        const actualStart = new Date(actual.start);
        const stop = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours');
        if (new Date() - actualStart > (6 * 3600 * 1000)) {
            this.props.getDatasetCal(uuid, 'sum', secret, socket, 3600, stop.valueOf() - (6 * 3600 * 1000), stop.valueOf(), { planUuid: uuid, actualUuid: actual.uuid });
        } else {
            const stopFirstPeriod = moment(actual.start).set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours');
            const hoursLeft = (stop - stopFirstPeriod) / (3600 * 1000);
            this.props.getDataCal(uuid, secret, socket, moment(actual.start).valueOf(), stopFirstPeriod, 'sum', { planUuid: uuid, actualUuid: actual.uuid, hoursLeft });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.dataEval && prevProps.dataEval !== this.props.dataEval && this.isThisTheRightData(this.props.dataEval, this.props.plan)) {
            // DP: use /apq instead 02/09/22
            const producedActual = { ...this.state.producedActual };
            const { planUuid, actual } = this.props.dataEval.properties;
            producedActual[actual.uuid] = this.props.dataEval.data;

            let allActuals = 0;
            Object.keys(producedActual).forEach(key => {
                allActuals += producedActual[key];
            });

            this.setState({ producedActual, totalMade: allActuals, overallProgress: allActuals * 100 / this.props.plan.target });

            // There is a case that needs this.state.producedActual
            // this.calculateForPartsMade(this.props.plan.uuid, actual, actual.machine.total_device_secret, actual.machine.total_device_socket);
        } else if (this.props.dataLatest && prevProps.dataLatest !== this.props.dataLatest && this.isThisTheRightData(this.props.dataLatest, this.props.plan)) {
            const currentMachineStatus = { ...this.state.currentMachineStatus };
            const { planUuid, actualUuid } = this.props.dataLatest.properties;
            currentMachineStatus[actualUuid] = this.props.dataLatest.data && this.props.dataLatest.data.length ? this.props.dataLatest.data[0].value : '';
            this.setState({ currentMachineStatus });
        } else if (this.props.datasetCal !== prevProps.datasetCal && this.props.datasetCal && this.isThisTheRightData(this.props.datasetCal, this.props.plan)) {
            // DP: use /apq instead 02/09/22
            const { properties: { actualUuid, continueFromFirst }, values } = this.props.datasetCal;
            const historyPartsMade = { ...this.state.historyPartsMade };
            if (continueFromFirst) {
                if (values && values.length) {
                    const actualHistory = { ...this.state.historyPartsMade[actualUuid] };
                    values.forEach(v => {
                        actualHistory.labels.push(moment(v.timestamp).add(1, 'hours').format('hh A'));
                        actualHistory.datasets[0].data.push(v.value);
                    });
                    const updatedHistory = updateObject(this.state.historyPartsMade, {
                        [actualUuid]: actualHistory
                    })
                    this.setState({ historyPartsMade: updatedHistory });
                }
            } else {
                if (this.state.producedActual[actualUuid]) {
                    const isOver = this.state.producedActual[actualUuid] - this.state.planShouldProduceNow >= 0;
                    const graphData = {
                        labels: [],
                        datasets: [
                            {
                                label: 'Parts made',
                                data: [],
                                backgroundColor: isOver ? '#34aa44' : '#ea272a',
                                borderColor: isOver ? '#34aa44' : '#ea272a',
                                borderWidth: 1,
                            },
                        ],
                    };
                    historyPartsMade[actualUuid] = historyPartsMade[actualUuid] ? { ...historyPartsMade[actualUuid] } : {};
                    if (values && values.length) {
                        values.forEach(v => {
                            graphData.labels.push(moment(v.timestamp).add(1, 'hours').format('hh A'));
                            graphData.datasets[0].data.push(v.value);
                        });
                    }
                    historyPartsMade[actualUuid] = graphData;
                    this.setState({ historyPartsMade });
                }
            }
        } else if (this.props.dataCal !== prevProps.dataCal && this.props.dataCal && this.isThisTheRightData(this.props.dataCal, this.props.plan)) {
            // DP: use /apq instead 02/09/22
            const { properties: { planUuid, actualUuid, hoursLeft }, value, timestamp, secret, socket } = this.props.dataCal;
            const historyPartsMade = { ...this.state.historyPartsMade };
            const isOver = this.state.producedActual[actualUuid] - this.state.eachActualShouldProduceNow[actualUuid] >= 0;
            const graphData = {
                labels: [],
                datasets: [
                    {
                        label: 'Parts made',
                        data: [],
                        backgroundColor: isOver ? '#34aa44' : '#ea272a',
                        borderColor: isOver ? '#34aa44' : '#ea272a',
                        borderWidth: 1,
                    },
                ],
            };
            historyPartsMade[actualUuid] = {};
            if (value || value === 0) {
                const stop = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours');
                const thisHour = moment(timestamp).set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours');
                const addEmpty = 5 - ((stop - thisHour) / (3600 * 1000));
                let start = thisHour.subtract(addEmpty, 'hours');
                for (let i = 0; i < addEmpty; i++) {
                    graphData.labels.push(start.format('hh A'));
                    graphData.datasets[0].data.push(null);
                    start.add(1, 'hours');
                }
                graphData.labels.push(thisHour.format('hh A'));
                graphData.datasets[0].data.push(value);
            }
            historyPartsMade[actualUuid] = graphData;
            this.setState({ historyPartsMade });

            // call the rest values;
            const stop = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours');
            this.props.getDatasetCal(planUuid, 'sum', secret, socket, 3600, stop.valueOf() - (hoursLeft * 3600 * 1000), stop.valueOf(), { planUuid, actualUuid, continueFromFirst: true });
        } else if (this.props.actualOee && prevProps.actualOee !== this.props.actualOee && this.isThisTheRightData(this.props.actualOee, this.props.plan)) {
            const { apq, sum, properties } = this.props.actualOee;
            const historyPartsMade = { ...this.state.historyPartsMade };

            // find total made by actualOee.sum
            const producedActual = { ...this.state.producedActual };
            producedActual[properties.actualUuid] = sum.total;

            let allActuals = 0;
            Object.keys(producedActual).forEach(key => {
                allActuals += producedActual[key];
            });

            if (apq && apq.length) {
                const isOver = producedActual[properties.actualUuid] - this.state.eachActualShouldProduceNow[properties.actualUuid] >= 0;
                const foundActual = this.props.plan.actuals.find(act => act.uuid === properties.actualUuid);
                const isPartsExceeded = producedActual[properties.actualUuid] >= foundActual.target;

                const graphData = {
                    labels: [],
                    datasets: [
                        {
                            label: 'Parts made',
                            data: [],
                            backgroundColor: isPartsExceeded ? "#9ea0a5" : isOver ? '#34aa44' : '#ea272a',
                            borderColor: isPartsExceeded ? "#9ea0a5" : isOver ? '#34aa44' : '#ea272a',
                            borderWidth: 1,
                        },
                    ],
                };
                historyPartsMade[properties.actualUuid] = historyPartsMade[properties.actualUuid] ? { ...historyPartsMade[properties.actualUuid] } : {};
                apq.forEach(v => {
                    graphData.labels.push(moment(v.ts).format('hh A'));
                    graphData.datasets[0].data.push(v.total);
                });
                historyPartsMade[properties.actualUuid] = graphData;
                this.setState({ historyPartsMade, noHistoryFound: false });
            } else {
                this.setState({ noHistoryFound: true });
            }

            this.setState({ producedActual, totalMade: allActuals, overallProgress: allActuals * 100 / this.props.plan.target });
        }
    }

    componentWillUnmount() {
        clearInterval(this.produceNowInterval);
        if (this.dataForPOInterval && Object.keys(this.dataForPOInterval).length) {
            Object.keys(this.dataForPOInterval).forEach(actualUuid => clearInterval(this.dataForPOInterval[actualUuid]));
            this.dataForPOInterval = {};
        }
    }

    isThisTheRightData = (data, comp) => {
        if (data.properties.planUuid === comp.uuid) {
            return true;
        }
        return false;
    }

    isActualRunning = (time) => {
        return time === '0001-01-01T00:00:00Z';
    }

    humanizeDuration = (ms) => {
        const days = moment.duration(ms).days();
        const hours = moment.duration(ms).hours();
        const minutes = moment.duration(ms).minutes();
        const seconds = moment.duration(ms).seconds();

        let result = days === 0 ? '' : days > 1 ? days + ' days ' : days + ' day ';
        result += hours === 0 ? '' : hours > 1 ? hours + ' hours ' : hours + ' hour ';
        result += minutes === 0 ? '' : minutes > 1 ? minutes + ' minutes ' : minutes + ' minute ';
        return result;
    }

    openRunningMachineHandler = () => {
        this.setState(prevState => {
            return { open: !prevState.open }
        });
    }

    setupOptions = () => {
        const options = {
            elements: {
                // point: { radius: 0 }
            },
            tooltips: {
                mode: 'index',
                intersect: false,
            },
            hover: {
                mode: 'nearest',
                intersect: true
            },
            responsive: true,
            animation: false,
            maintainAspectRatio: false,
            legend: {
                position: 'top',
                display: false,
            },
            scales: {
                xAxes: [{
                    display: true,
                    gridLines: {
                        color: '#2b2b30',
                        zeroLineColor: '#2b2b30',
                        lineWidth: 1
                    },
                    ticks: {
                        fontColor: '#9ea0a5',
                    }
                }],
                yAxes: [{
                    display: true,
                    gridLines: {
                        color: '#2b2b30',
                        zeroLineColor: '#2b2b30',
                        lineWidth: 1
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Parts made',
                        fontColor: '#9ea0a5',
                    },
                    ticks: {
                        min: 0,
                        maxTicksLimit: 4,
                        fontColor: '#9ea0a5',
                    }
                }]
            }
        };
        return options;
    }

    render() {
        const { uuid, po, actuals, start, end, target } = this.props.plan;
        const { open, totalMade, planShouldProduceNow, overallProgress, elaspedTime, currentMachineStatus, historyPartsMade, producedActual, eachActualShouldProduceNow } = this.state;
        let arrowClasses = [styles.ArrowIcon];
        if (open) {
            arrowClasses.push(styles.ToggleDown);
        }

        const ahead = totalMade - planShouldProduceNow >= 0;
        let whereIndicator = planShouldProduceNow * 100 / target;
        if (whereIndicator > 100) whereIndicator = 100;

        return (
            <div style={{ marginBottom: 30 }}>
                <div
                    className={styles.PoBox}
                    onClick={this.openRunningMachineHandler}
                    style={{
                        backgroundColor: !totalMade || !planShouldProduceNow ? '#9ea0a555' : ahead ? '#34aa4433' : '#e6492d33'
                    }}
                >
                    <div className={styles.RunningPercentBar} style={{
                        width: `${overallProgress}%`,
                        // backgroundColor: this.props.ahead ? '#34aa44' : '#e6492d',
                        background: ahead ? 'linear-gradient(270deg, #34aa44 0%, #34aa4433 100%)' : 'linear-gradient(270deg, #e6492d 0%, #e6492d33 100%)'
                    }}></div>
                    <div className={styles.TextOnProgress}>
                        <div className={styles.PoNameSection}>
                            <div className={styles.PoName}>{po}</div>
                            <div className={styles.Description}>Date : {moment(start).format("DD/MM/YY HH:mm")} - {moment(end).format("DD/MM/YY HH:mm")}</div>
                        </div>
                        <div className={styles.PercentSection}>
                            <div className={styles.Percentage}>{overallProgress.toFixed(2)}% <span className={styles.Elapsed}>(Total made {totalMade.toLocaleString()}/{target.toLocaleString()})</span></div>
                            <div className={styles.Elapsed}>Elapsed Time: {elaspedTime}</div>
                        </div>
                        <div className={arrowClasses.join(' ')} />
                    </div>
                    <div className={styles.POIndicator} style={{ left: `calc( ${whereIndicator}%  - 12px)` }}></div>
                </div>
                {
                    open
                        ? <div className={styles.EachMachineDetailsWrapper}>
                            {
                                actuals.map(actual => (actual.machine && this.isActualRunning(actual.end) &&
                                    <div key={`poview-actual-${actual.uuid}`} className={styles.MachineDetailsBox}
                                        style={{
                                            background: `linear-gradient(180deg, ${actual.machine.status_rules && (currentMachineStatus[actual.uuid] || currentMachineStatus[actual.uuid] === 0) && actual.machine.status_rules[currentMachineStatus[actual.uuid]]
                                                ? actual.machine.status_rules[currentMachineStatus[actual.uuid]].color
                                                : ''} 10%, #222226 70%)`
                                        }}>
                                        <div className={styles.MachineStatus}
                                            style={{
                                                backgroundColor: '#222226AA',
                                                color: actual.machine.status_rules && (currentMachineStatus[actual.uuid] || currentMachineStatus[actual.uuid] === 0) && actual.machine.status_rules[currentMachineStatus[actual.uuid]]
                                                    ? actual.machine.status_rules[currentMachineStatus[actual.uuid]].color
                                                    : ''
                                            }}
                                        >
                                            {
                                                actual.machine.status_rules && (currentMachineStatus[actual.uuid] || currentMachineStatus[actual.uuid] === 0) && actual.machine.status_rules[currentMachineStatus[actual.uuid]]
                                                    ? actual.machine.status_rules[currentMachineStatus[actual.uuid]].reason
                                                    : 'N/A'
                                            }
                                        </div>
                                        <div style={{ margin: '25px' }}>
                                            <div className={styles.MachineName}>{actual.machine.name}</div>
                                            <div className={styles.ProgressSection}>
                                                <div className={styles.Upper}>
                                                    <img src={MadeIcon} style={{ width: 20 }} />
                                                    <div className={styles.Percent}>{producedActual[actual.uuid] && (producedActual[actual.uuid] * 100 / actual.target).toFixed(2)}%</div>
                                                    <div className={styles.ActualTarget}>Made {producedActual[actual.uuid] && (producedActual[actual.uuid]).toLocaleString()} of {(actual.target).toLocaleString()}</div>
                                                </div>
                                                <div className={styles.Lower}>
                                                    {
                                                        producedActual[actual.uuid] >= actual.target
                                                            ? <div className={styles.PartsExceedsIcon} />
                                                            : producedActual[actual.uuid] - eachActualShouldProduceNow[actual.uuid] >= 0 ? <div className={styles.AheadIcon} style={{ margin: '0 2px' }} /> : <div className={styles.BehindIcon} style={{ margin: '0 2px' }} />
                                                    }
                                                    <div className={styles.Parts}>
                                                        {
                                                            producedActual[actual.uuid] >= actual.target
                                                                ? actual.target + ' parts exceeded'
                                                                : producedActual[actual.uuid] - eachActualShouldProduceNow[actual.uuid] >= 0
                                                                    ? Math.abs(producedActual[actual.uuid] - eachActualShouldProduceNow[actual.uuid]) > +actual.target ? actual.target + ' parts exceeded' : Math.abs(producedActual[actual.uuid] - eachActualShouldProduceNow[actual.uuid]).toLocaleString() + ' parts ahead'
                                                                    : Math.abs(producedActual[actual.uuid] - eachActualShouldProduceNow[actual.uuid]) > +actual.target ? actual.target + ' parts behind' : Math.abs(producedActual[actual.uuid] - eachActualShouldProduceNow[actual.uuid]).toLocaleString() + ' parts behind'
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <img src={TimeIcon} style={{ width: 20 }} />
                                                <div className={styles.MachineElapsed}>{this.humanizeDuration(moment().valueOf() - moment(actual.start))}</div>
                                            </div>
                                        </div>
                                        <div style={{ padding: 15 }}>
                                            {
                                                !this.state.noHistoryFound && historyPartsMade[actual.uuid] && historyPartsMade[actual.uuid].labels.length === 6
                                                    ? <Bar data={historyPartsMade[actual.uuid]} height={100} options={this.setupOptions()} />
                                                    : <div style={{ fontSize: 12, textAlign: 'center' }}>No data available for last 6 hours</div>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { dataEval, dataLatest, datasetCal, dataCal } = state.data;
    const { actualOee } = state.advanceActualProduction;

    return { currentOrg, dataEval, dataLatest, datasetCal, dataCal, actualOee };
};

export default connect(mapStateToProps, { getDataEval, getDataLatest, getDatasetCal, getDataCal, calculateOee })(POViewBox);