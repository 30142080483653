import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';
import * as XLSX from 'xlsx';

import styles from './DailyEnergyBar.module.scss';

class DailyEnergyBar extends Component {
    chartRef = React.createRef();
    state = {
        chartData: null,
        options: null,
        allRawDataset: [],
        rawDatasetForAvgEfficiency: [],
        tempRawDataset: [],
        total: null,
        loading: false,
        selectedBarStyle: 'group',
        sumData: null,
        barDatasets: null,
        totalEfficiencyKpi: 0,
        currentIndexChartData: -1,
        correctChartValue: null
    }

    componentDidMount() {
        this.setupData();
        if (this.props.forReport) {
            if (!this.props.fiscal) {
                if (this.props.isChildren) {
                    this.preparingData([...this.props.allRawDataset].filter((ar => ar.properties.group === this.props.childIndex)));
                } else {
                    this.preparingData(this.props.allRawDataset);
                }
            } else {
                this.preparingData(this.props.monthlyRawDataset, this.props.monthlyRawDatasetForAvgEfficiency);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.currentOrg !== prevProps.currentOrg && this.props.currentOrg) {
            this.setState({ chartData: null, options: null, total: null, allRawDataset: [] });
        } else if (!_.isEqual(prevProps.selectedChild, this.props.selectedChild) && this.props.selectedChild) {
            this.setState({ allRawDataset: [], total: [], loading: true });
        } else if (this.props.queryPeriod && prevProps.queryPeriod !== this.props.queryPeriod && (this.props.selectedRelation || (this.props.selectedChild && this.props.selectedChild.length))) {
            this.setState({
                allRawDataset: [],
                total: this.props.selectedChild && this.props.selectedChild.length ? [] : null,
                chartData: { datasets: [], labels: [] },
                options: null,
                sumData: null,
                selectedBarStyle: 'group',
                barDatasets: null,
                loading: true
            });
        } else if (this.props.selectedDate !== prevProps.selectedDate && this.props.selectedDate) {
            this.setState({ allRawDataset: [], total: this.props.selectedChild && this.props.selectedChild.length ? [] : null });
        } else if (this.props.isEfficiency !== prevProps.isEfficiency && (this.props.isEfficiency === true || this.props.isEfficiency === false)) {
            this.setState({ chartData: { datasets: [], labels: [] }, allRawDataset: [], total: this.props.selectedChild && this.props.selectedChild.length ? [] : null, loading: true });
        } else if (prevProps.powers !== this.props.powers && this.props.powers) {
            const { properties } = this.props.powers;
            if (properties.rt) {
                let tempRawDataset = [...this.state.tempRawDataset];
                // if (tempRawDataset.length === 0) {
                //     tempRawDataset = new Array(this.props.amountLeaves).fill(undefined);
                // }
                // Can I not care order? 
                tempRawDataset.push(this.props.powers)
                if (tempRawDataset.length === this.props.amountLeaves) {
                    this.addNewCurrentData(tempRawDataset);
                }
                this.setState({ tempRawDataset });
            } else {
                let allRawDataset = [...this.state.allRawDataset];
                let rawDatasetForAvgEfficiency = [...this.state.rawDatasetForAvgEfficiency];

                let loading = true;
                if (allRawDataset.length === 0) {
                    allRawDataset = new Array(this.props.amountLeaves).fill(undefined);
                    if (this.needAvgEfficiencyLastYear()) rawDatasetForAvgEfficiency = new Array(this.props.amountLeaves).fill(undefined);
                }

                if (this.needAvgEfficiencyLastYear()) {
                    if (properties.avgEfficiencyLastYear) rawDatasetForAvgEfficiency[properties.order - 1] = this.props.powers;
                    else allRawDataset[properties.order - 1] = this.props.powers;
                } else {
                    allRawDataset[properties.order - 1] = this.props.powers;
                }

                if (this.needAvgEfficiencyLastYear()) {
                    const isAnyUndefined = allRawDataset.filter(ar => !ar);
                    const isAnyAvgUndefined = rawDatasetForAvgEfficiency.filter(ar => !ar);
                    if (!isAnyUndefined.length && !isAnyAvgUndefined.length && allRawDataset.length === this.props.amountLeaves && rawDatasetForAvgEfficiency.length === this.props.amountLeaves) {
                        loading = false;
                        this.preparingData(allRawDataset, rawDatasetForAvgEfficiency);
                    }
                    if (!this.props.forReport) {
                        this.props.saveAllRawDataset(allRawDataset);
                    }
                } else {
                    if (!this.props.fiscal) {
                        const isAnyUndefined = allRawDataset.filter(ar => !ar);
                        if (!isAnyUndefined.length && allRawDataset.length === this.props.amountLeaves) {
                            loading = false;
                            if (this.props.forReport && this.props.isChildren) {
                                this.preparingData(allRawDataset.filter((ar => ar.properties.group === this.props.childIndex)));
                            } else {
                                this.preparingData(allRawDataset);
                            }
                            if (!this.props.forReport) {
                                this.props.saveAllRawDataset(allRawDataset);
                            }
                        }
                    }
                }
                this.setState({ allRawDataset, loading, rawDatasetForAvgEfficiency });
            }
        } else if (prevProps.downloadData !== this.props.downloadData && this.props.downloadData) {
            if (this.props.queryPeriod === 'd') {
                this.downloadDailyData();
            } else {
                this.downloadMonthlyOrYearlyData();
            }
        }
    }

    downloadDailyData = () => {
        const bookName = this.checkCurrentNode();
        const exportData = [];
        if (this.state.correctChartValue && this.state.correctChartValue.length) {
            this.state.correctChartValue[0].forEach((prodValue, i) => {
                exportData.push({
                    date: moment(this.state.allRawDataset[0].data[i].timestamp).format('DD/MM/YYYY'),
                    from: moment(this.state.allRawDataset[0].data[i].timestamp).format('HH:mm'),
                    to: moment(this.state.allRawDataset[0].data[i].timestamp).add(10, 'minutes').format('HH:mm'),
                    shift: this.isDayOrNightShift(moment(this.state.allRawDataset[0].data[i].timestamp), this.state.allRawDataset[0].data[0].timestamp),
                    total: prodValue + this.state.correctChartValue[1][i],
                    prod: prodValue,
                    nonProd: this.state.correctChartValue[1][i]
                });
            });
        }

        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, bookName);
        XLSX.utils.sheet_add_aoa(ws, [['Date', 'From', 'To', 'Shift', 'Total Consumption (kWh)', 'Total Production (kWh)', 'Total Non Production (kWh)']], { origin: "A1" });
        // downloaded data_plant.casting1_day_25052023
        const downloadingFileName = 'Downloaded data_' + bookName + '_day_' + moment(this.state.allRawDataset[0].data[0].timestamp).format('DDMMYYYY') + '.xlsx'
        XLSX.writeFile(wb, downloadingFileName);
    }

    isDayOrNightShift = (checkingTime, baseTime) => {
        const today = moment(baseTime).set({ 'hour': 19, 'minute': 30, 'second': 0, 'millisecond': 0 });
        const tmr = moment(baseTime).add(1, 'day').set({ 'hour': 7, 'minute': 30, 'second': 0, 'millisecond': 0 });
        if (checkingTime.isBetween(today, tmr, undefined, '[)')) return 'Night';
        return 'Day';
    }

    downloadMonthlyOrYearlyData = () => {
        if (this.state.allRawDataset && this.state.allRawDataset.length) {
            const bookName = this.checkCurrentNode();
            const exportData = [];
            const prodValues = [];
            const nonProdValues = [];
            const actualValues = [];
            const tsValues = [];
            this.state.allRawDataset.forEach((rawData, i) => {
                rawData.data.forEach((v, j) => {
                    prodValues[j] = v.production + (prodValues[j] ? prodValues[j] : 0);
                    nonProdValues[j] = v.non_production + (nonProdValues[j] ? nonProdValues[j] : 0);
                    actualValues[j] = v.actual + (actualValues[j] ? actualValues[j] : 0);
                    if (i === 0) {
                        if (this.props.queryPeriod === 'm') tsValues.push(moment(v.timestamp).format('DD/MM/YYYY'));
                        else tsValues.push(moment(v.timestamp).format('MMMM YYYY'));
                    }
                });
            });
            prodValues.forEach((prod, index) => {
                let efficiency = 0;
                if (actualValues[index] !== 0) efficiency = (prod + nonProdValues[index]) / actualValues[index];
                exportData[index] = {
                    date: tsValues[index],
                    totalUsage: (prod + nonProdValues[index]).toFixed(2),
                    prod: (prod).toFixed(2),
                    nonProd: (nonProdValues[index]).toFixed(2),
                    actual: actualValues[index],
                    efficiency: efficiency.toFixed(2),
                };
            });

            const ws = XLSX.utils.json_to_sheet(exportData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, bookName);
            XLSX.utils.sheet_add_aoa(ws, [['Date', 'Total Consumption (kWh)', 'Total Production (kWh)', 'Total Non Production (kWh)', 'Product (unit)', 'Consumption per Unit (kWh/unit)']], { origin: "A1" });
            const peiod = this.props.queryPeriod === 'm'
                ? '_month_' + moment(this.state.allRawDataset[0].data[0].timestamp).format('MMYYYY')
                : '_year_' + moment(this.state.allRawDataset[0].data[0].timestamp).format('YYYY')
            const downloadingFileName = 'Downloaded data_' + bookName + '' + peiod + '.xlsx';
            XLSX.writeFile(wb, downloadingFileName);
        }
    }

    needAvgEfficiencyLastYear = () => {
        return this.props.queryPeriod === 'y' && this.props.isEfficiency;
    }

    addNewCurrentData = (tempRawDataset) => {
        let productionValue = 0;
        let nonProductionValue = 0;
        tempRawDataset.forEach(temp => {
            const { data } = temp;
            productionValue += data[0].production;
            nonProductionValue += data[0].non_production;
        });

        const chartData = { ...this.state.chartData };
        chartData.datasets[0].data[this.state.currentIndexChartData] = chartData.datasets[0].data[this.state.currentIndexChartData - 1];    // production target
        chartData.datasets[1].data[this.state.currentIndexChartData] = chartData.datasets[1].data[this.state.currentIndexChartData - 1];    // non production target
        chartData.datasets[2].data[this.state.currentIndexChartData] = productionValue.toFixed(2);    // production value
        chartData.datasets[3].data[this.state.currentIndexChartData] = nonProductionValue.toFixed(2);    // non production value
        const chart = this.chartRef.current.chartInstance;
        chart.update();
        this.setState({ chartData });
    }

    preparingData = (allRawDataset, rawDatasetForAvgEfficiency) => {
        if (!this.props.forReport) this.props.doneLoading();
        // allRawDataset = {data: [timestamp, production, non_production, actual], properties: {infos, group, order}}
        let chartData = [[0], [0]];
        let efficiencyValue = [[0], [0]];
        let currentIndexChartData = -1;
        if (this.props.isEfficiency) chartData = [[0]];
        const timestamp = [];

        let avgEfficiencyLastYearValue = 0;
        if (this.needAvgEfficiencyLastYear()) {
            const currentMonth = moment(this.props.selectedDate).month(); // 0 = JAN
            const displayedYear = moment(this.props.selectedDate).format('YY');
            if (currentMonth === 0 || currentMonth === 1 || currentMonth === 2)
                displayedYear = moment(this.props.selectedDate).subtract(1, 'year').format('YY');
            const avgText = 'Avg. FY' + displayedYear;
            timestamp.push(avgText);
        }
        allRawDataset.forEach((rawData, i) => {
            if (rawData && rawData.data && rawData.data.length) {
                let timeNow = moment().format('HH:mm');
                let floorMin = (timeNow.split(":")[1])[0] + '0';
                let skipThisDataTime = timeNow.split(":")[0] + ':' + floorMin;
                rawData.data.forEach((v, j) => {
                    let t = moment(v.timestamp).format('HH:mm');
                    let showingTime = moment(v.timestamp).add(10, 'minutes').format('HH:mm');
                    if (this.props.queryPeriod === 'm') showingTime = moment(v.timestamp).format('DD');
                    else if (this.props.queryPeriod === 'y') showingTime = moment(v.timestamp).format('MMM-YY');
                    if (this.isFuture(v.timestamp)) {
                        if (this.props.isEfficiency) chartData[0][j] = null;
                        else {
                            chartData[0][j] = null;
                            chartData[1][j] = null;
                        }
                    } else if (skipThisDataTime === t && this.isToday(v.timestamp)) {
                        currentIndexChartData = j;
                        if (this.props.isEfficiency) chartData[0][j] = null;
                        else {
                            chartData[0][j] = null;
                            chartData[1][j] = null;
                        }
                    } else {
                        if (this.props.isEfficiency) {
                            if (this.needAvgEfficiencyLastYear()) {
                                if (rawDatasetForAvgEfficiency[i].data[j].actual !== 0) {
                                    avgEfficiencyLastYearValue += (rawDatasetForAvgEfficiency[i].data[j].production + rawDatasetForAvgEfficiency[i].data[j].non_production) / rawDatasetForAvgEfficiency[i].data[j].actual;
                                } else {
                                    avgEfficiencyLastYearValue += 0;
                                }
                            }
                            if (v.actual !== 0) {
                                chartData[0][j] = ((v.production + v.non_production) / v.actual) + (chartData[0][j] ? chartData[0][j] : 0);
                                efficiencyValue[0][j] = (v.production / v.actual) + (efficiencyValue[0][j] ? efficiencyValue[0][j] : 0);
                                efficiencyValue[1][j] = (v.non_production / v.actual) + (efficiencyValue[1][j] ? efficiencyValue[1][j] : 0);
                            } else {
                                chartData[0][j] = 0 + (chartData[0][j] ? chartData[0][j] : 0);
                                efficiencyValue[0][j] = 0 + (efficiencyValue[0][j] ? efficiencyValue[0][j] : 0);
                                efficiencyValue[1][j] = 0 + (efficiencyValue[1][j] ? efficiencyValue[1][j] : 0);
                            }
                        } else {
                            chartData[0][j] = v.production + (chartData[0][j] ? chartData[0][j] : 0);
                            chartData[1][j] = v.non_production + (chartData[1][j] ? chartData[1][j] : 0);
                        }
                    }
                    if (i === 0) {
                        timestamp.push(showingTime);
                    }
                });
            }
        });
        let correctChartValue = [[], []];
        if (this.props.isEfficiency) correctChartValue = [[]];
        let totalProduction = 0;
        let totalNonProduction = 0;
        let evaTimes = [];
        chartData.forEach((ds, i) => {
            if (this.props.queryPeriod === 'm') {
                // ds.splice(ds.length - 1, 1);
            }
            ds.forEach((value, j) => {
                correctChartValue[i].push(value || value === 0 ? Number(value.toFixed(2)) : null);
                if (i === 0) totalProduction += value || value === 0 ? value : 0;
                else {
                    totalNonProduction += value || value === 0 ? value : 0;
                    if (value !== 0) {
                        const t = allRawDataset[0].data[j].timestamp;
                        evaTimes.push(t);
                    }
                }
            });
        });

        if (this.props.forReport && !this.props.fiscal) {
            this.reAdjustEvaTimes(evaTimes, allRawDataset[0]?.data[0].timestamp);
        }

        avgEfficiencyLastYearValue = Number((avgEfficiencyLastYearValue / 12).toFixed(2));
        if (this.needAvgEfficiencyLastYear()) correctChartValue[0].splice(0, 0, avgEfficiencyLastYearValue);

        let totalEffProduction = 0;
        let totalEffNonProduction = 0;
        if (this.props.isEfficiency) {
            efficiencyValue.forEach((ev, i) => {
                ev.forEach(value => {
                    if (i === 0) totalEffProduction += value || value === 0 ? value : 0;
                    else totalEffNonProduction += value || value === 0 ? value : 0;
                });
            });
        }
        if (this.props.queryPeriod === 'm') {
            // timestamp.splice(timestamp.length - 1, 1);
        }

        let total = [
            Number((totalProduction + totalNonProduction).toFixed(2)),
            Number(totalProduction.toFixed(2)),
            Number(totalNonProduction.toFixed(2))
        ];
        if (this.props.isEfficiency) {
            total = [
                Number((totalEffProduction + totalEffNonProduction).toFixed(2)),
                Number(totalEffProduction.toFixed(2)),
                Number(totalEffNonProduction.toFixed(2))
            ];
        }

        this.setupData(correctChartValue, timestamp, allRawDataset);
        this.setState({ total, currentIndexChartData, correctChartValue });
    }

    reAdjustEvaTimes = (evaTimes, startTimePeriod) => {
        const result = [];
        let count = 0;
        let keepChecking = false;
        evaTimes.forEach((eva, k) => {
            if (moment().valueOf() < moment(eva).valueOf()) return;
            if (result && result.length) {
                let temp = moment(result[result.length - 1].start);
                if (keepChecking) {
                    temp = moment(evaTimes[k - 1]);
                }
                const isNextTen = moment(eva).diff(temp, 'minutes') === 10;
                if (!isNextTen) {
                    result[result.length - 1].amount = count;
                    count = 1;
                    result.push({
                        start: eva,
                        index: moment(eva).diff(moment(startTimePeriod), 'minutes')
                    });
                } else {
                    count++;
                }
            } else {
                result.push({
                    start: eva,
                    index: moment(eva).diff(moment(startTimePeriod), 'minutes')
                });
                count++;
                keepChecking = true;
            }
            if (k === evaTimes.length - 1) {
                result[result.length - 1].amount = count;
            }
        });


        if (this.props.isParent) this.props.setEvaTimes('parent', result);
        else this.props.setEvaTimes('children', result, this.props.childIndex);

    }

    setupOptions = (ts) => {
        let isStackingX = true;
        if (this.props.queryPeriod !== 'd') {
            if (this.state.selectedBarStyle === 'group') {
                isStackingX = false;
            }
        }
        let isStackingY = false;
        if (this.props.queryPeriod !== 'd') {
            if (this.state.selectedBarStyle === 'stack') {
                isStackingY = true;
            }
        }

        let options = {
            interaction: {
                mode: "nearest"
            },
            responsive: true,
            tooltips: {
                mode: 'index',
                intersect: false,
                // callbacks: {
                //     label: (tooltipItem, data) => {
                //         if (tooltipItem.datasetIndex === 1) {
                //             return this.humanizeDuration(tooltipItem.value, true) + ' (' + paretoData.percent[tooltipItem.index] + '%)';
                //         } else {
                //             return tooltipItem.value + '%';
                //         }
                //     }
                // }
            },
            hover: {
                mode: 'index',
                intersect: false
            },
            legend: {
                position: 'bottom',
                align: 'start',
                labels: {
                    fontColor: this.props.forReport ? 'rgb(0, 0, 0)' : 'rgba(100, 100, 100)'
                }
            },
            elements: {
                line: {
                    fill: false
                }
            },
            scales: {
                xAxes: [
                    {
                        stacked: isStackingX,
                        display: true,
                        gridLines: {
                            display: true,
                            color: this.props.forReport ? 'white' : '',
                            zeroLineColor: 'rgba(100, 100, 100)'
                        },
                        labels: ts,
                        ticks: {
                            fontColor: "",
                        }
                    }
                ],
                yAxes: [
                    {
                        stacked: isStackingY,
                        display: true,
                        gridLines: {
                            display: true,
                            color: 'rgba(100, 100, 100, 0.25)',
                            zeroLineColor: 'rgba(100, 100, 100)'
                        },
                        labels: {
                            show: true
                        },
                        ticks: {
                            fontColor: this.props.forReport ? 'black' : '',
                            minTicks: 5,
                            maxTicks: 5,
                            beginAtZero: true,
                            //     callback: (value, index, values) => {
                            //         return this.humanizeDuration(value);
                            //     }
                        }
                    }
                ]
            }
        }

        this.setState({ options });
    }

    onChangeBarStyleHandler = (barStyle) => {
        const chart = this.chartRef.current.chartInstance;
        let options = { ...this.state.options };
        let datasets = [];
        if (barStyle === 'group') {
            options.scales.xAxes[0].stacked = false;
            options.scales.yAxes[0].stacked = false;
            datasets = this.state.barDatasets.group;

        } else {
            options.scales.xAxes[0].stacked = true;
            options.scales.yAxes[0].stacked = true;
            datasets = this.state.barDatasets.stack;
        }

        setTimeout(() => {
            if (barStyle === 'group') {
                chart.options.scales.xAxes[0].stacked = false;
                chart.options.scales.yAxes[0].stacked = false;
            } else {
                chart.options.scales.xAxes[0].stacked = true;
                chart.options.scales.yAxes[0].stacked = true;
            }
            chart.update();
        }, 400);

        let chartData = { datasets };
        this.setState({ selectedBarStyle: barStyle, options, chartData });
    }

    isFuture = (t) => {
        return moment(t).valueOf() > moment().valueOf();
    }

    isToday = (t) => {
        if (this.props.queryPeriod !== 'd') return false;
        return moment(t).isSame(moment(), 'day');
    }

    setupData = (preparedChartData, ts, allRawDataset) => {
        const datasets = [];
        // add production and non production kpi
        let totalUsageKpi = 0;
        let productionUsageKpi = 0;
        let nonProductionUsageKpi = 0;
        let totalEfficiencyKpi = 0;
        if (this.props.selectedChild && (!this.props.selectedChild.length || !this.props.selectedChild[0])) {
            // select only level 0
            if (this.props.machines) {
                this.props.machines.forEach(machine => {
                    const { productionUsageTarget, kpis, efficiencyKPI } = machine.properties;
                    if (efficiencyKPI) {
                        totalEfficiencyKpi += Number(efficiencyKPI);
                    }
                    if (productionUsageTarget) {
                        productionUsageKpi += Number(productionUsageTarget);
                    }
                    if (this.props.schedulers && kpis && kpis.length) {
                        kpis.forEach(kpi => {
                            const foundSchedulerInThisPeriod = this.props.schedulers.filter(sch => sch.type_id === kpi.stypeId && sch.machine_ids.includes(machine.id));
                            if (foundSchedulerInThisPeriod && foundSchedulerInThisPeriod.length) {
                                nonProductionUsageKpi += Number(kpi.kpiValue) * foundSchedulerInThisPeriod.length;
                            }
                        });
                    }
                });
                totalUsageKpi = productionUsageKpi + nonProductionUsageKpi;
                if (this.props.queryPeriod === 'd' && preparedChartData) {
                    totalEfficiencyKpi /= preparedChartData[0].length;
                    productionUsageKpi /= preparedChartData[0].length;
                    nonProductionUsageKpi /= preparedChartData[0].length;
                    totalUsageKpi /= preparedChartData[0].length;
                } else if (this.props.queryPeriod === 'y' && preparedChartData) {
                    // const start = moment().set('month', 3).set('year', moment().get('years')).startOf('month'); // 1 APR, this year
                    // const end = moment().set('month', 2).set('year', moment().get('years') + 1).endOf('month'); // 1 MAR, next year
                    // const daysThisYear = end.diff(start, 'days');
                    totalEfficiencyKpi *= 30;
                    productionUsageKpi *= 30;
                    nonProductionUsageKpi *= 30;
                    totalUsageKpi *= 30;
                }
            }
        } else if (this.props.selectedChild && this.props.selectedChild && (this.props.selectedChild.length === 1 || !this.props.selectedChild[1])) {
            // select only level 1 || machine level
            const thisMachineId = this.props.selectedChild[0].itsId;
            const foundMachine = this.props.machines.find(m => m.id === +thisMachineId);
            const { productionUsageTarget, kpis, efficiencyKPI } = foundMachine.properties;
            if (efficiencyKPI) {
                totalEfficiencyKpi = Number(efficiencyKPI);
            }
            if (productionUsageTarget) {
                productionUsageKpi = Number(productionUsageTarget);
            }

            if (this.props.schedulers && kpis && kpis.length) {
                kpis.forEach(kpi => {
                    const foundSchedulerInThisPeriod = this.props.schedulers.filter(sch => sch.type_id === kpi.stypeId && sch.machine_ids.includes(foundMachine.id));
                    if (foundSchedulerInThisPeriod && foundSchedulerInThisPeriod.length) {
                        nonProductionUsageKpi += Number(kpi.kpiValue) * foundSchedulerInThisPeriod.length;
                    }
                });
            }
            totalUsageKpi = productionUsageKpi + nonProductionUsageKpi;
            if (this.props.queryPeriod === 'd' && preparedChartData) {
                totalEfficiencyKpi /= preparedChartData[0].length;
                productionUsageKpi /= preparedChartData[0].length;
                nonProductionUsageKpi /= preparedChartData[0].length;
                totalUsageKpi /= preparedChartData[0].length;
            } else if (this.props.queryPeriod === 'y' && preparedChartData) {
                // const start = moment().set('month', 3).set('year', moment().get('years')).startOf('month'); // 1 APR, this year
                // const end = moment().set('month', 2).set('year', moment().get('years') + 1).endOf('month'); // 1 MAR, next year
                // const daysThisYear = end.diff(start, 'days');
                totalEfficiencyKpi *= 30;
                productionUsageKpi *= 30;
                nonProductionUsageKpi *= 30;
                totalUsageKpi *= 30;
            }
        }

        // find max of those data
        // let max = 1;
        // let stackedValue = 0;
        // if (preparedChartData && preparedChartData.length) {
        //     preparedChartData.forEach(pcd => {
        //         stackedValue += _.max(pcd);
        //     });
        //     max = stackedValue;
        //     if (productionUsageKpi > max) max = productionUsageKpi;
        //     if (nonProductionUsageKpi > max) max = nonProductionUsageKpi;
        //     max = Math.ceil(max / 1000) * 1000;
        // }

        this.setupOptions(ts);
        const totalEfficiencyKpiDataset = [];
        if (this.needAvgEfficiencyLastYear()) {
            totalEfficiencyKpiDataset.push(null);
        }
        const productionUsageKpiDataset = [];
        const nonProductionUsageKpiDataset = [];
        const totalUsageKpiDataset = [];
        if (allRawDataset && allRawDataset.length) {
            let timeNow = moment().format('HH:mm');
            let floorMin = (timeNow.split(":")[1])[0] + '0';
            let skipThisDataTime = timeNow.split(":")[0] + ':' + floorMin;
            if (allRawDataset[0].data) {
                allRawDataset[0].data.forEach((d, i) => {
                    let t = moment(d.timestamp).format('HH:mm');
                    totalEfficiencyKpiDataset.push(this.isFuture(d.timestamp) || (t === skipThisDataTime && this.isToday(d.timestamp)) ? null : +totalEfficiencyKpi.toFixed(2));
                    productionUsageKpiDataset[i] = this.isFuture(d.timestamp) || (t === skipThisDataTime && this.isToday(d.timestamp)) ? null : +productionUsageKpi.toFixed(2);
                    nonProductionUsageKpiDataset[i] = this.isFuture(d.timestamp) || (t === skipThisDataTime && this.isToday(d.timestamp)) ? null : +nonProductionUsageKpi.toFixed(2);
                    totalUsageKpiDataset[i] = this.isFuture(d.timestamp) || (t === skipThisDataTime && this.isToday(d.timestamp)) ? null : +totalUsageKpi.toFixed(2);
                });
            }
        }

        let datasetsIndex = 0;
        let stackDatasets = [];
        if (preparedChartData && preparedChartData.length) {
            if (this.props.isEfficiency) {
                datasets[datasetsIndex] = {
                    label: 'Total Efficiency KPI',
                    type: 'line',
                    data: totalEfficiencyKpiDataset,
                    fill: false,
                    borderWidth: 2,
                    borderColor: 'red',
                    backgroundColor: 'red',
                    lineTension: 0,
                    pointRadius: 0,
                    offset: 0,
                };
                datasetsIndex++;
                preparedChartData.forEach((pcd, i) => {
                    datasets[i + datasetsIndex] = {
                        label: 'Total Efficiency',
                        type: 'bar',
                        data: pcd,
                        fill: false,
                        borderColor: '#3063D1',
                        backgroundColor: '#3063D1',
                    };
                });
                let chartData = { datasets };
                this.setState({ chartData });
            } else {
                if (!(this.props.selectedChild && this.props.selectedChild && this.props.selectedChild.length === 2 && this.props.selectedChild[1])) {
                    datasets[datasetsIndex] = {
                        label: 'Production Usage Target',
                        type: 'line',
                        data: productionUsageKpiDataset,
                        fill: false,
                        borderWidth: 2,
                        borderColor: 'red',
                        backgroundColor: 'red',
                        lineTension: 0,
                        pointRadius: 0,
                        offset: 0,
                    };
                    datasetsIndex++;
                    datasets[datasetsIndex] = {
                        label: 'Non Production Usage Target',
                        type: 'line',
                        data: nonProductionUsageKpiDataset,
                        fill: false,
                        borderDash: [5, 5],
                        borderWidth: 2,
                        borderColor: 'red',
                        backgroundColor: 'red',
                        lineTension: 0,
                        pointRadius: 0,
                        offset: 0,
                    };
                    datasetsIndex++;
                }

                preparedChartData.forEach((pcd, i) => {
                    datasets[i + datasetsIndex] = {
                        label: i === 0 ? 'Production' : 'Non Production',
                        type: 'bar',
                        data: pcd,
                        fill: false,
                        borderColor: i === 0 ? '#3063D1' : '#A0BAC7',
                        backgroundColor: i === 0 ? '#3063D1' : '#A0BAC7',
                    };
                });

                stackDatasets = [...datasets];
                if (stackDatasets[0] && stackDatasets[0].type === 'line' && stackDatasets[1] && stackDatasets[1].type === 'line') {
                    stackDatasets.splice(1, 1);
                    stackDatasets[0] = {
                        label: 'Total Usage Target',
                        type: 'line',
                        data: totalUsageKpiDataset,
                        fill: false,
                        borderWidth: 2,
                        borderColor: 'red',
                        backgroundColor: 'red',
                        lineTension: 0,
                        pointRadius: 0,
                        offset: 0
                    };
                }
            }

            let chartData = { datasets };
            if (this.state.selectedBarStyle === 'stack') {
                chartData = { datasets: stackDatasets };
            }
            let barDatasets = {
                group: datasets,
                stack: stackDatasets
            };

            this.setState({ chartData, barDatasets, totalEfficiencyKpi });
        }
    }

    checkCurrentNode = () => {
        if (!this.props.selectedRelation) return 'N/A';
        if (this.props.selectedChild && (!this.props.selectedChild.length || !this.props.selectedChild[0])) {
            return this.props.selectedRelation.label;
        } else if (this.props.selectedChild && this.props.selectedChild && (this.props.selectedChild.length === 1 || !this.props.selectedChild[1])) {
            return this.props.selectedChild[0].label;
        } else {
            if (!this.props.selectedChild) return 'N/A';
            return this.props.selectedChild[1].label;
        }
    }

    render() {
        const { total } = this.state;
        let displayedYear;
        if (this.props.forReport && this.props.isParent && this.props.fiscal) {
            const currentMonth = moment(this.props.selectedDate).month(); // 0 = JAN
            if (currentMonth === 0 || currentMonth === 1 || currentMonth === 2)
                displayedYear = moment(this.props.selectedDate).format('YYYY');
            else displayedYear = moment(this.props.selectedDate).add(1, 'year').format('YYYY');
        }

        return (
            <>
                <div className={styles.ValueConclusion}>
                    {
                        this.props.forReport && !this.props.fiscal
                            ? this.props.isParent
                                ? this.props.monthly
                                    ? this.props.isEfficiency
                                        ? <div className={styles.ChildTitle} style={{ fontSize: 26 }}>{moment(this.props.selectedDate).format('MMMM YYYY')}</div>
                                        : null
                                    : <div className={styles.ParentTitle}>{this.props.selectedRelation?.label}</div>
                                : (this.props.isChildren && !this.props.monthly) || (this.props.isChildren && this.props.isEfficiency && this.props.monthly)
                                    ? <div className={styles.ChildTitle}>{this.props.selectedChild[0]?.label}</div>
                                    : null
                            : null
                    }
                    {
                        this.props.forReport && this.props.isParent && this.props.fiscal
                            ? <div className={styles.ChildTitle} style={{ fontSize: 26 }}>Fiscal year {displayedYear}</div>
                            : null
                    }
                    {
                        this.props.fiscal || (this.props.monthly && !this.props.isChildren)
                            ? this.props.fiscal || (this.props.monthly && this.props.isParent && this.props.isEfficiency)
                                ? <div className={styles.Total} style={{ color: this.props.forReport ? 'black' : 'inherit' }}>Average: {total ? total.length ? (total[0] / 12).toFixed(2).toLocaleString() : 'Loading...' : 'N/A'}</div>
                                : null
                            : <>
                                <div className={styles.Total} style={{ color: this.props.forReport ? 'black' : 'inherit' }}>Total: {total ? total.length ? total[0].toLocaleString() : 'Loading...' : 'N/A'}</div>
                                <div className={styles.Production}>Production: {total ? total.length ? total[1].toLocaleString() : 'Loading...' : 'N/A'}</div>
                                <div className={styles.NonProduction}>Non Production: {total ? total.length ? total[2].toLocaleString() : 'Loading...' : 'N/A'}</div>
                            </>
                    }
                </div>
                <div className={styles.ChartWrapper} style={{ backgroundColor: this.props.forReport ? 'white' : '#222226', padding: this.props.forReport ? '0 20px' : 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                        <div style={{ flex: 1 }}>
                            {
                                this.props.isEfficiency
                                    ? this.props.queryPeriod === 'm'
                                        ? <div className={styles.Header} style={{ color: this.props.forReport ? 'black' : 'inherit' }}>kWh/unit <span style={{ color: this.props.forReport ? 'black' : 'inherit' }}>(Daily Efficiency Average)</span></div>
                                        : <div className={styles.Header} style={{ color: this.props.forReport ? 'black' : 'inherit' }}>kWh/unit <span style={{ color: this.props.forReport ? 'black' : 'inherit' }}>(Monthly Efficiency Average)</span></div>
                                    : this.props.queryPeriod === 'd'
                                        ? <div className={styles.Header} style={{ color: this.props.forReport ? 'black' : 'inherit' }}>kWh <span style={{ color: this.props.forReport ? 'black' : 'inherit' }}>(Usage 10 Mins)</span></div>
                                        : this.props.queryPeriod === 'm'
                                            ? <div className={styles.Header} style={{ color: this.props.forReport ? 'black' : 'inherit' }}>kWh <span style={{ color: this.props.forReport ? 'black' : 'inherit' }}>(Daily Average)</span></div>
                                            : <div className={styles.Header} style={{ color: this.props.forReport ? 'black' : 'inherit' }}>kWh <span style={{ color: this.props.forReport ? 'black' : 'inherit' }}>(Monthly Average)</span></div>
                            }
                        </div>
                        {
                            this.props.queryPeriod !== 'd' && !this.props.isEfficiency && !this.props.forReport
                                ? <div className={styles.BarStyles}>
                                    <div className={`${styles.Option} ${this.state.selectedBarStyle === 'group' ? styles.Active : ''}`} style={{ marginRight: 10 }} onClick={() => this.onChangeBarStyleHandler('group')}>Group</div>
                                    <div className={`${styles.Option} ${this.state.selectedBarStyle === 'stack' ? styles.Active : ''}`} onClick={() => this.onChangeBarStyleHandler('stack')}>Stack</div>
                                </div>
                                : null
                        }
                    </div>
                    {
                        this.state.loading
                            ? <div className={styles.LoadingText}>Loading...</div>
                            : this.state.chartData && this.state.options
                                ? <Bar ref={this.chartRef} data={this.state.chartData} height={this.props.forReport && !this.props.monthly && !this.props.fiscal ? 70 : 50} options={this.state.options} />
                                : null
                    }
                    {this.props.isEfficiency && !this.props.forReport ? <div className={styles.EfficiencyKpiValue}>KPI: {this.checkCurrentNode()}<span>{this.state.totalEfficiencyKpi ? this.state.totalEfficiencyKpi.toLocaleString() : 0} kWh/unit</span></div> : null}
                    {this.props.fiscal && this.props.isParent ? <div className={styles.EfficiencyKpiValue}>KPI: {this.props.selectedRelation.label}<span style={{ color: this.props.forReport ? 'black' : 'inherit' }}>{this.state.totalEfficiencyKpi ? this.state.totalEfficiencyKpi.toLocaleString() : 0} kWh/unit</span></div> : null}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { schedulers } = state.scheduler;
    const { powers } = state.power;

    return {
        currentOrg, powers, schedulers
    };
};

export default connect(mapStateToProps, {})(DailyEnergyBar);
