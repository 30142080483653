export const GET_DOCUMENT_NOTES_START = 'GET_DOCUMENT_NOTES_START';
export const GET_DOCUMENT_NOTES = 'GET_DOCUMENT_NOTES';
export const GET_DOCUMENT_NOTES_SUCCESS = 'GET_DOCUMENT_NOTES_SUCCESS';
export const GET_DOCUMENT_NOTES_FAIL = 'GET_DOCUMENT_NOTES_FAIL';

export const ADD_DOCUMENT_NOTE_START = 'ADD_DOCUMENT_NOTE_START';
export const ADD_DOCUMENT_NOTE = 'ADD_DOCUMENT_NOTE';
export const ADD_DOCUMENT_NOTE_SUCCESS = 'ADD_DOCUMENT_NOTE_SUCCESS';
export const ADD_DOCUMENT_NOTE_FAIL = 'ADD_DOCUMENT_NOTE_FAIL';

export const EDIT_DOCUMENT_NOTE_START = 'EDIT_DOCUMENT_NOTE_START';
export const EDIT_DOCUMENT_NOTE = 'EDIT_DOCUMENT_NOTE';
export const EDIT_DOCUMENT_NOTE_SUCCESS = 'EDIT_DOCUMENT_NOTE_SUCCESS';
export const EDIT_DOCUMENT_NOTE_FAIL = 'EDIT_DOCUMENT_NOTE_FAIL';

export const DELETE_DOCUMENT_NOTE_START = 'DELETE_DOCUMENT_NOTE_START';
export const DELETE_DOCUMENT_NOTE = 'DELETE_DOCUMENT_NOTE';
export const DELETE_DOCUMENT_NOTE_SUCCESS = 'DELETE_DOCUMENT_NOTE_SUCCESS';
export const DELETE_DOCUMENT_NOTE_FAIL = 'DELETE_DOCUMENT_NOTE_FAIL';

export const CLEAR_NOTE_TYPES = 'CLEAR_NOTE_TYPES';

export const GET_NOTE_TYPES_START = 'GET_NOTE_TYPES_START';
export const GET_NOTE_TYPES = 'GET_NOTE_TYPES';
export const GET_NOTE_TYPES_SUCCESS = 'GET_NOTE_TYPES_SUCCESS';
export const GET_NOTE_TYPES_FAIL = 'GET_NOTE_TYPES_FAIL';

export const ADD_NOTE_TYPE_START = 'ADD_NOTE_TYPE_START';
export const ADD_NOTE_TYPE = 'ADD_NOTE_TYPE';
export const ADD_NOTE_TYPE_SUCCESS = 'ADD_NOTE_TYPE_SUCCESS';
export const ADD_NOTE_TYPE_FAIL = 'ADD_NOTE_TYPE_FAIL';

export const EDIT_NOTE_TYPE_START = 'EDIT_NOTE_TYPE_START';
export const EDIT_NOTE_TYPE = 'EDIT_NOTE_TYPE';
export const EDIT_NOTE_TYPE_SUCCESS = 'EDIT_NOTE_TYPE_SUCCESS';
export const EDIT_NOTE_TYPE_FAIL = 'EDIT_NOTE_TYPE_FAIL';

export const DELETE_NOTE_TYPE_START = 'DELETE_NOTE_TYPE_START';
export const DELETE_NOTE_TYPE = 'DELETE_NOTE_TYPE';
export const DELETE_NOTE_TYPE_SUCCESS = 'DELETE_NOTE_TYPE_SUCCESS';
export const DELETE_NOTE_TYPE_FAIL = 'DELETE_NOTE_TYPE_FAIL';

// BIRD DID THIS: on document-list
export const GET_DOCUMENTS_START        = 'GET_DOCUMENTS_START';
export const GET_DOCUMENTS              = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_SUCCESS      = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAIL         = 'GET_DOCUMENTS_FAIL';

export const CREATE_DOCUMENT_START      = 'CREATE_DOCUMENT_START';
export const CREATE_DOCUMENT            = 'CREATE_DOCUMENT';
export const CREATE_DOCUMENT_SUCCESS    = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAIL       = 'CREATE_DOCUMENT_FAIL';

export const UPDATE_DOCUMENT_START      = 'UPDATE_DOCUMENT_START';
export const UPDATE_DOCUMENT            = 'UPDATE_DOCUMENT';
export const UPDATE_DOCUMENT_SUCCESS    = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAIL       = 'UPDATE_DOCUMENT_FAIL';

export const DELETE_DOCUMENT_START      = 'DELETE_DOCUMENT_START';
export const DELETE_DOCUMENT            = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_SUCCESS    = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAIL       = 'DELETE_DOCUMENT_FAIL';
