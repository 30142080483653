import * as actionTypes from './types';

// ----------------------- GET BREAKDOWN -----------------------
export const getBreakdownsStart = () => {
  return {
    type: actionTypes.GET_BREAKDOWNS_START
  };
}

export const getBreakdowns = (org_id, countingdevices_id, start, stop) => {
  return {
    type: actionTypes.GET_BREAKDOWNS,
    org_id, countingdevices_id, start, stop
  };
}

export const getBreakdownsSuccess = (response) => {
  return {
    type: actionTypes.GET_BREAKDOWNS_SUCCESS,
    breakdowns: response.breakdowns
  };
}

export const getBreakdownsFail = (error) => {
  return {
    type: actionTypes.GET_BREAKDOWNS_FAIL,
    error: error
  };
}

// ----------------------- EDIT BREAKDOWN -----------------------
export const editBreakdownStart = () => {
  return {
    type: actionTypes.EDIT_BREAKDOWN_START
  };
}

export const editBreakdown = (id, description) => {
  return {
    type: actionTypes.EDIT_BREAKDOWN,
    id, description
  };
}

export const editBreakdownSuccess = (response) => {
  return {
    type: actionTypes.EDIT_BREAKDOWN_SUCCESS,
    updatedBreakdown: response.breakdown,
    result: response.result
  };
}

export const editBreakdownFail = (response) => {
  return {
    type: actionTypes.EDIT_BREAKDOWN_FAIL,
    result: response.result,
    error: response.error
  };
}

export const clearBreakdowns = () => {
  return {
    type: actionTypes.CLEAR_BREAKDOWNS
  };
}


// export default {
//   getBreakdowns,
//   getBreakdownsStart,
//   getBreakdownsSuccess,
//   getBreakdownsFail,
//   editBreakdown,
//   editBreakdownStart,
//   editBreakdownSuccess,
//   editBreakdownFail,
//   clearBreakdowns
// };