import * as actionTypes from './types';

// ----------------------- GET COUNTING_DEVICES -----------------------
export const getCountingDevicesListStart = () => {
  return {
    type: actionTypes.GET_COUNTING_DEVICES_LIST_START
  };
}

export const getCountingDevicesList = (org_id) => {
  return {
    type: actionTypes.GET_COUNTING_DEVICES_LIST,
    org_id
  };
}

export const getCountingDevicesListSuccess = (response) => {
  return {
    type: actionTypes.GET_COUNTING_DEVICES_LIST_SUCCESS,
    countingDevicesList: response.counting_devices_list
  };
}

export const getCountingDevicesListFail = (error) => {
  return {
    type: actionTypes.GET_COUNTING_DEVICES_LIST_FAIL,
    error: error
  };
}

// ----------------------- ADD COUNTING_DEVICES -----------------------
export const addCountingDevicesStart = () => {
  return {
    type: actionTypes.ADD_COUNTING_DEVICES_START
  };
}

export const addCountingDevices = (org_id, name, desc, product_device_socket_id, defect_device_socket_id, status_device_socket_id, status_threshold, status_delay, status_sum_method, properties) => {
  return {
    type: actionTypes.ADD_COUNTING_DEVICES,
    org_id,
    name, desc, product_device_socket_id, defect_device_socket_id, status_device_socket_id, status_threshold, status_delay, status_sum_method, properties
  };
}

export const addCountingDevicesSuccess = (response) => {
  return {
    type: actionTypes.ADD_COUNTING_DEVICES_SUCCESS,
    addedCountingDevices: response.counting_devices,
    result: response.result
  };
}

export const addCountingDevicesFail = (error) => {
  return {
    type: actionTypes.ADD_COUNTING_DEVICES_FAIL,
    error: error
  };
}

// ----------------------- EDIT COUNTING_DEVICES -----------------------
export const editCountingDevicesStart = () => {
  return {
    type: actionTypes.EDIT_COUNTING_DEVICES_START
  };
}

export const editCountingDevices = (id, name, desc, product_device_socket_id, defect_device_socket_id, status_device_socket_id, status_threshold, status_delay, status_sum_method, approvers, properties) => {
  return {
    type: actionTypes.EDIT_COUNTING_DEVICES,
    id,
    name, desc, product_device_socket_id, defect_device_socket_id, status_device_socket_id, status_threshold, status_delay, status_sum_method, approvers, properties
  };
}

export const editCountingDevicesSuccess = (response) => {
  return {
    type: actionTypes.EDIT_COUNTING_DEVICES_SUCCESS,
    updatedCountingDevices: response.counting_devices,
    result: response.result
  };
}

export const editCountingDevicesFail = (error) => {
  return {
    type: actionTypes.EDIT_COUNTING_DEVICES_FAIL,
    error: error
  };
}

// ----------------------- DELETE COUNTING_DEVICES -----------------------
export const deleteCountingDevicesStart = () => {
  return {
    type: actionTypes.DELETE_COUNTING_DEVICES_START
  };
}

export const deleteCountingDevices = (id) => {
  return {
    type: actionTypes.DELETE_COUNTING_DEVICES,
    id: id
  };
}

export const deleteCountingDevicesSuccess = (result) => {
  return {
    type: actionTypes.DELETE_COUNTING_DEVICES_SUCCESS,
    result: result
  };
}

export const deleteCountingDevicesFail = (error) => {
  return {
    type: actionTypes.DELETE_COUNTING_DEVICES_FAIL,
    error: error
  };
}

// export default {
//   getCountingDevicesListStart,
//   getCountingDevicesList,
//   getCountingDevicesListSuccess,
//   getCountingDevicesListFail,
//   addCountingDevices,
//   addCountingDevicesStart,
//   addCountingDevicesSuccess,
//   addCountingDevicesFail,
//   editCountingDevices,
//   editCountingDevicesStart,
//   editCountingDevicesSuccess,
//   editCountingDevicesFail,
//   deleteCountingDevices,
//   deleteCountingDevicesStart,
//   deleteCountingDevicesSuccess,
//   deleteCountingDevicesFail
// };