import * as actionTypes from './types';

// ----------------------- GET RULES -----------------------
export const getHooksStart = () => {
  return {
    type: actionTypes.GET_HOOKS_START
  };
}

export const getHooks = (org_id) => {
  return {
    type: actionTypes.GET_HOOKS,
    org_id
  };
}

export const getHooksSuccess = (response) => {
  return {
    type: actionTypes.GET_HOOKS_SUCCESS,
    hooks: response.hooks
  };
}

export const getHooksFail = (error) => {
  return {
    type: actionTypes.GET_HOOKS_FAIL,
    error: error
  };
}

// ----------------------- ADD RULE -----------------------
export const addHookStart = () => {
  return {
    type: actionTypes.ADD_HOOK_START
  };
}

export const addHook = (hookForm) => {
  return {
    type: actionTypes.ADD_HOOK,
    hookForm
  };
}

export const addHookSuccess = (response) => {
  return {
    type: actionTypes.ADD_HOOK_SUCCESS,
    addedHook: response.hooks[0],
    result: response.result
  };
}

export const addHookFail = (response) => {
  return {
    type: actionTypes.ADD_HOOK_FAIL,
    error: {
      text: response.error,
    }
  };
}

// ----------------------- ENABLE RULE -----------------------
export const enableHookStart = () => {
  return {
    type: actionTypes.ENABLE_HOOK_START
  };
}

export const enableHook = (id, enable) => {
  return {
    type: actionTypes.ENABLE_HOOK,
    id, enable
  };
}

export const enableHookSuccess = (response) => {
  return {
    type: actionTypes.ENABLE_HOOK_SUCCESS,
    updatedEnableHook: response.hooks[0],
    result: response.result
  };
}

export const enableHookFail = (response) => {
  return {
    type: actionTypes.ENABLE_HOOK_FAIL,
    error: response.error
  };
}

// ----------------------- EDIT RULE -----------------------
export const editHookStart = () => {
  return {
    type: actionTypes.EDIT_HOOK_START
  };
}

export const editHook = (id, hookForm) => {
  return {
    type: actionTypes.EDIT_HOOK,
    id, hookForm
  };
}

export const editHookSuccess = (response) => {
  return {
    type: actionTypes.EDIT_HOOK_SUCCESS,
    updatedHook: response.hooks[0],
    result: response.result
  };
}

export const editHookFail = (response) => {
  return {
    type: actionTypes.EDIT_HOOK_FAIL,
    error: {
      text: response.error,
    }
  };
}

// ----------------------- DELETE RULE -----------------------
export const deleteHookStart = () => {
  return {
    type: actionTypes.DELETE_HOOK_START
  };
}

export const deleteHook = (id) => {
  return {
    type: actionTypes.DELETE_HOOK,
    id: id
  };
}

export const deleteHookSuccess = (result) => {
  return {
    type: actionTypes.DELETE_HOOK_SUCCESS,
    result: result
  };
}

export const deleteHookFail = (error) => {
  return {
    type: actionTypes.DELETE_HOOK_FAIL,
    error: error
  };
}

// export default {
//   getHooksStart,
//   getHooks,
//   getHooksSuccess,
//   getHooksFail,
//   addHook,
//   addHookStart,
//   addHookSuccess,
//   addHookFail,
//   enableHook,
//   enableHookStart,
//   enableHookSuccess,
//   enableHookFail,
//   deleteHook,
//   deleteHookStart,
//   deleteHookSuccess,
//   deleteHookFail,
// };