import React, { Component } from 'react';

import Map from '../Map/Map';
import styles from './DrawingMap.module.scss';
import Button from '../../UI/Button/Button';

const shapeOptions = {
  editable: false,
  draggable: false,
  strokeColor: '#1665d8',
  strokeOpacity: 0.9,
  strokeWeight: 2,
  fillColor: '#1665d8',
  fillOpacity: 0.5,
}

class DrawingMap extends Component {
  mapRef = React.createRef();

  state = {
    selectedShape: null,
    fences: [],
    fencesObject: [],
    mapDim: null
  }

  componentDidMount() {
    this.setState({ mapDim: { width: this.mapRef.current.offsetWidth, height: 423 } });
  }

  createDataShape = (map, fencesData) => {
    let newFence = [...this.state.fences];
    let newFenceObject = [...this.state.fencesObject];
    fencesData.forEach((fence, index) => {
      let shapeType = fence.type;
      let newShape;
      if (shapeType === 'rectangle') {
        let rectangle = new window.google.maps.Rectangle({
          bounds: {
            north: fence.position.northEast.lat,
            south: fence.position.southWest.lat,
            east: fence.position.northEast.lng,
            west: fence.position.southWest.lng,
          },
          options: shapeOptions
        });
        rectangle.id = index;
        rectangle.type = 'rectangle';
        newShape = rectangle;
      } else if (shapeType === 'polygon') {
        const polygon = new window.google.maps.Polygon({
          paths: fence.position,
          options: shapeOptions
        });
        polygon.id = index;
        polygon.type = 'polygon';
        newShape = polygon;
      } else {
        const circle = new window.google.maps.Circle({
          center: fence.position.center,
          radius: fence.position.radius,
          options: shapeOptions,
        });
        circle.id = index;
        circle.type = 'circle';
        newShape = circle;
      }
      fence.id = index;
      newFence.push(fence);
      newFenceObject.push(newShape);
      this.onEditShape(newShape);
      this.onClickShape(newShape);
      newShape.setMap(map);
    });
    this.setState({ fences: newFence, fencesObject: newFenceObject });
  }

  onEditShape = (shape) => {
    const drawType = 'edit';
    if (shape.type === 'rectangle') {
      shape.addListener('bounds_changed', () => this.onCompleteDrawer(shape, drawType));
    } else if (shape.type === 'polygon') {
      shape.getPath().addListener('insert_at', () => this.onCompleteDrawer(shape, drawType));
      shape.getPath().addListener('set_at', () => this.onCompleteDrawer(shape, drawType));
    } else {
      shape.addListener('radius_changed', () => this.onCompleteDrawer(shape, drawType));
      shape.addListener('center_changed', () => this.onCompleteDrawer(shape, drawType));
    }
  }

  onClickShape = (shape) => {
    shape.addListener('click', (e) => {
      this.setSelection(shape);
    });
  }

  createSearchBox = (map) => {
    var input = document.createElement("INPUT");
    input.setAttribute("class", `${styles.SearchBox}`);
    var searchBox = new window.google.maps.places.SearchBox(input);
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
    // Bias the SearchBox results towards current map's viewport.
    map.addListener('bounds_changed', function () {
      searchBox.setBounds(map.getBounds());
    });
    var markers = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', function () {
      var places = searchBox.getPlaces();
      if (places.length === 0) {
        return;
      }
      // Clear out the old markers.
      markers.forEach(function (marker) {
        marker.setMap(null);
      });
      markers = [];
      // For each place, get the icon, name and location.
      var bounds = new window.google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        var icon = {
          url: place.icon,
          size: new window.google.maps.Size(71, 71),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(17, 34),
          scaledSize: new window.google.maps.Size(25, 25)
        };
        // Create a marker for each place.
        markers.push(new window.google.maps.Marker({
          map: map,
          icon: icon,
          title: place.name,
          position: place.geometry.location
        }));
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  }

  createDrawingManager = (map) => {
    let drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['circle', 'polygon', 'rectangle']
      },
      circleOptions: shapeOptions,
      polygonOptions: shapeOptions,
      rectangleOptions: shapeOptions,
    });

    drawingManager.addListener('overlaycomplete', (e) => {
      const drawType = 'new';
      let newShape = e.overlay;
      newShape.type = e.type;
      if (this.props.fencesData) {
        newShape.id = this.props.fencesData.length + e.overlay.zIndex;
      } else {
        newShape.id = e.overlay.zIndex;
      }
      drawingManager.setDrawingMode(null);
      if (e.vertex !== undefined) {
        if (newShape.type === window.google.maps.drawing.OverlayType.POLYGON) {
          var path = newShape.getPaths().getAt(e.path);
          path.removeAt(e.vertex);
          if (path.length < 3) {
            newShape.setMap(null);
          }
        }
      }
      this.onCompleteDrawer(newShape, drawType);
      this.onClickShape(newShape);
      this.setSelection(newShape);
      if (this.state.selectedShape) {
        this.onEditShape(newShape, newShape.type);
      }
    });
    drawingManager.addListener('drawingmode_changed', this.clearSelection);
    drawingManager.setMap(map);
  }

  clearSelection = () => {
    if (this.state.selectedShape) {
      this.state.selectedShape.setEditable(false);
      this.setState({
        selectedShape: null
      });
    }
  }

  setSelection = (shape) => {
    this.clearSelection();
    shape.setEditable(true);
    this.setState({ selectedShape: shape });
  }

  deleteSelectedShape = () => {
    if (this.state.selectedShape) {
      let newFence = [...this.state.fences];
      let newFenceObject = [...this.state.fencesObject];
      let deleteIndex = newFence.findIndex(fence => fence.id === this.state.selectedShape.id);
      newFence.splice(deleteIndex, 1);
      newFenceObject.splice(deleteIndex, 1);
      this.state.selectedShape.setMap(null);
      this.setState({ selectedShape: null, fences: newFence, fencesObject: newFenceObject });
    }
  }

  onCompleteDrawer = (shape, drawType) => {
    let newFence = [...this.state.fences];
    let newFenceObject = [...this.state.fencesObject];
    let newPosition;
    let newType;
    if (shape.type === 'polygon') {
      newType = 'polygon';
      let positions = [];
      for (let i = 0; i < shape.getPath().length; i++) {
        positions.push(
          { lat: shape.getPath().getAt(i).lat(), lng: shape.getPath().getAt(i).lng() }
        )
      };
      newPosition = {
        id: shape.id,
        type: newType,
        position: positions
      }
    } else if (shape.type === 'rectangle') {
      newType = 'rectangle';
      newPosition = {
        id: shape.id,
        type: newType,
        position: {
          northEast: { lat: shape.getBounds().getNorthEast().lat(), lng: shape.getBounds().getNorthEast().lng() },
          southWest: { lat: shape.getBounds().getSouthWest().lat(), lng: shape.getBounds().getSouthWest().lng() }
        }
      }
    } else {
      newType = 'circle';
      newPosition = {
        id: shape.id,
        type: newType,
        position: {
          center: { lat: shape.getCenter().lat(), lng: shape.getCenter().lng() }, radius: shape.getRadius()
        }
      }
    }

    if (drawType === 'new') {
      newFence.push(newPosition);
      newFenceObject.push(shape);
    } else {
      let editIndex = newFence.findIndex(fence => fence.id === shape.id);
      newFence[editIndex] = newPosition;
      newFenceObject[editIndex] = shape;
    }
    this.setState({ fences: newFence, fencesObject: newFenceObject });
  }

  checkMarkerOnFence = (marker, type) => {
    let onFence = false;
    this.state.fencesObject.forEach(fence => {
      if (fence.type === 'polygon') {
        if (window.google.maps.geometry.poly.containsLocation(marker.position, fence)) {
          if (type === 'gps') {
            console.log('on fence ' + fence.type + ' id ' + fence.id);
          }
          onFence = true;
        }
      } else {
        if (fence.getBounds().contains(marker.getPosition())) {
          if (type === 'gps') {
            console.log('on fence ' + fence.type + ' id ' + fence.id);
          }
          onFence = true;
        }
      }
    });
    if (!onFence && type === 'asset') {
      console.log('out of fence!');
    }
  }

  createMarker = (map, markerData) => {
    const marker = new window.google.maps.Marker({
      position: markerData.position,
      icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
    });
    marker.setMap(map);
    let bounds = this.createBounds(marker);
    map.setCenter(bounds.getCenter());
    let zoom = this.getBoundsZoomLevel(bounds);
    map.setZoom(zoom);
    const polyLine = new window.google.maps.Polyline({
      geodesic: true,
      strokeColor: '#e6492d',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    polyLine.setMap(map);
    // setInterval(() => {
    //   this.updatePosition(marker, map, polyLine, markerData.type)
    // }, 3000);
  }

  updatePosition = (marker, map, polyLine, type) => {
    marker.setPosition({ lat: marker.getPosition().lat() + 0.0001, lng: marker.getPosition().lng() + 0.0001 });
    let path = polyLine.getPath();
    path.push(marker.getPosition());
    map.setCenter(marker.getPosition());
    this.checkMarkerOnFence(marker, type);
  }


  getBoundsZoomLevel = (bounds) => {
    const { mapDim } = this.state;
    var WORLD_DIM = { height: 256, width: 256 };
    var ZOOM_MAX = 21;

    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    var latFraction = (this.latRad(ne.lat()) - this.latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = this.zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = this.zoom(mapDim.width, WORLD_DIM.width, lngFraction);
    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }

  latRad = (lat) => {
    var sin = Math.sin(lat * Math.PI / 180);
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  zoom = (mapPx, worldPx, fraction) => {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  createBounds = (marker) => {
    var bounds = new window.google.maps.LatLngBounds();
    bounds.extend(marker.getPosition());
    if (this.props.fencesData) {
      this.props.fencesData.forEach(shape => {
        if (shape.type === 'rectangle') {
          bounds.extend(shape.position.northEast);
          bounds.extend(shape.position.southWest);
        } else if (shape.type === 'polygon') {
          shape.position.forEach(position => {
            bounds.extend(position);
          });
        } else {
          bounds.extend(shape.position.center);
        }
      });
    }

    return bounds;
  }


  render() {
    return (
      <div>
        <div ref={this.mapRef}>
          {
            this.state.mapDim ?
              <Map
                id="myMapDrawing"
                options={{
                  mapTypeId: 'satellite',
                  gestureHandling: 'greedy',
                }}
                onMapLoad={map => {
                  if (this.props.fencesData) {
                    this.createDataShape(map, this.props.fencesData);
                  }
                  this.createSearchBox(map);
                  this.createDrawingManager(map);
                  if (this.props.markerData) {
                    this.createMarker(map, this.props.markerData);
                  }
                  map.addListener('click', this.clearSelection);
                }}
              /> : null
          }
        </div>
        <div className={styles.DeleteShapeButton}>
          <Button type="button" click={this.deleteSelectedShape} color="borderred" name="Delete Selected Shape" />
        </div>
      </div>
    );
  }

}

export default DrawingMap;