export const GET_SCHEDULERS_START = 'GET_SCHEDULERS_START';
export const GET_SCHEDULERS = 'GET_SCHEDULERS';
export const GET_SCHEDULERS_SUCCESS = 'GET_SCHEDULERS_SUCCESS';
export const GET_SCHEDULERS_FAIL = 'GET_SCHEDULERS_FAIL';

export const ADD_SCHEDULER_START = 'ADD_SCHEDULER_START';
export const ADD_SCHEDULER = 'ADD_SCHEDULER';
export const ADD_SCHEDULER_SUCCESS = 'ADD_SCHEDULER_SUCCESS';
export const ADD_SCHEDULER_FAIL = 'ADD_SCHEDULER_FAIL';

export const EDIT_SCHEDULER_START = 'EDIT_SCHEDULER_START';
export const EDIT_SCHEDULER = 'EDIT_SCHEDULER';
export const EDIT_SCHEDULER_SUCCESS = 'EDIT_SCHEDULER_SUCCESS';
export const EDIT_SCHEDULER_FAIL = 'EDIT_SCHEDULER_FAIL';

export const DELETE_SCHEDULER_START = 'DELETE_SCHEDULER_START';
export const DELETE_SCHEDULER = 'DELETE_SCHEDULER';
export const DELETE_SCHEDULER_SUCCESS = 'DELETE_SCHEDULER_SUCCESS';
export const DELETE_SCHEDULER_FAIL = 'DELETE_SCHEDULER_FAIL';

export const GET_SCHEDULER_TYPES_START = 'GET_SCHEDULER_TYPES_START';
export const GET_SCHEDULER_TYPES = 'GET_SCHEDULER_TYPES';
export const GET_SCHEDULER_TYPES_SUCCESS = 'GET_SCHEDULER_TYPES_SUCCESS';
export const GET_SCHEDULER_TYPES_FAIL = 'GET_SCHEDULER_TYPES_FAIL';

export const ADD_SCHEDULER_TYPE_START = 'ADD_SCHEDULER_TYPE_START';
export const ADD_SCHEDULER_TYPE = 'ADD_SCHEDULER_TYPE';
export const ADD_SCHEDULER_TYPE_SUCCESS = 'ADD_SCHEDULER_TYPE_SUCCESS';
export const ADD_SCHEDULER_TYPE_FAIL = 'ADD_SCHEDULER_TYPE_FAIL';

export const EDIT_SCHEDULER_TYPE_START = 'EDIT_SCHEDULER_TYPE_START';
export const EDIT_SCHEDULER_TYPE = 'EDIT_SCHEDULER_TYPE';
export const EDIT_SCHEDULER_TYPE_SUCCESS = 'EDIT_SCHEDULER_TYPE_SUCCESS';
export const EDIT_SCHEDULER_TYPE_FAIL = 'EDIT_SCHEDULER_TYPE_FAIL';

export const DELETE_SCHEDULER_TYPE_START = 'DELETE_SCHEDULER_TYPE_START';
export const DELETE_SCHEDULER_TYPE = 'DELETE_SCHEDULER_TYPE';
export const DELETE_SCHEDULER_TYPE_SUCCESS = 'DELETE_SCHEDULER_TYPE_SUCCESS';
export const DELETE_SCHEDULER_TYPE_FAIL = 'DELETE_SCHEDULER_TYPE_FAIL';