export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  // let error = null;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
    // error = "This field is required.";
  }

  if (rules.minLength || rules.minLength === 0) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength || rules.maxLength === 0) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.min || rules.min === 0) {
    isValid = +value >= rules.min && isValid;
    // error = "The value must be more than or equal " + rules.minLength + ".";
  }

  if (rules.max || rules.max === 0) {
    isValid = +value <= rules.max && isValid;
    // error = "The value must be less than or equal " + rules.maxLength + ".";
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
    // error = "Please enter a valid email address.";
  }

  if (rules.isMobileNumber) {
    const pattern = /^[0][689][0-9]{8}$/;
    isValid = pattern.test(value) && isValid;
    // error = "Please enter a valid phone number.";
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
    // error = "This field must be a number.";
  }

  if (rules.ipAddress) {
    const pattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    isValid = pattern.test(value) && isValid;
  }

  // return { isValid: isValid, error: error };
  return isValid;
}

export const isEquivalent = (objA, objB) => {
  if (objA === objB) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null ||
    typeof objB !== 'object' || objB === null) {
    return false;
  }

  var keysA = Object.keys(objA);
  var keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  var bHasOwnProperty = hasOwnProperty.bind(objB);
  for (var i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}

export const getTimeByLongDate = (longdate, showDate, showSeconds) => {
  let d = new Date(longdate);
  let day = "" + d.getDate();
  let month = "" + (d.getMonth() + 1);
  let year = "" + d.getFullYear();
  let hh = (d.getHours() < 10) ? "0" + d.getHours() : "" + d.getHours();
  let mm = (d.getMinutes() < 10) ? "0" + d.getMinutes() : "" + d.getMinutes();
  let ss = (d.getSeconds() < 10) ? "0" + d.getSeconds() : "" + d.getSeconds();
  let res = hh + ":" + mm;
  if (showDate) {
    res = day + "/" + month + "/" + year + " " + res;
  }
  if (showSeconds) {
    res += ":" + ss;
  }
  return res;
}

export const calculateNewValFromEquation = (val, eqs) => {
  let res = val;
  if (eqs && eqs.length > 0) {
    eqs.forEach(eq => {
      switch (eq.operation) {
        case "+": res += eq.value; break;
        case "-": res -= eq.value; break;
        case "x": res = parseFloat((res * eq.value)); break;
        case "/": res = parseFloat((res / eq.value)); break;
        default: break;
      }
    });
  }
  return parseFloat(res.toFixed(2));
}

export const calculateTime = (difference) => {
  let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24
  let hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60
  let minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60
  let secondsDifference = Math.round(difference / 1000);

  let totalHoursDifference = daysDifference * 24 + hoursDifference;
  if (totalHoursDifference < 10) {
    totalHoursDifference = '0' + totalHoursDifference;
  }
  if (minutesDifference < 10) {
    minutesDifference = '0' + minutesDifference;
  }
  if (secondsDifference < 10) {
    secondsDifference = '0' + secondsDifference;
  }
  return ({ hour: totalHoursDifference, min: minutesDifference, sec: secondsDifference });
}