import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { ADD_COMMAND } from './types';

export function* watchCommand() {
  yield takeEvery(ADD_COMMAND, addCommandSaga);
}

export function* addCommandSaga(params) {
  yield put(actions.addCommandStart());

  let postData = "org_id=" + params.org_id;
  postData += "&device_socket_id=" + params.device_socket_id;
  postData += "&value=" + params.value;

  const api = 'v1/command';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD COMMAND SUCCESS", response);
    yield put(actions.addCommandSuccess(response.data));
  } catch (err) {
    console.log("ADD COMMAND FAILED", err.response.data);
    yield put(actions.addCommandFail(err.response.data.error));
  }
}