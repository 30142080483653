
import axios from '../../../configs/axios-orders';
import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_ORGS, ADD_ORG, EDIT_ORG, DELETE_ORG, INVITE_MEMBER, REMOVE_MEMBER, INVITE_ADMIN, REMOVE_ADMIN, GET_ADMINS, GEN_API_KEY } from './types';
import { push } from 'connected-react-router';

export function* watchOrg() {
  yield takeEvery(GET_ORGS, getOrgsSaga);
  yield takeEvery(ADD_ORG, addOrgSaga);
  yield takeEvery(EDIT_ORG, editOrgSaga);
  yield takeEvery(DELETE_ORG, deleteOrgSaga);
  yield takeEvery(INVITE_MEMBER, inviteMemberSaga);
  yield takeEvery(REMOVE_MEMBER, removeMemberSaga);
  yield takeEvery(GET_ADMINS, getAdminsSaga);
  yield takeEvery(INVITE_ADMIN, inviteAdminSaga);
  yield takeEvery(REMOVE_ADMIN, removeAdminSaga);
  yield takeEvery(GEN_API_KEY, genAPIKeySega);
}

export function* getOrgsSaga(params) {
  yield put(actions.getOrgsStart());

  let api = 'v1/org';
  if (params.id) {
    api += '/' + params.id;
  }

  api += params.name ? '?name=' + params.name : '';

  try {
    const response = yield axios.get(api);
    const loggedInUser = JSON.parse(localStorage.getItem("owlUser"));
    const currentOrg = localStorage.getItem("owlCurrentOrg");
    if (loggedInUser && loggedInUser.is_superuser && !currentOrg) {
      // console.log('SET ORG FOR ADMIN ', response.data.organizations[0].id);
      yield localStorage.setItem('owlCurrentOrg', response.data.organizations[0].id);
      yield put(actions.setCurrentOrg(response.data.organizations[0].id));
    }
    yield put(actions.getOrgsSuccess(response.data));
  } catch (err) {
    console.log("GET ORGS FAILED", err);
    yield put(actions.getOrgsFail(err.response.data));
  }
}

export function* addOrgSaga(params) {
  yield put(actions.addOrgStart());

  let postData = 'name=' + encodeURIComponent(params.name);
  postData += "&street_address=" + encodeURIComponent(params.street_address);
  postData += "&province=" + encodeURIComponent(params.province);
  postData += "&country=" + encodeURIComponent(params.country);
  postData += "&post_number=" + encodeURIComponent(params.post_number);
  postData += params.tax_id ? '&tax_id=' + encodeURIComponent(params.tax_id) : '';
  postData += params.tel ? '&tel=' + encodeURIComponent(params.tel) : '';
  postData += params.logo ? '&logo=' + encodeURIComponent(params.logo) : '';

  const api = 'v1/org';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD ORG SUCCESS", response);
    yield localStorage.setItem('owlCurrentOrg', response.data.organization.id);
    yield put(actions.setCurrentOrg(response.data.organization.id));
    yield put(actions.addOrgSuccess(response.data));
    yield put(push('/pages'));
  } catch (err) {
    console.log("ADD ORG FAILED", err.response.data);
    yield call([localStorage, 'removeItem'], 'owlCurrentOrg');
    yield put(actions.addOrgFail(err.response.data));
  }
}

export function* editOrgSaga(params) {
  yield put(actions.editOrgStart());

  let putData = 'name=' + encodeURIComponent(params.name);
  putData += "&street_address=" + encodeURIComponent(params.street_address);
  putData += "&province=" + encodeURIComponent(params.province);
  putData += "&country=" + encodeURIComponent(params.country);
  putData += "&post_number=" + encodeURIComponent(params.post_number);
  putData += '&tax_id=' + encodeURIComponent(params.tax_id);
  putData += '&tel=' + encodeURIComponent(params.tel);
  putData += params.logo || params.logo === '' ? '&logo=' + encodeURIComponent(params.logo) : '';

  const api = 'v1/org/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT ORG SUCCESS", response.data);
    yield put(actions.editOrgSuccess(response.data));
  } catch (err) {
    console.log("EDIT ORG FAILED", err.response.data);
    yield put(actions.editOrgFail(err.response.data));
  }
}

export function* deleteOrgSaga(params) {
  yield put(actions.deleteOrgStart());

  const api = 'v1/org/' + params.id;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE ORG SUCCESS", response.data);
    const currentOrg = parseInt(localStorage.getItem("owlCurrentOrg"));
    if (params.id === currentOrg) {
      const getOrgApi = 'v1/org';
      const response = yield axios.get(getOrgApi);
      if (response.data.organizations) {
        const orgId = response.data.organizations[0].id;
        yield localStorage.setItem('owlCurrentOrg', orgId);
        yield put(actions.setCurrentOrg(orgId));
      } else {
        yield call([localStorage, 'removeItem'], 'owlCurrentOrg');
        yield put(actions.clearCurrentOrg());
        yield put(push('/create-organization'));
      }
    }
    yield put(actions.deleteOrgSuccess(response.data));
  } catch (err) {
    console.log("DELETE ORG FAILED", err.response);
    yield put(actions.deleteOrgFail(err.response.data));
  }
}

export function* inviteMemberSaga(params) {
  yield put(actions.inviteMemberStart());

  const api = 'v1/orgmember/' + params.id + '?email=' + params.email;

  try {
    const response = yield axios.put(api);
    console.log("INVITE MEMBER SUCCESS", response.data);
    yield put(actions.inviteMemberSuccess(response.data.result));
  } catch (err) {
    console.log("INVITE MEMBER FAILED", err.response);
    yield put(actions.inviteMemberFail(err.response));
  }
}

export function* removeMemberSaga(params) {
  yield put(actions.removeMemberStart());

  const api = 'v1/orgmember/' + params.id + '?transfer_uid=' + params.transfer_uid + '&email=' + params.email;

  try {
    const response = yield axios.delete(api);
    console.log("REMOVE MEMBER SUCCESS", response.data);
    yield put(actions.removeMemberSuccess(response.data.result));
  } catch (err) {
    console.log("REMOVE MEMBER FAILED", err.response);
    yield put(actions.removeMemberFail(err.response));
  }
}

export function* inviteAdminSaga(params) {
  yield put(actions.inviteAdminStart());

  const api = 'v1/orgadmin/' + params.id + '/' + params.user_id;

  try {
    const response = yield axios.put(api);
    console.log("INVITE ADMIN SUCCESS", response.data);
    yield put(actions.inviteAdminSuccess(response.data));
  } catch (err) {
    console.log("INVITE ADMIN FAILED", err.response);
    yield put(actions.inviteAdminFail(err.response));
  }
}

export function* removeAdminSaga(params) {
  yield put(actions.removeAdminStart());

  const api = 'v1/orgadmin/' + params.id + '/' + params.user_id;

  try {
    const response = yield axios.delete(api);
    console.log("REMOVE ADMIN SUCCESS", response.data);
    yield put(actions.removeAdminSuccess(response.data.result));
  } catch (err) {
    console.log("REMOVE ADMIN FAILED", err.response);
    yield put(actions.removeAdminFail(err.response));
  }
}

export function* getAdminsSaga(params) {
  yield put(actions.getAdminsStart());

  let api = 'v1/orgadmin/' + params.id;

  try {
    const response = yield axios.get(api);
    console.log("GET ADMINS SUCCESS", response.data);
    yield put(actions.getAdminsSuccess(response.data));
  } catch (err) {
    console.log("GET ADMINS FAILED", err)
    yield put(actions.getAdminsFail(err.response.data));
  }
}

export function* genAPIKeySega(params) {
  yield put(actions.genAPIKeyStart());

  const api = 'v1/orgapikey/' + params.id;

  try {
    const response = yield axios.put(api);
    console.log("GEN API KEY SUCCESS", response.data);
    yield put(actions.genAPIKeySuccess(response.data));
  } catch (err) {
    console.log("GEN API KEY FAILED", err.response);
    yield put(actions.genAPIKeyFail(err.response));
  }
}