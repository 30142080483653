import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  ruleConditions: null,
  addedRuleCondition: null,
  updatedRuleCondition: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RULE_CONDITIONS_START: return getRuleConditionsStart(state, action);
    case actionTypes.GET_RULE_CONDITIONS_SUCCESS: return getRuleConditionsSuccess(state, action);
    case actionTypes.GET_RULE_CONDITIONS_FAIL: return getRuleConditionsFail(state, action);

    case actionTypes.ADD_RULE_CONDITION_START: return addRuleConditionStart(state, action);
    case actionTypes.ADD_RULE_CONDITION_SUCCESS: return addRuleConditionSuccess(state, action);
    case actionTypes.ADD_RULE_CONDITION_FAIL: return addRuleConditionFail(state, action);

    case actionTypes.EDIT_RULE_CONDITION_START: return editRuleConditionStart(state, action);
    case actionTypes.EDIT_RULE_CONDITION_SUCCESS: return editRuleConditionSuccess(state, action);
    case actionTypes.EDIT_RULE_CONDITION_FAIL: return editRuleConditionFail(state, action);
    
    case actionTypes.DELETE_RULE_CONDITION_START: return deleteRuleConditionStart(state, action);
    case actionTypes.DELETE_RULE_CONDITION_SUCCESS: return deleteRuleConditionSuccess(state, action);
    case actionTypes.DELETE_RULE_CONDITION_FAIL: return deleteRuleConditionFail(state, action);

    default: return state;
  }
};

const getRuleConditionsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getRuleConditionsSuccess = (state, action) => {
  return updateObject(state, {
    ruleConditions: action.ruleConditions,
    error: null,
    loading: false
  });
}

const getRuleConditionsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addRuleConditionStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addRuleConditionSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedRuleCondition: action.addedRuleCondition,
    error: null,
    loading: false
  });
}

const addRuleConditionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editRuleConditionStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editRuleConditionSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedRuleCondition: action.updatedRuleCondition,
    error: null,
    loading: false
  });
}

const editRuleConditionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteRuleConditionStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteRuleConditionSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteRuleConditionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;