import * as actionTypes from './types';

// ----------------------- GET NOTIFICATIONS -----------------------
export const getNotificationsStart = () => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_START
  };
}

export const getNotifications = (org_id, start, end, offset, limit) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS,
    org_id, start, end, offset, limit
  };
}

export const getNotificationsSuccess = (response) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
    notifications: response.notifications
  };
}

export const getNotificationsFail = (error) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_FAIL,
    error
  };
}

// ----------------------- ADD NOTIFICATION -----------------------

export const addNotificationStart = () => {
  return {
    type: actionTypes.ADD_NOTIFICATION_START
  };
}

export const addNotification = (title, content, notif_type, org_ids, start, end) => {
  return {
    type: actionTypes.ADD_NOTIFICATION,
    title, content, notif_type, org_ids, start, end
  };
}

export const addNotificationSuccess = (response) => {
  return {
    type: actionTypes.ADD_NOTIFICATION_SUCCESS,
    addedNotification: response.notifications,
    result: response.result
  };
}

export const addNotificationFail = (error) => {
  return {
    type: actionTypes.ADD_NOTIFICATION_FAIL,
    error
  };
}

// ----------------------- UPDATE NOTIFICATION -----------------------

export const updateNotificationStart = () => {
  return {
    type: actionTypes.UPDATE_NOTIFICATION_START
  };
}

export const updateNotification = (uuid, title, content) => {
  return {
    type: actionTypes.UPDATE_NOTIFICATION,
    uuid, title, content
  };
}

export const updateNotificationSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_NOTIFICATION_SUCCESS,
    updatedNotification: response.notifications,
    result: response.result
  };
}

export const updateNotificationFail = (error) => {
  return {
    type: actionTypes.UPDATE_NOTIFICATION_FAIL,
    error
  };
}

// ----------------------- DELETE_NOTIFICATIONG -----------------------
export const deleteNotificationStart = () => {
  return {
    type: actionTypes.DELETE_NOTIFICATION_START
  };
}

export const deleteNotification = (uuid) => {
  return {
    type: actionTypes.DELETE_NOTIFICATION,
    uuid
  };
}

export const deleteNotificationSuccess = (response) => {
  return {
    type: actionTypes.DELETE_NOTIFICATION_SUCCESS,
    notifications: response.notifications,
    result: response.result
  };
}

export const deleteNotificationFail = (error) => {
  return {
    type: actionTypes.DELETE_NOTIFICATION_FAIL,
    error
  };
}

// ----------------------- GET NOTIFICATION INBOX -----------------------

export const getNotificationsInboxStart = () => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_INBOX_START
  };
}

export const getNotificationsInbox = (offset, limit) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_INBOX,
    offset, limit
  };
}

export const getNotificationsInboxSuccess = (response) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_INBOX_SUCCESS,
    inbox: response.inbox
  };
}

export const getNotificationsInboxFail = (error) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_INBOX_FAIL,
    error
  };
}

// ----------------------- MARK AS READ -----------------------

export const markAsReadStart = () => {
  return {
    type: actionTypes.MARK_AS_READ_START
  };
}

export const markAsRead = (uuids) => {
  return {
    type: actionTypes.MARK_AS_READ,
    uuids
  };
}

export const markAsReadSuccess = (response) => {
  return {
    type: actionTypes.MARK_AS_READ_SUCCESS,
    inbox: response.inbox
  };
}

export const markAsReadFail = (error) => {
  return {
    type: actionTypes.MARK_AS_READ_FAIL,
    error
  };
}

// ----------------------- GET NOTIFICATION TOPBAR  -----------------------

export const getNotificationTopbarStart = () => {
  return {
    type: actionTypes.GET_NOTIFICATION_TOPBAR_START
  };
}

export const getNotificationTopbar = () => {
  return {
    type: actionTypes.GET_NOTIFICATION_TOPBAR,

  };
}

export const getNotificationTopbarSuccess = (response) => {
  return {
    type: actionTypes.GET_NOTIFICATION_TOPBAR_SUCCESS,
    notificationTopbar: response.notifications
  };
}

export const getNotificationTopbarFail = (error) => {
  return {
    type: actionTypes.GET_NOTIFICATION_TOPBAR_FAIL,
    error
  };
}