import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './NoteType.module.scss';
import Button from '../../components/UI/Button/Button';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import NoteTypeList from '../../components/NoteType/NoteTypeList';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import NoteTypeDrawer from '../../components/NoteType/NoteTypeDrawer';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';

import { deleteNoteType } from '../../../state/ducks/Document/actions';

const MENU_LIST = ['Type'];

class NoteType extends Component {
  state = {
    currentTabIndex: 0,
    isAdding: false,
    editingNoteType: null,
    isDrawerOpened: false,
    isDeleteModalOpened: false,
    deletingNoteTypeId: null
  };

  openDrawerForAddingHandler = () => {
    this.setState({ isAdding: true, editingNoteType: null, isDrawerOpened: true });
  }

  drawerToggleHandler = () => {
    this.setState(prevState => {
      return { isDrawerOpened: !prevState.isDrawerOpened }
    });
  }

  onEditNoteTypeHandler = (event, noteType) => {
    event.stopPropagation();
    this.setState({ editingNoteType: noteType, isDrawerOpened: true, isAdding: false });
  }

  onDeleteNoteTypeHandler = () => {
    this.setState({ deletingNoteTypeId: this.state.editingNoteType.id, isDeleteModalOpened: true });
  }

  onConfirmDeleteNoteType = (event) => {
    event.preventDefault();
    this.props.deleteNoteType(this.state.deletingNoteTypeId);
    this.setState({ deletingNoteTypeId: null, isDeleteModalOpened: false, isDrawerOpened: false, editingNoteType: null });
  }

  render() {
    return (
      <div className={styles.NoteType}>
        <SideDrawer title={this.state.isAdding ? 'Add New Type' : 'Edit Type'} open={this.state.isDrawerOpened} closed={this.drawerToggleHandler}>
          <NoteTypeDrawer
            isDrawerOpened={this.state.isDrawerOpened}
            closed={this.drawerToggleHandler}
            editingNoteType={this.state.editingNoteType}
            isAdding={this.state.isAdding}
            toggleDeleteModal={this.onDeleteNoteTypeHandler}
          />
        </SideDrawer>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              MENU_LIST.map((menuTitle, index) => (
                <div key={`note-type-${index}`} className={index === this.state.currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentNoteTypeIndex: index })}>
                  <span className={styles.MenuTitle}>{menuTitle}</span>
                </div>
              ))
            }
          </div>
          <div className={styles.AddButtonWrapper}>
            <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Type" noMargin click={this.openDrawerForAddingHandler} />
          </div>
        </div>
        <div className="MainContentPadding">
          <NoteTypeList editNoteType={this.onEditNoteTypeHandler} />
        </div>
        <DeleteModal
          title="batch"
          open={this.state.isDeleteModalOpened}
          modalClosed={() => this.setState({ isDeleteModalOpened: false })}
          confirmDelete={this.onConfirmDeleteNoteType}
        />
      </div>
    );
  }
}

export default connect(null, { deleteNoteType })(NoteType);
