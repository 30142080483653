import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_SEQUENTIAL_COMMAND, GET_SEQUENTIAL_COMMANDS, START_SEQUENTIAL_COMMAND, STOP_SEQUENTIAL_COMMAND } from './types';

export function* watchSequentialCommand() {
  yield takeEvery(GET_SEQUENTIAL_COMMAND, getSequentialCommandSaga);
  yield takeEvery(GET_SEQUENTIAL_COMMANDS, getSequentialCommandsSaga);
  yield takeEvery(START_SEQUENTIAL_COMMAND, startSequentialCommandSaga);
  yield takeEvery(STOP_SEQUENTIAL_COMMAND, stopSequentialCommandSaga);
}

export function* getSequentialCommandSaga(params) {
  yield put(actions.getSequentialCommandStart());

  let api = 'v1/sequencecommand/' + params.id;

  try {
    const response = yield axios.get(api);
    // console.log("GET A_SEQUENTIAL_COMMAND SUCCESS", response.data);
    yield put(actions.getSequentialCommandSuccess(response.data));
  } catch (err) {
    console.log("GET A_SEQUENTIAL_COMMAND FAILED", err)
    yield put(actions.getSequentialCommandFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* getSequentialCommandsSaga(params) {
  yield put(actions.getSequentialCommandsStart());

  let api = 'v1/sequencecommand';

  api += "?secret=" + params.secret;
  api += "&socket=" + params.socket;

  try {
    const response = yield axios.get(api);
    // console.log("GET_SEQUENTIAL_COMMANDSS SUCCESS", response.data);
    yield put(actions.getSequentialCommandsSuccess(response.data, params.wid));
  } catch (err) {
    console.log("GET_SEQUENTIAL_COMMANDSS FAILED", err)
    yield put(actions.getSequentialCommandsFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* startSequentialCommandSaga(params) {
  yield put(actions.startSequentialCommandStart());

  let api = 'v1/sequencecommand';

  let postData = "secret=" + params.secret;
  postData += "&socket=" + params.socket;
  postData += "&values=" + encodeURIComponent(params.values);
  postData += params.ref ? "&ref=" + params.ref : '';

  try {
    const response = yield axios.post(api, postData);
    // console.log("START_SEQUENTIAL_COMMAND SUCCESS", response.data);
    yield put(actions.startSequentialCommandSuccess(response.data));
  } catch (err) {
    console.log("START_SEQUENTIAL_COMMAND FAILED", err)
    yield put(actions.startSequentialCommandFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* stopSequentialCommandSaga(params) {
  yield put(actions.stopSequentialCommandStart());

  const api = 'v1/sequencecommand/' + params.id;

  try {
    const response = yield axios.put(api);
    // console.log("STOP_SEQUENTIAL_COMMAND SUCCESS", response.data);
    yield put(actions.stopSequentialCommandSuccess(response.data));
  } catch (err) {
    console.log("STOP_SEQUENTIAL_COMMAND FAILED", err)
    yield put(actions.stopSequentialCommandFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}