import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import styles from '../Report.module.scss';
import MultipleSelect from 'react-select';

import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';
import ViewIcon from '../../../../assets/icons/Report/view.svg';
import Tick from '../../../../assets/icons/tick-square.svg';
import Untick from '../../../../assets/icons/untick-square.png';
import Button from '../../UI/Button/Button';

import { getActualProduction } from '../../../../state/ducks/AdvanceActualProduction/actions';
import { getMachines } from '../../../../state/ducks/Machine/actions';
import { getModuleConfigs } from '../../../../state/ducks/Module/actions';
import { getApqOfMachine } from '../../../../state/ducks/Machine/actions';
import { getProductionPlanStructures } from '../../../../state/ducks/AdvanceProductionPlanStructure/actions';
import Modal from '../../UI/Modal/Modal';
import ActualOEE from './ActualOEE';
import TotalOEE from './TotalOEE';

const colourStyles = {
    control: (base, state) => ({
        ...base,
        backgroundColor: "#1c1c1f",
        borderRadius: 4,
        borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
        "&:hover": {
            borderColor: "#1665d8"
        },
        fontSize: 14,
        fontWeight: 500
    }),
    input: base => ({
        ...base,
        color: 'white'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            borderColor: "#1c1c1f",
            backgroundColor: isDisabled
                ? null
                : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
        };
    },
    menu: base => ({
        ...base,
        marginTop: 0
    }),
    menuList: base => ({
        ...base,
        backgroundColor: "#1c1c1f",
        fontSize: 14,
        fontWeight: 500
    }),
    multiValue: base => ({
        ...base,
        backgroundColor: "#1665d8",
    }),
    multiValueLabel: base => ({
        ...base,
        color: "white",
    })
};

const metricsOptions = [
    { id: 'oee', label: 'OEE', color: '#F79C65' }, //FFB5BF
    { id: 'a', label: 'Availability', color: '#6F97B1' },
    { id: 'p', label: 'Performance', color: '#AEC8A6' },
    { id: 'q', label: 'Quality', color: '#8ADFE3' },
]

class OEEReport extends Component {

    state = {
        options: [],
        selectedMachines: [],
        selectedMetrics: [...metricsOptions],
        errorMessage: '',
        actualProductions: null,
        startTime: '',
        endTime: '',
        startDate: '',
        endDate: '',
        isFloating: true,
        conclusionOEE: {},
        displayOverHundred: true
    };

    componentDidMount() {
        this.props.getProductionPlanStructures(this.props.currentOrg);
        this.props.getModuleConfigs(this.props.currentOrg, 3);
        this.setDefaultDateTime();
        if (!this.props.machines || this.props.machines.length === 0) {
            this.props.getMachines(this.props.currentOrg);
        } else {
            this.reInitOptions();
        }
    }

    setDefaultDateTime = () => {
        let defaultStop = new Date().getTime();
        let defaultStart = defaultStop - 3600000;
        this.setState({
            startDate: moment(defaultStart).format('YYYY-MM-DD'),
            startTime: moment(defaultStart).format('00:00'),
            endDate: moment(defaultStop).format('YYYY-MM-DD'),
            endTime: moment(defaultStop).format('HH:mm'),
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.machines !== this.props.machines) {
            if (!this.props.machines || this.props.machines.length === 0)
                this.props.getMachines(this.props.currentOrg);
            else
                this.reInitOptions();
        }
        if (prevProps.actualProductions !== this.props.actualProductions && this.props.actualProductions) {
            this.setState({ actualProductions: this.props.actualProductions });
        }
        if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg) {
            this.props.getProductionPlanStructures(this.props.currentOrg);
            this.props.getModuleConfigs(this.props.currentOrg, 3);
            this.setState({ actualProductions: null });
        }
        if (prevProps.moduleConfigs !== this.props.moduleConfigs && this.props.moduleConfigs) {
            let displayOverHundred = this.props.moduleConfigs.find(mc => mc.key === 'apqOver');
            displayOverHundred = displayOverHundred && displayOverHundred.value === 'true' ? true : false;
            this.setState({ displayOverHundred });
        }
        if (prevProps.machineAPQ !== this.props.machineAPQ && this.props.machineAPQ) {
            this.displayTotalOeeFromMachineAPQ(this.props.machineAPQ)
        }
    }

    reInitOptions = () => {
        const { machines } = this.props;
        const options = machines.map(m => ({
            id: m.id,
            label: m.name,
        }));
        this.setState({ options });
    }

    onSelectMachine = (value) => {
        this.setState({ selectedMachines: value });
    }

    onSelectMetrics = (value) => {
        // if (!value || value?.length === 0)
        //     this.setState({ selectedMetrics: [metricsOptions[0]] });
        // else
        this.setState({ selectedMetrics: value });
    }

    onTimeChange = (name, value) => {
        this.setState({ [name]: value });
    };

    onSubmitReportHandler = (e) => {
        e.preventDefault();
        const { selectedMachines, startDate, startTime, endDate, endTime } = this.state;
        const { currentOrg } = this.props;

        if (!selectedMachines || selectedMachines.length === 0) {
            this.setState({ errorMessage: 'Please select at least one machine' });
            return;
        }

        const start = new Date(startDate + ' ' + startTime);
        const end = new Date(endDate + ' ' + endTime);

        if (start > end) {
            this.setState({ errorMessage: 'Start date must be before end date' });
            return;
        }
        this.setState({ conclusionOEE: {} });
        this.props.getActualProduction(currentOrg, selectedMachines.map(m => m.id), '', start.getTime(), end.getTime());
        selectedMachines.forEach(m => {
            this.props.getApqOfMachine(m.id, start.getTime(), end.getTime(), { machine_id: m.id });
        });
    }

    force2Position = (num) => {
        if (num > 9)
            return '' + num;
        return '0' + num;
    }

    displayTotalOeeFromMachineAPQ = (machineAPQ) => {
        const { value, properties } = machineAPQ;
        const calculatingOnMachineId = properties.machine_id;
        let conclusionOEE = { ...this.state.conclusionOEE };
        if (!conclusionOEE.round) {
            conclusionOEE.round = 1;
            conclusionOEE.totalAva = { sum: 0, percent: 0 };
            conclusionOEE.totalPer = { sum: 0, percent: 0 };
            conclusionOEE.totalQua = { sum: 0, percent: 0 };
            conclusionOEE.totalOEE = { sum: 0, percent: 0 };
        }

        conclusionOEE.totalAva.sum += value.a ?? 0;
        conclusionOEE.totalAva.percent = conclusionOEE.totalAva.sum / conclusionOEE.round;
        conclusionOEE.totalPer.sum += value.p ?? 0;
        conclusionOEE.totalPer.percent = conclusionOEE.totalPer.sum / conclusionOEE.round;
        conclusionOEE.totalQua.sum += value.q ?? 0;
        conclusionOEE.totalQua.percent = conclusionOEE.totalQua.sum / conclusionOEE.round;
        const oee = value.a * value.p * value.q / 10000;
        conclusionOEE.totalOEE.sum += oee;
        conclusionOEE.totalOEE.percent += conclusionOEE.totalOEE.sum / conclusionOEE.round;

        conclusionOEE[calculatingOnMachineId] = {};
        conclusionOEE[calculatingOnMachineId].percentAva = value.a;
        conclusionOEE[calculatingOnMachineId].percentPer = value.p;
        conclusionOEE[calculatingOnMachineId].percentQua = value.q;
        conclusionOEE[calculatingOnMachineId].percentOEE = oee;
        conclusionOEE.round = conclusionOEE.round + 1;
        this.setState({ conclusionOEE });
    }

    // DP ?
    displayTotalOee = (actual, actualOEE) => {
        const calculatingOnMachineId = actual.machine ? actual.machine.id : 'N/A';
        let conclusionOEE = { ...this.state.conclusionOEE };
        if (actualOEE && actualOEE.apq?.length) {
            let runTime = 0, totalTime = 0;
            let good = 0, total = 0;
            actualOEE.apq.forEach(ea => {
                runTime += ea.runtime;
                totalTime += ea.total_time;
                good += ea.good;
                total += ea.total;
            });
            let totalExpected = total * actualOEE.expected;
            let diffDays = new Date(actual.end).getTime() - new Date(actual.start).getTime();

            // for Total OEE
            if (!conclusionOEE.totalAva) {
                conclusionOEE.totalAva = { runTime, totalTime, percent: 0 };
                conclusionOEE.totalPer = { sumTotalExpected: totalExpected, sumDiffDays: diffDays, percent: 0 };
                conclusionOEE.totalQua = { good, total, percent: 0 };
                conclusionOEE.round = 1;
                if (this.props.actualProductions && conclusionOEE.round === this.props.actualProductions.length) {
                    // only 1 round = 1 po.
                    const ava = conclusionOEE.totalAva.runTime * 100 / conclusionOEE.totalAva.totalTime;
                    const per = totalExpected * 100 / diffDays;
                    const qua = conclusionOEE.totalQua.good * 100 / conclusionOEE.totalQua.total;
                    const oee = ava * per * qua / 10000;
                    conclusionOEE.totalAva.percent = isNaN(ava) ? 0 : ava;
                    conclusionOEE.totalPer.percent = isNaN(per) ? 0 : per;
                    conclusionOEE.totalQua.percent = qua;
                    conclusionOEE.totalOEE = isNaN(oee) ? 0 : oee;
                }
            } else {
                conclusionOEE.round++;
                const updatedTotalAva = { ...conclusionOEE.totalAva };
                updatedTotalAva.runTime += runTime;
                updatedTotalAva.totalTime += totalTime;

                let updatedTotalPer = { ...conclusionOEE.totalPer };
                updatedTotalPer.sumTotalExpected += totalExpected;
                updatedTotalPer.sumDiffDays += diffDays;

                const updatedTotalQua = { ...conclusionOEE.totalQua };
                updatedTotalQua.good += good;
                updatedTotalQua.total += total;

                if (this.props.actualProductions && conclusionOEE.round === this.props.actualProductions.length) {
                    const ava = updatedTotalAva.runTime * 100 / updatedTotalAva.totalTime;
                    const per = updatedTotalPer.sumTotalExpected * 100 / updatedTotalPer.sumDiffDays;
                    const qua = updatedTotalQua.good * 100 / updatedTotalQua.total;
                    const oee = ava * per * qua / 10000;
                    updatedTotalAva.percent = ava;
                    updatedTotalPer.percent = per;
                    updatedTotalQua.percent = qua;
                    conclusionOEE.totalOEE = oee;
                }
                conclusionOEE = {
                    ...conclusionOEE, ...{
                        totalAva: updatedTotalAva,
                        totalPer: updatedTotalPer,
                        totalQua: updatedTotalQua
                    }
                }
            }

            // for each machine
            if (!conclusionOEE[calculatingOnMachineId]) {
                conclusionOEE[calculatingOnMachineId] = {};
                conclusionOEE[calculatingOnMachineId].runTime = runTime;
                conclusionOEE[calculatingOnMachineId].totalTime = totalTime;
                conclusionOEE[calculatingOnMachineId].sumTotalExpected = totalExpected;
                conclusionOEE[calculatingOnMachineId].sumDiffDays = diffDays;
                conclusionOEE[calculatingOnMachineId].good = good;
                conclusionOEE[calculatingOnMachineId].total = total;
                conclusionOEE[calculatingOnMachineId].round = 1;
            } else {
                const updatedInfos = { ...conclusionOEE[calculatingOnMachineId] };
                updatedInfos.round++;
                updatedInfos.runTime += runTime;
                updatedInfos.totalTime += totalTime;
                updatedInfos.sumTotalExpected += totalExpected;
                updatedInfos.sumDiffDays += diffDays;
                updatedInfos.good += good;
                updatedInfos.total += total;

                conclusionOEE = {
                    ...conclusionOEE, ...{
                        [calculatingOnMachineId]: updatedInfos
                    }
                }
            }
            if (this.props.actualProductions && conclusionOEE.round === this.props.actualProductions.length) {
                const ava = conclusionOEE[calculatingOnMachineId].runTime * 100 / conclusionOEE[calculatingOnMachineId].totalTime;
                const per = conclusionOEE[calculatingOnMachineId].sumTotalExpected * 100 / conclusionOEE[calculatingOnMachineId].sumDiffDays;
                const qua = conclusionOEE[calculatingOnMachineId].good * 100 / conclusionOEE[calculatingOnMachineId].total;
                const oee = ava * per * qua / 10000;
                conclusionOEE[calculatingOnMachineId].percentAva = ava;
                conclusionOEE[calculatingOnMachineId].percentPer = per;
                conclusionOEE[calculatingOnMachineId].percentQua = qua;
                conclusionOEE[calculatingOnMachineId].percentOEE = oee;
            }

            this.setState({ conclusionOEE });
        }
    }

    render() {
        const { options, isError, selectedMachines, selectedMetrics, loading, errorMessage, actualProductions, conclusionOEE } = this.state;
        const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        return (<>
            <div className={styles.ReportQueryWrapper}>
                <div className={styles.ReportTitleSection}>
                    <span className={styles.Title}>OEE Report</span>
                </div>
                <div className={styles.SelectorsSection}>
                    <form className={styles.FormWrapper} style={{ height: '280px' }} onSubmit={this.onSubmitReportHandler}>
                        <div className={styles.FormGroup} style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                            <label className={styles.Title}>SELECT MACHINE *</label>
                            <MultipleSelect error={isError} isMulti isSearchable closeMenuOnSelect={false} options={options} styles={colourStyles}
                                placeholder="You can select many machines to compare each other"
                                value={selectedMachines}
                                onChange={this.onSelectMachine}
                                getOptionValue={opt => opt.id}
                            />
                        </div>

                        <div className={styles.FormGroup} style={{ marginBottom: '1rem' }}>
                            <div style={{ flex: 1, marginRight: 20 }}>
                                <label className={styles.Title}>Starts *</label>
                                <div style={{ display: 'flex', position: 'relative' }}>
                                    {!isChrome && <img src={CalendarIcon} alt="Starts" className={styles.CalendarIcon} />}
                                    <input className={styles.SelectBox} style={{ paddingLeft: !isChrome ? 46 : null }} type="date"
                                        onChange={(event) => this.onTimeChange('startDate', event.target.value)} value={this.state.startDate} />
                                    <input className={styles.SelectBox} type="time" onChange={(event) => this.onTimeChange('startTime', event.target.value)}
                                        value={this.state.startTime} />
                                </div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <label className={styles.Title}>Ends *</label>
                                <div style={{ display: 'flex', position: 'relative' }}>
                                    {!isChrome && <img src={CalendarIcon} alt="Ends" className={styles.CalendarIcon} />}
                                    <input className={styles.SelectBox} style={{ paddingLeft: !isChrome ? 46 : null }} type="date"
                                        onChange={(event) => this.onTimeChange('endDate', event.target.value)} value={this.state.endDate} />
                                    <input className={styles.SelectBox} type="time" onChange={(event) => this.onTimeChange('endTime', event.target.value)}
                                        value={this.state.endTime} />
                                </div>
                            </div>
                        </div>

                        <div className={styles.FormGroup} style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                            <label className={styles.Title}>METRICS *</label>
                            <MultipleSelect isMulti isSearchable closeMenuOnSelect={false} options={metricsOptions} styles={colourStyles}
                                placeholder="You can select many metrics to compare each other on graph"
                                value={selectedMetrics}
                                onChange={this.onSelectMetrics}
                                getOptionValue={opt => opt.id}
                            />
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className={styles.FormGroup} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flex: 1 }}>
                                {
                                    this.state.isFloating
                                        ? <img style={{ width: 20 }} src={Tick} alt="tick" onClick={() => this.setState({ isFloating: false })} />
                                        : <img style={{ width: 20 }} src={Untick} alt="untick" onClick={() => this.setState({ isFloating: true })} />
                                } <label className={styles.Title} style={{ marginTop: 3, marginLeft: 5 }} onClick={() => this.setState(prevState => { return { isFloating: !prevState.isFloating } })}>IS PLAN'S TIME FLOATING FOR OEE CALCULATION ?</label>
                            </div>

                            <div className={styles.ButtonsWrapper}>
                                <div className={styles.ViewButton}>
                                    <Button type="submit" icon={ViewIcon} color="primary" name="View My Report" disabled={loading} noMargin />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {
                selectedMetrics && selectedMetrics.length && actualProductions
                    ? actualProductions.length > 0
                        ? <>
                            <TotalOEE
                                total={{
                                    oee: conclusionOEE.totalOEE?.percent,
                                    a: conclusionOEE.totalAva?.percent,
                                    p: conclusionOEE.totalPer?.percent,
                                    q: conclusionOEE.totalQua?.percent
                                }}
                                poAmount={selectedMachines ? selectedMachines.length : 0}
                                displayOverHundred={this.state.displayOverHundred} />
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {
                                    selectedMachines && selectedMachines.length > 1 && selectedMachines.map((machine, i) =>
                                        <div key={`${machine.label}forOEE`} style={{ flex: '0 1 49%', marginLeft: i % 2 !== 0 ? 10 : 0, marginRight: i % 2 === 0 ? 10 : 0 }}>
                                            <TotalOEE total={
                                                conclusionOEE[machine.id]
                                                    ? { oee: conclusionOEE[machine.id]?.percentOEE, a: conclusionOEE[machine.id]?.percentAva, p: conclusionOEE[machine.id]?.percentPer, q: conclusionOEE[machine.id]?.percentQua }
                                                    : null
                                            } machineName={machine.label} displayOverHundred={this.state.displayOverHundred} />
                                        </div>
                                    )
                                }
                            </div>
                            {actualProductions.map((actual, i) => <ActualOEE key={"actual-oee-" + actual.uuid} index={i} actual={actual} selectedMetrics={selectedMetrics} isFloating={this.state.isFloating} displayTotalOee={this.displayTotalOee} displayOverHundred={this.state.displayOverHundred} />)}
                        </>
                        : <div className={styles.ReportQueryWrapper} style={{ marginTop: 20 }}>
                            <div className={styles.ReportTitleSection} style={{ justifyContent: 'center' }}>
                                <span style={{ fontSize: 14, fontWeight: '600' }}>Data not found</span>
                            </div>
                        </div>
                    : null
            }
            <Modal className={styles.Modal} show={errorMessage !== ''} modalClosed={() => this.setState({ errorMessage: '' })}>
                <div className={styles.Title}>Error</div>
                <div className={styles.Description}>{errorMessage}</div>

                <div className={styles.ButtonWrapper}>
                    <Button type="button" color="green" name="OK" click={() => this.setState({ errorMessage: '' })} />
                </div>
            </Modal>
        </>);
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { type, error, loading, actualProductions } = state.advanceActualProduction;
    const { machines, machineAPQ } = state.machine;
    const { moduleConfigs } = state.module;
    return { currentOrg, machines, machineAPQ, loading, actualProductions, error, actualType: type, moduleConfigs };
};

export default connect(mapStateToProps, { getActualProduction, getMachines, getModuleConfigs, getApqOfMachine, getProductionPlanStructures })(OEEReport);