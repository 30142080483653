import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  countingDevicesList: null,
  addedCountingDevices: null,
  updatedCountingDevices: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COUNTING_DEVICES_LIST_START: return getCountingDevicesListStart(state, action);
    case actionTypes.GET_COUNTING_DEVICES_LIST_SUCCESS: return getCountingDevicesListSuccess(state, action);
    case actionTypes.GET_COUNTING_DEVICES_LIST_FAIL: return getCountingDevicesListFail(state, action);

    case actionTypes.ADD_COUNTING_DEVICES_START: return addCountingDevicesStart(state, action);
    case actionTypes.ADD_COUNTING_DEVICES_SUCCESS: return addCountingDevicesSuccess(state, action);
    case actionTypes.ADD_COUNTING_DEVICES_FAIL: return addCountingDevicesFail(state, action);

    case actionTypes.EDIT_COUNTING_DEVICES_START: return editCountingDevicesStart(state, action);
    case actionTypes.EDIT_COUNTING_DEVICES_SUCCESS: return editCountingDevicesSuccess(state, action);
    case actionTypes.EDIT_COUNTING_DEVICES_FAIL: return editCountingDevicesFail(state, action);

    case actionTypes.DELETE_COUNTING_DEVICES_START: return deleteCountingDevicesStart(state, action);
    case actionTypes.DELETE_COUNTING_DEVICES_SUCCESS: return deleteCountingDevicesSuccess(state, action);
    case actionTypes.DELETE_COUNTING_DEVICES_FAIL: return deleteCountingDevicesFail(state, action);

    default: return state;
  }
};

const getCountingDevicesListStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getCountingDevicesListSuccess = (state, action) => {
  return updateObject(state, {
    countingDevicesList: action.countingDevicesList,
    error: null,
    loading: false
  });
}

const getCountingDevicesListFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addCountingDevicesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addCountingDevicesSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedCountingDevices: action.addedCountingDevices,
    error: null,
    loading: false
  });
}

const addCountingDevicesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editCountingDevicesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editCountingDevicesSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedCountingDevices: action.updatedCountingDevices,
    error: null,
    loading: false
  });
}

const editCountingDevicesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteCountingDevicesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteCountingDevicesSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteCountingDevicesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;