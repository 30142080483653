import React, { Component } from 'react';
import { connect } from 'react-redux';
import _, { snakeCase } from 'lodash';
import moment from 'moment';
import { SortableElement, SortableContainer, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import styles from './PcsTimeline.module.scss';
import Button from '../../components/UI/Button/Button';
import FullScreenIcon from '../../../assets/icons/full-screen.svg';
import NormalScreenIcon from '../../../assets/icons/normal-screen.svg';
import Tick from '../../../assets/icons/tick-square.svg';
import Untick from '../../../assets/icons/untick-square.png';
import XIcon from '../../../assets/icons/cancel-circle.svg';
import TimelineRow from '../../components/Production/Timeline/TimelineRow';
import TimelineEmptyImg from '../../../assets/images/empty-timeline.svg';

import { getDevices } from '../../../state/ducks/Devices/actions';
import { getMachines } from '../../../state/ducks/Machine/actions';
import { getModuleConfigs, postModuleConfig } from '../../../state/ducks/Module/actions';

const SortableItem = SortableElement(({ machine, displayAt }) => (
  <li className={styles.SortableItem}>
    {
      machine.display
        ? <img className={styles.TickIcon} src={Tick} alt="checked" onClick={(e) => displayAt(e)} />
        : <img className={styles.TickIcon} src={Untick} alt="unchecked" onClick={(e) => displayAt(e)} />
    }
    {machine.name}
  </li>
));

const SortableList = SortableContainer(({ children }) => {
  return <ul className={styles.SortableListWrapper}>{children}</ul>;
});

const ONE_DAY_IN_MS = 86400000;
const getToday = () => {
  let today = new Date();
  let dayStartingTime = localStorage.getItem('default_day_starting_time');
  if (dayStartingTime) {
    dayStartingTime = dayStartingTime.split(":");
    let newDay = new Date(new Date().setHours(+dayStartingTime[0], +dayStartingTime[1], 0, 0));
    if (today < newDay) {
      today = new Date(moment(today).valueOf() - ONE_DAY_IN_MS);
    }
  }
  return today;
}

const parseNumToTextZero = (num) => {
  if (num < 10)
    return '0' + num;
  return '' + num;
}

class PcsTimeline extends Component {
  state = {
    machines: null,
    machinesStatuses: null,
    startingPeriod: null,
    isFullScreen: false,
    onSorting: false,
    tempMachines: null,
    loadingMachineAndModuleConfig: false,
    showingDate: getToday(),
    bottomDrawerShow: false,
    selectedMachine: null,
    statusLookingIndex: null,
  }

  componentDidMount() {
    this.props.getMachines(this.props.currentOrg);
    this.setState({ loadingMachineAndModuleConfig: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentOrg && prevProps.currentOrg !== this.props.currentOrg) {
      this.props.getMachines(this.props.currentOrg);
      this.handleCloseBottomDrawer();
      this.setState({ loadingMachineAndModuleConfig: true, showingDate: getToday() });
    } else {
      if (prevProps.machines !== this.props.machines && this.props.machines) {
        this.props.getModuleConfigs(this.props.currentOrg, 3);
        const tempMachines = [...this.props.machines];
        tempMachines.sort((a, b) => Object.keys(b.status_rules).length - Object.keys(a.status_rules).length);
        let machinesStatuses = [];
        if (tempMachines && tempMachines.length) {
          machinesStatuses = tempMachines[0].status_rules;
        }
        let newMachines = [...this.props.machines];
        newMachines = newMachines.map(obj => ({ ...obj, display: true }));

        this.setState({ machines: newMachines, machinesStatuses });
      } else if (this.props.moduleConfigs !== prevProps.moduleConfigs && this.props.moduleConfigs) {
        let startingPeriod = '08:00';
        if (this.props.moduleConfigs.length) {
          startingPeriod = this.props.moduleConfigs.find(mc => mc.key === 'timelineTime');
          startingPeriod = startingPeriod ? startingPeriod.value : '08:00';
          const savedLocations = this.props.moduleConfigs.find(mc => mc.key === 'pcsTimelineSavedLocations');
          if (savedLocations) {
            this.setMachinesAccordingToSavedLocations(savedLocations.value);
          } else {
            this.setState({ loadingMachineAndModuleConfig: false });
          }
        } else {
          // not set yet: default time 08:00
          this.setState({ loadingMachineAndModuleConfig: false });
        }
        this.setState({ startingPeriod: startingPeriod });
      } else if (this.state.bottomDrawerShow && (this.state.statusLookingIndex || this.state.statusLookingIndex === 0) && prevState.statusLookingIndex !== this.state.statusLookingIndex) {
        setTimeout(() => this.statusRefs[this.state.statusLookingIndex].current.scrollIntoView({ block: "center", behavior: "smooth" }), 300);
      }
    }
  }

  setMachinesAccordingToSavedLocations = (locs) => {
    let newMachines = [...this.props.machines];
    const savedLocations = JSON.parse(locs);
    if (savedLocations[this.props.currentOrg] && savedLocations[this.props.currentOrg].length) {
      let sortedMachines = [];
      savedLocations[this.props.currentOrg].map(sl => {
        let elem = newMachines.find(m => m.id === sl.id);
        elem.display = sl.display;
        sortedMachines.push(elem);
      });
      newMachines = [...sortedMachines];
      this.setState({ machines: newMachines, loadingMachineAndModuleConfig: false });
    }
  }

  toggleFullScreen = () => {
    this.setState(prevState => {
      return { isFullScreen: !prevState.isFullScreen }
    });
  }

  onSortEnd = (e) => {
    let machines = [...this.state.machines];
    machines = arrayMove(this.state.machines, e.oldIndex, e.newIndex);
    this.setState({ machines });
  };

  onSortingMachineTimelineHandler = () => {
    this.setState({ onSorting: true, tempMachines: [...this.state.machines] });
  }

  onActionSorting = (isSaved) => {
    if (isSaved) {
      let savedLocations = this.props.moduleConfigs?.find(mc => mc.key === 'pcsTimelineSavedLocations');
      if (savedLocations) {
        savedLocations = JSON.parse(savedLocations.value);
      } else {
        savedLocations = {};
      }
      savedLocations[this.props.currentOrg] = [];
      savedLocations[this.props.currentOrg] = [...this.state.machines].map(({ id, display }) => ({ id, display }));
      this.props.postModuleConfig(this.props.currentOrg, 3, 'pcsTimelineSavedLocations', JSON.stringify(savedLocations))
    } else {
      this.setState({ machines: [...this.state.tempMachines] });
    }
    this.setState({ onSorting: false, tempMachines: null });
  }

  displayMachineClicked = (event, index) => {
    event.stopPropagation();
    let machines = [...this.state.machines];
    machines[index].display = !machines[index].display;
    this.setState({ machines });
  }

  handleDateChange = (event) => {
    this.setState({ showingDate: new Date(event.target.value) });
    this.handleCloseBottomDrawer();
  }

  handleCloseBottomDrawer = (event) => {
    if (event) event.preventDefault();
    this.setState({
      bottomDrawerShow: false,
      selectedMachine: null,
      statusLookingIndex: null,
    });
  }

  handleTimelineBarClick = (selectedMachine, timelineStatuses, statusLookingIndex) => {
    this.setState({ bottomDrawerShow: true, selectedMachine, statusLookingIndex, statusList: timelineStatuses.data });
  }

  humanizeDuration = (ms) => {
    const days = moment.duration(ms).days();
    const hours = moment.duration(ms).hours();
    const minutes = moment.duration(ms).minutes();
    const seconds = moment.duration(ms).seconds();

    let result = days === 0 ? '' : days > 1 ? days + ' days ' : days + ' day ';
    result += hours === 0 ? '' : hours > 1 ? hours + ' hours ' : hours + ' hour ';
    result += minutes === 0 ? '' : minutes > 1 ? minutes + ' minutes ' : minutes + ' minute ';
    result += seconds === 0 ? '' : seconds > 1 ? seconds + ' seconds ' : seconds + ' second';

    return result;
  }

  render() {
    let ScreenStyle = styles.MinimizeScreen;
    if (this.state.isFullScreen) {
      ScreenStyle = styles.FullScreen;
    }

    let selectdate = this.state.showingDate;
    let selectdatestr = selectdate.getFullYear() + '-' + parseNumToTextZero(selectdate.getMonth() + 1) + '-' + parseNumToTextZero(selectdate.getDate());

    let statusRows = [];
    this.statusRefs = [];
    if (this.state.bottomDrawerShow && Array.isArray(this.state.statusList) && this.state.statusList.length > 0) {
      this.state.statusList.forEach((status, i) => {
        let start = new Date(status.start);
        let stop = new Date(status.stop) < start ? 'In Progress' : new Date(status.stop);
        let diff = 0;
        if (stop !== 'In Progress') {
          if (stop > start) {
            diff = (moment(stop).valueOf() - moment(start).valueOf());
          }
          stop = moment(stop).format('DD/MM/YYYY HH:mm:ss');
        } else {
          diff = (moment().valueOf() - moment(start).valueOf());
        }

        start = moment(start).format('DD/MM/YYYY HH:mm:ss');
        const machineStatus = this.state.selectedMachine.status_rules[status.value];

        let statusReason = 'N/A';
        let statusColor = '#9ea0a5';
        let isBlackColor = false;
        if (machineStatus) {
          statusReason = machineStatus.reason;
          statusColor = machineStatus.color;
          isBlackColor = machineStatus.color === '#222226' ? true : false;
        }
        this.statusRefs[i] = React.createRef();

        statusRows.push(
          <tr ref={this.statusRefs[i]} key={`status${i + 1}_` + start} className={(this.state.statusLookingIndex || this.state.statusLookingIndex === 0) && this.state.statusLookingIndex === i ? styles.SelectedRowFromTimeline : null}>
            <td>{start}</td>
            <td>{stop}</td>
            <td>{this.humanizeDuration(diff)}</td>
            <td className={styles.ReasonCol} style={{ color: statusColor }}>
              <div className={`${styles.DotStatusColor} ${isBlackColor ? styles.ShadowBox : ''}`} style={{ backgroundColor: statusColor }} />
              <span className={isBlackColor ? styles.ShadowText : ''}>{statusReason}</span>
            </td>
          </tr>
        );
      });
    }

    return (
      <div className={ScreenStyle}>
        <div className={styles.TopSection}>
          <div key="fullscreen-btn" className={styles.ScreenButton}>
            <input className={styles.SelectDate} type="date" placeholder="dd/mm/yyyy" value={selectdatestr} onChange={this.handleDateChange} onKeyDown={(e) => e.preventDefault()} />
            {
              this.state.onSorting ? <>
                <Button type="button" name="Cancel" color="borderred" click={() => this.onActionSorting(false)} noMargin overideButtonStyles={{ marginLeft: 10, width: 80 }} />
                <Button type="button" name="Save" color="green" click={() => this.onActionSorting(true)} noMargin overideButtonStyles={{ marginLeft: 10, width: 80 }} />
              </>
                : <Button type="button" name="Sort" color="primary" click={this.onSortingMachineTimelineHandler} noMargin overideButtonStyles={{ marginLeft: 10, width: 80 }} />
            }
            {
              this.state.isFullScreen
                ? <div className={styles.ScreenWrapper} onClick={this.toggleFullScreen}><img src={NormalScreenIcon} alt="Normal Screen Icon" style={{ width: 20, height: 20 }} /></div>
                : <div className={styles.ScreenWrapper} onClick={this.toggleFullScreen}><img src={FullScreenIcon} alt="Full Screen Icon" style={{ width: 20, height: 20 }} /></div>
            }
          </div>
        </div>
        <div className={styles.ContentSection}>
          {
            this.state.onSorting
              ? <SortableList onSortEnd={this.onSortEnd} distance={1}>
                {this.state.machines?.map((value, index) => (
                  <SortableItem key={`item-${value.name}`} index={index} machine={value} displayAt={(e) => this.displayMachineClicked(e, index)} />
                ))}
              </SortableList>
              : null
          }
          {
            !this.state.onSorting && this.props.machines && this.props.machines.length ?
              <div className={styles.StatusRulesLegend}>
                <div className={styles.LegendTitle}>Status :</div>
                {
                  this.state.machinesStatuses && Object.keys(this.state.machinesStatuses).length ? Object.keys(this.state.machinesStatuses).map(value => {
                    const { color, reason } = this.state.machinesStatuses[value];
                    return <div
                      key={`statusrules-${value}-${color}`}
                      className={styles.LegendBox}
                      style={{ backgroundColor: color, boxShadow: +value === 0 ? '0px 0px 6px -4px white' : 'none' }}>
                      {reason}
                    </div>
                  }) : null
                }
              </div>
              : this.state.onSorting
                ? null
                : <div style={{ width: '100%', textAlign: 'center', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <img src={TimelineEmptyImg} alt="no timelines" />
                  <div style={{ marginTop: 10 }}>
                    <h3>Please add a machine before using timelines.</h3>
                  </div>
                </div>
          }
          {
            this.state.loadingMachineAndModuleConfig
              ? null
              : !this.state.onSorting && this.state.machines && this.state.machines.length
                ? <div className={styles.TimelineTableWrapper} style={this.state.bottomDrawerShow ? { height: 'calc(100vh - 570px)' } : {}}>
                  <table>
                    <thead>
                      <tr>
                        <th className={styles.machine_col}>Machine</th>
                        <th>Timeline</th>
                        <th className={styles.reason_col}>Status</th>
                        <th className={styles.apq_col}>A</th>
                        <th className={styles.apq_col}>P</th>
                        <th className={styles.apq_col}>Q</th>
                        <th className={styles.oee_col}>OEE%</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.machines.map(machine => machine.display && (
                          <TimelineRow selectedDate={this.state.showingDate} key={`${machine.id}`} machine={machine} startingPeriod={this.state.startingPeriod} handleTimelineBarClick={this.handleTimelineBarClick} />
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                : null
          }

          {
            this.state.bottomDrawerShow && <div className={styles.BottomDrawer} style={this.state.bottomDrawerShow ? {} : { display: 'none' }}>
              <div className={styles.BottomDrawerTopbar}>
                <div className={styles.BottomDrawerTopbarTitle}>
                  <span style={{ color: 'white' }}>{this.state.selectedMachine.name}</span>'s Status Details
                </div>
                <img src={XIcon} alt="close" onClick={this.handleCloseBottomDrawer} style={{ cursor: 'pointer' }} />
              </div>
              <div className={styles.BottomDrawerContent}>
                <table style={{ borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th>Start</th>
                      <th>Stop</th>
                      <th>Duration</th>
                      <th>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statusRows}
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { devices } = state.devices;
  const { machines } = state.machine;
  const { moduleConfigs } = state.module;

  return {
    currentOrg, devices,
    machines,
    moduleConfigs
  };
};

export default connect(mapStateToProps, { getMachines, getDevices, getModuleConfigs, postModuleConfig })(PcsTimeline);
