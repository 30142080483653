export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const ADD_NOTIFICATION_START = 'ADD_NOTIFICATION_START';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS';
export const ADD_NOTIFICATION_FAIL = 'ADD_NOTIFICATION_FAIL';

export const UPDATE_NOTIFICATION_START = 'UPDATE_NOTIFICATION_START';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAIL = 'UPDATE_NOTIFICATION_FAIL';

export const DELETE_NOTIFICATION_START = 'DELETE_NOTIFICATION_START';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAIL = 'DELETE_NOTIFICATION_FAIL';

export const GET_NOTIFICATIONS_INBOX_START = 'GET_NOTIFICATIONS_INBOX_START';
export const GET_NOTIFICATIONS_INBOX = 'GET_NOTIFICATIONS_INBOX';
export const GET_NOTIFICATIONS_INBOX_SUCCESS = 'GET_NOTIFICATIONS_INBOX_SUCCESS';
export const GET_NOTIFICATIONS_INBOX_FAIL = 'GET_NOTIFICATIONS_INBOX_FAIL';

export const MARK_AS_READ_START = 'MARK_AS_READ_START';
export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_AS_READ_SUCCESS = 'MARK_AS_READ_SUCCESS';
export const MARK_AS_READ_FAIL = 'MARK_AS_READ_FAIL';

export const GET_NOTIFICATION_TOPBAR_START = 'GET_NOTIFICATION_TOPBAR_START';
export const GET_NOTIFICATION_TOPBAR = 'GET_NOTIFICATION_TOPBAR';
export const GET_NOTIFICATION_TOPBAR_SUCCESS = 'GET_NOTIFICATION_TOPBAR_SUCCESS';
export const GET_NOTIFICATION_TOPBAR_FAIL = 'GET_NOTIFICATION_TOPBAR_FAIL';
