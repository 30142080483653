import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import styles from './PcsScheduler.module.scss';
import Button from '../../components/UI/Button/Button';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';

import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import SchedulerCalendar from '../../components/PcsScheduler/SchedulerCalendar/SchedulerCalendar';
import SchedulerDrawer from '../../components/PcsScheduler/SchedulerDrawer/SchedulerDrawer';
import SchedulerTypeList from '../../components/PcsScheduler/SchedulerType/SchedulerTypeList';
import SchedulerTypeDrawer from '../../components/PcsScheduler/SchedulerType/SchedulerTypeDrawer';
import RepeatingConfirmModal from '../../components/PcsScheduler/RepeatingConfirmModal/RepeatingConfirmModal';

import { getMachines } from '../../../state/ducks/Machine/actions';
import { deleteScheduler, deleteSchedulerType, editScheduler } from '../../../state/ducks/Scheduler/actions';

const TAB_LIST = ['Calendar', 'Type'];

class PcsScheduler extends Component {
  state = {
    currentTabIndex: 0,
    isAdding: false,
    isDeleting: false,
    editingScheduler: null,
    isDrawerOpened: false,
    isDeleteModalOpened: false,
    editingType: null,
    isRepeatingModalOpened: false,
    schedulerForm: null
  };

  componentDidMount() {
    // this.props.getMachines(this.props.currentOrg);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentOrg !== prevProps.currentOrg && this.props.currentOrg) {
      // this.props.getMachines(this.props.currentOrg);
      // this.props.getPlannedDowntime(this.props.currentOrg);
    }
    // if (!_.isEqual(prevProps.updatedScheduler, this.props.updatedScheduler) && this.props.updatedScheduler) {
    // this.setState({ editingScheduler: null });
    // }
  }

  openDrawerForAddingHandler = () => {
    this.setState({ isAdding: true, editingScheduler: null, isDrawerOpened: true });
  }

  drawerToggleHandler = (which) => {
    this.setState(prevState => {
      return { isDrawerOpened: !prevState.isDrawerOpened }
    });
  }

  onToggleDeleteModalHandler = () => {
    this.setState({ isDeleteModalOpened: true });
  }

  onToggleRepeatingModalHandler = (sf, schedulerIsRepeatEnd, isDeleting) => {
    const schedulerForm = { ...sf };
    schedulerForm.isRepeatEnd = schedulerIsRepeatEnd;
    this.setState({ isRepeatingModalOpened: true, schedulerForm, isDeleting });
  }

  onConfirmSchedulerHandler = (event) => {
    event.preventDefault();
    if (this.state.currentTabIndex === 0) {
      // Delete an event on the calendar : only 1 case => Repeat: NONE
      const { id, start } = this.state.editingScheduler;
      this.props.deleteScheduler(id, moment(start).valueOf(), false, { scheduler: this.state.editingScheduler });
      this.setState({ editingScheduler: null, isDeleteModalOpened: false });
    } else {
      // Delete a type
      this.props.deleteSchedulerType(this.state.editingType.id);
      this.setState({ editingType: null, isDeleteModalOpened: false, isDrawerOpened: false });
    }
  }

  changeTabStructureHandler = (index) => {
    this.setState({ currentTabIndex: index });
  }

  onEditSchedulerTypeHandler = (event, st) => {
    event.preventDefault();
    this.setState({ editingType: st, isAdding: false, isDrawerOpened: true });
  }

  onEditSchedulerHandler = (scheduler, clickedEvent) => {
    const editingScheduler = { ...scheduler };
    editingScheduler.clickedEvent = clickedEvent;
    this.setState({ editingScheduler, isAdding: false, isDrawerOpened: true });
  }

  onConfirmRepeatingEventsActionHandler = (option) => {
    const { name, startDate, startTime, endDate, endTime, machine, repeat, endsRepeat, typeId, isRepeatEnd } = this.state.schedulerForm;
    const foundScheduler = this.props.schedulers.find(sd => sd.id === this.state.editingScheduler.id);

    if (this.state.isDeleting) {
      if (option === 'all') {
        const metaStartDate = moment(this.state.editingScheduler.start).format('YYYY-MM-DD');
        let startDelete = moment(metaStartDate + ' ' + startTime.value).valueOf();
        this.props.deleteScheduler(foundScheduler.id, startDelete, true, { scheduler: this.state.editingScheduler });
      } else if (option === 'all_future') {
        let startDelete = moment(startDate.value + ' ' + startTime.value).valueOf()
        this.props.deleteScheduler(foundScheduler.id, startDelete, true, { scheduler: this.state.editingScheduler, all_future: true, startDate: startDate.value, startTime: startTime.value });
      } else {
        let startDelete = moment(startDate.value + ' ' + startTime.value).valueOf()
        this.props.deleteScheduler(foundScheduler.id, startDelete, false, { scheduler: this.state.editingScheduler, all_future: false, startDate: startDate.value, startTime: startTime.value });
      }
    } else {
      if (option === 'all') {
        let metaStartDate = moment(this.state.editingScheduler.start).format('YYYY-MM-DD');
        let metaEndDate = moment(this.state.editingScheduler.end).format('YYYY-MM-DD');
        if (metaStartDate !== startDate.value) metaStartDate = startDate.value;
        if (metaEndDate !== endDate.value) metaEndDate = endDate.value;
        let startEdit = moment(metaStartDate + ' ' + startTime.value).valueOf();
        if (moment(startDate.value + ' ' + startTime.value).valueOf() !== moment(foundScheduler.start).valueOf()) {
          startEdit = moment(foundScheduler.start).valueOf();
        }
        if (!this.state.isDeleting) {
          this.props.editScheduler(
            this.state.editingScheduler.id,
            startEdit,
            name.value,
            typeId.value.id,
            machine.value.map((x) => x.id).join(','),
            moment(metaStartDate + ' ' + startTime.value).valueOf(),
            moment(metaEndDate + ' ' + endTime.value).valueOf(),
            isRepeatEnd ? moment(endsRepeat.value + ' ' + endTime.value).valueOf() : '',
            '',
            repeat.value.id,
            true, // all_in_future
            {}
          );
        }
      } else if (option === 'all_future') {
        this.props.editScheduler(
          this.state.editingScheduler.id,
          moment(this.state.editingScheduler.clickedEvent.start).valueOf(),
          name.value,
          typeId.value.id,
          machine.value.map((x) => x.id).join(','),
          moment(startDate.value + ' ' + startTime.value).valueOf(),
          moment(endDate.value + ' ' + endTime.value).valueOf(),
          isRepeatEnd ? moment(endsRepeat.value + ' ' + endTime.value).valueOf() : '',
          '',
          repeat.value.id,
          true, // all_in_future
          { all_future: true, fromId: this.state.editingScheduler.id }
        );
      } else {
        // only this event
        this.props.editScheduler(
          this.state.editingScheduler.id,
          moment(this.state.editingScheduler.clickedEvent.start).valueOf(),
          name.value,
          typeId.value.id,
          machine.value.map((x) => x.id).join(','),
          moment(startDate.value + ' ' + startTime.value).valueOf(),
          moment(endDate.value + ' ' + endTime.value).valueOf(),
          '',
          '',
          'none',
          false, // all_in_future
          { all_future: false, fromId: this.state.editingScheduler.id }
        );
      }
    }

    this.setState({ schedulerForm: null, editingScheduler: null, isRepeatingModalOpened: false, isDeleting: false });
  }

  render() {
    return (
      <div className={styles.PcsScheduler}>
        <SideDrawer
          title={
            this.state.currentTabIndex === 0
              ? this.state.isAdding ? 'Add New Scheduler' : 'Edit Scheduler'
              : this.state.isAdding ? 'Add New Type' : 'Edit Type'
          }
          open={this.state.isDrawerOpened}
          closed={this.drawerToggleHandler}>
          {
            this.state.currentTabIndex === 0
              ? <SchedulerDrawer
                isAdding={this.state.isAdding}
                closed={this.drawerToggleHandler}
                editingScheduler={this.state.editingScheduler}
                toggleDeleteModal={this.onToggleDeleteModalHandler}
                onToggleRepeatingModalHandler={this.onToggleRepeatingModalHandler}
              />
              : <SchedulerTypeDrawer
                isAdding={this.state.isAdding}
                closed={this.drawerToggleHandler}
                editingType={this.state.editingType}
                toggleDeleteModal={this.onToggleDeleteModalHandler}
              />
          }

        </SideDrawer>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              TAB_LIST.map((name, index) => (
                <div key={`logs-${index}`} className={index === this.state.currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.changeTabStructureHandler(index)}>
                  <span className={styles.MenuTitle}>{name}</span>
                </div>
              ))
            }
          </div>

          <div className={styles.AddButtonWrapper}>
            <Button type="button" icon={PlusCircleIcon} color="primary"
              name={`${this.state.currentTabIndex === 0 ? 'Add New Scheduler' : 'Add New Type'}`} noMargin
              click={this.openDrawerForAddingHandler} />
          </div>

        </div>
        <div className="MainContentPadding">
          {
            this.state.currentTabIndex === 0
              ? <SchedulerCalendar eventClicked={this.onEditSchedulerHandler} />
              : <SchedulerTypeList editSchedulerType={this.onEditSchedulerTypeHandler} />
          }
        </div>

        <DeleteModal
          title={this.state.currentTabIndex === 0 ? 'scheduler' : 'type'}
          open={this.state.isDeleteModalOpened}
          modalClosed={() => this.setState({ isDeleteModalOpened: false })}
          confirmDelete={this.onConfirmSchedulerHandler}
        />
        <RepeatingConfirmModal
          isEdit={!this.state.isDeleting}
          open={this.state.isRepeatingModalOpened}
          modalClosed={() => this.setState({ isRepeatingModalOpened: false })}
          confirm={(opt) => this.onConfirmRepeatingEventsActionHandler(opt)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { machines } = state.machine;
  const { schedulers, result, loading, error, type } = state.scheduler;

  return { currentOrg, machines, schedulers, result, loading, error, type };
};

export default connect(mapStateToProps, { getMachines, deleteSchedulerType, deleteScheduler, editScheduler })(PcsScheduler);
