import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import styles from './Reports.module.scss';
import DailyEnergyBar from '../DailyEnergyBar/DailyEnergyBar';
import { getPowersJson } from '../../../../state/ducks/Power/actions';

class MonthlyReport extends Component {
    state = {
        monthlyRawDataset: [],
        adjustedMonthlyRawDataset: [],
        monthlyRawDatasetForAvgEfficiency: [],
        adjustedMonthlyRawDatasetForAvgEfficiency: [],
        loading: true
    }

    componentDidMount() {
        const usingDate = this.props.selectedDate;
        const start = moment(usingDate).set('month', 3).set('year', moment(usingDate).get('years')).startOf('month').valueOf(); // 1 APR, this year
        const end = moment(usingDate).set('month', 2).set('year', moment(usingDate).get('years') + 1).endOf('month').valueOf(); // 1 MAR, next year
        const startAprLastYear = moment(usingDate).set('month', 3).set('year', moment(usingDate).get('years') - 1).startOf('month').valueOf();
        const endMarThisYear = moment(usingDate).set('month', 2).set('year', moment(usingDate).get('years')).endOf('month').valueOf();

        let body = {};
        body.org_id = this.props.currentOrg;
        body.start = start;
        body.end = end;
        body.range = 1;
        body.unit = 'month';
        body.need_eff = true;
        body.filters = [];
        if (this.props.groupMachines) {
            this.props.groupMachines.forEach((gm, i) => {
                body.start = start;
                body.end = end;
                body.filters = [];
                gm.forEach(device => {
                    const { infos, machineId, order } = device;
                    body.filters.push({
                        secret: infos.secret, socket: infos.socket, machine_id: machineId, ref: i + '_' + order
                    });
                });
                this.props.getPowersJson(body, { group: i });

                body.start = startAprLastYear;
                body.end = endMarThisYear;
                this.props.getPowersJson(body, { group: i, avgEfficiencyLastYear: true });
            });
        }
        this.props.showPageLoading();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.powersJSON !== this.props.powersJSON && this.props.powersJSON) {
            const { properties } = this.props.powersJSON;
            let loading = true;
            let monthlyRawDataset = [...this.state.monthlyRawDataset];
            let monthlyRawDatasetForAvgEfficiency = [...this.state.monthlyRawDatasetForAvgEfficiency];

            if (monthlyRawDataset.length === 0) {
                monthlyRawDataset = new Array(this.props.groupMachines.length).fill(undefined);
                monthlyRawDatasetForAvgEfficiency = new Array(this.props.groupMachines.length).fill(undefined);
            }

            if (properties.avgEfficiencyLastYear) monthlyRawDatasetForAvgEfficiency[properties.group] = this.props.powersJSON;
            else monthlyRawDataset[properties.group] = this.props.powersJSON;

            const isAnyUndefined = monthlyRawDataset.filter(ar => !ar);
            const isAnyAvgUndefined = monthlyRawDatasetForAvgEfficiency.filter(ar => !ar);
            if (!isAnyUndefined.length && !isAnyAvgUndefined.length && monthlyRawDataset.length === this.props.groupMachines.length && monthlyRawDatasetForAvgEfficiency.length === this.props.groupMachines.length) {
                loading = false;
                this.adjustRawDataset(monthlyRawDataset, monthlyRawDatasetForAvgEfficiency);
            }
            this.setState({ monthlyRawDataset, monthlyRawDatasetForAvgEfficiency, loading });
        }
    }

    adjustRawDataset = (monthlyRawDataset, monthlyRawDatasetForAvgEfficiency) => {
        this.props.doneLoading();
        let adjustedMonthlyRawDataset = [];
        let adjustedMonthlyRawDatasetForAvgEfficiency = [];
        monthlyRawDataset.forEach((mrd, i) => {
            Object.values(mrd.data).forEach((val, j) => {
                adjustedMonthlyRawDataset.push({
                    data: val,
                    properties: mrd.properties
                });
            });
        });
        monthlyRawDatasetForAvgEfficiency.forEach((mrd, i) => {
            Object.values(mrd.data).forEach((val, j) => {
                adjustedMonthlyRawDatasetForAvgEfficiency.push({
                    data: val,
                    properties: mrd.properties
                });
            });
        });
        this.setState({ adjustedMonthlyRawDataset, adjustedMonthlyRawDatasetForAvgEfficiency });
        this.props.readyToExport();
    }

    render() {

        return (
            <>
                <div ref={this.props.coverReportRef} style={{ width: 1500 }}>
                    <div className={styles.ReportWrapper} style={{ width: 'auto' }}>
                        <div className={styles.HeaderSection}>
                            <div className={styles.HeaderText} style={{ fontSize: 36 }}>Monthly Report</div>
                            <div className={styles.MonthlySubHeader}>{moment(this.props.selectedDate).format('MMMM YYYY')}'s report</div>
                        </div>
                        {
                            !this.state.loading
                                ? <DailyEnergyBar
                                    forReport isParent fiscal
                                    monthlyRawDataset={this.state.adjustedMonthlyRawDataset}
                                    monthlyRawDatasetForAvgEfficiency={this.state.adjustedMonthlyRawDatasetForAvgEfficiency}
                                    amountLeaves={this.props.amountLeaves - 1}
                                    allChildLeaves={this.props.allChildLeaves}
                                    machines={this.props.machines}
                                    selectedRelation={this.props.selectedRelation}
                                    selectedChild={[]}
                                    queryPeriod={'y'}
                                    selectedDate={this.props.selectedDate}
                                    isEfficiency={true}
                                    downloadData={this.props.downloadData}
                                    setEvaTimes={this.props.setEvaTimes}
                                /> : null
                        }
                        <div style={{ width: '100%', height: 30 }}></div>
                        <DailyEnergyBar
                            forReport isParent monthly
                            allRawDataset={this.props.allRawDataset}
                            amountLeaves={this.props.amountLeaves - 1}
                            allChildLeaves={this.props.allChildLeaves}
                            machines={this.props.machines}
                            selectedRelation={this.props.selectedRelation}
                            selectedChild={[]}
                            queryPeriod={'m'}
                            selectedDate={this.props.selectedDate}
                            isEfficiency={true}
                            downloadData={this.props.downloadData}
                            setEvaTimes={this.props.setEvaTimes}
                        />
                        <DailyEnergyBar
                            forReport isParent monthly
                            allRawDataset={this.props.allRawDataset}
                            amountLeaves={this.props.amountLeaves - 1}
                            allChildLeaves={this.props.allChildLeaves}
                            machines={this.props.machines}
                            selectedRelation={this.props.selectedRelation}
                            selectedChild={[]}
                            queryPeriod={'m'}
                            selectedDate={this.props.selectedDate}
                            isEfficiency={false}
                            downloadData={this.props.downloadData}
                            setEvaTimes={this.props.setEvaTimes}
                        />
                    </div>
                </div>
                {
                    this.props.childsOption[0] && this.props.childsOption[0].length
                        ? this.props.childsOption[0].map((co, i) => (
                            <div className={styles.ReportWrapper} key={`childs-${co.label}`} ref={this.props.nextPagesRef[i]}>
                                <div className={styles.LeftRightWrapper}>
                                    <div className={styles.DateTopRight}>
                                        <div className={styles.DateText}>{this.props.selectedRelation?.label} - {moment().format('DD MMMM YYYY')}</div>
                                    </div>
                                    <div className={styles.LeftSection}>
                                        <DailyEnergyBar
                                            forReport isChildren monthly
                                            childIndex={i}
                                            allRawDataset={this.props.allRawDataset}
                                            amountLeaves={this.props.amountLeaves - 1}
                                            machines={this.props.machines}
                                            selectedRelation={this.props.selectedRelation}
                                            selectedChild={this.props.childsOption[0] ? [this.props.childsOption[0][i]] : []}
                                            queryPeriod={'m'}
                                            selectedDate={this.props.selectedDate}
                                            isEfficiency={true}
                                            downloadData={this.props.downloadData}
                                            setEvaTimes={this.props.setEvaTimes}
                                        />
                                        <div style={{ width: '100%', height: 30 }}></div>
                                        <DailyEnergyBar
                                            forReport isChildren monthly
                                            childIndex={i}
                                            allRawDataset={this.props.allRawDataset}
                                            amountLeaves={this.props.amountLeaves - 1}
                                            machines={this.props.machines}
                                            selectedRelation={this.props.selectedRelation}
                                            selectedChild={this.props.childsOption[0] ? [this.props.childsOption[0][i]] : []}
                                            queryPeriod={'m'}
                                            selectedDate={this.props.selectedDate}
                                            isEfficiency={false}
                                            downloadData={this.props.downloadData}
                                            setEvaTimes={this.props.setEvaTimes}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))
                        : null
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { powersJSON } = state.power;
    return {
        currentOrg, powersJSON
    };
};

export default connect(mapStateToProps, { getPowersJson })(MonthlyReport);
