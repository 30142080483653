import React, { Component } from "react";
import { connect } from "react-redux";
import MultipleSelect from 'react-select';
import moment from "moment";

import { updateObject, checkValidity } from "../../../../state/utils";
import styles from "./AddNewAnomaly.module.scss";
import EmailIcon from "../../../../assets/icons/email.svg";
import mobile_numbersIcon from "../../../../assets/icons/message.svg";
import LineIcon from "../../../../assets/icons/line.svg";
import InfoIcon from "../../../../assets/icons/Rule/info.svg";
import CloseIcon from "../../../../assets/icons/cancel-circle.svg";
import Input from "../../UI/Input/Input";
import TagInput from "../../UI/TagInput/TagInput";
import Button from "../../UI/Button/Button";
import Select from "../../UI/Select/Select";
import Tick from '../../../../assets/icons/tick-square.svg';
import Untick from '../../../../assets/icons/untick-square.png';
import CalibrationModal from "../CalibrationModal/CalibrationModal";

const colourStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "#1665d8",
  }),
  multiValueLabel: base => ({
    ...base,
    color: "white",
  })
};

const DEFAULT_ANOMALY_FORM = {
  name: {
    value: "",
    valid: false,
    touched: false,
    validation: {
      required: true,
    },
  },
  description: {
    value: "",
    valid: true,
    touched: true,
    validation: {
      required: false,
    },
  },
  level: {
    value: 4,
    valid: true,
    touched: true,
    validation: {
      required: true,
    },
  },
  interval: {
    value: 10,
    valid: true,
    touched: true,
    validation: {
      required: true,
      min: 10
    },
  },
  emails: {
    value: "",
    valid: true,
    touched: true,
    validation: {
      isEmail: true,
    },
  },
  mobile_numbers: {
    value: "",
    valid: true,
    touched: true,
    validation: {
      isMobileNumber: true,
      required: false
    },
  },
  line_token: {
    value: "",
    valid: true,
    touched: true,
    validation: {
      required: false,
    },
  },
  true_message: {
    value: "",
    valid: false,
    touched: false,
    validation: {
      required: true,
    },
  },
  false_message: {
    value: "",
    valid: false,
    touched: false,
    validation: {
      required: true,
    },
  },
  machine_ids: {
    value: "",
    valid: false,
    touched: false,
    validation: {
      required: true,
    },
  }
};

const LEVELS = [
  { name: "Info", id: 4 },
  { name: "Alert", id: 3 },
  { name: "Warning", id: 2 },
  { name: "Critical", id: 1 },
  { name: "Breakdown", id: 0 }
];

const CALIBRATE_STATUS = ['Not calibrated', 'Calibration in progess', 'Monitoring (Calibrated)', 'Failed'];

class AddNewAnomaly extends Component {
  state = {
    formIsValid: false,
    anomalyForm: DEFAULT_ANOMALY_FORM,
    selectedDevices: [],
    selectedMachines: [],
    calibrateStatus: 0,
    isNotified: false,
    isCalibrationModalOpened: false,
    isCalibrating: false,
    calibratedTime: null
  };

  componentDidMount() {
    this.setDefaultDeviceOptions();
    if (this.props.isAdding) {
      this.onClearFields();
    } else if (this.props.editingAnomaly) {
      this.setupForm(this.props.editingAnomaly);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAdding && prevProps.isAdding !== this.props.isAdding) {
      this.onClearFields();
    } else if (this.props.editingAnomaly && this.props.editingAnomaly !== prevProps.editingAnomaly) {
      this.setupForm(this.props.editingAnomaly);
    }
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.anomalyForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
        validation: {
          required: true,
        },
      });

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement,
      });
    }
    this.setState({ anomalyForm: updatedForm, formIsValid: true, isNotified: form.notification, selectedDevices: form.devices, selectedMachines: form.machine_ids, calibratedTime: moment(form.calibratedTime), calibrateStatus: form.calibratedTime ? 2 : 0 });
  };

  onToggleNotificationHandler = () => {
    this.setState(prevState => {
      return {
        isNotified: !prevState.isNotified
      }
    });
  }

  onClearFields = () => {
    this.setState({ anomalyForm: DEFAULT_ANOMALY_FORM, formIsValid: false, selectedDevices: [], isNotified: false, calibrateStatus: 0, isCalibrating: false, calibratedTime: null });
  };

  setDefaultDeviceOptions = () => {
    if (this.props.devices) {
      let allDeviceOptions = [],
        onlyOutputDeviceOptions = [];
      let devices = [...this.props.devices];
      devices.forEach((device) => {
        device.device_sockets.forEach((socket) => {
          allDeviceOptions.push({
            id: socket.id,
            name: socket.name + " (" + device.name + ")",
            secret: socket.device_secret,
            socket: socket.socket,
          });
          if (socket.can_control)
            onlyOutputDeviceOptions.push({
              id: socket.id,
              name: socket.name + " (" + device.name + ")",
              secret: socket.device_secret,
              socket: socket.socket,
            });
        });
      });
      this.setState({ allDeviceOptions, onlyOutputDeviceOptions });
    }
  };

  handleChange = (event) => {
    let updatedElement;
    if (event.target.name === "true_message" || event.target.name === "false_message") {
      updatedElement = updateObject(this.state.anomalyForm[event.target.name], {
        value:
          event.target.value.length <= 70
            ? event.target.value
            : this.state.anomalyForm[event.target.name].value,
        valid: checkValidity(
          event.target.value,
          this.state.anomalyForm[event.target.name].validation
        ),
        touched: true,
      });
    } else {
      updatedElement = updateObject(this.state.anomalyForm[event.target.name], {
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          this.state.anomalyForm[event.target.name].validation
        ),
        touched: true,
      });
    }

    let updatedForm = updateObject(this.state.anomalyForm, {
      [event.target.name]: updatedElement,
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ anomalyForm: updatedForm, formIsValid });
  };

  handleTypeChange = (event) => {
    let newAnomalyForm = { ...this.state.anomalyForm };
    let updatedElement = updateObject(newAnomalyForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(
        event.target.value,
        newAnomalyForm[event.target.name].validation
      ),
      touched: true,
    });

    const updatedForm = updateObject(this.state.anomalyForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ anomalyForm: updatedForm, formIsValid });
  };

  onDevicesChange = (value) => {
    this.setState({ selectedDevices: value });
  }

  onMachinesChange = (value) => {
    let newAnomalyForm = { ...this.state.anomalyForm };
    let updatedElement = updateObject(newAnomalyForm["machine_ids"], {
      value: !value ? '' : value.map((x) => x.id).join(','),
      valid: !value ? false : true,
      touched: true,
    });

    const updatedForm = updateObject(this.state.anomalyForm, {
      machine_ids: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ anomalyForm: updatedForm, selectedMachines: value, formIsValid });
  }

  onSubmitAnomalyHandler = (event) => {
    event.preventDefault();

    const { name, description, level, interval, emails, mobile_numbers, line_token, true_message, false_message } = this.state.anomalyForm;
    const value = {
      name: name.value,
      description: description.value,
      level: +level.value,
      interval: +interval.value,
      emails: emails.value,
      mobile_numbers: mobile_numbers.value,
      line_token: line_token.value,
      true_message: true_message.value,
      false_message: false_message.value,
      notification: this.state.isNotified,
      devices: this.state.selectedDevices,
      machine_ids: this.state.selectedMachines,
      calibratedTime: this.state.calibratedTime ? this.state.calibratedTime.valueOf() : ''
    };

    let anomalyList = localStorage.getItem('sight_mocking_anomaly');
    if (!anomalyList) anomalyList = [];
    else anomalyList = JSON.parse(anomalyList);

    if (this.props.isAdding) {
      anomalyList.push(value);
    } else {
      anomalyList[this.props.selectedAnomalyIndex] = value
    }
    localStorage.setItem('sight_mocking_anomaly', JSON.stringify(anomalyList));
    this.props.saveAnomalySuccessfully(anomalyList);
  };

  calibrationModalClickHandler = () => {
    this.setState(prevState => {
      return { isCalibrationModalOpened: !prevState.isCalibrationModalOpened };
    });
  }


  onCalibrateHandler = () => {
    this.setState({ isCalibrationModalOpened: true });
  }

  onConfirmCalibrateHandler = () => {
    this.setState({ isCalibrationModalOpened: false, isCalibrating: true, calibrateStatus: 1 });
    setTimeout(() => {
      this.setState({ isCalibrating: false, calibrateStatus: 2, calibratedTime: moment() });
    }, 5 * 1000);
  }

  checkSubmitButton = () => {
    let valid = this.state.formIsValid;
    const { emails, mobile_numbers, line_token } = this.state.anomalyForm;
    if (emails.value !== '' || mobile_numbers.value !== '' || line_token.value !== '') {
      valid = valid && true;
    } else {
      valid = false;
    }

    let deviceValidity = this.state.selectedDevices && this.state.selectedDevices.length ? true : false;
    valid = valid && deviceValidity;

    return !valid;
  };

  render() {
    const {
      name,
      description,
      level,
      interval,
      emails,
      mobile_numbers,
      line_token,
      true_message,
      false_message
    } = this.state.anomalyForm;

    const machinesOption = [];
    if (this.props.machines) {
      this.props.machines.forEach(machine => {
        machinesOption.push({
          id: machine.id,
          label: machine.name
        });
      });
    }

    const devicesOption = [];
    if (this.props.devices) {
      this.props.devices.forEach(device => {
        device.device_sockets.forEach(ds => {
          devicesOption.push({
            id: ds.id,
            label: ds.name + " (" + device.name + ")",
            secret: ds.device_secret,
            socket: ds.socket,
            functions: ds.functions,
            unit_name: ds.unit_name
          });
        });
      });
    }

    return (
      <form
        className={styles.AddNewAnomalyWrapper}
        onSubmit={this.onSubmitAnomalyHandler}
      >
        <div className={styles.AddFormWrapper}>
          <img
            src={CloseIcon}
            className={styles.CloseIcon}
            alt="close"
            onClick={this.props.closed}
          />
          <div className={styles.Title}>
            {this.props.isAdding ? "Add New Anomaly Detection" : "Edit Anomaly Detection"}
          </div>
          <div className={styles.Description}>
            {this.props.isAdding
              ? "Please fill the information to create a new anomaly detection"
              : "Please edit your anomaly detection details "}
          </div>
          {this.props.error &&
            this.props.error.field !== "mobile_numbers" &&
            this.props.error.field !== "hook" && (
              <div className="ErrorText">{this.props.error.text}</div>
            )}

          <hr className={styles.Divider} />

          <Input
            label="name of the anomaly detection"
            name="name"
            type="text"
            placeholder="Please name your anomaly detection"
            value={name.value}
            autoComplete="off"
            onChange={this.handleChange}
            error={`${name.touched && !name.valid ? "Anomaly Detection Name is required" : ""
              }`}
            required
          />
          <div className={styles.Flex}>
            <div className={styles.LeftTextField}>
              <Input
                label="details"
                name="description"
                type="text"
                placeholder="What is this Anomaly Detection about?"
                overidestyleformgroup={{ marginBottom: 0 }}
                value={description.value}
                autoComplete="off"
                onChange={this.handleChange}
              />
            </div>
            <div className={styles.RightTextField}>
              <Select
                label="Level"
                name="level"
                placeholder="Please choose level"
                options={LEVELS}
                value={level.value}
                autoComplete="off"
                overidestyleformgroup={{ marginBottom: 15 }}
                onChange={this.handleTypeChange}
              />
            </div>
          </div>

          <hr className={styles.Divider} />
          <div className={styles.LabelGroup} style={{ flexDirection: 'column' }}>
            <label className={styles.Label} style={{ marginBottom: '0.7rem' }}>MONITORED INPUT *</label>
            <MultipleSelect isMulti isSearchable closeMenuOnSelect={false}
              options={devicesOption}
              styles={colourStyles}
              placeholder="You can select many devices"
              value={this.state.selectedDevices}
              onChange={this.onDevicesChange}
              getOptionValue={opt => opt.id}
            />
          </div>

          <div className={styles.LabelGroup} style={{ flexDirection: 'column' }}>
            <label className={styles.Label} style={{ marginBottom: '0.7rem' }}>ANOMALY MODEL CONTROL (if not calibrate, it can't be enabled)</label>
            <div style={{ display: 'flex' }}>
              <div className={styles.ValueTitle}><span>Status:</span> <span style={{ fontSize: 16, fontWeight: '600', opacity: 1, color: this.state.calibrateStatus === 0 ? '#fff' : this.state.calibrateStatus === 1 ? '#facf55' : '#1665d8' }}>{CALIBRATE_STATUS[this.state.calibrateStatus]}</span></div>
              <div className={styles.ValueTitle} style={{ marginLeft: 15 }}>
                <span style={{ marginRight: 5 }}>Calibrated on:</span>
                <span style={{ fontSize: 16, fontWeight: '600', opacity: 1, color: this.state.calibrateStatus === 0 ? '#fff' : this.state.calibrateStatus === 1 ? '#facf55' : '#1665d8' }}>
                  {this.state.calibrateStatus === 0 ? 'N/A' : this.state.calibrateStatus === 1 ? 'Loading...' : this.state.calibratedTime.format("DD/MM/YYYY HH:mm:ss")}
                </span>
              </div>
            </div>
            <div style={{ width: 200, marginTop: 10 }}>
              <Button
                type="button"
                name={this.state.isCalibrating ? 'Loading...' : 'Calibrate'}
                color="primary"
                disabled={this.state.isCalibrating}
                loading={this.props.loading}
                overideButtonStyles={{ marginBottom: 0 }}
                click={this.onCalibrateHandler}
              />
            </div>
          </div>

          <Input
            label="interval (at least 10 seconds) *"
            name="interval"
            type="number"
            overidestyleformgroup={{ marginBottom: 0 }}
            value={interval.value}
            autoComplete="off"
            onChange={this.handleChange}
          />

          <div className={styles.LabelGroup} style={{ alignItems: 'center', cursor: 'pointer', marginTop: '1rem' }} onClick={this.onToggleNotificationHandler}>
            <img src={this.state.isNotified ? Tick : Untick} alt="Tick" style={{ width: 20, height: 20 }} />
            <label className={styles.Label} style={{ color: 'white', marginLeft: 15, cursor: 'pointer' }}>NOTIFICATION</label>
          </div>

          <hr className={styles.Divider} />
          <div className={styles.Topic}>Please fill at least one of Emails/Mobile numbers/Line Token *</div>
          <div className={styles.Flex}>
            <div className={styles.LeftTextField}>
              <TagInput
                icon={EmailIcon}
                name="emails"
                type="text"
                placeholder="email@technimal.co.th, Add by press spacebar"
                overidestyleformgroup={{ marginBottom: 0 }}
                value={emails.value}
                validation={emails.validation}
                onChange={this.handleChange}
                error={`${"Emails must be in the correct format, 'hello@technimal.co.th'"}`}
                setStillShowingError={(value) => { this.setState({ stillShowingErrorOnMobiles: value }) }}
              />
            </div>
            <div className={styles.LeftTextField}>
              <TagInput
                icon={mobile_numbersIcon}
                name="mobile_numbers"
                type="text"
                placeholder="0891231234, Add by press spacebar"
                overidestyleformgroup={{ marginBottom: 0 }}
                value={mobile_numbers.value}
                validation={mobile_numbers.validation}
                onChange={this.handleChange}
                error={"Phone No. must be in the correct format, '0801234567'"}
                setStillShowingError={(value) => { this.setState({ stillShowingErrorOnEmails: value }) }}
              />
            </div>
            <div className={styles.RightTextField}>
              <Input
                suffixclick={this.instructionModalClickHandler}
                // suffixClick={"text"}
                icon={LineIcon}
                name="line_token"
                type="text"
                placeholder="LINE Token"
                overidestyleformgroup={{ marginBottom: 0 }}
                value={line_token.value}
                autoComplete="off"
                onChange={this.handleChange}
                suffixicon={InfoIcon}
                suffix="info"
              // onsuffixicclick={0}
              // error={`${emails.touched && !emails.valid ? 'Email is required and in the correct format' : ''}`}
              />
            </div>
          </div>
          <div className={styles.Flex} style={{ marginTop: 16 }}>
            <div className={styles.LeftTextField}>
              <Input
                label="Message when conditions are TRUE *"
                name="true_message"
                type="textarea"
                rows="2"
                // placeholder="Emails, Separate by comma (,) *"
                overidestyleformgroup={{ marginBottom: 0 }}
                value={true_message.value}
                autoComplete="off"
                onChange={this.handleChange}
              />
              <div className={styles.countingCharacters}>
                {true_message.value.length} / 70
              </div>
            </div>
            <div className={styles.RightTextField}>
              <Input
                label="Message when conditions are FALSE *"
                name="false_message"
                type="textarea"
                rows="2"
                // placeholder="+66991231234, Separate by comma (,) *"
                overidestyleformgroup={{ marginBottom: 0 }}
                value={false_message.value}
                autoComplete="off"
                onChange={this.handleChange}
              />
              <div className={styles.countingCharacters}>
                {false_message.value.length} / 70
              </div>
            </div>
          </div>
          <div className={styles.LabelGroup} style={{ flexDirection: 'column' }}>
            <label className={styles.Label} style={{ marginBottom: '0.7rem' }}>DISPLAY ON *</label>
            <MultipleSelect isMulti isSearchable closeMenuOnSelect={false}
              options={machinesOption}
              styles={colourStyles}
              placeholder="You can select many machines (it will pop the event up when it meets the condition."
              value={this.state.selectedMachines}
              onChange={this.onMachinesChange}
              getOptionValue={opt => opt.id}
            />
          </div>

        </div>
        <div className={styles.ButtonsWrapper}>
          {this.props.isAdding ? (
            <React.Fragment>
              <div style={{ width: 112 }}>
                <Button
                  type="button"
                  name="Cancel"
                  color="borderred"
                  noMargin
                  click={this.props.closed}
                />
              </div>
              <div style={{ width: 200 }}>
                <Button
                  type="submit"
                  name={this.props.loading ? "Loading..." : "Done"}
                  color="primary"
                  disabled={this.checkSubmitButton() || this.state.isCalibrating}
                  loading={this.props.loading}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div style={{ width: 112 }}>
                <Button
                  type="button"
                  name="Delete"
                  color="red"
                  noMargin
                  click={this.props.toggleDeleteModal}
                />
              </div>
              <div style={{ width: 200 }}>
                <Button
                  type="submit"
                  name={this.props.loading ? "Loading..." : "Save"}
                  color="green"
                  disabled={this.checkSubmitButton() || this.state.isCalibrating}
                  loading={this.props.loading}
                />
              </div>
            </React.Fragment>
          )}
          <CalibrationModal
            confirmCalibrate={this.onConfirmCalibrateHandler}
            open={this.state.isCalibrationModalOpened}
            modalClosed={this.calibrationModalClickHandler}
          />
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state) => {
  // const { currentOrg } = state.org;
  const { devices } = state.devices;
  const { machines } = state.machine;
  // const { type, loading, result, addedAnomaly, updatedAnomaly, error } = state.rule;
  return {
    //   currentOrg,
    devices,
    //   type,
    //   loading,
    //   result,
    //   addedAnomaly,
    //   updatedAnomaly,
    //   error,
    machines
  };
};

export default connect(mapStateToProps, {})(AddNewAnomaly);
