import React, { Component } from 'react';
import ReactSelect from 'react-select';

import styles from './LampForm.module.scss';
import widgetStyles from '../../WidgetDrawer/WidgetDrawer.module.scss';
import LightGray from '../../../../../assets/icons/Widgets/Lamp/lampstyle-light-gray.svg';
import LightBlue from '../../../../../assets/icons/Widgets/Lamp/lampstyle-light-blue.svg';
import BgGray from '../../../../../assets/icons/Widgets/Lamp/lampstyle-bg-gray.svg';
import BgBlue from '../../../../../assets/icons/Widgets/Lamp/lampstyle-bg-blue.svg';
import ColorTrigger from './ColorTrigger';

const DEFAULT_LAMP_PROPS = {
  lampStyle: null,
  usingMcStatus: false,
  colorTriggers: [{ isValid: false, point: '', color: null, displayedText: '' }]
};

const colourStyles = {
  singleValue: (styles) => ({
    ...styles,
    color: "white"
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
  }),
  input: base => ({
    ...base,
    color: "white"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
};

class LampForm extends Component {
  state = {
    formIsValid: false,
    lampProps: DEFAULT_LAMP_PROPS
  }

  componentDidMount() {
    if (this.props.editingLampProps) {
      const lampProps = { ...this.props.editingLampProps };
      if (!lampProps.colorTriggers || !lampProps.colorTriggers.length) {
        lampProps.colorTriggers = [{ isValid: false, point: '', color: null, displayedText: '' }];
      } else if (lampProps.colorTriggers) {
        lampProps.colorTriggers.forEach((ct, i) => {
          if (!ct.isValid) lampProps.colorTriggers[i].isValid = true;
          if (!ct.displayedText) lampProps.colorTriggers[i].displayedText = '';
        });
      }
      this.setState({ lampProps });
    } else {
      this.setState({ lampProps: DEFAULT_LAMP_PROPS });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.editingLampProps !== this.props.editingLampProps && this.props.editingLampProps) {
      const lampProps = this.props.editingLampProps;
      if (!lampProps.colorTriggers || !lampProps.colorTriggers.length) {
        lampProps.colorTriggers = [{ isValid: false, point: '', color: null, displayedText: '' }];
      }
      this.setState({ lampProps });
    }
  }

  onChangeLampStyleHandler = (selectedStyle) => {
    let updatedLampProps = { ...this.state.lampProps };
    updatedLampProps.lampStyle = selectedStyle;
    this.setState({ lampProps: updatedLampProps });
    this.props.handleLampChange(updatedLampProps);
  }

  addNewColorTrigger = () => {
    let updatedLampProps = { ...this.state.lampProps };
    let defaultColorRange = { isValid: false, point: '', color: null, displayedText: '' };
    updatedLampProps.colorTriggers.push(defaultColorRange);
    this.setState({ lampProps: updatedLampProps });
    this.props.handleLampChange(updatedLampProps);
  }

  removeColorTrigger = (index) => {
    let updatedLampProps = { ...this.state.lampProps };
    updatedLampProps.colorTriggers.splice(index, 1);
    this.setState({ lampProps: updatedLampProps });
    this.props.handleLampChange(updatedLampProps);
  }

  handleColorTriggerChange = (index, colorTriggerForm, isValid) => {
    let updatedLampProps = { ...this.state.lampProps };
    updatedLampProps.colorTriggers[index] = {
      isValid: isValid,
      point: +colorTriggerForm.point.value,
      color: colorTriggerForm.color.value,
      displayedText: colorTriggerForm.displayedText.value
    };
    this.setState({ lampProps: updatedLampProps });
    this.props.handleLampChange(updatedLampProps);
  }

  onChangeMcStatusHandler = () => {
    let updatedLampProps = { ...this.state.lampProps };
    updatedLampProps.usingMcStatus = !updatedLampProps.usingMcStatus;
    this.setState({ lampProps: updatedLampProps });
  }

  render() {
    const { lampStyle, usingMcStatus, colorTriggers } = this.state.lampProps;

    return (
      <div className={widgetStyles.FormGroup}>
        <label className={widgetStyles.Title}>LAMP STYLE *</label>
        <div style={{ display: 'flex', marginBottom: 15 }}>
          <div className={`${styles.LampStyleWrapper} ${lampStyle === 'light' ? styles.Selected : ''}`} style={{ marginRight: 10, backgroundColor: lampStyle === 'light' ? 'rgba(22, 101, 216, 0.5)' : '' }} onClick={() => this.onChangeLampStyleHandler('light')}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={lampStyle === 'light' ? LightBlue : LightGray} style={{ width: 36, marginRight: 10 }} alt="lamp selected" />
              <div className={`${styles.Title} ${lampStyle === 'light' ? styles.Selected : ''}`}>Light</div>
            </div>
          </div>
          <div className={`${styles.LampStyleWrapper} ${lampStyle === 'bg' ? styles.Selected : ''}`} style={{ backgroundColor: lampStyle === 'bg' ? 'rgba(22, 101, 216, 0.5)' : '' }} onClick={() => this.onChangeLampStyleHandler('bg')}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={lampStyle === 'bg' ? BgBlue : BgGray} style={{ width: 40, marginRight: 5 }} alt="bg selected" />
              <div className={`${styles.Title} ${lampStyle === 'bg' ? styles.Selected : ''}`}>Background</div>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          {/* <label style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" name="checkbox" value={usingMcStatus} onClick={this.onChangeMcStatusHandler} />
            <div className={styles.MachineStatusCbLabel}>Using machine's statuses</div>
          </label> */}
          {
            usingMcStatus
              ? <React.Fragment>
                <label style={{ marginTop: 15 }} className={widgetStyles.Title}>MACHINE *</label>
                <ReactSelect isSearchable options={this.props.deviceOptionsOnlyOutput} styles={colourStyles}
                  placeholder="Select a Machine"
                  // value={this.state.selectedSensor}
                  // onChange={this.onChange}
                  getOptionValue={opt => opt.id}
                />
                <div style={{ marginTop: 10, marginLeft: 2 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: 10, height: 10, backgroundColor: 'red' }}></div>
                    <div className={styles.MachineStatusCbLabel}>(0) Breakdown</div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: 10, height: 10, backgroundColor: 'yellow' }}></div>
                    <div className={styles.MachineStatusCbLabel}>(1) Test</div>
                  </div>
                </div>
              </React.Fragment>
              : <React.Fragment>
                <div style={{ display: 'flex', marginTop: 15 }}>
                  <div style={{ flex: 1 }}>
                    <label className={widgetStyles.Title}>Value Triggers</label>
                  </div>
                  <div style={{ flex: 2, marginLeft: 15 }}>
                    <label className={widgetStyles.Title}>Colors</label>
                  </div>
                </div>
                {
                  colorTriggers && colorTriggers.length ?
                    colorTriggers.map((ct, index) => (
                      <ColorTrigger
                        key={`colorTriggers-${index + 1}`}
                        colorTrigger={ct}
                        index={index}
                        formLength={colorTriggers.length}
                        addNewColorTrigger={this.addNewColorTrigger}
                        removeColorTrigger={() => this.removeColorTrigger(index)}
                        handleColorTriggerChange={this.handleColorTriggerChange}
                        colorOptions={this.props.colorOptions} />
                    )) : null
                }
              </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

export default LampForm;
