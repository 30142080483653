import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import styles from './NotificationDropdown.module.scss';
import Modal from '../../UI/Modal/Modal';
import InactiveNotificationIcon from '../../../../assets/icons/navigation-items/notification-white.png';
import ActiveNotificationIcon from '../../../../assets/icons/navigation-items/notification-blue.png';

import { getNotificationsInbox, markAsRead } from '../../../../state/ducks/Notification/actions';

class NotificationDropdown extends Component {
  state = {
    isDropdownOpened: false,
    isNotificationModalOpened: false,
    modalContent: null
  }

  componentDidMount() {
    this.props.getNotificationsInbox();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg) {
      this.props.getNotificationsInbox();
    }
  }

  dropdownMenuClickHandler = () => {
    this.setState(prevState => {
      return { isDropdownOpened: !prevState.isDropdownOpened }
    });
  }

  dropdownMenuClose = () => {
    this.setState({ isDropdownOpened: false });
  }

  isReadYet = (time) => {
    return time !== '0001-01-01T00:00:00Z';
  }

  onSelectNotificationHandler = (inbox) => {
    if (!this.isReadYet(inbox.view_date)) this.props.markAsRead(inbox.uuid);
    this.setState({ modalContent: inbox, isNotificationModalOpened: true });
  }

  onMarkAllAsReadHandler = () => {
    let uuids = '';
    this.props.inbox.forEach(inb => {
      if (!this.isReadYet(inb.view_date)) {
        uuids += (uuids === '' ? '' : ',') + inb.uuid;
      }
    });
    if (uuids) this.props.markAsRead(uuids);
  }

  modalClosed = () => {
    this.setState(prevState => {
      return { isNotificationModalOpened: !prevState.isNotificationModalOpened };
    });
  }

  render() {
    let dropdownClasses = [styles.Dropdown];
    if (this.state.isDropdownOpened) {
      dropdownClasses.push(styles.DropdownIn);
    } else {
      dropdownClasses.push(styles.DropdownOut);
    }

    let isMobile = this.props.isMobile;
    let ovStyle = { minHeight: 0, width: 600 };
    if (isMobile) {
      ovStyle.width = "calc(100% - 20px)";
      ovStyle.maxHeight = "calc(100% - 60px)";
      ovStyle.minHeight = "100px";
    }

    let unreadAmount = 0;
    if (this.props.inbox) {
      unreadAmount = _.reduce(this.props.inbox, (memo, b) => { return !this.isReadYet(b.view_date) ? memo + 1 : memo }, 0);
    }

    return (
      <div className={styles.NotificationDropdown}>
        <Modal
          isMobile={isMobile}
          show={this.state.isNotificationModalOpened}
          modalClosed={this.modalClosed}
          overideStyles={ovStyle}
        >
          {
            this.state.modalContent
              ? <div className={styles.ModalContent}>
                <div className={styles.Topic}>{this.state.modalContent.notification ? this.state.modalContent.notification.title : 'N/A'}</div>
                <div className={styles.Posted}>{this.state.modalContent.notification ? moment(this.state.modalContent.notification.start).format('llll') : null}</div>
                <hr className={styles.ContentDivider} />
                <div className={styles.Content}>{this.state.modalContent.notification ? this.state.modalContent.notification.content : '-'}</div>
              </div>
              : null
          }
        </Modal>
        <div style={{ display: "flex" }} className={styles.MenuClickable} onClick={this.dropdownMenuClickHandler}>
          {unreadAmount ? <div className={styles.Badge}>{unreadAmount}</div> : null}
          <div className={styles.MenuIcon}>
            <img className={styles.NotifIcon} src={this.state.isDropdownOpened ? ActiveNotificationIcon : InactiveNotificationIcon} alt="notif icon" />
          </div>
        </div>
        {this.state.isDropdownOpened ? <div className={styles.DropdownContainer} onClick={this.dropdownMenuClose} ></div> : null}
        <div className={dropdownClasses.join(' ')}>
          <ul style={{ maxHeight: this.props.offsetHeight ? 'calc(100vh - ' + (this.props.offsetHeight + 85) + 'px)' : 'calc(100vh - 85px)' }}>
            <div className={styles.HeaderNotifWrapper}>
              <div className={styles.Header}>Notification</div>
              <div className={styles.Mark} onClick={this.onMarkAllAsReadHandler}>Mark all as read</div>
            </div>
            {
              this.props.inbox && this.props.inbox.length
                ? this.props.inbox.map(inb => (
                  <li key={inb.uuid} className={`${this.isReadYet(inb.view_date) ? styles.Read : styles.Unread}`} onClick={() => this.onSelectNotificationHandler(inb)}>
                    <div style={{ display: "flex" }} >
                      <div style={{ display: 'flex', alignItems: 'center', margin: '10px 30px' }}>
                        <img style={{ width: 24, height: 24 }} src={InactiveNotificationIcon} alt="annoucement icon" />
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 30 }}>
                          <div className={`${styles.Content} ${this.isReadYet(inb.view_date) ? styles.Read : styles.Unread}`} style={{ fontWeight: '600', fontSize: 16, marginBottom: 5 }}>{_.truncate(inb.notification?.title, { 'length': 27, 'separator': ' ' })}</div>
                          <div className={`${styles.Content} ${this.isReadYet(inb.view_date) ? styles.Read : styles.Unread}`}>{_.truncate(inb.notification?.content, { 'length': 100, 'separator': ' ' })}</div>
                          <div className={`${styles.Time} ${this.isReadYet(inb.view_date) ? styles.Read : styles.Unread}`}>{moment.duration(moment(inb.notification?.start).valueOf() - moment().valueOf(), "milliseconds").humanize(true)}</div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
                : <div style={{ textAlign: 'center', margin: 30 }}>Your inbox is empty.</div>
            }
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { loggedInUser } = state.auth;
  const { inbox } = state.notification;
  return {
    loggedInUser, inbox, currentOrg
  };
};

export default connect(mapStateToProps, { getNotificationsInbox, markAsRead })(NotificationDropdown);