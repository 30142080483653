import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';

import styles from './PlansTable.module.scss';
import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';

import { addAdvanceProductionPlan, editAdvanceProductionPlan } from '../../../../state/ducks/AdvanceProductionPlan/actions';

const colourStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
    color: 'white'
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "white"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "#1665d8",
  }),
  multiValueLabel: base => ({
    ...base,
    color: "white",
  })
};

const DEFAULT_PLAN_FORM = {
  po: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  start: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  end: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  startTime: {
    value: '00:00',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  endTime: {
    value: '00:00',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  target: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false,
      min: 0
    }
  },
  machine: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  }
};

class PlanDrawer extends Component {
  state = {
    formIsValid: false,
    planForm: DEFAULT_PLAN_FORM,
    currentColumns: [],
    columnsForm: {},
    machinesOption: []
  }

  componentDidMount() {
    if (this.props.machines) {
      this.setMachinesOption(this.props.machines);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAdding && (prevProps.isAdding !== this.props.isAdding)) {
      this.onClearFields();
      this.onSetColumns(this.props.structure);
    } else if (this.props.structure && prevProps.structure !== this.props.structure) {
      this.onClearFields();
      this.onSetColumns(this.props.structure);
    } else if ((prevProps.addedAdvanceProductionPlan !== this.props.addedAdvanceProductionPlan) || (prevProps.updatedAdvanceProductionPlan !== this.props.updatedAdvanceProductionPlan)) {
      this.props.closed();
    } else if (this.props.editingPlan && prevProps.editingPlan !== this.props.editingPlan) {
      this.onSetColumns(this.props.structure);
      this.setupForm(this.props.editingPlan);
    } else if (this.props.machines && prevProps.machines !== this.props.machines) {
      this.setMachinesOption(this.props.machines);
    }
  }

  setMachinesOption = (machines) => {
    const machinesOption = [];
    machines.forEach(machine => {
      machinesOption.push({
        id: machine.id,
        label: machine.name
      });
    });
    this.setState({ machinesOption });
  }

  onClearFields = () => {
    const planForm = { ...DEFAULT_PLAN_FORM };
    const defaultStop = moment().add(1, 'days').startOf('day').valueOf();
    const defaultStart = new Date().setHours(0, 0, 0, 0);
    planForm.start.value = moment(defaultStart).format('YYYY-MM-DD');
    planForm.end.value = moment(defaultStop).format('YYYY-MM-DD');
    this.setState({ planForm, formIsValid: false, currentColumns: [], columnsForm: {} });
  }

  onSetColumns = (structure) => {
    const { columns, po_column_order, start_column_order, end_column_order, target_column_order, machine_column_order } = structure;
    const currentColumns = [];
    const columnsForm = {};
    currentColumns[po_column_order - 1] = { name: 'po', display_name: 'PO' };
    currentColumns[start_column_order - 1] = { name: 'start', display_name: 'Start' };
    currentColumns[end_column_order - 1] = { name: 'end', display_name: 'End' };
    currentColumns[target_column_order - 1] = { name: 'target', display_name: 'Target' };
    currentColumns[machine_column_order - 1] = { name: 'machine', display_name: 'Machine' };
    if (columns && columns.length) {
      columns.forEach(col => {
        currentColumns[col.order - 1] = { name: col.name, display_name: col.display_name };
        columnsForm[col.name] = '';
      });
    }

    this.setState({ currentColumns, columnsForm });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.planForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement;
      if (inputIdentifier === 'start' || inputIdentifier === 'startTime') {
        updatedElement = updateObject(inputIdentifier === 'start' ? updatedForm.start : updatedForm.startTime, {
          value: inputIdentifier === 'start' ? moment(form.start).format('YYYY-MM-DD') : moment(form.start).format('HH:mm'),
          valid: true,
          touched: true,
        });
      } else if (inputIdentifier === 'end' || inputIdentifier === 'endTime') {
        updatedElement = updateObject(inputIdentifier === 'end' ? updatedForm.end : updatedForm.endTime, {
          value: inputIdentifier === 'end' ? moment(form.end).format('YYYY-MM-DD') : moment(form.end).format('HH:mm'),
          valid: true,
          touched: true,
        });
      } else if (inputIdentifier === 'machine') {
        const found = form.machine ? this.state.machinesOption.filter(machine => machine.id === form.machine.id)[0] : ''
        updatedElement = updateObject(updatedForm.machine, {
          value: found,
          valid: true,
          touched: true,
        });
      } else {
        updatedElement = updateObject(updatedForm[inputIdentifier], {
          value: form[inputIdentifier] ? form[inputIdentifier] : '',
          valid: true,
          touched: true,
        });
      }

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }

    this.setState({ planForm: updatedForm, formIsValid: true, columnsForm: form.columns });
  }

  handleFormChange = event => {
    if (this.state.planForm[event.target.name]) {
      let updatedElement = updateObject(this.state.planForm[event.target.name], {
        value: event.target.value,
        valid: checkValidity(event.target.value, this.state.planForm[event.target.name].validation),
        touched: true
      });

      const updatedForm = updateObject(this.state.planForm, {
        [event.target.name]: updatedElement
      });

      let formIsValid = true;
      for (let inputIdentifier in updatedForm) {
        formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
      }

      this.setState({ planForm: updatedForm, formIsValid });
    } else {
      let updatedColumnsForm = { ...this.state.columnsForm };
      updatedColumnsForm[event.target.name] = event.target.value;
      this.setState({ columnsForm: updatedColumnsForm });
    }
  };

  onMachineChange = (value) => {
    let updatedElement = updateObject(this.state.planForm["machine"], {
      value,
      valid: checkValidity(value, this.state.planForm["machine"].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.planForm, {
      machine: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ planForm: updatedForm, formIsValid });
  }

  onSubmitPlanHandler = (event) => {
    event.preventDefault();
    const { po, start, startTime, end, endTime, target, machine } = this.state.planForm;
    if (this.props.isAdding) {
      this.props.addAdvanceProductionPlan(
        this.props.structure.uuid,
        po.value,
        target.value,
        moment(start.value + ' ' + startTime.value),
        moment(end.value + ' ' + endTime.value),
        machine.value.id,
        this.state.columnsForm
      );
    } else {
      this.props.editAdvanceProductionPlan(
        this.props.editingPlan.uuid,
        po.value,
        target.value,
        moment(start.value + ' ' + startTime.value),
        moment(end.value + ' ' + endTime.value),
        machine.value.id,
        this.state.columnsForm
      );
    }
    this.onClearFields();
    this.onSetColumns(this.props.structure);
  }

  render() {
    const { planForm, currentColumns, columnsForm } = this.state;
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    return (
      <div>
        <div className="ErrorText">{this.props.isAdding && this.props.result === 'failed' ? this.props.error : ''}</div>
        <form onSubmit={this.onSubmitPlanHandler}>
          {
            currentColumns && currentColumns.length ?
              currentColumns.map(col => {
                if (col.name === 'start' || col.name === 'end') {
                  return <div key={`input-${col.name}`} style={{ flex: 1, marginBottom: '1rem' }}>
                    <label className={styles.Title}>{col.name} *</label>
                    <div style={{ display: 'flex', position: 'relative' }}>
                      {!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
                      <input
                        className={styles.SelectBox}
                        style={{ paddingLeft: !isChrome ? 46 : null, flex: 2 }}
                        type="date"
                        onChange={this.handleFormChange}
                        name={col.name}
                        value={planForm[col.name].value}
                      />
                      <input
                        className={styles.SelectBox}
                        type="time"
                        onChange={this.handleFormChange}
                        name={col.name === 'start' ? 'startTime' : 'endTime'}
                        value={col.name === 'start' ? planForm.startTime.value : planForm.endTime.value}
                      />
                    </div>
                  </div>;
                } else if (col.name === 'machine') {
                  return <div key={`input-${col.name}`} style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                    <label className={styles.Title}>Machine</label>
                    {
                      this.props.machines && this.props.machines.length
                        ? <Select isSearchable options={this.state.machinesOption} styles={colourStyles}
                          placeholder="Please select a plan's machine"
                          value={planForm.machine.value}
                          onChange={this.onMachineChange}
                          getOptionValue={opt => opt.id}
                        />
                        : <Button type="button" name="Machine not found! Create a new one?" color="white" click={() => this.props.route.history.push('/machine')} />
                    }
                  </div>
                } else {
                  return <Input key={`input-${col.name}`}
                    label={col.display_name}
                    name={col.name}
                    type={col.name === 'target' ? 'number' : 'text'}
                    value={planForm[col.name] ? planForm[col.name].value : columnsForm[col.name]}
                    placeholder={`Please insert plan's ${col.display_name.toLowerCase()}`}
                    autoComplete="off"
                    onChange={this.handleFormChange}
                    error={`${planForm[col.name] && planForm[col.name].touched && !planForm[col.name].valid ? `${col.display_name} is required` : ''}`}
                    required={planForm[col.name] && planForm[col.name].validation.required}
                  />;
                }
              }) : null
          }

          <div style={{ height: 70, width: 'auto' }} />

          {
            this.props.isAdding
              ? <>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Done'}
                  color="primary"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
              </>
              : <>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Save'}
                  color="green"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Delete" color="borderred" click={this.props.toggleDeleteModal} />
              </>
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { currentOrg } = state.org;
  const { addedAdvanceProductionPlan, updatedAdvanceProductionPlan, result, error } = state.advanceProductionPlan;
  const { machines } = state.machine;
  return { loggedInUser, currentOrg, addedAdvanceProductionPlan, updatedAdvanceProductionPlan, result, error, machines };
};

export default connect(mapStateToProps, { addAdvanceProductionPlan, editAdvanceProductionPlan })(PlanDrawer);