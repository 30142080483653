import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './SchedulerType.module.scss';
import EditIcon from '../../../../assets/icons/edit-gray.svg';

import { getSchedulerTypes } from '../../../../state/ducks/Scheduler/actions';
import { DELETE_SCHEDULER_TYPE_SUCCESS } from '../../../../state/ducks/Scheduler/types';

class SchedulerTypeList extends Component {
	componentDidMount() {
		this.props.getSchedulerTypes(this.props.currentOrg);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg) {
			this.props.getSchedulerTypes(this.props.currentOrg);
		} else if (this.props.result !== prevProps.result && this.props.result === 'success') {
			if ((prevProps.addedSchedulerType !== this.props.addedSchedulerType) || (prevProps.updatedSchedulerType !== this.props.updatedSchedulerType
				|| this.props.type === DELETE_SCHEDULER_TYPE_SUCCESS)) {
				this.props.getSchedulerTypes(this.props.currentOrg);
			}
		}
	}

	render() {
		const { schedulerTypes } = this.props;

		return (
			<div className={styles.SchedulerType}>
				{
					schedulerTypes && schedulerTypes.length
						? schedulerTypes.map(st => (
							<div key={`SchedulerType-${st.id}`} className={styles.SchedulerTypeCard}>
								<div className={styles.SchedulerTypeInfos}>
									<div className={styles.SchedulerTypeName}><div style={{ backgroundColor: `${st.color}`}} className={styles.SchedulerColor} />{st.name}</div>
									<div className={styles.SchedulerTypeCate}>Category : {st.category.charAt(0).toUpperCase() + st.category.slice(1)}</div>
								</div>
								<div className={styles.ActionButtons}>
									<img src={EditIcon} style={{ width: 20, height: 20, marginLeft: 30 }} alt="Edit Note Type" onClick={(e) => this.props.editSchedulerType(e, st)} />
								</div>
							</div>
						))
						: null
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { currentOrg } = state.org;
	const { type, result, schedulerTypes, addedSchedulerType, updatedSchedulerType } = state.scheduler;
	return { currentOrg, type, result, schedulerTypes, addedSchedulerType, updatedSchedulerType };
};

export default connect(mapStateToProps, { getSchedulerTypes })(SchedulerTypeList);
