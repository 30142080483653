import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Energy.module.scss';
import EnergyDashboard from '../../components/Energy/EnergyDashboard/EnergyDashboard';

const TABS = ['Usage (kWh)', 'Efficiency (kWh/unit)'];

class Energy extends Component {
  state = {
    currentTabIndex: 0
  }

  render() {

    return (
      <div className={styles.Energy}>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              TABS.map((tab, index) => (
                <div key={`tab-${index}`} className={index === this.state.currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentTabIndex: index })}>
                  <span className={styles.MenuTitle}>{tab}</span>
                </div>
              ))
            }
          </div>
        </div>
        <div className={styles.MainContentPadding}>
          <EnergyDashboard currentTab={this.state.currentTabIndex} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;

  return {
    currentOrg
  };
};

export default connect(mapStateToProps, {})(Energy);
