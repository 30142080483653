import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import styles from './RelationsDrawer.module.scss';

import { updateObject, checkValidity } from '../../../../state/utils';
import MultipleSelect from 'react-select';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import DeleteIcon from '../../../../assets/icons/delete-red-bin.svg';

const colourStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "white"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "#1665d8",
  }),
  multiValueLabel: base => ({
    ...base,
    color: "white",
  })
};

const DEFAULT_RELATIONS_FORM = {
  title: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  type: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  }
};

const DATA_SOURCE_FROM = [{ id: 'any', label: 'Any' }, { id: 'machines', label: 'Machines' }, { id: 'devices', label: 'Devices' }];

class RelationsDrawer extends Component {
  state = {
    formIsValid: false,
    relationsFrom: DEFAULT_RELATIONS_FORM,
    typeOption: DATA_SOURCE_FROM,
    isMachineNow: false,
    isDeviceNow: false,
    machinesValue: [],
    devicesValue: [],
    currentEditingNode: null,
    newAddingChildren: [],
    devicesOption: [],
    lastMachineInLane: null,
    warningDevicesChildren: false
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.editingNode !== this.props.editingNode && this.props.editingNode) {
      this.onClearFields();
      const depth = this.props.editingNode.g_currentPath.split('-');
      let current = { ...this.props.relations };
      let lastMachineInLane = null;
      if (depth.length > 1) {
        depth.forEach(index => {
          if (index) {
            current = current.children[+index];
            if (current.isMachine) {
              lastMachineInLane = { ...current };
            }
          }
        });
      }
      this.setupForm(current);
      this.setState({ currentEditingNode: current, lastMachineInLane });
    }
  }

  onClearFields = () => {
    this.setState({ relationsFrom: DEFAULT_RELATIONS_FORM, formIsValid: false, newAddingChildren: [], machinesValue: [], devicesValue: [], isMachineNow: false, isDeviceNow: false });
  }

  setupForm = (node) => {
    let updatedForm = { ...this.state.relationsFrom };
    let titleValue = '';
    let typeValue = '';
    let isMachineNow = false;
    let isDeviceNow = false;
    if (!node.isMachine && !node.isDevice) {
      titleValue = node.value.title;
    }
    if (node.children && node.children.length && node.children[0].isMachine) {
      typeValue = DATA_SOURCE_FROM[1];
      isMachineNow = true;
      this.setMachinesValue(node.children);
    } else if (node.children && node.children.length && node.children[0].isDevice) {
      typeValue = DATA_SOURCE_FROM[2];
      isDeviceNow = true;
      this.setDevicesValue(node.children);
    } else if (node.children && node.children.length && !node.children[0].isMachine && !node.children[0].isDevice) {
      typeValue = DATA_SOURCE_FROM[0];
    }

    const updatedTitleElement = updateObject(updatedForm.title, {
      value: titleValue,
      valid: true,
      touched: true
    });
    const updatedTypeElement = updateObject(updatedForm.type, {
      value: typeValue,
      valid: true,
      touched: true
    });
    updatedForm = updateObject(updatedForm, {
      ['title']: updatedTitleElement,
      ['type']: updatedTypeElement
    });
    this.setState({ relationsFrom: updatedForm, formIsValid: true, isMachineNow, isDeviceNow });
  }

  setMachinesValue = (children) => {
    let machinesValue = [];
    if (this.props.devices) {
      children.forEach(ch => {
        this.props.machines.forEach(machine => {
          if (machine.id === +ch.itsId) {
            machinesValue.push({
              id: machine.id,
              label: machine.name
            });
          }
        });
      });
    }
    this.setState({ machinesValue });
  }

  setDevicesValue = (children) => {
    let devicesValue = [];
    if (this.props.devices) {
      children.forEach(ch => {
        this.props.devices.forEach(device => {
          device.device_sockets.forEach(ds => {
            if (ds.id === +ch.itsId) {
              devicesValue.push({
                id: ds.id,
                label: ds.name + " (" + device.name + ")",
                secret: ds.device_secret,
                socket: ds.socket
              });
              return;
            }
          });
        });
      });
    }
    this.setState({ devicesValue });
  }

  onChangeHandler = event => {
    const { name, value } = event.target;
    let updatedElement = updateObject(this.state.relationsFrom[name], {
      value,
      valid: name === 'type' ? true : checkValidity(value, this.state.relationsFrom[name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.relationsFrom, {
      [name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    let isMachineNow = false;
    let isDeviceNow = false;
    if (name === 'type' && value) {
      if (value.id === 'machines') {
        isMachineNow = true;
        isDeviceNow = false;
      } else if (value.id === 'devices') {
        isMachineNow = false;
        isDeviceNow = true;
        this.setDevicesOption();
      } else if (value.id === 'any') {
        isMachineNow = false;
        isDeviceNow = false;
      }
    }

    this.setState({ relationsFrom: updatedForm, formIsValid, isDeviceNow, isMachineNow });
  };

  setDevicesOption = () => {
    const depth = this.props.editingNode.g_currentPath.split('-');
    let foundLastMachine = null;
    if (this.props.machines && this.state.lastMachineInLane) {
      foundLastMachine = this.props.machines.find(machine => machine.id === this.state.lastMachineInLane.itsId);
    }

    let warningDevicesChildren = false;
    const devicesOption = [];
    if (foundLastMachine && foundLastMachine.properties && foundLastMachine.properties.devicesChildren) {
      if (this.props.devices) {
        this.props.devices.forEach(device => {
          device.device_sockets.forEach(ds => {
            const found = foundLastMachine.properties.devicesChildren.find(dc => dc.split('/')[0] === ds.device_secret && +dc.split('/')[1] === ds.socket);
            if (found) {
              devicesOption.push({
                id: ds.id,
                label: ds.name + " (" + device.name + ")",
                secret: ds.device_secret,
                socket: ds.socket
              });
            }
          });
        });
      }
      warningDevicesChildren = false;
    } else if (foundLastMachine && foundLastMachine.properties && (!foundLastMachine.properties.devicesChildren || foundLastMachine.properties.length === 0)) {
      warningDevicesChildren = true;
    } else {
      if (this.props.devices) {
        this.props.devices.forEach(device => {
          device.device_sockets.forEach(ds => {
            devicesOption.push({
              id: ds.id,
              label: ds.name + " (" + device.name + ")",
              secret: ds.device_secret,
              socket: ds.socket
            });
          });
        });
      }
      warningDevicesChildren = false;
    }
    this.setState({ devicesOption, warningDevicesChildren });
  }

  updateRelationsForm = (titleValue, type) => {
    let updatedTitleElement = updateObject(this.state.relationsFrom['title'], {
      value: titleValue,
      valid: checkValidity(titleValue, this.state.relationsFrom['title'].validation),
      touched: true
    });
    let updatedRelationsByElement = updateObject(this.state.relationsFrom['type'], {
      value: type,
      valid: true,
      touched: true
    });

    const updatedForm = updateObject(this.state.relationsFrom, {
      ['title']: updatedTitleElement,
      ['type']: updatedRelationsByElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ relationsFrom: updatedForm, formIsValid });
  }

  onSelectMultiHandler = (name, value) => {
    if (name === 'machines') {
      this.setState({ machinesValue: value });
    } else if (name === 'devices') {
      this.setState({ devicesValue: value });
    }
  }

  onAddChildHandler = () => {
    const newAddingChildren = [...this.state.newAddingChildren];
    if (!newAddingChildren.length || newAddingChildren[newAddingChildren.length - 1])
      newAddingChildren.push('');
    this.setState({ newAddingChildren });
  }

  onChangeChildrenTitleHandler = (index, value) => {
    const newAddingChildren = [...this.state.newAddingChildren];
    newAddingChildren[index] = value;
    this.setState({ newAddingChildren });
  }

  onRemoveNewChildHandler = (index) => {
    const newAddingChildren = [...this.state.newAddingChildren];
    newAddingChildren.splice(index, 1)
    this.setState({ newAddingChildren });
  }

  checkSaveButton = () => {
    let disabled = false;
    if (this.state.newAddingChildren && this.state.newAddingChildren.length) {
      const isEmpty = this.state.newAddingChildren.findIndex(nac => nac === '');
      if (isEmpty >= 0) return true;
    }
    return disabled;
  }

  onSaveRelationsHandler = (event) => {
    event.preventDefault();
    const { title, type } = this.state.relationsFrom;
    const updatedEditingNode = { ...this.state.currentEditingNode };
    if (!updatedEditingNode.isMachine && !updatedEditingNode.isDevice) {
      updatedEditingNode.value.title = title.value;
    }
    if (type.value && type.value.id === 'any') {
      if (this.state.newAddingChildren.length) {
        if (updatedEditingNode.children && updatedEditingNode.children.length && (updatedEditingNode.children[0].isMachine || updatedEditingNode.children[0].isDevice)) {
          updatedEditingNode.children = [];
        }
        this.state.newAddingChildren.forEach(nac => {
          let newUUID = uuidv4();
          updatedEditingNode.children.push({
            id: newUUID,
            uuid: newUUID,
            value: { title: nac },
            children: []
          });
        });
      }
    } else if (type.value && type.value.id === 'machines') {
      if (updatedEditingNode.children[0] && !updatedEditingNode.children[0].isMachine) {
        updatedEditingNode.children = [];
      }
      this.state.machinesValue.forEach(machine => {
        let newUUID = uuidv4();
        const isExist = updatedEditingNode.children.find(ch => ch.itsId === machine.id);
        if (!isExist) {
          updatedEditingNode.children.push({
            id: newUUID,
            uuid: newUUID,
            itsId: machine.id,
            isMachine: true,
            value: { title: machine.label },
            children: []
          });
        }
      });
    } else if (type.value && type.value.id === 'devices') {
      if (updatedEditingNode.children[0] && !updatedEditingNode.children[0].isDevice) {
        updatedEditingNode.children = [];
      }
      this.state.devicesValue.forEach(device => {
        let newUUID = uuidv4();
        const isExist = updatedEditingNode.children.find(ch => ch.itsId === device.id);
        if (!isExist) {
          updatedEditingNode.children.push({
            id: newUUID,
            uuid: newUUID,
            itsId: device.id,
            isDevice: true,
            secret: device.secret,
            socket: device.socket,
            value: { title: device.label },
            children: []
          });
        }
      });
    }

    this.props.saveRelations(updatedEditingNode);
    this.setState({ updatedEditingNode });
  }

  render() {
    const { title, type } = this.state.relationsFrom;

    const machinesOption = [];
    if (this.props.machines) {
      this.props.machines.forEach(machine => {
        machinesOption.push({
          id: machine.id,
          label: machine.name
        });
      });
    }

    return (
      <div>
        <form onSubmit={this.onSaveRelationsHandler}>
          {
            this.state.currentEditingNode && this.state.currentEditingNode.isDevice
              ? <div style={{ fontWeight: '500', fontSize: 14 }}>This is the last level (Device level), you can not add more children.</div>
              : null
          }
          {
            this.state.currentEditingNode && !this.state.currentEditingNode.isMachine && !this.state.currentEditingNode.isDevice ?
              <Input
                label={`${this.state.currentEditingNode.isRoot ? 'Relations title' : 'title'}`}
                name="title"
                type="text"
                value={title.value}
                placeholder="Please insert your title"
                autoComplete="off"
                onChange={this.onChangeHandler}
                required
              />
              : null
          }
          {
            this.state.currentEditingNode && !this.state.currentEditingNode.isDevice ?
              <div className={styles.LabelGroup} style={{ flexDirection: 'column' }}>
                <label className={styles.Label} style={{ marginBottom: '0.7rem' }}>Children's Type *</label>
                <MultipleSelect closeMenuOnSelect={true}
                  isClearable
                  options={this.state.typeOption}
                  styles={colourStyles}
                  placeholder="any, machines or devices"
                  value={type.value}
                  onChange={(value) => this.onChangeHandler({ target: { name: 'type', value } })}
                  getOptionValue={opt => opt.id}
                />
                {this.state.currentEditingNode && this.state.currentEditingNode.children && this.state.currentEditingNode.children.length
                  ? <div className={styles.Warning}>* If you change children's type, the existing children will be lost.</div>
                  : null}
              </div>
              : null
          }

          {
            this.state.isMachineNow
              ? <div className={styles.LabelGroup} style={{ flexDirection: 'column' }}>
                <label className={styles.Label} style={{ marginBottom: '0.7rem' }}>Machines *</label>
                <MultipleSelect isMulti isSearchable closeMenuOnSelect={false}
                  options={machinesOption}
                  styles={colourStyles}
                  placeholder="you can update machines"
                  value={this.state.machinesValue}
                  onChange={(value) => this.onSelectMultiHandler('machines', value)}
                  getOptionValue={opt => opt.id}
                />
              </div>
              : null
          }
          {
            this.state.isDeviceNow
              ? <div className={styles.LabelGroup} style={{ flexDirection: 'column' }}>
                <label className={styles.Label} style={{ marginBottom: '0.7rem' }}>Devices *</label>
                <MultipleSelect isMulti isSearchable closeMenuOnSelect={false}
                  options={this.state.devicesOption}
                  styles={colourStyles}
                  placeholder="you can update devices"
                  value={this.state.devicesValue}
                  onChange={(value) => this.onSelectMultiHandler('devices', value)}
                  getOptionValue={opt => opt.id}
                />
                {
                  this.state.warningDevicesChildren && this.state.lastMachineInLane
                    ? <div className={styles.Warning}>There is no devices children set on {this.state.lastMachineInLane.value.title}.</div>
                    : null
                }
              </div>
              : null
          }

          {
            type.value && !this.state.isDeviceNow && !this.state.isMachineNow
              ? <div className={styles.LabelGroup} style={{ flexDirection: 'column' }}>
                <label className={styles.Label} style={{ marginBottom: '0.5rem' }}>Children</label>
                {
                  this.state.currentEditingNode && this.state.currentEditingNode.children
                    ? this.state.currentEditingNode.children.map(ch => (
                      <div key={ch.uuid} className={styles.ChildrenText}>- {ch.value.title}</div>
                    ))
                    : null
                }
                {
                  this.state.newAddingChildren.map((addingCh, i) => (
                    <div key={`new-adding-children-${i}`} style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                      <Input
                        name="title"
                        type="text"
                        value={addingCh}
                        placeholder={`Please insert new children title #${i + 1}`}
                        autoComplete="off"
                        onChange={(e) => this.onChangeChildrenTitleHandler(i, e.target.value)}
                        overidestyleformgroup={{ marginBottom: 0, flex: 1 }}
                      />
                      <img className={styles.DeleteChildButton} alt="Delete chikd" src={DeleteIcon} onClick={() => this.onRemoveNewChildHandler(i)} />
                    </div>
                  ))
                }
                <Button overideButtonStyles={{ borderStyle: 'dashed', marginTop: 8 }}
                  type="button"
                  name="Add new child"
                  color="borderblue"
                  click={this.onAddChildHandler}
                />
              </div>
              : null
          }

          <div style={{ height: 70, width: 'auto' }} />
          {
            this.state.currentEditingNode && !this.state.currentEditingNode.isDevice
              ? <Button
                type="submit"
                name={this.props.loading ? 'Loading...' : 'Save'}
                color="green"
                disabled={!this.state.formIsValid || this.checkSaveButton()}
                loading={this.props.loading}
              />
              : null
          }
          {
            this.state.currentEditingNode && !this.state.currentEditingNode.isRoot
              ? <Button type="button" name="Delete" color="borderred" click={() => this.props.toggleDeleteModal(false)} />
              : this.state.currentEditingNode && this.state.currentEditingNode.isRoot && this.props.currentTabIndex !== -1
                ? <Button type="button" name="Delete this relations " color="red" click={() => this.props.toggleDeleteModal(true)} />
                : null
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { machines } = state.machine;
  const { devices } = state.devices;
  return { machines, devices };
};

export default connect(mapStateToProps, {})(RelationsDrawer);