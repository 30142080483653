
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_FORMULAS, ADD_FORMULA, EDIT_FORMULA, DELETE_FORMULA, GET_FORMULA_MAPPINGS, POST_FORMULA_MAPPING, DELETE_FORMULA_MAPPING } from './types';

export function* watchFormulas() {
  yield takeEvery(GET_FORMULAS, getFormulasSaga);
  yield takeEvery(ADD_FORMULA, addFormulaSaga);
  yield takeEvery(EDIT_FORMULA, editFormulaSaga);
  yield takeEvery(DELETE_FORMULA, deleteFormulaSaga);
  yield takeEvery(GET_FORMULA_MAPPINGS, getFormulaMappingsSaga);
  yield takeEvery(POST_FORMULA_MAPPING, postFormulaMappingSaga);
  yield takeEvery(DELETE_FORMULA_MAPPING, deleteFormulaMappingSaga);
}

export function* getFormulasSaga(params) {
  yield put(actions.getFormulasStart());

  let api = 'v1/formula';
  api += '?org_id=' + params.org_id;
  api += params.references ? '&references=' + params.references : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET FORMULAS SUCCESS", response.data);
    yield put(actions.getFormulasSuccess(response.data));
  } catch (err) {
    // console.log("GET FORMULAS FAILED", err)
    yield put(actions.getFormulasFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* addFormulaSaga(params) {
  yield put(actions.addFormulaStart());

  const api = 'v1/formula';
  let postData = JSON.stringify(params.formula);

  try {
    const response = yield axios.post(api, postData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    // console.log("ADD FORMULA SUCCESS", response);
    yield put(actions.addFormulaSuccess(response.data));
  } catch (err) {
    console.log("ADD FORMULA FAILED", err.response.data);
    yield put(actions.addFormulaFail(err.response.data.error));
  }
}

export function* editFormulaSaga(params) {
  yield put(actions.editFormulaStart());

  const api = 'v1/formula?id=' + params.id;
  let putData = JSON.stringify(params.formula);

  try {
    const response = yield axios.put(api, putData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    // console.log("EDIT FORMULA SUCCESS", response.data);
    yield put(actions.editFormulaSuccess(response.data));
  } catch (err) {
    console.log("EDIT FORMULA FAILED", err.response.data);
    yield put(actions.editFormulaFail(err.response.data.error));
  }
}

export function* deleteFormulaSaga(params) {
  yield put(actions.deleteFormulaStart());

  const api = 'v1/formula?id=' + params.id;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE Formula SUCCESS", response.data);
    yield put(actions.deleteFormulaSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE Formula FAILED", err.response);
    yield put(actions.deleteFormulaFail(err.response));
  }
}

export function* getFormulaMappingsSaga(params) {
  yield put(actions.getFormulaMappingsStart());

  let api = 'v1/formula/map';
  api += '?org_id=' + params.org_id;
  api += params.references ? '&references=' + params.references : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET FORMULA_MAPPINGS SUCCESS", response.data);
    yield put(actions.getFormulaMappingsSuccess(response.data));
  } catch (err) {
    console.log("GET FORMULA_MAPPINGS FAILED", err)
    yield put(actions.getFormulaMappingsFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* postFormulaMappingSaga(params) {
  yield put(actions.postFormulaMappingStart());

  let postData = 'id=' + params.id;
  postData += "&ref=" + params.ref;

  const api = 'v1/formula/map';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD FORMULA_MAPPING SUCCESS", response);
    yield put(actions.postFormulaMappingSuccess(response.data));
  } catch (err) {
    console.log("ADD FORMULA_MAPPING FAILED", err.response.data);
    yield put(actions.postFormulaMappingFail(err.response.data.error));
  }
}

export function* deleteFormulaMappingSaga(params) {
  yield put(actions.deleteFormulaMappingStart());

  const api = 'v1/formula/map?ref=' + params.ref;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE FormulaMapping SUCCESS", response.data);
    yield put(actions.deleteFormulaMappingSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE FormulaMapping FAILED", err.response);
    yield put(actions.deleteFormulaMappingFail(err.response));
  }
}