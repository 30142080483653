import { Component } from 'react';

import styles from './Pagination.module.scss';

export default class Pagination extends Component {

    render() {
        const { total, currentPage, rowsPerPage, onChangePage, onChangeRowsPerPage } = this.props;

        const totalPages = Math.ceil(total / rowsPerPage);

        let paginations = [];
        for (let i = 1 ; i <= totalPages ; i++) {
            paginations.push(i);
        }

        let currentSelect = currentPage + 1;
        if (currentPage >= totalPages) {
            currentSelect = 1;
        }

        return (
            <div className={styles.Container}>
                <div className={styles.PaginationWrapper}>
                    {paginations.map((page, index) => {
                        if (page > 1 && page < totalPages) {
                            if (page < currentSelect - 10 || page > currentSelect + 10)
                                return null;
                            if (page < currentSelect - 3 || page > currentSelect + 3)
                                return <div key={'pagination-'+index} className={styles.SkipPagination}>.</div>
                        }
                        return (
                            <div
                                key={'pagination-'+index}
                                className={currentSelect === page ? styles.Pagination + ' ' + styles.PaginationActive : styles.Pagination}
                                onClick={(e) => onChangePage(e, index)}
                            >
                                {page}
                            </div>
                        );
                    })}
                </div>
                <div className={styles.AmountWrapper}>
                    <span>Items per page:</span>
                    <select value={rowsPerPage} className={styles.SelectBox} onChange={onChangeRowsPerPage}>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>
        );
    }
}