import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './ActualsTable.module.scss';
import SearchIcon from '../../../../assets/icons/search.svg';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import AttachedIcon from '../../../../assets/icons/attach-file.png';
import Arrow from '../../../../assets/icons/arrow.svg';
import SortArrow from '../../../../assets/icons/sort.png';
import LoadingTable from '../../UI/LoadingSkeleton/LoadingTable/LoadingTable';

import { getApqOfMachine } from '../../../../state/ducks/Machine/actions';
import { calculateOee } from '../../../../state/ducks/AdvanceActualProduction/actions';
import { getModuleConfigs } from '../../../../state/ducks/Module/actions';

class ActualsTable extends Component {
  state = {
    headers: [],
    autoSearchIndex: 0,
    displayingActuals: [],
    constActuals: [],
    searchText: '',
    sorting: {},
    isFloating: false
  };

  componentDidMount() {
    this.onAutoSearchPlans(0);
    this.updateHeader();
    this.props.getModuleConfigs(this.props.currentOrg, 3);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.structure !== this.props.structure) {
      this.updateHeader();
    }
    if (prevProps.actuals !== this.props.actuals) {
      this.onSearchingHandler();
    }
    if (prevProps.advancedSearch !== this.props.advancedSearch && this.props.advancedSearch) {
      this.setState({ autoSearchIndex: -1 });
    }
    if (this.props.currentOrg !== prevProps.currentOrg && this.props.currentOrg) {
      this.clearAllDataInterval();
      this.props.getModuleConfigs(this.props.currentOrg, 3);
    } else if (this.props.machineAPQ !== prevProps.machineAPQ && this.props.machineAPQ) {
      // DP : 5/08/22 
      const { value, properties } = this.props.machineAPQ;
      const foundIndex = this.state.displayingActuals.findIndex(da => da.uuid === properties.actual_uuid);
      const updatedActuals = [...this.state.displayingActuals];
      updatedActuals[foundIndex].total = value.total;
      updatedActuals[foundIndex].ok = value.good;
      updatedActuals[foundIndex].defect = value.defect;

      this.setState({ displayingActuals: updatedActuals });
    } else if (this.props.actualOee && prevProps.actualOee !== this.props.actualOee) {
      const { sum, properties } = this.props.actualOee;
      const foundIndex = this.state.displayingActuals.findIndex(da => da.uuid === properties.actual_uuid);
      const updatedActuals = [...this.state.displayingActuals];
      if (updatedActuals && updatedActuals[foundIndex] && sum) {
        updatedActuals[foundIndex].total = sum.total;
        updatedActuals[foundIndex].ok = sum.good;
        updatedActuals[foundIndex].defect = sum.defect;
      }
      this.setState({ displayingActuals: updatedActuals });
    }
    if (this.props.moduleConfigs !== prevProps.moduleConfigs && this.props.moduleConfigs) {
      if (this.props.moduleConfigs.length) {
        let isFloating = this.props.moduleConfigs.find(mc => mc.key === 'isFloating');
        isFloating = isFloating && isFloating.value === 'true' ? true : false;
        this.setState({ isFloating });
      }
    }
  }

  componentWillUnmount() {
    this.clearAllDataInterval();
  }

  clearAllDataInterval = () => {
    if (this.apqMachinesInterval && Object.keys(this.apqMachinesInterval).length) {
      Object.keys(this.apqMachinesInterval).forEach(uuid => clearInterval(this.apqMachinesInterval[uuid]));
    }
    this.apqMachinesInterval = {};
  }

  updateHeader = () => {
    if (!this.props.structure)
      return;
    const { columns, po_column_order, start_column_order, end_column_order, target_column_order, machine_column_order } = this.props.structure;
    const currentColumns = [];
    currentColumns[po_column_order - 1] = { name: 'po', display_name: 'PO' };
    currentColumns[start_column_order - 1] = { name: 'start', display_name: 'Start' };
    currentColumns[end_column_order - 1] = { name: 'end', display_name: 'End' };
    currentColumns[target_column_order - 1] = { name: 'target', display_name: 'Target' };
    currentColumns[machine_column_order - 1] = { name: 'machine', display_name: 'Machine' };
    if (columns && columns.length) {
      columns.forEach(col => currentColumns[col.order - 1] = { name: col.name, display_name: col.display_name, custom: true });
    }
    currentColumns.push({ name: 'total', display_name: 'Total' });
    currentColumns.push({ name: 'ok', display_name: 'Good' });
    currentColumns.push({ name: 'defect', display_name: 'Defect' });
    this.setState({ headers: currentColumns });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  onAutoSearchPlans = (index) => {
    const { onFilter } = this.props;
    const { autoSearchIndex } = this.state;
    if (autoSearchIndex !== index) {
      let start = new Date().setHours(0, 0, 0, 0);
      let end = new Date().setHours(23, 59, 59, 999);
      if (index === 1) {
        // THIS WEEK
        start = moment().day(0).add(1, 'days').startOf('day').valueOf();
        end = moment().day(7).endOf('day').valueOf();
      } else if (index === 2) {
        // THISMONTH
        start = moment().startOf('month').valueOf();
        end = moment().endOf('month').valueOf();
      }
      this.setState({ autoSearchIndex: index });
      onFilter(start, end);
    }
  }

  onSearchingHandler = (e) => {
    const { actuals } = this.props;
    let { searchText } = this.state;

    if (e)
      searchText = e.target.value.toLowerCase();

    if (actuals?.length) {
      const searchResult = actuals.filter(p => {
        const status = p.end > p.start ? 'completed' : 'running';
        if (p && p.plan && p.plan.po.toLowerCase().includes(searchText)) return p;
        else if (p && p.target === +searchText) return p;
        else if (p && p.machine && p.machine.name.toLowerCase().includes(searchText)) return p;
        else if (status.includes(searchText)) return p;
        else if (p && p.columns) {
          let found = false;
          Object.keys(p.columns).map(key => {
            if (p.columns[key].toLowerCase().includes(searchText)) found = true;
          });
          if (found) return p;
        }
      });
      this.prepareDataForRunningActual(searchResult);
      this.setState({ displayingActuals: searchResult, searchText, constActuals: searchResult });
    } else {
      this.setState({ searchText, displayingActuals: [], constActuals: [] });
    }
  }

  prepareDataForRunningActual = (actuals) => {
    actuals.forEach(actual => {
      if (this.isActualRunning(actual.end)) {
        // this.props.getApqOfMachine(actual.machine.id, new Date(actual.start).getTime(), new Date().getTime(), { actual_uuid: actual.uuid });
        this.props.calculateOee(actual.uuid, null, new Date(actual.start).getTime(), new Date().getTime(), 60, { actual_uuid: actual.uuid }, this.state.isFloating);
      }
    });
  }

  displayCustomColumns = (value, name, actual) => {
    const pattern = /^\d+$/;
    if (value) {
      if (pattern.test(value)) return (+value).toLocaleString();
      return value;
    }
    else if (!value && actual.plan && actual.plan.columns[name]) return actual.plan.columns[name];

    return '-';
  }

  sortBy = (header, isCustom) => {
    if (isCustom) return;

    let updatedSorting = { ...this.state.sorting };
    const alreadySortedIndex = header === updatedSorting.by;
    let reset = false;
    if (alreadySortedIndex) {
      if (updatedSorting.how === 'asc') updatedSorting.how = 'desc';
      else {
        reset = true;
        updatedSorting = {};
      };
    } else {
      updatedSorting = { by: header, how: 'asc' };
    }
    this.renderBySorting(updatedSorting, reset);
    this.setState({ sorting: updatedSorting });
  }

  renderBySorting = (sorting, reset) => {
    let updatedActuals = [...this.state.displayingActuals];

    const { by, how } = sorting;
    if (by === 'po') {
      if (how === 'asc') updatedActuals = updatedActuals.sort((a, b) => a.plan[by].localeCompare(b.plan[by]));
      else updatedActuals = updatedActuals.sort((a, b) => b.plan[by].localeCompare(a.plan[by]));
    } else if (by === 'start' || by === 'end') {
      if (how === 'asc') updatedActuals = updatedActuals.sort((a, b) => new Date(a[by]) - new Date(b[by]));
      else updatedActuals = updatedActuals.sort((a, b) => new Date(b[by]) - new Date(a[by]));
    } else if (by === 'target') {
      if (how === 'asc') updatedActuals = updatedActuals.sort((a, b) => +a[by] - +b[by]);
      else updatedActuals = updatedActuals.sort((a, b) => +b[by] - +a[by]);
    } else if (by === 'machine') {
      if (how === 'asc') updatedActuals = updatedActuals.sort((a, b) => a.machine.name.localeCompare(b.machine.name));
      else updatedActuals = updatedActuals.sort((a, b) => b.machine.name.localeCompare(a.machine.name));
    } else if (by === 'total' || by === 'good' || by === 'defect') {
      let name = by === 'good' ? 'ok' : by;
      if (how === 'asc') updatedActuals = updatedActuals.sort((a, b) => +a[name] - +b[name]);
      else updatedActuals = updatedActuals.sort((a, b) => +b[name] - +a[name]);
    }

    this.setState({ displayingActuals: reset ? [...this.state.constActuals] : updatedActuals });
  }

  isActualRunning = (actual) => {
    return actual === '0001-01-01T00:00:00Z';
  }

  render() {
    const { loading, onAdvancedSearchClick, advancedSearch, documentNotes } = this.props;
    const { autoSearchIndex, headers, displayingActuals, searchText, sorting } = this.state;

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
          <div className={styles.SearchBarWrapper}>
            <div className={[styles.AutoFillBox, !advancedSearch && autoSearchIndex === 0 ? styles.Selected : ''].join(' ')} onClick={() => this.onAutoSearchPlans(0)}>TODAY</div>
            <div className={[styles.AutoFillBox, !advancedSearch && autoSearchIndex === 1 ? styles.Selected : ''].join(' ')} onClick={() => this.onAutoSearchPlans(1)}>THIS WEEK</div>
            <div className={[styles.AutoFillBox, !advancedSearch && autoSearchIndex === 2 ? styles.Selected : ''].join(' ')} onClick={() => this.onAutoSearchPlans(2)}>THIS MONTH</div>
            <div className={styles.SearchInputWrapper}>
              <img className={styles.SearchIconInput} style={{ verticalAlign: 'middle', width: 15 }} src={SearchIcon} alt="Search" />
              <input className={styles.SearchExpand} type="text" placeholder="Search" value={searchText} onChange={this.onSearchingHandler} />
              <div className={styles.AdvancedSearch} onClick={onAdvancedSearchClick}>Advanced Search</div>
            </div>
          </div>
        </div>

        <div className={styles.TableWrapper}>
          <table>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>No.</th>
                {
                  headers.map(header => (
                    <th key={header.name} style={{ display: sorting.by === header.name ? 'flex' : 'table-cell' }} onClick={() => this.sortBy(header.name, header.custom)}>
                      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                        <span style={{ flex: 1 }}>{header.display_name}</span>
                        {
                          sorting.by === header.name
                            ? <img style={{ width: 15, transform: `rotate(${sorting.how === 'asc' ? '-90' : '90'}deg)` }} src={Arrow} alt="sort" />
                            : !header.custom
                              ? <img style={{ width: 15 }} src={SortArrow} alt="sort" />
                              : null
                        }
                      </div>
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                loading ? <tr><td colSpan={headers.length + 1}><LoadingTable /></td></tr>
                  : displayingActuals && displayingActuals.length ?
                    displayingActuals?.map((p, index) => (
                      <tr key={`poplans-${index + 1}`} style={this.isActualRunning(p.end) ? { color: '#1665d8', backgroundColor: '#1665d811' } : {}}>
                        <td style={{ textAlign: 'center' }}>{index + 1}.</td>
                        {
                          headers.map(header => (
                            <td key={header.name} style={{ wordBreak: header.name === 'po' ? 'initial' : 'initial' }}>
                              {
                                header.name === 'start' || header.name === 'end'
                                  ? header.name === 'end' && this.isActualRunning(p[header.name])
                                    ? 'Running'
                                    : moment(p[header.name]).format('DD/MM/YYYY HH:mm')
                                  : header.name === 'po'
                                    ? p.plan?.po
                                    : header.name === 'target' || header.name === 'total' || header.name === 'ok' || header.name === 'defect'
                                      ? p[header.name].toLocaleString()
                                      : header.name === 'machine'
                                        ? p.machine ? p.machine.name : '-'
                                        : p[header.name]
                                          ? p[header.name]
                                          : this.displayCustomColumns(p.columns[header.name], header.name, p)
                              }
                            </td>
                          ))
                        }
                        {
                          this.props.isAdminOfOrg && !this.isActualRunning(p.end)
                            ? <td style={{ width: 50 }}>
                              <img src={EditIcon} style={{ cursor: 'pointer', width: 20 }} alt="edit" onClick={() => { this.props.editActual(p) }} />
                              {documentNotes && documentNotes.find(dn => dn.ref === p.uuid) ? <img src={AttachedIcon} style={{ cursor: 'pointer', width: 22, marginLeft: 6 }} alt="edit" onClick={() => { this.props.editActual(p) }} /> : null}
                            </td>
                            : <td style={{ width: 50 }}></td>
                        }
                      </tr>
                    )) : <tr><td colSpan="100%" style={{ textAlign: 'center' }}>No Actuals found</td></tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { machineAPQ } = state.machine;
  const { actualOee } = state.advanceActualProduction;
  const { documentNotes } = state.document;
  const { moduleConfigs } = state.module;

  return { currentOrg, machineAPQ, documentNotes, actualOee, moduleConfigs };
};

export default connect(mapStateToProps, { getApqOfMachine, calculateOee, getModuleConfigs })(ActualsTable);
