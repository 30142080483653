import * as actionTypes from "./types";
import { updateObject } from "../../utils";

const localCurrentOrg = parseInt(localStorage.getItem("owlCurrentOrg"));

const initialState = {
  filteredOrgBuff: null,
  type: null,
  result: null,
  error: null,
  loading: false,
  org: null,
  orgs: null,
  addedOrg: null,
  updatedOrg: null,
  currentOrg: localCurrentOrg ? localCurrentOrg : null,
  addedAdmin: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_ORG: return setCurrentOrg(state, action);
    case actionTypes.SET_CURRENT_ORG_FROM_KEY: return setCurrentOrgFromKey(state, action);
    case actionTypes.SET_FILTERED_ORG_BUFF: return setfilteredOrgBuff(state, action);
    case actionTypes.SET_FILTERED_ORG_BUFF_UP: return setfilteredOrgBuffUp(state, action);
    case actionTypes.SET_FILTERED_ORG_BUFF_DOWN: return setfilteredOrgBuffDown(state, action);
    case actionTypes.SET_FILTERED_ORG_BUFF_FALSE: return setfilteredOrgBufffalse(state, action);

    case actionTypes.CLEAR_CURRENT_ORG: return clearCurrentOrg(state, action);

    case actionTypes.GET_ORGS_START: return getOrgsStart(state, action);
    case actionTypes.GET_ORGS_SUCCESS: return getOrgsSuccess(state, action);
    case actionTypes.GET_ORGS_FAIL: return getOrgsFail(state, action);

    case actionTypes.ADD_ORG_START: return addOrgStart(state, action);
    case actionTypes.ADD_ORG_SUCCESS: return addOrgSuccess(state, action);
    case actionTypes.ADD_ORG_FAIL: return addOrgFail(state, action);

    case actionTypes.EDIT_ORG_START: return editOrgStart(state, action);
    case actionTypes.EDIT_ORG_SUCCESS: return editOrgSuccess(state, action);
    case actionTypes.EDIT_ORG_FAIL: return editOrgFail(state, action);

    case actionTypes.DELETE_ORG_START: return deleteOrgStart(state, action);
    case actionTypes.DELETE_ORG_SUCCESS: return deleteOrgSuccess(state, action);
    case actionTypes.DELETE_ORG_FAIL: return deleteOrgFail(state, action);

    case actionTypes.INVITE_MEMBER_START: return inviteMemberStart(state, action);
    case actionTypes.INVITE_MEMBER_SUCCESS: return inviteMemberSuccess(state, action);
    case actionTypes.INVITE_MEMBER_FAIL: return inviteMemberFail(state, action);

    case actionTypes.REMOVE_MEMBER_START: return removeMemberStart(state, action);
    case actionTypes.REMOVE_MEMBER_SUCCESS: return removeMemberSuccess(state, action);
    case actionTypes.REMOVE_MEMBER_FAIL: return removeMemberFail(state, action);

    case actionTypes.GET_ADMINS_START: return getAdminsStart(state, action);
    case actionTypes.GET_ADMINS_SUCCESS: return getAdminsSuccess(state, action);
    case actionTypes.GET_ADMINS_FAIL: return getAdminsFail(state, action);

    case actionTypes.INVITE_ADMIN_START: return inviteAdminStart(state, action);
    case actionTypes.INVITE_ADMIN_SUCCESS: return inviteAdminSuccess(state, action);
    case actionTypes.INVITE_ADMIN_FAIL: return inviteAdminFail(state, action);

    case actionTypes.REMOVE_ADMIN_START: return removeAdminStart(state, action);
    case actionTypes.REMOVE_ADMIN_SUCCESS: return removeAdminSuccess(state, action);
    case actionTypes.REMOVE_ADMIN_FAIL: return removeAdminFail(state, action);

    case actionTypes.GEN_API_KEY_START: return genAPIKeyStart(state, action);
    case actionTypes.GEN_API_KEY_SUCCESS: return genAPIKeySuccess(state, action);
    case actionTypes.GEN_API_KEY_FAIL: return genAPIKeyFail(state, action);

    default: return state;
  }
};

function getindexBuffAndId(filteredOrg) {
  var buffer;
  var itemId;
  buffer = filteredOrg.map((item) => {
    return item.didSelected
      ? { didSelected: true, itemId: item["itemId"] }
      : { didSelected: false, itemId: item["itemId"] };
  });
  var indexBuff = buffer
    .map(function (e) {
      return e.didSelected;
    })
    .indexOf(true);
  if (indexBuff === -1) {
    indexBuff = null;
    itemId = null;
  } else {
    itemId = buffer[indexBuff]["itemId"];
  }
  return { indexBuff, itemId };
}

const setfilteredOrgBufffalse = (state, action) => {
  if (state.filteredOrgBuff !== null) {
    var filteredOrgBuff = [...state.filteredOrgBuff];
    const currentOrg = state.currentOrg;
    var anyTrue = false;
    var newFilteredOrgBuff = filteredOrgBuff.map((item) => {
      if (+currentOrg === +item["itemId"]) {
        anyTrue = true;
        return { didSelected: true, itemId: item["itemId"] };
      } else {
        return { didSelected: false, itemId: item["itemId"] };
      }
    });
    try {
      if (!anyTrue) {
        newFilteredOrgBuff[0]["didSelected"] = true;
      }
    } catch (e) { }

    return { ...state, filteredOrgBuff: newFilteredOrgBuff };
  } else {
    return { ...state };
  }
};

const setfilteredOrgBuff = (state, action) => {
  const currentOrg = state.currentOrg;
  var anyTrue = false;
  const isEqual = action.isEqual;
  const newFilteredOrgBuff = action.newFilteredOrgBuff.map((item, index) => {
    if (currentOrg === item.id && isEqual) {
      anyTrue = true;
      return { didSelected: true, itemId: item.id };
    } else {
      return { didSelected: false, itemId: item.id };
    }
  });
  try {
    if (!anyTrue) {
      newFilteredOrgBuff[0]["didSelected"] = true;
    }
  } catch (e) { }
  return { ...state, filteredOrgBuff: newFilteredOrgBuff };
};

const setfilteredOrgBuffUp = (state, action) => {
  var newFilteredOrgBuff = [...state.filteredOrgBuff];
  const { indexBuff } = getindexBuffAndId(newFilteredOrgBuff);
  if (indexBuff === null) {
  } else if (indexBuff > 0) {
    newFilteredOrgBuff[indexBuff]["didSelected"] = false;
    newFilteredOrgBuff[indexBuff - 1]["didSelected"] = true;
  }
  return { ...state, filteredOrgBuff: newFilteredOrgBuff };
};

const setfilteredOrgBuffDown = (state, action) => {
  var newFilteredOrgBuff = [...state.filteredOrgBuff];
  const { indexBuff } = getindexBuffAndId(newFilteredOrgBuff);
  if (indexBuff == null) {
    newFilteredOrgBuff[0]["didSelected"] = true;
  } else if (indexBuff < newFilteredOrgBuff.length - 1) {
    newFilteredOrgBuff[indexBuff]["didSelected"] = false;
    newFilteredOrgBuff[indexBuff + 1]["didSelected"] = true;
  }
  return { ...state, filteredOrgBuff: newFilteredOrgBuff };
};

const setCurrentOrgFromKey = (state, action) => {
  var newFilteredOrgBuff = [...state.filteredOrgBuff];
  const { indexBuff, itemId } = getindexBuffAndId(newFilteredOrgBuff);
  if (indexBuff !== null) {
    localStorage.setItem("owlCurrentOrg", itemId);
    return updateObject(state, {
      currentOrg: +itemId,
    });
  } else {
    return { ...state };
  }
};

const setCurrentOrg = (state, action) => {
  localStorage.setItem("owlCurrentOrg", action.org_id);
  return updateObject(state, {
    currentOrg: +action.org_id
  });
};

const clearCurrentOrg = (state, action) => {
  return updateObject(state, {
    currentOrg: null
  });
};

const getOrgsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
};

const getOrgsSuccess = (state, action) => {
  if (action.org) {
    return updateObject(state, {
      org: action.org,
      error: null,
      loading: false
    });
  } else {
    return updateObject(state, {
      orgs: action.orgs,
      error: null,
      loading: false
    });
  }
};

const getOrgsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const addOrgStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
};

const addOrgSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedOrg: action.addedOrg,
    error: null,
    loading: false
  });
};

const addOrgFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const editOrgStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
};

const editOrgSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedOrg: action.updatedOrg,
    error: null,
    loading: false
  });
};

const editOrgFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const deleteOrgStart = (state, action) => {
  return updateObject(state, {
    result: null,
    error: null,
    loading: true,
    type: action.type
  });
};

const deleteOrgSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false,
  });
};

const deleteOrgFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const inviteMemberStart = (state, action) => {
  return updateObject(state, {
    result: null,
    error: null,
    loading: true,
    type: action.type
  });
};

const inviteMemberSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
};

const inviteMemberFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const removeMemberStart = (state, action) => {
  return updateObject(state, {
    result: null,
    error: null,
    loading: true,
    type: action.type
  });
};

const removeMemberSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
};

const removeMemberFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const getAdminsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
};

const getAdminsSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    admins: action.admins,
    error: null,
    loading: false
  });
};

const getAdminsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const inviteAdminStart = (state, action) => {
  return updateObject(state, {
    result: null,
    error: null,
    loading: true,
    type: action.type
  });
};

const inviteAdminSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedAdmin: action.addedAdmin,
    error: null,
    loading: false
  });
};

const inviteAdminFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const removeAdminStart = (state, action) => {
  return updateObject(state, {
    result: null,
    error: null,
    loading: true,
    type: action.type
  });
};

const removeAdminSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
};

const removeAdminFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const genAPIKeyStart = (state, action) => {
  return updateObject(state, {
    result: null,
    error: null,
    key: "",
    loading: true,
    type: action.type
  });
};

const genAPIKeySuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    newapikey: action.newapikey,
    error: null,
    loading: false
  });
};

const genAPIKeyFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

export default reducer;