import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from '../Report.module.scss';
import BarChart from '../../UI/BarChart/BarChart';
import GaugeChart from '../../UI/GaugeChart/GaugeChart';

class TotalOEE extends Component {

    getColor = (value) => {
        if (value >= 70)
            return '#34aa44';
        if (value >= 50)
            return '#facf55';
        if (value >= 30)
            return '#f6ab2f';
        return '#e6492d';
    }

    render() {
        const { total, poAmount, machineName, displayOverHundred } = this.props;

        return (
            <div className={styles.OEEResultWrapper}>
                <div className={styles.OEEResultCard} style={{ backgroundColor: '#3e3f42AA', borderColor: '#ffffff33' }}>
                    <div className={styles.CardTitle} style={{ borderBottomColor: '#ffffff33' }}>
                        {
                            poAmount
                                ? <p className={styles.Title}>TOTAL OEE of {poAmount} Machine(s)</p>
                                : <p className={styles.Title}><span className={styles.Topic} style={{ fontSize: 16 }}>Total OEE of </span> {machineName}</p>
                        }

                    </div>
                    <div className={styles.CardSummary} style={{ justifyContent: 'center' }}>
                        {
                            total ?
                                <>
                                    <div className={styles.OEEGauge}>
                                        <GaugeChart value={total.oee} min={0} max={100} size={180} color={this.getColor(total.oee)} />
                                        <div className={styles.OEELabelWrapper}>
                                            <p className={styles.Label}>OEE</p>
                                            <p className={styles.Value}>{!displayOverHundred && total.oee >= 100 ? 100 : total.oee % 1 > 0 ? total.oee.toFixed(2) : total.oee}%</p>
                                        </div>
                                    </div>
                                    <div className={styles.APQBar}>
                                        <BarChart value={!displayOverHundred && total.a >= 100 ? 100 : total.a} color={this.getColor(total.a)} label="A" showNumber />
                                        <BarChart value={!displayOverHundred && total.p >= 100 ? 100 : total.p} color={this.getColor(total.p)} label="P" showNumber />
                                        <BarChart value={!displayOverHundred && total.q >= 100 ? 100 : total.q} color={this.getColor(total.q)} label="Q" showNumber />
                                    </div>
                                </>
                                : <div style={{ fontSize: 14, fontWeight: '600' }}>Data not found</div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

// const mapStateToProps = (state) => {
//     const { currentOrg } = state.org;
//     return { currentOrg }
// }

export default connect(null, {})(TotalOEE);