import React from 'react';

import styles from './LoadingPresetCard.module.scss';

const LoadingPresetCard = () => {
  return (
    <React.Fragment>
      <div className={styles.LoadingPresetCard}></div>
      <div className={styles.LoadingPresetCard}></div>
    </React.Fragment>
  );
}

export default LoadingPresetCard;
