import React from 'react';

import styles from './ActualProgressBar.module.scss';

const actualProgressBar = ({ value, small, large, width, noBar, running }) => {
    return (
        <div className={styles.ProgressBarWrapper}>
            {!noBar && <div className={`${styles.Progress} ${small ? styles.Small : ''} ${large ? styles.Large : ''}`} style={{ flex: 1, minWidth: 80 }}>
                <div style={{
                    width: value
                        ? value >= 100
                            ? '100%'
                            : value.toFixed(2) + '%'
                        : '0'
                }} className={`${styles.Progress} ${running ? styles.Blue : styles.Green} ${small ? styles.Small : ''} ${large ? styles.Large : ''}`} />
            </div>}
            <div style={{ width: 73 }}>
                <span className={`${styles.Percent} ${running ? styles.Blue : value ? styles.Green : '#eaedf3'} ${small ? styles.Small : ''}`}>
                    {value ? value.toFixed(2) + '%' : '0%'}
                </span>
            </div>
        </div>
    );
}

export default actualProgressBar;