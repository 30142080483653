import React, { Component } from 'react';
import Select from 'react-select';

import styles from '../WidgetForm.module.scss';
import Input from '../../../UI/Input/Input';
// import AddIcon from '../../../../../assets/icons/Rule/add-circle.svg';
import DeleteIcon from '../../../../../assets/icons/Rule/delete-circle.svg';

import { updateObject, checkValidity } from '../../../../../state/utils';

const DEFAULT_RANGE_FORM = {
  start: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  stop: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  }
};

const colourStyles = {
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
  }),
  input: base => ({
    ...base,
    color: 'white',
    height: 35,
    paddingTop: 10
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
};

class RangeForm extends Component {
  state = {
    formIsValid: false,
    rangeForm: DEFAULT_RANGE_FORM,
    selectedColor: null
  }

  componentDidMount() {
    this.setupForm(this.props.rangeForm);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rangeForm !== this.props.rangeForm && this.props.rangeForm) {
      this.setupForm(this.props.rangeForm);
    }
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.rangeForm };
    for (let inputIdentifier in updatedForm) {
      const updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] || form[inputIdentifier] === 0 ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
      });

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }

    let selectedColor = form.color;
    let formIsValid = form.isValid;
    this.setState({ rangeForm: updatedForm, formIsValid, selectedColor });
  }

  onChange = (value) => {
    let formIsValid = true;
    for (let inputIdentifier in this.state.rangeForm) {
      formIsValid = this.state.rangeForm[inputIdentifier].valid && formIsValid;
    }
    formIsValid = formIsValid && value !== null;

    this.props.handleThresholdChange(this.props.index, this.state.rangeForm, value, formIsValid);
    this.setState({ selectedColor: value, formIsValid });
  }

  handleChange = event => {
    let updatedElement = updateObject(this.state.rangeForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.rangeForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.rangeForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    formIsValid = formIsValid && this.state.selectedColor !== null;

    this.props.handleThresholdChange(this.props.index, updatedForm, this.state.selectedColor, formIsValid);
    this.setState({ rangeForm: updatedForm, formIsValid });
  };

  render() {
    const { start, stop } = this.state.rangeForm;

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flex: 3, marginRight: 15 }}>
          <Input
            name="start"
            value={start.value}
            type="number"
            onChange={this.handleChange}
            error={`${start.touched && !start.valid ? 'Required' : ''}`}

          />
          <div style={{ fontSize: 12, margin: '14px 5px 0 5px' }}>to</div>
          <Input
            name="stop"
            value={stop.value}
            type="number"
            onChange={this.handleChange}
            error={`${stop.touched && !stop.valid ? 'Required' : ''}`}
          />
        </div>
        <div style={{ display: 'flex', flex: 2 }}>
          <div style={{ flex: 1 }}>
            <Select options={this.props.colorOptions} styles={colourStyles}
              placeholder="Color"
              value={this.state.selectedColor}
              onChange={this.onChange}
              getOptionValue={opt => opt.id}
            />
          </div>
          <div className={styles.IconWrapper}>
            <img className={styles.IconButton} src={DeleteIcon} alt="Delete Icon" onClick={this.props.removeRange} />
          </div>
        </div>
      </div>
    );
  }
}

export default RangeForm;
