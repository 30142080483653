import React from 'react';
import Input from '../../../UI/Input/Input';
import Select from '../../../UI/Select/Select';

export default class AnalogForm extends React.Component {

    handleTextChange = e => {
        const { selected, handleAnalogUpdate } = this.props;
        const updatedSocket = { ...this.props.socket };
        updatedSocket[e.target.name] = e.target.value;
        if (e.target.name === 'name' && selected && Object.keys(selected).length) {
            updatedSocket.is_valid = updatedSocket.name !== '';
        } else {
            updatedSocket.is_valid = true;
        }
        handleAnalogUpdate(updatedSocket);
    }

    render() {
        const { socket, analogOptions, selected, handleAnalogTypeChange, handleDelayChange, isSystemDevice } = this.props;

        return (
            <>
                <Input
                    labelin="true"
                    label="Name"
                    name="name"
                    type="text"
                    value={socket.name}
                    placeholder="Please name your sensor"
                    autoComplete="off"
                    onChange={this.handleTextChange}
                    error={`${!socket.is_valid ? 'Name is required.' : ''}`}
                />
                {
                    isSystemDevice ?
                        <Select
                            labelin="true"
                            notRequired
                            key={"socket-" + socket.id}
                            label="Type"
                            name={socket.id}
                            options={analogOptions}
                            placeholder="Select an option"
                            value={selected && selected.id ? selected.id : '-1'}
                            onChange={handleAnalogTypeChange}
                            overidestyleformgroup={{ marginBottom: '1rem' }}
                        />
                        : null
                }

                {
                    selected && (selected.id === 'TIMESTAMP' || selected.id === 'FREQUENCY') ?
                        <Input
                            labelin="true"
                            label="Delay (ms)"
                            name={socket.id}
                            type="number"
                            value={selected && selected.delay ? selected.delay : 0}
                            placeholder="Please insert a delay"
                            autoComplete="off"
                            onChange={handleDelayChange}
                        />
                        : null
                }

                <Input
                    labelin="true"
                    label="Location"
                    name="location"
                    type="text"
                    value={socket.location}
                    placeholder="Please insert your sensor location"
                    autoComplete="off"
                    onChange={this.handleTextChange}
                />

                <Input
                    labelin="true"
                    label="Unit"
                    name="unit_name"
                    type="text"
                    value={socket.unit_name}
                    placeholder="m/min"
                    autoComplete="off"
                    onChange={this.handleTextChange}
                />

                <Input
                    labelin="true"
                    label="Function"
                    name="functions"
                    type="text"
                    value={socket.functions}
                    placeholder="x 109 / 23"
                    autoComplete="off"
                    onChange={this.handleTextChange}
                />

                <Input
                    labelin="true"
                    label="Tag"
                    name="tag"
                    type="text"
                    value={socket.tag}
                    placeholder="Tag1,Tag2,..."
                    autoComplete="off"
                    onChange={this.handleTextChange}
                />
            </>
        );
    }
}