import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  deviceGroupings: null,
  addedDeviceGrouping: null,
  updatedDeviceGrouping: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DEVICE_GROUPINGS_START: return getDeviceGroupingsStart(state, action);
    case actionTypes.GET_DEVICE_GROUPINGS_SUCCESS: return getDeviceGroupingsSuccess(state, action);
    case actionTypes.GET_DEVICE_GROUPINGS_FAIL: return getDeviceGroupingsFail(state, action);

    case actionTypes.ADD_DEVICE_GROUPING_START: return addDeviceGroupingStart(state, action);
    case actionTypes.ADD_DEVICE_GROUPING_SUCCESS: return addDeviceGroupingSuccess(state, action);
    case actionTypes.ADD_DEVICE_GROUPING_FAIL: return addDeviceGroupingFail(state, action);

    case actionTypes.EDIT_DEVICE_GROUPING_START: return editDeviceGroupingStart(state, action);
    case actionTypes.EDIT_DEVICE_GROUPING_SUCCESS: return editDeviceGroupingSuccess(state, action);
    case actionTypes.EDIT_DEVICE_GROUPING_FAIL: return editDeviceGroupingFail(state, action);

    case actionTypes.DELETE_DEVICE_GROUPING_START: return deleteDeviceGroupingStart(state, action);
    case actionTypes.DELETE_DEVICE_GROUPING_SUCCESS: return deleteDeviceGroupingSuccess(state, action);
    case actionTypes.DELETE_DEVICE_GROUPING_FAIL: return deleteDeviceGroupingFail(state, action);

    default: return state;
  }
};

const getDeviceGroupingsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getDeviceGroupingsSuccess = (state, action) => {
  return updateObject(state, {
    deviceGroupings: action.deviceGroupings,
    error: null,
    loading: false
  });
}

const getDeviceGroupingsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addDeviceGroupingStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addDeviceGroupingSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedDeviceGrouping: action.addedDeviceGrouping,
    error: null,
    loading: false
  });
}

const addDeviceGroupingFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editDeviceGroupingStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editDeviceGroupingSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedDeviceGrouping: action.updatedDeviceGrouping,
    error: null,
    loading: false
  });
}

const editDeviceGroupingFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteDeviceGroupingStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteDeviceGroupingSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteDeviceGroupingFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;