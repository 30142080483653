import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './VirtualDevice.module.scss';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import Button from '../../components/UI/Button/Button';
import AddNewVirtualDevice from '../../components/VirtualDevice/AddNewVirtualDevice/AddNewVirtualDevice';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import VirtualDeviceList from '../../components/VirtualDevice/VirtualDeviceList/VirtualDeviceList';

import { getVirtualDevices, deleteVirtualDevice } from '../../../state/ducks/VirtualDevice/actions';
import { getDevices } from '../../../state/ducks/Devices/actions';
import { DELETE_VIRTUAL_DEVICE_SUCCESS } from '../../../state/ducks/VirtualDevice/types';

class VirtualDevice extends Component {
  state = {
    selectedVirtualDevice: null,
    openVirtualDeviceForm: false,
    isAdding: false,
    editingVirtualDevice: null,
    isDeleteModalOpened: false,
  }

  componentDidMount() {
    this.props.getVirtualDevices(this.props.currentOrg);
    if (!this.props.devices)
      this.props.getDevices(this.props.currentOrg, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.props.getVirtualDevices(this.props.currentOrg);
      this.props.getDevices(this.props.currentOrg, true);
    } else {
      if ((this.props.result !== prevProps.result) && this.props.result === 'success') {
        if ((prevProps.addedVirtualDevice !== this.props.addedVirtualDevice) || (prevProps.updatedVirtualDevice !== this.props.updatedVirtualDevice) || (this.props.type === DELETE_VIRTUAL_DEVICE_SUCCESS)) {
          this.props.getVirtualDevices(this.props.currentOrg);
        } else {
          // TODO: need to get deleted page index
          // case: delete
          // this.props.tags.splice(this.state.deletingIndex, 1);
        }
      }
    }
  }

  onAddVirtualDevice = () => {
    if (!this.state.isAdding) {
      this.setState({ isAdding: true, editingVirtualDevice: null, openVirtualDeviceForm: true, selectedVirtualDevice: null });
    }
  }

  closeAddNewVirtualDeviceHandler = () => {
    this.setState({ openVirtualDeviceForm: false, isAdding: false, editingVirtualDevice: null, selectedVirtualDevice: null })
  }

  onEditVirtualDevice = (index) => {
    if (!this.state.editingVirtualDevice || this.state.editingVirtualDevice.uuid !== this.props.virtualDevices[index].uuid) {
      this.setState({ isAdding: false, editingVirtualDevice: this.props.virtualDevices[index], openVirtualDeviceForm: true, selectedVirtualDevice: index });
    }
  }

  onDeleteVirtualDeviceHandler = () => {
    this.setState({ isDeleteModalOpened: true });
  }

  deleteModalClickHandler = () => {
    this.setState(prevState => {
      return { isDeleteModalOpened: !prevState.isDeleteModalOpened }
    });
  }

  onConfirmDeleteVirtualDevice = () => {
    this.props.deleteVirtualDevice(this.state.editingVirtualDevice.uuid);
    this.setState({ isDeleteModalOpened: false, openVirtualDeviceForm: false });
  }

  render() {
    return (
      <div className={styles.VirtualDevice}>
        <div className={styles.VirtualDeviceTopics}>
          <div className={styles.VirtualDeviceFix}>
            <div className={styles.TopMenuVirtualDevice}>
              <div className={styles.MenuWrapper}>
                <div className={styles.Menu}><span className={styles.MenuTitle}>Virtual Devices</span></div>
              </div>
              <div className={styles.AddButtonWrapper}>
                <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Virtual Device" noMargin click={this.onAddVirtualDevice} />
              </div>
            </div>
            {
              this.props.loading ? <><div className={styles.LoadingCard}></div> <div className={styles.LoadingCard}></div></> :
              this.props.virtualDevices ?
                  <VirtualDeviceList virtualDevices={this.props.virtualDevices} onEditVirtualDevice={this.onEditVirtualDevice} selectedVirtualDevice={this.state.selectedVirtualDevice} closed={this.closeAddNewVirtualDeviceHandler} />
                  : null
            }
          </div>
        </div>

        <div className={styles.VirtualDeviceDetails}>
          {
            this.state.openVirtualDeviceForm &&
            <AddNewVirtualDevice
              isAdding={this.state.isAdding}
              devices={this.props.devices}
              editingVirtualDevice={this.state.editingVirtualDevice}
              closed={this.closeAddNewVirtualDeviceHandler}
              toggleDeleteModal={this.onDeleteVirtualDeviceHandler}
            />
          }
        </div>

        <DeleteModal
          title="virtual device"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeleteVirtualDevice}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { devices } = state.devices;
  const { type, result, virtualDevices, getVirtualDevices, addedVirtualDevice, updatedVirtualDevice, deleteVirtualDevice, loading } = state.virtualDevice;
  return { 
    currentOrg, devices, type, result, virtualDevices, getVirtualDevices, addedVirtualDevice, updatedVirtualDevice, deleteVirtualDevice, loading
   };
};

export default connect(mapStateToProps, { 
  getVirtualDevices, getDevices, deleteVirtualDevice
 })(VirtualDevice);