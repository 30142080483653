import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './Device.module.scss';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import EditHoverIcon from '../../../../assets/icons/edit.svg';
import ResetIcon from '../../../../assets/icons/Admin/switch-red.svg';
import ResetHoverIcon from '../../../../assets/icons/Admin/switch-red-hover.svg';
import RebootIcon from '../../../../assets/icons/Admin/reboot.svg';
import RebootDisableIcon from '../../../../assets/icons/Admin/reboot-disable.svg';
import RebootHoverIcon from '../../../../assets/icons/Admin/reboot-hover.svg';
import MonitorIcon from '../../../../assets/icons/Admin/cctv-gray.png';
import MonitorHoverIcon from '../../../../assets/icons/Admin/cctv-blue.png';

class DeviceListItem extends Component {

  renderItem = (device) => {
    const { orgs } = this.props;
    const { id, status, name, secret, created_date, tag, org_id, system_device } = device;

    let deviceAndSocketStyles = status === 'on' ? [styles.DeviceAndSocketWrapper, styles.Active] : [styles.DeviceAndSocketWrapper];

    return (
      <div key={`device-${id}`} className={styles.DeviceCardList}>
        <div className={styles.DeviceCard}>
          <div className={deviceAndSocketStyles.join(' ')}>
            <div className={styles.DeviceWrapper}>
              <div className={styles.NameAndInformation}>
                <div className={styles.DeviceName}>({id}) {name}</div>
                <div className={styles.OtherInformation}>
                  {secret}
                  <span className={styles.MiddleDot}>&#183;</span>
                  {org_id === 0 ? 'Free' : orgs.find(org => org.id === org_id)?.name}
                  <span className={styles.MiddleDot}>&#183;</span>
                  {moment(created_date).format("MMM DD, YYYY")}
                </div>
              </div>
              {tag !== '' &&
                <div className={styles.TagsWrapper}>
                  {tag.split(",").map((tag, i) => {
                    if (i > 3)
                      return null;
                    const random = Math.floor(Math.random() * 1000);
                    let moreTagsStyle = [styles.MoreTags];
                    let tagBox = [styles.TagBox];
                    if (status !== 'on') {
                      moreTagsStyle.push(styles.Off);
                      tagBox.push(styles.Off);
                    }
                    if (i === 3)
                      return <div key={`moretag+${tag}`} className={moreTagsStyle.join(' ')}>+{tag.split(",").length - 3}</div>;
                    return <div key={`tag+${tag} ${random}`} className={tagBox.join(' ')}>{tag}</div>;
                  })}
                </div>
              }
              <div className={styles.ActionButtons}>
                {system_device
                  ? <>
                    <img
                      style={{ width: 24, marginRight: 20, cursor: 'pointer' }}
                      src={device.monitor ? MonitorHoverIcon : MonitorIcon}
                      alt="monitor device"
                      onClick={() => this.props.onToggleMonitorDevice(device)}
                    />
                    <img
                      style={{ marginRight: 20, cursor: 'pointer' }}
                      src={EditIcon}
                      onMouseOver={e => (e.currentTarget.src = EditHoverIcon)}
                      onMouseOut={e => (e.currentTarget.src = EditIcon)}
                      alt="edit device"
                      onClick={() => this.props.onEditDevice(device)}
                    />
                    <img
                      style={{ marginRight: 20, cursor: 'pointer' }}
                      src={ResetIcon}
                      onMouseOver={e => (e.currentTarget.src = ResetHoverIcon)}
                      onMouseOut={e => (e.currentTarget.src = ResetIcon)}
                      alt="reset device"
                      onClick={() => this.props.onResetDevice(device)}
                    />
                    <img
                      style={{ marginRight: 30, cursor: 'pointer' }}
                      src={status === 'on' ? RebootIcon : RebootDisableIcon}
                      onMouseOver={e => (e.currentTarget.src = status === 'on' ? RebootHoverIcon : RebootDisableIcon)}
                      onMouseOut={e => (e.currentTarget.src = status === 'on' ? RebootIcon : RebootDisableIcon)}
                      alt="reboot device"
                      onClick={() => this.props.onRebootDevice(device)}
                    />
                  </>
                  :
                  <img
                    style={{ marginLeft: 40, marginRight: 109, cursor: 'pointer' }}
                    src={EditIcon}
                    onMouseOver={e => (e.currentTarget.src = EditHoverIcon)}
                    onMouseOut={e => (e.currentTarget.src = EditIcon)}
                    alt="edit device"
                    onClick={() => this.props.onEditDevice(device)}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }

  render() {
    const { filteredDevice } = this.props;
    return filteredDevice ? filteredDevice.map(device => { return this.renderItem(device); }) : null;
  }
}

const mapStateToProps = (state) => {
  const { orgs } = state.org;
  return { orgs };
};

export default connect(mapStateToProps, {})(DeviceListItem);