import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './AdminDevices.module.scss';
import Button from '../../components/UI/Button/Button';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import LoadingWideCard from '../../components/UI/LoadingSkeleton/LoadingWideCard/LoadingWideCard';
import DeviceListItem from '../../components/Admin/Device/DeviceListItem';
import AddDeviceFormModal from '../../components/Admin/Device/AddDeviceFormModal';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import SystemDeviceConfigModal from '../../components/Devices/SystemDeviceConfig/SystemDeviceConfigModal';
import Pagination from '../../components/UI/Pagination/Pagination';

import { getDevices, deleteDevice, deleteOwlDevices, editDevice } from '../../../state/ducks/Devices/actions';
import { EDIT_DEVICE_START, EDIT_DEVICE_SUCCESS, EDIT_DEVICE_FAIL } from '../../../state/ducks/Devices/types';
import { resetDevice, rebootDevice, createDevice } from '../../../state/ducks/Admin/actions';


const CONFIRM_DELETE = 'confirmDelete';
const CONFIRM_RESET = 'confirmReset';
const CONFIRM_REBOOT = 'confirmReboot';

class AdminDevices extends Component {
  state = {
    searchDevice: '',
    searchTimeout: null,
    filteredDevice: [],
    searchIncorrect: false,
    isDeviceConfigModalOpened: false,
    isDeleteModalOpened: false,
    isAddDeviceFormOpened: false,
    editingDevice: null,
    confirmType: CONFIRM_DELETE,
    currentPage: 0,
    rowsPerPage: 10,
    formError: '',
  }

  componentDidMount() {
    this.props.getDevices(0, true, true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { devices, adminResult, getDevices, type, lastResult } = this.props;
    if (prevProps.devices !== devices) {
      this.onSearchHandler();
    }
    if (prevProps.adminResult !== adminResult) {
      getDevices(0, true, true);
    }
    if (prevProps.lastResult !== lastResult && (type === EDIT_DEVICE_SUCCESS || type === EDIT_DEVICE_FAIL)) {
      getDevices(0, true, true);
    }
  }

  handleSearchChange = (text) => {
    const { searchTimeout } = this.state;
    if (searchTimeout)
      clearTimeout(searchTimeout);

    this.setState({ searchDevice: text, searchTimeout: setTimeout(this.onSearchHandler, 1200) });
  }

  searchWithOrg = (devices, name) => {
    if (name === 'free')
      return devices.filter(device => device.org_id === 0);
    const { orgs } = this.props;
    const filteredOrg = orgs.filter(org => org.name.toLowerCase().indexOf(name) !== -1);
    let memids = [];
    let filteredDevice = [];
    filteredOrg.forEach(org => {
      let preFilters = devices.filter(device => device.org_id === org.id);
      filteredDevice = filteredDevice.concat(preFilters.filter(device => memids.indexOf(device.id) === -1));
      memids = memids.concat(preFilters.map(device => device.id));
    });
    return filteredDevice;
  }

  onSearchHandler = () => {
    const { devices } = this.props;
    const { searchDevice } = this.state;
    if (searchDevice.trim().length === 0) {
      this.setState({ filteredDevice: devices, searchIncorrect: false });
      return;
    }

    let memids = [];
    let filteredDevice = [];
    let splitOr = searchDevice.toLowerCase().split(' or ');
    try {
      splitOr.forEach(text => {
        let preFilters = devices;
        let splitAnd = text.trim().split('and');
        for (let i = 0; i < splitAnd.length; i++) {
          let searchtext = splitAnd[i].trim();
          if (!searchtext)
            continue;

          let eqIndex = searchtext.indexOf('=');
          if (eqIndex < 0) {
            this.setState({ searchIncorrect: true });
            return;
          }
          let value = searchtext.substring(eqIndex + 1).replace(/^\"?|\"?$/g, "");
          if (searchtext.startsWith('name=')) {
            preFilters = preFilters.filter(device => device.name.toLowerCase().indexOf(value) >= 0);
          } else if (searchtext.startsWith('secret=')) {
            preFilters = preFilters.filter(device => device.secret.toLowerCase().indexOf(value) >= 0);
          } else if (searchtext.startsWith('id=')) {
            preFilters = preFilters.filter(device => device.id === +value);
          } else if (searchtext.startsWith('status=')) {
            preFilters = preFilters.filter(device => device.status === value);
          } else if (searchtext.startsWith('tag=')) {
            preFilters = preFilters.filter(device => device.tag.toLowerCase().indexOf(value) >= 0);
          } else if (searchtext.startsWith('org=')) {
            preFilters = this.searchWithOrg(preFilters, value);
          } else {
            this.setState({ searchIncorrect: true });
            return;
          }
        }
        filteredDevice = filteredDevice.concat(preFilters.filter(device => memids.indexOf(device.id) === -1));
        memids = memids.concat(preFilters.map(device => device.id));
      });
    } catch (e) {
      this.setState({ searchIncorrect: true });
      return;
    }
    if (filteredDevice && filteredDevice.length) {
      this.setState({ currentPage: 0 });
    }
    this.setState({ filteredDevice, searchIncorrect: false });
  }

  onEditDeviceHandler = (device) => {
    this.setState({ isReset: false, editingDevice: device, isDeviceConfigModalOpened: true });
  }

  onResetDeviceHandler = (device) => {
    this.setState({ confirmType: CONFIRM_RESET, editingDevice: device, isDeleteModalOpened: true });
  }

  systemDeviceConfigClickHandler = () => {
    this.setState({ isDeviceConfigModalOpened: false });
  }

  toggleDeleteModalOpen = () => {
    this.setState({ confirmType: CONFIRM_DELETE, isDeleteModalOpened: !this.state.isDeleteModalOpened });
  }

  onConfirmDeleteDevice = (event) => {
    event.preventDefault();

    const { editingDevice, confirmType } = this.state;
    const { currentOrg, deleteOwlDevices, deleteDevice, resetDevice, rebootDevice } = this.props;

    if (confirmType === CONFIRM_RESET) {
      // RESET DEVICE
      resetDevice(editingDevice);
      this.setState({ editingDevice: null, isDeleteModalOpened: false, isDeviceConfigModalOpened: false, confirmType: CONFIRM_DELETE });
      return;
    }

    if (confirmType === CONFIRM_REBOOT) {
      // REBOOT DEVICE
      rebootDevice(editingDevice);
      this.setState({ editingDevice: null, isDeleteModalOpened: false, isDeviceConfigModalOpened: false, confirmType: CONFIRM_DELETE });
      return;
    }

    // DELETE DEVICE
    if (editingDevice.system_device) {
      deleteOwlDevices(currentOrg, editingDevice.secret);
    } else {
      deleteDevice(editingDevice.id);
    }
    this.setState({ editingDevice: null, isDeleteModalOpened: false, isDeviceConfigModalOpened: false });
  }

  onChangePageHandler = (event, page) => {
    event.preventDefault();
    this.setState({ currentPage: page });
  }

  onChangeRowsPerPageHandler = (event) => {
    event.preventDefault();

    let amount = +event.target.value;

    const { filteredDevice } = this.state;
    let currentPage = this.state.currentPage;
    if (currentPage > 0 && (currentPage * amount) > filteredDevice.length) {
      currentPage = 0;
    }
    this.setState({ rowsPerPage: amount, currentPage });
  }

  onRebootDeviceHandler = (device) => {
    if (device.status !== 'on' || !device.system_device)
      return;

    this.setState({ confirmType: CONFIRM_REBOOT, editingDevice: device, isDeleteModalOpened: true });
  }

  onAddDeviceHandler = () => {
    this.setState({ isAddDeviceFormOpened: true });
  }

  onAddDeviceModalClose = () => {
    this.setState({ isAddDeviceFormOpened: false, formError: '' });
  }

  onAddDeviceModalSubmit = (form) => {
    const { devices, createDevice } = this.props;
    if (devices.find(device => device.secret === form.secret)) {
      this.setState({ formError: 'Secret already exists' });
      return;
    }
    createDevice(form);
    this.setState({ isAddDeviceFormOpened: false, formError: '' });
  }

  onToggleMonitorDeviceHandler = (device) => {
    this.props.editDevice(device.id, device.name, device.description, device.tag, device.image, device.secret, !device.monitor);
  }

  render() {
    const { searchDevice, searchIncorrect, filteredDevice, editingDevice, isDeviceConfigModalOpened, isDeleteModalOpened, isAddDeviceFormOpened, confirmType, currentPage, rowsPerPage, formError } = this.state;
    let { loading } = this.props;

    let confirmTitle = null;
    let confirmDesc1 = null;
    let confirmDesc2 = null;
    if (confirmType === CONFIRM_RESET) {
      confirmTitle = <>Are you sure to <span style={{ color: 'red' }}>RESET</span> this?</>;
      confirmDesc1 = "This process will delete data and free device";
      confirmDesc2 = "which is irreversible.";
    } else if (confirmType === CONFIRM_REBOOT) {
      confirmTitle = <>Are you sure to <span style={{ color: 'red' }}>REBOOT</span> this?</>;
      confirmDesc1 = "This process will send command to device";
      confirmDesc2 = "and must wait for the response.";
    }

    return (
      <div className={styles.Devices}>
        <div className={styles.TopSection}>
          <div className={styles.SearchWrapper}>
            <div className={styles.Hint}>Keys : name, secret, status, tag, org, id</div>
            <input type="text" value={searchDevice}
              onChange={e => this.handleSearchChange(e.target.value)}
              className={searchIncorrect ? [styles.SearchInput, styles.SearchInputIncorrect].join(' ') : styles.SearchInput}
              placeholder='Example: name="Device A" and secret="AA:BB:CC" or org="Organization A" and status="on"' />
          </div>
          <div className={styles.AddButtonWrapper}>
            <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Device" noMargin click={this.onAddDeviceHandler} overideButtonStyles={{ height: 40 }} />
          </div>
        </div>
        <div className={styles.DevicesTable}>
          {loading ?
            <LoadingWideCard /> :
            <DeviceListItem
              filteredDevice={filteredDevice.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage)}
              onEditDevice={this.onEditDeviceHandler}
              onResetDevice={this.onResetDeviceHandler}
              onRebootDevice={this.onRebootDeviceHandler}
              onToggleMonitorDevice={this.onToggleMonitorDeviceHandler}
            />
          }
          <Pagination
            total={filteredDevice.length}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            onChangePage={this.onChangePageHandler}
            onChangeRowsPerPage={this.onChangeRowsPerPageHandler}
          />
        </div>

        <SystemDeviceConfigModal
          open={isDeviceConfigModalOpened}
          modalClosed={this.systemDeviceConfigClickHandler}
          editingDevice={editingDevice}
          onDelete={this.toggleDeleteModalOpen}
        />
        <DeleteModal
          title="device"
          overrideTitle={confirmTitle}
          overrideDescription1={confirmDesc1}
          overrideDescription2={confirmDesc2}
          open={isDeleteModalOpened}
          modalClosed={this.toggleDeleteModalOpen}
          confirmDelete={this.onConfirmDeleteDevice}
        />
        <AddDeviceFormModal
          isOpen={isAddDeviceFormOpened}
          onClose={this.onAddDeviceModalClose}
          onSubmit={this.onAddDeviceModalSubmit}
          formError={formError}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { type, result, devices, addedDevice, updatedDevice, loading, lastResult } = state.devices;
  const { orgs } = state.org;
  return {
    type, result, devices, addedDevice, updatedDevice, loading, lastResult, orgs, adminResult: state.admin.result
  };
};

export default connect(mapStateToProps, { getDevices, deleteDevice, deleteOwlDevices, resetDevice, rebootDevice, createDevice, editDevice })(AdminDevices);
