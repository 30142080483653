import React, { Component } from 'react';

import styles from './Lamp.module.scss';
import chartStyles from '../Chart.module.scss';
import RedLamp from '../../../../../assets/icons/Widgets/lamp-red-full.svg';
import OrangeLamp from '../../../../../assets/icons/Widgets/lamp-orange-full.svg';
import BlueLamp from '../../../../../assets/icons/Widgets/lamp-blue-full.svg';
import YellowLamp from '../../../../../assets/icons/Widgets/lamp-yellow-full.svg';
import GreenLamp from '../../../../../assets/icons/Widgets/lamp-green-full.svg';
import DefaultLamp from '../../../../../assets/icons/Widgets/lamp-gray-full.svg';
import { calculateNewValFromEquation } from '../../../../../state/utils';

const LAMPS = [
  { icon: RedLamp, color: '#ff5454' },
  { icon: OrangeLamp, color: '#ffa000' },
  { icon: YellowLamp, color: '#facf55' },
  { icon: GreenLamp, color: '#34aa44' },
  { icon: BlueLamp, color: '#1665d8' },
];


class Lamp extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if ((nextProps.data || nextProps.data === 0) && this.props.data !== nextProps.data) {
      return true;
    } else if (nextProps.lastUpdated !== this.props.lastUpdated) {
      return true;
    }
    return false;
  }

  render() {
    const { data, widget: { equation, unit, lampProps }, id, lastUpdated } = this.props;
    // const renderedValue = isNaN(parseFloat(data)) ? null : calculateNewValFromEquation(data, equation);
    const renderedValue = 1;

    let lampResult = <img src={DefaultLamp} alt="Lamp status" width="32px"/>;
    let foundLamp = null;
    let displayedText = '';
    let displayedBgColor = '';
    if (lampProps && lampProps.colorTriggers && lampProps.colorTriggers.length) {
      lampProps.colorTriggers.sort((a, b) => a.point - b.point).forEach(lamp => {
        if (renderedValue >= lamp.point) {
          foundLamp = LAMPS.filter(c => c.color === lamp.color)[0];
          displayedBgColor = lamp.color;
          if (lamp.displayedText) displayedText = lamp.displayedText;
        }
      });
    }

    if (lampProps && lampProps.lampStyle === 'light') {
      if (foundLamp) {
        lampResult = <img src={foundLamp.icon} alt="Lamp status" width="32px"/>;
      }

      return (
        <div id={id} className={styles.LightStyle}>
          {lampResult}
          <div>
            <div className={styles.Value}>
              {
                renderedValue || renderedValue === 0 ?
                  renderedValue
                  : 
                  lastUpdated ?
                    <div className={chartStyles.NoData}></div>
                    : <span className={chartStyles.LoadingText}>N/A</span>
              }
            </div>
            {/* {
              renderedValue || renderedValue === 0 ?
                <div className={styles.Unit}>{unit}</div>
                : null
            } */}
          </div>
        </div>
      );
    } else if (lampProps && lampProps.lampStyle === 'bg') {
      return (
        <div id={id} className={styles.BgStyle} style={{ backgroundColor: displayedBgColor }}>
          <div className={styles.BgTextWrapper}>
            <div className={styles.Value}>
              {
                renderedValue || renderedValue === 0 ?
                  displayedText ? displayedText : renderedValue
                  : lastUpdated ?
                    <div className={chartStyles.NoData}></div>
                    : <span className={styles.LoadingText}>N/A</span>
              }
            </div>
            {
              ((renderedValue || renderedValue === 0) && unit) ?
                <div className={styles.Unit}>{unit}</div>
                : null
            }
          </div>
        </div>
      );
    }

    return <div id={id} className={styles.LightStyle}>Loading...</div>;
  }
}

export default Lamp;