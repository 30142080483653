import { Component } from "react";

import styles from './Device.module.scss';
import Modal from '../../UI/Modal/Modal';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import Select from "../../UI/Select/Select";

class AddDeviceFormModal extends Component {

    state = {
        model: 'e02sa',
        secret: '',
        serial: '',
        firmware: '',
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState({
                model: 'e02sa',
                secret: '',
                serial: '',
                firmware: '',
            });
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { model, secret, serial, firmware } = this.state;
        let form = { model, secret, serial, firmware };
        this.props.onSubmit(form);
    }

    onChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { onClose, isOpen, formError } = this.props;
        const { model, secret, serial, firmware } = this.state;

        const models = [
            { id: 'e02sa', name: 'E02SA' },
            { id: 'e02ha', name: 'E02HA' },
            { id: 'e04sd', name: 'E04SD' },
            { id: 'e02sa-fuso', name: 'E02SA FUSO' },
        ];

        return (
            <Modal darkMode disableCloseOnBackdrop show={isOpen} modalClosed={onClose} className={styles.AddDeviceWrapper}>
                <div className={styles.AddDeviceHeader}>
                    Add New Device
                </div>
                <div className={styles.AddDeviceBody}>
                    {formError ? <div className={styles.Error}>{formError}</div> : null}
                    <div className={styles.FormGroup}>
                        <span className={styles.FormLabel}>Model</span>
                        <Select name="model" value={model} options={models} overidestyleformgroup={{ marginTop: -30, marginBottom: 10 }} onChange={this.onChange} />
                    </div>
                    <div className={styles.FormGroup}>
                        <span className={styles.FormLabel}>Secret</span>
                        <Input name="secret" value={secret} type="text" placeholder="AA:BB:CC:DD:EE" overidestyleformgroup={{ marginTop: -10 }} onChange={this.onChange} />
                    </div>
                    <div className={styles.FormGroup}>
                        <span className={styles.FormLabel}>Serial</span>
                        <Input name="serial" value={serial} type="text" placeholder="001122AABBCC" overidestyleformgroup={{ marginTop: -10 }} onChange={this.onChange} />
                    </div>
                    <div className={styles.FormGroup}>
                        <span className={styles.FormLabel}>Firmware Version</span>
                        <Input name="firmware" value={firmware} type="text" placeholder="X.Y.Z" overidestyleformgroup={{ marginTop: -10 }} onChange={this.onChange} />
                    </div>
                </div>
                <div className={styles.AddDeviceFooter}>
                    <Button className={styles.AddDeviceButton} type="button" name="Create" color="green" click={this.onSubmit} />
                    <Button className={styles.AddDeviceButton} type="button" name="Cancel" color="red" click={onClose} />
                </div>
            </Modal>
        );
    }
}

export default AddDeviceFormModal;