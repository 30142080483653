import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './AddDeviceForm.module.scss';
import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import DeviceForm from '../DeviceForm/DeviceForm';
import GrayArrowIcon from '../../../../assets/icons/Device/grey-arrow.svg';
import BlueArrowIcon from '../../../../assets/icons/Device/blue-arrow.svg';

import { addOwlDevices } from '../../../../state/ducks/Devices/actions';
import { ADD_OWL_DEVICES_SUCCESS } from '../../../../state/ducks/Devices/types';

const DEFAULT_DEVICE_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  }
};

class AddDeviceForm extends Component {
  state = {
    socketOpened: null,
    formIsValid: false,
    deviceForm: DEFAULT_DEVICE_FORM,
    allDeviceSocketsForm: null
  }

  componentDidMount() {
    this.setDefaultAllDeviceSocketsForm();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.result !== this.props.result && this.props.result === 'success' && this.props.type === ADD_OWL_DEVICES_SUCCESS) {
      this.setState({
        socketOpened: null,
        formIsValid: false,
        deviceForm: DEFAULT_DEVICE_FORM,
        allDeviceSocketsForm: null
      });
    }
  }

  handleTextChange = event => {
    let updatedElement = updateObject(this.state.deviceForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.deviceForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.deviceForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ deviceForm: updatedForm, formIsValid });
  };

  openSocketHandler = (index) => {
    let newSocketOpen = null;
    if (this.state.socketOpened !== index) {
      newSocketOpen = index;
    }
    this.setState({ socketOpened: newSocketOpen });
  }

  setDefaultAllDeviceSocketsForm = () => {
    const owlModel = this.props.owlModel[0];
    let updatedForm = [];
    owlModel.device_sockets.forEach(ds => {
      const defaultSocketForm = {
        name: { value: ds.name, touched: true, valid: true },
        location: { value: ds.location, touched: true, valid: true },
        unit_name: { value: ds.unit_name, touched: true, valid: true },
      };

      updatedForm.push({
        id: ds.id,
        socket: ds.socket,
        device_secret: ds.device_secret,
        form: defaultSocketForm,
        isValid: true
      });
    });
    this.setState({ allDeviceSocketsForm: updatedForm });
  }

  handleOwlDeviceFormChange = (deviceSocketId, form, isValid) => {
    let updatedForm = [...this.state.allDeviceSocketsForm];
    updatedForm.forEach((element) => {
      if (element.id === deviceSocketId) {
        element.form = form;
        element.isValid = isValid;
      }
    });
    this.setState({ allDeviceSocketsForm: updatedForm });
  }

  checkSubmitButton = () => {
    let valid = this.state.formIsValid;
    if (this.state.allDeviceSocketsForm) {
      this.state.allDeviceSocketsForm.forEach(form => {
        valid = form.isValid && valid;
      });
    }
    return !valid;
  }

  onSubmitDeviceHandler = (event) => {
    event.preventDefault();
    this.props.addOwlDevices(this.props.currentOrg, this.props.secretNumber, this.props.owlModel[0].id, this.state.deviceForm, this.state.allDeviceSocketsForm);
  }

  render() {
    let devicesForm = null;
    if (this.props.owlModel && this.props.owlModel.length !== 0) {
      const owlModel = this.props.owlModel[0];
      devicesForm = (
        <React.Fragment>
          <div className={styles.Label}>There are {owlModel.device_sockets.length} available sockets</div>
          {
            owlModel.device_sockets.map((ds, index) => {
              let socketDetailStyle = [styles.CollapseContainer];
              let socketStyle = [styles.Socket];
              let arrowIcon = GrayArrowIcon;
              if (this.state.socketOpened === index) {
                socketStyle.push(styles.Active);
                socketDetailStyle.push(styles.Open);
                arrowIcon = BlueArrowIcon;
              } else {
                socketDetailStyle.push(styles.Close);
              }

              if (this.state.allDeviceSocketsForm) {
                if (!this.state.allDeviceSocketsForm[index].isValid) {
                  socketStyle.push(styles.Error);
                }
              }
              return (
                <div key={ds.id} className={styles.SocketsWrapper}>
                  <div className={styles.SlideSocket} onClick={() => this.openSocketHandler(index)}>
                    <div className={socketStyle.join(' ')}>
                      <div style={{ display: 'flex' }}>
                        <div className={styles.SocketNumber}>Socket-{ds.socket}</div>
                        <div className={styles.ArrowIcon}><img alt="Arrow" src={arrowIcon} /></div>
                      </div>
                    </div>
                  </div>
                  <div className={socketDetailStyle.join(' ')}>
                    <DeviceForm deviceSocket={owlModel.device_sockets[index]} handleOwlDeviceFormChange={this.handleOwlDeviceFormChange} />
                  </div>
                </div>
              )
            })
          }
        </React.Fragment >
      );
    }

    return (
      <form onSubmit={this.onSubmitDeviceHandler}>
        <div className={styles.Divider} />
        <Input
          label="device name"
          name="name"
          type="text"
          value={this.state.deviceForm.name.value}
          placeholder="Please insert Device Name"
          autoComplete="off"
          onChange={this.handleTextChange}
          error={`${this.state.deviceForm.name.touched && !this.state.deviceForm.name.valid ? 'Device Name is required' : ''}`}
          required
        />

        {devicesForm}

        <Button
          type="submit"
          name={this.props.loading ? 'Loading...' : 'Done'}
          color="primary"
          disabled={this.checkSubmitButton()}
          loading={this.props.loading}
        />
        <Button
          type="button"
          name="Cancel"
          color="borderred"
          click={this.props.closed}
        />
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { type, result, owlModel, loading } = state.devices;
  return { currentOrg, type, result, owlModel, loading };
};

export default connect(mapStateToProps, { addOwlDevices })(AddDeviceForm);