import React, { Component } from 'react';

import styles from './Auth.module.scss';
import SignIn from '../../../components/Auth/SignIn/SignIn';
import SignUp from '../../../components/Auth/SignUp/SignUp';
import ForgetPassword from '../../../components/Auth/ForgetPassword/ForgetPassword';

class Auth extends Component {
  state = {
    isSignIn: true,
    isForgetPassword: false
  }

  onToggleAuth = () => {
    this.setState(prevState => {
      return {
        isSignIn: !prevState.isSignIn,
        isForgetPassword: false
      };
    });
  }

  onGoToForgetPassword = () => {
    this.setState({
      isSignIn: false,
      isForgetPassword: true
    });
  }

  render() {
    return (
      <div className={styles.Auth}>
        <div className={styles.AuthBoxContainer}>
          {
            this.state.isForgetPassword ? <ForgetPassword isMobile={this.props.isMobile} /> :
              this.state.isSignIn ? <SignIn goToForgetPassword={this.onGoToForgetPassword} isMobile={this.props.isMobile} /> : <SignUp isMobile={this.props.isMobile} />
          }
          {this.props.isMobile ? null :
          <div className={styles.SwapSignUp}>
            {
              this.state.isForgetPassword ?
                <div>Go back to <span className={styles.SwapText} onClick={this.onToggleAuth}>Sign In!</span></div>
                : this.state.isSignIn ?
                  <div>Doesn't have account yet? Let us take you to <span className={styles.SwapText} onClick={this.onToggleAuth}>Sign Up!</span></div>
                  : <div>Already on Board? Let us take you to <span className={styles.SwapText} onClick={this.onToggleAuth}>Sign In!</span></div>
            }
          </div>
          }
        </div>
      </div>
    );
  }
}

export default Auth;
