import React from 'react';
import Input from '../../../UI/Input/Input';

export default class ControlForm extends React.Component {

    handleTextChange = e => {
        const { handleAnalogUpdate } = this.props;
        const updatedSocket = { ...this.props.socket };
        updatedSocket[e.target.name] = e.target.value;
        updatedSocket.is_valid = updatedSocket.name !== '';
        handleAnalogUpdate(updatedSocket);
    }

    render() {
        const { socket } = this.props;

        return (
            <>
                <Input
                    labelin="true"
                    label="Name"
                    name="name"
                    type="text"
                    value={socket.name}
                    placeholder="Please name your sensor"
                    autoComplete="off"
                    onChange={this.handleTextChange}
                    error={`${!socket.is_valid ? 'Name is required.' : ''}`}
                />

                <Input
                    labelin="true"
                    label="Location"
                    name="location"
                    type="text"
                    value={socket.location}
                    placeholder="Please insert your sensor location"
                    autoComplete="off"
                    onChange={this.handleTextChange}
                />

                <Input
                    labelin="true"
                    label="Unit"
                    name="unit_name"
                    type="text"
                    value={socket.unit_name}
                    placeholder="m/min"
                    autoComplete="off"
                    onChange={this.handleTextChange}
                />

                <Input
                    labelin="true"
                    label="Function"
                    name="functions"
                    type="text"
                    value={socket.functions}
                    placeholder="x 109 / 23"
                    autoComplete="off"
                    onChange={this.handleTextChange}
                />

                <Input
                    labelin="true"
                    label="Tag"
                    name="tag"
                    type="text"
                    value={socket.tag}
                    placeholder="Tag1,Tag2,..."
                    autoComplete="off"
                    onChange={this.handleTextChange}
                />
            </>
        );
    }
}