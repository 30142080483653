import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './PresetDrawer.module.scss';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import Select from '../../UI/Select/Select';
import SimpleSwitch from '../../../components/UI/SimpleSwitch/SimpleSwitch';
import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';

import { addReportPreset, editReportPreset } from '../../../../state/ducks/ReportPreset/actions';

import { intervalToDateConverter } from '../../../../state/utils/Report';

const summarizeOptions = [
  { id: '60', name: '1 min' },
  { id: '120', name: '2 mins' },
  { id: '900', name: '15 mins' },
  { id: '1800', name: '30 mins' },
  { id: '3600', name: '1 hour' },
];

const methodOptions = [
  { id: 'avg', name: 'Average' },
  { id: 'max', name: 'Max' },
  { id: 'min', name: 'Min' },
  { id: 'mode', name: 'Mode' },
  { id: 'sum', name: 'Sum' },
  { id: 'count', name: 'Count' },
];

const intervalOptions = [
  { id: 'daily', name: 'Every Day' },
  { id: 'weekly', name: 'Every Week' },
  { id: 'monthly', name: 'Every Month' },
];

const FILTER_OPTION = [
  { id: 0, name: 'Show all statuses (for only 1 machine)' },
  { id: 1, name: 'Combine others' },
  { id: 2, name: 'Not display others' }
];

const DEFAULT_PRESET_DATA_PROPERTIES = {
  devices: [],
  method: '',
  summarize: '',
  startDate: '',
  startTime: '',
  stopDate: '',
  stopTime: '',
  interval: ''
}

const DEFAULT_PRESET_PARETO_PROPERTIES = {
  machines: [],
  filter: 0,
  startDate: '',
  startTime: '',
  stopDate: '',
  stopTime: '',
  interval: 1
}

const emailFormValid = (email) => {
  if (email) {
    let emailValid = false;
    if (email.includes('@')) {
      let emailContent = email.split('@');
      emailValid = emailContent[1].includes('.');
      if (emailValid) {
        let address = emailContent[1].split('.');
        emailValid = address[1] !== '';
        return emailValid
      } else {
        emailValid = false
        return emailValid
      }
    } else {
      return emailValid
    }
  } else {
    // Email will be null for non-email-set  preset
    return true
  }
}

// const minuteTwoCharConverter = (minute) => minute > 9 ? minute : '0' + minute;

class PresetDrawer extends Component {
  state = {
    preset: this.props.preset,
    presetName: this.props.preset ? this.props.preset.name : '',
    presetAutoGenEnabled: this.props.preset ? this.props.preset.auto_gen : false,
    presetEmail: this.props.preset ? this.props.preset.emails !== null && this.props.preset.emails !== 'null' ? this.props.preset.emails : '' : '',
    presetTime: '00:00',
    presetHour: this.props.preset ? this.props.preset.hour : '00',
    presetMinute: this.props.preset ? this.props.preset.minute : '00',
    presetInterval: this.props.preset ? this.props.preset.interval : 'daily',
    // presetProperties: this.props.preset ? this.props.preset.properties : this.props.presetProperty ? this.props.presetProperty : this.props.presetType ? DEFAULT_PRESET_DATA_PROPERTIES : DEFAULT_PRESET_PARETO_PROPERTIES,
    presetProperties: this.props.presetProperty ? this.props.presetProperty : this.props.preset ? this.props.preset.properties : this.props.presetType === 'data' ? DEFAULT_PRESET_DATA_PROPERTIES : DEFAULT_PRESET_PARETO_PROPERTIES,
    presetNameValid: true,
    presetEmailValid: true,
  }

  componentDidMount() {
    let presetMinuteAdjust = this.state.presetMinute > 9 ? this.state.presetMinute : '0' + this.state.presetMinute;
    this.setState({
      presetTime: this.state.presetHour + ':' + presetMinuteAdjust,
    });
  }

  componentDidUpdate(prevProps, prevState) {

    // Change valid state for preset name input warning
    if (prevState.presetName !== '' && this.state.presetName === '') {
      this.setState({
        presetNameValid: false,
      })
    } else if (prevState.presetName === '' && this.state.presetName !== '') {
      this.setState({
        presetNameValid: true,
      })
    }

    // Change valid state for preset email input warning
    if (prevState.presetEmail !== '' && this.state.presetEmail === '') {
      this.setState({
        presetEmailValid: false,
      })
    } else if (prevState.presetEmail === '' && this.state.presetEmail !== '' && emailFormValid(this.state.presetEmail)) {
      this.setState({
        presetEmailValid: true,
      })
    } else if (this.state.presetEmail !== '' && !emailFormValid(prevState.presetEmail) && emailFormValid(this.state.presetEmail)) {
      this.setState({
        presetEmailValid: true,
      })
    } else if (this.state.presetEmail !== '' && emailFormValid(prevState.presetEmail) && !emailFormValid(this.state.presetEmail)) {
      this.setState({
        presetEmailValid: false,
      })
    }
  }

  handleChange = (name, value) => {
    if (name === 'name') {
      this.setState({ presetName: value });
    }
    else if (name === 'email') {
      this.setState({ presetEmail: value });
    }
  }

  onAutoGenToggleHanlder = () => {
    this.setState(prevState => ({
      presetAutoGenEnabled: !prevState.presetAutoGenEnabled
    }))
  }

  onTimeChangeHandler = (event) => {
    let dateTime = event.target.value.split(':');
    this.setState(prevState => ({
      presetHour: dateTime[0],
      presetMinute: dateTime[1],
      presetTime: dateTime[0] + ':' + dateTime[1]
    }))
  }

  onIntervalChangeHandler = (event) => {
    let newPresetInterval = event.target.value;
    this.setState(prevState => ({
      presetInterval: newPresetInterval
    }))
  }

  onSubmitWidgetHandler = (event) => {
    event.preventDefault();
    const { orgId, presetType } = this.props;
    const { preset, presetName, presetAutoGenEnabled, presetEmail, presetHour, presetMinute, presetInterval, presetProperties } = this.state;
    if (!this.props.preset) {
      // Add Preset
      this.props.addReportPreset(orgId, presetName, presetType, presetAutoGenEnabled, presetEmail, presetHour, presetMinute, presetInterval, presetProperties);
    } else {
      // Edit Preset
      this.props.editReportPreset(preset.id, presetName, preset.type, presetAutoGenEnabled, presetEmail, presetHour, presetMinute, presetInterval, presetProperties);
    }
    this.props.presetDrawerToggle(true);
  }

  render() {
    const { presetType } = this.props;
    const { preset, presetName, presetAutoGenEnabled, presetEmail, presetTime, presetHour, presetMinute, presetInterval, presetNameValid, presetEmailValid, presetProperties } = this.state;
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    let disabledButton = true;
    if (presetNameValid && presetName !== '') {
      if (presetAutoGenEnabled) {
        if (presetEmailValid && presetEmail !== '' && presetHour !== 0 && presetMinute !== 0) {
          disabledButton = false;
        }
      } else {
        disabledButton = false;
      }
    }

    let listKey, listText, newStartDate, newStopDate, newStartDateText, newStopDateText, type;
    if(presetType) {
      listKey = presetType === 'data' ? 'devices' : 'machines';
      listText = presetType === 'data' ? 'Devices' : 'Machines';
      type = presetType;
    } else {
      listKey = preset.type === 'data' ? 'devices' : 'machines';
      listText = preset.type === 'data' ? 'Devices' : 'Machines';
      type = preset.type;
    }
    newStartDate = presetProperties.startDate;
    newStopDate = presetProperties.stopDate;
    if (presetProperties.interval === '-1' || presetProperties.interval === -1) {
      newStartDate = new Date(Date.now() + presetProperties.startDiff).toISOString().substring(0, 10);
      newStopDate = new Date(Date.now() + presetProperties.stopDiff).toISOString().substring(0, 10);
    } else {
      let intervalToDate = intervalToDateConverter(presetProperties.interval);
      newStartDate = intervalToDate[0];
      newStopDate = intervalToDate[1];
    }
    newStartDate = new Date(newStartDate);
    newStopDate = new Date(newStopDate);
    // newStartDateText = newStartDate.getDate() + '/' + (newStartDate.getMonth() + 1) + '/' + newStartDate.getFullYear() + ' ' + presetProperties.startTime;
    // newStopDateText = newStopDate.getDate() + '/' + (newStopDate.getMonth() + 1) + '/' + newStopDate.getFullYear() + ' ' + presetProperties.stopTime;
    newStartDateText = moment(newStartDate).format("DD/MM/YYYY") + ' ' + presetProperties.startTime;
    newStopDateText = moment(newStopDate).format("DD/MM/YYYY") + ' ' + presetProperties.stopTime;

    return (
      <div className={styles.PresetDrawer}>
        <form onSubmit={this.onSubmitWidgetHandler}>
          <Input
            label="preset name"
            type="text"
            value={presetName}
            placeholder="Insert preset name"
            autoComplete="off"
            onChange={(event) => this.handleChange('name', event.target.value)}
            error={`${!presetNameValid ? 'Preset Name is required' : ''}`}
            required
          />

          <hr className={styles.Divider} />

          <div className={styles.ReportDetail}>
            {/* <p className={styles.Title}>Information</p> */}
            <p>{listText}</p>
            <ul>
              {presetProperties[listKey].map((d,i) => <li key={'d'+i}>{d.label}</li>)}
            </ul>
            { type === 'data' ?
              <>
                <p>METHOD : <span>{presetProperties.method !== '-1' ? methodOptions.filter(opt => opt.id === presetProperties.method)[0]['name'] : 'N/A'}</span></p>
                <p>SUMMARIZE : <span>{presetProperties.summarize !== '-1' ? summarizeOptions.filter(opt => opt.id === presetProperties.summarize)[0]['name'] : 'N/A'}</span></p>
              </>
              :
              <p>FILTER : <span>{FILTER_OPTION.filter(opt => opt.id === presetProperties.filter)[0]['name']}</span></p>
            }
            <p>DATE : <span>{newStartDateText + ' - ' + newStopDateText}</span></p>
            <small>*Report date will be re-calculated every day.</small>
          </div>

          {/* TODO: Enable auto gen to be show */}
          {/* <hr className={styles.Divider} style={{marginTop: '24px'}}/> */}
          {false &&
            <div className={styles.AutoGenWrapper}>
              <p className={styles.Title}>AUTO-GEN</p>
              <div className={styles.SwitchBox}>
                <p style={{ color: presetAutoGenEnabled ? '#34aa44' : '#6b6c6f' }}>Auto-Gen is ON</p>
                <SimpleSwitch enable={presetAutoGenEnabled} onChangeEnableHandler={this.onAutoGenToggleHanlder} />
              </div>
              {
                !presetAutoGenEnabled ?
                  null
                  :
                  <React.Fragment>
                    <div className={styles.FormGroup}>
                      <Input
                        label="email address"
                        type="text"
                        value={presetEmail}
                        placeholder="Put the Email Address here"
                        autoComplete="off"
                        onChange={(event) => this.handleChange('email', event.target.value)}
                        error={`${!presetEmailValid ? 'Email Address is required' : ''}`}
                        required
                      />
                    </div>
                    <div className={styles.FormGroup}>
                      <div style={{ flex: 1 }}>
                        <label className={styles.Title}>TIME</label>
                        <div style={{ display: 'flex', position: 'relative' }}>
                          {!isChrome && <img src={CalendarIcon} alt="Starts" className={styles.CalendarIcon} />}
                          <input className={styles.SelectBox} type="time"
                            onChange={(event) => this.onTimeChangeHandler(event)}
                            value={presetTime}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.FormGroup}>
                      <Select
                        name="selectedInterval"
                        label="Interval"
                        // placeholder="Please select interval"
                        options={intervalOptions}
                        value={presetInterval}
                        autoComplete="off"
                        overidestyleformgroup={{ marginBottom: 15 }}
                        onChange={(event) => this.onIntervalChangeHandler(event)}
                        // notRequired={false}
                        noPlaceHolder={true}
                      // tooltip={"When you set date(start-stop) over 24 hours, you NEED to set method AND summarize"}
                      />
                    </div>
                  </React.Fragment>
              }
            </div>
          }

          <div style={{ height: 50, width: 'auto' }} />

          {
            preset ?
              <React.Fragment>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Save'}
                  color="green"
                  disabled={disabledButton}
                  loading={this.props.loading}
                />
                <Button type="button" name="Delete" color="borderred" click={(event) => this.props.onDelete(event, this.props.editWidget)} />
              </React.Fragment>
              :
              <React.Fragment>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Done'}
                  color="primary"
                  disabled={disabledButton}
                  loading={this.props.loading}
                />
                <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
              </React.Fragment>
          }
          {/* <Button type="button" name="Delete" color="borderred" click={(event) => this.props.onDelete(event, this.props.editWidget)} /> */}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { type, loading, addedReportPreset, updatedReportPreset } = state.reportPreset;
  return { type, loading, addedReportPreset, updatedReportPreset };
};

export default connect(mapStateToProps, { addReportPreset, editReportPreset })(PresetDrawer);