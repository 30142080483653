import * as actionTypes from './types';

// ----------------------- GET DEVICE_SOCKETS -----------------------
export const getDeviceSocketsStart = () => {
  return {
    type: actionTypes.GET_DEVICE_SOCKETS_START
  };
}

export const getDeviceSockets = (secret, tag) => {
  return {
    type: actionTypes.GET_DEVICE_SOCKETS,
    secret,
    tag
  };
}

export const getDeviceSocketsSuccess = (response) => {
  return {
    type: actionTypes.GET_DEVICE_SOCKETS_SUCCESS,
    deviceSockets: response.device_sockets
  };
}

export const getDeviceSocketsFail = (error) => {
  return {
    type: actionTypes.GET_DEVICE_SOCKETS_FAIL,
    error: error
  };
}

// ----------------------- ADD DEVICE_SOCKET -----------------------
export const addDeviceSocketStart = () => {
  return {
    type: actionTypes.ADD_DEVICE_SOCKET_START
  };
}

export const addDeviceSocket = (device, socket, name, location, description, can_control, unit, tag, functions) => {
  return {
    type: actionTypes.ADD_DEVICE_SOCKET,
    device, socket, name, location, description, can_control, unit, tag, functions
  };
}

export const addDeviceSocketSuccess = (response) => {
  return {
    type: actionTypes.ADD_DEVICE_SOCKET_SUCCESS,
    addedDeviceSocket: response.device_socket,
    result: response.result
  };
}

export const addDeviceSocketFail = (error) => {
  return {
    type: actionTypes.ADD_DEVICE_SOCKET_FAIL,
    error: error
  };
}

// ----------------------- EDIT DEVICE_SOCKET -----------------------
export const editDeviceSocketStart = () => {
  return {
    type: actionTypes.EDIT_DEVICE_SOCKET_START
  };
}

export const editDeviceSocket = (deviceSocket, name, location, description, unit, tag, functions) => {
  return {
    type: actionTypes.EDIT_DEVICE_SOCKET,
    deviceSocket, name, location, description, unit, tag, functions
  };
}

export const editDeviceSocketSuccess = (response) => {
  return {
    type: actionTypes.EDIT_DEVICE_SOCKET_SUCCESS,
    updatedDeviceSocket: response.device_socket,
    result: response.result
  };
}

export const editDeviceSocketFail = (error) => {
  return {
    type: actionTypes.EDIT_DEVICE_SOCKET_FAIL,
    error: error
  };
}

// ----------------------- EDIT DEVICE_SOCKETS_WITH_JSON -----------------------
export const editDeviceSocketsWithJSONStart = () => {
  return {
    type: actionTypes.EDIT_DEVICE_SOCKETS_WITH_JSON_START
  };
}

export const editDeviceSocketsWithJSON = (secret, form) => {
  return {
    type: actionTypes.EDIT_DEVICE_SOCKETS_WITH_JSON,
    secret, form
  };
}

export const editDeviceSocketsWithJSONSuccess = (response) => {
  return {
    type: actionTypes.EDIT_DEVICE_SOCKETS_WITH_JSON_SUCCESS,
    updatedDeviceSockets: response.device_sockets,
    result: response.result
  };
}

export const editDeviceSocketsWithJSONFail = (error) => {
  return {
    type: actionTypes.EDIT_DEVICE_SOCKETS_WITH_JSON_FAIL,
    error: error
  };
}

// ----------------------- EDIT CHANGE_SOCKET_ICON -----------------------
export const changeSocketIconStart = () => {
  return {
    type: actionTypes.CHANGE_SOCKET_ICON_START
  };
}

export const changeSocketIcon = (id, image) => {
  return {
    type: actionTypes.CHANGE_SOCKET_ICON,
    id, image
  };
}

export const changeSocketIconSuccess = (response) => {
  return {
    type: actionTypes.CHANGE_SOCKET_ICON_SUCCESS,
    updatedDeviceSocket: response.device_socket,
    result: response.result
  };
}

export const changeSocketIconFail = (error) => {
  return {
    type: actionTypes.CHANGE_SOCKET_ICON_FAIL,
    error: error
  };
}

// export default {
//   getDeviceSocketsStart,
//   getDeviceSockets,
//   getDeviceSocketsSuccess,
//   getDeviceSocketsFail,
//   addDeviceSocket,
//   addDeviceSocketStart,
//   addDeviceSocketSuccess,
//   addDeviceSocketFail,
//   editDeviceSocket,
//   editDeviceSocketStart,
//   editDeviceSocketSuccess,
//   editDeviceSocketFail,
//   changeSocketIconStart,
//   changeSocketIcon,
//   changeSocketIconSuccess,
//   changeSocketIconFail
// };