import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';

import styles from '../StartOperatorDrawer/OperatorDrawer.module.scss';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

const colourStyles = {
    singleValue: (styles, { data }) => ({
        ...styles,
        color: data.color
    }),
    control: (base, state) => ({
        ...base,
        backgroundColor: "#1c1c1f",
        borderRadius: 4,
        borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
        "&:hover": {
            borderColor: "#1665d8"
        },
        fontSize: 14,
        fontWeight: 500,
    }),
    input: base => ({
        ...base,
        color: 'white'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            borderColor: "#1c1c1f",
            backgroundColor: isDisabled
                ? null
                : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
        };
    },
    menu: base => ({
        ...base,
        marginTop: 0,
    }),
    menuList: base => ({
        ...base,
        backgroundColor: "#1c1c1f",
        fontSize: 14,
        fontWeight: 500
    }),
};

class SearchDrawer extends Component {
    state = {
        searchTexts: {},
        machinesOption: []
    }

    componentDidMount() {
        let machinesOption = [];
        if (this.props.machines) {
            this.props.machines
                .sort((a, b) => a.name.localeCompare(b.name))
                .forEach(machine => {
                    machinesOption.push({
                        id: machine.id,
                        label: machine.name
                    });
                });
        }

        const foundDefaultMachine = JSON.parse(localStorage.getItem("OwlOperatorDefaultMachine"));

        let now = moment().valueOf();
        const today = moment(now).format('YYYY-MM-DD');

        const searchTexts = {};
        if (this.props.columns && this.props.columns.length) {
            this.props.columns.forEach(col => {
                if (col.name === 'start' || col.name === 'end') {
                    searchTexts[col.name] = today;
                } else if (col.name === 'machine' && foundDefaultMachine && foundDefaultMachine[this.props.currentOrg]) {
                    searchTexts.machine = machinesOption.find(mo => mo.id === foundDefaultMachine[this.props.currentOrg].id);
                } else {
                    searchTexts[col.name] = '';
                }
            });
        }
        this.setState({ searchTexts, machinesOption });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        const searchTexts = { ...this.state.searchTexts };
        searchTexts[name] = value;
        this.setState({ searchTexts });
    }

    onMachineChange = (value) => {
        const searchTexts = { ...this.state.searchTexts };
        searchTexts.machine = value;
        this.setState({ searchTexts });
    }

    render() {
        return (
            <div>
                <form onSubmit={(e) => this.props.searchPlans(e, this.state.searchTexts)}>
                    {
                        this.props.columns && this.props.columns.length
                            ? this.props.columns.map(col => (
                                col.name === 'start' || col.name === 'end'
                                    ? <div className={styles.FormGroup} key={`search-${col.name}`} >
                                        <label className={styles.Title}>{col.name} *</label>
                                        <div style={{ display: 'flex', position: 'relative' }}>
                                            <input
                                                className={styles.SelectBox}
                                                type="date"
                                                onChange={this.handleChange}
                                                name={col.name}
                                                value={this.state.searchTexts[col.name] ? this.state.searchTexts[col.name] : ''}
                                            />
                                        </div>
                                    </div>
                                    : col.name === 'machine'
                                        ? <div className={styles.FormGroup} key={`search-${col.name}`}>
                                            <label className={styles.Title}>{col.name}</label>
                                            <div>
                                                <Select options={this.state.machinesOption} styles={colourStyles}
                                                    isClearable
                                                    placeholder="Machine"
                                                    value={this.state.searchTexts.machine}
                                                    onChange={this.onMachineChange}
                                                    getOptionValue={opt => opt.id}
                                                />
                                            </div>
                                        </div>
                                        : <div className={styles.FormGroup} key={`search-${col.name}`}>
                                            <Input label={col.display_name} name={col.name} type="text" value={this.state.searchTexts[col.name] ? this.state.searchTexts[col.name] : ''} onChange={this.handleChange} overidestyleformgroup={{ marginBottom: 0 }} />
                                        </div>
                            ))
                            : null
                    }

                    <div style={{ height: 70, width: 'auto' }} />
                    <Button
                        type="submit"
                        name={'Search'}
                        color="primary"
                    />
                    <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { machines } = state.machine;

    return { currentOrg, machines };
};

export default connect(mapStateToProps, {})(SearchDrawer);