import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill.css';

import Button from '../UI/Button/Button';
import BackIcon from '../../../assets/icons/goback.svg';

import styles from './Documents.module.scss';

class DocumentEditor extends Component {
    modules = {
        toolbar: [
            [{ 'font': [] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'color': [] }, { 'background': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
    }

    formats = [
        'font', 'size', 'color', 'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]

    render() {
        const { isAdmin, isOpen, isNew, target, onTitleChange, onContentChange, onClose, onSave } = this.props;

        return (
            <div className={styles.DocumentEditor} style={isOpen ? {} : { display: 'none' }}>
                <div className={styles.EditorHeader}>
                    <img className={styles.EditorBackButton} src={BackIcon} alt="Back" onClick={onClose} />
                    <div className={styles.EditorHeaderTitle}>
                        <input type="text" value={target.title} onChange={e => onTitleChange(e.target.value)} />
                    </div>
                    {isAdmin &&
                        <div className={styles.EditorSaveButton}>
                            <Button type="button" color="green" name={isNew ? "Add" : "Save"} noMargin disabled={target.title.trim() === '' || target.content.trim() === '<p><br></p>'} click={onSave} />
                        </div>}
                </div>
                <ReactQuill
                    className={styles.Editor}
                    modules={this.modules}
                    formats={this.formats}
                    theme="snow" value={target.content}
                    readOnly={!isAdmin}
                    onChange={onContentChange} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { loggedInUser } = state.auth;
    const { type, documents } = state.document;
    return { currentOrg, loggedInUser, type, documents };
};

export default connect(mapStateToProps, {})(DocumentEditor);