export const GET_EXPORT_CHECK_START = 'GET_EXPORT_CHECK_START';
export const GET_EXPORT_CHECK = 'GET_EXPORT_CHECK';
export const GET_EXPORT_CHECK_SUCCESS = 'GET_EXPORT_CHECK_SUCCESS';
export const GET_EXPORT_CHECK_FAIL = 'GET_EXPORT_CHECK_FAIL';

export const GET_EXPORT_EXCEL_START = 'GET_EXPORT_EXCEL_START';
export const GET_EXPORT_EXCEL = 'GET_EXPORT_EXCEL';
export const GET_EXPORT_EXCEL_SUCCESS = 'GET_EXPORT_EXCEL_SUCCESS';
export const GET_EXPORT_EXCEL_FAIL = 'GET_EXPORT_EXCEL_FAIL';

export const GET_EXPORT_BREAKDOWN_EXCEL_START = 'GET_EXPORT_BREAKDOWN_EXCEL_START';
export const GET_EXPORT_BREAKDOWN_EXCEL = 'GET_EXPORT_BREAKDOWN_EXCEL';
export const GET_EXPORT_BREAKDOWN_EXCEL_SUCCESS = 'GET_EXPORT_BREAKDOWN_EXCEL_SUCCESS';
export const GET_EXPORT_BREAKDOWN_EXCEL_FAIL = 'GET_EXPORT_BREAKDOWN_EXCEL_FAIL';
