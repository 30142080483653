import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateObject, checkValidity } from '../../../../state/utils';
import ReactSelect from 'react-select';
import moment from 'moment';

import styles from '../WidgetDrawer/WidgetDrawer.module.scss';
import WidgetSelector from '../../UI/WidgetSelector/WidgetSelector';
// import LineIcon from '../../../../assets/icons/Widgets/linechart.svg';
// import GaugeIcon from '../../../../assets/icons/Widgets/gauge.svg';
// import TextIcon from '../../../../assets/icons/Widgets/text.svg';
// import SwitchIcon from '../../../../assets/icons/Widgets/switch.svg';
import LampIcon from '../../../../assets/icons/Widgets/light.svg';
// import TimeIcon from '../../../../assets/icons/Widgets/time.svg';
// import DateIcon from '../../../../assets/icons/Widgets/date.svg';
// import PowerIcon from '../../../../assets/icons/Widgets/power.svg';
// import TimelineIcon from '../../../../assets/icons/Widgets/timeline.svg';
// import AddIcon from '../../../../assets/icons/plus-circle-blue.svg';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import Select from '../../UI/Select/Select';
import ColorRangeForm from '../../shared/ColorRangeForm/ColorRangeForm';
// import DeviceEquationArea from '../../UI/DeviceEquationArea/DeviceEquationArea';
import GaugeForm from '../WidgetForm/GaugeForm/GaugeForm';
import PowerForm from '../WidgetForm/PowerForm/PowerForm';
import LampForm from '../WidgetForm/LampForm/LampForm';
import SwitchForm from '../WidgetForm/SwitchForm/SwitchForm';

import { getDevices } from '../../../../state/ducks/Devices/actions';

const rangeOptions = [
  { id: '60', name: '1 min (realtime)' },
  { id: '3600', name: '1 hour' },
  { id: '86400', name: '1 day' },
];

const summarizeOptions = [
  { id: '60', name: '1 min' },
  { id: '120', name: '2 mins' },
  { id: '900', name: '15 mins' },
  { id: '1800', name: '30 mins' },
];

const calculateOptions = [
  { id: 'avg', name: 'Average' },
  { id: 'max', name: 'Max' },
  { id: 'min', name: 'Min' },
  { id: 'mode', name: 'Mode' },
  { id: 'sum', name: 'Sum' },
  { id: 'count', name: 'Count' },
];

let colorOptions = [
  { label: 'Red', id: '#ff5454', color: '#ff5454' },
  { label: 'Orange', id: '#ffa000', color: '#ffa000' },
  { label: 'Yellow', id: '#facf55', color: '#facf55' },
  { label: 'Green', id: '#34aa44', color: '#34aa44' },
  { label: 'Blue', id: '#1665d8', color: '#1665d8' },
];

const WIDGET_TYPES = [
  // { label: 'Line', value: 'line', icon: LineIcon },
  // { label: 'Gauge', value: 'gauge', icon: GaugeIcon, styles: { width: 36 } },
  // { label: 'Text', value: 'text', icon: TextIcon },
  // { label: 'Switch', value: 'switch', icon: SwitchIcon },
  { label: 'Lamp', value: 'lamp', icon: LampIcon, styles: { width: 34 } },
  // { label: 'Time', value: 'time', icon: TimeIcon },
  // { label: 'Date', value: 'date', icon: DateIcon },
  // { label: 'Power', value: 'power', icon: PowerIcon },
  // { label: 'Timeline', value: 'timeline', icon: TimelineIcon }
];

const colourStyles = {
  singleValue: (styles) => ({
    ...styles,
    color: "white"
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
  }),
  input: base => ({
    ...base,
    color: "white"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
};

const colourMultipleStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 400
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "#1665d8",
  }),
  multiValueLabel: base => ({
    ...base,
    color: "white",
  })
};

const DEFAULT_WIDGET_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  type: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  static_value: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  },
  // on_value: {
  //   value: '100',
  //   valid: true,
  //   touched: true,
  //   validation: {
  //     required: false
  //   }
  // },
  // off_value: {
  //   value: '0',
  //   valid: true,
  //   touched: true,
  //   validation: {
  //     required: false
  //   }
  // }
};

const DEFAULT_TIMERANGE_FORM = {
  start_time: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  stop_time: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  }
};

const DEFAULT_POWERPROPS = {
  isValid: true,
  ref_time: '00:00',
  prices: [{
    start: '00:00',
    stop: '00:00',
    price: 0
  }]
};


class WidgetDrawerFloorPlan extends Component {
  state = {
    formIsValid: false,
    widgetForm: DEFAULT_WIDGET_FORM,
    timeRangeForm: DEFAULT_TIMERANGE_FORM,
    timeRangeFormIsValid: false,
    selectedSensors: [],
    selectedSensor: null,
    selectedRange: '60',
    selectedSummarize: '-1',
    selectedCalculate: '-1',
    allColorRangeForm: [],
    colorRangeCount: 0,
    lampProps: {},
    gaugeProps: {},
    powerProps: DEFAULT_POWERPROPS,
    formula: '',
    // formula: this.props.editWidget.formula,
    customUnit: '',
    switchProps: {},
    isWidgetFormulaModalOpened: false,
    selectedCombiningWidget: null,
  }

  componentDidUpdate(prevProps, nextState) {
    if (prevProps.orgId !== this.props.orgId) {
      this.props.getDevices(this.props.orgId, true, true);
    } else if (prevProps.editWidget !== this.props.editWidget && this.props.editWidget) {
      this.setupForm(this.props.editWidget);
      this.clearColorRange();
      this.clearTimeRangeForm();
      // this.clearLampForm();
      // this.clearGaugeProps();
      console.log('edit', this.props.editWidget )
      if (this.props.editWidget.type === 'line') {
        if (this.props.editWidget.lineProps && this.props.editWidget.lineProps.combiningWidget) {
          console.log('get lineprops:' , this.props.editWidget.lineProps)
          this.setState({ selectedCombiningWidget: this.props.editWidget.lineProps.combiningWidget });
        } else {
          this.setState({ selectedCombiningWidget: null });
        }
      } else if (this.props.editWidget.type === 'timeline') {
        this.setupTimeRangeForm(this.props.editWidget.time_range);
        this.setupColorRangeForm(this.props.editWidget.colors);
      } else if (this.props.editWidget.type === 'lamp') {
        // this.setupLampForm(this.props.editWidget.lamps);
        this.setState({ lampProps: this.props.editWidget.lampProps });
      } else if (this.props.editWidget.type === 'gauge') {
        // this.setupGaugeProps(this.props.editWidget.gaugeProps);
        this.setState({ gaugeProps: this.props.editWidget.gaugeProps });
      } else if (this.props.editWidget.type === 'power' && this.props.editWidget.powerProps) {
        this.setState({ powerProps: this.props.editWidget.powerProps });
      } else if (this.props.editWidget.type === 'text') {
        this.setState({ formula: this.props.editWidget.formula });
        this.setState({ customUnit: this.props.editWidget.customUnit == null ? '' : this.props.editWidget.customUnit });
      } else if (this.props.editWidget.type === 'switch') {
        const switchProps = { ...this.props.editWidget.switchProps };
        switchProps.isValid = true;
        this.setState({ switchProps });
      }
      this.setupSelectedSensors(this.props.editWidget);
    } else if (prevProps.isAdding !== this.props.isAdding && this.props.isAdding) {
      this.onClearFields();
      this.clearColorRange();
      this.clearTimeRangeForm();
      // this.clearLampForm();
      // this.clearGaugeProps();
      this.setState({ formula: '', unit: '' });
    }
  }

  handleSelectedChange = (event) => {
    if (event.target.name === 'selectedRange') {
      let newSelectedCalculate;
      let newSelectedSummarize;
      if (event.target.value === '60') {
        newSelectedCalculate = '-1';
        newSelectedSummarize = '-1';
      } else if (event.target.value === '3600') {
        newSelectedCalculate = 'avg';
        newSelectedSummarize = '900';
      } else if (event.target.value === '86400') {
        newSelectedCalculate = 'avg';
        newSelectedSummarize = '1800';
      }
      this.setState({ selectedCalculate: newSelectedCalculate, selectedSummarize: newSelectedSummarize })
    }

    this.setState({ [event.target.name]: event.target.value })
  }

  onClearFields = () => {
    this.setState({ widgetForm: DEFAULT_WIDGET_FORM, formIsValid: false, selectedSensors: [], selectedSensor: null, selectedCombiningWidget: null });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.widgetForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] || form[inputIdentifier] === 0 ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
      });

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });

      this.setState({ widgetForm: updatedForm, formIsValid: true });
    }
  }

  setupSelectedSensors = (form) => {
    let selectedSensors = [];
    let selectedSensor = null;
    let selectedRange = '60';

    if (form.range) {
      selectedRange = form.range.range;
      let selectedCalculate = form.range.calculate;
      let selectedSummarize = form.range.summarize;
      this.setState({ selectedCalculate, selectedSummarize });
    }

    if (Array.isArray(form.secret)) {
      for (let i = 0; i < form.secret.length; i++) {
        let findSensor = this.props.deviceOptions.find(device => device.secret === form.secret[i] && device.socket === form.socket[i]);
        if (findSensor) {
          selectedSensors.push(findSensor);
        }
      }
    } else {
      selectedSensor = this.props.deviceOptions.find(device => device.secret === form.secret && device.socket === form.socket);
    }

    this.setState({ selectedSensor, selectedSensors, selectedRange });
  }

  setupTimeRangeForm = (timeRange) => {
    let updatedForm = { ...this.state.timeRangeForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: inputIdentifier === 'start_time' ? moment(timeRange.start).format('HH:mm') : moment(timeRange.stop).format('HH:mm'),
        valid: true,
        touched: true,
      });

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });

      this.setState({ timeRangeForm: updatedForm, timeRangeFormIsValid: true });
    }
  }

  handleChange = (name, value) => {
    let selectedSensors = [...this.state.selectedSensors];
    let selectedSensor = this.state.selectedSensor;

    let updatedElement = updateObject(this.state.widgetForm[name], {
      value: value,
      valid: checkValidity(value, this.state.widgetForm[name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.widgetForm, {
      [name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    if (updatedForm['type'].value === 'time' || updatedForm['type'].value === 'date') {
      if (updatedForm['name'].valid && updatedForm['type'].valid) {
        formIsValid = true;
      }
    } else if (updatedForm['type'].value === 'line') {
      if (updatedForm['name'].valid && updatedForm['type'].valid) {
        formIsValid = true;
      }
    }

    if (name === 'type') {
      if (value === 'line') {
        // updatedForm['on_value'].value = '100';
        // updatedForm['off_value'].value = '0';
        selectedSensor = null;
        this.clearColorRange();
        this.clearTimeRangeForm();
        // this.clearLampForm();
      } else if (value === 'timeline') {
        updatedForm['static_value'].value = '';
        // updatedForm['on_value'].value = '100';
        // updatedForm['off_value'].value = '0';
        selectedSensors = [];
        this.createFirstColorRangeForm();
        // this.clearLampForm();
      } else if (value === 'lamp') {
        updatedForm['static_value'].value = '';
        // updatedForm['on_value'].value = '100';
        // updatedForm['off_value'].value = '0';
        selectedSensors = [];
        // this.addNewLampColorRange(true);
      } else if (value === 'switch') {
        updatedForm['static_value'].value = '';
        selectedSensors = [];
        this.clearColorRange();
        this.clearTimeRangeForm();
        // this.clearLampForm();
      } else {
        updatedForm['static_value'].value = '';
        // updatedForm['on_value'].value = '100';
        // updatedForm['off_value'].value = '0';
        selectedSensors = [];
        this.clearColorRange();
        this.clearTimeRangeForm();
        // this.clearGaugeProps();
        // this.clearLampForm();
        this.setState({ formula: '', customUnit: '' });
      }
    }
    this.setState({ widgetForm: updatedForm, formIsValid, selectedSensors, selectedSensor });
  };


  handleDateTimeChange = (name, value) => {
    let updatedElement = updateObject(this.state.timeRangeForm[name], {
      value: value,
      valid: checkValidity(value, this.state.timeRangeForm[name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.timeRangeForm, {
      [name]: updatedElement
    });

    let timeRangeFormIsValid = true;
    for (let inputIdentifier in updatedForm) {
      timeRangeFormIsValid = updatedForm[inputIdentifier].valid && timeRangeFormIsValid;
    }
    this.setState({ timeRangeForm: updatedForm, timeRangeFormIsValid });
  };

  onMultipleChange = (value) => {
    if (value) {
      if (value.length < 5) {
        this.setState({ selectedSensors: value });
      }
    } else {
      this.setState({ selectedSensors: value });
    }
  }

  onChange = (value) => {
    this.setState({ selectedSensor: value });
  }

  setupColorRangeForm = (widgetColors) => {
    if (widgetColors) {
      let colorRangeCount = 0;
      let allColorRangeForm = [];
      widgetColors.forEach(cr => {
        const findColor = colorOptions.find(c => c.id === cr.color);
        let colors = { id: colorRangeCount, isValid: true, from_value: cr.from_value, to_value: cr.to_value, color: findColor };
        allColorRangeForm.push(colors);
        colorRangeCount++;
      });
      this.setState({ allColorRangeForm, colorRangeCount });
    } else {
      this.createFirstColorRangeForm();
    }
  }

  clearTimeRangeForm = () => {
    let timeRangeForm = DEFAULT_TIMERANGE_FORM;
    this.setState({ timeRangeForm, timeRangeFormIsValid: false });
  }

  clearColorRange = () => {
    let allColorRangeForm = [];
    let colorRangeCount = 0;
    this.setState({ allColorRangeForm, colorRangeCount });
  }

  createFirstColorRangeForm = () => {
    let allColorRangeForm = [];
    let colorRangeCount = 0;
    let defaultColorRange = { id: colorRangeCount, isValid: false, to_value: '', from_value: '', color: null };
    allColorRangeForm.push(defaultColorRange);
    this.setState({ allColorRangeForm, colorRangeCount });
  }

  addNewColorRange = () => {
    let allColorRangeForm = [...this.state.allColorRangeForm];
    let colorRangeCount = this.state.colorRangeCount;
    colorRangeCount++;
    let defaultColorRange = { id: colorRangeCount, isValid: false, to_value: '', from_value: '', color: null };
    allColorRangeForm.push(defaultColorRange);
    this.setState({ allColorRangeForm, colorRangeCount });
  }

  removeColorRange = (id) => {
    let allColorRangeForm = [...this.state.allColorRangeForm];
    let indexToDelete = allColorRangeForm.findIndex(af => af.id === id);
    allColorRangeForm.splice(indexToDelete, 1);
    this.setState({ allColorRangeForm });
  }

  handleColorRangeChange = (index, colorRangeForm, selectedColor, isValid) => {
    let updatedForm = [...this.state.allColorRangeForm];
    updatedForm[index] = {
      id: updatedForm[index].id,
      isValid: isValid,
      from_value: +colorRangeForm.from_value.value,
      to_value: +colorRangeForm.to_value.value,
      color: selectedColor
    };
    this.setState({ allColorRangeForm: updatedForm });
  }

  onSubmitWidgetHandler = (event) => {
    event.preventDefault();

    this.onClearFields();
    let colorRangeForm = null;
    let dateTimeRange = null;
    let selectedSensor = this.state.selectedSensor;
    let gaugeProps = this.state.gaugeProps;
    if (this.state.widgetForm.type.value === 'timeline') {
      if (this.state.timeRangeFormIsValid && this.state.allColorRangeForm.length) {
        let today = new Date();
        const start = new Date(moment(today).format("YYYY-MM-DD") + ' ' + this.state.timeRangeForm.start_time.value);
        const stop = new Date(moment(today).format("YYYY-MM-DD") + ' ' + this.state.timeRangeForm.stop_time.value);
        dateTimeRange = { start: start.getTime(), stop: stop.getTime() };
        let colors = [];
        this.state.allColorRangeForm.forEach(cf => {
          if (cf.isValid) {
            colors.push({ from_value: cf.from_value, to_value: cf.to_value, color: cf.color.id });
          }
        });
        if (colors.length) {
          colorRangeForm = colors;
        }
      }
    } else if (this.state.widgetForm.type.value === 'gauge') {
      if (this.state.gaugeProps.mode === 'select') {
        gaugeProps = updateObject(this.state.gaugeProps, {
          formula: ''
        });
        this.setState({ gaugeProps });
        if (this.state.gaugeProps.selectedSensor) {
          selectedSensor = this.state.gaugeProps.selectedSensor;
        }
      } else {
        // select formula
        selectedSensor = null;
        gaugeProps = updateObject(this.state.gaugeProps, {
          selectedSensor: null
        });
        this.setState({ gaugeProps });
      }
    }

    let lineProps = null;
    if (this.state.selectedCombiningWidget) {
      lineProps = {
        combiningWidget: this.state.selectedCombiningWidget
      }
    }

    if (this.props.isAdding) {
      this.props.settingUpNewAddedWidget(
        this.state.widgetForm,
        this.state.selectedSensors,
        selectedSensor,
        colorRangeForm,
        dateTimeRange,
        lineProps,
        this.state.lampProps,
        gaugeProps,
        this.state.formula,
        this.state.customUnit,
        this.state.powerProps,
        this.state.switchProps
      );
    } else {
      let lineChartRange = null;
      if (this.state.widgetForm.type.value === 'line') {
        if (this.state.selectedRange === '60') {
          lineChartRange = null;
        } else {
          lineChartRange = { range: this.state.selectedRange, calculate: this.state.selectedCalculate, summarize: this.state.selectedSummarize };
        }
      }
      this.props.settingUpEditWidget(this.props.editWidget.id,
        this.state.widgetForm,
        this.state.selectedSensors,
        selectedSensor,
        lineChartRange,
        colorRangeForm,
        dateTimeRange,
        lineProps,
        this.state.lampProps,
        gaugeProps,
        this.state.formula,
        this.state.customUnit,
        this.state.powerProps,
        this.state.switchProps
      );
    }
  }

  onCombiningWidgetChange = (value) => {
    this.setState({ selectedCombiningWidget: value });
  }

  render() {
    const { name, type, static_value } = this.state.widgetForm;
    const { start_time, stop_time } = this.state.timeRangeForm;

    let disabledButton = false;
    if (type.value === 'line') {
      disabledButton = !this.state.formIsValid || !this.state.selectedSensors || !this.state.selectedSensors.length;
    } else if (type.value === 'time' || type.value === 'date') {
      disabledButton = !this.state.formIsValid;
    } else if (type.value === 'timeline') {
      disabledButton = !this.state.formIsValid || !this.state.timeRangeFormIsValid;
    } else if (type.value === 'text') {
      // disabledButton = !this.state.formIsValid || !this.state.formula;
      disabledButton = !this.state.formIsValid || !this.props.selectedWidgetFormula;
    } else if (type.value === 'gauge') {
      disabledButton = !this.state.formIsValid || (this.state.gaugeProps && !this.state.gaugeProps.isValid);
    } else if (type.value === 'power') {
      disabledButton = !this.state.formIsValid || !this.state.selectedSensor || !this.state.powerProps.isValid;
    } else if (type.value === 'switch') {
      disabledButton = !this.state.formIsValid || (this.state.switchProps && !this.state.switchProps.isValid);
    } else {
      disabledButton = !this.state.formIsValid || !this.state.selectedSensor;
    }

    let deviceOptionsOnlyOutput = this.props.deviceOptions;
    if (this.state.widgetForm.type.value && this.state.widgetForm.type.value === 'switch') {
      deviceOptionsOnlyOutput = [];
      this.props.deviceOptions.forEach(option => {
        if (option.can_control) {
          deviceOptionsOnlyOutput.push(option);
        }
      });
    }

    let widgetLampOption = [];
    this.props.dashboard.forEach(d => {
      if (d.type === 'lamp') {
        widgetLampOption.push({
          id: d.id,
          label: d.name
        });
      }
    });
    if (widgetLampOption && widgetLampOption.length) widgetLampOption = widgetLampOption.sort((a, b) => a.label.localeCompare(b.label));

    return (
      <div className={styles.WidgetDrawer}>
        <form onSubmit={this.onSubmitWidgetHandler}>
          <Input
            label="widget name"
            type="text"
            value={name.value}
            placeholder="Insert widget name"
            autoComplete="off"
            onChange={(event) => this.handleChange('name', event.target.value)}
            error={`${name.touched && !name.valid ? 'Widget Name is required' : ''}`}
            required
          />

          <WidgetSelector widgetTypes={WIDGET_TYPES} widgetValue={type.value} selectWidgetTypeHandler={(val) => this.handleChange('type', val)} />

          {
            type.value === 'text' &&
            <>
              <div className={styles.FormGroup}>
                <label className={styles.Title}>FORMULA</label>
                {/* <DeviceEquationArea deviceOptions={this.props.deviceOptions} value={this.state.formula} setFormula={(fml) => this.setState({ formula: fml })} /> */}
                <Button type="button" name="Set Formula" color="bordergray" click={(event) => this.props.onEditFormula(event, this.props.editWidget)} />
                {/* <p style={{marginTop: '0', fontSize: '12px', color: '#9ea0a5'}}>Formula Output: {this.props.selectedWidgetFormulaOutput}</p> */}
                <p className={styles.FormulaOutput}>Output : {this.props.selectedWidgetFormulaOutput !== '' ? this.props.selectedWidgetFormulaOutput : this.props.selectedWidgetFormula}</p>
              </div>
              <div className={styles.FormGroup}>
                <label className={styles.Title}>UNIT</label>
                <input type="text" className={styles.FormField} value={this.state.customUnit} onChange={(event) => this.setState({ customUnit: event.target.value })} />
              </div>
            </>
          }

          {
            type.value && type.value !== 'time' && type.value !== 'date' && type.value !== 'line' && type.value !== 'text' && type.value !== 'gauge' && type.value !== 'switch' &&
            <React.Fragment>
              <label className={styles.Title}>DEVICES *</label>
              <ReactSelect isSearchable options={deviceOptionsOnlyOutput} styles={colourStyles}
                placeholder="Select a device"
                value={this.state.selectedSensor}
                onChange={this.onChange}
                getOptionValue={opt => opt.id}
              />
            </React.Fragment>
          }
          {
            type.value === 'timeline' &&
            <React.Fragment>
              <div className={styles.FormGroup} style={{ display: 'flex', marginBottom: 15 }}>
                <div style={{ flex: 1, marginRight: 15 }}>
                  <label className={styles.Title}>Starts *</label>
                  <input className={styles.FormField} type="time" value={start_time.value} onChange={(event) => this.handleDateTimeChange('start_time', event.target.value)} />
                </div>
                <div style={{ flex: 1 }}>
                  <label className={styles.Title}>Stops *</label>
                  <input type="time" className={styles.FormField} value={stop_time.value} onChange={(event) => this.handleDateTimeChange('stop_time', event.target.value)} />
                </div>
              </div>
              {
                this.state.allColorRangeForm && this.state.allColorRangeForm.length ?
                  this.state.allColorRangeForm.map((cf, index) => {
                    return (
                      <ColorRangeForm
                        key={cf.id}
                        colorForm={cf}
                        index={index}
                        formLength={this.state.allColorRangeForm.length}
                        addNewColorRange={this.addNewColorRange}
                        removeColorRange={() => this.removeColorRange(cf.id)}
                        handleColorRangeChange={this.handleColorRangeChange}
                        colorOptions={colorOptions} />
                    );
                  }) : null
              }
            </React.Fragment>
          }
          {
            type.value === 'lamp' &&
            <LampForm
              colorOptions={colorOptions}
              deviceOptionsOnlyOutput={deviceOptionsOnlyOutput}
              editingLampProps={this.props.editWidget ? this.props.editWidget.lampProps : null}
              handleLampChange={(lampProps) => this.setState({ lampProps })}
            />
          }
          {
            type.value === 'switch' &&
            <SwitchForm
              deviceOptionsOnlyOutput={deviceOptionsOnlyOutput}
              editingSwitchProps={this.props.editWidget ? this.props.editWidget.switchProps : null}
              handleSwitchChange={(switchProps) => this.setState({ switchProps })}
            />
            // <div style={{ display: 'flex', marginTop: 15 }}>
            //   <Input
            //     label="on"
            //     type="number"
            //     value={on_value.value}
            //     placeholder="Default is 100"
            //     autoComplete="off"
            //     overidestyleformgroup={{ marginRight: 15 }}
            //     onChange={(event) => this.handleChange('on_value', event.target.value)}
            //   />
            //   <Input
            //     label="off"
            //     type="number"
            //     value={off_value.value}
            //     placeholder="Default is 0"
            //     autoComplete="off"
            //     onChange={(event) => this.handleChange('off_value', event.target.value)}
            //   />
            // </div>
          }
          {
            type.value === 'gauge' &&
            <GaugeForm
              deviceOptions={this.props.deviceOptions}
              editingWidget={this.props.editWidget}
              handleGaugePropsChange={(gp) => this.setState({ gaugeProps: gp })}
            />
          }
          {
            type.value === 'power' &&
            <PowerForm
              editingPowerProps={this.props.editWidget ? this.props.editWidget.powerProps : null}
              handlePowerChange={(powerProps) => this.setState({ powerProps })}
            />
          }
          {
            type.value === 'line' &&
            <React.Fragment>
              <div className={styles.LineFormGroup} style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                <label className={styles.Title}>DEVICES *</label>
                <ReactSelect isMulti isSearchable closeMenuOnSelect={false} options={this.props.deviceOptions} styles={colourMultipleStyles}
                  placeholder="Select many devices"
                  value={this.state.selectedSensors}
                  onChange={this.onMultipleChange}
                  getOptionValue={opt => opt.id}
                />
              </div>
              <div className={styles.LineFormGroup} style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                <label className={styles.Title}>COMBINE WIDGET (Display only text)</label>
                <ReactSelect isClearable isSearchable closeMenuOnSelect options={widgetLampOption} styles={colourStyles}
                  placeholder="Select a widget"
                  value={this.state.selectedCombiningWidget}
                  onChange={this.onCombiningWidgetChange}
                  getOptionValue={opt => opt.id}
                />
              </div>
              <Input
                label="static value"
                type="number"
                value={static_value.value}
                placeholder="Insert static value"
                autoComplete="off"
                onChange={(event) => this.handleChange('static_value', event.target.value)}
              />
              {
                this.props.editWidget &&
                <div>
                  <Select
                    label="range *"
                    name="selectedRange"
                    placeholder="Select a range"
                    options={rangeOptions}
                    value={this.state.selectedRange}
                    onChange={this.handleSelectedChange}
                    overidestyleformgroup={{ marginBottom: 16 }}
                  />
                  {
                    this.state.selectedRange !== '60' &&
                    <React.Fragment>
                      <Select
                        label="Summarize Every *"
                        name="selectedSummarize"
                        placeholder="Sumarize every"
                        options={summarizeOptions}
                        value={this.state.selectedSummarize}
                        onChange={this.handleSelectedChange}
                        overidestyleformgroup={{ marginBottom: 16 }}
                      />
                      <Select
                        label="Calculate Method *"
                        name="selectedCalculate"
                        placeholder="Select a method"
                        options={calculateOptions}
                        value={this.state.selectedCalculate}
                        onChange={this.handleSelectedChange}
                        overidestyleformgroup={{ marginBottom: 16 }}
                      />
                    </React.Fragment>
                  }
                </div>
              }
            </React.Fragment>
          }

          <div style={{ height: 70, width: 'auto' }} />
          {
            this.props.isAdding ?
              <React.Fragment>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Done'}
                  color="primary"
                  disabled={disabledButton}
                  loading={this.props.loading}
                />
                <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
              </React.Fragment> :
              <React.Fragment>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Save'}
                  color="green"
                  disabled={disabledButton}
                  loading={this.props.loading}
                />
                <Button type="button" name="Delete" color="borderred" click={(event) => this.props.onDelete(event, this.props.editWidget)} />
              </React.Fragment>
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading } = state.widgets;
  return { loading };
};

export default connect(mapStateToProps, { getDevices })(WidgetDrawerFloorPlan);