import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import ChangeNewManager from '../ChangeNewManager/ChangeNewManager';
import ToolTip from '../../UI/ToolTip/Tooltip';
import styles from './SearchMember.module.scss';
import DeleteIcon from '../../../../assets/icons/delete-member-button.svg';
import TransferIcon from '../../../../assets/icons/transfer-member-button.svg';
import AdminProfile from '../../../../assets/images/admin.svg';

import { getMembers } from '../../../../state/ducks/Members/actions';

const colourStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 400
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "#1665d8",
  }),
  multiValueLabel: base => ({
    ...base,
    color: "white",
  })
};

class SearchMember extends Component {
  state = {
    searchMember: null,
    admin: null,
    membersRender: null,
    addingMembers: [],
    deletingMembers: [],
    options: [],
    isChangeManagerModalOpened: false,
    membersNotMatch: false,
    formChanged: false
  }

  componentDidMount() {
    if (!this.props.members && this.props.membersAlreadyIn && this.props.editing) {
      this.findAdminAndMembers(this.props.membersAlreadyIn, this.props.editing.admin_id);
      this.props.getMembers(this.props.currentOrg);
    } else if (this.props.members && this.props.membersAlreadyIn && this.props.editing) {
      this.findAdminAndMembers(this.props.membersAlreadyIn, this.props.editing.admin_id);
      this.setMembersToOptions(this.props.membersAlreadyIn, this.props.members);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isDrawerOpened && prevProps.isDrawerOpened !== this.props.isDrawerOpened) {
      this.setState({ isChangeManagerModalOpened: false, formChanged: false });
      if (!this.props.members) {
        this.props.getMembers(this.props.currentOrg);
      }
      // clear eveything new after open drawer
      if (this.props.members && this.props.membersAlreadyIn && this.props.editing) {
        this.findAdminAndMembers(this.props.membersAlreadyIn, this.props.editing.admin_id);
        this.setMembersToOptions(this.props.membersAlreadyIn, this.props.members);
        this.setState({ membersNotMatch: false });
      }
    }

    if (this.props.membersAlreadyIn && prevProps.membersAlreadyIn !== this.props.membersAlreadyIn && this.props.editing) {
      if (!this.state.options && this.props.members) {
        // console.log('no options!!!')
        this.setMembersToOptions(this.props.membersAlreadyIn, this.props.members);
      }
      if (!this.props.noAdmin) {
        this.findAdminAndMembers(this.props.membersAlreadyIn, this.props.editing.admin_id);
      }
    }

    if (this.props.membersAlreadyIn && this.props.members !== prevProps.members) {
      this.setMembersToOptions(this.props.membersAlreadyIn, this.props.members);
      // console.log('set ooptions as normal')
    } else if (this.props.membersAlreadyIn && prevProps.membersAlreadyIn !== this.props.membersAlreadyIn && this.props.members && !this.props.noAdmin) {
      // console.log('membersAlreadyIn not eq ', prevProps.membersAlreadyIn, this.props.membersAlreadyIn)
      this.setMembersToOptions(this.props.membersAlreadyIn, this.props.members);
    }

    if ((this.props.isFormChanged !== prevProps.isFormChanged)) {
      if (this.props.isFormChanged && this.state.isChangeManagerModalOpened) {
        this.setState({ isChangeManagerModalOpened: false, formChanged: true });
      } else if (!this.props.isFormChanged) {
        this.setState({ formChanged: false });
      }
    }
  }

  setMembersToOptions = (existingMembersAlreadyIn, allMembers) => {
    let options = [];
    allMembers.forEach(member => {
      if (!member.is_superuser) {
        const found = existingMembersAlreadyIn.filter(mp => mp.id === member.id);
        if (found && found.length === 0) {
          options.push({
            id: member.id,
            label: member.first_name + ' ' + member.last_name + ' (' + member.email + ')'
          });
        }
      }
    });
    this.setState({ options, addingMembers: [], deletingMembers: [] });
  }

  findAdminAndMembers = (membersAlreadyIn, adminId) => {
    let membersRender = [];
    membersAlreadyIn.forEach(mp => {
      if (mp.id === adminId) {
        this.setState({ admin: mp });
      } else {
        membersRender.push(mp);
      }
    });
    this.setState({ membersRender });
  }

  onAddMemberHandler = value => {
    const newMember = this.props.members.filter(member => member.id === value.id)[0];
    // membersRender for render here, addingMembers for save (api)
    const membersRender = [...this.state.membersRender];
    membersRender.push(newMember);

    if (!this.checkMembersRenderChanges(membersRender)) {
      if (this.state.isChangeManagerModalOpened) {
        this.setState({ isChangeManagerModalOpened: false, membersNotMatch: true });
      }
    } else {
      this.setState({ membersNotMatch: false });
    }

    const deletingMembers = this.state.deletingMembers.filter(dm => dm.id !== value.id);
    this.props.saveOrDeleteMembers('delete', deletingMembers);

    let options = this.state.options.filter(opt => opt.id !== value.id);
    this.setState({ membersRender, options, deletingMembers });

    let addingMembers;
    if (!this.props.noAdmin) {
      const foundExistingMember = this.props.membersAlreadyIn.filter(member => member.id === value.id);
      if (foundExistingMember && foundExistingMember.length === 0) {
        //console.log('can be added!', newMember.first_name)
        addingMembers = [...this.state.addingMembers];
      }
    } else {
      addingMembers = [...this.state.membersRender];
    }
    addingMembers.push(newMember);
    this.props.saveOrDeleteMembers('add', addingMembers);
    this.setState({ addingMembers });
  }

  onRemoveMemberHandler = (i) => {
    const membersRender = [...this.state.membersRender];
    membersRender.splice(i, 1);

    if (!this.checkMembersRenderChanges(membersRender)) {
      if (this.state.isChangeManagerModalOpened) {
        this.setState({ isChangeManagerModalOpened: false, membersNotMatch: true });
      }
    } else {
      this.setState({ membersNotMatch: false });
    }

    let addingMembers;
    if (!this.props.noAdmin) {
      addingMembers = this.state.addingMembers.filter(am => am.id !== this.state.membersRender[i].id);
    } else {
      addingMembers = this.state.membersRender.filter(am => am.id !== this.state.membersRender[i].id);
    }
    this.props.saveOrDeleteMembers('add', addingMembers);

    this.checkForDeletingMember(this.state.membersRender[i].id);
    this.addBackToOption(this.state.membersRender[i]);
    this.setState({ membersRender, addingMembers });
  }

  checkForDeletingMember = (id) => {
    const deletingMembers = [...this.state.deletingMembers];
    const foundExistingMember = this.props.membersAlreadyIn.filter(member => member.id === id);
    const deletingMemberOnTheList = deletingMembers.filter(dm => dm.id === id);
    if (foundExistingMember && foundExistingMember.length > 0 && deletingMemberOnTheList && deletingMemberOnTheList.length === 0) {
      deletingMembers.push(foundExistingMember[0]);
      this.setState({ deletingMembers });
      this.props.saveOrDeleteMembers('delete', deletingMembers);
    }
  }

  addBackToOption = (member) => {
    const options = [...this.state.options];
    options.push({
      id: member.id,
      label: member.first_name + ' ' + member.last_name + ' (' + member.email + ')'
    });
    this.setState({ options });
  }

  onOpenChangeManagerHandler = () => {
    if (this.state.isChangeManagerModalOpened) {
      this.setState({ isChangeManagerModalOpened: false });
    } else {
      if (this.checkMembersRenderChanges(this.state.membersRender) && !this.props.isFormChanged) {
        this.setState({ isChangeManagerModalOpened: true, membersNotMatch: false });
      } else if (this.props.isFormChanged) {
        this.setState({ formChanged: true });
      } else {
        this.setState({ membersNotMatch: true });
      }
    }
  }

  checkMembersRenderChanges = (membersRender) => {
    let result = true;
    if (membersRender.length !== this.props.membersAlreadyIn.length - 1) {
      return false;
    }

    membersRender.forEach(mr => {
      if (this.state.admin && mr.id !== this.state.admin.id) {
        const found = this.props.membersAlreadyIn.findIndex(m => m.id === mr.id);
        if (found < 0) {
          result = result && false;
        } else {
          result = result && true;
        }
      } else {
        result = true;
      }
    });
    return result;
  }

  onChangeAdminHandler = (indexOfMembersRender) => {
    const newMembersRender = [...this.state.membersRender];
    newMembersRender.splice(indexOfMembersRender, 1);
    newMembersRender.push(this.state.admin);
    this.setState({
      admin: this.state.membersRender[indexOfMembersRender],
      membersRender: newMembersRender
    });

    if (this.props.editing.admin_id !== this.state.membersRender[indexOfMembersRender].id) {
      this.props.isChangingAdmin(this.state.membersRender[indexOfMembersRender].id);
    } else {
      this.props.isChangingAdmin(null);
    }
  }

  render() {
    const isPageOrTeamAdmin = this.props.loggedInUser && this.state.admin && this.props.loggedInUser.id === this.state.admin.id;
    const isAdmin = this.props.loggedInUser && this.props.loggedInUser.is_superuser;

    let profilePictureStyles = [styles.ProfilePictureWrapper];
    let adminProfilePictureStyles = [styles.ProfilePictureWrapper];
    if ((isPageOrTeamAdmin || this.props.isOrgAdmin || isAdmin) && !this.props.newAdmin) {
      profilePictureStyles.push(styles.CanDelete);
      if (this.props.membersAlreadyIn && this.props.membersAlreadyIn.length > 1) {
        if (this.state.membersRender && this.state.membersRender.length > 0) {
          adminProfilePictureStyles.push(styles.CanDelete);
        }
      }
    }

    return (
      <React.Fragment>
        <ChangeNewManager
          onChangeOwner={this.onChangeAdminHandler}
          isDrawerOpened={this.props.isDrawerOpened}
          open={this.state.isChangeManagerModalOpened}
          closed={this.onOpenChangeManagerHandler}
          owner={this.state.admin}
          members={this.state.membersRender}
        />
        <div className={styles.MemberSearchTopic}>{this.props.label ? this.props.label : 'MEMBERS'}</div>
        {
          (isPageOrTeamAdmin || this.props.isOrgAdmin || isAdmin) && !this.props.newAdmin ?
            <Select
              isSearchable
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              options={this.state.options}
              styles={colourStyles}
              placeholder="Search by name/email/username"
              value={this.state.searchMember}
              onChange={this.onAddMemberHandler}
              getOptionValue={opt => opt.id}
            />
            : null
        }
        <div className={styles.MembersWrapper}>
          {
            this.state.admin &&
            <ToolTip text={`${this.state.admin.first_name} ${this.state.admin.last_name}`} overideIcon overideTitleStyles={{ marginLeft: '-4px' }}>
              <div className={adminProfilePictureStyles.join(' ')}>
                <img src={this.state.admin.profile_picture ? this.state.admin.profile_picture : AdminProfile} alt="admin profile pic" className={`${styles.MemberProfilePicture} ${styles.Owner}`} />
                <img src={TransferIcon} className={styles.DeleteMemberButton} alt="Transfer" onClick={this.onOpenChangeManagerHandler} />
              </div>
            </ToolTip>
          }
          {
            this.state.membersRender ?
              this.state.membersRender.map((member, i) => (
                <ToolTip key={`member-${member.id}`} text={`${member.first_name} ${member.last_name}`} overideIcon overideTitleStyles={{ marginLeft: '-4px', width: 100 }}>
                  <div className={profilePictureStyles.join(' ')}>
                    <img src={member.profile_picture} alt="member profile pic" className={styles.MemberProfilePicture} />
                    <img onClick={() => this.onRemoveMemberHandler(i)} src={DeleteIcon} className={styles.DeleteMemberButton} alt="Delete" />
                  </div>
                </ToolTip>
              ))
              : null
          }
        </div>
        {
          this.state.membersNotMatch || this.state.formChanged ?
            <div className={styles.MembersNotMatch}>Please save changes before changing manager!</div>
            : null
        }
      </React.Fragment>
    );
  }
}

const Svg = p => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div style={{ color: 'rgb(204, 204, 204)', height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { members } = state.members;
  const { currentOrg } = state.org;
  return { loggedInUser, members, currentOrg };
};

export default connect(mapStateToProps, { getMembers })(SearchMember);