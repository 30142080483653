import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateObject, checkValidity } from '../../../state/utils';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';

import { addNoteType, editNoteType } from '../../../state/ducks/Document/actions';

const DEFAULT_NOTE_TYPE_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  }
};

class BatchDrawer extends Component {
  state = {
    formIsValid: false,
    noteTypeForm: DEFAULT_NOTE_TYPE_FORM,
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAdding && (prevProps.isAdding !== this.props.isAdding)) {
      this.onClearFields();
    } else if ((prevProps.addedNoteType !== this.props.addedNoteType) || (prevProps.updatedNoteType !== this.props.updatedNoteType)) {
      this.props.closed();
    } else if (this.props.editingNoteType && prevProps.editingNoteType !== this.props.editingNoteType) {
      this.setupForm(this.props.editingNoteType);
    }
  }

  onClearFields = () => {
    const noteTypeForm = { ...DEFAULT_NOTE_TYPE_FORM };
    this.setState({ noteTypeForm, formIsValid: false });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.noteTypeForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
      });

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }

    this.setState({ noteTypeForm: updatedForm, formIsValid: true });
  }

  handleFormChange = event => {
    let updatedElement = updateObject(this.state.noteTypeForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.noteTypeForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.noteTypeForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ noteTypeForm: updatedForm, formIsValid });
  };

  onSubmitNoteTypeHandler = (event) => {
    event.preventDefault();
    const { name } = this.state.noteTypeForm;
    if (this.props.isAdding) {
      this.props.addNoteType(
        this.props.currentOrg,
        name.value
      );
    } else {
      this.props.editNoteType(
        this.props.editingNoteType.id,
        name.value
      );
    }
    this.onClearFields();
  }

  render() {
    const { name } = this.state.noteTypeForm;

    return (
      <div>
        <div className="ErrorText">{this.props.isAdding && this.props.result === 'failed' ? this.props.error : ''}</div>
        <form onSubmit={this.onSubmitNoteTypeHandler}>
          <Input
            label="Name"
            name="name"
            type="text"
            value={name.value}
            placeholder="Please insert your type name"
            autoComplete="off"
            onChange={this.handleFormChange}
            error={`${name.touched && !name.valid ? `Batch is required` : ''}`}
            required
          />

          <div style={{ height: 70, width: 'auto' }} />

          {
            this.props.isAdding
              ? <>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Done'}
                  color="primary"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
              </>
              : <>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Save'}
                  color="green"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Delete" color="borderred" click={this.props.toggleDeleteModal} />
              </>
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { addedNoteType, updatedNoteType, result, error } = state.document;
  return {
    currentOrg, addedNoteType, updatedNoteType, result, error
  };
};

export default connect(mapStateToProps, { addNoteType, editNoteType })(BatchDrawer);