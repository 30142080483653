import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './ChatRoom.module.scss';

class ChatRoom extends Component {
  render() {
    return (
      <div className={styles.ChatRoom}>
        <div className={styles.TopSection}>
          <div className={styles.Name}>{}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { teams } = state.team;
  return { teams };
};

export default connect(mapStateToProps, {})(ChatRoom);
