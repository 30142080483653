export const GET_MEMBERS_START = 'GET_MEMBERS_START';
export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_FAIL = 'GET_MEMBERS_FAIL';

export const GET_MEMBER_START = 'GET_MEMBER_START';
export const GET_MEMBER = 'GET_MEMBER';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_FAIL = 'GET_MEMBER_FAIL';

export const ADD_MEMBER_START = 'ADD_MEMBER_START';
export const ADD_MEMBER = 'ADD_MEMBER';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_FAIL = 'ADD_MEMBER_FAIL';

export const EDIT_MEMBER_START = 'EDIT_MEMBER_START';
export const EDIT_MEMBER = 'EDIT_MEMBER';
export const EDIT_MEMBER_SUCCESS = 'EDIT_MEMBER_SUCCESS';
export const EDIT_MEMBER_FAIL = 'EDIT_MEMBER_FAIL';

export const DELETE_MEMBER_START = 'DELETE_MEMBER_START';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAIL = 'DELETE_MEMBER_FAIL';