
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_COUNTING_DEVICES_LIST, ADD_COUNTING_DEVICES, EDIT_COUNTING_DEVICES, DELETE_COUNTING_DEVICES } from './types';

export function* watchCountingDevices() {
  yield takeEvery(GET_COUNTING_DEVICES_LIST, getCountingDevicesListSaga);
  yield takeEvery(ADD_COUNTING_DEVICES, addCountingDevicesSaga);
  yield takeEvery(EDIT_COUNTING_DEVICES, editCountingDevicesSaga);
  yield takeEvery(DELETE_COUNTING_DEVICES, deleteCountingDevicesSaga);
}

export function* getCountingDevicesListSaga(params) {
  yield put(actions.getCountingDevicesListStart());

  let api = 'v1/countingdevices?org_id=' + params.org_id;

  try {
    const response = yield axios.get(api);
    console.log("GET COUNTING_DEVICES LIST SUCCESS", response.data);
    yield put(actions.getCountingDevicesListSuccess(response.data));
  } catch (err) {
    console.log("GET COUNTING_DEVICES LIST FAILED", err)
    yield put(actions.getCountingDevicesListFail(err.response));
  }
}

export function* addCountingDevicesSaga(params) {
  yield put(actions.addCountingDevicesStart());

  let postData = "org_id=" + params.org_id;
  postData += "&name=" + encodeURIComponent(params.name);
  postData += params.desc ? "&desc=" + encodeURIComponent(params.desc) : '';
  postData += "&product_device_socket_id=" + params.product_device_socket_id;
  postData += params.defect_device_socket_id ? "&defect_device_socket_id=" + params.defect_device_socket_id : '';
  postData += "&status_device_socket_id=" + params.status_device_socket_id;
  postData += "&status_threshold=" + params.status_threshold;
  postData += "&status_delay=" + params.status_delay;
  postData += "&status_sum_method=" + params.status_sum_method;
  postData += "&properties=" + JSON.stringify(params.properties);

  const api = 'v1/countingdevices';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD COUNTING_DEVICES SUCCESS", response);
    yield put(actions.addCountingDevicesSuccess(response.data));
  } catch (err) {
    console.log("ADD COUNTING_DEVICES FAILED", err.response.data);
    yield put(actions.addCountingDevicesFail(err.response.data.error));
  }
}

export function* editCountingDevicesSaga(params) {
  yield put(actions.editCountingDevicesStart());

  let putData = "name=" + encodeURIComponent(params.name);
  putData += params.desc ? "&desc=" + encodeURIComponent(params.desc) : '';
  putData += "&product_device_socket_id=" + params.product_device_socket_id;
  putData += "&defect_device_socket_id=" + params.defect_device_socket_id;
  putData += "&status_device_socket_id=" + params.status_device_socket_id;
  putData += "&status_threshold=" + params.status_threshold;
  putData += "&status_delay=" + params.status_delay;
  putData += "&status_sum_method=" + params.status_sum_method;
  putData += "&approvers=" + params.approvers;
  putData += "&properties=" + JSON.stringify(params.properties);

  const api = 'v1/countingdevices/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT COUNTING_DEVICES SUCCESS", response.data);
    yield put(actions.editCountingDevicesSuccess(response.data));
  } catch (err) {
    console.log("EDIT COUNTING_DEVICES FAILED", err.response.data);
    yield put(actions.editCountingDevicesFail(err.response.data));
  }
}

export function* deleteCountingDevicesSaga(params) {
  yield put(actions.deleteCountingDevicesStart());

  const api = 'v1/countingdevices/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE COUNTING_DEVICES SUCCESS", response.data);
    yield put(actions.deleteCountingDevicesSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE COUNTING_DEVICES FAILED", err.response);
    yield put(actions.deleteCountingDevicesFail(err.response));
  }
}