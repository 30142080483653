import * as actionTypes from './types';

// ----------------------- GET REPORT_PRESETS -----------------------
export const getReportPresetsStart = () => {
  return {
    type: actionTypes.GET_REPORT_PRESETS_START
  };
}

export const getReportPresets = (org_id) => {
  return {
    type: actionTypes.GET_REPORT_PRESETS,
    org_id
  };
}

export const getReportPresetsSuccess = (response) => {
  return {
    type: actionTypes.GET_REPORT_PRESETS_SUCCESS,
    reportPresets: response.presets
  };
}

export const getReportPresetsFail = (error) => {
  return {
    type: actionTypes.GET_REPORT_PRESETS_FAIL,
    error
  };
}

// ----------------------- ADD REPORT_PRESET -----------------------
export const addReportPresetStart = () => {
  return {
    type: actionTypes.ADD_REPORT_PRESET_START
  };
}

export const addReportPreset = (org_id, name, presetType, auto_gen, emails, hour, minute, interval, properties) => {
  return {
    type: actionTypes.ADD_REPORT_PRESET,
    org_id, name, presetType, auto_gen, emails, hour, minute, interval, properties
  };
}

export const addReportPresetSuccess = (response) => {
  return {
    type: actionTypes.ADD_REPORT_PRESET_SUCCESS,
    result: response.result,
    addedReportPreset: response.presets[0]
  };
}

export const addReportPresetFail = (error) => {
  return {
    type: actionTypes.ADD_REPORT_PRESET_FAIL,
    error
  };
}

// ----------------------- EDIT REPORT_PRESET -----------------------
export const editReportPresetStart = () => {
  return {
    type: actionTypes.EDIT_REPORT_PRESET_START
  };
}

export const editReportPreset = (id, name, presetType, auto_gen, emails, hour, minute, interval, properties) => {
  return {
    type: actionTypes.EDIT_REPORT_PRESET,
    id, name, presetType, auto_gen, emails, hour, minute, interval, properties
  };
}

export const editReportPresetSuccess = (response) => {
  return {
    type: actionTypes.EDIT_REPORT_PRESET_SUCCESS,
    result: response.result,
    updatedReportPreset: response.presets[0],
  };
}

export const editReportPresetFail = (error) => {
  return {
    type: actionTypes.EDIT_REPORT_PRESET_FAIL,
    error
  };
}

// ----------------------- DELETE REPORT_PRESET -----------------------
export const deleteReportPresetStart = () => {
  return {
    type: actionTypes.DELETE_REPORT_PRESET_START
  };
}

export const deleteReportPreset = (id) => {
  return {
    type: actionTypes.DELETE_REPORT_PRESET,
    id
  };
}

export const deleteReportPresetSuccess = (result) => {
  return {
    type: actionTypes.DELETE_REPORT_PRESET_SUCCESS,
    result
  };
}

export const deleteReportPresetFail = (error) => {
  return {
    type: actionTypes.DELETE_REPORT_PRESET_FAIL,
    error
  };
}