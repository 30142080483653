import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  value: null,
  error: null,
  loading: false,
  virtualDevices: null,
  addedVirtualDevice: null,
  updatedVirtualDevice: null,
  updatedEnableVirtualDevice: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_VIRTUAL_DEVICES_START: return actionStart(state, action);
    case actionTypes.GET_VIRTUAL_DEVICES_SUCCESS: return getVirtualDevicesSuccess(state, action);
    case actionTypes.GET_VIRTUAL_DEVICES_FAIL: return actionFail(state, action);

    case actionTypes.ADD_VIRTUAL_DEVICE_START: return actionStart(state, action);
    case actionTypes.ADD_VIRTUAL_DEVICE_SUCCESS: return addVirtualDeviceSuccess(state, action);
    case actionTypes.ADD_VIRTUAL_DEVICE_FAIL: return actionFail(state, action);

    case actionTypes.EDIT_VIRTUAL_DEVICE_START: return actionStart(state, action);
    case actionTypes.EDIT_VIRTUAL_DEVICE_SUCCESS: return editVirtualDeviceSuccess(state, action);
    case actionTypes.EDIT_VIRTUAL_DEVICE_FAIL: return actionFail(state, action);

    case actionTypes.DELETE_VIRTUAL_DEVICE_START: return actionStart(state, action);
    case actionTypes.DELETE_VIRTUAL_DEVICE_SUCCESS: return deleteVirtualDeviceSuccess(state, action);
    case actionTypes.DELETE_VIRTUAL_DEVICE_FAIL: return actionFail(state, action);

    case actionTypes.TEST_VIRTUAL_DEVICE_SCRIPT_START: return actionStart(state, action);
    case actionTypes.TEST_VIRTUAL_DEVICE_SCRIPT_SUCCESS: return testVirtualDeviceScriptSuccess(state, action);
    case actionTypes.TEST_VIRTUAL_DEVICE_SCRIPT_FAIL: return actionFail(state, action);
    default: return state;
  }
};

const actionStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const actionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getVirtualDevicesSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    virtualDevices: action.virtualDevices ? action.virtualDevices.sort((a, b) => a.id - b.id) : null,
    error: null,
    loading: false
  });
}

const addVirtualDeviceSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedVirtualDevice: action.addedVirtualDevice,
    error: null,
    loading: false
  });
}

const editVirtualDeviceSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedVirtualDevice: action.updatedVirtualDevice,
    error: null,
    loading: false
  });
}

const deleteVirtualDeviceSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const testVirtualDeviceScriptSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    value: action.value,
    error: null,
    loading: false
  });
}

export default reducer;