import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import styles from './DocumentMasterData.module.scss';
import Button from '../../components/UI/Button/Button';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import EditIcon from '../../../assets/icons/edit-gray.svg';
import EditWhiteIcon from '../../../assets/icons/edit-white.svg';
import EditHoverIcon from '../../../assets/icons/edit.svg';

import { getDocMasterDatas, deleteDocMasterData } from '../../../state/ducks/DocumentMasterData/actions';
import { getNoteTypes, clearNoteTypes } from '../../../state/ducks/Document/actions';
import { DELETE_DOC_MASTER_DATA_SUCCESS } from '../../../state/ducks/DocumentMasterData/types';
import DocMasterDataDrawer from '../../components/Documents/MasterData/MasterDataDrawer';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';

const magicBgLayers = ['#2b2b30', '#2b2b30BB', '#2b2b3088', '#2b2b3044', '#2b2b3022']

const RecursiveComponent = ({ node, indexes, level, selectElement, openingIndexes, parentNode, openChilds, editMasterData }) => {
  const hasChildren = node && node.childs && node.childs.length

  return (
    <>
      <div className={`${styles.DocMasterDataRow} ${parentNode && parentNode.currentParentUuid === node.uuid ? styles.Selected : ''}`} style={{ backgroundColor: magicBgLayers[level > 4 ? 4 : level] }} onClick={() => selectElement(indexes, level)}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 30 * level }} />
            <div className={styles.OpenChild} onClick={(e) => openChilds(e, indexes)}>{hasChildren ? node.opened ? '-' : '+' : ''}</div>
            <div className={styles.Code}>{node.code}</div>
            <div style={{ margin: '0 12px' }}>|</div>
            <div className={styles.Title}>{node.title}</div>
            <div className={styles.ChildAmount}>{node.childs ? '(' + node.childs.length + ')' : ''}</div>
          </div>
          {node.description && <div style={{ display: 'flex', alignItems: 'center' }}><div style={{ width: (30 * level) + 24 }} /><div className={styles.Description}>{node.description}</div></div>}
        </div>
        <img style={{ marginRight: 30 }}
          src={EditIcon}
          onMouseOver={e => (e.currentTarget.src = `${parentNode && parentNode.currentParentUuid === node.uuid ? EditWhiteIcon : EditHoverIcon}`)}
          onMouseOut={e => (e.currentTarget.src = EditIcon)}
          alt="Edit Icon"
          onClick={(e) => editMasterData(e, node, indexes, level)} />
      </div>
      {node.opened && hasChildren && node.childs.sort((a, b) => new Date(a.created_date) - new Date(b.created_date)).map((item, j) => (
        <RecursiveComponent key={item.code} node={item} indexes={[...indexes, j]} level={level + 1} selectElement={selectElement} openingIndexes={openingIndexes} parentNode={parentNode} openChilds={openChilds} editMasterData={editMasterData} />
      ))}
    </>
  )
}

class DocumentMasterData extends Component {
  state = {
    currentDocMasterDatas: [],
    isDrawerOpened: false,
    isAdding: false,
    editingMasterData: null,
    openingIndexes: [],
    parentNode: null,
    selectingNode: [],
    isDeleteModalOpened: false,
    deletingUuid: null,
    currentTabIndex: 0
  }

  componentDidMount() {
    this.props.getNoteTypes(this.props.currentOrg);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.props.clearNoteTypes();
      setTimeout(() => {
        this.props.getNoteTypes(this.props.currentOrg);
      }, 500);
    } else if (this.props.noteTypes !== prevProps.noteTypes && this.props.noteTypes) {
      if (this.props.noteTypes && this.props.noteTypes.length) this.props.getDocMasterDatas(this.props.currentOrg, true, this.props.noteTypes[this.state.currentTabIndex].id);
    } else if (this.state.currentTabIndex !== prevState.currentTabIndex) {
      this.props.getDocMasterDatas(this.props.currentOrg, true, this.props.noteTypes[this.state.currentTabIndex].id);
    } else if (this.props.docMasterDatas && !_.isEqual(prevProps.docMasterDatas, this.props.docMasterDatas)) {
      this.setState({ currentDocMasterDatas: [...this.props.docMasterDatas] });
    } else {
      if ((this.props.result !== prevProps.result) && this.props.result === 'success') {
        if (prevProps.updatedDocMasterData !== this.props.updatedDocMasterData || prevProps.addedDocMasterData !== this.props.addedDocMasterData || this.props.type === DELETE_DOC_MASTER_DATA_SUCCESS) {
          this.props.getDocMasterDatas(this.props.currentOrg, true, this.props.noteTypes[this.state.currentTabIndex].id);
        }
      }
    }
  }

  drawerToggleHandler = () => {
    this.setState(prevState => {
      return { isDrawerOpened: !prevState.isDrawerOpened }
    });
  }

  openDrawerForAddingHandler = () => {
    this.setState({ isAdding: true, editingMasterData: null, isDrawerOpened: true });
  }

  onOpenChildsHandler = (event, indexes) => {
    event.stopPropagation();
    const updatedDocMasterDatas = [...this.state.currentDocMasterDatas];
    let node = [...updatedDocMasterDatas];
    indexes.forEach((ind, j) => {
      if (j === indexes.length - 1) {
        node[ind].opened = node[ind].opened ? false : true;
      } else {
        node[ind].opened = true;
      }
      node = node[ind].childs;
    });

    this.setState({ currentDocMasterDatas: updatedDocMasterDatas });
  }

  onEditMasterDataHandler = (event, node, indexes, level) => {
    event.stopPropagation();
    let parentNode = this.getParentNodeByIndexes(indexes, level);

    this.setState({ isAdding: false, parentNode, editingMasterData: { ...node, ...{ path: this.getParentNodeByIndexes(indexes, level, true).keyTitle } }, isDrawerOpened: true });
  }

  onSelectMasterDataHandler = (indexes, level) => {
    let parentNode = this.getParentNodeByIndexes(indexes, level);

    const isOldNodeClicked = indexes.length === this.state.selectingNode.length && indexes.every((value, index) => value === this.state.selectingNode[index])
    this.setState({ parentNode: isOldNodeClicked ? null : parentNode, selectingNode: isOldNodeClicked ? [] : indexes });
  }

  getParentNodeByIndexes = (indexes, level, notLast) => {
    let parentNode = { ...this.state.parentNode };
    let keyCode = '', keyTitle = '', currentParentUuid;
    let initItem = this.props.docMasterDatas;
    indexes.forEach((ind, c) => {
      if (notLast && c === indexes.length - 1) {
      } else {
        keyCode += initItem[ind].code + ' / ';
        keyTitle += initItem[ind].title + ' / ';
        currentParentUuid = initItem[ind].uuid;
        initItem = initItem[ind].childs;
      }
    });
    parentNode = {
      level: level,
      keyCode,
      keyTitle,
      currentParentUuid
    };
    return parentNode;
  }

  onDeleteDocMasterDataHandler = () => {
    this.setState({ deletingUuid: this.state.editingMasterData.uuid, isDeleteModalOpened: true });
  }

  onConfirmDeleteDocMasterData = (event) => {
    event.preventDefault();
    this.props.deleteDocMasterData(this.props.currentOrg, this.state.deletingUuid);
    this.setState({ deletingUuid: null, isDeleteModalOpened: false, isDrawerOpened: false, editingMasterData: null, parentNode: null });
  }

  changeTabTypeHandler = (index) => {
    this.setState({ currentTabIndex: index, deletingUuid: null, editingMasterData: null, parentNode: null });
  };

  render() {

    return (
      <div className={styles.DocMasterDatas}>
        <SideDrawer title="Update Master Data" open={this.state.isDrawerOpened} closed={this.drawerToggleHandler}>
          <DocMasterDataDrawer
            docType={this.props.noteTypes ? this.props.noteTypes[this.state.currentTabIndex] : ''}
            closed={this.drawerToggleHandler}
            isAdding={this.state.isAdding}
            parentNode={this.state.parentNode}
            editingMasterData={this.state.editingMasterData}
            toggleDeleteModal={this.onDeleteDocMasterDataHandler}
          />
        </SideDrawer>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {/* <div className={styles.Menu}><span className={styles.MenuTitle}>Master Data</span></div> */}
            {
              this.props.noteTypes && this.props.noteTypes.map((nt, index) => (
                <div key={`noteTypes-list-${index}`} className={index === this.state.currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.changeTabTypeHandler(index)}>
                  <span className={styles.MenuTitle}>{nt.name}</span>
                </div>
              ))
            }
          </div>
          <div className={styles.AddButtonWrapper}>
            {
              this.props.noteTypes && this.props.noteTypes.length
                ? <Button type="button" icon={PlusCircleIcon} color="primary" name="Add Master Data" noMargin click={this.openDrawerForAddingHandler} />
                : null
            }
          </div>
        </div>

        <div className="MainContentPadding">
          {/* {
            this.props.loading ?
              <div>Loading...</div>
              : */}
          {
            this.props.noteTypes && !this.props.noteTypes.length
              ? <div style={{ fontWeight: '500', fontSize: 14 }}>Please add some document type.</div>
              : null
          }
          <div className={styles.MasterDataWrapper}>
            {
              this.state.currentDocMasterDatas && this.state.currentDocMasterDatas.length
                ? this.state.currentDocMasterDatas.map((bc, i) => !bc.parent_uuid && (
                  <React.Fragment key={bc.uuid}>
                    <RecursiveComponent
                      key={bc.code}
                      indexes={[i]}
                      node={bc}
                      level={0}
                      selectElement={this.onSelectMasterDataHandler}
                      openingIndexes={this.state.openingIndexes}
                      parentNode={this.state.parentNode}
                      openChilds={this.onOpenChildsHandler}
                      editMasterData={this.onEditMasterDataHandler}
                    />
                    <div style={{ height: 10, width: '100%' }} />
                  </React.Fragment>
                ))
                : null
            }
          </div>
          {/* } */}

        </div>
        <DeleteModal
          title="Master Data"
          open={this.state.isDeleteModalOpened}
          modalClosed={() => this.setState({ isDeleteModalOpened: false })}
          confirmDelete={this.onConfirmDeleteDocMasterData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { docMasterDatas, updatedDocMasterData, addedDocMasterData, loading, result, type } = state.docMasterData;
  const { noteTypes } = state.document;

  return {
    currentOrg, docMasterDatas, updatedDocMasterData, addedDocMasterData, loading, result, type, noteTypes
  };
};

export default connect(mapStateToProps, { getDocMasterDatas, deleteDocMasterData, getNoteTypes, clearNoteTypes })(DocumentMasterData);
