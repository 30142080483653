import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import styles from './NavigationItems.module.scss';
import PagesIcon from '../../../../assets/icons/navigation-items/pages.svg';
import FormulaIcon from '../../../../assets/icons/navigation-items/star.svg';
import PeopleIcon from '../../../../assets/icons/navigation-items/people.png';
import MembersIcon from '../../../../assets/icons/navigation-items/members.svg';
import ReportIcon from '../../../../assets/icons/navigation-items/report.svg';
import RuleIcon from '../../../../assets/icons/navigation-items/rule.svg';
import ProductionIcon from '../../../../assets/icons/navigation-items/production.svg';
import LinkIcon from '../../../../assets/icons/navigation-items/link.svg';
import MachineIcon from '../../../../assets/icons/navigation-items/machine.svg';
import OpenSubMenuIcon from '../../../../assets/icons/navigation-items/menu-down.svg';
import DevicesIcon from '../../../../assets/icons/navigation-items/devices.svg';
import subMenuGrayIcon from '../../../../assets/icons/navigation-items/submenu-gray.svg';
import subMenuBlueIcon from '../../../../assets/icons/navigation-items/submenu-blue.svg';
import DocumentIcon from '../../../../assets/icons/navigation-items/document.png';
import NotificationIcon from '../../../../assets/icons/navigation-items/notification-white.png';
import BreakdownIcon from '../../../../assets/icons/navigation-items/breakdown.png';
import AnomalyIcon from '../../../../assets/icons/navigation-items/anomaly.png';
import EnergyIcon from '../../../../assets/icons/navigation-items/energy.png';

class navigationItems extends Component {
  state = {
    navItems: [],
    openSubMenuIndex: null,
    menuActive: false
  };

  componentDidMount() {
    this.setupNavItems();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.orgs, this.props.orgs)) {
      this.setupNavItems();
    }
    if (prevProps.route.location.pathname !== this.props.route.location.pathname && this.props.route.location.pathname) {
      this.checkCurrentNav(this.props.route.location.pathname);
    }
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.setupNavItems();
    }
  }

  setupNavItems = () => {
    const { currentOrg, orgs, loggedInUser, isAdminMode } = this.props;

    let foundedOrg = (orgs?.length > 0) ? orgs.filter(o => o.id === +currentOrg)[0] : null;
    const isOrgAdmin = foundedOrg && loggedInUser?.admin_of_org && loggedInUser?.admin_of_org.includes(foundedOrg.id);
    const isAdmin = loggedInUser?.is_superuser;

    // Order of side bar
    // - Pages
    // - Report
    // - Rule (Org admin or super user)
    // - Device 
    // - Team
    // - Production (Need module enable)
    // - Member (Org admin or super user)
    // - Team

    let navItems = [];
    if (isAdminMode && isAdmin) {
      navItems = [
        { name: "Devices", icon: DevicesIcon, link: '/admin/device' },
        { name: "Notification", icon: NotificationIcon, link: '/admin/notification' },
      ];
    } else {
      navItems = [
        { name: "Pages", link: "/pages", icon: PagesIcon },
        { name: "Report", link: "/report", icon: ReportIcon },
        // { name: "Devices", link: "/devices", icon: DevicesIcon },
        { name: "Team", link: "/team", icon: PeopleIcon },
        {
          name: "EDGE", icon: MachineIcon, subMenu: [
            { name: "Devices", link: "/devices" },
            { name: "Virtual Device", link: "/virtual-device" },
            { name: "Machine", link: "/machine" },
            { name: "Grouping", link: "/device-grouping" },
          ]
        },
        { name: "Formula", link: "/formula-builder", icon: FormulaIcon }
      ];
      let ruleIndex = 2;
      if (foundedOrg && foundedOrg.modules?.includes(3)) {
        navItems.splice(ruleIndex, 0, {
          name: "PCS", icon: ProductionIcon, subMenu: [
            { name: "Dashboard", link: "/pcs" },
            { name: "Timeline", link: "/pcs-timeline" },
            { name: "Batch", link: "/batch" },
            { name: "Structure", link: "/structure" },
            { name: "Plans", link: "/production-plans" },
            { name: "Actual", link: "/actual-production" },
            { name: "Breakdown Log", link: "/breakdown-log" },
            { name: "Scheduler", link: "/scheduler" },
            { name: "Settings", link: "/pcs-settings" },
          ]
        });
        ruleIndex++;
        this.props.gotPcsAccess(true);
      } else {
        this.props.gotPcsAccess(false);
      }

      if (foundedOrg && foundedOrg.modules?.includes(7)) {
        navItems.splice(ruleIndex, 0, { name: "Energy", link: "/energy", icon: EnergyIcon });
        ruleIndex++;
      }

      if (foundedOrg && foundedOrg.modules?.includes(1)) {
        // for GS
        navItems.splice(ruleIndex, 0, { name: "Production", link: "/production", icon: ProductionIcon });
        ruleIndex++;
      }

      if (foundedOrg && foundedOrg.modules?.includes(6)) {
        navItems.splice(ruleIndex, 0, { name: "Anomaly", link: "/anomaly-detection", icon: AnomalyIcon });
        ruleIndex++;
      }

      if (isOrgAdmin || isAdmin) {
        navItems.splice(ruleIndex, 0, { name: "Event", link: "/event", icon: RuleIcon });
        navItems.splice(ruleIndex + 1, 0, { name: "Hook", link: "/hook", icon: LinkIcon });
        navItems.splice(ruleIndex + 2, 0, { name: "Members", link: "/members", icon: MembersIcon });
        navItems.splice(ruleIndex + 5, 0, { name: "Notification", link: "/notification", icon: NotificationIcon });
      }
      if (foundedOrg && foundedOrg.modules?.includes(4)) {
        // navItems.splice(ruleIndex, 0, { name: "Breakdown Code", link: "/breakdown-code", icon: BreakdownIcon });
        ruleIndex++;
      }
      if (foundedOrg && foundedOrg.modules?.includes(5)) {
        navItems.splice(ruleIndex, 0, {
          name: "Document", icon: DocumentIcon, subMenu: [
            { name: "List", link: "/documents" },
            { name: "Type", link: "/document-type" },
            { name: "Master Data", link: "/master-data" }
          ]
        });
        ruleIndex++;
      }
    }
    this.checkCurrentNav(this.props.route.location.pathname, navItems);
    // this.checkLocationIsValid(this.props.route.location.pathname, navItems)
    this.setState({ navItems });
  }

  // checkLocationIsValid = (whereNow, navItems) => {
  //   let stay = false;
  //   navItems.forEach(ni => {
  //     if (ni.link && whereNow === ni.link) stay = true;

  //     if (ni.subMenu) {
  //       ni.subMenu.forEach(sub => {
  //         if (sub.link && whereNow === sub.link) stay = true;
  //       });
  //     }
  //   });

  //   if (!stay) this.props.route.history.push('/pages');
  // }

  openSubMenuHandler = (index) => {
    let open = index;
    if (this.state.openSubMenuIndex === index) open = null;
    this.setState({ openSubMenuIndex: open });
  }

  checkCurrentNav = (pathname, savingNavItems) => {
    const navItems = savingNavItems ? savingNavItems : this.state.navItems;
    const foundIndex = navItems.findIndex(selected => selected.subMenu && selected.subMenu.find(sm => sm.link === pathname));
    this.setState({
      openSubMenuIndex: foundIndex === -1 ? null : foundIndex,
      menuActive: foundIndex === -1 ? false : true,
    });
  }

  render() {
    const { navItems, openSubMenuIndex, menuActive } = this.state;

    return (
      <ul className={styles.Nav}>
        {
          navItems.map((item, i) =>
            <li key={item.name}>
              {
                item.subMenu
                  ? <div className={`${styles.NavItem} ${i === openSubMenuIndex && menuActive ? styles.Active : ''}`} style={{ flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }} onClick={() => this.openSubMenuHandler(i)}>
                      <div className={styles.LinkBorder}></div>
                      <div className={`${styles.NavLink} ${styles.ActiveLink} `}>
                        <img src={item.icon} alt={item.name} className={styles.MenuIcon} />
                        <span
                          className={`${styles.MenuTitle} ${window.innerWidth <= 1024 ? styles.MenuTitleMobile : ''}`}>
                          {item.name}
                        </span>
                        <img
                          src={OpenSubMenuIcon}
                          alt="open submenu"
                          className={`${styles.SubMenuIcon} ${i === openSubMenuIndex ? (menuActive ? styles.OpenActive : styles.Open) : styles.Close}`}
                        />
                      </div>
                    </div>
                    <div className={`${styles.SubMenuWrapper} ${i === openSubMenuIndex ? styles.Open : styles.Close}`}>
                      {
                        item.subMenu.map((subm, j) => {
                          if (j !== item.subMenu.length - 1) {
                            return (
                              <div key={`submenu-${subm.name}`}>
                                <NavLink to={subm.link} className={styles.NavSubMenu} activeClassName={styles.Active}>
                                  <div className={styles.SubMenuLabel}>
                                    <img src={this.props.route.location.pathname === subm.link ? subMenuBlueIcon : subMenuGrayIcon} style={{ width: 10, height: 10, marginRight: 10 }} alt="submenu icon" />
                                    {subm.name}
                                  </div>
                                </NavLink>
                                <div className={styles.SubMenuDivider} />
                              </div>
                            )
                          }
                          return <NavLink key={`submenu-${subm.name}`} className={styles.NavSubMenu} to={subm.link} activeClassName={styles.Active}>
                            <div className={styles.SubMenuLabel}>
                              <img src={this.props.route.location.pathname === subm.link ? subMenuBlueIcon : subMenuGrayIcon} style={{ width: 10, height: 10, marginRight: 10 }} alt="submenu icon" />
                              {subm.name}
                            </div>
                          </NavLink>;
                        })
                      }
                    </div>
                  </div>
                  : <NavLink to={item.link} className={styles.NavItem} activeClassName={styles.Active}>
                    <div className={styles.LinkBorder}></div>
                    <div className={`${styles.NavLink} ${styles.ActiveLink} `}>
                      <img src={item.icon} alt={item.name} className={styles.MenuIcon} />
                      <span
                        className={`${styles.MenuTitle} ${window.innerWidth <= 1024 ? styles.MenuTitleMobile : ''}`}>
                        {item.name}
                      </span>
                    </div>
                  </NavLink>
              }
            </li>
          )
        }
      </ul>
    );
  }
}
const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    currentOrg: state.org.currentOrg,
    orgs: state.org.orgs,
  };
};

export default connect(mapStateToProps, {})(navigationItems);