export const GET_USER_FAVORITES_START = 'GET_USER_FAVORITES_START';
export const GET_USER_FAVORITES = 'GET_USER_FAVORITES';
export const GET_USER_FAVORITES_SUCCESS = 'GET_USER_FAVORITES_SUCCESS';
export const GET_USER_FAVORITES_FAIL = 'GET_USER_FAVORITES_FAIL';

export const ADD_USER_FAVORITE_START = 'ADD_USER_FAVORITE_START';
export const ADD_USER_FAVORITE = 'ADD_USER_FAVORITE';
export const ADD_USER_FAVORITE_SUCCESS = 'ADD_USER_FAVORITE_SUCCESS';
export const ADD_USER_FAVORITE_FAIL = 'ADD_USER_FAVORITE_FAIL';

export const DELETE_USER_FAVORITE_START = 'DELETE_USER_FAVORITE_START';
export const DELETE_USER_FAVORITE = 'DELETE_USER_FAVORITE';
export const DELETE_USER_FAVORITE_SUCCESS = 'DELETE_USER_FAVORITE_SUCCESS';
export const DELETE_USER_FAVORITE_FAIL = 'DELETE_USER_FAVORITE_FAIL';