import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import EditIcon from '../../../assets/icons/edit.svg';
import DeleteIcon from '../../../assets/icons/delete-red-bin.svg';

import styles from './TimelineBar.module.scss';
import { getDatasetCal, getDataCal, getDataEval } from '../../../state/ducks/Data/actions';
import { getTimelineStatus, clearTimelineStatuses } from '../../../state/ducks/TimelineStatus/actions';

// const BAR_COUNT_H = 24;
const BAR_COUNT_M = 1440;
// const BAR_COUNT_5M = 288;

const ONE_MIN_IN_MS = 60000;
const ONE_DAY_IN_MS = 86400000;

// for GreenSpot
const GS_AVA_DENOMINATOR = ['slow speed', 'std speed'];
const GS_NOPLAN_REASON = 'No Plan/Production'.toLowerCase();

class TimelineBar extends Component {
  state = {
    isLoading: true,
    startQueryTime: 0,
    stopQueryTime: 0,
    currentvalue: 0,
    realtime: false,
    currentReason: '',
    bars: [],
    isNoData: false,
    oee: { availability: 0, performance: 0, quality: 0 },
    isNoPlanNow: false,
    avaDenominator: [],
  }

  componentDidMount() {
    this.props.clearTimelineStatuses();
    this.loadDataSet();
    if (!this.props.noInterval) {
      this.loadDataSetInterval = setInterval(() => {
        this.loadDataSet();
      }, 60 * 1000);
    }

    const avaDenominator = [];
    let status_rules;
    if (this.props.timeline && this.props.timeline.machine)
      status_rules = this.props.timeline.machine.status_rules;
    else if (this.props.machine)
      status_rules = this.props.machine.status_rules;
    GS_AVA_DENOMINATOR.forEach(denText => {
      const foundSpeedValue = Object.keys(status_rules).find(key => status_rules[key].reason.toLowerCase() === denText);
      avaDenominator.push(+foundSpeedValue);
    });
    this.setState({ avaDenominator });
  }

  componentWillUnmount() {
    if (!this.props.noInterval) {
      clearInterval(this.loadDataSetInterval);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { timeline, machine, timelineStatuses } = this.props;
    if (timelineStatuses && !_.isEqual(timelineStatuses, prevProps.timelineStatuses)) {
      if (timelineStatuses.data && timeline && timelineStatuses.properties.timeline_id === timeline.id) {
        // console.log(timeline, " #### ", this.props.timelineStatuses)
        this.checkLastStatus(timeline && timeline.machine ? timeline.machine.status_rules : machine.status_rules, timelineStatuses)
        this.createBar(timelineStatuses);
        this.setState({ isLoading: false, savedTimelineStatus: timelineStatuses });
      } else {
        this.setState({ isLoading: false });
      }
    } else if (!_.isEqual(prevProps.timeline, timeline) && prevProps.timeline && timeline && timelineStatuses && timelineStatuses.data && this.state.savedTimelineStatus.properties.timeline_id === timeline.id) {
      this.createBar(timelineStatuses);
    } else if (this.props.date && this.props.date !== prevProps.date) {
      this.setState({ isNoPlanNow: false });
      this.loadDataSet();
      if (!this.props.noInterval) {
        clearInterval(this.loadDataSetInterval);
        this.loadDataSetInterval = setInterval(() => {
          this.loadDataSet();
        }, 60 * 1000);
      }
    } else if (this.props.dataEval && prevProps.dataEval !== this.props.dataEval && timeline && this.props.dataEval.properties.tid === timeline.id && !this.state.isNoPlanNow) {
      this.setUpOEE(this.props.dataEval);
    }
  }

  checkLastStatus = (status_rules, timelineStatuses) => {
    if (timelineStatuses.data && timelineStatuses.data.length) {
      const noplanStatusValue = Object.keys(status_rules).find(key => status_rules[key].reason.toLowerCase() === GS_NOPLAN_REASON);
      const lastStop = timelineStatuses.data[timelineStatuses.data.length - 1].stop;
      if (timelineStatuses.data[timelineStatuses.data.length - 1].value === +noplanStatusValue && (lastStop === '0001-01-01T00:00:00Z' || moment(lastStop).valueOf() > moment().valueOf())) {
        const oee = { availability: 'N/A', performance: 'N/A', quality: 'N/A' };
        this.setState({ oee, isNoPlanNow: true });
      } else {
        this.calculateAvailability(timelineStatuses);
        this.setState({ isNoPlanNow: false });
      }
    }
  }

  setUpOEE = (dataEval) => {
    const { data, properties: { isAvailability, isPerformance, isQuality } } = dataEval;
    const oee = { ...this.state.oee };
    if (isAvailability) {
      oee.availability = data;
    } else if (isPerformance) {
      oee.performance = data;
    } else if (isQuality) {
      oee.quality = data;
    }
    this.setState({ oee });
  }

  calculateAvailability = (timelineStatuses) => {
    if (!this.props.timeline || !this.props.timeline.machine) return;
    const { machine: { status_rules }, day_starting_time } = this.props.timeline;
    const now = moment().valueOf();
    const onlyCountInRuntimeValue = [];
    for (let key in status_rules) {
      const sr = status_rules;
      if (sr[key].calInRunTime) onlyCountInRuntimeValue.push(+key);
    }

    let splittedRef = day_starting_time.split(":");
    let refTime = new Date(this.props.date).setHours(splittedRef[0], splittedRef[1], 0, 0);
    let sum = 0;
    let tempCanSum = 0;
    let speedTimeFrame = []; // case GS: finding slow, std speed of timelineStatuses
    timelineStatuses.data.forEach((status, i) => {
      const foundSpeedStatus = this.state.avaDenominator.find(den => den === status.value);
      if (foundSpeedStatus) {
        speedTimeFrame.push(status);
        sum += tempCanSum;
        tempCanSum = 0;
      }

      const foundCountIn = onlyCountInRuntimeValue.find(runtime => runtime === status.value);
      if (foundCountIn || foundCountIn === 0) {
        let startInRange = moment(status.start).valueOf();
        let stopInRange = moment(status.stop).valueOf();
        if (startInRange < refTime) {
          startInRange = refTime;
        }
        if (stopInRange < startInRange) {
          // only case for "in progress"
          if (now > this.state.stopQueryTime) stopInRange = this.state.stopQueryTime;
          else stopInRange = now;
        }

        if (speedTimeFrame.length > 0) {
          tempCanSum += (stopInRange - startInRange);
        }

        if (i === timelineStatuses.data.length - 1 && foundSpeedStatus) {
          sum += tempCanSum;
        }
      }
    });

    const endRequestDate = now > this.state.stopQueryTime ? this.state.stopQueryTime : now;
    let ava = sum * 100 / (endRequestDate - refTime);

    if (this.props.currentOrg === 25) {
      // 25 = Green Spot
      if (!speedTimeFrame.length) {
        ava = 0;
      } else {
        const lastStop = speedTimeFrame[speedTimeFrame.length - 1].stop === '0001-01-01T00:00:00Z' ? now : speedTimeFrame[speedTimeFrame.length - 1].stop;
        const avaDenominator = moment(lastStop).valueOf() - moment(speedTimeFrame[0].start).valueOf();
        ava = sum * 100 / avaDenominator;
      }
    }

    const oee = { ...this.state.oee };
    oee.availability = ava;
    this.setState({ oee });
  }

  createBar = (timelineStatuses) => {
    const status_rules = this.props.timeline && this.props.timeline.machine ? this.props.timeline.machine.status_rules : this.props.machine.status_rules;
    let bars = [];
    let currentReason = '';
    let isNoData = false;
    if (timelineStatuses && timelineStatuses.data && timelineStatuses.data.length) {
      timelineStatuses.data.forEach((tls, i) => {
        let baseBarClass = styles.Bar;
        let barStart = moment(tls.start).valueOf();
        let barStop = moment(tls.stop).valueOf() < moment(tls.start).valueOf() ? moment().valueOf() : moment(tls.stop).valueOf();
        barStart = barStart < this.state.startQueryTime ? this.state.startQueryTime : barStart;
        barStop = barStop > this.state.stopQueryTime ? this.state.stopQueryTime : barStop;

        if (i === 0 && moment(tls.start).valueOf() > this.state.startQueryTime) {
          bars.push(this.renderEachBar(i, this.state.startQueryTime, moment(tls.start).valueOf(), null, styles.BarFirst, timelineStatuses));
          baseBarClass = styles.Bar;
        }
        if (barStart === this.state.startQueryTime) {
          baseBarClass = styles.BarFirst;
        }
        if (barStop === this.state.stopQueryTime) {
          baseBarClass = styles.BarLast;
        }
        if (barStart === this.state.startQueryTime && barStop === this.state.stopQueryTime) {
          baseBarClass = styles.BarFirst + ' ' + styles.BarLast;
        }

        bars.push(this.renderEachBar(i, barStart, barStop, tls, baseBarClass, timelineStatuses));

        if (i === timelineStatuses.data.length - 1) {
          currentReason = status_rules[tls.value] ? status_rules[tls.value].reason : 'N/A';
        }
      });
    } else {
      // All empty
      isNoData = true;
      bars.push(this.renderEachBar(null, this.state.startQueryTime, this.state.stopQueryTime, 0, styles.BarFirst + ' ' + styles.BarLast, timelineStatuses));
    }

    this.setState({ bars, currentReason, isNoData });
  }

  loadDataSet = () => {
    const { timeline, getTimelineStatus, start, end, machine } = this.props;
    if (timeline && timeline.forOeeReport) {
      getTimelineStatus(timeline.id, start.getTime(), end.getTime(), machine.id);
      this.setState({ startQueryTime: start.getTime(), stopQueryTime: end.getTime(), realtime: false });
      return;
    }

    let realtime = this.state.realtime;
    let date = this.props.date;
    let today = new Date();
    if (date.toString() === 'Invalid Date') return;

    let splited = timeline.day_starting_time.split(":");
    let h = +splited[0];
    let m = +splited[1];
    if (today.getHours() < h || (today.getHours() === h && today.getMinutes() < m)) {
      today = new Date(today.getTime() - ONE_DAY_IN_MS);
    }

    if (!date || (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear())) {
      date = today;
      realtime = true;
    }
    let startQueryTime = date.setHours(h, m, 0, 0);
    let stopQueryTime = startQueryTime + ONE_DAY_IN_MS;
    getTimelineStatus(timeline.id, startQueryTime, stopQueryTime);

    const now = moment().valueOf();
    if (timeline.formula_oee) {
      const { p, q } = timeline.formula_oee;
      // this.props.getDataEval(a, { tid: timeline.id, isAvailability: true });
      if (p) {
        // {potarget(123,22/11/2020)} or {potarget(123,123456789)} , 123 = machine_id
        const potarget = 'potarget(' + timeline.machine_id + ',' + startQueryTime + ')';
        let fullPerformanceFormula = p.replace(":potarget", potarget);
        fullPerformanceFormula = fullPerformanceFormula.replace(/:start/gi, startQueryTime);
        fullPerformanceFormula = fullPerformanceFormula.replace(/:stop/gi, stopQueryTime);
        fullPerformanceFormula = fullPerformanceFormula.replace(/:now/gi, now);
        if (stopQueryTime < now) {
          fullPerformanceFormula = fullPerformanceFormula.replace(/:range/gi, stopQueryTime - startQueryTime);
        } else {
          fullPerformanceFormula = fullPerformanceFormula.replace(/:range/gi, now - startQueryTime);
        }
        this.props.getDataEval(fullPerformanceFormula, { tid: timeline.id, isPerformance: true });
      }

      if (q) this.props.getDataEval(q, { tid: timeline.id, isQuality: true });
    }

    this.setState({ startQueryTime, stopQueryTime, realtime });
  }

  handleBarClick = (timelineStatuses, timelineStatusRowIndex) => {
    if (!this.props.onBarClick) return;
    this.props.onBarClick(this.props.timeline.id, timelineStatuses, timelineStatusRowIndex);
  }

  renderEachBar = (index, starttime, endtime, timelineStatusRow, baseclass, timelineStatuses) => {
    const { timeline, machine } = this.props;
    const status_rules = timeline && timeline.machine ? timeline.machine.status_rules : machine.status_rules;
    let timedif = (endtime - starttime) / ONE_MIN_IN_MS;
    let barwidth = timedif / BAR_COUNT_M;
    if (this.props.renderTheExactTimeWidth) {
      const timeWidth = Math.ceil((this.props.end - this.props.start) / 60000);
      barwidth = timedif / timeWidth;
    }
    let divstyle = { width: 'calc(100% * ' + barwidth + ')' };

    // const now = new Date().setSeconds(0);
    // const diffnow = (now - this.state.startQueryTime) / ONE_MIN_IN_MS;
    // let barwidth = timedif / parseInt(diffnow);
    // let divstyle = { width: 'calc(100% * ' + barwidth + ')' };

    let rule;
    let className = baseclass;
    if (!timelineStatusRow) {
      rule = { color: '#222226' };
    } else if (status_rules[timelineStatusRow.value]) {
      rule = status_rules[timelineStatusRow.value];
    } else {
      rule = { color: '#9ea0a5' };
    }

    if (rule.color) {
      divstyle.backgroundColor = rule.color === '#023E8A' ? '#062A68' : rule.color;
      if (rule.flashing)
        className += ' ' + styles.BarFlashing;
    }

    if (timelineStatusRow) {
      return <div key={`Bar-${starttime}-${index}`} className={`${className} ${this.props.onBarClick ? styles.BarClickAble : ''}`} style={divstyle} onClick={() => this.handleBarClick(timelineStatuses, index)}></div>;
    } else {
      return <div key={`NoBar-${starttime}-${endtime}`} className={className} style={divstyle}></div>;
    }
  }

  render() {
    let { timeline, isEdit, start, end } = this.props;
    if (isEdit === null) isEdit = false;

    // Label
    let bar_label = [];

    const bar = (
      <div className={styles.TimelineBar} style={this.props.style ? this.props.style : {}}>
        {
          this.state.isLoading
            ? <div className={styles.TimelineLoading}>Loading...</div>
            : !this.state.isNoData
              ? <div className={styles.TimelineWrapper}>
                {this.state.bars}
              </div>
              : <div className={styles.TimelineWrapper}>
                <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>No Data</div>
                {this.state.bars}
              </div>
        }
        <div className={styles.TimelineLabel}>
          {bar_label}
        </div>
      </div>
    );

    if (timeline && timeline.forOeeReport) {
      let width = (end.getTime() - start.getTime()) / 12;
      let i = new Date(start.getTime());
      while (i <= end) {
        let d = moment(i).format('HH:mm');
        bar_label.push(<div key={'bar_label_' + i}>{d}</div>);
        i = new Date(i.getTime() + width);
      }
      return (
        <tr className={styles.TimelineRow}>
          <td>
            {bar}
          </td>
        </tr>
      );
    }

    let splited = timeline.day_starting_time.split(":");
    for (let i = 0; i < 13; i++) {
      let d = new Date().setHours((i * 2) + (+splited[0]), +splited[1], 0, 0);
      d = new Date(d);
      d = moment(d).format('HH:mm');
      bar_label.push(<div key={'bar_label_' + i}>{d}</div>);
    }

    const { availability, performance, quality } = this.state.oee;
    const { properties: { colorThreshold } } = timeline;

    const percentOee = this.state.isNoPlanNow ? 'N/A' : availability * performance * quality / 10000;
    const avaStyles = [styles.apq_cell, this.state.isNoPlanNow ? styles.unavailable : availability >= colorThreshold.a[1] ? styles.normal : availability >= colorThreshold.a[0] ? styles.warning : styles.danger];
    const perStyles = [styles.apq_cell, this.state.isNoPlanNow ? styles.unavailable : performance >= colorThreshold.p[1] ? styles.normal : performance >= colorThreshold.p[0] ? styles.warning : styles.danger];
    const quaStyles = [styles.apq_cell, this.state.isNoPlanNow ? styles.unavailable : quality >= colorThreshold.q[1] ? styles.normal : quality >= colorThreshold.q[0] ? styles.warning : styles.danger];
    const oeeStyles = [styles.oee_cell, this.state.isNoPlanNow ? styles.unavailable : percentOee >= colorThreshold.oee[1] ? styles.normal : percentOee >= colorThreshold.oee[0] ? styles.warning : styles.danger];
    return (
      <tr className={styles.TimelineRow}>
        <td style={{ fontWeight: 500, paddingBottom: 13 }}>{timeline.name}</td>
        <td>
          {bar}
        </td>
        <td><div className={styles.reason_cell}>{this.state.currentReason}</div></td>
        <td>
          <div className={avaStyles.join(' ')}>{this.state.isNoPlanNow ? 'N/A' : availability && availability !== 'N/A' ? availability.toFixed(2) : 0}</div>
        </td>
        <td>
          <div className={perStyles.join(' ')}>{this.state.isNoPlanNow ? 'N/A' : performance && performance !== 'N/A' ? performance.toFixed(2) : 0}</div>
        </td>
        <td>
          <div className={quaStyles.join(' ')}>{this.state.isNoPlanNow ? 'N/A' : quality && quality !== 'N/A' ? quality.toFixed(2) : 0}</div>
        </td>
        <td>
          <div className={oeeStyles.join(' ')}>{this.state.isNoPlanNow ? 'N/A' : percentOee && percentOee !== 'N/A' ? percentOee.toFixed(2) : 0}</div>
        </td>
        {isEdit && <td><img src={EditIcon} style={{ cursor: 'pointer' }} alt="edit" onClick={e => { this.props.onEdit(timeline) }} /></td>}
        {isEdit && <td><img src={DeleteIcon} style={{ cursor: 'pointer' }} alt="delete" onClick={e => { this.props.onDelete(timeline) }} /></td>}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { datasetCal, dataCal, dataEval, result } = state.data
  const { timelineStatuses } = state.timelineStatus;
  return { currentOrg, datasetCal, dataCal, dataEval, result, timelineStatuses }
}

export default connect(mapStateToProps, { getDatasetCal, getDataCal, getTimelineStatus, getDataEval, clearTimelineStatuses })(TimelineBar);