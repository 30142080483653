import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './AdvanceProductionPlans.module.scss';
import Button from '../../components/UI/Button/Button';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import UploadIcon from '../../../assets/icons/upload.svg';
import DownloadIcon from '../../../assets/icons/AssetTracking/cloud.svg';
import Backdrop from '../../components/UI/Backdrop/NoColorBackdrop';
import ArrowTemplateIcon from '../../../assets/icons/navigation-items/menu-down.svg';
import FullScreenIcon from '../../../assets/icons/full-screen.svg';
import NormalScreenIcon from '../../../assets/icons/normal-screen.svg';

import { getProductionPlanStructures } from '../../../state/ducks/AdvanceProductionPlanStructure/actions';
import { getAdvanceProductionPlans, uploadAdvanceProductionPlans, deleteAdvanceProductionPlan, clearUploadedPlans } from '../../../state/ducks/AdvanceProductionPlan/actions';
import { getMachines } from '../../../state/ducks/Machine/actions';
import PlansTable from '../../components/Production/AdvanceProductionPlans/PlansTable';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import PlanDrawer from '../../components/Production/AdvanceProductionPlans/PlanDrawer';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import SearchDrawer from '../../components/Operator/SearchDrawer/SearchDrawer';

import * as XLSX from 'xlsx';

class AdvanceProductionPlans extends Component {
  state = {
    currentTabIndex: 0,
    addStructureTrigger: 0,
    planList: [],
    isAdding: false,
    editingPlan: null,
    isDrawerOpened: false,
    isDeleteModalOpened: false,
    deletingPlanUuid: null,
    currentHeaders: [],
    isAdvancedSearch: false,
    excelCols: [],
    errorUpload: '',
    onSelectingTemplateMenu: false,
    isFullScreen: false
  };

  componentDidMount() {
    this.props.getProductionPlanStructures(this.props.currentOrg);
    this.props.getMachines(this.props.currentOrg);
    this.props.clearUploadedPlans();
    if (this.props.planStructures) {
      this.updateExcelCols();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentOrg !== prevProps.currentOrg && this.props.currentOrg) {
      this.props.getProductionPlanStructures(this.props.currentOrg);
      this.props.getMachines(this.props.currentOrg);
      this.setState({ currentTabIndex: 0 });
    } else if (this.props.planStructures && prevProps.planStructures !== this.props.planStructures) {
      this.updateExcelCols();
      this.props.clearUploadedPlans();
      this.setState({ planList: this.props.planStructures, errorUpload: '' });
    } else if ((this.state.currentTabIndex || this.state.currentTabIndex === 0) && this.state.currentTabIndex !== prevState.currentTabIndex) {
      this.updateExcelCols();
    }
  }

  updateExcelCols = () => {
    const { currentTabIndex } = this.state;
    const { planStructures } = this.props;
    let structure = planStructures[currentTabIndex];
    if (structure) {
      let now = moment(new Date());
      let cols = [
        { name: "PO", value: 'PO12345678', order: structure.po_column_order },
        { name: "Start", value: now.format("DD/MM/YYYY HH:mm:ss"), order: structure.start_column_order },
        { name: "End", value: now.add(1, 'hours').format("DD/MM/YYYY HH:mm:ss"), order: structure.end_column_order },
        { name: "Target", value: 10000, order: structure.target_column_order },
        { name: "Machine", value: 'Machine A', order: structure.machine_column_order },
        ...structure.columns.map(col => { return { name: col.display_name, order: col.order } })
      ];
      this.setState({ excelCols: cols.sort((a, b) => a.order - b.order), errorUpload: '' });
    }
  }

  openDrawerForAddingHandler = () => {
    this.setState({ isAdding: true, editingPlan: null, isDrawerOpened: true });
  }

  drawerToggleHandler = (which) => {
    this.setState(prevState => {
      return { isDrawerOpened: !prevState.isDrawerOpened, isAdvancedSearch: which === 'search' }
    });
  }

  onEditPlanHandler = (plan) => {
    this.setState({ editingPlan: plan, isDrawerOpened: true, isAdding: false });
  }

  onDeletePlanHandler = () => {
    this.setState({ deletingPlanUuid: this.state.editingPlan.uuid, isDeleteModalOpened: true });
  }

  onConfirmDeletePlan = (event) => {
    event.preventDefault();
    this.props.deleteAdvanceProductionPlan(this.state.deletingPlanUuid);
    this.setState({ deletingPlanUuid: null, isDeleteModalOpened: false, isDrawerOpened: false, editingPlan: null });
  }

  onSearchPlansHandler = (e, searchTexts) => {
    e.preventDefault();
    const others = {};
    Object.keys(searchTexts).forEach(key => {
      if (key !== 'po' && key !== 'start' && key !== 'end' && key !== 'target' && key !== 'machine' && searchTexts[key] !== '') {
        others[key] = searchTexts[key];
      }
    });
    this.props.getAdvanceProductionPlans(
      this.props.currentOrg,
      this.props.planStructures[this.state.currentTabIndex].uuid,
      moment(searchTexts.start),
      moment(searchTexts.end).endOf('day').valueOf(),
      false,
      searchTexts.po ? searchTexts.po : '',
      searchTexts.target ? searchTexts.target : '',
      searchTexts.machine ? searchTexts.machine.id : '',
      others
    );
    this.drawerToggleHandler();
  }

  s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }

    return buf;
  }

  onDownloadPlansHandler = () => {
    console.log(this.props.advanceProductionPlans, this.state.currentHeaders);
    let data = [[], []];

    this.state.currentHeaders.forEach((header, i) => {
      data[0].push(header.display_name);
    });
    this.props.advanceProductionPlans.forEach((app, i) => {
      if (!data[i + 1]) data[i + 1] = [];
      this.state.currentHeaders.forEach(header => {
        const col = header.name;
        if (app[col]) {
          if (col === 'machine') data[i + 1].push(app.machine.name)
          else if (col === 'start' || col === 'end') data[i + 1].push(moment(app[col]).format('DD/MM/YYYY HH:mm'));
          else data[i + 1].push(app[col]);
        } else {
          data[i + 1].push(app.columns[col]);
        }
      
      });
    });
    /* convert from array of arrays to workbook */
    let wb = XLSX.utils.book_new();
    // let ws = XLSX.utils.json_to_sheet(dd);
    let ws = XLSX.utils.aoa_to_sheet(data);

    wb.SheetNames.push('');
    wb.Sheets[''] = ws;

    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

    let url = window.URL.createObjectURL(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }));

    let a = document.createElement('a');
    a.href = url;
    a.download = 'Plans.xlsx';
    a.click();

    window.URL.revokeObjectURL(url);
  }

  exportExcelTemplateHandler = () => {
    const { currentTabIndex, excelCols } = this.state;
    const { planStructures } = this.props;
    const structure = planStructures[currentTabIndex];

    // let data = {};
    // excelCols.forEach(col => {
    //   data[col.name] = col.value ? col.value : '';
    // });

    let data = [[], []];
    excelCols.forEach(col => {
      data[0].push(col.name);
      data[1].push(col.value ? col.value : '');
    });

    /* convert from array of arrays to workbook */
    let wb = XLSX.utils.book_new();
    // let ws = XLSX.utils.json_to_sheet(dd);
    let ws = XLSX.utils.aoa_to_sheet(data);

    wb.SheetNames.push('');
    wb.Sheets[''] = ws;

    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

    let url = window.URL.createObjectURL(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }));

    let a = document.createElement('a');
    a.href = url;
    a.download = structure.name.toLowerCase() + '_template.xlsx';
    a.click();

    window.URL.revokeObjectURL(url);
    this.setState({ onSelectingTemplateMenu: false });
  }

  uploadExcelHandler = (e) => {
    e.preventDefault();
    const { currentTabIndex } = this.state;
    const { planStructures, machines, uploadAdvanceProductionPlans } = this.props;
    const structure = planStructures[currentTabIndex];

    let uploadData = [];
    let f = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (evt) => {
      let data = evt.target.result;

      let wb = XLSX.read(data, { type: 'binary', cellDates:true });
      let sheet = wb.Sheets[wb.SheetNames[0]];

      data = XLSX.utils.sheet_to_json(sheet);
      let errorMessage = [];
      // Verify data
      data.forEach((row, i) => {
        errorMessage[i] = '';
        if (!row.PO) {
          errorMessage[i] += ' Missing PO.';
        }
        if (isNaN(parseInt(row.Target))) {
          errorMessage[i] += ' Missing target.';
        }
        if (!row.Machine) {
          errorMessage[i] += ' Missing Machine';
        } else if (!machines.find(m => m.name === row.Machine)) {
          errorMessage[i] += ' Machine \"' + row.Machine + '\" is not found.';
        }
        let start = typeof(row.Start) !== 'object' ? moment(row.Start, 'DD/MM/YYYY HH:mm:ss') : moment(row.Start);
        let end = typeof(row.end) !== 'object' ? moment(row.End, 'DD/MM/YYYY HH:mm:ss') : moment(row.End);
        if (!start.isValid()) {
          errorMessage[i] += ' Start data is invalid (make sure data format = Text).';
        } else if (!end.isValid()) {
          errorMessage[i] += ' End data is invalid (make sure data format = Text).';
        } else if (!end.isAfter(start)) {
          errorMessage[i] += ' Start can not be after End (' + row.Start + ' - ' + row.End + ').';
        }
        if (errorMessage[i] !== '') errorMessage[i] = `Row ${i + 1}  : ` + errorMessage[i];
      });
      errorMessage = errorMessage.join("\r\n").trim();
      this.setState({ errorUpload: errorMessage });

      // Create plan
      if (errorMessage === '') {
        data.forEach(row => {
          let start = typeof(row.Start) !== 'object' ? moment(row.Start, 'DD/MM/YYYY HH:mm:ss') : moment(row.Start);
          let end = typeof(row.end) !== 'object' ? moment(row.End, 'DD/MM/YYYY HH:mm:ss') : moment(row.End);
          let machine = row.Machine ? machines.find(m => m.name === row.Machine) : undefined;
          let customCols = {};
          structure.columns.forEach(col => {
            customCols[col.name] = row[col.display_name] ? '' + row[col.display_name] : '';
          });
          uploadData.push({
            structure_uuid: structure.uuid,
            po: "" + row.PO,
            target: +row.Target,
            start: start.valueOf(),
            end: end.valueOf(),
            machine_id: machine ? machine.id : 0,
            columns: customCols
          });
        });
        uploadAdvanceProductionPlans(uploadData);
      }
    };
    reader.readAsArrayBuffer(f);
    this.setState({ onSelectingTemplateMenu: false });
  }

  changeTabStructureHandler = (index) => {
    this.props.clearUploadedPlans();
    this.setState({ currentTabIndex: index });
  };

  toggleFullScreen = () => {
    this.setState(prevState => {
      return { isFullScreen: !prevState.isFullScreen }
    });
  }

  render() {
    const { currentTabIndex, planList } = this.state;
    let ScreenStyle = styles.MinimizeScreen;
    if (this.state.isFullScreen) {
      ScreenStyle = styles.FullScreen;
    }

    return (
      <div className={`${styles.AdvanceProductionPlans} ${ScreenStyle}`}>
        <SideDrawer
          title={
            this.state.isAdvancedSearch
              ? 'Advanced Search Plans'
              : this.state.isAdding ? 'Add New Plan' : 'Edit Plan'
          }
          open={this.state.isDrawerOpened}
          closed={this.drawerToggleHandler}>
          {
            this.state.isAdvancedSearch
              ? <SearchDrawer columns={this.state.currentHeaders} searchPlans={this.onSearchPlansHandler} closed={this.drawerToggleHandler} />
              : <PlanDrawer
                isDrawerOpened={this.state.isDrawerOpened}
                closed={this.drawerToggleHandler}
                editingPlan={this.state.editingPlan}
                isAdding={this.state.isAdding}
                toggleDeleteModal={this.onDeletePlanHandler}
                structure={planList[currentTabIndex]}
                route={this.props.route}
              />
          }
        </SideDrawer>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              planList.map((pl, index) => (
                <div key={`plan-list-${index}`} className={index === currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.changeTabStructureHandler(index)}>
                  <span className={styles.MenuTitle}>{pl.name}</span>
                </div>
              ))
            }
          </div>
          {
            this.props.planStructures && this.props.planStructures.length ?
              <>
                <div className={styles.DropdownWrapper}>
                  <div className={styles.AddButtonWrapper}>
                    <Button type="button" color="green" name="Template" noMargin click={() => this.setState({ onSelectingTemplateMenu: true })} />
                    <div className={`${styles.Arrow} ${this.state.onSelectingTemplateMenu ? styles.Up : ''}`}></div>
                    <img src={ArrowTemplateIcon} alt="ArrowTemplateIcon" className={`${styles.Arrow} ${this.state.onSelectingTemplateMenu ? styles.Up : ''}`}
                    />
                  </div>
                  <Backdrop show={this.state.onSelectingTemplateMenu} clicked={() => this.setState({ onSelectingTemplateMenu: false })} />
                  {
                    this.state.onSelectingTemplateMenu ?
                      <div className={styles.DropdownMenu}>
                        <div className={styles.List} onClick={this.exportExcelTemplateHandler}>
                          <div className={styles.Wrapper}>
                            <img src={DownloadIcon} alt="Download Icon" className={styles.Icon} />
                            <label className={styles.Label}>Download</label>
                          </div>
                        </div>
                        <div className={styles.List} onClick={(e) => {
                          e.preventDefault();
                          let i = document.createElement('input');
                          i.type = 'file';
                          i.onchange = this.uploadExcelHandler;
                          i.click();
                        }}>
                          <div className={styles.Wrapper}>
                            <img src={UploadIcon} alt="Upload Icon" className={styles.Icon} />
                            <label className={styles.Label}>Upload</label>
                          </div>
                        </div>
                      </div> : null
                  }
                </div>
                <div className={styles.AddButtonWrapper}>
                  <Button type="button" color="borderblue" name="Download Plans" noMargin click={this.onDownloadPlansHandler} />
                </div>
                <div className={styles.AddButtonWrapper}>
                  <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Plan" noMargin click={this.openDrawerForAddingHandler} />
                </div>
              </>
              : null
          }
          <div className={styles.ScreenButton}>
            {
              this.state.isFullScreen ?
                <img src={NormalScreenIcon} alt="Normal Screen Icon" onClick={this.toggleFullScreen} /> :
                <div className={styles.ScreenWrapper} onClick={this.toggleFullScreen} >
                  <img src={FullScreenIcon} alt="Full Screen Icon" />
                </div>
            }
          </div>
        </div>
        <div className="MainContentPadding">
          {this.state.errorUpload
            ? <div className="ErrorText" style={{ whiteSpace: 'pre' }}>{this.state.errorUpload}</div>
            : this.props.error
              ? <div className="ErrorText">Something went wrong! Please contact admin.</div>
              : ''
          }
          {this.props.uploadedPlans && this.props.uploadedPlans.length ? <div className={styles.SuccessText}>{this.props.uploadedPlans.length} plan(s) has been uploaded successfully.</div> : ''}
          {
            this.props.planStructures && this.props.planStructures.length === 0
              ? <div style={{ fontWeight: '500', marginLeft: 20 }}>Please add a structure before adding a new plan.</div>
              : null
          }
          {
            planList.length
              ? <PlansTable
                structure={planList[currentTabIndex]}
                editPlan={this.onEditPlanHandler}
                openAdvancedSearchDrawer={() => this.drawerToggleHandler('search')}
                setHeaders={(headers) => this.setState({ currentHeaders: headers })}
              />
              : null
          }
        </div>

        <DeleteModal
          title="production plan"
          open={this.state.isDeleteModalOpened}
          modalClosed={() => this.setState({ isDeleteModalOpened: false })}
          confirmDelete={this.onConfirmDeletePlan}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { planStructures } = state.advanceProductionPlanStructure;
  const { error, uploadedPlans, advanceProductionPlans } = state.advanceProductionPlan;
  const { machines } = state.machine;
  return { currentOrg, planStructures, machines, uploadedPlans, error, advanceProductionPlans };
};

export default connect(mapStateToProps, { getProductionPlanStructures, getMachines, uploadAdvanceProductionPlans, deleteAdvanceProductionPlan, getAdvanceProductionPlans, clearUploadedPlans })(AdvanceProductionPlans);
