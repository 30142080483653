import React, { Component } from 'react';

import styles from '../SystemDeviceConfig.module.scss';
import AddDeviceStyles from '../../AddDeviceForm/AddDeviceForm.module.scss';
import AddIcon from '../../../../../assets/icons/plus-circle-blue.svg';
import AnalogForm from './AnalogForm';
import ModbusForm from './ModbusForm';
import { DEFAULT_ANALOG_OPTIONS, DEFAULT_MODBUS_FORM } from '../variable';

import DeleteIcon from '../../../../../assets/icons/delete-red-bin.svg';
import { MODBUS_LENGTH_OPTIONS, MODBUS_ORDER_OPTIONS, MODBUS_VARIABLE_OPTIONS, MODBUS_ACTION_TYPES } from '../variable';

class SensorSettings extends Component {
  state = {
    canSaveConfig: true,
    analogs: [],
    // hasModbus: false,
    // modbusFromDeviceConfigs: [],
    modbusResult: null,
    owlMessage: null,
    analogOptions: DEFAULT_ANALOG_OPTIONS,
    currentMenu: 0,
    allModbusForm: [],
    freeSocket: 21,
    modbusIndexInDeviceSocketForm: 0
  }

  componentDidMount() {
    if (this.props.isSystemDevice) {
      this.props.setWentToTabInput();
      if (this.props.editingDevice) {
        this.setUpAnalogSocketsAndOptions();
      }
    }
  }

  setUpAnalogSocketsAndOptions = () => {
    if (this.props.editingDevice.firmware_version.toLowerCase().startsWith('f')) {
      // Fuso version, can only select timestamp
      const onlyTimestamp = [{ name: 'Timestamp (Rising-Falling)', id: 'TIMESTAMP' }];
      this.setState({ analogOptions: onlyTimestamp });
      this.setUpSensorSettings(onlyTimestamp);
    } else {
      // CTO Said: 23/05/2022 -> all model -> all options except fuso
      const filteredOptions = [...DEFAULT_ANALOG_OPTIONS];
      this.setUpSensorSettings(filteredOptions);
      this.setState({ analogOptions: filteredOptions });
      // if (this.props.editingDevice.model.toLowerCase().endsWith('sd')) {
      //   const filteredOptions = DEFAULT_ANALOG_OPTIONS.slice(2);
      //   if (!this.props.newFirmwareVersion) filteredOptions.splice(3, 1);
      //   this.setState({ analogOptions: filteredOptions });
      //   this.setUpSensorSettings(this.props.analogSockets, filteredOptions);
      // } else {
      //   const filteredOptions = [...DEFAULT_ANALOG_OPTIONS];
      //   filteredOptions.splice(5, 1);
      //   this.setUpSensorSettings(this.props.analogSockets, filteredOptions);
      //   this.setState({ analogOptions: filteredOptions });
      // }
    }
  }

  setUpSensorSettings = (options) => {
    const { currentConfigs } = this.props;
    let initAnalogs = [];
    const { analogOptions } = this.state;
    let alOptions = options ? options : analogOptions;
    if (currentConfigs && currentConfigs.analog_inputs && currentConfigs.analog_inputs.length) {
      currentConfigs.analog_inputs.forEach((analog) => {
        const index = options.length === 1 ? 0 : analog.type;
        initAnalogs[+analog.socket - 1] = {
          key: analog.socket,
          delay: analog.delay ? analog.delay : 0,
          ...alOptions[index]
        };
      });
    }

    let modbusFromDeviceConfigs = [];
    // let hasModbus = false;
    if (currentConfigs && currentConfigs.modbus_inputs && currentConfigs.modbus_inputs.length) {
      modbusFromDeviceConfigs = currentConfigs.modbus_inputs;
      // hasModbus = true;
    }

    this.setupModbus(modbusFromDeviceConfigs);

    if (this.props.wentToTabInput) {
      initAnalogs = [...this.props.analogList];
    }
    this.setState({ analogs: initAnalogs });
    this.props.setAnalogList(initAnalogs);
    // this.props.setHasModbus(hasModbus);
    // console.log('set here too setModbusList(modbusFromDeviceConfigs)', modbusFromDeviceConfigs)
    // this.props.setModbusList(modbusFromDeviceConfigs);
    // console.log('DONE setup analogs & modbus', initAnalogs, modbusFromDeviceConfigs)
  }

  // onChangeEnableHandler = (event) => {
  //   event.stopPropagation();
  //   this.props.setHasModbus(!this.state.hasModbus);
  //   this.setState(prevState => {
  //     return { hasModbus: !prevState.hasModbus }
  //   });
  // }

  onSetModbusHandler = (modbusList) => {
    let canSaveConfig = true;
    modbusList.forEach(mb => {
      canSaveConfig = canSaveConfig && mb.isValid;
    });

    this.setState({ canSaveConfig, modbusResult: modbusList });
    this.props.setModbusList(modbusList);
  }

  handleAnalogTypeChange = event => {
    const { name, value } = event.target;
    const { analogOptions } = this.state;
    const updatedAnalogSockets = [...this.props.deviceSocketForm];
    let analogIndex = updatedAnalogSockets.findIndex(a => a.id === +name);
    let updatedAnalogs = [...this.state.analogs];

    if (value !== '-1') {
      const hasTimestamp = updatedAnalogs.findIndex((ua, i) => ua && ua.id === 'TIMESTAMP' && +name - 1 !== i) >= 0;
      const optionIndex = analogOptions.findIndex(opt => opt.id === value);
      if (!hasTimestamp || (hasTimestamp && value === 'TIMESTAMP')) {
        updatedAnalogs[analogIndex] = {
          key: +updatedAnalogSockets[analogIndex].id,
          delay: updatedAnalogs[analogIndex] ? updatedAnalogs[analogIndex].delay : 0,
          ...analogOptions[optionIndex]
        };
      } else {
        updatedAnalogs[analogIndex] = {};
      }
      if (value === 'TIMESTAMP') {
        updatedAnalogs.forEach((ua, i) => {
          if (ua.id !== 'TIMESTAMP') {
            updatedAnalogs[i] = {};
          }
        });
      }
      updatedAnalogSockets[analogIndex].is_valid = updatedAnalogSockets[analogIndex].name !== '';
    } else {
      updatedAnalogs[analogIndex] = {};
      updatedAnalogSockets[analogIndex].is_valid = true;
    }
    this.setState({ analogs: updatedAnalogs });
    this.props.setAnalogList(updatedAnalogs);
    this.props.setDeviceSocketForm(updatedAnalogSockets);
  }

  handleDelayChange = event => {
    const { name, value } = event.target;
    let updatedAnalogs = [...this.state.analogs];
    let analogIndex = this.props.deviceSocketForm.findIndex(a => a.id === +name);
    updatedAnalogs[analogIndex].delay = +value;

    this.setState({ analogs: updatedAnalogs });
    this.props.setAnalogList(updatedAnalogs);
  }

  handleAnalogUpdate = socket => {
    const updatedDeviceSocketForm = [...this.props.deviceSocketForm];
    for (let i = 0; i < updatedDeviceSocketForm.length; i++) {
      if (socket.id === updatedDeviceSocketForm[i].id) {
        updatedDeviceSocketForm[i] = JSON.parse(JSON.stringify(socket));
        this.props.setDeviceSocketForm(updatedDeviceSocketForm);
        break;
      }
    }
  }

  setupModbus = (modbusFromDeviceConfigs) => {
    let updatedAllModbusForm = [];
    modbusFromDeviceConfigs
      .sort((a, b) => (a.socket > b.socket) ? 1 : ((b.socket > a.socket) ? -1 : 0))
      .forEach(modbus => {
        let updatedForm = { ...DEFAULT_MODBUS_FORM };
        Object.keys(updatedForm).forEach(key => {
          // if (modbus.hasOwnProperty(key) || key.startsWith('action')) {
          let value = modbus[key];
          if (key === 'length') {
            value = MODBUS_LENGTH_OPTIONS[modbus[key] - 1];
          } else if (key === 'order') {
            value = MODBUS_ORDER_OPTIONS[modbus[key]];
          } else if (key === 'dataType') {
            value = MODBUS_VARIABLE_OPTIONS[modbus.data_type];
          } else if (key === 'actionType') {
            value = MODBUS_ACTION_TYPES[modbus.action ? modbus.action.type : 0];
          } else if (key === 'actionFunction') {
            value = modbus.action ? modbus.action.function : '';
          } else if (key === 'actionRegister') {
            value = modbus.action ? modbus.action.reg : '';
          } else if (key === 'actionLength') {
            value = MODBUS_LENGTH_OPTIONS[modbus.action ? (modbus.action.length > 0 ? modbus.action.length - 1 : 0) : 0];
          } else if (key === 'actionValue') {
            value = modbus.action && modbus.action.value ? this._base64ToArrayBuffer(modbus.action.value, modbus.action.length) : '';
          } else if (key === 'pb_function') {
            value = modbus.function || modbus.function === 0 ? modbus.function : '';
          }

          let updatedElement = {
            value: value || value === 0 ? value : '',
            valid: true,
            touched: true,
            validation: updatedForm[key].validation
          };
          updatedForm[key] = updatedElement;
          // }
        });
        let ms = this.props.editingDevice.device_sockets.find(ds => ds.socket === +updatedForm.socket.value);
        let functions = '';
        if (ms && ms.functions) {
          ms.functions.forEach(func => {
            functions += func.operation + ' ' + func.value + ' ';
          });
          updatedForm.display_name = {
            value: ms.name,
            valid: true,
            touched: true,
            validation: updatedForm.display_name.validation
          };
          updatedForm.location = {
            value: ms.location ? ms.location : '',
            valid: true,
            touched: true,
            validation: updatedForm.location.validation
          };
          updatedForm.functions = {
            value: functions,
            valid: true,
            touched: true,
          };
          updatedForm.unit_name = {
            value: ms.unit_name ? ms.unit_name : '',
            valid: true,
            touched: true,
            validation: updatedForm.unit_name.validation
          };
          updatedForm.tag = {
            value: ms.tag ? ms.tag : '',
            valid: true,
            touched: true,
            validation: updatedForm.tag.validation
          };
        }

        updatedAllModbusForm.push({
          form: updatedForm,
          isValid: true,
          overTcp: updatedForm.ip.value !== '' ? true : false,
          hasModbusActions: updatedForm.actionFunction.value > 0 ? true : false
        });
      });

    if (this.props.wentToTabInput) {
      updatedAllModbusForm = [...this.props.modbusList];
    }
    this.setState({ allModbusForm: updatedAllModbusForm });
    this.onSetModbusHandler(updatedAllModbusForm);
  }

  _base64ToArrayBuffer = (base64, actionLength) => {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      if (actionLength === 1) {
        bytes[0] = 0;
        bytes[1] = binary_string.charCodeAt(0);
      } else {
        bytes[i] = binary_string.charCodeAt(i);
      }
    }
    let result = new DataView(bytes.buffer, 0);
    return result.getUint16(0, false);
  }

  handleModbusFormChange = (index, form, isValid, overTcp, hasModbusActions) => {
    let updatedForm = [...this.state.allModbusForm];
    updatedForm[index].form = form;
    updatedForm[index].isValid = isValid;
    updatedForm[index].overTcp = overTcp;
    updatedForm[index].hasModbusActions = hasModbusActions;
    this.setState({ allModbusForm: updatedForm });
    this.onSetModbusHandler(updatedForm);
    this.updatesDeviceSocketsFormWhenModbusChanges(form);
  }

  updatesDeviceSocketsFormWhenModbusChanges = (form) => {
    const updatedDeviceSocketForm = [...this.props.deviceSocketForm];
    if (+form.socket.value >= 21) {
      const updatedElement = {
        id: +form.socket.value,
        functions: form.functions.value,
        is_valid: form.display_name.value !== '',
        location: form.location.value,
        name: form.display_name.value,
        tag: form.tag.value,
        unit_name: form.unit_name.value
      }

      updatedDeviceSocketForm[this.state.modbusIndexInDeviceSocketForm] = updatedElement;
      this.props.setDeviceSocketForm(updatedDeviceSocketForm);
    }
  }

  canUseSocketNumber = (checkingIndex, checkingSocket) => {
    let maximumSocket = 20;
    // if (this.props.model) {
    //   if (this.props.model.length === 2) {
    //     maximumSocket += 15;
    //   } else if (this.props.model.length === 4) {
    //     maximumSocket += 30;
    //   } else {
    //     maximumSocket += 50;
    //   }
    // }
    maximumSocket += 15; // Bird said all models have 15 modbus's sockets (6/10/21)

    if (checkingSocket === '' || +checkingSocket <= 20 || +checkingSocket > maximumSocket) return false;
    let valid = this.state.allModbusForm.find((all, index) => index !== checkingIndex && all.form.socket.value === +checkingSocket) === undefined; // if equals undefine, it means still valid

    return valid;
  }

  addNewModbusHandler = () => {
    const { editingDevice, analogSockets } = this.props;
    let { freeSocket } = this.state;
    let updatedAllModbusForm = [...this.state.allModbusForm]
    updatedAllModbusForm.push({
      form: JSON.parse(JSON.stringify(DEFAULT_MODBUS_FORM)),
      isValid: false
    });
    while (freeSocket <= 35) {
      if (!editingDevice?.device_sockets || editingDevice.device_sockets.findIndex(ds => ds.socket === freeSocket && ds.enable) < 0) {
        break;
      }
      freeSocket++;
    }
    updatedAllModbusForm[updatedAllModbusForm.length - 1].form.socket.value = String(freeSocket);
    updatedAllModbusForm[updatedAllModbusForm.length - 1].form.socket.valid = true;
    updatedAllModbusForm[updatedAllModbusForm.length - 1].form.socket.touched = true;
    updatedAllModbusForm[updatedAllModbusForm.length - 1].overTcp = false;
    updatedAllModbusForm[updatedAllModbusForm.length - 1].hasModbusActions = false;
    freeSocket++;
    this.onSetModbusHandler(updatedAllModbusForm);
    let currentMenu = analogSockets ? analogSockets.length + updatedAllModbusForm.length - 1 : updatedAllModbusForm.length - 1;

    const updatedDeviceSocketForm = [...this.props.deviceSocketForm];
    updatedDeviceSocketForm.push({
      id: freeSocket - 1,
    });

    this.setState({ allModbusForm: updatedAllModbusForm, freeSocket, currentMenu, modbusIndexInDeviceSocketForm: updatedDeviceSocketForm.length - 1 });
    this.props.setDeviceSocketForm(updatedDeviceSocketForm);
  }

  onRemoveNewModbusHandler = (event, index) => {
    event.stopPropagation();
    const { allModbusForm } = this.state;
    const { analogSockets } = this.props;
    const deletingSocket = +allModbusForm[index].form.socket.value;
    allModbusForm.splice(index, 1);
    let whereCursorIndex = (analogSockets.length + allModbusForm.length) - 1;

    this.setState({ allModbusForm, currentMenu: whereCursorIndex });
    this.onSetModbusHandler(allModbusForm);

    const updatedDeviceSocketForm = [...this.props.deviceSocketForm];
    const foundIndex = updatedDeviceSocketForm.findIndex(uds => uds.id === deletingSocket);
    updatedDeviceSocketForm.splice(foundIndex, 1);
    this.props.setDeviceSocketForm(updatedDeviceSocketForm);
  }

  addNewAnalogSocketHandler = () => {
    const updatedDeviceSocketForm = [...this.props.deviceSocketForm];
    updatedDeviceSocketForm.push({
      id: updatedDeviceSocketForm.length + 1,
      name: 'Socket' + (updatedDeviceSocketForm.length + 1),
      functions: '',
      location: '',
      tag: '',
      unit_name: '',
      is_valid: true,
    });
    this.props.setDeviceSocketForm(updatedDeviceSocketForm);
  }

  onRemoveAnalogSocketHandler = (event, index) => {
    event.stopPropagation();
    const updatedDeviceSocketForm = [...this.props.deviceSocketForm];
    updatedDeviceSocketForm.splice(index, 1);
    updatedDeviceSocketForm.forEach((ud, i) => {
      ud.id = i + 1;
      ud.name = ud.name.startsWith('Socket') ? 'Socket' + (i + 1) : ud.name;
    });
    this.props.setDeviceSocketForm(updatedDeviceSocketForm);
  }

  onSelectRowHandler = (index, modbusSelected) => {
    if (modbusSelected) {
      const foundIndex = this.props.deviceSocketForm.findIndex(ds => ds.id === +this.state.allModbusForm[index].form.socket.value);
      this.setState({ currentMenu: index + this.props.analogSockets.length, modbusIndexInDeviceSocketForm: foundIndex })
    } else {
      this.setState({ currentMenu: index, modbusIndexInDeviceSocketForm: index });
    }
  }

  render() {
    const { analogs, analogOptions, currentMenu, allModbusForm } = this.state;
    const { analogSockets, isSystemDevice } = this.props;

    let content = null;
    if (analogSockets && currentMenu < analogSockets.length) {
      content = <AnalogForm
        isSystemDevice={isSystemDevice}
        newFirmwareVersion={this.props.newFirmwareVersion}
        socket={analogSockets[currentMenu]}
        selected={analogs[currentMenu]}
        handleAnalogUpdate={this.handleAnalogUpdate}
        handleAnalogTypeChange={this.handleAnalogTypeChange}
        handleDelayChange={this.handleDelayChange}
        analogOptions={analogOptions}
      />;
    } else {
      if (isSystemDevice) {
        content = <ModbusForm
          newFirmwareVersion={this.props.newFirmwareVersion}
          defaultModbusForm={DEFAULT_MODBUS_FORM}
          lengthOptions={MODBUS_LENGTH_OPTIONS}
          orderOptions={MODBUS_ORDER_OPTIONS}
          variableOptions={MODBUS_VARIABLE_OPTIONS}
          overTcpState={allModbusForm[currentMenu - analogSockets.length] ? allModbusForm[currentMenu - analogSockets.length].overTcp : false}
          modbusActionsState={allModbusForm[currentMenu - analogSockets.length] ? allModbusForm[currentMenu - analogSockets.length].hasModbusActions : false}
          modbusInfo={allModbusForm[currentMenu - analogSockets.length] ? allModbusForm[currentMenu - analogSockets.length].form : DEFAULT_MODBUS_FORM}
          editIndex={currentMenu - analogSockets.length}
          handleModbusFormChange={this.handleModbusFormChange}
          model={analogSockets}
          canUseSocketNumber={this.canUseSocketNumber}
        />;
      }
    }

    return (
      <div className={styles.Container}>
        <div className={styles.MenuWrapper}>
          {
            analogSockets ? analogSockets.map((socket, index) => {
              return <div key={`Analog-Socket-${index}`} className={currentMenu === index ? `${styles.Menu} ${styles.Active}` : styles.Menu} onClick={() => this.onSelectRowHandler(index)}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <div className={`${styles.TabLegend} ${styles.Analog}`} />
                  <span className={styles.Label} style={{ color: socket.is_valid ? '' : '#e6492d' }}>{socket.name ? '(' + socket.id + ') ' + socket.name : 'Socket-' + (index + 1)}</span>
                </div>
                {!isSystemDevice && <img className={styles.Btn} src={DeleteIcon} onClick={(event) => this.onRemoveAnalogSocketHandler(event, index)} />}
              </div>
            }) : null
          }
          {
            allModbusForm ? allModbusForm.map((modbus, index) => {
              return <div key={`Modbus-Socket-${index}`} className={currentMenu === (index + analogSockets.length) ? `${styles.Menu} ${styles.Active}` : styles.Menu} onClick={() => this.onSelectRowHandler(index, true)}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <div className={`${styles.TabLegend} ${styles.Modbus}`} />
                  <span className={styles.Label} style={{ color: modbus.isValid ? '' : '#e6492d' }}>{modbus.form.display_name.value ? '(' + modbus.form.socket.value + ') ' + modbus.form.display_name.value : 'Modbus-' + modbus.form.socket.value}</span>
                </div>
                <img className={styles.Btn} src={DeleteIcon} onClick={(event) => this.onRemoveNewModbusHandler(event, index)} />
              </div>
            }) : null
          }
          {
            isSystemDevice && this.state.analogOptions && this.state.analogOptions.length > 1
              ? <div className={AddDeviceStyles.AddSocketButton} style={{ margin: 10 }} onClick={this.addNewModbusHandler}>
                <img src={AddIcon} alt="Add icon for modbus" className={AddDeviceStyles.AddIcon} />
                <div className={AddDeviceStyles.ButtonName}>Add new modbus</div>
              </div>
              : null
          }
          {
            !isSystemDevice
              ? <div className={AddDeviceStyles.AddSocketButton} style={{ margin: 10 }} onClick={this.addNewAnalogSocketHandler}>
                <img src={AddIcon} alt="Add icon for analog" className={AddDeviceStyles.AddIcon} />
                <div className={AddDeviceStyles.ButtonName}>Add new socket</div>
              </div>
              : null
          }
        </div>
        <div className={styles.ContentWrapper}>
          {content}
        </div>
      </div>
    );
  }
}

export default SensorSettings;