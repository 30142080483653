import * as actionTypes from './types';

// ----------------------- GET TIMELINES -----------------------
export const getTimelinesStart = () => {
  return {
    type: actionTypes.GET_TIMELINES_START
  };
}

export const getTimelines = (page_id) => {
  return {
    type: actionTypes.GET_TIMELINES,
    page_id,
  };
}

export const getTimelinesSuccess = (response) => {
  return {
    type: actionTypes.GET_TIMELINES_SUCCESS,
    timelines: response.timelines
  };
}

export const getTimelinesFail = (error) => {
  return {
    type: actionTypes.GET_TIMELINES_FAIL,
    error: error
  };
}

// ----------------------- ADD TIMELINE -----------------------
export const addTimelineStart = () => {
  return {
    type: actionTypes.ADD_TIMELINE_START
  };
}

export const addTimeline = (page_id, name, machine_id, formula_oee, day_starting_time, properties) => {
  return {
    type: actionTypes.ADD_TIMELINE,
    page_id,
    name,
    machine_id,
    formula_oee,
    day_starting_time,
    properties
  };
}

export const addTimelineSuccess = (response) => {
  return {
    type: actionTypes.ADD_TIMELINE_SUCCESS,
    addedTimeline: response.timelines[0]
  };
}

export const addTimelineFail = (error) => {
  return {
    type: actionTypes.ADD_TIMELINE_FAIL,
    error: error
  };
}

// ----------------------- EDIT TIMELINE -----------------------
export const editTimelineStart = () => {
  return {
    type: actionTypes.EDIT_TIMELINE_START
  };
}

export const editTimeline = (id, name, machine_id, formula_oee, day_starting_time, properties) => {
  return {
    type: actionTypes.EDIT_TIMELINE,
    id,
    name,
    machine_id,
    formula_oee,
    day_starting_time,
    properties,
  };
}

export const editTimelineSuccess = (response) => {
  return {
    type: actionTypes.EDIT_TIMELINE_SUCCESS,
    updatedTimeline: response.timelines[0],
  };
}

export const editTimelineFail = (error) => {
  return {
    type: actionTypes.EDIT_TIMELINE_FAIL,
    error: error
  };
}

// ----------------------- DELETE TIMELINE -----------------------
export const deleteTimelineStart = () => {
  return {
    type: actionTypes.DELETE_TIMELINE_START
  };
}

export const deleteTimeline = (id) => {
  return {
    type: actionTypes.DELETE_TIMELINE,
    id: id
  };
}

export const deleteTimelineSuccess = (result) => {
  return {
    type: actionTypes.DELETE_TIMELINE_SUCCESS,
    result: result
  };
}

export const deleteTimelineFail = (error) => {
  return {
    type: actionTypes.DELETE_TIMELINE_FAIL,
    error: error
  };
}