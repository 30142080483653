import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import TimeAgo from 'react-timeago';

import SimpleSwitch from '../../UI/SimpleSwitch/SimpleSwitch';
import styles from './AnomalyList.module.scss';

// import AlarmHistoryIcon from '../../../../assets/icons/Rule/alarm-history.svg';
// import { enableRule } from '../../../../state/ducks/Rule/actions';
// import { ENABLE_RULE_SUCCESS } from '../../../../state/ducks/Rule/types';

class AnomalyList extends Component {
  state = {  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    return (
      this.props.anomalyList && this.props.anomalyList.length ? this.props.anomalyList.map((ad, i) => {
        let ruleWrapper = [styles.AnomalyListWrapper];
        if (this.props.selectedAnomalyIndex === i) {
          ruleWrapper.push(styles.Selected);
        }

        return (
          <div key={ad.name} className={ruleWrapper.join(' ')} onClick={() => this.props.onEditAnomaly(i)}>
            <div className={styles.SwitchWrapper}>
              <SimpleSwitch disabledSwitch={ad.calibratedTime === ''} enable={ad.enable} onChangeEnableHandler={(event) => this.props.toggleEnableAnomaly(event, i)} />
            </div>
            <div style={{ flex: 1 }}>
              <div className={styles.Name}>{ad.name}</div>
              <div className={styles.Description}>{ad.description}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {ad.calibratedTime !== ''
                ? <>
                  <div className={styles.LastTriggerText}>
                    <div>Last calibrated at</div>
                    {moment(ad.calibratedTime).format('HH:mm:ss, DD/MM/YYYY')}
                  </div>
                </>
                : <div className={styles.LastTriggerText}>Never</div>
              }
            </div>
          </div>
        )
      }) : null
    )
  }

}
const mapStateToProps = (state) => {
  // const { updatedEnableRule, type } = state.rule;
  // return { updatedEnableRule, type };
  return {};
};

export default connect(mapStateToProps, {})(AnomalyList);