import React, { Component } from 'react';
import { connect } from 'react-redux';

import VerifiedFail from '../../../components/Auth/VerifiedFail/VerifiedFail';
import VerifiedSuccess from '../../../components/Auth/VerifiedSuccess/VerifiedSuccess';
import styles from './VerifiedEmail.module.scss';
import Spinner from '../../../components/UI/Spinner/Spinner';

import { verifiedUser } from '../../../../state/ducks/Auth/actions';

class VerifiedEmail extends Component {

  componentDidMount() {
    const token = this.props.match.params.token;
    if (token !== "reset-password-success") {
      this.props.verifiedUser(token);
    }
  }

  goBackToLogin = () => {
    this.props.history.push('/auth');
  }

  render() {
    const token = this.props.match.params.token;

    let verifiedpage = null;
    let type = "verified";

    if (this.props.loading) {
      verifiedpage = <div className={styles.MiddleCenter}><Spinner /></div>;
    }
    else {
      if (token === "reset-password-success") {
        type = "resetPassword";
        verifiedpage = <VerifiedSuccess type={type} goBackToLogin={this.goBackToLogin} />;
      }
      else if (this.props.verifiedStatus === "success") {
        verifiedpage = <VerifiedSuccess type={type} goBackToLogin={this.goBackToLogin} />;
      }
      else if (this.props.verifiedStatus === "failed") {
        verifiedpage = <VerifiedFail />;
      }
    }

    return (
      <div className={styles.VerifiedEmail}>
        {verifiedpage}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { verifiedStatus, loading } = state.auth;
  return { verifiedStatus, loading };
};


export default connect(mapStateToProps, { verifiedUser })(VerifiedEmail);
