import React, { Component } from 'react';
// import { connect } from 'react-redux';
import MobileDetect from 'mobile-detect';

import GaugeChart from '../../../UI/GaugeChart/GaugeChart';
import styles from './Gauge.module.scss';
import chartStyles from '../Chart.module.scss';

import { calculateNewValFromEquation } from '../../../../../state/utils';


class Gauge extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    if ((nextProps.data || nextProps.data === 0) && this.props.data !== nextProps.data) {
      return true;
    } else if (nextProps.lastUpdated !== this.props.lastUpdated) {
      return true;
    }
    return false;
  }

  render() {
    const { data, widget, lastValue } = this.props;
    let gaugeValue = isNaN(parseFloat(data)) ? null : calculateNewValFromEquation(data, widget.equation);

    let gaugeColor = '#1665D8';
    if (widget && widget.gaugeProps && widget.gaugeProps.thresholdRanges && widget.gaugeProps.thresholdRanges.length) {
      widget.gaugeProps.thresholdRanges.forEach(tr => {
        if (+gaugeValue >= tr.start && +gaugeValue < tr.stop) {
          gaugeColor = tr.color.color;
        }
      });
    }

    let displayedValue = gaugeValue;
    let displayedLastValue = lastValue;
    if (gaugeValue && (gaugeValue.toString().includes(".00") || !gaugeValue.toString().includes("."))) {
      displayedValue = gaugeValue.toLocaleString();
    } else if (lastValue && (lastValue.toString().includes(".00") || !lastValue.toString().includes("."))) {
      displayedLastValue = lastValue.toLocaleString();
    }

    const textStyle = [styles.Value];
    if (displayedValue && displayedValue.toString().length > 6) {
      textStyle.push(styles.Small);
    } else if (lastValue && lastValue.toString().length > 6) {
      textStyle.push(styles.Small);
    }

    // console.log(window.innerWidth, window.matchMedia("(min-width: 1600px)").matches)
    const isSixteenhundredVp = window.innerWidth < 1600;
    const isMobile = new MobileDetect(window.navigator.userAgent).mobile() !== null && window.innerWidth < 600;
    let gaugeTextSize = isSixteenhundredVp ? '1vw' : 20;
    gaugeTextSize = isMobile ? 20: gaugeTextSize;

    return (
      <div style={{ marginTop: isSixteenhundredVp ? 55 : 40 }}>
        {
          <GaugeChart
            value={
              gaugeValue || gaugeValue === 0 ?
                gaugeValue
                : lastValue || lastValue === 0 ?
                  lastValue
                  : 0
            }
            min={widget.gaugeProps ? widget.gaugeProps.min : 0}
            max={widget.gaugeProps ? widget.gaugeProps.max : 2000}
            size={isSixteenhundredVp ? 150 : 180}
            strokewidth={isSixteenhundredVp < 1600 ? 26 : 32}
            color={gaugeColor}
          />
        }
        <div className={textStyle.join(' ')} style={{ fontSize: gaugeTextSize, top: isSixteenhundredVp ? 60 : 70 }}>
          {
            gaugeValue || gaugeValue === 0 ?
              displayedValue.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })
              : lastValue || lastValue === 0 ?
                displayedLastValue.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })
                : <span className={chartStyles.LoadingText}>Loading...</span>
          }
        </div>
        <div className={styles.Unit} style={{ top: isSixteenhundredVp ? 85 : 100 }}>
          {
            gaugeValue || gaugeValue === 0 || lastValue || lastValue === 0
              ? widget.gaugeProps && widget.gaugeProps.customUnit
                ? widget.gaugeProps.customUnit
                : widget.unit
              : ''
          }
        </div>
      </div>
    );
  }
}

export default Gauge;