import React, { Component } from 'react';
import _ from 'lodash';

import styles from './Modal.module.scss';
import Backdrop from '../Backdrop/Backdrop';

class Modal extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
  }

  render() {
    let modalStyles = {
      transform: this.props.show ? 'translate(-50%, -50%)' : 'translate(-50%, -200vh)',
      opacity: this.props.show ? '1' : '0'
    };

    if (this.props.overideStyles) {
      modalStyles = _.merge(
        {},
        modalStyles,
        this.props.overideStyles
      );
    }

    let defaultModalTheme = [styles.Modal];
    let closeBtnStyles = [styles.CloseBtn];
    if (this.props.darkMode)
      defaultModalTheme.push(styles.DarkMode);
      closeBtnStyles.push(styles.DarkMode);
    if (this.props.whiteCloseButton)
      closeBtnStyles.push(styles.WhiteCloseButton);
    if (this.props.className)
      defaultModalTheme.push(this.props.className);

    return (
      <React.Fragment>
        <Backdrop show={this.props.show} clicked={!this.props.disableCloseOnBackdrop ? this.props.modalClosed : null} />
        <div
          className={defaultModalTheme.join(' ')}
          style={modalStyles}>
          {
            !this.props.noCloseButton && <div style={{ position: 'relative' }}>
              <span className={closeBtnStyles.join(' ')} aria-hidden="true" onClick={this.props.modalClosed}>&times;</span>
            </div>
          }
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

export default Modal;