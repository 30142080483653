import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './ProductionDashboard.module.scss';
import DashboardGrid from '../../components/Production/Dashboard/DashboardGrid';
import FullScreenIcon from '../../../assets/icons/full-screen.svg';
import NormalScreenIcon from '../../../assets/icons/normal-screen.svg';
import TimeClock from './TimeClock';

const DASHBOARD_TYPE = ['Machine', 'Production Order', 'Batch'];

class ProductionDashboard extends Component {
  state = {
    currentDashboardIndex: 0,
    isFullScreen: false
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg) {
      this.setState({ currentDashboardIndex: 0 });
    }
  }

  toggleFullScreen = () => {
    this.setState(prevState => {
      return { isFullScreen: !prevState.isFullScreen }
    });
  }

  render() {
    let ScreenStyle = styles.MinimizeScreen;
    if (this.state.isFullScreen) {
      ScreenStyle = styles.FullScreen;
    }

    return (
      <div className={ScreenStyle}>
        <div className={styles.ProductionDashboard}>
          <div className={styles.TopSection}>
            <div className={styles.MenuWrapper}>
              {
                DASHBOARD_TYPE.map((dashboard, index) => (
                  <div key={`dashboard-${index}`} className={index === this.state.currentDashboardIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentDashboardIndex: index })}>
                    <span className={styles.MenuTitle}>{dashboard}</span>
                  </div>
                ))
              }
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 30 }}>
              <TimeClock />
            </div>
            <div className={styles.VerticalDivider} />
            <div className={styles.ScreenButton}>
              {
                this.state.isFullScreen ?
                  <img src={NormalScreenIcon} alt="Normal Screen Icon" onClick={this.toggleFullScreen} /> :
                  <div className={styles.ScreenWrapper} onClick={this.toggleFullScreen} >
                    <img src={FullScreenIcon} alt="Full Screen Icon" />
                    {/* <div className={styles.ScreenTitle}>Full Screen</div> */}
                  </div>
              }
            </div>
          </div>
          <div className="MainContentPadding">
            <DashboardGrid dashboardIndex={this.state.currentDashboardIndex} />
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  return { currentOrg }
}

export default connect(mapStateToProps, {})(ProductionDashboard);