import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import styles from './TimelineBar.module.scss';
import TimelineBar from './TimelineBar';
import { getApqOfMachine } from '../../../../state/ducks/Machine/actions';

const ONE_DAY_IN_MS = 86400000;

class TimelineRow extends Component {
    state = {
        currentStatus: '',
        oee: { availability: 0, performance: 0, quality: 0 },
    }

    componentDidMount() {
        if (this.props.startingPeriod) {
            clearInterval(this.loadAPQInterval);
            this.reloadAPQInterval();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentOrg !== this.props.currentOrg && this.props.currentOrg) {
            clearInterval(this.loadAPQInterval);
        } else if (this.props.startingPeriod !== prevProps.startingPeriod && this.props.startingPeriod) {
            clearInterval(this.loadAPQInterval);
            this.reloadAPQInterval();
        } else if (this.props.machineAPQ && this.props.machineAPQ !== prevProps.machineAPQ) {
            if (this.props.machineAPQ.properties.machine_id === this.props.machine.id) {
                // case : only 1 machine timeline
                const { a, p, q } = this.props.machineAPQ.value;
                let oee = {
                    availability: a, performance: p, quality: q
                };
                this.setState({ oee });
            }
        } else if (this.props.machineAPQ && this.props.machineAPQ.value && prevProps.machineAPQ && prevProps.machineAPQ.properties.machine_id !== this.props.machineAPQ.properties.machine_id) {
            if (this.props.machineAPQ.properties.machine_id === this.props.machine.id) {
                // console.log("##", this.props.machine.id, this.props.machine.name, this.props.machineAPQ.value)
                const { a, p, q } = this.props.machineAPQ.value;
                let oee = {
                    availability: a, performance: p, quality: q
                };
                this.setState({ oee });
            }
        } else if (this.props.selectedDate !== prevProps.selectedDate && this.props.selectedDate) {
            clearInterval(this.loadAPQInterval);
            this.reloadAPQInterval();
        }
    }

    reloadAPQInterval = () => {
        this.getMachinesAPQ(this.props.startingPeriod);
        this.loadAPQInterval = setInterval(() => {
            this.getMachinesAPQ(this.props.startingPeriod);
        }, 60 * 1000);
    }

    componentWillUnmount() {
        if (this.loadAPQInterval) {
            clearInterval(this.loadAPQInterval);
        }
    }

    getMachinesAPQ = (startingPeriod) => {
        let day = new Date(this.props.selectedDate);
        let splited = startingPeriod.split(":");
        let h = +splited[0];
        let m = +splited[1];
        // if (today.getHours() < h || (today.getHours() === h && today.getMinutes() < m)) {
        //     today = new Date(today.getTime() - ONE_DAY_IN_MS);
        // }

        let startQueryTime = day.setHours(h, m, 0, 0);
        let stopQueryTime = startQueryTime + ONE_DAY_IN_MS;
        this.props.getApqOfMachine(this.props.machine.id, startQueryTime, stopQueryTime, { machine_id: this.props.machine.id });
    }

    render() {
        const { availability, performance, quality } = this.state.oee;

        const percentOee = availability * performance * quality / 10000;
        const avaStyles = [styles.apq_cell, availability >= 80 ? styles.normal : availability >= 60 ? styles.warning : styles.danger];
        const perStyles = [styles.apq_cell, performance >= 80 ? styles.normal : performance >= 60 ? styles.warning : styles.danger];
        const quaStyles = [styles.apq_cell, quality >= 80 ? styles.normal : quality >= 60 ? styles.warning : styles.danger];
        const oeeStyles = [styles.oee_cell, percentOee >= 80 ? styles.normal : percentOee >= 60 ? styles.warning : styles.danger];

        return (
            <tr className={styles.TimelineRow}>
                <td>{this.props.machine.name}</td>
                <td>
                    <TimelineBar
                        selectedDate={this.props.selectedDate}
                        machine={this.props.machine}
                        startingPeriod={this.props.startingPeriod}
                        setCurrentStatus={(value) => this.setState({ currentStatus: value })}
                        onBarClick={this.props.handleTimelineBarClick}
                    />
                </td>
                <td><div className={styles.reason_cell}>{this.state.currentStatus ? this.state.currentStatus : '-'}</div></td>
                <td>
                    <div className={avaStyles.join(' ')}>{availability && availability !== 'N/A' ? availability.toFixed(2) : 0}</div>
                </td>
                <td>
                    <div className={perStyles.join(' ')}>{performance && performance !== 'N/A' ? performance.toFixed(2) : 0}</div>
                </td>
                <td>
                    <div className={quaStyles.join(' ')}>{quality && quality !== 'N/A' ? quality.toFixed(2) : 0}</div>
                </td>
                <td>
                    <div className={oeeStyles.join(' ')}>{percentOee && percentOee !== 'N/A' ? percentOee.toFixed(2) : 0}</div>
                </td>
            </tr>
        )
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { machineAPQ } = state.machine;
    return { currentOrg, machineAPQ };
}

export default connect(mapStateToProps, { getApqOfMachine })(TimelineRow);