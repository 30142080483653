import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import SearchMember from '../../shared/SearchMember/SearchMember';

import { getMembers } from '../../../../state/ducks/Members/actions';
import { addTeam, editTeam, addTeamMembers, removeTeamMembers, changeTeamAdmin } from '../../../../state/ducks/Team/actions';

const DEFAULT_TEAM_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  detail: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  }
};

class TeamDrawer extends Component {
  state = {
    formIsValid: false,
    teamForm: DEFAULT_TEAM_FORM,
    addingMembers: '',
    deletingMembers: '',
    isTeamFormChanged: false,
    newAdmin: null
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAdding && (prevProps.isAdding !== this.props.isAdding)) {
      this.onClearFields();
    } else if ((prevProps.addedTeam !== this.props.addedTeam) || (prevProps.updatedTeam !== this.props.updatedTeam)) {
      this.props.closed();
    } else if (this.props.editingTeam && prevProps.editingTeam !== this.props.editingTeam) {
      this.setupForm(this.props.editingTeam);
    }
    if (this.props.isDrawerOpened && this.props.editingTeam && (prevProps.isDrawerOpened !== this.props.isDrawerOpened)) {
      this.onClearMemberChange();
    }
  }

  onClearFields = () => {
    this.setState({ teamForm: DEFAULT_TEAM_FORM, formIsValid: false });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.teamForm };
    for (let inputIdentifier in updatedForm) {
      const updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
      });
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });

      this.setState({ teamForm: updatedForm, formIsValid: true, isTeamFormChanged: false });
    }
  }

  handleTextChange = event => {
    let updatedElement = updateObject(this.state.teamForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.teamForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.teamForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    if (this.props.editingTeam) {
      let isTeamFormChanged = this.props.editingTeam.name !== updatedForm.name.value || this.props.editingTeam.detail !== updatedForm.detail.value;
      this.setState({ isTeamFormChanged });
    }


    this.setState({ teamForm: updatedForm, formIsValid });
  };

  onSubmitTeamHandler = (event) => {
    event.preventDefault();
    const { name, detail } = this.state.teamForm;
    if (this.props.isAdding) {
      this.props.addTeam(
        this.props.currentOrg,
        name.value,
        detail.value
      );
      this.onClearFields();
    } else {
      if (this.state.newAdmin) {
        this.props.changeTeamAdmin(this.props.editingTeam.id, this.state.newAdmin);
        this.setState({ newAdmin: null });
      } else {
        this.props.editTeam(
          this.props.editingTeam.id,
          name.value,
          detail.value,
          this.state.addingMembers ? this.state.addingMembers : null,
          this.state.deletingMembers ? this.state.deletingMembers : null
        );
        if (this.state.addingMembers) {
          this.setState({ addingMembers: '' });
        }
        if (this.state.deletingMembers) {
          this.setState({ deletingMembers: '' });
        }
      }
    }
  }

  onSaveOrDeleteMembersHandler = (type, members) => {
    let user_ids = '';
    members.forEach((member, i) => {
      user_ids += member.id;
      if (i !== members.length - 1) {
        user_ids += ',';
      }
    });

    if (type === 'add') {
      this.setState({ addingMembers: user_ids });
    } else {
      this.setState({ deletingMembers: user_ids });
    }
  }

  isChangingAdminHandler = (uid) => {
    this.setState({ newAdmin: uid });
  }

  onClearMemberChange = () => {
    this.setState({ newAdmin: null, addingMembers: '', deletingMembers: '' })
  }

  render() {
    const { name, detail } = this.state.teamForm;
    const isTeamAdmin = this.props.editingTeam && this.props.loggedInUser.id === this.props.editingTeam.admin_id;
    const isOrgAdmin = this.props.currentOrg && this.props.loggedInUser.admin_of_org && this.props.loggedInUser.admin_of_org.includes(+this.props.currentOrg);
    const isAdmin = this.props.loggedInUser && this.props.loggedInUser.is_superuser;

    return (
      <div>
        <div className="ErrorText">
          {
            this.props.isAdding && this.props.result === 'failed' ?
              this.props.error === 'This name already in use' ? 'This name is already in use' : 'Please contact admin!'
              : null
          }
        </div>
        <form onSubmit={this.onSubmitTeamHandler}>
          <Input
            label="team name"
            name="name"
            type="text"
            value={name.value}
            placeholder="Please insert your team name"
            autoComplete="off"
            onChange={this.handleTextChange}
            error={`${name.touched && !name.valid ? 'Team Name is required' : ''}`}
            disabled={(!this.props.isAdding && !isTeamAdmin && !isOrgAdmin && !isAdmin) || this.state.newAdmin}
            required
          />
          <Input
            label="detail"
            name="detail"
            value={detail.value}
            type="textarea"
            placeholder="What is this Team about? Tell people more juicy details."
            rows="5"
            onChange={this.handleTextChange}
            disabled={(!this.props.isAdding && !isTeamAdmin && !isOrgAdmin  && !isAdmin) || this.state.newAdmin}
          />
          {
            !this.props.isAdding ?
              <div style={{ position: 'relative' }}>
                <SearchMember
                  editing={this.props.editingTeam}
                  isDrawerOpened={this.props.isDrawerOpened}
                  saveOrDeleteMembers={this.onSaveOrDeleteMembersHandler}
                  isChangingAdmin={this.isChangingAdminHandler}
                  membersAlreadyIn={
                    this.props.allTeamsMembers && this.props.editingTeam && this.props.allTeamsMembers[this.props.editingTeam.id] ?
                      this.props.allTeamsMembers[this.props.editingTeam.id] : null
                  }
                  isFormChanged={this.state.isTeamFormChanged}
                  isOrgAdmin={isOrgAdmin}
                  newAdmin={this.state.newAdmin}
                />
              </div>
              : null
          }

          <div style={{ height: 70, width: 'auto' }} />

          {
            this.props.isAdding ?
              <React.Fragment>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Done'}
                  color="primary"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
              </React.Fragment>
              :
              isTeamAdmin || isOrgAdmin || isAdmin ?
                <React.Fragment>
                  <Button
                    type="submit"
                    name={this.props.loading ? 'Loading...' : 'Save'}
                    color="green"
                    disabled={!this.state.formIsValid}
                    loading={this.props.loading}
                  />
                  <Button type="button" name="Delete" color="borderred" click={this.props.toggleDeleteModal} />
                </React.Fragment>
                : <Button type="button" name="Leave this team" color="borderred" click={this.props.leaveTeam} />
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { currentOrg } = state.org;
  const { addedTeam, updatedTeam, allTeamsMembers, result, error, loading } = state.team;
  return { loggedInUser, currentOrg, addedTeam, updatedTeam, allTeamsMembers, result, error, loading };
};

export default connect(mapStateToProps, {
  addTeam,
  editTeam,
  addTeamMembers,
  removeTeamMembers,
  getMembers,
  changeTeamAdmin
})(TeamDrawer);