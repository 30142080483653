import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SnackBar } from '../../components/UI/SnackBar/SnackBar';

import ProfileCard from '../../components/Profile/ProfileCard/ProfileCard';
import BasicProfileForm from '../../components/Profile/BasicProfileForm/BasicProfileForm';
import PasswordForm from '../../components/Profile/PasswordForm/PasswordForm';
import styles from './Profile.module.scss';

import { getLoggedInUser } from '../../../state/ducks/Auth/actions';

class Profile extends Component {
  snackbarRef = React.createRef();

  componentDidUpdate(prevProps, prevState) {
    if (this.props.result !== prevProps.result) {
      if (this.props.result === 'success') {
        this.snackbarRef.current.openSnackBar('Saved');
        if (prevProps.updatedUser !== this.props.updatedUser) {
          this.props.getLoggedInUser(this.props.loggedInUser.id);
        }
      } else if (this.props.result === 'failed') {
        if (this.props.error === 'Username or password incorrect') {
          this.snackbarRef.current.openSnackBar('Old password incorrect!');
        } else if (this.props.error) {
          this.snackbarRef.current.openSnackBar(this.props.error);
        }
      }
    }
  }

  render() {
    return (
      <div className={styles.Profile}>
        <SnackBar ref={this.snackbarRef} type={this.props.result}/>
        <div style={{ display: 'flex' }}>
          <div className={styles.ProfileCard}>
            <ProfileCard />
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <BasicProfileForm />
              <PasswordForm />
            </div>
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const { updatedUser, result, loggedInUser, error } = state.auth;
  return { updatedUser, result, loggedInUser, error };
};

export default connect(mapStateToProps, { getLoggedInUser })(Profile);