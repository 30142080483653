import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  timelineStatuses: null,
  paretoData: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_TIMELINE_STATUSES: return clearTimelineStatuses(state, action);

    case actionTypes.GET_TIMELINE_STATUSES_START: return getTimelineStatussStart(state, action);
    case actionTypes.GET_TIMELINE_STATUSES_SUCCESS: return getTimelineStatusSuccess(state, action);
    case actionTypes.GET_TIMELINE_STATUSES_FAIL: return getTimelineStatusFail(state, action);

    case actionTypes.ADD_TIMELINE_REMARK_START: return addTimelineRemarkStart(state, action);
    case actionTypes.ADD_TIMELINE_REMARK_SUCCESS: return addTimelineRemarkSuccess(state, action);
    case actionTypes.ADD_TIMELINE_REMARK_FAIL: return addTimelineRemarkFail(state, action);

    case actionTypes.EDIT_TIMELINE_REMARK_START: return editTimelineRemarkStart(state, action);
    case actionTypes.EDIT_TIMELINE_REMARK_SUCCESS: return editTimelineRemarkSuccess(state, action);
    case actionTypes.EDIT_TIMELINE_REMARK_FAIL: return editTimelineRemarkFail(state, action);

    case actionTypes.GET_PARETO_DATA_START: return getParetoDataStart(state, action);
    case actionTypes.GET_PARETO_DATA_SUCCESS: return getParetoDataSuccess(state, action);
    case actionTypes.GET_PARETO_DATA_FAIL: return getParetoDataFail(state, action);

    default: return state;
  }
};

const clearTimelineStatuses = (state, action) => {
  return updateObject(state, { result: null, error: null, timelineStatuses: null });
}

const getTimelineStatussStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getTimelineStatusSuccess = (state, action) => {
  const sortedTimeline = action.timelineStatuses ? action.timelineStatuses.sort((a, b) => a.created_date - b.created_date) : null;
  const newTimelineStatuses = {
    data: sortedTimeline,
    properties: action.properties
  }
  return updateObject(state, {
    timelineStatuses: newTimelineStatuses,
    error: null,
    loading: false
  });
}

const getTimelineStatusFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addTimelineRemarkStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addTimelineRemarkSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const addTimelineRemarkFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editTimelineRemarkStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editTimelineRemarkSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const editTimelineRemarkFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getParetoDataStart = (state, action) => {
  return updateObject(state, { paretoData: null, result: null, error: null, loading: true });
}

const getParetoDataSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    paretoData: {
      data: action.paretoData,
      properties: action.properties
    },
    error: null,
    loading: false
  });
}

const getParetoDataFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;