
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';

import styles from './MachineOverview.module.scss';
import GaugeChart from '../../UI/GaugeChart/GaugeChart';
import SelectMachineDropdown from '../SelectMachineDropdown/SelectMachineDropdown';

import { getDataEval, getDataLatest, getDatasetCal, getDataCal } from '../../../../state/ducks/Data/actions';
import { calculateOee } from '../../../../state/ducks/AdvanceActualProduction/actions';
import { setDefaultOperatorMachine, getMachinesAndActuals } from '../../../../state/ducks/Machine/actions';

const PERIODS_FOR_OEE_CHART = 6;

class MachineOverview extends Component {
	state = {
		producedActual: 0,
		currentMachineStatus: 0,
		currentValues: {},
		historyOEE: null,
		historyPartsMade: null,
		preparingHistoryPartsMade: null,
		displayOverHundred: false
	}

	componentDidMount() {
		if (this.props.defaultOperatorMachine) {
			this.setupDisplayingValues();
		}
	}

	setupDisplayingValues = () => {
		let isFloating = false;
		let displayOverHundred = false;
		if (this.props.moduleConfigs) {
			isFloating = this.props.moduleConfigs.find(mc => mc.key === 'isFloating');
			isFloating = isFloating && isFloating.value === 'true' ? true : false;

			displayOverHundred = this.props.moduleConfigs.find(mc => mc.key === 'apqOver');
			displayOverHundred = displayOverHundred && displayOverHundred.value === 'true' ? true : false;
			this.setState({ displayOverHundred });
		}

		const { total_device_secret, total_device_socket, id, status_device_secret, status_device_socket } = this.props.defaultOperatorMachine;
		const foundMachine = this.props.machinesAndActuals && this.props.machinesAndActuals.find(mac => mac.id === this.props.defaultOperatorMachine.id);
		if (foundMachine) {
			let owlOperatorDefaultMachine = localStorage.getItem("OwlOperatorDefaultMachine") ? JSON.parse(localStorage.getItem("OwlOperatorDefaultMachine")) : {};
			owlOperatorDefaultMachine[this.props.currentOrg] = foundMachine;
			this.props.setDefaultOperatorMachine(JSON.stringify(owlOperatorDefaultMachine));
		}

		if (foundMachine && foundMachine.actuals && foundMachine.actuals.length) {
			this.props.getDataLatest(status_device_socket, status_device_secret, null, null, { machineId: id });
			// this.props.calculateOee(foundMachine.actuals[0].uuid, new Date().getTime(), null, null, null, { machineId: id }, is_floating);
			this.findOeeHistory(foundMachine.actuals[0], isFloating);
			this.findPartsMadeHistory(foundMachine.actuals[0], isFloating);
			// this.calculateForPartsMade(foundMachine.actuals[0].plan.uuid, foundMachine.actuals[0], total_device_secret, total_device_socket);

			this.dataForMachineInterval = setInterval(() => {
				this.props.getDataLatest(status_device_socket, status_device_secret, null, null, { machineId: id });
				// this.props.calculateOee(foundMachine.actuals[0].uuid, new Date().getTime(), null, null, null, { machineId: id }, is_floating);
				this.findOeeHistory(foundMachine.actuals[0], isFloating);
				this.findPartsMadeHistory(foundMachine.actuals[0], isFloating);
				// this.calculateForPartsMade(foundMachine.actuals[0].plan.uuid, foundMachine.actuals[0], total_device_secret, total_device_socket);
			}, 5 * 1000);
		} else {
			if (this.dataForMachineInterval) {
				clearInterval(this.dataForMachineInterval);
			}
			setTimeout(() => {
				this.setState({
					producedActual: 0,
					currentMachineStatus: 0,
					currentValues: {},
					historyOEE: null,
					historyPartsMade: null,
					preparingHistoryPartsMade: null
				});
			}, 2 * 1000);
		}
	}

	findPartsMadeHistory = (actual, isFloating) => {
		let startQuery = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).subtract(4, 'hours').valueOf();
		let nextHour = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours').valueOf();
		this.props.calculateOee(actual.uuid, null, startQuery, nextHour, 60, { isPartsMade: true }, isFloating);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.defaultOperatorMachine && !_.isEqual(prevProps.defaultOperatorMachine, this.props.defaultOperatorMachine)) {
			clearInterval(this.dataForMachineInterval);
			this.setupDisplayingValues();
		} else if (this.props.dataEval && prevProps.dataEval !== this.props.dataEval) {
			let producedActual = 0;
			producedActual = this.props.dataEval.data;
			this.setState({ producedActual });
		} else if (this.props.dataLatest && prevProps.dataLatest !== this.props.dataLatest) {
			let currentMachineStatus = 0;
			currentMachineStatus = this.props.dataLatest.data[0] && this.props.dataLatest.data[0].value;
			this.setState({ currentMachineStatus });
		} else if (this.props.actualOee && prevProps.actualOee !== this.props.actualOee) {
			// if (!this.props.actualOee.properties.isHistory) {
			// 	// DEPRECATED (13/06/2022) => use history to loop calculation each apq
			// 	// For current OEE
			// 	let currentValues = { ...this.state.currentValues };
			// 	if (this.props.actualOee.apq) {
			// 		currentValues["a"] = this.props.actualOee.apq[0].a;
			// 		currentValues["p"] = this.props.actualOee.apq[0].p;
			// 		currentValues["q"] = this.props.actualOee.apq[0].q;
			// 		currentValues["oee"] = this.props.actualOee.apq[0].a * this.props.actualOee.apq[0].p * this.props.actualOee.apq[0].q / 10000;
			// 		currentValues["good"] = this.props.actualOee.apq[0].good;
			// 		currentValues["defect"] = this.props.actualOee.apq[0].defect;
			// 		currentValues["total"] = this.props.actualOee.apq[0].total;
			// 		currentValues["lastCycle"] = this.humanizeDuration(this.props.actualOee.apq[0].last_cycle);
			// 	} else {
			// 		currentValues = {};
			// 	}

			// 	currentValues["averageCycle"] = this.humanizeDuration(this.props.actualOee.average);
			// 	currentValues["expectedCycle"] = this.humanizeDuration(this.props.actualOee.expected);

			// 	if (this.props.defaultOperatorMachine && this.props.defaultOperatorMachine.actuals && this.props.defaultOperatorMachine.actuals.length) {
			// 		this.findOeeHistory(this.props.defaultOperatorMachine.actuals[0]);
			// 	}
			// 	this.setState({ currentValues });
			// }
			if (this.props.actualOee.properties.isPartsMade) {
				let historyPartsMade = { ...this.state.historyPartsMade };
				const isOver = this.state.currentValues.oee >= 80;
				const graphData = {
					labels: [],
					datasets: [
						{
							label: 'Parts made',
							data: [],
							backgroundColor: isOver ? '#34aa44' : '#ea272a',
							borderColor: isOver ? '#34aa44' : '#ea272a',
							borderWidth: 1,
						},
					],
				};
				historyPartsMade = historyPartsMade ? { ...this.state.historyPartsMade } : {};
				if (this.props.actualOee.apq && this.props.actualOee.apq.length) {
					this.props.actualOee.apq.forEach(v => {
						graphData.labels.push(moment(v.ts).format('hh A'));
						graphData.datasets[0].data.push(v.total);
					});
				}
				historyPartsMade = graphData;
				this.setState({ historyPartsMade });
			} else {
				const foundMachine = this.props.machinesAndActuals && this.props.machinesAndActuals.find(mac => mac.id === this.props.defaultOperatorMachine.id);
				if (foundMachine && foundMachine.actuals && foundMachine.actuals.length) {
					// for OEE history
					const isOver = this.state.currentValues.oee >= 80;
					let graphData = {
						labels: [],
						datasets: [{
							label: 'OEE%',
							borderColor: isOver ? '#34aa44' : '#ea272a',
							backgroundColor: isOver ? '#34aa4433' : '#ea272a33',
							// backgroundColor: true ? '#203623' : '#3d2122',
							fill: true,
							data: [],
							pointBackgroundColor: isOver ? '#34aa44' : '#ea272a',
							pointRadius: 1,
							lineTension: 0
						}]
					};

					let currentValues = { ...this.state.currentValues };
					let historyOEE = {};
					let lastIndex = -1;
					let value = { runTime: 0, totalTime: 0, good: 0, total: 0, defect: 0 };

					const foundMachine = this.props.machinesAndActuals && this.props.machinesAndActuals.find(mac => mac.id === this.props.defaultOperatorMachine.id);
					const { start } = foundMachine.actuals[0];
					const diffDays = new Date().getTime() - new Date(start).getTime();

					if (this.props.actualOee.apq) {
						this.props.actualOee.apq.forEach((ea, i) => {
							value.runTime += ea.runtime;
							value.totalTime += ea.total_time;
							value.good += ea.good;
							value.total += ea.total;
							value.defect += ea.defect;

							graphData.labels.push(moment(ea.ts).format('hh A'));
							if (ea.a === -1 || ea.p === -1 || ea.q === -1) {
								graphData.datasets[0].data.push(null);
							} else {
								if (i === 0 && this.props.actualOee.apq[1] && this.props.actualOee.apq[1].a === -1 && this.props.actualOee.apq[1].p === -1 && this.props.actualOee.apq[1].q === -1) {
									// case for plot only first data (0) and jump to now()
									graphData.datasets[0].data.push(null);
								} else {
									if (moment(ea.ts) > moment() - (60 * 1000)) {
										if (lastIndex == -1) lastIndex = i - 1;
										graphData.datasets[0].data.push(null);
									} else {
										const ava = ea.a;
										const per = ea.p;
										const qua = ea.q;
										// const ava = (value.runTime * 100 / value.totalTime);
										// const per = value.total * this.props.actualOee.expected * 100 / diffDays;
										// const qua = (value.good * 100 / value.total);
										const oeePercent = (ava * per * qua / 10000);
										if (!this.state.displayOverHundred && oeePercent >= 100) {
											graphData.datasets[0].data.push(100);
										} else {
											graphData.datasets[0].data.push(oeePercent.toFixed(2));
										}
									}
								}
							}
						});
						const avaPercent = this.props.actualOee.sum.a;
						const perPercent = this.props.actualOee.sum.p;
						const quaPercent = this.props.actualOee.sum.q;
						// const avaPercent = (value.runTime * 100 / value.totalTime);
						// const perPercent = value.total * this.props.actualOee.expected * 100 / diffDays;
						// const quaPercent = (value.good * 100 / value.total);
						const oeePercent = (avaPercent * perPercent * quaPercent) / 10000;
						currentValues["a"] = avaPercent;
						currentValues["p"] = perPercent;
						currentValues["q"] = quaPercent;
						currentValues["oee"] = oeePercent;
					}
					historyOEE = graphData;

					let lastFew = 0;
					for (let i = lastIndex; i > lastIndex - 5; i--) {
						lastFew += this.props.actualOee.apq[i].last_cycle;
					}

					currentValues["good"] = value.good;
					currentValues["defect"] = value.defect;
					currentValues["total"] = value.total;
					currentValues["lastFew"] = this.humanizeDuration(lastFew / 5);
					currentValues["lastCycle"] = this.humanizeDuration(this.props.actualOee.apq[lastIndex].last_cycle);
					currentValues["averageCycle"] = this.humanizeDuration(this.props.actualOee.average);
					currentValues["expectedCycle"] = this.humanizeDuration(this.props.actualOee.expected);
					this.setState({ historyOEE, currentValues });
				}
			}
		} else if (this.props.datasetCal !== prevProps.datasetCal && this.props.datasetCal) {
			// DP: use /apq instead 02/09/22
			const { properties: { actualUuid, continueFromFirst }, values } = this.props.datasetCal;
			let historyPartsMade = { ...this.state.historyPartsMade };
			if (continueFromFirst) {
				const preparingHistoryPartsMade = { ...this.state.preparingHistoryPartsMade };
				if (values && values.length) {
					values.forEach(v => {
						preparingHistoryPartsMade.labels.push(moment(v.timestamp).add(1, 'hours').format('hh A'));
						preparingHistoryPartsMade.datasets[0].data.push(v.value);
					});
					this.setState({ historyPartsMade: preparingHistoryPartsMade });
				}
			} else {
				const isOver = this.state.currentValues.oee >= 80;
				const graphData = {
					labels: [],
					datasets: [
						{
							label: 'Parts made',
							data: [],
							backgroundColor: isOver ? '#34aa44' : '#ea272a',
							borderColor: isOver ? '#34aa44' : '#ea272a',
							borderWidth: 1,
						},
					],
				};
				historyPartsMade = historyPartsMade ? { ...this.state.historyPartsMade } : {};
				if (values && values.length) {
					values.forEach(v => {
						graphData.labels.push(moment(v.timestamp).add(1, 'hours').format('hh A'));
						graphData.datasets[0].data.push(v.value);
					});
				}
				historyPartsMade = graphData;
				this.setState({ historyPartsMade });
			}
		} else if (this.props.dataCal !== prevProps.dataCal && this.props.dataCal) {
			const { properties: { planUuid, actualUuid, hoursLeft }, value, timestamp, secret, socket } = this.props.dataCal;
			const isOver = this.state.currentValues.oee >= 80;
			const graphData = {
				labels: [],
				datasets: [
					{
						label: 'Parts made',
						data: [],
						backgroundColor: isOver ? '#34aa44' : '#ea272a',
						borderColor: isOver ? '#34aa44' : '#ea272a',
						borderWidth: 1,
					},
				],
			};
			let historyPartsMade = {};
			if (value || value === 0) {
				const stop = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours');
				const thisHour = moment(timestamp).set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours');
				const addEmpty = 5 - ((stop - thisHour) / (3600 * 1000));
				let start = thisHour.subtract(addEmpty, 'hours');
				for (let i = 0; i < addEmpty; i++) {
					graphData.labels.push(start.format('hh A'));
					graphData.datasets[0].data.push(null);
					start.add(1, 'hours');
				}
				graphData.labels.push(thisHour.format('hh A'));
				graphData.datasets[0].data.push(value);
			}
			historyPartsMade = graphData;
			this.setState({ preparingHistoryPartsMade: historyPartsMade });

			// call the rest values;
			const stop = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours');
			if (hoursLeft !== 0) {
				this.props.getDatasetCal(planUuid, 'sum', secret, socket, 3600, (stop.valueOf() - (hoursLeft * 3600 * 1000)) - 1, stop.valueOf(), { planUuid, actualUuid, continueFromFirst: true });
			} else {
				this.setState({ historyPartsMade });
			}
		}
	}

	humanizeDuration = (ms) => {
		if (ms === -1) return '0:00';
		let minutes = Math.floor(ms / 60000);
		let seconds = ((ms % 60000) / 1000).toFixed(0);

		return (
			seconds == 60 ?
				(minutes + 1) + ":00" :
				minutes + ":" + (seconds < 10 ? "0" : "") + seconds
		);
	}

	componentWillUnmount() {
		clearInterval(this.dataForMachineInterval);
	}

	calculateForPartsMade = (uuid, actual, secret, socket) => {
		const actualStart = new Date(actual.start);
		const stop = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours');
		if (new Date() - actualStart > (6 * 3600 * 1000)) {
			this.props.getDatasetCal(uuid, 'sum', secret, socket, 3600, stop.valueOf() - (6 * 3600 * 1000), stop.valueOf(), { planUuid: uuid, actualUuid: actual.uuid });
		} else {
			const stopFirstPeriod = moment(actual.start).set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours');
			const hoursLeft = (stop - stopFirstPeriod) / (3600 * 1000);
			this.props.getDataCal(uuid, secret, socket, moment(actual.start).valueOf(), stopFirstPeriod, 'sum', { planUuid: uuid, actualUuid: actual.uuid, hoursLeft });
		}
	}

	findOeeHistory = (actual, isFloating) => {
		let startQuery = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).subtract(PERIODS_FOR_OEE_CHART - 1, 'hours').valueOf();
		let nextHour = moment().set({ 'minute': 0, 'second': 0, 'millisecond': 0 }).add(1, 'hours').valueOf();
		if (actual && this.props.defaultOperatorMachine) {
			this.props.calculateOee(actual.uuid, null, startQuery, nextHour, 1, { isHistory: true, machineId: this.props.defaultOperatorMachine.id }, isFloating);
		}
	}

	setupLineChartOptions = () => {
		const options = {
			elements: {
				// point: { radius: 0 }
			},
			tooltips: {
				mode: 'index',
				intersect: false,
			},
			hover: {
				mode: 'nearest',
				intersect: true
			},
			responsive: true,
			animation: false,
			maintainAspectRatio: false,
			legend: {
				position: 'top',
				display: false,
			},
			scales: {
				xAxes: [{
					display: true,
					ticks: {
						minTicksLimit: 6,
						maxTicksLimit: 6,
						callback: (dataLabel, index, labels) => {
							if (index % 60 === 0) return dataLabel;
							else return '';
						},
						fontColor: '#bdbdbd',
					}
				}],
				yAxes: [{
					display: true,
					scaleLabel: {
						display: true,
						labelString: 'OEE %',
						fontColor: '#bdbdbd',
					},
					ticks: {
						min: 0,
						stepSize: 50,
						maxTicksLimit: 3,
						fontColor: '#bdbdbd',
					}
				}]
			}
		};
		return options;
	}

	setupOptions = () => {
		const options = {
			elements: {
				// point: { radius: 0 }
			},
			tooltips: {
				mode: 'index',
				intersect: false,
			},
			hover: {
				mode: 'nearest',
				intersect: true
			},
			responsive: true,
			animation: false,
			maintainAspectRatio: false,
			legend: {
				position: 'top',
				display: false,
			},
			scales: {
				xAxes: [{
					display: true,
					gridLines: {
						color: '#2b2b30',
						zeroLineColor: '#2b2b30',
						lineWidth: 1
					},
					ticks: {
						fontColor: '#9ea0a5',
					}
				}],
				yAxes: [{
					display: true,
					gridLines: {
						color: '#2b2b30',
						zeroLineColor: '#2b2b30',
						lineWidth: 1
					},
					scaleLabel: {
						display: true,
						labelString: 'Parts made',
						fontColor: '#9ea0a5',
					},
					ticks: {
						min: 0,
						maxTicksLimit: 4,
						fontColor: '#9ea0a5',
					}
				}]
			}
		};
		return options;
	}

	isEndYet = (actual) => {
		return actual !== '0001-01-01T00:00:00Z';
	}

	render() {
		if (!this.props.defaultOperatorMachine) return (
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 100 }}>
				<div style={{ fontSize: 28, textAlign: 'center', margin: 20 }}>Please select a machine.</div>
				<SelectMachineDropdown />
			</div>
		);

		const { defaultOperatorMachine: { id, name, actuals, status_rules } } = this.props;
		const { historyPartsMade } = this.state;
		const isValuesAvailable = this.state.currentValues && Object.keys(this.state.currentValues).length > 0;

		return (
			<div className={`${styles.FullWrapper}`}
				// style={{ backgroundColor: actuals && status_rules[this.state.currentMachineStatus] ? status_rules[this.state.currentMachineStatus].color + '55' : '#9ea0a5' }}
				style={{ background: `linear-gradient(180deg, ${actuals && !this.isEndYet(actuals[0].end) && status_rules[this.state.currentMachineStatus] ? status_rules[this.state.currentMachineStatus].color : '#9ea0a555'} 10%, #222226 70%)` }}
			>
				<div className={styles.HeaderWrapper}>
					<div style={{ flex: 1 }}>
						<div className={styles.HeaderText} style={{ display: 'flex', alignItems: 'center' }}>{this.props.defaultOperatorMachine?.name}</div>
						<div className={styles.DescriptionHeader}>{actuals && !this.isEndYet(actuals[0].end) ? actuals[0].plan.po : '-'} {actuals ? moment(actuals[0].start).format(', DD MMM YYYY HH:mm') : ''}</div>
					</div>
					{actuals && !this.isEndYet(actuals[0].end) && status_rules[this.state.currentMachineStatus]
						? <div className={styles.MachineStatus}><div>{status_rules[this.state.currentMachineStatus].reason}</div></div>
						: null
					}
					<div style={{ flex: 1, display: 'flex', justifyContent: 'end' }}><SelectMachineDropdown /></div>
				</div>

				<div className={styles.MiddleWrapper}>
					<div className={styles.GaugeWrapper}>
						<GaugeChart
							value={
								isValuesAvailable
									? !this.state.displayOverHundred && this.state.currentValues.oee >= 100 ? 100 : this.state.currentValues.oee
									: 0
							}
							min={0}
							max={100}
							size={200}
							strokewidth={20}
							color={isValuesAvailable ? this.state.currentValues.oee <= 80 ? '#ea272a' : '#34aa44' : ''}
						/>
						<div className={styles.GaugeValue}>
							{
								this.state.currentValues && (this.state.currentValues.oee || this.state.currentValues.oee === 0)
									? !this.state.displayOverHundred && this.state.currentValues.oee >= 100 ? '100%' : this.state.currentValues.oee.toFixed(2) + '%'
									: '-'
							}
							<div className={styles.GaugeDetails}>OEE</div>
						</div>
					</div>
					<div className={styles.MiddleRight}>

					</div>
				</div>
				<div className={styles.InfoBoxesWrapper}>
					<div className={`${styles.InfoBox}`}>
						<div className={styles.MainWrapper}>
							<div className={styles.MainText}>{isValuesAvailable ? this.state.currentValues.expectedCycle : '-'}</div>
							<div style={{ textAlign: 'center' }}>Expected</div>
						</div>
						<div className={styles.SubInfos}>
							<div style={{ display: 'flex' }}><span className={styles.LeftSub}>{isValuesAvailable ? this.state.currentValues.lastCycle : '-'}</span><span style={{ flex: 2 }}>Last Cycle</span></div>
							<div style={{ display: 'flex' }}><span className={styles.LeftSub}>{isValuesAvailable ? this.state.currentValues.lastFew : '-'}</span><span style={{ flex: 2 }}>Last Few</span></div>
							<div style={{ display: 'flex' }}><span className={styles.LeftSub}>{isValuesAvailable ? this.state.currentValues.averageCycle : '-'}</span><span style={{ flex: 2 }}>Average</span></div>
						</div>
					</div>
					<div className={`${styles.InfoBox}`}>
						<div className={styles.MainWrapper}>
							<div className={styles.MainText}>
								{this.state.currentValues && (this.state.currentValues.oee || this.state.currentValues.oee === 0)
									? !this.state.displayOverHundred && this.state.currentValues.oee >= 100
										? this.state.currentValues.oee > 100 ? '> 100%' : '100%'
										: this.state.currentValues.oee.toFixed(2) + '%'
									: '-'}
							</div>
							<div style={{ textAlign: 'center' }}>OEE</div>
						</div>
						<div className={styles.SubInfos}>
							<div style={{ display: 'flex' }}><span className={styles.LeftSub}>{isValuesAvailable && (this.state.currentValues.a || this.state.currentValues.a === 0)
								? !this.state.displayOverHundred && this.state.currentValues.a >= 100
									? this.state.currentValues.a > 100 ? '> 100%' : '100%'
									: this.state.currentValues.a.toFixed(2) + '%'
								: '-'}</span><span style={{ flex: 2 }}>Availability</span></div>
							<div style={{ display: 'flex' }}><span className={styles.LeftSub}>{isValuesAvailable && (this.state.currentValues.p || this.state.currentValues.p === 0)
								? !this.state.displayOverHundred && this.state.currentValues.p >= 100
									? this.state.currentValues.p > 100 ? '> 100%' : '100%'
									: this.state.currentValues.p.toFixed(2) + '%'
								: '-'}</span><span style={{ flex: 2 }}>Performance</span></div>
							<div style={{ display: 'flex' }}><span className={styles.LeftSub}>{isValuesAvailable && (this.state.currentValues.q || this.state.currentValues.q === 0)
								? !this.state.displayOverHundred && this.state.currentValues.q >= 100
									? this.state.currentValues.q > 100 ? '> 100%' : '100%'
									: this.state.currentValues.q.toFixed(2) + '%'
								: '-'}</span><span style={{ flex: 2 }}>Quality</span></div>
						</div>
					</div>
					<div className={`${styles.InfoBox}`}>
						<div className={styles.MainWrapper}>
							<div className={styles.MainText}>{actuals && isValuesAvailable ? actuals[0].target.toLocaleString() : '-'}</div>
							<div style={{ textAlign: 'center' }}>Targets</div>
						</div>
						<div className={styles.SubInfos}>
							<div style={{ display: 'flex' }}><span className={styles.LeftSub}>{isValuesAvailable ? this.state.currentValues.good?.toLocaleString() : '-'}</span><span style={{ flex: 2 }}>Good</span></div>
							<div style={{ display: 'flex' }}><span className={styles.LeftSub}>{isValuesAvailable ? this.state.currentValues.defect?.toLocaleString() : '-'}</span><span style={{ flex: 2 }}>Defect</span></div>
							<div style={{ display: 'flex' }}><span className={styles.LeftSub}>{actuals && isValuesAvailable ? (actuals[0].target - this.state.currentValues.total).toLocaleString() : '-'}</span><span style={{ flex: 2 }}>Remainings</span></div>
						</div>
					</div>
				</div>

				<div className={styles.Chart1}>
					{actuals && this.state.historyOEE ? <Line data={this.state.historyOEE} height={150} options={this.setupLineChartOptions()} /> : null}

				</div>
				<div className={styles.Chart2}>
					{historyPartsMade && historyPartsMade.labels.length === 6 ? <Bar data={historyPartsMade} height={150} options={this.setupOptions()} /> : ''}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { currentOrg } = state.org;
	const { defaultOperatorMachine, machinesAndActuals } = state.machine;
	const { dataEval, dataLatest, datasetCal, dataCal } = state.data;
	const { actualOee } = state.advanceActualProduction;
	const { moduleConfigs } = state.module;

	return {
		currentOrg, defaultOperatorMachine: defaultOperatorMachine[currentOrg], machinesAndActuals,
		dataEval, dataLatest, actualOee, datasetCal, dataCal, moduleConfigs
	};
}

export default connect(mapStateToProps, { getDataEval, getDataLatest, calculateOee, getDatasetCal, getDataCal, setDefaultOperatorMachine, getMachinesAndActuals })(MachineOverview);

