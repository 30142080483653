export const GET_COUNTING_DEVICES_LIST_START = 'GET_COUNTING_DEVICES_LIST_START';
export const GET_COUNTING_DEVICES_LIST = 'GET_COUNTING_DEVICES_LIST';
export const GET_COUNTING_DEVICES_LIST_SUCCESS = 'GET_COUNTING_DEVICES_LIST_SUCCESS';
export const GET_COUNTING_DEVICES_LIST_FAIL = 'GET_COUNTING_DEVICES_LIST_FAIL';

export const ADD_COUNTING_DEVICES_START = 'ADD_COUNTING_DEVICES_START';
export const ADD_COUNTING_DEVICES = 'ADD_COUNTING_DEVICES';
export const ADD_COUNTING_DEVICES_SUCCESS = 'ADD_COUNTING_DEVICES_SUCCESS';
export const ADD_COUNTING_DEVICES_FAIL = 'ADD_COUNTING_DEVICES_FAIL';

export const EDIT_COUNTING_DEVICES_START = 'EDIT_COUNTING_DEVICES_START';
export const EDIT_COUNTING_DEVICES = 'EDIT_COUNTING_DEVICES';
export const EDIT_COUNTING_DEVICES_SUCCESS = 'EDIT_COUNTING_DEVICES_SUCCESS';
export const EDIT_COUNTING_DEVICES_FAIL = 'EDIT_COUNTING_DEVICES_FAIL';

export const DELETE_COUNTING_DEVICES_START = 'DELETE_COUNTING_DEVICES_START';
export const DELETE_COUNTING_DEVICES = 'DELETE_COUNTING_DEVICES';
export const DELETE_COUNTING_DEVICES_SUCCESS = 'DELETE_COUNTING_DEVICES_SUCCESS';
export const DELETE_COUNTING_DEVICES_FAIL = 'DELETE_COUNTING_DEVICES_FAIL';