import React, { Component } from 'react';
import { connect } from 'react-redux';
// import MultipleSelect from 'react-select';
// import moment from 'moment';
// import _ from 'lodash';

import styles from '../Report.module.scss';
import PresetCard from './PresetCard';
import SideDrawer from '../../Navigation/SideDrawer/SideDrawer';
import PresetDrawer from '../PresetDrawer/PresetDrawer';
import DeleteModal from '../../shared/DeleteModal/DeleteModal';
import LoadingPresetCard from '../../../components/UI/LoadingSkeleton/LoadingPresetCard/LoadingPresetCard';
import { SnackBar } from '../../UI/SnackBar/SnackBar';
import { ADD_REPORT_PRESET_SUCCESS, EDIT_REPORT_PRESET_SUCCESS } from '../../../../state/ducks/ReportPreset/types';

import { getReportPresets, editReportPreset, deleteReportPreset } from '../../../../state/ducks/ReportPreset/actions';

const sortingArray = (array, type) => {
  let sortedPreset = array;
  switch (type) {
    // Default: Ordered by preset id in descending
    case 0:
      sortedPreset.sort(function (a, b) {
        return b.id - a.id;
      });
      break;
    // A-Z
    case 1:
      sortedPreset.sort(function (a, b) {
        let nameA = a.name.toUpperCase();
        let nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      break;
    // Z-A
    case 2:
      sortedPreset.sort(function (a, b) {
        let nameA = a.name.toUpperCase();
        let nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      break;
    // Oldest by last_updated
    case 3:
      sortedPreset.sort(function (a, b) {
        let dateA = new Date(a.last_updated);
        let dateB = new Date(b.last_updated);
        return dateA - dateB
      });
      break;
    // Newest by last_updated
    case 4:
      sortedPreset.sort(function (a, b) {
        let dateA = new Date(a.last_updated);
        let dateB = new Date(b.last_updated);
        return dateB - dateA
      });
      break;
    default:
      break;
  }
  return sortedPreset
}

class PresetReport extends Component {
  snackbarRef = React.createRef();

  state = {
    presetList: this.props.reportPresets ? this.props.reportPresets : [],
    presetDrawerOpened: false,
    selectedPreset: null,
    isDeleteModalOpened: false,
    deletingPreset: null,
    firstLoad: false,
  };

  componentDidMount() {
    const { currentOrg } = this.props;
    this.props.getReportPresets(currentOrg);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.props.getReportPresets(this.props.currentOrg);
    } else {
      if (prevProps.result === null && this.props.result === 'success') {
        this.props.getReportPresets(this.props.currentOrg);
      }

      // First load handler, also no exist preset handler for Report page
      if (prevProps.loading === true && this.props.loading === false) {
        this.props.sortingOptionDisplay(this.props.reportPresets.length)
        this.setState({
          firstLoad: true,
        })
      }

      // Preset refresh handler
      if (prevProps.reportPresets !== this.props.reportPresets) {
        let sortedPreset = sortingArray(this.props.reportPresets, this.props.sortingOption);
        this.setState({
          presetList: sortedPreset
        })
      }

      // Sorting handler
      if (prevProps.sortingOption !== this.props.sortingOption) {
        let sortedPreset = sortingArray(this.state.presetList, this.props.sortingOption);
        this.setState({
          presetList: sortedPreset
        })
      }

      // Handler for preset CRUD response
      if (prevProps.result === null && this.props.result === 'success') {
        if (this.props.type === ADD_REPORT_PRESET_SUCCESS) {
          this.snackbarRef.current.openSnackBar('Preset has been saved');
        } else if (this.props.type === EDIT_REPORT_PRESET_SUCCESS) {
          this.snackbarRef.current.openSnackBar('Preset has been saved');
        }
      }
    }
  }

  componentWillUnmount() {
    this.snackbarRef.current = null;
    this.snackbarRef = null;
  }

  presetDrawerToggleHandler = (preset) => {
    this.setState(prevState => ({
      presetDrawerOpened: !prevState.presetDrawerOpened,
      selectedPreset: preset ? preset : prevState.preset,
    }));
  }

  deleteModalClickHandler = (preset) => {
    this.setState(prevState => {
      return {
        isDeleteModalOpened: !prevState.isDeleteModalOpened,
        deletingPreset: preset,
      }
    });
  }

  onConfirmDeletePreset = (event) => {
    event.preventDefault();
    if (this.state.deletingPreset) {
      const { id } = this.state.deletingPreset;
      this.props.deleteReportPreset(id);
      this.setState({ deletingPreset: null, isDeleteModalOpened: false });
    }
  }

  render() {
    // const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    const { firstLoad, presetList } = this.state;
    const { loading, loggedInUser, currentOrg } = this.props;

    return (
      <div>
        <SnackBar ref={this.snackbarRef} type="success" />

        {
          (!firstLoad || loading) ?
            <LoadingPresetCard />
            :
            (firstLoad && (!presetList || presetList.length === 0)) ?
              <div className={styles.NoPresetWarning}>
                <p>No preset report found.</p>
                <p>You can create a preset report in data report page and pareto report page.</p>
              </div>
              :
              presetList.map((preset, index) => {
                return (
                  <PresetCard
                    key={'preset' + index}
                    loggedInUser={loggedInUser}
                    currentOrg={currentOrg}
                    cardName={preset.name}
                    lastUpdated={preset.last_updated}
                    createdUser={preset.created_user}
                    presetType={preset.type}
                    deletePresetHandler={() => this.deleteModalClickHandler(preset)}
                    editPresetHandler={() => this.presetDrawerToggleHandler(preset)}
                    viewPresetHandler={() => this.props.viewPresetHandler(preset)} />
                )
              })
        }

        <SideDrawer
          noHeader
          open={this.state.presetDrawerOpened}
          closed={this.presetDrawerToggleHandler}
        >
          {
            this.state.presetDrawerOpened ?
              <PresetDrawer
                closed={this.presetDrawerToggleHandler}
                orgId={this.props.pageAndMembers ? this.props.pageAndMembers.page.org_id : null}
                presetDrawerToggle={this.presetDrawerToggleHandler}
                preset={this.state.selectedPreset}
                onDelete={() => this.deleteModalClickHandler(this.state.selectedPreset)}
              />
              : null
          }
        </SideDrawer>

        {/* {
          this.state.isDeleteModalOpened ? */}
        <DeleteModal
          title="widget"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeletePreset}
        />
        {/* :
            null
        } */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { currentOrg } = state.org;
  const { loading, reportPresets, result, type } = state.reportPreset;
  return {
    loggedInUser, currentOrg, loading, reportPresets, result, type
  };
};

export default connect(mapStateToProps, { getReportPresets, editReportPreset, deleteReportPreset })(PresetReport);