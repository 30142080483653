import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../state/ducks/index';

import styles from './CreateOrg.module.scss';
import Button from '../../components/UI/Button/Button';
import InputLogo from '../../../assets/icons/Organization/avatar.svg';
import DeleteLogo from '../../../assets/icons/Organization/delete.svg';
import Input from '../../components/UI/Input/Input';
import Select from '../../components/UI/Select/Select';

import { checkValidity, updateObject } from '../../../state/utils';
import { addOrg } from '../../../state/ducks/Organization/actions';
import { storage } from '../../../firebase/index';
import { signout } from '../../../state/ducks/Auth/actions';

const countryOptions = [
  { id: 'Thailand', name: '🇹🇭 Thailand' },
];

const DEFAULT_CREATE_ORG_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  tax_id: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  },
  street_address: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  province: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  country: {
    value: '-1',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  post_number: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  tel: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  },
};

class CreateOrg extends Component {
  state = {
    formIsValid: false,
    createOrgForm: DEFAULT_CREATE_ORG_FORM,
    logo: null,
    uploading: false,
    imgsrc: null,
  };

  componentDidMount() {
    if (this.props.currentOrg) {
      history.push('/pages');
    }
  }

  handleChange = event => {
    let updatedElement = updateObject(this.state.createOrgForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.createOrgForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.createOrgForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ createOrgForm: updatedForm, formIsValid });
  };

  onSelectFile = (event) => {
    if (this.state.imgsrc) {
      URL.revokeObjectURL(this.state.imgsrc);
      this.setState({
        imgsrc: URL.createObjectURL(event.target.files[0]),
        logo: event.target.files[0]
      })
    }
    else {
      this.setState({
        imgsrc: URL.createObjectURL(event.target.files[0]),
        logo: event.target.files[0]
      })
    }
  }

  onClearFile = (event) => {
    event.target.value = null;
  }

  onDeleteSelectLogo = () => {
    if (this.state.imgsrc) {
      URL.revokeObjectURL(this.state.imgsrc);
    }
    this.setState({ logo: null, imgsrc: null })
  }

  onCreateOrgHandler = (event) => {
    event.preventDefault();
    const { name, tax_id, street_address, post_number, province, country, tel } = this.state.createOrgForm;
    const { logo } = this.state;

    if (logo) {
      const uploadTask = storage.ref(`images/${logo.name}`).put(logo);
      uploadTask.on('state_changed',
        (snapshot) => {
          this.setState({ uploading: true })
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage.ref('images').child(logo.name).getDownloadURL().then(url => {
            this.setState({ uploading: false })
            this.props.addOrg(name.value, street_address.value, province.value, country.value, post_number.value, tax_id.value, tel.value, url);
          })
        });

    }
    else {
      this.props.addOrg(name.value, street_address.value, province.value, country.value, post_number.value, tax_id.value, tel.value, logo);
    }
  }

  onClickSignout = () => {
    this.props.signout();
  }

  render() {
    const { name, tax_id, street_address, post_number, province, country, tel } = this.state.createOrgForm;
    return (
      <div className={styles.Org}>
        <div className={styles.OrgBox}>
          <div className={styles.OrgBoxContainer}>
            <div className={styles.HeaderWrapper}>
              <div className={styles.Header}>Your First Organization Profile</div>
              <div className={styles.Description}>You can create a new organization or wait for an invitation.</div>
            </div>
            <form onSubmit={this.onCreateOrgHandler}>
              <div className={styles.FormWrapper}>
                <div style={{ display: 'flex' }}>
                  <div className={styles.FormGroup} style={{ marginRight: 15 }}>
                    <Input
                      label="name"
                      name="name"
                      type="text"
                      value={name.value}
                      onChange={this.handleChange}
                      overidestyleformgroup={{ marginBottom: 0 }}
                      error={`${name.touched && !name.valid ? 'Name is required' : ''}`}
                      whitemode="true"
                    />
                  </div>
                  <div className={styles.FormGroup}>
                    <Input
                      label="tax id"
                      name="tax_id"
                      type="text"
                      placeholder="(optional)"
                      value={tax_id.value}
                      onChange={this.handleChange}
                      overidestyleformgroup={{ marginBottom: 0 }}
                      whitemode="true"
                    />
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  <div className={styles.FormGroup} style={{ marginRight: 15 }}>
                    <Input
                      label="address"
                      name="street_address"
                      type="text"
                      value={street_address.value}
                      onChange={this.handleChange}
                      overidestyleformgroup={{ marginBottom: 0 }}
                      error={`${street_address.touched && !street_address.valid ? 'Address is required' : ''}`}
                      whitemode="true"
                    />
                  </div>
                  <div className={styles.FormGroup}>
                    <Input
                      label="postal code"
                      name="post_number"
                      type="text"
                      value={post_number.value}
                      onChange={this.handleChange}
                      overidestyleformgroup={{ marginBottom: 0 }}
                      error={`${post_number.touched && !post_number.valid ? 'Postal Code is required' : ''}`}
                      whitemode="true"
                    />
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  <div className={styles.FormGroup} style={{ marginRight: 15 }}>
                    <Select
                      label="country"
                      name="country"
                      placeholder="Select a country"
                      value={country.value}
                      options={countryOptions}
                      onChange={this.handleChange}
                      overidestyleformgroup={{ marginBottom: 0 }}
                      whitemode />
                  </div>
                  <div className={styles.FormGroup}>
                    <Input
                      label="city"
                      name="province"
                      type="text"
                      value={province.value}
                      onChange={this.handleChange}
                      overidestyleformgroup={{ marginBottom: 0 }}
                      error={`${province.touched && !province.valid ? 'City is required' : ''}`}
                      whitemode="true"
                    />
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  <div className={styles.FormGroup} style={{ marginRight: 15 }}>
                    <div className={styles.FormAddLogo}>
                      <label htmlFor="logo" className={styles.CustomFileUpload} style={{ display: 'flex' }}>
                        <div>{this.state.imgsrc ? <img src={this.state.imgsrc} className={styles.ImagePreview} alt="Preview" /> : <img src={InputLogo} alt="Add Logo" />}</div>
                        <div className={styles.AddLogo}>{this.state.logo ? this.state.logo.name : "Add a Logo"}</div>
                      </label>
                      <input
                        name="logo"
                        id="logo"
                        type="file"
                        onChange={this.onSelectFile}
                        onClick={this.onClearFile}
                        accept=".png, .jpg, .jpeg"
                      />
                      <div className={styles.ShowSelectedFile}><img src={DeleteLogo} onClick={this.onDeleteSelectLogo} alt="Bin Icon" /></div>
                    </div>
                  </div>
                  <div className={styles.FormGroup}>
                    <Input
                      label="phone number"
                      name="tel"
                      type="text"
                      placeholder="(optional)"
                      value={tel.value}
                      onChange={this.handleChange}
                      overidestyleformgroup={{ marginBottom: 0 }}
                      whitemode="true"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.ButtonWrapper}>
                <Button
                  type="submit"
                  color="primary"
                  name={this.props.loading || this.state.uploading ? 'Loading...' : 'Save Settings'}
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading || this.state.uploading}
                  noMargin
                />
              </div>
            </form>
          </div>
          <div className={styles.SwapToSignOut}>
            <div>Would you like to change an account? <span className={styles.SwapText} onClick={this.onClickSignout}>Sign out!</span></div>
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  const { loading, currentOrg } = state.org;
  return { loading, currentOrg };
};

export default connect(mapStateToProps, { addOrg, signout })(CreateOrg);
