export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';

export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';

export const RENEW_TOKEN = 'RENEW_TOKEN';
export const RENEW_TOKEN_SUCCESS = 'RENEW_TOKEN_SUCCESS';

export const VERIFIED_USER_START = "VERIFIED_USER_START";
export const VERIFIED_USER = 'VERIFIED_USER';
export const VERIFIED_USER_SUCCESS = 'VERIFIED_USER_SUCCESS';
export const VERIFIED_USER_FAIL = 'VERIFIED_USER_FAIL';

export const RESEND_VERIFY_START = 'RESEND_VERIFY_START';
export const RESEND_VERIFY = 'RESEND_VERIFY';
export const RESEND_VERIFY_SUCCESS = 'RESEND_VERIFY_SUCCESS';
export const RESEND_VERIFY_FAIL = 'RESEND_VERIFY_FAIL';

export const FORGET_PASSWORD_START = 'FORGET_PASSWORD_START';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const GET_LOGGEDIN_USER_START = 'GET_LOGGEDIN_USER_START';
export const GET_LOGGEDIN_USER = 'GET_LOGGEDIN_USER';
export const GET_LOGGEDIN_USER_SUCCESS = 'GET_LOGGEDIN_USER_SUCCESS';
export const GET_LOGGEDIN_USER_FAIL = 'GET_LOGGEDIN_USER_FAIL';

export const EDIT_USER_START = 'EDIT_USER_START';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';