import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import styles from './Operator.module.scss';
import Topbar from '../../components/Navigation/Topbar/Topbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';

import { getNoteTypes, deleteDocumentNote } from '../../../state/ducks/Document/actions';
import { getProductionPlanStructures } from '../../../state/ducks/AdvanceProductionPlanStructure/actions';
import { getAdvanceProductionPlans } from '../../../state/ducks/AdvanceProductionPlan/actions';
import { startPlan, stopPlan } from '../../../state/ducks/AdvanceActualProduction/actions';
import { getModuleConfigs } from '../../../state/ducks/Module/actions';
import { getMachinesAndActuals, setDefaultOperatorMachine } from '../../../state/ducks/Machine/actions';

import StartOperatorDrawer from '../../components/Operator/StartOperatorDrawer/StartOperatorDrawer';
import StopOperatorDrawer from '../../components/Operator/StopOperatorDrawer/StopOperatorDrawer';
import StartStopPoModal from '../../components/Operator/StartStopPoModal/StartStopPoModal';
import OperatorTable from '../../components/Operator/OperatorTable/OperatorTable';
import SearchDrawer from '../../components/Operator/SearchDrawer/SearchDrawer';
import SelectMachineDropdown from '../../components/Operator/SelectMachineDropdown/SelectMachineDropdown';

import SelectedJobsIcon from '../../../assets/icons/Operator/job-blue.svg';
import JobsIcon from '../../../assets/icons/Operator/job-gray.svg';
import MachineIcon from '../../../assets/icons/Operator/machine-gray.svg';
import SelectedMachineIcon from '../../../assets/icons/Operator/machine-blue.svg';
import BreakdownIcon from '../../../assets/icons/Operator/breakdown-gray.png';
import SelectedBreakdownIcon from '../../../assets/icons/Operator/breakdown-blue.png';
import MachineOverview from '../../components/Operator/MachineOverview/MachineOverview';
import ScannerModal from '../../components/Operator/StartStopPoModal/ScannerModal';
import BreakdownLog from '../../components/Operator/BreakdownLog/BreakdownLog';
import ProductionLogDrawer from '../../components/Operator/StopOperatorDrawer/ProductionLogDrawer';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import OperatorWs from './OperatorWs';
import BreakdownSnackbar from '../../components/Operator/BreakdownSnackbar/BreakdownSnackbar';

class Operator extends Component {
	state = {
		currentStructureIndex: 0,
		isDrawerOpened: false,
		isModalOpened: false,
		selectingPlan: null,
		selectingActual: null,
		startPlanProps: null,
		stopPlanProps: null,
		drawerType: '',
		headers: [],
		isLastAdvSearch: false,
		mainTab: 0,
		isScannerModalOpened: false,
		scannerText: '',
		updatedCustomColumns: {},
		isDrawerProdDocOpened: false,
		isProdDocAdding: false,
		editingProdDoc: null,
		isDeleteProdDocModalOpened: false,
		deletingProdDocUuid: null,
		happeningEvents: [],
		fillDocUuid: null
	}

	componentDidMount() {
		this.props.getMachinesAndActuals(this.props.currentOrg);
		this.props.getProductionPlanStructures(this.props.currentOrg);
		this.props.getNoteTypes(this.props.currentOrg);
		this.props.getModuleConfigs(this.props.currentOrg, 3); // 3: pcs module

		this.getMachinesInterval = setInterval(() => {
			this.props.getMachinesAndActuals(this.props.currentOrg);
		}, 10 * 1000);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.currentOrg !== this.props.currentOrg) {
			this.props.getMachinesAndActuals(this.props.currentOrg);
			this.props.getProductionPlanStructures(this.props.currentOrg);
			this.setState({ mainTab: 0 });
		} else if (this.props.startStopResult !== prevProps.startStopResult && this.props.startStopResult === 'success') {
			if ((prevProps.startedProduction !== this.props.startedProduction) || (prevProps.stoppedProduction !== this.props.stoppedProduction)) {
				this.props.getMachinesAndActuals(this.props.currentOrg);
			}
		} else if (this.props.machines && !_.isEqual(prevProps.machines, this.props.machines)) {
			let defaultMachines = JSON.parse(localStorage.getItem("OwlOperatorDefaultMachine"));
			if (defaultMachines && defaultMachines[this.props.currentOrg] && Object.keys(defaultMachines[this.props.currentOrg]).length) {
				// selecting a machine on local storage.
				const foundMachine = this.props.machines.find(m => m.id === defaultMachines[this.props.currentOrg].id);
				if (!_.isEqual(defaultMachines[this.props.currentOrg], foundMachine)) {
					defaultMachines[this.props.currentOrg] = foundMachine;
					this.props.setDefaultOperatorMachine(JSON.stringify(defaultMachines));
				}
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.getMachinesInterval);
	}

	onMessage = (message) => {
		if (+message.level === 0) {
			// catching on only breakdown events.
			const happeningEvents = [...this.state.happeningEvents];
			happeningEvents.push(message);
			this.setState({ happeningEvents });
		}
	}

	toggleDrawerHandler = (which) => {
		if (which === 'startpo' || which === 'stoppo') {
			this.setState({ selectingPlan: null, selectingActual: null });
		}
		this.setState(prevState => { return { isDrawerOpened: !prevState.isDrawerOpened, drawerType: which } });
		if (this.state.isDrawerOpened) {
			this.setState({ isDrawerProdDocOpened: false });
		}
	}

	modalClickHandler = () => {
		this.setState(prevState => {
			return { isModalOpened: !prevState.isModalOpened }
		});
	}

	prepareToStartPlanHandler = (form) => {
		this.setState({ startPlanProps: form, isModalOpened: true });
	}

	prepareToStopPlanHandler = (form, updatedCustomColumns) => {
		this.setState({ stopPlanProps: form, updatedCustomColumns, isModalOpened: true });
	}

	onSearchPlansHandler = (e, searchTexts) => {
		e.preventDefault();
		const others = {};
		Object.keys(searchTexts).forEach(key => {
			if (key !== 'po' && key !== 'start' && key !== 'end' && key !== 'target' && key !== 'machine' && searchTexts[key] !== '') {
				others[key] = searchTexts[key];
			}
		});
		this.props.getAdvanceProductionPlans(
			this.props.currentOrg,
			this.props.planStructures[this.state.currentStructureIndex].uuid,
			moment(searchTexts.start),
			moment(searchTexts.end).endOf('day').valueOf(),
			false,
			searchTexts.po ? searchTexts.po : '',
			searchTexts.target ? searchTexts.target : '',
			searchTexts.machine ? searchTexts.machine.id : '',
			others,
			true
		);
		this.toggleDrawerHandler();
		this.setState({ isLastAdvSearch: true });
	}

	onConfirmPlanHandler = () => {
		this.modalClickHandler();
		if (!this.state.selectingActual) {
			const { machine, startDate, startTime, newTargets } = this.state.startPlanProps;
			this.props.startPlan(this.state.selectingPlan.uuid, moment(startDate.value + ' ' + startTime.value), machine.value.id, newTargets.value);
			this.toggleDrawerHandler();
			this.setState({ startPlanProps: null });
		} else {
			const { total, ok, defect } = this.state.stopPlanProps;
			this.props.stopPlan(
				this.state.selectingActual.uuid,
				total.value || +total.value === 0 ? +total.value : null,
				ok.value || +ok.value === 0 ? +ok.value : null,
				defect.value || +defect.value === 0 ? +defect.value : null,
				this.state.updatedCustomColumns
			);
			this.toggleDrawerHandler();
			this.setState({ stopPlanProps: null, selectingActual: null });
		}
	}

	isEndYet = (time) => {
		return time !== '0001-01-01T00:00:00Z';
	}

	toggleScannerHandler = () => {
		this.setState(prevState => {
			return { isScannerModalOpened: !prevState.isScannerModalOpened }
		});
	}

	addProductionNoteHandler = () => {
		this.setState({ isProdDocAdding: true, editingProdDoc: null, isDrawerProdDocOpened: true, deletingProdDocUuid: null });
	}

	toggleProductionNoteHandler = () => {
		this.setState(prevState => { return { isDrawerProdDocOpened: !prevState.isDrawerProdDocOpened } });
	}

	onEditProdDocHandler = (doc) => {
		this.setState({ isProdDocAdding: false, editingProdDoc: doc, isDrawerProdDocOpened: true, deletingProdDocUuid: null });
	}

	onConfirmDeleteProdDoc = () => {
		this.props.deleteDocumentNote(this.state.deletingProdDocUuid);
		this.setState({ isProdDocAdding: false, editingProdDoc: null, isDeleteProdDocModalOpened: false, deletingProdDocUuid: null });
	}

	onDeleteProdDocHandler = (doc) => {
		this.setState({ deletingProdDocUuid: doc.uuid, isDeleteProdDocModalOpened: true });
	}

	fillInTheDocHandler = (i) => {
		// go to breakdown log;
		this.setState({ mainTab: 2, happeningEvents: [], fillDocUuid: this.state.happeningEvents[i].uuid });
	}

	alreadyFillInDocHandler = () => {
		this.setState({ fillDocUuid: null });
	}

	closeSomeHappeningEventsHandler = (e, i) => {
		e.stopPropagation();
		const happeningEvents = [...this.state.happeningEvents];
		happeningEvents.splice(i, 1);
		this.setState({ happeningEvents });
	}

	render() {
		return (
			<>
				<OperatorWs currentOrg={this.props.currentOrg} authToken={localStorage.getItem("owlJwtToken")} onMessage={this.onMessage} />
				<div className={styles.WrapperForSnackbar}>
					<BreakdownSnackbar
						machines={this.props.machines}
						events={this.state.happeningEvents}
						fillTheDoc={this.fillInTheDocHandler}
						closed={this.closeSomeHappeningEventsHandler}
					/>
				</div>
				<Topbar />
				<SideDrawer overrideStyles={{ width: '100%', maxWidth: '95%', paddingRight: 400, zIndex: this.state.isScannerModalOpened ? 1000 : 1001 }}
					title="Production Document Note"
					open={this.state.isDrawerProdDocOpened}
					closed={this.toggleProductionNoteHandler}
				>
					<ProductionLogDrawer
						mainTab={this.state.mainTab}
						isAdding={this.state.isProdDocAdding}
						prodDocDrawerClosed={this.toggleProductionNoteHandler}
						editingLog={this.state.editingProdDoc}
						selectingActual={this.state.selectingActual}
						toggleDeleteModal={this.onDeleteProdDocHandler}
						toggleScannerHandler={this.toggleScannerHandler}
						scannerText={this.state.scannerText}
						clearScannerText={() => this.setState({ scannerText: '' })}
					/>
				</SideDrawer>
				<SideDrawer
					title={
						this.state.drawerType
							? this.state.drawerType === 'search'
								? 'Search Plans'
								: this.state.drawerType === 'startpo' ? 'Start Production Plan' : 'Stop Production Plan'
							: !this.state.selectingActual ? 'Start Production Plan' : 'Stop Production Plan'
					}
					open={this.state.isDrawerOpened}
					closed={this.toggleDrawerHandler}
					overrideStyles={{ zIndex: this.state.isScannerModalOpened ? 1000 : 1001 }}
				>

					{
						this.state.drawerType
							? this.state.drawerType === 'search'
								? <SearchDrawer columns={this.state.headers} searchPlans={this.onSearchPlansHandler} closed={this.toggleDrawerHandler} />
								: this.state.drawerType === 'startpo'
									? <StartOperatorDrawer
										scannerText={this.state.scannerText}
										clearScannerText={() => this.setState({ scannerText: '' })}
										newForm={true}
										closed={this.toggleDrawerHandler}
										confirmStart={this.prepareToStartPlanHandler}
										selectingPlan={this.state.selectingPlan}
										setSelectingPlan={(e) => this.setState({ selectingPlan: e })}
										toggleScannerHandler={this.toggleScannerHandler}
										structure={this.props.planStructures ? this.props.planStructures[this.state.currentStructureIndex] : null}
									/>
									: <StopOperatorDrawer
										scannerText={this.state.scannerText}
										clearScannerText={() => this.setState({ scannerText: '' })}
										newForm={true}
										drawerOpened={this.state.isDrawerOpened}
										closed={this.toggleDrawerHandler}
										confirmStop={this.prepareToStopPlanHandler}
										setSelectingActual={(e) => this.setState({ selectingActual: e })}
										toggleScannerHandler={this.toggleScannerHandler}
										addProductionNote={this.addProductionNoteHandler}
										mainTab={this.state.mainTab}
										editProdDoc={this.onEditProdDocHandler}
										deleteProdDoc={this.onDeleteProdDocHandler}
									/>
							: !this.state.selectingActual
								? <StartOperatorDrawer
									closed={this.toggleDrawerHandler}
									selectingPlan={this.state.selectingPlan}
									confirmStart={this.prepareToStartPlanHandler}
									structure={this.props.planStructures ? this.props.planStructures[this.state.currentStructureIndex] : null}
								/>
								: <StopOperatorDrawer
									drawerOpened={this.state.isDrawerOpened}
									closed={this.toggleDrawerHandler}
									selectedPlan={this.state.selectingPlan}
									selectedActual={this.state.selectingActual}
									confirmStop={this.prepareToStopPlanHandler}
									addProductionNote={this.addProductionNoteHandler}
									mainTab={this.state.mainTab}
									editProdDoc={this.onEditProdDocHandler}
									deleteProdDoc={this.onDeleteProdDocHandler}
								/>
					}
				</SideDrawer>
				{
					this.state.mainTab === 0
						? <div style={{ margin: '90px 30px 0 30px' }}>
							<div className={styles.TopSection}>
								<div className={styles.MenuWrapper}>
									{
										this.props.planStructures ? this.props.planStructures.map((pl, index) => (
											<div key={`structure-${index}`} className={index === this.state.currentStructureIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentStructureIndex: index })}>
												<span className={styles.MenuTitle}>{pl.name}</span>
											</div>
										)) : null
									}
								</div>
								<SelectMachineDropdown />
							</div>

							<OperatorTable
								clearSelection={this.state.drawerType === 'startpo' || this.state.drawerType === 'stoppo' ? true : false}
								structure={this.props.planStructures ? this.props.planStructures[this.state.currentStructureIndex] : null}
								selectActual={(value) => this.setState({ selectingActual: value })}
								selectPlan={(value) => this.setState({ selectingPlan: value })}
								openPlanDrawer={this.toggleDrawerHandler}
								openAdvancedSearchDrawer={() => this.toggleDrawerHandler('search')}
								setHeaders={(headers) => this.setState({ headers })}
								isLastAdvSearch={this.state.isLastAdvSearch}
							/>
						</div>
						: this.state.mainTab === 1
							? <div style={{ margin: '62px 0px 74px 0px' }}>
								<MachineOverview />
							</div>
							: <div style={{ margin: '90px 30px 0 30px' }}>
								<BreakdownLog
									fillDocUuid={this.state.fillDocUuid}
									alreadyFillIn={this.alreadyFillInDocHandler}
									toggleScannerHandler={this.toggleScannerHandler}
									scannerText={this.state.scannerText}
									clearScannerText={() => this.setState({ scannerText: '' })}
								/>
							</div>
				}

				<div className={styles.BottomMenuBar}>
					<div className={`${styles.MenuWrapper} ${this.state.mainTab === 0 ? styles.Selected : ''}`} onClick={() => this.setState({ mainTab: 0 })}>
						<img src={this.state.mainTab === 0 ? SelectedJobsIcon : JobsIcon} style={{ width: 20, marginBottom: 5 }}></img> Jobs
					</div>
					<div className={`${styles.MenuWrapper} ${this.state.mainTab === 1 ? styles.Selected : ''}`} onClick={() => this.setState({ mainTab: 1 })}>
						<img src={this.state.mainTab === 1 ? SelectedMachineIcon : MachineIcon} style={{ width: 20, marginBottom: 5 }}></img> Machine Status
					</div>
					<div className={`${styles.MenuWrapper} ${this.state.mainTab === 2 ? styles.Selected : ''}`} onClick={() => this.setState({ mainTab: 2 })}>
						<img src={this.state.mainTab === 2 ? SelectedBreakdownIcon : BreakdownIcon} style={{ width: 20, marginBottom: 5 }}></img> Breakdown Log
					</div>
				</div>
				<StartStopPoModal
					type={this.state.drawerType ? this.state.drawerType === 'startpo' ? 'start' : 'stop' : !this.state.selectingActual ? 'start' : 'stop'}
					planProps={this.state.startPlanProps ? this.state.startPlanProps : this.state.stopPlanProps}
					open={this.state.isModalOpened}
					modalClosed={this.modalClickHandler}
					confirm={this.onConfirmPlanHandler}
				/>
				{
					this.state.isScannerModalOpened &&
					<ScannerModal
						receivedScannerText={(text) => this.setState({ scannerText: text })}
						open={this.state.isScannerModalOpened}
						modalClosed={this.toggleScannerHandler}
					/>
				}
				<DeleteModal
					title="Production Document"
					open={this.state.isDeleteProdDocModalOpened}
					modalClosed={() => this.setState({ isDeleteProdDocModalOpened: false })}
					confirmDelete={this.onConfirmDeleteProdDoc}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	const { currentOrg } = state.org;
	const { planStructures } = state.advanceProductionPlanStructure;
	const { startedProduction, stoppedProduction } = state.advanceActualProduction;

	return { currentOrg, planStructures, machines: state.machine.machinesAndActuals, startedProduction, stoppedProduction, startStopResult: state.advanceActualProduction.result };
}

export default connect(mapStateToProps, { getProductionPlanStructures, startPlan, stopPlan, getMachinesAndActuals, getAdvanceProductionPlans, getNoteTypes, deleteDocumentNote, setDefaultOperatorMachine, getModuleConfigs })(Operator);

