import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  eventsResult: null,
  addedEvent: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_EVENTS_START: return searchEventsStart(state, action);
    case actionTypes.SEARCH_EVENTS_SUCCESS: return searchEventsSuccess(state, action);
    case actionTypes.SEARCH_EVENTS_FAIL: return searchEventsFail(state, action);

    case actionTypes.ADD_EVENT_START: return addEventStart(state, action);
    case actionTypes.ADD_EVENT_SUCCESS: return addEventSuccess(state, action);
    case actionTypes.ADD_EVENT_FAIL: return addEventFail(state, action);

    default: return state;
  }
};

const searchEventsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const searchEventsSuccess = (state, action) => {
  return updateObject(state, {
    eventsResult: action.eventsResult,
    error: null,
    loading: false
  });
}

const searchEventsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addEventStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addEventSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedEvent: action.addedEvent,
    error: null,
    loading: false
  });
}

const addEventFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;