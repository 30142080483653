export const GET_CHATROOMS_START = 'GET_CHATROOMS_START';
export const GET_CHATROOMS = 'GET_CHATROOMS';
export const GET_CHATROOMS_SUCCESS = 'GET_CHATROOMS_SUCCESS';
export const GET_CHATROOMS_FAIL = 'GET_CHATROOMS_FAIL';

export const GET_CHATROOM_START = 'GET_CHATROOM_START';
export const GET_CHATROOM = 'GET_CHATROOM';
export const GET_CHATROOM_SUCCESS = 'GET_CHATROOM_SUCCESS';
export const GET_CHATROOM_FAIL = 'GET_CHATROOM_FAIL';

export const ADD_CHATROOM_START = 'ADD_CHATROOM_START';
export const ADD_CHATROOM = 'ADD_CHATROOM';
export const ADD_CHATROOM_SUCCESS = 'ADD_CHATROOM_SUCCESS';
export const ADD_CHATROOM_FAIL = 'ADD_CHATROOM_FAIL';
