import React, { Component } from 'react';
import styles from './GaugeChart.module.scss';

class Gauge extends Component {

  static defaultProps = {
    value: 0,
    size: 116,
    strokewidth: 26,
    min: 0,
    max: 100,
    color: '#1665D8'
  };

  render() {
    const halfsize = (this.props.size * 0.5);
    const radius = halfsize - (this.props.strokewidth * 0.5);
    const circumference = 2 * Math.PI * radius;
    const strokeval = (((this.props.value - this.props.min) * circumference) / (this.props.max - this.props.min));
    const dashval = (strokeval + ' ' + circumference);

    const trackstyle = { strokeWidth: this.props.strokewidth };
    const indicatorstyle = { strokeWidth: this.props.strokewidth, strokeDasharray: dashval, stroke: this.props.color }
    const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';
    let DonutChartIndicatorStyles = [styles.DonutChartIndicator];

    return (
      <svg width={this.props.size} height={this.props.size} className={styles.DonutChart}>
        <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className={styles.DonutChartTrack} />
        <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className={DonutChartIndicatorStyles.join(' ')} />
      </svg>
    );
  }
}

export default Gauge;
