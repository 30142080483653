export const RESET_DEVICE_START = 'RESET_DEVICE_START';
export const RESET_DEVICE = 'RESET_DEVICE';
export const RESET_DEVICE_SUCCESS = 'RESET_DEVICE_SUCCESS';
export const RESET_DEVICE_FAIL = 'RESET_DEVICE_FAIL';

export const REBOOT_DEVICE_START = 'REBOOT_DEVICE_START';
export const REBOOT_DEVICE = 'REBOOT_DEVICE';
export const REBOOT_DEVICE_SUCCESS = 'REBOOT_DEVICE_SUCCESS';
export const REBOOT_DEVICE_FAIL = 'REBOOT_DEVICE_FAIL';

export const CREATE_DEVICE_START = 'CREATE_DEVICE_START';
export const CREATE_DEVICE = 'CREATE_DEVICE';
export const CREATE_DEVICE_SUCCESS = 'CREATE_DEVICE_SUCCESS';
export const CREATE_DEVICE_FAIL = 'CREATE_DEVICE_FAIL';