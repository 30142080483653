import React, { Component } from 'react';
import { connect } from 'react-redux';
import _, { isBoolean } from 'lodash';

import AddNewStructure from './AddNewStructure';
import DeleteModal from '../../../components/shared/DeleteModal/DeleteModal';

import { getProductionPlanStructures, addProductionPlanStructure, editProductionPlanStructure, deleteProductionPlanStructure } from '../../../../state/ducks/AdvanceProductionPlanStructure/actions';
import { DELETE_PRODUCTION_PLAN_STRUCTURE_SUCCESS } from '../../../../state/ducks/AdvanceProductionPlanStructure/types';

class PlanStructure extends Component {
	state = {
		editingStructureIndex: null,
		isAdding: false,
		savingStructureForm: null,
		savingCols: [],
		isDeleteModalOpened: false,
		deletingStructureUuid: null,
		properties: {}
	};

	componentDidMount() {
		this.props.getProductionPlanStructures(this.props.currentOrg);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.currentOrg !== this.props.currentOrg) {
			this.props.getProductionPlanStructures(this.props.currentOrg);
		} else if (this.props.isNewAdd && prevProps.isNewAdd !== this.props.isNewAdd) {
			this.setState({ editingStructureIndex: null, isAdding: true, editingStructureIndex: null });
		} else {
			if (this.props.result !== prevProps.result && this.props.result === 'success') {
				if ((prevProps.addedPlanStructure !== this.props.addedPlanStructure) || (prevProps.updatedPlanStructure !== this.props.updatedPlanStructure)
					|| [DELETE_PRODUCTION_PLAN_STRUCTURE_SUCCESS].includes(this.props.type)) {
					this.props.getProductionPlanStructures(this.props.currentOrg);
				}
			}
		}
	}

	onUpdateAllHandler = (savingStructureForm, savingCols, displayingCols, uniquePo) => {
		const properties = { ...this.state.properties };
		if (displayingCols) properties.displayingColumns = displayingCols;
		if (uniquePo || isBoolean(uniquePo)) properties.unique_po = uniquePo;
		this.setState({ savingStructureForm, savingCols, properties });
	}

	onUpdatingHandler = (isForm, data, displayingCols, uniquePo) => {
		if (isForm) {
			this.setState({ savingStructureForm: data });
		} else {
			if (data) this.setState({ savingCols: data });
			else {
				const properties = { ...this.state.properties };
				properties.displayingColumns = displayingCols ? displayingCols : [];
				if (uniquePo || isBoolean(uniquePo)) properties.unique_po = uniquePo;
				this.setState({ properties });
			}
		}
	}

	onSaveNewStructureHandler = (which, event) => {
		event.preventDefault();
		const { savingStructureForm: { name, detail }, savingCols } = this.state;
		let columns = {}; // {columnName:{order:1, display_name:xxx},...}
		savingCols.forEach((ac, i) => {
			if (ac.removable) {
				columns[_.camelCase(ac.display_name)] = {
					order: i + 1,
					display_name: ac.display_name
				};
			} else {
				columns[ac.name] = {
					order: i + 1,
					display_name: ac.display_name
				};
			}
		});
		if (which === 'add') {
			this.setState({ isAdding: false });
			this.props.addProductionPlanStructure(this.props.currentOrg, name.value, detail.value, columns);
		} else if (which === 'edit') {
			this.props.editProductionPlanStructure(this.props.planStructures[this.state.editingStructureIndex].uuid, name.value, detail.value, columns, this.state.properties);
			this.setState({ editingStructureIndex: null, properties: {} });
		}
	}

	onDeleteStructureHandler = (uuid) => {
		this.setState({ deletingStructureUuid: uuid, isDeleteModalOpened: true });
	}

	deleteModalClickHandler = () => {
		this.setState(prevState => {
			return { isDeleteModalOpened: !prevState.isDeleteModalOpened }
		});
	}

	onConfirmDeleteStructure = (event) => {
		event.preventDefault();
		this.props.deleteProductionPlanStructure(this.state.deletingStructureUuid);
		this.setState({ deletingStructureUuid: null, isDeleteModalOpened: false });
	}

	render() {
		return (
			<div>
				{
					this.state.isAdding ?
						<AddNewStructure
							isAdding={this.state.isAdding}
							updatingStructureForm={this.onUpdatingHandler}
							saveNewStructure={(which, e) => this.onSaveNewStructureHandler(which, e)}
							cancel={() => this.setState({ isAdding: false, savingStructureForm: null, savingCols: [] })}
						/>
						: null
				}
				{
					this.props.planStructures && this.props.planStructures.map((ps, index) => (
						<AddNewStructure
							key={`${ps.name}__${ps.detail}__${index + 1}`}
							structure={ps}
							structureIndex={index}
							isEditing={this.state.editingStructureIndex === index}
							setEditingIndex={(i) => this.setState({ editingStructureIndex: i })}
							deleteStructure={(uuid) => this.onDeleteStructureHandler(uuid)}
							updatingStructureForm={this.onUpdatingHandler}
							onUpdateAllHandler={this.onUpdateAllHandler}
							saveNewStructure={(which, e) => this.onSaveNewStructureHandler(which, e)}
							cancel={() => this.setState({ editingStructureIndex: null })}
						/>
					))
				}
				<DeleteModal
					title="plan's structure"
					open={this.state.isDeleteModalOpened}
					modalClosed={this.deleteModalClickHandler}
					confirmDelete={this.onConfirmDeleteStructure}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { currentOrg } = state.org;
	const { type, planStructures, result, addedPlanStructure, updatedPlanStructure } = state.advanceProductionPlanStructure;
	return { type, currentOrg, planStructures, result, addedPlanStructure, updatedPlanStructure };
};

export default connect(mapStateToProps, { getProductionPlanStructures, addProductionPlanStructure, editProductionPlanStructure, deleteProductionPlanStructure })(PlanStructure);
