import * as actionTypes from './types';

// ----------------------- GET RULE_COMMAND -----------------------
export const getRuleCommandsStart = () => {
  return {
    type: actionTypes.GET_RULE_COMMANDS_START
  };
}

export const getRuleCommands = (rule_id) => {
  return {
    type: actionTypes.GET_RULE_COMMANDS,
    rule_id
  };
}

export const getRuleCommandsSuccess = (response) => {
  return {
    type: actionTypes.GET_RULE_COMMANDS_SUCCESS,
    ruleCommands: response.rule_commands
  };
}

export const getRuleCommandsFail = (error) => {
  return {
    type: actionTypes.GET_RULE_COMMANDS_FAIL,
    error: error
  };
}

// ----------------------- ADD RULE_COMMAND -----------------------
export const addRuleCommandStart = () => {
  return {
    type: actionTypes.ADD_RULE_COMMAND_START
  };
}

export const addRuleCommand = (rule_id, device_secret, device_socket, value) => {
  return {
    type: actionTypes.ADD_RULE_COMMAND,
    rule_id, device_secret, device_socket, value
  };
}

export const addRuleCommandSuccess = (response) => {
  return {
    type: actionTypes.ADD_RULE_COMMAND_SUCCESS,
    addedRuleCommand: response.rule_command,
    result: response.result
  };
}

export const addRuleCommandFail = (error) => {
  return {
    type: actionTypes.ADD_RULE_COMMAND_FAIL,
    error: error
  };
}

// ----------------------- EDIT RULE_COMMAND -----------------------
export const editRuleCommandStart = () => {
  return {
    type: actionTypes.EDIT_RULE_COMMAND_START
  };
}

export const editRuleCommand = (id, device_secret, device_socket, value) => {
  return {
    type: actionTypes.EDIT_RULE_COMMAND,
    id, device_secret, device_socket, value
  };
}

export const editRuleCommandSuccess = (response) => {
  return {
    type: actionTypes.EDIT_RULE_COMMAND_SUCCESS,
    updatedRuleCommand: response.rule_command,
    result: response.result
  };
}

export const editRuleCommandFail = (error) => {
  return {
    type: actionTypes.EDIT_RULE_COMMAND_FAIL,
    error: error
  };
}

// ----------------------- DELETE RULE_COMMAND -----------------------
export const deleteRuleCommandStart = () => {
  return {
    type: actionTypes.DELETE_RULE_COMMAND_START
  };
}

export const deleteRuleCommand = (id) => {
  return {
    type: actionTypes.DELETE_RULE_COMMAND,
    id: id
  };
}

export const deleteRuleCommandSuccess = (result) => {
  return {
    type: actionTypes.DELETE_RULE_COMMAND_SUCCESS,
    result: result,
  };
}

export const deleteRuleCommandFail = (error) => {
  return {
    type: actionTypes.DELETE_RULE_COMMAND_FAIL,
    error: error
  };
}

// export default {
//   getRuleCommandsStart,
//   getRuleCommands,
//   getRuleCommandsSuccess,
//   getRuleCommandsFail,
//   addRuleCommandStart,
//   addRuleCommand,
//   addRuleCommandSuccess,
//   addRuleCommandFail,
//   editRuleCommandStart,
//   editRuleCommand,
//   editRuleCommandSuccess,
//   editRuleCommandFail,
//   deleteRuleCommandStart,
//   deleteRuleCommand,
//   deleteRuleCommandSuccess,
//   deleteRuleCommandFail,
// }

