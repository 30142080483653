import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_BREAKDOWNS, EDIT_BREAKDOWN } from './types';

export function* watchBreakdown() {
  yield takeEvery(GET_BREAKDOWNS, getBreakdownsSaga);
  yield takeEvery(EDIT_BREAKDOWN, editBreakdownSaga);
}

export function* getBreakdownsSaga(params) {
  yield put(actions.getBreakdownsStart());

  let api = 'v1/breakdown';
  api += '?org_id=' + params.org_id;
  api += params.countingdevices_id ? '&countingdevices_id=' + params.countingdevices_id : '';
  api += params.start ? ('&start=' + params.start) : '';
  api += params.stop ? ('&stop=' + params.stop) : '';

  try {
    const response = yield axios.get(api);
    console.log("GET BREAKDOWN SUCCESS", response.data);
    yield put(actions.getBreakdownsSuccess(response.data));
  } catch (err) {
    console.log("GET BREAKDOWN FAILED", err)
    yield put(actions.getBreakdownsFail(err.response));
  }
}

export function* editBreakdownSaga(params) {
  yield put(actions.editBreakdownStart());

  const api = 'v1/breakdown/' + params.id;
  let putData = 'description=' + params.description;
  putData += params.start ? ('&start=' + params.start) : '';
  putData += params.stop ? ('&stop=' + params.stop) : '';

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT BREAKDOWN SUCCESS", response.data);
    yield put(actions.editBreakdownSuccess(response.data));
  } catch (err) {
    console.log("GET BREAKDOWN FAILED", err)
    yield put(actions.editBreakdownFail(err.response));
  }
}