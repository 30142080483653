import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import styles from './BreakdownLog.module.scss';
import Button from '../../components/UI/Button/Button';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';

import BreakdownLogTable from '../../components/Production/BreakdownLog/BreakdownLogTable';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import BreakdownLogDrawer from '../../components/Operator/BreakdownLogDrawer/BreakdownLogDrawer';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import SearchLogDrawer from '../../components/Production/BreakdownLog/SearchLogDrawer';
import ScannerModal from '../../components/Operator/StartStopPoModal/ScannerModal';

import { searchEvents, addEvent } from '../../../state/ducks/Event/actions';
import { getMachines } from '../../../state/ducks/Machine/actions';
import { addDocumentNote, deleteDocumentNote } from '../../../state/ducks/Document/actions';
import { DELETE_DOCUMENT_NOTE_SUCCESS } from '../../../state/ducks/Document/types';

const TAB_LIST = ['Breakdown'];

class BreakdownLog extends Component {
  state = {
    currentTabIndex: 0,
    isAdding: false,
    editingBreakdownLog: null,
    isDrawerOpened: false,
    isDeleteModalOpened: false,
    deletingBreakdownLogUuid: null,
    isAdvancedSearch: false,
    savingSearchParams: {},
    scannerText: '',
    isScannerModalOpened: false,
    savingEvent: null,
    breakdownLogs: []
  };

  componentDidMount() {
    this.props.getMachines(this.props.currentOrg);
    this.props.searchEvents(this.props.currentOrg, null, null, null, 0); // search only BREAKDOWN events
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentOrg !== prevProps.currentOrg && this.props.currentOrg) {
      this.props.getMachines(this.props.currentOrg);
      this.props.searchEvents(this.props.currentOrg, null, null, null, 0);
    } else if (prevProps.result !== this.props.result && this.props.result === 'success') {
      if (this.props.updatedDocumentNote !== prevProps.updatedDocumentNote || this.props.addedDocumentNote !== prevProps.addedDocumentNote || this.props.type === DELETE_DOCUMENT_NOTE_SUCCESS) {
        this.props.searchEvents(this.props.currentOrg, null, null, null, 0); // search only BREAKDOWN events
        this.setState({ savingEvent: null });
      }
    } else if (this.props.addedEvent && !_.isEqual(this.props.addedEvent, prevProps.addedEvent)) {
      const { typeId, title, content, option } = this.state.savingEvent;
      this.props.addDocumentNote(
        this.props.currentOrg,
        typeId,
        title,
        content,
        option,
        null,
        this.props.addedEvent[0].uuid
      );
    }
  }

  openDrawerForAddingHandler = () => {
    this.setState({ isAdding: true, editingBreakdownLog: null, isDrawerOpened: true });
  }

  drawerToggleHandler = (which) => {
    this.setState(prevState => {
      return { isDrawerOpened: !prevState.isDrawerOpened, isAdvancedSearch: which === 'search' }
    });
  }

  onAddEventHandler = (docForm) => {
    this.props.addEvent(
      this.props.currentOrg,
      docForm.option.machine.id,
      moment(docForm.option.startDate + ' ' + docForm.option.startTime).valueOf(),
      moment(docForm.option.endDate + ' ' + docForm.option.endTime).valueOf(),
      'TNM_operator',
      0,
      'Manual'
    );
    this.setState({ savingEvent: docForm });
  }

  onEditDocumentHandler = (e, i) => {
    e.stopPropagation();
    this.setState({ editingBreakdownLog: this.state.breakdownLogs[i], isAdding: false, isDrawerOpened: true });
  }

  onToggleDeleteModalHandler = (event) => {
    event.preventDefault();
    this.setState({ isDeleteModalOpened: true });
  }

  onConfirmDeleteDocumentHandler = (event) => {
    event.preventDefault();
    this.props.deleteDocumentNote(this.state.editingBreakdownLog.documents[0].uuid);
    this.setState({ editingBreakdownLog: null, isDeleteModalOpened: false, isDrawerOpened: false });
  }

  changeTabStructureHandler = (index) => {
    this.setState({ currentTabIndex: index });
  };

  onSearchBreakdownLogsHandler = (e, searchParams) => {
    e.preventDefault();
    const { startDate, startTime, endDate, endTime, machine } = searchParams;
    this.props.searchEvents(
      this.props.currentOrg,
      startDate && startTime ? new Date(startDate + ' ' + startTime).getTime() : null,
      endDate && endTime ? new Date(endDate + ' ' + endTime).getTime() : null,
      null,
      0,
      machine ? machine.id : null
    );

    this.drawerToggleHandler();
    this.setState({ savingSearchParams: searchParams });
  }

  toggleScannerHandler = () => {
    this.setState(prevState => {
      return { isScannerModalOpened: !prevState.isScannerModalOpened }
    });
  }

  render() {
    const { currentTabIndex } = this.state;

    return (
      <div className={styles.BreakdownLog}>
        <SideDrawer
          title={
            this.state.isAdvancedSearch
              ? 'Advanced Search Logs'
              : this.state.isAdding ? 'Add New Breakdown Log' : 'Edit Breakdown Log'
          }
          open={this.state.isDrawerOpened}
          closed={this.drawerToggleHandler}>
          {
            this.state.isAdvancedSearch
              ? <SearchLogDrawer searchBreakdownLogs={this.onSearchBreakdownLogsHandler} closed={this.drawerToggleHandler} savingSearchParams={this.state.savingSearchParams} />
              : <BreakdownLogDrawer
                isAdding={this.state.isAdding}
                closed={this.drawerToggleHandler}
                editingLog={this.state.editingBreakdownLog}
                addEventHandler={this.onAddEventHandler}
                toggleDeleteModal={this.onToggleDeleteModalHandler}
                toggleScannerHandler={this.toggleScannerHandler}
                scannerText={this.state.scannerText}
                clearScannerText={() => this.setState({ scannerText: '' })}
              />
          }
        </SideDrawer>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            {
              TAB_LIST.map((name, index) => (
                <div key={`logs-${index}`} className={index === currentTabIndex ? styles.ActiveMenu : styles.Menu} onClick={() => this.changeTabStructureHandler(index)}>
                  <span className={styles.MenuTitle}>{name}</span>
                </div>
              ))
            }
          </div>

          <div className={styles.AddButtonWrapper}>
            <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Breakdown Log" noMargin click={this.openDrawerForAddingHandler} />
          </div>

        </div>
        <div className="MainContentPadding">
          {
            this.props.error
              ? <div className="ErrorText">Something went wrong! Please contact admin. ({this.props.error})</div>
              : ''
          }

          <BreakdownLogTable
            editBreakdownLog={this.onEditDocumentHandler}
            openAdvancedSearchDrawer={() => this.drawerToggleHandler('search')}
            savingSearchParams={this.state.savingSearchParams}
            saveBreakdownLogs={(value) => this.setState({ breakdownLogs: value })}
          />
        </div>
        {
          this.state.isScannerModalOpened &&
          <ScannerModal
            receivedScannerText={(text) => this.setState({ scannerText: text })}
            open={this.state.isScannerModalOpened}
            modalClosed={this.toggleScannerHandler}
          />
        }
        <DeleteModal
          title="production plan"
          open={this.state.isDeleteModalOpened}
          modalClosed={() => this.setState({ isDeleteModalOpened: false })}
          confirmDelete={this.onConfirmDeleteDocumentHandler}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { machines } = state.machine;
  const { addedDocumentNote, updatedDocumentNote, result, loading, error, type } = state.document;
  const { addedEvent } = state.event;

  return { currentOrg, machines, addedEvent, addedDocumentNote, updatedDocumentNote, result, loading, error, type };
};

export default connect(mapStateToProps, { getMachines, searchEvents, addEvent, addDocumentNote, deleteDocumentNote })(BreakdownLog);
