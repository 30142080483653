import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './AddDeviceDrawer.module.scss';
import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import RadioUnselectIcon from '../../../../assets/icons/radio-unselect.svg';
import RadioSelectIcon from '../../../../assets/icons/radio-select.svg';
import CorrectIcon from '../../../../assets/icons/Device/correct.svg';
import WrongIcon from '../../../../assets/icons/Device/wrong.svg';
import ButtonSpinner from '../../UI/Spinner/ButtonSpinner';

import AddDeviceForm from '../AddDeviceForm/AddDeviceForm';
import AddCustomDeviceForm from '../AddDeviceForm/AddCustomDeviceForm';

import { searchOwlModel, clearOwlModel } from '../../../../state/ducks/Devices/actions';
import { ADD_OWL_DEVICES_SUCCESS } from '../../../../state/ducks/Devices/types';

const DeviceTypes = [
  { id: 'owl', name: 'OWL' },
  { id: 'custom', name: 'Customize' }
];

class AddDeviceDrawer extends Component {
  state = {
    selectedDeviceType: 'owl',
    secretNumber: '',
    submitSearchSecret: false,
    duplicatedDevice: false
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedDeviceType !== prevState.selectedDeviceType) {
      if (this.state.selectedDeviceType === 'owl') {
        this.props.clearOwlModel();
      }
      this.onClearFields();
    } else if (prevProps.result !== this.props.result && this.props.result === 'success') {
      if (this.props.type === ADD_OWL_DEVICES_SUCCESS || this.props.addedDevice !== prevProps.addedDevice) {
        this.props.closed();
        this.props.clearOwlModel();
        this.onClearFields();
        this.setState({ selectedDeviceType: 'owl' });
      }
    }
  }

  onClearFields = () => {
    this.setState({ secretNumber: '', submitSearchSecret: false, duplicatedDevice: false });
  }

  handleTextChange = event => {
    let updatedElement = updateObject(this.state.deviceForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.deviceForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.deviceForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ deviceForm: updatedForm, formIsValid });
  };

  handleTypeChange = (event) => {
    this.setState({ selectedDeviceType: event.target.value });
  }

  handleSecretNumberChange = (event) => {
    this.setState({ secretNumber: event.target.value });
  }

  onSearchSecretHandler = (event) => {
    event.preventDefault();
    const alreadyAddedDevice = this.props.devices.find(d => d.secret === this.state.secretNumber);
    if (alreadyAddedDevice) {
      this.setState({ duplicatedDevice: true });
    } else {
      if (this.state.secretNumber) {
        this.setState({ submitSearchSecret: true, duplicatedDevice: false });
        this.props.searchOwlModel(this.state.secretNumber);
      }
    }
  }

  cancelAdding = () => {
    this.setState({
      selectedDeviceType: 'owl',
      secretNumber: '',
      submitSearchSecret: false,
      duplicatedDevice: false
    });
    this.props.clearOwlModel();
    this.props.closed();
  }

  render() {
    let resultSearchSecretIcon = WrongIcon;
    if (this.props.owlModel && this.props.owlModel.length !== 0 && !this.props.owlModel[0].org_id) {
      resultSearchSecretIcon = CorrectIcon;
    }

    const searchSecretForm =
      <React.Fragment>
        <div className={styles.Label}>SECRET NUMBER</div>
        <div className={styles.SecretContainer}>
          <form onSubmit={this.onSearchSecretHandler}>
            <Input
              name="secret"
              type="text"
              value={this.state.secretNumber}
              placeholder="30:AE:A4:99:06:FC"
              autoComplete="off"
              onChange={this.handleSecretNumberChange}
            />
            {
              this.state.duplicatedDevice
                ? <div className="ErrorText">This secret has already been added!</div>
                : this.props.owlModel && this.props.owlModel.length === 0
                  ? <div className="ErrorText">This secret can't be found</div>
                  : this.props.owlModel && this.props.owlModel[0].org_id
                    ? <div className="ErrorText">Already exist in another organization!</div>
                    : null
            }
            {
              this.state.submitSearchSecret ?
                this.props.searchOwlModelLoading ?
                  <div className={styles.SecretLoading}><ButtonSpinner /></div>
                  : <img alt="Secret" src={resultSearchSecretIcon} className={styles.SecretIcon} />
                : null
            }
            {
              !this.props.owlModel || (this.props.owlModel && (this.props.owlModel.length === 0 || this.props.owlModel[0].org_id)) ?
                <React.Fragment>
                  <div style={{ height: 50, width: 'auto' }} />
                  <Button
                    type="submit"
                    name={this.props.searchOwlModelLoading ? 'Loading...' : 'Search'}
                    color="primary"
                    loading={this.props.searchOwlModelLoading}
                  />
                  <Button
                    type="button"
                    name="Cancel"
                    color="borderred"
                    click={this.cancelAdding}
                  />
                </React.Fragment>
                : null
            }
          </form>
        </div>
      </React.Fragment>

    return (
      <div className={styles.AddDeviceDrawer}>
        {this.props.error && <div className={styles.ErrorMessage}>{this.props.error}</div>}
        <div className={styles.Label}>MODEL</div>
        <div className={styles.RadioField}>
          {
            DeviceTypes.map(type => {
              let RadioIcon = RadioUnselectIcon;
              let isChecked = false;
              if (this.state.selectedDeviceType === type.id) {
                RadioIcon = RadioSelectIcon;
                isChecked = true;
              }
              return (
                <div className={styles.RadioContainer} key={type.id}>
                  <img src={RadioIcon} alt="Radio Icon" className={styles.RadioIcon} />
                  <input id={type.id} type="radio" value={type.id} checked={isChecked} onChange={this.handleTypeChange} />
                  <label htmlFor={type.id} className={styles.RadioLabel}>{type.name}</label>
                </div>
              );
            })
          }
        </div>
        {this.state.selectedDeviceType === 'owl' ? searchSecretForm : null}
        {
          (this.props.owlModel && this.props.owlModel.length !== 0 && !this.props.owlModel[0].org_id) && this.state.selectedDeviceType === 'owl' ?
            <AddDeviceForm secretNumber={this.state.secretNumber} closed={this.cancelAdding} />
            :
            this.state.selectedDeviceType === 'custom' ? <AddCustomDeviceForm closed={this.cancelAdding} /> : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { type, result, devices, addedDevice, owlModel, searchOwlModelLoading, error } = state.devices;
  return { owlModel, devices, addedDevice, searchOwlModelLoading, type, result, error };
};

export default connect(mapStateToProps, { searchOwlModel, clearOwlModel })(AddDeviceDrawer);