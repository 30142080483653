import * as actionTypes from './types';

// ----------------------- GET ADVANCE_PRODUCTION_BATCHS -----------------------
export const getAdvanceProductionBatchsStart = () => {
  return {
    type: actionTypes.GET_ADVANCE_PRODUCTION_BATCHS_START
  };
}

export const getAdvanceProductionBatchs = (org_id, batch, date, start, end) => {
  return {
    type: actionTypes.GET_ADVANCE_PRODUCTION_BATCHS,
    org_id, batch, date, start, end
  };
}

export const getAdvanceProductionBatchsSuccess = (response) => {
  return {
    type: actionTypes.GET_ADVANCE_PRODUCTION_BATCHS_SUCCESS,
    productionBatchs: response.batchs,
    result: response.result
  };
}

export const getAdvanceProductionBatchsFail = (error) => {
  return {
    type: actionTypes.GET_ADVANCE_PRODUCTION_BATCHS_FAIL,
    error
  };
}

// ----------------------- ADD ADVANCE_PRODUCTION_BATCH -----------------------
export const addAdvanceProductionBatchStart = () => {
  return {
    type: actionTypes.ADD_ADVANCE_PRODUCTION_BATCH_START
  };
}

export const addAdvanceProductionBatch = (org_id, batch, start, end, columns) => {
  return {
    type: actionTypes.ADD_ADVANCE_PRODUCTION_BATCH,
    org_id, batch, start, end, columns
  };
}

export const addAdvanceProductionBatchSuccess = (response) => {
  return {
    type: actionTypes.ADD_ADVANCE_PRODUCTION_BATCH_SUCCESS,
    addedProductionBatch: response.batch,
    result: response.result
  };
}

export const addAdvanceProductionBatchFail = (error) => {
  return {
    type: actionTypes.ADD_ADVANCE_PRODUCTION_BATCH_FAIL,
    error
  };
}

// ----------------------- EDIT ADVANCE_PRODUCTION_BATCH -----------------------
export const editAdvanceProductionBatchStart = () => {
  return {
    type: actionTypes.EDIT_ADVANCE_PRODUCTION_BATCH_START
  };
}

export const editAdvanceProductionBatch = (uuid, batch, start, end, columns) => {
  return {
    type: actionTypes.EDIT_ADVANCE_PRODUCTION_BATCH,
    uuid, batch, start, end, columns
  };
}

export const editAdvanceProductionBatchSuccess = (response) => {
  return {
    type: actionTypes.EDIT_ADVANCE_PRODUCTION_BATCH_SUCCESS,
    updatedProductionBatch: response.batch,
    result: response.result
  };
}

export const editAdvanceProductionBatchFail = (error) => {
  return {
    type: actionTypes.EDIT_ADVANCE_PRODUCTION_BATCH_FAIL,
    error
  };
}

// ----------------------- DELETE ADVANCE_PRODUCTION_BATCH -----------------------
export const deleteAdvanceProductionBatchStart = () => {
  return {
    type: actionTypes.DELETE_ADVANCE_PRODUCTION_BATCH_START
  };
}

export const deleteAdvanceProductionBatch = (uuid) => {
  return {
    type: actionTypes.DELETE_ADVANCE_PRODUCTION_BATCH,
    uuid
  };
}

export const deleteAdvanceProductionBatchSuccess = (result) => {
  return {
    type: actionTypes.DELETE_ADVANCE_PRODUCTION_BATCH_SUCCESS,
    result
  };
}

export const deleteAdvanceProductionBatchFail = (error) => {
  return {
    type: actionTypes.DELETE_ADVANCE_PRODUCTION_BATCH_FAIL,
    error
  };
}

// ----------------------- MAP_PLANS_TO_BATCH -----------------------
export const mapPlansToBatchStart = () => {
  return {
    type: actionTypes.MAP_PLANS_TO_BATCH_START
  };
}

export const mapPlansToBatch = (uuid, plan_uuids) => {
  return {
    type: actionTypes.MAP_PLANS_TO_BATCH,
    uuid, plan_uuids
  };
}

export const mapPlansToBatchSuccess = (result) => {
  return {
    type: actionTypes.MAP_PLANS_TO_BATCH_SUCCESS,
    result
  };
}

export const mapPlansToBatchFail = (error) => {
  return {
    type: actionTypes.MAP_PLANS_TO_BATCH_FAIL,
    error
  };
}

// ----------------------- GET_BATCH_PROGRESS -----------------------
export const getBatchProgressStart = () => {
  return {
    type: actionTypes.GET_BATCH_PROGRESS_START
  };
}

export const getBatchProgress = (uuid, properties) => {
  return {
    type: actionTypes.GET_BATCH_PROGRESS,
    uuid, properties
  };
}

export const getBatchProgressSuccess = (response, properties) => {
  return {
    type: actionTypes.GET_BATCH_PROGRESS_SUCCESS,
    progress: response.value,
    result: response.result,
    properties: properties,
  };
}

export const getBatchProgressFail = (error) => {
  return {
    type: actionTypes.GET_BATCH_PROGRESS_FAIL,
    error
  };
}