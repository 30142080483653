import React, { Component } from 'react';

import { updateObject, checkValidity } from '../../../../../state/utils';
import styles from '../SystemDeviceConfig.module.scss';
import Input from '../../../UI/Input/Input';

const DEFAULT_FORM = {
  main_interval: {
    value: 5,
    valid: true,
    touched: true,
    validation: {
      isNumeric: true,
      min: 5
    }
  },
  coap_host: {
    value: '35.198.205.42',
    valid: true,
    touched: true,
    validation: {
      ipAddress: true
    }
  },
  coap_port: {
    value: '5683',
    valid: true,
    touched: true,
    validation: {
      isNumeric: true,
      maxLength: 5
    }
  },
  reboot_interval: {
    value: 0,
    valid: true,
    touched: true,
    validation: {
      isNumeric: true,
      min: 0
    }
  }
};

class CoapFields extends Component {
  state = {
    form: DEFAULT_FORM,
    formIsValid: false
  }

  componentDidMount() {
    if (this.props.edgeConfig) {
      this.setupForm(this.props.edgeConfig);
      let updatedNetworkDetails = { ...this.props.networkDetails };
      const { main_interval, coap_host, coap_port, reboot_interval } = this.props.edgeConfig;
      if (main_interval) updatedNetworkDetails.main_interval = main_interval;
      if (coap_host) updatedNetworkDetails.coap_host = coap_host;
      if (coap_port) updatedNetworkDetails.coap_port = coap_port;
      if (reboot_interval) updatedNetworkDetails.reboot_interval = reboot_interval;
      this.props.setAllNetworkDetails(updatedNetworkDetails);
    } else {
      this.setDefaultForm();
    }
  }

  setDefaultForm = () => {
    let updatedNetworkDetails = {};
    updatedNetworkDetails.main_interval = 5000;
    updatedNetworkDetails.coap_host = '35.198.205.42';
    updatedNetworkDetails.coap_port = 5683;
    updatedNetworkDetails.reboot_interval = 0;
    this.props.setAllNetworkDetails(updatedNetworkDetails);
    this.setState({ form: DEFAULT_FORM });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.edgeConfig !== this.props.edgeConfig && (this.props.edgeConfig || this.props.edgeConfig === '')) {
      if (this.props.edgeConfig === '') {
        this.setDefaultForm();
      } else {
        this.setupForm(this.props.edgeConfig);
      }
    }
  }

  setupForm = (edgeConfig) => {
    let updatedForm = { ...this.state.form };
    for (let inputIdentifier in updatedForm) {
      const value = inputIdentifier === 'main_interval' || inputIdentifier === 'reboot_interval'
        ? edgeConfig[inputIdentifier]
          ? edgeConfig[inputIdentifier] / 1000 : 0
        : edgeConfig[inputIdentifier];
      const updatedElement = updateObject(updatedForm[inputIdentifier], {
        value,
        valid: true,
        touched: true
      });
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }
    this.setState({ form: updatedForm, formIsValid: true });
  }

  handleCoapTextChange = event => {
    const { name, value } = event.target;

    let updatedElement;
    if (name === 'reboot_interval') {
      updatedElement = updateObject(this.state.form[name], {
        value,
        valid: +value === 0 || +value >= +this.state.form.main_interval.value * 10,
        touched: true
      });
    } else {
      updatedElement = updateObject(this.state.form[name], {
        value,
        valid: checkValidity(value, this.state.form[name].validation),
        touched: true
      });
    }

    let updatedForm = updateObject(this.state.form, {
      [name]: updatedElement
    });

    if (name === 'main_interval') {
      if (+this.state.form.reboot_interval.value !== 0 && +this.state.form.reboot_interval.value < +value * 10) {
        updatedForm = updateObject(updatedForm, {
          reboot_interval: updateObject(this.state.form.reboot_interval, {
            valid: false
          })
        });
      } else {
        updatedForm = updateObject(updatedForm, {
          reboot_interval: updateObject(this.state.form.reboot_interval, {
            valid: true
          })
        });
      }
    }

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    this.props.setNetworkDetails(name, name === 'main_interval' || name === 'reboot_interval' ? value * 1000 : value, !formIsValid);
    this.setState({ form: updatedForm, formIsValid });
  };

  render() {
    const { main_interval, coap_host, coap_port, reboot_interval } = this.state.form;

    return (
      <>
        <div className={styles.Label}>Data Interval and CoAP Settings</div>
        <Input
          labelin="true"
          label="Data Interval (seconds)"
          name="main_interval"
          type="number"
          value={main_interval.value}
          autoComplete="off"
          placeholder="Equal or more than 5"
          onChange={this.handleCoapTextChange}
        />
        <Input
          labelin="true"
          label="CoAP Host"
          name="coap_host"
          type="text"
          value={coap_host.value}
          autoComplete="off"
          placeholder="(initial host: 35.198.205.42)"
          onChange={this.handleCoapTextChange}
        />
        <Input
          labelin="true"
          label="CoAP Port"
          name="coap_port"
          type="number"
          value={coap_port.value}
          autoComplete="off"
          placeholder="(initial port: 5683)"
          onChange={this.handleCoapTextChange}
        />
        <Input
          labelin="true"
          label="Reboot Interval (seconds)"
          name="reboot_interval"
          type="number"
          value={reboot_interval.value}
          autoComplete="off"
          placeholder="0 || >= 10x of Data Interval"
          onChange={this.handleCoapTextChange}
          error={reboot_interval.touched && !reboot_interval.valid ? 'Reboot Interval can be 0 or more than 10x of Data Interval' : ''}
        />
      </>
    );
  }
}

export default CoapFields;