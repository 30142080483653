import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateObject, checkValidity } from '../../../../state/utils';
import styles from './FormulaForm.module.scss';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import CloseIcon from "../../../../assets/icons/cancel-circle.svg";
import FormulaModal from '../../../components/FormulaBuilder/FormulaModal/FormulaModal';
import SymbolModal from '../SymbolModal/SymbolModal';

import { addFormula, editFormula } from '../../../../state/ducks/Formula/actions';
import { deleteVariable } from '../../../../state/ducks/Variable/actions';

const DEFAULT_FORMULA_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  }
};

class FormulaForm extends Component {
  state = {
    formulaSet: [[{ operator: '', special: '', variable: { name: 'new', value: -1 } }]],
    formulaForm: DEFAULT_FORMULA_FORM,
    formIsValid: false,
    formulaModalOpened: false,
    symbolModalOpened: false,
    editingVariable: null,
    editingVarIndex: []
  }

  componentDidMount() {
    if (this.props.editingFormula) {
      this.setupFormula(this.props.editingFormula);
      this.setupForm(this.props.editingFormula);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAdding && prevProps.isAdding !== this.props.isAdding) {
      this.onClearFields();
    } else if (this.props.editingFormula && this.props.editingFormula !== prevProps.editingFormula) {
      this.setupFormula(this.props.editingFormula);
      this.setupForm(this.props.editingFormula);
    }
  }

  setupFormula = (editingFormula) => {
    let formulaSet = [];
    let row = 0;
    formulaSet[row] = [];

    const { sets } = editingFormula;
    sets.sort((a, b) => a.order - b.order).forEach((fs, i) => {
      if (fs.special === '\n') {
        if (formulaSet[row] && formulaSet[row].length) row++;
        if (sets[i + 1].operator) {
          formulaSet[row] = [sets[i + 1]];
          row++;
        }
      } else {
        if (row > 1 && fs === formulaSet[row - 1][0]) { }
        else {
          if (!formulaSet[row]) formulaSet[row] = [];
          formulaSet[row].push(fs);
        }
      }
    });
    this.setState({ formulaSet });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.formulaForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
        validation: {
          required: true,
        },
      });

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement,
      });
    }
    this.setState({ formulaForm: updatedForm, formIsValid: true });
  };

  handleFormChange = (event) => {
    let updatedElement = updateObject(this.state.formulaForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.formulaForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.formulaForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ formulaForm: updatedForm, formIsValid });
  };

  onClearFields = () => {
    const firstInit = { operator: '', special: '', variable: { name: 'new', value: -1 } };
    this.setState({ formulaSet: [[firstInit]], formulaForm: DEFAULT_FORMULA_FORM, formIsValid: false });
  }

  checkSubmitButton = () => {
    let isSomethingError = false;
    this.state.formulaSet.forEach(row => {
      row.forEach(fs => {
        if (fs && fs.variable) {
          if (fs.variable.name && (fs.variable.name === '' || fs.variable.name === 'new')) {
            isSomethingError = true;
          } else if (fs.variable.value && (fs.variable.value === '' || fs.variable.value === -1)) {
            isSomethingError = true;
          }
        }
      })
    });
    return isSomethingError || !this.state.formIsValid;
  }

  onEditItemHandler = (groupIndex, itemIndex) => {
    this.setState({ formulaModalOpened: true, editingVariable: this.state.formulaSet[groupIndex][itemIndex].variable, editingVarIndex: [groupIndex, itemIndex] });
  }

  closeFormulaModalHandler = () => {
    this.setState({ formulaModalOpened: false });
  }

  onAddNewItemHandler = (groupIndex, itemIndex) => {
    const formulaSet = [...this.state.formulaSet];
    formulaSet[groupIndex].push({ operator: '+', special: '', variable: null });
    formulaSet[groupIndex].push({ operator: '', special: '', variable: { name: 'new', value: -1 } });
    this.setState({ formulaSet });
  }

  onAddNewRowHandler = () => {
    const formulaSet = [...this.state.formulaSet];
    formulaSet.push([{ operator: '+', special: '', variable: null }]);
    formulaSet.push([{ operator: '', special: '', variable: { name: 'new', value: -1 } }]);
    this.setState({ formulaSet });
  }

  onOpenSymbolsModalHandler = (groupIndex, itemIndex) => {
    const symbolModalOpened = [...Array(groupIndex + 1)].map(e => Array(itemIndex + 1));
    symbolModalOpened[groupIndex][itemIndex] = true;
    this.setState({ symbolModalOpened });
  }

  onToggleSymbolsHandler = (event) => {
    event.stopPropagation();
    const symbolModalOpened = [[]];
    this.setState({ symbolModalOpened });
  }

  onChangeSymbolsHandler = (value, groupIndex, itemIndex) => {
    const formulaSet = [...this.state.formulaSet];
    formulaSet[groupIndex][itemIndex] = { operator: value, special: '', variable: null };
    this.setState({ formulaSet });
  }

  onToggleBracketHandler = (event, hasBracket, groupIndex, itemIndex) => {
    event.stopPropagation();
    const formulaSet = [...this.state.formulaSet];
    if (!hasBracket) {
      // toggle open
      if (formulaSet[groupIndex][itemIndex - 1] && formulaSet[groupIndex][itemIndex - 1].special !== ')') {
        formulaSet[groupIndex].splice(itemIndex - 1 < 0 ? 0 : itemIndex - 1, 0, { operator: '', special: '(', variable: null });
      } else {
        let howManyPairs = 0;
        let foundPairs = 0;
        let prevOpeningBracket = 0;
        for (let k = itemIndex; k >= 0; k--) {
          if (formulaSet[groupIndex][k].special === ')') {
            howManyPairs++;
          } else if (formulaSet[groupIndex][k].special === '(') {
            prevOpeningBracket = k;
            foundPairs++;
            if (howManyPairs === foundPairs) break;
          }
        }
        formulaSet[groupIndex].splice(prevOpeningBracket, 0, { operator: '', special: '(', variable: null });
      }
      if (formulaSet[groupIndex][itemIndex + 2] && formulaSet[groupIndex][itemIndex + 2].special !== '(') {
        formulaSet[groupIndex].splice(itemIndex + 3, 0, { operator: '', special: ')', variable: null });
      } else {
        let howManyPairs = 0;
        let foundPairs = 0;
        let nextClosingBracket = 0;
        for (let k = itemIndex + 2; k <= formulaSet[groupIndex].length - 1; k++) {
          if (formulaSet[groupIndex][k].special === '(') {
            howManyPairs++;
          } else if (formulaSet[groupIndex][k].special === ')') {
            nextClosingBracket = k;
            foundPairs++;
            if (howManyPairs === foundPairs) break;
          }
        }
        formulaSet[groupIndex].splice(nextClosingBracket, 0, { operator: '', special: ')', variable: null });
      }
      const symbolModalOpened = [...Array(groupIndex + 1)].map(e => Array(itemIndex + 2));
      symbolModalOpened[groupIndex][itemIndex + 1] = true;
      this.setState({ symbolModalOpened });
    } else {
      // toggle close
      if (formulaSet[groupIndex][itemIndex - 2] && formulaSet[groupIndex][itemIndex - 2].special === '(') {
        formulaSet[groupIndex].splice(itemIndex - 2, 1);
      } else {
        let howManyPairs = 0;
        let foundPairs = 0;
        let prevOpeningBracket = 0;
        for (let k = itemIndex; k >= 0; k--) {
          if (formulaSet[groupIndex][k].special === ')') {
            howManyPairs++;
          } else if (formulaSet[groupIndex][k].special === '(') {
            prevOpeningBracket = k;
            foundPairs++;
            if (howManyPairs + 1 === foundPairs) break;
          }
        }
        formulaSet[groupIndex].splice(prevOpeningBracket, 1);
      }
      if (formulaSet[groupIndex][itemIndex + 1] && formulaSet[groupIndex][itemIndex + 1].special === ')') {
        formulaSet[groupIndex].splice(itemIndex + 1, 1);
      } else {
        let howManyPairs = 0;
        let foundPairs = 0;
        let nextClosingBracket = 0;
        for (let k = itemIndex; k <= formulaSet[groupIndex].length - 1; k++) {
          if (formulaSet[groupIndex][k].special === '(') {
            howManyPairs++;
          } else if (formulaSet[groupIndex][k].special === ')') {
            nextClosingBracket = k;
            foundPairs++;
            if (howManyPairs + 1 === foundPairs) break;
          }
        }
        formulaSet[groupIndex].splice(nextClosingBracket, 1);
      }
      const symbolModalOpened = [...Array(groupIndex + 1)].map(e => Array(itemIndex));
      symbolModalOpened[groupIndex][itemIndex - 1] = true;
      this.setState({ symbolModalOpened });
    }

    this.setState({ formulaSet });
  }

  onSaveVariableHandler = (savedVar) => {
    const { editingVarIndex } = this.state;
    const { id, name, value, org_id, properties } = savedVar;
    const formulaSet = [...this.state.formulaSet];
    let isNew = true;
    formulaSet.forEach(row => {
      row.forEach(fs => {
        if (fs.variable && fs.variable.id === id) {
          isNew = false;
          fs.variable = {
            id, name, value, org_id, properties
          };
        }
      });
    });
    if (isNew) {
      formulaSet[editingVarIndex[0]][editingVarIndex[1]].variable = {
        id, name, value, org_id, properties
      };
    }
    this.closeFormulaModalHandler();
    this.setState({ formulaSet });
  }

  onRemoveItemHandler = () => {
    const formulaSet = [...this.state.formulaSet];
    const { editingVarIndex } = this.state;
    let movingIndex = editingVarIndex[1];
    // 1. the editingVar(x) must have symbol before (A+x) or After (x+B), find After is easier, then splice them (editing and symbol)
    if (formulaSet[editingVarIndex[0]][editingVarIndex[1] + 1] && this.isOperator(formulaSet[editingVarIndex[0]][editingVarIndex[1] + 1].operator)) {
      formulaSet[editingVarIndex[0]].splice(editingVarIndex[1] + 1, 1);
      formulaSet[editingVarIndex[0]].splice(editingVarIndex[1], 1);
    } else {
      formulaSet[editingVarIndex[0]].splice(editingVarIndex[1] - 1, 1);
      formulaSet[editingVarIndex[0]].splice(editingVarIndex[1] - 1, 1);
      movingIndex -= 2;
    }

    // 2. check brackets
    if (formulaSet[editingVarIndex[0]][movingIndex - 1] && formulaSet[editingVarIndex[0]][movingIndex - 1].special === '(' && formulaSet[editingVarIndex[0]][movingIndex + 1] && formulaSet[editingVarIndex[0]][movingIndex + 1].special === ')') {
      formulaSet[editingVarIndex[0]].splice(movingIndex + 1, 1);
      formulaSet[editingVarIndex[0]].splice(movingIndex - 1, 1);
    }

    // 3. check if this row is empty [] , so it should remove the row also.
    if (!formulaSet[editingVarIndex[0]].length) {
      if (editingVarIndex[0] !== 0) {
        formulaSet.splice(editingVarIndex[0], 1);
        formulaSet.splice(editingVarIndex[0] - 1, 1);
      }
    }
    this.closeFormulaModalHandler();
    this.setState({ formulaSet });
  }

  isOperator = (value) => {
    return value === '+' || value === '-' || value === '*' || value === '/';
  }

  onDeleteVariableHandler = () => {
    this.onRemoveItemHandler();
    this.props.deleteVariable(this.state.editingVariable.id);
    this.closeFormulaModalHandler();
  }

  onSubmitFormulaFormHandler = (event) => {
    event.preventDefault();

    let order = 1;
    let result = {
      name: this.state.formulaForm.name.value,
      org_id: this.props.currentOrg,
      sets: []
    };
    this.state.formulaSet.forEach((row, ri) => {
      row.forEach(fs => {
        result.sets.push({
          order,
          operator: fs.operator,
          special: fs.special,
          variable_id: fs.variable ? fs.variable.id : ''
        });
        order++;
      });
      if (ri !== this.state.formulaSet.length - 1) {
        result.sets.push({
          order,
          operator: '',
          special: '\n',
          variable_id: ''
        });
        order++;
      }
    });
    if (this.props.isAdding) {
      this.props.addFormula(result);
    } else {
      this.props.editFormula(this.props.editingFormula.id, result);
    }
  }

  render() {
    let result = '';
    result = this.state.formulaSet.map((row, i) => {
      return (
        <React.Fragment key={`group-formula-${i}`}>
          <div className={styles.Group} style={{ paddingLeft: (row.length === 1 && !row[0].operator) || row.length > 1 ? 51 : 0 }}>
            {
              row.map((ea, j) => {
                if (ea.variable) {
                  return (
                    <React.Fragment key={`formula-var-${j}`}>
                      {j === 0 ? <div className={styles.Parentheses} style={{ opacity: 0.5 }}>(</div> : null}
                      <div className={styles.Box} style={{ borderStyle: ea.variable.id ? 'solid' : 'dashed' }} onClick={() => this.onEditItemHandler(i, j)}>{ea.variable.name}</div>
                      {j === row.length - 1 ? <div className={styles.Parentheses} style={{ opacity: 0.5 }}>)</div> : null}
                      {j === row.length - 1 ? <div style={{ display: 'flex', color: '#6b6c6f', width: 50 }}>.....<div className={styles.AddButton} onClick={() => this.onAddNewItemHandler(i, j)}>+</div></div> : null}
                    </React.Fragment>
                  )
                } else if (ea.operator) {
                  return (
                    <React.Fragment key={`formula-var-${j}`}>
                      <div className={styles.Symbol} onClick={() => this.onOpenSymbolsModalHandler(i, j)}>
                        {ea.operator === '*' ? 'x' : ea.operator}
                        <SymbolModal
                          rowData={row}
                          currentItemIndex={j}
                          selected={ea.operator}
                          show={this.state.symbolModalOpened[i] ? this.state.symbolModalOpened[i][j] ? this.state.symbolModalOpened[i][j] : false : false}
                          closed={(e) => this.onToggleSymbolsHandler(e)}
                          selectSymbol={(value) => this.onChangeSymbolsHandler(value, i, j)}
                          toggleBracket={(e, hasBracket) => this.onToggleBracketHandler(e, hasBracket, i, j)}
                        />
                      </div>
                    </React.Fragment>
                  )
                } else if (ea.special === '(' || ea.special === ')') {
                  return (
                    <React.Fragment key={`formula-var-${j}`}>
                      {j === 0 ? <div className={styles.Parentheses} style={{ opacity: 0.5 }}>(</div> : null}
                      <div className={styles.Parentheses}>{ea.special}</div>
                      {j === row.length - 1 ? <div className={styles.Parentheses} style={{ opacity: 0.5 }}>)</div> : null}
                      {j === row.length - 1 ? <div style={{ display: 'flex', color: '#6b6c6f', width: 50 }}>.....<div className={styles.AddButton} onClick={() => this.onAddNewItemHandler(i, j)}>+</div></div> : null}
                    </React.Fragment>
                  )
                }
                // if (j === row.length - 1) {
                //   return (
                //     <React.Fragment key={`formula-button-${j}`}>
                //       <div style={{ display: 'flex', color: '#6b6c6f', width: 50 }}>.....<div className={styles.AddButton} onClick={() => this.onAddNewItemHandler(i, j)}>+</div></div>
                //     </React.Fragment>
                //   );
                // }
              })
            }
          </div>
        </React.Fragment>
      );
    });

    return (
      <div className={styles.FormulaBuilder}>
        <img src={CloseIcon} className={styles.CloseIcon} alt="close" onClick={this.props.closed} />
        <div style={{ width: 300 }}>
          <Input
            label="Formula Name"
            name="name"
            type="text"
            value={this.state.formulaForm.name.value}
            placeholder="Please insert your formula name"
            autoComplete="off"
            onChange={this.handleFormChange}
            error={`${this.state.formulaForm.name.touched && !this.state.formulaForm.name.valid ? `Name is required` : ''}`}
            required
          />
        </div>
        <div style={{ padding: 20 }}>
          <div className={styles.Container}>
            {result}
            <div className={styles.Group}>
              <Button type="button" color="primary" name="Add new group" noMargin overideButtonStyles={{ width: 150 }} click={this.onAddNewRowHandler} />
            </div>
          </div>

          <FormulaModal
            formulaSet={this.state.formulaSet}
            editingVarIndex={this.state.editingVarIndex}
            open={this.state.formulaModalOpened}
            removeItem={this.onRemoveItemHandler}
            modalClosed={this.closeFormulaModalHandler}
            editingVariable={this.state.editingVariable}
            saveVariable={this.onSaveVariableHandler}
            deleteVariable={this.onDeleteVariableHandler}
          />

          <div className={styles.ButtonsWrapper}>
            {this.props.isAdding ? (
              <React.Fragment>
                <div style={{ width: 112 }}>
                  <Button
                    type="button"
                    name="Cancel"
                    color="borderred"
                    noMargin
                    click={this.props.closed}
                  />
                </div>
                <div style={{ width: 200 }}>
                  <Button
                    type="button"
                    name={this.props.loading ? "Loading..." : "Done"}
                    color="primary"
                    disabled={this.checkSubmitButton()}
                    loading={this.props.loading}
                    click={this.onSubmitFormulaFormHandler}
                  />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div style={{ width: 112 }}>
                  <Button
                    type="button"
                    name="Delete"
                    color="red"
                    noMargin
                    click={this.props.toggleDeleteModal}
                  />
                </div>
                <div style={{ width: 200 }}>
                  <Button
                    type="button"
                    name={this.props.loading ? "Loading..." : "Save"}
                    color="green"
                    disabled={this.checkSubmitButton()}
                    loading={this.props.loading}
                    click={this.onSubmitFormulaFormHandler}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { loading } = state.formula;
  return { currentOrg, loading };
};

export default connect(mapStateToProps, { addFormula, editFormula, deleteVariable })(FormulaForm);
