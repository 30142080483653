import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import styles from './OperatorDrawer.module.scss';
import { updateObject } from '../../../../state/utils';
import Button from '../../UI/Button/Button';
import CalendarIcon from '../../../../assets/icons/Report/calendar.svg';
import Input from '../../UI/Input/Input';
import ArrowIcon from '../../../../assets/icons/Device/grey-arrow.svg';
import TargetIcon from '../../../../assets/icons/Operator/target.svg';
import StartTimeIcon from '../../../../assets/icons/Operator/start-time.svg';
import StopTimeIcon from '../../../../assets/icons/Operator/stop-time.svg';
import SpentIcon from '../../../../assets/icons/Operator/spent.svg';
import SearchPoInput from '../../UI/SearchPoInput/SearchPoInput';

import { searchAdvanceProductionPlans } from '../../../../state/ducks/AdvanceProductionPlan/actions';

const DEFAULT_OPERATOR_FORM = {
	po: {
		value: '',
		valid: false,
		touched: false,
		validation: {
			required: true
		}
	},
	machine: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	startDate: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	startTime: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	},
	newTargets: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: true
		}
	}
};

class StartOperatorDrawer extends Component {
	state = {
		formIsValid: true,
		operatorForm: DEFAULT_OPERATOR_FORM,
		showHistories: false,
		historyActuals: [],
		autoCheckingPoLoading: false,
		isPoSelected: false,
		isSearchResultsOpened: false,
		searchResults: []
	}

	componentDidMount() {
		if (this.props.newForm) {
			this.setupNewForm();
			this.setState({ historyActuals: [] });
		} else if (this.props.selectingPlan) {
			this.setObjForHistories(this.props.selectingPlan);
			this.setupForm(this.props.selectingPlan);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectingPlan !== this.props.selectingPlan && this.props.selectingPlan) {
			this.setObjForHistories(this.props.selectingPlan);
			this.setupForm(this.props.selectingPlan);
		} else if (this.props.resultSearchPlans !== prevProps.resultSearchPlans && this.props.resultSearchPlans) {
			clearTimeout(this.autoCheckPo);
			if (!this.props.resultSearchPlans.length) {
				this.setPoInValid(false);
			} else {
				this.setState({ isSearchResultsOpened: true });
			}
			if (this.props.scannerText) {
				if (this.props.resultSearchPlans.length) {
					this.clearOthersExceptPo(this.props.scannerText, this.props.resultSearchPlans[0]);
					this.setState({ isSearchResultsOpened: false, isPoSelected: true });
				}
			} else {
				this.setState({ isSearchResultsOpened: true });
			}
			this.setState({ autoCheckingPoLoading: false, searchResults: this.props.resultSearchPlans });
		} else if (this.props.newForm !== prevProps.newForm && this.props.newForm) {
			this.setupNewForm();
			this.setState({ historyActuals: [] });
		} else if (this.props.scannerText !== prevProps.scannerText && this.props.scannerText) {
			this.handleChange({ target: { name: 'po', value: this.props.scannerText } }, true);
		}
	}

	setPoInValid = () => {
		const updatedForm = { ...this.state.operatorForm };
		updatedForm.po.valid = false;
		this.setState({ operatorForm: updatedForm, formIsValid: false, isPoSelected: false });
	}

	setupNewForm = () => {
		let now = moment().valueOf();
		const operatorForm = { ...DEFAULT_OPERATOR_FORM };
		for (let inputIdentifier in operatorForm) {
			if (inputIdentifier === 'startDate') {
				operatorForm.startDate.value = moment(now).format('YYYY-MM-DD');
			} else if (inputIdentifier === 'startTime') {
				operatorForm.startTime.value = moment(now).format('HH:mm');
			} else {
				operatorForm[inputIdentifier].value = '';
				operatorForm[inputIdentifier].valid = false;
				operatorForm[inputIdentifier].touched = false;
			}
		}
		this.setState({ operatorForm, formIsValid: false });
	}

	setupForm = (plan) => {
		let now = moment().valueOf();
		const operatorForm = { ...this.state.operatorForm };
		operatorForm.startDate.value = moment(now).format('YYYY-MM-DD');
		operatorForm.startTime.value = moment(now).format('HH:mm');
		operatorForm.po = {
			value: plan.po,
			valid: true,
			touched: true,
			validation: { required: true }
		};
		operatorForm.machine.value = plan.machine ? plan.machine : '';
		operatorForm.machine.valid = plan.machine ? this.isMachineAvailable(plan.machine.id) : false;
		operatorForm.newTargets.value = plan.target;
		operatorForm.newTargets.valid = true;
		operatorForm.newTargets.valid = true;

		let formIsValid = true;
		for (let inputIdentifier in operatorForm) {
			formIsValid = operatorForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({ operatorForm, formIsValid });
	}

	setObjForHistories = (plan) => {
		const historyActuals = [];
		if (plan.actuals) {
			plan.actuals.slice(0).reverse().map(actual => {
				if (!this.isActualRunning(actual.end)) {
					historyActuals.push(actual);
				}
			});
		}
		this.setState({ historyActuals });
	}

	isActualRunning = (time) => {
		return time === '0001-01-01T00:00:00Z';
	}

	onConfirmStartHandler = (event) => {
		event.preventDefault();
		this.props.confirmStart(this.state.operatorForm);
	}

	handleChange = (e, fromScan) => {
		const { name, value } = e.target;
		if (name === 'po') {
			clearTimeout(this.autoCheckPo);
			if (!fromScan) this.props.clearScannerText();
			this.clearOthersExceptPo(value);
			this.setState({ isPoSelected: false, formIsValid: false });
			if (value !== '') {
				this.setState({ autoCheckingPoLoading: true });
				this.autoCheckPo = setTimeout(() => {
					this.props.searchAdvanceProductionPlans(this.props.currentOrg, value);
				}, 1000);
			} else {
				this.setState({ searchResults: [], autoCheckingPoLoading: false, isSearchResultsOpened: false });
			}
		} else {
			const updatedElement = updateObject(this.state.operatorForm[name], {
				value,
				valid: name === 'newTargets' ? value && value > 0 : true,
				touched: true
			});

			const updatedForm = updateObject(this.state.operatorForm, {
				[name]: updatedElement
			});

			let formIsValid = true;
			for (let inputIdentifier in updatedForm) {
				formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
			}
			this.setState({ operatorForm: updatedForm, formIsValid });
		}
	}

	clearOthersExceptPo = (newPoValue, plan) => {
		const operatorForm = { ...DEFAULT_OPERATOR_FORM };
		operatorForm.po = {
			value: newPoValue,
			valid: newPoValue === '' ? false : true,
			touched: true,
			validation: { required: true }
		};
		let formIsValid = true;
		for (let inputIdentifier in operatorForm) {
			formIsValid = operatorForm[inputIdentifier].valid && formIsValid;
		}
		if (plan) {
			// if click from a result list (search po)
			this.props.setSelectingPlan(plan);
		}
		this.setState({ operatorForm, formIsValid });
	}

	onChangeMachineHandler = (index) => {
		const updatedElement = updateObject(this.state.operatorForm.machine, {
			value: this.props.machines[index],
			valid: this.isMachineAvailable(this.props.machines[index].id),
			touched: true
		});

		const updatedForm = updateObject(this.state.operatorForm, {
			machine: updatedElement
		});

		let formIsValid = true;
		for (let inputIdentifier in updatedForm) {
			formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
		}

		this.setState({ operatorForm: updatedForm, formIsValid });
	}

	humanizeDuration = (ms, showSec) => {
		const days = moment.duration(ms).days();
		const hours = moment.duration(ms).hours();
		const minutes = moment.duration(ms).minutes();
		const seconds = moment.duration(ms).seconds();

		let result = days === 0 ? '' : days > 1 ? days + ' days ' : days + ' day ';
		result += hours === 0 ? '' : hours > 1 ? hours + ' hours ' : hours + ' hour ';
		result += minutes === 0 ? '' : minutes > 1 ? minutes + ' minutes ' : minutes + ' minute ';
		if (showSec) {
			result += seconds === 0 ? '' : seconds > 1 ? seconds + ' seconds ' : seconds + ' second';
		}

		return result;
	}

	isMachineAvailable = (machineId) => {
		if (this.props.machines) {
			const foundMachine = this.props.machines.find(m => m.id === machineId);
			if (foundMachine && foundMachine.actuals && foundMachine.actuals.find(act => act.end === '0001-01-01T00:00:00Z')) {
				return false;
			}
		}
		return true;
	}

	calculateRemainingTargets = () => {
		if (this.props.selectingPlan) {
			if (!this.props.selectingPlan.actuals) return this.props.selectingPlan.target;
			return this.props.selectingPlan.target - _.reduce(
				this.props.selectingPlan.actuals,
				(memo, b) => {
					return memo + b.target;
				}, 0);
		}
	}

	render() {
		const { po, machine, startDate, startTime, newTargets } = this.state.operatorForm;
		const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

		return (
			<div>
				<form onSubmit={this.onConfirmStartHandler}>
					{
						this.props.selectingPlan && !this.props.newForm ?
							<Input
								label="po"
								name="po"
								type="text"
								value={po.value ? po.value : ''}
								autoComplete="off"
								onChange={this.handleChange}
								required
								disabled
							/>
							: <SearchPoInput
								poValue={po}
								handleChange={this.handleChange}
								isPoSelected={this.state.isPoSelected}
								autoCheckingPoLoading={this.state.autoCheckingPoLoading}
								setIsPoSelected={(e) => this.setState({ isPoSelected: e })}
								isSearchResultsOpened={this.state.isSearchResultsOpened}
								setIsSearchResultsOpened={(e) => this.setState({ isSearchResultsOpened: e })}
								clearOthersExceptPo={this.clearOthersExceptPo}
								searchResults={this.state.searchResults}
								toggleScannerHandler={this.props.toggleScannerHandler}
							/>
					}

					<div className={styles.FormGroup}>
						<label className={styles.Title}>Start *</label>
						<div style={{ display: 'flex', position: 'relative' }}>
							{!isChrome && <img src={CalendarIcon} alt="time" className={styles.CalendarIcon} />}
							<input
								className={styles.SelectBox}
								style={{ paddingLeft: !isChrome ? 46 : null, flex: 2, position: 'initial' }}
								type="date"
								onChange={this.handleChange}
								name="startDate"
								value={startDate.value}
							/>
							<input
								className={styles.SelectBox}
								style={{ position: 'initial' }}
								type="time"
								onChange={this.handleChange}
								name="startTime"
								value={startTime.value}
							/>
						</div>
					</div>
					<div className={styles.FormGroup}>
						<label className={styles.Title}>Machine *</label>
						<div className={styles.MachineBoxWrapper}>
							{
								this.props.machines ?
									this.props.machines.map((m, index) =>
										<div key={`MachineBox-${m.id}`} onClick={() => this.onChangeMachineHandler(index)}
											className={
												`${styles.MachineBox} ${machine.value && m.id === machine.value.id ? styles.Selected : ''} ${this.isMachineAvailable(m.id) ? '' : styles.Disabled}`
											}>
											{m.name}
										</div>
									) : null
							}
						</div>
						{this.state.operatorForm.machine.value && <div className="ErrorText" style={{ marginTop: 5 }}>{this.isMachineAvailable(this.state.operatorForm.machine.value.id) ? '' : 'This machine is not available.'}</div>}
					</div>

					{/* <div className={styles.InfosGroup}>
						<label className={styles.Title} style={{ flex: 1, marginBottom: 0 }}>Target :</label>
						<div className={styles.InfoValue}>{target}</div>
					</div> */}
					<Input
						label={`Targets`}
						name="newTargets"
						type="number"
						value={newTargets.value}
						onChange={this.handleChange}
						required
						error={newTargets.touched && !newTargets.valid ? 'Targets is required' : null}
					/>

					<div style={{ height: 10, width: '100%' }}></div>

					{
						this.props.selectingPlan && this.props.selectingPlan.columns && this.props.structure
							? this.props.structure.columns.sort((a, b) => a.order - b.order).map(col => (
								<div key={`${po}-${col.name}`} className={styles.InfosGroup}>
									<label className={styles.Title} style={{ flex: 1, marginBottom: 0 }}>{col.display_name} :</label>
									<div className={styles.InfoValue}>{this.props.selectingPlan.columns[col.name] ? this.props.selectingPlan.columns[col.name] : '-'}</div>
								</div>
							))
							: null
					}

					{
						this.props.selectingPlan && this.state.historyActuals.length && this.props.selectingPlan.actuals && this.props.selectingPlan.actuals.length
							? <div className={styles.HistoriesWrapper}>
								<div className={styles.BoxWrapper} onClick={() => this.setState(prevState => { return { showHistories: !prevState.showHistories } })}>
									<div className={styles.Title}>Histories ({this.state.historyActuals.length}{this.state.historyActuals.length > 1 ? ' rounds' : ' round'})</div>
									<div className={`${styles.ArrowIcon} ${this.state.showHistories ? styles.Open : ''}`}><img alt="Arrow" src={ArrowIcon} /></div>
								</div>
								{
									this.state.showHistories
										? this.state.historyActuals.map((actual, index) => (
											<div key={actual.uuid} className={styles.HistoriesInfo}>
												<div className={styles.HistoriesRow} style={{ justifyContent: 'center', marginTop: 0 }}><span style={{ color: '#1665d8', fontWeight: 600 }}>History {index + 1}. ({actual.machine.name})</span></div>
												<div className={styles.HistoriesRow}><img src={StartTimeIcon} alt="Start" /><div>Start : <span>{moment(actual.start).format('DD/MM/YYYY HH:mm')}</span> by <span>{actual.start_by ? actual.start_by.first_name : 'n/a'}</span></div></div>
												<div className={styles.HistoriesRow}><img src={StopTimeIcon} alt="Stop" /><div>Stop : <span>{moment(actual.stop).format('DD/MM/YYYY HH:mm')}</span> by <span>{actual.stop_by ? actual.stop_by.first_name : 'n/a'}</span></div></div>
												<div className={styles.HistoriesRow}><img src={SpentIcon} alt="Spent" /><div>Spent: <span>{this.humanizeDuration(moment(actual.stop).valueOf() - moment(actual.start).valueOf())}</span></div></div>
												<div className={styles.HistoriesRow}><img src={TargetIcon} alt="Target" /><div>Target: <span>{actual.target}</span></div></div>
												<div className={styles.HistoriesForTotal}>Total: {actual.total}</div>
												<div style={{ display: 'flex' }}>
													<div className={styles.HistoriesForGood}>Good: {actual.ok}</div>
													<div className={styles.HistoriesForDefect}>Defect: {actual.defect}</div>
												</div>

											</div>
										))
										: null
								}
							</div>
							: null
					}

					<div style={{ height: 70, width: 'auto' }} />
					<Button
						type="submit"
						name={this.props.loading ? 'Loading...' : 'Start PO'}
						color="primary"
						disabled={!this.state.formIsValid}
						loading={this.props.loading}
					/>
					<Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
				</form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { currentOrg } = state.org;
	const { resultSearchPlans } = state.advanceProductionPlan;
	return { currentOrg, machines: state.machine.machinesAndActuals, resultSearchPlans };
};

export default connect(mapStateToProps, { searchAdvanceProductionPlans })(StartOperatorDrawer);