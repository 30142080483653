import React, { Component } from 'react';

import styles from './WidgetSelector.module.scss';

class WidgetSelector extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.widgetValue !== this.props.widgetValue) {
      return true;
    }
    return false;
  }

  onSelectWidgeType = (val) => {
    this.props.selectWidgetTypeHandler(val);
  }

  render() {
    return (
      <div className={styles.FormGroup}>
        <label className={styles.Title}>Widgets *</label>
        <div className={styles.WidgetButtonWrapper}>
          {
            this.props.widgetTypes ?
              this.props.widgetTypes.map((type, i) => {
                let widgetButtonStyles = [styles.WidgetButton];
                if (this.props.widgetValue === type.value) {
                  widgetButtonStyles.push(styles.Selected)
                }
                return (
                  <div key={type.label} className={widgetButtonStyles.join(' ')} onClick={() => this.onSelectWidgeType(type.value)}>
                    <img src={type.icon} alt="{type.label} widget type" style={{ flex: 2, width: type.styles ? type.styles.width : 28 }} />
                    <span className={styles.WidgetButtonLabel}>{type.label}</span>
                  </div>
                );
              }) : null
          }
        </div>
      </div>
    );
  }
}

export default WidgetSelector;
