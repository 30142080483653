import React, { Component } from 'react';
import { connect } from 'react-redux';
import MultipleSelect from 'react-select';

import styles from './SchedulerType.module.scss';
import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

import { addSchedulerType, editSchedulerType } from '../../../../state/ducks/Scheduler/actions';

const colourStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30;",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500,
    color: 'white'
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "white"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0,
    zIndex: 1000
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "#1665d8",
  }),
  multiValueLabel: base => ({
    ...base,
    color: "white",
  })
};

let CATEGORIES = [{ id: 'planned', label: 'Planned' }, { id: 'unplanned', label: 'Unplanned' }, { id: 'others', label: 'Others' }];
const COLORS = [
  '#33D391', '#359970', '#B0DC51', '#FFD533', '#FDBC64', '#FF7B4D', '#E6492D', '#E8697D', '#C95C76', '#FF7BD0', '#777AE5', '#79AFFD', '#5591EA', '#5C5C5C'
];

const DEFAULT_SCHEDULER_TYPE_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  color: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  category: {
    value: CATEGORIES[0],
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
  customCategory: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: true
    }
  },
};

class SchedulerTypeDrawer extends Component {
  state = {
    formIsValid: false,
    schedulerTypeForm: DEFAULT_SCHEDULER_TYPE_FORM,
    categoriesOption: CATEGORIES
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAdding && (prevProps.isAdding !== this.props.isAdding)) {
      this.onClearFields();
    } else if (this.props.schedulerTypes && prevProps.schedulerTypes !== this.props.schedulerTypes) {
      let categoriesOption = [{ id: 'planned', label: 'Planned' }, { id: 'unplanned', label: 'Unplanned' }];
      this.props.schedulerTypes.forEach(st => {
        const isExisting = categoriesOption.find(co => co.id === st.category);
        if (st.category !== 'planned' && st.category !== 'unplanned' && !isExisting) {
          categoriesOption.push({
            id: st.category,
            label: st.category
          });
        }
      });
      categoriesOption.push({ id: 'others', label: 'Others' });
      this.setState({ categoriesOption });
    } else if ((prevProps.addedSchedulerType !== this.props.addedSchedulerType) || (prevProps.updatedSchedulerType !== this.props.updatedSchedulerType)) {
      this.props.closed();
    } else if (this.props.editingType && prevProps.editingType !== this.props.editingType) {
      this.setupForm(this.props.editingType);
    }
  }

  onClearFields = () => {
    const schedulerTypeForm = { ...DEFAULT_SCHEDULER_TYPE_FORM };
    this.setState({ schedulerTypeForm, formIsValid: false });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.schedulerTypeForm };
    for (let inputIdentifier in updatedForm) {
      let value = form[inputIdentifier] ? form[inputIdentifier] : '';
      if (inputIdentifier === 'category') {
        value = this.state.categoriesOption.find(cat => cat.id === value);
      }
      let updatedElement = updateObject(updatedForm[inputIdentifier], {
        value,
        valid: true,
        touched: true,
      });

      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }

    this.setState({ schedulerTypeForm: updatedForm, formIsValid: true });
  }

  handleFormChange = event => {
    const { name, value } = event.target;
    let updatedElement = updateObject(this.state.schedulerTypeForm[name], {
      value,
      valid: name === 'category' || name === 'color' ? true : checkValidity(event.target.value, this.state.schedulerTypeForm[name].validation),
      touched: true
    });

    let updatedForm = updateObject(this.state.schedulerTypeForm, {
      [name]: updatedElement
    });

    if (name === 'category') {
      if (value.id === 'others') {
        let updatedCustomCatElement = updateObject(this.state.schedulerTypeForm[name], {
          value: '',
          valid: false,
          touched: false
        });
        updatedForm = updateObject(this.state.schedulerTypeForm, {
          [name]: updatedElement,
          customCategory: updatedCustomCatElement
        });
      } else {
        let updatedCustomCatElement = updateObject(this.state.schedulerTypeForm[name], {
          value: '',
          valid: true,
          touched: true
        });
        updatedForm = updateObject(this.state.schedulerTypeForm, {
          [name]: updatedElement,
          customCategory: updatedCustomCatElement
        });
      }
    }

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ schedulerTypeForm: updatedForm, formIsValid });
  };

  onSubmitSchedulerTypeHandler = (event) => {
    event.preventDefault();
    const { name, color, category, customCategory } = this.state.schedulerTypeForm;
    if (this.props.isAdding) {
      this.props.addSchedulerType(
        this.props.currentOrg,
        name.value,
        color.value,
        category.value.id !== 'others' ? category.value.id : customCategory.value
      );
    } else {
      this.props.editSchedulerType(
        this.props.editingType.id,
        name.value,
        color.value,
        category.value.id !== 'others' ? category.value.id : customCategory.value
      );
    }
    this.onClearFields();
  }

  render() {
    const { name, color, category, customCategory } = this.state.schedulerTypeForm;

    return (
      <div>
        <form onSubmit={this.onSubmitSchedulerTypeHandler}>
          <Input
            label="Name"
            name="name"
            type="text"
            value={name.value}
            placeholder="Please insert your type name"
            autoComplete="off"
            onChange={this.handleFormChange}
            error={`${name.touched && !name.valid ? `Name is required` : ''}`}
            required
          />

          <div className={styles.FormGroup}>
            <label className={styles.Title}>Color *</label>
            <div className={styles.ColorBoxWrapper}>
              {
                COLORS.map((c, index) =>
                  <div key={`Type-Color-${index}`} style={{ position: 'relative' }}>
                    {color.value === c ? <span style={{ position: 'absolute', left: 4 }}>✓</span> : null}
                    <div style={{ backgroundColor: c }} className={styles.ColorBox} onClick={() => this.handleFormChange({ target: { name: 'color', value: c } })} />
                  </div>
                )
              }
            </div>
          </div>

          <div className={styles.FormGroup}>
            <label className={styles.Title}>Category *</label>
            <MultipleSelect closeMenuOnSelect={true} options={this.state.categoriesOption} styles={colourStyles}
              placeholder="Please select category"
              value={category.value}
              onChange={(value) => this.handleFormChange({ target: { name: 'category', value } })}
              getOptionValue={opt => opt.id}
            />
          </div>

          {
            category && category.value && category.value.id === 'others' ?
              <Input
                label="New category"
                name="customCategory"
                type="text"
                value={customCategory.value}
                placeholder="Please name your new category"
                autoComplete="off"
                onChange={this.handleFormChange}
                error={`${customCategory.touched && !customCategory.valid ? `New category is required` : ''}`}
                required
              />
              : null
          }
          <div className="ErrorText">{this.props.error ? this.props.error : ''}</div>

          <div style={{ height: 70, width: 'auto' }} />

          {
            this.props.isAdding
              ? <>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Done'}
                  color="primary"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
              </>
              : <>
                <Button
                  type="submit"
                  name={this.props.loading ? 'Loading...' : 'Save'}
                  color="green"
                  disabled={!this.state.formIsValid}
                  loading={this.props.loading}
                />
                <Button type="button" name="Delete" color="borderred" click={this.props.toggleDeleteModal} />
              </>
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { schedulerTypes, addedSchedulerType, updatedSchedulerType, result, error } = state.scheduler;
  return {
    currentOrg, schedulerTypes, addedSchedulerType, updatedSchedulerType, result, error
  };
};

export default connect(mapStateToProps, { addSchedulerType, editSchedulerType })(SchedulerTypeDrawer);