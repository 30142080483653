import * as actionTypes from './types';

// ----------------------- CLEAR TIMELINE STATUSES -----------------------
export const clearTimelineStatuses = () => {
  return {
    type: actionTypes.CLEAR_TIMELINE_STATUSES
  };
}

// ----------------------- GET TIMELINE STATUSES -----------------------
export const getTimelineStatusStart = () => {
  return {
    type: actionTypes.GET_TIMELINE_STATUSES_START
  };
}

export const getTimelineStatus = (timeline_id, start, stop, machine_id, properties) => {
  return {
    type: actionTypes.GET_TIMELINE_STATUSES,
    timeline_id,
    start,
    stop,
    machine_id,
    properties
  };
}

export const getTimelineStatusSuccess = (response, properties) => {
  return {
    type: actionTypes.GET_TIMELINE_STATUSES_SUCCESS,
    timelineStatuses: response.status,
    properties
  };
}

export const getTimelineStatusFail = (error) => {
  return {
    type: actionTypes.GET_TIMELINE_STATUSES_FAIL,
    error: error
  };
}

// ----------------------- ADD TIMELINE REMARK -----------------------
export const addTimelineRemarkStart = () => {
  return {
    type: actionTypes.ADD_TIMELINE_REMARK_START
  };
}

export const addTimelineRemark = (timeline_id, start, stop, text) => {
  return {
    type: actionTypes.ADD_TIMELINE_REMARK,
    timeline_id, start, stop, text
  };
}

export const addTimelineRemarkSuccess = (response) => {
  return {
    type: actionTypes.ADD_TIMELINE_REMARK_SUCCESS,
    result: response.result
  };
}

export const addTimelineRemarkFail = (error) => {
  return {
    type: actionTypes.ADD_TIMELINE_REMARK_FAIL,
    error: error
  };
}

// ----------------------- EDIT TIMELINE REMARK -----------------------
export const editTimelineRemarkStart = () => {
  return {
    type: actionTypes.EDIT_TIMELINE_REMARK_START
  };
}

export const editTimelineRemark = (id, text) => {
  return {
    type: actionTypes.EDIT_TIMELINE_REMARK,
    id,
    text
  };
}

export const editTimelineRemarkSuccess = (response) => {
  return {
    type: actionTypes.EDIT_TIMELINE_REMARK_SUCCESS,
    result: response.result
  };
}

export const editTimelineRemarkFail = (error) => {
  return {
    type: actionTypes.EDIT_TIMELINE_REMARK_FAIL,
    error: error
  };
}

// ----------------------- GET PARETO DATA -----------------------
export const getParetoDataStart = () => {
  return {
    type: actionTypes.GET_PARETO_DATA_START
  };
}

export const getParetoData = (machine_id, start, stop, padding) => {
  return {
    type: actionTypes.GET_PARETO_DATA,
    machine_id, start, stop, padding
  };
}

export const getParetoDataSuccess = (response, properties) => {
  return {
    type: actionTypes.GET_PARETO_DATA_SUCCESS,
    paretoData: response.data,
    properties
  };
}

export const getParetoDataFail = (error) => {
  return {
    type: actionTypes.GET_PARETO_DATA_FAIL,
    error
  };
}