import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
    result: null,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_DEVICE_START:
        case actionTypes.REBOOT_DEVICE_START:
        case actionTypes.CREATE_DEVICE_START: 
            return callStart(state, action);
        case actionTypes.RESET_DEVICE_SUCCESS: 
        case actionTypes.REBOOT_DEVICE_SUCCESS:
        case actionTypes.CREATE_DEVICE_SUCCESS:
            return callSuccess(state, action);
        case actionTypes.RESET_DEVICE_FAIL:
        case actionTypes.REBOOT_DEVICE_FAIL:
        case actionTypes.CREATE_DEVICE_FAIL: 
            return callFail(state, action);
        default: return state;
    }
};

const callStart = (state, action) => {
    return updateObject(state, { result: null, error: null });
};

const callSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        result: action
    });
};

const callFail = (state, action) => {
    return updateObject(state, {
        result: null,
        error: action.error,
    });
};

export default reducer;