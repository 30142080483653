import React, { Component } from 'react';
import { connect } from 'react-redux';
import MultipleSelect from 'react-select';

import styles from './MachineDrawer.module.scss';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import SimpleSwitch from '../../UI/SimpleSwitch/SimpleSwitch';
import ArrowUpIcon from '../../../../assets/icons/arrow-up.svg';
import DeleteIcon from '../../../../assets/icons/delete-red-bin.svg';
import PlusIcon from '../../../../assets/icons/plus-circle-blue.svg';
import AddKPIIcon from '../../../../assets/icons/Rule/add-circle.svg';
import DeleteKPIIcon from '../../../../assets/icons/Rule/delete-circle.svg';

import { getFormulaMappings } from '../../../../state/ducks/Formula/actions';
import { getSchedulerTypes } from '../../../../state/ducks/Scheduler/actions';

const colourStyles = (isError = false) => {
  return {
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data && state.data.color ? state.data.color : "white",
      backgroundColor: state.data && state.data.color ? state.data.color : '',
      animation: state.data && state.data.flashing ? '2s infinite ' + styles.flashing : '',
      height: state.data.color ? 30 : '',
      width: state.data.color ? 30 : '',
      overflowX: 'hidden'
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#1c1c1f",
      borderRadius: 4,
      borderColor: state.isFocused ? "#1665d8" : isError ? "#e6492d" : "#2b2b30;",
      "&:hover": {
        borderColor: "#1665d8"
      },
      fontSize: 14,
      fontWeight: 500,
    }),
    input: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        margin: '5px 8px',
        height: data.color ? 30 : '',
        width: data.color ? 30 : '',
        color: data.color ? data.color : '',
        animation: data.flashing ? '2s infinite ' + styles.flashing : '',
        borderColor: "#1c1c1f",
        backgroundColor: data && data.color ? data.color : '#1c1c1f',
        "&:hover,&:active": {
          backgroundColor: data && data.color ? data.color : '#1665d8'
        }
      };
    },
    menu: base => ({
      ...base,
      marginTop: 0,
    }),
    menuList: base => ({
      ...base,
      backgroundColor: "#1c1c1f",
      fontSize: 14,
      fontWeight: 500
    })
  }
};

const multiSelectColourStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#1c1c1f",
    borderRadius: 4,
    borderColor: state.isFocused ? "#1665d8" : "#2b2b30",
    "&:hover": {
      borderColor: "#1665d8"
    },
    fontSize: 14,
    fontWeight: 500
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "white"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: "#1c1c1f",
      backgroundColor: isDisabled
        ? null
        : isFocused ? 'rgba(22, 101, 216, 0.7)' : "#1c1c1f",
    };
  },
  menu: base => ({
    ...base,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    backgroundColor: "#1c1c1f",
    fontSize: 14,
    fontWeight: 500
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: "#1665d8",
  }),
  multiValueLabel: base => ({
    ...base,
    color: "white",
  })
};

const colorOptions = [
  { label: '', id: '#ff5454', color: '#ff5454', flashing: false },
  { label: '', id: '#9ea0a5', color: '#9ea0a5', flashing: false },
  { label: '', id: '#ffa000', color: '#ffa000', flashing: false },
  { label: '', id: '#34aa44', color: '#34aa44', flashing: false },
  { label: '', id: '#B5EF8A', color: '#B5EF8A', flashing: false },
  { label: '', id: '#023E8A', color: '#023E8A', flashing: false },
  { label: '', id: '#1665d8', color: '#1665d8', flashing: false },
  // { label: '', id: '#facf55', color: '#facf55', flashing: true }, yellow
  // { label: '', id: '#222226', color: '#222226', flashing: false },
];

// const methodOptions = [
//   { label: 'Max', id: 'max', method: 'max' },
//   { label: 'Sum', id: 'sum', method: 'sum' },
// ];

const DEFAULT_STATUS = {
  value: null,
  reason: 'N/A',
  color: { label: '', id: '#1665d8', color: '#1665d8', flashing: false },
  calInRunTime: false,
  open: true,
  // generateEvent: false
};

const DEFAULT_FORM = {
  name: '',
  statusDevice: null,
  status: [
    { value: 0, reason: 'Default', color: { label: '', id: '#1665d8', color: '#1665d8', flashing: false }, calInRunTime: false, open: false }, // generateEvent: false
  ],
  totalDevice: null,
  goodDevice: null,
  defectDevice: null,
  aFormula: null,
  pFormula: null,
  qFormula: null,
  devicesChildrenValue: null,
  efficiencyKPI: null,
  productionUsageTarget: null
};

class MachineDrawer extends Component {
  state = {
    form: { ...DEFAULT_FORM },
    formErr: {
      name: null,
      statusDevice: false,
      status: ['', ''],
    },
    kpis: [{ stype: '', kpiValue: '' }]
  }

  componentDidMount() {
    this.props.getSchedulerTypes(this.props.currentOrg);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.editingMachine && prevProps.editingMachine !== this.props.editingMachine) {
      this.setupForm(this.props.editingMachine);
      const ref = 'machine:' + this.props.editingMachine.id;
      this.props.getFormulaMappings(this.props.currentOrg, ref);
      if (this.props.editingMachine.properties.kpis) {
        this.setupKPIs(this.props.editingMachine.properties.kpis);
      }
    } else if (this.props.isAdding !== prevProps.isAdding && this.props.isAdding) {
      this.setState({
        form: { ...DEFAULT_FORM },
        formErr: {
          name: null,
          statusDevice: false,
          status: ['', ''],
        },
        kpis: [{ stype: '', kpiValue: '' }]
      });
    } else if (this.props.formulaMappings !== prevProps.formulaMappings && this.props.formulaMappings) {
      let form = { ...this.state.form };
      const aRef = 'machine:' + this.props.editingMachine.id + ':a';
      const aFormula = this.props.formulaMappings.find(fm => fm.reference === aRef);
      form.aFormula = aFormula ? { id: aFormula.formula_id, label: aFormula.formula.name } : null;

      const pRef = 'machine:' + this.props.editingMachine.id + ':p';
      const pFormula = this.props.formulaMappings.find(fm => fm.reference === pRef);
      form.pFormula = pFormula ? { id: pFormula.formula_id, label: pFormula.formula.name } : null;

      const qRef = 'machine:' + this.props.editingMachine.id + ':q';
      const qFormula = this.props.formulaMappings.find(fm => fm.reference === qRef);
      form.qFormula = qFormula ? { id: qFormula.formula_id, label: qFormula.formula.name } : null;
      this.setState({ form });
    }
  }

  setValuesToForm = (values, isVirtual, device) => {
    if (!values) return {};
    if (isVirtual) {
      return {
        id: values.id,
        name: values.name + " (Virtual)",
        label: values.name + " (Virtual)",
        secret: values.secret,
        socket: values.socket
      };
    } else {
      return {
        id: values.id,
        name: values.name + " (" + device.name + ")",
        label: values.name + " (" + device.name + ")",
        secret: values.device_secret,
        socket: values.socket,
        unit: values.unit_name,
        equation: values.functions,
        can_control: values.can_control
      };
    }
  }

  setupForm = (obj) => {
    // console.log('Edit', obj)
    let form = { ...this.state.form };
    form.name = obj.name;
    form.devicesChildrenValue = [];
    for (let i = 0; i < this.props.devices.length; i++) {
      let d = this.props.devices[i];
      for (let j = 0; j < d.device_sockets.length; j++) {
        let ds = d.device_sockets[j];
        if (obj.status_device_secret && obj.status_device_socket) {
          if (ds.device_secret === obj.status_device_secret && ds.socket === obj.status_device_socket) {
            form.statusDevice = this.setValuesToForm(ds, false, d);
          }
        }
        if (obj.total_device_secret && obj.total_device_socket) {
          if (ds.device_secret === obj.total_device_secret && ds.socket === obj.total_device_socket) {
            form.totalDevice = this.setValuesToForm(ds, false, d);
          }
        }
        if (obj.good_device_secret && obj.good_device_socket) {
          if (ds.device_secret === obj.good_device_secret && ds.socket === obj.good_device_socket) {
            form.goodDevice = this.setValuesToForm(ds, false, d);
          }
        }
        if (obj.defect_device_secret && obj.defect_device_socket) {
          if (ds.device_secret === obj.defect_device_secret && ds.socket === obj.defect_device_socket) {
            form.defectDevice = this.setValuesToForm(ds, false, d);
          }
        }
        if (obj.properties && obj.properties.devicesChildren) {
          obj.properties.devicesChildren.forEach(dc => {
            const value = dc.split('/');
            const secret = value[0];
            const socket = value[1];
            if (ds.device_secret === secret && ds.socket === +socket) {
              form.devicesChildrenValue.push(this.setValuesToForm(ds, false, d));
            }
          });
        }
      }
    }

    if (this.props.virtualDevices) {
      this.props.virtualDevices.forEach(vd => {
        if (vd.secret === obj.status_device_secret && vd.socket === obj.status_device_socket) {
          form.statusDevice = this.setValuesToForm(vd, true);
        }
        if (vd.secret === obj.total_device_secret && vd.socket === obj.total_device_socket) {
          form.totalDevice = this.setValuesToForm(vd, true);
        }
        if (vd.secret === obj.good_device_secret && vd.socket === obj.good_device_socket) {
          form.goodDevice = this.setValuesToForm(vd, true);
        }
        if (vd.secret === obj.defect_device_secret && vd.socket === obj.defect_device_socket) {
          form.defectDevice = this.setValuesToForm(vd, true);
        }
      });
    }

    if (obj.properties && obj.properties.devicesChildren && this.props.virtualDevices) {
      obj.properties.devicesChildren.forEach(dc => {
        const value = dc.split('/');
        const secret = value[0];
        const socket = value[1];
        const foundVirtual = this.props.virtualDevices.find(vd => vd.secret === secret && vd.socket === +socket);
        if (foundVirtual) form.devicesChildrenValue.push(this.setValuesToForm(foundVirtual, true));
      });
    }

    if (obj.properties) {
      if (obj.properties.efficiencyKPI) form.efficiencyKPI = obj.properties.efficiencyKPI;
      else form.efficiencyKPI = '';

      if (obj.properties.efficiencyKPI) form.productionUsageTarget = obj.properties.productionUsageTarget;
      else form.productionUsageTarget = '';
    }

    form.status = [];
    for (let key in obj.status_rules) {
      let sr = obj.status_rules[key];
      form.status[+sr.id - 1] = {
        reason: sr.reason,
        color: { label: '', id: sr.color, color: sr.color, flashing: sr.flashing },
        value: key,
        calInRunTime: sr.calInRunTime,
        // generateEvent: sr.generateEvent ? true : false
      }
    }

    this.setState({ form });
  }

  setupKPIs = (kpis) => {
    let receivingKPI = [];
    if (this.props.schedulerTypes) {
      kpis.forEach(kpi => {
        const foundSchedulerType = this.props.schedulerTypes.find(st => st.id === kpi.stypeId);
        if (foundSchedulerType) {
          receivingKPI.push({
            stype: { id: foundSchedulerType.id, label: foundSchedulerType.name },
            kpiValue: kpi.kpiValue
          });
        } else {
          receivingKPI.push({
            stype: { id: '0000', label: '(not found)' },
            kpiValue: kpi.kpiValue
          });
        }
      });
    }

    if (receivingKPI.length === 0) {
      receivingKPI = [{ stype: '', kpiValue: '' }];
    }
    this.setState({ kpis: receivingKPI });
  }

  onSubmitHandler = (event) => {
    event.preventDefault();
    let e = { ...this.state.formErr };
    e.status.forEach(e => {
      if (e.status !== '') return;
    });

    if (this.state.form.statusDevice === null) {
      e.statusDevice = true;
      this.setState({ formErr: e });
      return;
    }
    e.statusDevice = false;

    this.props.onSubmit(this.state.form, this.props.formulaMappings, this.state.kpis);
    this.setState({ form: { ...DEFAULT_FORM }, formErr: e });
    this.props.closed();
  }

  handleInputChange = (key, value) => {
    let form = { ...this.state.form };
    let formErr = { ...this.state.formErr };
    form[key] = value;
    if (key === 'name') {
      if (value === '')
        formErr[key] = 'Machine Name is required';
      else
        formErr[key] = '';
    }
    if (key === 'statusDevice') {
      formErr.statusDevice = value === null;
    }
    this.setState({ form: form, formErr: formErr });
  }

  // VERIFIED
  handleStatusChange = (event, index, colorValue) => {
    let updatedForm = { ...this.state.form };
    let updatedFormErr = { ...this.state.formErr };

    if (event) {
      const { name, value } = event.target;
      updatedForm.status[index][name] = name === 'value' ? +value : value;
      updatedFormErr.status[index] = (name === 'value' && +value < 0) ? 'Greater than or equal to 0' : '';
    } else {
      updatedForm.status[index].color = colorValue;
      updatedFormErr.status[index] = '';
    }
    this.setState({ form: updatedForm, formErr: updatedFormErr });
  }

  // VERIFIED
  handleToggleStatusDetail = (id) => {
    let updatedForm = { ...this.state.form };
    updatedForm.status[id].open = !updatedForm.status[id].open;
    if (updatedForm.status[id].open) {
      for (let key in updatedForm.status) {
        if (+key !== id) {
          updatedForm.status[key].open = false;
        }
      }
    }
    this.setState({ form: updatedForm });
  }

  // VERIFIED
  handleSwithCountInRunTime = (index) => {
    let updatedForm = { ...this.state.form };
    updatedForm.status[index].calInRunTime = !updatedForm.status[index].calInRunTime;
    this.setState({ form: updatedForm });
  }

  handleSwithGenerateEvent = (index) => {
    let updatedForm = { ...this.state.form };
    updatedForm.status[index].generateEvent = !updatedForm.status[index].generateEvent;
    this.setState({ form: updatedForm });
  }

  // VERIFIED
  handleAddStatus = () => {
    let updatedForm = { ...this.state.form };
    let updatedFormErr = { ...this.state.formErr };
    updatedForm.status.forEach(s => s.open = false);
    updatedForm.status.push({ ...DEFAULT_STATUS });
    updatedFormErr.status[updatedForm.status.length - 1] = '';
    this.setState({ form: updatedForm, formErr: updatedFormErr });
  }

  // VERIFIED
  handleRemoveStatus = (event, index) => {
    event.stopPropagation();
    let updatedForm = { ...this.state.form };
    updatedForm.status.splice(index, 1);
    this.setState({ form: updatedForm });
  }

  handleKPIChange = (name, index, value) => {
    const kpis = [...this.state.kpis];
    kpis[index][name] = value;
    this.setState({ kpis });
  }

  onAddKPIHandler = () => {
    const kpis = [...this.state.kpis];
    kpis.push({
      stype: '',
      kpiValue: ''
    });
    this.setState({ kpis });
  }

  onRemoveKPIHandler = (i) => {
    const kpis = [...this.state.kpis];
    kpis.splice(i, 1);
    this.setState({ kpis });
  }

  render() {
    let deviceOptions = [];
    let allDevicesOptions = [];
    if (this.props.devices) {
      this.props.devices.forEach(device => {
        device.device_sockets.forEach(socket => {
          const d = {
            id: socket.id,
            name: socket.name + " (" + device.name + ")",
            label: socket.name + " (" + device.name + ")",
            secret: socket.device_secret,
            socket: socket.socket,
            unit: socket.unit_name,
            equation: socket.functions,
            can_control: socket.can_control
          };
          if (!socket.can_control) {
            deviceOptions.push(d);
          }
          allDevicesOptions.push(d);
        });
      });
    }

    if (this.props.virtualDevices) {
      this.props.virtualDevices.forEach(vd => {
        const v = {
          id: vd.uuid,
          name: vd.name + " (Virtual)",
          label: vd.name + " (Virtual)",
          secret: vd.secret,
          socket: vd.socket
        };
        deviceOptions.push(v);
        allDevicesOptions.push(v);
      });
    }

    let formulaOptions = [];
    if (this.props.formulas) {
      this.props.formulas.forEach(fml => {
        formulaOptions.push({
          id: fml.id,
          label: fml.name
        });
      });
    }

    let schedulerTypesOption = [];
    if (this.props.schedulerTypes) {
      this.props.schedulerTypes.forEach(st => {
        schedulerTypesOption.push({
          id: st.id,
          label: st.name
        });
      });
    }

    const status = this.state.form.status.map((s, i) => {
      const pickColor = s.color.color === '#222226' ? '#9ea0a5' : s.color.color;
      const overrideBoxColor = { color: pickColor, borderColor: pickColor };
      const overrideStatusColor = { backgroundColor: pickColor, animation: s.color.flashing ? '2s infinite ' + styles.flashing : '' };
      return (
        <div key={"state_" + i} style={i !== 0 ? { marginTop: 10 } : {}}>
          <div style={overrideBoxColor} className={`${styles.StatusLabel} ${this.state.formErr.status[i] ? styles.StatusLabelError : ''}`}>
            <div style={{ padding: 10, cursor: 'pointer' }} onClick={() => this.handleToggleStatusDetail(i)}>
              <div style={overrideStatusColor} className={styles.StatusColor} /> {s.reason}
              {i > 0 && <img src={DeleteIcon} alt="open status" className={styles.DeleteIcon} onClick={e => this.handleRemoveStatus(e, i)} />}
              <img src={ArrowUpIcon} alt="open status" className={`${styles.Arrow} ${s.open ? styles.Open : ''}`} />
            </div>
            <div style={{ display: s.open ? 'flex' : 'none', alignItems: 'baseline', padding: 10, paddingBottom: 0 }}>
              <div style={{ flex: '0 1 90px', marginRight: 10 }} onClick={e => e.stopPropagation()}>
                <Input
                  label="Value"
                  name="value"
                  placeholder="Value"
                  type="number"
                  min={0}
                  value={s.value || s.value === 0 ? s.value : ''}
                  onChange={event => this.handleStatusChange(event, i)}
                  error={this.state.formErr.status[i]}
                  required
                />
              </div>
              <div style={{ flex: 1 }} onClick={e => e.stopPropagation()}>
                <Input
                  label="Reason"
                  name="reason"
                  placeholder="Reason"
                  type="text"
                  value={s.reason}
                  onChange={event => this.handleStatusChange(event, i)}
                  required={false}
                />
              </div>
            </div>
            <div style={{ display: s.open ? 'flex' : 'none', alignItems: 'center', padding: 10, paddingTop: 0 }}>
              <div style={{ flex: 1 }}>
                <div style={{ display: 'flex', flex: 1, marginRight: 10, alignItems: 'center', justifyContent: 'left' }} onClick={e => e.stopPropagation()}>
                  <div style={{ marginRight: 10, color: '#6b6c6f' }}>Count in run time</div>
                  <SimpleSwitch enable={s.calInRunTime} onChangeEnableHandler={() => { this.handleSwithCountInRunTime(i) }} />
                </div>
                {/* <div style={{ display: 'flex', flex: 1, marginRight: 10, alignItems: 'center', justifyContent: 'left', marginTop: 10 }} onClick={e => e.stopPropagation()}>
                  <div style={{ marginRight: 24, color: '#6b6c6f' }}>Generate event</div>
                  <SimpleSwitch enable={s.generateEvent} onChangeEnableHandler={() => { this.handleSwithGenerateEvent(i) }} />
                </div> */}
              </div>
              <div style={{ flex: '0 1 100px' }} onClick={e => e.stopPropagation()}>
                <MultipleSelect options={colorOptions} styles={colourStyles()}
                  placeholder="Color"
                  value={s.color}
                  onChange={value => this.handleStatusChange(null, i, value)}
                  getOptionValue={opt => opt.id}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className={styles.MachineDrawer}>
        <form onSubmit={this.onSubmitHandler}>
          <Input
            label="Machine name"
            type="text"
            value={this.state.form.name}
            placeholder="Insert machine name"
            autoComplete="off"
            onChange={(event) => this.handleInputChange('name', event.target.value)}
            error={this.state.formErr.name}
            overidestyleformgroup={{ marginRight: 10, flex: 2 }}
            required
          />

          <div>
            <label className={styles.Title}>STATUS DEVICE *</label>
            <MultipleSelect isSearchable options={deviceOptions} styles={colourStyles(this.state.formErr.statusDevice)}
              placeholder="Select a device"
              value={this.state.form.statusDevice}
              onChange={(value) => this.handleInputChange('statusDevice', value)}
              style={{ borderColor: 'red' }}
              getOptionValue={opt => opt.id}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
            <label className={styles.Title}>Status *</label>
            {status}
            <div className={styles.AddStatusBtn} onClick={e => this.handleAddStatus()}><img src={PlusIcon} alt="add status" /><p>Add Status</p></div>
          </div>

          <div style={{ marginTop: 15 }}>
            <label className={styles.Title}>TOTAL DEVICE</label>
            <MultipleSelect isClearable isSearchable options={deviceOptions} styles={colourStyles(this.state.formErr.totalDevice)}
              placeholder="Select a device"
              value={this.state.form.totalDevice}
              onChange={(value) => this.handleInputChange('totalDevice', value)}
              style={{ borderColor: 'red' }}
              getOptionValue={opt => opt.id}
            />
          </div>

          <div style={{ marginTop: 15 }}>
            <label className={styles.Title}>GOOD DEVICE</label>
            <MultipleSelect isClearable isSearchable options={deviceOptions} styles={colourStyles(this.state.formErr.goodDevice)}
              placeholder="Select a device"
              value={this.state.form.goodDevice}
              onChange={(value) => this.handleInputChange('goodDevice', value)}
              style={{ borderColor: 'red' }}
              getOptionValue={opt => opt.id}
            />
          </div>

          <div style={{ marginTop: 15 }}>
            <label className={styles.Title}>DEFECT DEVICE</label>
            <MultipleSelect isClearable isSearchable options={deviceOptions} styles={colourStyles(this.state.formErr.defectDevice)}
              placeholder="Select a device"
              value={this.state.form.defectDevice}
              onChange={(value) => this.handleInputChange('defectDevice', value)}
              style={{ borderColor: 'red' }}
              getOptionValue={opt => opt.id}
            />
          </div>

          {
            !this.props.isAdding ?
              <>
                <div style={{ marginTop: 15 }}>
                  <label className={styles.Title}>Availability's Formula</label>
                  <MultipleSelect isClearable isSearchable options={formulaOptions} styles={colourStyles(false)}
                    placeholder="Select a formula or use default setting"
                    value={this.state.form.aFormula}
                    onChange={(value) => this.handleInputChange('aFormula', value)}
                    style={{ borderColor: 'red' }}
                    getOptionValue={opt => opt.id}
                  />
                </div>

                <div style={{ marginTop: 15 }}>
                  <label className={styles.Title}>Performance's Formula</label>
                  <MultipleSelect isClearable isSearchable options={formulaOptions} styles={colourStyles(false)}
                    placeholder="Select a formula or use default setting"
                    value={this.state.form.pFormula}
                    onChange={(value) => this.handleInputChange('pFormula', value)}
                    style={{ borderColor: 'red' }}
                    getOptionValue={opt => opt.id}
                  />
                </div>

                <div style={{ marginTop: 15 }}>
                  <label className={styles.Title}>Quality's Formula</label>
                  <MultipleSelect isClearable isSearchable options={formulaOptions} styles={colourStyles(false)}
                    placeholder="Select a formula or use default setting"
                    value={this.state.form.qFormula}
                    onChange={(value) => this.handleInputChange('qFormula', value)}
                    style={{ borderColor: 'red' }}
                    getOptionValue={opt => opt.id}
                  />
                </div>
              </>
              : null
          }
          <div style={{ marginTop: 15 }}>
            <label className={styles.Title}>Devices children</label>
            <MultipleSelect isMulti isSearchable closeMenuOnSelect={false}
              options={allDevicesOptions}
              styles={multiSelectColourStyles}
              placeholder="Select all devices under this machine"
              value={this.state.form.devicesChildrenValue}
              onChange={(value) => this.handleInputChange('devicesChildrenValue', value)}
              getOptionValue={opt => opt.id}
            />
          </div>

          <div style={{ marginTop: 15 }}>
            <label className={styles.Title} style={{ textTransform: 'unset' }}>EFFICIENCY KPI (kWh/unit/day)</label>
            <Input
              type="number"
              step="0.01"
              value={this.state.form.efficiencyKPI ? this.state.form.efficiencyKPI : ''}
              placeholder="Insert your Efficiency KPI (kWh/Unit)"
              autoComplete="off"
              onChange={(event) => this.handleInputChange('efficiencyKPI', event.target.value)}
            />
          </div>

          <div style={{ marginTop: 15 }}>
            <label className={styles.Title} style={{ textTransform: 'unset' }}>PRODUCTION USAGE TARGET (kWh/day)</label>
            <Input
              type="number"
              step="0.01"
              value={this.state.form.productionUsageTarget ? this.state.form.productionUsageTarget : ''}
              placeholder="Insert your production usage target (kWh)"
              autoComplete="off"
              onChange={(event) => this.handleInputChange('productionUsageTarget', event.target.value)}
            />
          </div>

          <label className={styles.Title} style={{ textTransform: 'unset' }}>NON PRODUCTION USAGE TARGET (kWh/day)</label>
          {
            this.state.kpis
              ? this.state.kpis.map((kpi, i) => (
                <div key={`kpi-${i}`} className={styles.KpisWrapper}>
                  <div style={{ flex: 2 }}>
                    <MultipleSelect isClearable isSearchable options={schedulerTypesOption} styles={colourStyles(false)}
                      placeholder="Scheduler type"
                      value={kpi ? kpi.stype : ''}
                      onChange={(value) => this.handleKPIChange('stype', i, value)}
                      getOptionValue={opt => opt.id}
                      style={{ height: 70 }}
                    />
                  </div>
                  <Input
                    type="number"
                    step="0.01"
                    value={kpi ? kpi.kpiValue : ''}
                    placeholder="kWh"
                    autoComplete="off"
                    onChange={(event) => this.handleKPIChange('kpiValue', i, event.target.value)}
                    style={{ height: 38, minHeight: 38 }}
                    overidestyleformgroup={{ margin: 0, flex: '0 1 130px', height: 38, minHeight: 38, marginLeft: 5 }}
                  />
                  <div className={styles.KPIButtonsSection}>
                    {this.state.kpis.length > 1 ? <img className={styles.IconButton} src={DeleteKPIIcon} alt="Delete KPI Icon" onClick={() => this.onRemoveKPIHandler(i)} /> : null}
                    {i === this.state.kpis.length - 1 ? <img className={styles.IconButton} src={AddKPIIcon} alt="Add KPI Icon" onClick={this.onAddKPIHandler} /> : null}
                  </div>
                </div>
              ))
              : null
          }

          <div style={{ height: 70, width: 'auto' }} />
          {
            this.props.isAdding ?
              <React.Fragment>
                <Button
                  type="submit"
                  name="Done"
                  color="primary"
                />
                <Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
              </React.Fragment>
              :
              <React.Fragment>
                <Button
                  type="submit"
                  name="Save"
                  color="green"
                />
                <Button type="button" name="Delete" color="borderred" click={this.props.toggleDeleteModal} />
              </React.Fragment>
          }
        </form>


      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { formulaMappings } = state.formula;
  const { schedulerTypes } = state.scheduler;
  return { currentOrg, formulaMappings, schedulerTypes };
};

export default connect(mapStateToProps, { getFormulaMappings, getSchedulerTypes })(MachineDrawer);
