export const GET_PRODUCTION_PLANS_START = 'GET_PRODUCTION_PLANS_START';
export const GET_PRODUCTION_PLANS = 'GET_PRODUCTION_PLANS';
export const GET_PRODUCTION_PLANS_SUCCESS = 'GET_PRODUCTION_PLANS_SUCCESS';
export const GET_PRODUCTION_PLANS_FAIL = 'GET_PRODUCTION_PLANS_FAIL';

export const GET_PRODUCTION_PLAN_START = 'GET_PRODUCTION_PLAN_START';
export const GET_PRODUCTION_PLAN = 'GET_PRODUCTION_PLAN';
export const GET_PRODUCTION_PLAN_SUCCESS = 'GET_PRODUCTION_PLAN_SUCCESS';
export const GET_PRODUCTION_PLAN_FAIL = 'GET_PRODUCTION_PLAN_FAIL';

export const ADD_PRODUCTION_PLAN_START = 'ADD_PRODUCTION_PLAN_START';
export const ADD_PRODUCTION_PLAN = 'ADD_PRODUCTION_PLAN';
export const ADD_PRODUCTION_PLAN_SUCCESS = 'ADD_PRODUCTION_PLAN_SUCCESS';
export const ADD_PRODUCTION_PLAN_FAIL = 'ADD_PRODUCTION_PLAN_FAIL';

export const EDIT_PRODUCTION_PLAN_START = 'EDIT_PRODUCTION_PLAN_START';
export const EDIT_PRODUCTION_PLAN = 'EDIT_PRODUCTION_PLAN';
export const EDIT_PRODUCTION_PLAN_SUCCESS = 'EDIT_PRODUCTION_PLAN_SUCCESS';
export const EDIT_PRODUCTION_PLAN_FAIL = 'EDIT_PRODUCTION_PLAN_FAIL';

export const DELETE_PRODUCTION_PLAN_START = 'DELETE_PRODUCTION_PLAN_START';
export const DELETE_PRODUCTION_PLAN = 'DELETE_PRODUCTION_PLAN';
export const DELETE_PRODUCTION_PLAN_SUCCESS = 'DELETE_PRODUCTION_PLAN_SUCCESS';
export const DELETE_PRODUCTION_PLAN_FAIL = 'DELETE_PRODUCTION_PLAN_FAIL';

export const SET_SELECTED_PRODUCTION_PLAN = 'SET_SELECTED_PRODUCTION_PLAN';

export const CLEAR_SELECTED_PRODUCTION_PLAN = 'CLEAR_SELECTED_PRODUCTION_PLAN';
