import React, { Component } from "react";

import styles from './Map.module.scss';

class Map extends Component {

  componentDidUpdate(prevProps, prevState) {
    if (this.props.id !== prevProps.id) {
      this.onScriptLoad();
    }
  }

  componentDidMount() {
    this.onScriptLoad();
  }

  onScriptLoad = () => {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options);
    this.props.onMapLoad(map);
  }

  render() {
    return (
      <div className={styles.GoogleMap} id={this.props.id} />
    );
  }
}

export default Map;