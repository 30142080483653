import React, { useState, useEffect } from 'react';
import moment from 'moment';

import styles from './BreakdownSnackbar.module.scss';
import BreakdownIcon from '../../../../assets/icons/Operator/breakdown-blue.png';
import ArrowDownIcon from '../../../../assets/icons/navigation-items/menu-down-black.svg';

const BreakdownSnackbar = ({ machines, events, closed, fillTheDoc }) => {
    const [on, setOn] = useState(false);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setOn(true);
    }, [events]);

    const expandEvents = () => {
        if (events.length > 1) {
            setOn(false);
            setExpanded(true);
            setOn(true);
        } else {
            // take actions
            fillTheDoc(0);
        }
    }

    const showLess = () => { setExpanded(false); setOn(false); }

    const findMachines = (ids) => {
        if (machines && machines.length) {
            let result = '';
            ids.forEach((mid, i) => {
                result += machines.find(mac => mac.id === mid).name + (i < ids.length - 1 ? ', ' : '');
            });
            return result;
        }
    }

    return (
        events && events.length && (!expanded || events.length === 1) ?
            <div style={{ position: 'relative', marginTop: 10 }} onClick={expandEvents}>
                <div className={`${styles.Snackbar} ${false ? styles.Off : styles.On}`}>
                    <div className={styles.Wrapper}>
                        <span className={styles.CloseBtn} aria-hidden="true" onClick={(e) => closed(e, 0)}>&times;</span>
                        <div className={styles.IconWrapper}>
                            <img src={BreakdownIcon} style={{ width: 60 }} />
                        </div>
                        <div className={styles.Content}>
                            <div className={styles.Title}>{events[0].rule.name}</div>
                            <div className={styles.Details}>Location : {findMachines(events[0].machine_ids)}</div>
                            <div className={styles.Details}>{moment(events[0].created_date).format("DD/MM/YYYY HH:mm")}</div>
                            <div className={styles.Navigation}>Click to fill in the document</div>
                        </div>
                    </div>
                </div>
                {
                    events && events.length > 1 ?
                        <>
                            <div className={styles.Snackbar}>
                                <div className={`${styles.Wrapper} ${styles.More} ${styles.More1}`}></div>
                            </div>
                            <div className={styles.Snackbar}>
                                <div className={`${styles.Wrapper} ${styles.More} ${styles.More2}`}></div>
                            </div>
                        </>
                        : null
                }
            </div>
            : events && events.length ?
                events.map((ev, i) => (
                    <div key={ev.uuid}>
                        {i === 0
                            ? <div style={{ textAlign: 'right', marginRight: 30 }}>
                                <div className={styles.ShowLess} onClick={showLess}>
                                    <img src={ArrowDownIcon} className={styles.ArrowLess} />
                                    Show less
                                </div>
                            </div>
                            : null
                        }
                        <div style={{ position: 'relative', marginTop: 10 }}>
                            <div className={`${styles.Snackbar} ${on ? styles.On : styles.Off}`} onClick={() => fillTheDoc(i)}>
                                <div className={styles.Wrapper}>
                                    <span className={styles.CloseBtn} aria-hidden="true" onClick={(e) => closed(e, i)}>&times;</span>
                                    <div className={styles.IconWrapper}>
                                        <img src={BreakdownIcon} style={{ width: 60 }} />
                                    </div>
                                    <div className={styles.Content}>
                                        <div className={styles.Title}>{ev.rule.name}</div>
                                        <div className={styles.Details}>Location : {findMachines(ev.machine_ids)}</div>
                                        <div className={styles.Details}>{moment(ev.created_date).format("DD/MM/YYYY HH:mm")}</div>
                                        <div className={styles.Navigation}>Click to fill in the document</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                : null

    );
}

export default BreakdownSnackbar;