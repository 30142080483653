import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import styles from './BreakdownLog.module.scss';
import Button from '../../UI/Button/Button';
import PlusCircleIcon from '../../../../assets/icons/plus-circle.svg';
import LoadingTable from '../../UI/LoadingSkeleton/LoadingTable/LoadingTable';
import BreakdownLogDrawer from '../BreakdownLogDrawer/BreakdownLogDrawer';
import SideDrawer from '../../Navigation/SideDrawer/SideDrawer';
import DeleteModal from '../../shared/DeleteModal/DeleteModal';

import { addDocumentNote, deleteDocumentNote } from '../../../../state/ducks/Document/actions';
import { DELETE_DOCUMENT_NOTE_SUCCESS } from '../../../../state/ducks/Document/types';
import { searchEvents, addEvent } from '../../../../state/ducks/Event/actions';
import SelectMachineDropdown from '../SelectMachineDropdown/SelectMachineDropdown';
import Pagination from '../../UI/Pagination/Pagination';

const FILTER_STATUS = {
	PENDING: 0,
	ATTACHED: 1
}

class BreakdownLog extends Component {
	state = {
		autoFilterLogsIndex: -1,
		isAdding: false,
		editingLog: null,
		isDrawerOpened: false,
		logList: [],
		savingEvent: null,
		eventList: null,
		isDeleteModalOpened: false,
		currentPage: 0,
		rowsPerPage: 50,
	}

	componentDidMount() {
		this.props.searchEvents(this.props.currentOrg, null, null, null, 0); // search only BREAKDOWN events
		if (this.props.eventsResult) {
			this.setState({ eventList: this.props.eventsResult });
			if (this.props.fillDocUuid) {
				const foundLog = this.props.eventsResult.find(el => el.uuid === this.props.fillDocUuid);
				this.setState({ editingLog: foundLog, isAdding: false, isDrawerOpened: true });
				this.props.alreadyFillIn();
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.result !== this.props.result && this.props.result === 'success') {
			if (this.props.updatedDocumentNote !== prevProps.updatedDocumentNote || this.props.addedDocumentNote !== prevProps.addedDocumentNote || this.props.type === DELETE_DOCUMENT_NOTE_SUCCESS) {
				this.props.searchEvents(this.props.currentOrg, null, null, null, 0); // search only BREAKDOWN events
				this.setState({ savingEvent: null });
			}
		} else if (this.props.addedEvent && !_.isEqual(this.props.addedEvent, prevProps.addedEvent)) {
			const { typeId, title, content, option } = this.state.savingEvent;
			this.props.addDocumentNote(
				this.props.currentOrg,
				typeId,
				title,
				content,
				option,
				null,
				this.props.addedEvent[0].uuid
			);
		} else if (!_.isEqual(this.props.eventsResult, prevProps.eventsResult)) {
			this.setState({ eventList: this.props.eventsResult });
			if (this.props.fillDocUuid) {
				const foundLog = this.props.eventsResult.find(el => el.uuid === this.props.fillDocUuid);
				this.setState({ editingLog: foundLog, isAdding: false, isDrawerOpened: true });
				this.props.alreadyFillIn();
			}
		} else if (this.props.fillDocUuid !== prevProps.fillDocUuid && this.props.fillDocUuid) {
			const foundLog = this.state.eventList.find(el => el.uuid === this.props.fillDocUuid);
			this.setState({ editingLog: foundLog, isAdding: false, isDrawerOpened: true });
			this.props.alreadyFillIn();
		}
	}

	onAutoFilterLogsHandler = (index) => {
		if (index === this.state.autoFilterLogsIndex) {
			this.setState({ eventList: this.props.eventsResult });
		} else {
			let foundList = null;
			if (index === FILTER_STATUS.PENDING) {
				foundList = this.props.eventsResult.filter(evr => !evr.documents);
			} else {
				foundList = this.props.eventsResult.filter(evr => evr.documents && evr.documents.length);
			}
			this.setState({ eventList: foundList });
		}

		this.setState({ autoFilterLogsIndex: index === this.state.autoFilterLogsIndex ? -1 : index });
	}

	drawerToggleHandler = () => {
		this.setState(prevState => {
			return { isDrawerOpened: !prevState.isDrawerOpened }
		});
	}

	onCreateNewLogHandler = () => {
		this.setState({ isAdding: true, editingLog: null, isDrawerOpened: true });
	}

	renderDateTime = (date, time) => {
		return moment(date + ' ' + time).format("DD/MM/YY HH:mm");
	}

	onAddEventHandler = (docForm) => {
		this.props.addEvent(
			this.props.currentOrg,
			docForm.option.machine.id,
			moment(docForm.option.startDate + ' ' + docForm.option.startTime).valueOf(),
			moment(docForm.option.endDate + ' ' + docForm.option.endTime).valueOf(),
			'TNM_operator',
			0,
			'Manual'
		);
		this.setState({ savingEvent: docForm });
	}

	onEditDocumentHandler = (e, i) => {
		e.stopPropagation();
		this.setState({ editingLog: this.state.eventList[i], isAdding: false, isDrawerOpened: true });
	}

	onToggleDeleteModalHandler = (event) => {
		event.preventDefault();
		this.setState({ isDeleteModalOpened: true });
	}

	onConfirmDeleteDocumentHandler = (event) => {
		event.preventDefault();
		this.props.deleteDocumentNote(this.state.editingLog.documents[0].uuid);
		this.setState({ editingLog: null, isDeleteModalOpened: false, isDrawerOpened: false });
	}

	renderMachineText = (ids) => {
		if (!ids || !ids.length || !this.props.machines) return '-';
		let res = '';
		if (ids) {
			ids.sort((a, b) => a - b).forEach((id, i) => {
				const found = this.props.machines.find(m => m.id === id);
				res += found ? (found.name + (i !== ids.length - 1 ? ', ' : '')) : '-'
			});
		}
		return res ? res : '-';
	}

	whatIsDefaultMachineId = () => {
		return (this.props.defaultOperatorMachine && this.props.defaultOperatorMachine[this.props.currentOrg]) ? this.props.defaultOperatorMachine[this.props.currentOrg].id : null;
	}

	onChangePageHandler = (event, page) => {
		event.preventDefault();
		this.setState({ currentPage: page });
	}

	onChangeRowsPerPageHandler = (event) => {
		event.preventDefault();
		let amount = +event.target.value;
		const { eventList } = this.state;
		let currentPage = this.state.currentPage;
		if (currentPage > 0 && (currentPage * amount) > eventList?.length) {
			currentPage = 0;
		}
		this.setState({ rowsPerPage: amount, currentPage });
	}

	render() {
		const { eventList, currentPage, rowsPerPage } = this.state;
		let events = eventList && eventList.length ? eventList.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage) : [];

		return (
			<>
				<SideDrawer
					title={this.state.isAdding ? 'Add New Log (Manual)' : this.state.editingLog && this.state.editingLog.documents ? 'Edit Log' : `Add new Log (Event: ${this.state.editingLog?.rule.name})`}
					open={this.state.isDrawerOpened} closed={this.drawerToggleHandler}
					overrideStyles={{ width: '80vw', maxWidth: '80%' }}>
					<BreakdownLogDrawer
						isAdding={this.state.isAdding}
						closed={this.drawerToggleHandler}
						editingLog={this.state.editingLog}
						addEventHandler={this.onAddEventHandler}
						toggleDeleteModal={this.onToggleDeleteModalHandler}
						toggleScannerHandler={this.props.toggleScannerHandler}
						scannerText={this.props.scannerText}
						clearScannerText={this.props.clearScannerText}
					/>
				</SideDrawer>
				<div className={styles.HeaderTableWrapper}>
					<div className={styles.MainSection}>
						<div className={styles.HeaderText}>Breakdown Log of <div style={{ width: 20, height: 5 }} /><SelectMachineDropdown /></div>
						<div className={styles.AutoFillBoxWrapper}>
							<div className={[styles.AutoFillBox, this.state.autoFilterLogsIndex === FILTER_STATUS.PENDING ? styles.Incomplete : ''].join(' ')} onClick={() => this.onAutoFilterLogsHandler(FILTER_STATUS.PENDING)}>PENDING</div>
							<div className={[styles.AutoFillBox, this.state.autoFilterLogsIndex === FILTER_STATUS.ATTACHED ? styles.Complete : ''].join(' ')} onClick={() => this.onAutoFilterLogsHandler(FILTER_STATUS.ATTACHED)}>ATTACHED</div>
							{/* <div className={[styles.AutoFillBox, this.state.autoFilterLogsIndex === 2 ? styles.Manual : ''].join(' ')} onClick={() => this.onAutoFilterLogsHandler(2)}>MANUAL</div> */}
						</div>
						<Button type="button" icon={PlusCircleIcon} color="primary" name="Add new Log" overideButtonStyles={{ width: 180 }} noMargin click={this.onCreateNewLogHandler} />
					</div>
				</div>

				<div className={styles.TableWrapper}>
					<table>
						<thead>
							<tr>
								<th style={{ textAlign: 'center', width: 50 }}>No.</th>
								<th>Name</th>
								<th>Start</th>
								<th>Stop</th>
								<th>Reason</th>
								<th>Machine(s)</th>
								<th>Origin</th>
								<th style={{ width: 120, textAlign: 'center' }}>Log Status</th>
							</tr>
						</thead>
						<tbody>
							{
								this.props.eventLoading
									? <tr><td colSpan='100%'><LoadingTable /></td></tr>
									: events && events.length
										? events.map((evr, i) => {
											if (!this.whatIsDefaultMachineId()) {
												return (
													<tr key={evr.uuid} onClick={(e) => this.onEditDocumentHandler(e, i)}>
														<td style={{ textAlign: 'center' }}>{(rowsPerPage * currentPage) + (i + 1)}.</td>
														<td>{evr.rule ? evr.rule.name : 'Manual'}</td>
														<td>{moment(evr.start).format("DD/MM/YY HH:mm")}</td>
														<td>{evr.end.startsWith('0001-01-01') ? '-' : moment(evr.end).format("DD/MM/YY HH:mm")}</td>
														<td>{evr.documents && evr.documents.length ? evr.documents[0].option.breakdowns.map(e => e.title).join(', ') : '-'}</td>
														<td>{this.renderMachineText(evr.machine_ids)}</td>
														<td>{evr.origin}</td>
														<td className={`${evr.documents && evr.documents.length ? styles.Complete : styles.Incomplete}`}>{evr.documents && evr.documents.length ? 'ATTACHED' : 'PENDING'}</td>
													</tr>
												)
											} else {
												return evr.machine_ids && evr.machine_ids.length && evr.machine_ids.includes(+this.whatIsDefaultMachineId()) && (
													<tr key={evr.uuid} onClick={(e) => this.onEditDocumentHandler(e, i)}>
														<td style={{ textAlign: 'center' }}>{(rowsPerPage * currentPage) + (i + 1)}.</td>
														<td>{evr.rule ? evr.rule.name : 'Manual'}</td>
														<td>{moment(evr.start).format("DD/MM/YY HH:mm")}</td>
														<td>{moment(evr.end).format("DD/MM/YY HH:mm")}</td>
														<td>{evr.documents && evr.documents.length ? evr.documents[0].option.breakdowns.map(e => e.title).join(', ') : '-'}</td>
														<td>{this.renderMachineText(evr.machine_ids)}</td>
														<td>{evr.origin}</td>
														<td className={`${evr.documents && evr.documents.length ? styles.Complete : styles.Incomplete}`}>{evr.documents && evr.documents.length ? 'ATTACHED' : 'PENDING'}</td>
													</tr>
												)
											}
										})
										: <tr><td colSpan="100%" style={{ textAlign: 'center' }}>Not found</td></tr>
							}
						</tbody>
					</table>
				</div>
				<Pagination
					total={this.state.eventList?.length}
					currentPage={currentPage}
					rowsPerPage={rowsPerPage}
					onChangePage={this.onChangePageHandler}
					onChangeRowsPerPage={this.onChangeRowsPerPageHandler}
				/>
				<div style={{ height: 100, width: '100%' }} />
				<DeleteModal
					title="Document Log"
					open={this.state.isDeleteModalOpened}
					modalClosed={() => this.setState({ isDeleteModalOpened: false })}
					confirmDelete={this.onConfirmDeleteDocumentHandler}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	const { currentOrg } = state.org;
	const { addedDocumentNote, updatedDocumentNote, result, loading, error, type } = state.document;
	const { defaultOperatorMachine } = state.machine;
	const { eventsResult, addedEvent } = state.event;

	return {
		currentOrg, defaultOperatorMachine, machines: state.machine.machinesAndActuals, addedDocumentNote, updatedDocumentNote, result, loading, error, type,
		eventsResult, eventLoading: state.event.loading, addedEvent
	};
}

export default connect(mapStateToProps, { searchEvents, addEvent, addDocumentNote, deleteDocumentNote })(BreakdownLog);

