import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  deviceConfigs: null,
  systemDeviceConfig: null,
  systemDeviceConfigJson: null,
  updatedSystemDeviceConfigJson: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DEVICE_CONFIGS_START: return getDeviceConfigsStart(state, action);
    case actionTypes.GET_DEVICE_CONFIGS_SUCCESS: return getDeviceConfigsSuccess(state, action);
    case actionTypes.GET_DEVICE_CONFIGS_FAIL: return getDeviceConfigsFail(state, action);

    case actionTypes.ADD_DEVICE_CONFIG_START: return addDeviceConfigStart(state, action);
    case actionTypes.ADD_DEVICE_CONFIG_SUCCESS: return addDeviceConfigSuccess(state, action);
    case actionTypes.ADD_DEVICE_CONFIG_FAIL: return addDeviceConfigFail(state, action);

    case actionTypes.DELETE_DEVICE_CONFIGS_START: return deleteDeviceConfigsStart(state, action);
    case actionTypes.DELETE_DEVICE_CONFIGS_SUCCESS: return deleteDeviceConfigsSuccess(state, action);
    case actionTypes.DELETE_DEVICE_CONFIGS_FAIL: return deleteDeviceConfigsFail(state, action);

    case actionTypes.GET_SYSTEM_DEVICE_CONFIG: return getSystemDeviceConfigStart(state, action);
    case actionTypes.GET_SYSTEM_DEVICE_CONFIG_SUCCESS: return getSystemDeviceConfigSuccess(state, action);
    case actionTypes.GET_SYSTEM_DEVICE_CONFIG_FAIL: return getSystemDeviceConfigFail(state, action);

    case actionTypes.SAVE_SYSTEM_DEVICE_CONFIG: return saveSystemDeviceConfigStart(state, action);
    case actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_SUCCESS: return saveSystemDeviceConfigSuccess(state, action);
    case actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_FAIL: return saveSystemDeviceConfigFail(state, action);

    case actionTypes.GET_SYSTEM_DEVICE_CONFIG_JSON: return getSystemDeviceConfigJsonStart(state, action);
    case actionTypes.GET_SYSTEM_DEVICE_CONFIG_JSON_SUCCESS: return getSystemDeviceConfigJsonSuccess(state, action);
    case actionTypes.GET_SYSTEM_DEVICE_CONFIG_JSON_FAIL: return getSystemDeviceConfigJsonFail(state, action);

    case actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_JSON: return saveSystemDeviceConfigJsonStart(state, action);
    case actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_JSON_SUCCESS: return saveSystemDeviceConfigJsonSuccess(state, action);
    case actionTypes.SAVE_SYSTEM_DEVICE_CONFIG_JSON_FAIL: return saveSystemDeviceConfigJsonFail(state, action);

    default: return state;
  }
};

const getDeviceConfigsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getDeviceConfigsSuccess = (state, action) => {
  // const deviceConfigs = action.deviceConfigs ? action.deviceConfigs.sort((a, b) => a.created_date - b.created_date) : null;
  return updateObject(state, {
    deviceConfigs: action.deviceConfigs,
    error: null,
    loading: false
  });
}

const getDeviceConfigsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addDeviceConfigStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addDeviceConfigSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const addDeviceConfigFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}
const deleteDeviceConfigsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const deleteDeviceConfigsSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    // result: action.result,
    error: null,
    loading: false
  });
}

const deleteDeviceConfigsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getSystemDeviceConfigStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: false });
}

const getSystemDeviceConfigSuccess = (state, action) => {
  return updateObject(state, {
    systemDeviceConfig: action.systemDeviceConfig,
    error: null,
    loading: false
  });
}

const getSystemDeviceConfigFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const saveSystemDeviceConfigStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const saveSystemDeviceConfigSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const saveSystemDeviceConfigFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getSystemDeviceConfigJsonStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getSystemDeviceConfigJsonSuccess = (state, action) => {
  return updateObject(state, {
    systemDeviceConfigJson: action.systemDeviceConfigJson,
    error: null,
    loading: false
  });
}

const getSystemDeviceConfigJsonFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const saveSystemDeviceConfigJsonStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const saveSystemDeviceConfigJsonSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    updatedSystemDeviceConfigJson: action.updatedSystemDeviceConfigJson,
    result: action.result,
    error: null,
    loading: false
  });
}

const saveSystemDeviceConfigJsonFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;