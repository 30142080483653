export const GET_DEVICE_SOCKETS_START = 'GET_DEVICE_SOCKETS_START';
export const GET_DEVICE_SOCKETS = 'GET_DEVICE_SOCKETS';
export const GET_DEVICE_SOCKETS_SUCCESS = 'GET_DEVICE_SOCKETS_SUCCESS';
export const GET_DEVICE_SOCKETS_FAIL = 'GET_DEVICE_SOCKETS_FAIL';

export const ADD_DEVICE_SOCKET_START = 'ADD_DEVICE_SOCKET_START';
export const ADD_DEVICE_SOCKET = 'ADD_DEVICE_SOCKET';
export const ADD_DEVICE_SOCKET_SUCCESS = 'ADD_DEVICE_SOCKET_SUCCESS';
export const ADD_DEVICE_SOCKET_FAIL = 'ADD_DEVICE_SOCKET_FAIL';

export const EDIT_DEVICE_SOCKET_START = 'EDIT_DEVICE_SOCKET_START';
export const EDIT_DEVICE_SOCKET = 'EDIT_DEVICE_SOCKET';
export const EDIT_DEVICE_SOCKET_SUCCESS = 'EDIT_DEVICE_SOCKET_SUCCESS';
export const EDIT_DEVICE_SOCKET_FAIL = 'EDIT_DEVICE_SOCKET_FAIL';

export const EDIT_DEVICE_SOCKETS_WITH_JSON_START = 'EDIT_DEVICE_SOCKETS_WITH_JSON_START';
export const EDIT_DEVICE_SOCKETS_WITH_JSON = 'EDIT_DEVICE_SOCKETS_WITH_JSON';
export const EDIT_DEVICE_SOCKETS_WITH_JSON_SUCCESS = 'EDIT_DEVICE_SOCKETS_WITH_JSON_SUCCESS';
export const EDIT_DEVICE_SOCKETS_WITH_JSON_FAIL = 'EDIT_DEVICE_SOCKETS_WITH_JSON_FAIL';

export const CHANGE_SOCKET_ICON_START = 'CHANGE_SOCKET_ICON_START';
export const CHANGE_SOCKET_ICON = 'CHANGE_SOCKET_ICON';
export const CHANGE_SOCKET_ICON_SUCCESS = 'CHANGE_SOCKET_ICON_SUCCESS';
export const CHANGE_SOCKET_ICON_FAIL = 'CHANGE_SOCKET_ICON_FAIL';