import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import styles from '../Report.module.scss';
import Button from '../../UI/Button/Button';
import BarChart from '../../UI/BarChart/BarChart';
import GaugeChart from '../../UI/GaugeChart/GaugeChart';
import { calculateOee } from '../../../../state/ducks/AdvanceActualProduction/actions';
import moment from 'moment';

import TimelineBar from '../../Timeline/TimelineBar';

let lineOptions = {
    tooltips: {
        mode: 'index',
        intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        position: 'top',
    },
    scales: {
        yAxes: [{
            display: true,
            ticks: {
                stepSize: 25,
                min: 0,
                // max: 100,
                beginAtZero: true,
                callback: function (value, index, ticks) {
                    return value + '%';
                }
            }
        }]
    }
};

// function getRandomArbitrary(min, max) {
//     return Math.random() * (max - min) + min;
//   }

class ActualOEE extends Component {
    state = {
        total: {
            oee: 0,
            a: 0,
            p: 0,
            q: 0,
            total: 0,
            good: 0,
            defect: 0
        },
        lineData: {
            a: [],
            p: [],
            q: [],
            oee: [],
            labels: []
        },
        expected: 0,
        avg: 0,
        pdfRef: React.createRef(),
        speed: 0
    }

    componentDidMount() {
        this.getOEE();
        if (!this.props.displayOverHundred) {
            lineOptions.scales.yAxes[0].ticks.max = 100;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.actual !== this.props.actual) {
            this.getOEE();
        } else if (prevProps.actualOee !== this.props.actualOee && this.props.actualOee.properties?.uuid === this.props.actual.uuid) {
            this.reinit();
        }
    }

    getOEE = () => {
        const { actual, calculateOee } = this.props;
        if (!actual) return;
        const start = new Date(actual.start);
        const end = new Date(actual.end);
        const interval = Math.ceil(((end.getTime() - start.getTime()) / 12) / 60000);
        calculateOee(actual.uuid, null, start.getTime(), end.getTime(), interval, { uuid: actual.uuid }, this.props.isFloating);
    }

    reinit = () => {
        const { actual, actualOee, displayOverHundred } = this.props;
        const total = { oee: 0, a: 0, p: 0, q: 0 };
        let value = { runTime: 0, totalTime: 0, speed: 0, planSpeed: 0, good: 0, total: 0 };
        const lineData = {
            a: [],
            p: [],
            q: [],
            oee: [],
            labels: []
        };

        for (let i = 0; i < actualOee.apq.length; i++) {
            const point = actualOee.apq[i];
            value.runTime += point.runtime;
            value.totalTime += point.total_time;
            value.good += point.good;
            value.total += point.total;

            // const avaPercent = (value.runTime * 100 / value.totalTime);
            const avaPercent = point.a;
            const perPercent = point.p;
            const quaPercent = point.q;
            // const quaPercent = (value.good * 100 / value.total);
            lineData.a.push(isNaN(avaPercent) ? 0 : !displayOverHundred && avaPercent >= 100 ? 100 : avaPercent.toFixed(2));
            lineData.p.push(isNaN(perPercent) ? 0 : !displayOverHundred && perPercent >= 100 ? 100 : perPercent.toFixed(2));
            lineData.q.push(isNaN(quaPercent) ? 0 : !displayOverHundred && quaPercent >= 100 ? 100 : quaPercent.toFixed(2));
            const oeePercent = (avaPercent * perPercent * quaPercent) / 10000;
            lineData.oee.push((isNaN(avaPercent * perPercent * quaPercent) ? 0 : !displayOverHundred && oeePercent >= 100 ? 100 : oeePercent.toFixed(2)));
            lineData.labels.push(moment(point.ts).format('HH:mm'));
        }
        // total.a = (value.runTime * 100 / value.totalTime);
        // total.p = (value.total * actualOee.expected / (new Date(actual.end).getTime() - new Date(actual.start).getTime())) * 100;
        // total.q = (value.good * 100 / value.total);
        // total.oee = (total.a * total.p * total.q) / 10000;

        total.total = actualOee.sum.total;
        total.good = actualOee.sum.good;
        total.defect = actualOee.sum.defect;

        // now get from actualOee.sum
        total.a = actualOee.sum.a;
        total.p = actualOee.sum.p;
        total.q = actualOee.sum.q;
        total.oee = (total.a * total.p * total.q) / 10000;

        const start = new Date(actual.start);
        const end = new Date(actual.end);
        const speed = actual.total / ((end.getTime() - start.getTime()) / 3600000);

        this.setState({ total, lineData, expected: actualOee.expected, avg: actualOee.average, speed });
        // DP ?
        // this.props.displayTotalOee(this.props.actual, actualOee);
    }

    getColor = (value) => {
        if (value >= 70)
            return '#34aa44';
        if (value >= 50)
            return '#facf55';
        if (value >= 30)
            return '#f6ab2f';
        return '#e6492d';
    }

    renderColumns = (columns) => {
        if (!columns) return null;
        let result = [];
        const structure = this.props.planStructures.find(ps => ps.uuid === this.props.actual.plan.structure_uuid);
        for (let key in columns) {
            const foundCol = structure.columns.find(col => col.name === key);
            result.push(
                <div key={'column-' + key} style={{ flex: 1 }}>
                    <span style={{ whiteSpace: 'nowrap' }}>{foundCol ? foundCol.display_name : foundCol}</span>
                    <p className={styles.Detail} style={{ whiteSpace: 'nowrap' }}>{columns[key] ? columns[key] : 'N/A'}</p>
                </div>
            )
        }
        return result;
    }

    export = () => {
        const { actual } = this.props;
        const source = this.state.pdfRef.current;
        source.style.backgroundColor = 'white';
        source.style.color = 'black';
        html2canvas(source, { useCORS: true }).then(canvas => {
            const data = canvas.toDataURL('image/png');
            const pdf = new jsPDF({ orientation: 'landscape' });
            const imgProperties = pdf.getImageProperties(data);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
            pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`${actual.plan.po}_${moment().format('DD-MM-YYYY')}.pdf`);
            source.style.backgroundColor = '';
            source.style.color = '';
        });
    }

    render() {
        const { total, lineData } = this.state;
        const { actual, selectedMetrics, displayOverHundred } = this.props;

        let data = {
            labels: lineData.labels,
            datasets: []
        }

        selectedMetrics.forEach(metric => {
            data.datasets.push({
                label: metric.label,
                borderColor: metric.color,
                backgroundColor: metric.color,
                fill: false,
                data: lineData[metric.id]
            })
        });

        return (
            <div className={styles.OEEResultWrapper}>
                <div className={styles.Button}>
                    <Button color="blue" noMargin click={this.export}>Export</Button>
                </div>
                <div className={styles.OEEResultCard} ref={this.state.pdfRef}>
                    <div className={styles.CardTitle}>
                        <p className={styles.Title}><span className={styles.Topic} style={{ fontSize: 16 }}>PO#</span> {actual.plan ? actual.plan.po : 'N/A'}</p>
                    </div>
                    <div className={styles.CardSummary}>
                        <div className={styles.OEEGauge}>
                            <GaugeChart value={total.oee} min={0} max={100} size={180} color={this.getColor(total.oee)} />
                            <div className={styles.OEELabelWrapper}>
                                <p className={styles.Label}>OEE</p>
                                <p className={styles.Value}>{!displayOverHundred && total.oee >= 100 ? 100 : total.oee % 1 > 0 ? total.oee.toFixed(2) : total.oee}%</p>
                            </div>
                        </div>
                        <div className={styles.APQBar}>
                            <BarChart value={!displayOverHundred && total.a >= 100 ? 100 : total.a} color={this.getColor(total.a)} label="A" showNumber />
                            <BarChart value={!displayOverHundred && total.p >= 100 ? 100 : total.p} color={this.getColor(total.p)} label="P" showNumber />
                            <BarChart value={!displayOverHundred && total.q >= 100 ? 100 : total.q} color={this.getColor(total.q)} label="Q" showNumber />
                        </div>
                        <div className={styles.ActualDetail}>
                            <div className={styles.Row} style={{ borderBottom: '1px solid #2b2b30' }}>
                                <div style={{ flex: 1 }}><span className={styles.Topic}>Machine</span><p className={styles.Detail}>{actual.machine ? actual.machine.name : 'N/A'}</p></div>
                                <div style={{ flex: 1 }}><span className={styles.Topic}>Start</span><p className={styles.Detail}>{moment(actual.start).format('DD/MM/YYYY HH:mm')}</p></div>
                                <div style={{ flex: 1 }}><span className={styles.Topic}>End</span><p className={styles.Detail}>{moment(actual.end).format('DD/MM/YYYY HH:mm')}</p></div>
                            </div>
                            <div className={styles.Row}>
                                <div style={{ flex: 1 }}><span className={styles.Topic}>Target</span><p className={styles.Detail} style={{ color: '#1665d8' }}>{actual.target.toLocaleString()}</p></div>
                                <div style={{ flex: 1 }}><span className={styles.Topic}>Actual</span><p className={styles.Detail} style={{ color: '#facf55' }}>{total.total.toLocaleString()}</p></div>
                                <div style={{ flex: 1 }}><span className={styles.Topic}>Good</span><p className={styles.Detail} style={{ color: '#34aa44' }}>{total.good.toLocaleString()}</p></div>
                                <div style={{ flex: 1 }}><span className={styles.Topic}>Defect</span><p className={styles.Detail} style={{ color: '#e6492d' }}>{total.defect.toLocaleString()}</p></div>
                                <div style={{ flex: 1 }}><span className={styles.Topic}>Speed</span><p className={styles.Detail} style={{ color: '#f6ab2f' }}>{this.state.speed.toFixed(2).toLocaleString()}/hr</p></div>
                            </div>
                            <div className={styles.Row}>
                                {this.renderColumns(actual.columns?.length > 0 ? actual.columns : (actual.plan ? actual.plan.columns : null))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.CardLineChart}>
                        <Line data={data} height={250} options={lineOptions} />
                    </div>
                    <table className={styles.CardStatusBar}>
                        <tbody>
                            <TimelineBar
                                noInterval
                                renderTheExactTimeWidth
                                isEdit={false}
                                timeline={{ id: this.props.index, forOeeReport: true }} // timeline_id should be 0 , but need to re-map .properties.timeline_id === timeline.id
                                start={new Date(actual.start)}
                                end={new Date(actual.end)}
                                machine={actual.machine} />
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { actualOee } = state.advanceActualProduction;
    const { planStructures } = state.advanceProductionPlanStructure;
    return { currentOrg, actualOee, planStructures }
}

export default connect(mapStateToProps, { calculateOee })(ActualOEE);