// import jwt_decode from 'jwt-decode';
// import axios from '../configs/axios-orders';
// import { push } from 'connected-react-router';

// let pending = false;
// let actionPromise;

const jwtMiddleware = store => next => action => {
  return next(action);
  // let token = localStorage.getItem("owlJwtToken");
  // console.log("ACTION>>> ", action)
  // console.log('token', token)
  // if (token) {
  //   const decodedToken = jwt_decode(token);
  //   console.log(new Date(decodedToken.exp * 1000))
  //   if (new Date().getTime() < decodedToken.exp * 1000) {
  //     if (!pending) {
  //       pending = true;
  //       console.log("Refreshing token...")
  //       actionPromise = axios.put('v1/renewtoken', '', { headers: { Authorization: 'Bearer ' + token } });
  //         // .then(res => {
  //         //   pending = false;
  //         //   console.log("Get new token!", res.data.token)
  //         //   localStorage.setItem("owlJwtToken", res.data.token);
  //         //   return next(action);
  //         // }, err => {
  //         //   pending = false;
  //         //   console.log("Refresh is invalid!", err)
  //         //   localStorage.removeItem("owlJwtToken");
  //         //   localStorage.removeItem("owlUser");
  //         //   store.dispatch(push('/auth'));
  //         // });
  //       } else {
  //         actionPromise.then(res => {
  //           pending = false;
  //           console.log("Get new token!", res.data.token)
  //           localStorage.setItem("owlJwtToken", res.data.token);
  //           return next(action);
  //         }, err => {
  //           pending = false;
  //           console.log("Refresh is invalid!", err)
  //           localStorage.removeItem("owlJwtToken");
  //           localStorage.removeItem("owlUser");
  //           store.dispatch(push('/auth'));
  //         });
  //     }
  //   } else {
  //     console.log("out of time...")
  //     localStorage.removeItem("owlJwtToken");
  //     localStorage.removeItem("owlUser");
  //     store.dispatch(push('/auth'));
  //   }
  // } else {
  //   console.log('no token,,')
  //   return next(action);
  // }
}

export default jwtMiddleware;