import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateObject } from '../../../../../state/utils';
import Input from '../../../UI/Input/Input';
import AddIcon from '../../../../../assets/icons/Rule/add-circle.svg';
import DeleteIcon from '../../../../../assets/icons/Rule/delete-circle.svg';
import styles from './PowerForm.module.scss';

const ONE_DAY_IN_SECONDS = 86400 * 1000;

const DEFAULT_POWER_PROPS = {
  ref_time_start: '00:00',
  ref_time_stop: '00:00',
  prices: [{
    start: '00:00',
    stop: '00:00',
    price: 0
  }]
};

class PowerForm extends Component {
  state = {
    powerProps: DEFAULT_POWER_PROPS,
    errorRange: false,
    errorRefTime: false
  };

  componentDidMount() {
    if (this.props.editingPowerProps) {
      this.setState({ powerProps: this.props.editingPowerProps });
    } else {
      this.setState({
        powerProps: DEFAULT_POWER_PROPS
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.editingPowerProps !== this.props.editingPowerProps && this.props.editingPowerProps) {
      this.setState({ powerProps: this.props.editingPowerProps });
    }
  }

  handleAddPowerPrices = event => {
    event.preventDefault();
    let prices = [...this.state.powerProps.prices];
    prices.push({
      start: '00:00',
      stop: prices[prices.length - 1].stop,
      price: 0
    });
    prices[prices.length - 2].stop = '00:00';
    const updatedPowerProps = updateObject(this.state.powerProps, {
      'prices': prices
    });
    this.validateRange(updatedPowerProps);
    this.setState({ powerProps: updatedPowerProps });
  }

  handleRemovePowerPrices = (event, index) => {
    event.preventDefault();
    let prices = [...this.state.powerProps.prices];
    if (index === 0) {
      prices[1].start = prices[0].start;
    } else if (index === prices.length - 1) {
      prices[prices.length - 2].stop = prices[prices.length - 1].stop;
    }
    prices.splice(index, 1);
    const updatedPowerProps = updateObject(this.state.powerProps, {
      'prices': prices
    });
    this.validateRange(updatedPowerProps);
    this.setState({ powerProps: updatedPowerProps });
  }

  handlePowerRefTimeChange = event => {
    const { name, value } = event.target;
    const updatedPowerProps = updateObject(this.state.powerProps, {
      [name]: value
    });
    const time = value.split(":");
    let errorRefTime = true;
    if (name === 'ref_time_start') {
      const refStop = this.state.powerProps.ref_time_stop.split(":");
      errorRefTime = time[0] > refStop[0] || (refStop[0] === time[0] && time[1] > refStop[1]);
      let prices = [...this.state.powerProps.prices];
      prices[0].start = value;
      const updatedPowerProps = updateObject(this.state.powerProps, {
        'prices': prices
      });
      this.validateRange(updatedPowerProps, value, null);
      this.setState({ powerProps: updatedPowerProps });
    } else {
      const refStart = this.state.powerProps.ref_time_start.split(":");
      errorRefTime = refStart[0] > time[0] || (refStart[0] === time[0] && refStart[1] > time[1]);
      let prices = [...this.state.powerProps.prices];
      prices[prices.length - 1].stop = value;
      const updatedPowerProps = updateObject(this.state.powerProps, {
        'prices': prices
      });
      this.validateRange(updatedPowerProps, null, value);
      this.setState({ powerProps: updatedPowerProps });
    }
    let newProps = { ...updatedPowerProps };
    newProps.isValid = !this.state.errorRange && !errorRefTime;
    this.setState({ powerProps: updatedPowerProps, errorRefTime });
    this.props.handlePowerChange(newProps);
  }

  handlePowerPricesChange = (index, type, value) => {
    let prices = this.state.powerProps.prices;
    if (index < 0 || index >= prices.length)
      return;
    if (type === 'start')
      prices[index].start = value;
    else if (type === 'stop')
      prices[index].stop = value;
    else if (type === 'price')
      prices[index].price = +value;
    else
      return;
    const updatedPowerProps = updateObject(this.state.powerProps, {
      'prices': prices
    });
    this.validateRange(updatedPowerProps)
    this.setState({ powerProps: updatedPowerProps });
  }

  validateRange = (powerProps, refStart, refStop) => {
    let ref_start = refStart ? refStart.split(":") : this.state.powerProps.ref_time_start.split(":");
    let ref_stop = refStop ? refStop.split(":") : this.state.powerProps.ref_time_stop.split(":");
    ref_start = new Date().setHours(+ref_start[0], +ref_start[1], 0, 0);
    ref_stop = new Date().setHours(+ref_stop[0], +ref_stop[1], 0, 0) + (this.state.powerProps.ref_time_stop === '00:00' ? ONE_DAY_IN_SECONDS : 0);
    const coverTime = ref_stop - ref_start;

    let sum = 0;
    let errorRange = false;
    powerProps.prices.forEach(price => {
      let start = price.start.split(":");
      let stop = price.stop.split(":");
      start = new Date().setHours(+start[0], +start[1], 0, 0);
      stop = new Date().setHours(+stop[0], +stop[1], 0, 0);
      stop = stop <= start ? stop += 86400000 : stop;
      sum += (stop - start);
    });

    if (sum !== coverTime) {
      errorRange = true;
    } 
    
    this.setState({ errorRange });
    let newProps = { ...powerProps };
    newProps.isValid = !errorRange && !this.state.errorRefTime;
    this.props.handlePowerChange(newProps);
  }

  render() {
    const { ref_time_start, ref_time_stop, prices } = this.state.powerProps;

    let power_prices = null;
    power_prices = prices.map((price, i) => (
      <div key={"power_price_row_" + i} style={{ display: 'flex', marginTop: 15 }}>
        <Input
          overidestyleformgroup={{ flex: 1, marginRight: 10, marginBottom: 0 }}
          label="Start"
          name={"price_start_" + i}
          value={price.start}
          type="time"
          disabled={i === 0}
          onChange={(event) => { this.handlePowerPricesChange(i, 'start', event.target.value) }} />
        <Input
          overidestyleformgroup={{ flex: 1, marginRight: 10, marginBottom: 0 }}
          label="Stop"
          name={"price_stop_" + i}
          value={price.stop}
          type="time"
          disabled={i === prices.length - 1}
          onChange={(event) => { this.handlePowerPricesChange(i, 'stop', event.target.value) }}
        />
        <Input
          overidestyleformgroup={{ flex: 1, marginBottom: 0 }}
          label="Price"
          name={"price_" + i}
          value={price.price}
          type="number"
          onChange={(event) => { this.handlePowerPricesChange(i, 'price', event.target.value) }}
        />
        {
          prices.length > 1
            ? <div className={styles.IconWrapper}>
              <img className={styles.IconButton} src={DeleteIcon} alt="Delete Command Icon" onClick={(event) => this.handleRemovePowerPrices(event, i)} />
            </div>
            : null
        }
      </div>
    ));

    return (
      <React.Fragment>
        <div style={{ display: 'flex', marginTop: 15 }}>
          <Input label="Reference Time start -" name="ref_time_start" value={ref_time_start} type="time" onChange={this.handlePowerRefTimeChange} overidestyleformgroup={{ flex: 1, marginBottom: 0 }} />
          <Input label="end" name="ref_time_stop" value={ref_time_stop} type="time" onChange={this.handlePowerRefTimeChange} overidestyleformgroup={{ flex: 1, marginBottom: 0 }} />
        </div>
        {this.state.errorRefTime ? <div className={styles.ErrorMessage}>Start time must be before End time</div> : null}
        {power_prices}
        {this.state.errorRange ? <div className={styles.ErrorMessage}>Price range must exactly cover reference time (start-stop)</div> : null}
        <div className={styles.AddPowerPriceButton} onClick={this.handleAddPowerPrices}>
          <img src={AddIcon} alt="Add icon for price" className={styles.AddIcon} />
          <div className={styles.ButtonName}>Add price</div>
        </div>
      </React.Fragment>
    );
  }
}

// const mapStateToProps = (state) => {
//   const { loading, updatedWidget } = state.widgets;
//   return { loading, updatedWidget };
// };

export default connect(null, {})(PowerForm);