import PageIcon1 from '../../assets/images/Widget/bitmap1.svg';
import PageIcon2 from '../../assets/images/Widget/bitmap2.svg';
import PageIcon3 from '../../assets/images/Widget/bitmap3.svg';
import PageIcon4 from '../../assets/images/Widget/bitmap4@2x.jpg';

import BatteryIcon from '../../assets/icons/Device/battery.svg';
import CameraIcon from '../../assets/icons/Device/camera.svg';
import Co2Icon from '../../assets/icons/Device/co2.svg';
import CountingIcon from '../../assets/icons/Device/counting.svg';
import ElectricityIcon from '../../assets/icons/Device/electricity.svg';
import FanIcon from '../../assets/icons/Device/fan.svg';
import FingerprintIcon from '../../assets/icons/Device/fingerprint.svg';
import FlameIcon from '../../assets/icons/Device/flame.svg';
import IceIcon from '../../assets/icons/Device/ice.svg';
import LightIcon from '../../assets/icons/Device/lights.svg';
import MapIcon from '../../assets/icons/Device/map.svg';
import N2Icon from '../../assets/icons/Device/n2.svg';
import O2Icon from '../../assets/icons/Device/o2.svg';
import OilIcon from '../../assets/icons/Device/oil.svg';
import PlugIcon from '../../assets/icons/Device/plug.svg';
import Pm25Icon from '../../assets/icons/Device/pm25.svg';
import PressureIcon from '../../assets/icons/Device/pressure.svg';
import TemperatureIcon from '../../assets/icons/Device/temperature.svg';
import WaterIcon from '../../assets/icons/Device/water.svg';
import InputIcon from '../../assets/icons/Device/input.svg';
import OutputIcon from '../../assets/icons/Device/output.svg';

const FILE_LIST = {
	// Page
	'bitmap1': PageIcon1, 'bitmap2': PageIcon2, 'bitmap3': PageIcon3, 'bitmap4@2x': PageIcon4,
	// Device
	battery: BatteryIcon, camera: CameraIcon, co2: Co2Icon, counting: CountingIcon, electricity: ElectricityIcon, fan: FanIcon,
	fingerprint: FingerprintIcon, flame: FlameIcon, ice: IceIcon, lights: LightIcon, map: MapIcon, n2: N2Icon, o2: O2Icon,
	oil: OilIcon, plug: PlugIcon, pm25: Pm25Icon, pressure: PressureIcon, temperature: TemperatureIcon, water: WaterIcon, input: InputIcon, output: OutputIcon,
};

// For HASH CHANGING PROB.
export const handleImagePath = (path) => {
	if (path.includes('/static/media/')) {
		const fileName = path.split('/static/media/')[1].split('.')[0];
		return FILE_LIST[fileName];
	} else {
		return path;
	}
}