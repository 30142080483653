import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  powers: null,
  powersJSON: null,
  requestCount: 0,
  responseCount: 0,
  awaitingRequests: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_POWERS_START: return getPowersStart(state, action);
    case actionTypes.GET_POWERS_SUCCESS: return getPowersSuccess(state, action);
    case actionTypes.GET_POWERS_FAIL: return getPowersFail(state, action);

    case actionTypes.GET_POWERS_JSON_START: return getPowersJsonStart(state, action);
    case actionTypes.GET_POWERS_JSON_SUCCESS: return getPowersJsonSuccess(state, action);
    case actionTypes.GET_POWERS_JSON_FAIL: return getPowersJsonFail(state, action);

    case actionTypes.SAVE_REQUEST: return saveRequest(state, action);
    case actionTypes.CLEAR_REQ_RES_COUNT: return clearRequestResponseCount(state, action);

    default: return state;
  }
};

const saveRequest = (state, action) => {
  const awaitingRequests = [...state.awaitingRequests];
  awaitingRequests.push({
    req: action.request,
    props: action.properties
  });
  return updateObject(state, { awaitingRequests });
}

const clearRequestResponseCount = (state, action) => {
  return updateObject(state, { awaitingRequests: [], requestCount: 0, responseCount: 0 });
}

const getPowersStart = (state, action) => {
  let requestCount = state.requestCount;
  if (requestCount <= state.awaitingRequests.length + 20) {
    // console.log('state.requestCount++', requestCount, '<=', state.awaitingRequests.length + 20)
    requestCount = state.requestCount + 1;
  }
  return updateObject(state, { result: null, error: null, loading: true, requestCount });
}

const getPowersSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    powers: action.powers,
    error: null,
    loading: false,
    result: action.result,
    responseCount: state.responseCount + 1
  });
}

const getPowersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    result: null
  });
}


const getPowersJsonStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, requestCount: state.requestCount + 1 });
}

const getPowersJsonSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    powersJSON: action.powers,
    error: null,
    loading: false,
    result: action.result,
    responseCount: state.responseCount + 1
  });
}

const getPowersJsonFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    result: null
  });
}

export default reducer;