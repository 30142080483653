import React from 'react';
import styles from '../SystemDeviceConfig.module.scss';
import Input from '../../../UI/Input/Input';

export default class InformationFields extends React.Component {

    render() {
        const { deviceForm, handleDeviceFormTextChange } = this.props;
        return (
            <>
                <div className={styles.Label}>Device's information</div>
                <Input
                    label="name of the device"
                    name="name"
                    type="text"
                    value={deviceForm.name.value}
                    placeholder="Please name your device"
                    autoComplete="off"
                    onChange={e => handleDeviceFormTextChange(e)}
                    error={`${deviceForm.name.touched && !deviceForm.name.valid ? 'Name is required.' : ''}`}
                    required
                />
                <Input
                    label="description"
                    name="description"
                    type="textarea"
                    value={deviceForm.description.value}
                    placeholder="What is this device about? Tell people more juicy details."
                    rows="3"
                    onChange={e => handleDeviceFormTextChange(e)}
                />
                <Input
                    label="tag"
                    name="tag"
                    type="text"
                    value={deviceForm.tag.value}
                    placeholder="Tag1,Tag2,..."
                    autoComplete="off"
                    onChange={e => handleDeviceFormTextChange(e)}
                />
            </>
        );
    }
}