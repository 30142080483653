import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import styles from '../StartOperatorDrawer/OperatorDrawer.module.scss';
import { updateObject } from '../../../../state/utils';
import Button from '../../UI/Button/Button';
import AddIcon from '../../../../assets/icons/plus-circle-blue.svg';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import DeleteIcon from '../../../../assets/icons/delete-red-bin.svg';
import PrintIcon from '../../../../assets/icons/Operator/printing-black.png';
import Input from '../../UI/Input/Input';
import SearchPoInput from '../../UI/SearchPoInput/SearchPoInput';
import DeleteModal from '../../shared/DeleteModal/DeleteModal';

import { getDataEval } from '../../../../state/ducks/Data/actions';
import { calculateOee } from '../../../../state/ducks/AdvanceActualProduction/actions';
import { searchAdvanceProductionPlans } from '../../../../state/ducks/AdvanceProductionPlan/actions';
import { getDocumentNotes } from '../../../../state/ducks/Document/actions';
import { DELETE_DOCUMENT_NOTE_SUCCESS } from '../../../../state/ducks/Document/types';
import ReactToPrint from "react-to-print";
import PrintoutPO from './PrintoutPO';

const DEFAULT_OPERATOR_FORM = {
	po: {
		value: '',
		valid: false,
		touched: false,
		validation: {
			required: true
		}
	},
	machine: {
		value: '',
		valid: false,
		touched: false,
		validation: {
			required: true
		}
	},
	total: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: false
		}
	},
	ok: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: false
		}
	},
	defect: {
		value: '',
		valid: true,
		touched: true,
		validation: {
			required: false
		}
	}
};

class StopOperatorDrawer extends Component {
	state = {
		formIsValid: true,
		autoCheckingPoLoading: false,
		isPoSelected: false,
		operatorForm: DEFAULT_OPERATOR_FORM,
		isSearchResultsOpened: false,
		searchResults: [],
		updatedCustomColumns: {}
	}

	componentDidMount() {
		if (this.props.newForm) {
			this.setupNewForm();
		} else if (this.props.selectedActual) {
			this.setFormBySelectedActual(this.props.selectedActual);
			this.setState({ isPoSelected: false, searchResults: [] });

			this.callOeeThings();
		}
		if (this.props.noteTypes && this.props.noteTypes.length) {
			const foundType = this.props.noteTypes.find(nt => nt.name.toLowerCase() === 'production');
			this.props.getDocumentNotes(this.props.currentOrg, foundType.id);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.newForm !== this.props.newForm && this.props.newForm) {
			this.setupNewForm();
		} else if (prevProps.selectedActual !== this.props.selectedActual && this.props.selectedActual) {
			this.setFormBySelectedActual(this.props.selectedActual);
			this.setState({ isPoSelected: false, searchResults: [] });
		} else if (this.props.dataEval && prevProps.dataEval !== this.props.dataEval) {
			const { data, properties: { name } } = this.props.dataEval;
			this.handleChange({ target: { name, value: data } });
		} else if (this.props.resultSearchPlans !== prevProps.resultSearchPlans && this.props.resultSearchPlans) {
			clearTimeout(this.autoCheckPo);
			if (!this.props.resultSearchPlans.length) {
				this.setPoInValid(false);
			} else {
				this.setState({ isSearchResultsOpened: true });
			}
			if (this.props.scannerText) {
				if (this.props.resultSearchPlans.length) {
					this.clearOthersExceptPo(this.props.scannerText, this.props.resultSearchPlans[0]);
					this.setState({ isSearchResultsOpened: false, isPoSelected: true });
				}
			} else {
				this.setState({ isSearchResultsOpened: true });
			}
			this.setState({ autoCheckingPoLoading: false, searchResults: this.props.resultSearchPlans });
		} else if (this.props.scannerText !== prevProps.scannerText && this.props.scannerText) {
			this.handleChange({ target: { name: 'po', value: this.props.scannerText } }, true);
		} else if (prevProps.documentResult !== this.props.documentResult && this.props.documentResult === 'success' && this.props.mainTab === 0) {
			if (this.props.updatedDocumentNote !== prevProps.updatedDocumentNote || this.props.addedDocumentNote !== prevProps.addedDocumentNote || this.props.type === DELETE_DOCUMENT_NOTE_SUCCESS) {
				const foundType = this.props.noteTypes.find(nt => nt.name.toLowerCase() === 'production');
				this.props.getDocumentNotes(this.props.currentOrg, foundType.id);
			}
		}
		if (this.props.drawerOpened !== prevProps.drawerOpened && this.props.drawerOpened) {
			this.callOeeThings();
		} else if (this.props.actualOee && prevProps.actualOee !== this.props.actualOee) {
			const { sum } = this.props.actualOee;
			const updatedTotal = updateObject(this.state.operatorForm['total'], {
				value: sum.total,
				valid: true,
				touched: true
			});
			const updatedGood = updateObject(this.state.operatorForm['ok'], {
				value: sum.good,
				valid: true,
				touched: true
			});
			const updatedDefect = updateObject(this.state.operatorForm['defect'], {
				value: sum.defect,
				valid: true,
				touched: true
			});
			const updatedForm = updateObject(this.state.operatorForm, {
				total: updatedTotal,
				ok: updatedGood,
				defect: updatedDefect
			});
	
			let formIsValid = true;
			for (let inputIdentifier in updatedForm) {
				formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
			}
			this.setState({ operatorForm: updatedForm, formIsValid });
		}
	}

	callOeeThings = () => {
		let isFloating = false;
		if (this.props.moduleConfigs) {
			isFloating = this.props.moduleConfigs.find(mc => mc.key === 'isFloating');
			isFloating = isFloating && isFloating.value === 'true' ? true : false;
		}
		if (this.props.selectedActual) {
			const { uuid, start } = this.props.selectedActual;
			this.props.calculateOee(uuid, null, new Date(start).getTime(), new Date().getTime(), 60, { actualUuid: uuid }, isFloating);
		}
	}

	setFormBySelectedActual = (actual) => {
		const updatedForm = { ...DEFAULT_OPERATOR_FORM };
		updatedForm.po.value = this.props.selectedPlan.po;
		updatedForm.po.valid = true;
		updatedForm.po.touched = true;

		updatedForm.machine.value = actual.machine;
		updatedForm.machine.valid = true;
		updatedForm.machine.touched = true;

		if (actual.machine.total_device_secret !== "") updatedForm.total.value = "";
		if (actual.machine.good_device_secret !== "") updatedForm.ok.value = "";
		if (actual.machine.defect_device_secret !== "") updatedForm.defect.value = "";

		this.setState({ operatorForm: updatedForm, formIsValid: true });
		// this.getThingsValue(actual);
	}

	setPoInValid = () => {
		const updatedForm = { ...this.state.operatorForm };
		updatedForm.po.valid = false;
		this.setState({ operatorForm: updatedForm, formIsValid: false, isPoSelected: false });
	}

	setupNewForm = () => {
		const operatorForm = { ...DEFAULT_OPERATOR_FORM };
		for (let inputIdentifier in operatorForm) {
			if (inputIdentifier === 'po' || inputIdentifier === 'machine') {
				operatorForm[inputIdentifier].value = '';
				operatorForm[inputIdentifier].valid = false;
				operatorForm[inputIdentifier].touched = false;
			} else {
				operatorForm[inputIdentifier].value = '';
				operatorForm[inputIdentifier].valid = true;
				operatorForm[inputIdentifier].touched = true;
			}
		}
		this.setState({ operatorForm, formIsValid: false });
	}

	getThingsValue = (actual) => {
		// DP : 05/08/22
		const { total_device_secret, total_device_socket, good_device_secret, good_device_socket, defect_device_secret, defect_device_socket } = actual.machine;
		let methodTotal = 'sum';
		let methodGood = 'sum';
		let methodDefect = 'sum';
		if (actual.machine.is_total_device_accumulated) methodTotal = 'inc';
		if (actual.machine.is_good_device_accumulated) methodGood = 'inc';
		if (actual.machine.is_defect_device_accumulated) methodDefect = 'inc';
		const totalsFromStart = '{ra:' + methodTotal + '(' + total_device_secret + '@' + total_device_socket + '@' + moment(actual.start).valueOf() + '-now())}';
		const goodsFromStart = '{ra:' + methodGood + '(' + good_device_secret + '@' + good_device_socket + '@' + moment(actual.start).valueOf() + '-now())}';
		const defectsFromStart = '{ra:' + methodDefect + '(' + defect_device_secret + '@' + defect_device_socket + '@' + moment(actual.start).valueOf() + '-now())}';

		if (total_device_secret !== "") this.props.getDataEval(totalsFromStart, { name: 'total' });
		if (good_device_secret !== "") this.props.getDataEval(goodsFromStart, { name: 'ok' });
		if (defect_device_secret !== "") this.props.getDataEval(defectsFromStart, { name: 'defect' });
	}

	onConfirmStopHandler = (event) => {
		event.preventDefault();
		this.props.confirmStop(this.state.operatorForm, this.state.updatedCustomColumns);
	}

	handleChange = (e, fromScan) => {
		const { name, value } = e.target;
		if (name === 'po') {
			clearTimeout(this.autoCheckPo);
			this.clearOthersExceptPo(value);
			if (!fromScan) this.props.clearScannerText();
			this.setState({ isPoSelected: false, formIsValid: false });
			this.props.setSelectingActual(null);
			if (value !== '') {
				this.setState({ autoCheckingPoLoading: true });
				this.autoCheckPo = setTimeout(() => {
					this.props.searchAdvanceProductionPlans(this.props.currentOrg, value);
				}, 1000);
			} else {
				this.setState({ searchResults: [], autoCheckingPoLoading: false, isSearchResultsOpened: false });
			}
		} else {
			const updatedElement = updateObject(this.state.operatorForm[name], {
				value,
				valid: true,
				touched: true
			});

			const updatedForm = updateObject(this.state.operatorForm, {
				[name]: updatedElement
			});

			let formIsValid = true;
			for (let inputIdentifier in updatedForm) {
				formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
			}
			this.setState({ operatorForm: updatedForm, formIsValid });
		}
	}

	clearOthersExceptPo = (newPoValue) => {
		const operatorForm = { ...DEFAULT_OPERATOR_FORM };
		operatorForm.po = {
			value: newPoValue,
			valid: newPoValue === '' ? false : true,
			touched: true,
			validation: { required: true }
		};
		let formIsValid = true;
		for (let inputIdentifier in operatorForm) {
			formIsValid = operatorForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({ operatorForm, formIsValid });
	}

	onChangeMachineHandler = (index) => {
		const foundPlan = this.state.searchResults.find(rsp => rsp.po.toLowerCase() === this.state.operatorForm.po.value.toLowerCase());
		// this.getThingsValue(foundPlan.actuals[index]);
		const updatedElement = updateObject(this.state.operatorForm.machine, {
			value: foundPlan.actuals[index].machine,
			valid: true,
			touched: true
		});

		const updatedForm = updateObject(this.state.operatorForm, {
			machine: updatedElement
		});

		let formIsValid = true;
		for (let inputIdentifier in updatedForm) {
			formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
		}
		this.props.setSelectingActual(foundPlan.actuals[index]);
		this.setState({ operatorForm: updatedForm, formIsValid });
	}

	isEndYet = (actual, checkAllActual) => {
		if (checkAllActual) {
			return !actual.find(ac => ac.end === '0001-01-01T00:00:00Z');
		}
		return actual !== '0001-01-01T00:00:00Z';
	}

	handleCustomChange = (e) => {
		const { name, value } = e.target;
		const updatedCustomColumns = { ...this.state.updatedCustomColumns };
		updatedCustomColumns[name] = value;
		this.setState({ updatedCustomColumns });
	}

	render() {
		const { po, machine, total, ok, defect } = this.state.operatorForm;

		return (
			<div>
				<div style={{ position: 'relative' }}>
					<ReactToPrint
						trigger={() => <div className={styles.PrintButtonWrapper}><img src={PrintIcon} style={{ width: 20, height: 20 }} /><a href="#">Print</a></div>}
						content={() => this.componentRef}
					/>
					<div style={{ display: 'none' }}>
						<PrintoutPO
							ref={el => (this.componentRef = el)}
							customCols={this.props.selectedPlan ? this.props.selectedPlan.columns : null}
							updatedCustomCols={this.state.updatedCustomColumns}
							po={this.props.selectedPlan ? po.value : 'n/a'}
							machine={machine.value}
							total={total.value}
							ok={ok.value}
							defect={defect.value}
							org={this.props.orgs && this.props.currentOrg ? this.props.orgs.find(o => o.id === this.props.currentOrg).name : 'n/a'}
							actual={this.props.selectedActual}
							loggedInUser={this.props.loggedInUser}
						/>
					</div>
				</div>
				<form onSubmit={this.onConfirmStopHandler}>
					{
						this.props.selectedPlan
							? <Input
								label="po"
								name="po"
								type="text"
								value={po.value ? po.value : ''}
								onChange={e => this.handleChange(e.name, e.value)}
								required
								disabled
							/>
							:
							<SearchPoInput
								poValue={po}
								handleChange={this.handleChange}
								isPoSelected={this.state.isPoSelected}
								autoCheckingPoLoading={this.state.autoCheckingPoLoading}
								setIsPoSelected={(e) => this.setState({ isPoSelected: e })}
								isSearchResultsOpened={this.state.isSearchResultsOpened}
								setIsSearchResultsOpened={(e) => this.setState({ isSearchResultsOpened: e })}
								clearOthersExceptPo={this.clearOthersExceptPo}
								searchResults={this.state.searchResults}
								toggleScannerHandler={this.props.toggleScannerHandler}
							/>
					}

					{
						this.props.selectedPlan
							? <Input
								label="machine"
								name="machine"
								type="text"
								value={machine.value ? machine.value.name : ''}
								required
								disabled
							/>
							: this.state.isPoSelected && this.state.searchResults && this.state.searchResults.length
								? <div className={styles.FormGroup}>
									<label className={styles.Title}>Machine *</label>
									{
										!this.isEndYet(this.state.searchResults.find(sr => sr.po.toLowerCase() === po.value.toLowerCase()).actuals, true)
											? <div className={styles.MachineBoxWrapper}>
												{
													this.state.searchResults.find(sr => sr.po.toLowerCase() === po.value.toLowerCase()).actuals.map((actual, index) =>
														!this.isEndYet(actual.end) &&
														<div key={`MachineBox-${actual.machine.id}`} onClick={() => this.onChangeMachineHandler(index)}
															className={`${styles.MachineBox} ${machine.value && actual.machine.id === machine.value.id ? styles.Selected : ''}`}>
															{actual.machine.name}
														</div>
													)
												}
											</div>
											: <div style={{ fontSize: 14, fontWeight: 500 }}>No machines running!</div>
									}

								</div>
								: null
					}
					<Input
						label="total"
						name="total"
						type="number"
						value={+total.value < 0 ? 0 : total.value}
						onChange={this.handleChange}
						overidestyleformgroup={{ position: 'initial' }}
					/>
					<Input
						label="ok"
						name="ok"
						type="number"
						value={+ok.value < 0 ? 0 : ok.value}
						onChange={this.handleChange}
						overidestyleformgroup={{ position: 'initial' }}
					/>
					<Input
						label="defect"
						name="defect"
						type="number"
						value={+defect.value < 0 ? 0 : defect.value}
						onChange={this.handleChange}
						overidestyleformgroup={{ position: 'initial' }}
					/>

					{
						this.props.selectedPlan && this.props.selectedPlan.columns && Object.keys(this.props.selectedPlan.columns).length > 0
							? Object.keys(this.props.selectedPlan.columns).map((key, i) => (
								<React.Fragment key={`${key}_${i}`}>
									<Input
										label={key}
										name={key}
										type="text"
										value={this.state.updatedCustomColumns[key] ? this.state.updatedCustomColumns[key] : this.props.selectedPlan.columns[key]}
										onChange={this.handleCustomChange}
										overidestyleformgroup={{ position: 'initial' }}
									/>
								</React.Fragment>
							))
							: null
					}

					<div className={styles.FormGroup}>
						<label className={styles.Title}>Attached Documents</label>
						{
							this.props.selectedActual && this.props.documentNotes && this.props.documentNotes.length && this.props.documentNotes.find(n => n.ref === this.props.selectedActual.uuid)
								? this.props.documentNotes.map(dn => this.props.selectedActual.uuid === dn.ref && (
									<div key={dn.uuid} className={styles.DocumentWrapper}>
										<div className={styles.Title}>{dn.title}</div>
										<img src={EditIcon} alt="Edit" className={styles.Icon} onClick={() => this.props.editProdDoc(dn)} />
										<img src={DeleteIcon} alt="Delete" className={styles.Icon} onClick={() => this.props.deleteProdDoc(dn)} />
									</div>
								))
								: <div style={{ fontSize: 12, fontWeight: '500' }}>None</div>
						}

					</div>

					{
						this.props.selectedPlan || this.state.isPoSelected ?
							<div className={styles.AddProdBtn} onClick={this.props.addProductionNote}>
								<img src={AddIcon} alt="Add icon for modbus" className={styles.AddIcon} />
								<div className={styles.ButtonName}>Add Production Note</div>
							</div>
							: null
					}
					<div style={{ height: 70, width: 'auto' }} />
					<Button
						type="submit"
						name={this.props.loading ? 'Loading...' : 'Stop PO'}
						color="red"
						disabled={!this.state.formIsValid}
						loading={this.props.loading}
					/>
					<Button type="button" name="Cancel" color="borderred" click={this.props.closed} />
				</form>

			</div >
		);
	}
}

const mapStateToProps = (state) => {
	const { loggedInUser } = state.auth;
	const { orgs, currentOrg } = state.org;
	const { dataEval } = state.data;
	const { resultSearchPlans } = state.advanceProductionPlan;
	const { documentNotes, addedDocumentNote, updatedDocumentNote, noteTypes, type, result } = state.document;
	const { actualOee } = state.advanceActualProduction;
	const { moduleConfigs } = state.module;

	return {
		loggedInUser, orgs, currentOrg, dataEval, resultSearchPlans,
		documentNotes, addedDocumentNote, updatedDocumentNote, documentResult: state.document.result, noteTypes, type, result,
		actualOee, moduleConfigs
	};
};

export default connect(mapStateToProps, { getDataEval, searchAdvanceProductionPlans, getDocumentNotes, calculateOee })(StopOperatorDrawer);