import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_RULE_CONDITIONS, ADD_RULE_CONDITION, EDIT_RULE_CONDITION, DELETE_RULE_CONDITION } from './types';

export function* watchRuleConditions() {
  yield takeEvery(GET_RULE_CONDITIONS, getRuleConditionsSaga);
  yield takeEvery(ADD_RULE_CONDITION, addRuleConditionSaga);
  yield takeEvery(EDIT_RULE_CONDITION, editRuleConditionSaga);
  yield takeEvery(DELETE_RULE_CONDITION, deleteRuleConditionSaga);
}

export function* getRuleConditionsSaga(params) {
  yield put(actions.getRuleConditionsStart());

  let api = 'v1/rulecondition';

  api += '?rule_id=' + params.rule_id;
  try {
    const response = yield axios.get(api);
    console.log("GET RULE_CONDITION SUCCESS", response.data);
    yield put(actions.getRuleConditionsSuccess(response.data));
  } catch (err) {
    console.log("GET RULE_CONDITION FAILED", err)
    yield put(actions.getRuleConditionsFail(err.response));
  }
}

export function* addRuleConditionSaga(params) {
  yield put(actions.addRuleConditionStart());
  const api = 'v1/rulecondition';

  let postData = "rule_id=" + +params.rule_id;
  postData += params.operation ? "&operation=" + params.operation : '';
  postData += "&condition=" + encodeURIComponent(params.condition);
  postData += "&device_secret=" + encodeURIComponent(params.device_secret);
  postData += "&device_socket=" + +params.device_socket;
  postData += "&value=" + parseFloat(params.value);

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD RULE_CONDITION SUCCESS", response);
    yield put(actions.addRuleConditionSuccess(response.data));
  } catch (err) {
    console.log("ADD RULE_CONDITION FAILED", err.response.data);
    yield put(actions.addRuleConditionFail(err.response.data.error));
  }
}

export function* editRuleConditionSaga(params) {
  yield put(actions.editRuleConditionStart());

  let putData = "value=" + parseFloat(params.value);
  putData += params.operation ? "&operation=" + params.operation: '';
  putData += params.condition ? "&condition=" + encodeURIComponent(params.condition): '';
  putData += params.device_secret ? "&device_secret=" + encodeURIComponent(params.device_secret) : '';
  putData += params.device_socket !== '' ? "&device_socket=" + +params.device_socket : '';

  const api = 'v1/rulecondition/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT RULE_CONDITION SUCCESS", response.data);
    yield put(actions.editRuleConditionSuccess(response.data));
  } catch (err) {
    console.log("EDIT RULE_CONDITION FAILED", err.response.data);
    yield put(actions.editRuleConditionFail(err.response.data));
  }
}

export function* deleteRuleConditionSaga(params) {
  yield put(actions.deleteRuleConditionStart());

  const api = 'v1/rulecondition/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE RULE_CONDITION SUCCESS", response.data);
    yield put(actions.deleteRuleConditionSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE RULE_CONDITION FAILED", err.response);
    yield put(actions.deleteRuleConditionFail(err.response));
  }
}