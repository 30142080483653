import React from 'react';

import './Spinner.scss';

const spinner = (props) => (
  <div className={`spinner ${props.small ? 'small': ''}`}>
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </div>
);

export default spinner;
