import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Modal from '../../components/UI/Modal/Modal';
import styles from './Rule.module.scss';
import ArrowIcon from '../../../assets/icons/arrow-up.svg';
import { getAlarmHistories } from '../../../state/ducks/Rule/actions';

class AlarmHistoriesModal extends Component {
	state = {
		openCondition: true
	};

	componentDidUpdate(prevProps, prevState) {
		if ((!prevProps.selectedRule && this.props.selectedRule) || prevProps.selectedRule.id !== this.props.selectedRule.id) {
			this.props.getAlarmHistories(this.props.selectedRule.id);
			this.setState({ openCondition: true });
		}
	}

	closeConditionMessageHandler = () => {
		this.setState(prevState => {
			return { openCondition: !prevState.openCondition };
		});
	}

	render() {
		const { alarmHistories } = this.props;

		let arrowClasses = [styles.ArrowIcon];
		let messageBoxWrapperClasses = [styles.MessageBoxWrapper];
		if (!this.state.openCondition) {
			arrowClasses.push(styles.ToggleUp);
			messageBoxWrapperClasses.push(styles.Close);
		}

		return (
			<Modal show={this.props.open} modalClosed={this.props.modalClosed} overideStyles={{ maxHeight: '60%', overflow: 'auto', width: 720 }}>
				<div className={styles.ModalHeaderWrapper}>
					<div className={styles.ModalHeader}><span style={{ color: '#6b6c6f' }}>Histories of</span> {this.props.selectedRule ? this.props.selectedRule.name : ''}</div>
				</div>
				<div className={styles.MessageWrapper}>
					<div className={styles.MessageTitle} onClick={() => this.closeConditionMessageHandler()}>
						<div style={{ flex: 1 }}>Condition Message</div>
						<img className={arrowClasses.join(' ')} src={ArrowIcon} alt="Open/Close Condition" />
					</div>
					<div className={messageBoxWrapperClasses.join(' ')}>
						<div className={`${styles.MessageBox} ${styles.TrueBox}`}>
							<div className={styles.TrueTitle}>True</div>
							<div className={styles.ConditionText}>{this.props.selectedRule ? this.props.selectedRule.true_message : ''}</div>
						</div>
						<div className={`${styles.MessageBox} ${styles.FalseBox}`}>
							<div className={styles.FalseTitle}>False</div>
							<div className={styles.ConditionText}>{this.props.selectedRule ? this.props.selectedRule.false_message : ''}</div>
						</div>
					</div>
				</div>
				<div style={{ margin: '24px 30px 24px 30px' }}>
					{
						this.props.historiesLoading
							? <><div className={styles.LoadingCardWhite}></div><div className={styles.LoadingCardWhite}></div></>
							: alarmHistories && alarmHistories.length
								? <table className={styles.HistoriesWrapper}>
									<thead>
										<tr>
											<th className={styles.HistoriesHeader}>No.</th>
											<th className={styles.HistoriesHeader}>Date</th>
											<th className={styles.HistoriesHeader}>Condition</th>
											<th className={styles.HistoriesHeader}>Emails</th>
											<th className={styles.HistoriesHeader}>Mobile No.</th>
										</tr>
									</thead>
									<tbody>
										{
											alarmHistories.map((ah, i) => (
												<tr key={`histories-${ah.id}`} style={{ height: 40, borderBottom: '1px dashed #9ea0a5' }}>
													<td className={styles.HistoriesInfo}>{alarmHistories.length - i}.</td>
													<td className={styles.HistoriesInfo}>{moment(ah.timestamp).format("DD/MM/YYYY HH:mm")}</td>
													<td className={styles.HistoriesInfo}>{ah.result ? <div style={{ color: '#34aa44' }}>True</div> : <div style={{ color: '#e6492d' }}>False</div>}</td>
													<td className={styles.HistoriesInfo}>{ah.emails ? ah.emails.map((email, ei) => <div key={`rule-${alarmHistories.length - i}-emails${ei}`}>{email}</div>) : '-'}</td>
													<td className={styles.HistoriesInfo}>{ah.mobile_numbers ? ah.mobile_numbers.map((mb, mi) => <div key={`rule-${alarmHistories.length - i}-mobile_numbers${mi}`}>{mb}</div>) : '-'}</td>
												</tr>
											))
										}
									</tbody>
								</table>
								: <div className={styles.NotFoundText}>Histories not found</div>
					}
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	const { alarmHistories, historiesLoading } = state.rule;
	return { alarmHistories, historiesLoading };
};

export default connect(mapStateToProps, { getAlarmHistories })(AlarmHistoriesModal);