import * as actionTypes from './types';

// ----------------------- GET MEMBERS -----------------------
export const getMembersStart = () => {
  return {
    type: actionTypes.GET_MEMBERS_START
  };
}

export const getMembers = (org_id, first_name, last_name, email, username, verified, team_id) => {
  return {
    type: actionTypes.GET_MEMBERS,
    org_id, first_name, last_name, email, username, verified, team_id
  };
}

export const getMembersSuccess = (response) => {
  return {
    type: actionTypes.GET_MEMBERS_SUCCESS,
    members: response.users
  };
}

export const getMembersFail = (error) => {
  return {
    type: actionTypes.GET_MEMBERS_FAIL,
    error: error
  };
}

// ----------------------- GET SINGLE MEMBER -----------------------
export const getMemberStart = () => {
  return {
    type: actionTypes.GET_MEMBER_START
  };
}

export const getMember = (id) => {
  return {
    type: actionTypes.GET_MEMBER,
    id
  };
}

export const getMemberSuccess = (response) => {
  return {
    type: actionTypes.GET_MEMBER_SUCCESS,
    member: response.user
  };
}

export const getMemberFail = (error) => {
  return {
    type: actionTypes.GET_MEMBER_FAIL,
    error: error
  };
}

// ----------------------- ADD MEMBER -----------------------
export const addMemberStart = () => {
  return {
    type: actionTypes.ADD_MEMBER_START
  };
}

export const addMember = (username, password, first_name, last_name, email, job_title, is_system_admin, org_id, team_id, verified, profile_pic) => {
  return {
    type: actionTypes.ADD_MEMBER,
    username, password, first_name, last_name, email, job_title, is_system_admin, org_id, team_id, verified, profile_pic
  };
}

export const addMemberSuccess = (response) => {
  return {
    type: actionTypes.ADD_MEMBER_SUCCESS,
    addedMember: response.user,
    result: response.result
  };
}

export const addMemberFail = (error) => {
  return {
    type: actionTypes.ADD_MEMBER_FAIL,
    error: error
  };
}

// ----------------------- EDIT MEMBER -----------------------
export const editMemberStart = () => {
  return {
    type: actionTypes.EDIT_MEMBER_START
  };
}

export const editMember = (id, first_name, last_name, password, job_title) => {
  return {
    type: actionTypes.EDIT_MEMBER,
    id, first_name, last_name, password, job_title
  };
}

export const editMemberSuccess = (response) => {
  return {
    type: actionTypes.EDIT_MEMBER_SUCCESS,
    updatedMember: response.user,
    result: response.result
  };
}

export const editMemberFail = (error) => {
  return {
    type: actionTypes.EDIT_MEMBER_FAIL,
    error: error
  };
}

// ----------------------- DELETE MEMBER -----------------------
export const deleteMemberStart = () => {
  return {
    type: actionTypes.DELETE_MEMBER_START
  };
}

export const deleteMember = (id) => {
  return {
    type: actionTypes.DELETE_MEMBER,
    id: id
  };
}

export const deleteMemberSuccess = (result) => {
  return {
    type: actionTypes.DELETE_MEMBER_SUCCESS,
    result: result
  };
}

export const deleteMemberFail = (error) => {
  return {
    type: actionTypes.DELETE_MEMBER_FAIL,
    error: error
  };
}

// export default {
//   getMembersStart,
//   getMembers,
//   getMembersSuccess,
//   getMembersFail,
//   addMember,
//   addMemberStart,
//   addMemberSuccess,
//   addMemberFail,
//   editMember,
//   editMemberStart,
//   editMemberSuccess,
//   editMemberFail,
//   deleteMember,
//   deleteMemberStart,
//   deleteMemberSuccess,
//   deleteMemberFail,
// };