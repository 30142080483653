import React from 'react';
import styles from '../SystemDeviceConfig.module.scss';
import CoapFields from './CoapFields';
import DeviceHealth from './DeviceHealth';
import InformationFields from './InformationFields';
import RawData from './RawData';

export default class GeneralSettings extends React.Component {

    state = {
        currentMenu: 1
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.editingDevice !== this.props.editingDevice && this.props.editingDevice) {
            this.setState({ currentMenu: 1 });
        }
    }

    checkFormIsValid = () => {
        let formIsValid = true;
        for (let inputIdentifier in this.props.deviceForm) {
            formIsValid = this.props.deviceForm[inputIdentifier].valid && formIsValid;
        }
        return formIsValid;
    }

    render() {
        const { currentMenu } = this.state;
        const { deviceForm, currentConfigs, isSystemDevice, editingDevice, open } = this.props;

        return (
            <div className={styles.Container} style={open ? {} : { display: "none" }}>
                <div className={styles.MenuWrapper}>
                    <div className={currentMenu === 1 ? `${styles.Menu} ${styles.Active}` : styles.Menu} onClick={() => this.setState({ currentMenu: 1 })}>
                        <span className={styles.Label} style={{ color: this.checkFormIsValid() ? '' : '#e6492d' }}>Information</span>
                    </div>
                    {
                        isSystemDevice ?
                            <>
                                <div className={currentMenu === 2 ? `${styles.Menu} ${styles.Active}` : styles.Menu} onClick={() => this.setState({ currentMenu: 2 })}>
                                    <span className={styles.Label}>System</span>
                                </div>
                                <div className={currentMenu === 3 ? `${styles.Menu} ${styles.Active}` : styles.Menu} onClick={() => this.setState({ currentMenu: 3 })}>
                                    <span className={styles.Label}>Device Health</span>
                                </div>
                            </>
                            : null
                    }
                    <div className={currentMenu === 4 ? `${styles.Menu} ${styles.Active}` : styles.Menu} onClick={() => this.setState({ currentMenu: 4 })}>
                        <span className={styles.Label} style={{ color: this.checkFormIsValid() ? '' : '#e6492d' }}>Raw Data</span>
                    </div>
                </div>
                <div className={styles.ContentWrapper}>
                    {currentMenu === 1 && <InformationFields deviceForm={deviceForm} handleDeviceFormTextChange={this.props.handleDeviceFormTextChange} />}
                    {currentMenu === 2 && <CoapFields
                        edgeConfig={currentConfigs.edge_config}
                        networkDetails={this.props.networkDetails}
                        setAllNetworkDetails={this.props.setAllNetworkDetails}
                        setNetworkDetails={this.props.setNetworkDetails}
                    />}
                    {currentMenu === 3 && <DeviceHealth />}
                    <RawData editingDevice={editingDevice} open={open && currentMenu === 4} />
                </div>
            </div>
        );
    }
}

