import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './assets/styles/form.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import configureStore from "./state/store";
import { history } from './state/ducks';
import { ConnectedRouter } from 'connected-react-router';
import ErrorBoundary from './views/components/UI/ErrorBoundary/ErrorBoundary';

export const reduxStore = configureStore(window.REDUX_INITIAL_DATA);

const app = (
  <Provider store={reduxStore}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
