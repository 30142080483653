import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Input.module.scss';
import PasswordIcon from '../../../../assets/icons/Auth/view-pwd.svg';
import SeePasswordIcon from '../../../../assets/icons/Auth/view-pwd-blue.svg';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-terminal";
import "ace-builds/webpack-resolver";

export default class Input extends Component {
  // static propTypes = {
  //   labelin: PropTypes.bool
  // };

  state = {
    seePassword: false
  }

  onSeePasswordHandler = () => {
    this.setState(prevState => {
      return { seePassword: !prevState.seePassword };
    });
  }

  render() {
    let inputElement;
    switch (this.props.type) {
      case 'editor': {
        inputElement = (
          <AceEditor 
            className={this.props.className ? `${styles.FormField} ${this.props.className}` : styles.FormField}
            mode="javascript"
            theme="terminal"
            value={this.props.value}
            onChange={this.props.onChange}
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              tabSize: 2,
            }}
            style={{width: '100%', height: 400}}
            />
        );
        break;
      }
      case 'number':
      case 'text': {
        if (this.props.icon) {
          inputElement = (
            <div style={{ position: 'relative' }}>
              <input
                className={`${styles.FormField} ${styles.WithIcon} ${this.props.suffixicon ? styles.WithSuffixIcon : ''} ${this.props.whitemode ? styles.WhiteMode : ''} ${this.props.error ? styles.Error : ''} ${this.props.disabled ? styles.Disabled : ''}`}
                onChange={this.props.onChange} {...this.props}
              />
              <div className={styles.IconWrapper}><img className={styles.Icon} src={this.props.icon} alt={`${this.props.name} icon`} /></div>
                {this.props.suffixclick ?  <div onClick={this.props.suffixclick} {...this.props} className={styles.SuffixIconWrapper}><img className={styles.SuffixIcon} src={this.props.suffixicon} alt={`${this.props.suffixname} icon`} /></div> : null}
            </div>
          );
        } else {
          inputElement = (
            <input
              className={`${styles.FormField} ${this.props.whitemode ? styles.WhiteMode : ''} ${this.props.error ? styles.Error : ''} ${this.props.disabled ? styles.Disabled : ''} ${this.props.labelin ? styles.TextRight : ''}`}
              onChange={this.props.onChange} {...this.props}
            />
          );
        }
        break;
      }
      case 'time': {
        inputElement = (
          <input
            type='time'
            className={`${styles.FormField} ${this.props.whitemode ? styles.WhiteMode : ''} ${this.props.error ? styles.Error : ''} ${this.props.disabled ? styles.Disabled : ''}`}
            onChange={this.props.onChange} {...this.props}
          />
        );
        break;
      }
      case 'password': {
        inputElement =
          <div style={{ position: 'relative' }}>
            <input
              type={this.state.seePassword ? 'text' : 'password'}
              name={this.props.name}
              className={`${styles.FormField} ${this.props.whitemode ? styles.WhiteMode : ''} ${this.props.error ? styles.Error : ''}`}
              onChange={this.props.onChange}
              value={this.props.value}
              placeholder={this.props.placeholder}
              error={this.props.error}
            />
            <img className={styles.SeePassword} src={this.state.seePassword ? SeePasswordIcon : PasswordIcon} alt="See Password" onClick={this.onSeePasswordHandler} />
          </div>
        break;
      }
      case 'textarea':
        inputElement = (
          <textarea
            className={`${styles.FormField} ${this.props.whitemode ? styles.WhiteMode : ''} ${this.props.error ? styles.Error : ''} ${this.props.disabled ? styles.Disabled : ''}`}
            onChange={this.props.onChange}
            {...this.props}
          />
        );
        break;
      case 'radio': {
        const { name, value } = this.props;
        inputElement = this.props.options.map((data, i) => {
          let radioFieldStyles = [styles.RadioField];
          let radioIconWrapperStyles = [styles.RadioIconWrapper];

          if (value === data.value) {
            radioFieldStyles.push(styles.Selected);
            radioIconWrapperStyles.push(styles.Selected);
          }

          return (
            <label key={data.title} className={radioFieldStyles.join(' ')} onClick={() => this.props.onChangeRadioButton(i)}>
              <div className={radioIconWrapperStyles.join(' ')}>
                <img src={data.icon} alt={`${data.icon} icon`} className={styles.RadioIcon} width="24px"/>
              </div>
              <span className={styles.RadioLabel}>{data.title}</span>
              <input
                type="radio"
                name={name}
                value={value}
                className={styles.RadioButtonStyle}
                checked={value === data.value}
                onClick={(event) => event.stopPropagation()}
                onChange={() => this.props.onChangeRadioButton(i)}
              />
            </label>
          );
        });
        break;
      }
      default:
        inputElement = "Please check input type.";
        break;
    }

    return (
      <div className={styles.FormGroup} style={this.props.overidestyleformgroup}>
        {
          this.props.label &&
          <label className={`${styles.Title} ${this.props.labelin ? styles.LabelIn : ''}`}>
            {this.props.label} {this.props.required ? '*' : ''}
          </label>
        }
        {inputElement}
        {this.props.error && <div className={styles.ErrorText}>{this.props.error}</div>}
      </div>
    );
  }
}