
import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './ForgetPassword.module.scss';
import DogFinding from '../../../../assets/images/dog-finding.png';
import CeoImage from '../../../../assets/images/ceo.png';
import WorldIcon from '../../../../assets/icons/world.svg';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

import { checkValidity, updateObject } from '../../../../state/utils';
import { forgetPassword } from '../../../../state/ducks/Auth/actions';

const DEFAULT_FORGET_PASSWORD_FORM = {
  email: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true,
      isEmail: true
    }
  }
};

class ForgetPassword extends Component {
  state = {
    formIsValid: false,
    forgetPasswordForm: DEFAULT_FORGET_PASSWORD_FORM
  }

  handleChange = event => {
    let updatedElement = updateObject(this.state.forgetPasswordForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.forgetPasswordForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.forgetPasswordForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ forgetPasswordForm: updatedForm, formIsValid });
  };

  onForgetPasswordHandler = (event) => {
    event.preventDefault();
    if (this.state.formIsValid) {
      this.props.forgetPassword(this.state.forgetPasswordForm.email.value);
      this.onClearFields();
    }
    else {
      this.onSetTouchedFields();
    }
  }

  onSetTouchedFields = () => {
    let newState = { ...this.state.forgetPasswordForm };
    Object.keys(this.state.forgetPasswordForm).forEach(name => {
      let updatedElement = updateObject(newState[name], {
        touched: true
      });
      const updatedForm = updateObject(newState, {
        [name]: updatedElement
      });
      newState = updatedForm;
      this.setState({ forgetPasswordForm: updatedForm });
    });
  }

  onClearFields = () => {
    this.setState({ forgetPasswordForm: DEFAULT_FORGET_PASSWORD_FORM, formIsValid: false });
  }

  render() {
    const { email } = this.state.forgetPasswordForm;

    let signInForm = (
      <React.Fragment>
        <div style={{ flex: '0 1 90px' }}>
          <div className={styles.AuthTitle}>Forgot Password ?</div>
          <div className={styles.AuthSubTitle}>
            <div>Enter your Email address below.</div>
            We’ll get you back on track.
          </div>
        </div>
        <form onSubmit={this.onForgetPasswordHandler} className={styles.FormContainer}>
          <div style={{ flex: 1, marginTop: 40 }}>
            {this.props.forgetPasswordStatus === 'success' ? <div className={styles.SuccessMessage}>Send link success</div> : ''}
            {this.props.error === 'User not found' ? <div className={styles.ErrorMessage}>Please check your email!</div> : ''}
            <div className={styles.FormGroup}>
              <Input
                label="e-mail address"
                name="email"
                type="text"
                value={email.value}
                onChange={this.handleChange}
                error={`${email.touched && !email.valid ? 'Email is required and must be the correct email format' : ''}`}
                whitemode="true"
              />
            </div>
            <Button
              type="submit"
              color="blue"
              name={this.props.loading ? 'Loading...' : 'Request Reset Link'}
              loading={this.props.loading}
              noMargin
            />
          </div>
        </form>
      </React.Fragment>
    );

    return (
      <div className={styles.Auth}>
        <div className={styles.LeftSection}>
          <div className={styles.AuthWrapper}>
            {signInForm}
          </div>
        </div>
        {
          this.props.isMobile ? null :
            <div className={`${styles.RightSection} ${styles.ForgetBg}`} style={{ backgroundImage: `url(${DogFinding})` }}>
              <div className={styles.Testimonial}>
                <div>
                  <img src={WorldIcon} alt="Technimal.co.th" className={styles.WorldIcon} />
                  <b>Technimal.co.th</b>
                </div>
                <p style={{ width: 250 }}>Delivering our technology to help you achieve business goal</p>
                <div>
                  <img src={CeoImage} alt="CEO of Technimal" className={styles.CeoImage} />
                  <span style={{ fontSize: 14 }}><b>CEO</b> Thanyathat Angsuphisit</span>
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, forgetPasswordStatus, error } = state.auth;
  return { loading, forgetPasswordStatus, error };
};

export default connect(mapStateToProps, { forgetPassword })(ForgetPassword);