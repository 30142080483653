import React, { Component } from 'react';

import styles from './EditOrganization.module.scss';
import OrganizationForm from '../OrganizationForm/OrganizationForm';
import SwitchIcon from '../../../../assets/icons/switch.svg';
import OrganizationAdmin from '../OrganizationAdmin/OrganizationAdmin';

class EditOrganization extends Component {
  render() {
    return (
      <div className={styles.EditOrganization}>
        <div className={styles.HeaderWrapper}>
          {
            this.props.isAdding ? <div className={styles.Header}>Add New Organization</div> :
              <div className={styles.HeaderTap}>
                <div className={this.props.editingType === 'org' ? styles.SelectedHeader : styles.UnSelectedHeader} style={{ marginRight: 40 }}
                  onClick={() => this.props.onChangeEditType('org')}>
                  Edit Organization
                </div>
                <div className={this.props.editingType === 'admin' ? styles.SelectedHeader : styles.UnSelectedHeader}
                  onClick={() => this.props.onChangeEditType('admin')}>
                  Edit Admin
                </div>
              </div>
          }
          <div style={{ width: 200 }}>
            <button className={styles.SwitchButton} type="button" onClick={this.props.toggleModeHandler}>
              <img src={SwitchIcon} alt="Switch to organization" className={styles.IconButton} />
              Switch Organization
          </button>
          </div>
        </div>
        {
          this.props.editingType === 'org' ? <OrganizationForm editingOrg={this.props.editingOrg} isAdding={this.props.isAdding} modalClosed={this.props.modalClosed} toggleModeHandler={this.props.toggleModeHandler} /> :
            <OrganizationAdmin editingOrg={this.props.editingOrg} modalClosed={this.props.modalClosed} />
        }
      </div>
    );
  }
}

export default EditOrganization;
