import * as actionTypes from './types';

// ----------------------- GET CHATROOMS -----------------------
export const getChatroomsStart = () => {
  return {
    type: actionTypes.GET_CHATROOMS_START
  };
}

export const getChatrooms = (org_id, user_id) => {
  return {
    type: actionTypes.GET_CHATROOMS,
    org_id, user_id
  };
}

export const getChatroomsSuccess = (response) => {
  return {
    type: actionTypes.GET_CHATROOMS_SUCCESS,
    chatrooms: response.chatrooms
  };
}

export const getChatroomsFail = (error) => {
  return {
    type: actionTypes.GET_CHATROOMS_FAIL,
    error: error
  };
}

// ----------------------- GET SINGLE CHATROOMS -----------------------
export const getChatroomStart = () => {
  return {
    type: actionTypes.GET_CHATROOM_START
  };
}

export const getChatroom = (id) => {
  return {
    type: actionTypes.GET_CHATROOM,
    id
  };
}

export const getChatroomSuccess = (response) => {
  return {
    type: actionTypes.GET_CHATROOM_SUCCESS,
    chatroom: response.chatroom
  };
}

export const getChatroomFail = (error) => {
  return {
    type: actionTypes.GET_CHATROOM_FAIL,
    error: error
  };
}

// ----------------------- ADD CHATROOM -----------------------
export const addChatroomStart = () => {
  return {
    type: actionTypes.ADD_CHATROOM_START
  };
}

export const addChatroom = (title, org_id, member_ids, logo) => {
  return {
    type: actionTypes.ADD_CHATROOM,
    title, org_id, member_ids, logo
  };
}

export const addChatroomSuccess = (response) => {
  return {
    type: actionTypes.ADD_CHATROOM_SUCCESS,
    addedChatroom: response.chatroom,
    result: response.result
  };
}

export const addChatroomFail = (error) => {
  return {
    type: actionTypes.ADD_CHATROOM_FAIL,
    error: error
  };
}