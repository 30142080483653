import React, { Component } from 'react';
import { connect } from 'react-redux';

import Modal from '../../UI/Modal/Modal';
import Button from '../../UI/Button/Button';
import styles from './SystemDeviceConfig.module.scss';
import SaveIcon from '../../../../assets/icons/save_device_config.svg';
import DeleteIcon from '../../../../assets/icons/delete_device_config.svg';

import { updateObject, checkValidity } from '../../../../state/utils';
import { editDevice, getDeviceStatus } from '../../../../state/ducks/Devices/actions';
import { editDeviceSocketsWithJSON } from '../../../../state/ducks/DeviceSocket/actions';
import { getSystemDeviceConfig, saveSystemDeviceConfig, getSystemDeviceConfigJson, saveSystemDeviceConfigJson } from '../../../../state/ducks/DeviceConfig/actions';
import { SAVE_SYSTEM_DEVICE_CONFIG_SUCCESS, SAVE_SYSTEM_DEVICE_CONFIG_JSON_SUCCESS } from '../../../../state/ducks/DeviceConfig/types';
import GeneralSettings from './GeneralSettings/GeneralSettings';
import NetworkSettings from './NetworkSettings/NetworkSettings';
import SensorSettings from './SensorSettings/SensorSettings';
import ControlSettings from './ControlSettings/ControlSettings';
// import SummaryConfig from './SummaryConfig/SummaryConfig';
import Loading from '../../UI/LoadingSkeleton/LoadingTable/LoadingTable';

const owl = require('../../../../owlmsg_pb');

const DEFAULT_DEVICE_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  description: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  },
  tag: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  }
};

class SystemDeviceConfigModal extends Component {
  state = {
    steps: [],
    currentTab: 1,
    owlMessage: null,
    currentConfigs: null,
    networkEnable: [],
    networkDetails: {},
    analogList: [],
    modbusList: [],
    controlList: [],
    disabledNext: false,
    deviceForm: DEFAULT_DEVICE_FORM,
    deviceSocketForm: [],
    errorProtoBuf: false,
    fusoVersion: false,
    newFirmwareVersion: false,
    isSystemDevice: false,
    isItReadyToSave: false,
    isDeviceFormValid: false,
    wentToTabInput: false,
    hasAlreadyBeenToNetworkTab: false,
    hasAlreadyBeenToControlTab: false
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.result !== this.props.result && this.props.result === 'success' && this.props.type === SAVE_SYSTEM_DEVICE_CONFIG_JSON_SUCCESS) {
      this.onSaveDeviceSocketInformationHandler();
    } else if (prevProps.updatedDeviceSockets !== this.props.updatedDeviceSockets && this.props.updatedDeviceSockets) {
      this.props.modalClosed();
    } else if (prevProps.systemDeviceConfigJson !== this.props.systemDeviceConfigJson && this.props.systemDeviceConfigJson) {
      this.setState({ currentConfigs: this.props.systemDeviceConfigJson });
    }
    if (this.props.editingDevice && prevProps.editingDevice !== this.props.editingDevice) {
      this.setupNewDevice();
      if (this.props.editingDevice.system_device) {
        // this.props.getSystemDeviceConfig(this.props.editingDevice.secret);
        this.props.getSystemDeviceConfigJson(this.props.editingDevice.secret);
        this.props.getDeviceStatus(this.props.editingDevice.secret);
        this.setState({
          steps: ['General', 'Network', 'Input', 'Output'],
          fusoVersion: this.props.editingDevice.firmware_version.toLowerCase().startsWith('f'),
          newFirmwareVersion: this.isThisNewFirmwareVersion(this.props.editingDevice.firmware_version)
        });
      } else {
        this.setState({ steps: ['General', 'Input'] });
      }
      this.setState({
        currentTab: 1,
        errorProtoBuf: false,
        wentToTabInput: false,
        hasAlreadyBeenToNetworkTab: false
      });
    }
    // if ((this.props.systemDeviceConfig || this.props.systemDeviceConfig === '') && prevProps.systemDeviceConfig !== this.props.systemDeviceConfig) {
    //   this.decodeConfig(this.props.systemDeviceConfig);
    // }
  }

  isThisNewFirmwareVersion = (v) => {
    const newFirmwareVersion = ['3', '8', '2'];
    if (v.toLowerCase().startsWith('f')) return true;

    const currentVersion = v.split('.');
    if (currentVersion[0] > newFirmwareVersion[0]) return true;
    else if (currentVersion[0] < newFirmwareVersion[0]) return false;
    else {
      if (currentVersion[1] > newFirmwareVersion[1]) return true;
      else if (currentVersion[1] < newFirmwareVersion[1]) return false;
      else {
        if (currentVersion[2] >= newFirmwareVersion[2]) return true;
        else return false;
      }
    }
  }

  setupNewDevice = () => {
    let editDevice = this.props.editingDevice;
    let updatedForm = { ...this.state.deviceForm };
    for (let inputIdentifier in updatedForm) {
      const updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: editDevice[inputIdentifier] ? editDevice[inputIdentifier] : '',
        valid: true,
        touched: true
      });
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }

    let deviceSocketForm = [];
    for (let i = 0; i < this.props.editingDevice.device_sockets.length; i++) {
      let deviceSocket = this.props.editingDevice.device_sockets[i];
      if (deviceSocket && deviceSocket.enable || (deviceSocket.socket >= 11 && deviceSocket.socket <= 20 && !deviceSocket.enable)) {
        //input
        let functions = "";
        if (deviceSocket.functions && deviceSocket.functions.length) {
          deviceSocket.functions.forEach(func => {
            functions += func.operation + ' ' + func.value + ' ';
          });
        }
        deviceSocketForm.push({
          id: deviceSocket.socket,
          name: deviceSocket.name,
          functions: functions,
          location: deviceSocket.location,
          tag: deviceSocket.tag,
          unit_name: deviceSocket.unit_name,
          is_valid: true,
        });
      }
    }
    this.setState({ deviceForm: updatedForm, deviceSocketForm, isItReadyToSave: true, isDeviceFormValid: true, isSystemDevice: editDevice.system_device });
  }

  handleDeviceFormTextChange = event => {
    let updatedElement = updateObject(this.state.deviceForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.deviceForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.deviceForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ deviceForm: updatedForm, isDeviceFormValid: formIsValid });
  };

  // decodeConfig = (configs) => {
  //   let loadedConfig = null;
  //   if (configs) {
  //     const convertConfigs = this.hexToBytes(configs);
  //     try {
  //       loadedConfig = owl.OwlMsg.deserializeBinary(convertConfigs);
  //       console.log("LOADED config", loadedConfig.toObject());
  //       this.setState({ owlMessage: loadedConfig, currentConfigs: loadedConfig.toObject() });
  //     } catch (e) {
  //       console.error("SOMETHING WRONG WITH DECODING PROTOBUF :", e);
  //     }
  //   } else {
  //     const newOwlMsg = new owl.OwlMsg();
  //     this.setState({ owlMessage: newOwlMsg, currentConfigs: newOwlMsg.toObject() });
  //   }
  // }

  onSaveAll = () => {
    if (this.state.isSystemDevice) {
      if (this.checkSaveButtonIsAvailable()) {
        this.onSaveDeviceConfigHandler();
        this.onSaveDeviceInformationHandler();
        this.setState({ errorProtoBuf: false });
      } else {
        this.setState({ errorProtoBuf: true });
      }
    } else {
      this.onSaveDeviceInformationHandler();
      this.onSaveDeviceSocketInformationHandler();
    }
  }

  onSaveDeviceConfigHandler = () => {
    // DEPRECATED: SAVE PROTO
    // try {
    //   const hexData = this.bytesToHex(this.state.owlMessage.serializeBinary());
    //   // console.log("saving", this.state.owlMessage.toObject());
    //   this.props.saveSystemDeviceConfig(this.props.editingDevice.secret, hexData);
    // } catch (e) {
    //   console.error("Validation failed ", e);
    // }

    // NEW /saveSystemDeviceConfigJson
    this.props.saveSystemDeviceConfigJson(this.props.editingDevice.secret, this.setupBodyMessageForSystemConfig());
  }

  onSaveDeviceInformationHandler = () => {
    const { deviceForm } = this.state;
    this.props.editDevice(
      this.props.editingDevice.id,
      deviceForm.name.value,
      deviceForm.description.value,
      deviceForm.tag.value
    );
  }

  onSaveDeviceSocketInformationHandler = () => {
    const updatedDeviceSocketForm = [...this.state.deviceSocketForm];
    updatedDeviceSocketForm.forEach(ds => {
      if (ds.name === '') {
        ds.name = ds.id <= 10 ? 'Socket-' + ds.id : 'Control-' + ds.id;
      }
    });
    this.props.editDeviceSocketsWithJSON(this.props.editingDevice.secret, updatedDeviceSocketForm);
  }

  setupBodyMessageForSystemConfig = () => {
    const { wifi_config, eth_config, xbee_config, cellular_config, edge_config, analog_inputs, modbus_inputs, enable_outputs } = this.state.currentConfigs;
    const body = {};
    const {
      ssid, password, wifiEnableStaticIp, wifiIp, wifiSubnet, wifiGateway, wifiPrimaryDns, wifiSecondaryDns,
      ethernetEnableStaticIp, ethernetIp, ethernetSubnet, ethernetGateway, ethernetPrimaryDns, ethernetSecondaryDns,
      xbeeIsGateway, masterHighAddress, masterLowAddress,
      cellularAPN, cellularUsername, cellularPassword,
      main_interval, coap_host, coap_port, reboot_interval
    } = this.state.networkDetails;

    if (!this.state.hasAlreadyBeenToNetworkTab) {
      body.wifi_config = wifi_config;
      body.eth_config = eth_config;
      body.xbee_config = xbee_config;
      body.cellular_config = cellular_config;
    } else {
      this.state.networkEnable.forEach(ne => {
        if (ne.name === 'Wi-Fi') {
          body.wifi_config = {
            enable: ne.value,
            ssid, password,
            static_ip: wifiEnableStaticIp
          };
          if (ne.value && wifiEnableStaticIp) {
            body.wifi_config = updateObject(body.wifi_config, {
              ip: wifiIp,
              subnet: wifiSubnet,
              gateway: wifiGateway,
              primary_dns: wifiPrimaryDns,
              secondary_dns: wifiSecondaryDns
            });
          }
        } else if (ne.name === 'Ethernet') {
          body.eth_config = {
            enable: ne.value,
            static_ip: ethernetEnableStaticIp
          };
          if (ne.value && ethernetEnableStaticIp) {
            body.eth_config = updateObject(body.eth_config, {
              ip: ethernetIp,
              subnet: ethernetSubnet,
              gateway: ethernetGateway,
              primary_dns: ethernetPrimaryDns,
              secondary_dns: ethernetSecondaryDns
            });
          }
        } else if (ne.name === 'XBee') {
          body.xbee_config = { enable: ne.value };
          if (!xbeeIsGateway) {
            if (masterHighAddress) {
              body.xbee_config = updateObject(body.xbee_config, {
                master_h_addr: parseInt(masterHighAddress, 16)
              });
            }
            if (masterLowAddress) {
              body.xbee_config = updateObject(body.xbee_config, {
                master_l_addr: parseInt(masterLowAddress, 16)
              });
            }
          } else {
            body.xbee_config = updateObject(body.xbee_config, {
              master_h_addr: 0, master_l_addr: 0
            });
          }
        } else if (ne.name === 'Cellular') {
          body.cellular_config = { enable: ne.value };
          if (ne.value) {
            body.cellular_config = updateObject(body.cellular_config, {
              apn_configs_map: [
                ['name', cellularAPN ? cellularAPN : 'internet'],
                ['password', cellularPassword],
                ['username', cellularUsername]
              ]
            });
          }
        }
      });
    }

    if (main_interval) {
      // assume that went to edge setup, otherwise not gone yet.
      body.edge_config = {
        main_interval: main_interval ? +main_interval : 5000,
        coap_host: coap_host ? coap_host : '35.198.205.42',
        coap_port: coap_port ? +coap_port : 5683,
        reboot_interval: reboot_interval ? +reboot_interval : 0
      };
    } else {
      if (edge_config && Object.keys(edge_config).length) body.edge_config = edge_config;
      else body.edge_config = {};
    }

    const { analogList, modbusList } = this.state;
    if (this.state.wentToTabInput) {
      body.analog_inputs = [];
      if (analogList && analogList.length > 0) {
        analogList.forEach(analog => {
          if (analog && Object.keys(analog).length) {
            body.analog_inputs.push({
              socket: analog.key,
              delay: analog.id === 'TIMESTAMP' || analog.id === 'FREQUENCY' ? analog.delay : 0,
              type: owl.OwlAnalogInputType[analog.id]
            });
          }
        });
      }

      body.modbus_inputs = [];
      if (modbusList && modbusList.length > 0) {
        modbusList.forEach((mb, i) => {
          const { socket, address, pb_function, reg, length, order, dataType, ip, port, actionType, actionFunction, actionRegister, actionLength, actionValue } = mb.form;
          body.modbus_inputs[i] = {
            socket: +socket.value,
            address: +address.value,
            function: +pb_function.value,
            reg: +reg.value,
            length: length.value.id
          };
          if (length.value.id === 2) {
            body.modbus_inputs[i].order = owl.OwlModbusOrder[order.value.id];
            body.modbus_inputs[i].data_type = owl.OwlModbusDataType[dataType.value.id];
          }
          if (mb.overTcp) {
            body.modbus_inputs[i].ip = ip.value;
            body.modbus_inputs[i].port = +port.value;
          }
          if (mb.hasModbusActions && actionFunction.value && actionRegister.value && actionValue.value) {
            const base64String = btoa(String.fromCharCode(...new Uint8Array(this.decimalToUint(+actionValue.value, actionLength.value.id))));
            body.modbus_inputs[i].action = {
              type: owl.OwlModbusActionType[actionType.value.id],
              function: +actionFunction.value,
              reg: +actionRegister.value,
              length: actionLength.value.id,
              value: base64String
            }
          }
        });
      }
    } else {
      body.analog_inputs = analog_inputs;
      body.modbus_inputs = modbus_inputs;
    }

    if (this.state.hasAlreadyBeenToControlTab) {
      const { controlList } = this.state;
      body.enable_outputs = [];
      if (controlList && controlList.length > 0) {
        body.enable_outputs = [...controlList];
      }
    } else {
      body.enable_outputs = enable_outputs;
    }

    const foundXbeeIndex = this.state.networkEnable.findIndex(ne => ne.name === 'XBee');
    if (this.state.networkEnable[foundXbeeIndex] && this.state.networkEnable[foundXbeeIndex].value && xbeeIsGateway) {
      body.analog_inputs = [];
      body.modbus_inputs = [];
      body.enable_outputs = [];
    }
    return body;
  }

  setupConfigsMessage = () => {
    const owlMessage = this.state.owlMessage;
    const {
      ssid, password, wifiEnableStaticIp, wifiIp, wifiSubnet, wifiGateway, wifiPrimaryDns, wifiSecondaryDns,
      ethernetEnableStaticIp, ethernetIp, ethernetSubnet, ethernetGateway, ethernetPrimaryDns, ethernetSecondaryDns,
      xbeeIsGateway, masterHighAddress, masterLowAddress,
      cellularAPN, cellularUsername, cellularPassword,
      mainInterval, coapHost, coapPort, rebootInterval
    } = this.state.networkDetails;

    this.state.networkEnable.forEach(ne => {
      if (ne.name === 'Wi-Fi') {
        let updatedWifiConfig = new owl.OwlWiFiAndEthernetConfig();
        updatedWifiConfig.setEnable(ne.value);
        updatedWifiConfig.setSsid(ssid);
        updatedWifiConfig.setPassword(password);
        if (ne.value && wifiEnableStaticIp) {
          updatedWifiConfig.setStaticIp(wifiEnableStaticIp);
          updatedWifiConfig.setIp(wifiIp);
          updatedWifiConfig.setSubnet(wifiSubnet);
          updatedWifiConfig.setGateway(wifiGateway);
          updatedWifiConfig.setPrimaryDns(wifiPrimaryDns);
          updatedWifiConfig.setSecondaryDns(wifiSecondaryDns);
        }
        this.state.owlMessage.setWifiConfig(updatedWifiConfig);
      } else if (ne.name === 'Ethernet') {
        let updatedEthConfig = new owl.OwlWiFiAndEthernetConfig();
        updatedEthConfig.setEnable(ne.value);
        if (ne.value && ethernetEnableStaticIp) {
          updatedEthConfig.setStaticIp(ethernetEnableStaticIp);
          updatedEthConfig.setIp(ethernetIp);
          updatedEthConfig.setSubnet(ethernetSubnet);
          updatedEthConfig.setGateway(ethernetGateway);
          updatedEthConfig.setPrimaryDns(ethernetPrimaryDns);
          updatedEthConfig.setSecondaryDns(ethernetSecondaryDns);
        }
        this.state.owlMessage.setEthConfig(updatedEthConfig);
      } else if (ne.name === 'XBee') {
        let updatedXbeeConfig = new owl.OwlXbeeConfig();
        updatedXbeeConfig.setEnable(ne.value);
        if (!xbeeIsGateway) {
          if (masterHighAddress) {
            updatedXbeeConfig.setMasterHAddr(parseInt(masterHighAddress, 16));
          }
          if (masterLowAddress) {
            updatedXbeeConfig.setMasterLAddr(parseInt(masterLowAddress, 16));
          }
        } else {
          updatedXbeeConfig.setMasterHAddr(0);
          updatedXbeeConfig.setMasterLAddr(0);
        }
        this.state.owlMessage.setXbeeConfig(updatedXbeeConfig);
      } else if (ne.name === 'Cellular') {
        let updatedCellularConfig = new owl.OwlCellularConfig();
        updatedCellularConfig.setEnable(ne.value);
        if (ne.value) {
          let apnConfigs = updatedCellularConfig.getApnConfigsMap();
          apnConfigs.set('name', cellularAPN ? cellularAPN : 'internet');
          apnConfigs.set('username', cellularUsername);
          apnConfigs.set('password', cellularPassword);
        }
        this.state.owlMessage.setCellularConfig(updatedCellularConfig);
      }
    });

    // Data Interval and CoAP
    let updatedEdgeConfig = owlMessage.getEdgeConfig();
    if (!updatedEdgeConfig) {
      updatedEdgeConfig = new owl.OwlEdgeConfig();
    }
    updatedEdgeConfig.setMainInterval(mainInterval ? +mainInterval * 1000 : 5);
    updatedEdgeConfig.setCoapHost(coapHost ? coapHost : '35.198.205.42');
    updatedEdgeConfig.setCoapPort(coapPort ? coapPort : '5683');
    updatedEdgeConfig.setRebootInterval(rebootInterval ? +rebootInterval * 1000 : 0);
    owlMessage.setEdgeConfig(updatedEdgeConfig);

    const { analogList, modbusList, currentConfigs } = this.state;
    // set up analog list
    if (analogList && analogList.length > 0) {
      owlMessage.clearAnalogInputsList();
      let updatedAnalogInputsList = owlMessage.getAnalogInputsList();
      let i = 0;
      analogList.forEach(analog => {
        if (analog && Object.keys(analog).length) {
          updatedAnalogInputsList[i] = new owl.OwlAnalogInput();
          updatedAnalogInputsList[i].setName('' + analog.key);
          updatedAnalogInputsList[i].setDelay(analog.delay);
          updatedAnalogInputsList[i].setType(owl.OwlAnalogInputType[analog.id]);
          i++;
        }
      });
      owlMessage.setAnalogInputsList(updatedAnalogInputsList);
    } else if (currentConfigs.analogInputsList && currentConfigs.analogInputsList.length) {
      // It's already have the old one. but should have this cond otherwise, it goes to clearAnalogInputsList()
    } else {
      owlMessage.clearAnalogInputsList();
    }

    // set up modbus list
    if (modbusList && modbusList.length > 0) {
      owlMessage.clearModbusInputsList();
      let updatedModbusInputsList = owlMessage.getModbusInputsList();
      modbusList.forEach((mb, i) => {
        const { name, address, pb_function, reg, length, order, dataType, ip, port, actionType, actionFunction, actionRegister, actionLength, actionValue } = mb.form;
        updatedModbusInputsList[i] = new owl.OwlModbusInput();
        updatedModbusInputsList[i].setName(name.value);
        updatedModbusInputsList[i].setAddress(+address.value);
        updatedModbusInputsList[i].setFunction(+pb_function.value);
        updatedModbusInputsList[i].setReg(+reg.value);
        updatedModbusInputsList[i].setLength(length.value.id);
        if (length.value.id === 2) {
          updatedModbusInputsList[i].setOrder(owl.OwlModbusOrder[order.value.id]);
          updatedModbusInputsList[i].setDataType(owl.OwlModbusDataType[dataType.value.id]);
        }
        if (mb.overTcp) {
          updatedModbusInputsList[i].setIp(ip.value);
          updatedModbusInputsList[i].setPort(+port.value);
        }

        let modbusAction = new owl.OwlModbusAction();
        if (mb.hasModbusActions && actionFunction.value && actionRegister.value && actionValue.value) {
          modbusAction.setType(owl.OwlModbusActionType[actionType.value.id]);
          modbusAction.setFunction(+actionFunction.value);
          modbusAction.setReg(+actionRegister.value);
          modbusAction.setLength(actionLength.value.id);
          modbusAction.setValue(this.decimalToUint(actionValue.value, actionLength.value.id));
        }
        updatedModbusInputsList[i].setAction(modbusAction);
      });
      owlMessage.setModbusInputsList(updatedModbusInputsList);
    } else if (currentConfigs.modbusInputsList && currentConfigs.modbusInputsList.length && !this.state.wentToTabInput) {
      // 2 conditions : - have old, but didnt go that tab => should save the old config
      //              : - have old -> go that tab -> delete all modbus (state.wentToTabInput=true) => should clear (else cond.)
    } else {
      owlMessage.clearModbusInputsList();
    }

    // set up Controls
    const { controlList } = this.state;
    if (controlList && controlList.length > 0) {
      owlMessage.setEnableOutputsList([...controlList]);
    } else if (currentConfigs.enableOutputsList && currentConfigs.enableOutputsList.length) {
      // It's already have the old one. but should have this cond otherwise, it goes to clearModbusInputsList()
    } else {
      owlMessage.clearEnableOutputsList();
    }

    const foundXbeeIndex = this.state.networkEnable.findIndex(ne => ne.name === 'XBee');
    if (this.state.networkEnable[foundXbeeIndex] && this.state.networkEnable[foundXbeeIndex].value && xbeeIsGateway) {
      owlMessage.clearAnalogInputsList();
      owlMessage.clearModbusInputsList();
      owlMessage.clearEnableOutputsList();
    }

    this.setState({ owlMessage });

    let canNext = false;
    try {
      this.state.owlMessage.serializeBinary();
      canNext = true;
    } catch (e) {
      // console.error("Validation failed ", e);
    }
    return canNext;
  }

  bytesToHex = (byteArray) => {
    return Array.from(byteArray, function (byte) {
      return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    }).join('')
  }

  hexToBytes = (hex) => {
    // substr seems to be deprecated! , if so, try substring(c, 2+1)
    for (var bytes = [], c = 0; c < hex.length; c += 2)
      bytes.push(parseInt(hex.substr(c, 2), 16));
    return bytes;
  }

  decimalToUint = (v, actionLength) => {
    let result = new Uint8Array(2);
    if (actionLength === 1) {
      result[0] = v & 0xff;
    } else {
      result[0] = (v / 256) & 0xff;
      result[1] = v & 0xff;
    }

    return result;
  }

  onSetNetworkDetailsHandler = (key, value, disabledNext) => {
    const updatedDetails = { ...this.state.networkDetails };
    updatedDetails[key] = value;
    this.setState({ networkDetails: updatedDetails, disabledNext });
  }

  onChangeTabHandler = (i) => {
    if ((this.state.owlMessage && !this.props.loading) || (!this.props.isSystemDevice && !this.props.loading)) {
      this.setState({ currentTab: i + 1 });
    }
  }

  checkSaveButtonIsAvailable = () => {
    let canSave = true;
    this.state.modbusList.forEach(ml => canSave = canSave && ml.isValid);
    this.state.deviceSocketForm.forEach(dsf => {
      if (dsf.id <= 20) {
        canSave = canSave && dsf.is_valid;
      }
    });
    this.setState({ isItReadyToSave: canSave && this.state.isDeviceFormValid });
    return canSave && this.state.isDeviceFormValid;
  }

  render() {
    const { currentTab } = this.state;
    let filteredAnalogSockets = this.state.deviceSocketForm.filter(f => f.id <= 10);
    // if (this.state.fusoVersion) {
    //   filteredAnalogSockets = this.state.deviceSocketForm.filter(f => f.id <= 2);
    // }
    let content = null;

    if ((!this.state.currentConfigs && this.state.isSystemDevice) || this.props.loading) {
      content = <Loading />;
    } else if (currentTab === 2) {
      if (this.state.isSystemDevice) {
        content = <NetworkSettings
          fusoVersion={this.state.fusoVersion}
          currentConfigs={this.state.currentConfigs}
          setNetworkEnable={(networkEnable) => this.setState({ networkEnable })}
          setAllNetworkDetails={(networkDetails) => this.setState({ networkDetails })}
          networkDetails={this.state.networkDetails}
          setNetworkDetails={this.onSetNetworkDetailsHandler}
          hasAlreadyBeenToNetworkTab={this.state.hasAlreadyBeenToNetworkTab}
          beenToNetworkTab={() => this.setState({ hasAlreadyBeenToNetworkTab: true })}
        />;
      } else {
        content = <SensorSettings
          isSystemDevice={this.state.isSystemDevice}
          editingDevice={this.props.editingDevice}
          analogSockets={filteredAnalogSockets}
          deviceSocketForm={this.state.deviceSocketForm}
          setDeviceSocketForm={(deviceSocketForm) => this.setState({ deviceSocketForm })}
        />;
      }
    } else if (currentTab === 3) {
      content = <SensorSettings
        isSystemDevice={this.state.isSystemDevice}
        newFirmwareVersion={this.state.newFirmwareVersion}
        currentConfigs={this.state.currentConfigs}
        editingDevice={this.props.editingDevice}
        analogSockets={filteredAnalogSockets}
        modbusSockets={this.state.deviceSocketForm.filter(f => f.id >= 21)}
        setAnalogList={(analogList) => this.setState({ analogList })}
        analogList={this.state.analogList}
        setModbusList={(modbusList) => this.setState({ modbusList })}
        modbusList={this.state.modbusList}
        setWentToTabInput={() => this.setState({ wentToTabInput: true })}
        wentToTabInput={this.state.wentToTabInput}
        deviceSocketForm={this.state.deviceSocketForm}
        setDeviceSocketForm={(deviceSocketForm) => this.setState({ deviceSocketForm })}
      />;
    } else if (currentTab === 4) {
      content = <ControlSettings
        currentConfigs={this.state.currentConfigs}
        editingDevice={this.props.editingDevice}
        setControlList={(controlList) => this.setState({ controlList })}
        controlList={this.state.controlList}
        deviceSocketForm={this.state.deviceSocketForm}
        controlForms={this.state.deviceSocketForm.filter(f => f.id > 10 && f.id < 21)}
        setDeviceSocketForm={(deviceSocketForm) => this.setState({ deviceSocketForm })}
        hasAlreadyBeenToControlTab={this.state.hasAlreadyBeenToControlTab}
        beenToControlTab={() => this.setState({ hasAlreadyBeenToControlTab: true })}
      />;
    }

    const mediaMatch = window.matchMedia('screen and (max-width: 900px)');

    return (
      <Modal darkMode noCloseButton disableCloseOnBackdrop show={this.props.open} modalClosed={this.props.modalClosed} overideStyles={{ width: mediaMatch.matches ? 600 : 900, display: 'flex', flexDirection: 'column', height: 565 }}>
        <div className={styles.HeaderSection}>
          <div style={{ flex: 1 }}>
            <div className={styles.HeaderText}>Device Configuration</div>
            {
              this.props.editingDevice
                ? <div className={styles.SubHeaderText}>{this.props.editingDevice.name} - {this.props.editingDevice.secret}</div>
                : null
            }
            {!this.state.errorProtoBuf && this.state.isItReadyToSave ? '' : <div style={{ fontSize: 12, fontWeight: '500', color: '#e6492d', marginTop: 10 }}>Something weng wrong! Please check all inputs.</div>}
          </div>
          <div className={styles.HeaderButtonsWrappper}>
            <Button type="button" name="Save" color="green" icon={SaveIcon} disabled={this.props.loading} overideButtonStyles={{ marginTop: 15, marginRight: 20 }} click={this.onSaveAll} />
            <Button type="button" name="Delete" color="red" icon={DeleteIcon} disabled={this.props.loading} overideButtonStyles={{ marginTop: 15, marginRight: mediaMatch.matches ? 0 : 45 }} click={this.props.onDelete} />
          </div>
          <div className={styles.CloseButton}>
            <span className={styles.Btn} aria-hidden="true" onClick={this.props.modalClosed} style={{ marginTop: 0 }}>&times;</span>
          </div>
        </div>
        <div className={styles.TabWrapper}>
          {
            this.state.steps.map((step, i) => {
              let stepStyles = [styles.TabSection];
              if (currentTab === (i + 1)) {
                stepStyles.push(styles.Active)
              }

              return <div key={`device-config-step-${i + 1}`} className={stepStyles.join(' ')} onClick={() => this.onChangeTabHandler(i)}>{step}</div>;
            })
          }
        </div>

        <div className={styles.BodySection}>
          <GeneralSettings
            isSystemDevice={this.state.isSystemDevice}
            deviceForm={this.state.deviceForm}
            editingDevice={this.props.editingDevice}
            currentConfigs={this.state.currentConfigs}
            handleDeviceFormTextChange={this.handleDeviceFormTextChange}
            setAllNetworkDetails={(networkDetails) => this.setState({ networkDetails })}
            networkDetails={this.state.networkDetails}
            setNetworkDetails={this.onSetNetworkDetailsHandler}
            open={currentTab === 1}
          />
          {content}
        </div>

      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { updatedDevice } = state.devices;
  const { updatedDeviceSockets } = state.deviceSockets;
  const { systemDeviceConfig, type, result, systemDeviceConfigJson } = state.deviceConfigs;
  let loading = state.devices.loading || state.deviceSockets.loading;
  return { updatedDevice, updatedDeviceSockets, systemDeviceConfig, loading, type, result, dtype: state.devices.type, dstype: state.deviceSockets.type, systemDeviceConfigJson };
};

export default connect(mapStateToProps, { getSystemDeviceConfig, saveSystemDeviceConfig, editDevice, editDeviceSocketsWithJSON, getDeviceStatus, getSystemDeviceConfigJson, saveSystemDeviceConfigJson })(SystemDeviceConfigModal);