import React, { Component } from 'react'
import Input from '../../UI/Input/Input';

import { updateObject, checkValidity } from '../../../../state/utils';

const DEFAULT_DEVICESOCKET_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  location: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: false
    }
  },
  unit_name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: false
    }
  },
  functions: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  },
  tag: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  }
};

class DeviceForm extends Component {
  state = {
    formIsValid: false,
    deviceForm: DEFAULT_DEVICESOCKET_FORM
  }

  componentDidMount() {
    if (this.props.deviceSocket) {
      this.setupForm(this.props.deviceSocket);
    }
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.deviceForm };
    for (let inputIdentifier in updatedForm) {
      let updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] ? form[inputIdentifier] : '',
        valid: true,
        touched: true,
      });
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }
    this.setState({ deviceForm: updatedForm, formIsValid: true });
  }

  handleTextChange = event => {
    let updatedElement = updateObject(this.state.deviceForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.deviceForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.deviceForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ deviceForm: updatedForm, formIsValid });
    this.props.handleOwlDeviceFormChange(
      this.props.addIndex || this.props.addIndex === 0 ? this.props.addIndex: this.props.deviceSocket.id,
      updatedForm,
      formIsValid
    );
  };

  render() {
    const { name, location, unit_name, functions, tag } = this.state.deviceForm;
    return (
      <>
        <Input
          label="device name"
          name="name"
          type="text"
          value={name.value}
          placeholder="Please insert your Device name"
          autoComplete="off"
          onChange={this.handleTextChange}
          error={`${name.touched && !name.valid ? 'Device Name is required' : ''}`}
          required
        />
        <Input
          label="location"
          name="location"
          value={location.value}
          type="text"
          placeholder="Please insert Device's location"
          onChange={this.handleTextChange}
          error={`${location.touched && !location.valid ? 'Location is required' : ''}`}
        />
        <Input
          label="unit"
          name="unit_name"
          type="text"
          value={unit_name.value}
          placeholder="m/min"
          autoComplete="off"
          onChange={this.handleTextChange}
          error={`${unit_name.touched && !unit_name.valid ? 'Unit is required' : ''}`}
        />
        <Input
          label="function"
          name="functions"
          type="text"
          value={functions.value}
          placeholder="x 109 / 23"
          autoComplete="off"
          onChange={this.handleTextChange}
        />
        <Input
          label="tags"
          name="tag"
          type="textarea"
          rows="3"
          value={tag.value}
          placeholder="Separate by comma (,) e.g. tag1,tag2,tag3"
          autoComplete="off"
          onChange={this.handleTextChange}
        />
        <div style={{ height: 15, width: '100%' }} />
      </>
    )
  }
}

export default DeviceForm;