import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_RULE_COMMANDS, ADD_RULE_COMMAND, EDIT_RULE_COMMAND, DELETE_RULE_COMMAND } from './types';

export function* watchRuleCommands() {
  yield takeEvery(GET_RULE_COMMANDS, getRuleCommandsSaga);
  yield takeEvery(ADD_RULE_COMMAND, addRuleCommandSaga);
  yield takeEvery(EDIT_RULE_COMMAND, editRuleCommandSaga);
  yield takeEvery(DELETE_RULE_COMMAND, deleteRuleCommandSaga);
}

export function* getRuleCommandsSaga(params) {
  yield put(actions.getRuleCommandsStart());

  let api = 'v1/rulecommand';

  api += '?rule_id=' + params.rule_id;
  try {
    const response = yield axios.get(api);
    console.log("GET RULE_COMMAND SUCCESS", response.data);
    yield put(actions.getRuleCommandsSuccess(response.data));
  } catch (err) {
    console.log("GET RULE_COMMAND FAILED", err)
    yield put(actions.getRuleCommandsFail(err.response));
  }
}

export function* addRuleCommandSaga(params) {
  yield put(actions.addRuleCommandStart());

  const api = 'v1/rulecommand';

  let postData = "rule_id=" + params.rule_id;
  postData += "&device_secret=" + encodeURIComponent(params.device_secret);
  postData += "&device_socket=" + +params.device_socket;
  postData += "&value=" + parseFloat(params.value);
  
  try {
    const response = yield axios.post(api, postData);
    console.log("ADD RULE_COMMAND SUCCESS", response);
    yield put(actions.addRuleCommandSuccess(response.data));
  } catch (err) {
    console.log("ADD RULE_COMMAND FAILED", err.response.data);
    yield put(actions.addRuleCommandFail(err.response.data.error));
  }
}

export function* editRuleCommandSaga(params) {
  yield put(actions.editRuleCommandStart());

  let putData = "value=" + parseFloat(params.value);
  putData += params.device_secret ? "&device_secret=" + encodeURIComponent(params.device_secret) : '';
  putData += params.device_socket !== '' ? "&device_socket=" + +params.device_socket : '';

  const api = 'v1/rulecommand/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    console.log("EDIT RULE_COMMAND SUCCESS", response.data);
    yield put(actions.editRuleCommandSuccess(response.data));
  } catch (err) {
    console.log("EDIT RULE_COMMAND FAILED", err.response.data);
    yield put(actions.editRuleCommandFail(err.response.data));
  }
}

export function* deleteRuleCommandSaga(params) {
  yield put(actions.deleteRuleCommandStart());

  const api = 'v1/rulecommand/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE RULE_COMMAND SUCCESS", response.data);
    yield put(actions.deleteRuleCommandSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE RULE_COMMAND FAILED", err.response);
    yield put(actions.deleteRuleCommandFail(err.response));
  }
}