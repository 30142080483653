import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './SchedulerCalendar.module.scss';
import moment from 'moment';
import _ from 'lodash';

import FullCalendar from '@fullcalendar/react';
import rrulePlugin from '@fullcalendar/rrule'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

import { getMachines } from '../../../../state/ducks/Machine/actions';
import { getSchedulers } from '../../../../state/ducks/Scheduler/actions';

// let eventGuid = 0;
// export function createEventId() {
//   return String(eventGuid++)
// }

const contentStyles = {
  title: {
    width: '90%',
    paddingLeft: 5,
    fontWeight: '500',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }
}

function renderEventContent(eventInfo, offsetWidth, oneCellWidth) {
  const found = offsetWidth.find(ow => ow.instanceId === eventInfo.event._instance.instanceId && ow.defId === eventInfo.event._instance.defId);
  if (found) {
    const condt = eventInfo.isEnd && (!eventInfo.isStart || found.offsetWidth > (oneCellWidth * 1.5));
    return found && (
      <div className='where-am-i' style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        {eventInfo.event.allDay || eventInfo.view.type === 'listMonth' ? null : <div style={{ width: 8, height: 8, borderRadius: 5, marginLeft: 5, backgroundColor: `${eventInfo.borderColor}` }} />}
        <div style={{ flex: 1, maxWidth: condt ? 'none' : oneCellWidth - 48 }}><div style={contentStyles.title}>{eventInfo.event?.title}</div></div>
        {condt
          ? found.offsetWidth - oneCellWidth < 10
            ? null
            : <div className={"fc-custom-time-start"} style={{ opacity: 0.75, fontSize: 12, position: 'absolute', right: `${found.offsetWidth === 0 ? 0 : found.offsetWidth - oneCellWidth}px` }}>{moment(eventInfo.event.start).format('HH:mm')}</div>
          : <div className={"fc-custom-time-start"} style={{ opacity: 0.75, fontSize: 12, textAlign: 'right', width: 34.5 }}>{moment(eventInfo.event.start).format('HH:mm')}</div>
        }
        {condt ? <div className={"fc-custom-time-end"} style={{ opacity: 0.75, fontSize: 12 }}>ends {moment(eventInfo.event.end).format('HH:mm')}</div> : null}
      </div>
    );
  }
}

class SchedulerCalendar extends Component {
  calendarRef = React.createRef();
  eventsOffsetWidth = [];
  oneCellWidth = 0;
  state = {
    weekendsVisible: true,
    currentEvents: [],
    schedulers: [],
    firstSetEventsOnCalendar: false,
  };

  componentDidMount() {
    this.props.getMachines(this.props.currentOrg);
    const oneMonthAgo = moment().subtract(1, 'M').startOf('month').valueOf();
    const oneMonthAhead = moment().add(1, 'M').endOf('month').valueOf();
    this.props.getSchedulers(this.props.currentOrg, oneMonthAgo, oneMonthAhead);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentOrg !== prevProps.currentOrg && this.props.currentOrg) {
      this.props.getMachines(this.props.currentOrg);
      this.props.getSchedulers(this.props.currentOrg);
    } else if (prevProps.schedulers !== this.props.schedulers && this.props.schedulers) {
      // console.log('get.', this.props.schedulers)
      const schedulers = [];
      this.props.schedulers.forEach((pd, i) => {
        const isSameDay = moment(pd.start).isSame(pd.end, 'day');
        schedulers.push({
          id: pd.id,
          title: pd.title,
          backgroundColor: pd.type.color + (!isSameDay ? '66' : ''),
          borderColor: pd.type.color,
        });
        if (pd.frequency === 'none') {
          schedulers[i].start = pd.start;
          schedulers[i].end = pd.end;
        } else {
          const startDate = moment(pd.start).format('YYYY-MM-DD') + ' 00:00';
          const diffMs = moment(pd.end).valueOf() - moment(startDate).valueOf();
          schedulers[i].groupId = pd.id;
          schedulers[i].startTime = moment(pd.start).format('HH:mm');
          schedulers[i].endTime = { milliseconds: diffMs };
          if (pd.frequency === 'daily') {
            if (!pd.exceptions) {
              // if no exceptions, it can add in a normal way.
              schedulers[i].startRecur = pd.start;
              if (!this.isNoEnding(pd.end_repeat)) schedulers[i].endRecur = pd.end_repeat;
            } else {
              schedulers[i].rrule = { freq: 'daily', dtstart: pd.start };
              if (!this.isNoEnding(pd.end_repeat)) schedulers[i].rrule.until = pd.end_repeat;
            }
          } else {
            // LIST : weekly, monthly, yearly
            schedulers[i].rrule = { freq: pd.frequency, dtstart: pd.start };
            if (!this.isNoEnding(pd.end_repeat)) schedulers[i].rrule.until = pd.end_repeat;
            schedulers[i].duration = moment(pd.end).diff(moment(pd.start), 'milliseconds');
          }
          if (pd.exceptions) {
            const exdates = [];
            pd.exceptions.forEach(exc => {
              const t = moment(exc.start).format('YYYY-MM-DD') + ' ' + moment(pd.start).format('HH:mm');
              exdates.push(moment(t).format());
            });
            schedulers[i].exdate = exdates;
          }
        }
      });
      // console.log(schedulers)
      this.setState({ schedulers, firstSetEventsOnCalendar: true });
    } else if (!_.isEqual(prevProps.addedScheduler, this.props.addedScheduler) && this.props.addedScheduler) {
      this.props.getSchedulers(this.props.currentOrg);
      const { id, title, start, end, type, frequency, end_repeat } = this.props.addedScheduler;
      const isSameDay = moment(start).isSame(end, 'day');
      let calendarApi = this.calendarRef.current.getApi();
      let addingResult = {
        id,
        title,
        backgroundColor: type.color + (!isSameDay ? '66' : ''),
        borderColor: type.color,
      };
      if (frequency === 'none') {
        addingResult.start = start;
        addingResult.end = end;
      } else {
        const startDate = moment(start).format('YYYY-MM-DD') + ' 00:00';
        const diffMs = moment(end).valueOf() - moment(startDate).valueOf();
        addingResult.groupId = id;
        addingResult.startTime = moment(start).format('HH:mm');
        addingResult.endTime = { milliseconds: diffMs };
        if (frequency === 'daily') {
          addingResult.startRecur = start;
          if (!this.isNoEnding(end_repeat)) {
            addingResult.endRecur = end_repeat;
          }
        } else {
          // ADD : weekly, monthly, yearly
          addingResult.rrule = { freq: frequency, dtstart: start };
          addingResult.duration = moment(end).diff(moment(start), 'milliseconds');;
          if (!this.isNoEnding(end_repeat)) {
            addingResult.rrule.until = end_repeat;
          }
        }
      }
      calendarApi.addEvent(addingResult);
    } else if (!_.isEqual(prevProps.updatedSchedulers, this.props.updatedSchedulers) && this.props.updatedSchedulers && this.props.updatedSchedulers.length) {
      let calendarApi = this.calendarRef.current.getApi();
      let foundTheEditedOneIndex = this.props.updatedSchedulers.findIndex(us => us.properties && us.properties.fromId);
      foundTheEditedOneIndex = foundTheEditedOneIndex === -1 ? 0 : foundTheEditedOneIndex;
      const foundTheEditedOne = this.props.updatedSchedulers[foundTheEditedOneIndex];
      // console.log("updated:", this.props.updatedSchedulers, foundTheEditedOneIndex)

      // if the result has fromId : case edit all_future OR only this event.
      if (foundTheEditedOne.properties && foundTheEditedOne.properties.fromId) {
        const foundTheOldOne = this.state.currentEvents.find(ce => ce.id === foundTheEditedOne.properties.fromId);
        if (foundTheOldOne) foundTheOldOne.remove();

        // there are 2 ways to create the old one with new end_repeat => 
        // 1. get a result w/o props.fromId and use from there 
        // 2. find this.state.schedulers with same fromId but need to change id the new one (probably results[1])
        let addingOne = this.state.schedulers.find(sch => sch.id === foundTheEditedOne.properties.fromId);
        const thisScheduler = this.props.schedulers.find(schp => schp.id === foundTheEditedOne.properties.fromId);
        const theOtherIndexFromResults = foundTheEditedOneIndex === 0 ? 1 : 0;
        // console.log("the other one : ", theOtherIndexFromResults, this.props.updatedSchedulers[theOtherIndexFromResults].id)
        if (foundTheEditedOne.properties.all_future) {
          // case: edit ALL FUTURE and result => change end_repeat of the editing one
          let addingOneWithChangedEndRepeat = {
            id: this.props.updatedSchedulers[theOtherIndexFromResults].id,
            title: addingOne.title,
            backgroundColor: addingOne.backgroundColor,
            borderColor: addingOne.borderColor,
          };
          const startDate = moment(addingOne.start).format('YYYY-MM-DD') + ' 00:00';
          const diffMs = moment(addingOne.end).valueOf() - moment(startDate).valueOf();
          addingOneWithChangedEndRepeat.groupId = this.props.updatedSchedulers[theOtherIndexFromResults].id;
          addingOneWithChangedEndRepeat.startTime = moment(addingOne.start).format('HH:mm');
          addingOneWithChangedEndRepeat.endTime = { milliseconds: diffMs };
          let t = moment(foundTheEditedOne.start).format('YYYY-MM-DD') + ' ' + moment(thisScheduler.start).format('HH:mm');
          if (thisScheduler.frequency === 'daily') {
            t = moment(t).subtract(1, 'd');
          } else if (thisScheduler.frequency === 'weekly') {
            t = moment(t).subtract(1, 'w');
          } else if (thisScheduler.frequency === 'monthly') {
            t = moment(t).subtract(1, 'M');
          } else if (thisScheduler.frequency === 'yearly') {
            t = moment(t).subtract(1, 'y');
          }
          addingOneWithChangedEndRepeat.rrule = {
            freq: thisScheduler.frequency,
            dtstart: thisScheduler.start,
            until: t.format()
          }
          addingOneWithChangedEndRepeat.duration = moment(addingOne.end).diff(moment(addingOne.start), 'milliseconds');;
          // console.log('addingOneWithChangedEndRepeat :', addingOneWithChangedEndRepeat)
          calendarApi.addEvent(addingOneWithChangedEndRepeat);
        } else {
          // case: edit ONLY THIS EVENT and result => new meta, so we need to update the editing one with exceptions
          let addingOneWithException = {
            id: this.props.updatedSchedulers[theOtherIndexFromResults].id,
            title: addingOne.title,
            backgroundColor: addingOne.backgroundColor,
            borderColor: addingOne.borderColor,
          }
          const startDate = moment(addingOne.start).format('YYYY-MM-DD') + ' 00:00';
          const diffMs = moment(addingOne.end).valueOf() - moment(startDate).valueOf();
          addingOneWithException.groupId = this.props.updatedSchedulers[theOtherIndexFromResults].id;
          addingOneWithException.startTime = moment(addingOne.start).format('HH:mm');
          addingOneWithException.endTime = { milliseconds: diffMs };
          addingOneWithException.rrule = {
            freq: thisScheduler.frequency,
            dtstart: thisScheduler.start,
            until: thisScheduler.end_repeat
          }
          addingOneWithException.duration = moment(addingOne.end).diff(moment(addingOne.start), 'milliseconds');;
          const exdates = addingOne.exceptions ? [...addingOne.exceptions] : [];
          const t = moment(foundTheEditedOne.start).format('YYYY-MM-DD') + ' ' + moment(thisScheduler.start).format('HH:mm');
          exdates.push(moment(t).format());
          addingOneWithException.exdate = exdates;

          calendarApi.addEvent(addingOneWithException);
        }
      }

      // case Edit == remove the existing one and add new one instead, couldn't find the way to update recurring values
      const { id, title, start, end, type, frequency, end_repeat } = foundTheEditedOne;
      const foundUpdated = this.state.currentEvents.find(ce => ce.id === foundTheEditedOne.id);
      if (foundUpdated) foundUpdated.remove();
      const isSameDay = moment(start).isSame(end, 'day');
      let addingInstead = {
        id,
        title,
        backgroundColor: type.color + (!isSameDay ? '66' : ''),
        borderColor: type.color,
      };
      if (frequency === 'none') {
        addingInstead.start = start;
        addingInstead.end = end;
      } else {
        const startDate = moment(start).format('YYYY-MM-DD') + ' 00:00';
        const diffMs = moment(end).valueOf() - moment(startDate).valueOf();
        addingInstead.groupId = id;
        addingInstead.startTime = moment(start).format('HH:mm');
        addingInstead.endTime = { milliseconds: diffMs };
        if (frequency === 'daily') {
          addingInstead.startRecur = start;
          if (!this.isNoEnding(end_repeat)) {
            addingInstead.endRecur = end_repeat;
          }
        } else {
          addingInstead.rrule = { freq: frequency, dtstart: start };
          addingInstead.duration = moment(end).diff(moment(start), 'milliseconds');;
          if (!this.isNoEnding(end_repeat)) {
            addingInstead.rrule.until = end_repeat;
          }
        }
      }
      // console.log('addingInstead ', addingInstead)
      calendarApi.addEvent(addingInstead);
      this.props.getSchedulers(this.props.currentOrg);
      // this.setState({ currentEvents });
    } else if (!_.isEqual(prevProps.deletedSchedulerProperties, this.props.deletedSchedulerProperties) && this.props.deletedSchedulerProperties) {
      const { scheduler, all_future, startDate, startTime } = this.props.deletedSchedulerProperties;
      // console.log(this.props.deletedSchedulerProperties)
      const foundDeleted = this.state.currentEvents.find(ce => ce.id === scheduler.id);
      foundDeleted.remove();

      const { id, title, start, end, type, frequency, end_repeat, exceptions } = this.props.deletedSchedulerProperties.scheduler;
      let calendarApi = this.calendarRef.current.getApi();
      if (all_future) {
        // delete the exiting one and then add the old one with new end_repeat
        const isSameDay = moment(start).isSame(end, 'day');
        const addingNew = {
          id,
          title,
          backgroundColor: type.color + (!isSameDay ? '66' : ''),
          borderColor: type.color,
        };
        if (frequency === 'none') {
          addingNew.start = start;
          addingNew.end = end;
        } else {
          const sd = moment(start).format('YYYY-MM-DD') + ' 00:00';
          const diffMs = moment(end).valueOf() - moment(sd).valueOf();
          addingNew.groupId = id;
          addingNew.startTime = moment(start).format('HH:mm');
          addingNew.endTime = { milliseconds: diffMs };
          if (frequency === 'daily') {
            const t = moment(startDate + ' ' + startTime);
            t.subtract(1, 'd');
            addingNew.startRecur = start;
            addingNew.endRecur = t.format();
          } else {
            const t = moment(startDate + ' ' + startTime);
            if (frequency === 'weekly') {
              t.subtract(1, 'w');
            } else if (frequency === 'monthly') {
              t.subtract(1, 'M');
            } else if (frequency === 'yearly') {
              t.subtract(1, 'y');
            }
            addingNew.rrule = {
              freq: frequency,
              dtstart: start,
              until: t.format()
            }
            addingNew.duration = moment(end).diff(moment(start), 'milliseconds');;
          }
        }
        calendarApi.addEvent(addingNew);
      } else {
        // delete the exiting one and then add the old one with exception
        if (startDate && startTime) {
          const isSameDay = moment(start).isSame(end, 'day');
          const addingNewWithException = {
            id,
            title,
            backgroundColor: type.color + (!isSameDay ? '66' : ''),
            borderColor: type.color,
          };
          if (frequency === 'none') {
            addingNewWithException.start = start;
            addingNewWithException.end = end;
          } else {
            const sd = moment(start).format('YYYY-MM-DD') + ' 00:00';
            const diffMs = moment(end).valueOf() - moment(sd).valueOf();
            addingNewWithException.groupId = id;
            addingNewWithException.startTime = moment(start).format('HH:mm');
            addingNewWithException.endTime = { milliseconds: diffMs };
            addingNewWithException.rrule = {
              freq: frequency,
              dtstart: start,
              until: end_repeat
            }
            addingNewWithException.duration = moment(end).diff(moment(start), 'milliseconds');;

            const exdates = exceptions ? [...exceptions] : [];
            const t = moment(startDate + ' ' + startTime);
            exdates.push(moment(t).format());
            addingNewWithException.exdate = exdates;
          }
          calendarApi.addEvent(addingNewWithException);
        }
      }
      this.props.getSchedulers(this.props.currentOrg);
    }
  }

  isNoEnding = (time) => {
    return time === "0001-01-01T00:00:00Z" || time === "1970-01-01T07:00:00+07:00" || time === "1970-01-01T00:00:00Z";
  }

  handleDateSelect = (selectInfo) => {
    // console.log('handleDateSelect : ', selectInfo)
  }

  handleEventClick = (clickInfo) => {
    // console.log(clickInfo.event.id,this.props.schedulers, this.state.currentEvents)
    const foundScheduler = this.props.schedulers.find(ce => ce.id === clickInfo.event.id);
    this.props.eventClicked(foundScheduler, clickInfo.event);
  }

  handleEvents = (events) => {
    this.setState({ currentEvents: events });
  }

  onEventsDidMount = (e) => {
    const start = moment(e.event.start).startOf('day');
    const end = moment(e.event.end).startOf('day');
    const diffDays = end.diff(start, 'days');
    const isSameDay = moment(e.event.start).isSame(e.event.end, 'day');
    const existing = this.eventsOffsetWidth.findIndex(ev => ev.instanceId === e.event._instance.instanceId);
    const result = {
      instanceId: e.event._instance.instanceId,
      defId: e.event._instance.defId,
      offsetWidth: !isSameDay ? e.el.offsetWidth : 0
    };
    if (existing === -1) {
      this.eventsOffsetWidth.push(result);
    } else {
      this.eventsOffsetWidth[existing] = result;
    }
    // console.log(e.event._instance.instanceId, this.eventsOffsetWidth.length)
    if (isSameDay) {
      this.oneCellWidth = e.el.offsetWidth;
    } else {
      if (this.oneCellWidth === 0) this.oneCellWidth = e.el.offsetWidth / (diffDays + 1);
    }
  }

  clickCustom = (e) => {
    let calendarApi = this.calendarRef.current.getApi();
    this.eventsOffsetWidth = [];
    calendarApi.next();
    // this.props.getSchedulers(this.props.currentOrg, 1672542000000, 1675047600000);
  }

  render() {
    return this.state.firstSetEventsOnCalendar && (
      <div className={styles.SchedulerCalendar}>
        {/* {this.renderSidebar()} */}
        <FullCalendar
          ref={this.calendarRef}
          plugins={[rrulePlugin, dayGridPlugin, timeGridPlugin, listPlugin]}
          // customButtons={{
          //   myCustomButton: {
          //     text: 'next year',
          //     click: (e) => this.clickCustom(e),
          //     icon: 'chevrons-right'
          //   },
          // }}
          headerToolbar={{
            left: 'prevYear,prev,next,nextYear today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek,dayGridDay,listMonth'
          }}
          initialView='dayGridMonth'
          // editable={true}
          // selectable={true}
          // selectMirror={true}
          // dayMaxEvents={true}
          weekends={this.state.weekendsVisible}
          initialEvents={this.state.schedulers} // alternatively, use the `events` setting to fetch from a feed
          select={this.handleDateSelect}
          // eventContent={(e) => renderEventContent(e, this.eventsOffsetWidth, this.oneCellWidth)} // custom render function
          eventClick={this.handleEventClick}
          eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
          eventDidMount={(e) => this.onEventsDidMount(e)}

        /* you can update a remote database when these fire:
        eventAdd={function(){}}
        eventChange={function(){}}
        eventRemove={function(){}}
        
        */
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { machines } = state.machine;
  const { schedulers, addedScheduler, updatedSchedulers, deletedSchedulerProperties } = state.scheduler;

  return { currentOrg, machines, schedulers, addedScheduler, updatedSchedulers, deletedSchedulerProperties };
};

export default connect(mapStateToProps, { getMachines, getSchedulers })(SchedulerCalendar);
