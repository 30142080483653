
import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './SelectMachineDropdown.module.scss';
import Backdrop from '../../UI/Backdrop/NoColorBackdrop';
import RadioUnselectIcon from '../../../../assets/icons/radio-unselect.svg';
import RadioSelectIcon from '../../../../assets/icons/radio-select.svg';
import FilterGrayIcon from '../../../../assets/icons/filter-gray.svg';

import { setDefaultOperatorMachine } from '../../../../state/ducks/Machine/actions';

class SelectMachineDropdown extends Component {
  state = {
    show: false,
    selectMachineIndex: -1
  }

  componentDidMount() {
    if (this.props.machines) {
      this.findSelectedMachine();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.machines !== prevProps.machines && this.props.machines) {
      this.findSelectedMachine();
    }
  }

  findSelectedMachine = () => {
    const found = localStorage.getItem("OwlOperatorDefaultMachine");
    if (found) {
      const defaultMachine = JSON.parse(found);
      this.setState({ selectMachineIndex: this.props.machines.sort((a, b) => a.name.localeCompare(b.name)).findIndex(m => defaultMachine[this.props.currentOrg] && m.id === defaultMachine[this.props.currentOrg].id) });
    }
  }

  openDropdown = () => {
    this.setState({ show: true });
  }

  handleChange = (index) => {
    const sortedMachines = this.props.machines.sort((a, b) => a.name.localeCompare(b.name));
    const selectedMachine = index >= 0 ? sortedMachines[index] : '';
    const updatedDefaultMachine = index >= 0 ? JSON.stringify({ [this.props.currentOrg]: selectedMachine }) : JSON.stringify({ [this.props.currentOrg]: '' });
    this.props.setDefaultOperatorMachine(updatedDefaultMachine);
    this.setState({ selectMachineIndex: index >= 0 ? sortedMachines.findIndex(sm => sm.id === selectedMachine.id) : -1, show: false });
  }

  render() {
    return (
      <div className={styles.DropdownWrapper}>
        <div className={styles.Selected} onClick={this.openDropdown}>
          {!this.props.loading && <img src={FilterGrayIcon} className={styles.FilterIcon} />}
          {
            !this.props.loading ?
              this.props.machines && this.props.machines.length
                ? this.state.selectMachineIndex >= 0
                  ? this.props.machines[this.state.selectMachineIndex]?.name
                  : 'All machines'
                : 'No machines found'
              : 'Loading...'
          }
        </div>
        <Backdrop show={this.state.show} clicked={() => this.setState({ show: false })} />
        {
          this.state.show && this.props.machines && this.props.machines.length ?
            <div className={styles.DropdownMenu}>
              <div className={styles.RadioField}>
                <label htmlFor="all" className={styles.List}>
                  <div className={styles.RadioWrapper}>
                    <img src={this.state.selectMachineIndex === -1 ? RadioSelectIcon : RadioUnselectIcon} alt="Radio Icon" className={styles.RadioIcon} />
                    <input id="all" type="radio" checked={this.state.selectMachineIndex === -1} onChange={() => this.handleChange(-1)} />
                    <label htmlFor="all" className={styles.RadioLabel}>All machines</label>
                  </div>
                </label>
                {
                  this.props.machines.sort((a, b) => a.name.localeCompare(b.name)).map((machine, index) => (
                    <div htmlFor={`MACHINE-RADIO-${machine.id}`} className={styles.List} key={`MACHINE-KEY-${index}`}>
                      <div className={styles.RadioWrapper}>
                        <img src={this.state.selectMachineIndex === index ? RadioSelectIcon : RadioUnselectIcon} alt="Radio Icon" className={styles.RadioIcon} />
                        <input id={`MACHINE-RADIO-${machine.id}`} type="radio" value={machine.id} checked={this.state.selectMachineIndex === index} onChange={() => this.handleChange(index)} />
                        <label htmlFor={`MACHINE-RADIO-${machine.id}`} className={styles.RadioLabel}>{machine.name}</label>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div> : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { loading, defaultOperatorMachine } = state.machine;
  return { currentOrg, machines: state.machine.machinesAndActuals, loading, defaultOperatorMachine };
};

export default connect(mapStateToProps, { setDefaultOperatorMachine })(SelectMachineDropdown);
