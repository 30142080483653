import React from 'react';

import styles from './BarChart.module.scss';

class BarChart extends React.Component {
    render() {
        const { value, valueLabelColor, height, color, overrideStyle, label, labelColor, showNumber } = this.props;

        let barStyle = {
            backgroundColor: color ? color : '#1665D8',
            width: value > 100 ? '100%' : (value < 0 ? '0' : value+'%'),
            height: height ? height : '30px',
        }

        return (
            <div className={styles.BarChart}>
                {
                    label && <div className={styles.Label} style={labelColor ? {color: labelColor} : {}}>{label}</div>
                }
                <div className={styles.BarWarpper} style={overrideStyle}>
                    <div className={styles.Bar} style={barStyle}></div>  
                    {
                        showNumber && <div className={styles.ValueLabel} style={valueLabelColor ? {color: valueLabelColor} : {}}>{value % 1 > 0 ? value.toFixed(2) : value}%</div>
                    }  
                </div>
            </div>
        )
    }
}

export default BarChart;