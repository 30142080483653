export const GET_MACHINES_START = 'GET_MACHINES_START';
export const GET_MACHINES = 'GET_MACHINES';
export const GET_MACHINES_SUCCESS = 'GET_MACHINES_SUCCESS';
export const GET_MACHINES_FAIL = 'GET_MACHINES_FAIL';

export const GET_MACHINES_AND_ACTUALS_START = 'GET_MACHINES_AND_ACTUALS_START';
export const GET_MACHINES_AND_ACTUALS = 'GET_MACHINES_AND_ACTUALS';
export const GET_MACHINES_AND_ACTUALS_SUCCESS = 'GET_MACHINES_AND_ACTUALS_SUCCESS';
export const GET_MACHINES_AND_ACTUALS_FAIL = 'GET_MACHINES_AND_ACTUALS_FAIL';

export const ADD_MACHINE_START = 'ADD_MACHINE_START';
export const ADD_MACHINE = 'ADD_MACHINE';
export const ADD_MACHINE_SUCCESS = 'ADD_MACHINE_SUCCESS';
export const ADD_MACHINE_FAIL = 'ADD_MACHINE_FAIL';

export const EDIT_MACHINE_START = 'EDIT_MACHINE_START';
export const EDIT_MACHINE = 'EDIT_MACHINE';
export const EDIT_MACHINE_SUCCESS = 'EDIT_MACHINE_SUCCESS';
export const EDIT_MACHINE_FAIL = 'EDIT_MACHINE_FAIL';

export const DELETE_MACHINE_START = 'DELETE_MACHINE_START';
export const DELETE_MACHINE = 'DELETE_MACHINE';
export const DELETE_MACHINE_SUCCESS = 'DELETE_MACHINE_SUCCESS';
export const DELETE_MACHINE_FAIL = 'DELETE_MACHINE_FAIL';

export const SET_DEFAULT_OPERATOR_MACHINE = 'SET_DEFAULT_OPERATOR_MACHINE';

export const GET_APQ_OF_MACHINE_START = 'GET_APQ_OF_MACHINE_START';
export const GET_APQ_OF_MACHINE = 'GET_APQ_OF_MACHINE';
export const GET_APQ_OF_MACHINE_SUCCESS = 'GET_APQ_OF_MACHINE_SUCCESS';
export const GET_APQ_OF_MACHINE_FAIL = 'GET_APQ_OF_MACHINE_FAIL';