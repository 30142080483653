
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_MACHINES, ADD_MACHINE, EDIT_MACHINE, DELETE_MACHINE, GET_MACHINES_AND_ACTUALS, GET_APQ_OF_MACHINE } from './types';

export function* watchMachine() {
  yield takeEvery(GET_MACHINES, getMachinesSaga);
  yield takeEvery(GET_MACHINES_AND_ACTUALS, getMachinesAndActualsSaga);
  yield takeEvery(ADD_MACHINE, addMachineSaga);
  yield takeEvery(EDIT_MACHINE, editMachineSaga);
  yield takeEvery(DELETE_MACHINE, deleteMachineSaga);
  yield takeEvery(GET_APQ_OF_MACHINE, getApqOfMachineSaga);
}

export function* getMachinesSaga(params) {
  yield put(actions.getMachinesStart());

  let api = 'v1/machine';
  api += '?org_id=' + params.org_id;

  try {
    const response = yield axios.get(api);
    // console.log("GET MACHINES SUCCESS", response.data);
    yield put(actions.getMachinesSuccess(response.data));
  } catch (err) {
    console.log("GET MACHINES FAILED", err)
    yield put(actions.getMachinesFail(err.response));
  }
}

export function* getApqOfMachineSaga(params) {
  yield put(actions.getApqOfMachineStart());

  let api = 'v1/machine/' + params.id + '/apq';
  api += '?start=' + params.start;
  api += '&end=' + params.end;

  try {
    const response = yield axios.get(api);
    // console.log("GET GET_APQ_OF_MACHINE SUCCESS", response.data);
    yield put(actions.getApqOfMachineSuccess(response.data, params.properties));
  } catch (err) {
    // console.log("GET GET_APQ_OF_MACHINE FAILED", err)
    yield put(actions.getApqOfMachineFail(err.response));
  }
}

export function* getMachinesAndActualsSaga(params) {
  yield put(actions.getMachinesAndActualsStart());

  let api = 'v1/machinewithactual';
  api += '?org_id=' + params.org_id;

  try {
    const response = yield axios.get(api);
    yield put(actions.getMachinesAndActualsSuccess(response.data));
  } catch (err) {
    console.log("GET MACHINES FAILED", err)
    yield put(actions.getMachinesAndActualsFail(err.response));
  }
}

export function* addMachineSaga(params) {
  yield put(actions.addMachineStart());

  let postData = 'name=' + encodeURIComponent(params.name);
  postData += "&org_id=" + params.org_id;
  postData += "&status_device_secret=" + encodeURIComponent(params.status_device_secret);
  postData += "&status_device_socket=" + params.status_device_socket;
  postData += "&status_rules=" + encodeURIComponent(JSON.stringify(params.status_rules));
  postData += params.total_device_secret ? "&total_device_secret=" + params.total_device_secret : '';
  postData += params.total_device_socket ? "&total_device_socket=" + params.total_device_socket : '';
  postData += params.good_device_secret ? "&good_device_secret=" + params.good_device_secret : '';
  postData += params.good_device_socket ? "&good_device_socket=" + params.good_device_socket : '';
  postData += params.defect_device_secret ? "&defect_device_secret=" + params.defect_device_secret : '';
  postData += params.defect_device_socket ? "&defect_device_socket=" + params.defect_device_socket : '';
  postData += "&properties=" + encodeURIComponent(JSON.stringify(params.properties));

  const api = 'v1/machine';

  try {
    const response = yield axios.post(api, postData);
    console.log("ADD MACHINE SUCCESS", response);
    yield put(actions.addMachineSuccess(response.data));
  } catch (err) {
    console.log("ADD MACHINE FAILED", err.response.data);
    yield put(actions.addMachineFail(err.response.data.error));
  }
}

export function* editMachineSaga(params) {
  yield put(actions.editMachineStart());

  let putData = params.name ? 'name=' + encodeURIComponent(params.name) : '';
  putData += params.status_device_secret ? '&status_device_secret=' + encodeURIComponent(params.status_device_secret) : '';
  putData += params.status_device_socket ? '&status_device_socket=' + params.status_device_socket : '';
  putData += params.status_rules ? '&status_rules=' + encodeURIComponent(JSON.stringify(params.status_rules)) : '';
  putData += params.total_device_secret ? "&total_device_secret=" + params.total_device_secret : '';
  putData += params.total_device_socket ? "&total_device_socket=" + params.total_device_socket : '';
  putData += params.good_device_secret ? "&good_device_secret=" + params.good_device_secret : '';
  putData += params.good_device_socket ? "&good_device_socket=" + params.good_device_socket : '';
  putData += params.defect_device_secret ? "&defect_device_secret=" + params.defect_device_secret : '';
  putData += params.defect_device_socket ? "&defect_device_socket=" + params.defect_device_socket : '';
  putData += params.properties ? '&properties=' + encodeURIComponent(JSON.stringify(params.properties)) : '';

  const api = 'v1/machine/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT MACHINE SUCCESS", response.data);
    yield put(actions.editMachineSuccess(response.data));
  } catch (err) {
    console.log("EDIT MACHINE FAILED", err.response.data);
    yield put(actions.editMachineFail(err.response.data));
  }
}

export function* deleteMachineSaga(params) {
  yield put(actions.deleteMachineStart());

  const api = 'v1/machine/' + params.id;

  try {
    const response = yield axios.delete(api);
    console.log("DELETE Machine SUCCESS", response.data);
    yield put(actions.deleteMachineSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE Machine FAILED", err.response);
    yield put(actions.deleteMachineFail(err.response));
  }
}