import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { updateObject, checkValidity } from '../../../../state/utils';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import Copy from '../../../../assets/icons/copy-to-clipboard.svg';

import { editDevice } from '../../../../state/ducks/Devices/actions';

const DEFAULT_DEVICE_FORM = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      required: true
    }
  },
  description: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  },
  tag: {
    value: '',
    valid: true,
    touched: true,
    validation: {
      required: false
    }
  }
};

class EditDeviceDrawer extends Component {
  state = {
    formIsValid: false,
    deviceForm: DEFAULT_DEVICE_FORM,
    secret: '',
    api_token: ''
  }

  componentDidMount() {
    if (this.props.editingDevice) {
      this.setupForm(this.props.editingDevice);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.editingDevice && prevProps.editingDevice !== this.props.editingDevice) {
      this.setupForm(this.props.editingDevice);
    } else if (prevProps.updatedDevice !== this.props.updatedDevice) {
      this.props.closed();
    }
  }

  onClearFields = () => {
    this.setState({ deviceForm: DEFAULT_DEVICE_FORM, formIsValid: false });
  }

  setupForm = (form) => {
    let updatedForm = { ...this.state.deviceForm };
    for (let inputIdentifier in updatedForm) {
      const updatedElement = updateObject(updatedForm[inputIdentifier], {
        value: form[inputIdentifier] ? form[inputIdentifier] : '',
        valid: true,
        touched: true
      });
      updatedForm = updateObject(updatedForm, {
        [inputIdentifier]: updatedElement
      });
    }
    this.setState({ deviceForm: updatedForm, formIsValid: true, secret: form.secret, api_token: form.api_token });

  }

  handleTextChange = event => {
    let updatedElement = updateObject(this.state.deviceForm[event.target.name], {
      value: event.target.value,
      valid: checkValidity(event.target.value, this.state.deviceForm[event.target.name].validation),
      touched: true
    });

    const updatedForm = updateObject(this.state.deviceForm, {
      [event.target.name]: updatedElement
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({ deviceForm: updatedForm, formIsValid });
  };

  onSaveDeviceHandler = (event) => {
    event.preventDefault();
    this.props.editDevice(
      this.props.editingDevice.id,
      this.state.deviceForm.name.value,
      this.state.deviceForm.description.value,
      this.state.deviceForm.tag.value
    );
  }

  render() {
    const { name, description, tag } = this.state.deviceForm;
    return (
      <div>
        <form onSubmit={this.onSaveDeviceHandler}>
          <Input
            label="device name"
            name="name"
            type="text"
            value={name.value}
            placeholder="Please insert your device name"
            autoComplete="off"
            onChange={this.handleTextChange}
            error={`${name.touched && !name.valid ? 'device Name is required' : ''}`}
            required
          />
          {
            this.props.editingDevice && !this.props.editingDevice.system_device ?
              <>
                <div style={{ position: 'relative' }}>
                  <Input
                    disabled
                    label="secret"
                    name="secret"
                    type="text"
                    value={this.state.secret}
                  />
                  <CopyToClipboard text={this.props.editingDevice.secret}>
                    <img src={Copy} alt="copy to clipboard" style={{ position: 'absolute', right: 16, top: 38, cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>
                <div style={{ position: 'relative' }}>
                  <Input
                    disabled
                    label="api token"
                    name="api_token"
                    type="text"
                    value={this.state.api_token}
                  />
                  <CopyToClipboard text={this.props.editingDevice.api_token}>
                    <img src={Copy} alt="copy to clipboard" style={{ position: 'absolute', right: 16, top: 38, cursor: 'pointer' }} />
                  </CopyToClipboard>
                </div>
              </>
              : null
          }
          <Input
            label="description"
            name="description"
            value={description.value}
            type="textarea"
            placeholder="What is this device about? Tell people more juicy details."
            onChange={this.handleTextChange}
            rows="3"
          />
          <Input
            label="tag"
            name="tag"
            value={tag.value}
            type="text"
            placeholder="Tag1,Tag2,..."
            autoComplete="off"
            onChange={this.handleTextChange}
          />
          <div style={{ height: 70, width: 'auto' }} />
          <Button
            type="submit"
            name={this.props.loading ? 'Loading...' : 'Save'}
            color="green"
            disabled={!this.state.formIsValid}
            loading={this.props.loading}
          />
          <Button type="button" name="Delete" color="borderred" click={this.props.toggleDeleteModal} />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { updatedDevice } = state.devices;
  return { updatedDevice };
};

export default connect(mapStateToProps, { editDevice })(EditDeviceDrawer);