import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import styles from './Reports.module.scss';
import DailyEnergyBar from '../DailyEnergyBar/DailyEnergyBar';
import UsageEnergyLine from '../UsageEnergyLine/UsageEnergyLine';

class DailyReport extends Component {
    evaRef = React.createRef();
    childEvaRef = React.createRef();
    state = {}

    componentDidMount() {
        // console.log(this.props.childsOption[0])
        // if (this.props.childsOption[0]) {
        //     this.props.childsOption[0].forEach
        // }
    }

    componentDidUpdate(prevProps, prevState) { }

    checkTags = (machine) => {
        // console.log(machine.label)
        // if (this.props.devices) {
        //     if (machine.children && machine.children.length) {
        //         machine.children.forEach(mc => {
        //             const foundEdge = this.props.devices.find(d => d.secret === mc.secret);
        //             const foundDevice = foundEdge.device_sockets.find(fe => fe.socket === mc.socket);
        //             // console.log(foundDevice)
        //         });
        //     }
        // }
    }

    render() {

        return (
            <>
                <div ref={this.props.coverReportRef} style={{ width: 1500 }}>
                    <div className={styles.ReportWrapper} style={{ width: 'auto' }}>
                        <div className={styles.HeaderSection}>
                            <div className={styles.HeaderText}>Daily Report - {this.props.isStandardForm ? 'Standard Form' : 'Env. Form'}</div>
                            <div className={styles.DateText}>{this.props.selectedRelation?.label} - {moment(this.props.selectedDate).format('DD MMMM YYYY')}</div>
                        </div>
                        <DailyEnergyBar
                            forReport isParent
                            allRawDataset={this.props.allRawDataset}
                            amountLeaves={this.props.amountLeaves - 1}
                            machines={this.props.machines}
                            selectedRelation={this.props.selectedRelation}
                            selectedChild={this.props.selectedChild}
                            queryPeriod={this.props.queryPeriod}
                            selectedDate={this.props.selectedDate}
                            isEfficiency={this.props.isEfficiency}
                            downloadData={this.props.downloadData}
                            setEvaTimes={this.props.setEvaTimes}
                        />
                        <UsageEnergyLine
                            forReport isParent
                            allRawDataset={this.props.allRawDataset}
                            amountLeaves={this.props.amountLeaves - 1}
                            selectedChild={this.props.selectedChild}
                            selectedRelation={this.props.selectedRelation}
                            queryPeriod={this.props.queryPeriod}
                            selectedDate={this.props.selectedDate}
                        />
                        <div className={styles.Eva}>
                            <div className={styles.SubHeader}>EVA</div>
                            <div className={styles.BarWrapper}>
                                <div ref={this.evaRef} style={{ position: 'relative' }}>
                                    {
                                        this.props.parentEvaTimes && this.props.parentEvaTimes.length && this.evaRef.current
                                            ? this.props.parentEvaTimes.map(eva => eva.amount && (
                                                <div key={`parent-${eva.start}`} className={styles.NonProdTimeWrapper} style={{ left: (eva.index / 10) * this.evaRef.current?.offsetWidth / 144 }}>
                                                    <div className={styles.EachBar} style={{ width: this.evaRef.current ? (eva.amount * 100 / 144) * this.evaRef.current?.offsetWidth / 100 : 0 }}></div>
                                                    <div className={styles.TimeText}>{moment(eva.start).format('HH:mm')} - {moment(eva.start).add(eva.amount * 10, 'minutes').format('HH:mm')}</div>
                                                </div>
                                            ))
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.props.childsOption[0] && this.props.childsOption[0].length
                        ? this.props.childsOption[0].map((co, i) => (
                            <div className={styles.ReportWrapper} key={`childs-${co.label}`} ref={this.props.nextPagesRef[i]}>
                                <div className={styles.LeftRightWrapper}>
                                    <div className={styles.LeftSection}>
                                        <DailyEnergyBar
                                            forReport isChildren
                                            childIndex={i}
                                            allRawDataset={this.props.allRawDataset}
                                            amountLeaves={this.props.amountLeaves - 1}
                                            machines={this.props.machines}
                                            selectedRelation={this.props.selectedRelation}
                                            selectedChild={this.props.childsOption[0] ? [this.props.childsOption[0][i]] : []}
                                            queryPeriod={this.props.queryPeriod}
                                            selectedDate={this.props.selectedDate}
                                            isEfficiency={this.props.isEfficiency}
                                            downloadData={this.props.downloadData}
                                            setEvaTimes={this.props.setEvaTimes}
                                        />
                                    </div>
                                    <div className={styles.RightSection}>
                                        <div className={styles.DateText}>{this.props.selectedRelation?.label} - {moment(this.props.selectedDate).format('DD MMMM YYYY')}</div>
                                    </div>
                                </div>
                                <div className={styles.LeftRightWrapper}>
                                    <div className={styles.LeftSection}>
                                        <UsageEnergyLine
                                            forReport isChildren
                                            childIndex={i}
                                            allRawDataset={this.props.allRawDataset}
                                            amountLeaves={this.props.amountLeaves - 1}
                                            selectedRelation={this.props.selectedRelation}
                                            selectedChild={this.props.childsOption[0] ? [this.props.childsOption[0][i]] : []}
                                            queryPeriod={this.props.queryPeriod}
                                            selectedDate={this.props.selectedDate}
                                        />
                                    </div>
                                    <div className={styles.RightSection}>
                                        <div className={styles.TagWrapper}>
                                            <div className={styles.TopSection}>
                                                <div className={styles.TagTitle}>Holiday</div>
                                                <div className={styles.TagName}>Tag A</div>
                                            </div>
                                            <div className={styles.DeviceList}>
                                                {/* {this.checkTags(this.props.childsOption[0][i])} */}
                                            </div>
                                        </div>
                                        <div className={styles.TagWrapper}>
                                            <div className={styles.TopSection}>
                                                <div className={styles.TagTitle}>Holiday</div>
                                                <div className={styles.TagName}>Tag B</div>
                                            </div>
                                            <div className={styles.DeviceList}></div>
                                        </div>
                                        <div className={styles.TagWrapper}>
                                            <div className={styles.TopSection}>
                                                <div className={styles.TagTitle}>Break 1 hr. & Shift change</div>
                                                <div className={styles.TagName}>Tag C</div>
                                            </div>
                                            <div className={styles.DeviceList}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.LeftRightWrapper}>
                                    <div className={styles.LeftSection}>
                                        <div className={styles.Eva}>
                                            <div className={styles.SubHeader}>EVA</div>
                                            <div className={styles.BarWrapper}>
                                                <div ref={this.childEvaRef} style={{ position: 'relative' }}>
                                                    {
                                                        this.props.childEvaTimes && this.props.childEvaTimes.length && this.childEvaRef.current
                                                            ? this.props.childEvaTimes[i].map(eva => eva.amount && (
                                                                <div key={`parent-${eva.start}`} className={styles.NonProdTimeWrapper} style={{ left: (eva.index / 10) * this.childEvaRef.current?.offsetWidth / 144 }}>
                                                                    <div className={styles.EachBar} style={{ width: this.childEvaRef.current ? (eva.amount * 100 / 144) * this.childEvaRef.current?.offsetWidth / 100 : 0 }}></div>
                                                                    <div className={styles.TimeText} style={{ fontSize: 10 }}>{moment(eva.start).format('HH:mm')} - {moment(eva.start).add(eva.amount * 10, 'minutes').format('HH:mm')}</div>
                                                                </div>
                                                            ))
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.TableSection}>
                                            <div className={styles.SubHeader}>OVER CAPACITY LOAD OF NON PRODUCTION TIME</div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th style={{ width: 450 }}>{this.props.isStandardForm ? 'Description' : 'ประเภทการใช้ไฟฟ้าในช่วงไม่มีผลิต (1/2/3/4/5 อื่นๆ โปรดระบุ) *'}</th>
                                                        <th>{this.props.isStandardForm ? 'Countermeasure' : 'C/M (การปรับปรุงเพื่อลดการใช้ไฟฟ้า)'}</th>
                                                        <th>{this.props.isStandardForm ? 'Recorder' : 'กำหนดเสร็จ'}</th>
                                                        <th>{this.props.isStandardForm ? 'Due Date' : 'ผู้บันทึก'}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr><td /><td /><td /><td /><td /></tr>
                                                    <tr><td /><td /><td /><td /><td /></tr>
                                                    <tr><td /><td /><td /><td /><td /></tr>
                                                    <tr><td /><td /><td /><td /><td /></tr>
                                                    <tr><td /><td /><td /><td /><td /></tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={styles.RightSection}>
                                        {!this.props.isStandardForm
                                            ? <div className={styles.TagWrapper} style={{ marginTop: 50 }}>
                                                <div className={styles.TopSection}>
                                                    <div className={styles.TagTitle}>ประเภทการใช้ไฟฟ้า</div>
                                                </div>
                                                <div className={styles.DeviceList}>
                                                    <div>1. Prepare Line (เตรียมงาน)</div>
                                                    <div>2. Machine Tag A & B (เครื่องจักรที่ไม่สามารถปิดได้)</div>
                                                    <div>3. MT&ME Job Support O/H, test run, marker</div>
                                                    <div>4. Prod. Job 3Pillars, 4S, OM, Marker</div>
                                                    <div>5. Other (อื่นๆ)</div>
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                        : null
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { devices } = state.devices;
    return {
        currentOrg, devices
    };
};

export default connect(mapStateToProps, {})(DailyReport);
