import firebase from 'firebase/app';
import "firebase/storage";

const firebaseConfig = {
    // Your firebase configuration object
    apiKey: "AIzaSyBLol1KQ7gVszn1D-xpk42YU5JZzxviSWM",
    authDomain: "owl-v3.firebaseapp.com",
    databaseURL: "https://owl-v3.firebaseio.com",
    projectId: "owl-v3",
    storageBucket: "owl-v3.appspot.com",
    messagingSenderId: "840132428951",
    appId: "1:840132428951:web:46298ac3a4f57493"
}

const firebaseApp = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

// TODO:: Optimize app check for security
// const firebaseAppCheck = firebaseApp.appCheck();
// // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// // key is the counterpart to the secret key you set in the Firebase console.
// firebaseAppCheck.activate(
//     'NM4jIPAqzC7O',
//     // Optional argument. If true, the SDK automatically refreshes App Check
//     // tokens as needed.
//     true);

export const firebaseStorageFloorPlan = firebaseApp.storage("gs://owl-v3-floor-plan");

export const uploadFloorPlanImage = async (imageFile) => {
    try {
        const storageRef = firebaseStorageFloorPlan.ref();
        const floorPlanImageRef = storageRef.child(imageFile.name);
        const upload = await floorPlanImageRef.put(imageFile);
        const imageUrl = await upload.ref.getDownloadURL();
        return imageUrl
    } catch (err) {
        console.log(err)
    }
}
