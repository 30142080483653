import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../state/ducks';

import ReleasesLog from './releases-log.json';
import styles from './Releases.module.scss';
import OwlBlueLogo from '../../../assets/icons/owl-logo/owl-logo-blue.svg';
import UpdatesLogo from '../../../assets/icons/Releases-log/updates.svg';
import FixesLogo from '../../../assets/icons/Releases-log/fixes.svg';

class Releases extends Component {

	goTo = () => {
		if (this.props.loggedInUser) {
			this.props.route.history.push('/pages');
		} else {
			history.push('/auth');
		}
	}

	render() {

		return (
			<>
				<div className={styles.Topbar}>
					<div className={styles.LeftItems}>
						<img src={OwlBlueLogo} style={{ width: 35, marginRight: 10 }} alt="owl logo" />
						<div className={styles.LogoText}>OWL</div>
					</div>
					<div className={styles.RightItems}>
						<div className={styles.GoToButton} onClick={() => this.goTo()}>{this.props.loggedInUser ? 'Dashboard' : 'Login'}</div>
					</div>
				</div>
				<div style={{ height: 100 }} />

				{
					Object.keys(ReleasesLog).map((version, i) => (
						<div key={`releases-log-v${version}`} className={styles.ReleasesWrapper}>
							<div className={styles.LeftSection}>
								<div className={styles.VersionTag}><i className={styles.GgTag}></i><span className={styles.VersionText}>{version}</span></div>
							</div>
							<div className={styles.RightSection}>
								<div style={{ marginBottom: 15 }}>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className={styles.HeaderVersion}>OWL <span className={styles.VersionText}>{version}</span></div>
										{i === 0 && <div className={styles.LatestRelease}>Latest release</div>}
									</div>
									<div className={styles.SubHeader}><span className={styles.DeveloperName}>{ReleasesLog[version].releasedBy}</span> RELEASED ON {ReleasesLog[version].date}</div>
								</div>
								{ReleasesLog[version].updates && <div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<img src={UpdatesLogo} style={{ width: 25, marginRight: 10 }} alt="updates logo" />
										<div className={styles.ContentHeader}>Updates :</div>
									</div>
									<ul className={styles.Content}>
										{ReleasesLog[version].updates.map(upd =>
											<li key={`releases-log-v${version}_u-${upd.text}`}>{upd.text}
												<div className={`${styles.SubHeader} ${styles.InDetails}`}>by {upd.developer}</div>
											</li>
										)}
									</ul>
								</div>}
								{ReleasesLog[version].fixes && <div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<img src={FixesLogo} style={{ width: 25, marginRight: 10 }} alt="fixes logo" />
										<div className={styles.ContentHeader}>Fixes :</div>
									</div>
									<ul className={styles.Content}>
										{ReleasesLog[version].fixes.map(fix =>
											<li key={`releases-log-v${version}_f-${fix.text}`}>{fix.text}
												<div className={`${styles.SubHeader} ${styles.InDetails}`}>by {fix.developer}</div>
											</li>)}
									</ul>
								</div>}
							</div>
						</div>
					))
				}

			</>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		loggedInUser: state.auth.loggedInUser
	};
};

export default connect(mapStateToProps, {})(Releases);