import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import styles from './MemberTable.module.scss';
import Admin from '../../../../assets/images/admin.svg';
import ArrowIcon from '../../../../assets/icons/arrow-up.svg';
import BlockIcon from '../../../../assets/icons/block.svg';
// import EditIcon from '../../../../assets/icons/edit-gray.svg';
// import DeleteIcon from '../../../../assets/icons/trash-bin.svg';
import VisitPageIcon from '../../../../assets/icons/arrow.svg';
import Tooltip from '../../UI/ToolTip/Tooltip';
import LoadingTable from '../../UI/LoadingSkeleton/LoadingTable/LoadingTable';

import { getPages } from '../../../../state/ducks/Pages/actions';
import { removeMember } from '../../../../state/ducks/Organization/actions';

class MemberTable extends Component {
  state = {
    memberDetailsOpen: [],
    sortedMembers: null
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.sortedMembers && this.props.membersForShowing) {
      this.setState({ sortedMembers: this.props.membersForShowing });
    } else if ((prevProps.membersForShowing !== this.props.membersForShowing) && this.props.sorting) {
      this.sortingMembers(this.props.sorting, this.props.membersForShowing);
    } else if (prevProps.sorting !== this.props.sorting) {
      this.sortingMembers(this.props.sorting, this.props.membersForShowing);
    }
  }

  sortingMembers = (sorting, membersForShowing) => {
    let sortedMembers = membersForShowing;
    if (sorting !== '-1') {
      switch (sorting) {
        case 1: sortedMembers = _.orderBy(membersForShowing, ['first_name', 'last_name'], ['asc']); break;;
        case 2: sortedMembers = _.orderBy(membersForShowing, ['first_name', 'last_name'], ['desc']); break;
        case 3: sortedMembers = membersForShowing.sort((a, b) => a.created_date - b.created_date); break;
        case 4: sortedMembers = membersForShowing.sort((a, b) => b.created_date - a.created_date); break;
        default: sortedMembers = membersForShowing; break;
      }
    }
    this.setState({ sortedMembers, memberDetailsOpen: [] });
  }


  openMoreDetailsHandler = (index) => {
    const isAdmin = this.state.sortedMembers[index].admin_of_org && (this.state.sortedMembers[index].admin_of_org.filter(m => m === +this.props.currentOrg)).length > 0;
    if (!isAdmin) {
      const newOpen = this.state.sortedMembers.map(() => false);
      if (!this.state.memberDetailsOpen[index]) {
        newOpen[index] = true;
        this.props.getPages(this.props.currentOrg, this.state.sortedMembers[index].id);
      }
      this.setState({ memberDetailsOpen: newOpen });
    }
  }

  render() {
    return (
      this.state.sortedMembers ? this.state.sortedMembers.map((member, index) => {
        if (!member.is_superuser) {
          let memberCardClasses = [styles.MemberCard];
          let arrowClasses = [styles.ArrowIcon];
          if (this.state.memberDetailsOpen[index] && !member.is_superuser) {
            memberCardClasses.push(styles.Open);
            arrowClasses.push(styles.ToggleDown);
          }

          let hasActions = (
            <div className={styles.ActionButtons}>
              <img style={{ marginRight: 30 }} src={BlockIcon} alt="Block member" onClick={(event) => this.props.removeMemberHandler(event, member.email)} />
              {/* <img style={{ marginRight: 30 }} src={EditIcon} alt="Edit member" onClick={(event) => this.props.editMemberHandler(event, index)} />
               <img style={{ marginRight: 30 }} src={DeleteIcon} alt="Delete member" onClick={(event) => this.props.deleteMemberHandler(event, member.id)} /> */}
              <img className={arrowClasses.join(' ')} src={ArrowIcon} alt="Open more member's details" />
            </div>
          );
          if (this.props.currentOrg && member.admin_of_org) {
            const isAdmin = (member.admin_of_org.filter(m => m === +this.props.currentOrg)).length > 0;
            if (isAdmin) {
              hasActions = null;
            }
          }

          let pagesDetails = (
            <div className={styles.DetailBody} style={{ padding: '0 30px' }}>
              <LoadingTable />
            </div>
          );
          if (!this.props.loading) {
            pagesDetails = (
              this.props.pages && this.props.pages.length > 0 ? this.props.pages.map(page => (
                <div key={`member${member.id}_page${page.id}`} className={styles.DetailBody}>
                  <div style={{ flex: 1, lineHeight: '28px' }}>{page.name} ({page.detail})</div>
                  <div className={styles.Cell200px}>{moment(page.created_date).format("MMM DD, YY")}</div>
                  <div className={styles.Cell200px}>
                    {
                      page.members.map((uid, i) => {
                        if (this.props.allMembers) {
                          const found = this.props.allMembers.filter(m => m.id === uid);
                          if (found && found[0]) {
                            if (i === 0) {
                              return (
                                <Tooltip overideIcon text={`${found[0].first_name} ${found[0].last_name}`} key={`memberpage${uid}_profilepicture`} overideTitleStyles={{ lineHeight: 2 }}>
                                  <img src={found[0].profile_picture} alt="profile pic" className={styles.MembersPageProfilePicture} />
                                </Tooltip>
                              );
                            } else if (i < 3) {
                              return (
                                <Tooltip overideIcon text={`${found[0].first_name} ${found[0].last_name}`} key={`memberpage${uid}_profilepicture`} overideTitleStyles={{ marginLeft: '-4px' }}>
                                  <img key={`memberpage${uid}_profilepicture`} src={found[0].profile_picture} alt="profile pic" className={`${styles.MembersPageProfilePicture} ${styles.Over}`} />
                                </Tooltip>
                              );
                            } else if (i === 3) {
                              return <div key="more_profilepicture" className={`${styles.MembersPageProfilePicture} ${styles.More} ${styles.Over}`}>+{page.members.length - 3}</div>;
                            }
                          }
                        }
                        return null;
                      })
                    }
                  </div>
                  <div className={styles.VisitWrapper}>
                    {
                      page.page_type === 1 ?
                        <Link to={`/pages/${page.id}`}>
                          <span className={styles.VisitPage}>
                            Visit page <img className={styles.ArrowIcon} src={VisitPageIcon} alt="Visit page" />
                          </span>
                        </Link> :
                        <a href={page.url}>
                          <span className={styles.VisitPage}>
                            Visit page <img className={styles.ArrowIcon} src={VisitPageIcon} alt="Visit page" />
                          </span>
                        </a>
                    }
                  </div>
                </div>
              ))
                : (
                  <div className={styles.DetailBody}>
                    <div style={{ flex: 1 }}>No joined pages</div>
                  </div>
                )
            );
          }

          return (
            <div key={`member${member.id}`} className={memberCardClasses.join(' ')}>
              <div className={styles.MemberWrapper} onClick={() => this.openMoreDetailsHandler(index)}>
                <div className={styles.ProfilePicture}>
                  {
                    member.profile_picture ?
                      <img src={member.profile_picture} alt="profile pic" style={{ width: '100%' }} />
                      : <img src={Admin} alt="admin profile pic" style={{ width: '100%' }} />
                  }
                </div>
                <div className={styles.NameAndInformation}>
                  <div className={styles.FullName}>{member.first_name} {member.last_name}</div>
                  <div className={styles.OtherInformation}>
                    {member.username} <span className={styles.MiddleDot}>&#183;</span>{member.email}
                    {member.job_title ? (
                      <React.Fragment>
                        <span className={styles.MiddleDot}>&#183;</span>{member.job_title}
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
                {hasActions}
              </div>

              {
                // TODO: change to add css classes? from invisible -> visible, height = 0 to auto? better?
                this.state.memberDetailsOpen[index] &&
                <div className={styles.CollapseContainer}>
                  <div className={styles.DetailHeader}>
                    <div style={{ flex: 1 }}>Pages</div>
                    <div style={{ width: 200 }}>Created Date</div>
                    <div style={{ width: 200 }}>Page Members</div>
                    <div style={{ width: 120 }}></div>
                  </div>
                  {pagesDetails}
                </div>
              }
            </div>
          );
        } else {
          // not showing admin
          return null;
        }
      })
        : null
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser } = state.auth;
  const { currentOrg } = state.org;
  const { loading, pages } = state.pages;
  return { currentOrg, loading, pages, loggedInUser };
};

export default connect(mapStateToProps, { getPages, removeMember })(MemberTable);