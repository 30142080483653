import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  variables: null,
  addedVariable: null,
  updatedVariable: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_VARIABLES_START: return getVariablesStart(state, action);
    case actionTypes.GET_VARIABLES_SUCCESS: return getVariablesSuccess(state, action);
    case actionTypes.GET_VARIABLES_FAIL: return getVariablesFail(state, action);

    case actionTypes.ADD_VARIABLE_START: return addVariableStart(state, action);
    case actionTypes.ADD_VARIABLE_SUCCESS: return addVariableSuccess(state, action);
    case actionTypes.ADD_VARIABLE_FAIL: return addVariableFail(state, action);

    case actionTypes.EDIT_VARIABLE_START: return editVariableStart(state, action);
    case actionTypes.EDIT_VARIABLE_SUCCESS: return editVariableSuccess(state, action);
    case actionTypes.EDIT_VARIABLE_FAIL: return editVariableFail(state, action);

    case actionTypes.DELETE_VARIABLE_START: return deleteVariableStart(state, action);
    case actionTypes.DELETE_VARIABLE_SUCCESS: return deleteVariableSuccess(state, action);
    case actionTypes.DELETE_VARIABLE_FAIL: return deleteVariableFail(state, action);

    default: return state;
  }
};

const getVariablesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getVariablesSuccess = (state, action) => {
  const variables = action.variables ? action.variables.sort((a, b) => a.name - b.name) : null;
  return updateObject(state, {
    variables,
    error: null,
    loading: false
  });
}

const getVariablesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addVariableStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addVariableSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedVariable: action.addedVariable,
    error: null,
    loading: false
  });
}

const addVariableFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editVariableStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editVariableSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedVariable: action.updatedVariable,
    error: null,
    loading: false
  });
}

const editVariableFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteVariableStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteVariableSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteVariableFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;