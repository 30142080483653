import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  planStructures: null,
  addedPlanStructure: null,
  updatedPlanStructure: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCTION_PLAN_STRUCTURES_START: return getPlanStructuresStart(state, action);
    case actionTypes.GET_PRODUCTION_PLAN_STRUCTURES_SUCCESS: return getPlanStructuresSuccess(state, action);
    case actionTypes.GET_PRODUCTION_PLAN_STRUCTURES_FAIL: return getPlanStructuresFail(state, action);

    case actionTypes.ADD_PRODUCTION_PLAN_STRUCTURE_START: return addPlanStructureStart(state, action);
    case actionTypes.ADD_PRODUCTION_PLAN_STRUCTURE_SUCCESS: return addPlanStructureSuccess(state, action);
    case actionTypes.ADD_PRODUCTION_PLAN_STRUCTURE_FAIL: return addPlanStructureFail(state, action);

    case actionTypes.EDIT_PRODUCTION_PLAN_STRUCTURE_START: return editPlanStructureStart(state, action);
    case actionTypes.EDIT_PRODUCTION_PLAN_STRUCTURE_SUCCESS: return editPlanStructureSuccess(state, action);
    case actionTypes.EDIT_PRODUCTION_PLAN_STRUCTURE_FAIL: return editPlanStructureFail(state, action);

    case actionTypes.DELETE_PRODUCTION_PLAN_STRUCTURE_START: return deletePlanStructureStart(state, action);
    case actionTypes.DELETE_PRODUCTION_PLAN_STRUCTURE_SUCCESS: return deletePlanStructureSuccess(state, action);
    case actionTypes.DELETE_PRODUCTION_PLAN_STRUCTURE_FAIL: return deletePlanStructureFail(state, action);
    
    default: return state;
  }
};

const getPlanStructuresStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getPlanStructuresSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    planStructures: action.planStructures,
    error: null,
    loading: false,
    result: action.result
  });
}

const getPlanStructuresFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    result: null
  });
}

const addPlanStructureStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addPlanStructureSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedPlanStructure: action.addedPlanStructure,
    error: null,
    loading: false
  });
}

const addPlanStructureFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editPlanStructureStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editPlanStructureSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedPlanStructure: action.updatedPlanStructure,
    error: null,
    loading: false
  });
}

const editPlanStructureFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deletePlanStructureStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deletePlanStructureSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deletePlanStructureFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;