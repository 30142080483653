import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  rule: null,
  rules: null,
  addedRule: null,
  updatedRule: null,
  updatedEnableRule: null,
  alarmHistories: null,
  historiesLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RULES_START: return getRulesStart(state, action);
    case actionTypes.GET_RULES_SUCCESS: return getRulesSuccess(state, action);
    case actionTypes.GET_RULES_FAIL: return getRulesFail(state, action);

    case actionTypes.ADD_RULE_START: return addRuleStart(state, action);
    case actionTypes.ADD_RULE_SUCCESS: return addRuleSuccess(state, action);
    case actionTypes.ADD_RULE_FAIL: return addRuleFail(state, action);

    case actionTypes.EDIT_RULE_START: return editRuleStart(state, action);
    case actionTypes.EDIT_RULE_SUCCESS: return editRuleSuccess(state, action);
    case actionTypes.EDIT_RULE_FAIL: return editRuleFail(state, action);

    case actionTypes.ENABLE_RULE_START: return enableRuleStart(state, action);
    case actionTypes.ENABLE_RULE_SUCCESS: return enableRuleSuccess(state, action);
    case actionTypes.ENABLE_RULE_FAIL: return enableRuleFail(state, action);

    case actionTypes.DELETE_RULE_START: return deleteRuleStart(state, action);
    case actionTypes.DELETE_RULE_SUCCESS: return deleteRuleSuccess(state, action);
    case actionTypes.DELETE_RULE_FAIL: return deleteRuleFail(state, action);
    
    case actionTypes.CLEAR_RULE_ERROR: return clearRuleError(state, action);

    case actionTypes.GET_ALARM_HISTORIES_START: return getAlarmHistoriesStart(state, action);
    case actionTypes.GET_ALARM_HISTORIES_SUCCESS: return getAlarmHistoriesSuccess(state, action);
    case actionTypes.GET_ALARM_HISTORIES_FAIL: return getAlarmHistoriesFail(state, action);
    default: return state;
  }
};

const clearRuleError = (state, action) => {
  return updateObject(state, { result: null, error: null });
}

const getRulesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getRulesSuccess = (state, action) => {
  const rules = action.rules ? action.rules.sort((a, b) => a.created_date - b.created_date) : null;
  return updateObject(state, {
    rules,
    error: null,
    loading: false
  });
}

const getRulesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addRuleStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addRuleSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedRule: action.addedRule,
    error: null,
    loading: false
  });
}

const addRuleFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editRuleStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editRuleSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedRule: action.updatedRule,
    error: null,
    loading: false
  });
}

const editRuleFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const enableRuleStart = (state, action) => {
  return updateObject(state, { error: null });
}

const enableRuleSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    updatedEnableRule: action.updatedEnableRule,
    error: null,
  });
}

const enableRuleFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
}

const deleteRuleStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteRuleSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteRuleFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getAlarmHistoriesStart = (state, action) => {
  return updateObject(state, { result: null, error: null, historiesLoading: true });
}

const getAlarmHistoriesSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    alarmHistories: action.alarmHistories,
    error: null,
    historiesLoading: false,
    result: action.result
  });
}

const getAlarmHistoriesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    historiesLoading: false,
    result: null
  });
}

export default reducer;