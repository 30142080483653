import React, { Component } from 'react';

import styles from './DeleteModal.module.scss';
import Modal from '../../UI/Modal/Modal';
import Background from '../../../../assets/images/modal-bg.svg';
import Button from '../../UI/Button/Button';

class DeleteModal extends Component {
  componentDidMount() {
    window.addEventListener('keydown', this.handleEsc);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEsc);
  }

  handleEsc = (event) => {
    if (this.props.open && event.keyCode === 27) {
      this.props.modalClosed();
    }
  };

  render() {
    const { title, overrideTitle, overrideDescription1, overrideDescription2 } = this.props;
    return (
      <Modal show={this.props.open} modalClosed={this.props.modalClosed}>
        <div className={styles.DeleteModal}>
          <div className={styles.Title}>{overrideTitle ? overrideTitle : <>Are you sure to <span style={{ color: 'red' }}>DELETE</span> this?</>}</div>
          <div className={styles.Description}>{overrideDescription1 ? overrideDescription1 : "Click yes and this " + title + " will be deleted"}</div>
          <div className={styles.Description}>{overrideDescription2 ? overrideDescription2 : "and you can not undo this."}</div>

          <div className={styles.YesButtonWrapper}>
            <Button type="button" color="red" name="Yes please" click={this.props.confirmDelete} />
          </div>
          <div className={styles.CancelButton} onClick={this.props.modalClosed}>Cancel</div>

        </div>
        <div className={styles.Background} style={{ backgroundImage: `url(${Background})` }} />
      </Modal>
    );
  }
}

export default DeleteModal;