import * as actionTypes from './types';

export const clearData = () => {
  return {
    type: actionTypes.CLEAR_DATA
  };
}

// ----------------------- GET DATA -----------------------
export const getDataStart = () => {
  return {
    type: actionTypes.GET_DATA_START
  };
}

export const getData = (line_id, device_id, start, stop, secret, socket) => {
  return {
    type: actionTypes.GET_DATA,
    line_id, device_id, start, stop, secret, socket
  };
}

export const getDataSuccess = (response, line_id, props) => {
  return {
    type: actionTypes.GET_DATA_SUCCESS,
    data: { line_id, values: response, properties: props }
  };
}

export const getDataFail = (error) => {
  return {
    type: actionTypes.GET_DATA_FAIL,
    error: error
  };
}

// ----------------------- GET DATACAL -----------------------
export const getDataCalStart = () => {
  return {
    type: actionTypes.GET_DATACAL_START
  };
}

export const getDataCal = (line_id, secret, socket, start, stop, method, properties) => {
  return {
    type: actionTypes.GET_DATACAL,
    line_id, secret, socket, start, stop, method, properties
  };
}

export const getDataCalSuccess = (line_id, response, secret, socket, timestamp, properties) => {
  return {
    type: actionTypes.GET_DATACAL_SUCCESS,
    dataCal: { id: line_id, secret: secret, socket: socket, timestamp: timestamp, value: response.value, properties }
  };
}

export const getDataCalFail = (error) => {
  return {
    type: actionTypes.GET_DATACAL_FAIL,
    error: error
  };
}

// ----------------------- GET DATASET CAL -----------------------
export const getDatasetCalStart = (line_id) => {
  return {
    type: actionTypes.GET_DATASET_CAL_START,
    loadingId: line_id
  };
}

export const getDatasetCal = (line_id, method, secret, socket, cal_range, start, stop, gapfill, properties) => {
  return {
    type: actionTypes.GET_DATASET_CAL,
    line_id, method, secret, socket, cal_range, start, stop, gapfill, properties
  };
}

export const getDatasetCalSuccess = (line_id, response, secret, socket, stop, properties) => {
  return {
    type: actionTypes.GET_DATASET_CAL_SUCCESS,
    datasetCal: { id: line_id, secret: secret, socket: socket, values: response, lastTimeStamp: stop, properties }
  };
}

export const getDatasetCalFail = (error) => {
  return {
    type: actionTypes.GET_DATASET_CAL_FAIL,
    error: error
  };
}

// ----------------------- GET DATASET CAL -----------------------
export const getFullDatasetCalStart = (line_id) => {
  return {
    type: actionTypes.GET_FULL_DATASET_CAL_START,
    loadingId: line_id
  };
}

export const getFullDatasetCal = (line_id, method, secret, socket, cal_range, start, stop, gapfill, properties) => {
  return {
    type: actionTypes.GET_FULL_DATASET_CAL,
    line_id, method, secret, socket, cal_range, start, stop, gapfill, properties
  };
}

export const getFullDatasetCalSuccess = (line_id, response, secret, socket, stop, properties) => {
  return {
    type: actionTypes.GET_FULL_DATASET_CAL_SUCCESS,
    datasetCal: { id: line_id, secret: secret, socket: socket, values: response, lastTimeStamp: stop, properties }
  };
}

export const getFullDatasetCalFail = (error) => {
  return {
    type: actionTypes.GET_FULL_DATASET_CAL_FAIL,
    error: error
  };
}

export const setLoadingPercent = (percent) => {
  return {
    type: actionTypes.SET_LOADING_PERCENT,
    loadingPercent: percent
  };
}


// ----------------------- GET DATALATEST -----------------------
export const getDataLatestStart = () => {
  return {
    type: actionTypes.GET_DATALATEST_START
  };
}

export const getDataLatest = (socket, secret, device_id, wid, properties) => {
  return {
    type: actionTypes.GET_DATALATEST,
    socket, secret, device_id, wid, properties
  };
}

export const getDataLatestSuccess = (response, props) => {
  return {
    type: actionTypes.GET_DATALATEST_SUCCESS,
    dataLatest: response.data,
    props
  };
}

export const getDataLatestFail = (error) => {
  return {
    type: actionTypes.GET_DATALATEST_FAIL,
    error: error
  };
}

// ----------------------- GET DATA EVAL -----------------------
export const getDataEvalStart = () => {
  return {
    type: actionTypes.GET_DATA_EVAL_START
  };
}

export const getDataEval = (formula, properties) => {
  return {
    type: actionTypes.GET_DATA_EVAL,
    formula, properties
  };
}

export const getDataEvalSuccess = (response, props) => {
  return {
    type: actionTypes.GET_DATA_EVAL_SUCCESS,
    dataEval: response.value,
    props
  };
}

export const getDataEvalFail = (error) => {
  return {
    type: actionTypes.GET_DATA_EVAL_FAIL,
    error: error
  };
}


// export default {
//   clearData,
//   getDataStart,
//   getData,
//   getDataSuccess,
//   getDataFail,
//   getDataCalStart,
//   getDataCal,
//   getDataCalSuccess,
//   getDataCalFail,
//   getDatasetCalStart,
//   getDatasetCal,
//   getDatasetCalSuccess,
//   getDatasetCalFail,
//   setLoadingPercent,
//   getDataLatestStart,
//   getDataLatest,
//   getDataLatestSuccess,
//   getDataLatestFail,
//   getDataEvalStart,
//   getDataEval,
//   getDataEvalSuccess,
//   getDataEvalFail
// };