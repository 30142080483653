import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  formulas: null,
  addedFormula: null,
  updatedFormula: null,
  formulaMappingResult: null,
  formulaMappings: null,
  updatedFormulaMapping: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FORMULAS_START: return getFormulasStart(state, action);
    case actionTypes.GET_FORMULAS_SUCCESS: return getFormulasSuccess(state, action);
    case actionTypes.GET_FORMULAS_FAIL: return getFormulasFail(state, action);

    case actionTypes.ADD_FORMULA_START: return addFormulaStart(state, action);
    case actionTypes.ADD_FORMULA_SUCCESS: return addFormulaSuccess(state, action);
    case actionTypes.ADD_FORMULA_FAIL: return addFormulaFail(state, action);

    case actionTypes.EDIT_FORMULA_START: return editFormulaStart(state, action);
    case actionTypes.EDIT_FORMULA_SUCCESS: return editFormulaSuccess(state, action);
    case actionTypes.EDIT_FORMULA_FAIL: return editFormulaFail(state, action);

    case actionTypes.DELETE_FORMULA_START: return deleteFormulaStart(state, action);
    case actionTypes.DELETE_FORMULA_SUCCESS: return deleteFormulaSuccess(state, action);
    case actionTypes.DELETE_FORMULA_FAIL: return deleteFormulaFail(state, action);

    case actionTypes.GET_FORMULA_MAPPINGS_START: return getFormulaMappingsStart(state, action);
    case actionTypes.GET_FORMULA_MAPPINGS_SUCCESS: return getFormulaMappingsSuccess(state, action);
    case actionTypes.GET_FORMULA_MAPPINGS_FAIL: return getFormulaMappingsFail(state, action);

    case actionTypes.POST_FORMULA_MAPPING_START: return postFormulaMappingStart(state, action);
    case actionTypes.POST_FORMULA_MAPPING_SUCCESS: return postFormulaMappingSuccess(state, action);
    case actionTypes.POST_FORMULA_MAPPING_FAIL: return postFormulaMappingFail(state, action);

    case actionTypes.DELETE_FORMULA_MAPPING_START: return deleteFormulaMappingStart(state, action);
    case actionTypes.DELETE_FORMULA_MAPPING_SUCCESS: return deleteFormulaMappingSuccess(state, action);
    case actionTypes.DELETE_FORMULA_MAPPING_FAIL: return deleteFormulaMappingFail(state, action);

    default: return state;
  }
};

const getFormulasStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getFormulasSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    formulas: action.formulas,
    error: null,
    loading: false,
    result: action.result
  });
}

const getFormulasFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    result: null
  });
}

const addFormulaStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addFormulaSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedFormula: action.addedFormula,
    error: null,
    loading: false
  });
}

const addFormulaFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editFormulaStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editFormulaSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedFormula: action.updatedFormula,
    error: null,
    loading: false
  });
}

const editFormulaFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteFormulaStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteFormulaSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteFormulaFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getFormulaMappingsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getFormulaMappingsSuccess = (state, action) => {
  return updateObject(state, {
    formulaMappings: action.formulaMappings,
    error: null,
    loading: false
  });
}

const getFormulaMappingsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const postFormulaMappingStart = (state, action) => {
  return updateObject(state, { formulaMappingResult: null, error: null, loading: true });
}

const postFormulaMappingSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    formulaMappingResult: action.result,
    updatedFormulaMapping: action.updatedFormulaMapping,
    error: null,
    loading: false
  });
}

const postFormulaMappingFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteFormulaMappingStart = (state, action) => {
  return updateObject(state, { formulaMappingResult: null, error: null, loading: true, type: action.type });
}

const deleteFormulaMappingSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    formulaMappingResult: action.result,
    error: null,
    loading: false
  });
}

const deleteFormulaMappingFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}


export default reducer;