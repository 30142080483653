import React, { Component } from 'react';

import Input from '../Input/Input';
import ButtonSpinner from '../Spinner/ButtonSpinner';

import styles from './SearchPoInput.module.scss';
import Tick from '../../../../assets/icons/tick-square.svg';
import ScannerIcon from '../../../../assets/icons/Operator/scanner.png';

class SearchPoInput extends Component {

  dropdownMenuClose = () => {
    this.props.setIsSearchResultsOpened(false);
  }

  onFocusPoInput = () => {
    if (this.props.poValue.value !== '') {
      this.props.setIsSearchResultsOpened(true);
    }
  }

  onPickPoHandler = (index) => {
    this.props.clearOthersExceptPo(this.props.searchResults[index].po, this.props.searchResults[index]);
    this.props.setIsPoSelected(true);
    this.props.setIsSearchResultsOpened(false);
  }

  render() {
    const { value, touched, valid } = this.props.poValue;
    let dropdownClasses = [styles.CustomDropdown];
    if (this.props.isSearchResultsOpened) {
      dropdownClasses.push(styles.CustomDropdownIn);
    } else {
      dropdownClasses.push(styles.CustomDropdownOut);
    }

    return (
      <React.Fragment>
        <div className={styles.PoInputWrapper}>
          <Input
            label="Search or scan PO"
            name="po"
            type="text"
            value={value}
            autoComplete="off"
            onChange={this.props.handleChange}
            onFocus={this.onFocusPoInput}
            required
            error={`${touched && !valid ? value === '' ? 'PO is required' : value !== '' ? 'PO not found!' : '' : ''}`}
          />
          {this.props.autoCheckingPoLoading ? <div className={styles.AutoCheck}><ButtonSpinner /></div> : ''}
          {this.props.isPoSelected ? <img className={styles.PoVerified} src={Tick} alt="Spent" /> : ''}
          <img className={styles.ScannerIconInPO} src={ScannerIcon} alt="Spent" style={{ width: 24, height: 24 }} onClick={this.props.toggleScannerHandler} />

          {this.props.isSearchResultsOpened ? <div className={styles.CustomDropdownContainer} onClick={this.dropdownMenuClose} ></div> : null}

        </div>
        <div className={dropdownClasses.join(' ')}>
          <ul>
            {this.props.searchResults.map((sr, i) => (
              <li key={sr.uuid} onClick={() => this.onPickPoHandler(i)}>
                <div style={{ display: "flex" }}>
                  <div className={styles.CustomListItem}>{sr.po}</div>
                </div>
              </li>
            ))}

          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchPoInput;