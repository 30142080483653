
import axios from '../../../configs/axios-orders';
import { takeEvery, put, select } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_AWAITING_POWERS, GET_POWERS, GET_POWERS_JSON } from './types';

export function* watchPower() {
  yield takeEvery(GET_AWAITING_POWERS, getAwaitingPowersSaga);
  yield takeEvery(GET_POWERS, getPowersSaga);
  yield takeEvery(GET_POWERS_JSON, getPowersJsonSaga);
}

export function* getAwaitingPowersSaga(params) {
  yield put(actions.getPowersStart());

  let api = params.apiParams;
  try {
    let response;
    for (let i = 0; i < 5; i++) {
      try {
        response = yield axios.get(api);
        if (i > 1) console.log('retry ', i, api)
        yield put(actions.getPowersSuccess(response.data, params.properties));
        return;
      } catch (e) {
        console.log('request power failed retry');
      }
    }
    yield put(actions.getPowersFail('Something went wrong'));
  } catch (err) {
    // console.log("GET POWERS FAILED", err)
    yield put(actions.getPowersFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* getPowersSaga(params) {
  yield put(actions.getPowersStart());

  let api = 'v1/power';
  api += '?secret=' + params.secret;
  api += '&socket=' + params.socket;
  api += params.start ? '&start=' + params.start : '';
  api += params.end ? '&end=' + params.end : '';
  api += params.cal_range ? '&cal_rang=' + params.cal_range : '';
  api += params.range_unit ? '&rang_unit=' + params.range_unit : '';
  api += params.machine_id ? '&machine_id=' + params.machine_id : '';
  api += params.need_efficiency ? '&need_efficiency=' + params.need_efficiency : '';
  const state = yield select();
  const requestCount = state.power.requestCount;

  try {
    let response;
    if (requestCount > 20) yield put(actions.saveRequest(api, params.properties));
    else {
      for (let i = 0; i < 5; i++) {
        try {
          response = yield axios.get(api);
          if (i > 1) console.log('retry ', i, api)
          yield put(actions.getPowersSuccess(response.data, params.properties));
          return;
        } catch (e) {
          console.log('request power failed retry ', i, api);
        }
      }
      yield put(actions.getPowersFail('Something went wrong'));
    }
  } catch (err) {
    console.log("GET POWERS FAILED", err)
    yield put(actions.getPowersFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* getPowersJsonSaga(params) {
  yield put(actions.getPowersJsonStart());

  let api = 'v1/power';
  let err;
  for (let i = 0 ; i < 5 ; i++) {
    try {
      const response = yield axios.post(api, JSON.stringify(params.body), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (i > 1) console.log('retry ', i, api)
      yield put(actions.getPowersJsonSuccess(response.data, params.properties));
      return;
    } catch (e) {
      console.log("GET POWERS JSON FAILED", e);
      err = e;
    }
  }
  yield put(actions.getPowersJsonFail(err.response ? err.response.data.error : 'Something went wrong'));
}