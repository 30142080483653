import * as actionTypes from './types';

// ----------------------- GET DOCUMENT_NOTES -----------------------
export const getDocumentNotesStart = () => {
  return {
    type: actionTypes.GET_DOCUMENT_NOTES_START
  };
}

export const getDocumentNotes = (org_id, type_id) => {
  return {
    type: actionTypes.GET_DOCUMENT_NOTES,
    org_id, type_id
  };
}

export const getDocumentNotesSuccess = (response) => {
  return {
    type: actionTypes.GET_DOCUMENT_NOTES_SUCCESS,
    documentNotes: response.note_documents
  };
}

export const getDocumentNotesFail = (error) => {
  return {
    type: actionTypes.GET_DOCUMENT_NOTES_FAIL,
    error
  };
}

// ----------------------- ADD DOCUMENT_NOTE -----------------------

export const addDocumentNoteStart = () => {
  return {
    type: actionTypes.ADD_DOCUMENT_NOTE_START
  };
}

export const addDocumentNote = (org_id, type_id, title, content, options, ref, event_uuid, attachments) => {
  return {
    type: actionTypes.ADD_DOCUMENT_NOTE,
    org_id, type_id, title, content, options, ref, event_uuid, attachments
  };
}

export const addDocumentNoteSuccess = (response) => {
  return {
    type: actionTypes.ADD_DOCUMENT_NOTE_SUCCESS,
    addedDocumentNote: response.note_documents,
    result: response.result
  };
}

export const addDocumentNoteFail = (error) => {
  return {
    type: actionTypes.ADD_DOCUMENT_NOTE_FAIL,
    error
  };
}

// ----------------------- EDIT DOCUMENT_NOTE -----------------------
export const editDocumentNoteStart = () => {
  return {
    type: actionTypes.EDIT_DOCUMENT_NOTE_START
  };
}

export const editDocumentNote = (uuid, type_id, title, content, options, ref, event_uuid, attachments) => {
  return {
    type: actionTypes.EDIT_DOCUMENT_NOTE,
    uuid, type_id, title, content, options, ref, event_uuid, attachments
  };
}

export const editDocumentNoteSuccess = (response) => {
  return {
    type: actionTypes.EDIT_DOCUMENT_NOTE_SUCCESS,
    updatedDocumentNotes: response.note_documents,
    result: response.result
  };
}

export const editDocumentNoteFail = (error) => {
  return {
    type: actionTypes.EDIT_DOCUMENT_NOTE_FAIL,
    error
  };
}

// ----------------------- DELETE DOCUMENT_NOTE -----------------------
export const deleteDocumentNoteStart = () => {
  return {
    type: actionTypes.DELETE_DOCUMENT_NOTE_START
  };
}

export const deleteDocumentNote = (uuid) => {
  return {
    type: actionTypes.DELETE_DOCUMENT_NOTE,
    uuid
  };
}

export const deleteDocumentNoteSuccess = (result) => {
  return {
    type: actionTypes.DELETE_DOCUMENT_NOTE_SUCCESS,
    result
  };
}

export const deleteDocumentNoteFail = (error) => {
  return {
    type: actionTypes.DELETE_DOCUMENT_NOTE_FAIL,
    error
  };
}

export const clearNoteTypes = () => {
  return {
    type: actionTypes.CLEAR_NOTE_TYPES
  };
}

// ----------------------- GET NOTE_TYPES -----------------------
export const getNoteTypesStart = () => {
  return {
    type: actionTypes.GET_NOTE_TYPES_START
  };
}

export const getNoteTypes = (org_id) => {
  return {
    type: actionTypes.GET_NOTE_TYPES,
    org_id
  };
}

export const getNoteTypesSuccess = (response) => {
  return {
    type: actionTypes.GET_NOTE_TYPES_SUCCESS,
    noteTypes: response.types
  };
}

export const getNoteTypesFail = (error) => {
  return {
    type: actionTypes.GET_NOTE_TYPES_FAIL,
    error
  };
}


// ----------------------- ADD NOTE_TYPE -----------------------

export const addNoteTypeStart = () => {
  return {
    type: actionTypes.ADD_NOTE_TYPE_START
  };
}

export const addNoteType = (org_id, name) => {
  return {
    type: actionTypes.ADD_NOTE_TYPE,
    org_id, name
  };
}

export const addNoteTypeSuccess = (response) => {
  return {
    type: actionTypes.ADD_NOTE_TYPE_SUCCESS,
    addedNoteType: response.types,
    result: response.result
  };
}

export const addNoteTypeFail = (error) => {
  return {
    type: actionTypes.ADD_NOTE_TYPE_FAIL,
    error
  };
}

// ----------------------- EDIT NOTE_TYPE -----------------------
export const editNoteTypeStart = () => {
  return {
    type: actionTypes.EDIT_NOTE_TYPE_START
  };
}

export const editNoteType = (id, name) => {
  return {
    type: actionTypes.EDIT_NOTE_TYPE,
    id, name
  };
}

export const editNoteTypeSuccess = (response) => {
  return {
    type: actionTypes.EDIT_NOTE_TYPE_SUCCESS,
    updatedNoteType: response.types,
    result: response.result
  };
}

export const editNoteTypeFail = (error) => {
  return {
    type: actionTypes.EDIT_NOTE_TYPE_FAIL,
    error
  };
}

// ----------------------- DELETE NOTE_TYPE -----------------------
export const deleteNoteTypeStart = () => {
  return {
    type: actionTypes.DELETE_NOTE_TYPE_START
  };
}

export const deleteNoteType = (id) => {
  return {
    type: actionTypes.DELETE_NOTE_TYPE,
    id
  };
}

export const deleteNoteTypeSuccess = (result) => {
  return {
    type: actionTypes.DELETE_NOTE_TYPE_SUCCESS,
    result
  };
}

export const deleteNoteTypeFail = (error) => {
  return {
    type: actionTypes.DELETE_NOTE_TYPE_FAIL,
    error
  };
}


export const actionStart = (type) => {
  return { type };
}

export const getDocuments = (org_id, start, end, offset, limit) => {
  return {
    type: actionTypes.GET_DOCUMENTS,
    org_id, start, end, offset, limit
  }
}

export const createDocument = (org_id, type_id, title, content, ref, event_uuid, attachments, options) => {
  return {
    type: actionTypes.CREATE_DOCUMENT,
    org_id, type_id, title, content, ref, event_uuid, attachments, options
  }
}

export const updateDocument = (uuid, title, content, ref, event_uuid, attachments, options) => {
  return {
    type: actionTypes.UPDATE_DOCUMENT,
    uuid, title, content, ref, event_uuid, attachments, options
  }
}

export const deleteDocument = (uuid) => {
  return {
    type: actionTypes.DELETE_DOCUMENT,
    uuid
  }
}

export const getCreateOrUpdateDocumentsSuccess = (type, note_documents) => {
  return {
    type,
    note_documents,
    result: 'success'
  }
}

export const defaultActionDocumentFinish = (type, result, error) => {
  return {
    type,
    result,
    error
  }
}