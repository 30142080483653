import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  url: null,
  exportId: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EXPORT_CHECK_START: return getExportCheckStart(state, action);
    case actionTypes.GET_EXPORT_CHECK_SUCCESS: return getExportCheckSuccess(state, action);
    case actionTypes.GET_EXPORT_CHECK_FAIL: return getExportCheckFail(state, action);

    case actionTypes.GET_EXPORT_EXCEL_START: return getExportExcelStart(state, action);
    case actionTypes.GET_EXPORT_EXCEL_SUCCESS: return getExportExcelSuccess(state, action);
    case actionTypes.GET_EXPORT_EXCEL_FAIL: return getExportExcelFail(state, action);

    case actionTypes.GET_EXPORT_BREAKDOWN_EXCEL_START: return getExportBreakdownExcelStart(state, action);
    case actionTypes.GET_EXPORT_BREAKDOWN_EXCEL_SUCCESS: return getExportBreakdownExcelSuccess(state, action);
    case actionTypes.GET_EXPORT_BREAKDOWN_EXCEL_FAIL: return getExportBreakdownExcelFail(state, action);

    default: return state;
  }
};

const getExportCheckStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getExportCheckSuccess = (state, action) => {
  return updateObject(state, {
    url: action.url,
    error: action.error,
    loading: false
  });
}

const getExportCheckFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getExportExcelStart = (state) => {
  return updateObject(state, { error: null, loading: true });
}

const getExportExcelSuccess = (state, action) => {
  return updateObject(state, {
    exportId: action.exportId,
    error: null,
    loading: false
  });
}

const getExportExcelFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getExportBreakdownExcelStart = (state) => {
  return updateObject(state, { error: null, loading: true });
}

const getExportBreakdownExcelSuccess = (state, action) => {
  return updateObject(state, {
    exportId: action.exportId,
    error: null,
    loading: false
  });
}

const getExportBreakdownExcelFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;