import * as actionTypes from './types';

// ----------------------- GET SCHEDULER -----------------------
export const getSchedulersStart = () => {
  return {
    type: actionTypes.GET_SCHEDULERS_START
  };
}

export const getSchedulers = (org_id, start, end) => {
  return {
    type: actionTypes.GET_SCHEDULERS,
    org_id, start, end
  };
}

export const getSchedulersSuccess = (response) => {
  return {
    type: actionTypes.GET_SCHEDULERS_SUCCESS,
    schedulers: response.meta,
    result: response.result
  };
}

export const getSchedulersFail = (error) => {
  return {
    type: actionTypes.GET_SCHEDULERS_FAIL,
    error
  };
}

// ----------------------- ADD SCHEDULER -----------------------
export const addSchedulerStart = () => {
  return {
    type: actionTypes.ADD_SCHEDULER_START
  };
}

export const addScheduler = (org_id, title, type_id, machine_ids, start, end, end_repeat, description, frequency, properties) => {
  return {
    type: actionTypes.ADD_SCHEDULER,
    org_id, title, type_id, machine_ids, start, end, end_repeat, description, frequency, properties
  };
}

export const addSchedulerSuccess = (response) => {
  return {
    type: actionTypes.ADD_SCHEDULER_SUCCESS,
    addedScheduler: response.schedulers[0],
    result: response.result
  };
}

export const addSchedulerFail = (error) => {
  return {
    type: actionTypes.ADD_SCHEDULER_FAIL,
    error
  };
}

// ----------------------- EDIT SCHEDULER -----------------------
export const editSchedulerStart = () => {
  return {
    type: actionTypes.EDIT_SCHEDULER_START
  };
}

export const editScheduler = (id, start_edit, title, type_id, machine_ids, start, end, end_repeat, description, frequency, all_in_future, properties) => {
  return {
    type: actionTypes.EDIT_SCHEDULER,
    id, start_edit, title, type_id, machine_ids, start, end, end_repeat, description, frequency, all_in_future, properties
  };
}

export const editSchedulerSuccess = (response, properties) => {
  return {
    type: actionTypes.EDIT_SCHEDULER_SUCCESS,
    updatedSchedulers: response.schedulers,
    result: response.result
  };
}

export const editSchedulerFail = (error) => {
  return {
    type: actionTypes.EDIT_SCHEDULER_FAIL,
    error
  };
}

// ----------------------- DELETE SCHEDULER -----------------------
export const deleteSchedulerStart = () => {
  return {
    type: actionTypes.DELETE_SCHEDULER_START
  };
}

export const deleteScheduler = (id, start_delete, all_in_future, properties) => {
  return {
    type: actionTypes.DELETE_SCHEDULER,
    id, start_delete, all_in_future, properties
  };
}

export const deleteSchedulerSuccess = (result, properties) => {
  return {
    type: actionTypes.DELETE_SCHEDULER_SUCCESS,
    result, properties
  };
}

export const deleteSchedulerFail = (error) => {
  return {
    type: actionTypes.DELETE_SCHEDULER_FAIL,
    error
  };
}

// ----------------------- GET SCHEDULER_TYPES -----------------------
export const getSchedulerTypesStart = () => {
  return {
    type: actionTypes.GET_SCHEDULER_TYPES_START
  };
}

export const getSchedulerTypes = (org_id) => {
  return {
    type: actionTypes.GET_SCHEDULER_TYPES,
    org_id
  };
}

export const getSchedulerTypesSuccess = (response) => {
  return {
    type: actionTypes.GET_SCHEDULER_TYPES_SUCCESS,
    schedulerTypes: response.types,
    result: response.result
  };
}

export const getSchedulerTypesFail = (error) => {
  return {
    type: actionTypes.GET_SCHEDULER_TYPES_FAIL,
    error
  };
}

// ----------------------- ADD SCHEDULER_TYPE -----------------------
export const addSchedulerTypeStart = () => {
  return {
    type: actionTypes.ADD_SCHEDULER_TYPE_START
  };
}

export const addSchedulerType = (org_id, name, color, category) => {
  return {
    type: actionTypes.ADD_SCHEDULER_TYPE,
    org_id, name, color, category
  };
}

export const addSchedulerTypeSuccess = (response) => {
  return {
    type: actionTypes.ADD_SCHEDULER_TYPE_SUCCESS,
    addedSchedulerType: response.types[0],
    result: response.result
  };
}

export const addSchedulerTypeFail = (error) => {
  return {
    type: actionTypes.ADD_SCHEDULER_TYPE_FAIL,
    error
  };
}

// ----------------------- EDIT SCHEDULER_TYPE -----------------------
export const editSchedulerTypeStart = () => {
  return {
    type: actionTypes.EDIT_SCHEDULER_TYPE_START
  };
}

export const editSchedulerType = (id, name, color, category) => {
  return {
    type: actionTypes.EDIT_SCHEDULER_TYPE,
    id, name, color, category
  };
}

export const editSchedulerTypeSuccess = (response) => {
  return {
    type: actionTypes.EDIT_SCHEDULER_TYPE_SUCCESS,
    updatedSchedulerType: response.types[0],
    result: response.result
  };
}

export const editSchedulerTypeFail = (error) => {
  return {
    type: actionTypes.EDIT_SCHEDULER_TYPE_FAIL,
    error
  };
}

// ----------------------- DELETE SCHEDULER_TYPE -----------------------
export const deleteSchedulerTypeStart = () => {
  return {
    type: actionTypes.DELETE_SCHEDULER_TYPE_START
  };
}

export const deleteSchedulerType = (id) => {
  return {
    type: actionTypes.DELETE_SCHEDULER_TYPE,
    id
  };
}

export const deleteSchedulerTypeSuccess = (result) => {
  return {
    type: actionTypes.DELETE_SCHEDULER_TYPE_SUCCESS,
    result
  };
}

export const deleteSchedulerTypeFail = (error) => {
  return {
    type: actionTypes.DELETE_SCHEDULER_TYPE_FAIL,
    error
  };
}