import React, { Component } from "react";
import { connect } from "react-redux";

import styles from "./AddNewVirtualDevice.module.scss";
import CloseIcon from "../../../../assets/icons/cancel-circle.svg";
import Input from "../../UI/Input/Input";
import Select from '../../UI/Select/Select';
import Button from "../../UI/Button/Button";
import SimpleSwitch from "../../UI/SimpleSwitch/SimpleSwitch";

import { addVirtualDevice, editVirtualDevice, testVirtualDeviceScript, deleteVirtualDevice } from "../../../../state/ducks/VirtualDevice/actions";
import { 
    TEST_VIRTUAL_DEVICE_SCRIPT_START, 
    TEST_VIRTUAL_DEVICE_SCRIPT_SUCCESS, 
    ADD_VIRTUAL_DEVICE_START, 
    ADD_VIRTUAL_DEVICE_SUCCESS,
    EDIT_VIRTUAL_DEVICE_START,
    EDIT_VIRTUAL_DEVICE_SUCCESS,
    DELETE_VIRTUAL_DEVICE_START,
    DELETE_VIRTUAL_DEVICE_SUCCESS} 
    from "../../../../state/ducks/VirtualDevice/types";

const DEFAULT_SCRIPT = `function run() {
    return 1;
}`;

class AddNewVirtualDevice extends Component {
    state = {
        formIsValid: false,
        allDeviceOptions: [],
        tested: false,
        testError: '',
        selectedDevice: -1,
        form: {
            name: '',
            triggerDeviceSecret: '',
            triggerDeviceSocket: 0,
            useSystemTrigger: false,
            triggerInterval: 10,
            script: DEFAULT_SCRIPT,
        }
    }

    componentDidMount = () => {
        this.setDefaultDeviceOptions();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps === this.props)
            return;

        if (prevProps.type === TEST_VIRTUAL_DEVICE_SCRIPT_START) {
            if (this.props.type === TEST_VIRTUAL_DEVICE_SCRIPT_SUCCESS && !this.state.tested) {
                this.setState({tested: true});
            } else if (this.props.error) {
                this.setState({tested: false, testError: this.props.error});
            }
        } else if (prevProps.type === ADD_VIRTUAL_DEVICE_START && this.props.type === ADD_VIRTUAL_DEVICE_SUCCESS) {
            this.props.closed();
        } else if (prevProps.type === EDIT_VIRTUAL_DEVICE_START && this.props.type === EDIT_VIRTUAL_DEVICE_SUCCESS) {
            this.props.closed();
        } else if (prevProps.type === DELETE_VIRTUAL_DEVICE_START && this.props.type === DELETE_VIRTUAL_DEVICE_SUCCESS) {
            this.props.closed();
        }

        if (prevProps.editingVirtualDevice !== this.props.editingVirtualDevice)
            this.initForm(this.state.allDeviceOptions);
    }

    setDefaultDeviceOptions = () => {
        if (!this.props.devices)
            return;

        let allDeviceOptions = [];
        this.props.devices.forEach((device) => {
            device.device_sockets.forEach((socket) => {
                allDeviceOptions.push({
                    id: socket.id,
                    name: socket.name + " (" + device.name + ")",
                    secret: socket.device_secret,
                    socket: socket.socket,
                    value: socket.id,
                });
            });
        });
        this.initForm(allDeviceOptions);
    };

    initForm = (allDeviceOptions) => {
        let { form, selectedDevice, formIsValid } = this.state;
        if (!this.props.isAdding && this.props.editingVirtualDevice) {
            let vd = this.props.editingVirtualDevice;
            form = {
                name: vd.name,
                triggerDeviceSecret: vd.trigger_secret,
                triggerDeviceSocket: vd.trigger_socket,
                useSystemTrigger: vd.use_system_trigger,
                triggerInterval: vd.system_trigger_interval,
                script: vd.script,
            }
            selectedDevice = allDeviceOptions.find(option => option.secret === form.triggerDeviceSecret && option.socket === form.triggerDeviceSocket)?.id;
            formIsValid = true;
        } else {
            form = {
                name: '',
                triggerDeviceSecret: allDeviceOptions[0].secret,
                triggerDeviceSocket: allDeviceOptions[0].socket,
                useSystemTrigger: false,
                triggerInterval: 10,
                script: DEFAULT_SCRIPT,
            }
            selectedDevice = allDeviceOptions[0].id;
            formIsValid = false;
        }
        this.setState({ allDeviceOptions, form, selectedDevice, formIsValid });
    }

    validateForm = (f) => {
        return f.name && f.script && ((f.useSystemTrigger && f.triggerInterval > 0) || (f.triggerDeviceSecret && f.triggerDeviceSocket))
    } 

    onTriggerDeviceSelect = (e) => {
        let {allDeviceOptions, form} = this.state;
        let selectedOption = allDeviceOptions.find(option => option.id === +e.target.value);
        form.triggerDeviceSecret = selectedOption.secret;
        form.triggerDeviceSocket = selectedOption.socket;
        form.useSystemTrigger = false;
        this.setState({selectedDevice: e.target.value, form, formIsValid: this.validateForm(form)});
    }

    onTestButtonClick = (e) => {
        this.setState({testError: '', tested: false});
        this.props.testVirtualDeviceScript(this.props.currentOrg, this.state.form.script);
    }

    onFormUpdate = (key, value) => {
        let { form, tested } = this.state;
        form[key] = value;
        this.setState({form, tested: key === 'script' ? false : tested, formIsValid: this.validateForm(form)});
    }

    onDeleteButtonClick = () => {
        this.props.deleteVirtualDevice(this.props.editingVirtualDevice.uuid);
    }

    onSubmitRuleHandler = (e) => {
        e.preventDefault();
        const { form } = this.state;
        if (this.props.isAdding)
            this.props.addVirtualDevice(this.props.currentOrg, form);
        else
            this.props.editVirtualDevice(this.props.editingVirtualDevice.uuid, form);
    }

    render() {
        const { form, allDeviceOptions, selectedDevice } = this.state;

        return (
            <form className={styles.AddNewVirtualDeviceWrapper} onSubmit={this.onSubmitRuleHandler}>
                <div className={styles.AddFormWrapper}>
                    <img
                        src={CloseIcon}
                        className={styles.CloseIcon}
                        alt="close"
                        onClick={this.props.closed}
                    />
                    <div className={styles.Title}>
                        {this.props.isAdding ? "Add New Vurtual Device" : "Edit Vurtual Device"}
                    </div>
                    <div className={styles.Description}>
                        {this.props.isAdding
                        ? "Please fill the information to create a new virtual device"
                        : "Please edit your vurtual device details "}
                    </div>

                    <hr className={styles.Divider} />

                    <Input
                        label="name of the virtual device"
                        name="name"
                        type="text"
                        placeholder="Please name your virtual device"
                        value={form.name}
                        autoComplete="off"
                        onChange={e => this.onFormUpdate('name', e.target.value)}
                        error={''}
                        required
                    />

                    <div className={styles.LabelGroup}>
                        <div className={styles.Label}>Trigger By</div>
                    </div>
                    <div className={styles.Row}>
                        Device
                        <SimpleSwitch enable={form.useSystemTrigger} onChangeEnableHandler={() => this.onFormUpdate('useSystemTrigger', !form.useSystemTrigger)} />
                        System
                    </div>
                    {form.useSystemTrigger ? 
                    <Input 
                        label="system trigger interval"
                        name="trigger interval"
                        type="number"
                        onChange={e => this.onFormUpdate('triggerInterval', +e.target.value)}
                        autoComplete="off"
                        value={form.triggerInterval}
                        required
                    /> : <>
                    <div className={styles.LabelGroup}>
                        <div className={styles.Label}>Trigger Device *</div>
                    </div>
                    <Select
                        name="device_socket_id"
                        placeholder="Select a device"
                        options={allDeviceOptions}
                        value={selectedDevice}
                        autoComplete="off"
                        overidestyleformgroup={{ marginBottom: 15 }}
                        onChange={this.onTriggerDeviceSelect}
                        />
                    </>}

                    
                    <Input
                        label="action script of the virtual device"
                        name="action script"
                        type="editor"
                        onChange={value => this.onFormUpdate('script', value)}
                        value={form.script}
                        className={styles.AceEditor}
                        required
                    />

                    {
                        this.state.tested &&
                        <Input
                            label="test result"
                            name="test result"
                            type="text"
                            value={this.props.value}
                            disabled
                        />
                    }
                    {this.state.testError &&
                        <div className="ErrorText">{this.state.testError}</div>
                    }
                </div>
                <div className={styles.ButtonsWrapper}>
                    {this.props.isAdding ?
                    <>
                    <div style={{ width: 112 }}>
                        <Button
                        type="button"
                        name="Cancel"
                        color="borderred"
                        noMargin
                        click={this.props.closed}
                        />
                    </div>
                    <div style={{ width: 200 }}>
                        <Button
                        disabled={this.state.tested}
                        type="button"
                        color="orange"
                        name={this.props.loading ? "Loading..." : "Test"}
                        click={this.onTestButtonClick}
                        loading={this.props.loading}
                        />
                    </div>
                    <div style={{ width: 200 }}>
                        <Button
                        disabled={!this.state.tested || !this.state.formIsValid}
                        type="submit"
                        name={this.props.loading ? "Loading..." : "Done"}
                        color="primary"
                        loading={this.props.loading}
                        />
                    </div>
                    </>
                    :
                    <>
                    <div style={{ width: 112 }}>
                        <Button
                        type="button"
                        name="Delete"
                        color="borderred"
                        noMargin
                        click={this.props.toggleDeleteModal}
                        />
                    </div>
                    <div style={{ width: 200 }}>
                        <Button
                        disabled={this.state.tested}
                        type="button"
                        color="orange"
                        name={this.props.loading ? "Loading..." : "Test"}
                        click={this.onTestButtonClick}
                        loading={this.props.loading}
                        />
                    </div>
                    <div style={{ width: 200 }}>
                        <Button
                        disabled={!this.state.tested || !this.state.formIsValid}
                        type="submit"
                        name={this.props.loading ? "Loading..." : "Save"}
                        color="primary"
                        loading={this.props.loading}
                        />
                    </div>
                    </>
                    }
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentOrg } = state.org;
    const { devices } = state.devices;
    const { type, loading, value, result, addedVirtualDevice, updatedVirtualDevice, testVirtualDeviceScript, deleteVirtualDevice, error } = state.virtualDevice;
    return {
        currentOrg,
        devices,
        type, 
        loading,
        result,
        value,
        addedVirtualDevice,
        updatedVirtualDevice,
        deleteVirtualDevice,
        error,
        testVirtualDeviceScript,
    };
};
  
export default connect(mapStateToProps, { addVirtualDevice, editVirtualDevice, testVirtualDeviceScript, deleteVirtualDevice })(AddNewVirtualDevice);