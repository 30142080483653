export const GET_SHIFTS_START = 'GET_SHIFTS_START';
export const GET_SHIFTS = 'GET_SHIFTS';
export const GET_SHIFTS_SUCCESS = 'GET_SHIFTS_SUCCESS';
export const GET_SHIFTS_FAIL = 'GET_SHIFTS_FAIL';

export const ADD_SHIFT_START = 'ADD_SHIFT_START';
export const ADD_SHIFT = 'ADD_SHIFT';
export const ADD_SHIFT_SUCCESS = 'ADD_SHIFT_SUCCESS';
export const ADD_SHIFT_FAIL = 'ADD_SHIFT_FAIL';

export const EDIT_SHIFT_START = 'EDIT_SHIFT_START';
export const EDIT_SHIFT = 'EDIT_SHIFT';
export const EDIT_SHIFT_SUCCESS = 'EDIT_SHIFT_SUCCESS';
export const EDIT_SHIFT_FAIL = 'EDIT_SHIFT_FAIL';

export const DELETE_SHIFT_START = 'DELETE_SHIFT_START';
export const DELETE_SHIFT = 'DELETE_SHIFT';
export const DELETE_SHIFT_SUCCESS = 'DELETE_SHIFT_SUCCESS';
export const DELETE_SHIFT_FAIL = 'DELETE_SHIFT_FAIL';