import * as actionTypes from './types';

// ----------------------- GET DEVICE_GROUPINGS -----------------------
export const getDeviceGroupingsStart = () => {
  return {
    type: actionTypes.GET_DEVICE_GROUPINGS_START
  };
}

export const getDeviceGroupings = (org_id) => {
  return {
    type: actionTypes.GET_DEVICE_GROUPINGS,
    org_id
  };
}

export const getDeviceGroupingsSuccess = (response) => {
  return {
    type: actionTypes.GET_DEVICE_GROUPINGS_SUCCESS,
    deviceGroupings: response.groupings
  };
}

export const getDeviceGroupingsFail = (error) => {
  return {
    type: actionTypes.GET_DEVICE_GROUPINGS_FAIL,
    error
  };
}

// ----------------------- ADD DEVICE_GROUPING -----------------------
export const addDeviceGroupingStart = () => {
  return {
    type: actionTypes.ADD_DEVICE_GROUPING_START
  };
}

export const addDeviceGrouping = (org_id, relation_ref, payload) => {
  return {
    type: actionTypes.ADD_DEVICE_GROUPING,
    org_id, relation_ref, payload
  };
}

export const addDeviceGroupingSuccess = (response) => {
  return {
    type: actionTypes.ADD_DEVICE_GROUPING_SUCCESS,
    addedDeviceGrouping: response.groupings[0],
    result: response.result
  };
}

export const addDeviceGroupingFail = (error) => {
  return {
    type: actionTypes.ADD_DEVICE_GROUPING_FAIL,
    error
  };
}

// ----------------------- EDIT DEVICE_GROUPING -----------------------
export const editDeviceGroupingStart = () => {
  return {
    type: actionTypes.EDIT_DEVICE_GROUPING_START
  };
}

export const editDeviceGrouping = (id, relation_ref, payload) => {
  return {
    type: actionTypes.EDIT_DEVICE_GROUPING,
    id, relation_ref, payload
  };
}

export const editDeviceGroupingSuccess = (response) => {
  return {
    type: actionTypes.EDIT_DEVICE_GROUPING_SUCCESS,
    updatedDeviceGrouping: response.groupings[0],
    result: response.result
  };
}

export const editDeviceGroupingFail = (error) => {
  return {
    type: actionTypes.EDIT_DEVICE_GROUPING_FAIL,
    error
  };
}

// ----------------------- DELETE DEVICE_GROUPING -----------------------
export const deleteDeviceGroupingStart = () => {
  return {
    type: actionTypes.DELETE_DEVICE_GROUPING_START
  };
}

export const deleteDeviceGrouping = (id) => {
  return {
    type: actionTypes.DELETE_DEVICE_GROUPING,
    id
  };
}

export const deleteDeviceGroupingSuccess = (result) => {
  return {
    type: actionTypes.DELETE_DEVICE_GROUPING_SUCCESS,
    result
  };
}

export const deleteDeviceGroupingFail = (error) => {
  return {
    type: actionTypes.DELETE_DEVICE_GROUPING_FAIL,
    error
  };
}
