import * as actionTypes from './types';

// ----------------------- GET PAGES -----------------------
export const getPagesStart = () => {
  return {
    type: actionTypes.GET_PAGES_START
  };
}

export const getPages = (org_id, user_id, name, page_type) => {
  return {
    type: actionTypes.GET_PAGES,
    org_id,
    user_id,
    name,
    page_type
  };
}

export const getPagesSuccess = (response) => {
  if (response.page) {
    return {
      type: actionTypes.GET_PAGES_SUCCESS,
      page: response.page
    };
  }
  return {
    type: actionTypes.GET_PAGES_SUCCESS,
    pages: response.pages
  };
}

export const getPagesFail = (error) => {
  return {
    type: actionTypes.GET_PAGES_FAIL,
    error: error
  };
}

// ----------------------- GET PAGE AND MEMBERS IN THE PAGE -----------------------
export const getPageAndMembersStart = () => {
  return {
    type: actionTypes.GET_PAGE_AND_MEMBERS_START
  };
}

export const getPageAndMembers = (id) => {
  return {
    type: actionTypes.GET_PAGE_AND_MEMBERS,
    id
  };
}

export const getPageAndMembersSuccess = (response) => {
  return {
    type: actionTypes.GET_PAGE_AND_MEMBERS_SUCCESS,
    pageAndMembers: {
      page: response.page,
      members: response.members
    }
  };
}

export const getPageAndMembersFail = (error) => {
  return {
    type: actionTypes.GET_PAGE_AND_MEMBERS_FAIL,
    error: error
  };
}

// ----------------------- ADD PAGE -----------------------
export const addPageStart = () => {
  return {
    type: actionTypes.ADD_PAGE_START
  };
}

export const addPage = (org_id, name, detail, page_type, url, properties) => {
  return {
    type: actionTypes.ADD_PAGE,
    org_id,
    name,
    detail,
    page_type,
    url,
    properties
  };
}

export const addPageSuccess = (response) => {
  return {
    type: actionTypes.ADD_PAGE_SUCCESS,
    addedPage: response.page,
    result: response.result
  };
}

export const addPageFail = (error) => {
  return {
    type: actionTypes.ADD_PAGE_FAIL,
    error: error
  };
}

// ----------------------- EDIT PAGE -----------------------
export const editPageStart = () => {
  return {
    type: actionTypes.EDIT_PAGE_START
  };
}

export const editPage = (id, name, detail, page_type, url, properties, addingMembers, deletingMembers) => {
  return {
    type: actionTypes.EDIT_PAGE,
    id,
    name,
    detail,
    page_type,
    url,
    properties,
    addingMembers, deletingMembers
  };
}

export const editPageSuccess = (response) => {
  return {
    type: actionTypes.EDIT_PAGE_SUCCESS,
    updatedPage: response.page,
    result: response.result
  };
}

export const editPageFail = (error) => {
  return {
    type: actionTypes.EDIT_PAGE_FAIL,
    error: error
  };
}

// ----------------------- DELETE PAGE -----------------------
export const deletePageStart = () => {
  return {
    type: actionTypes.DELETE_PAGE_START
  };
}

export const deletePage = (id) => {
  return {
    type: actionTypes.DELETE_PAGE,
    id: id
  };
}

export const deletePageSuccess = (result) => {
  return {
    type: actionTypes.DELETE_PAGE_SUCCESS,
    result: result
  };
}

export const deletePageFail = (error) => {
  return {
    type: actionTypes.DELETE_PAGE_FAIL,
    error: error
  };
}

// ----------------------- UPDATE_PAGE_AFTER_ADD_WIDGET -----------------------

export const updatePageAfterAddWidget = (response) => {
  return {
    type: actionTypes.UPDATE_PAGE_AFTER_ADD_WIDGET,
    pageAndMembers: {
      page: response.page,
      members: response.members
    }
  };
}

// ----------------------- ADD PAGE MEMBERS -----------------------
export const addPageMembersStart = () => {
  return {
    type: actionTypes.ADD_PAGE_MEMBERS_START
  };
}

export const addPageMembers = (id, user_ids) => {
  return {
    type: actionTypes.ADD_PAGE_MEMBERS,
    id,
    user_ids
  };
}

export const addPageMembersSuccess = (response) => {
  return {
    type: actionTypes.ADD_PAGE_MEMBERS_SUCCESS,
    updatedPage: response.page,
    result: response.result
  };
}

export const addPageMembersFail = (error) => {
  return {
    type: actionTypes.ADD_PAGE_MEMBERS_FAIL,
    error: error
  };
}

// ----------------------- REMOVE PAGE MEMBERS -----------------------
export const removePageMembersStart = () => {
  return {
    type: actionTypes.REMOVE_PAGE_MEMBERS_START
  };
}

export const removePageMembers = (id, user_ids) => {
  return {
    type: actionTypes.REMOVE_PAGE_MEMBERS,
    id,
    user_ids
  };
}

export const removePageMembersSuccess = (response) => {
  return {
    type: actionTypes.REMOVE_PAGE_MEMBERS_SUCCESS,
    updatedPage: response.page,
    result: response.result
  };
}

export const removePageMembersFail = (error) => {
  return {
    type: actionTypes.REMOVE_PAGE_MEMBERS_FAIL,
    error: error
  };
}

// ----------------------- CHANGE PAGE ADMIN -----------------------
export const changePageAdminStart = () => {
  return {
    type: actionTypes.CHANGE_PAGE_ADMIN_START
  };
}

export const changePageAdmin = (id, user_id) => {
  return {
    type: actionTypes.CHANGE_PAGE_ADMIN,
    id,
    user_id
  };
}

export const changePageAdminSuccess = (response) => {
  return {
    type: actionTypes.CHANGE_PAGE_ADMIN_SUCCESS,
    updatedPage: response.page,
    result: response.result
  };
}

export const changePageAdminFail = (error) => {
  return {
    type: actionTypes.CHANGE_PAGE_ADMIN_FAIL,
    error: error
  };
}

// ----------------------- LEAVE PAGE -----------------------
export const leavePageStart = () => {
  return {
    type: actionTypes.LEAVE_PAGE_START
  };
}

export const leavePage = (id) => {
  return {
    type: actionTypes.LEAVE_PAGE,
    id: id
  };
}

export const leavePageSuccess = (result) => {
  return {
    type: actionTypes.LEAVE_PAGE_SUCCESS,
    result: result
  };
}

export const leavePageFail = (error) => {
  return {
    type: actionTypes.LEAVE_PAGE_FAIL,
    error: error
  };
}

// export default {
//   getPagesStart,
//   getPages,
//   getPagesSuccess,
//   getPagesFail,
//   addPage,
//   addPageStart,
//   addPageSuccess,
//   addPageFail,
//   editPage,
//   editPageStart,
//   editPageSuccess,
//   editPageFail,
//   deletePage,
//   deletePageStart,
//   deletePageSuccess,
//   deletePageFail,
//   updatePageAfterAddWidget,
//   addPageMembers,
//   addPageMembersStart,
//   addPageMembersSuccess,
//   addPageMembersFail,
//   removePageMembers,
//   removePageMembersStart,
//   removePageMembersSuccess,
//   removePageMembersFail,
//   changePageAdmin,
//   changePageAdminStart,
//   changePageAdminSuccess,
//   changePageAdminFail,
//   leavePage,
//   leavePageStart,
//   leavePageSuccess,
//   leavePageFail,
// };