import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './NotificationCenter.module.scss';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import Button from '../../components/UI/Button/Button';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import AddNewNotification from '../../components/Notification/AddNewNotification/AddNewNotification';
import NotificationList from '../../components/Notification/NotificationList/NotificationList';

import { getNotifications, deleteNotification } from '../../../state/ducks/Notification/actions';
import { DELETE_NOTIFICATION_SUCCESS } from '../../../state/ducks/Notification/types';

class NotificationCenter extends Component {
  state = {
    currentTabIndex: 0,
    selectedNotification: null,
    openNotificationForm: false,
    isAdding: false,
    editingNotification: null,
    isDeleteModalOpened: false,
  }

  componentDidMount() {
    this.queryNotification();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.queryNotification();
      this.closeAddNewNotificationHandler();
    } else {
      if ((this.props.result !== prevProps.result) && this.props.result === 'success') {
        if ((prevProps.addedNotification !== this.props.addedNotification) || (prevProps.updatedNotification !== this.props.updatedNotification) || (this.props.type === DELETE_NOTIFICATION_SUCCESS)) {
          this.queryNotification();
        } else { }
      }
    }
  }

  queryNotification = () => {
    const isAdminMode = localStorage.getItem('sight_view_mode') === 'admin';
    if (isAdminMode) {
      this.props.getNotifications();
    } else {
      this.props.getNotifications(this.props.currentOrg);
    }
  }

  onAddNotificationHandler = () => {
    if (!this.state.isAdding) {
      this.setState({ isAdding: true, editingNotification: null, openNotificationForm: true, selectedNotification: null });
    }
  }

  closeAddNewNotificationHandler = () => {
    this.setState({ openNotificationForm: false, isAdding: false, editingNotification: null, selectedNotification: null })
  }

  onEditNotificationHandler = (index) => {
    if (!this.state.editingNotification || this.state.editingNotification.uuid !== this.props.notifications[index].uuid) {
      this.setState({ isAdding: false, editingNotification: this.props.notifications[index], openNotificationForm: true, selectedNotification: index });
    }
  }

  onDeleteNotificationHandler = () => {
    this.setState({ isDeleteModalOpened: true });
  }

  deleteModalClickHandler = () => {
    this.setState(prevState => {
      return { isDeleteModalOpened: !prevState.isDeleteModalOpened }
    });
  }

  onConfirmDeleteNotification = () => {
    this.props.deleteNotification(this.state.editingNotification.uuid);
    this.setState({ isDeleteModalOpened: false });
  }

  render() {
    const isAdminMode = localStorage.getItem('sight_view_mode') === 'admin';

    return (
      <div className={styles.Notification}>
        <div className={styles.NotificationTopBar}>
          <div className={styles.TopMenuNotification}>
            <div style={{ display: 'flex', flex: 1 }}>
              <div className={styles.MenuWrapper}>
                <div className={this.state.currentTabIndex === 0 ? styles.ActiveMenu : styles.Menu} onClick={() => this.setState({ currentTabIndex: 0 })}>
                  <span className={styles.MenuTitle}>Notification</span>
                </div>
              </div>
            </div>
            {
              this.state.currentTabIndex === 0
                ? <div className={styles.AddButtonWrapper}>
                  <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Notification" noMargin click={this.onAddNotificationHandler} />
                </div>
                : null
            }
          </div>
        </div>
        {
          this.state.currentTabIndex === 0 ?
            <div className={styles.NotificationBody}>
              <div className={styles.NotificationTopics}>
                <div className={styles.NotificationFix} style={{ height: `calc(100vh - ${140 + this.props.offsetHeight}px` }}>
                  {
                    this.props.loading ? <><div className={styles.LoadingCard}></div> <div className={styles.LoadingCard}></div></> :
                      this.props.notifications ?
                        <NotificationList
                          notifications={isAdminMode ? this.props.notifications.filter(n => n.type !== 3) : this.props.notifications.filter(n => n.type === 3)}
                          onEditNotification={this.onEditNotificationHandler}
                          selectedNotification={this.state.selectedNotification}
                          closed={this.closeAddNewNotificationHandler}
                        />
                        : null
                  }
                </div>
              </div>

              <div className={styles.NotificationDetails}>
                {
                  this.state.openNotificationForm &&
                  <AddNewNotification
                    isAdding={this.state.isAdding}
                    editingNotification={this.state.editingNotification}
                    closed={this.closeAddNewNotificationHandler}
                    toggleDeleteModal={this.onDeleteNotificationHandler}
                  />
                }
              </div>
            </div>
            : null
        }

        <DeleteModal
          title="Notification"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeleteNotification}
        />

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { loggedInUser } = state.auth;
  const { notifications, loading, type, result, addedNotification, updatedNotification } = state.notification;
  return {
    loggedInUser, currentOrg,
    notifications, loading, type, result, addedNotification, updatedNotification
  };
};

export default connect(mapStateToProps, { getNotifications, deleteNotification })(NotificationCenter);