import * as actionTypes from './types';

// ----------------------- GET MODULE CONFIG -----------------------
export const getModuleConfigsStart = () => {
  return {
    type: actionTypes.GET_MODULE_CONFIGS_START
  };
}

export const getModuleConfigs = (org_id, module_id) => {
  return {
    type: actionTypes.GET_MODULE_CONFIGS,
    org_id, module_id
  };
}

export const getModuleConfigsSuccess = (response) => {
  return {
    type: actionTypes.GET_MODULE_CONFIGS_SUCCESS,
    moduleConfigs: response.module_configs
  };
}

export const getModuleConfigsFail = (error) => {
  return {
    type: actionTypes.GET_MODULE_CONFIGS_FAIL,
    error
  };
}

// ----------------------- ADD MODULE CONFIG -----------------------

export const postModuleConfigStart = () => {
  return {
    type: actionTypes.POST_MODULE_CONFIG_START
  };
}

export const postModuleConfig = (org_id, module_id, key, value) => {
  return {
    type: actionTypes.POST_MODULE_CONFIG,
    org_id, module_id, key, value
  };
}

export const postModuleConfigSuccess = (response) => {
  return {
    type: actionTypes.POST_MODULE_CONFIG_SUCCESS,
    moduleConfigs: response.module_configs
  };
}

export const postModuleConfigFail = (error) => {
  return {
    type: actionTypes.POST_MODULE_CONFIG_FAIL,
    error
  };
}

// ----------------------- ADD MODULE CONFIG AS JSON -----------------------

export const postModuleConfigJsonStart = () => {
  return {
    type: actionTypes.POST_MODULE_CONFIG_JSON_START
  };
}

export const postModuleConfigJson = (body) => {
  return {
    type: actionTypes.POST_MODULE_CONFIG_JSON,
    body
  };
}

export const postModuleConfigJsonSuccess = (response) => {
  return {
    type: actionTypes.POST_MODULE_CONFIG_JSON_SUCCESS,
    module_configs: response.module_configs
  };
}

export const postModuleConfigJsonFail = (error) => {
  return {
    type: actionTypes.POST_MODULE_CONFIG_JSON_FAIL,
    error
  };
}