import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  sequentialCommand: null,
  commands: null,
  selectedSequentialCommand: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SEQUENTIAL_COMMAND_START: return getSequentialCommandStart(state, action);
    case actionTypes.GET_SEQUENTIAL_COMMAND_SUCCESS: return getSequentialCommandSuccess(state, action);
    case actionTypes.GET_SEQUENTIAL_COMMAND_FAIL: return getSequentialCommandFail(state, action);

    case actionTypes.GET_SEQUENTIAL_COMMANDS_START: return getSequentialCommandsStart(state, action);
    case actionTypes.GET_SEQUENTIAL_COMMANDS_SUCCESS: return getSequentialCommandsSuccess(state, action);
    case actionTypes.GET_SEQUENTIAL_COMMANDS_FAIL: return getSequentialCommandsFail(state, action);

    case actionTypes.START_SEQUENTIAL_COMMAND_START: return startSequentialCommandStart(state, action);
    case actionTypes.START_SEQUENTIAL_COMMAND_SUCCESS: return startSequentialCommandSuccess(state, action);
    case actionTypes.START_SEQUENTIAL_COMMAND_FAIL: return startSequentialCommandFail(state, action);

    case actionTypes.STOP_SEQUENTIAL_COMMAND_START: return stopSequentialCommandStart(state, action);
    case actionTypes.STOP_SEQUENTIAL_COMMAND_SUCCESS: return stopSequentialCommandSuccess(state, action);
    case actionTypes.STOP_SEQUENTIAL_COMMAND_FAIL: return stopSequentialCommandFail(state, action);

    default: return state;
  }
};

const getSequentialCommandStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getSequentialCommandSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    selectedSequentialCommand: action.sequentialCommand,
    error: null,
    loading: false
  });
}

const getSequentialCommandFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const getSequentialCommandsStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true, commands: null });
}

const getSequentialCommandsSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    commands: { sequentialCommands: action.sequentialCommands, wid: action.wid },
    error: null,
    loading: false
  });
}

const getSequentialCommandsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const startSequentialCommandStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const startSequentialCommandSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    sequentialCommand: action.sequentialCommand,
    error: null,
    loading: false
  });
}

const startSequentialCommandFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const stopSequentialCommandStart = (state) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const stopSequentialCommandSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    // sequentialCommand: action.sequentialCommand, 
    error: null,
    loading: false
  });
}

const stopSequentialCommandFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;