import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './ProfileCard.module.scss';
import EditIcon from '../../../../assets/icons/edit-gray.svg';
import ButtonSpinner from '../../UI/Spinner/ButtonSpinner';
import Admin from '../../../../assets/images/admin.svg';

import { storage } from '../../../../firebase';
import { editUser } from '../../../../state/ducks/Auth/actions';

class ProfileCard extends Component {

  state = {
    uploading: false,
    editType: 'profilePicture'
  }

  onSelectFile = event => {
    if (event.target.files[0]) {
      const { loggedInUser } = this.props;
      let profilePicture = event.target.files[0];
      const uploadTask = storage.ref(`images/${profilePicture.name}`).put(profilePicture);
      uploadTask.on('state_changed',
        (snapshot) => {
          this.setState({ uploading: true })
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage.ref('images').child(profilePicture.name).getDownloadURL().then(url => {
            this.setState({ uploading: false })
            this.props.editUser(
              loggedInUser.id,
              this.state.editType,
              loggedInUser.first_name,
              loggedInUser.last_name,
              loggedInUser.tel,
              loggedInUser.job_title,
              url
            );
          })
        });
    }
  }

  render() {
    let { loggedInUser } = this.props;
    return (
      <div className={styles.ProfileContainer}>
        <div className={styles.HeaderCard}>
          <div style={{ position: 'relative' }}>
            <div className={styles.ProfilePicture}>
              <label htmlFor="profile_pic">
              <img className={styles.Picture} src={loggedInUser.profile_picture? loggedInUser.profile_picture : Admin} alt="my profile pic" />
              </label>
              {
                this.state.uploading || (this.props.loading && this.props.editType === 'profilePicture') ? <div className={styles.Loading}><ButtonSpinner /></div> : null
              }
            </div>
            <div className={styles.EditPicture}>
              <label htmlFor="profile_pic"><img src={EditIcon} alt="profile" /></label>
              <input type="file" id="profile_pic" onChange={this.onSelectFile} accept=".png, .jpg, .jpeg" /></div>
          </div>
          <div className={styles.Name}>{loggedInUser.first_name} {loggedInUser.last_name}</div>
          <div className={styles.JobTitle}>{loggedInUser.job_title}</div>
        </div>
        <div className={styles.Line}>&nbsp;</div>
        <div className={styles.BodyCard}>
          <div className={styles.ListData}>
            <div className={styles.Title}>USERNAME</div>
            <div className={styles.Text}>{loggedInUser.username}</div>
          </div>
          <div className={styles.ListData}>
            <div className={styles.Title}>EMAIL ADDRESS</div>
            <div className={styles.Text}>{loggedInUser.email}</div>
          </div>
          <div className={styles.ListData}>
            <div className={styles.Title}>PHONE NUMBER</div>
            <div className={styles.Text}>{loggedInUser.tel ? loggedInUser.tel : '-'}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser, loading, editType } = state.auth;
  return { loggedInUser, loading, editType };
};

export default connect(mapStateToProps, { editUser })(ProfileCard);