import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  ruleCommands: null,
  addedRuleCommand: null,
  updatedRuleCommand: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RULE_COMMANDS_START: return getRuleCommandsStart(state, action);
    case actionTypes.GET_RULE_COMMANDS_SUCCESS: return getRuleCommandsSuccess(state, action);
    case actionTypes.GET_RULE_COMMANDS_FAIL: return getRuleCommandsFail(state, action);

    case actionTypes.ADD_RULE_COMMAND_START: return addRuleCommandStart(state, action);
    case actionTypes.ADD_RULE_COMMAND_SUCCESS: return addRuleCommandSuccess(state, action);
    case actionTypes.ADD_RULE_COMMAND_FAIL: return addRuleCommandFail(state, action);

    case actionTypes.EDIT_RULE_COMMAND_START: return editRuleCommandStart(state, action);
    case actionTypes.EDIT_RULE_COMMAND_SUCCESS: return editRuleCommandSuccess(state, action);
    case actionTypes.EDIT_RULE_COMMAND_FAIL: return editRuleCommandFail(state, action);

    case actionTypes.DELETE_RULE_COMMAND_START: return deleteRuleCommandStart(state, action);
    case actionTypes.DELETE_RULE_COMMAND_SUCCESS: return deleteRuleCommandSuccess(state, action);
    case actionTypes.DELETE_RULE_COMMAND_FAIL: return deleteRuleCommandFail(state, action);

    default: return state;
  }
};

const getRuleCommandsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getRuleCommandsSuccess = (state, action) => {
  return updateObject(state, {
    ruleCommands: action.ruleCommands,
    error: null,
    loading: false
  });
}

const getRuleCommandsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addRuleCommandStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addRuleCommandSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedRuleCommand: action.addedRuleCommand,
    error: null,
    loading: false
  });
}

const addRuleCommandFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editRuleCommandStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editRuleCommandSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedRuleCommand: action.updatedRuleCommand,
    error: null,
    loading: false
  });
}

const editRuleCommandFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const deleteRuleCommandStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true, type: action.type });
}

const deleteRuleCommandSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteRuleCommandFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

export default reducer;