
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_REPORT_PRESETS, ADD_REPORT_PRESET, EDIT_REPORT_PRESET, DELETE_REPORT_PRESET } from './types';

export function* watchReportPreset() {
  yield takeEvery(GET_REPORT_PRESETS, getReportPresetsSaga);
  yield takeEvery(ADD_REPORT_PRESET, addReportPresetSaga);
  yield takeEvery(EDIT_REPORT_PRESET, editReportPresetSaga);
  yield takeEvery(DELETE_REPORT_PRESET, deleteReportPresetSaga);
}

export function* getReportPresetsSaga(params) {
  yield put(actions.getReportPresetsStart());

  let api = 'v1/reportpreset';
  api += '?org_id=' + params.org_id;
  
  try {
    const response = yield axios.get(api);
    // console.log("GET REPORT_PRESETS SUCCESS", response.data);
    yield put(actions.getReportPresetsSuccess(response.data));
  } catch (err) {
    console.log("GET REPORT_PRESETS FAILED", err)
    yield put(actions.getReportPresetsFail(err.response));
  }
}

export function* addReportPresetSaga(params) {
  yield put(actions.addReportPresetStart());
  // org_id, name, type, auto_gen, emails, hour, minute, interval, properties
  let postData = 'name=' + encodeURIComponent(params.name);
  postData += "&org_id=" + params.org_id;
  postData += "&type=" + params.presetType;
  postData += "&auto_gen=" + params.auto_gen;
  postData += "&emails=" + encodeURIComponent(params.emails);
  postData += "&hour=" + params.hour;
  postData += "&minute=" + params.minute;
  postData += "&interval=" + params.interval;
  postData += "&properties=" + encodeURIComponent(JSON.stringify(params.properties));

  const api = 'v1/reportpreset';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD REPORT_PRESET SUCCESS", response);
    yield put(actions.addReportPresetSuccess(response.data));
  } catch (err) {
    console.log("ADD REPORT_PRESET FAILED", err.response.data);
    yield put(actions.addReportPresetFail(err.response.data.error));
  }
}

export function* editReportPresetSaga(params) {
  yield put(actions.editReportPresetStart());

  let putData = 'name=' + encodeURIComponent(params.name);
  putData += "&type=" + params.presetType;
  putData += "&auto_gen=" + params.auto_gen;
  putData += "&emails=" + encodeURIComponent(params.emails);
  putData += "&hour=" + params.hour;
  putData += "&minute=" + params.minute;
  putData += "&interval=" + params.interval;
  putData += "&properties=" + encodeURIComponent(JSON.stringify(params.properties));

  const api = 'v1/reportpreset/' + params.id;

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT REPORT_PRESET SUCCESS", response.data);
    yield put(actions.editReportPresetSuccess(response.data));
  } catch (err) {
    console.log("EDIT REPORT_PRESET FAILED", err.response.data);
    yield put(actions.editReportPresetFail(err.response.data));
  }
}

export function* deleteReportPresetSaga(params) {
  yield put(actions.deleteReportPresetStart());

  const api = 'v1/reportpreset/' + params.id;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE ReportPreset SUCCESS", response.data);
    yield put(actions.deleteReportPresetSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE ReportPreset FAILED", err.response);
    yield put(actions.deleteReportPresetFail(err.response));
  }
}