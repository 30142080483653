import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  updatedActualProduction: null,
  startedProduction: null,
  stoppedProduction: null,
  actualOee: null,
  actualProductions: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTUAL_PRODUCTION_START: return getActualProductionsStart(state, action);
    case actionTypes.GET_ACTUAL_PRODUCTION_SUCCESS: return getActualProductionSuccess(state, action);
    case actionTypes.GET_ACTUAL_PRODUCTION_FAIL: return getActualProductionFail(state, action);

    case actionTypes.UPDATE_ACTUAL_PRODUCTION_START: return updateActualProductionStart(state, action);
    case actionTypes.UPDATE_ACTUAL_PRODUCTION_SUCCESS: return updateActualProductionSuccess(state, action);
    case actionTypes.UPDATE_ACTUAL_PRODUCTION_FAIL: return updateActualProductionFail(state, action);

    case actionTypes.DELETE_ACTUAL_PRODUCTION_START: return deleteActualProductionStart(state, action);
    case actionTypes.DELETE_ACTUAL_PRODUCTION_SUCCESS: return deleteActualProductionSuccess(state, action);
    case actionTypes.DELETE_ACTUAL_PRODUCTION_FAIL: return deleteActualProductionFail(state, action);

    case actionTypes.START_PLAN_START: return startPlanStart(state, action);
    case actionTypes.START_PLAN_SUCCESS: return startPlanSuccess(state, action);
    case actionTypes.START_PLAN_FAIL: return startPlanFail(state, action);

    case actionTypes.STOP_PLAN_START: return stopPlanStart(state, action);
    case actionTypes.STOP_PLAN_SUCCESS: return stopPlanSuccess(state, action);
    case actionTypes.STOP_PLAN_FAIL: return stopPlanFail(state, action);

    case actionTypes.CALCULATE_OEE_START: return calculateOeeStart(state, action);
    case actionTypes.CALCULATE_OEE_SUCCESS: return calculateOeeSuccess(state, action);
    case actionTypes.CALCULATE_OEE_FAIL: return calculateOeeFail(state, action);

    default: return state;
  }
};

const getActualProductionsStart = (state, action) => {
  return updateObject(state, { type: action.type, result: null, error: null, actualProductions: null, loading: true });
}


const getActualProductionSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    actualProductions: action.actualProductions,
    loading: false
  });
}

const getActualProductionFail = (state, action) => {
  return updateObject(state, {
    type: action.type,
    error: action.error,
    loading: false
  });
}

const updateActualProductionStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const updateActualProductionSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    updatedActualProduction: action.updatedActualProduction,
    error: null,
    loading: false,
    result: action.result
  });
}

const updateActualProductionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    result: null
  });
}

const deleteActualProductionStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const deleteActualProductionSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    error: null,
    loading: false
  });
}

const deleteActualProductionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const startPlanStart = (state, action) => {
  return updateObject(state, { result: null, error: null });
}

const startPlanSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    startedProduction: action.startedProduction,
    error: null,
    // loading: false
  });
}

const startPlanFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    // loading: false
  });
}

const stopPlanStart = (state, action) => {
  return updateObject(state, { result: null, error: null });
}

const stopPlanSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    stoppedProduction: action.stoppedProduction,
    error: null,
    // loading: false
  });
}

const stopPlanFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    // loading: false
  });
}

const calculateOeeStart = (state, action) => {
  return updateObject(state, { result: null, error: null });
}

const calculateOeeSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    actualOee: {
      apq: action.apq,
      average: action.average,
      expected: action.expected,
      sum: action.sum,
      properties: action.properties
    },
    error: null
  });
}

const calculateOeeFail = (state, action) => {
  return updateObject(state, {
    error: action.error
  });
}

export default reducer;