
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_VARIABLES, ADD_VARIABLE, EDIT_VARIABLE, DELETE_VARIABLE } from './types';

export function* watchVariables() {
  yield takeEvery(GET_VARIABLES, getVariablesSaga);
  yield takeEvery(ADD_VARIABLE, addVariableSaga);
  yield takeEvery(EDIT_VARIABLE, editVariableSaga);
  yield takeEvery(DELETE_VARIABLE, deleteVariableSaga);
}

export function* getVariablesSaga(params) {
  yield put(actions.getVariablesStart());

  let api = 'v1/variable';
  api += '?org_id=' + params.org_id;

  try {
    const response = yield axios.get(api);
    // console.log("GET VARIABLES SUCCESS", response.data);
    yield put(actions.getVariablesSuccess(response.data));
  } catch (err) {
    console.log("GET VARIABLES FAILED", err)
    yield put(actions.getVariablesFail(err.response ? err.response.data.error : 'Something went wrong'));
  }
}

export function* addVariableSaga(params) {
  yield put(actions.addVariableStart());

  let postData = 'org_id=' + params.org_id;
  postData += "&name=" + encodeURIComponent(params.name);
  postData += "&value=" + encodeURIComponent(params.value);
  postData += "&properties=" + encodeURIComponent(JSON.stringify(params.properties));

  const api = 'v1/variable';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD VARIABLE SUCCESS", response);
    yield put(actions.addVariableSuccess(response.data));
  } catch (err) {
    console.log("ADD VARIABLE FAILED", err.response.data);
    yield put(actions.addVariableFail(err.response.data.error));
  }
}

export function* editVariableSaga(params) {
  yield put(actions.editVariableStart());

  let putData = 'id=' + params.id;
  putData += params.name ? '&name=' + encodeURIComponent(params.name) : '';
  putData += params.value ? '&value=' + encodeURIComponent(params.value) : '';
  putData += params.properties ? '&properties=' + encodeURIComponent(JSON.stringify(params.properties)) : '';

  const api = 'v1/variable'

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT VARIABLE SUCCESS", response.data);
    yield put(actions.editVariableSuccess(response.data));
  } catch (err) {
    console.log("EDIT VARIABLE FAILED", err.response.data);
    yield put(actions.editVariableFail(err.response.data.error));
  }
}

export function* deleteVariableSaga(params) {
  yield put(actions.deleteVariableStart());

  const api = 'v1/variable?id=' + params.id;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE Variable SUCCESS", response.data);
    yield put(actions.deleteVariableSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE Variable FAILED", err.response);
    yield put(actions.deleteVariableFail(err.response));
  }
}