import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Team.module.scss';
import PlusCircleIcon from '../../../assets/icons/plus-circle.svg';
import Button from '../../components/UI/Button/Button';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import TeamDrawer from '../../components/Team/TeamDrawer/TeamDrawer';
import TeamCard from '../../components/Team/TeamCard/TeamCard';
import DeleteModal from '../../components/shared/DeleteModal/DeleteModal';
import LoadingCard from '../../components/UI/LoadingSkeleton/LoadingCard/LoadingCard';

import { getTeams, deleteTeam, leaveTeam } from '../../../state/ducks/Team/actions';
import { DELETE_TEAM_SUCCESS, LEAVE_TEAM_SUCCESS } from '../../../state/ducks/Team/types';

class Team extends Component {
  state = {
    isDrawerOpened: false,
    isAdding: false,
    editingTeam: null,
    isDeleteModalOpened: false,
    deletingTeamId: null
  }

  componentDidMount() {
    const { teams, currentOrg } = this.props;
    if (!teams || !teams.length || teams[0].org_id !== currentOrg) {
      this.props.getTeams(currentOrg);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentOrg !== this.props.currentOrg) {
      this.props.getTeams(this.props.currentOrg);
    } else {
      if (this.props.result !== prevProps.result && this.props.result === 'success') {
        if ((prevProps.addedTeam !== this.props.addedTeam) || (prevProps.updatedTeam !== this.props.updatedTeam)
          || [DELETE_TEAM_SUCCESS, LEAVE_TEAM_SUCCESS].includes(this.props.type)) {
          this.props.getTeams(this.props.currentOrg);
        } else {
          // TODO: need to get deleted team index
          // case: delete
          // this.props.tags.splice(this.state.deletingIndex, 1);
        }
      }
    }
  }

  drawerToggleHandler = () => {
    this.setState(prevState => {
      return { isDrawerOpened: !prevState.isDrawerOpened }
    });
  }

  openDrawerForAddingHandler = () => {
    this.setState({ isAdding: true, editingTeam: null, isDrawerOpened: true });
  }

  onEditTeam = (index) => {
    this.setState({ isAdding: false, editingTeam: this.props.teams[index], isDrawerOpened: true });
  }

  onDeleteTeamHandler = (event) => {
    event.stopPropagation();
    this.setState({ deletingTeamId: this.state.editingTeam.id, isDeleteModalOpened: true });
  }

  deleteModalClickHandler = () => {
    this.setState(prevState => {
      return { isDeleteModalOpened: !prevState.isDeleteModalOpened }
    });
  }

  onConfirmDeleteTeam = (event) => {
    event.preventDefault();
    this.props.deleteTeam(this.state.deletingTeamId);
    this.setState({ deletingTeamId: null, isDeleteModalOpened: false, isDrawerOpened: false, editingTeam: null });
  }

  leaveTeamHandler = () => {
    this.props.leaveTeam(this.state.editingTeam.id);
    this.setState({ deletingTeamId: null, isDeleteModalOpened: false, isDrawerOpened: false, editingTeam: null });
  }

  render() {
    let content;
    if (this.props.loading) {
      content = <LoadingCard type="nopic" />;
    } else if (this.props.teams && this.props.teams.length) {
      content = this.props.teams.map((team, index) => {
        if (!team.is_public) {
          return (
            <TeamCard
              key={`team${team.id}`}
              teamIndex={index} data={team}
              editingTeamHandler={this.onEditTeam}
              teamMembers={this.props.allTeamsMembers[team.id]}
            />
          )
        }
        return null;
      });
    } else {
      content = <TeamCard addingCard click={this.openDrawerForAddingHandler} />;
    }

    return (
      <div className={styles.Team}>
        <SideDrawer title={this.state.isAdding ? 'Add New Team' : 'Edit Team'} open={this.state.isDrawerOpened} closed={this.drawerToggleHandler}>
          <TeamDrawer
            isDrawerOpened={this.state.isDrawerOpened}
            closed={this.drawerToggleHandler}
            editingTeam={this.state.editingTeam}
            isAdding={this.state.isAdding}
            toggleDeleteModal={this.onDeleteTeamHandler}
            leaveTeam={this.leaveTeamHandler}
          />
        </SideDrawer>
        <div className={styles.TopSection}>
          <div className={styles.MenuWrapper}>
            <div className={styles.Menu}><span className={styles.MenuTitle}>All Teams</span></div>
          </div>
          <div className={styles.AddButtonWrapper}>
            <Button type="button" icon={PlusCircleIcon} color="primary" name="Add New Team" noMargin click={this.openDrawerForAddingHandler} />
          </div>
        </div>
        <div className="MainContentPadding">
          <div className={styles.CardGridContainer}>
            {content}
          </div>
        </div>

        <DeleteModal
          title="team"
          open={this.state.isDeleteModalOpened}
          modalClosed={this.deleteModalClickHandler}
          confirmDelete={this.onConfirmDeleteTeam}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { type, result, teams, addedTeam, updatedTeam, allTeamsMembers, loading } = state.team;
  return { currentOrg, type, result, teams, addedTeam, updatedTeam, allTeamsMembers, loading };
};

export default connect(mapStateToProps, { getTeams, deleteTeam, leaveTeam })(Team);
