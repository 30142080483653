
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';

import { GET_VIRTUAL_DEVICES, ADD_VIRTUAL_DEVICE, EDIT_VIRTUAL_DEVICE, DELETE_VIRTUAL_DEVICE, TEST_VIRTUAL_DEVICE_SCRIPT } from './types';

export function* watchVirtualDevices() {
  yield takeEvery(GET_VIRTUAL_DEVICES, getVirtualDevicesSaga);
  yield takeEvery(ADD_VIRTUAL_DEVICE, addVirtualDeviceSaga);
  yield takeEvery(EDIT_VIRTUAL_DEVICE, editVirtualDeviceSaga);
  yield takeEvery(DELETE_VIRTUAL_DEVICE, deleteVirtualDeviceSaga);
  yield takeEvery(TEST_VIRTUAL_DEVICE_SCRIPT, testVirtualDeviceScriptSaga);
}

export function* getVirtualDevicesSaga(params) {
  yield put(actions.getVirtualDevicesStart());
  let api = 'v1/virtualdevice?org_id=' + params.org_id;

  try {
    const response = yield axios.get(api);
    // console.log("GET VIRTUAL_DEVICES SUCCESS", response.data);
    yield put(actions.getVirtualDevicesSuccess(response.data.virtual_devices));
  } catch (err) {
    console.log("GET VIRTUAL_DEVICES FAILED", err)
    yield put(actions.getVirtualDevicesFail(err.response));
  }
}

export function* addVirtualDeviceSaga(params) {
  yield put(actions.addVirtualDeviceStart());

  const api = 'v1/virtualdevice';
  let postData = 'org_id=' + params.org_id;
  postData += '&name=' + encodeURIComponent(params.form.name);
  postData += '&trigger_secret=' + encodeURIComponent(params.form.triggerDeviceSecret);
  postData += '&trigger_socket=' + params.form.triggerDeviceSocket;
  postData += '&script=' + encodeURIComponent(params.form.script);
  postData += '&use_system_trigger=' + (params.form.useSystemTrigger ? 'true' : 'false');
  postData += '&trigger_interval=' + params.form.triggerInterval;

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD VIRTUAL_DEVICE SUCCESS", response.data);
    yield put(actions.addVirtualDeviceSuccess(response.data));
  } catch (err) {
    console.log("ADD VIRTUAL_DEVICE FAILED", err.response.data);
    yield put(actions.addVirtualDeviceFail(err.response.data));
  }
}

export function* editVirtualDeviceSaga(params) {
  yield put(actions.editVirtualDeviceStart());

  const api = 'v1/virtualdevice';
  let putData = 'uuid=' + encodeURIComponent(params.uuid);
  putData += '&name=' + encodeURIComponent(params.form.name);
  putData += '&trigger_secret=' + encodeURIComponent(params.form.triggerDeviceSecret);
  putData += '&trigger_socket=' + params.form.triggerDeviceSocket;
  putData += '&script=' + encodeURIComponent(params.form.script);
  putData += '&use_system_trigger=' + (params.form.useSystemTrigger ? 'true' : 'false');
  putData += '&trigger_interval=' + params.form.triggerInterval;

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT VIRTUAL_DEVICE SUCCESS", response.data);
    yield put(actions.editVirtualDeviceSuccess(response.data));
  } catch (err) {
    console.log("EDIT VIRTUAL_DEVICE FAILED", err.response.data);
    yield put(actions.editVirtualDeviceFail(err.response.data));
  }
}

export function* deleteVirtualDeviceSaga(params) {
  yield put(actions.deleteVirtualDeviceStart());

  const api = 'v1/virtualdevice?uuid=' + params.uuid;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE VIRTUAL_DEVICE SUCCESS", response.data);
    yield put(actions.deleteVirtualDeviceSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE VIRTUAL_DEVICE FAILED", err.response);
    yield put(actions.deleteVirtualDeviceFail(err.response));
  }
}

export function* testVirtualDeviceScriptSaga(params) {
  yield put(actions.testVirtualDeviceScriptStart());

  const api = 'v1/virtualdevice/test';
  let postData = 'org_id=' + params.org_id;
  postData += '&script=' + encodeURIComponent(params.script);

  try {
    const response = yield axios.post(api, postData);
    // console.log("TEST VIRTUAL DEVICE SCRIPT SUCCESS", response.data);
    yield put(actions.testVirtualDeviceScriptSuccess(response.data.value));
  } catch (err) {
    console.log("TEST VIRTUAL DEVICE SCRIPT FAILED", err.response.data);
    yield put(actions.testVirtualDeviceScriptFail(err.response.data.error));
  }
}