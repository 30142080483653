import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import styles from './BreakdownLog.module.scss';
import SearchIcon from '../../../../assets/icons/search.svg';
import LoadingTable from '../../UI/LoadingSkeleton/LoadingTable/LoadingTable';

import Pagination from '../../UI/Pagination/Pagination';

const FILTER_STATUS = {
  PENDING: 0,
  ATTACHED: 1
}

class BreakdownLogTable extends Component {
  state = {
    autoFilterLogsIndex: -1,
    eventList: null,
    currentPage: 0,
    rowsPerPage: 50,
  };

  componentDidMount() {
    if (this.props.eventsResult) {
      this.setState({ eventList: this.props.eventsResult });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.eventsResult, this.props.eventsResult) && this.props.eventsResult) {
      const { name, duration, origin, status } = this.props.savingSearchParams;
      if (name !== '' || duration !== '' || origin !== -1 || status !== -1) {
        this.filterListFromAdvSearch(this.props.savingSearchParams);
      } else {
        this.setState({ eventList: this.props.eventsResult });
        this.props.saveBreakdownLogs(this.props.eventsResult);
      }
    } else if (prevProps.savingSearchParams !== this.props.savingSearchParams && this.props.savingSearchParams) {
      this.filterListFromAdvSearch(this.props.savingSearchParams);
    }
  }

  filterListFromAdvSearch = (params, searchResult) => {
    const { name, duration, origin, status } = params;
    let filtered = searchResult ? searchResult : [...this.props.eventsResult];
    if (!filtered || !filtered.length) filtered = [];

    if (name && name !== '') {
      filtered = filtered.filter(evr => evr.rule.name.includes(name));
    }
    if (duration) {
      filtered = filtered.filter(evr => {
        if (evr.end && evr.end.startsWith('0001-01-01')) {
          return false;
        }
        const ms = moment(evr.end).valueOf() - moment(evr.start).valueOf();
        const minutes = Math.floor(ms / 60000);
        return minutes <= +duration ? evr : false;
      });
    }

    // ORIGIN 
    if (origin && origin !== -1) {
      filtered = filtered.filter(evr => evr.origin.toLowerCase().includes(origin.label.toLowerCase()));
    }

    // STATUS
    if (status && status !== -1) {
      filtered = filtered.filter(evr => (
        (status.label === 'Attached' && evr.documents && evr.documents.length) || (status.label === 'Pending' && (!evr.documents || !evr.documents.length))
      ));
    }

    // REASON = needs <BreakdownCodeTreeSelection>

    this.props.saveBreakdownLogs(filtered);
    this.setState({ eventList: filtered });
  }

  onSearchingHandler = (e) => {
    const searchText = e.target.value.toLowerCase();
    if (this.props.eventsResult && this.props.eventsResult.length) {
      const searchResult = this.props.eventsResult.filter(evr => {
        // console.log(evr.documents,searchText, "pending".includes(searchText), (!evr.documents || !evr.documents.length))
        if (evr.rule && evr.rule.name.toLowerCase().includes(searchText)) return evr;
        if (evr.machine_ids && evr.machine_ids.length) {
          const machineNames = this.renderMachineText(evr.machine_ids);
          if (machineNames.toLowerCase().includes(searchText)) return evr;
        }
        if (evr.origin) {
          if (evr.origin.toLowerCase().includes(searchText)) return evr;
        }
        if (evr.documents && evr.documents.length && "attached".includes(searchText)) {
          return evr;
        }
        if ((!evr.documents || !evr.documents.length) && "pending".includes(searchText)) {
          return evr;
        }
        if (evr.documents && evr.documents.length) {
          const reasons = evr.documents[0].option.breakdowns.map(e => e.title).join(', ');
          if (reasons.toLowerCase().includes(searchText)) return evr;
        }

      });

      const { name, duration, origin, status } = this.props.savingSearchParams;
      if (this.props.savingSearchParams && Object.keys(this.props.savingSearchParams).length && (name !== '' || duration !== '' || origin !== -1 || status !== -1)) {
        this.filterListFromAdvSearch(this.props.savingSearchParams, searchResult);
      } else {
        this.setState({ eventList: searchResult });
        this.props.saveBreakdownLogs(searchResult);

      }
    }
  }

  onChangePageHandler = (event, page) => {
    event.preventDefault();
    this.setState({ currentPage: page });
  }

  onChangeRowsPerPageHandler = (event) => {
    event.preventDefault();
    let amount = +event.target.value;
    const { eventList } = this.state;
    let currentPage = this.state.currentPage;
    if (currentPage > 0 && (currentPage * amount) > eventList?.length) {
      currentPage = 0;
    }
    this.setState({ rowsPerPage: amount, currentPage });
  }

  onAutoFilterLogsHandler = (index) => {
    if (index === this.state.autoFilterLogsIndex) {
      this.setState({ eventList: this.props.eventsResult });
    } else {
      let foundList = null;
      if (index === FILTER_STATUS.PENDING) {
        foundList = this.props.eventsResult.filter(evr => !evr.documents);
      } else {
        foundList = this.props.eventsResult.filter(evr => evr.documents && evr.documents.length);
      }
      this.setState({ eventList: foundList });
    }

    this.setState({ autoFilterLogsIndex: index === this.state.autoFilterLogsIndex ? -1 : index });
  }

  renderDateTime = (date, time) => {
    return moment(date + ' ' + time).format("DD/MM/YY HH:mm");
  }

  renderMachineText = (ids) => {
    if (!ids || !ids.length || !this.props.machines) return '-';
    let res = '';
    ids.sort((a, b) => a - b).forEach((id, i) => {
      const found = this.props.machines.find(m => m.id === id);
      res += found ? (found.name + (i !== ids.length - 1 ? ', ' : '')) : '-'
    });
    return res;
  }

  humanizeDuration = (end, start) => {
    if (end && end.startsWith('0001-01-01')) return '-';

    const ms = moment(end).valueOf() - moment(start).valueOf();
    const days = moment.duration(ms).days();
    const hours = moment.duration(ms).hours();
    const minutes = moment.duration(ms).minutes();
    const seconds = moment.duration(ms).seconds();

    let result = days === 0 ? '' : days > 1 ? days + ' days ' : days + ' day ';
    result += hours === 0 ? '' : hours > 1 ? hours + ' hours ' : hours + ' hour ';
    result += minutes === 0 ? '' : minutes > 1 ? minutes + ' minutes ' : minutes + ' minute ';
    if (result === '') {
      result = seconds === 0 ? '' : seconds > 1 ? seconds + ' seconds ' : seconds + ' second';
    }
    return result;
  }

  render() {
    const { eventList, currentPage, rowsPerPage } = this.state;
    let events = eventList && eventList.length ? eventList.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage) : [];

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
          <div className={styles.SearchBarWrapper}>
            <div className={[styles.AutoFillBox, this.state.autoFilterLogsIndex === FILTER_STATUS.PENDING ? styles.Incomplete : ''].join(' ')} onClick={() => this.onAutoFilterLogsHandler(FILTER_STATUS.PENDING)}>PENDING</div>
            <div className={[styles.AutoFillBox, this.state.autoFilterLogsIndex === FILTER_STATUS.ATTACHED ? styles.Complete : ''].join(' ')} onClick={() => this.onAutoFilterLogsHandler(FILTER_STATUS.ATTACHED)}>ATTACHED</div>
            <div className={styles.SearchInputWrapper}>
              <img className={styles.SearchIconInput} style={{ verticalAlign: 'middle', width: 15 }} src={SearchIcon} alt="Search" />
              <input className={styles.SearchExpand} type="text" placeholder="Search any in the list below" onChange={this.onSearchingHandler} />
              <div className={styles.AdvancedSearch} onClick={this.props.openAdvancedSearchDrawer}>Advanced Search</div>
            </div>
          </div>
        </div>

        <div className={styles.TableWrapper}>
          <table>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>No.</th>
                <th>Event Name</th>
                <th>Start</th>
                <th>Stop</th>
                <th>Duration</th>
                <th>Reason</th>
                <th>Machine(s)</th>
                <th>Origin</th>
                <th style={{ width: 120, textAlign: 'center' }}>Log Status</th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.eventLoading
                  ? <tr><td colSpan='100%'><LoadingTable /></td></tr>
                  : events && events.length
                    ? events.map((evr, i) => {
                      return (
                        <tr key={evr.uuid} onClick={(e) => this.props.editBreakdownLog(e, i)}>
                          <td style={{ textAlign: 'center' }}>{(rowsPerPage * currentPage) + (i + 1)}.</td>
                          <td>{evr.rule ? evr.rule.name : 'Manual'}</td>
                          <td>{moment(evr.start).format("DD/MM/YY HH:mm")}</td>
                          <td>{evr.end && evr.end.startsWith('0001-01-01') ? '-' : moment(evr.end).format("DD/MM/YY HH:mm")}</td>
                          <td>{this.humanizeDuration(evr.end, evr.start)}</td>
                          <td>{evr.documents && evr.documents.length ? evr.documents[0].option.breakdowns.map(e => e.title).join(', ') : '-'}</td>
                          <td>{this.renderMachineText(evr.machine_ids)}</td>
                          <td>{evr.origin}</td>
                          <td className={`${evr.documents && evr.documents.length ? styles.Complete : styles.Incomplete}`}>{evr.documents && evr.documents.length ? 'ATTACHED' : 'PENDING'}</td>
                        </tr>
                      )
                    })
                    : <tr><td colSpan="100%" style={{ textAlign: 'center' }}>Not found</td></tr>
              }
            </tbody>
          </table>

        </div>
        <Pagination
          total={this.state.eventList?.length}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          onChangePage={this.onChangePageHandler}
          onChangeRowsPerPage={this.onChangeRowsPerPageHandler}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrg } = state.org;
  const { machines } = state.machine;
  const { eventsResult, addedEvent } = state.event;

  return { currentOrg, machines, eventsResult, addedEvent, eventLoading: state.event.loading };
};

export default connect(mapStateToProps, {})(BreakdownLogTable);
