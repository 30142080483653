export const GET_DATA_START = 'GET_DATA_START';
export const GET_DATA = 'GET_DATA';
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
export const GET_DATA_FAIL = 'GET_DATA_FAIL';

export const GET_DATACAL_START = 'GET_DATACAL_START';
export const GET_DATACAL = 'GET_DATACAL';
export const GET_DATACAL_SUCCESS = 'GET_DATACAL_SUCCESS';
export const GET_DATACAL_FAIL = 'GET_DATACAL_FAIL';

export const GET_DATASET_CAL_START = 'GET_DATASET_CAL_START';
export const GET_DATASET_CAL = 'GET_DATASET_CAL';
export const GET_DATASET_CAL_SUCCESS = 'GET_DATASET_CAL_SUCCESS';
export const GET_DATASET_CAL_FAIL = 'GET_DATASET_CAL_FAIL';

export const GET_FULL_DATASET_CAL_START = 'GET_FULL_DATASET_CAL_START';
export const GET_FULL_DATASET_CAL = 'GET_FULL_DATASET_CAL';
export const GET_FULL_DATASET_CAL_SUCCESS = 'GET_FULL_DATASET_CAL_SUCCESS';
export const GET_FULL_DATASET_CAL_FAIL = 'GET_FULL_DATASET_CAL_FAIL';

export const GET_DATALATEST_START = 'GET_DATALATEST_START';
export const GET_DATALATEST = 'GET_DATALATEST';
export const GET_DATALATEST_SUCCESS = 'GET_DATALATEST_SUCCESS';
export const GET_DATALATEST_FAIL = 'GET_DATALATEST_FAIL';

export const CLEAR_DATA = 'CLEAR_DATA';

export const SET_LOADING_PERCENT = 'SET_LOADING_PERCENT';

export const GET_DATA_EVAL_START = 'GET_DATA_EVAL_START';
export const GET_DATA_EVAL = 'GET_DATA_EVAL';
export const GET_DATA_EVAL_SUCCESS = 'GET_DATA_EVAL_SUCCESS';
export const GET_DATA_EVAL_FAIL = 'GET_DATA_EVAL_FAIL';