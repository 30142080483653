import * as actionTypes from './types';

// ----------------------- GET Virtual DeviceS -----------------------
export const getVirtualDevicesStart = () => {
  return {
    type: actionTypes.GET_VIRTUAL_DEVICES_START
  };
}

export const getVirtualDevices = (org_id) => {
  return {
    type: actionTypes.GET_VIRTUAL_DEVICES,
    org_id
  };
}

export const getVirtualDevicesSuccess = (response) => {
  return {
    type: actionTypes.GET_VIRTUAL_DEVICES_SUCCESS,
    virtualDevices: response
  };
}

export const getVirtualDevicesFail = (error) => {
  return {
    type: actionTypes.GET_VIRTUAL_DEVICES_FAIL,
    error: error
  };
}

// ----------------------- ADD Virtual Device -----------------------
export const addVirtualDeviceStart = () => {
  return {
    type: actionTypes.ADD_VIRTUAL_DEVICE_START
  };
}

export const addVirtualDevice = (org_id, form) => {
  return {
    type: actionTypes.ADD_VIRTUAL_DEVICE,
    org_id,
    form
  };
}

export const addVirtualDeviceSuccess = (response) => {
  return {
    type: actionTypes.ADD_VIRTUAL_DEVICE_SUCCESS,
    addedVirtualDevice: response.virtual_device,
    result: response.result
  };
}

export const addVirtualDeviceFail = (response) => {
  return {
    type: actionTypes.ADD_VIRTUAL_DEVICE_FAIL,
    error: {
      text: response.error,
    }
  };
}

// ----------------------- EDIT Virtual Device -----------------------
export const editVirtualDeviceStart = () => {
  return {
    type: actionTypes.EDIT_VIRTUAL_DEVICE_START
  };
}

export const editVirtualDevice = (uuid, form) => {
  return {
    type: actionTypes.EDIT_VIRTUAL_DEVICE,
    uuid, form
  };
}

export const editVirtualDeviceSuccess = (response) => {
  return {
    type: actionTypes.EDIT_VIRTUAL_DEVICE_SUCCESS,
    updatedVirtualDevice: response.virtual_device,
    result: response.result
  };
}

export const editVirtualDeviceFail = (response) => {
  return {
    type: actionTypes.EDIT_VIRTUAL_DEVICE_FAIL,
    error: {
      text: response.error,
    }
  };
}

// ----------------------- DELETE Virtual Device -----------------------
export const deleteVirtualDeviceStart = () => {
  return {
    type: actionTypes.DELETE_VIRTUAL_DEVICE_START
  };
}

export const deleteVirtualDevice = (uuid) => {
  return {
    type: actionTypes.DELETE_VIRTUAL_DEVICE,
    uuid
  };
}

export const deleteVirtualDeviceSuccess = (result) => {
  return {
    type: actionTypes.DELETE_VIRTUAL_DEVICE_SUCCESS,
    result: result
  };
}

export const deleteVirtualDeviceFail = (error) => {
  return {
    type: actionTypes.DELETE_VIRTUAL_DEVICE_FAIL,
    error: error
  };
}

// ----------------------- TEST Virtual Device Script -----------------------
export const testVirtualDeviceScriptStart = () => {
  return {
    type: actionTypes.TEST_VIRTUAL_DEVICE_SCRIPT_START
  };
}

export const testVirtualDeviceScript = (org_id, script) => {
  return {
    type: actionTypes.TEST_VIRTUAL_DEVICE_SCRIPT,
    org_id, 
    script
  };
}

export const testVirtualDeviceScriptSuccess = (value) => {
  return {
    type: actionTypes.TEST_VIRTUAL_DEVICE_SCRIPT_SUCCESS,
    value
  };
}

export const testVirtualDeviceScriptFail = (error) => {
  return {
    type: actionTypes.TEST_VIRTUAL_DEVICE_SCRIPT_FAIL,
    error: error
  };
}