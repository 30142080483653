import React, { Component } from 'react'
import { connect } from 'react-redux'
import Draggable from 'react-draggable';

import styles from './DraggableContainer.module.scss';

import { editWidgetLayout } from '../../../../state/ducks/Widgets/actions';


class DraggableContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            posX: this.props.dash.layout.x ? this.props.dash.layout.x : 1,
            posY: this.props.dash.layout.y ? this.props.dash.layout.y : 1,
        }
    }

    onPositionChangeHandler = (e, data) => {
        this.setState({
            posX: data.x,
            posY: data.y
        }, () => {
            this.props.editWidgetLayout(
                this.props.pageId,
                this.props.dash.id,
                data.x,
                data.y,
                1,
                1
            );
        })
    }

    render() {
        const { children } = this.props;
        const { posX, posY } = this.state;

        return (
            <Draggable
                bounds="parent"
                position={{x: posX, y: posY}}
                onStop={this.onPositionChangeHandler}
            >
                <div className={styles.Box}>
                    {children}
                </div>
            </Draggable>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    editWidgetLayout 
}

export default connect(mapStateToProps, mapDispatchToProps)(DraggableContainer)