
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_PRODUCTION_PLAN_STRUCTURES, ADD_PRODUCTION_PLAN_STRUCTURE, EDIT_PRODUCTION_PLAN_STRUCTURE, DELETE_PRODUCTION_PLAN_STRUCTURE } from './types';

export function* watchPlanStructures() {
  yield takeEvery(GET_PRODUCTION_PLAN_STRUCTURES, getPlanStructuresSaga);
  yield takeEvery(ADD_PRODUCTION_PLAN_STRUCTURE, addPlanStructureSaga);
  yield takeEvery(EDIT_PRODUCTION_PLAN_STRUCTURE, editPlanStructureSaga);
  yield takeEvery(DELETE_PRODUCTION_PLAN_STRUCTURE, deletePlanStructureSaga);
}

export function* getPlanStructuresSaga(params) {
  yield put(actions.getProductionPlanStructuresStart());

  let api = 'v1/advanceproductionplanstructure?org_id=' + params.org_id;

  try {
    const response = yield axios.get(api);
    // console.log("GET PRODUCTION_PLAN_STRUCTURES SUCCESS", response.data);
    yield put(actions.getProductionPlanStructuresSuccess(response.data));
  } catch (err) {
    console.log("GET PRODUCTION_PLAN_STRUCTURES FAILED", err)
    yield put(actions.getProductionPlanStructuresFail(err.response));
  }
}

export function* addPlanStructureSaga(params) {
  yield put(actions.addProductionPlanStructureStart());

  let api = 'v1/advanceproductionplanstructure';
  let postData = "org_id=" + params.org_id;
  postData += "&name=" + encodeURIComponent(params.name);
  postData += params.detail ? "&detail=" + encodeURIComponent(params.detail) : '';
  postData += "&columns=" + encodeURIComponent(JSON.stringify(params.columns));
  postData += params.properties ? '&properties=' + encodeURIComponent(JSON.stringify(params.properties)) : '';

  try {
    const response = yield axios.post(api, postData);
    // console.log("ADD PRODUCTION_PLAN_STRUCTURE SUCCESS", response.data);
    yield put(actions.addProductionPlanStructureSuccess(response.data));
  } catch (err) {
    console.log("ADD PRODUCTION_PLAN_STRUCTURE FAILED", err.response)
    yield put(actions.addProductionPlanStructureFail(err.response));
  }
}

export function* editPlanStructureSaga(params) {
  yield put(actions.editProductionPlanStructureStart());

  let putData = "uuid=" + params.uuid;
  putData += "&name=" + encodeURIComponent(params.name);
  putData += params.detail ? "&detail=" + encodeURIComponent(params.detail) : '';
  putData += "&columns=" + encodeURIComponent(JSON.stringify(params.columns));
  putData += params.properties ? '&properties=' + encodeURIComponent(JSON.stringify(params.properties)) : '';

  const api = 'v1/advanceproductionplanstructure';

  try {
    const response = yield axios.put(api, putData);
    // console.log("EDIT PRODUCTION_PLAN_STRUCTURE SUCCESS", response.data);
    yield put(actions.editProductionPlanStructureSuccess(response.data));
  } catch (err) {
    console.log("EDIT PRODUCTION_PLAN_STRUCTURE FAILED", err.response.data);
    yield put(actions.editProductionPlanStructureFail(err.response.data));
  }
}

export function* deletePlanStructureSaga(params) {
  yield put(actions.deleteProductionPlanStructureStart());

  const api = 'v1/advanceproductionplanstructure?uuid=' + params.uuid;

  try {
    const response = yield axios.delete(api);
    // console.log("DELETE PRODUCTION_PLAN_STRUCTURE SUCCESS", response.data);
    yield put(actions.deleteProductionPlanStructureSuccess(response.data.result));
  } catch (err) {
    console.log("DELETE PRODUCTION_PLAN_STRUCTURE FAILED", err.response);
    yield put(actions.deleteProductionPlanStructureFail(err.response));
  }
}