import * as actionTypes from './types';
import { updateObject } from '../../utils';

const initialState = {
  type: null,
  result: null,
  error: null,
  loading: false,
  deviceSockets: null,
  addedDeviceSocket: null,
  updatedDeviceSocket: null,
  updatedDeviceSockets: null,
  iconLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DEVICE_SOCKETS_START: return getDeviceSocketsStart(state, action);
    case actionTypes.GET_DEVICE_SOCKETS_SUCCESS: return getDeviceSocketsSuccess(state, action);
    case actionTypes.GET_DEVICE_SOCKETS_FAIL: return getDeviceSocketsFail(state, action);

    case actionTypes.ADD_DEVICE_SOCKET_START: return addDeviceSocketStart(state, action);
    case actionTypes.ADD_DEVICE_SOCKET_SUCCESS: return addDeviceSocketSuccess(state, action);
    case actionTypes.ADD_DEVICE_SOCKET_FAIL: return addDeviceSocketFail(state, action);

    case actionTypes.EDIT_DEVICE_SOCKET_START: return editDeviceSocketStart(state, action);
    case actionTypes.EDIT_DEVICE_SOCKET_SUCCESS: return editDeviceSocketSuccess(state, action);
    case actionTypes.EDIT_DEVICE_SOCKET_FAIL: return editDeviceSocketFail(state, action);

    case actionTypes.EDIT_DEVICE_SOCKETS_WITH_JSON_START: return editDeviceSocketsWithJSONStart(state, action);
    case actionTypes.EDIT_DEVICE_SOCKETS_WITH_JSON_SUCCESS: return editDeviceSocketsWithJSONSuccess(state, action);
    case actionTypes.EDIT_DEVICE_SOCKETS_WITH_JSON_FAIL: return editDeviceSocketsWithJSONFail(state, action);

    case actionTypes.CHANGE_SOCKET_ICON_START: return changeSocketIconStart(state, action);
    case actionTypes.CHANGE_SOCKET_ICON_SUCCESS: return changeSocketIconSuccess(state, action);
    case actionTypes.CHANGE_SOCKET_ICON_FAIL: return changeSocketIconFail(state, action);

    default: return state;
  }
};

const getDeviceSocketsStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const getDeviceSocketsSuccess = (state, action) => {
  const deviceSockets = action.deviceSockets ? action.deviceSockets.sort((a, b) => a.created_date - b.created_date) : null;
  return updateObject(state, {
    deviceSockets,
    error: null,
    loading: false
  });
}

const getDeviceSocketsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const addDeviceSocketStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const addDeviceSocketSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    addedDeviceSocket: action.addedDeviceSocket,
    error: null,
    loading: false
  });
}

const addDeviceSocketFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editDeviceSocketStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editDeviceSocketSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedDeviceSocket: action.updatedDeviceSocket,
    error: null,
    loading: false
  });
}

const editDeviceSocketFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const editDeviceSocketsWithJSONStart = (state, action) => {
  return updateObject(state, { result: null, error: null, loading: true });
}

const editDeviceSocketsWithJSONSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    result: action.result,
    updatedDeviceSockets: action.updatedDeviceSockets,
    error: null,
    loading: false
  });
}

const editDeviceSocketsWithJSONFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const changeSocketIconStart = (state, action) => {
  return updateObject(state, { iconLoading: true });
}

const changeSocketIconSuccess = (state, action) => {
  return updateObject(state, {
    type: action.type,
    updatedDeviceSocket: action.updatedDeviceSocket,
    iconLoading: false
  });
}

const changeSocketIconFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    iconLoading: false
  });
}

export default reducer;