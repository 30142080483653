
import axios from '../../../configs/axios-orders';
import { takeEvery, put } from 'redux-saga/effects';

import * as actions from './actions';
import { GET_DATA, GET_DATACAL, GET_DATASET_CAL, GET_FULL_DATASET_CAL, GET_DATALATEST, GET_DATA_EVAL } from './types';

export function* watchData() {
  yield takeEvery(GET_DATA, getDataSaga);
  yield takeEvery(GET_DATACAL, getDataCalSaga);
  yield takeEvery(GET_DATASET_CAL, getDatasetCalSaga);
  yield takeEvery(GET_FULL_DATASET_CAL, getFullDatasetCalSaga);
  yield takeEvery(GET_DATALATEST, getDataLatestSaga);
  yield takeEvery(GET_DATA_EVAL, getDataEvalSaga);
}

export function* getDataSaga(params) {
  yield put(actions.getDataStart());

  try {
    let data = [];
    let start = params.start;
    // interval in msec unit
    // 14400000 ms = 4hr
    // 86400000 ms = 1day
    let interval = 86400000; // 1 day
    let index = start + interval; // 1 day 
    let loop = Math.ceil((params.stop - start) / interval);
    let count = 0;

    while (start < params.stop) {
      if (index > params.stop) {
        index = params.stop;
      }
      count++;

      let api = 'v1/data';
      api += params.start ? ('?start=' + start) : '';
      api += params.stop ? ('&stop=' + index) : '';
      api += params.device_id ? '&device_id=' + params.device_id : '';
      api += params.secret ? '&secret=' + params.secret : '';
      api += params.socket ? '&socket=' + params.socket : '';

      const response = yield axios.get(api);

      let deviceIndex = params.line_id;

      let loadingPercent = { percent: ((count / loop) * 100).toFixed(2), deviceIndex: deviceIndex };
      yield put(actions.setLoadingPercent(loadingPercent));

      if (response.data.result === "success") {
        if (response.data.data !== null) {
          data = data.concat(response.data.data);
        }
      }

      start = index + 1;
      index += interval;
    }

    const props = {
      secret: params.secret,
      socket: params.socket
    };

    // console.log("GET DATA SUCCESS", data);
    yield put(actions.getDataSuccess(data, params.line_id, props));
  } catch (err) {
    console.log("GET DATA FAILED", err)
    yield put(actions.getDataFail(err.response));
  }
}

export function* getDataCalSaga(params) {
  yield put(actions.getDataCalStart());

  const secret = params.secret;
  const socket = params.socket
  const line_id = params.line_id;
  const timestamp = params.start;

  let api = 'v1/datacal';
  api += '?secret=' + params.secret;
  api += '&socket=' + params.socket;
  api += params.start ? ('&start=' + params.start) : '';
  api += params.stop ? ('&stop=' + params.stop) : '';
  api += params.method ? ('&method=' + params.method) : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET DATACAL SUCCESS", response.data, 'line_id: ', line_id);
    yield put(actions.getDataCalSuccess(line_id, response.data, secret, socket, timestamp, params.properties));
  } catch (err) {
    console.log("GET DATACAL FAILED", err)
    yield put(actions.getDataCalFail(err.response));
  }
}

export function* getDatasetCalSaga(params) {
  const secret = params.secret;
  const socket = params.socket
  const line_id = params.line_id;
  const stop = params.stop;
  const cal_range = Number(params.cal_range) * 1000

  yield put(actions.getDatasetCalStart(line_id));

  try {
    let data = [];
    let start = params.start;
    const fourHr = 14400000;
    let index = start + fourHr - 1; // + 4hr
    let loop = Math.ceil((stop - start) / fourHr);
    let count = 0;

    while (start < params.stop) {
      if (index > params.stop) {
        index = params.stop;
      }

      count++;
      let api = 'v1/datasetcal';
      api += '?secret=' + params.secret;
      api += '&socket=' + params.socket;
      api += '&cal_range=' + params.cal_range;
      api += params.start ? ('&start=' + start) : '';
      api += params.stop ? ('&stop=' + index) : '';
      api += params.method ? ('&method=' + params.method) : '';
      api += params.gapfill ? ('&gapfill=' + params.gapfill) : '';

      // If request time range is less than selected summerized time range (cal_range), request to datacal instead
      if (index - start < cal_range) {
        api = 'v1/datacal';
        api += '?secret=' + params.secret;
        api += '&socket=' + params.socket;
        api += params.start ? ('&start=' + start) : '';
        api += params.stop ? ('&stop=' + index) : '';
        api += params.method ? ('&method=' + params.method) : '';
        api += params.gapfill ? ('&gapfill=' + params.gapfill) : '';
      }

      const response = yield axios.get(api);

      let deviceIndex = params.line_id;

      let loadingPercent = { percent: ((count / loop) * 100).toFixed(2), deviceIndex: deviceIndex };
      yield put(actions.setLoadingPercent(loadingPercent));

      if (response.data.result === "success") {
        if (response.data.values) {
          data = data.concat(response.data.values);
        } else if (response.data.value) {
          data = data.concat({
            timestamp: index,
            value: response.data.value
          });
        }
      }
      start = index + 1;
      index += fourHr;
    }
    // console.log("GET DATASET CAL SUCCESS", data);
    yield put(actions.getDatasetCalSuccess(line_id, data, secret, socket, stop, params.properties));
  } catch (err) {
    console.log("GET DATASET CAL FAILED", err)
    yield put(actions.getDatasetCalFail(err.response));
  }
}

export function* getFullDatasetCalSaga(params) {
  yield put(actions.getFullDatasetCalStart());
  let api = 'v1/datasetcal';
  api += '?secret=' + params.secret;
  api += '&socket=' + params.socket;
  api += '&cal_range=' + params.cal_range;
  api += params.start ? ('&start=' + params.start) : '';
  api += params.stop ? ('&stop=' + params.stop) : '';
  api += params.method ? ('&method=' + params.method) : '';
  api += params.gapfill ? ('&gapfill=' + params.gapfill) : '';

  try {
    const response = yield axios.get(api);
    // console.log("GET FULL DATASET CAL SUCCESS", response.data);
    yield put(actions.getFullDatasetCalSuccess(params.line_id, response.data ? response.data.values : null, params.secret, params.socket, params.stop, params.properties));
  } catch (err) {
    console.log("GET FULL DATASET CAL FAILED", err)
    yield put(actions.getFullDatasetCalFail(err.response));
  }
}

export function* getDataLatestSaga(params) {
  yield put(actions.getDataLatestStart());

  let api = 'v1/datalatest';
  api += '?socket=' + params.socket;
  api += params.secret ? '&secret=' + params.secret : '';
  api += params.device_id ? '&device_id=' + params.device_id : '';

  let props = {
    secret: params.secret,
    socket: params.socket,
    wid: params.wid
  };
  props = { ...props, ...params.properties };

  try {
    const response = yield axios.get(api);
    // console.log("GET DATALATEST SUCCESS", response.data.data[0], props);
    yield put(actions.getDataLatestSuccess(response.data, props));
  } catch (err) {
    console.log("GET DATALATEST FAILED", err)
    yield put(actions.getDataLatestFail(err.response));
  }
}

export function* getDataEvalSaga(params) {
  yield put(actions.getDataEvalStart());

  let api = 'v1/dataeval';
  api += '?formula=' + encodeURIComponent(decodeURIComponent(params.formula));

  try {
    const response = yield axios.get(api);
    // console.log("GET DATA EVAL SUCCESS", response.data.value, params.properties);
    yield put(actions.getDataEvalSuccess(response.data, params.properties));
  } catch (err) {
    console.log("GET DATA EVAL FAILED", err)
    yield put(actions.getDataEvalFail(err.response));
  }
}