export const GET_PRODUCTION_PLAN_STRUCTURES_START = 'GET_PRODUCTION_PLAN_STRUCTURES_START';
export const GET_PRODUCTION_PLAN_STRUCTURES = 'GET_PRODUCTION_PLAN_STRUCTURES';
export const GET_PRODUCTION_PLAN_STRUCTURES_SUCCESS = 'GET_PRODUCTION_PLAN_STRUCTURES_SUCCESS';
export const GET_PRODUCTION_PLAN_STRUCTURES_FAIL = 'GET_PRODUCTION_PLAN_STRUCTURES_FAIL';

export const ADD_PRODUCTION_PLAN_STRUCTURE_START = 'ADD_PRODUCTION_PLAN_STRUCTURE_START';
export const ADD_PRODUCTION_PLAN_STRUCTURE = 'ADD_PRODUCTION_PLAN_STRUCTURE';
export const ADD_PRODUCTION_PLAN_STRUCTURE_SUCCESS = 'ADD_PRODUCTION_PLAN_STRUCTURE_SUCCESS';
export const ADD_PRODUCTION_PLAN_STRUCTURE_FAIL = 'ADD_PRODUCTION_PLAN_STRUCTURE_FAIL';

export const EDIT_PRODUCTION_PLAN_STRUCTURE_START = 'EDIT_PRODUCTION_PLAN_STRUCTURE_START';
export const EDIT_PRODUCTION_PLAN_STRUCTURE = 'EDIT_PRODUCTION_PLAN_STRUCTURE';
export const EDIT_PRODUCTION_PLAN_STRUCTURE_SUCCESS = 'EDIT_PRODUCTION_PLAN_STRUCTURE_SUCCESS';
export const EDIT_PRODUCTION_PLAN_STRUCTURE_FAIL = 'EDIT_PRODUCTION_PLAN_STRUCTURE_FAIL';

export const DELETE_PRODUCTION_PLAN_STRUCTURE_START = 'DELETE_PRODUCTION_PLAN_STRUCTURE_START';
export const DELETE_PRODUCTION_PLAN_STRUCTURE = 'DELETE_PRODUCTION_PLAN_STRUCTURE';
export const DELETE_PRODUCTION_PLAN_STRUCTURE_SUCCESS = 'DELETE_PRODUCTION_PLAN_STRUCTURE_SUCCESS';
export const DELETE_PRODUCTION_PLAN_STRUCTURE_FAIL = 'DELETE_PRODUCTION_PLAN_STRUCTURE_FAIL';