import moment from 'moment';

export const intervalToDateConverter = (interval) => {
    let newStartDate;
    let newStopDate;
    let currentFullDate = new Date();
    let currentYear = currentFullDate.getFullYear();
    let currentMonth = currentFullDate.getMonth() + 1;
    currentMonth = currentMonth < 10 ? '0' + currentMonth : currentMonth.toString();
    let currentDate = currentFullDate.getDate();

    if (interval === 0 || interval === '0') {
        newStartDate = `${currentYear}-${currentMonth}-${currentDate}`;
        newStopDate = `${currentYear}-${currentMonth}-${currentDate}`;
    }
    else if (interval === 1 || interval === '1') {
        newStartDate = `${currentYear}-${currentMonth}-${currentDate}`;
        newStopDate = moment().add(1, 'days').format("YYYY-MM-DD");
    }
    else if (interval === 2 || interval === '2') {
        newStartDate = moment().startOf('week').format("YYYY-MM-DD");
        newStopDate = moment().endOf('week').add(1, 'days').format("YYYY-MM-DD");
    } else if (interval === 3 || interval === '3') {
        newStartDate = moment().startOf('month').format("YYYY-MM-DD");
        newStopDate = moment().endOf('month').format("YYYY-MM-DD");
    }
    
    return [newStartDate, newStopDate]
}