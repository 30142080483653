import React from 'react';

import styles from './Tooltip.module.scss';
import InformationIcon from '../../../../assets/icons/Rule/info.svg';

const tooltip = (props) => (
  <div className={styles.Tooltip} style={props.style}>
    {
      props.isInfo
        ? <>
          <img src={InformationIcon} alt="Tooltip Hover Icon" />
          <span className={styles.Title} style={props.overideTitleStyles}>{props.children}</span>
        </>
        : props.overideIcon
          ? <>
            {props.children}
            <span className={styles.Title} style={props.overideTitleStyles}>{props.text}</span>
          </>
          : props.children
    }

  </div>
);

export default tooltip;