export const GET_REPORT_PRESETS_START = 'GET_REPORT_PRESETS_START';
export const GET_REPORT_PRESETS = 'GET_REPORT_PRESETS';
export const GET_REPORT_PRESETS_SUCCESS = 'GET_REPORT_PRESETS_SUCCESS';
export const GET_REPORT_PRESETS_FAIL = 'GET_REPORT_PRESETS_FAIL';

export const ADD_REPORT_PRESET_START = 'ADD_REPORT_PRESET_START';
export const ADD_REPORT_PRESET = 'ADD_REPORT_PRESET';
export const ADD_REPORT_PRESET_SUCCESS = 'ADD_REPORT_PRESET_SUCCESS';
export const ADD_REPORT_PRESET_FAIL = 'ADD_REPORT_PRESET_FAIL';

export const EDIT_REPORT_PRESET_START = 'EDIT_REPORT_PRESET_START';
export const EDIT_REPORT_PRESET = 'EDIT_REPORT_PRESET';
export const EDIT_REPORT_PRESET_SUCCESS = 'EDIT_REPORT_PRESET_SUCCESS';
export const EDIT_REPORT_PRESET_FAIL = 'EDIT_REPORT_PRESET_FAIL';

export const DELETE_REPORT_PRESET_START = 'DELETE_REPORT_PRESET_START';
export const DELETE_REPORT_PRESET = 'DELETE_REPORT_PRESET';
export const DELETE_REPORT_PRESET_SUCCESS = 'DELETE_REPORT_PRESET_SUCCESS';
export const DELETE_REPORT_PRESET_FAIL = 'DELETE_REPORT_PRESET_FAIL';